import { observable } from 'mobx';

export class DictionaryListItem {
  @observable id: number = 0;
  @observable name: string = '';
  @observable listItemKey: string = '';
  @observable value: string = '';
  @observable migrationId: number | null = null;
  @observable createdOn: Date = new Date();
  @observable createdBy: number = 0;
  @observable modifiedOn: Date | null = null;
  @observable modifiedBy: number | null = null;
  @observable isActive: boolean = false;
  @observable dictionaryListId: number = 0;
}

export class DictionaryList{
  @observable id:number = 0;
  @observable name:string = '';
}