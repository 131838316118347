// productsQuery.ts
import { DocumentNode } from 'graphql';

/**
 * Define the TypeScript interface for the query variables.
 */
export interface ProductsQueryVariables {
  first: number;
  query?: string;
  //sortKey?: ProductSortKeys;
  reverse?: boolean;
}

/**
 * Define the type for the expected response shape.
 * Tailor these fields to match exactly what you plan to use.
 */
export interface ProductsQueryResponse {
  products: {
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    edges: Array<{
      cursor: string;
      node: {
        id: string;
        availableForSale: boolean;
        createdAt: string;
        updatedAt: string;
        descriptionHtml: string;
        description: string;
        handle: string;
        productType: string;
        title: string;
        vendor: string;
        publishedAt: string;
        onlineStoreUrl?: string;
        options: Array<{
          name: string;
          values: string[];
        }>;
        images: {
          pageInfo: {
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
          edges: Array<{
            cursor: string;
            node: {
              id: string;
              url: string;
              altText?: string | null;
              width?: number | null;
              height?: number | null;
            };
          }>;
        };
        variants: {
          pageInfo: {
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
          edges: Array<{
            cursor: string;
            node: {
              id: string;
              title: string;
              price: {
                amount: string;
                currencyCode: string;
              };
              weight?: number | null;
              availableForSale: boolean;
              sku?: string | null;
              compareAtPrice?: {
                amount: string;
                currencyCode: string;
              } | null;
              image?: {
                id: string;
                url: string;
                altText?: string | null;
                width?: number | null;
                height?: number | null;
              } | null;
              selectedOptions: Array<{
                name: string;
                value: string;
              }>;
              unitPrice?: {
                amount: string;
                currencyCode: string;
              } | null;
              unitPriceMeasurement?: {
                measuredType?: string | null;
                quantityUnit?: string | null;
                quantityValue?: number | null;
                referenceUnit?: string | null;
                referenceValue?: number | null;
              } | null;
            };
          }>;
        };
      };
    }>;
  };
}

/**
 * This is your raw GraphQL query as a string.
 * (No need for gql-tag if you’re just passing the string to the Storefront client.)
 */
export const PRODUCTS_QUERY_STRING = /* GraphQL */ `
  query {
    products(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          availableForSale
          createdAt
          updatedAt
          descriptionHtml
          description
          handle
          productType
          title
          vendor
          publishedAt
          onlineStoreUrl
          options {
            name
            values
          }
          images(first: 250) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                url
                altText
                width
                height
              }
            }
          }
          variants(first: 250) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                id
                title
                price {
                  amount
                  currencyCode
                }
                weight
                availableForSale
                sku
                compareAtPrice {
                  amount
                  currencyCode
                }
                image {
                  id
                  url
                  altText
                  width
                  height
                }
                selectedOptions {
                  name
                  value
                }
                unitPrice {
                  amount
                  currencyCode
                }
                unitPriceMeasurement {
                  measuredType
                  quantityUnit
                  quantityValue
                  referenceUnit
                  referenceValue
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * You can either export a simple object with `query` and `variables`,
 * or provide a helper function that takes variables as arguments.
 *
 * Example: A helper function returning the object.
 */
export function getProductsQueryObject(variables: ProductsQueryVariables) {
  return PRODUCTS_QUERY_STRING;
}

/**
 * (Optionally) If your setup requires a DocumentNode instead of a string,
 * you can use a GraphQL parser, e.g., `graphql-tag`, to transform it:
 */
// import { gql } from 'graphql-tag';
// export const PRODUCTS_QUERY: DocumentNode = gql(PRODUCTS_QUERY_STRING);
