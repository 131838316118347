import {
    Box,
    Card,
    Link,
    Typography,
    styled,
    lighten,
    useTheme,
    alpha
} from '@mui/material';
import { SidebarContext } from '../../../contexts/SidebarContext';

const FooterWrapper = styled(Box)(
    ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.colors.primary.dark};
    background-color: ${theme.palette.grey[100]};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    bottom: 0;
    z-index: 15;
    position: fixed;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function Footer() {
    const theme = useTheme();
    return (
        <FooterWrapper

            sx={{
                boxShadow:
                    theme.palette.mode === 'dark'
                        ? `0 1px 0 ${alpha(
                            lighten(theme.colors.primary.main, 0.7),
                            0.15
                        )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                        : `0px 2px 8px -3px ${alpha(
                            theme.colors.alpha.black[100],
                            0.2
                        )}, 0px 5px 22px -4px ${alpha(
                            theme.colors.alpha.black[100],
                            0.1
                        )}`
            }}
        >
            <Box
                p={4}
                display={{ xs: 'block', md: 'flex' }}
                alignItems="center"
                textAlign={{ xs: 'center', md: 'left' }}
                justifyContent="space-between"
            >
                <Box>
                    <Typography variant="subtitle1">
                        2025 - Vivos® Therapeutics, Inc. | All Rights Reserved
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="subtitle1">
                        866-908-4867 | helpme@vivoslife.com
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            pt: { xs: 2, md: 0 }
                        }}
                        variant="subtitle1"
                    >
                        <Link
                            href="https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Privacy 8-17-2020.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </Link>
                        {" | "}
                        <Link
                            href="https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Software Agreement 10-2018.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Software Agreement
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </FooterWrapper>
    );
}

export default Footer;
