import { observer } from 'mobx-react';
import React, { FC, Fragment, useEffect } from 'react';
import { Grid, IconButton, MenuItem, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import { GetAuthToken } from '../components/GetAuthToken';
import { useMainStoreContext } from '../stores/OldStores/MainStore';
import { LeftNav } from '../components/LeftNav/LeftNav';
import { DataGridBL } from '../components/DataGrid/DataGridBL';
import DetailPanelContentTeamList from './Teams/DetailPanelTeamList';
import { GridHeaderTitle } from '../components/GridHeader/GridHeaderTitle';
import { ModalContainer } from '../components/ModalContainer';
import Item from "../components/Item";
import { NewTeamsModal } from './Teams/forms/NewTeamModal';
import DataGrid from '../components/DataGrid/Mui_DataGridGeneric';

const TeamsAdminComponent = (props) => {

    let gridBL: DataGridBL = new DataGridBL();
    let userId = 0;
    let userRoles: any = [];
    const {
        isLeftNavExpanded,
        teamsData,
        setTeamsData,
        toggleLeftNav,
        pageTitle,
        toggleShowNewTeam,
        toggleShowNewTeamModal,
        loadData
    } = useMainStoreContext().teamAdminStore;

    const columns = [
        { field: 'name', headerName: 'Team Name', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'description', headerName: 'Team Description', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
    ]

    const [hasLoaded, setHasLoaded] = React.useState(false);

    useEffect(() => {
        loadData(props.teamTypeId);
    }, []);

    useEffect(() => {
        setHasLoaded(true);
    }, [teamsData]);

    const hdrButtons: any = [
        (<Button className='vivButton large' onClick={() => { }}>FILTER RESULTS</Button>)
    ];

    const handleChange = (value) => {
        console.log(value);
    }

    return (
        <Fragment>
            <GetAuthToken>
                {({ roles, userId }) => { userRoles = roles; userId = userId; return <div></div> }}
            </GetAuthToken>

            <Grid xl={12} container direction="row" className='page'>
                {/* <LeftNav onToggle={toggleLeftNav} /> */}
                <Grid className={'content' + (isLeftNavExpanded === true ? ' small' : ' big')} >
                    <Grid >
                        <GridHeaderTitle
                            title={pageTitle}
                            onChange={() => { }}
                            filterClass='filter'
                            onClear={() => { }}
                            onCancel={() => { }}
                            onFilter={() => { }}
                            numResults={0}

                            buttons={hdrButtons}
                        />
                        <ModalContainer show={toggleShowNewTeam} title='NEW TEAM' onClose={toggleShowNewTeamModal} onClick={toggleShowNewTeamModal} >
                            <NewTeamsModal teamTypeId={props.teamTypeId} />
                        </ModalContainer>
                    </Grid>
                    <Grid>
                        <Grid container direction="column">
                            <Item><Button className='vivButton normal' onClick={toggleShowNewTeamModal}>Add new team</Button></Item>
                        </Grid>
                    </Grid>
                    <Grid className="grid">
                        {hasLoaded &&
                            <DataGrid
                                parentId={"teams_grid"}
                                id={userId}
                                data={teamsData}
                                columns={columns}
                                onChange={handleChange}
                                detailPanel={<DetailPanelContentTeamList />}
                                gridBL={gridBL}
                                getRowId={row => row["id"]}
                            />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export const TeamsAdmin = observer(TeamsAdminComponent);