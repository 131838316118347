import jwt_decode from 'jwt-decode';
import { action, makeObservable, observable } from "mobx";
import { Gateway } from '../../api/Gateway';
import { GlobalUtils } from '../../api/GlobalUtils';
import DictionaryListItem from '../../entities/DictionaryListItem';
import { MainStore } from './MainStore';

export default class AIReportDataLogMeasurementsStore {
    mainStore: MainStore;
    @observable submissionId = 0;
    @observable userId = 0;
    @observable dataLogValues: any = [];
    @observable loading = false;
    @observable vivosPropertyNames: DictionaryListItem[] = [];
    @observable measurementProperties: any = [];

    @action SetDataLogValues = (value: any) => { this.dataLogValues = value; }
    @action setDatalogLoading = (value: boolean) => { this.loading = !value; }
    @action setMeasurementSubmissionId = (value: number) => { this.submissionId = value; }
    @action setVivosPropertyNames = (value: DictionaryListItem[]) => { this.vivosPropertyNames = value; }
    @action setMeasurementProperties = (value: any) => { this.measurementProperties = value; }

    constructor(mainstore: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    @action loadDataValues = (submissionId) => {
        const url = `aireports/measurmentpropertyvalues/${submissionId}`;
        Gateway.get(url).then(resp => {
            this.SetDataLogValues(resp);
            this.setDatalogLoading(false);
        })
    };

    @action loadVivosPropValues = () => {
        GlobalUtils.getVivosMeasurementProperties().then(resp => {
            this.setVivosPropertyNames(resp);
        })
    };

    @action savePropertyValues = (data: any[]) => {
        const url = `aireports/measurementpropertyvalues/save/${this.userId}`;
        Gateway.postNonAudit(url, data).then((resp) => {
            this.loadDataValues(resp[0].submissionId);
        });
    }

    @action saveDataValues = (propertyId, column, newValue, visitRecordId) => {
        const url = `aireports/measurementpropertyvalues/${this.submissionId}/visit/${visitRecordId}`;
        if (column == "vivosPropertyId") {
            Gateway.post(url, { "propertyId": propertyId, "vivosPropertyId": newValue, "IsActive": true });

        } else if (column.toLowerCase() == "isactive") {
            Gateway.post(url, { "propertyId": propertyId, "IsActive": false }).then(resp => {
                this.loadDataValues(this.submissionId);
            });

        } else {
            Gateway.post(url, { "propertyId": propertyId, "column": column, "newValue": newValue, "IsActive": true });
        }
    };

    @action deleteAllDataValues = (visitRecordId) => {
        const url = `aireports/measurementpropertyvalues/deleteall/${this.submissionId}/visit/${visitRecordId}`;
        Gateway.put(url, {}).then(resp => {
            this.loadDataValues(this.submissionId);
        });
    };

    @action loadMeasurementProperties = () => {
        const url = `aireports/getmeasurmentproperties/`;
        Gateway.get(url).then(resp => {
            this.setMeasurementProperties(resp);
        });
    }
};
