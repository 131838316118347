// Vendors
import React, { FC } from "react";
import { Grid } from "@mui/material";

// Components
import { ProviderHeader } from "./components/ProviderHeader";
import { ProviderDetails } from "./components/ProviderDetails";
import { ProviderPayments } from "./components/ProviderPayments";
import { ProviderOrderHistory } from "./components/ProviderOrderHistory";
import { ProviderPreferences } from "./components/ProviderPreferences";
import { ProviderSubscriptions } from "./components/ProviderSubscriptions";
import { ProviderMembership } from "./components/ProviderMembership";

// Store
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// Entities

// API
import { observer } from "mobx-react";
import { Oval } from "react-loader-spinner";
import ProviderHeaderLayout from "./components/ProviderHeaderLayout";
import { ProviderLocations } from "./components/ProviderLocations";
import { ProviderTabs } from "./ProviderTabs";


interface IGIProps {
}



export const ProviderSettings: FC<IGIProps> = observer(() => {

    const store = useMainStoreContext().providerStore;
    const [isLoaded, setIsLoaded] = React.useState(false);
    const providerTabs = Object.values(ProviderTabs);
    const handleTabChange = (event, newValue) => {
        var newTabEnumValue = providerTabs[newValue];
        store.setActiveTabEnum(newTabEnumValue);
    };

    const renderTabContent = () => {
        switch (store.activeTabEnum) {
            case ProviderTabs.ACCOUNT_DETAILS:
                return <ProviderDetails />;
            case ProviderTabs.LOCATIONS:
                return <ProviderLocations />;
            // case ProviderTabs.MEMBERSHIP:
            //     return <ProviderMembership />;
            // case ProviderTabs.PAYMENTS:
            //     return <ProviderPayments />;
            case ProviderTabs.ORDER_HISTORY:
                return <ProviderOrderHistory />;
            // case ProviderTabs.SUBSCRIPTIONS:
            //     return <ProviderSubscriptions />;
            case ProviderTabs.SETTINGS:
                return <ProviderPreferences />;
            default:
                return null;
        }
    };

    const convertEnumToInteger = (enumValue: ProviderTabs): number => {
        const enumValues = Object.values(ProviderTabs);
        return enumValues.indexOf(enumValue);
    };

    React.useEffect(() => {
        store.getProviderDetails().then(() => {
            setIsLoaded(true);
        });
    }, []);

    if (!isLoaded) {
        return (
            <Grid container sx={{ marginTop: '50px', justifyContent: 'center' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        );
    } else {
        return <>
            <Grid container>
                <Grid item xs={12}>
                    <ProviderHeaderLayout
                        provider={store.providerData}
                        tabs={providerTabs}
                        activeTab={convertEnumToInteger(store.activeTabEnum)}
                        handleTabChange={handleTabChange}
                        isLoading={!isLoaded}
                    />
                </Grid>
                <Grid item xs={12}>
                    {renderTabContent()}
                </Grid>
            </Grid>
        </>
    }
});