import type { ReactNode } from 'react';

//icons 
import PeopleIconTwoTone from '@mui/icons-material/PeopleTwoTone';
import AssessmentIconTwoTone from '@mui/icons-material/AssessmentTwoTone';
import CorporateFareIconTwoTone from '@mui/icons-material/CorporateFareTwoTone';
import AssignmentIconTwoTone from '@mui/icons-material/AssignmentTwoTone';
import SchoolIconTwoTone from '@mui/icons-material/SchoolTwoTone';
import CoPresentIconTwoTone from '@mui/icons-material/CoPresentTwoTone';
import StoreIconTwoTone from '@mui/icons-material/StoreTwoTone';
import ConstructionIconTwoTone from '@mui/icons-material/ConstructionTwoTone';
import BiotechIconTwoTone from '@mui/icons-material/BiotechTwoTone';
import CalendarMonthIconTwoTone from '@mui/icons-material/CalendarMonthTwoTone';
import Diversity1IconTwoTone from '@mui/icons-material/Diversity1TwoTone'
import HomeRepairServiceIconTwoTone from '@mui/icons-material/HomeRepairServiceTwoTone'
import PrecisionManufacturingTwoToneIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';

// Being removed icons.
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Home',
        icon: DesignServicesTwoToneIcon,
        link: '/dashboard'
      },

    ]
  },
  {
    heading: 'Patient Management',
    items: [
      {
        name: 'Patients',
        icon: PeopleIconTwoTone,
        link: '/patients',
        // items: [
        //   {
        //     name: 'All Patients',
        //     link: '/patients'
        //   },
        //   {
        //     name: 'Pending Action',
        //     link: '/patients/pending-action',
        //     badge: '',
        //     badgeTooltip: '5 patients need action'
        //   },
        //   {
        //     name: 'Pending Treatment',
        //     link: '/patients/pending-treatment'
        //   },
        //   {
        //     name: 'Completed',
        //     link: '/patients/completed'
        //   },
        //   {
        //     name: 'Archive',
        //     link: '/patients/arcivhed',
        //   },
        // ]
      },
      {
        name: 'Appliances',
        icon: SmartToyTwoToneIcon,
        link: '/orders',
      },
      {
        name: 'Reports',
        icon: AssessmentIconTwoTone,
        link: '/reports',
      },
      // {
      //   name: 'Consultations',
      //   icon: CoPresentIconTwoTone,
      //   link: '/consultations',
      // },
    ]
  },
  {
    heading: 'Practice Management',

    items: [
      // {
      //   name: 'Catalog',
      //   icon: StoreIconTwoTone,
      //   link: '/catalog',
      // },
      {
        name: 'Online Store',
        icon: StoreIconTwoTone,
        link: '/onlinestore',
      },
      // {
      //   name: 'Advisory',
      //   icon: CoPresentIconTwoTone,
      //   link: '/advisory',
      // },
      // {
      //   name: 'My Forms',
      //   icon: AssignmentIconTwoTone,
      //   link: '/customForms',
      // },
    ]
  },
  {
    heading: 'Clinical Advisory',

    items: [
      {
        name: 'Clinical Advisor Page',
        icon: CorporateFareIconTwoTone,
        link: '/clinicaladvisor',
      },
      {
        name: 'My Network',
        icon: CorporateFareIconTwoTone,
        link: '/myNetwork',
      },
    ]
  },
  {
    heading: 'Admin Management',
    items: [
      {
        name: 'Admin',
        icon: TableChartTwoToneIcon,
        link: '/admin',
      },
      {
        name: 'Custom Forms',
        icon: AssignmentIconTwoTone,
        link: '/customForms',
      }
    ]
  },
  //3rd Party External Lab Production Management
  {
    heading: 'Production Management',
    items: [
      {
        name: 'Lab Management',
        icon: PrecisionManufacturingTwoToneIcon,
        link: '/labmanagement',

      },
      {
        name: 'Repair & Warranty Claims',
        icon: ConstructionIconTwoTone,
        link: '/reworkClaims'
      },
    ]
  },
  //Vivos Production Management
  {
    heading: 'Vivos Management',
    items: [
      {
        name: 'QC Management',
        icon: PrecisionManufacturingTwoToneIcon,
        link: '/qcmanagement',

      },
      /*
      {
        name: 'Lab Management TEMP',
        icon: PrecisionManufacturingTwoToneIcon,
        link: '/labmanagement',

      },
      */
      {
        name: 'Reworks',
        icon: ConstructionIconTwoTone,
        link: '/reworkClaims'
      },
    ]
  },
  {
    heading: 'TX Nav Management',
    items: [
      {
        name: 'Lincare Orders',
        icon: PrecisionManufacturingTwoToneIcon,
        link: '/treatment_navigator'
      },
    ]
  },
  {
    heading: 'Report Management',
    items: [
      {
        name: 'AI Reports',
        icon: AssessmentIconTwoTone,
        link: '/aiReports'
      },
    ]
  },
  {
    heading: 'MyoCorrect Management',
    items: [
      {
        name: 'Patients',
        link: '/myocorrect',
        icon: PeopleIconTwoTone
      },
      // {
      //   name: 'Scheduling',
      //   icon: CalendarMonthIconTwoTone,
      //   link: '/scheduling'
      // },
      // {
      //   name: 'Tools',
      //   icon: HomeRepairServiceIconTwoTone,
      //   link: '/myocorrect/tools'
      // }
    ]
  },
  {
    heading: 'Team Management',
    items: [
      {
        name: 'My Teams',
        link: '/admin/teams',
        icon: Diversity1IconTwoTone
      }
    ]
  },
  {
    heading: 'Help Center',
    items: [
      {
        name: 'Knowledge Base',
        link: '/knowledgebase',
        icon: SchoolIconTwoTone
      },
      {
        name: 'Contact Us',
        link: '/contactus',
        icon: PeopleIconTwoTone
      }
    ]
  }
];

export default menuItems;
