import { getTableSortLabelUtilityClass } from '@mui/material';
import * as _ from '../api/GlobalUtils';
// interface IDictionaryList{
//     name: string;
//     value: number;
//     listName: string;
// }
// interface DictionaryList extends IDictionaryList{}
// class DictionaryList {
//     constructor({name, value, listName}: IDictionaryList) {
//         this.name = name;
//         this.value= value;
//         this.listName = listName;
//     }
//     toObject(): IDictionaryList {
//         return {...this};
//     }
// } export default DictionaryList;
export enum PatientFilters {
    PendingTreatment,
    ActiveTreatment,
    ApplianceTreatment,
    MyoPatients,
    CompletedPatients,
    Archived
}

export enum MyoCorrectFilters {
    TodayAppts,
    AllActive,
    NewEvals,
    Unassigned,
    MissingNotes,
    Completed,
    Scheduled,
    Unscheduled,
    AllCases,
    MyCases,
    MyTeamCases,
    FrenectomyCases,
    InitalEvalNotScheduled,
    Within7Days
}

export enum ReworkClaimFilters {
    NewSubmission,
    InProgressWarranty,
    InProgressRepair,
    PendingApproval,
    Completed,
    Approved,
    AllClaims
}

export enum LabCasesFilters {
    Pending,
    Ready,
    Claims,
    AllRXs
}

export enum NumberingSystem {
    UNIVERSAL = 'UNIVERSAL',
    PALMER = 'PALMER',
    FDI = 'FDI',
}

export enum TeethSet {
    DECIDUOUS = 'DECIDUOUS',
    ADULT = 'ADULT',
    ALL = 'ALL',
}

export enum AIReportsFilters {
    PendingCases,
    CasesInProgress,
    Assigned,
    ToBeAssigned,
    Completed,
    NoFilter,
}

export enum MyoCorrectAppointmentType {
    '-',
    'Session 1',
    'Session 2',
    'Session 3',
    'Session 4',
    'Session 5',
    'Session 6',
    'Pre Frenectomy',
    'Post Frenectomy',
}

export enum MyoCorrectTherapistType {
    'Admin',
    'Advisor',
    'Advocate',
}

export enum MyoCorrectEvaluationType {
    'Initial Evaluation' = 21,
    'Mid Evaluation',
    'Final Evaluation',
}

export enum MyoCorrectSessionStatus {
    'Cancelled' = 32,
    'Completed',
    'No Show',
    'Pending',
    'Rescheduled',
    'Scheduled',
}

export enum MyoCorrectPlan {
    'Legacy' = 1,
    'MyoCorrect' = 2,
    'MyoCorrect+' = 3,
    'Additional Session' = 4,
}
export enum TreatmentStatus {
    'Pending Treatment' = 164,
    'In Treatment' = 5,
    'Completed' = 165,
    'Prospect' = 1255
}

export enum AIReportStatus {
    'Pending Review' = 134,
    'Pending Information' = 58,
    'Ready To Submit' = 59,
    'Submitted' = 60,
    'Assigned' = 61,
    'Processing' = 62,
    'Completed' = 133
}

export enum MyoCorrectStatus {
    'Not Enrolled' = 0,
    'Not Applicable' = 11,
    'Enrolled' = 7,
    'In Progress' = 9,
    'Complete' = 10,
    'Refunded' = 102,
    'Cancelled' = 101,
}

export enum FrenectomyStatus {
    'None Needed' = 0,
    'Lip Frenectomy' = 1,
    'Buccal Frenectomy' = 2,
    'Lingual Frenectomy' = 4,
    'Completed' = 8,
}


export enum PresentConditions {
    'Missing' = 838,
    'Implants' = 125,
    'Impacted' = 841,
    'Crown' = 840,
    'Bridge' = 839,
};

export enum Ethnicity {
    'Caucasian' = 24,
    'Hispanic',
    'Other',
    'Asian',
    'African American',
    'Pacific Islander',
}

export enum Gender {
    'Female' = 22,
    'Male' = 23,
    'Other' = 24,
}

export enum UploadType {
    Image,
    File,
}

export enum AddOnType {
    Radiology = 105,
    Sassouni,
    FrontalAnalysis,
    Printed3DAirway,
}

export enum UneruptedConditions {
    'None' = 109,
    'Poor Eruption Path' = 110,
    'Impacted' = 111,
    'Embedded' = 112,
}

export enum MissingReasons {
    'N/A' = 113,
    'Unerupted' = 114,
    'Congential' = 115,
    'Exfoliated',
    'Extraction',
    'Ortho - Limited Space',
    'Trauma',
    'Perio',
    'Caries',
};

export const SupernumeraryPositions = ['inline', 'Labial', 'Lingual'];

export const countryLabelValues = [
    { label: 'Canada', value: 'CA', codeISO: 'CAN' },
    { label: 'United States', value: 'US', codeISO: 'USA' },
    { label: 'Australia', value: 'AUS', codeISO: 'AUS' },
];

export const stateLabelValues = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'States of Micronesia', value: 'FM' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Guam', value: 'GU' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Palau', value: 'PW' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' },
];

export enum IntraoralCondition {
    INTRAORALCROWDING = 'INTRAORALCROWDING',
    SPACING = 'SPACING',
    CROSSBITE = 'CROSSBITE',
    EDGETOEDGE = 'EDGETOEDGE',
    OVERBITE = 'OVERBITE',
    OVERJET = 'OVERJET',
    ANTERIOROPENBITE = 'ANTERIOROPENBITE',
    POSTERIOROPENBITE = 'POSTERIOROPENBITE',
    CLEFTHARDPALATE = 'CLEFTHARDPALATE',
    EXOSTOSES = 'EXOSTOSES',
    ANOMALIESINTERARCHDIST = 'ANOMALIESINTERARCHDIST',
    MAXILARYASYMMETRY = 'MAXILARYASYMMETRY',
    LIPTIE = 'LIPTIE',
    TONGUETIE = 'TONGUETIE',
    REVERSESWALLOW = 'REVERSESWALLOW',
    ANTERIORTONGUETHRUST = 'ANTERIORTONGUETHRUST',
    INTRAORALOTHER = 'INTRAORALOTHER',
    CLASSI = 'CLASSI',
    CLASSII = 'CLASSII',
    CLASSIITENDENCY = 'CLASSIITENDENCY',
    CLASSIII = 'CLASSIII',
    CLASSIIITENDENCY = 'CLASSIIITENDENCY',
}

export enum ExtraoralCondition {
    HEADACHE = 'HEADACHE',
    ATYPFACEPAIN = 'ATYPFACEPAIN',
    MACROGENIA = 'MACROGENIA',
    TEMPOMANDJOINTDIS = 'TEMPOMANDJOINTDIS',
    OTHERSPECDIS = 'OTHERSPECDIS',
    LIMMANDIROM = 'LIMMANDIROM',
    DEVOPENCLOSEMAND = 'DEVOPENCLOSEMAND',
    MOUTHBREATHER = 'MOUTHBREATHER',
    EXTRAORALOTHER = 'EXTRAORALOTHER',
}

export enum SleepCondition {
    OBSSLEEPAP = 'OBSSLEEPAP',
    SLEEPRELBRU = 'SLEEPRELBRU',
    OTHERSLEEPDIS = 'OTHERSLEEPDIS',
}

export enum CbctConditions {
    MAXILIAHYPERPLASI = 'MAXILIAHYPERPLASI',
    MAXILIAHYPOPLASIA = 'MAXILIAHYPOPLASIA',
    MANDIBLEHYPERPLASIA = 'MANDIBLEHYPERPLASIA',
    MANDIBLEHYPOPLASIA = 'MANDIBLEHYPOPLASIA',
    TMJBEAKING = 'TMJBEAKING',
    EXCESSIVETUBEROSITY = 'EXCESSIVETUBEROSITY',
    ATROPHICBONE = 'ATROPHICBONE',
    CBCTOTHER = 'CBCTOTHER',
}

export enum ImageTypes {
    'CBCT Scan' = 72
}

export enum ClaimStatus {
    'New Submission' = 1258,
    'Waiting on Clase Materials' = 1262,
    'Pending Lab Investigation' = 1241,
    'Pending Lab ERF' = 1242,
    'Pending ERF Approval' = 1244,
    'Pending DOF + ESD' = 1260,
    'Waiting on Case to Ship' = 1263,
    'Pending ERF Review' = 1243,
    'Pending Approval' = 592,
    'Pending Remake RX' = 1261,
    'Pending CCR Form' = 1259,
    'Pending Manager Review' = 1246,
    'Completed: Warranty Remake' = 1576,
    'Approved' = 593,
    'Cancelled' = 594,
    'Completed' = 830
}

export enum ClaimType {
    'Pending' = 589,
    'Warranty' = 590,
    'Repair' = 591,
    'Warranty & Repair' = 817
}

export enum ProductionStatus {
    'Pending' = 595,
    'Hold' = 596,
    'In Fabrication' = 597,
    'Shipped' = 598,
    'Cancelled' = 599,
    'Ready' = 823,
    'Remake' = 1247
}

export enum ClaimCategory {
    'Manufacturing' = 613,
    'Material' = 614,
    'Provider/Patient' = 615,
    'Not Use' = 616,
    'Other' = 617
}
export enum LabManagementFilters {
    All,
    NewPrescriptions,
    AllClaims,
    Warranty,
    Repair,
    ProductionReady,
    InFabrication,
    Shipped,
    CasesOver7Days,
    BitesOnHold,
    ImpressionsOnHold
}
export enum ProdStatus {
    'Pending Approval' = 592,
    'Approved' = 593,
    'Denied' = 594
}

export enum ErfStatus {
    'Pending ERF' = 609,
    'Pending Approval' = 610,
    'Default Approval' = 611,
    'Provider Approval' = 612
}

export enum CcrStatus {
    'CCR Status 1' = 600,
    'CCR Status 2' = 601,
    'Completed' = 853,
    'In Progress' = 854,
    'Manager Review' = 855,
    'N/A' = 856
}

export enum ActionItemStatus {
    'Not Applicable' = 826,
    'Required' = 827,
    'Completed' = 828,
    'Dismissed' = 829
}

export enum Roles {
    AISADMIN = 'AISAdmin',
    EXTERNALLAB = 'ExternalLab',
    AISADMINMANAGER = 'AISAdminManager',
    VIVOSADMIN = 'VivosAdmin',
    AISSUPERVISOR = 'AISSupervisor',
    ESTIMATEDREPAIRREVIEW = 'EstimatedRepairReview',
    AIREPORTADMIN = "AIReportAdmin",
    AIREPORTTECH = "AIReportTech",
    AISSPECIALIST = "AISSpecialist"
}

export enum RxAppliances {
    GUIDE = 'Guide',
    GUIDEVG = 'Guide VG',
    GUIDEVGLincare = 'Guide VG - Lincare',
    GUIDEVGX = 'Guide VGx',
    GUIDEVGXLincare = 'Guide VGx - Lincare',
    GUIDEVW = 'Guide VW',
    GUIDEVWLincare = 'Guide VW - Lincare',

    VIDA = 'Vida',
    VIDASLEEP = 'VidaSleep',

    VERSA = 'Versa',
    VERSANYLONEMA = 'Versa Nylon Ema',
    VERSA9 = 'Versa 9',

    DNAmRNAmmRNA = 'DNA/mRNA/mmRNA',

    DNA = 'DNA',
    DNASAU = 'Vivos Single Arch DNA Upper',
    DNASAL = 'Vivos Single Arch DNA Lower',
    DNADUAL = 'Vivos Dual DNA System',

    MRNA = 'mRNA',
    MRNAV = 'Vivos mRNA System',
    MRNASAU = 'Vivos mRNA Single Arch Upper',
    MRNASAL = 'Vivos mRNA Single Arch Lower',

    MMRNA = 'mmRNA',
    MMRNAV = 'Vivos mmRNA System',
    MMRNASAU = 'Vivos mmRNA Single Arch Upper',
    MMRNASAL = 'Vivos mmRNA Single Arch Lower',

    PEX = 'PEx',
    PEXU = 'PEx-U',
    PEXL = 'PEx-L',
    PEXD = 'PEx-D',

    EMANOW = 'EMA-Now',
    EMANOWTemp = 'EMA-NOW Temporary Sleep Appliance',


    NONE = 'None'
}

export enum ConstructionTypes {
    'Versa Oral Appliance' = 539,
    'Vivos Single Arch DNA Upper' = 540,
    'Vivos Single Arch DNA Lower' = 541,
    'Vivos Dual DNA System' = 542,
    'Vivos mRNA System' = 543,
    'Vivos mRNA Single Arch Upper' = 544,
    'Vivos mRNA Single Arch Lower' = 545,
    'Vivos mmRNA System' = 546,
    'Vivos mmRNA Single Arch Upper' = 547,
    'Vivos mmRNA Single Arch Lower' = 548,
    'Vivos System Guide' = 549,
    'Other' = 550,
    'Versa-9' = 689,
    'Versa-EMA' = 690,
    // 'DNA-U' = 691,
    // 'DNA-L' = 692,
    // 'DNA-D' = 693,
    // 'mRNA-U' = 694,
    // 'mRNA-L' = 695,
    // 'mRNA' = 696,
    // 'mmRNA-U' = 697,
    // 'mmRNA-L' = 698,
    // 'mmRNA' = 699,
    'PEx-U' = 701,
    'PEx-L' = 702,
    'PEx-D' = 703,
    'GuideVG' = 886,
    'GuideVGx' = 887,
    'GuideVW' = 888,
    'Vida' = 1234,
    'VidaSleep' = 1235,
    'emaNow' = 1403,
    'Starter' = 1545,
    'Starter For Kids' = 1575,
    'Rescue Guide' = 1544
   
}

export enum ApplianceSizes {
    '25mm' = 868,
    '30mm' = 869,
    '70mm' = 877
}

export enum ApplianceColors {
    'Blue' = 878,
    'Green' = 879,
    'Red' = 880
}

export enum Labs {
    VIVOS = 'Vivos',
    APEX = 'Apex Dental Sleep Lab',
    AURUM = 'Aurum Dental Laboratories',
    FIVESTAR = 'Five Star Orthodontics',
    GERGENS = "Gergen's Orthodontic Lab",
    JDL = "John's Dental Lab",
    SML = 'Space Maintainers Lab',
    STAA = 'STAA Lab',
    VERSA = 'Versa Lab',
    AIC = 'Airway Intelligence Center',
    PEX = 'PEx Lab',
}

export enum VersaTypes {
    VERSA9 = 'Versa 9',
    VERSAEMA = 'Versa Nylon EMA'
}

export enum Allergies {
    'Acrylic' = 1,
    'Metals' = 2,
    'Plastic' = 4,
    'Latex' = 8,
    'Food' = 16,
    'Pain Medications' = 32,
    'Other' = 64
}

export enum OrderStatus {
    'Placed' = 831,
    'Under Review' = 832,
    'Manufacturing' = 833,
    'Shipped' = 834,
    'Delivered' = 835,
    'Canceled' = 836,
    'On Hold' = 837,
    'Paid' = 885
}

export enum OcclusionModelType {
    'Physical' = 906,
    'Digital' = 907
}

export enum AIReportType {
    'Standard' = 63,
    'Comparison' = 64,
    'Free Standard' = 1322
}

export enum AIReportAddOn {
    '3D Printed Airway' = 108
}

export enum AddressType {
    'Shipping Address' = 921,
    'Practice Address' = 920
}

export enum ConstructionBiteInformation {
    'Digital construction bite is embedded in my upper and lower arch STL files.' = 1404,
    'Digital construction bite is included as a separate STL file.' = 1405,
    'Physical construction bite was taken and will be mailed in when applicable.' = 1406,
    'No construction bite included with these models.' = 1407
}

export enum ConstructionBiteType {
    'Digital' = 1408,
    'Physical' = 1409,
    'No Construction Bite' = 1410
}

export enum PotrusionPosition {
    'Left' = 1413,
    'Center' = 1414,
    'Right' = 1415
}

export enum LabClaimMaterialApplianceStatus {
    'Received' = 618,
    'Pending' = 619,
    'Needed' = 1484,
    'Waiting on Opposing Appliance' = 1485,
    'Cancelled' = 1486
}

export enum LabClaimMaterialBiteStatus {
    'Hold' = 1489,
    'Cancelled' = 1490,
    'N/A' = 620,
    'Received' = 621,
    'Pending' = 622
}

export enum LabClaimMaterialModelStatus {
    'Hold' = 1487,
    'Cancelled' = 1488,
    'N/A' = 623,
    'Received' = 624,
    'Pending' = 625
}

export enum LabModelStatus {
    'Hold' = 713,
    'Cancelled' = 715,
    'Received' = 714,
    'Pending' = 712
}

export enum LabBiteStatus {
    'Hold' = 709,
    'Cancelled' = 711,
    'Received' = 710,
    'Pending' = 708
}

export enum RepairOptions {
    'Clasping' = 1491,
    '3D Axial Springs' = 1492,
    'Acrylic' = 1493,
    'Labial Bow' = 1494,
    'OSA' = 1495,
    'Expansion Screw' = 1496,
    'Refit to updated models' = 1497,
    'Bite Reset' = 1498,
    'Face Mask Hooks' = 1499,
    'mRNA Fin Adjustment' = 1500,
    'Dorsal Fin Replacement' = 1501,
    'mRNA RPE Fin Replacement' = 1502,
    'mmRNA Arm Adjustment' = 1503
}

export enum LabReworkMaterialModelStatus {
    'Pending' = 1504,
    'Hold' = 1505,
    'Received' = 1506,
    'Cancelled' = 1507
}

export enum LabReworkMaterialBiteStatus {
    'Pending' = 1508,
    'Hold' = 1509,
    'Received' = 1510,
    'Cancelled' = 1511
}

export enum ClaimClassificationSubcategories {
    'Rx was not followed' = 1524,
    'Nonfunctional Assembly' = 1525,
    'Chemical Degradation' = 1526,
    'Corrosion' = 1527,
    'Discoloration' = 1528,
    'Inadequate Hardness' = 1529,
    'Surface Irregularities' = 1530,
    'Porosity' = 1531,
    'Ordering system' = 1532,
    'Shipment' = 1533
}

export enum FabricationTime {
    'Standard' = 1558,
    'Rush' = 1559
}

export enum IntakeReports {
    'Sleep Test' = 191
}

export enum SleepStudyProperties {
    'Sleep Study Date' = 1,
    'Oxygen Sat Ave',
    'Sleep Min',
    'AHI 3%',
    'RDI 3%',
    'ODI 3%',
    'Average BPM',
    'AHI 4%',
    'ODI 4%',
    'RDI 4%',
    'Sleep Study Source',
    'Average Oxygen Saturation',
    'REM',
    'O2 Nadir',
    'Average Snore DB',
    'Sleep Latency',
    'Patient Records Date',
    'Treatment Stage',
    'Read by Qualified Sleep Physician',
    'No Diagnosis',
    'Obstructive Sleep Apnea',
    'Upper Airway Resistence Syndrome',
    'Central Sleep Apnea',
    'Other',
    'Sleep Test Type',
    'This sleep test was done using an oral appliance'
}

export enum AIReportProcessedImagesTypes {
    'Billing Radiographic Image' = 1577
}