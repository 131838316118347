import { action, computed, makeObservable, observable } from "mobx";
import { MainStore } from "./MainStore";
import { Gateway } from "../../api/Gateway";
import jwt_decode from 'jwt-decode';
import { CustomerAccessToken, CustomerAccessTokenCreateWithMultipass } from "../../models/shopifyResponse/customerAccessTokenCreateWithMultipass";
import { Orders } from "../../models/shopifyResponse/orders";
import { BaseMainStore } from "../base/MainStore";
import { createStorefrontApiClient } from '@shopify/storefront-api-client';
import { getProductsQueryObject, ProductsQueryResponse } from "../../models/shopifyRequests/fetchProducts";
import { CART_ADD_LINES_OPERATION, CART_CREATE_OPERATION, CART_REMOVE_LINES_OPERATION } from "../../models/shopifyRequests/cart";

export default class ShopifyStore {
	mainStore?: BaseMainStore;
	private token;

	userID: number = 0;
	ProviderID: number = 0;
	userName: string = "";
	constructor(mainstore?: BaseMainStore) {
		makeObservable(this);
		this.mainStore = mainstore;
		const storefrontAccessToken = process.env.REACT_APP_SHOPIFY_API_TOKEN ?? "";
		const domain = process.env.REACT_APP_SHOPIFY_STORE_DOMAIN ?? "";
		const client = createStorefrontApiClient({
			storeDomain: domain,
			publicAccessToken: storefrontAccessToken,
			apiVersion: '2024-04',
		});
		this.client = client;
		this.token = sessionStorage.getItem('token');
		if (this.token) {
			var tokenData: { roles; id; userFullName; providerId; userName } = jwt_decode(this.token);
			this.userID = tokenData.id;
			this.ProviderID = tokenData.providerId;
			this.userName = tokenData.userName;
		}
	}
	@observable isCartModalOpen = false;
	@action setCartModalOpen = (open: boolean) => {
		this.isCartModalOpen = open;
	};

	@observable client: any;

	@observable urlMultipass: string = '';

	@observable cart: any = {
		lines: [],
		subtotalPrice: 0,
		checkoutUrl: "",
		id: "",
		order: {},
		ready: false,
		cost: {
			totalAmount: {
				amount: 0,
				currencyCode: ''
			}
		}
	};


	@action fetchProducts = async () => {
		const queryObject = getProductsQueryObject({
			first: 250,
			// optionally: query: 'tag:Clothing',
			// optionally: sortKey: 'CREATED_AT',
			// optionally: reverse: false,
		});

		const { data, errors, extensions } = await this.client.request(queryObject);

		// Flatten the variants structure
		const products = data.products.edges.map(edge => {
			const product = edge.node;
			product.variants = product.variants.edges.map(variantEdge => variantEdge.node);
			return product;
		});

		return products;
	}


	@computed get cartCount() {
		if (!this.cart.lineItems) return 0;
		return this.cart.lineItems.reduce((total: number, item: any) => {
			return total + item.quantity;
		}, 0);
	}

	@action openCheckoutUrl = async () => {
		let url = await this.getCheckoutUrl();
		let newWindow = window.open(this.urlMultipass, '_blank');
	}

	@action getCheckoutUrl = async () => {
		if (this.cart) {
			let token = await this.getMultipassToken(this.cart.checkoutUrl);
			var shopifyToken = 'https://' + process.env.REACT_APP_SHOPIFY_STORE_DOMAIN + '/account/login/multipass/' + token;
			this.urlMultipass = shopifyToken;
			return this.urlMultipass;
		}

		return "";
	}

	@action getCustomCheckoutUrl = async (checkoutUrl: string) => {
		if (checkoutUrl) {
			let token = await this.getMultipassToken(checkoutUrl);
			var shopifyToken = 'https://' + process.env.REACT_APP_SHOPIFY_STORE_DOMAIN + '/account/login/multipass/' + token;
			this.urlMultipass = shopifyToken;
			return this.urlMultipass;
		}
		return "";
	}

	@action getMultipassToken = async (checkoutUrl: string) => {
		var url = '/provider/shopify/' + this.userID + '/token';
		return await Gateway.postStrongType<string>(url, { checkoutUrl });
	}

	@action getCustomerAccessToken = async () => {

		const storedTokenString = sessionStorage.getItem('customerAccessToken');
		if (storedTokenString) {
			var storedToken = JSON.parse(storedTokenString);
			const currentTime = new Date();

			if (storedToken && storedToken.expiresAt && new Date(storedToken.expiresAt) > currentTime) {
				// Access token is still valid
				return storedToken.accessToken;
			}
		}

		const query = `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) { customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) { customerAccessToken { accessToken expiresAt } customerUserErrors { field message } } }`;
		let multipassToken = await this.getMultipassToken('');
		const variables = { multipassToken };

		try {
			const response = await Gateway.shopifyPost<CustomerAccessTokenCreateWithMultipass>(query, variables);

			if (response && response.customerAccessToken) {
				const { accessToken, expiresAt } = response.customerAccessToken;

				// Store the access token and expiresAt in session storage
				sessionStorage.setItem('customerAccessToken', JSON.stringify(response.customerAccessToken));

				return response.customerAccessToken.accessToken;
			}
		} catch (error) {
			console.error('Error fetching access token:', error);
		}

	}

	@action getCustomerOrderHistory = async () => {
		const query = `query($customerAccessToken: String!) {
			customer(customerAccessToken: $customerAccessToken) {
			  orders(first: 250) {
				edges {
				  node {
					id
					name
					processedAt
					financialStatus
					fulfillmentStatus
					totalPrice {
					  amount
					  currencyCode
					}
					totalShippingPrice {
						amount
					}
					totalTax {
						amount
					}
					currentTotalTax {
						amount
					}
					lineItems(first: 250) {
					  edges {
						node {
						  title
						  quantity
						  originalTotalPrice {
						  	amount
						  }
						}
					  }
					}
				  }
				}
			  }
			}
}
		`;
		let customerAccessToken = await this.getCustomerAccessToken();
		const variables = { customerAccessToken };

		try {
			const response = await Gateway.shopifyPost<{ orders: Orders }>(query, variables);
			return response.orders;
		} catch (error) {
			console.error('Error fetching order history:', error);
			return undefined;
		}
	};

	@observable isCartOpen = false;
	@action setCartOpen = async (open: boolean) => {
		if (open) {
			const { data, errors } = await this.client.request(CART_CREATE_OPERATION, {
				variables: {
					"cartInput": {}
				}
			});
			this.cart = data.cartCreate.cart;
		}

		this.isCartOpen = open;
	};

	@action decrementQuantity = async (lineItem: any) => {
		const updatedQuantity = lineItem.quantity - 1;
		await this.updateQuantityInCart(lineItem.id, updatedQuantity);
	};
	@action incrementQuantity = async (lineItem: any) => {
		const updatedQuantity = lineItem.quantity + 1;
		await this.updateQuantityInCart(lineItem.id, updatedQuantity);
	};

	@action updateQuantityInCart = async (lineItemId: string, quantity: number) => {
		if (this.client && this.cart && this.cart.id) {
			const lineItemsToUpdate = [{ id: lineItemId, quantity }];
			this.cart = await this.client.checkout.updateLineItems(this.cart.id, lineItemsToUpdate);
		}
	};

	@action removeLineItemFromCart = async (lineItemId: any) => {
		if (this.client && this.cart && this.cart.id) {
			this.cart = await this.client.checkout.removeLineItems(this.cart.id, [lineItemId]);
		}
	};


	@action addVariantToCart = async (variantId: string, quantity: number, priceToOverride?: number) => {

		if (this.client && this.cart && this.cart.id) {
			const variables = {
				cartId: this.cart.id,
				lines: [
					{
						quantity: quantity,
						merchandiseId: variantId,
						...(priceToOverride && { attributes: [{ key: "Override Price", value: priceToOverride.toString() }] })
					}
				]
			}

			const { data, errors } = await this.client.request(CART_ADD_LINES_OPERATION, {
				variables
			});
			console.log(errors);
			console.log(data);
			this.cart = data.cartLinesAdd.cart;
		}
	};

	@action removeItemFromCart = async (variantId: string) => {
		if (this.client && this.cart && this.cart.id) {
			// Find the line item ID based on the variant ID
			const lineItem = this.cart.lines.edges.find(
				(edge) => edge.node.merchandise.id === variantId
			);

			if (!lineItem) {
				console.warn(`Variant ID ${variantId} not found in cart`);
				return;
			}

			const lineItemId = lineItem.node.id;

			const variables = {
				cartId: this.cart.id,
				lineIds: [lineItemId],
			};

			try {
				const { data, errors } = await this.client.request(CART_REMOVE_LINES_OPERATION, {
					variables,
				});

				if (errors) {
					console.error("Error removing item from cart:", errors);
					return;
				}

				console.log("Updated Cart Data:", data);

				// Update cart state with the new cart object
				this.cart = data.cartLinesRemove.cart;
			} catch (error) {
				console.error("GraphQL request failed:", error);
			}
		}
	};

	@action addMultipleVariantsToCart = async (variants: { variantId: string; quantity: number; priceToOverride?: number }[]) => {
		if (this.client && this.cart && this.cart.id) {
		  const lines = variants.map(({ variantId, quantity, priceToOverride }) => ({
			quantity,
			merchandiseId: variantId,
			...(priceToOverride && { attributes: [{ key: "Override Price", value: priceToOverride.toString() }] }),
		  }));
	  
		  const variables = {
			cartId: this.cart.id,
			lines,
		  };
	  
		  try {
			const { data, errors } = await this.client.request(CART_ADD_LINES_OPERATION, { variables });
	  
			if (errors) {
			  console.error("Error adding multiple variants to cart:", errors);
			  return;
			}
	  
			console.log("Updated Cart Data:", data);
			this.cart = data.cartLinesAdd.cart;
		  } catch (error) {
			console.error("GraphQL request failed:", error);
		  }
		}
	  };
	  
	  @action emptyCart = async () => {
		if (this.client && this.cart && this.cart.id) {
		  const lineIds = this.cart.lines.edges.map(edge => edge.node.id);
	  
		  if (lineIds.length === 0) {
			console.warn("Cart is already empty.");
			return;
		  }
	  
		  const variables = {
			cartId: this.cart.id,
			lineIds,
		  };
	  
		  try {
			const { data, errors } = await this.client.request(CART_REMOVE_LINES_OPERATION, { variables });
	  
			if (errors) {
			  console.error("Error emptying cart:", errors);
			  return;
			}
	  
			console.log("Cart emptied successfully:", data);
			this.cart = data.cartLinesRemove.cart;
		  } catch (error) {
			console.error("GraphQL request failed:", error);
		  }
		}
	  };


	@observable selectedVariant: Variant | null = null;
	@action setSelectedVariant = (variant: Variant | null) => {
		this.selectedVariant = variant;
	}

	@computed get selectedVariantImage() {
		if (!this.selectedVariant) return null;
		return this.selectedVariant.image.originalSrc;
	}
	@computed get selectedVariantQuantity() {
		if (!this.selectedVariant) return null;
		return this.selectedVariant.quantity;
	}

	@observable products: any[] = []
	@action setProducts = (products: any[]) => {
		this.products = products;
	}
}

export interface Variant {
	id: string;
	title: string;
	price: string;
	availableForSale: boolean;
	quantity: number;
	image: {
		originalSrc: string;
	};
};

export interface Cart {
	lineItems: any[];
	subtotalPrice: any;
	checkoutUrl: string;
	id: string;
	order: {};
	ready: boolean;
}

