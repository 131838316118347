import { observable } from 'mobx';
import PatientVisit from './patientVisit';

class Patient {
  @observable id: number = 0;
  @observable personId: number = 0;
  @observable organizationId: number = 0;
  @observable vivosId: string = '';
  @observable providerId: number | null = 0;
  @observable rxId: string = '';
  @observable profileImageUrl: string = '';
  @observable isProspect: boolean | null = false;
  @observable isLincare: boolean = false;
  @observable lincareStatusId: number | null = 0;
  @observable lincareApprovalCode: string = '';
  @observable treatmentStatusId: number | null = 0;
  @observable myoCorrectStatusId: number | null = 0;
  @observable occlusionTypeId: number | null = 0;

  //@observable statusModifiedDate: Date | null = null;
  //@observable submittedDate: Date | null = null;
  //@observable originalSubmittedDate: Date | null = null;
  //@observable setPatientTechinicianDate: Date | null = null;
  //@observable setPatientDiagnosticianDate: Date | null = null;
  //@observable assignedToDiagnostician: number | null = null;
  //@observable assignedTo: number | null = null;
  @observable createdOn: Date = new Date();
  @observable createdBy: number = 0;
  @observable modifiedOn: Date | null = null;
  @observable modifiedBy: number | null = null;
  @observable isLincareActive: boolean = false;
  @observable isActive: boolean | null = true;
  @observable companyId: number | null = null;

  @observable patientVisits: PatientVisit[] = [];
}

export default Patient;
