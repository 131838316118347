// Vendors
import react, { FC, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Switch,
    Card,
    Box,
    Typography,
    Divider,
    CardContent,
    TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// Components
import Text from "../../../components/UI/Text";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import { AddressType, countryLabelValues, stateLabelValues } from "../../../entities/Enums";

// API
import { observer } from "mobx-react";
import _ from "lodash";
import { GlobalUtils } from "../../../api/GlobalUtils";

interface IGIProps {
    onClose(): any;
}


export const AddProviderLocation: FC<IGIProps> = observer((props) => {

    const store = useMainStoreContext().providerStore;
    const [toggleValidateModal, setToggleValidateModal] = react.useState(false);
    const [validationList, setValidationList] = react.useState<any>([]);
    const [invalidEmail, setInvalidEmail] = react.useState(false);
    let practiceAddress: any = {};
    practiceAddress.company = {};

    const stateMap = (): any => {
        var states = _.map(stateLabelValues, (s, i) => {
            return (
                <MenuItem key={i} value={s.value}>
                    {s.label}
                </MenuItem>
            );
        });
        return states;
    };

    const countryMap = (): any => {
        var countries = _.map(countryLabelValues, (s, i) => {
            return (
                <MenuItem key={i} value={s.value}>
                    {s.label}
                </MenuItem>
            );
        });
        return countries;
    };

    const handleSaveButton = () => {
        const validation = validate();
        if (validation.length > 0) {
            setToggleValidateModal(true);
        }
        else {
            practiceAddress.AddressTypeId = AddressType["Practice Address"];
            store.providerData.addresses.push(practiceAddress);
            store.saveProvider().then(async () => {
                await props.onClose();
            })
        }
    }

    const validate = (): string[] => {
        let validations: string[] = [];
        if (practiceAddress.company?.companyName === "") {
            validations.push("Company Name is required");
        }
        if (practiceAddress?.address1 === "") {
            validations.push("Address1 is required");
        }
        if (practiceAddress?.city === "") {
            validations.push("City is required");
        }
        if (practiceAddress?.state === "") {
            validations.push("State is required");
        }
        if (practiceAddress?.postalCode === "") {
            validations.push("Postal Code is required");
        }
        if (practiceAddress?.country === "") {
            validations.push("Country is required");
        }
        else if (!GlobalUtils.validateEmail(practiceAddress?.emailAddress ? practiceAddress?.emailAddress : "")) {
            validations.push("Invalid Email Format");
        }
        setValidationList(validations);
        return validations;
    }

    return (
        <Grid container className="patientDetailsTab">
            <Grid item xs={12} container display={'flex'} justifyContent={'flex-end'} paddingRight={2}>
                <Card>
                    <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        Input information as needed for the practice you are adding to your account.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid container direction={'row'} justifyContent={'flex-end'}>
                                <Button variant={'outlined'} onClick={() => { handleSaveButton() }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <CardContent>
                        <Typography variant="subtitle2">
                            <Grid container paddingY={1}>
                                <Grid item container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} justifyContent={"flex-end"} display={'flex'} alignItems={'center'}>
                                            Practice Name
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.company.companyName = event.target.value;
                                                }}
                                                value={practiceAddress?.company?.companyName}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} justifyContent={"flex-end"} display={'flex'} alignItems={'center'}>
                                            Practice Email
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.emailAddress = event.target.value;
                                                }}
                                                value={practiceAddress?.emailAddress}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} justifyContent={"flex-end"} display={'flex'} alignItems={'center'}>
                                            Website
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.website = event.target.value;
                                                }}
                                                value={practiceAddress?.website}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={1}>
                                <Grid item xs={12} container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">Practice Location Address</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                            Address 1
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.address1 = event.target.value;
                                                }}
                                                value={practiceAddress?.address1}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                            Address 2
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8} >
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.address2 = event.target.value;
                                                }}
                                                value={practiceAddress?.address2}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                            City
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.city = event.target.value;
                                                }}
                                                value={practiceAddress?.city}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                            State
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                select
                                                required={true}
                                                onChange={(event: any) => {
                                                    practiceAddress.state = event.target.value;
                                                }}
                                                value={
                                                    _.find(stateLabelValues, st => {
                                                        return st.value == practiceAddress?.state;
                                                    })?.value

                                                }
                                            >{stateMap()}</TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                            Postal Code
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                required={true}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    practiceAddress.postalCode = event.target.value;
                                                }}
                                                value={practiceAddress?.postalCode}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                            Country
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                select
                                                required={true}
                                                onChange={(event: any) => {
                                                    practiceAddress.country = event.target.value;
                                                }}
                                                value={
                                                    _.find(countryLabelValues, st => {
                                                        return st.value == practiceAddress?.country;
                                                    })?.value
                                                }
                                            >{countryMap()}</TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Typography>

                    </CardContent>
                </Card>
            </Grid>
            <Grid>
            </Grid>
            <Dialog
                open={toggleValidateModal}
                onClose={() => { }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid display="flex" alignItems="center">
                        <Grid flexGrow={1} >Invalid Provider Data</Grid>
                        <Grid>
                            {<IconButton className="closeButton" onClick={() => { setToggleValidateModal(false) }}>
                                <CloseIcon />
                            </IconButton>}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid>
                        <Grid>Please correct the following:</Grid>
                        <Grid>{_.map(validationList, (vl) => {
                            return <Grid>{vl}</Grid>
                        })}</Grid>
                    </Grid>
                    <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setToggleValidateModal(false) }}>OK</Button></Grid>
                </DialogContent>
            </Dialog>
        </Grid >
    );

});