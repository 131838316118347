// Vendors
import { Box, Button, CircularProgress, Grid, MenuItem, TextField, Typography, makeStyles, useTheme } from '@mui/material';


// Component
import RxBlock1 from '../RxFrameworkComponents/RxBlock1';
import RxBlock2 from '../RxFrameworkComponents/RxBlock2';
import RxApplianceOptions from './RxApplianceOptions';
import RxPatientInfo from './RxPatientInfo';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { Allergies, Ethnicity, Gender, RxAppliances } from '../../../entities/Enums';

// API
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

const RxStepOne = () => {

    const {
        currentPatient,
        patientId,
        setStep,
        updatePerson,
        setIsSavingPatient,
        isLoadingAppliances,
        isLoading,
        selectedAppliance
    } = usePrescriptionStore().prescriptionStore;

    const handleChangeAppliance = (appliance: RxAppliances) => {
        //setSelectedAppliance(appliance);
    }

    const handleSaveChanges = async () => {
        setIsSavingPatient(true);
        await updatePerson(currentPatient, patientId);
        // await upsertPatientHistory(patientId);
        setIsSavingPatient(false);
        setStep(true);
    };

    useEffect(() => {

    }, [isLoadingAppliances, isLoading, selectedAppliance])

    return (
        <Grid container spacing={3} padding={2}>
            {!isLoadingAppliances && <><Grid item xs={12} sm={9}>
                <RxBlock1
                    RxBlock1Content={<RxPatientInfo />}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <RxBlock2
                    RxBlock2Content={<RxApplianceOptions />}
                />
        </Grid></>}
            {isLoadingAppliances && <Grid container direction="row" justifyContent="center">
                    <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                    <CircularProgress sx={{
                        width: "90%",
                        height: "90%"
                    }} />
                </Grid>}
        </Grid>
    );
};
export default observer(RxStepOne);