// Vendors
import React, { FC, useState, useEffect } from 'react';
import { Grid, MenuItem, TextField, Typography, Box, SelectChangeEvent } from '@mui/material';

// Components
import Text from '../../components/UI/Text';
import Label from '../../components/UI/Label';

// Entities
import ProviderPatient from '../../entities/ProviderPatient';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import moment from "moment";

// Styling
import '../../scss/_PatientEdit.scss';
import '../../scss/_global.scss';


interface IGIProps {
    patient: ProviderPatient;
}

const FrenectomyStatusConst = [
    {
        id: '0',
        name: "None Needed"
    },
    {
        id: '1',
        name: "Lip Frenectomy"
    },
    {
        id: '4',
        name: "Lingual Frenectomy"
    },
    {
        id: '2',
        name: "Buccal Frenectomy"
    },
    {
        id: '8',
        name: "Completed"
    }
];

export const MyoHeaderDetails: FC<IGIProps> = (props: IGIProps) => {

    const {
        myoCorrectStatus,
        selectedFrenectomy,
        clearSessionData,
        handleMyocorrectStatusChange,
        setMyoCorrectStatus,
        setSelectedFrenectomy,
        updateFrenectomyStatus } = useMainStoreContext().sessionsStore;

    useEffect(() => {
        if (props.patient.frenectomyStatuses) {
            setSelectedFrenectomy(JSON.parse(props.patient.frenectomyStatuses).map(th => th.Status));
        }
    }, [props.patient.frenectomyStatuses])

    const changeStatus = (patientData, event) => {
        handleMyocorrectStatusChange(patientData, event);
    }

    const handleChange = (event: SelectChangeEvent<any>) => {
        var arrayvalue = event.target.value as string[];
        if (arrayvalue.indexOf("Completed") > -1) {
            setSelectedFrenectomy(["Completed"]);
        }
        else if (arrayvalue.indexOf("None Needed") > -1) {
            setSelectedFrenectomy(["None Needed"]);
        }
        else {
            setSelectedFrenectomy(event.target.value as string[]);
        }
    };

    const handleClickSave = () => {
        updateFrenectomyStatus(props.patient.id);
    }

    return (
        <Grid container height={'100%'}>
            <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'}>
                <Grid container>
                    <Grid item xs={12} paddingBottom={1}>
                        <Typography variant='h2' color={'white'} textAlign={'center'}>
                            MyoSync Status
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingBottom={1} container direction={'column'}>
                        <TextField
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '40px' } }}
                            // labelId="myocorrectstatusDropDownLabel"
                            id="myocorrectstatusDropDown"
                            value={(!_.isUndefined(myoCorrectStatus) ? myoCorrectStatus : 0)}
                            // label="MyoCorrectStatus"
                            defaultValue={0}
                            select
                            onChange={(event) => { changeStatus(props.patient, event.target.value); }}
                            sx={{ border: "0px", height: '20px' }}
                        >
                            <MenuItem key={0} value={0}>{"Select a Status..."}</MenuItem>
                            <MenuItem disabled key={7} value={7}><Label color={'warning'}>{"Enrolled"}</Label></MenuItem>
                            <MenuItem disabled key={9} value={9}><Label color={'primary'}>{"In-Progress"}</Label></MenuItem>
                            <MenuItem key={101} value={101}><Label color={'error'}>{"Cancelled"}</Label></MenuItem>
                            <MenuItem key={102} value={102}><Label color={'error'}>{"Refunded"}</Label></MenuItem>
                            <MenuItem key={10} value={10}><Label color={'success'}>{"Completed"}</Label></MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={6} paddingRight={1}>
                        <Typography variant="h5" color={'white'}>
                            Frenectomy Status:
                        </Typography>
                        {selectedFrenectomy.map(row => (
                            <Label color={'info'} key={row}>
                                {row}
                            </Label>
                        ))}
                    </Grid>
                    <Grid item xs={6} paddingLeft={1}>
                        <Typography variant="h5" color={'white'}>
                            Treatment Status:
                        </Typography>
                        <Label color={'info'}>{props.patient.treatmentStatusId}</Label>
                    </Grid>
                    {/* <Grid item xs={8} pl={2}>
                        <Typography variant="h5" color={'white'} textAlign={"left"}>
                            Add-Ons Requested:
                        </Typography>

                        <Box height={'50px'} sx={{ background: 'white' }} border={2} borderRadius={1} borderColor={'white'}>
                            <Grid container direction="row" >
                                {
                                    _.map(submission.addOns, ((item) => {
                                        if (item.addOnType === 105) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid> <RadiologySvg width={50} height={50} className={'svgGrid status-' + item.statusId}></RadiologySvg></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 106) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid><img style={{ width: "50px", height: "50px" }} src={SassouniImg} className={'svgGrid status-' + item.statusId} /></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 108) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid> <Print3DSvg width={50} height={50} className={'svgGrid status-' + item.statusId}></Print3DSvg></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 107) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid><FrontalAnalysis width={50} height={50} className={'svgGrid status-' + item.statusId}></FrontalAnalysis></Grid>
                                                </Grid>
                                            );
                                        }
                                    }))
                                }
                            </Grid>
                        </Box>

                    </Grid> */}
                </Grid>
            </Box>
        </Grid>
    )
};
