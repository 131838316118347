import React, { ChangeEvent, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    alpha,
    Avatar,
    Button,
    Tabs,
    Tab,
    Grid,
    useTheme,
    Divider,
    Link
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { airRecommendedRecords } from './Helper/documents';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);
const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);
const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
          overflow: visible !important;
  
          .MuiTabs-scroller {
              overflow: visible !important;
          }
  
          .MuiButtonBase-root {
              text-transform: uppercase;
              font-size: ${theme.typography.pxToRem(12)};
  
              &:last-child {
                  margin-right: 0;
              }
          }
      `
);
const ButtonWrapper = styled(Button)(
    ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
    `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
          background-color: ${theme.colors.success.main};
          color: ${theme.palette.getContrastText(theme.colors.success.dark)};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          box-shadow: ${theme.colors.shadows.success};
          top: -${theme.spacing(4)};
          position: absolute;
          left: ${theme.spacing(3)};
    `
);

function AIReportContent() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const tabs = [
        { value: 'overview', label: 'Overview' },
        { value: 'basics', label: 'Basics' },
        { value: 'turnaroundTime', label: 'Turnaround Time' },
        // { value: 'pricing', label: 'pricing' },
        { value: 'comparativeAir', label: 'Comparison AIR' },
        { value: 'addOn', label: 'Add-Ons' },
        { value: 'radReports', label: 'Radiology Reports' }
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    return (
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={12} xl={5}>
                    <BoxComposed
                        display="flex"
                        alignItems="center"
                        sx={{
                            minHeight: '100%',
                            background: `${theme.colors.gradients.blue2}`
                        }}
                    >
                        <BoxComposedContent px={8} pt={8} pb={4}>
                            <Avatar
                                variant="square"
                                sx={{
                                    mx: 'auto',
                                    mb: 2,
                                    width: 'auto',
                                    height: 200,
                                    '& img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                src="/logos/AIReport.png"
                            />
                        </BoxComposedContent>
                    </BoxComposed>
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Box py={3} px={4}>
                        <TabsWrapper
                            centered
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                    </Box>

                    <Divider />
                    {currentTab === 'overview' && (
                        <>
                            <Box p={4}>
                                <Card
                                    sx={{
                                        p: 3,
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        gutterBottom
                                        textAlign="center"
                                    >
                                        Overview
                                    </Typography>
                                    <Divider
                                        sx={{
                                            my: 2,
                                            borderColor: 'primary.main',
                                            borderWidth: 1,
                                            width: '50%',
                                            mx: 'auto',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        lineHeight={1.8}
                                        textAlign="justify"
                                        gutterBottom
                                    >
                                        An Airway Intelligence Report (AIR) is a diagnostic report summary
                                        that utilizes a patient's CBCT scan, photos, teeth impressions, and
                                        other records such as sleep study reports. It provides essential
                                        measurements and data for better patient analysis and treatment.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        lineHeight={1.8}
                                        textAlign="justify"
                                        gutterBottom
                                    >
                                        These reports are compiled in a Microsoft PowerPoint presentation to
                                        aid providers and patients. They can be used to educate patients,
                                        determine diagnoses, create treatment plans, track progress, and more.
                                    </Typography>

                                    <Box
                                        sx={{
                                            mt: 3,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            href="https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/Sample AIR/Vivos AI Report_Sample Report.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ textTransform: 'none' }}
                                        >
                                            View Sample Report
                                        </Button>
                                    </Box>
                                </Card>
                            </Box>
                        </>
                    )}
                    {currentTab === 'basics' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={3}>
                                    {/* Box for Question 1 */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                What are the requirements to order an Airway Intelligence Report (AIReport)?
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.8}
                                                textAlign="left"
                                                gutterBottom
                                            >
                                                To view the detailed requirements for ordering an Airway Intelligence Report (AIReport), click the button below:
                                            </Typography>
                                            <Box
                                                sx={{
                                                    mt: 3,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                href="https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Requirements/3.2024_AirwayIntelligenceReportRequirements.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    textTransform: 'none',
                                                    borderRadius: 2,
                                                    fontWeight: 'bold',
                                                    px: 3,
                                                    py: 1.5,
                                                    '&:hover': {
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.primary.contrastText,
                                                        borderColor: theme.palette.primary.main,
                                                    },
                                                }}
                                            >
                                                AIReport Requirements
                                            </Button>
                                        </Box>
                                        <Box
                                                sx={{
                                                    mt: 3,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                href={airRecommendedRecords}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    textTransform: 'none',
                                                    borderRadius: 2,
                                                    fontWeight: 'bold',
                                                    px: 3,
                                                    py: 1.5,
                                                    '&:hover': {
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.primary.contrastText,
                                                        borderColor: theme.palette.primary.main,
                                                    },
                                                }}
                                            >
                                                AIReport Recommended Records Checklist
                                            </Button>
                                        </Box>

                                        </Card>
                                    </Grid>

                                    {/* Box for Question 2 */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                How do I interpret an Airway Intelligence Report?
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.8}
                                                textAlign="left"
                                            >
                                                For help interpreting Airway Intelligence Reports, we recommend watching{" "}
                                                <em>“Airway Intelligence Reports - Clinical Application”</em> available in{" "}
                                                <strong>Block 1</strong> on the Vivos Absorb Learning Platform. You can also schedule an AIS Consultation for further guidance.
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'turnaroundTime' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={3}>
                                    {/* Box for Turnaround Time Question */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                What is the average turnaround time for an Airway Intelligence Report?
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.8}
                                                textAlign="left"
                                                gutterBottom
                                            >
                                                The average turnaround time for an AI Report is 3-5 business days. This timeframe may vary based on demand and case complexity.
                                            </Typography>
                                        </Card>
                                    </Grid>

                                    {/* Example of an Additional Turnaround Time Box (if needed) */}
                                    {/* <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                Can I expedite an Airway Intelligence Report?
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.8}
                                                textAlign="left"
                                            >
                                                Yes, you may request an expedited Airway Intelligence Report by contacting our Help Desk. Please allow a minimum of 3 business hours for rush cases to be completed. Requests are fulfilled based on demand and technician availability.
                                            </Typography>
                                        </Card>
                                    </Grid> */}
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'comparativeAir' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={3}>
                                    {/* Box for Question 1 */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                What is included in a Comparative Airway Intelligence Report?
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.8}
                                                textAlign="left"
                                            >
                                                Comparative Airway Intelligence Reports are compiled using initial and either mid or post-treatment records to create a side-by-side comparative analysis report. Comparative slides within this report can include records such as extra and intraoral photos, teeth impressions, sleep study reports, CBCT scan data, and more. Additional measurements and analyses can be requested according to provider preferences when ordering a Comparison Airway Intelligence Report.
                                            </Typography>
                                        </Card>
                                    </Grid>

                                    {/* Box for Question 2 */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                How do I interpret a Comparative Airway Intelligence Report?
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.8}
                                                textAlign="left"
                                            >
                                                We recommend scheduling an AIS Consultation for help interpreting Comparative Airway Intelligence Reports. This ensures a comprehensive understanding of the report's data and measurements to aid in treatment planning.
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'addOn' && (
                        <>
                            <Box p={4}>
                                <Typography variant="h5" gutterBottom>
                                    Explore Add-Ons
                                </Typography>
                                <Grid container spacing={1.5}>
                                    {[
                                        {
                                            title: "Radiology Report",
                                            link: "",
                                            image: "/images/general/knowledgeBase/Radiology Reports.png",
                                            description: "A detailed report of your patient's CBCT scan prepared by a licensed radiologist courtesy of Beam Readers.",
                                            additionalCost: true,
                                        },
                                        {
                                            title: "Sassouni",
                                            link: "",
                                            image: "/images/general/knowledgeBase/Sassouni Analysis.png",
                                            description: "A craniofacial analysis utilizing the lateral Ceph CBCT image.",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Frontal Analysis",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AIR Add-Ons_Frontal Analysis.pdf",
                                            image: "/images/general/knowledgeBase/Frontal Analysis.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "3D Printed Airway",
                                            link: "",
                                            image: "/images/general/knowledgeBase/3D Printed Airways.png",
                                            description: "A physical 3D printed airway modeled from your patient's CBCT scan.",
                                            additionalCost: true,
                                        },
                                        {
                                            title: "Faciometrics",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_Add-Ons_Faciometrics.pdf",
                                            image: "/images/general/knowledgeBase/Faciometrics.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Cranial Base Measurements",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AI Report Add_Ons  Cranial Base.pdf",
                                            image: "/images/general/knowledgeBase/Cranial Base Measurements.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Cervical Measurements",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AIR Add-Ons_Cervical.pdf",
                                            image: "/images/general/knowledgeBase/Cervical Measurements.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Retropalatal/Retroglossal (RP/RG) AP Measurements",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AIR Add-Ons_RPRG AP.pdf",
                                            image: "/images/general/knowledgeBase/RP RG Distance.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Retropalatal/Retroglossal (RP/RG) Area Measurements",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AIR Add-Ons_RPRG Area.pdf",
                                            image: "/images/general/knowledgeBase/RP RG Area.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Posterior Nasal Aperture (PNA) Measurements",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AIR Add-Ons_PosteriorNasalAperture.pdf",
                                            image: "/images/general/knowledgeBase/Posterior Nasal Aperture.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Inferior Nasal Concha (IC) Measurements",
                                            link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Add Ons/4.2024_AIR Add-Ons_IC.pdf",
                                            image: "/images/general/knowledgeBase/Inferior Nasal Concha.png",
                                            description: "",
                                            additionalCost: false,
                                        },
                                        {
                                            title: "Treatment Plan Sequencing",
                                            link: "",
                                            image: "/images/general/knowledgeBase/TreatmentPlan.png",
                                            description: "A tailored treatment plan comprised of a comprehensive review of your AI Report, including recommendations for rehabilitation, management, and pediatric appliance solutions.",
                                            additionalCost: false,
                                        },
                                    ].map((item, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: 1.5,
                                                    bgcolor: 'background.paper',
                                                    borderRadius: 2,
                                                    boxShadow: 2,
                                                    transition: 'all 0.2s ease-in-out',
                                                    '&:hover': {
                                                        boxShadow: 4,
                                                        transform: 'scale(1.02)',
                                                    },
                                                }}
                                            >
                                                {/* Left Icon */}
                                                <Box
                                                    sx={{
                                                        width: 60,
                                                        height: 60,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginRight: 2,
                                                        overflow: 'hidden',
                                                        borderRadius: 1,
                                                        backgroundColor: 'white',
                                                        flexShrink: 0,
                                                    }}
                                                >
                                                    <img
                                                        src={item.image}
                                                        alt={item.title}
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '100%',
                                                            objectFit: 'contain',
                                                        }}
                                                    />
                                                </Box>

                                                {/* Title, Description, and Additional Cost Label */}
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        fontWeight="bold"
                                                        sx={{ color: theme.palette.primary.main }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                    {item.description && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{ color: theme.palette.text.secondary }}
                                                        >
                                                            {item.description}
                                                        </Typography>
                                                    )}
                                                    {item.additionalCost && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: theme.palette.primary.main,
                                                                fontStyle: 'italic',
                                                                fontSize: '0.8rem',
                                                                marginTop: '2px',
                                                            }}
                                                        >
                                                            *Additional Cost
                                                        </Typography>
                                                    )}
                                                </Box>

                                                {/* Button */}
                                                {item.link && (
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        sx={{
                                                            textTransform: 'none',
                                                            borderRadius: 2,
                                                            fontSize: '0.75rem',
                                                            paddingY: 0.5,
                                                            paddingX: 1.5,
                                                        }}
                                                    >
                                                        Learn More
                                                    </Button>
                                                )}
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'radReports' && (
                    <>
                        <Box p={4}>
                            <Grid container spacing={3}>
                                {/* Box for Question 1 */}
                                <Grid item xs={12}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 3,
                                            bgcolor: 'background.paper',
                                            borderRadius: 2,
                                            boxShadow: 2,
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                boxShadow: 4,
                                                transform: 'scale(1.02)',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            gutterBottom
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            How do I order a Radiology Report?
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            lineHeight={1.8}
                                            textAlign="left"
                                        >
                                            To order a Radiology Report, go to the patient’s profile, click on the <strong>"AI Reports"</strong> tab, and check the box <strong>"Radiology Report"</strong> under the "Customize Report" options.
                                        </Typography>
                                    </Card>
                                </Grid>

                                {/* Box for Question 2 */}
                                <Grid item xs={12}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 3,
                                            bgcolor: 'background.paper',
                                            borderRadius: 2,
                                            boxShadow: 2,
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                boxShadow: 4,
                                                transform: 'scale(1.02)',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            gutterBottom
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            What are the requirements for a Radiology Report?
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            lineHeight={1.8}
                                            textAlign="left"
                                        >
                                            <ul style={{ margin: 0, paddingLeft: '1.5em' }}>
                                                <li>CBCT scan(s) must be saved in either single-file DICOM or multi-file DICOM format.</li>
                                                <li>The DICOM cannot be anonymized.</li>
                                                <li>We recommend reviewing all CBCT scans before submission to ensure scan quality and an accurate field of view.</li>
                                                <li>Clinical notes will be forwarded to the Radiologist whenever possible for a comprehensive case view.</li>
                                            </ul>
                                        </Typography>
                                    </Card>
                                </Grid>

                                {/* Box for Question 3 */}
                                <Grid item xs={12}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 3,
                                            bgcolor: 'background.paper',
                                            borderRadius: 2,
                                            boxShadow: 2,
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                boxShadow: 4,
                                                transform: 'scale(1.02)',
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            gutterBottom
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            Do you have a sample Radiology Report?
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            lineHeight={1.8}
                                            textAlign="left"
                                        >
                                            Yes, click <a href="https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/Radiology Reports/Sample Radiology Report.pdf" target="_blank" rel="noopener noreferrer"><strong>HERE</strong></a> to view a sample Radiology Report. This sample is courtesy of BeamReaders.com.
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
                </Grid>
            </Grid>
        </Card>
    );
}

export default AIReportContent;
