import React, { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { action, makeObservable, observable } from "mobx";
import { GridHeaderBL } from "../../../components/GridHeader/GridHeaderBL";
import {
    Typography,
    Box,
    styled,
    Avatar,
    lighten,
    alpha,
    Grid,
    Button,
    Popover
} from '@mui/material';
import ForumIconTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";


const AvatarPageTitle = styled(Avatar)(
    ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);



interface PageHeaderProps { }

@observer
export class PageHeader extends Component<PageHeaderProps> {
    state = {
        anchorEl: null,
    };

    // Handler to open Popover and set its anchor element
    handlePopoverOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    // Handler to close Popover
    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    // Check if Popover is open based on whether an anchor element is set
    isPopoverOpen = () => Boolean(this.state.anchorEl);

    render() {
        const { anchorEl } = this.state;
        return (
            <Box

                display="flex"
                alignItems={{ xs: 'stretch', md: 'center' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
            >
                <Box display="flex" flex='2' alignItems="center">
                    <AvatarPageTitle variant="rounded">
                        <ForumIconTwoToneIcon fontSize="large" />
                    </AvatarPageTitle>
                    <Box>
                        <Typography variant="h3" component="h3" gutterBottom>
                            Consultations
                        </Typography>
                        <Typography variant="subtitle2">
                            All scheduled Airway Intelligence Service consultations can be found below.
                        </Typography>
                    </Box>
                </Box>
                <Box
                    py={2}
                    display="flex"
                    flex="2"
                    alignItems={{ xs: 'stretch', md: 'center' }}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent="right">
                    <Grid justifyContent="right" alignItems="center" container spacing={3}>
                        <Grid item xs={12} md={6} lg={4} >
                            <Button
                                sx={{
                                    mt: { xs: 2, sm: 0 }
                                }}
                                onClick={this.handlePopoverOpen}
                                variant="contained"
                                startIcon={<HelpTwoToneIcon fontSize="small" />}
                            >
                                How to schedule a consultation?
                            </Button>
                            <Popover
                                open={this.isPopoverOpen()}
                                anchorEl={anchorEl}
                                onClose={this.handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Box p={2} maxWidth="400px">
                                    <Typography variant="h5" component="h2">
                                        Scheduling a Consultation
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Follow these steps to schedule your consultation:
                                    </Typography>
                                    <ol>
                                        <li>Navigate to a patient you are interested in consulting about.</li>
                                        <li>Click on the "Shedule Consultation" button.</li>
                                        <li>Select date and time.</li>
                                        <li>Fill in the required details about the consultation.</li>
                                        <li>Submit Request</li>
                                    </ol>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>Requirement:</strong> First Standard AI Report purchased for the patient you are consulting about.
                                    </Typography>
                                </Box>
                            </Popover>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

export default PageHeader;
