import { MainStore } from './MainStore';
import { action, makeAutoObservable, observable, toJS } from 'mobx';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';

export class CalendlyConsultationsStore {
    mainStore: MainStore;

    constructor(mainStore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    userId = 0;
    roles: any[] = [];
    patientId = 0;
    consultantId = 0;
    questions: string = '';
    primaryBitwise: number = 0;
    otherNotes = '';

    email = '';
    sessionIndexNumber = 0;
    scheduleDateTime = '2019 10:33:30 GMT-0300';
    calendlyUri = '';
    calendlyRescheduleLink = '';

    selectDateTime = false;

    setSelectDateTime = (selectDateTime: boolean) => {
        this.selectDateTime = selectDateTime;
    };

    @observable currentRow: any = {};
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);

    resetConsultations = () => {
        this.patientId = 0;
        this.consultantId = 0;
        this.questions = '';
        this.primaryBitwise = 0;
        this.otherNotes = '';
        this.currentRow = {};
    };

    setPrimaryBitwise = (bitwise: number) => {
        this.primaryBitwise = bitwise;
    };

    setOtherNotes = (notes: any) => {
        this.otherNotes = notes;
    };

    setQuestions = (questions: any) => {
        this.questions = questions;
    };

    setPatientId = (id: number) => {
        this.patientId = id;
    };

    setConsultantId = (consultantId: any) => {
        this.consultantId = consultantId;
    };

    setScheduleDateTime = (scheduledDateAndTime: any) => {
        this.scheduleDateTime = scheduledDateAndTime;

        this.selectDateTime = scheduledDateAndTime ? true : false;

        console.log(this.scheduleDateTime);
    };

    setCalendlyUri = (calendlyUri: any) => {
        this.calendlyUri = calendlyUri;
    };

    setCalendlyRescheduleLink = (calendlyRescheduleLink: any) => {
        this.calendlyRescheduleLink = calendlyRescheduleLink;
    };

    saveTopics = async () => {
        let data = {
            userId: this.userId,
            topics: this.primaryBitwise,
            otherNotes: this.otherNotes,
            questions: this.questions,
            patientId: this.patientId,
            consultantId: this.consultantId,
            scheduleDateTime: this.scheduleDateTime,
            calendlyUri: this.calendlyUri,
            calendlyRescheduleLink: this.calendlyRescheduleLink,
        };

        const url = `provider/${this.userId}/p2pconsultations`;
        await Gateway.post(url, data).then(response => {});
    };
}
