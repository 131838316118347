import { observer } from "mobx-react";
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import {
    Grid,
    Box,
    TextField,
    Typography,
    Divider,
} from "@mui/material";
import ButtonGroupingOptions from "./NoteComponents.tsx/ButtonGroupingOptions";



const InitialEvaluation = (props) => {
    const { currentAppointmentNote, setCurrentAppointmentNoteProp, currentSession } = useMainStoreContext().createAppointmentNotesStore;


    return (
        <Grid container paddingTop={2} paddingLeft={4} paddingRight={4}>
            <Grid item xs={12} lg={6} padding={2}>
                <Box margin={1} padding={1}>
                    <TextField
                        fullWidth
                        style={{ width: '100%', height: '100px' }}
                        inputProps={{
                            style: {
                                color: currentAppointmentNote['chiefComplaint']?.markAsEdited ? 'red' : 'Black',
                            },
                        }}
                        multiline
                        minRows={3}
                        maxRows={3}
                        variant="outlined"
                        label="Chief Complaint"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'chiefComplaint');
                        }}
                        value={currentAppointmentNote['chiefComplaint']?.response}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} lg={6} padding={2}>
                <Box margin={1} padding={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    style: {
                                        color: currentAppointmentNote['currentDentalAppliances']?.markAsEdited ? 'red' : 'Black',
                                    },
                                }} size="small"
                                multiline
                                minRows="1"
                                required={false}
                                label="Current Appliances"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCurrentAppointmentNoteProp(event.target.value, 'currentDentalAppliances');
                                }}
                                value={currentAppointmentNote['currentDentalAppliances']?.response}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    style: {
                                        color: currentAppointmentNote['previousDentalAppliances']?.markAsEdited ? 'red' : 'Black',
                                    },
                                }}
                                size="small"
                                multiline
                                minRows="1"
                                required={false}
                                label="Prior Appliances"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCurrentAppointmentNoteProp(event.target.value, 'previousDentalAppliances');
                                }}
                                value={currentAppointmentNote['previousDentalAppliances']?.response}
                            />
                        </Grid>

                    </Grid>
                </Box>
            </Grid>
            <Divider />
            <Grid item xs={12} mt={1}>
                <Typography variant="h3">Medical History</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} lg={6} padding={2}>
                        <Grid container direction={'column'}>
                            <ButtonGroupingOptions
                                title={'General'}
                                options={[
                                    'Sleep Apnea',
                                    'Facial Enhancments (Surgery or Injectables)',
                                    'Autism',
                                    'ADHD or ADD',
                                    'Tourette Syndrome',
                                    'Anxiety',
                                    'Depression',
                                    'Surgery',
                                    'Auto Immune Disease',
                                    'Cancer']}
                                values={[
                                    'sleepApnea',
                                    'anyFacialCosmeticsEnhancement',
                                    'autism',
                                    'adhdOrAdd',
                                    'touretteSyndrome',
                                    'anxiety',
                                    'depression',
                                    'surgery',
                                    'autoImmuneDisease',
                                    'cancer']}
                                currentAppointmentNote={currentAppointmentNote}
                                setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                            />
                            <Grid m={1} spacing={2} direction={'column'}>
                                {parseInt(currentAppointmentNote['sleepApnea']?.response) === 1 &&
                                    <Grid container alignItems={'center'} m={1}>
                                        <Grid item xs={4} paddingLeft={1}>
                                            <Typography variant="h6">
                                                Sleep Apnea Details:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} paddingRight={2}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                minRows="1"
                                                required={false}
                                                label="AHI"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setCurrentAppointmentNoteProp(parseInt(event.target.value), 'ahi');
                                                }}
                                                value={currentAppointmentNote['ahi']?.response}
                                            />
                                        </Grid>
                                        <Grid item xs={4} paddingRight={2}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                minRows="1"
                                                required={false}
                                                label="RDI"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setCurrentAppointmentNoteProp(parseInt(event.target.value), 'rdi');
                                                }}
                                                value={currentAppointmentNote['rdi']?.response}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                {parseInt(currentAppointmentNote['surgery']?.response) === 1 &&
                                    <Grid container alignItems={'center'} m={1}>
                                        <Grid item xs={4} paddingLeft={1}>
                                            <Typography variant="h6">
                                                Surgery Details:
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} paddingRight={2}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                minRows="1"
                                                required={false}
                                                label="Surgery Explanation"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setCurrentAppointmentNoteProp(event.target.value, 'surgeryExplain');
                                                }}
                                                value={currentAppointmentNote['surgeryExplain']?.response}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                {parseInt(currentAppointmentNote['autoImmuneDisease']?.response) === 1 &&
                                    <Grid container alignItems={'center'} m={1}>
                                        <Grid item xs={4} paddingLeft={1}>
                                            <Typography variant="h6">
                                                Auto Immunne Disease Details:
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} paddingRight={2}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                minRows="1"
                                                required={false}
                                                label="Auto Immune Disease Explanation"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setCurrentAppointmentNoteProp(event.target.value, 'autoImmuneDiseaseExplain');
                                                }}
                                                value={currentAppointmentNote['autoImmuneDiseaseExplain']?.response}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                {parseInt(currentAppointmentNote['cancer']?.response) === 1 &&
                                    <Grid container alignItems={'center'} m={1}>
                                        <Grid item xs={4} paddingLeft={1}>
                                            <Typography variant="h6">
                                                Cancer Details:
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} paddingRight={2}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                minRows="1"
                                                required={false}
                                                label="Cancer Explanation"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setCurrentAppointmentNoteProp(event.target.value, 'cancerExplain');
                                                }}
                                                value={currentAppointmentNote['cancerExplain']?.response}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <ButtonGroupingOptions
                                title={'Bone'}
                                options={['Osteoporosis', 'Arthritis', 'Backaches', 'Neck Pain', 'Bone Injury', 'Scoliosis']}
                                values={[
                                    'osteoporosis',
                                    'arthritis',
                                    'backaches',
                                    'neckPain',
                                    'boneInjury',
                                    'scoliosis']}
                                currentAppointmentNote={currentAppointmentNote}
                                setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                            />
                            <Grid m={1} spacing={2} direction={'column'}>
                                {parseInt(currentAppointmentNote['boneInjury']?.response) === 1 &&
                                    <Grid container alignItems={'center'} m={1}>
                                        <Grid item xs={4} paddingLeft={1}>
                                            <Typography variant="h6">
                                                Bone Injury Details:
                                            </Typography>
                                        </Grid>
                                        <Grid xs={8} paddingRight={2}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                minRows="2"
                                                required={false}
                                                label="Bone Injury Explanation"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setCurrentAppointmentNoteProp(event.target.value, 'boneInjuryExplain');
                                                }}
                                                value={currentAppointmentNote['boneInjuryExplain']?.response}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <ButtonGroupingOptions
                                title={'Craniofacial'}
                                options={['Ear Infection', 'Tinnitis', 'Ear fullness or pain', 'Frequent Headaches/migraines', 'TMJ Clicking', 'TMJ Pain', 'Dizziness', 'Neurological Issues', 'Craniofacial Scoliosis']}
                                values={[
                                    'earInfection',
                                    'tinnitus',
                                    'earFullnessOrPain',
                                    'frequentHeadachesMigraines',
                                    'tmjClicking',
                                    'tmjPain',
                                    'dizziness',
                                    'neurologicalIssues',
                                    'scoliosisCraniofacial']}
                                currentAppointmentNote={currentAppointmentNote}
                                setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} padding={2}>
                        <ButtonGroupingOptions
                            title={'Respiratory'}
                            options={['Asthma', 'Bronchitis', 'Pneumonia', 'COPD', 'Croup']}
                            values={[
                                'asthma',
                                'bronchitis',
                                'pneumonia',
                                'copdchronicObstructivePulmonaryDisease',
                                'croup']}
                            currentAppointmentNote={currentAppointmentNote}
                            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                        />
                        <ButtonGroupingOptions
                            title={'Upper Respiratory'}
                            options={['Sinus Infection', 'Tonsillitis or Adenoiditis', 'Frequent sore throat', 'Daily Cough (or clearing of throat area?)', 'Consistent runny nose and/or Congestion']}
                            values={[
                                'sinusInfections',
                                'tonsillitisOrAdenoiditis',
                                'frequentSoarThroat',
                                'dailyCoughorClearingOfThroat',
                                'consistentRunnyNoseAndorCongestion']}
                            currentAppointmentNote={currentAppointmentNote}
                            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                        />
                        <ButtonGroupingOptions
                            title={'Cardiac'}
                            options={['High Blood Pressure', 'Heart Attack', 'Stroke']}
                            values={[
                                'hypertensionhighBloodPressure',
                                'heartAttack',
                                'stroke']}
                            currentAppointmentNote={currentAppointmentNote}
                            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                        />
                        <ButtonGroupingOptions
                            title={'Allergies'}
                            options={['Acrylic', 'Plastic', 'Food', 'Metals', 'Latex', 'PainMedications', 'Other']}
                            values={[
                                'acrylic',
                                'plastic',
                                'food',
                                'metals',
                                'latex',
                                'painMedications',
                                'other']}
                            currentAppointmentNote={currentAppointmentNote}
                            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                        />
                        <Grid m={1} spacing={2} direction={'column'}>
                            {parseInt(currentAppointmentNote['other']?.response) === 1 &&
                                <Grid container alignItems={'center'} m={1}>
                                    <Grid item xs={4} paddingLeft={1}>
                                        <Typography variant="h6">
                                            Other allergy details:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={8}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            multiline
                                            minRows="2"
                                            required={false}
                                            label="Allergy Explanation"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentAppointmentNoteProp(event.target.value, 'otherExplain');
                                            }}
                                            value={currentAppointmentNote['otherExplain']?.response}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
export default observer(InitialEvaluation);