import { createContext, useContext } from "react";
import { BaseMainStore } from "../../base/MainStore";
import { PersonStore } from "../../Person/PersonStore";
import { isMinor } from "../../../api/getAge";
import { action, computed, observable } from "mobx";
import { GlobalUtils } from "../../../api/GlobalUtils";
import moment from "moment";
import { PatientStore } from "../PatientStore";
import { VisitStore } from "../../Visit/VisitStore";
import LegalGuardian from "../../../models/state_models/legalGuardian";
import Person from "../../../models/state_models/people";
import PatientVisit from "../../../models/state_models/patientVisit";
import { CustomFormStore } from "../../CustomForm/CustomFormStore";
import Patient from "../../../models/state_models/patient";
import { Gateway } from "../../../api/Gateway";

export class CreatePatientMainStore extends BaseMainStore {
    personStore: PersonStore;
    patientStore: PatientStore;
    visitStore: VisitStore;
    customFormStore: CustomFormStore;

    @computed
    get patientVisit(): PatientVisit | undefined {
        return this.visitStore.visit;
    }

    @observable legalGuardians: LegalGuardian[] = [];

    constructor() {
        super();
        this.personStore = new PersonStore(this);
        this.patientStore = new PatientStore(this);
        this.visitStore = new VisitStore(this);
        this.customFormStore = new CustomFormStore(this);
    }

    @action validatePatient = (): string[] => {
        let fieldValidationList: string[] = [];
        let isOfAge = !isMinor(this.personStore.person.dateOfBirth);

        if (this.personStore.person?.firstName === "") {
            fieldValidationList.push("First Name is required.");
            //this.setHelperFirstNameText("Required Field");
        }
        else if (!GlobalUtils.validateAlpha(this.personStore.person.firstName)) {
            fieldValidationList.push("First Name has invalid characters.");
            //this.setHelperFirstNameText("Invalid characters");
        }

        if (this.personStore.person.lastName === "") {
            fieldValidationList.push("Last Name is required.");
            //this.setHelperLastNameText("Required Field");
        } else if (!GlobalUtils.validateAlpha(this.personStore.person.lastName)) {
            fieldValidationList.push("Last Name has invalid characters.");
            //this.setHelperLastNameText("Invalid characters");
        }

        if (moment(this.personStore.person.dateOfBirth).format("YYYYMMDD") === moment(new Date()).format("YYYYMMDD")) {
            fieldValidationList.push("Date of Birth is required.");
        }

        if (this.personStore.person.emailAddress !== "" && !GlobalUtils.validateEmail(this.personStore.person.emailAddress)) {
            fieldValidationList.push("Invalid Email Format");
            //this.setHelperEmailText("Invalid Email Format");
        }

        if (this.personStore.person.primaryPhone !== "" && !GlobalUtils.validateNumeric(this.personStore.person.primaryPhone)) {
            fieldValidationList.push("Primary Phone may contain only numbers.");
            //this.setHelperPrimaryPhoneText("Invalid characters");

        } if (this.personStore.person.altPhone !== "" && !GlobalUtils.validateNumeric(this.personStore.person.altPhone)) {
            fieldValidationList.push("Alternate Phone may contain only numbers.");
            //this.setHelperAltPhoneText("Invalid characters");
        }

        if (isOfAge) {
            if (this.personStore.person.firstName === "") {
                fieldValidationList.push("First Name is required.");
                //this.setHelperFirstNameText("Required Field");
            }
            else if (!GlobalUtils.validateAlpha(this.personStore.person.firstName)) {
                fieldValidationList.push("First Name has invalid characters.");
                //this.setHelperFirstNameText("Invalid characters");
            }

            if (this.personStore.person.lastName === "") {
                fieldValidationList.push("Last Name is required.");
                //this.setHelperLastNameText("Required Field");
            } else if (!GlobalUtils.validateAlpha(this.personStore.person.lastName)) {
                fieldValidationList.push("Last Name has invalid characters.");
                //this.setHelperLastNameText("Invalid characters");
            }
        }
        else {
            /*
          this.setHelperAddress1Text("");
          this.setHelperCityText("");
          this.setHelperStateText("");
          this.setHelperPostalCodeText("");
          this.setHelperEmailText("");
          this.setHelperPrimaryPhoneText("");
          this.setHelperAltPhoneText("");
          */
        }
        return fieldValidationList;
    }

    @action savePersonAndHandleActions = async (): Promise<Person | null> => {

        this.personStore.person.legalGuardians = this.legalGuardians;
        this.personStore.person.patients.push(this.patientStore.patient);

        return Gateway.postStrongType<Person>(`/patient`, this.personStore.person).then<Person | null>((response): Person | null => {
            if (response) {
                this.personStore.setPerson(response);
                var createdPatient = response.patients?.pop();
                if (createdPatient) {
                    this.patientStore.patient = createdPatient;
                    var createdVisit = createdPatient.patientVisits.pop();
                    if(createdVisit){
                        this.visitStore.visit = createdVisit;
                    }
                }

                return response;
            }
            else{
                return null;
            }
        });
    };


    @action resetStore = (): void => {
        this.personStore = new PersonStore(this);
        this.patientStore = new PatientStore(this);
        this.visitStore = new VisitStore(this);
        this.customFormStore = new CustomFormStore(this);
        this.legalGuardians = [];
    }
}

export const store = new CreatePatientMainStore();
export const MainStoreContext = createContext<CreatePatientMainStore>(store);
export const useCreatePatientMainStoreContext = () => {
    return useContext(MainStoreContext);
};
