// Vendors
import { Component } from "react";
import {
    Typography,
    Box,
    styled,
    Avatar,
    lighten,
    alpha,
    Grid,
    Card,
    Stack,
    Divider,
    CardActionArea,
    Tooltip,
    Button
} from '@mui/material';
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';

// Components
import { GridHeaderBL } from "../../../components/GridHeader/GridHeaderBL";

// API
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";

const AvatarPageTitle = styled(Avatar)(
    ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
  
          .MuiTouchRipple-root {
            opacity: .2;
          }
    
          &:hover {
            .MuiCardActionArea-focusHighlight {
              opacity: 0;
            }
          }
    `
);

interface PageHeaderProps {
}

@observer
export class PageHeader extends Component<PageHeaderProps> {
    @observable store = new GridHeaderBL();


    constructor(props: any) {
        super(props);
        makeObservable(this);
    }

    render() {
        return (
            <Box

                display="flex"
                alignItems={{ xs: 'stretch', md: 'center' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
            >
                <Box display="flex" flex='2' alignItems="center">
                    <AvatarPageTitle variant="rounded">
                        <PeopleTwoToneIcon fontSize="large" />
                    </AvatarPageTitle>
                    <Box>
                        <Typography variant="h3" component="h3" gutterBottom>
                            Rework Management
                        </Typography>
                        <Typography variant="subtitle2">
                            All rework request can be found below.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default PageHeader;
