import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface ConfirmDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    message?: string;
    textOnClose?: string;
    textOnConfirm?: string;
    
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, onClose, onConfirm, title, message, textOnClose = "Cancel", textOnConfirm = "Yes" }) => {
    const defaultTitle = "Confirm Deletion";
    const finalTitle = title || defaultTitle;
    const defaultMessage = "Are you sure you want to delete this element?";
    const finalMessage = message || defaultMessage;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{finalTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {finalMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {textOnClose}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {textOnConfirm}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;