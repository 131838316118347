import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import DictionaryListItem from "../../entities/DictionaryListItem";
import UploadImgDragDrop from "../UploadDragAndDrop/UploadImgDragDrop";
import { GlobalUtils } from "../../api/GlobalUtils";

interface IProps {
    isIntake: boolean;
    isVisit: boolean;
}

const IntraoralImage: React.FC<IProps> = ({ isIntake, isVisit }) => {

    const { patient, intraoralImageType, setIntraoralImageType, patientIntraoralImages } = useMainStoreContext().createPatientStore;
    const { getVisitId, visitIntraoral2dimages, imagesToDelete, isLoading } = useMainStoreContext().visitRecordStore;
    const { intraoral2dimages, vivosId } = useMainStoreContext().aiReportOrder;
    const [currentSelectedType, setCurrentSelectedType] = React.useState<DictionaryListItem>(new DictionaryListItem({
        id: 0,
        name: "",
        listItemKey: "",
        value: "",
        isActive: true,
        dictionaryListId: 0
    }));
    const [hasLoaded, setHasLoaded] = React.useState(false);

    const handleonClick = (data64string, dataPathUrl, imageType,) => {

    }

    const deleteCallback = (itemOption: number) => {
        imagesToDelete.push(itemOption);
        const indexToRemove = visitIntraoral2dimages.findIndex(x => x.imageType.id === itemOption);
        if (indexToRemove !== -1) {
            visitIntraoral2dimages.splice(indexToRemove, 1);
        }
    }

    useEffect(() => {
        setIntraoralImageType(GlobalUtils.getIntraOralImages());
        setHasLoaded(true);
    }, [setIntraoralImageType]);

    return (
        <Grid className="formBasics">
            {isLoading &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>
            }
            {!isLoading && <Grid paddingBottom={10}>
                <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                    Intraoral Images
                </Typography>
                <Box paddingTop={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={12} container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                        <Grid xs={12} item direction="column">
                            {hasLoaded && <Grid container xs={12} direction="column">
                                <Typography variant="body1" paddingBottom={2}>
                                Locate images on your device, then click to select or drag your files into the box below. After uploading, please label each image accordingly.
                                </Typography>
                                <UploadImgDragDrop
                                    extImages={isIntake ? patientIntraoralImages : (isVisit) ? visitIntraoral2dimages : intraoral2dimages}
                                    type="intraoral" parentCallback={handleonClick}
                                    typeOptions={intraoralImageType}
                                    vivosId={isIntake ? patient.vivosId : vivosId}
                                    visitId={getVisitId()}
                                    deleteCallback={deleteCallback} />
                            </Grid>}
                        </Grid>
                    </Grid>
                </Box>
            </Grid>}
        </Grid>
    )
}

export default observer(IntraoralImage);