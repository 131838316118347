// Vendors
import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';

// Components
import { AlertDialog } from '../../components/AlertDialog';
import { VisitHeader } from './components/VisitHeader';
import ExtraoralImages from '../../components/ImagesAndDocs/ExtraoralImages';
import { PatientVitalsAndMeasurements } from '../../components/PatientProfile/PatientVisitRecordComponents/PatientVitalsAndMeasurements';
import IntraoralImages from '../../components/ImagesAndDocs/IntraoralImages';
import ScanCbct from '../../components/ImagesAndDocs/ScanCbct';
import ScanOcclusion from '../../components/ImagesAndDocs/ScanOcclusion';
import Documents from '../../components/ImagesAndDocs/Documents';
import { PatientCompliance } from './components/Compliance';
import { VisitTab } from './components/VisitTab';
import SleepTest from '../../components/ImagesAndDocs/SleepTest';

// Entities
import PatientVisit from '../../entities/PatientVisit';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { FC } from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { Gateway } from '../../api/Gateway';
import { GlobalUtils } from '../../api/GlobalUtils';

// Styling
import '../../scss/_VisitRecord.scss'
import PatientFile from '../../entities/PatientFIle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import CssTextField from '../../components/CssTextField';
import { DigitalFormResponses } from '../../components/PatientProfile/PatientModalCustomForm/DigitalFormResponses';
import PatientImage from '../../entities/PatientImage';

interface IGIProps {
    patient: any;
    visit?: any;
    onClose(): any;
}

const tabs = [
    'Compliance',
    'Vitals & Measurements',
    'Extraoral Images',
    'Intraoral Images',
    'CBCT',
    'Impressions',
    'Sleep Test',
    'Documents',
    'Form Responses',
];

const COMPLIANCE = 0;
const VITALS = 1;
const EXTRAORAL = 2;
const INTRAORAL = 3;
const CBCT = 4;
const IMPRESSIONS = 5;
const SLEEPTEST = 6;
const DOCUMENTS = 7;
const FORMRESPONSES = 8;

export const VisitRecord: FC<IGIProps> = observer(({ patient, visit, onClose }) => {
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const { getVitalsAndMeasurements, setVitalsAndMeasurements, setVisitId, vitalsAndMeasurementsReset } = useMainStoreContext().vitalsAndMeasurementsStore;
    const { id, activeTab, previousTab, setActiveTab, currentVisit, patientsImages, visitExtraoral3dimages, visitExtraoral2dimages, userId,
        getVisitId, loadVisit, resetVisit, setImages, setPreviousTab,
        setPatientVitalsAndMeasurements, setExtraoralImageTypes, setIntraoralImageTypes, setOcclusionImageTypes,
        setVisitPatientId, saveVisit, saveVitals, setVisit, saveCompliance, visitImagesToPatientImages, visitImagesToComplianceImages, visitOcclusion3dimages, visitOcclusion2dimages,
        patientUploadFiles, setPatientUploadFiles, patientFiles, setPatientFiles, visitIntraoral2dimages, deleteImages, deleteComplianceImages, setDocumentFiles, setCreatedOn,
        patientCompliances, setIsLoading, setCurrentVisit, setPatientCompliances, isCurrentVisitLoaded, setId } = useMainStoreContext().visitRecordStore;
    const { updateCbctDetails } = useMainStoreContext().scanStore;
    const { setMolarRelationshipType, setMachineBrand3DType, setCbctMachineBrandType, setpatientImageType, setpatientOcclusionImageType } = useMainStoreContext().createPatientStore;
    const occlusionStore = useMainStoreContext().occlusionStore;
    const intakeReportsStore = useMainStoreContext().intakeReportsStore;
    const visitStore = useMainStoreContext().visitRecordStore;
    const [visitDate, setVisitDate] = React.useState('');
    const [isSaving, setIsSaving] = React.useState(false);
    const [progress, setProgress] = React.useState(0)
    const [showProgress, setShowProgress] = React.useState(false)
    // Add the loading state
    /*
const handleDateChange = (e) => {
    if(e!=null){
        const selectedDate = new Date(e); // Get the selected date
        selectedDate.setHours(0, 0, 0, 0); // Normalize the date to midnight to prevent timezone issues
    
        // Format the date as YYYY-MM-DD for storing it in the state
        const formattedDate = selectedDate.toISOString().split('T')[0];
        setVisitDate(formattedDate);
    }
};
*/
    React.useEffect(() => {
        resetStores();
        setPreviousTab(COMPLIANCE);
        setActiveTab(COMPLIANCE);
        if (visit && visit.id > 0) {
            setId(visit.id)
        } else {
            saveVisit();
        }
        loadVisit();
    }, []);

    React.useEffect(() => {
        if (isCurrentVisitLoaded) {
            if (currentVisit && currentVisit.id) {
                setVisit(currentVisit);
                visitImagesToComplianceImages();
                visitImagesToPatientImages();
            }
            else {
                setVisit({} as PatientVisit)
                setCurrentVisit({} as PatientVisit)
            }
            setVisitDate(_.isEmpty(currentVisit) ? moment().format('MM/DD/yyyy') : moment(currentVisit.createdOn).format('MM/DD/yyyy'));
            setVisitPatientId(patient.id);
            setHasLoaded(true);
        }
    }, [isCurrentVisitLoaded]);

    React.useEffect(() => {
        if (isCurrentVisitLoaded) {
            if (visit && previousTab !== activeTab) {
                //setVisit(currentVisit);
                visitImagesToComplianceImages();
                loadVisit();
                visitImagesToPatientImages();
            }
        }
    }, [activeTab]);


    React.useMemo(() => {
        if (isCurrentVisitLoaded) {
            setExtraoralImageTypes(GlobalUtils.getExtraOralImages());
            setIntraoralImageTypes(GlobalUtils.getIntraOralImages());
            setOcclusionImageTypes(GlobalUtils.getPatientOcclusionImageTypes());
            setMachineBrand3DType(GlobalUtils.get3DMachineBrands());
            setMolarRelationshipType(GlobalUtils.getMolarRelationships());
            setCbctMachineBrandType(GlobalUtils.getCBCTMachineBrands());
            setpatientImageType(GlobalUtils.getPatientImageTypes());
            setpatientOcclusionImageType(GlobalUtils.getPatientOcclusionImageTypes());
        }
    }, [isCurrentVisitLoaded]);

    const resetStores = () => {
        resetVisit();
        vitalsAndMeasurementsReset();
        setImages([]);
    }

    const handleVitalsSave = () => {
        if (isSaving) { return; }
        setIsSaving(true);
        setIsLoading(true);

        let vitals = getVitalsAndMeasurements();
        vitals["patientId"] = patient.id;

        setPatientVitalsAndMeasurements(vitals);

        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (resp) {
                    setCurrentVisit(resp);
                    setVisitId(currentVisit.id);
                }
            }).finally(() => {
                setIsSaving(false);
                setIsLoading(false);
            });
        } else {
            vitals.visitId = currentVisit.id;
            saveVitals().then((resp) => {
                if (resp) {
                    setVisitId(resp.id);
                }
            }).finally(() => {
                setIsSaving(false);
                setIsLoading(false);
            });
        }
    }

    const handleExtraoralImagesSave = () => {
        if (isSaving) return;

        setIsSaving(true);
        setIsLoading(true);

        const combinedImages = visitExtraoral2dimages
            .concat(visitExtraoral3dimages)
            .filter(image => image.data64String && image.data64String.trim() !== "");

        const saveImages = (visitId: number) => {
            Gateway.post(
                `/patient/${patient.id}/${visitId}/${userId}/addpatientimages`,
                combinedImages
            ).then(() => {
                setImages([]);
                loadVisit();
            }).finally(() => {
                setIsSaving(false);
            });
        };

        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (resp) {
                    const currentV = resp as unknown as PatientVisit;
                    setVisit(currentV);
                    setVisitId(currentV.id);
                    setCurrentVisit(currentV);

                    saveImages(currentV.id);
                }
            });
        } else if (currentVisit) {
            saveImages(currentVisit.id);
            deleteImages(currentVisit.id);
        }
    };

    const handleIntraloralImagesSave = () => {
        if (isSaving) return;

        setIsSaving(true);
        setIsLoading(true);

        const saveImages = (visitId: number) => {
            Gateway.post(
                `/patient/${patient.id}/${visitId}/${userId}/addpatientimages`,
                visitIntraoral2dimages.filter(image => image.data64String && image.data64String.trim() !== "")
            ).then(() => {
                setImages([]);
                loadVisit();
            }).finally(() => {
                setIsSaving(false);
            });
        };

        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (resp) {
                    const currentV = resp as unknown as PatientVisit;
                    setVisit(currentV);
                    setVisitId(currentV.id);
                    setCurrentVisit(currentV);

                    saveImages(currentV.id);
                }
            });
        } else if (currentVisit) {
            saveImages(currentVisit.id);
            deleteImages(currentVisit.id);
        }
    };

    const handleCbctSave = () => {
        if (isSaving) { return; }
        setIsSaving(true);
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    _.forEach(patientsImages, (img) => {
                        Gateway.post(`/patient/${patient.id}/${currentVisit.id}/${userId}/addpatientimage`, img).then(
                            () => {
                                setImages([]);
                                loadVisit();
                            });
                    })
                } else {
                    setIsSaving(false);
                }
            }).finally(() => setIsSaving(false));

        } else {
            setIsSaving(false);
            updateCbctDetails();
        }
    }

    const handleImpressionsSave = () => {
        if (isSaving) { return; }
        setIsSaving(true);
        setIsLoading(true);
        let promises: any = [];
        if (getVisitId() <= 0) {
            saveVisit().then((resp: any) => {
                if (currentVisit) {

                    let images = visitOcclusion2dimages.concat(visitOcclusion3dimages);
                    _.forEach(images, (img) => {
                        promises.push(Gateway.post(`/patient/${patient.id}/${currentVisit.id}/${userId}/addpatientimage`, img));
                    })
                }
                Promise.all(promises).then(() => {
                    occlusionStore.upsertOcclusionDetails(resp.id).then(() => {
                        setIsSaving(false);
                        setImages([]);
                        loadVisit();
                        setIsLoading(false);
                    })
                })
            })

        } else {
            occlusionStore.setVisitId(currentVisit.id);
            let images = visitOcclusion2dimages.concat(visitOcclusion3dimages);

            _.forEach(images, (img) => {
                promises.push(Gateway.post(`/patient/${patient.id}/${currentVisit.id}/${userId}/addpatientimage`, img));
            })
            Promise.all(promises).then(() => {
                occlusionStore.upsertOcclusionDetails(currentVisit.id).then(() => {
                    deleteImages(currentVisit.id).then(() => { setIsSaving(false); loadVisit(); setIsLoading(false); });
                })

            }).catch((error) => {
                console.error("Error posting images:", error);
                setIsSaving(false);
            });
        }
    }

    const handleDocumentsSave = () => {
        if (isSaving) { return; }
        setIsSaving(true);
        if (getVisitId() <= 0) {
            saveVisit().then((resp) => {
                if (currentVisit) {
                    _.forEach(patientUploadFiles, (doc) => {
                        Gateway.postStrongType<any>(`/patient/${patient.id}/${currentVisit.id}/${userId}/addpatientfile`, doc).then(
                            (resp) => {
                                setPatientUploadFiles([]);
                                var vPatientFiles = patientFiles;
                                vPatientFiles.push(resp as PatientFile);
                                setPatientFiles(vPatientFiles);
                                setDocumentFiles(vPatientFiles.filter(x => x.fileDescriptorId !== 191));
                                loadVisit();
                            });
                    })
                }
            }).finally(() => setIsSaving(false));;
        } else {
            _.forEach(patientUploadFiles, (doc) => {
                Gateway.postStrongType<any>(`/patient/${patient.id}/${currentVisit.id}/${userId}/addpatientfile`, doc).then(
                    (resp) => {
                        setPatientUploadFiles([]);
                        var vPatientFiles = patientFiles;
                        vPatientFiles.push(resp as PatientFile);
                        setPatientFiles(vPatientFiles);
                        setDocumentFiles(vPatientFiles.filter(x => x.fileDescriptorId !== 191));
                    }
                ).finally(() => setIsSaving(false));
            })
        }
    }

    const handleSleepTestSave = () => {
        setIsLoading(true);
        setIsSaving(true);
        if (getVisitId() <= 0) {
            var vReportFiles = intakeReportsStore.reportFiles;
            var vReportFileValues = intakeReportsStore.reportFileValues;
            saveVisit().then((resp: any) => {
                if (currentVisit) {
                    _.forEach(vReportFiles, (doc, index) => {
                        Gateway.postStrongType<any>(`/patient/${patient.id}/${resp.id}/${userId}/addpatientfile`, doc).then(
                            (respFile) => {
                                var reportInfo = {
                                    visitId: resp.id,
                                    reportValues: vReportFileValues[index],
                                    patientFileId: respFile.id,
                                    patientId: patient.id
                                }
                                Gateway.post(`/patient/reportinfo/sleepstudy/${userId}`, reportInfo).then(() => {
                                    var vPatientFiles = patientFiles;
                                    vPatientFiles.push(respFile as PatientFile);
                                    setPatientFiles(vPatientFiles);
                                    setPatientUploadFiles([]);
                                    loadVisit();
                                })
                            });
                    })
                }
            }).finally(async () => {
                await wrapLoadVisit();
                setIsSaving(false);
            });

        } else {
            _.forEach(intakeReportsStore.reportFiles, (doc, index) => {
                if (_.isEmpty(doc.data64String)) {
                    var reportInfo = {
                        visitId: currentVisit.id,
                        reportValues: intakeReportsStore.reportFileValues[index],
                        patientFileId: doc.id,
                        patientId: patient.id
                    }
                    Gateway.post(`/patient/reportinfo/sleepstudy/${userId}`, reportInfo).then(() => {
                        // setIsLoading(false);
                    }).finally(async () => {
                        await wrapLoadVisit();
                        setIsSaving(false);
                    });
                } else {
                    Gateway.postStrongType<any>(`/patient/${patient.id}/${currentVisit.id}/${userId}/addpatientfile`, doc).then(
                        (resp) => {
                            var reportInfo = {
                                visitId: currentVisit.id,
                                reportValues: intakeReportsStore.reportFileValues[index],
                                patientFileId: resp.id,
                                patientId: patient.id
                            }
                            Gateway.post(`/patient/reportinfo/sleepstudy/${userId}`, reportInfo).then(() => {
                                var vPatientFiles = patientFiles;
                                vPatientFiles.push(resp as PatientFile);
                                visitStore.setPatientFiles([...vPatientFiles]);
                                visitStore.setPatientUploadFiles([]);
                                visitStore.loadVisit();
                            })
                        }
                    ).finally(async () => {
                        await wrapLoadVisit();
                        setIsSaving(false);
                    });
                }
            })
        }
    }

    const handleComplianceSave = () => {
        if (isSaving) return;

        setIsLoading(true);
        setIsSaving(true);
        setCreatedOn(moment(visitDate).format('yyyy-MM-DDTHH:mm:ss.000'));

        const performSaveCompliance = () => {
            return saveCompliance(patientCompliances).then(() => {
                setPatientCompliances(patientCompliances);
            });
        };

        const saveProcess = currentVisit?.id && currentVisit.id > 0
            ? deleteComplianceImages(currentVisit.id).then(performSaveCompliance)
            : saveVisit().then((resp) => {
                if (resp) {
                    setCurrentVisit(resp);
                    setVisitId(currentVisit.id);
                    return performSaveCompliance();
                }
            });

        saveProcess
            .catch(error => {
                console.error("Error saving the compliance:", error);
            })
            .finally(() => {
                setIsSaving(false);
                setIsLoading(false);
            });
    };

    const handleTab = (newTab) => {
        setPreviousTab(activeTab);
        setActiveTab(newTab);
    }

    const wrapLoadVisit = () => {
        return new Promise<void>((resolve) => {
            const originalVisitLoadedState = visitStore.isCurrentVisitLoaded;

            // Check if `isCurrentVisitLoaded` finished
            const interval = setInterval(() => {
                if (visitStore.isCurrentVisitLoaded !== originalVisitLoadedState) {
                    clearInterval(interval);
                    resolve();
                }
            }, 50);

            loadVisit();
        });
    };

    const handleImagesChangeOcclusion = (images: PatientImage[]) => {
        visitStore.visitOcclusion3dimages = images;
    }

    return hasLoaded ? (
        <Grid container direction={"column"} xs={12} className="visitModalContainer" >
            <Grid xs={12}>
                <VisitHeader
                    tabs={tabs}
                    activeTab={activeTab}
                    onClick={handleTab}
                    onClose={onClose} />
            </Grid>
            <Grid xs={12} alignItems="center" textAlign='center'>
                <Typography variant="subtitle1">
                    Visit Date:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        className="DateBox"
                        openTo="day"
                        views={['year', 'month', 'day']}
                        value={moment(visitDate).format('MM/DD/yyyy')}

                        onChange={newValue => {
                            if (newValue != null) {
                                setVisitDate(moment(newValue).format('MM/DD/yyyy'));
                            }
                        }}

                        /*onChange={handleDateChange}*/
                        renderInput={params => (
                            <CssTextField
                                disabled={visit?.id == 0}
                                {...params}
                                sx={{
                                    '& input': {
                                        backgroundColor: 'white',
                                        color: '#050505',
                                        fontFamily: 'Proxima Nova',
                                        fontSize: '16px',
                                    },
                                }}
                                className="TextBox"
                                size="small"
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid xs={12} className="visitTabContainer">
                {activeTab === COMPLIANCE && <VisitTab onClick={handleComplianceSave}> <PatientCompliance patient={patient} visit={currentVisit} /></VisitTab>}
                {activeTab === VITALS && <VisitTab onClick={handleVitalsSave}> <PatientVitalsAndMeasurements visit={currentVisit} /></VisitTab>}
                {activeTab === EXTRAORAL && <VisitTab onClick={handleExtraoralImagesSave}> <ExtraoralImages isIntake={false} isVisit={true} /></VisitTab>}
                {activeTab === INTRAORAL && <VisitTab onClick={handleIntraloralImagesSave}> <IntraoralImages isIntake={false} isVisit={true} /></VisitTab>}
                {activeTab === CBCT && <VisitTab onClick={handleCbctSave}> <ScanCbct isIntake={false} visit={currentVisit} /></VisitTab>}
                {activeTab === IMPRESSIONS && <VisitTab onClick={handleImpressionsSave}> <Box paddingX={4}><ScanOcclusion vivosId={patient.vivosId} handleImagesChange={handleImagesChangeOcclusion} images={visitStore.visitOcclusion3dimages} isIntake={false} visit={currentVisit} isRework={false} isPrescription={false} /></Box></VisitTab>}
                {activeTab === SLEEPTEST && <VisitTab onClick={handleSleepTestSave}>  <SleepTest visit={currentVisit} /></VisitTab>}
                {activeTab === DOCUMENTS && <VisitTab onClick={handleDocumentsSave}> <Documents isIntake={false} visit={currentVisit} isPatientFormStep={false} patient={patient} /></VisitTab>}
                {activeTab === FORMRESPONSES && <VisitTab onClick={handleDocumentsSave}>
                    {/* <DigitalFormResponses isIntake={false} visit={currentVisit} isPatientFormStep={false} patient={patient} /> */}
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                        <Typography variant="h4" gutterBottom>
                            Coming Soon
                        </Typography>
                        <Typography variant="body1">
                            This feature is under development and will be available soon. Stay tuned!
                        </Typography>
                    </div>
                </VisitTab>}
            </Grid>
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    ) : (
        <></>
    );
});
