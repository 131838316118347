//My document variables

//Vivos Appliances
export const turningScrew = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/2023.10 Turning the Screw.mp4"
export const careOrderingRequirements = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/2024.4_FinalCAREApplianceRequirements.pdf"
export const careAdjustments = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/2024.03._WireBending CARE_Bennett.mp4"
export const carePredelivery = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/2022.7 CARE Predelivery Checklist.pdf"
export const careRxDesign = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/FINAL_C.A.R.E. Appliance Design 101 Reference Sheet 6.24.24.pdf"
export const applianceBrochure = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/General/FinalVivosAppliancesHandout_Web_7.29.24.pdf"
export const pexRemakePolicy = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/PEx/2023.10 PEx Remake Policy.pdf"
export const guideMeasurement = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Guides/2023.10 Measurement Guide for Guides.png"

//Instructions to Dentists (ITD)
export const carevidaSleepITD = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Instructions for Vivos Appliances/2023.07 - Care Appliance - ITD.pdf"
export const vidaITD = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Instructions for Vivos Appliances/2023.07 - Vida - ITD.pdf"
export const guidesITD = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Instructions for Vivos Appliances/2023.08 - Guides - ITD.pdf"
export const pexITD = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Instructions for Vivos Appliances/2023.11 Vivos PEx - ITD.pdf"

//Patient Instructions for Use (IFU)
export const careIFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/Digital - 01.2025 - DNA, mRNA, and mmRNA IFU - Rev 7.pdf"
export const vidaIFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/Digital File - Vida IFU - Rev 2.pdf"
export const vidaSleepIFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/Digital File - 11.2024 - Vida Sleep IFU - Rev 2 (1).pdf"
export const versaEmaIFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/2023.11 Versa Nylon-EMA IFU.pdf"
export const versa9IFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/2023.11 Vivos Versa 9 - IFU.pdf"
export const guidesIFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/Digital File - 11.2024 - IFU - Guides - Rev 7.pdf"
export const pexIFU = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Instructions for Vivos Appliances/09.2024 - PEx IFU - Rev 3 - Digital.pdf"

//Pricing Guides
export const vipPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_Vivos Providers Pricing Guide_Rev 11.pdf"
export const ggdPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_GGD Pricing Guide_Rev 4.pdf"
export const ahsPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_AHS Pricing Guide_Rev 5.pdf"
export const lifelinePricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_Lifeline Pricing Guide_Rev 5.pdf"
export const caPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_Clinical Advisor Pricing Guide_Rev 2.pdf"
export const swjPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_SWJ Pricing Guide_Rev 2.pdf"
export const careOnlyPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/FINAL_3.2025_CARE Only Pricing Guide_Rev 2.pdf"
export const odoPricingGuide = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Pricing and Billing/Pricing Guides/Final ODO Spark Pricing Sheet 1.2025.pdf"


//Record Taking
export const constructionBiteCare = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/2024.03_CARE ConstructionBites Bennett.mp4"
export const constructionBite101 = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/2022.1 - Construction bites Overview Video.mp4"
export const airRecommendedRecords = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Airway Intelligence Reports/AIR Requirements/FINAL_AI Report Recommended Records Checklist 1.25.pdf"

//MyoCorrect
export const myoPatientForm = "https://prodimagesvaire.blob.core.windows.net/sharedresources/MyoCorrect/MyoCorrect Patient Forms/2023.6 - MyoCorrect Patient Questionaire.pdf"
export const myoEnrollmentVideo = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/111 EnrollingMyosync Rev3.mp4"

//Consultations
export const clinicalConsultationRequest = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/104 ClinicalConsultations Rev2.mp4"
export const aisConsultationRequest = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/ScheduleAISConsultation Rev3.mp4"

//Navigating vCloud
export const orderRxDoc = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Documents/vCloud_How To Order an RX.pdf"
export const submitRepairDoc = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Documents/vCloud_How to Submit a Warranty Repair Claim.pdf"
export const submitRepairVideo = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/113 SubmitReworkClaim Rev1.mp4"
export const dashboardIntro = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/101 vCloud Intro Rev2.mp4"
export const txPlanTutorial = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/103 Creating Treatment Plan Rev1.mp4"
export const newPtCreation = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/Creating New Patient Tutorial_Rev1.mp4"
export const careRxOrder = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/106 CARERXOverview Rev2.mp4"
export const lifelineRxOrder = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/107 LifelineRXOverview Rev2.mp4"
export const editRxTutorial = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/112 Edit Add RX Rev1.mp4"
export const manageRxOrders = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/116 MangingRxOrders Rev2.mp4"
export const quickCreatePt = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Documents/How to Quick Create Patient.pdf"
export const toothchartingtutorial = "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/Tooth Charting Tutorial Video.mp4"