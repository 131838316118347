import jwt_decode from 'jwt-decode';

export class BaseMainStore {
    userID: number = 0;
    constructor() {
        var token = sessionStorage.getItem('token');
      if (token) {
        var tokenData: { roles; id } = jwt_decode(token);
        this.userID = tokenData.id;
      }
    }
}