import React from 'react';

const CurrencyFormatter = ({ amount }) => {
  // Convert the string to a number (assuming the string represents a valid number)
  const numberAmount = parseFloat(amount);

  // Format the number as a currency
  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', // Specify the currency (e.g., USD, EUR, GBP)
  }).format(numberAmount);

  return <span>{formattedAmount}</span>;
};

export default CurrencyFormatter;
