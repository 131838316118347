// Vendors
import {
	Grid,
	Typography,
	Link,
	Card,
	CardContent,
	CardActionArea,
	Button,
	styled,
	Box,
	alpha,
	Avatar,
	darken
} from "@mui/material";
// Components

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities

// API
import { observer } from "mobx-react";
import { MyoCorrectTherapyPlans, MyoCorrectTherapyPlanType } from "../../../entities/const";
import { useEffect } from "react";

const BoxBorderBottom = styled(Box)(
	() => `
          border-bottom: transparent 5px solid;
    `
);

const BoxComposed = styled(Box)(
	() => `
      position: relative;
    `
);

const BoxComposedContent = styled(Box)(
	({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const BgComposed = styled(Box)(
	({ theme }) => `
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: ${theme.transitions.create(['opacity'])};
      background: ${darken(alpha(theme.colors.primary.main, 0.9), 0.8)};
      z-index: 6;
      opacity: 0;
      box-shadow: inset 0 0 2.3rem 0.5rem ${darken(
		theme.colors.primary.main,
		0.9
	)};
    `
);

const Label = styled(Box)(
	({ theme }) => `
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 1.2)};
      border-radius: 50px;
    `
);

const StepTwo = () => {
	const { selectedPlan, setSelectedPlan, setStep, selectedPlanECom } = useMainStoreContext().myoCorrectEnrollmentStore;
	const { addVariantToCart, products } = useMainStoreContext().shopifyStore;
	useEffect(() => {
		if (!selectedPlanECom) {
			setSelectedPlan(MyoCorrectTherapyPlanType["MyoCorrect Therapy Plan"]);
		}
	}, []);
	const handleSelectPlan = (plan: number) => {

		setSelectedPlan(plan);
	}

	const handleNext = () => {
		setStep(2);
		var product = products.find(x => x.id.toString().includes(selectedPlanECom?.ecommerceItem.ecommerceId.toString()));
		if (product.variants && product.variants[0]) {
			var variantRecord = product.variants[0];

			addVariantToCart(variantRecord.id, 1);
		}
	}


	return (
		<Grid container>
			<Grid item xs={12} container justifyContent={'center'} padding={3}>
				<Card>
					<BoxComposed
						display="flex"
						alignItems="center"
						sx={(theme) => ({
							minHeight: '100%',
							background: theme.colors.gradients.blue3,
						})}
					>
						<BoxComposedContent px={8} pt={8} pb={4}>
							<Grid container>
								<Grid item xs={12} lg={6}>
									<Avatar
										variant="square"
										sx={{
											mx: 'auto',
											mb: 2,
											width: 'auto',
											height: 175,
											'& img': {
												objectFit: 'contain'
											}
										}}
										src="/logos/MyoCorrect.png"
									/>
								</Grid>
								<Grid item xs={12} lg={6} container>
									<Grid item xs={12}>
										<Typography lineHeight="1.5" fontWeight="normal" variant="h4">
											MyoSync, an interdisciplinary myofunctional therapy program provided through telemedicine
											by Vivos, is designed to integrate with various Vivos appliance therapy programs and caters to
											the majority of Vivos patients.
										</Typography>
									</Grid>
									<Grid item xs={12} paddingTop={2}>
										<Typography variant="h4" fontWeight="normal" >MyoFunctional therapy has been shown* to directly or indirectly help with the following:</Typography>
									</Grid>
									<Grid item xs={12} paddingX={3}>
										<Typography variant="h5">
											<ul>
												<li>Shortened treatment time in Vivos oral appliance therapy with higher satisfaction</li>
												<li>Nasal breathing as primary function</li>
												<li>Reducing Anxiety</li>
											</ul>
										</Typography>
									</Grid>
									<Grid item xs={12} container justifyContent={'flex-end'}>
										<Box maxWidth={300}>
											<Typography fontWeight="normal" fontSize={8}>
												* Felício, C. M., Melchior, M. D., and Marco Antonio Moreira Rodrigues Da Silva. (2010). “Effects of Orofacial Myofunctional Therapy on Temporomandibular Disorders.” MCranio®, 28(4), 249-259. doi:10.1179/crn.2010.033
											</Typography>
										</Box>
									</Grid>
								</Grid>
							</Grid>


						</BoxComposedContent>
					</BoxComposed>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h3" textAlign={'center'}>Select Myofunctional Therapy Plan</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={3} paddingX={3}>
							<Grid item xs={12} sm={4}>

								<Card
									sx={(theme) => ({
										textAlign: 'center',
										position: 'relative',
										transition: `${theme.transitions.create([
											'box-shadow',
											'transform'
										])}`,
										transform: 'translateY(0px)',

										'&:hover': {
											transform: `translateY(-${theme.spacing(0.5)})`,
											boxShadow: `0 2rem 8rem 0 ${alpha(
												theme.colors.alpha.black[100],
												0.05
											)}, 
											0 0.6rem 1.6rem ${alpha(
												theme.colors.alpha.black[100],
												0.15
											)}, 
											0 0.2rem 0.2rem ${alpha(
												theme.colors.alpha.black[100],
												0.1
											)}`,

											'& .MuiBgComposed': {
												opacity: 1
											}
										}
									})}
								>
									<BgComposed
										display="flex"
										flexDirection="column"
										alignItems="center"
										justifyContent="center"
										className="MuiBgComposed"
									>
										<Button variant="contained"
											color="info" onClick={() =>
												handleSelectPlan(MyoCorrectTherapyPlanType["MyoCorrect Therapy Plan"])
											}>Select Program</Button>
									</BgComposed>
									<Grid container>
										<Grid item xs={12}>
											<Box
												sx={(theme) => ({
													position: `relative`,
													left: `0`,
													top: `0`,
													height: `75px`,
													borderRadius: `${theme.general.borderRadius}`,
													background: `${theme.colors.gradients.black2}`
												})}>
												<BoxComposedContent py={2}>
													<Typography variant='h3'>
														MyoSync
													</Typography>
													{selectedPlan === MyoCorrectTherapyPlanType["MyoCorrect Therapy Plan"] && <Label
														sx={(theme) => ({
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center', height: 35,
															position: 'absolute', // Position label absolutely relative to its parent Box
															top: theme.spacing(1), // Adjust top spacing as needed
															right: theme.spacing(1),
															background: `${theme.palette.success.main}`,
															color: `${theme.palette.success.contrastText}`
														})}
													>
														<Typography fontSize={14} variant="h5" fontWeight={'normal'}>Selected</Typography>
													</Label>}
												</BoxComposedContent>
											</Box>
										</Grid>
										<Grid xs={12} padding={1} container>
											<Grid item xs={12} padding={2}>
												<Typography variant='h4' textAlign={'center'}>
													12 Sessions
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h5" textAlign={'left'}>
													<ul>
														<li>This program is meticulously crafted to address the patient's awareness, posture, nasal breathing, proper tongue placement, and lip closure.</li>
														<li>Incorporating Buteyko Breathing, the program aims to enhance nasal breathing. </li>
														<li>Ideal for both children and adults, starting from 5 years old, who exhibit minimal Orofacial Myofunctional Disorder (OMD) needs. It focuses on achieving correct tongue posture, mouth closure, and nasal breathing.</li>
														<li>Specifically tailored for individuals with mild Obstructive Sleep Apnea and Sleep Disordered Breathing. </li>
													</ul>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Card
									sx={(theme) => ({
										textAlign: 'center',
										position: 'relative',
										transition: `${theme.transitions.create([
											'box-shadow',
											'transform'
										])}`,
										transform: 'translateY(0px)',

										'&:hover': {
											transform: `translateY(-${theme.spacing(0.5)})`,
											boxShadow: `0 2rem 8rem 0 ${alpha(
												theme.colors.alpha.black[100],
												0.05
											)}, 
											0 0.6rem 1.6rem ${alpha(
												theme.colors.alpha.black[100],
												0.15
											)}, 
											0 0.2rem 0.2rem ${alpha(
												theme.colors.alpha.black[100],
												0.1
											)}`,

											'& .MuiBgComposed': {
												opacity: 1
											}
										}
									})}
								>
									<BgComposed
										display="flex"
										flexDirection="column"
										alignItems="center"
										justifyContent="center"
										className="MuiBgComposed"
									>
										<Button variant="contained"
											color="info" onClick={() => handleSelectPlan(MyoCorrectTherapyPlanType["MyoCorrect+ Therapy Plan"])}>Select Program</Button>
									</BgComposed>
									<Grid container>
										<Grid item xs={12}>
											<Box
												sx={(theme) => ({
													position: `relative`,
													left: `0`,
													top: `0`,
													height: `75px`,
													borderRadius: `${theme.general.borderRadius}`,
													background: `${theme.colors.gradients.black2}`
												})}>
												<BoxComposedContent py={2}>
													<Typography variant='h3'>
														MyoSync +
													</Typography>
													{selectedPlan === MyoCorrectTherapyPlanType["MyoCorrect+ Therapy Plan"] && <Label
														sx={(theme) => ({
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center', height: 35,
															position: 'absolute', // Position label absolutely relative to its parent Box
															top: theme.spacing(1), // Adjust top spacing as needed
															right: theme.spacing(1),
															background: `${theme.palette.success.main}`,
															color: `${theme.palette.success.contrastText}`
														})}
													>
														<Typography fontSize={14} variant="h5" fontWeight={'normal'}>Selected</Typography>
													</Label>}
												</BoxComposedContent>
											</Box>
										</Grid>
										<Grid xs={12} padding={1} container>
											<Grid item xs={12} padding={2}>
												<Typography variant='h4' textAlign={'center'}>
													16 Sessions
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h5" textAlign={'left'}>
													<ul>
														<li>The program is intricately crafted to address crucial aspects such as awareness, posture, nasal breathing, proper tongue placement, and lip closure for the patient.</li>
														<li>Including Buteyko Breathing, the program provides additional support for improving nasal breathing. </li>
														<li>Tailored for both children and adults, starting from the age of 5, who grapple with symptoms associated with Complex Orofacial Myofunctional Disorders (OMD).</li>
														<li>Symptoms encompass TMD, facial or jaw pain, open mouth posture, mouth breathing, tongue "thrusting" during swallowing or speaking, as well as patients requiring frenectomies for Tongue, Lip, and/or Buccal Ties. </li>
														<li>Suited for individuals experiencing Moderate to Severe Obstructive Sleep Apnea and Sleep Disordered Breathing. </li>
													</ul>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Card>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Card
									sx={(theme) => ({
										textAlign: 'center',
										position: 'relative',
										transition: `${theme.transitions.create([
											'box-shadow',
											'transform'
										])}`,
										transform: 'translateY(0px)',

										'&:hover': {
											transform: `translateY(-${theme.spacing(0.5)})`,
											boxShadow: `0 2rem 8rem 0 ${alpha(
												theme.colors.alpha.black[100],
												0.05
											)}, 
											0 0.6rem 1.6rem ${alpha(
												theme.colors.alpha.black[100],
												0.15
											)}, 
											0 0.2rem 0.2rem ${alpha(
												theme.colors.alpha.black[100],
												0.1
											)}`,

											'& .MuiBgComposed': {
												opacity: 1
											}
										}
									})}
								>
									<BgComposed
										display="flex"
										flexDirection="column"
										alignItems="center"
										justifyContent="center"
										className="MuiBgComposed"
									>
										<Button variant="contained"
											color="info" onClick={() => setSelectedPlan(MyoCorrectTherapyPlanType["Myofunctional Therapy Additional Session"])}>Select Program</Button>
									</BgComposed>
									<Grid container>
										<Grid item xs={12}>
											<Box
												sx={(theme) => ({
													position: `relative`,
													left: `0`,
													top: `0`,
													height: `75px`,
													borderRadius: `${theme.general.borderRadius}`,
													background: `${theme.colors.gradients.black2}`
												})}>
												<BoxComposedContent py={2}>
													<Typography variant='h3'>
														Tongue Release Program
													</Typography>
													{selectedPlan === MyoCorrectTherapyPlanType["Myofunctional Therapy Additional Session"] && <Label
														sx={(theme) => ({
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center', height: 35,
															position: 'absolute',
															top: theme.spacing(1),
															right: theme.spacing(1),
															background: `${theme.palette.success.main}`,
															color: `${theme.palette.success.contrastText}`
														})}
													>
														<Typography fontSize={14} variant="h5" fontWeight={'normal'}>Selected</Typography>
													</Label>}
												</BoxComposedContent>
											</Box>
										</Grid>
										<Grid xs={12} padding={1} container>
											<Grid item xs={12} padding={2}>
												<Typography variant='h4' textAlign={'center'}>
													8 Sessions
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography variant="h5" textAlign={'left'}>
													<ul>
														<li>Emphasizing pre and post-stretching exercises, along with post-healing practices.</li>
														<li>Tailored for individuals experiencing severe tongue and/or lip ties, providing an effective pathway for a swift transition into speech therapy and/or appliance therapy.</li>
													</ul>
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Card>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} paddingRight={5}>
						<Typography variant="h4" textAlign={'right'}>By enrolling your patient into MyoSync, you also agree to the <Link href="https://prodimagesvaire.blob.core.windows.net/sharedresources/Business%20Documents/MyoSync%20Terms%20and%20Conditions%202%20(1).pdf" target="_blank">Terms and Conditions</Link></Typography>
					</Grid>
				</Grid>
			</Grid>
			<BoxBorderBottom
				padding={1}
				sx={(theme) => ({
					borderBottomColor: theme.palette.primary.main,
					width: '100%',
					backgroundColor: theme.colors.alpha.black[5],
					position: 'absolute',
					bottom: '0',
				})}>
				<Grid container justifyContent={'space-between'}>
					<Grid item>
						<Button onClick={() => setStep(0)} variant="contained" color="inherit" >Back</Button>
					</Grid>
					<Grid item>
						<Button 
							onClick={() => handleNext()} 
							variant="contained" 
							color="primary" 
							disabled={!selectedPlanECom}
						>
							Save and Continue
						</Button>
					</Grid>
				</Grid>
			</BoxBorderBottom>
		</Grid>
	)
}
export default observer(StepTwo);