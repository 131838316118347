// Vendors
import React, { useEffect, useState } from 'react';
import {
    Box,
    Checkbox,
    Grid,
    Typography,
    alpha,
    Divider,
    Button,
    TableCell,
    TableBody,
    Table,
    TableContainer,
    styled,
    Card,
    TableRow,
} from '@mui/material';

// Components
import { VisitRecordSectionModule } from '../../modals/VisitRecords/components/VisitRecordSelectionModule';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { AIReportType } from '../../entities/Enums';
import { useCreateAIReportMainStore } from '../../stores/AiReport/CreateAIReportMainStore';


const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const BoxComposedBg = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const LabelSuccess = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

const BoxOverlineButton = styled(Box)(
    ({ theme }) => `
      text-align: center;
      position: relative;
      z-index: 7;
  
      .MuiButton-root {
          border-radius: 50px;
          height: ${theme.spacing(7.5)};
          margin-top: -${theme.spacing(3.75)};
          padding: ${theme.spacing(0, 4)};
          border: 3px solid ${theme.colors.alpha.white[100]};
      }
    `
);

const CardWrapper = styled(Card)(
    ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[10], 0.05)};
    `
);

interface IGIProps {
    visits?: any;
    reportsOrdered: number;
}

export const AiReportAddons: React.FC<IGIProps> = observer(({ visits, reportsOrdered }) => {

    const { reportTypeId, addOns, availableAddOns, aiReportSetup, setAiReportSetupSelected, setAddOns, setReportTypeId, setVisits } = useCreateAIReportMainStore().aiReportOrder;

    const handleAddons = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = Number(event.target.id);
        const isChecked = event.target.checked;
        const selectedAddOn = availableAddOns.find(addOn => addOn.id === id);

        if (selectedAddOn) {
            if (isChecked) {
                setAddOns([...addOns, selectedAddOn]);
            } else {
                setAddOns(addOns.filter(addOn => addOn.id !== id));
            }
        }
    };

    const handleReportType = (event) => {
        switch (event.target.name) {
            case AIReportType[AIReportType.Standard]:
                setReportTypeId(event.target.checked ? AIReportType.Standard : 0);
                let selectedReport1 = aiReportSetup.find(report => report.reportType === AIReportType.Standard);
                if (selectedReport1) {
                    setAiReportSetupSelected(selectedReport1);
                }

                break;
            case AIReportType[AIReportType.Comparison]:
                setReportTypeId(event.target.checked ? AIReportType.Comparison : 0);
                const selectedReport2 = aiReportSetup.find(report => report.reportType === AIReportType.Comparison);
                if (selectedReport2) {
                    setAiReportSetupSelected(selectedReport2);
                }
                break;
            default:
                setReportTypeId(AIReportType['Free Standard']);
                const selectedReport3 = aiReportSetup.find(report => report.reportType === AIReportType['Free Standard']);
                if (selectedReport3) {
                    setAiReportSetupSelected(selectedReport3);
                }
                break;
        }
        setVisits([]);
    };

    useEffect(() => {
        handleReportType({ target: { name: AIReportType[AIReportType.Standard], checked: true } });
    }, []);

    const handleSelectVisit = (visit) => {
        setVisits(Array.isArray(visit) ? visit : [visit]);
    }

    return (
        <Grid container padding={1}>
            <Card>
                <BoxComposed
                    sx={(theme) => ({
                        background: `${theme.colors.gradients.black2}`
                    })}
                >

                    <BoxComposedBg
                        sx={(theme) => ({
                            opacity: 0.1,
                            background: `${theme.colors.gradients.black1}`
                        })}
                    />
                    <BoxComposedContent pb={9} pt={7}>
                        <Typography
                            textAlign="center"
                            sx={{
                                pb: 1
                            }}
                            variant="h3"
                        >
                            <img src={"/logos/AIReport_white.png"} alt="AIReport_white Logo" style={{ height: '75px' }} />
                        </Typography>
                        <Grid container>
                            <Grid item paddingBottom={3} xs={12}>
                                <Typography textAlign="center" fontWeight="normal" variant="h4">
                                    Our team of Airway Intelligence Service Techinicians and Specialist are on standby waiting to complete and assist you with your report!
                                </Typography>
                            </Grid>
                            <Grid item xs={12} paddingBottom={2}>
                                <Typography fontWeight="normal" variant="h4" textAlign={'center'}>
                                    What is included with your first AI Report purchase?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container direction={'column'}>
                                <Typography variant="h4" textAlign={'center'} fontStyle={'italic'}>
                                    Patient specific AIS support
                                </Typography>
                                <Typography variant="h4" textAlign={'center'} fontStyle={'italic'}>
                                    Unlimited AIS Specialist Consultations for 1 year
                                </Typography>
                                <Typography variant="h4" textAlign={'center'} fontStyle={'italic'}>
                                    Unlimited standard and comparison custom Airway Intelligence Reports for 3 years
                                </Typography>
                            </Grid>
                        </Grid>
                    </BoxComposedContent>
                </BoxComposed>
                <BoxOverlineButton>
                    <Button variant="contained" size="large" color="success">
                        Let's Get Started
                    </Button>
                </BoxOverlineButton>
                <Box p={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={4}>
                            <Box height={'250px'} paddingBottom={1}>
                                <CardWrapper
                                    elevation={0}
                                    sx={{
                                        textAlign: 'center',
                                        pb: 2.5
                                    }}
                                >
                                    <Grid container>
                                        <Grid xs={12}>
                                            <Box
                                                sx={(theme) => ({
                                                    position: `relative`,
                                                    left: `0`,
                                                    top: `0`,
                                                    height: `75px`,
                                                    borderRadius: `${theme.general.borderRadius}`,
                                                    background: `${theme.colors.gradients.black2}`
                                                })}>
                                                <BoxComposedContent py={2}>
                                                    <Typography variant='h3'>
                                                        Select Airway Intelligence Report Type
                                                    </Typography>
                                                </BoxComposedContent>
                                            </Box>
                                        </Grid>
                                        <Grid xs={12} padding={1}>
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow hover>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    sx={{
                                                                        padding: 0.5
                                                                    }}
                                                                    checked={reportTypeId === AIReportType.Standard || reportTypeId === AIReportType['Free Standard']}
                                                                    onClick={handleReportType}
                                                                    name={AIReportType[AIReportType.Standard]}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    pl: 0
                                                                }}
                                                            >
                                                                <Box pb={0.5} display="flex" alignItems="center">
                                                                    <Typography
                                                                        sx={{
                                                                            pl: 0.5
                                                                        }}
                                                                        variant="h4"
                                                                        noWrap
                                                                    >
                                                                        {AIReportType[AIReportType.Standard]}
                                                                    </Typography>
                                                                </Box>
                                                            </TableCell>
                                                            {/* <TableCell align="right">
                                                                <Typography variant="h4">$149.00</Typography>
                                                            </TableCell> */}
                                                        </TableRow>
                                                        {reportsOrdered > 0 &&
                                                            <TableRow hover>
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        sx={{
                                                                            padding: 0.5
                                                                        }}
                                                                        checked={reportTypeId === AIReportType.Comparison}
                                                                        onClick={handleReportType}
                                                                        name={AIReportType[AIReportType.Comparison]}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        pl: 0
                                                                    }}
                                                                >
                                                                    <Box pb={0.5} display="flex" alignItems="center">
                                                                        <Typography
                                                                            sx={{
                                                                                pl: 0.5
                                                                            }}
                                                                            variant="h4"
                                                                            noWrap
                                                                        >
                                                                            {AIReportType[AIReportType.Comparison]}
                                                                        </Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                {/* <TableCell align="right">
                                                                <Typography variant="h4">$0.00</Typography>
                                                            </TableCell> */}
                                                            </TableRow>
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </CardWrapper>
                            </Box>
                            <VisitRecordSectionModule visits={visits} rx={false} singleSelect={reportTypeId === AIReportType.Comparison ? false : true} onSelectVisit={handleSelectVisit} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardWrapper
                                elevation={0}
                                sx={{
                                    textAlign: 'center',
                                    pb: 2.5
                                }}
                            >
                                <Grid container>
                                    <Grid xs={12}>
                                        <Box
                                            sx={(theme) => ({
                                                position: `relative`,
                                                left: `0`,
                                                top: `0`,
                                                height: `75px`,
                                                borderRadius: `${theme.general.borderRadius}`,
                                                background: `${theme.colors.gradients.black2}`
                                            })}>
                                            <BoxComposedContent py={2}>
                                                <Typography variant='h3'>
                                                    Customize Report
                                                </Typography>
                                            </BoxComposedContent>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} padding={1}>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {availableAddOns.filter(x => x.dictionaryList && x.dictionaryList.value == "" && x.dictionaryList.isActive).map((t, key: number) => {
                                                        return (<TableRow hover key={t.id}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={addOns.some(x => x.id === t.id)}
                                                                    onChange={handleAddons} name={t.name} id={t.id.toString()}
                                                                    sx={{
                                                                        padding: 0.5
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    pl: 0
                                                                }}
                                                            >
                                                                <Box pb={0.5} display="flex" alignItems="center">
                                                                    <Grid container justifyContent={'space-between'} alignContent={'center'}><Typography
                                                                        sx={{
                                                                            pl: 0.5
                                                                        }}
                                                                        variant="h4"
                                                                        noWrap
                                                                    >
                                                                        {t.name}
                                                                    </Typography>
                                                                        {t.name.includes("3D Printed") &&

                                                                            <Typography variant='caption'>Additional Cost</Typography>
                                                                        }
                                                                    </Grid>
                                                                </Box>
                                                                {t.name.includes("Radiology") &&
                                                                    <Grid container justifyContent={'space-between'}>
                                                                        <LabelSuccess>Recommended</LabelSuccess>
                                                                        <Typography variant='caption'>Additional Cost</Typography>
                                                                    </Grid>
                                                                }

                                                            </TableCell>
                                                        </TableRow>);
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardWrapper>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CardWrapper
                                elevation={0}
                                sx={{
                                    textAlign: 'center',
                                    pb: 2.5
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={(theme) => ({
                                                position: `relative`,
                                                left: `0`,
                                                top: `0`,
                                                height: `75px`,
                                                borderRadius: `${theme.general.borderRadius}`,
                                                background: `${theme.colors.gradients.black2}`
                                            })}>
                                            <BoxComposedContent py={2}>
                                                <Typography variant='h3'>
                                                    Medical Billing Radiographic Images
                                                </Typography>
                                            </BoxComposedContent>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} padding={1}>
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {availableAddOns.filter(x => x.dictionaryList && x.dictionaryList.value !== "" && x.dictionaryList.isActive).map((t, key: number) => {
                                                        return (<TableRow hover key={t.id}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={addOns.some(x => x.id === t.id)}
                                                                    onChange={handleAddons} name={t.name} id={t.id.toString()}
                                                                    sx={{
                                                                        padding: 0.5
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    pl: 0
                                                                }}
                                                            >
                                                                <Box pb={0.5} display="flex" alignItems="center">
                                                                    <Typography
                                                                        sx={{
                                                                            pl: 0.5
                                                                        }}
                                                                        variant="h4"
                                                                        noWrap
                                                                    >
                                                                        {t.name}
                                                                    </Typography>
                                                                </Box>

                                                            </TableCell>
                                                            {/* <TableCell align="right">
                                                            <Typography variant="h4">$0.00</Typography>
                                                        </TableCell> */}
                                                        </TableRow>);
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </CardWrapper>
                        </Grid>
                    </Grid>
                </Box >
                <Divider />

            </Card >
        </Grid >
        // <Grid container paddingLeft={3} paddingRight={3} paddingTop={3} sx={{ border: '1px solid #ccc', }}>
        //     <Grid xs={12}>
        //         <Typography variant='h3'>Select Report Type</Typography>
        //     </Grid>

        // </Grid>
    );
});
