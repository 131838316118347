import { observable } from 'mobx';

export class OrderDetail {
    @observable id: number = 0;
    @observable orderId: number = 0;
    @observable itemId: number | null = null;
    @observable quantity: number | null = null;
    @observable unitPrice: number | null = null;
    @observable unitCost: number | null = null;
    @observable unitPriceOverride: boolean | null = null;
    @observable migrationId: number | null = null;
    @observable externalEcommerceItemId: string = '';
    @observable externalEcommerceTitle: string = '';
    @observable isActive: boolean | null = null;
    @observable createdOn: Date = new Date();
    @observable createdBy: number = 0;
    @observable modifiedOn: Date | null = null;
    @observable modifiedBy: number | null = null;
}
