// Vendors
import { Fragment, useState } from "react";
import { Button, Grid, Box } from "@mui/material";
import PendingActionsTwoToneIcon from "@mui/icons-material/PendingActionsTwoTone";
import PendingTwoToneIcon from "@mui/icons-material/PendingTwoTone";

// Components
import { LabCaseReview } from "../../components/LabCaseReview/LabCaseReview";
import { DashboardInfoBox } from "../../components/DashboardInfoBox/DashboardInfoBox";
import DataGrid from "../../components/DataGrid/DataGrid";
import { LeftNav } from "../../components/LeftNav/LeftNav";
import ModalContainer from "../../components/ModalContainer";
import { MyoCorrectGridHeader } from "../../components/MyoCorrectGridHeader";
import PageTitleWrapper from "../../components/UI/PageTitleWrapper";
import PageHeader from "./components/PageHeader";
import DashboardInfoCard from "../../components/UI/Cards/DashboardInfoCard";


// Stores
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// Entities

// API
import { observer } from "mobx-react";

export const LaboratoryComponent = () => {
    const {
        getSearchData,
        clearData,
        toggleFilterResultsExpand,
        isLeftNavExpanded,
        pageTitle,
        userId
    } = useMainStoreContext().laboratoryStore;

    const {
        triggerShowClaimModal,
        setTriggerShowClaimModal
    } = useMainStoreContext().labCaseReviewStore;

    const numResults = 0;
    const infoBoxes: any = [];
    const [selectedRow, setSelectedRow] = useState<any>({});

    infoBoxes.push(<DashboardInfoCard key={"dib1"} cardLabelText={"Cases to QC"} filter={0} cardNumberValue={12} icon={<PendingActionsTwoToneIcon />} currentFilter={0} onFilter={undefined} />)
    infoBoxes.push(<DashboardInfoCard key={"dib2"} cardLabelText={"Bites on Hold"} filter={0} cardNumberValue={4} icon={<PendingTwoToneIcon />} currentFilter={0} onFilter={undefined} />)
    infoBoxes.push(<DashboardInfoCard key={"dib3"} cardLabelText={"Models on Hold"} filter={0} cardNumberValue={5} icon={<PendingTwoToneIcon />} currentFilter={0} onFilter={undefined} />)
    infoBoxes.push(<DashboardInfoCard key={"dib4"} cardLabelText={"Exceeding 7 Days in Queue"} filter={0} cardNumberValue={2} icon={<PendingTwoToneIcon />} currentFilter={0} onFilter={undefined} />)
    infoBoxes.push(<DashboardInfoCard key={"dib5"} cardLabelText={"All"} filter={0} cardNumberValue={855} icon={<PendingTwoToneIcon />} currentFilter={0} onFilter={undefined} />)

    const columns = [
        {
            field: 'dateSubmitted',
            headerName: 'Date Submitted',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'daysInQueue',
            headerName: 'Days in Queue',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'rxId',
            headerName: 'RX ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'aireport.dashboard.cell.toggleAIReportModel.' + 1}
                                variant="text"
                                onClick={() => {
                                    setSelectedRow(mockData[0]);
                                    setTriggerShowClaimModal(true);
                                }}
                            >
                                {mockData[0].rxId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'provider',
            headerName: 'Provider',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patient',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'lab',
            headerName: 'Lab',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'qcStatus',
            headerName: 'QC Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'biteSrc',
            headerName: 'Bite Src',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'biteStatus',
            headerName: 'Bite Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'modelSrc',
            headerName: 'Model Src',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'modelStatus',
            headerName: 'Model Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'vivosId13',
            headerName: 'Notes',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        }
    ]

    const mockData = [{
        id: 1,
        dateSubmitted: '2022-11-11',
        daysInQueue: 7,
        rxId: 'VIV-23421-01',
        provider: 'Tara Friffin',
        patient: 'Jane Doe',
        lab: 'Apex',
        appliance: 'mRNA',
        qcStatus: 'Pending',
        biteSrc: 'Dig',
        biteStatus: 'Pending',
        modelSrc: 'Dig',
        modelStatus: 'Pending',
        providerComment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
    }];

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader
                    onChange={getSearchData}
                    onClear={clearData}
                    onCancel={clearData}
                    onFilter={() => { }}
                />
            </PageTitleWrapper>
            <Grid container>
                <Grid item xs={12} container spacing={2} paddingX={2} paddingBottom={1}>
                    {infoBoxes.map((box, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={12 / 5} key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'  // This ensures the flex items (cards) are stacked vertically
                            }}>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>  {/* This Box wraps the card and allows it to grow */}
                                {box}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid>
                <DataGrid columns={columns} loading={false} data={mockData} />
            </Grid>
            {/* <Grid xs={12} item container direction="row" className="page">
                <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')}>
                    <Grid container direction="row" sx={{ marginBottom: '15px;' }} justifyContent={'space-between'}>
                        {infoBoxes}
                    </Grid>
                    <Grid className="grid" id={'aireporttech.dashboard' + userId}>
                        <DataGrid columns={columns} loading={false} data={mockData} />
                    </Grid>
                </Grid>
            </Grid> */}
            <ModalContainer
                noScroll={true}
                show={triggerShowClaimModal}
                title={"Case Review"}
                onClick={() => { }}
                onClose={() => {
                    setTriggerShowClaimModal(false);
                }}>
                <LabCaseReview labCase={selectedRow} />
            </ModalContainer>
        </Fragment>
    )
};
export const Laboratory = observer(LaboratoryComponent);