// Vendors
import React, { useEffect } from "react";
import {
    CircularProgress,
    Grid,
    Button,
    SelectChangeEvent,
    TextField,
    IconButton,
    MenuItem,
    styled,
    Tooltip,
    Box,
    CardMedia
} from '@mui/material';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import RotateRightTwoToneIcon from "@mui/icons-material/RotateRightTwoTone"
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";

// API
import { observer } from "mobx-react";
import Cropper from "react-cropper";
import _, { set } from "lodash";
import { GlobalUtils } from "../../api/GlobalUtils";
import { AIReportProcessedImagesTypes } from "../../entities/Enums";


const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
);

const MAXWIDTH = 430;
const MAXHEIGHT = 250;
const MAXCROPPERWIDTH = 100;
const MAXCROPPERHEIGHT = 100;
interface IUploaderProps {
    index: number;
    cropperIndex: number;
    images: any[];
    typeOptions: any[];
    selectedOptions: number[];
    imageOption?: number;
    vivosId: string;
    container: string,
    containerId: number;
    parentContainerId?: number;
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;
    handleOptionSelect(event: any, oldOption: number, index: number, isRemoval: boolean): any;
    handleRemove(image: any, itemOption: number): any;
    setImages(images: any[]): void;
}

const UploadRotateCropper: React.FC<IUploaderProps> =
    ({ images, cropperIndex, index, typeOptions, selectedOptions, imageOption, vivosId, container,
        containerId, parentContainerId, parentCallback, handleRemove, handleOptionSelect, setImages }) => {
        const [cropData, setCropData] = React.useState("#");
        const [cropper, setCropper] = React.useState<any>();
        const [options, setOptions] = React.useState<any[]>([]);
        const [selectedOptionsList, setSelectedOptionsList] = React.useState<any[]>([]);
        const [option, setOption] = React.useState<number>(-1);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [imageLoading, setImageLoading] = React.useState(true);
        const [failedLoad, setFailedLoad] = React.useState(false);
        const [isEditing, setIsEditing] = React.useState(false);

        useEffect(() => {
            if (typeOptions) {
                setOptions(typeOptions);
                setHasLoaded(true);
            }
            if (selectedOptions) {
                setSelectedOptionsList(selectedOptions);
            }
            setOption(imageOption ? imageOption : (images[cropperIndex] && images[cropperIndex].imageType ? images[cropperIndex].imageType.id : -1));
        }, [])

        useEffect(() => {
            setOption(imageOption ? imageOption : (images[cropperIndex] && images[cropperIndex].imageType ? images[cropperIndex].imageType.id : -1));
        }, [imageOption])


        useEffect(() => {
            setOptions(typeOptions);
            setSelectedOptionsList(selectedOptions);
        }, [index, typeOptions, selectedOptions])

        const handleEditClick = () => {
            setIsEditing(!isEditing);
        };

        const handleCropChange = () => {
            const croppedImgData = cropper.getCroppedCanvas().toDataURL();
            setCropData(croppedImgData);
            images[cropperIndex]["edited"] = true;
            images[cropperIndex].data64String = croppedImgData;
            setImages(images);
        }

        const handleRotate = () => {
            if (typeof cropper !== "undefined") {
                cropper.rotate(90);
                handleCropChange();
            }
        }
        const handleCropEnd = (event) => {
            const croppedImgData = event.target.cropper.getCroppedCanvas().toDataURL()
            setCropData(croppedImgData);
            images[cropperIndex]["edited"] = true;
            images[cropperIndex].data64String = croppedImgData;
            setImages(images);
        }

        const generateOptions = (list: any[], selectedList: number[]) => {
            var newList = _.map(list, (option) => {
                var index = _.findIndex(selectedList, (l) => { return option.id === l })
                return (
                    <MenuItem disabled={index >= 0 && selectedList[index] !== 791} value={option.id}>{option.name}</MenuItem>)
            })
            var arr = newList.slice();
            arr.unshift(<MenuItem value={-1}>{"Select a type..."}</MenuItem>);
            newList = arr;
            setOptions(newList);
        }

        const handleReady = () => {
            parentCallback("", "", 0);
            setImageLoading(false);
        }

        const getSource = () => {
            var url = "";
            switch (container) {
                case process.env.REACT_APP_AIREPORTS_CONTAINER:
                    url = GlobalUtils.getAIReportUrl(images[cropperIndex].data64Url, vivosId, containerId);
                    break;
                case process.env.REACT_APP_REWORKCLAIMS_CONTAINER:
                    url = GlobalUtils.getClaimDocumentUrl(images[cropperIndex].data64Url, vivosId, parentContainerId, containerId);
                    break;
                default:
                    url = GlobalUtils.getVisitRecordUrl(images[cropperIndex].data64Url, vivosId, containerId);
                    break;
            }
            /*return container == process.env.REACT_APP_AIREPORTS_CONTAINER ?
                GlobalUtils.getAIReportUrl(images[cropperIndex].data64Url, vivosId, containerId) :
                GlobalUtils.getVisitRecordUrl(images[cropperIndex].data64Url, vivosId, containerId);
            */
            return url;
        }
        const handleImageError = () => {
            setImageLoading(false);
            setFailedLoad(true);
        };

        return (
            hasLoaded ?
                <Grid sx={{ height: '250px' }} key={index} container>
                    {imageLoading &&
                        <Grid style={{
                            // do your styles depending on your needs.
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            zIndex: "30",
                            position: "relative",
                            top: "150px",
                            background: 1
                        }}>
                            {
                                <Grid container direction="row" justifyContent="center">
                                    <Grid container justifyContent="center"><Grid>Image Loading...</Grid></Grid>
                                    <CircularProgress sx={{
                                        width: "90%",
                                        height: "90%"
                                    }} />
                                </Grid>
                            }
                        </Grid>
                    }

                    <Grid item xs={12} sx={{
                        visibility: !imageLoading ? "visible" : "hidden",
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        {failedLoad ? (
                            <p style={{ justifySelf: 'center', alignSelf: 'center', display: 'flex', flexGrow: '1' }}>Image failed to load.</p>
                        ) : isEditing ? (
                            <Cropper
                                style={{ height: MAXHEIGHT, width: '100%', maxHeight: MAXHEIGHT, maxWidth: MAXWIDTH }}
                                className={"shadow  crop"}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                checkOrientation={true}
                                autoCropArea={1}
                                ready={handleReady}
                                viewMode={0}
                                src={getSource()}
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                cropend={handleCropEnd}
                                guides={true}
                                onInvalid={handleImageError}
                                onLoadedData={handleImageError}
                                onError={handleImageError}
                            />) :
                            <CardMedia
                                style={{ height: MAXHEIGHT, width: '100%', maxHeight: MAXHEIGHT, maxWidth: MAXWIDTH }}
                                component={'img'}
                                className={"shadow"}
                                src={getSource()}
                                onLoad={handleReady}
                                onInvalid={handleImageError}
                                onLoadedData={handleImageError}
                                onError={handleImageError}
                                sx={{ objectFit: 'contain' }}
                            />
                        }
                    </Grid>

                    {(!imageLoading && !failedLoad && typeOptions) &&
                        <Grid container paddingTop={2} justifyContent={'center'}>
                            {typeOptions.length > 0 && <Box width="300px">
                                <TextField
                                    label="Image Type"
                                    fullWidth
                                    select
                                    onChange={(event: any) => {
                                        handleOptionSelect(event, option, cropperIndex, false);
                                        //var opts = handleOptionSelect(event, option, cropperIndex, false);
                                        //generateOptions(typeOptions, opts);
                                        setOption(Number(event.target.value));
                                    }}
                                    value={option}
                                    error={option === -1}
                                >
                                    <MenuItem value={-1}>{"Select a type..."}</MenuItem>
                                    {options.map(mapOption => {
                                        var isSetOption = selectedOptionsList.find(x => x === mapOption.id);
                                        var setTrueSelected = isSetOption !== undefined && mapOption.id !== option && mapOption.id !== AIReportProcessedImagesTypes["Billing Radiographic Image"];
                                        return (
                                            <MenuItem disabled={setTrueSelected} value={mapOption.id}>{mapOption.name}</MenuItem>)
                                    })}
                                </TextField>
                            </Box>}

                            {isEditing && typeOptions.length > 0 &&
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={'Save'} arrow>
                                        <IconButtonWrapper
                                            style={{ alignSelf: "center" }}
                                            sx={(theme) => ({
                                                ml: 1,
                                                backgroundColor: theme.colors.success.lighter,
                                                color: theme.colors.success.main,
                                                transition: theme.transitions.create(['all']),

                                                '&:hover': {
                                                    backgroundColor: theme.colors.success.main,
                                                    color: theme.palette.getContrastText(
                                                        theme.colors.success.main
                                                    )
                                                }
                                            })
                                            }
                                            onClick={handleEditClick}
                                        >
                                            <SaveTwoToneIcon fontSize="small" />
                                        </IconButtonWrapper>
                                    </Tooltip>
                                    <Tooltip title={'Rotate'} arrow>
                                        <IconButtonWrapper
                                            style={{ alignSelf: "center" }}
                                            sx={(theme) => ({
                                                ml: 1,
                                                backgroundColor: theme.colors.primary.lighter,
                                                color: theme.colors.primary.main,
                                                transition: theme.transitions.create(['all']),

                                                '&:hover': {
                                                    backgroundColor: theme.colors.primary.main,
                                                    color: theme.palette.getContrastText(
                                                        theme.colors.primary.main
                                                    )
                                                }
                                            })
                                            }
                                            onClick={handleRotate}
                                        >
                                            <RotateRightTwoToneIcon fontSize="small" />
                                        </IconButtonWrapper>
                                    </Tooltip>
                                    <Tooltip title={'Delete'} arrow>
                                        <IconButtonWrapper
                                            style={{
                                                alignSelf: "center",
                                            }}
                                            sx={(theme) => ({
                                                ml: 1,
                                                backgroundColor: theme.colors.error.lighter,
                                                color: theme.colors.error.main,
                                                transition: theme.transitions.create(['all']),

                                                '&:hover': {
                                                    backgroundColor: theme.colors.error.main,
                                                    color: theme.palette.getContrastText(
                                                        theme.colors.error.main
                                                    )
                                                }
                                            })
                                            }
                                            onClick={(event: any) => {
                                                handleRemove(images[cropperIndex], option);
                                            }}
                                        >
                                            <DeleteTwoToneIcon fontSize="small" />
                                        </IconButtonWrapper>
                                    </Tooltip>
                                </Box>
                            }
                            {!isEditing &&
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Tooltip title={'Edit'} arrow>
                                        <IconButtonWrapper
                                            style={{ alignSelf: "center", display: 'none' }}
                                            sx={(theme) => ({
                                                ml: 1,
                                                backgroundColor: theme.colors.primary.lighter,
                                                color: theme.colors.primary.main,
                                                transition: theme.transitions.create(['all']),

                                                '&:hover': {
                                                    backgroundColor: theme.colors.primary.main,
                                                    color: theme.palette.getContrastText(
                                                        theme.colors.primary.main
                                                    )
                                                }
                                            })
                                            }
                                            onClick={handleEditClick}
                                        >
                                            <EditTwoToneIcon fontSize="small" />
                                        </IconButtonWrapper>
                                    </Tooltip>
                                    <Tooltip title={option === -1 ? 'Select Image Type to Delete' : 'Delete'} arrow>
                                        <IconButtonWrapper
                                            style={{
                                                alignSelf: "center",
                                            }}
                                            sx={(theme) => ({
                                                ml: 1,
                                                backgroundColor: theme.colors.error.lighter,
                                                color: theme.colors.error.main,
                                                transition: theme.transitions.create(['all']),

                                                '&:hover': {
                                                    backgroundColor: theme.colors.error.main,
                                                    color: theme.palette.getContrastText(
                                                        theme.colors.error.main
                                                    )
                                                }
                                            })
                                            }
                                            onClick={(event: any) => {
                                                handleRemove(images[cropperIndex], option);
                                            }}
                                        >
                                            <DeleteTwoToneIcon fontSize="small" />
                                        </IconButtonWrapper>
                                    </Tooltip>
                                </Box>

                            }
                        </Grid>}

                </Grid > : null
        )
    }
export default observer(UploadRotateCropper);