// Vendors

// Components
import { UploadPDF } from './components/UploadPDF';
import View from './components/ViewCustomFormResponse/View'

// Layouts
import ExtendedSidebarLayout from './layouts/ExtendedSidebarLayout';

// Pages
import { Admin } from './pages/Admin';
import Login from './pages/LoginIDS';
import { MyoCorrectAdmin } from './pages/MyoCorrectManagement/MyoCorrectAdmin';
import { TeamsAdmin } from './pages/Teams';
import CreateTestPatients from './pages/CreateTestPatients';
import { AIReports } from './pages/AIReportManagement/AIReport';
import { CustomForms } from './pages/customForms/CustomForms';
import { PendingCases } from './pages/PendingCases';
import MyoCorrectAdminTools from './pages/MyoCorrectManagement/MyoCorrectAdminTools';
import { Consultations } from './pages/consultations/Consultations';
import { ClinicalAdvocate } from './pages/ClinicalAdvocate';
import { MyNetwork } from './pages/MyNetwork';
import { Form } from './pages/Form';
import { ReworkClaimDashboard } from './pages/ReworkManagement/ReworkClaimDashboard';
import { Laboratory } from './pages/LabQCManagement/Laboratory';
import { LabManagementDashboard } from './pages/LabManagement/LabManagementDashboard';
import { QCManagementDashboard } from './pages/LabManagement/QCManagementDashboard';
import { Orders } from './pages/orders/Orders';
import Catalog from './pages/catalog/Catalog';
import { MainDashboard } from './pages/MainDashboard/MainDashboard';
import KnowledgeBase from './pages/KnowledgeBase/KnowledgeBase';
import ContactUs from './pages/ContactUs';

// API
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// Styling
import './scss/main.scss';
import 'cropperjs/dist/cropper.css';

// Store
import { useUserStoreMainContext } from '../src/stores/User/UserStore';
import { TreatmentDashboard } from './pages/TreatmentDashboard';
import { AIReportOrders } from './pages/AIReportManagement/AIReportOrders';
import AdvisoryConsultationsPage from './pages/consultations/Advisory';
import Patients from './pages/Patients/Patients';
import OnlineStore from './components/onlineStore';


let isAuthenticated = false;
let isMyoCorrectAdvisor = false;
let isMyoCorrectAdvocate = false;
let isMyoCorrectAdmin = false;
let isVivosAdmin = false;
let isProvider = false;
let isAiReportAdmin = false;
let isAiReportTech = false;
let isLabAdmin = false;
let isLabTech = false;
let isLabManager = false;
let isClinicalAdvocate = false;
let isPracticeAdvisor = false;
let isAISAdminManager = false;
let isAISSupervisor = false;
let isAISAdmin = false;
let isComplianceManager = false;
let isExternalLab = false;
let isTreatmentNav = false;
let isAISSpecialist = false;
let decodedToken: any = [];
let rolesDecoded: any[] = [];

function App() {

    const { setAuthData } = useUserStoreMainContext();

    const ExternalRedirect = ({ to }) => {
        window.location.href = to;
        return null;
    };

    var token = sessionStorage.getItem('token');
    if (token) {
        isAuthenticated = true;
        decodedToken = jwt_decode(token);
        setAuthData(decodedToken);
        rolesDecoded = JSON.parse(decodedToken.roles);

        for (let role of rolesDecoded) {
            if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                isVivosAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_PROVIDER) {
                isProvider = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVISOR) {
                isMyoCorrectAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE) {
                isMyoCorrectAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADMIN) {
                isMyoCorrectAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_ADMIN) {
                isAiReportAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_TECH) {
                isAiReportTech = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_LAB_ADMIN) {
                isLabAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_LAB_TECH) {
                isLabTech = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_LAB_MANAGER) {
                isLabManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_PRACTICE_ADVISOR) {
                isPracticeAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_CLINICAL_ADVOCATE) {
                isClinicalAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) {
                isAISAdminManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR) {
                isAISSupervisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN) {
                isAISAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_COMPLIANCE_MANAGER) {
                isComplianceManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_EXTERNAL_LAB) {
                isExternalLab = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_TREATMENT_NAV) {
                isTreatmentNav = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SPECIALIST) {
                isAISSpecialist = true;
            }
        }
    }

    const [queryParameters] = useSearchParams();

    return (
        // <Layout>

        <ExtendedSidebarLayout>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/form" element={<Form />} />
                {isMyoCorrectAdmin || isAiReportAdmin ? (
                    <Route
                        path="/admin/teams"
                        element={<TeamsAdmin teamTypeId={isMyoCorrectAdmin ? 1 : isAiReportAdmin ? 2 : 3} />}
                    />
                ) : (
                    <Route path="/admin/teams" element={<Navigate replace to="/" />} />
                )}
                {isMyoCorrectAdvisor || isMyoCorrectAdmin || isMyoCorrectAdvocate ? (
                    <Route path="/myocorrect" element={<MyoCorrectAdmin />} />
                ) : (
                    <Route path="/myocorrect" element={<Navigate replace to="/" />} />
                )}
                {isMyoCorrectAdvisor || isMyoCorrectAdmin ? (
                    <Route path="/myocorrect/:myoCorrectFilter" element={<MyoCorrectAdmin />} key={Math.random()} />
                ) : (
                    <Route path="/myocorrect/:myoCorrectFilter" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin ? (
                    <Route path="/admin" element={<Admin />} />
                ) : (
                    <Route path="/admin" element={<Navigate replace to="/" />} />
                )}
                {isProvider && <Route path="/consultations" element={<Consultations />}></Route>}
                {isProvider || isAISSpecialist ? (
                    <Route path="/patients" element={<Patients userRoles={rolesDecoded} />} />
                ) : (
                    <Route path="/patients" element={<Navigate replace to="/" />} />
                )}
                {isProvider || isAISSpecialist ? (
                    <Route path="/dashboard" element={<MainDashboard />} />
                ) : (
                    <Route path="/dashboard" element={<Navigate replace to="/" />} />
                )}
                {isClinicalAdvocate ? (
                    <Route path="/clinicaladvocate" element={<ClinicalAdvocate userRoles={rolesDecoded} />} />
                ) : (
                    <Route path="/clinicaladvocate" element={<Navigate replace to="/" />} />
                )}
                {isProvider || isAISSpecialist ? (
                    <Route path="/reports" element={<AIReportOrders userRoles={rolesDecoded} />} />
                ) : (
                    <Route path="/reports" element={<Navigate replace to="/" />} />
                )}
                {isProvider ? (
                    <Route path="/form/:questionaireId/:responseId" element={<View />} />
                ) : (
                    <Route path="/reports" element={<Navigate replace to="/" />} />
                )}
                {isProvider ? (
                    <Route path="/myPractice" element={<Patients userRoles={rolesDecoded} />} />
                ) : (
                    <Route path="/myPractice" element={<Navigate replace to="/" />} />
                )}
                {isProvider ? (
                    <Route path="/advisory" element={<AdvisoryConsultationsPage />} />
                ) : (
                    <Route path="/advisory" element={<Navigate replace to="/" />} />
                )}
                {isProvider ? (
                    <Route path="/notifications" element={<Patients userRoles={rolesDecoded} />} />
                ) : (
                    <Route path="/notifications" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin || isProvider || isAISSpecialist ? (
                    <Route path="/orders" element={<Orders />} />
                ) : (
                    <Route path="/orders" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin ? (
                    <Route path="/customForms" element={<CustomForms />} />
                ) : (
                    <Route path="/customForms" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin || isAiReportAdmin || isAiReportTech ? (
                    <Route path="/aiReports" element={<AIReports userRoles={rolesDecoded} />} />
                ) : (
                    <Route path="/aiReports" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin || isAiReportAdmin ? (
                    <Route path="/pendingCases" element={<PendingCases />} />
                ) : (
                    <Route path="/pendingCases" element={<Navigate replace to="/" />} />
                )}
                {isMyoCorrectAdmin ? (
                    <Route path="/myocorrect/tools" element={<MyoCorrectAdminTools />} />
                ) : (
                    <Route path="/myocorrect" element={<Navigate replace to="/" />} />
                )}
                {isClinicalAdvocate ? (
                    <Route path="/myNetwork" element={<MyNetwork />} />
                ) : (
                    <Route path="/myNetwork" element={<Navigate replace to="/" />} />
                )}
                <Route path="/testcreate" element={<CreateTestPatients />} />
                {isAiReportTech ? (
                    <Route
                        path="/uploadpdf"
                        element={<UploadPDF filePath={'test/pdf'} blobUrl={alert} parentCallback={() => { }} />}
                    />
                ) : (
                    <Route path="/uploadpdf" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin || isAISAdminManager || isAISSupervisor || isAISAdmin ? (
                    <Route
                        path="/qcmanagement"
                        element={<QCManagementDashboard />}
                    />
                ) : (
                    <Route path="/qcmanagement" element={<Navigate replace to="/" />} />
                )}
                {isExternalLab || isLabAdmin || isLabTech || isLabManager || isVivosAdmin || isAISAdminManager || isAISSupervisor || isAISAdmin ? (
                    <Route
                        path="/labmanagement"
                        element={<LabManagementDashboard />}
                    />
                ) : (
                    <Route path="/labmanagement" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin || isAISAdminManager || isAISSupervisor || isAISAdmin || isComplianceManager || isExternalLab || isLabManager ? (
                    <Route
                        path="/reworkClaims"
                        element={<ReworkClaimDashboard />}
                    />
                ) : (
                    <Route path="/reworkClaims" element={<Navigate replace to="/" />} />
                )}
                {isVivosAdmin || isAISAdminManager || isAISSupervisor || isAISAdmin ? (
                    <Route path="/laboratory" element={<Laboratory />} />
                ) : (
                    <Route path="/laboratory" element={<Navigate replace to="/" />} />
                )}
                {isTreatmentNav ? (
                    <Route
                        path="/treatment_navigator"
                        element={<TreatmentDashboard />}
                    />
                ) : (
                    <Route path="/treatment_navigator" element={<Navigate replace to="/" />} />
                )}
                {isProvider ? (
                    <Route path="/catalog" element={<Catalog />} />
                ) : (
                    <Route path="/catalog" element={<Navigate replace to="/" />} />
                )}
                {isProvider ? (
                    <Route path="/onlinestore" element={<OnlineStore  />} />
                ) : (
                    <Route path="/onlinestore" element={<Navigate replace to="/" />} />
                )}
                {isProvider || isAISSpecialist ? (
                    <Route path="/knowledgebase" element={<KnowledgeBase />} />
                ) : (
                    <Route path="/knowledgebase" element={<Navigate replace to="/" />} />
                )}
                {isProvider || isAISSpecialist ? (
                    <Route path="/contactus" element={<ContactUs />} />
                ) : (
                    <Route path="/contactus" element={<Navigate replace to="/" />} />
                )}

            </Routes>
            {/* </Layout> */}
        </ExtendedSidebarLayout>
    );
}

export default App;
