// Vendors
import React from 'react';
import { Component } from 'react';
import {
    Button,
    Grid,
    Box,
    IconButton,
} from '@mui/material';

// Components
import { CommentsPanel } from '../../components/CommentsPanel/CommentsPanel';
import PatientEditBL from '../../components/PatientProfile/PatientEditBL';

// Entities
import ProviderPatient from '../../entities/ProviderPatient';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import CommentsEditStore from '../../components/CommentsPanel/CommentsEditStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { PatientStore } from '../../api/PatientStore';


interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
    // onClose();
}

let commentsEdit = new CommentsEditStore();

@observer
export class MyoPatientActionButtons extends Component<IGIProps> {

    store = new PatientStore();
    openDialog = () => {
        this.setState({ toggleUploadModal: true });
    };

    closeDialog = () => {
        this.setState({ toggleUploadModal: false });
    }


    render() {

        return (
            <Grid container direction="column" spacing={1}>

                <CommentsPanel
                    commentType={15553}
                    significantId={this.props.patient.id}
                    isOpen={commentsEdit.isOpenModal}
                    onClose={commentsEdit.handleCloseModal.bind(this)}
                />
                {/* <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                    <IconButton className="vivButton invert" onClick={this.props.onClose}>
                        <Close />
                    </IconButton>
                </Box> */}
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        onClick={commentsEdit.handleClickModal}
                        variant='contained'
                    >
                        Patient Collaboration
                    </Button>
                    {/* <Button
                                startIcon={<DynamicFormTwoToneIcon />}
                                variant="contained"
                                // onClick={this.openDialog}
                                sx={{
                                    width: '85%', textAlign: 'left', mb: 1,
                                    mx: 2
                                }}
                            >
                                Create Action Item
                            </Button> */}
                </Grid>
            </Grid>
        )
    }
};
