// Vendors
import React, { FC, Fragment, useEffect } from 'react';
import { Grid, LinearProgress, MenuItem, Button, TextField, Box, CardMedia, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import { GridActionsCellItem, GridCellParams, GridFilterInputValue, GridFilterItem } from '@mui/x-data-grid';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';

// Components
import DataGrid from '../../components/DataGrid/DataGrid';
import ModalContainer from '../../components/ModalContainer';
import { ClaimReview } from '../../modals/Claim/ClaimReview';
import PageHeader from './components/PageHeader';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import DashboardInfoCard from '../../components/UI/Cards/DashboardInfoCard';
import SearchBar from '../../components/UI/SearchBar';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";

// Entities
import { ReworkClaimFilters } from '../../entities/Enums';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

// Styling
import '../../scss/_ReworkClaim.scss';
import CssSelectField from '../../components/CssSelectField';


interface ReworkClaimDashboardProps { }

const ReworkClaimDashboardComponent: FC<ReworkClaimDashboardProps> = (props: ReworkClaimDashboardProps) => {
  //let params = useParams();

  const numResults = 0;

  let store = useMainStoreContext().reworkClaimStore;
  let prescriptionStore = usePrescriptionStore().prescriptionStore;

  const {
    setTriggerShowClaimModal,
    triggerShowClaimModal
  } = useMainStoreContext().claimStore;

  const defaultPage = "CLAIMS";

  useEffect(() => {
    store.didInitClaims = true;
    store.getFilterNums();
    store.loadPage();
    store.loadAISUsers();
  }, [store.loadPage, store.loadAISUsers]);

  useEffect(() => {
    store.loadPage();
  }, [store.filter])

  const [currentButton, setState] = React.useState(1);
  const [selectedRow, setSelectedRow] = React.useState<any>({});

  const onButtonClicked = (newIcon) => {
    setState(newIcon);
  };

  const columns = [
    // {
    //   field: 'id', headerName: 'ID', flex: 0.1, headerAlign: "center", headerClassName: "colHeader", align: "center", type: "number", renderCell: (params) => {
    //     return (<Grid>
    //       <Button id={"reworkClaim.dashboard.cell.id." + params.row.id} variant="text"
    //         onClick={() => {

    //           setTriggerShowClaimModal(true);
    //           setSelectedRow(params.row);
    //         }}> {params.row.id}</Button></Grid>)
    //   }
    // },
    {
      field: 'dateSubmitted', headerName: 'Date Submitted', flex: 1, headerAlign: "center", headerClassName: "colHeader", type: "date", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.dateSubmitted." + params.row.id}>
          {params.row.dateSubmitted && moment(params.row.dateSubmitted).format('MM/DD/yyyy')}</Grid>)
      }
    },
    {
      field: 'daysInQueue', headerName: 'Days In Queue', flex: .5, headerAlign: "center", headerClassName: "colHeader", type: "number", align: "center", cellClassName: (params: GridCellParams<number>) => {
        if (params.value == null) {
          return "";
        }

        return clsx("daysInQueue", {
          redNumber: params.value >= 7,
          orangeNumber: params.value >= 4 && params.value < 7,
          greenNumber: params.value < 4
        });
      }, renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.daysInQueue." + params.row.id}>{params.row.daysInQueue}</Grid>)
      }
    },
    {
      field: 'rxClaimId', headerName: 'RX Claim ID', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
        // return (<Grid>
        //   <Button id={"reworkClaim.dashboard.cell.rxClaimId." + params.row.id} variant="text"> {params.row.rxClaimId}</Button></Grid>)
        return (<Grid>
          <Button id={"reworkClaim.dashboard.cell.id." + params.row.id} variant="text"
            onClick={() => {
              setTriggerShowClaimModal(true);
              setSelectedRow(params.row);
              store.setVwClaim(params.row);
            }}> {params.row.rxClaimId}</Button></Grid>)
      }
    },
    {
      field: 'providerName', headerName: 'Provider', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", sortable: true, renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.provider." + params.row.id}>{params.row.providerName}</Grid>)
      }
    },
    {
      field: 'patientName', headerName: 'Patient', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "ce", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.patient." + params.row.id}>{params.row.patientName}</Grid>)
      }
    },
    {
      field: 'assignedToId', headerName: 'Assigned To', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
      sortComparator: (v1, v2, param1, param2) => {
        var a1 = _.find(store.teamMembers, (a) => { return a.userID === param1.value });
        var a2 = _.find(store.teamMembers, (a) => { return a.userID === param2.value });
        var ad1 = (a1?.firstName + " " + a1?.lastName);
        var ad2 = (a2?.firstName + " " + a2?.lastName);
        return ad1.localeCompare(ad2);
      },
      valueOptions: store.teamMembers.map((a) => { return a.firstName + " " + a.lastName }),
      filterOperators: [
        {
          value: 'is',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value === '') {
              return null;
            }

            return ({ value }): boolean => {
              var teamMember = _.find(store.teamMembers, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
              if (!_.isNull(teamMember) && !_.isUndefined(teamMember)) {
                return teamMember.userID === value;
              }
              return false;
            };
          },
          InputComponent: GridFilterInputValue,
          InputComponentProps: { type: 'singleSelect' },
        }],
      renderCell: (params) => {
        if (store.teamMembers.filter(x => x.userID === params.row.assignedToId).length === 0) {
          params.row.assignedToId = 0;
        }
        const disabled = store.isAISAdmin;

        return (
          <Grid>
            {!store.isExternalLab && <CssSelectField
              id={"reworkClaim.dashboard.cell.assignedToId." + params.row.id}
              sx={{ border: "0px" }}
              //defaultValue="0"
              //label="Assign To"
              disabled={disabled}
              value={(params.row.assignedToId ? params.row.assignedToId : 0)}
              onChange={(event) => {
                params.row.assignedToId = event.target.value;
                store.handleAssignToChange(params.row, event)
              }}
            >
              <MenuItem key={0} value={0}>{"Assign To..."}</MenuItem>
              {
                store.teamMembers.map((tm) =>
                  (<MenuItem key={tm.userID} value={tm.userID}>{tm.firstName + ' ' + tm.lastName}</MenuItem>))
              }
            </CssSelectField>}
            {/* {store.isExternalLab && params.row.assignedToName} */}
          </Grid>
        )
      }
    },
    {
      field: 'labName', headerName: 'Lab', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center",
      renderCell: (params) => {
        const labLogo = prescriptionStore.labsImages.find(lab => lab.name === params.row.labName)?.logo;
        return (
          <Box display="flex" alignItems="center" height={30}>
            {labLogo ? (
              <CardMedia
                component="img"
                src={labLogo}
                style={{ maxHeight: '100%', width: 'auto' }}
              />
            ) : (
              <Typography>{params.row.labName}</Typography>
            )}
          </Box>
        )
      }
    },
    {
      field: "appliance", headerName: 'Appliance', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.appliance." + params.row.id}>{params.row.appliance}</Grid>)
      }
    },
    {
      field: 'claimType', headerName: 'Claim Type', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.claimType." + params.row.id}>
          <Button id={"reworkClaim.dashboard.cell.claimType." + params.row.id} variant="text">{params.row.claimType}</Button></Grid>)
      }
    },
    {
      field: 'claimStatus', headerName: 'Claim Status', sortable: true, flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center",
      renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.claimStatus." + params.row.id}>{params.row.claimStatus}</Grid>)
      }
    },
    {
      field: 'productionStatus', headerName: 'Production Status', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.productionStatus." + params.row.id}>{params.row.productionStatus}</Grid>)
      }
    },
    // {
    //   field: 'ccrStatus', headerName: 'CCR Status', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
    //     return (<Grid id={"reworkClaim.dashboard.cell.ccrstatus." + params.row.id}>{params.row.ccrStatus}</Grid>)
    //   }
    // },
    {
      field: 'erfStatus', headerName: 'ERF Status', flex: 1, headerAlign: "center", sortable: "true", filterable: "true", headerClassName: "colHeader", align: "center", renderCell: (params) => {
        return (<Grid id={"reworkClaim.dashboard.cell.erfstatus." + params.row.id}>{params.row.erfStatus}</Grid>)
      }
    },
    {
      field: 'actions', headerName: 'Notes', flex: .3, headerAlign: "center", headerClassName: "colHeader", type: 'actions', align: "center",
      getActions: (params) => [
        <GridActionsCellItem icon={currentButton === 0 ? < ConnectWithoutContactIcon id={"reworkClaim.dashboard.cell.reworkClaimnotes." + params.row.id} /> : <AddCircleOutlineIcon id={"reworkclaim.dashboard.cell.reworkClaimnotes_" + params.row.id} />} onClick={() => onButtonClicked(0)} label="Notes" />
      ]
    }
  ]

  var hdrButtons: any = [];
  hdrButtons.push(<Button id={"reworkClaim.header.btn.filterReworkClaims." + store.userID} className='vivButton large' onClick={store.toggleFilterResultsExpand}>FILTER RESULTS</Button>);

  var infoBoxes: any = [];
  infoBoxes.push(<DashboardInfoCard key={"dib6"} cardLabelText={"New Submission"} filter={ReworkClaimFilters.NewSubmission} cardNumberValue={store.numNewSubmissions} icon={<PendingActionsTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.NewSubmission) }} />)
  infoBoxes.push(<DashboardInfoCard key={"dib2"} cardLabelText={"In Progress (Warranty)"} filter={ReworkClaimFilters.InProgressWarranty} cardNumberValue={store.numInProgressWarranty} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.InProgressWarranty) }} />)
  infoBoxes.push(<DashboardInfoCard key={"dib3"} cardLabelText={"In Progress (Repair)"} filter={ReworkClaimFilters.InProgressRepair} cardNumberValue={store.numInProgressRepair} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.InProgressRepair) }} />)
  //infoBoxes.push(<DashboardInfoCard key={"dib2"} cardLabelText={"In Progress (Repair)"} filter={ReworkClaimFilters.Approved} cardNumberValue={store.numApproved} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.Approved) }} />)
  infoBoxes.push(<DashboardInfoCard key={"dib4"} cardLabelText={"Pending Approval"} filter={ReworkClaimFilters.PendingApproval} cardNumberValue={store.numPendingApproval} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.PendingApproval) }} />)
  infoBoxes.push(<DashboardInfoCard key={"dib5"} cardLabelText={"Completed"} filter={ReworkClaimFilters.Completed} cardNumberValue={store.numCompleted} icon={<AssignmentTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.Completed) }} />)
  infoBoxes.push(<DashboardInfoCard key={"dib1"} cardLabelText={"All Claims"} filter={ReworkClaimFilters.AllClaims} cardNumberValue={store.numAllClaims} icon={<AssignmentTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, ReworkClaimFilters.AllClaims) }} />)

  return (
    <Fragment>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid container>
        <Grid item xs={12} container spacing={2} paddingX={2} paddingBottom={1}>
          {infoBoxes.map((box, index) => (
            <Grid item xs={12} sm={6} md={3} lg={2} key={index}
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                {box}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} padding={2} container spacing={2}>
        <Grid item xs={6}>
          <SearchBar
            onChange={store.getSearchData}
            onClear={store.clearData}
            onCancel={store.clearData}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label='Filter by Status'
            select>
            <MenuItem>In-Progress</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Grid className="grid" id={"reworkClaim.dashboard" + store.userID}>
            {store.isLoadingClaims &&
              <LinearProgress variant="determinate" value={store.loadingClaimsProgress} />
            }
            <DataGrid
              data={store.claimData}
              columns={columns}

              pagination={true}
              pageSize={store.pageSize}
              rowCount={store.totalPages}
              serverSidePagination={true}

              loading={store.isLoadingClaims}


              onPageChange={(pageNumber) => {
                store.currentPage = pageNumber;
                store.loadPage();
              }}

              onSortModelChange={(sortObj) => {
                store.onSortChange(sortObj);
                store.loadPage();
              }}
              onFilterChange={(filterObj) => {
                store.onFilterChange(filterObj);
                store.loadPage();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <ModalContainer
        noScroll={false}
        show={triggerShowClaimModal}
        title={"Claim Review"}
        onClick={() => { }}
        onClose={() => {
          setTriggerShowClaimModal(false);
          store.loadPage();
          store.getFilterNums();
        }}>
        <ClaimReview claimViewData={store.vwClaim} />
      </ModalContainer>
    </Fragment>
  )
}

export const ReworkClaimDashboard = observer(ReworkClaimDashboardComponent);