// Vendors
import { Fragment, useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography, Box, CardMedia, MenuItem } from "@mui/material";
import { Comment } from '@mui/icons-material'
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';


// Components
import DashboardInfoCard from "../../components/UI/Cards/DashboardInfoCard";
import DataGrid from "../../components/DataGrid/DataGrid";
import ModalContainer from "../../components/ModalContainer";
import { RxStatusDetails } from "../../components/PatientProfile/PatientApplianceComponents/SubmitReworkModals/RxStatusDetails";
import PageTitleWrapper from "../../components/UI/PageTitleWrapper";
import PageHeader from "./components/PageHeader";
import Label from "../../components/UI/Label";
import { RxManagement } from "../../modals/RxMangement/RxManagement";

// Stores
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// Entities
import { LabManagementFilters, RxAppliances, ProductionStatus, LabBiteStatus } from '../../entities/Enums';
import ProviderPatient from "../../entities/ProviderPatient";

// API
import { observer } from "mobx-react";
import moment from 'moment';
import { DataGridPro } from "@mui/x-data-grid-pro";
import CssSelectField from "../../components/CssSelectField";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";


export const QCManagementDashboardComponent = () => {
    const [selectedRow, setSelectedRow] = useState<any>({ id: 0 });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentPatient, setCurrentPatient] = useState<any>({});
    const store = useMainStoreContext().labManagementStore;
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    useEffect(() => {
        store.loadAISUsers();
        store.loadFilterCount();
        store.loadPage();
    }, [store.loadPage, store.loadAISUsers])
    const numResults = 0;
    const infoBoxes: any = [];

    const handleRxClick = async (row: any) => {
        //const labData = await store.loadLabData(row.id);
        var patient = {
            id: row.patientId,
            name: row.patient
        }
        await prescriptionStore.loadAllPrescriptionsListItemValues();
        await prescriptionStore.getPrescription(row.id);
        setCurrentPatient(patient);

        //setSelectedRow(labData);
        setShowModal(true);
    };

    infoBoxes.push(<DashboardInfoCard key={"dib1"} cardLabelText={"QC Pending & Hold"} filter={LabManagementFilters.NewPrescriptions} cardNumberValue={store.numNewPrescriptions} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, LabManagementFilters.NewPrescriptions) }} />)
    infoBoxes.push(<DashboardInfoCard key={"dib2"} cardLabelText={"Bites On Hold"} filter={LabManagementFilters.BitesOnHold} cardNumberValue={store.numBitesOnHold} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, LabManagementFilters.BitesOnHold) }} />)
    infoBoxes.push(<DashboardInfoCard key={"dib3"} cardLabelText={"Impressions On Hold"} filter={LabManagementFilters.ImpressionsOnHold} cardNumberValue={store.numImpressionsOnHold} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, LabManagementFilters.ImpressionsOnHold) }} />)
    infoBoxes.push(<DashboardInfoCard key={"dib4"} cardLabelText={"Production Ready"} filter={LabManagementFilters.ProductionReady} cardNumberValue={store.numProductionReady} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, LabManagementFilters.ProductionReady) }} />)
    infoBoxes.push(<DashboardInfoCard key={"dib5"} cardLabelText={"In Fabrication"} filter={LabManagementFilters.InFabrication} cardNumberValue={store.numInFabrication} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, LabManagementFilters.InFabrication) }} />)
    infoBoxes.push(<DashboardInfoCard key={"dib6"} cardLabelText={"Shipped"} filter={LabManagementFilters.Shipped} cardNumberValue={store.numShipped} icon={<AssignmentTurnedInTwoToneIcon />} currentFilter={store.currentFilter} onFilter={(value) => { store.onFilter(value, LabManagementFilters.Shipped) }} />)

    const columns = [
        {
            field: 'submissionDate',
            headerName: 'Submitted',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { submissionDate } }) => (
                <Grid>{moment(submissionDate).format('MM/DD/yyyy')}</Grid>
            )
        },
        {
            field: 'daysInQueue',
            headerName: 'Days in Q',
            headerAlign: 'center',
            flex: 1,
            maxWidth: 100,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { daysInQueue } }) => {
                const color = daysInQueue <= 3 ? 'green' :
                    daysInQueue <= 6 ? 'orange' :
                        'red';
                return (

                    <Typography variant="h6" sx={{ color, fontWeight: 'bold' }}>{daysInQueue}</Typography>)
            },
        },
        {
            field: 'assignedTo',
            headerName: 'Assigned To',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                if (store.AISUsers.filter(x => x.userID === params.row.assignedTo).length === 0) {
                    params.row.assignedTo = 0;
                }
                return (
                    <Grid>
                        {(store.isAISAdminManager || store.isAISSupervisor || store.isAISAdmin) &&
                            <CssSelectField
                                id={"qc.dashboard.cell.assignedToId." + params.row.assignedTo}
                                sx={{ border: "0px" }}
                                defaultValue='0'
                                label='Select an user'
                                value={(params.row.assignedTo ?? 0)}
                                onChange={(event) => {
                                    params.row.assignedTo = event.target.value;
                                    store.handleQCUserChange(params.row, event);
                                }}
                            >
                                <MenuItem key={0} value={0}>{"Select an User..."}</MenuItem>
                                {store.AISUsers.map((th) =>
                                    (<MenuItem key={th.userID + th.lastName} value={th.userID}>{th.firstName + ' ' + th.lastName}</MenuItem>))}
                            </CssSelectField>}
                    </Grid>
                )
            }
        },
        // {
        //     field: 'assignedToName',
        //     headerName: 'Assigned To Name',
        //     headerAlign: 'center',
        //     flex: 1,
        //     headerClassName: 'colHeader',
        //     align: 'center',
        // },
        {
            field: 'rxId',
            headerName: 'RX ID',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row }) => (<Button variant="text" onClick={() => handleRxClick(row)}>{row.rxId}</Button>)
        },
        {
            field: 'provider',
            headerName: 'Provider',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patient',
            headerName: 'Patient',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'lab',
            headerName: 'Lab',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row }) => {
                const labLogo = prescriptionStore.labsImages.find(lab => lab.name === row.lab)?.logo;
                return (
                    <Box display="flex" alignItems="center" height={30}>
                        {labLogo ? (
                            <CardMedia
                                component="img"
                                src={labLogo}
                                style={{ maxHeight: '100%', width: 'auto' }}
                            />
                        ) : (
                            <Typography>{row.lab}</Typography>
                        )}
                    </Box>
                )
            },
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            // renderCell: ({ row }) => {
            //     const applianceLogo = prescriptionStore.applianceOptions.find(a => a.constructId === row.applianceTypeId)?.logo
            //     return (
            //         <Box display="flex" alignItems="center" height={30}>
            //             {applianceLogo ? (
            //                 <CardMedia
            //                     component="img"
            //                     src={applianceLogo}
            //                     style={{ maxHeight: '100%', width: 'auto' }}
            //                 />
            //             ) : (
            //                 <Typography>{row.applianceTypeName}</Typography>
            //             )}
            //         </Box>
            //     )
            // },
        },
        {
            field: 'fabricationTime',
            headerName: 'Fab Time',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { dliFabricationTime } }) => {
                return (
                    <Grid>{dliFabricationTime}</Grid>
                );
            }
        },
        {
            field: 'fabricatedOn',
            headerName: 'Date of Fab',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { fabricatedOn } }) => {
                const date = moment(fabricatedOn);
                return (
                    <Grid>{date.isValid() ? date.format('MM/DD/yyyy') : ''}</Grid>
                );
            }
        },
        {
            field: 'neededByDate',
            headerName: 'Date Need',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { neededByDate } }) => {
                const date = moment(neededByDate);
                return (
                    <Grid>{date.isValid() ? date.format('MM/DD/yyyy') : ''}</Grid>
                );
            }
        },
        {
            field: 'dliQcstatus',
            headerName: 'QC',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const qcStatus = params.row.dliQcstatus;
                return (
                    <Grid container justifyContent={'center'}>
                        {getQCStatusLabel(qcStatus as any)}
                    </Grid>
                )
            },
        },
        {
            field: 'dliLabBiteStatus',
            headerName: 'Bite',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const biteStatus = params.row.dliLabBiteStatus;
                return (
                    <Grid container justifyContent={'center'}>
                        {getBiteImpressStatusLabel(biteStatus as any)}
                    </Grid>
                )
            },
        },
        {
            field: 'dliModelStatus',
            headerName: 'Impression',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const impressionStatus = params.row.dliModelStatus;
                return (
                    <Grid container justifyContent={'center'}>
                        {getBiteImpressStatusLabel(impressionStatus as any)}
                    </Grid>
                )
            },
        },
        {
            field: 'productionStatus',
            headerName: 'Production',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const productionStatus = params.row.productionStatus;
                return (
                    <Grid container justifyContent={'center'}>
                        {getProductionStatusLabel(productionStatus as any)}
                    </Grid>
                )
            },
        },
        {
            field: 'estimateShippedDate',
            headerName: 'Est Ship Date',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { estimateShippedDate } }) => {
                const date = moment(estimateShippedDate);
                return (
                    <Grid>{date.isValid() ? date.format('MM/DD/yyyy') : ''}</Grid>
                );
            }
        },
        /*
        {
            field: 'notes',
            headerName: 'Notes',
            headerAlign: 'center',
            flex: 1,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (param) => <IconButton color="primary"><Comment /></IconButton>
        },
        */
    ];

    const getProductionStatusLabel = (productionStatus: ProductionStatus): JSX.Element => {
        const map = {
            Pending: {
                text: 'Pending',
                color: 'warning'
            },
            Hold: {
                text: 'Hold',
                color: 'warning'
            },
            'In Fabrication': {
                text: 'In Fabrication',
                color: 'info'
            },
            Shipped: {
                text: 'Shipped',
                color: 'success'
            },
            Cancelled: {
                text: 'Cancelled',
                color: 'error'
            },
            Remake: {
                text: 'Remake',
                color: 'error'
            },
            Ready: {
                text: 'Ready',
                color: 'primary'
            },
            'default': {
                text: '',
                color: 'inherit'
            }
        };

        const { text, color }: any = map[productionStatus] ? map[productionStatus] : {};

        return (
            <Label color={color}>
                <b>{text}</b>
            </Label>
        );
    };

    const getQCStatusLabel = (productionStatus: ProductionStatus): JSX.Element => {
        const map = {
            Pending: {
                text: 'Pending',
                color: 'warning'
            },
            Hold: {
                text: 'Hold',
                color: 'warning'
            },
            Cancelled: {
                text: 'Cancelled',
                color: 'error'
            },
            Ready: {
                text: 'Ready',
                color: 'success'
            },
            'default': {
                text: '',
                color: 'inherit'
            }
        };

        const { text, color }: any = map[productionStatus] ? map[productionStatus] : {};

        return (
            <Label color={color}>
                <b>{text}</b>
            </Label>
        );
    };

    const getBiteImpressStatusLabel = (biteStatus: LabBiteStatus): JSX.Element => {
        const map = {
            Pending: {
                text: 'Pending',
                color: 'warning'
            },
            Received: {
                text: 'Received',
                color: 'success'
            },
            Cancelled: {
                text: 'Cancelled',
                color: 'error'
            },
            Hold: {
                text: 'Hold',
                color: 'error'
            },
            'default': {
                text: '',
                color: 'inherit'
            }
        };

        const { text, color }: any = map[biteStatus] ? map[biteStatus] : {};

        return (
            <Label color={color}>
                <b>{text}</b>
            </Label>
        );
    };

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader
                    onChange={() => { }}
                    onClear={() => { }}
                    onCancel={() => { }}
                    onFilter={() => { }}
                />
            </PageTitleWrapper>
            <Grid container>
                <Grid item xs={12} container spacing={2} paddingX={2} paddingBottom={1}>
                    {infoBoxes.map((box, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={2} key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                {box}
                            </Box>
                        </Grid>
                    ))}
                </Grid>

                <Grid item xs={12} className="grid" id={'lab.dashboard' + store.userId}>
                    <DataGrid
                        pagination={true}
                        pageSize={store.pageSize}
                        rowCount={store.totalRows}
                        serverSidePagination={true}
                        columns={columns}
                        loading={store.isLoadingData}
                        data={store.labManagementData}
                        onSortModelChange={(sortObj) => {
                            store.onSortChange(sortObj);
                            store.loadPage();
                        }}
                        onFilterChange={(filterObj) => {
                            store.onFilterChange(filterObj);
                            store.loadPage();
                        }}
                        onPageChange={(pageNumber) => {
                            store.currentPage = pageNumber;
                            store.loadPage();
                        }}
                    />
                </Grid>
            </Grid>
            <ModalContainer
                show={showModal}
                title="PRESCRIPTION"
                onClose={() => {
                    setShowModal(false);
                }}
                onClick={() => { }}
            >
                <RxManagement
                    prescription={prescriptionStore.prescription}
                    patient={currentPatient as unknown as ProviderPatient}
                    labAccessed={false}
                />

                {/* <RxStatusDetails
                    prescription={prescriptionStore.prescription}
                    patient={currentPatient as unknown as ProviderPatient}
                /> */}

            </ModalContainer>
        </Fragment>
    )
};


export const QCManagementDashboard = observer(QCManagementDashboardComponent);