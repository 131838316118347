// Vendors
import react, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    Box,
    TextField,
    Button,
    alpha,
    CardMedia,
    Tabs,
    Tab,
    Card,
    Alert,
    Switch
} from "@mui/material"

// Components
import RxTeethSelection from "../RxTeethSelection";
import RxConstructionContainer from "../RxConstructionContainer";
import CompletedIcon from "../../../../components/UI/Icons/CompletedIcon";
import RequiredIcon from "../../../../components/UI/Icons/RequiredIcon";

// Store
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";

// Entities
import { MissingReasons, RxAppliances } from "../../../../entities/Enums"
import DictionaryListItem from "../../../../entities/DictionaryListItem";

// API
import { observer } from 'mobx-react';
import _ from "lodash";
import { GlobalUtils } from "../../../../api/GlobalUtils";
import { Gateway } from "../../../../api/Gateway";
import { Part } from "../../../../models/state_models/part";
import { usePrescriptionStore } from "../../../../stores/Prescription/CreateEdit/MainStoreContext";
import MoreInfoIndicator from "../../../../components/UI/Icons/MoreInfoIndicator";

const CareConstruction = ({ constructionType }) => {
    // All DNA, mRNA and mmRNA options
    const teethChartStore = useMainStoreContext().teethChartStore;
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    const [missingTeeth, setMissingTeeth] = useState<number[]>([]);
    const [unavailableTeeth, setUnavailableTeeth] = useState<number[]>([]);
    const [isAdjacentClasp, setIsAdjacentClasp] = useState(false);
    const [isMoreThanThreeCLoop, setIsMoreThanThreeCLoop] = useState(false);

    const [advancedOptions, setAdvancedOptions] = useState(false);

    const [upperBaseValues, setUpperBaseValues] = useState<DictionaryListItem[]>([]);
    const [lowerBaseValues, setLowerBaseValues] = useState<DictionaryListItem[]>([]);
    const [upperBowValues, setUpperBowValues] = useState<DictionaryListItem[]>([]);
    const [lowerBowValues, setLowerBowValues] = useState<DictionaryListItem[]>([]);
    const [upperMandibularAdvancementValues, setUpperMandibularAdvancementValues] = useState<DictionaryListItem[]>([]);
    const [upperScrewValues, setUpperScrewValues] = useState<DictionaryListItem[]>([]);
    const [dentalPartsValues, setDentalPartsValues] = useState<Part[]>([]);

    const [loadedValues, setLoadedValues] = useState(false);

    // Handle switch toggle
    const handleAdvancedOptions = (event) => {
        setAdvancedOptions(event.target.checked);
    };

    var warningAdjacentText = "Placing Adams clasps on adjacent teeth may cause issues with appliance fabrication. " +
        "Please ensure that there is adequate spacing between the teeth or select a different tooth to clasp on. Thank you";
    var warningCLoopText = "Placing more than three C-Loop 3D Axial Springs on a single arch may cause issues with appliance fabrication. Are you sure you want to continue?";

    useEffect(() => {
        loadData().then(() => {
            if (prescriptionStore.isUpdating && !prescriptionStore.isConstructionFormInProgress) {
                prescriptionStore.populateDentalPiecesMap();
            }

            setLoadedValues(true);

            if (prescriptionStore.isUpdating) {
                prescriptionStore.setIsConstructionFormInProgress(true)
            }
        })
    }, [])

    const loadData = async () => {
        setUpperBaseValues(await GlobalUtils.getApplianceUpperBase());
        setLowerBaseValues(await GlobalUtils.getApplianceLowerBase());
        setUpperBowValues(await GlobalUtils.getApplianceUpperBow());
        setLowerBowValues(await GlobalUtils.getApplianceLowerBow());
        setUpperMandibularAdvancementValues(await GlobalUtils.getApplianceUpperMandibularAdvancement());
        setUpperScrewValues(await GlobalUtils.getApplianceUpperScrew());
        setDentalPartsValues(await Gateway.get('/prescription/appliance/parts'));
    }
    useEffect(() => {
    }, [prescriptionStore.appliance]);

    useEffect(() => {
        _.forEach(teethChartStore.teethChart.teethChartTeeth, (tooth) => {
            if (!(tooth.missingReason === MissingReasons["N/A"] || _.isNull(tooth.missingReason))) {
                missingTeeth.push(tooth.toothNumber);
            }
        })
    }, [teethChartStore.teethChart.teethChartTeeth, prescriptionStore.selectedConstructionType, dentalPartsValues])

    const distinct = (value, index, self) => {
        return self.indexOf(value) === index;
    }



    useEffect(() => {
        if (loadedValues) {
            if (prescriptionStore && prescriptionStore.appliance) {
                // Create a map for dentalPartsValues to reduce find operations
                const dentalPartsMap = new Map(dentalPartsValues.map(x => [x.partName, x.id ?? -1]));

                // Upper Arch
                const upperBaseName = upperBaseValues.find(x => x.id === prescriptionStore.appliance.appliancesystemUppersystemBaseId)?.name ?? '';
                const upperBowName = upperBowValues.find(x => x.id === prescriptionStore.appliance.appliancesystemUppersystemBowId)?.name ?? '';
                const upperScrewName = upperScrewValues.find(x => x.id === prescriptionStore.appliance.applianceSystemUpperSystemScrewId)?.name ?? '';

                // Lower Arch
                const lowerBaseName = lowerBaseValues.find(x => x.id === prescriptionStore.appliance.appliancesystemLowersystemBaseId)?.name ?? '';
                const lowerBowName = lowerBowValues.find(x => x.id === prescriptionStore.appliance.appliancesystemLowersystemBowId)?.name ?? '';

                setIncludeClassIIHooksUpper(prescriptionStore.appliance.applianceSystemUpperSystemElasticHooksClassIIBool ? true : false);
                setIncludeVerticalHooksUpper(prescriptionStore.appliance.applianceSystemUpperSystemElasticHooksVerticalBool ? true : false);
                setIncludeClassIIHooksLower(prescriptionStore.appliance.applianceSystemLowerSystemElasticHooksClassIIBool ? true : false);
                setIncludeVerticalHooksLower(prescriptionStore.appliance.applianceSystemLowerSystemElasticHooksVerticalBool ? true : false);

                setIncludeOSAExtension(prescriptionStore.appliance.applianceSystemUpperSystemOsaExtensionBool ? true : false);
                setIncludeAnteriorIndexRamp(prescriptionStore.appliance.applianceSystemUpperSystemAnteriorIndexRampBool ? true : false);
                setIncludeAnteriorBitePlane(prescriptionStore.appliance.applianceSystemUpperSystemAnteriorBitePlaneBool ? true : false);
                setOSAExtensionLength(prescriptionStore.appliance.applianceSystemUpperSystemOsaExtensionLengthInt ?? 0);


                // Set all state updates in a batch
                setUpperBaseOption(upperBaseName);
                setUpperBowOption(upperBowName);
                setUpperScrewOption(upperScrewName);
                setLowerBaseOption(lowerBaseName);
                setLowerBowOption(lowerBowName);

                // Advancement (mRNA only)
                const advancementName = upperMandibularAdvancementValues.find(x => x.id === prescriptionStore.appliance.appliancesystemUppersystemMandibularadvancementId)?.name ?? '';
                setMandibularAdvancementOption(advancementName);

                // Retrieve the IDs for each part using the map
                const occlusalAcrylicCoveragePartId = dentalPartsMap.get('Occlusal Acrylic Coverage');
                const adamsClaspPartId = dentalPartsMap.get('Adams Clasp');
                const ballClaspPartId = dentalPartsMap.get('Ball Clasp');
                const cClaspPartId = dentalPartsMap.get('C Clasp');
                const arrowClaspPartId = dentalPartsMap.get('Arrow Clasp');
                const axialSprint3D = dentalPartsMap.get('3D Axial Springs');
                const CLoopAxialSpringt = dentalPartsMap.get('C Loop 3D Axial Springs');
                const screwPartId = dentalPartsMap.get(upperScrewName);
                const stopsMesial = dentalPartsMap.get('Stops Mesial');
                const stopsDistal = dentalPartsMap.get('Stops Distal');
                const stopsOcclusal = dentalPartsMap.get('Stops Occlusal');
                const faceMaskHoosk = dentalPartsMap.get('Facemask Hooks');
                const eruptionFriendly = dentalPartsMap.get('Eruption Friendly');

                // Fetch entries from the map for Upper Arch
                const upperAcrylicCoverageEntry = prescriptionStore.dentalPiecesMap.get(`${occlusalAcrylicCoveragePartId},Upper`);
                const adamsClaspEntry = prescriptionStore.dentalPiecesMap.get(`${adamsClaspPartId},Upper`);
                const ballClaspEntry = prescriptionStore.dentalPiecesMap.get(`${ballClaspPartId},Upper`);
                const cClaspEntry = prescriptionStore.dentalPiecesMap.get(`${cClaspPartId},Upper`);
                const arrowClaspEntry = prescriptionStore.dentalPiecesMap.get(`${arrowClaspPartId},Upper`);
                const axialSprint3DEntry = prescriptionStore.dentalPiecesMap.get(`${axialSprint3D},Upper`);
                const CLoopAxialSpringtEntry = prescriptionStore.dentalPiecesMap.get(`${CLoopAxialSpringt},Upper`);
                const upperScrewTeethEntry = prescriptionStore.dentalPiecesMap.get(`${screwPartId},Upper`);
                const upperStopsMesialTeethEntry = prescriptionStore.dentalPiecesMap.get(`${stopsMesial},Upper`);
                const upperStopsDistalTeethEntry = prescriptionStore.dentalPiecesMap.get(`${stopsDistal},Upper`);
                const upperStopsOcclusalTeethEntry = prescriptionStore.dentalPiecesMap.get(`${stopsOcclusal},Upper`);
                const upperFaceMaskEntry = prescriptionStore.dentalPiecesMap.get(`${faceMaskHoosk},Upper`);
                const upperEruptionFriendlyEntry = prescriptionStore.dentalPiecesMap.get(`${eruptionFriendly},Upper`);

                // Fetch entries from the map for Lower Arch
                const lowerAcrylicCoverageEntry = prescriptionStore.dentalPiecesMap.get(`${occlusalAcrylicCoveragePartId},Lower`);
                const lowerAdamsClaspEntry = prescriptionStore.dentalPiecesMap.get(`${adamsClaspPartId},Lower`);
                const lowerBallClaspEntry = prescriptionStore.dentalPiecesMap.get(`${ballClaspPartId},Lower`);
                const lowerCClaspEntry = prescriptionStore.dentalPiecesMap.get(`${cClaspPartId},Lower`);
                const lowerArrowClaspEntry = prescriptionStore.dentalPiecesMap.get(`${arrowClaspPartId},Lower`);
                const lowerAxialSprint3DEntry = prescriptionStore.dentalPiecesMap.get(`${axialSprint3D},Lower`);
                const lowerCLoopAxialSpringtEntry = prescriptionStore.dentalPiecesMap.get(`${CLoopAxialSpringt},Lower`);
                const lowerStopsMesialTeethEntry = prescriptionStore.dentalPiecesMap.get(`${stopsMesial},Lower`);
                const lowerStopsDistalTeethEntry = prescriptionStore.dentalPiecesMap.get(`${stopsDistal},Lower`);
                const lowerStopsOcclusalTeethEntry = prescriptionStore.dentalPiecesMap.get(`${stopsOcclusal},Lower`);
                const lowerEruptionFriendlyEntry = prescriptionStore.dentalPiecesMap.get(`${eruptionFriendly},Lower`);


                // Batch update the state for each part for Upper Arch
                setUpperScrewTeeth(upperScrewTeethEntry?.teethNumbers ?? []);
                setUpperAcrylicCoverage(upperAcrylicCoverageEntry?.teethNumbers ?? []);
                setUpperAdamsClaspTeeth(adamsClaspEntry?.teethNumbers ?? []);
                setUpperBallClaspTeeth(ballClaspEntry?.teethNumbers ?? []);
                setUpperCClaspTeeth(cClaspEntry?.teethNumbers ?? []);
                setUpperArrowClaspTeeth(arrowClaspEntry?.teethNumbers ?? []);
                handleUpper3DAxialSpring(axialSprint3DEntry?.teethNumbers ?? [6, 7, 8, 9, 10, 11]);
                // setUpper3DAxialSpring(axialSprint3DEntry?.teethNumbers ?? [6, 7, 8, 9, 10, 11]);
                setUpperCLoop3DAxialSpring(CLoopAxialSpringtEntry?.teethNumbers ?? []);
                setUpperStopsMesialTeeth(upperStopsMesialTeethEntry?.teethNumbers ?? []);
                setUpperStopsDistalTeeth(upperStopsDistalTeethEntry?.teethNumbers ?? []);
                setUpperStopsOcclusalTeeth(upperStopsOcclusalTeethEntry?.teethNumbers ?? []);
                setUpperFaceMaskHookTeeth(upperFaceMaskEntry?.teethNumbers ?? []);
                setUpperEruptionFriendly(upperEruptionFriendlyEntry?.teethNumbers ?? []);
                setUpperCoverageFocus(upperAcrylicCoverageEntry?.teethNumbers ? 'Occlusal Acrylic Coverage' : prescriptionStore.isConstructionFormInProgress ? 'No Occlusal Acrylic Coverage' : '');

                // Batch update the state for each part for Lower Arch
                //setLowerScrewTeeth(lowerScrewTeethEntry?.teethNumbers ?? []);
                setLowerAcrylicCoverage(lowerAcrylicCoverageEntry?.teethNumbers ?? []);
                setLowerAdamsClaspTeeth(lowerAdamsClaspEntry?.teethNumbers ?? []);
                setLowerBallClaspTeeth(lowerBallClaspEntry?.teethNumbers ?? []);
                setLowerCClaspTeeth(lowerCClaspEntry?.teethNumbers ?? []);
                setLowerArrowClaspTeeth(lowerArrowClaspEntry?.teethNumbers ?? []);
                handleLower3DAxialSpring(lowerAxialSprint3DEntry?.teethNumbers ?? [22, 23, 24, 25, 26, 27]);
                // setLower3DAxialSpring(lowerAxialSprint3DEntry?.teethNumbers ?? [22, 23, 24, 25, 26, 27]);
                setLowerCLoop3DAxialSpring(lowerCLoopAxialSpringtEntry?.teethNumbers ?? []);
                setLowerStopsMesialTeeth(lowerStopsMesialTeethEntry?.teethNumbers ?? []);
                setLowerStopsDistalTeeth(lowerStopsDistalTeethEntry?.teethNumbers ?? []);
                setLowerStopsOcclusalTeeth(lowerStopsOcclusalTeethEntry?.teethNumbers ?? []);
                setLowerEruptionFriendly(lowerEruptionFriendlyEntry?.teethNumbers ?? []);
                setLowerCoverageFocus(lowerAcrylicCoverageEntry?.teethNumbers ? 'Occlusal Acrylic Coverage' : prescriptionStore.isConstructionFormInProgress ? 'No Occlusal Acrylic Coverage' : '');
            }
        }
    }, [loadedValues, prescriptionStore.isUpdating, prescriptionStore, prescriptionStore.appliance]);

    const isAdjacent = (array) => {
        array.sort();
        var count = 0;
        for (let i = 0; i < array.length - 1; i++) {
            if (array[i] === array[i - 1] + 1) {
                count++;
            }
            if (array[i] === array[i + 1] - 1) {
                count++;
            }
        }
        return count !== 0;
    }


    // Base Options
    const [upperBaseOption, setUpperBaseOption] = useState('');
    const handleUpperBaseOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemUppersystemBaseId = upperBaseValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        setUpperBaseOption(event.currentTarget.value);
    };
    const [lowerBaseOption, setLowerBaseOption] = useState('');
    const handleLowerBaseOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemLowersystemBaseId = lowerBaseValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        setLowerBaseOption(event.currentTarget.value);
    };

    // Mandibular Advancement: mRNA only
    const [mandibularAdvancementOption, setMandibularAdvancementOption] = useState('');
    const handleMandibularAdvancementOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemUppersystemMandibularadvancementId = upperMandibularAdvancementValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        setMandibularAdvancementOption(event.currentTarget.value);
    };

    // Screw Options
    const [upperScrewOption, setUpperScrewOption] = useState('');
    const handleUpperScrewOptionOld = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUpperScrewOption(event.currentTarget.value);
        prescriptionStore.appliance.applianceSystemUpperSystemScrewId = upperScrewValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        if (event.currentTarget.value === "Y Split Standard") {
            setUnavailableTeeth([1, 2, 3, 4, 5, 12, 13, 14, 15, 16]);
        } else if (event.currentTarget.value === "Transverse") {
            // Clean teeths when selecting Transverse
            prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === upperScrewOption)?.id ?? -1) + ',Upper', { teethNumbers: [], arch: 'Upper' });
            setUpperScrewTeeth([]);
        }
    };

    useEffect(() => {
        // Check if the currently selected screw option is "Y Split Asymmetrical" on component load
        if (upperScrewOption === "Y Split Asymmetrical") {
            setAdvancedOptions(true); // Enable "Additional Options"
        }
    }, [upperScrewOption]);



    const handleUpperScrewOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        const selectedOption = event.currentTarget.value;
        setUpperScrewOption(selectedOption);
        prescriptionStore.appliance.applianceSystemUpperSystemScrewId = upperScrewValues.find(x => x.name === selectedOption)?.id ?? null;

        // Automatically toggle "Additional Options" if "Y Split Asymmetrical" is selected
        if (selectedOption === "Y Split Asymmetrical" || selectedOption === "Cruciform" || selectedOption === "Wireframe") {
            setAdvancedOptions(true);
        }

        // Handle other conditions for screw options
        if (selectedOption === "Y Split Standard") {
            setUnavailableTeeth([1, 2, 3, 4, 5, 12, 13, 14, 15, 16]);
        } else if (selectedOption === "Transverse") {
            prescriptionStore.dentalPiecesMap.set(
                (dentalPartsValues.find(x => x.partName === upperScrewOption)?.id ?? -1) + ',Upper',
                { teethNumbers: [], arch: 'Upper' }
            );
            setUpperScrewTeeth([]);
        }
    };

    const [upperScrewTeeth, setUpperScrewTeeth] = useState<number[]>([]);
    const handleUpperScrewTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === upperScrewOption)?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperScrewTeeth(teethNumbers);
    };

    // Clasping
    const [upperClaspingFocus, setUpperClaspingFocus] = useState('');
    const handleUpperClaspingFocus = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
        prescriptionStore.appliance.applianceSystemUpperSystemClaspsId = key;
        setUpperClaspingFocus(event.currentTarget.value);

        var allTeethSelected = _.concat(upperClaspingOptions["Adams Clasp"].valueHolder, upperClaspingOptions["Ball Clasp"].valueHolder,
            upperClaspingOptions["C Clasp"].valueHolder, upperClaspingOptions.Arrow.valueHolder, [6, 7, 8, 9, 10, 11]);

        setUnavailableTeeth(allTeethSelected.filter(x => !(upperClaspingOptions[event.currentTarget.value].valueHolder.includes(x))).filter(distinct));

    };

    const [upperAdamsClaspTeeth, setUpperAdamsClaspTeeth] = useState<number[]>([]);
    const handleUpperAdamsClaspTeeth = (teethNumbers: number[]) => {
        setIsAdjacentClasp(isAdjacent(teethNumbers));
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Adams Clasp')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperAdamsClaspTeeth(teethNumbers);

    };
    // Initialize state for upper ball clasp teeth with an empty array
    const [upperBallClaspTeeth, setUpperBallClaspTeeth] = useState<number[]>([]);

    // Function to handle updates to the upper ball clasp teeth
    const handleUpperBallClaspTeeth = (teethNumbers: number[]) => {
        // Find the dental part with the name 'Ball Clasp' and get its ID
        const ballClaspPartId = dentalPartsValues.find(x => x.partName === 'Ball Clasp')?.id;

        // Check if the part ID was found
        if (ballClaspPartId === undefined) {
            console.error("Error: No part ID found for 'Ball Clasp'");
            return; // Exit the function if no valid part ID is found
        }

        // Create a key by combining the part ID with 'Upper'
        const key = `${ballClaspPartId},Upper`;

        // Update the dental pieces map in the prescription store with the new teeth numbers and arch
        prescriptionStore.dentalPiecesMap.set(key, { teethNumbers: teethNumbers, arch: 'Upper' });

        // Update the state with the new teeth numbers
        setUpperBallClaspTeeth(teethNumbers);
    };
    const [upperCClaspTeeth, setUpperCClaspTeeth] = useState<number[]>([]);
    const handleUpperCClaspTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'C Clasp')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperCClaspTeeth(teethNumbers);
    };
    const [upperArrowClaspTeeth, setUpperArrowClaspTeeth] = useState<number[]>([]);
    const handleUpperArrowClaspTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Arrow Clasp')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperArrowClaspTeeth(teethNumbers);
    };

    const upperClaspingOptions = {
        "Adams Clasp": {
            valueHolder: upperAdamsClaspTeeth,
            handler: handleUpperAdamsClaspTeeth,
            advanced: false
        },
        "Ball Clasp": {
            valueHolder: upperBallClaspTeeth,
            handler: handleUpperBallClaspTeeth,
            advanced: false
        },
        "C Clasp": {
            valueHolder: upperCClaspTeeth,
            handler: handleUpperCClaspTeeth,
            advanced: true
        },
        "Arrow": {
            valueHolder: upperArrowClaspTeeth,
            handler: handleUpperArrowClaspTeeth,
            advanced: true
        },
    };

    const [lowerClaspingFocus, setLowerClaspingFocus] = useState('');
    const handleLowerClaspingFocus = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
        prescriptionStore.appliance.applianceSystemLowerSystemClaspsId = key;
        setLowerClaspingFocus(event.currentTarget.value);

        var allTeethSelected = _.concat(lowerClaspingOptions["Adams Clasp"].valueHolder, lowerClaspingOptions["Ball Clasp"].valueHolder,
            lowerClaspingOptions["C Clasp"].valueHolder, lowerClaspingOptions.Arrow.valueHolder, [27, 26, 25, 24, 23, 22]);

        setUnavailableTeeth(allTeethSelected.filter(x => !(lowerClaspingOptions[event.currentTarget.value].valueHolder.includes(x))).filter(distinct));
    };
    const [lowerAdamsClaspTeeth, setLowerAdamsClaspTeeth] = useState<number[]>([]);
    const handleLowerAdamsClaspTeeth = (teethNumbers: number[]) => {
        setIsAdjacentClasp(isAdjacent(teethNumbers));
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Adams Clasp')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerAdamsClaspTeeth(teethNumbers);
    };
    const [lowerBallClaspTeeth, setLowerBallClaspTeeth] = useState<number[]>([]);
    const handleLowerBallClaspTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Ball Clasp')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerBallClaspTeeth(teethNumbers);
    };
    const [lowerCClaspTeeth, setLowerCClaspTeeth] = useState<number[]>([]);
    const handleLowerCClaspTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'C Clasp')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerCClaspTeeth(teethNumbers);
    };
    const [lowerArrowClaspTeeth, setLowerArrowClaspTeeth] = useState<number[]>([]);
    const handleLowerArrowClaspTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Arrow Clasp')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerArrowClaspTeeth(teethNumbers);
    };
    const lowerClaspingOptions = {
        "Adams Clasp": {
            valueHolder: lowerAdamsClaspTeeth,
            handler: handleLowerAdamsClaspTeeth,
            advanced: false,
        },
        "Ball Clasp": {
            valueHolder: lowerBallClaspTeeth,
            handler: handleLowerBallClaspTeeth,
            advanced: false,
        },
        "C Clasp": {
            valueHolder: lowerCClaspTeeth,
            handler: handleLowerCClaspTeeth,
            advanced: true,
        },
        "Arrow": {
            valueHolder: lowerArrowClaspTeeth,
            handler: handleLowerArrowClaspTeeth,
            advanced: true,
        },
    };
    const claspingImages = {
        "Adams Clasp": "/images/components/AdamsClasp.jpg",
        "Ball Clasp": "/images/components/BallClasp.jpg",
        "C Clasp": "/images/components/CClasp.jpg",
        "Arrow": "/images/components/ArrowClasp.png",
    };


    // Stops
    const [upperStopsFocus, setUpperStopsFocus] = useState('');
    const handleUpperStopsFocus = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUpperStopsFocus(event.currentTarget.value);
    };
    const [upperStopsMesialTeeth, setUpperStopsMesialTeeth] = useState<number[]>([]);
    const handleUpperStopsMesialTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Stops Mesial')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperStopsMesialTeeth(teethNumbers);
    };
    const [upperStopsDistalTeeth, setUpperStopsDistalTeeth] = useState<number[]>([]);
    const handleUpperStopsDistalTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Stops Distal')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperStopsDistalTeeth(teethNumbers);
    };
    const [upperStopsOcclusalTeeth, setUpperStopsOcclusalTeeth] = useState<number[]>([]);
    const handleUpperStopsOcclusalTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Stops Occlusal')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperStopsOcclusalTeeth(teethNumbers);
    };
    const upperStopsOptions = {
        "Mesial": {
            valueHolder: upperStopsMesialTeeth,
            handler: handleUpperStopsMesialTeeth,
        },
        "Distal": {
            valueHolder: upperStopsDistalTeeth,
            handler: handleUpperStopsDistalTeeth,
        },
        "Occlusal": {
            valueHolder: upperStopsOcclusalTeeth,
            handler: handleUpperStopsOcclusalTeeth,
        },
    };
    const [lowerStopsFocus, setLowerStopsFocus] = useState('');
    const handleLowerStopsFocus = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLowerStopsFocus(event.currentTarget.value);
    };
    const [lowerStopsMesialTeeth, setLowerStopsMesialTeeth] = useState<number[]>([]);
    const handleLowerStopsMesialTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Stops Mesial')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerStopsMesialTeeth(teethNumbers);
    };
    const [lowerStopsDistalTeeth, setLowerStopsDistalTeeth] = useState<number[]>([]);
    const handleLowerStopsDistalTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Stops Distal')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerStopsDistalTeeth(teethNumbers);
    };
    const [lowerStopsOcclusalTeeth, setLowerStopsOcclusalTeeth] = useState<number[]>([]);
    const handleLowerStopsOcclusalTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Stops Occlusal')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerStopsOcclusalTeeth(teethNumbers);
    };
    const lowerStopsOptions = {
        "Mesial": {
            valueHolder: lowerStopsMesialTeeth,
            handler: handleLowerStopsMesialTeeth,
        },
        "Distal": {
            valueHolder: lowerStopsDistalTeeth,
            handler: handleLowerStopsDistalTeeth,
        },
        "Occlusal": {
            valueHolder: lowerStopsOcclusalTeeth,
            handler: handleLowerStopsOcclusalTeeth,
        },
    };
    const stopImages = {
        "Mesial": "/images/components/MesialStop.png",
        "Distal": "/images/components/DistalStop.jpg",
        "Occlusal": "/images/components/OcclusalStop.png",
    };

    // Hooks Upper
    const [includeClassIIHooksUpper, setIncludeClassIIHooksUpper] = react.useState(false);
    const handleClassIIHooksUpper = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemUpperSystemElasticHooksClassIIBool = event;
        setIncludeClassIIHooksUpper(event);
    }

    const [includeVerticalHooksUpper, setIncludeVerticalHooksUpper] = react.useState(false);
    const handleVerticalHooksUpper = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemUpperSystemElasticHooksVerticalBool = event;
        setIncludeVerticalHooksUpper(event);
    }

    const [includeFacemaskHooks, setIncludeFacemaskHooks] = react.useState(false);
    const handleIncludeFacemaskHooks = (event: boolean) => {
        setIncludeFacemaskHooks(event)
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Facemask Hooks')?.id ?? -1) + ',Upper', { teethNumbers: [], arch: 'Upper' });
        setUpperFaceMaskHookTeeth([]);
    }

    const [upperFaceMaskHookTeeth, setUpperFaceMaskHookTeeth] = useState<number[]>([]);
    const handleUpperFaceMaskHookTeeth = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Facemask Hooks')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperFaceMaskHookTeeth(teethNumbers);
    };

    useEffect(() => {
        if (upperFaceMaskHookTeeth && upperFaceMaskHookTeeth.length > 0) {
            setIncludeFacemaskHooks(true);
        }
    }, [upperFaceMaskHookTeeth]);

    // Hooks Lower
    const [includeClassIIHooksLower, setIncludeClassIIHooksLower] = react.useState(false);
    const handleClassIIHooksLower = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemLowerSystemElasticHooksClassIIBool = event;
        setIncludeClassIIHooksLower(event);
    }

    const [includeVerticalHooksLower, setIncludeVerticalHooksLower] = react.useState(false);
    const handleVerticalHooksLower = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemLowerSystemElasticHooksVerticalBool = event;
        setIncludeVerticalHooksLower(event);
    }

    // Bow
    const [upperBowOption, setUpperBowOption] = useState('');
    const handleUpperBowOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemUppersystemBowId = upperBowValues.find(x => x.name.trim().toUpperCase() === event.currentTarget.value.trim().toUpperCase())?.id ?? null;
        setUpperBowOption(event.currentTarget.value);
    };
    const [lowerBowOption, setLowerBowOption] = useState('');
    const handleLowerBowOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemLowersystemBowId = lowerBowValues.find(x => x.name.trim().toUpperCase() === event.currentTarget.value.trim().toUpperCase())?.id ?? null;
        setLowerBowOption(event.currentTarget.value);
    };

    useEffect(() => {
        // Check if the currently selected screw option is "Y Split Asymmetrical" on component load
        if (upperBowOption === "Circumferential (wrap)" || upperBowOption === 'None') {
            setAdvancedOptions(true); // Enable "Additional Options"
        }
    }, [upperBowOption]);

    // Acrylic Occlusal Coverage
    const [upperCoverageFocus, setUpperCoverageFocus] = useState('');
    const handleUpperCoverageFocus = (event: React.MouseEvent<HTMLButtonElement>) => {
        setUpperCoverageFocus(event.currentTarget.value);
        if (event.currentTarget.value === "No Occlusal Acrylic Coverage") {
            prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Occlusal Acrylic Coverage')?.id ?? -1) + ',Upper', { teethNumbers: [], arch: 'Upper' });
            setUpperAcrylicCoverage([]);

        }
    };
    const [upperAcrylicCoverage, setUpperAcrylicCoverage] = useState<number[]>([]);
    const handleUpperAcrylicCoverage = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Occlusal Acrylic Coverage')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        if (prescriptionStore.dentalPiecesMap) {
            setUpperAcrylicCoverage(teethNumbers);
        }
    };

    const [lowerCoverageFocus, setLowerCoverageFocus] = useState('');
    const handleLowerCoverageFocus = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLowerCoverageFocus(event.currentTarget.value);
        if (event.currentTarget.value === "No Occlusal Acrylic Coverage") {
            prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Occlusal Acrylic Coverage')?.id ?? -1) + ',Lower', { teethNumbers: [], arch: 'Lower' });
            setLowerAcrylicCoverage([]);
        }
    };
    const [lowerAcrylicCoverage, setLowerAcrylicCoverage] = useState<number[]>([]);
    const handleLowerAcrylicCoverage = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Occlusal Acrylic Coverage')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerAcrylicCoverage(teethNumbers);
    };

    // Axial Springs
    const [upperAxialSpringFocus, setUpperAxialSpringFocus] = useState('');
    const handleUpperAxialSpringFocus = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
        prescriptionStore.appliance.applianceSystemUpperSystemAxialSpringsId = key;
        setUpperAxialSpringFocus(event.currentTarget.value);

        var availableUpperTeeth = [6, 7, 8, 9, 10, 11];
        var unavailableForCLoop3d: number[] = [];
        var unavailableTeeth = [1, 2, 3, 4, 5, 12, 13, 14, 15, 16];
        if (event.currentTarget.value === Object.keys(upperAxialSpringOptions)[1]) {
            unavailableForCLoop3d = Array.from(new Set(availableUpperTeeth.concat(upper3DAxialSpring))).filter(tooth => !upper3DAxialSpring.includes(tooth));
        }
        setUnavailableTeeth(unavailableForCLoop3d.concat(unavailableTeeth));
    };
    const [upper3DAxialSpring, setUpper3DAxialSpring] = useState<number[]>([]);
    const handleUpper3DAxialSpring = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === '3D Axial Springs')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpper3DAxialSpring(teethNumbers);
    };
    const [upperCLoop3DAxialSpring, setUpperCLoop3DAxialSpring] = useState<number[]>([]);
    const handleUpperCLoop3DAxialSpring = (teethNumbers: number[]) => {
        setUpperCLoop3DAxialSpring(teethNumbers);
        setIsMoreThanThreeCLoop((teethNumbers.length + lowerCLoop3DAxialSpring.length) >= 3);
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'C Loop 3D Axial Springs')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
    };
    const upperAxialSpringOptions = {
        "3D Axial Springs": {
            valueHolder: upper3DAxialSpring,
            handler: handleUpper3DAxialSpring,
        },
        "'C' Loop 3D Axial Springs": {
            valueHolder: upperCLoop3DAxialSpring,
            handler: handleUpperCLoop3DAxialSpring,
        },
    };
    const [lowerAxialSpringFocus, setLowerAxialSpringFocus] = useState('');
    const handleLowerAxialSpringFocus = (event: React.MouseEvent<HTMLButtonElement>, key: string) => {
        prescriptionStore.appliance.applianceSystemLowerSystemAxialSpringsId = key;
        setLowerAxialSpringFocus(event.currentTarget.value);

        var availableLowerTeeth = [22, 23, 24, 25, 26, 27];
        var unavailableForCLoop3d: number[] = [];
        var unavailableTeeth = [17, 18, 19, 20, 21, 28, 29, 30, 31, 32];
        if (event.currentTarget.value === Object.keys(lowerAxialSpringOptions)[1]) {
            unavailableForCLoop3d = Array.from(new Set(availableLowerTeeth.concat(lower3DAxialSpring))).filter(tooth => !lower3DAxialSpring.includes(tooth));
        }
        setUnavailableTeeth(unavailableForCLoop3d.concat(unavailableTeeth));
    };
    const [lower3DAxialSpring, setLower3DAxialSpring] = useState<number[]>([]);
    const handleLower3DAxialSpring = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === '3D Axial Springs')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLower3DAxialSpring(teethNumbers);
    };
    const [lowerCLoop3DAxialSpring, setLowerCLoop3DAxialSpring] = useState<number[]>([]);
    const handleLowerCLoop3DAxialSpring = (teethNumbers: number[]) => {
        setLowerCLoop3DAxialSpring(teethNumbers);
        setIsMoreThanThreeCLoop((teethNumbers.length + upperCLoop3DAxialSpring.length) >= 3);
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'C Loop 3D Axial Springs')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
    };
    const lowerAxialSpringOptions = {
        "3D Axial Springs": {
            valueHolder: lower3DAxialSpring,
            handler: handleLower3DAxialSpring,
        },
        "'C' Loop 3D Axial Springs": {
            valueHolder: lowerCLoop3DAxialSpring,
            handler: handleLowerCLoop3DAxialSpring,
        },
    };

    // Other Options
    const [isUpperEruptionFriendlyConsideration, setIsUpperEruptionFriendlyConsideration] = react.useState(false);
    const handleUpperEruptionFriendlyConsideration = (event: boolean) => {
        setIsUpperEruptionFriendlyConsideration(event)
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Eruption Friendly')?.id ?? -1) + ',Upper', { teethNumbers: [], arch: 'Upper' });
        setUpperEruptionFriendly([]);
    };

    const [isLowerEruptionFriendlyConsideration, setIsLowerEruptionFriendlyConsideration] = react.useState(false);
    const handleLowerEruptionFriendlyConsideration = (event: boolean) => {
        setIsLowerEruptionFriendlyConsideration(event)
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Eruption Friendly')?.id ?? -1) + ',Lower', { teethNumbers: [], arch: 'Lower' });
        setLowerEruptionFriendly([]);
    };

    const [upperEruptionFriendly, setUpperEruptionFriendly] = useState<number[]>([]);
    const handleUpperEruptionFriendly = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Eruption Friendly')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setUpperEruptionFriendly(teethNumbers);
    };
    const [lowerEruptionFriendly, setLowerEruptionFriendly] = useState<number[]>([]);
    const handleLowerEruptionFriendly = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Eruption Friendly')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setLowerEruptionFriendly(teethNumbers);
    };

    useEffect(() => {
        if (upperEruptionFriendly && upperEruptionFriendly.length > 0) {
            setIsUpperEruptionFriendlyConsideration(true);
        }
        if (lowerEruptionFriendly && lowerEruptionFriendly.length > 0) {
            setIsLowerEruptionFriendlyConsideration(true);
        }
    }, [upperEruptionFriendly, upperEruptionFriendly]);

    const [includeOSAExtension, setIncludeOSAExtension] = react.useState(false);
    const handleOSAExtension = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemUpperSystemOsaExtensionBool = event;
        setIncludeOSAExtension(event);
    }
    const [osaExtensionLength, setOSAExtensionLength] = useState(0);
    const handleOSAExtensionLength = (event: number) => {
        prescriptionStore.appliance.applianceSystemUpperSystemOsaExtensionLengthInt = event;
        setOSAExtensionLength(event);
    }
    const [includeFacemask, setIncludeFacemask] = react.useState(false);

    const [includeAnteriorIndexRamp, setIncludeAnteriorIndexRamp] = react.useState(false);
    const handleAnteriorIndexRamp = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemUpperSystemAnteriorIndexRampBool = event;
        setIncludeAnteriorIndexRamp(event);
    }

    const [includeAnteriorBitePlane, setIncludeAnteriorBitePlane] = react.useState(false);
    const handleAnteriorBitePlane = (event: boolean) => {
        prescriptionStore.appliance.applianceSystemUpperSystemAnteriorBitePlaneBool = event;
        setIncludeAnteriorBitePlane(event);

    }

    const upperTabs = [
        {
            value: "base", label: "Base", required: true,
            completed: upperBaseOption
        },
        ...((constructionType === RxAppliances.MRNAV || constructionType === RxAppliances.MRNASAU) ? [{
            value: "advancement", label: "Advancement", required: true, completed: mandibularAdvancementOption
        }] : []),
        {
            value: "screw", label: "Expansion", required: true,
            completed: (upperScrewOption && (upperScrewOption !== "Transverse" && upperScrewTeeth.length > 1)) || upperScrewOption === "Transverse" || upperScrewOption === "Wireframe"
        },
        {
            value: "clasps", label: "Clasps", required: true,
            completed: (upperAdamsClaspTeeth.length + upperBallClaspTeeth.length + upperCClaspTeeth.length + upperArrowClaspTeeth.length) > 1
        },
        { value: "bow", label: "Bow", required: true, completed: upperBowOption },
        {
            value: "occlusalcoverage", label: "Occlusal Coverage", required: true,
            completed: upperAcrylicCoverage.length > 1 || upperCoverageFocus === "No Occlusal Acrylic Coverage"
        },
        {

            value: "axialsprings", label: "Axial Springs", required: true,
            completed: upper3DAxialSpring.length > 1
        },
        { value: "stops", label: "Stops", required: false, completed: undefined },
        { value: "hooks", label: "Hooks", required: false, completed: undefined },
        { value: "other", label: "Other", required: false, completed: undefined }
    ];
    const lowerTabs = [
        {
            value: "base", label: "Base", required: true,
            completed: lowerBaseOption
        },
        {
            value: "clasps", label: "Clasps", required: true,
            completed: (lowerAdamsClaspTeeth.length + lowerBallClaspTeeth.length + lowerCClaspTeeth.length + lowerArrowClaspTeeth.length) > 1
        },
        { value: "bow", label: "Bow", required: true, completed: lowerBowOption },
        {
            value: "occlusalcoverage", label: "Occlusal Coverage", required: true,
            completed: lowerAcrylicCoverage.length > 1 || lowerCoverageFocus === "No Occlusal Acrylic Coverage"
        },
        {
            value: "axialsprings", label: "Axial Springs", required: true,
            completed: lower3DAxialSpring.length > 1
        },
        { value: "hooks", label: "Hooks", required: false, completed: undefined },
        { value: "stops", label: "Stops", required: false, completed: undefined },
        { value: "other", label: "Other", required: false, completed: undefined }
    ];

    const rxUpperComplete =
        upperBaseOption !== '' &&
        (((constructionType === RxAppliances.MRNAV || constructionType === RxAppliances.MRNASAU) && mandibularAdvancementOption !== '')
            || (constructionType === RxAppliances.MMRNAV || constructionType === RxAppliances.MMRNASAU)
            || !constructionType.includes("mRNA")
        ) &&
        ((upperScrewOption && (upperScrewOption !== "Transverse" && upperScrewTeeth.length > 1)) || upperScrewOption === "Transverse" || upperScrewOption === "Wireframe") &&
        (upperAdamsClaspTeeth.length + upperBallClaspTeeth.length + upperCClaspTeeth.length + upperArrowClaspTeeth.length) > 1 &&
        upperBowOption !== '' &&
        (upperAcrylicCoverage.length > 1 || upperCoverageFocus === "No Occlusal Acrylic Coverage") &&
        upper3DAxialSpring.length > 1;

    const rxLowerComplete =
        lowerBaseOption !== '' &&
        (lowerAdamsClaspTeeth.length + lowerBallClaspTeeth.length + lowerCClaspTeeth.length + lowerArrowClaspTeeth.length) > 1 &&
        lowerBowOption !== '' &&
        (lowerAcrylicCoverage.length > 1 || lowerCoverageFocus === "No Occlusal Acrylic Coverage") &&
        lower3DAxialSpring.length > 1;

    useEffect(() => {
        if (constructionType.includes("Upper") && rxUpperComplete) {
            prescriptionStore.setIsConstructionComplete(true);
        }
        else if (constructionType.includes("Lower") && rxLowerComplete) {
            prescriptionStore.setIsConstructionComplete(true);
        }
        else if (!constructionType.includes("Upper") && !constructionType.includes("Lower") && rxUpperComplete && rxLowerComplete) {
            prescriptionStore.setIsConstructionComplete(true);
        } else {
            prescriptionStore.setIsConstructionComplete(false);
        }
    }, [rxUpperComplete, rxLowerComplete, prescriptionStore.selectedConstructionType]);

    // Screen Dynamics
    const [constructionFocus, setConstructionFocus] = useState('Upper');
    const [currentUpperComponentValue, setCurrentUpperComponentValue] = useState(upperTabs[0].value);
    const handleCurrentUpperComponentChange = (event, newValue) => {
        setCurrentUpperComponentValue(newValue);

        if (newValue === 'screw') {
            if (prescriptionStore.appliance.applianceSystemUpperSystemScrewId === upperScrewValues.find(x => x.name === "Y Split Standard")?.id) {
                setUnavailableTeeth([1, 2, 3, 4, 5, 12, 13, 14, 15, 16]);
            }
        }

        if (newValue === 'clasps') {
            var allTeethSelected = _.concat(upperClaspingOptions["Adams Clasp"].valueHolder, upperClaspingOptions["Ball Clasp"].valueHolder,
                upperClaspingOptions["C Clasp"].valueHolder, upperClaspingOptions.Arrow.valueHolder, [6, 7, 8, 9, 10, 11]);

            setUnavailableTeeth(allTeethSelected.filter(x => !(upperClaspingOptions[prescriptionStore.appliance.applianceSystemUpperSystemClaspsId ?? ""].valueHolder.includes(x))).filter(distinct));
        }

        if (newValue === 'axialsprings') {
            var availableUpperTeeth = [6, 7, 8, 9, 10, 11];
            var unavailableForCLoop3d: number[] = [];
            var unavailableTeeth = [1, 2, 3, 4, 5, 12, 13, 14, 15, 16];
            if (event.currentTarget.value === Object.keys(upperAxialSpringOptions)[1]) {
                unavailableForCLoop3d = Array.from(new Set(availableUpperTeeth.concat(upper3DAxialSpring))).filter(tooth => !upper3DAxialSpring.includes(tooth));
            }
            setUnavailableTeeth(unavailableForCLoop3d.concat(unavailableTeeth));
        }
    };
    const [currentLowerComponentValue, setCurrentLowerComponentValue] = useState(lowerTabs[0].value);
    const handleCurrentLowerComponentChange = (event, newValue) => {
        setCurrentLowerComponentValue(newValue);

        if (newValue === 'clasps') {
            var allTeethSelected = _.concat(lowerClaspingOptions["Adams Clasp"].valueHolder, lowerClaspingOptions["Ball Clasp"].valueHolder,
                lowerClaspingOptions["C Clasp"].valueHolder, lowerClaspingOptions.Arrow.valueHolder, [27, 26, 25, 24, 23, 22]);

            setUnavailableTeeth(allTeethSelected.filter(x => !(lowerClaspingOptions[prescriptionStore.appliance.applianceSystemLowerSystemClaspsId ?? ""].valueHolder.includes(x))).filter(distinct));
        }

        if (newValue === 'axialsprings') {
            var availableLowerTeeth = [21, 22, 23, 24, 25, 26, 27, 28];
            var unavailableForCLoop3d: number[] = [];
            var unavailableTeeth = [17, 18, 19, 20, 29, 30, 31, 32];
            if (event.currentTarget.value === Object.keys(lowerAxialSpringOptions)[1]) {
                unavailableForCLoop3d = Array.from(new Set(availableLowerTeeth.concat(lower3DAxialSpring))).filter(tooth => !lower3DAxialSpring.includes(tooth));
            }
            setUnavailableTeeth(unavailableForCLoop3d.concat(unavailableTeeth));
        }
    };
    const handleUpperButtonClick = () => {
        setConstructionFocus("Upper");
    };
    const handleLowerButtonClick = () => {
        setConstructionFocus("Lower");
    };
    const handleDesignReviewButtonClick = () => {
        setConstructionFocus("Design Review");
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box position="relative">
                    <Grid container spacing={2} justifyContent={'center'}>
                        {(constructionType === RxAppliances.DNADUAL ||
                            constructionType === RxAppliances.DNASAU ||
                            constructionType === RxAppliances.MRNAV ||
                            constructionType === RxAppliances.MRNASAU ||
                            constructionType === RxAppliances.MMRNAV ||
                            constructionType === RxAppliances.MMRNASAU) &&
                            <Grid item xs={2}>
                                <Button
                                    fullWidth
                                    variant={constructionFocus === "Upper" ? "contained" : "outlined"}
                                    onClick={handleUpperButtonClick}
                                >
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            Upper Design
                                        </Grid>
                                        <Grid item>
                                            {rxUpperComplete ? <CompletedIcon /> : <RequiredIcon />}
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        }
                        {(constructionType === RxAppliances.DNADUAL ||
                            constructionType === RxAppliances.DNASAL ||
                            constructionType === RxAppliances.MRNAV ||
                            constructionType === RxAppliances.MRNASAL ||
                            constructionType === RxAppliances.MMRNAV ||
                            constructionType === RxAppliances.MMRNASAL) &&
                            <Grid item xs={2}>
                                <Button
                                    fullWidth
                                    disabled={constructionType.includes("Upper")}
                                    variant={constructionFocus === "Lower" ? "contained" : "outlined"}
                                    onClick={handleLowerButtonClick}
                                >
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            Lower Design
                                        </Grid>
                                        <Grid item>
                                            {rxLowerComplete ? <CompletedIcon /> : <RequiredIcon />}
                                        </Grid>
                                    </Grid>
                                </Button>
                            </Grid>
                        }
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                // disabled={constructionType.includes("Upper")}
                                variant={constructionFocus === "Design Review" ? "contained" : "outlined"}
                                onClick={handleDesignReviewButtonClick}
                            >
                                Review Design
                            </Button>
                        </Grid>
                    </Grid>
                    <Box position="absolute" top={10} right={0}>
                        <Grid direction={'column'} container paddingRight={5} paddingTop={1}>
                            <Grid item>
                                <Typography variant="h6">
                                    Additional Options
                                </Typography>
                            </Grid>
                            <Grid item justifyContent={'right'} container>
                                <Switch checked={advancedOptions} onChange={handleAdvancedOptions} />
                            </Grid>


                            {/* <FormControlLabel
                                control={<Switch />}
                            /> */}
                        </Grid>

                    </Box>
                </Box>
            </Grid>
            {constructionFocus === "Upper" &&
                <Grid container paddingX={3} margin={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Card
                                    variant="outlined"
                                    sx={(theme) => ({
                                        background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                    })}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        value={currentUpperComponentValue}
                                        onChange={handleCurrentUpperComponentChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                        sx={{
                                            height: '100%',
                                            '.MuiTabs-indicator': {
                                                left: 0,
                                                right: 'auto',
                                                width: '100%'
                                            },
                                            '.MuiTab-root': {
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                                paddingLeft: '16px',
                                                '& .MuiTab-wrapper': {
                                                    textAlign: 'left !important',
                                                },
                                            }
                                        }}
                                    >
                                        {upperTabs.map((tab, key) => (
                                            <Tab key={key} value={tab.value}
                                                label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                        <Typography sx={{ marginRight: 1 }}>{tab.label}</Typography>
                                                        {tab.completed
                                                            ? <CompletedIcon />
                                                            : tab.required && <RequiredIcon />
                                                        }
                                                    </Box>
                                                }
                                                sx={{
                                                    marginBottom: 2,
                                                    textTransform: 'none', // Prevents uppercase transformation
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </Card>
                            </Grid>
                            <Grid item xs={10}>
                                <RxConstructionContainer
                                    rxComplete={rxUpperComplete}
                                    rxConstructionTitle={"Upper Construction"}
                                    rxConstructionComponent={
                                        <>
                                            {currentUpperComponentValue === "base" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={2}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Acrylic"
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                    variant={upperBaseOption === "Acrylic" ? "contained" : "outlined"}
                                                                    onClick={handleUpperBaseOption}
                                                                >
                                                                    Acrylic
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperBaseOption === "Acrylic" && <CardMedia
                                                                component="img"
                                                                height="350"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/DNABase_Acrylic.png"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            {currentUpperComponentValue === "advancement" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={2}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Classic"
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                    variant={mandibularAdvancementOption === "Classic" ? "contained" : "outlined"}
                                                                    onClick={handleMandibularAdvancementOption}
                                                                >
                                                                    Classic
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Dorsal"
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                    disabled={prescriptionStore.selectedLab.name === 'STAA Lab'}
                                                                    variant={mandibularAdvancementOption === "Dorsal" ? "contained" : "outlined"}
                                                                    onClick={handleMandibularAdvancementOption}
                                                                >
                                                                    Dorsal
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {mandibularAdvancementOption === "Classic" && <CardMedia
                                                                component="img"
                                                                height="350"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/RPEStyle.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {mandibularAdvancementOption === "Dorsal" && <CardMedia
                                                                component="img"
                                                                height="350"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/DorsalStyle.png"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            {currentUpperComponentValue === "screw" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={2}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Transverse"
                                                                    variant={upperScrewOption === "Transverse" ? "contained" : "outlined"}
                                                                    onClick={handleUpperScrewOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Transverse
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Y Split Standard"
                                                                    variant={upperScrewOption === "Y Split Standard" ? "contained" : "outlined"}
                                                                    onClick={handleUpperScrewOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Y Split Standard
                                                                </Button>
                                                            </Grid>
                                                            {advancedOptions === true && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Y Split Asymmetrical"
                                                                    variant={upperScrewOption === "Y Split Asymmetrical" ? "contained" : "outlined"}
                                                                    onClick={handleUpperScrewOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Y Split Asymmetrical
                                                                </Button>
                                                            </Grid>}
                                                            {advancedOptions === true && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Cruciform"
                                                                    variant={upperScrewOption === "Cruciform" ? "contained" : "outlined"}
                                                                    onClick={handleUpperScrewOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Cruciform
                                                                </Button>
                                                            </Grid>}
                                                            {advancedOptions === true && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Wireframe"
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                    variant={upperScrewOption === "Wireframe" ? "contained" : "outlined"}
                                                                    onClick={handleUpperScrewOption}
                                                                >
                                                                    Wireframe
                                                                </Button>
                                                            </Grid>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperScrewOption === "Y Split Standard" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/SymmetricalScrew.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {upperScrewOption === "Y Split Asymmetrical" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/AsymmetricalScrew.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {upperScrewOption === "Transverse" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/TransverseScrew.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {upperScrewOption === "Cruciform" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/CruciformScrew.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {upperScrewOption === "Wireframe" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/DNABase_Wireframe.png"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {upperScrewOption.includes("Y Split")
                                                                ? "Y Split Cuts Distal to #s :"
                                                                : upperScrewOption.includes("Cruciform") && "Cruciform Cuts Distal on #s"}
                                                        </Typography>
                                                        {(upperScrewOption.includes("Y Split") || upperScrewOption.includes("Cruciform")) &&
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={upperScrewTeeth.length > 0 ? upperScrewTeeth.join(', ') : 'None'}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={handleUpperScrewTeeth}
                                                                        displayLower={false}
                                                                        displayUpper={true}
                                                                        ruleDisabledTeeth={unavailableTeeth}
                                                                        patientDisabledTeeth={[]}
                                                                        currentTeeth={upperScrewTeeth} />
                                                                </Box>
                                                            </>}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "clasps" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={2}>
                                                            {Object.keys(upperClaspingOptions).map((option) => {
                                                                const optionDetails = upperClaspingOptions[option];
                                                                if (!advancedOptions && optionDetails.advanced) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <Grid item xs={12} key={option}>
                                                                        <Button
                                                                            fullWidth
                                                                            value={option}
                                                                            variant={upperClaspingFocus === option ? "contained" : "outlined"}
                                                                            onClick={event => handleUpperClaspingFocus(event, option)}
                                                                            sx={{
                                                                                justifyContent: 'space-between'
                                                                            }}
                                                                        >
                                                                            {option}
                                                                            {optionDetails.valueHolder && optionDetails.valueHolder.length > 0 && <MoreInfoIndicator />}
                                                                        </Button>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperClaspingFocus in claspingImages && <CardMedia
                                                                component="img"
                                                                height="175"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image={claspingImages[upperClaspingFocus]}
                                                                alt={`${upperClaspingFocus} Image`}
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {upperClaspingFocus}
                                                        </Typography>
                                                        {upperClaspingFocus in upperClaspingOptions && (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={upperClaspingOptions[upperClaspingFocus].valueHolder.length > 0 ? upperClaspingOptions[upperClaspingFocus].valueHolder.join(', ') : 'None'}
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1} key={upperClaspingFocus}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={upperClaspingOptions[upperClaspingFocus].handler}
                                                                        displayLower={false}
                                                                        displayUpper={true}
                                                                        ruleDisabledTeeth={unavailableTeeth}
                                                                        patientDisabledTeeth={missingTeeth}
                                                                        currentTeeth={upperClaspingOptions[upperClaspingFocus].valueHolder} />
                                                                </Box>
                                                            </>
                                                        )}
                                                        {(upperClaspingFocus === "Ball Clasp" || upperClaspingFocus === "Arrow") &&
                                                            <Typography variant="h5">
                                                                Clasp will be placed in the embrasure distal to the tooth selected
                                                            </Typography>}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "bow" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Standard"
                                                                    variant={upperBowOption === "Standard" ? "contained" : "outlined"}
                                                                    onClick={handleUpperBowOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Standard
                                                                </Button>
                                                            </Grid>
                                                            {advancedOptions && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Circumferential (wrap)"
                                                                    variant={upperBowOption === "Circumferential (wrap)" ? "contained" : "outlined"}
                                                                    onClick={handleUpperBowOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Circumferential (Wrap)
                                                                </Button>
                                                            </Grid>}
                                                            {advancedOptions && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="None"
                                                                    variant={upperBowOption === "None" ? "contained" : "outlined"}
                                                                    onClick={handleUpperBowOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    None
                                                                </Button>
                                                            </Grid>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperBowOption === "Standard" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/StandardBow.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {upperBowOption === "Circumferential (Wrap)" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/CircumferentialBow.png"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "occlusalcoverage" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value={"Occlusal Acrylic Coverage"}
                                                                    variant={upperCoverageFocus === "Occlusal Acrylic Coverage" ? "contained" : "outlined"}
                                                                    onClick={handleUpperCoverageFocus}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Occlusal Acrylic Coverage
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value={"No Occlusal Acrylic Coverage"}
                                                                    variant={upperCoverageFocus === "No Occlusal Acrylic Coverage" ? "contained" : "outlined"}
                                                                    onClick={handleUpperCoverageFocus}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    No Occlusal Acrylic Coverage
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {upperCoverageFocus === "No Occlusal Acrylic Coverage" && <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/NoCoverage.png"
                                                                alt="BaseImage"
                                                            />
                                                        </Grid>
                                                    </Grid>}
                                                    {upperCoverageFocus === "Occlusal Acrylic Coverage" && <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperCoverageFocus === "Occlusal Acrylic Coverage" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/OcclusalCoverage.png"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {upperCoverageFocus}
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            value={upperAcrylicCoverage.length > 0 ? upperAcrylicCoverage.join(', ') : 'None'}
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                        />
                                                        <Box paddingY={2} paddingX={1}>
                                                            <RxTeethSelection
                                                                onTeethSelected={handleUpperAcrylicCoverage}
                                                                displayLower={false}
                                                                displayUpper={true}
                                                                ruleDisabledTeeth={[6, 7, 8, 9, 10, 11]}
                                                                patientDisabledTeeth={[]}
                                                                currentTeeth={upperAcrylicCoverage} />
                                                        </Box>
                                                    </Grid>}
                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "axialsprings" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            {Object.keys(upperAxialSpringOptions).map((option) => {
                                                                const optionDetails = upperAxialSpringOptions[option];
                                                                return (<Grid item key={option} xs={12}>
                                                                    <Button
                                                                        fullWidth
                                                                        value={option}
                                                                        variant={upperAxialSpringFocus === option ? "contained" : "outlined"}
                                                                        onClick={event => handleUpperAxialSpringFocus(event, option)}
                                                                        sx={{
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        {option}
                                                                        {optionDetails.valueHolder && optionDetails.valueHolder.length > 0 && <MoreInfoIndicator />}
                                                                    </Button>
                                                                </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperAxialSpringFocus === "3D Axial Springs" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/3DAxialSpring.png"
                                                                alt="SpringImage"
                                                            />}
                                                            {upperAxialSpringFocus === "'C' Loop 3D Axial Springs" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/CLoopAxialSpring.png"
                                                                alt="SpringImage"
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {upperAxialSpringFocus}
                                                        </Typography>
                                                        {upperAxialSpringFocus in upperAxialSpringOptions && (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={upperAxialSpringOptions[upperAxialSpringFocus].valueHolder.length > 0 ? upperAxialSpringOptions[upperAxialSpringFocus].valueHolder.join(', ') : 'None'}
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1} key={upperAxialSpringFocus}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={upperAxialSpringOptions[upperAxialSpringFocus].handler}
                                                                        displayLower={false}
                                                                        displayUpper={true}
                                                                        ruleDisabledTeeth={unavailableTeeth}
                                                                        patientDisabledTeeth={[]}
                                                                        currentTeeth={upperAxialSpringOptions[upperAxialSpringFocus].valueHolder} />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "stops" &&
                                                <Grid container>
                                                    <Grid xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            {Object.keys(upperStopsOptions).map((option) => {
                                                                const optionDetails = upperStopsOptions[option];
                                                                return (<Grid item key={option} xs={12}>
                                                                    <Button
                                                                        fullWidth
                                                                        value={option}
                                                                        variant={upperStopsFocus === option ? "contained" : "outlined"}
                                                                        onClick={handleUpperStopsFocus}
                                                                        sx={{
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        {option}
                                                                        {optionDetails.valueHolder && optionDetails.valueHolder.length > 0 && <MoreInfoIndicator />}
                                                                    </Button>
                                                                </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {upperStopsFocus in stopImages && <CardMedia
                                                                component="img"
                                                                height="175"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image={stopImages[upperStopsFocus]}
                                                                alt={`${upperStopsFocus} Image`}
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {upperStopsFocus}
                                                        </Typography>
                                                        {upperStopsFocus in upperStopsOptions && (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={upperStopsOptions[upperStopsFocus].valueHolder.length > 0 ? upperStopsOptions[upperStopsFocus].valueHolder.join(', ') : 'None'}
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1} key={upperStopsFocus}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={upperStopsOptions[upperStopsFocus].handler}
                                                                        displayLower={false}
                                                                        displayUpper={true}
                                                                        ruleDisabledTeeth={[]}
                                                                        patientDisabledTeeth={missingTeeth}
                                                                        currentTeeth={upperStopsOptions[upperStopsFocus].valueHolder} />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "hooks" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            {(constructionType === RxAppliances.DNADUAL ||
                                                                constructionType === RxAppliances.MRNAV ||
                                                                constructionType === RxAppliances.MMRNAV) && <>
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            fullWidth
                                                                            variant={includeClassIIHooksUpper ? "contained" : "outlined"}
                                                                            onClick={() => handleClassIIHooksUpper(!includeClassIIHooksUpper)}
                                                                            sx={{
                                                                                justifyContent: 'flex-start'
                                                                            }}
                                                                        >
                                                                            Class II Hooks
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            fullWidth
                                                                            variant={includeVerticalHooksUpper ? "contained" : "outlined"}
                                                                            onClick={() => handleVerticalHooksUpper(!includeVerticalHooksUpper)}
                                                                            sx={{
                                                                                justifyContent: 'flex-start'
                                                                            }}
                                                                        >
                                                                            Vertical Hooks
                                                                        </Button>
                                                                    </Grid>
                                                                </>}
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={includeFacemaskHooks ? "contained" : "outlined"}
                                                                    onClick={() => handleIncludeFacemaskHooks(!includeFacemaskHooks)}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Facemask Hooks
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container direction={'column'}>
                                                            <Grid item container justifyContent={'center'} alignContent={'center'}>
                                                                {includeClassIIHooksUpper && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Class II Hooks
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/ClassIIHooks.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                                {includeVerticalHooksUpper && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Vertical Hooks
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/VerticalHooks.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                                {includeFacemaskHooks && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Facemask Hooks
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/FacemaskHooks.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}

                                                            </Grid>
                                                            {includeFacemaskHooks &&
                                                                <Grid item padding={1}>
                                                                    <Typography variant="h6">
                                                                        Facemask Hook Location
                                                                    </Typography>
                                                                    <TextField
                                                                        fullWidth
                                                                        value={upperFaceMaskHookTeeth.length > 0 ? upperFaceMaskHookTeeth.join(', ') : 'None'}
                                                                        InputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                    />
                                                                    <Box paddingY={2} paddingX={1}>
                                                                        <RxTeethSelection
                                                                            onTeethSelected={handleUpperFaceMaskHookTeeth}
                                                                            displayLower={false}
                                                                            displayUpper={true}
                                                                            ruleDisabledTeeth={[]}
                                                                            patientDisabledTeeth={[]}
                                                                            currentTeeth={upperFaceMaskHookTeeth} />
                                                                    </Box>
                                                                </Grid>}
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            }
                                            {currentUpperComponentValue === "other" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            {constructionType === RxAppliances.DNASAU &&
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            fullWidth
                                                                            variant={includeAnteriorIndexRamp ? "contained" : "outlined"}
                                                                            onClick={() => handleAnteriorIndexRamp(!includeAnteriorIndexRamp)}
                                                                            sx={{
                                                                                justifyContent: 'flex-start'
                                                                            }}
                                                                        >
                                                                            Anterior Index Ramp
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            fullWidth
                                                                            variant={includeAnteriorBitePlane ? "contained" : "outlined"}
                                                                            onClick={() => handleAnteriorBitePlane(!includeAnteriorBitePlane)}
                                                                            sx={{
                                                                                justifyContent: 'flex-start'
                                                                            }}
                                                                        >
                                                                            Anterior Bite Plane
                                                                        </Button>
                                                                    </Grid>
                                                                </>
                                                            }
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={isUpperEruptionFriendlyConsideration ? "contained" : "outlined"}
                                                                    onClick={() => handleUpperEruptionFriendlyConsideration(!isUpperEruptionFriendlyConsideration)}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Eruption Friendly Consideration
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={includeOSAExtension ? "contained" : "outlined"}
                                                                    onClick={() => {
                                                                        handleOSAExtension(!includeOSAExtension)
                                                                    }}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    OSA Extension
                                                                </Button>
                                                            </Grid>
                                                            {includeOSAExtension &&
                                                                <Grid item xs={12} marginY={1}>
                                                                    <TextField
                                                                        fullWidth
                                                                        size="small"
                                                                        value={osaExtensionLength}
                                                                        onChange={(e) => {
                                                                            if (!_.isNaN(Number(e.target.value))) {
                                                                                handleOSAExtensionLength(Number(e.target.value));
                                                                            }
                                                                        }}
                                                                        label="Enter OSA Extension Length"
                                                                        type="numeric"
                                                                        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                                                    />
                                                                </Grid>
                                                            }
                                                            {/* <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={includeFacemask ? "contained" : "outlined"}
                                                                    onClick={() => setIncludeFacemask(!includeFacemask)}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Facemask
                                                                </Button>
                                                            </Grid> */}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container direction={'column'}>
                                                            {isUpperEruptionFriendlyConsideration &&
                                                                <Grid item xs={12} padding={1}>
                                                                    <Typography variant="h6">
                                                                        Eruption Friendly
                                                                    </Typography>
                                                                    <TextField
                                                                        fullWidth
                                                                        value={upperEruptionFriendly.length > 0 ? upperEruptionFriendly.join(', ') : 'None'}
                                                                        InputProps={{ readOnly: true }}
                                                                        variant="outlined"
                                                                    />
                                                                    <Box paddingY={2} paddingX={1}>
                                                                        <RxTeethSelection
                                                                            onTeethSelected={handleUpperEruptionFriendly}
                                                                            displayLower={false}
                                                                            displayUpper={true}
                                                                            ruleDisabledTeeth={[]}
                                                                            patientDisabledTeeth={[]}
                                                                            currentTeeth={upperEruptionFriendly} />
                                                                    </Box>
                                                                </Grid>
                                                            }
                                                            <Grid item xs={12} container>
                                                                {includeOSAExtension && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        OSA Extension
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/OSAExtension.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                                {includeAnteriorIndexRamp && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Anterior Index Ramp
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/AnteriorIndexRamp.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                                {includeAnteriorBitePlane && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Anterior Bite Plane
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/BitePlane.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            }

                                        </>
                                    }

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {constructionFocus === "Lower" &&
                <Grid container paddingX={3} margin={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Card
                                    variant="outlined"
                                    sx={(theme) => ({
                                        background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                    })}
                                >
                                    <Tabs
                                        orientation="vertical"
                                        value={currentLowerComponentValue}
                                        onChange={handleCurrentLowerComponentChange}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                        sx={{
                                            height: '100%',
                                            '.MuiTabs-indicator': {
                                                left: 0,
                                                right: 'auto',
                                                width: '100%'
                                            },
                                            '.MuiTab-root': {
                                                alignItems: 'flex-start',
                                                textAlign: 'left',
                                                paddingLeft: '16px',
                                                '& .MuiTab-wrapper': {
                                                    textAlign: 'left !important',
                                                },
                                            }
                                        }}
                                    >
                                        {lowerTabs.map((tab, key) => (
                                            <Tab key={key} value={tab.value}
                                                label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                        <Typography sx={{ marginRight: 1 }}>{tab.label}</Typography>
                                                        {tab.completed
                                                            ? <CompletedIcon />
                                                            : tab.required && <RequiredIcon />
                                                        }
                                                    </Box>
                                                }
                                                sx={{
                                                    marginBottom: 2,
                                                    textTransform: 'none', // Prevents uppercase transformation
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </Card>
                            </Grid>
                            <Grid item xs={10}>
                                <RxConstructionContainer
                                    rxComplete={rxLowerComplete}
                                    rxConstructionTitle={"Lower Construction"}
                                    rxConstructionComponent={
                                        <>
                                            {currentLowerComponentValue === "base" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={2}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Acrylic"
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                    variant={lowerBaseOption === "Acrylic" ? "contained" : "outlined"}
                                                                    onClick={handleLowerBaseOption}
                                                                >
                                                                    Acrylic
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {lowerBaseOption === "Acrylic" && <CardMedia
                                                                component="img"
                                                                height="350"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/DNABase_Lower_Acrylic.jpg"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                            {currentLowerComponentValue === "clasps" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={2}>
                                                            {Object.keys(lowerClaspingOptions).map((option) => {
                                                                const optionDetails = lowerClaspingOptions[option];
                                                                if (!advancedOptions && optionDetails.advanced) {
                                                                    return null;
                                                                }
                                                                return (
                                                                    <Grid item xs={12} key={option}>
                                                                        <Button
                                                                            fullWidth
                                                                            value={option}
                                                                            variant={lowerClaspingFocus === option ? "contained" : "outlined"}
                                                                            onClick={event => handleLowerClaspingFocus(event, option)}
                                                                            sx={{
                                                                                justifyContent: 'space-between'
                                                                            }}
                                                                        >
                                                                            {option}
                                                                            {optionDetails.valueHolder && optionDetails.valueHolder.length > 0 && <MoreInfoIndicator />}
                                                                        </Button>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {lowerClaspingFocus in claspingImages && <CardMedia
                                                                component="img"
                                                                height="175"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image={claspingImages[lowerClaspingFocus]}
                                                                alt={`${lowerClaspingFocus} Image`}
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {lowerClaspingFocus}
                                                        </Typography>
                                                        {lowerClaspingFocus in lowerClaspingOptions && (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={lowerClaspingOptions[lowerClaspingFocus].valueHolder.length > 0 ? lowerClaspingOptions[lowerClaspingFocus].valueHolder.join(', ') : 'None'}
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1} key={lowerClaspingFocus}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={lowerClaspingOptions[lowerClaspingFocus].handler}
                                                                        displayLower={true}
                                                                        displayUpper={false}
                                                                        ruleDisabledTeeth={unavailableTeeth}
                                                                        patientDisabledTeeth={missingTeeth}
                                                                        currentTeeth={lowerClaspingOptions[lowerClaspingFocus].valueHolder} />
                                                                </Box>
                                                            </>
                                                        )}
                                                        {(lowerClaspingFocus === "Ball Clasp" || lowerClaspingFocus === "Arrow") &&
                                                            <Typography variant="h5">
                                                                Clasp will be placed in the embrasure distal to the tooth selected
                                                            </Typography>}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentLowerComponentValue === "stops" &&
                                                <Grid container>
                                                    <Grid xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            {Object.keys(lowerStopsOptions).map((option) => {
                                                                const optionDetails = lowerStopsOptions[option];
                                                                return (<Grid item key={option} xs={12}>
                                                                    <Button
                                                                        fullWidth
                                                                        value={option}
                                                                        variant={lowerStopsFocus === option ? "contained" : "outlined"}
                                                                        onClick={handleLowerStopsFocus}
                                                                        sx={{
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        {option}
                                                                        {optionDetails.valueHolder && optionDetails.valueHolder.length > 0 && <MoreInfoIndicator />}
                                                                    </Button>
                                                                </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {lowerStopsFocus in stopImages && <CardMedia
                                                                component="img"
                                                                height="175"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image={stopImages[lowerStopsFocus]}
                                                                alt={`${lowerStopsFocus} Image`}
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {lowerStopsFocus}
                                                        </Typography>
                                                        {lowerStopsFocus in lowerStopsOptions && (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={lowerStopsOptions[lowerStopsFocus].valueHolder.length > 0 ? lowerStopsOptions[lowerStopsFocus].valueHolder.join(', ') : 'None'}
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1} key={lowerStopsFocus}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={lowerStopsOptions[lowerStopsFocus].handler}
                                                                        displayLower={true}
                                                                        displayUpper={false}
                                                                        ruleDisabledTeeth={[]}
                                                                        patientDisabledTeeth={missingTeeth}
                                                                        currentTeeth={lowerStopsOptions[lowerStopsFocus].valueHolder} />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentLowerComponentValue === "hooks" &&
                                                (constructionType === RxAppliances.DNADUAL ||
                                                    constructionType === RxAppliances.MRNAV ||
                                                    constructionType === RxAppliances.MMRNAV) &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={includeClassIIHooksLower ? "contained" : "outlined"}
                                                                    onClick={() => handleClassIIHooksLower(!includeClassIIHooksLower)}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Class II Hooks
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={includeVerticalHooksLower ? "contained" : "outlined"}
                                                                    onClick={() => handleVerticalHooksLower(!includeVerticalHooksLower)}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Vertical Hooks
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container direction={'column'}>
                                                            <Grid item container justifyContent={'center'} alignContent={'center'}>
                                                                {includeClassIIHooksLower && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Class II Hooks
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/ClassIIHooks.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                                {includeVerticalHooksLower && <Grid item xs={4}>
                                                                    <Typography variant={'h6'} textAlign={'center'}>
                                                                        Vertical Hooks
                                                                    </Typography>
                                                                    <CardMedia
                                                                        component="img"
                                                                        height="150"
                                                                        sx={{
                                                                            objectFit: 'contain'
                                                                        }}
                                                                        image="/images/components/VerticalHooks.png"
                                                                        alt="BaseImage"
                                                                    /></Grid>}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentLowerComponentValue === "bow" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Standard"
                                                                    variant={lowerBowOption === "Standard" ? "contained" : "outlined"}
                                                                    onClick={handleLowerBowOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Standard
                                                                </Button>
                                                            </Grid>
                                                            {advancedOptions && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="Circumferential (Wrap)"
                                                                    variant={lowerBowOption === "Circumferential (Wrap)" ? "contained" : "outlined"}
                                                                    onClick={handleLowerBowOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Circumferential (Wrap)
                                                                </Button>
                                                            </Grid>}
                                                            {advancedOptions && <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value="None"
                                                                    variant={lowerBowOption === "None" ? "contained" : "outlined"}
                                                                    onClick={handleLowerBowOption}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    None
                                                                </Button>
                                                            </Grid>}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {lowerBowOption === "Standard" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/StandardBow.png"
                                                                alt="BaseImage"
                                                            />}
                                                            {lowerBowOption === "Circumferential (Wrap)" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/CircumferentialBow.png"
                                                                alt="BaseImage"
                                                            />}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentLowerComponentValue === "occlusalcoverage" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value={"Occlusal Acrylic Coverage"}
                                                                    variant={lowerCoverageFocus === "Occlusal Acrylic Coverage" ? "contained" : "outlined"}
                                                                    onClick={handleLowerCoverageFocus}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Occlusal Acrylic Coverage
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    value={"No Occlusal Acrylic Coverage"}
                                                                    variant={lowerCoverageFocus === "No Occlusal Acrylic Coverage" ? "contained" : "outlined"}
                                                                    onClick={handleLowerCoverageFocus}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    No Occlusal Acrylic Coverage
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {lowerCoverageFocus === "No Occlusal Acrylic Coverage" && <Grid item xs={8}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/LowerNoCoverage.png"
                                                                alt="BaseImage"
                                                            />
                                                        </Grid>
                                                    </Grid>}
                                                    {lowerCoverageFocus === "Occlusal Acrylic Coverage" && <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/LowerOcclusalCoverage.png"
                                                                alt="BaseImage"
                                                            />
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {lowerCoverageFocus}
                                                        </Typography>
                                                        <TextField
                                                            fullWidth
                                                            value={lowerAcrylicCoverage.length > 0 ? lowerAcrylicCoverage.join(', ') : 'None'}
                                                            InputProps={{ readOnly: true }}
                                                            variant="outlined"
                                                        />
                                                        <Box paddingY={2} paddingX={1}>
                                                            <RxTeethSelection
                                                                onTeethSelected={handleLowerAcrylicCoverage}
                                                                displayLower={true}
                                                                displayUpper={false}
                                                                ruleDisabledTeeth={[27, 26, 25, 24, 23, 22]}
                                                                patientDisabledTeeth={[]}
                                                                currentTeeth={lowerAcrylicCoverage} />
                                                        </Box>
                                                    </Grid>}
                                                </Grid>
                                            }
                                            {currentLowerComponentValue === "axialsprings" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            {Object.keys(lowerAxialSpringOptions).map((option) => {
                                                                const optionDetails = lowerAxialSpringOptions[option];
                                                                return (<Grid item key={option} xs={12}>
                                                                    <Button
                                                                        fullWidth
                                                                        value={option}
                                                                        variant={lowerAxialSpringFocus === option ? "contained" : "outlined"}
                                                                        onClick={event => handleLowerAxialSpringFocus(event, option)}
                                                                        sx={{
                                                                            justifyContent: 'space-between'
                                                                        }}
                                                                    >
                                                                        {option}
                                                                        {optionDetails.valueHolder && optionDetails.valueHolder.length > 0 && <MoreInfoIndicator />}
                                                                    </Button>
                                                                </Grid>
                                                                )
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} padding={1}>
                                                        <Grid container justifyContent={'center'} alignContent={'center'}>
                                                            {lowerAxialSpringFocus === "3D Axial Springs" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/3DAxialSpring.png"
                                                                alt="SpringImage"
                                                            />}
                                                            {lowerAxialSpringFocus === "'C' Loop 3D Axial Springs" && <CardMedia
                                                                component="img"
                                                                height="250"
                                                                sx={{
                                                                    objectFit: 'contain'
                                                                }}
                                                                image="/images/components/CLoopAxialSpring.png"
                                                                alt="SpringImage"
                                                            />}
                                                        </Grid>
                                                        <Typography variant="h6">
                                                            {lowerAxialSpringFocus}
                                                        </Typography>
                                                        {lowerAxialSpringFocus in lowerAxialSpringOptions && (
                                                            <>
                                                                <TextField
                                                                    fullWidth
                                                                    value={lowerAxialSpringOptions[lowerAxialSpringFocus].valueHolder.length > 0 ? lowerAxialSpringOptions[lowerAxialSpringFocus].valueHolder.join(', ') : 'None'}
                                                                    InputProps={{ readOnly: true }}
                                                                    variant="outlined"
                                                                />
                                                                <Box paddingY={2} paddingX={1} key={lowerAxialSpringFocus}>
                                                                    <RxTeethSelection
                                                                        onTeethSelected={lowerAxialSpringOptions[lowerAxialSpringFocus].handler}
                                                                        displayLower={true}
                                                                        displayUpper={false}
                                                                        ruleDisabledTeeth={unavailableTeeth}
                                                                        patientDisabledTeeth={[]}
                                                                        currentTeeth={lowerAxialSpringOptions[lowerAxialSpringFocus].valueHolder} />
                                                                </Box>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            }
                                            {currentLowerComponentValue === "other" &&
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={1} padding={1}>
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    fullWidth
                                                                    variant={isLowerEruptionFriendlyConsideration ? "contained" : "outlined"}
                                                                    onClick={() => handleLowerEruptionFriendlyConsideration(!isLowerEruptionFriendlyConsideration)}
                                                                    sx={{
                                                                        justifyContent: 'flex-start'
                                                                    }}
                                                                >
                                                                    Eruption Friendly Consideration
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {isLowerEruptionFriendlyConsideration &&
                                                        <Grid item xs={8} padding={1}>
                                                            <Typography variant="h6">
                                                                Eruption Friendly
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                value={lowerEruptionFriendly.length > 0 ? lowerEruptionFriendly.join(', ') : 'None'}
                                                                InputProps={{ readOnly: true }}
                                                                variant="outlined"
                                                            />
                                                            <Box paddingY={2} paddingX={1}>
                                                                <RxTeethSelection
                                                                    onTeethSelected={handleLowerEruptionFriendly}
                                                                    displayLower={true}
                                                                    displayUpper={false}
                                                                    ruleDisabledTeeth={[]}
                                                                    patientDisabledTeeth={[]}
                                                                    currentTeeth={lowerEruptionFriendly} />
                                                            </Box>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }

                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {constructionFocus === "Design Review" &&
                <Grid container spacing={1} padding={2} justifyContent={'center'}>
                    {(constructionType === RxAppliances.DNADUAL ||
                        constructionType === RxAppliances.DNASAU ||
                        constructionType === RxAppliances.MRNAV ||
                        constructionType === RxAppliances.MRNASAU ||
                        constructionType === RxAppliances.MMRNAV ||
                        constructionType === RxAppliances.MMRNASAU) &&
                        <Grid item xs={6}>
                            <RxConstructionContainer
                                rxComplete={rxUpperComplete}
                                rxConstructionTitle={"Review Upper Arch Design"}
                                rxConstructionComponent={
                                    <Grid item xs={12} margin={1} padding={1}>
                                        <Typography variant='h4'>Upper Design Review</Typography>
                                        <Box paddingX={2} paddingTop={1}>
                                            {upperBaseOption != "" &&
                                                <Typography variant='body1'>
                                                    <b>Base:</b> {upperBaseOption}
                                                </Typography>}
                                            {(constructionType === RxAppliances.MRNAV && mandibularAdvancementOption != "") &&
                                                <Typography variant='body1'>
                                                    <b>Mandibular Advancment:</b> {mandibularAdvancementOption}
                                                </Typography>}
                                            {upperScrewOption != "" &&
                                                <Typography variant='body1'>
                                                    <b>Expansion:</b> {upperScrewOption}
                                                </Typography>}
                                            {upperScrewOption != "" && upperScrewOption != "Transverse" && upperScrewOption != "Wireframe" &&
                                                <>
                                                    <Typography variant='subtitle1' textAlign={'center'}>
                                                        <b>Screw Cuts Distal to #s:</b>
                                                    </Typography>
                                                    <Typography variant='subtitle1' textAlign={'center'}>
                                                        <b>{upperScrewTeeth.length > 0 ? upperScrewTeeth.join(', ') : 'None'}</b>
                                                    </Typography>
                                                </>
                                            }
                                            {(upperAdamsClaspTeeth.length != 0 || upperBallClaspTeeth.length != 0 || upperCClaspTeeth.length != 0 || upperArrowClaspTeeth.length != 0) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>Clasping:</b>
                                                    </Typography>
                                                    {upperAdamsClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Adams:</b> {upperAdamsClaspTeeth.length > 0 ? upperAdamsClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {upperBallClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Ball:</b> {upperBallClaspTeeth.length > 0 ? upperBallClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {upperCClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>C:</b> {upperCClaspTeeth.length > 0 ? upperCClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {upperArrowClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Arrow:</b> {upperArrowClaspTeeth.length > 0 ? upperArrowClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                </>
                                            }
                                            {(upperStopsDistalTeeth.length != 0 || upperStopsMesialTeeth.length != 0 || upperStopsOcclusalTeeth.length != 0) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>Stops:</b>
                                                    </Typography>
                                                    {upperStopsDistalTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Distal:</b> {upperStopsDistalTeeth.length > 0 ? upperStopsDistalTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {upperStopsMesialTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Mesial:</b> {upperStopsMesialTeeth.length > 0 ? upperStopsMesialTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {upperStopsOcclusalTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Occlusal:</b> {upperStopsOcclusalTeeth.length > 0 ? upperStopsOcclusalTeeth.join(', ') : 'None'}
                                                        </Typography>}

                                                </>
                                            }
                                            {(includeClassIIHooksUpper || includeVerticalHooksUpper || includeFacemaskHooks) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>Hooks:</b>
                                                    </Typography>
                                                    {includeClassIIHooksUpper &&
                                                        (constructionType === RxAppliances.DNADUAL ||
                                                            constructionType === RxAppliances.MRNAV ||
                                                            constructionType === RxAppliances.MMRNAV) &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>{includeClassIIHooksUpper && "Class II Hooks"}</b>
                                                        </Typography>}
                                                    {includeVerticalHooksUpper &&
                                                        (constructionType === RxAppliances.DNADUAL ||
                                                            constructionType === RxAppliances.MRNAV ||
                                                            constructionType === RxAppliances.MMRNAV) &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>{includeVerticalHooksUpper && "Vertical Hooks"}</b>
                                                        </Typography>}
                                                    {includeFacemaskHooks &&
                                                        (constructionType === RxAppliances.DNADUAL ||
                                                            constructionType === RxAppliances.DNASAU ||
                                                            constructionType === RxAppliances.MRNAV ||
                                                            constructionType === RxAppliances.MRNASAU ||
                                                            constructionType === RxAppliances.MMRNAV ||
                                                            constructionType === RxAppliances.MMRNASAU) &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>{includeFacemaskHooks && "Facemask Hooks: "}</b> {upperFaceMaskHookTeeth.join(', ')}
                                                        </Typography>}
                                                </>}
                                            {upperBowOption != "" &&
                                                <Typography variant='body1'>
                                                    <b>Bow:</b> {upperBowOption}
                                                </Typography>}
                                            {(upperAcrylicCoverage.length != 0 || upperCoverageFocus === "No Occlusal Acrylic Coverage") &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>{upperCoverageFocus}</b>
                                                    </Typography>
                                                    {upperAcrylicCoverage.length != 0 && upperCoverageFocus === "Occlusal Acrylic Coverage" &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>On:</b> {upperAcrylicCoverage.length > 0 ? upperAcrylicCoverage.join(', ') : 'None'}
                                                        </Typography>}
                                                </>
                                            }
                                            {(upper3DAxialSpring.length != 0 || upperCLoop3DAxialSpring.length != 0) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>3D Axial Springs:</b>
                                                    </Typography>
                                                    {upper3DAxialSpring.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>On:</b> {upper3DAxialSpring.length > 0 ? upper3DAxialSpring.join(', ') : 'None'}
                                                        </Typography>}
                                                    {upperCLoop3DAxialSpring.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>'C' Loops:</b> {upperCLoop3DAxialSpring.length > 0 ? upperCLoop3DAxialSpring.join(', ') : 'None'}
                                                        </Typography>}
                                                </>
                                            }
                                            {(constructionType === "Upper DNA" && includeAnteriorIndexRamp) &&
                                                <Typography variant='body1'>
                                                    <b>Include Anterior Index Ramp</b>
                                                </Typography>
                                            }
                                            {(constructionType === "Upper DNA" && includeAnteriorBitePlane) &&
                                                <Typography variant='body1'>
                                                    <b>Include Anterior Bite Plane</b>
                                                </Typography>
                                            }
                                            {upperEruptionFriendly.length != 0 &&
                                                <Typography variant='subtitle1' textAlign={'center'}>
                                                    <b>Eruption Friendly:</b> {upperEruptionFriendly.length > 0 ? upperEruptionFriendly.join(', ') : 'None'}
                                                </Typography>}
                                            {includeOSAExtension &&
                                                <Typography variant='body1'>
                                                    <b>OSA Extension:</b> {osaExtensionLength + " mm"}
                                                </Typography>}
                                            {includeFacemask &&
                                                <Typography variant='body1'>
                                                    <b>Include Facemask</b>
                                                </Typography>}
                                        </Box>
                                    </Grid>
                                }

                            />
                        </Grid>
                    }
                    {(constructionType === RxAppliances.DNADUAL ||
                        constructionType === RxAppliances.DNASAL ||
                        constructionType === RxAppliances.MRNAV ||
                        constructionType === RxAppliances.MRNASAL ||
                        constructionType === RxAppliances.MMRNAV ||
                        constructionType === RxAppliances.MMRNASAL) &&
                        <Grid item xs={6}>
                            <RxConstructionContainer
                                rxComplete={rxLowerComplete}
                                rxConstructionTitle={"Review Lower Arch Design"}
                                rxConstructionComponent={
                                    <Grid item xs={12} margin={1} padding={1}>
                                        <Typography variant='h4'>Lower Design Review</Typography>
                                        <Box paddingX={2} paddingTop={1}>
                                            {lowerBaseOption != "" &&
                                                <Typography variant='body1'>
                                                    <b>Base:</b> {lowerBaseOption}
                                                </Typography>}
                                            {(lowerAdamsClaspTeeth.length != 0 || lowerBallClaspTeeth.length != 0 || lowerCClaspTeeth.length != 0 || lowerArrowClaspTeeth.length != 0) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>Clasping:</b>
                                                    </Typography>
                                                    {lowerAdamsClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Adams:</b> {lowerAdamsClaspTeeth.length > 0 ? lowerAdamsClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {lowerBallClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Ball:</b> {lowerBallClaspTeeth.length > 0 ? lowerBallClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {lowerCClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>C:</b> {lowerCClaspTeeth.length > 0 ? lowerCClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {lowerArrowClaspTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Arrow:</b> {lowerArrowClaspTeeth.length > 0 ? lowerArrowClaspTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                </>
                                            }
                                            {(lowerStopsDistalTeeth.length != 0 || lowerStopsMesialTeeth.length != 0 || lowerStopsOcclusalTeeth.length != 0) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>Stops:</b>
                                                    </Typography>
                                                    {lowerStopsDistalTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Distal:</b> {lowerStopsDistalTeeth.length > 0 ? lowerStopsDistalTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {lowerStopsMesialTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Mesial:</b> {lowerStopsMesialTeeth.length > 0 ? lowerStopsMesialTeeth.join(', ') : 'None'}
                                                        </Typography>}
                                                    {lowerStopsOcclusalTeeth.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>Occlusal:</b> {lowerStopsOcclusalTeeth.length > 0 ? lowerStopsOcclusalTeeth.join(', ') : 'None'}
                                                        </Typography>}

                                                </>
                                            }
                                            {(includeClassIIHooksLower || includeVerticalHooksLower) &&
                                                (constructionType === RxAppliances.DNADUAL ||
                                                    constructionType === RxAppliances.MRNAV ||
                                                    constructionType === RxAppliances.MMRNAV) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>Hooks:</b>
                                                    </Typography>
                                                    {includeClassIIHooksLower &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>{includeClassIIHooksLower && "Class II Hooks"}</b>
                                                        </Typography>}
                                                    {includeVerticalHooksLower &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>{includeVerticalHooksLower && "Vertical Hooks"}</b>
                                                        </Typography>}
                                                </>}
                                            {lowerBowOption != "" &&
                                                <Typography variant='body1'>
                                                    <b>Bow:</b> {lowerBowOption}
                                                </Typography>}
                                            {(lowerAcrylicCoverage.length != 0 || lowerCoverageFocus === "No Occlusal Acrylic Coverage") &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>{lowerCoverageFocus}</b>
                                                    </Typography>
                                                    {lowerAcrylicCoverage.length != 0 && lowerCoverageFocus === "Occlusal Acrylic Coverage" &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>On:</b> {lowerAcrylicCoverage.length > 0 ? lowerAcrylicCoverage.join(', ') : 'None'}
                                                        </Typography>}

                                                </>
                                            }
                                            {(lower3DAxialSpring.length != 0 || lowerCLoop3DAxialSpring.length != 0) &&
                                                <>
                                                    <Typography variant='body1'>
                                                        <b>3D Axial Springs:</b>
                                                    </Typography>
                                                    {lower3DAxialSpring.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>On:</b> {lower3DAxialSpring.length > 0 ? lower3DAxialSpring.join(', ') : 'None'}
                                                        </Typography>}
                                                    {lowerCLoop3DAxialSpring.length != 0 &&
                                                        <Typography variant='subtitle1' textAlign={'center'}>
                                                            <b>'C' Loops:</b> {lowerCLoop3DAxialSpring.length > 0 ? lowerCLoop3DAxialSpring.join(', ') : 'None'}
                                                        </Typography>}
                                                </>
                                            }
                                            {lowerEruptionFriendly.length != 0 &&
                                                <Typography variant='subtitle1' textAlign={'center'}>
                                                    <b>Eruption Friendly:</b> {lowerEruptionFriendly.length > 0 ? lowerEruptionFriendly.join(', ') : 'None'}
                                                </Typography>}
                                        </Box>
                                    </Grid>
                                }

                            />

                        </Grid>
                    }
                </Grid>
            }
            {isAdjacentClasp && <Box paddingY={2} paddingX={1} >
                <Alert severity="warning" onClose={() => { }}>
                    {warningAdjacentText}
                </Alert>
            </Box>}
            {isMoreThanThreeCLoop && <Box paddingY={2} paddingX={1} >
                <Alert severity="warning" onClose={() => { }}>
                    {warningCLoopText}
                </Alert>
            </Box>}
        </Grid>
    )
}; export default observer(CareConstruction)