import { Grid, MenuItem } from '@mui/material';
import { useEffect, FC } from "react";
import { useState } from 'react';
import { observer } from 'mobx-react';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { _allowStateChangesInsideComputed } from 'mobx';
import * as _ from 'lodash';
import CssSelectField from '../../../components/CssSelectField';
import { UploadPDF } from '../../../components/UploadPDF';
import { FileUploader } from 'react-drag-drop-files';
import PatientDocument from '../../../entities/PatientDocument';
import { AddOnType } from '../../../entities/Enums';
import { Gateway } from '../../../api/Gateway';

interface IGIProps {
    submissionId: number;
}

export const AIReportAddons: FC<IGIProps> = observer(
    ({ submissionId }) => {
        const [hasLoaded, setHasLoaded] = useState(false);
        const { submission, addOnTypes, aiReportStatuses, addOnStatusChange } = useMainStoreContext().aiReportEntryStore;
        const [file, setFile] = useState<File>();

        useEffect(() => {
            setHasLoaded(true);
        }, [submission.addOns])

        const handleAddOnChange = (event: any, addOn: any) => {
            addOnStatusChange(event.target.value, addOn.id);
        }

        const handleAddOnUpload = async (file, addOn) => {
            setFile(file);
            await doWork(file, addOn);
        }

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, addOn) {
            var doc = {} as PatientDocument;
            doc.data64String = await convertBase64(file) as unknown as string;
            doc.pathUrl = submission.id + "-Radiology-Report.pdf";
            doc["new"] = true;
            doc.imageType = addOn.addOnTypeNavigation;
            doc.id = addOn.id;
            Gateway.post('/aiReports/' + submission.id + '/addOnDocument', doc).then(() => { });
        }

        return (
            <Grid sx={{ margin: "25px 0px" }} className="modalPageHeight autoScroll" xs={12} container direction="column">
                {
                    _.map(submission.addOns, (addOn) => {
                        return (
                            <Grid xs={12} sx={{ margin: "25px" }} container direction="row" className="center">
                                <Grid xs={3} sx={{ margin: "5px 10px" }}>{_.find(addOnTypes, ((a) => { return a.id == addOn.addOnType }))?.name}</Grid>
                                <Grid xs={2} sx={{ margin: "5px 10px" }}>
                                    <CssSelectField
                                        className='TextBox'
                                        onChange={e => {
                                            addOn.statusId = e.target.value;
                                            handleAddOnChange(e, addOn)
                                        }}
                                        value={addOn.statusId}>
                                        {_.map(aiReportStatuses, (s) => {
                                            return (<MenuItem value={s.id}>{s.name}</MenuItem>)
                                        })}
                                    </CssSelectField>
                                </Grid>
                                <Grid xs={6}>
                                    {addOn.addOnType === AddOnType.Radiology && <Grid>
                                        <FileUploader
                                            label="Upload or drop a PDF here"
                                            handleChange={(event) => {
                                                handleAddOnUpload(event, addOn);
                                            }}
                                            name="file"
                                            types={['PDF']}
                                            id="pdfFileUploader"
                                        />
                                        <div id="pdf.fileName">{addOn.url ? addOn.url.split('/').pop() : null}</div>
                                    </Grid>}
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>

        );
    });


