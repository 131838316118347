import { action, makeAutoObservable, observable } from 'mobx';
import { Gateway } from '../../api/Gateway';
import VitalsAndMeasurments from '../../entities/VitalsAndMeasurments';
import { MainStore } from './MainStore';
import PatientVisit from '../../entities/PatientVisit';
import PatientEpworth from '../../entities/PatientEpworth';
import PatientHeadNeck from '../../entities/PatientHeadNeck';
import PatientFile from '../../entities/PatientFIle';
import PatientSleepStudy from '../../entities/PatientSleepStudy';
import PatientImage from '../../entities/PatientImage';
import jwt_decode from 'jwt-decode';
import DictionaryListItem from '../../entities/DictionaryListItem';
import Compliance from '../../entities/Compliance';
import _ from 'lodash';
import PatientImages from '../../entities/PatientImages';
import { GlobalUtils } from '../../api/GlobalUtils';
import { BaseMainStore } from '../base/MainStore';


const defaultEpworth = {
    id: 0,
    patientId: 0,
    visitId: 0,
    epworthPropertyId: 0, // Assuming it's an integer identifier for a specific property
    value: 0, // Assuming this is a numerical value or can be adjusted based on your model
    isActive: true, // Assuming the default Epworth entry is active
    displayName: ''
};

export const defaultPatientImages = {
    id: 0, // Assuming default id is 0
    patientId: 0, // Default patientId as 0
    patientVisitId: 0, // Default patientVisitId as 0
    cbctScan: "", // Assuming cbctScan is a string representing a file path or data, default as an empty string
    cbctMachineBrandId: 0, // Assuming default is 0 for machine brand
    cbctFov01: 0, // Assuming these are numeric fields, default to 0
    cbctFov02: 0,
    extraoralFrontRelaxed: "", // Assuming this is a string for an image or file path
    extraoralBigSmile: "",
    extraoralRightProfile: "",
    extraoralSubmentoVertex: "",
    extraoralFullBodyPostAnterior: "",
    extraoralFullBodyPostRight: "",
    extraoral3DFaceScan: "", // 3D Face scan, likely a string or file path, default as an empty string
    intraoralUpperArch: "", // Intraoral images as strings (file paths)
    intraoralLowerArch: "",
    intraoralAnteriorOcclusion: "",
    intraoralLeftOcclusion: "",
    intraoralRightOcclusion: "",
    intraoralLingualFrenum: "",
    intraoralSurfaceTongue: "",
    occlusionRMolarRelationship: "", // Occlusion relationships as strings, default empty string
    occlusionLMolarRelationship: "",
    occlusionMemberTookImpression: "", // Assuming this is a string, could also be a boolean depending on how data is represented
    occlusionImpressionDate: new Date(), // Default to current date
    occlusion3DUpperArch: "", // Assuming these are 3D scan file paths or identifiers
    occlusion3DLowerArch: "",
    occlusion3DConstructedBite: "",
    occlusion3DMachineBrandId: 0, // Assuming 0 is the default machine brand id
    occlusionUpperArch: "", // Default as empty strings
    occlusionLowerArch: "",
    occlusionAnterior: "",
    occlusionLeft: "",
    occlusionRight: "",
    isActive: true // Defaulting to active status
};



export const defaultVisit = {
    id: 0,
    patientId: 0,
    patientFiles: [], // Assuming it's an array; modify if it's an object
    patientEpworth: new PatientEpworth(defaultEpworth), // Assuming it's a single value; modify as needed
    patientsImages: new PatientImages(defaultPatientImages),
    patientVitalsAndMeasurements: [],
    patientSleepStudies: [], // Assuming it's an array; modify if it's an object
    patientHeadNecks: [], // Assuming it's an array; modify if it's an object
    patientCompliances: [],
    isActive: false, // Assuming it's a boolean
};





const defaultVitalsAndMeasurments: VitalsAndMeasurments = {
    toObject: function() {
        return { ...this };
    },
    id: 0,
    patientId: 0,
    visitId: 0,
    heartbeats: 0,
    bloodPressure: '',
    temperature: 0,
    respirations: 0,
    oxygenSaturation: 0,
    height: 0,
    weight: 0,
    headCircumference: 0,
    neckCircumference: 0,
    waistCircumference: 0,
    notes: '',
    unitOfMeasure: false,
    isActive: true
  };
  

const defaultCompliance = {
    id: 0,
    visitId: -1,
    prescribedDaytimeHours: 0,
    prescribedNighttimeHours: 0,
    actualDaytimeHours: 0,
    actualNighttimeHours: 0,
    topViewUpper: null,
    topViewLower: null,
    bottomViewUpper: null,
    bottomViewLower: null,
    images: [] as any[],
    isActive: true,
}

export default class VisitStore {
    mainStore: BaseMainStore;
    constructor(mainstore: BaseMainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
        this.intraoralImageTypes = GlobalUtils.getIntraOralImages();
        this.extraoralImageTypes = GlobalUtils.getExtraOralImages();
        this.occlusionImageTypes = GlobalUtils.getPatientOcclusionImageTypes();
    }

    @observable id = 0;
    @observable userId = 0;
    @observable patientId = 0;
    @observable patientStatus = 0;
    @observable patientVitalsAndMeasurements: VitalsAndMeasurments = {} as VitalsAndMeasurments;
    @observable patientsImages: any[] = [];
    @observable patientFiles: PatientFile[] = [];
    @observable sleepTestFiles: PatientFile[] = [];
    @observable documentsFiles: PatientFile[] = [];
    @observable patientUploadFiles: PatientFile[] = [];
    @observable patientEpworth: PatientEpworth = {} as PatientEpworth;
    @observable patientSleepStudies: PatientSleepStudy[] = [];
    @observable patientHeadNecks: PatientHeadNeck[] = [];
    @observable patientCompliances: Compliance[] = [];
    @observable visitRecords: PatientVisit[] = [];
    @observable currentVisit: PatientVisit = {} as PatientVisit;
    @observable intraoralImageTypes: DictionaryListItem[] = [];
    @observable extraoralImageTypes: DictionaryListItem[] = [];
    @observable occlusionImageTypes: DictionaryListItem[] = [];
    @observable visitExtraoral2dimages: any[] = [];
    @observable visitExtraoral3dimages: any[] = [];
    @observable visitIntraoral2dimages: any[] = [];
    @observable visitOcclusion2dimages: any[] = [];
    @observable visitOcclusion3dimages: any[] = [];
    @observable topUpperImages: any[] = [];
    @observable topLowerImages: any[] = [];
    @observable bottomUpperImages: any[] = [];
    @observable bottomLowerImages: any[] = [];
    @observable imagesToDelete: any[] = [];
    @observable complianceImagesToDelete: any[] = [];

    @observable visitCbctscan: any = {};
    @observable activeTab: number = 0;
    @observable previousTab: number = 0;
    @observable imagesToSave: any[] = [];
    @observable complianceImagesSaved: boolean = false;
    @observable newCompliance: boolean = false;
    @observable isLoading: boolean = false;
    @observable progress: number = 0;
    @observable isCurrentVisitLoaded: boolean = false;


    @observable createdOn: any = '';
    getCreatedOn = () => {
        return this.createdOn;
    };
    @action setCreatedOn = (newValue: string) => {
        this.createdOn = newValue;
    };

    getVisitId = () => {
        return this.id;
    };

    @action setExtraoral2dImages = (value: any[]) => { this.visitExtraoral2dimages = value; }
    @action setExtraoral3dImages = (value: any[]) => { this.visitExtraoral3dimages = value; }
    @action setintraoral2dImages = (value: any[]) => { this.visitIntraoral2dimages = value; }
    @action setOcclusion2dImages = (value: any[]) => { this.visitOcclusion2dimages = value; }
    @action setOcclusion3dImages = (value: any[]) => { this.visitOcclusion3dimages = value; }
    @action setTopUpperImages = (value: any[]) => { this.topUpperImages = value; }
    @action setTopLowerImages = (value: any[]) => { this.topLowerImages = value; }
    @action setBottomUpperImages = (value: any[]) => { this.bottomUpperImages = value; }
    @action setBottomLowerImages = (value: any[]) => { this.bottomLowerImages = value; }

    @action setExtraoralImageTypes = (value: any[]) => { this.extraoralImageTypes = value; }
    @action setIntraoralImageTypes = (value: any[]) => { this.intraoralImageTypes = value; }
    @action setOcclusionImageTypes = (value: any[]) => { this.occlusionImageTypes = value; }
    @action setImagesToDelete = (value: any[]) => { this.imagesToDelete = value; }
    @action setComplianceImagesSaved = (value: boolean) => { this.complianceImagesSaved = value; }
    @action setNewCompliance = (value: boolean) => { this.newCompliance = value; }
    @action setCbctScan = (value: any) => { this.visitCbctscan = value; }

    @action setVisitRecords = (visitRecords: PatientVisit[]) => {
        this.visitRecords = visitRecords;
    };

    @action setCurrentVisit = (newValue: any) => {
        this.currentVisit = newValue;
    }

    @action setIsCurrentVisitLoaded = (newValue: boolean) => {
        this.isCurrentVisitLoaded = newValue
    }

    @action setActiveTab = (newValue: number) => {
        this.activeTab = newValue;
    }

    @action setPreviousTab = (newValue: number) => {
        this.previousTab = newValue;
    }

    @action setId = (newValue: number) => {
        this.id = newValue;
    };
    @action setVisitPatientId = (newValue: number) => {
        this.patientId = newValue;
    };
    @action setPatientVitalsAndMeasurements = (newValue: VitalsAndMeasurments) => {
        this.patientVitalsAndMeasurements = newValue;
    };
    @action setImages = (newValue: PatientImage[]) => {
        this.patientsImages = newValue;
    };
    @action
    setPatientFiles = (files: PatientFile[]) => {
        this.patientFiles = files;
    };
    @action
    setSleepTestFiles = (files: PatientFile[]) => {
        this.sleepTestFiles = files;
    };
    @action
    setDocumentFiles = (files: PatientFile[]) => {
        this.documentsFiles = files;
    };
    @action
    setPatientUploadFiles = (files: PatientFile[]) => {
        this.patientUploadFiles = files;
    };

    @action
    setPatientCompliances = (value: Compliance[]) => {
        this.patientCompliances = value;
    }

    @action
    setIsLoading = (value: boolean) => {
        this.isLoading = value;
    }

    @action
    setProgress = (value: number) => {
        this.progress = value;
    }

    @action
    setVisit = (visit: any) => {
        if (visit && visit.id && visit.id > 0) {
            // If valid visit object:
            this.setId(visit.id);
            this.setVisitPatientId(visit.patientId);
            this.setPatientVitalsAndMeasurements(visit.patientVitalsAndMeasurments);
            this.setImages(visit.patientsImages);
            this.setPatientFiles(visit.patientFiles);
            this.setPatientCompliances(visit.patientCompliances);
            this.setCurrentVisit(visit);
        } else {
            // Reset to defaults if visit is invalid:
            this.setId(0);
            this.setVisitPatientId(0);
            this.setPatientVitalsAndMeasurements(defaultVitalsAndMeasurments);
            this.setImages([]);
            this.setPatientFiles([]);
            this.setPatientCompliances([]);
            this.setCurrentVisit(visit);
        }
    };


    @action
    loadVisits = async () => {
        return await Gateway.get('visitrecord/' + this.patientId).then(resp => {
            this.setVisitRecords(resp);
            if (resp.length > 1) {
                this.setId(resp[resp.length - 1].id);
                this.setVisitPatientId(resp[resp.length - 1].patientId);
                this.setPatientVitalsAndMeasurements(resp[resp.length - 1].patientVitalsAndMeasurements);
                this.setImages(resp[resp.length - 1].patientsImages);
                this.setPatientCompliances(resp[resp.length - 1].compliances);
            } else if (resp.length === 1) {
                this.setId(resp[0].id);
                this.setVisitPatientId(resp[0].patientId);
                this.setPatientVitalsAndMeasurements(resp[0].patientVitalsAndMeasurements);
                this.setImages(resp[0].patientsImages);
                this.setPatientCompliances(resp[0].compliances);
            } else {
                this.setId(0);
                this.setVisitPatientId(0);
                this.setPatientVitalsAndMeasurements({} as VitalsAndMeasurments);
                this.setImages([]);
                this.setPatientCompliances([defaultCompliance as Compliance]);
            }

            return resp;
        });
    };

    @action
    loadVisitsForGrid = async () => {
        return await Gateway.get('visitrecord/getforgrid/' + this.patientId).then(resp => {
            this.setVisitRecords(resp);
            if (resp.length > 1) {
                this.setId(resp[resp.length - 1].id);
                this.setVisitPatientId(resp[resp.length - 1].patientId);
                this.setPatientVitalsAndMeasurements(resp[resp.length - 1].patientVitalsAndMeasurements);
                this.setImages(resp[resp.length - 1].patientsImages);
                this.setPatientCompliances(resp[resp.length - 1].compliances);
            } else if (resp.length === 1) {
                this.setId(resp[0].id);
                this.setVisitPatientId(resp[0].patientId);
                this.setPatientVitalsAndMeasurements(resp[0].patientVitalsAndMeasurements);
                this.setImages(resp[0].patientsImages);
                this.setPatientCompliances(resp[0].compliances);
            } else {
                this.setId(0);
                this.setVisitPatientId(0);
                this.setPatientVitalsAndMeasurements({} as VitalsAndMeasurments);
                this.setImages([]);
                this.setPatientCompliances([defaultCompliance as Compliance]);
            }

            return resp;
        });
    };


    @action
    deleteFile = async (fileId, visitId) => {
        return await Gateway.delete('patient/deletefile/' + fileId + '/' + visitId + '/' + this.userId, {}).then(resp => {
            return resp;
        });
    };

    @action
    deleteImages = async (visitId) => {
        while (this.imagesToDelete.length > 0) {
            const img = this.imagesToDelete[0];

            try {
                await Gateway.delete(`patient/${visitId}/${img}/${this.userId}/deletepatientimage`, {});
                this.imagesToDelete.shift();
            } catch (error) {
                console.error(`Error deleting the image ${img}:`, error);
                break;
            }
        }
    };

    @action
    deleteComplianceImages = async (visitId) => {
        while (this.complianceImagesToDelete.length > 0) {
            const img = this.complianceImagesToDelete[0];

            try {
                await Gateway.delete(`visitrecord/compliance/${visitId}/${img}/${this.userId}`, {});
                this.complianceImagesToDelete.shift();
            } catch (error) {
                console.error(`Error deleting the image ${img}:`, error);
                break;
            }
        }
    };

    @action
    uploadFiles = async (patientId, visitId) => {
        _.forEach(this.patientUploadFiles.filter(x => _.isUndefined(x.id) === true), (doc, index) => {
            Gateway.post(`/patient/${patientId}/${visitId}/${this.userId}/addpatientfile`, doc).then(
                (resp: any) => {
                    this.patientUploadFiles[index].id = resp.id;
                    this.setPatientUploadFiles(this.patientUploadFiles);
                }
            );
        })
    };

    @action
    visitImagesToComplianceImages = () => {
        this.clearComplianceImages();
        this.setPatientCompliances(this.currentVisit.patientCompliances);

        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            if (this.patientCompliances[0]?.topViewUpper) {
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].topViewUpper);
                img.pathUrl = decodeURI(this.patientCompliances[0].topViewUpper);
                img['filename'] = 'topViewUpper';
                img['type'] = '2D';
                this.topUpperImages.push(img);
            }
            if (this.patientCompliances[0]?.topViewLower) {
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].topViewLower);
                img.pathUrl = decodeURI(this.patientCompliances[0].topViewLower);
                img['filename'] = 'topViewLower';
                img['type'] = '2D';
                this.topLowerImages.push(img);
            }
            if (this.patientCompliances[0]?.bottomViewLower) {
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].bottomViewLower);
                img.pathUrl = decodeURI(this.patientCompliances[0].bottomViewLower);
                img['filename'] = 'bottomViewLower';
                img['type'] = '2D';
                this.bottomLowerImages.push(img);
            }
            if (this.patientCompliances[0]?.bottomViewUpper) {
                var img = {} as PatientImage;
                img.id = 0;
                img.data64Url = decodeURI(this.patientCompliances[0].bottomViewUpper);
                img.pathUrl = decodeURI(this.patientCompliances[0].bottomViewUpper);
                img['filename'] = 'bottomViewUpper';
                img['type'] = '2D';
                this.bottomUpperImages.push(img);
            }
            Promise.all(promises).then(() => {
                this.setTopUpperImages(this.topUpperImages);
                this.setTopLowerImages(this.topLowerImages);
                this.setBottomLowerImages(this.bottomLowerImages);
                this.setBottomUpperImages(this.bottomUpperImages);

                resolve(true);
            });
        });
    };


    @action
    visitImagesToPatientImages = () => {
        this.clearImages();
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            if (this.currentVisit && this.currentVisit.patientsImages && this.currentVisit.patientsImages[0]) {
                _.forOwn(this.currentVisit.patientsImages[0], async (val, k) => {
                    if (val !== null && val !== undefined) {
                        var img = {} as PatientImage;
                        img.id = 0;
                        img.data64Url = decodeURI(val);
                        img.pathUrl = decodeURI(val);
                        img['filename'] = k;

                        if (k.toString().toLowerCase().indexOf('extraoral') >= 0) {
                            if (k.toString().toLowerCase().indexOf('3d') >= 0) {
                                img['type'] = '3D';
                                this.visitExtraoral3dimages.push(img);
                            } else {
                                img['type'] = '2D';
                                var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                                if (opt) {
                                    img.imageType = opt;
                                }
                                this.visitExtraoral2dimages.push(img);
                            }
                        } else if (k.toString().toLowerCase().indexOf('intraoral') >= 0) {
                            img['type'] = '2D';
                            var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                            if (opt) {
                                img.imageType = opt;
                            }
                            this.visitIntraoral2dimages.push(img);
                        } else if (k.toString().toLowerCase().indexOf('cbctscan') >= 0) {
                            img['type'] = 'zip';
                            this.visitCbctscan = [img];
                        } else if (k.toString().toLowerCase().indexOf('occlusion') >= 0) {
                            if (
                                k.toString().toLowerCase().indexOf('3dupperarch') >= 0 ||
                                k.toString().toLowerCase().indexOf('3dlowerarch') >= 0 ||
                                k.toString().toLowerCase().indexOf('3dconstructedbite') >= 0
                            ) {
                                img['type'] = '3D';
                                var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                                if (opt) {
                                    img.imageType = opt;
                                }
                                this.visitOcclusion3dimages.push(img);
                            } else if (
                                k.toString().toLowerCase().indexOf('occlusionupperarch') >= 0 ||
                                k.toString().toLowerCase().indexOf('occlusionlowerarch') >= 0 ||
                                k.toString().toLowerCase().indexOf('occlusionanterior') >= 0 ||
                                k.toString().toLowerCase().indexOf('occlusionleft') >= 0 ||
                                k.toString().toLowerCase().indexOf('occlusionright') >= 0
                            ) {
                                img['type'] = '2D';
                                var opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                                if (opt) {
                                    img.imageType = opt;
                                }
                                this.visitOcclusion2dimages.push(img);
                            }
                        }
                    }
                });
            }
            Promise.all(promises).then(() => {
                this.setExtraoral2dImages(this.visitExtraoral2dimages);
                this.setExtraoral3dImages(this.visitExtraoral3dimages);
                this.setintraoral2dImages(this.visitIntraoral2dimages);
                this.setOcclusion2dImages(this.visitOcclusion2dimages);
                this.setOcclusion3dImages(this.visitOcclusion3dimages);
                this.setCbctScan(this.visitCbctscan);
                resolve(true);
            });
        });
    };

    @action
    loadVisit = () => {
        if (this.id === 0) {
            this.setCurrentVisit(defaultVisit as unknown as PatientVisit);
            this.setIsCurrentVisitLoaded(true);
            return defaultVisit;
        }

        Gateway.get('visitrecord/' + this.patientId + '/visit/' + this.id).then(resp => {
            this.setCurrentVisit(resp as unknown as PatientVisit);
            this.setIsCurrentVisitLoaded(true);
            this.setIsLoading(false);
            return this.currentVisit;
        });
    };

    @action
    getVisit = (): PatientVisit => {
        var visit = {} as PatientVisit;
        visit.patientEpworth = this.patientEpworth;
        visit.patientFiles = this.patientFiles;
        visit.patientHeadNecks = this.patientHeadNecks;
        visit.patientSleepStudies = this.patientSleepStudies;
        visit.patientVitalsAndMeasurements = [this.patientVitalsAndMeasurements];
        // visit.patientsImages = this.patient;
        visit.patientCompliances = this.patientCompliances;
        visit.patientId = this.patientId;
        visit.id = this.id;
        visit.createdOn = this.getCreatedOn() == "" ? new Date() : this.getCreatedOn();
        return visit;
    }

    @action
    saveVisit = () => {
        let visit = this.getVisit();
        visit.id = this.id;
        //visit.createdOn = moment(visit.createdOn).format('MM/DD/yyyy');//this.visitDate;
        visit.patientId = this.patientId;
        this.imagesToSave = this.patientCompliances[0] ? this.patientCompliances[0].images : [];

        return new Promise((resolve, reject) => {
            var promises: any[] = [];

            if (this.id > 0) {
                promises.push(
                    Gateway.post(`visitrecord/${visit.id}`, visit).then(resp => {
                        this.setVisit(resp);
                        var createdVisit = resp as unknown as PatientVisit;
                        this.setId(createdVisit.id);
                        this.setCurrentVisit(createdVisit);
                    }),
                );
            } else {
                promises.push(
                    Gateway.post('visitrecord', visit).then(resp => {
                        this.setVisit(resp);
                        var createdVisit = resp as unknown as PatientVisit;
                        this.setId(createdVisit.id);
                        this.setCurrentVisit(createdVisit);
                    }),
                );
            }
            Promise.all(promises).then(() => {
                resolve(this.currentVisit);
            });
        })
    };

    @action
    saveCompliance = async (patientCompliances: any[]): Promise<any> => {
        const compliance = patientCompliances[0];
        compliance.visitId = this.id;

        // Verifica si ya existe un id para actualizar o crear uno nuevo
        if (compliance.id > 0) {
            return Gateway.put(`visitrecord/compliance`, compliance).then((resp) => {
                this.currentVisit.patientCompliances[0] = resp as unknown as Compliance;
                this.setCurrentVisit(this.currentVisit);
                this.complianceImagesSaved = true;
                this.setIsLoading(false);
                return resp as unknown as Compliance;
            });
        } else {
            return Gateway.post('visitrecord/compliance', compliance).then((resp) => {
                this.currentVisit.patientCompliances.push(resp as unknown as Compliance);
                this.setCurrentVisit(this.currentVisit);
                this.complianceImagesSaved = true;
                this.setIsLoading(false);
                return resp as unknown as Compliance;
            });
        }
    };

    @action
    saveVitals = async (): Promise<any> => {
        if (this.patientVitalsAndMeasurements.id > 0) {
            return Gateway.post(`visitrecord/vitals/${this.id}`, this.patientVitalsAndMeasurements)
                .then((resp) => {
                    this.setPatientVitalsAndMeasurements(resp as unknown as VitalsAndMeasurments);
                    this.currentVisit.patientVitalsAndMeasurements = resp;
                    this.setCurrentVisit(this.currentVisit);
                    return resp as unknown as VitalsAndMeasurments;
                });
        } else {
            return Gateway.post('visitrecord/vitals', this.patientVitalsAndMeasurements)
                .then((resp) => {
                    this.setPatientVitalsAndMeasurements(resp as unknown as VitalsAndMeasurments);
                    this.currentVisit.patientVitalsAndMeasurements = resp;
                    this.setCurrentVisit(this.currentVisit);
                    return resp as unknown as VitalsAndMeasurments;
                });
        }
    };

    @action
    complianceImageDecode = (url: string): any => {
        var img = {} as PatientImage;
        img.id = 0;
        img.data64Url = decodeURI(url);
        img.pathUrl = decodeURI(url);
        img['filename'] = url;
        img['type'] = 'appliance';

        return img;
    }

    @action
    resetVisit = () => {
        this.setId(0);
        this.setPatientVitalsAndMeasurements({} as VitalsAndMeasurments);
        this.setImages([]);
        this.setPatientFiles([]);
        this.setPatientCompliances([]);
        this.clearImages();
        this.clearComplianceImages();
        this.setCurrentVisit(null);
        this.setIsCurrentVisitLoaded(false);
    }

    @action
    clearImages = () => {
        this.setExtraoral2dImages([]);
        this.setExtraoral3dImages([]);
        this.setintraoral2dImages([]);
        this.setOcclusion3dImages([]);
        this.setOcclusion2dImages([]);
        this.setCbctScan({});
    };

    @action
    clearComplianceImages = () => {
        this.setTopLowerImages([]);
        this.setTopUpperImages([]);
        this.setBottomLowerImages([]);
        this.setBottomUpperImages([]);
    };

    @action
    getTypeFromName = (name: any) => {
        switch (name.toLowerCase()) {
            // case "cbctScan":
            //     return "CBCT Scan";
            //     break;
            case 'extraoralfrontrelaxed':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 182;
                }); // "Front Relaxed";
                break;
            case 'extraoralbigsmile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 181;
                }); // "Big Smile";
                break;
            case 'extraoralrightprofile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 183;
                }); // "Right Profile";
                break;
            case 'extraoralsubmentovertex':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 184;
                }); // "Submento Vertex";
                break;
            case 'extraoralfullbodypostanterior':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 185;
                }); // "Full Body Post Anterior";
                break;
            case 'extraoralfullbodypostright':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 186;
                }); // "Full Body Post Right";
                break;
            case 'extraoral3dfacescan':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 187;
                }); // "3D Face Scan";
                break;
            case 'intraoralupperarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 170;
                }); //  "Upper Arch";
                break;
            case 'intraorallowerarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 171;
                }); // "Lower Arch";
                break;
            case 'intraoralanteriorocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 172;
                }); // "Anterior Occlusion";
                break;
            case 'intraoralleftocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 173;
                }); //  "Left Occlusion";
                break;
            case 'intraoralrightocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 174;
                }); //  "right Occlusion";
                break;
            case 'intraorallingualfrenum':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 220;
                }); // "Lingual Frenum";
                break;
            case 'intraoralsurfacetongue':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 175;
                }); // "Vertical Surface Tongue";
                break;
            case 'occlusion3dupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 221;
                }); // "3D Upper Arch";
                break;
            case 'occlusion3dlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 222;
                }); //  "3D Lower Arch";
                break;
            case 'occlusion3dconstructedbite':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 223;
                }); //  "3D Constructed Bite";
                break;
            case 'occlusionupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 224;
                }); // "Upper Arch";
                break;
            case 'occlusionlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 225;
                }); //  "Lower Arch";
                break;
            case 'occlusionanterior':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 226;
                }); //  "Anterior";
                break;
            case 'occlusionleft':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 227;
                }); //  "Left";
                break;
            case 'occlusionright':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 228;
                }); //  "Right";
                break;
            default:
                return;
        }
    };

}
