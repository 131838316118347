// Vendors
import * as React from 'react';
import { FC, useEffect } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    SelectChangeEvent,
    IconButton,
    styled,
    Typography,
    Avatar,
    TextField,
    Divider,
    Card,
    Tabs,
    Tab,
    useMediaQuery,
    useTheme
} from "@mui/material";

// API
import * as _ from 'lodash';


const RootWrapper = styled(Box)(
    ({ theme }) => `
        margin-top: ${theme.spacing(2)};
        width: 100%;
        background: ${theme.colors.gradients.blue3};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};
  `
);


// const TabsContainerWrapper = styled(Box)(
//     ({ theme }) => `
//       background-color: ${theme.colors.alpha.black[5]};
//       justifyContent: 'space-evenly',
//     `
// );
interface SidebarProps {
    tabs: any[];
    activeTab: number;
    handleTabChange: (index: number) => void;
}

const Sidebar: FC<SidebarProps> = (props: SidebarProps) => {
    // function Sidebar() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        props.handleTabChange(newValue);
    };

    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Grid container paddingTop={2} >
            {/* <TabsContainerWrapper> */}
            <Card sx={{ display: 'flex', p: 2, overflow: 'auto' }}>
                <Tabs
                    orientation="vertical"
                    value={props.activeTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        '.MuiTabs-indicator': {
                            left: 0,
                            right: 'auto',
                            width: '100%'
                        },
                        '.MuiTab-root': { // Target the root of the Tab
                            alignItems: 'flex-start', // Align items to the start
                            textAlign: 'left', // Align text to the left
                            paddingLeft: '16px', // Adjust padding to your preference
                            '& .MuiTab-wrapper': { // Target the internal wrapper
                                textAlign: 'left !important', // Force text alignment to the left
                            },
                        }
                    }}
                >
                    {_.map(props.tabs, (t, key: number) => (
                        <Tab key={key} label={props.tabs[key]}
                            sx={{
                                marginBottom: 2,
                                textTransform: 'none', // Prevents uppercase transformation
                            }}
                        />
                    ))}
                </Tabs>
            </Card>
            {/* </TabsContainerWrapper > */}
        </Grid>
    )
};


export default Sidebar;