// Vendors
import React, { FC, useState, useEffect } from "react"
import {
    Button,
    Divider,
    Grid,
    TextField,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
    styled,
    Avatar,
    Card,
    Dialog,
    DialogTitle,
    DialogContent,
    MenuItem
} from "@mui/material";
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

// Components
import SectionContainer from "../../../components/UI/Containers/SectionContainer";

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import Claim from "../../../entities/Claim";
import { ClaimStatus, ClaimType, ClaimCategory } from "../../../entities/Enums";

// API
import { observer } from "mobx-react-lite"
import moment from "moment";
import _ from "lodash";

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.main};
        color: ${theme.palette.success.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.success};
  `
);
const AvatarError = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.error.main};
        color: ${theme.palette.error.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.error};
  `
);

const RootWrapper = styled(Card)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${theme.colors.gradients.blue3};
        color:  ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(2)};
  `
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};
  `
);

interface IGIProps {
    claim: any,
    claimData: any
}

export const InitialImpression: FC<IGIProps> = observer(({ claim, claimData }) => {
    const store = useMainStoreContext().reworkClaimStore;

    const [otherIssueText, setOtherIssueText] = useState(store.vwClaim.initialImpressionOtherNotes ? store.vwClaim.initialImpressionOtherNotes : '');
    const [deniedReasonNotesText, setDeniedReasonNotesText] = useState(store.vwClaim.deniedReasonNotes ? store.vwClaim.deniedReasonNotes : null);
    const [deniedReasonId, setDeniedReasonId] = useState(store.vwClaim.deniedReasonId ? store.vwClaim.deniedReasonId : null);
    const [claimTypeId, setClaimTypeId] = useState(store.vwClaim.claimTypeId ? store.vwClaim.claimTypeId : ClaimType.Pending);
    const [initialImpressionId, setInitialImpressionId] = useState(store.vwClaim.initialImpressionId ? store.vwClaim.initialImpressionId : null);
    const [hasAdverseEvents, setHasAdverseEvents] = useState(store.vwClaim.hasAdverseEvents);
    const [hasPatientComplaint, setHasPatientComplaint] = useState(store.vwClaim.hasPatientComplaint);
    const [initialImpressionByFullName, setInitialImpressionByFullName] = useState(store.vwClaim.initialImpressionByFirstName + ' ' + store.vwClaim.initialImpressionByLastName);
    const [initialImpressionOn, setInitialImpressionOn] = useState(store.vwClaim.initialImpressionOn ? moment(store.vwClaim.initialImpressionOn).format('MM/DD/yyyy') : '');
    const [cancelImpressionByFullName, setCancelImpressionByFullName] = useState((store.vwClaim.cancelImpressionByFirstName ?? '') + ' ' + (store.vwClaim.cancelImpressionByLastName ?? ''));
    const [cancelImpressionOn, setCancelImpressionOn] = useState(store.vwClaim.cancelImpressionOn ? moment(store.vwClaim.cancelImpressionOn).format('MM/DD/yyyy') : '');
    const [isDenied, setIsDenied] = useState(store.vwClaim.claimStatusId ? store.vwClaim.claimStatusId === ClaimStatus.Cancelled : false);
    const [isClaimIssuePopupOpen, setIsClaimIssuePopupOpen] = useState(false);

    React.useEffect(() => {
        store.loadAllReworkRequestValues();
        store.loadReworkRequestValues(claim);
    }, [store.claim]);

    const handleClaimTypeToggle = (
        event: React.MouseEvent<HTMLElement>,
        claimTypeSelect: number | null
    ) => {
        if (claimTypeSelect !== null) {
            setClaimTypeId(claimTypeSelect);
        }
    };

    const handleInitialImpressionToggle = (
        event: React.MouseEvent<HTMLElement>,
        initialImpressionSelect: number | null
    ) => {
        if (initialImpressionSelect !== null) {
            setInitialImpressionId(initialImpressionSelect);
        }
    };

    const handleHasAdverseEventsToggle = (
        event: React.MouseEvent<HTMLElement>,
        hasAdverseEventsSelect: boolean | null
    ) => {
        if (hasAdverseEventsSelect !== null) {
            setHasAdverseEvents(hasAdverseEventsSelect);
        }
    };

    const handleHasPatientComplaintToggle = (
        event: React.MouseEvent<HTMLElement>,
        hasPatientComplaintSelect: boolean | null
    ) => {
        if (hasPatientComplaintSelect !== null) {
            setHasPatientComplaint(hasPatientComplaintSelect);
        }
    };

    const updateClaim = async (isSendToLab?: boolean) => {
        const initialValue = 0;
        const sumWithInitial = store.reworkSelection.reduce(
            (accumulator, element) => accumulator + parseInt(element.value),
            initialValue
        );

        var temp = "";
        switch (claimTypeId) {
            case ClaimType.Repair:
                temp = store.claim.rxId.replace("-C", "-R");
                break;

            case ClaimType.Warranty:
                temp = store.claim.rxId.replace("-C", "-W");
                break;

            case ClaimType["Warranty & Repair"]:
                temp = store.claim.rxId.replace("-C", "-WR");
                break;

            default:
                break;
        }

        var updateClaim = (isSendToLab && _.isNull(store.vwClaim?.initialImpressionBy)) ? {
            ...store.claim,
            initialImpressionBy: store.userID,
            initialImpressionOn: new Date(),
            labClaimReceivedOn: new Date(),
            claimType: claimTypeId,
            initialImpression: initialImpressionId,
            initialImpressionOtherNotes: initialImpressionId === ClaimCategory.Other ? otherIssueText : '',
            hasAdverseEvents: hasAdverseEvents,
            hasPatientComplaint: hasPatientComplaint,
            rxId: temp,
            requestIds: sumWithInitial
        } : {
            ...store.claim,
            claimType: claimTypeId,
            initialImpression: initialImpressionId,
            initialImpressionOtherNotes: initialImpressionId === ClaimCategory.Other ? otherIssueText : '',
            hasAdverseEvents: hasAdverseEvents,
            hasPatientComplaint: hasPatientComplaint,
            rxId: temp,
            requestIds: sumWithInitial
        };

        if (!_.isNull(store.vwClaim?.cancelImpressionBy)) {
            updateClaim.cancelImpressionBy = store.userID;
            updateClaim.cancelImpressionOn = new Date();
        }

        var claims: any = await store.updateClaimChanges(updateClaim);
        store.vwClaim = claims.value.find(x => x.id === store.claimId);
    }

    const sendClaimToLab = async () => {
        updateClaim(true).then(() => {
            setInitialImpressionByFullName(store.vwClaim.initialImpressionByFirstName + ' ' + store.vwClaim.initialImpressionByLastName);
            setInitialImpressionOn(moment(store.vwClaim.initialImpressionOn).format('MM/DD/yyyy'));
        })
    }

    const cancelClaim = async () => {
        setIsDenied(true);
        let updateClaim = {
            ...store.claim,
            deniedReasonNotes: deniedReasonNotesText,
            deniedReasonId: deniedReasonId,
            // claimStatus: ClaimStatus.Cancelled,
            cancelImpressionBy: store.userID,
            cancelImpressionOn: new Date()
        };
        var claims: any = await store.updateClaimChanges(updateClaim);
        store.vwClaim = claims.value.find(x => x.id === store.claimId);
        setCancelImpressionByFullName(store.vwClaim.cancelImpressionByFirstName + ' ' + store.vwClaim.cancelImpressionByLastName);
        setCancelImpressionOn(moment(store.vwClaim.cancelImpressionOn).format('MM/DD/yyyy'));
    }

    const handleRequestSelected = (e) => {
        let newList: any = [];

        if ([...store.reworkSelection].some(x => x.id === e.id)) {
            newList = [...store.reworkSelection].filter(x => x.id !== e.id);
        }
        else {
            newList = [...store.reworkSelection, e];
        }

        store.setReworkSelection(newList);

    };

    return (
        <Grid container className={store.isExternalLab ? "principal-claim-container-disabled" : "principal-claim-container"} justifyContent={'center'}>
            <Grid xs={12} item container justifyContent={'flex-end'} padding={1}>
                {!store.isExternalLab && <Button variant="outlined" disabled={isDenied} onClick={() => { updateClaim() }}> Save Changes </Button>}
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingRight={1}>
                {/* <Box sx={{ pointerEvents: store.vwClaim.claimTypeId !== ClaimType.Pending ? 'none' : 'all' }}> */}
                <Box>
                    <SectionContainer
                        sectionTitle={'Claim Impression'}
                        cardContentHeight={'400px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Classify Claim</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimTypeId === ClaimType.Warranty ? "contained" : "outlined"}
                                                            onClick={() => setClaimTypeId(ClaimType.Warranty)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Warranty
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimTypeId === ClaimType.Repair ? "contained" : "outlined"}
                                                            onClick={() => setClaimTypeId(ClaimType.Repair)}
                                                        >
                                                            Repair
                                                        </Button>
                                                    </Grid>
                                                    {/* <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimTypeId === ClaimType["Warranty & Repair"] ? "contained" : "outlined"}
                                                            onClick={() => setClaimTypeId(ClaimType["Warranty & Repair"])}
                                                        >
                                                            Warranty & Repair
                                                        </Button>
                                                    </Grid> */}

                                                    {/* <ToggleButtonGroup exclusive onChange={handleClaimTypeToggle} value={claimTypeId}>
                                                    <ToggleButton value={ClaimType.Warranty} className="vivButton normalToggle"> Warranty </ToggleButton>
                                                    <ToggleButton value={ClaimType.Repair} className="vivButton normalToggle"> Repair </ToggleButton>
                                                    <ToggleButton value={ClaimType["Warranty & Repair"]} className="vivButton normalToggle"> Warranty & Repair </ToggleButton>
                                                </ToggleButtonGroup> */}
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Claim Issue</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <Grid item xs={4} container justifyContent={'center'}>
                                                        <Button size="small" variant="contained" onClick={() => setIsClaimIssuePopupOpen(true)}>
                                                            Select Issues
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={8} container justifyContent={'center'}>
                                                        {store.reworkSelection.map(y => (
                                                            <Grid item xs={12}>
                                                                <Typography

                                                                >
                                                                    • {y.name}
                                                                </Typography>
                                                            </Grid>
                                                        ))}
                                                    </Grid>


                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    {/* {((claimTypeId === ClaimType.Warranty) || (claimTypeId === ClaimType["Warranty & Repair"])) &&
                                        <>
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Set Initial Impression</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    size="small"
                                                                    fullWidth
                                                                    variant={initialImpressionId === InitialImpressionIssue["Manufacturing Issue"] ? "contained" : "outlined"}
                                                                    onClick={() => setInitialImpressionId(InitialImpressionIssue["Manufacturing Issue"])}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Manufacturing
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    size="small"
                                                                    fullWidth
                                                                    variant={initialImpressionId === InitialImpressionIssue["Material Issue"] ? "contained" : "outlined"}
                                                                    onClick={() => setInitialImpressionId(InitialImpressionIssue["Material Issue"])}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Material
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    size="small"
                                                                    fullWidth
                                                                    variant={initialImpressionId === InitialImpressionIssue["Provider/Patient Issue"] ? "contained" : "outlined"}
                                                                    onClick={() => setInitialImpressionId(InitialImpressionIssue["Provider/Patient Issue"])}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Provider/Patient
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    size="small"
                                                                    fullWidth
                                                                    variant={initialImpressionId === InitialImpressionIssue["Patient Issue"] ? "contained" : "outlined"}
                                                                    onClick={() => setInitialImpressionId(InitialImpressionIssue["Patient Issue"])}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Patient
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Button
                                                                    size="small"
                                                                    fullWidth
                                                                    variant={initialImpressionId === InitialImpressionIssue.Other ? "contained" : "outlined"}
                                                                    onClick={() => setInitialImpressionId(InitialImpressionIssue.Other)}
                                                                >
                                                                    Other
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    }
                                    {(initialImpressionId === InitialImpressionIssue.Other) &&
                                        <>
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">If other, explain:</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                            <TextField
                                                                onChange={(e) => { setOtherIssueText(e.target.value) }}
                                                                style={{ width: "100%" }}
                                                                minRows={4}
                                                                maxRows={4}
                                                                value={otherIssueText}
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    } */}
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Were there any Adverse Events?</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={hasAdverseEvents === true ? "contained" : "outlined"}
                                                            onClick={() => setHasAdverseEvents(true)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={hasAdverseEvents === false ? "contained" : "outlined"}
                                                            onClick={() => setHasAdverseEvents(false)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            No
                                                        </Button>
                                                    </Grid>

                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Was there a patient complaint involved?</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={hasPatientComplaint === true ? "contained" : "outlined"}
                                                            onClick={() => setHasPatientComplaint(true)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={hasPatientComplaint === false ? "contained" : "outlined"}
                                                            onClick={() => setHasPatientComplaint(false)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            No
                                                        </Button>
                                                    </Grid>

                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingLeft={1}>
                <Grid container direction={'column'}>
                    <Grid paddingBottom={1}>
                        <RootWrapper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'block', sm: 'flex' },
                                    width: '100%',
                                }}
                            >
                                <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
                                    <AvatarSuccess sx={{ mr: 2 }} variant="rounded">
                                        <AddTaskTwoToneIcon />
                                    </AvatarSuccess>
                                    <Box>
                                        <TypographyPrimary variant="h5">
                                            Initial Impression Complete?
                                        </TypographyPrimary>
                                        <TypographySecondary variant="subtitle2">
                                            Rework Request ID: {store.vwClaim.id}
                                        </TypographySecondary>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: { xs: 2, sm: 0 } }}>
                                    <Button variant="contained" color="info" disabled={_.isNumber(store.vwClaim.initialImpressionBy)} onClick={() => { sendClaimToLab() }}>Send to lab for investigation</Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    pt: { xs: 2, sm: 2 },
                                }}
                            >
                                <Typography variant="h6">Initial Impression Recorded By: <b>{initialImpressionByFullName}</b></Typography>
                                <Typography variant="h6">Date Recorded: <b>{initialImpressionOn}</b></Typography>
                            </Box>
                        </RootWrapper>
                    </Grid>
                    <Grid paddingTop={1}>
                        <RootWrapper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'block', sm: 'flex' },
                                    width: '100%',
                                }}
                            >
                                <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
                                    <AvatarError sx={{ mr: 2 }} variant="rounded">
                                        <CancelTwoToneIcon />
                                    </AvatarError>
                                    <Box>
                                        <TypographyPrimary variant="h5">
                                            Does rework request not meet criteria?
                                        </TypographyPrimary>
                                        <TypographySecondary variant="subtitle2">
                                            Input reason for cancelling the request
                                        </TypographySecondary>
                                    </Box>

                                </Box>
                                <Box sx={{ pt: { xs: 2, sm: 0 } }}>
                                    <Button variant="contained" disabled={(deniedReasonNotesText === null || deniedReasonId === null) || isDenied} onClick={() => { cancelClaim() }}>Cancel Request</Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    pt: { xs: 2, sm: 2 },
                                }}
                            >
                                <TextField
                                    InputProps={{ style: { backgroundColor: 'white' } }}
                                    fullWidth
                                    multiline
                                    onChange={(e) => { setDeniedReasonNotesText(e.target.value) }}
                                    minRows={4}
                                    maxRows={4}
                                    value={deniedReasonNotesText}
                                    margin="normal"
                                    disabled={isDenied}
                                />
                                <TypographyPrimary variant="h5">
                                    Cancellation Reason?
                                </TypographyPrimary>
                                <TextField
                                    InputProps={{ style: { backgroundColor: 'white' } }}
                                    fullWidth
                                    select
                                    placeholder={'Select Cancellation Reason'}
                                    value={deniedReasonId}
                                    onChange={event => {
                                        setDeniedReasonId(event.target.value)
                                    }}
                                >
                                    {store.deniedReasonValues.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}

                                </TextField>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    pt: { xs: 2, sm: 2 },
                                }}
                            >
                                <Typography variant="h6">Cancellation Recorded By: <b>{cancelImpressionByFullName}</b></Typography>
                                <Typography variant="h6">Date Recorded: <b>{cancelImpressionOn}</b></Typography>
                            </Box>
                        </RootWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={isClaimIssuePopupOpen} onClose={() => setIsClaimIssuePopupOpen(false)} maxWidth="md">
                <DialogTitle>Select all that apply:</DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={1}>
                        {store.allReworkRequestValues.map(x => (
                            <Grid item xs={6}>
                                <Button
                                    fullWidth
                                    variant={store.reworkSelection.some(y => y.id === x.id) ? "contained" : "outlined"}
                                    onClick={() => handleRequestSelected(x)}
                                >
                                    {x.name}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    )
})