import { action, makeObservable, observable, computed, runInAction } from "mobx";
import React from "react";
import { RefObject } from "react";
import { Gateway } from "../../api/Gateway";
import Plan from "../../entities/Plan";
import { MainStore } from "./MainStore";
import { RadioGroup } from "@mui/material";
import jwt_decode from "jwt-decode";
import * as _ from 'lodash';
import { FrenectomyStatus } from "../../entities/Enums";
import MyoCorrectSession from "../../entities/MyoCorrectSession";
import MyoCorrectSessionEvaluation from "../../entities/MyoCorrectSessionEvaluation";

const defaultPlan = {
	id: 0,
	planName: "Legacy",
	numberOfSessions: 0,
	midEval: null,
	finalEval: null,
	maxAdditionalSessionsAllowed: 0
}

export class SessionsStore {

	@observable private _sessions: any[] = [];
	@observable private _origSessions: any[] = [];
	@observable private _amountSessionsToAdd = 1;
	@observable private _locationSessions = 1;
	@observable private _amountSessionsToAddValueRef: RefObject<any>;
	@observable private _sessionsLocationRef: RefObject<typeof RadioGroup>;
	@observable private _sessionsBetweenValueRef: RefObject<any>;
	@observable private _sessionsInlineValueRef: RefObject<any>;
	@observable private _amountSessionsToAddRangeEnd: RefObject<HTMLDivElement>;
	@observable private _betweenSessionsRangeEnd: RefObject<HTMLDivElement>;
	@observable private _sessionsBetweenRdo: RefObject<HTMLDivElement>;
	@observable private _sessionsInlineRdo: RefObject<HTMLDivElement>;
	@observable private _addSessionsBtn: RefObject<HTMLButtonElement>;
	@observable private _addSessionsForm: RefObject<HTMLDivElement>;
	@observable private _plan: Plan = new Plan(defaultPlan);
	@observable private _ammountOfPlannedSessions = 0;
	@observable private _sessionsLocation = 0;
	@observable private _allowedSessionsCount = 0;
	@observable private _initialSessionNumber = 0;
	@observable private _disableRadiobuttons = false;
	@observable private _selectedFrenectomy: string[] = [];
	@observable private _patientIdentification: number = -1;
	@observable private _assignedTherapist: number = 0;
	@observable private _currentRow: any = {};
	@observable private _myoCorrectStatus = 0;
	@observable selectedFrenectomy: string[] = []
	@observable isLoadingSessions = false;

	userID: number = 0;

	@observable mainStore: MainStore;
	constructor(mainstore: MainStore) {
		makeObservable(this);
		this.mainStore = mainstore;
		this._amountSessionsToAddValueRef = React.createRef();
		this._amountSessionsToAddRangeEnd = React.createRef();
		this._sessionsLocationRef = React.createRef();
		this._sessionsBetweenRdo = React.createRef();
		this._sessionsBetweenValueRef = React.createRef();
		this._betweenSessionsRangeEnd = React.createRef();
		this._sessionsInlineRdo = React.createRef();
		this._sessionsInlineValueRef = React.createRef();
		this._addSessionsBtn = React.createRef();
		this._addSessionsForm = React.createRef();
		var token = sessionStorage.getItem('token');
		if (token) {
			var tokenData: { roles, id } = jwt_decode(token);
			this.userID = tokenData.id;
		}
	}

	@computed
	get patientIdentification(): number {
		return this._patientIdentification;
	}

	set patientIdentification(value: number) {
		this._patientIdentification = value;
	}

	@computed
	get myoCorrectStatus(): any {
		return this._myoCorrectStatus;
	}

	set myoCorrectStatus(value: any) {
		this._myoCorrectStatus = value;
	}

	@computed
	get sessions(): MyoCorrectSession[] {
		return this._sessions;
	}

	set sessions(value: MyoCorrectSession[]) {
		this._sessions = value;
	}

	get origSessions(): MyoCorrectSession[] {
		return this._origSessions;
	}

	set origSessions(value: MyoCorrectSession[]) {
		this._origSessions = value;
	}

	@computed
	get initialSessionNumber() {
		return this._initialSessionNumber;
	}

	set initialSessionNumber(value: any) {
		this._initialSessionNumber = value;
	}

	get assignedTherapist() {
		return this._assignedTherapist;
	}

	@action setAssignedTherapist = (value: number) => this._assignedTherapist = value;

	@computed
	get allowedSessionsCount() {
		return this._allowedSessionsCount;
	}

	set allowedSessionsCount(value: any) {
		this._allowedSessionsCount = value;
	}

	@computed
	get disableRadiobuttons() {
		return this._disableRadiobuttons;
	}

	set disableRadiobuttons(value: any) {
		this._disableRadiobuttons = value;
	}

	@computed
	get amountSessionsToAdd() {
		return this._amountSessionsToAdd;
	}

	set amountSessionsToAdd(value: any) {
		this._amountSessionsToAdd = value;
	}

	@computed
	get locationSessions() {
		return this._locationSessions;
	}

	set locationSessions(value: any) {
		this._locationSessions = value;
	}

	get currentRow(): any {
		return this._currentRow;
	}

	@action setCurrentRow = (newRow: any) => this._currentRow = newRow;

	@computed
	get sessionsLocation() {
		return this._sessionsLocation;
	}

	set sessionsLocation(value: any) {
		this._sessionsLocation = value;
	}

	@computed
	get amountSessionsToAddValueRef() {
		return this._amountSessionsToAddValueRef;
	}

	set amountSessionsToAddValueRef(value: any) {
		this._amountSessionsToAddValueRef = value;
	}

	@computed
	get sessionsLocationRef() {
		return this._sessionsLocationRef;
	}

	set sessionsLocationRef(value: any) {
		this._sessionsLocationRef = value;
	}

	@computed
	get sessionsBetweenValueRef() {
		return this._sessionsBetweenValueRef;
	}

	set sessionsBetweenValueRef(value: any) {
		this._sessionsBetweenValueRef = value;
	}

	@computed
	get sessionsInlineValueRef() {
		return this._sessionsInlineValueRef;
	}

	set sessionsInlineValueRef(value: any) {
		this._sessionsInlineValueRef = value;
	}

	@computed
	get sessionsBetweenRdo() {
		return this._sessionsBetweenRdo;
	}

	set sessionsBetweenRdo(value: any) {
		this._sessionsBetweenRdo = value;
	}

	@computed
	get sessionsInlineRdo() {
		return this._sessionsInlineRdo;
	}

	set sessionsInlineRdo(value: any) {
		this._sessionsInlineRdo = value;
	}

	@computed
	get addSessionsBtn() {
		return this._addSessionsBtn;
	}

	set addSessionsBtn(value: any) {
		this._addSessionsBtn = value;
	}

	@computed
	get addSessionsForm() {
		return this._addSessionsForm;
	}

	set addSessionsForm(value: any) {
		this._addSessionsForm = value;
	}

	@computed
	get betweenSessionsRangeEnd() {
		return this._betweenSessionsRangeEnd;
	}

	set betweenSessionsRangeEnd(value: any) {
		this._betweenSessionsRangeEnd = value;
	}

	@computed
	get amountSessionsToAddRangeEnd() {
		return this._amountSessionsToAddRangeEnd;
	}

	set amountSessionsToAddRangeEnd(value: any) {
		this._amountSessionsToAddRangeEnd = value;
	}

	@computed
	get plan(): Plan {
		return this._plan;
	}

	set plan(value: Plan) {
		this._plan = value;
	}

	@computed
	get ammountOfPlannedSessions() {
		return this._ammountOfPlannedSessions;
	}

	set ammountOfPlannedSessions(value: any) {
		this._ammountOfPlannedSessions = value;
	}

	@action setSelectedFrenectonomy = (newValue: string[]) => this.selectedFrenectomy = newValue;
	@action setPatientIdentification = (value: number) => { this.patientIdentification = value };
	@action setSelectedFrenectomy = (newValue: string[]) => { this.selectedFrenectomy = newValue };
	@action setAmountSessionsToAdd = (newAmmount: number) => { this.amountSessionsToAdd = newAmmount };
	@action setLocationSessions = (locationSessions: number) => { this.locationSessions = locationSessions };
	@action setLocation = (newLocation: number) => { this.sessionsLocation = newLocation };
	@action setMyoCorrectStatus = (newStatus: number) => { this.myoCorrectStatus = newStatus };

	clearSessionData = () => {
		runInAction(() => {
			this.plan = new Plan(defaultPlan);
			this.sessions = [];
			this.setCurrentRow({});
			this.origSessions = [];

		})
	}

	@action
	onAmountSessionsToAddChange = (value: any) => {
		this.setAmountSessionsToAdd(value);
		if (this.amountSessionsToAddRangeEnd !== undefined) {
			if (this.amountSessionsToAddRangeEnd.current !== undefined) {
				var numVal = Number.parseInt(value);
				if (numVal >= 1 && numVal <= this.allowedSessionsCount) {
					this.amountSessionsToAddRangeEnd.current.innerText = (numVal + 1).toString();
				} else {
					this.amountSessionsToAddRangeEnd.current.setAttribute("error", "");
				}
			}
		}
	}

	@action
	onBetweenSessionsChange = (value: any) => {
		this.setLocationSessions(value);
		if (this.betweenSessionsRangeEnd !== undefined) {
			if (this.betweenSessionsRangeEnd.current !== undefined) {
				var numVal = Number.parseInt(value);
				if (numVal >= 0 && numVal <= this.plan.numberOfSessions) {
					this.betweenSessionsRangeEnd.current.innerText = (numVal + 1).toString();
				} else {
					this.betweenSessionsRangeEnd.current.setAttribute("error", "");
				}
			}
		}
	}

	@action
	onSessionsInlineChange = (value: any) => {
		this.setLocationSessions(value);
	}

	@action
	onRdogroupChange = (event: any) => {
		if (event.currentTarget.value === "Between") {
			this.setLocation(0);
			this.sessionsBetweenRdo.current?.classList.remove("hidden");
			this.sessionsInlineRdo.current?.classList.add("hidden");
		}
		else {
			this.setLocation(1);
			this.sessionsBetweenRdo.current?.classList.add("hidden");
			this.sessionsInlineRdo.current?.classList.remove("hidden");
		}
	}

	@action
	updateFrenectomyStatus = async (patientId) => {
		var statuses = this.selectedFrenectomy.map(row => (
			FrenectomyStatus[row].toString()
		));
		var request = {
			PatientId: patientId,
			Statuses: statuses
		}
		await Gateway.post("myocorrect/patients/setfrenectomy", request);
	}

	@action
	addSession = async () => {
		var sessionsList: any[] = [];
		var newSession = {
			id: 0,
			patientRegistrationId: this.sessions[0].patientRegistrationId,
			sessionIndexNumber: Number(this.sessions.length),
			appointmentType: "Session " + (Number(this.sessions.length)),
			evaluationType: "",
			scheduleStatus: "Pending",
			assignedTherapistId: this.assignedTherapist,
			createdBy: Number(this.userID),
			modifiedBy: Number(this.userID),
			isActive: true,
			session: "Session"
		}
		sessionsList.push(newSession);
		var url = "/myocorrect/sessions/add";
		runInAction(() => this.isLoadingSessions = true);
		await Gateway.post(url, sessionsList).then((resp) => {
			this.sessions = resp;
		});
		runInAction(() => this.isLoadingSessions = false);
	}

	@action
	removeSession = async (sessionId) => {
		var url = "/myocorrect/session/" + sessionId;
		await Gateway.delete(url, null).then((resp) => {
			this.sessions = [];
			this.sessions = resp;
		});
	}

	@action setIsLoadingSessions = (newValue: boolean) => this.isLoadingSessions = newValue;

	@action
	loadCurrentSessions = async (patientId: any) => {
		var url = "myocorrect/patients/registration/" + patientId;
		runInAction(() => this.isLoadingSessions = true);
		await Gateway.get(url)
			.then((respData) => {
				this.sessions = respData["patientSessions"];
				if (!_.isNull(this.sessions) && !_.isUndefined(this.sessions)) {
					this.allowedSessionsCount = this.plan.numberOfSessions + 1 + this.plan.maxAdditionalSessionsAllowed - (this.sessions ? this.sessions.length : 0);

					var completedSessions = this.sessions.filter((session) => session.scheduleStatus === "Completed").sort((a, b) => {
						return b.sessionIndexNumber - a.sessionIndexNumber
					});;
					this.initialSessionNumber = completedSessions?.length > 0 ? completedSessions[0]?.sessionIndexNumber : 0;
				}
				if (this.allowedSessionsCount === 0) {
					this.disableRadiobuttons = true;
				}
				runInAction(() => this.isLoadingSessions = false);
			});
	}


	@action
	loadPlan = async (planId: any) => {
		var url = "myocorrect/plans/" + planId;
		await Gateway.get(url)
			.then((resp) => {
				this.plan = resp as unknown as Plan;
			});
	}

	@action
	handleAppointmentTypeChange = (patientSessionData, event) => {

		var newValue = event.value ? event.value : event.innerText;
		if (newValue !== '') {
			if (newValue === 'Pre Frenectomy' || newValue === 'Post Frenectomy') {
				if (this.sessions.find(x => x.appointmentType === newValue) !== undefined || patientSessionData.sessionIndexNumber === 0) {
					patientSessionData.appointmentType = patientSessionData.appointmentType;
					return;
				}
			}
			patientSessionData.appointmentType = event.value ? event.value : event.innerText;
			var url = "myocorrect/session/" + patientSessionData.id;
			Gateway.post(url, patientSessionData)
				.then((respData) => {
					if (respData) {
						this.sessions = [];
						this.sessions = respData;
					}

				})
		}
	};

	@action
	handleScheduleStatusChange = (patientSessionData, event) => {
		patientSessionData.scheduleStatus = event;
		var url = "myocorrect/session/" + patientSessionData.id;
		Gateway.post(url, patientSessionData)
			.then((respData) => {
				if (respData) {
					this.sessions = [];
					this.sessions = respData;
				}

			})
		// .then(() => {
		// });
	};


	@action
	handleEvaluationTypeChange = (patientSessionData, event) => {
		patientSessionData.evaluationType = event;
		var url = "myocorrect/session/" + patientSessionData.id;
		Gateway.post(url, patientSessionData)
			.then((respData) => {
				if (respData) {
					this.sessions = [];
					this.sessions = respData;
				}
			})
		// .then(() => {
		// });
	};

	@action
	handleSessionUpdate = (sessionData, newadvocateid) => {
		this.isLoadingSessions = true;
		var url = "/myocorrect/session/" + sessionData.id;
		let data =
			this.currentRow;
		data.assignedTherapistId = newadvocateid;
		data.appointmentType = sessionData.appointmentType;
		data.scheduleStatus = sessionData.scheduleStatus;
		data.evaluationType = sessionData.evaluationType;
		data.additionalNotes = sessionData.additionalNotes;
		data.scheduleDateTime = sessionData.scheduleDateTime;
		Gateway.post(url, data)
			.then((respData) => {
				if (respData) {
					this.sessions = [];
					this.sessions = respData;
				}
				this.isLoadingSessions = false;
			})
	};

	@action
	handleMyocorrectStatusChange = (patientData, event) => {
		var patientDto = {
			id: patientData.id,
			orgId: patientData.organizationId,
			myoCorrectStatusId: event
		}
		var url = 'myocorrect/patients/update';
		Gateway.post(url, patientDto)
			.then((respData) => {
				if (respData) {
					this.setMyoCorrectStatus(event)
				}
			})
	};
}
