import { Grid } from "@material-ui/core";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { useMainStoreContext } from "../stores/OldStores/MainStore";
// Default V2 theme
import 'survey-core/defaultV2.min.css';
// Modern theme
// import 'survey-core/modern.min.css';
import { StylesManager, Model } from 'survey-core';
import { GlobalUtils } from "../api/GlobalUtils";

interface IProps {
    qparam?: string | null;
}

StylesManager.applyTheme("defaultV2");

const FormPage: FC<IProps> = ({ qparam }) => {

    const [queryParameters] = useSearchParams();
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const { patient, provider, customForm, decodeAndLoad, saveResponses } = useMainStoreContext().userCustomFormStore;
    const [survey, setSurvey] = React.useState<Model>({} as Model);

    useEffect(() => {
        var aqparam = queryParameters.get("details");
        if (aqparam) {
            decodeAndLoad(aqparam);
        }
    }, []);

    useEffect(() => {
        if (patient.id && provider.id && customForm.id) {
            var model = new Model(customForm);
            //model.mode = 'display';
            model.onComplete.add((sender: Model) => {
                //alert(JSON.stringify(sender.data));
                saveResponses(sender.data);
            });
            setSurvey(model);
            setHasLoaded(true);
        }
    }, [provider, patient, customForm])

    const alertResults = useCallback((sender) => {
        const results = JSON.stringify(sender.data);
        //alert(results);
        saveResponses(sender.data);
        // Gateway.post(`questionaires/response/{questionId}/{sessionId}/{patientId}`, sender.data)
    }, []);

    return (
        hasLoaded ?
            <Grid container xs={12} direction="column">
                < Grid className='header' container xs={12} style={{ paddingTop: "15px" }} >
                    {/* <Grid container xs={12}> */}
                    <Grid container xs={6} justifyContent={"flex-start"} alignContent="center" direction="row">
                        <Grid container xs={4} alignContent="center" >
                            <Grid>
                                <img style={{ maxWidth: "200px" }} src={(patient.profileImageUrl) ? GlobalUtils.getPatientProfileUrl(patient.profileImageUrl, patient.vivosId) :
                                    patient.patientBigSmileFrontalPhoto ? GlobalUtils.getPatientProfileUrl(patient.patientBigSmileFrontalPhoto, patient.vivosId) : ""} />
                            </Grid>
                        </Grid>
                        <Grid container xs={8} direction="column" justifyContent="center" alignContent="flex-start">
                            <Grid>{`${patient.firstName} ${patient.lastName}`}</Grid>
                            <Grid>{`Email: ${patient.emailAddress}`}</Grid>
                            <Grid>{`Phone:  ${patient.primaryPhone}`}</Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={6} justifyContent={"flex-end"} >
                        <Grid xs={7} container alignContent="center" justifyContent={"flex-end"}>
                            <img style={{ maxWidth: "200px" }} src={(provider.logo && provider.personId) ? GlobalUtils.getProviderUrl(provider.logo, provider.personId) : ""} />
                        </Grid>
                        <Grid container xs={5} direction="column" justifyContent="center" alignContent={"flex-end"}>
                            <Grid>{`${provider.person.firstName} ${provider.person.lastName}`}</Grid>
                            <Grid>{`Email: ${provider.person.emailAddress}`}</Grid>
                            <Grid>{`Phone:  ${provider.person.primaryPhone}`}</Grid>
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                </Grid >
                <Grid className='body' container xs={12}>
                    <Survey model={survey} />
                </Grid>
                <Grid xl={12} container className="footer" justifyContent="center" style={{ marginTop: "25px" }}>
                    <Grid className="label">&copy; 2021 Vivos, Inc | Terms &amp; Conditions</Grid>
                </Grid>
            </Grid >
            : <></>
    )
}

export const Form = observer(FormPage);