
import { observer } from "mobx-react";
import { Button, Grid } from "@mui/material";
import ProviderPatient from "../../../../entities/ProviderPatient";
import React, { useCallback, useState } from "react";
import DataGrid from "../../../DataGrid/DataGrid";
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";
import ModalContainer from "../../../ModalContainer";
import { SubmitReworkClaimWizard } from "../SubmitReworkClaimWizard";
import react from "react";
import moment from "moment";
import { RxStatusDetails } from "./RxStatusDetails";
import CommentsEditStore from "../../../CommentsPanel/CommentsEditStore";
import { CommentsPanel } from "../../../CommentsPanel/CommentsPanel";
import { usePrescriptionStore } from "../../../../stores/Prescription/CreateEdit/MainStoreContext";
import { ClaimStatus, ProductionStatus } from "../../../../entities/Enums";

interface IGIProps {
    patient: ProviderPatient;
}

export const PatientPrescriptionDashboard: React.FC<IGIProps> = observer(({ patient }) => {

    const [selectedRow, setSelectedRow] = useState<any>({});
    let store = useMainStoreContext().reworkClaimStore;
    let prescriptionStore = usePrescriptionStore().prescriptionStore;
    let commentsEdit = new CommentsEditStore();
    react.useEffect(() => {
        prescriptionStore.getPrescriptionsByPatientIdGrid(patient.id);
        store.getClaimsByPatientId(patient.id);
        prescriptionStore.loadPage(patient.id);
        prescriptionStore.loadAllPrescriptionsListItemValues();
    }, []);



    const isAvailableToCreate = useCallback((shippedDate, applianceType, prescriptionId, productionStatusId) => {
        const shippedOn = moment(shippedDate);
        const today = moment(new Date());
        const diff = (today.diff(shippedOn, 'days'));
        const productionStatus = ProductionStatus[productionStatusId];
        let list = [...store.claimData];
        let result = list.filter(x =>
            x.prescriptionId === prescriptionId &&
            x.isActive === true &&
            ![ClaimStatus.Completed, ClaimStatus['Pending CCR Form']].includes(x.claimStatus)
        );

        if (productionStatus === "Cancelled") {
            return false;
        }

        return (([689, 690].includes(applianceType)) && diff < 1825) || diff < 1825 && result.length === 0
    }, [store.claimData]);
    const columns = [
        {
            field: 'rxId',
            headerName: 'RX ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={"patientPrescription.dashboard.cell.rxDetails." + params.row.id}
                                variant="text"
                                onClick={async () => {
                                    await prescriptionStore.getPrescription(params.row.id);

                                    setSelectedRow(prescriptionStore.prescription);
                                    store.setReworkSelection([]);
                                    store.setClaimFiles([]);
                                    store.setProviderNotes("");
                                    prescriptionStore.setShowRxStatusDetails(true);
                                }}
                            >
                                {params.row.rxId}
                            </Button>
                        }
                    </Grid>
                );
            }
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return <Grid>{params.row.appliance.name}</Grid>
            }
        },
        {
            field: 'shippedDate',
            headerName: 'Shipped Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>{params.row.shippedDate && moment(params.row.shippedDate).format('MM/DD/yyyy')}</Grid>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <Button id={"reworkClaim.dashboard.cell.action" + params.row.id}
                            onClick={() => {
                                setSelectedRow(params.row);
                                store.setReworkSelection([]);
                                store.setClaimFiles([]);
                                store.setProviderNotes("");
                                store.setShowSubmitReworkWizard(true);
                            }}
                            disabled={!params.row.shippedDate || !isAvailableToCreate(
                                params.row.shippedDate,
                                params.row.appliance.applianceType,
                                params.row.id,
                                params.row.productionStatus,
                            )}>
                            Create Claim
                        </Button>
                    </Grid>
                );
            },
        },
        // {
        //     field: 'action2',
        //     headerName: '',
        //     flex: 1,
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <Grid>                        
        //                 <CommentsPanel
        //                     commentType={15551}
        //                     significantId={params.row.id}
        //                     isOpen={commentsEdit.isOpenModal}
        //                     onClose={commentsEdit.handleCloseModal.bind(this)}
        //                 />
        //                 <Button id={"reworkClaim.dashboard.cell.action" + params.row.id}
        //                     onClick={() => {
        //                         setSelectedRow(params.row);
        //                         store.setReworkSelection([]);
        //                         store.setClaimFiles([]);
        //                         store.setProviderNotes("");
        //                         commentsEdit.handleClickModal();
        //                     }}
        //                     >
        //                     Add Comment
        //                 </Button>
        //             </Grid>
        //         );
        //     },
        // }
    ]

    return (
        <Grid>
            <Grid className="grid" id={'patientPrescription.dashboard' + 1}>
                <DataGrid
                    columns={columns}
                    loading={prescriptionStore.isLoadingPrescriptions}
                    data={prescriptionStore.prescriptionData}
                />
            </Grid>
            {prescriptionStore.showRxStatusDetails && <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={prescriptionStore.showRxStatusDetails}
                title={"Rx Status Details"}
                onClick={() => { }}
                onClose={() => {
                    prescriptionStore.setShowRxStatusDetails(false);
                }}>
                <RxStatusDetails
                    patient={patient}
                    prescription={selectedRow}
                />
            </ModalContainer>}
            {store.showSubmitReworkWizard && <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={store.showSubmitReworkWizard}
                title={"Submit Rework Claim"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowSubmitReworkWizard(false);
                }}>
                <SubmitReworkClaimWizard
                    patient={patient}
                    claim={selectedRow}
                    isEdit={false}
                    prescription={prescriptionStore.prescription}
                    onClose={() => {
                        store.setShowSubmitReworkWizard(false);
                    }} />
            </ModalContainer>}
        </Grid>
    )
}
);



