import { createContext, useContext } from "react";
import { BaseMainStore } from "../base/MainStore";
import { action, makeAutoObservable } from 'mobx';
import AuthEntity from "../../models/state_models/authUser";

export class UserStore extends BaseMainStore {
    authData: AuthEntity | null = null;

    constructor() {
        super();
        this.authData = new AuthEntity();
    }


    @action setAuthData(data: any) {
        store.authData = new AuthEntity();
        store.authData?.update(data);
    }
}


export const store = new UserStore();
export const MainStoreContext = createContext<UserStore>(store);
export const useUserStoreMainContext = () => {
    return useContext(MainStoreContext);
};


