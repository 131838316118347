import { ChangeEvent, useRef, useState } from 'react';

import {
    alpha,
    Avatar,
    Badge,
    Box,
    IconButton,
    Tabs,
    Popover,
    useTheme,
    styled,
    Tooltip,
} from '@mui/material';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import CardNotifications from '../../../../../components/Notifications';

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function HeaderNotifications() {
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const theme = useTheme();

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip arrow title={('Notifications')}>
                {/* <Badge
                    variant="dot"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    sx={{
                        '.MuiBadge-badge': {
                            background: theme.colors.success.main,
                            animation: 'pulse 1s infinite',
                            transition: `${theme.transitions.create(['all'])}`
                        }
                    }}
                > */}
                <IconButtonWrapper
                    sx={{
                        mr: 1,
                        background: alpha(theme.colors.primary.main, 0.1),
                        transition: `${theme.transitions.create(['background'])}`,
                        color: theme.colors.primary.main,

                        '&:hover': {
                            background: alpha(theme.colors.primary.main, 0.2)
                        }
                    }}
                    color="primary"
                    ref={ref}
                    onClick={handleOpen}
                >
                    <NotificationsActiveTwoToneIcon fontSize="small" />
                </IconButtonWrapper>
                {/* </Badge> */}
            </Tooltip>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <CardNotifications />
            </Popover>
        </>
    );
}

export default HeaderNotifications;
