import { action, makeObservable, observable } from "mobx";
import Person from "../../models/state_models/people";
import { BaseMainStore } from "../base/MainStore";

export  class PersonStore {
    mainStore: BaseMainStore;

    constructor(mainstore: BaseMainStore) {
      makeObservable(this);
      this.mainStore = mainstore;
    }

    @observable person: Person = new Person();
    @action setPerson = (newValue: Person) => { this.person = newValue }
}