import { observable } from "mobx";
import EcommerceItem from "./ecommerceItem";
import { Variant } from "../../stores/OldStores/ShopifyStore";

export class AIReportSetup {
    @observable id: number = 0;
    @observable name: string = "";
    @observable reportType: number = 0;
    @observable ecommerceItemId: number = 0;
    @observable ecommerceItem: EcommerceItem = new EcommerceItem();
    @observable variantSelectedShopify: Variant | undefined = undefined;
    @observable shopifyProduct: any | undefined = undefined; 
}