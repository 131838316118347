
// Vendors
import { Grid, Typography, TextField, MenuItem, Button, Box, styled, Alert } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
// Components

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import { Ethnicity, Gender } from '../../../entities/Enums';

//API
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { observer } from "mobx-react";
import { isMinor } from "../../../api/getAge";
import { GlobalUtils } from "../../../api/GlobalUtils";

import React, { useEffect, useState } from "react";
import _ from "lodash";

const BoxBorderBottom = styled(Box)(
	() => `
          border-bottom: transparent 5px solid;
    `
);

const StepOne = () => {
	const { currentPatient, setCurrentPatient, setStep } = useMainStoreContext().myoCorrectEnrollmentStore;
	const genders = Object.values(Gender).filter(v => typeof (v) === 'string');
	const ethnicities = Object.values(Ethnicity).filter(v => typeof (v) === 'string');
	const patientHistoryEditStore = useMainStoreContext().patientEditStore;
	const [relationships, setRelationships] = useState<any[]>([]);

	useEffect(() => {
		setRelationships(GlobalUtils.getRelationshipTypes());
	}, [])

	const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.firstName = event.target.value;
		setCurrentPatient(patient);
	};
	const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.lastName = event.target.value;
		setCurrentPatient(patient);
	};
	const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.genderId = parseInt(event.target.value);
		setCurrentPatient(patient);
	};
	const handleEthnicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.ethnicityId = parseInt(event.target.value);
		setCurrentPatient(patient);
	};
	const handleDateOfBirthChange = (value: Date | null) => {
		if (!value) return;
		const patient = { ...currentPatient };
		patient.dateOfBirth = value;
		setCurrentPatient(patient);
	};
	const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.altPhone = event.target.value;
		setCurrentPatient(patient);
	};
	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.primaryPhone = event.target.value;
		setCurrentPatient(patient);
	}
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const patient = { ...currentPatient };
		patient.emailAddress = event.target.value;
		setCurrentPatient(patient);
	};

	const getAge = (dateOfBirth: Date): number => {
		const today = new Date();
		const date = new Date(dateOfBirth);
		let age = today.getFullYear() - date.getFullYear();
		const months = today.getMonth() - date.getMonth();
		if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
			age--;
		}
		return age;
	};

	if (!currentPatient) return (<>Loading Patient...</>);

	const validateEmail = () => {
		var isEmptyLegalGuardianEmailAddress = true;
		var isEmptyPatientEmailAddress = _.isEmpty(currentPatient?.emailAddress);
		for (var i = 0; i< patientHistoryEditStore.legalGuardians.length; i++){
			if (!_.isEmpty(patientHistoryEditStore.legalGuardians[i]?.emailAddress)){
				isEmptyLegalGuardianEmailAddress = false;
				break;
			}
		}
		return isMinor(currentPatient.dateOfBirth) ? isEmptyLegalGuardianEmailAddress : isEmptyPatientEmailAddress;


	}

	return (
		<Grid container>
			<Grid item xs={12}>
				{/* TODO UPDATE PATIENT PERSONAL INFORMATION TO BE A REUSABLE WIDGET FOR 
				UPDATING NOT JUST PATIENT CREATION.
				<PatientPersonalInformation/> */}
				<Grid container spacing={3} padding={3}>
					<Grid item xs={10}>
						<Typography variant="h3">Verify Patient Information</Typography>
					</Grid>
					<Grid item xs={10}>
						<Box mb={2}>
							<Alert severity="info">If you want to Update Patient information please do it in the Patient Details tab.</Alert>
						</Box>
						{validateEmail() && isMinor(currentPatient.dateOfBirth) && <Box mb={2}>
							<Alert severity="warning">Remember that legal guardian's email address is required to continue the enrollment process.</Alert>
						</Box>}
						{validateEmail() && !isMinor(currentPatient.dateOfBirth) && <Box mb={2}>
							<Alert severity="warning">Remember that email address is required to continue the enrollment process.</Alert>
						</Box>}
					</Grid>
					<Grid item xs={2} sx={{ textAlign: 'right' }}>
						{/* <LoadingButton loading={isSavingPatient} size="small" variant='outlined' sx={{ borderRadius: '20px' }} onClick={handleSaveChanges}>Save Changes</LoadingButton> */}
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="first-name"
							label="First Name"
							fullWidth
							disabled={true}
							value={currentPatient?.firstName}
							onChange={handleFirstNameChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="last-name"
							label="Last Name"
							fullWidth
							disabled={true}
							value={currentPatient?.lastName}
							onChange={handleLastNameChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="gender"
							select
							label="Gender"
							fullWidth
							disabled={true}
							value={currentPatient?.genderId}
							onChange={handleGenderChange}
						>
							{genders.map((option) => (
								<MenuItem key={Gender[option]} value={Gender[option]}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Grid container spacing={3}>
							<Grid item xs={8} sm={8}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DatePicker
										label="Date of Birth"
										value={currentPatient?.dateOfBirth ?? ''}
										openTo="day"
										disableFuture={true}
										disabled={true}
										views={['year', 'month', 'day']}
										renderInput={(params) => <TextField {...params} id="date-of-birth" label="Date of Birth" fullWidth value={currentPatient?.dateOfBirth ?? ''} />}
										onChange={handleDateOfBirthChange}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={4} sm={4}>
								<TextField
									id="age"
									label="Age"
									fullWidth
									disabled
									value={currentPatient?.dateOfBirth ? getAge(currentPatient?.dateOfBirth) : ''} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="ethnicity"
							select
							label="Ethnicity"
							fullWidth
							disabled={true}
							value={currentPatient?.ethnicityId}
							onChange={handleEthnicityChange}
						>
							{ethnicities.map((option) => (
								<MenuItem key={Ethnicity[option]} value={Ethnicity[option]}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item xs={12} sm={4}></Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="email"
							label="Email"
							fullWidth
							disabled={true}
							value={currentPatient?.emailAddress}
							onChange={handleEmailChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="mobile-phone"
							label="Mobile Phone"
							fullWidth
							disabled={true}
							value={currentPatient?.altPhone}
							onChange={handleCellPhoneChange} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							id="home-phone"
							label="Home Phone"
							fullWidth
							disabled={true}
							value={currentPatient?.primaryPhone}
							onChange={handlePhoneChange} />
					</Grid>
					{(isMinor(currentPatient.dateOfBirth))
						?
						<Grid container spacing={3} padding={3}>
							<Grid item xs={12}>

								{patientHistoryEditStore.legalGuardians.map(legalGuardian => {
									return (
										<Grid container rowSpacing={1} columnSpacing={3} padding={3} direction="row">
											<Grid item xs={10}>
												<Typography variant="h4">Legal Guardian(s)</Typography>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													label='First Name'
													name="firstName"
													disabled={true}
													value={legalGuardian?.firstName}
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													label='Last Name'
													name="lastName"
													disabled={true}
													value={legalGuardian?.lastName}
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													label='Relationship'
													name="relationship"
													disabled={true}
													value={relationships.find(x => x.id === legalGuardian?.relationshipTypeId)?.name}
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={12} md={4}>
												<TextField
													fullWidth
													label='Email address'
													name="emailAddress"
													disabled={true}
													value={legalGuardian?.emailAddress}
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<TextField
													fullWidth
													label='Primary Phone'
													name="primaryPhone"
													disabled={true}
													value={legalGuardian?.primaryPhone}
													variant="outlined"
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<TextField
													fullWidth
													label='Mobile Phone'
													name="altPhone"
													disabled={true}
													value={legalGuardian?.altPhone}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									)
								})}
							</Grid>
						</Grid>
						: ''}
				</Grid>
			</Grid>
			<BoxBorderBottom
				padding={1}
				sx={(theme) => ({
					borderBottomColor: theme.palette.primary.main,
					width: '100%',
					backgroundColor: theme.colors.alpha.black[5],
					position: 'absolute',
					bottom: '0',
				})}>
				<Grid container justifyContent={'flex-end'}>
					<Button onClick={() => setStep(1)} variant="contained" color="primary" disabled={validateEmail()}>Continue</Button>
				</Grid>
			</BoxBorderBottom>

		</Grid>
	);
};

export default observer(StepOne);