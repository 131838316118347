// Vendors
import React, { FC, useState } from "react";
import {
    Button,
    Divider,
    Grid,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    styled,
    Card,
    MenuItem,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,

} from "@mui/material";
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';

// Components
import SectionContainer from "../../../components/UI/Containers/SectionContainer";
import { LabEstimatedRepairFees } from "./LabEstimatedRepairFees";
import { LabRemake } from "./LabRemake";

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { LabReworkMaterialBiteStatus, LabReworkMaterialModelStatus, Roles } from "../../../entities/Enums";

// API
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite"
import moment from "moment";

import CssTextField from "../../../components/CssTextField";
import _ from "lodash";

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
    `
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};
  `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.main};
        color: ${theme.palette.success.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.success};
  `
);

const RootWrapper = styled(Card)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${theme.colors.gradients.blue3};
        color:  ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(2)};
  `
);

interface IGIProps {
    claim: any,
    claimData: any;
    roles: any[]
}
interface RepairOption {
    name: string;
}

export const LabRework: FC<IGIProps> = observer(({ claim, claimData, roles }) => {
    const store = useMainStoreContext().reworkClaimStore;
    const [dateValueFabricatedOn, setDateValueFabricatedOn] = useState<Date | null>(claim.fabricatedOn);
    const [dateValueEstimatedShipDate, setDateValueEstimatedShipDate] = useState<Date | null>(claim.estimatedShipDate);
    const [dateValueShippedOn, setDateValueShippedOn] = useState<Date | null>(claim.shippedOn);
    const [labReworkNotes, setLabReworkNotes] = useState(claim.labReworkNotes);
    const [prodStatus, setProdStatus] = useState<string | undefined>("");
    const [prodStatusId, setProdStatusId] = useState(store.vwClaim.productionStatusId ? store.vwClaim.productionStatusId : 0);
    const [erfStatus, setErfStatus] = useState<string | undefined>("");
    const [erfQuote, setErfQuote] = useState(claim.erfamount ? claim.erfamount : 0);
    //const [erfEstimate, setErfEstimate] = useState(claim.erfamount ? (erfQuote + (erfQuote * 0.15) + 20) : 0);
    const [erfEstimateUpcharge, setErfEstimateUpcharge] = useState(claim.erfamount ? ((erfQuote * 0.15) + 20) : 0);
    const [labReworkRecordedBy, setlabReworkRecordedBy] = useState(store.vwClaim.reworkRecordedByFirstName + ' ' + store.vwClaim.reworkRecordedByLastName);
    const [labReworkRecordedOn, setLabReworkRecordedOn] = useState(store.vwClaim.labReworkRecordedOn ? moment(store.vwClaim.labReworkRecordedOn).format('MM/DD/yyyy') : '');
    const [isAisAdmin, setIsAisAdmin] = useState<any>([]);
    const [isERF, setIsERF] = useState<any>([]);
    const [isRepairPopupOpen, setIsRepairPopupOpen] = useState(false);
    //const [selectedRepairOptions, setSelectedRepairOptions] = useState<string[]>(store.repairOptions.map(x => x.name));
    const [isModelPrintingFeeIncluded, setIsModelPrintingFeeIncluded] = useState(claim.modelPrintingFeeIncluded ?? false);
    const [modelPrintQuote, setModelPrintQuote] = useState(claim.modelPrintQuote ?? 0);
    // const [updatedModelStatus, setUpdatedModelStatus] = useState<any>(claim.updatedX0020ModelX0020Status ? claim.updatedX0020ModelX0020Status : 1300);
    // const [updatedBiteStatus, setUpdatedBiteStatus] = useState<any>(claim.updatedX0020BiteX0020Status ? claim.updatedX0020BiteX0020Status : 1296);
    const [reworkBiteStatus, setReworkBiteStatus] = useState<any>(claim.labReworkMaterialBiteStatus ? claim.labReworkMaterialBiteStatus : LabReworkMaterialBiteStatus.Pending);
    const [reworkModelStatus, setReworkModelStatus] = useState<any>(claim.labReworkMaterialModelStatus ? claim.labReworkMaterialModelStatus : LabReworkMaterialModelStatus.Pending);
    const [trackingNumber, setTrackingNumber] = useState<string | null>(claim.trackingNoReworkToProvider);
    const [erfTotalAmount, setErfTotalAmount] = useState<string | null>(claim?.erftotalAmount);

    const updatedClaim = {
        ...claim,
        labReworkNotes: labReworkNotes,
        erfamount: erfQuote,
        erftotalAmount: Number(erfTotalAmount),
        fabricatedOn: dateValueFabricatedOn,
        modelPrintingFeeIncluded: isModelPrintingFeeIncluded,
        modelPrintQuote: modelPrintQuote,
        estimatedShipDate: dateValueEstimatedShipDate,
        shippedOn: dateValueShippedOn,
        trackingNoReworkToProvider: trackingNumber,
        labReworkMaterialBiteStatus: reworkBiteStatus,
        labReworkMaterialModelStatus: reworkModelStatus
    }

    React.useEffect(() => {
        store.loadProductionStatusValues();
        store.loadRepairOptions(store.vwClaim);
        setProdStatus(store.productionStatusValues.find(ps => ps.id === claim.productionStatus)?.name);
        setErfStatus(store.erfStatusValues.find(es => es.id === claim.erfstatus)?.name);
        // let user = store.teamMembers.find(tm => tm.userID === store.claim.labReworkRecordedBy)
        // setlabReworkRecordedBy(user ? user.firstName + " " + user.lastName : "");
        setIsAisAdmin(roles.find(x => x.Name === Roles.AISADMIN || x.Name === Roles.AISADMINMANAGER || x.Name === Roles.VIVOSADMIN));
        setIsERF(roles.find(x => x.Name === Roles.ESTIMATEDREPAIRREVIEW));
    }, [store.claim]);

    React.useEffect(() => {
        setErfEstimateUpcharge((erfQuote * 0.15) + 20);
    }, [erfQuote]);

    const saveRework = (isSendDetailsToVivos?: boolean) => {
        const initialValue = 0;
        const sumWithInitial = store.repairOptions.reduce(
            (accumulator, element) => accumulator + parseInt(element.value),
            initialValue
        );

        let updateClaim = isSendDetailsToVivos ?
            {
                ...updatedClaim,
                labReworkRepairOptions: sumWithInitial,
                labReworkRecordedOn: new Date(),
                labReworkRecordedBy: store.userID
            }
            : {
                ...updatedClaim,
                labReworkRepairOptions: sumWithInitial
            }
        store.updateClaimChanges(updateClaim).then((resp: any) => {
            store.vwClaim = resp.value.find(x => x.id === store.claimId);
            if (isSendDetailsToVivos) {
                setlabReworkRecordedBy(store.vwClaim.reworkRecordedByFirstName + ' ' + store.vwClaim.reworkRecordedByLastName);
                setLabReworkRecordedOn(moment(store.vwClaim.labReworkRecordedOn).format('MM/DD/yyyy'));
            }
        });
    };

    const onErfTotalAmountChange = erfTotalAmount => {
        setErfTotalAmount(erfTotalAmount);
    }

    const requestApproval = () => {
        setErfStatus("Pending Approval");
        let updateClaim = updatedClaim;
        updateClaim.erfstatus = 610; //Pending approval 
        store.updateClaimChanges(updateClaim);

        //TODO: Adding Shipping Cost and notify the provider.
    }

    const getLabelColor = (prodName) => {
        switch (prodName) {
            case "Pending":
                return "info";
            case "On Hold":
            case "Ready":
                return "warning";
            case "In Fabrication":
                return "primary";
            case "Shipped":
                return "success";
            case "Cancelled":
            case "Remake":
                return "error";
            default:
                return "secondary";
        }
    };

    const handleRepairOptionSelection = (option) => {
        let newList: any = [];

        if ([...store.repairOptions].some(x => x.id === option.id)) {
            newList = [...store.repairOptions].filter(x => x.id !== option.id);
        }
        else {
            newList = [...store.repairOptions, option];
        }

        store.setRepairOptions(newList);
    };

    const handleModelPrintingFeeIncluded = () => {
        setIsModelPrintingFeeIncluded(true);
    };

    const handleModelPrintingFeeNotIncluded = () => {
        setIsModelPrintingFeeIncluded(false);
    };

    return (
        <Grid container padding={1} justifyContent={'center'}>
            <Grid xs={12} item container justifyContent={'flex-end'} padding={1} spacing={1}>
                <Grid item>
                    <Button variant="outlined" onClick={() => { saveRework() }}> Save Changes </Button>
                </Grid>
                <Grid item>
                    <Button variant="outlined"> Generate Label </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingRight={1}>
                <Grid container direction={'column'}>
                    <Grid paddingBottom={1}>
                        <Box>
                            <SectionContainer
                                sectionTitle={'Lab Rework Details'}
                                cardContentHeight={'400px'}
                                sectionComponent={
                                    <Grid container direction={'column'} spacing={1} padding={2}>
                                        <List
                                            sx={{
                                                py: 0
                                            }}
                                        >
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Describe manufacturing work that needs to be performed to resolve request.</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                            <TextField
                                                                multiline
                                                                onChange={(e) => { setLabReworkNotes(e.target.value) }}
                                                                style={{ width: "-webkit-fill-available" }}
                                                                minRows={2}
                                                                maxRows={5}
                                                                value={labReworkNotes}
                                                            />
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Repairs to be made:</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                            <Grid item xs={4}>
                                                                <Button variant="contained" onClick={() => setIsRepairPopupOpen(true)}>
                                                                    Select Repairs
                                                                </Button>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {store.repairOptions.map(y => (
                                                                    <Grid item xs={12}>
                                                                        <Typography

                                                                        >
                                                                            • {y.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">ERF (estimated repair fee) to resolve claim.</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} paddingTop={1}>
                                                            <TextField
                                                                // style={{ width: "-webkit-fill-available" }}
                                                                size="small"
                                                                placeholder=""
                                                                type="number"
                                                                required={true}
                                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                    setErfQuote(Math.max(0, parseInt(event.target.value)));
                                                                }}
                                                                value={erfQuote}
                                                            />
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Is there a model printing fee included in the total ERF?</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} paddingTop={1}>
                                                            <Grid item xs={6}>
                                                                <Button variant={isModelPrintingFeeIncluded ? "contained" : "outlined"} size="small" onClick={handleModelPrintingFeeIncluded}>Yes</Button>
                                                                <Button variant={!isModelPrintingFeeIncluded ? "contained" : "outlined"} size="small" onClick={handleModelPrintingFeeNotIncluded}>No</Button>
                                                            </Grid>
                                                            {!isModelPrintingFeeIncluded && <Grid item xs={6} container direction={'column'}>
                                                                <Typography>How much will you be charging?</Typography>
                                                                <TextField
                                                                    size="small"
                                                                    placeholder=""
                                                                    type="number"
                                                                    value={modelPrintQuote}
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        setModelPrintQuote(Math.max(0, parseInt(event.target.value)));
                                                                    }}
                                                                />
                                                            </Grid>}

                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    </Grid>
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid paddingTop={1}>
                        <RootWrapper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'block', sm: 'flex' },
                                    width: '100%',
                                }}
                            >
                                <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
                                    <AvatarSuccess sx={{ mr: 2 }} variant="rounded">
                                        <AddTaskTwoToneIcon />
                                    </AvatarSuccess>
                                    <Box>
                                        <TypographyPrimary variant="h5">
                                            Lab rework review complete?
                                        </TypographyPrimary>
                                        <TypographySecondary variant="subtitle2">
                                            Rework Request ID: {claim.id}
                                        </TypographySecondary>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: { xs: 2, sm: 0 } }}>
                                    <Button
                                        variant="contained"
                                        disabled={store.vwClaim.labReworkRecordedBy}
                                        onClick={() => { saveRework(true) }}
                                        color="info"
                                    >
                                        Send Details to Vivos
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    pt: { xs: 2, sm: 2 },
                                }}
                            >
                                <Typography variant="h6">Rework Details Recorded By: <b>{labReworkRecordedBy}</b></Typography>
                                <Typography variant="h6">Date Recorded: <b>{labReworkRecordedOn}</b></Typography>
                            </Box>
                        </RootWrapper>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingLeft={1} paddingRight={1}>
                <Box>
                    <SectionContainer
                        sectionTitle={'Rework Tracker'}
                        cardContentHeight={'575px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Repair Cost Approval</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            {store.vwClaim.erfStatus.toLowerCase().includes('pending')
                                                ? <LabelWrapper
                                                    sx={(theme) => ({
                                                        background: theme.palette.warning.main,
                                                        color: theme.palette.getContrastText(theme.palette.warning.dark),
                                                    })}
                                                >

                                                    Pending
                                                </LabelWrapper>
                                                : store.vwClaim.erfStatus.toLowerCase().includes('approv')

                                                    ? <LabelWrapper
                                                        sx={(theme) => ({
                                                            background: theme.palette.success.main,
                                                            color: theme.palette.getContrastText(theme.palette.success.dark),
                                                        })}
                                                    >

                                                        Approved
                                                    </LabelWrapper>
                                                    : <LabelWrapper
                                                        sx={(theme) => ({
                                                            background: theme.palette.error.main,
                                                            color: theme.palette.getContrastText(theme.palette.error.dark),
                                                        })}
                                                    >

                                                        {store.vwClaim.erfStatus}
                                                    </LabelWrapper>
                                            }

                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Production Status</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <LabelWrapper
                                                sx={(theme) => {
                                                    const color = getLabelColor(prodStatus);
                                                    return {
                                                        background: theme.palette[color].main,
                                                        color: theme.palette.getContrastText(theme.palette[color].dark),
                                                    };
                                                }}
                                            >
                                                {prodStatus}
                                            </LabelWrapper>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Updated Material Status</Typography>}
                                            secondary={
                                                <Grid container spacing={1} paddingTop={1} justifyContent={'center'}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Model</Typography>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            defaultValue={LabReworkMaterialModelStatus.Pending}
                                                            value={reworkModelStatus}
                                                            onChange={event => {
                                                                setReworkModelStatus(event.target.value)
                                                            }}>
                                                            {store.labReworkMaterialModelStatusValues.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Bite</Typography>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            size="small"
                                                            defaultValue={LabReworkMaterialBiteStatus.Pending}
                                                            value={reworkBiteStatus}
                                                            onChange={event => {
                                                                setReworkBiteStatus(event.target.value)
                                                            }}
                                                        >
                                                            {store.labReworkMaterialBiteStatusValues.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />

                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Fabrication Date</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1} paddingX={3}>
                                                    <Grid xs={6} paddingTop={1}>

                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="DateBox"
                                                                openTo="day"
                                                                views={['year', 'month', 'day']}
                                                                value={dateValueFabricatedOn}
                                                                onChange={(value) => {
                                                                    setDateValueFabricatedOn(value);
                                                                }}
                                                                renderInput={params => (
                                                                    <CssTextField
                                                                        disabled={false}
                                                                        {...params}
                                                                        sx={{
                                                                            '& input': {
                                                                                backgroundColor: 'white',
                                                                                color: '#050505',
                                                                                fontFamily: 'Proxima Nova',
                                                                                fontSize: '16px',
                                                                            },
                                                                        }}
                                                                        className="claim-textarea"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Estimated Ship Date</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1} paddingX={3}>
                                                    <Grid xs={6} paddingTop={1}>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="DateBox"
                                                                openTo="day"
                                                                views={['year', 'month', 'day']}
                                                                value={dateValueEstimatedShipDate}
                                                                onChange={(value) => {
                                                                    setDateValueEstimatedShipDate(value);
                                                                }}
                                                                renderInput={params => (
                                                                    <CssTextField
                                                                        disabled={false}
                                                                        {...params}
                                                                        sx={{
                                                                            '& input': {
                                                                                backgroundColor: 'white',
                                                                                color: '#050505',
                                                                                fontFamily: 'Proxima Nova',
                                                                                fontSize: '16px',
                                                                            },
                                                                        }}
                                                                        className="claim-textarea"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Shipping Details</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1} paddingX={3}>
                                                    <Grid xs={6} paddingTop={1}>
                                                        <Typography variant="h5">Shipped Date</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="DateBox"
                                                                openTo="day"
                                                                views={['year', 'month', 'day']}
                                                                value={dateValueShippedOn}
                                                                onChange={(value) => {
                                                                    setDateValueShippedOn(value);
                                                                }}
                                                                renderInput={params => (
                                                                    <CssTextField
                                                                        disabled={false}
                                                                        {...params}
                                                                        sx={{
                                                                            '& input': {
                                                                                backgroundColor: 'white',
                                                                                color: '#050505',
                                                                                fontFamily: 'Proxima Nova',
                                                                                fontSize: '16px',
                                                                            },
                                                                        }}
                                                                        className="claim-textarea"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid xs={6} paddingTop={1}>
                                                        <Typography variant="h5">Tracking Number</Typography>
                                                        <TextField
                                                            // style={{ width: "-webkit-fill-available" }}
                                                            size="small"
                                                            placeholder=""
                                                            type="number"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                setTrackingNumber(event.target.value)
                                                            }}
                                                            value={trackingNumber}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />



                                </List>
                            </Grid>
                        }
                    />
                </Box>
            </Grid>
            {isAisAdmin && <Grid item xs={12} md={6} lg={4} paddingLeft={1}>
                <Grid container direction={"column"}>
                    <Grid paddingBottom={1}>
                        <LabEstimatedRepairFees
                            claimData={store.vwClaim}
                            erfQuote={erfQuote}
                            erfEstimateUpcharge={erfEstimateUpcharge}
                            erfApprovalDate={claim.erfapprovedOn && moment(claim.erfapprovedOn).format('MM/DD/yyyy')}
                            erfApprovalStatus={store.vwClaim.erfStatus}
                            modelPrintFee={modelPrintQuote}
                            onErfTotalAmountChange={onErfTotalAmountChange}
                        />
                    </Grid>
                    <Grid paddingTop={1}>
                        <LabRemake
                            claimData={store.vwClaim}
                        />
                    </Grid>
                </Grid>
            </Grid>}
            <Dialog open={isRepairPopupOpen} onClose={() => setIsRepairPopupOpen(false)} maxWidth="md">
                <DialogTitle>Select Repair Option</DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={1}>
                        {store.repairOptionsValues.map(option => (
                            <Grid item xs={6} key={option.name}>
                                <Button
                                    fullWidth
                                    variant={store.repairOptions.some(y => y.name === option.name) ? "contained" : "outlined"}
                                    onClick={() => handleRepairOptionSelection(option)}
                                >
                                    {option.name}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    );
})