// Vendors
import React, { useEffect } from "react";
import { Box, Divider, FormControl, Grid, Typography, TextField, CircularProgress } from "@mui/material";

// Components
import UploadImgDragDrop from "../../../components/UploadDragAndDrop/UploadImgDragDrop";

// Stores
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import ProviderPatient from "../../../entities/ProviderPatient";
import PatientVisit from "../../../entities/PatientVisit";
import Compliance from "../../../entities/Compliance";

// API
import { observer } from "mobx-react";
import _ from "lodash";

interface IGIProps {
    patient: ProviderPatient;
    visit: PatientVisit;
}

const defaultCompliance = {
    id: 0,
    visitId: -1,
    prescribedDaytimeHours: 0,
    prescribedNighttimeHours: 0,
    actualDaytimeHours: 0,
    actualNighttimeHours: 0,
    topViewUpper: null,
    topViewLower: null,
    bottomViewUpper: null,
    bottomViewLower: null,
    images: [] as any[],
    isActive: true,
}


export const PatientCompliance: React.FC<IGIProps> = observer(
    ({ patient, visit }) => {

        const [hasLoaded, setHasLoaded] = React.useState(false);
        const { complianceImagesSaved, complianceImagesToDelete, setComplianceImagesSaved, isLoading, currentVisit } = useMainStoreContext().visitRecordStore;
        const vs = useMainStoreContext().visitRecordStore;

        useEffect(() => {
            if (visit?.patientCompliances?.[0] && vs.patientCompliances?.[0]) {
                vs.patientCompliances[0].visitId = visit.id;
                vs.patientCompliances[0].images = [];
            }
            else {
                if (!vs.patientCompliances || vs.patientCompliances.length === 0) {
                    vs.setPatientCompliances([defaultCompliance as Compliance]);
                }
            }
            setComplianceImagesSaved(false);
            setHasLoaded(true);
        }, [currentVisit, vs.patientCompliances])

        const handleTopUpper = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "topUpper",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(vs.patientCompliances[0].images, (x) => {
                    return x.name === "topUpper";
                })
                if (i >= 0) {
                    vs.patientCompliances[0].images[i] = img;
                }
                else {
                    vs.patientCompliances[0].images.push(img);
                }
            }
        }

        const handleTopLower = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "topLower",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }
                var i = _.findIndex(vs.patientCompliances[0].images, (x) => {
                    return x.name === "topLower";
                })
                if (i >= 0) {
                    vs.patientCompliances[0].images[i] = img;
                }
                else {
                    vs.patientCompliances[0].images.push(img);
                }
            }
        }

        const handleBottomUpper = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "bottomUpper",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(vs.patientCompliances[0].images, (x) => {
                    return x.name === "bottomUpper";
                })
                if (i >= 0) {
                    vs.patientCompliances[0].images[i] = img;
                }
                else {
                    vs.patientCompliances[0].images.push(img);
                }

            }
        }

        const handleBottomLower = (data64string: string, dataPathUrl: string, imgType: any) => {
            if (data64string && dataPathUrl) {
                var img = {
                    name: "bottomLower",
                    pathUrl: dataPathUrl,
                    data64string: data64string
                }

                var i = _.findIndex(vs.patientCompliances[0].images, (x) => {
                    return x.name === "bottomLower";
                })
                if (i >= 0) {
                    vs.patientCompliances[0].images[i] = img;
                }
                else {
                    vs.patientCompliances[0].images.push(img);
                }

            }
        }

        const deleteTopUpperCallback = () => {
            complianceImagesToDelete.push("TOPUPPER");
            vs.currentVisit.patientCompliances[0].topViewUpper = null;
            vs.topUpperImages = []
        }
        const deleteTopLowerCallback = () => {
            complianceImagesToDelete.push("TOPLOWER");
            vs.currentVisit.patientCompliances[0].topViewLower = null;
            vs.topLowerImages = []
        }
        const deleteBottomUpperCallback = () => {
            complianceImagesToDelete.push("BOTTOMUPPER");
            vs.currentVisit.patientCompliances[0].bottomViewUpper = null;
            vs.bottomUpperImages = []
        }
        const deleteBottomLowerCallback = () => {
            complianceImagesToDelete.push("BOTTOMLOWER");
            vs.currentVisit.patientCompliances[0].bottomViewLower = null;
            vs.bottomLowerImages = []
        }

        return (
            <Grid className="formBasics">
                {isLoading &&
                    <div style={{
                        // do your styles depending on your needs.
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                    }}>
                        {
                            <Grid container direction="row" justifyContent="center">
                                <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                                <CircularProgress sx={{
                                    width: "90%",
                                    height: "90%"
                                }} />
                            </Grid>
                        }
                    </div>
                }
                {!isLoading && <Grid container direction="row" xs={12}>
                    <Grid xs={12} paddingLeft={3} paddingBottom={2} paddingTop={1} container direction="row">
                        <Grid xs={6}>
                            <Typography variant="h6">Prescribed Wear Time</Typography>
                            <Typography variant="h6" style={{ fontSize: "small" }}>(since the last record)</Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Typography variant="h6">Actual Wear Time</Typography>
                            <Typography variant="h6" style={{ fontSize: "small" }}>(since the last record)</Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} container direction="row" style={{ padding: "0px 0px 25px 50px" }}>
                        <Grid xs={3} >
                            <FormControl >
                                <Grid container direction={'row'}>
                                    <Typography variant="h5">Day Time Hours</Typography>
                                </Grid>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    placeholder=""
                                    required={true}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (vs.patientCompliances) {
                                            vs.patientCompliances[0].prescribedDaytimeHours = Number.parseInt(event.target.value);
                                        }
                                    }}
                                    value={vs.patientCompliances && vs.patientCompliances[0] ? vs.patientCompliances[0].prescribedDaytimeHours : 0}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={3}>
                            <FormControl>
                                <Grid container direction={'row'}>
                                    <Typography variant="h5">Night Time Hours</Typography>
                                </Grid>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    placeholder=""
                                    required={true}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (vs.patientCompliances) {
                                            vs.patientCompliances[0].prescribedNighttimeHours = Number.parseInt(event.target.value);
                                        }
                                    }}
                                    value={vs.patientCompliances && vs.patientCompliances[0] ? vs.patientCompliances[0].prescribedNighttimeHours : 0}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={3}>
                            <FormControl >
                                <Grid container direction={'row'}>
                                    <Typography variant="h5">Day Time Hours</Typography>
                                </Grid>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    placeholder=""
                                    required={true}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (vs.patientCompliances) {
                                            vs.patientCompliances[0].actualDaytimeHours = Number.parseInt(event.target.value);
                                        }
                                    }}
                                    value={vs.patientCompliances && vs.patientCompliances[0] ? vs.patientCompliances[0].actualDaytimeHours : 0}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={3}>
                            <FormControl >
                                <Grid container direction={'row'} >
                                    <Typography variant="h5">Night Time Hours</Typography>
                                </Grid>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder=""
                                    type="number"
                                    required={true}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        if (vs.patientCompliances[0]) {
                                            vs.patientCompliances[0].actualNighttimeHours = Number.parseInt(event.target.value);
                                        }
                                    }}
                                    value={vs.patientCompliances && vs.patientCompliances[0] ? vs.patientCompliances[0].actualNighttimeHours : 0}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid xs={12} className="modalPageHeight">
                        <Box paddingTop={2} sx={{ flexGrow: 1 }}>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Top View Upper Appliance</Typography>
                                <UploadImgDragDrop
                                    allowMultiple={false}
                                    type="appliance"
                                    parentCallback={handleTopUpper}
                                    typeOptions={[]}
                                    vivosId={patient.vivosId}
                                    visitId={visit?.id}
                                    extImages={vs.topUpperImages}
                                    deleteCallback={deleteTopUpperCallback} />
                            </Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Top View Lower Appliance</Typography>
                                <UploadImgDragDrop
                                    allowMultiple={false}
                                    type="appliance"
                                    parentCallback={handleTopLower}
                                    typeOptions={[]}
                                    vivosId={patient.vivosId}
                                    visitId={visit?.id}
                                    extImages={vs.topLowerImages}
                                    deleteCallback={deleteTopLowerCallback} />
                            </Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Bottom View Upper Appliance</Typography>
                                <UploadImgDragDrop
                                    allowMultiple={false}
                                    type="appliance"
                                    parentCallback={handleBottomUpper}
                                    typeOptions={[]}
                                    vivosId={patient.vivosId}
                                    visitId={visit?.id}
                                    extImages={vs.bottomUpperImages}
                                    deleteCallback={deleteBottomUpperCallback} />
                            </Grid>
                            <Grid container direction="column">
                                <Divider />
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Bottom View Lower Appliance</Typography>
                                <UploadImgDragDrop
                                    allowMultiple={false}
                                    type="appliance"
                                    parentCallback={handleBottomLower}
                                    typeOptions={[]}
                                    vivosId={patient.vivosId}
                                    visitId={visit?.id}
                                    extImages={vs.bottomLowerImages}
                                    deleteCallback={deleteBottomLowerCallback} />
                            </Grid>
                        </Box>
                        {complianceImagesSaved && <Grid container direction={'row'} justifyContent={'flex-end'} className="formLabel">
                            <Grid>Images uploaded successfully.</Grid>
                        </Grid>}
                    </Grid>
                </Grid>}
            </Grid>
        )
    }
);
