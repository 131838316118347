import { action, computed, makeAutoObservable, observable } from 'mobx';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';
import { MainStore } from './MainStore';
import PatientDocument from '../../entities/PatientDocument';
export class IntakeReportsStore {

    @observable private mainStore: MainStore;
    @observable private _reportPropertiesData: any[] = [];
    @observable private _reportValuesData: any[] = [];
    @observable private _reportFiles: PatientDocument[] = [];
    @observable private _reportFileValues: { [key: number]: [{ [key: string]: string }] } = {};
    userId: number = 0;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        const token = sessionStorage.getItem('token');
        if (token) {
            const tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    @computed
    get defaultReportValue(): any[] {
        return [{
            PropertyId: 0,
            Name: "",
            Value: 0
        }];
    }

    @computed
    get reportPropertiesData(): any[] {
        return this._reportPropertiesData;
    }

    set reportPropertiesData(value: any[]) {
        this._reportPropertiesData = value;
    }

    @computed
    get reportValuesData(): any[] {
        return this._reportValuesData;
    }

    set reportValuesData(value: any[]) {
        this._reportValuesData = value;
    }

    @computed
    get reportFiles(): PatientDocument[] {
        return this._reportFiles;
    }

    set reportFiles(value: PatientDocument[]) {
        this._reportFiles = value;
    }

    @computed
    get reportFileValues(): { [key: number]: [{ [key: string]: string }] } {
        return this._reportFileValues;
    }

    set reportFileValues(value: { [key: number]: [{ [key: string]: string }] }) {
        this._reportFileValues = value;
    }

    @action
    loadReportPropertiesData = async (reportName) => {
        const url = `patient/reportinfo/${reportName}/properties`;
        this.reportPropertiesData = await Gateway.get(url);

        return this.reportPropertiesData;
    };

    @action
    saveReportInfo = async (visitId, reportValues, reportName) => {
        var newReportInfoDto = {
            VisitId: visitId,
            ReportValues: reportValues.map(x => ({ PropertyId: x.PropertyId, Value: x.Value }))
        };

        const url = `/patient/reportinfo/${reportName}/${this.userId}`;
        var result = await Gateway.post(url, newReportInfoDto)
            .then(resp => {
                return (true);
            })
            .catch(err => {
                console.log(err);
                window.location.reload();
                return false;
            });
        return result;
    };
}