import { makeObservable, observable } from 'mobx';
import { TreatmentComplaint } from './treatmentComplain';
import { TreatmentObjective } from './treatmentObjective';
import { TreatmentPlanPhase } from './treatmentPlanPhase';
import { TreatmentSleepDisorder } from './treatmentPlanSleepDisorder';
import { TreatmentWorkingDiagnosis } from './treatmentWorkingDiagnosis ';

export class TreatmentPlan {
    @observable id: number = 0;
    @observable patientId: number = 0;
    @observable complaintNotes: string = '';
    @observable treatmentPlanDentalNotes: string = '';
    @observable treatmentPlanEvaluationNotes: string = '';
    @observable treatmentPlanOtherNotes: string = '';
    @observable treatmentPlanPrimarytherapyNotes: string = '';
    @observable treatmentPlanSupplementaltherapiesNotes: string = '';
    @observable treatmentPlanSurgeryNotes: string = '';
    @observable obstructiveSleepApnea: string = '';
    @observable dentalDiagnosisMalocclusion: string = '';
    @observable treatmentConditionsId?: number; // Optional chaining for nullable property
    @observable migrationId?: number; // Optional chaining for nullable property
    @observable createdBy: number = 0;
    @observable createdOn: Date = new Date();
    @observable modifiedBy?: number; // Optional chaining for nullable property
    @observable modifiedOn?: Date; // Optional chaining for nullable property
    @observable isActive?: boolean; // Optional chaining for nullable property
    @observable treatmentComplaints:TreatmentComplaint[] = [];
    @observable treatmentObjectives: TreatmentObjective[] = [];
    @observable treatmentPlanPhases: TreatmentPlanPhase[] = [new TreatmentPlanPhase()];
    @observable treatmentSleepDisorders:TreatmentSleepDisorder[] = [];
    @observable treatmentWorkingDiagnoses: TreatmentWorkingDiagnosis[] = [];

    constructor() {
        makeObservable(this);
    }
}