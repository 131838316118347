// Vendors
import React from 'react';
import {
    Typography,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@mui/material';

// API
import { observer } from 'mobx-react-lite';

// Components
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

interface ConstructionTablesProps {
    prescription: any;
}

export const VidaConstructionTable: React.FC<ConstructionTablesProps> = observer(({ prescription }) => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    return (
        <Grid>
            <Grid>
                <Typography variant="h3">
                    Construction
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>Vertical Thickness to be Based On</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.verticalThickness.find(x => x.id === prescription.appliance?.verticalThicknessId)?.name}</TableCell>
                            </TableRow>
                            {prescriptionStore.verticalThickness.find(
                                (x) => x.id === prescription.appliance?.verticalThicknessId
                            )?.name === 'Custom vertical' && (
                                    <TableRow>
                                        <TableCell>Custom Thickness</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>
                                            {prescription.appliance?.customThickness ?? 'N/A'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            <TableRow>
                                <TableCell>Unilateral Bite Block</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.unilateralBiteBlock.find(x => x.id === prescription.appliance?.unilateralBiteBlockId)?.name}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
});
