import { ChangeEvent, useRef, useState } from 'react';

import {
    alpha,
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    IconButton,
    Tab,
    Tabs,
    CardMedia,
    CardActionArea,
    AvatarGroup,
    Card,
    Popover,
    useTheme,
    styled,
    Tooltip,
    Typography
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from '../UI/Scrollbar';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { format, subDays } from 'date-fns';
import Text from '../UI/Text';

import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Link from '@mui/material/Link';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import BusinessCenterTwoToneIcon from '@mui/icons-material/BusinessCenterTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';

const BoxComposed = styled(Box)(
    () => `
  position: relative;
`
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
  position: relative;
  z-index: 7;

  .MuiTypography-root {
      color: ${theme.palette.primary.contrastText};

      & + .MuiTypography-root {
          color: ${alpha(theme.palette.primary.contrastText, 0.7)};
      }
  }
  
`
);

const BoxComposedImage = styled(Box)(
    () => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  filter: grayscale(80%);
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: inherit;
`
);

const BoxComposedBg = styled(Box)(
    () => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  height: 100%;
  width: 100%;
  border-radius: inherit;
`
);

const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
      overflow: visible !important;

      .MuiTabs-scroller {
          overflow: visible !important;
      }

      .MuiButtonBase-root {
          text-transform: uppercase;
          font-size: ${theme.typography.pxToRem(12)};

          &:last-child {
            margin-right: 0;
          }
      }
  `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      margin: 0 auto ${theme.spacing(2)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(42)};
      }
`
);

const LabelPrimary = styled(Box)(
    ({ theme }) => `
    font-weight: bold;
    border-radius: ${theme.general.borderRadiusSm};
    background: ${theme.colors.primary.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    padding: ${theme.spacing(0.5, 1.5)};
    color: ${theme.palette.primary.contrastText};
`
);

const DividerVertialPrimary = styled(Box)(
    ({ theme }) => `
  height: 60%;
  width: 6px;
  left: -3px;
  border-radius: 50px;
  position: absolute;
  top: 20%;
  background: ${theme.colors.primary.main};
`
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function CardNotifications() {
    const ref = useRef<any>(null);
    const theme = useTheme();

    const [currentTab, setCurrentTab] = useState<string>('Notifications');

    const tabs = [
        // { value: 'Activity', label: ('Activity') },
        { value: 'Notifications', label: ('Notifications COMING SOON') },
        // { value: 'collaboration', label: ('Collaboration') }
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };


    return (
        <>
            <Card>
                <Box minWidth={440} p={2}>
                    <BoxComposed
                        mb={2}
                        sx={{
                            borderRadius: `${theme.general.borderRadius}`,
                            background: `${theme.colors.gradients.black1}`
                        }}
                    >

                    </BoxComposed>
                    <TabsWrapper
                        centered
                        onChange={handleTabsChange}
                        value={currentTab}
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {tabs.map((tab) => (
                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                    </TabsWrapper>
                </Box>
                <Divider />
                {currentTab === 'Activity' && (
                    <Box
                        sx={{
                            height: 220
                        }}
                    >
                        <Scrollbar>
                            <Box p={3}>
                                <Typography
                                    sx={{
                                        pb: 1
                                    }}
                                    component="div"
                                    variant="caption"
                                    fontWeight="bold"
                                >
                                    Activity content to be added in at a later time.
                                </Typography>
                            </Box>
                        </Scrollbar>
                    </Box>
                )}
                {currentTab === 'Notifications' && (
                    <Box
                        sx={{
                            height: 150
                        }}
                    >
                        <Scrollbar>
                            <Timeline
                                sx={{
                                    px: 2,
                                    py: 1,
                                    m: 2
                                }}
                            >
                                <TimelineItem
                                    sx={{
                                        p: 0
                                    }}
                                >
                                    <TimelineSeparator
                                        sx={{
                                            position: 'relative'
                                        }}
                                    >
                                        {/* <TimelineDot
                                            sx={{
                                                marginTop: 0,
                                                left: `-${theme.spacing(2.1)} !important`,
                                                top: `-${theme.spacing(0.5)}`
                                            }}
                                            color="success"
                                        >
                                            <BusinessCenterTwoToneIcon />
                                        </TimelineDot> */}

                                    </TimelineSeparator>
                                    <TimelineContent
                                        sx={{
                                            pl: 3,
                                            pb: 4
                                        }}
                                    >
                                        <Typography variant="h5" gutterBottom>
                                            {format(new Date(), 'MMMM dd yyyy')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            In app notifications coming soon! Email notifications will be sent with important events, reminders, confirmations, etc
                                        </Typography>

                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Scrollbar>
                    </Box>
                )}
                {/* <Divider />
                <Box
                    p={2}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        sx={{
                            px: 2,
                            py: 0.5,
                            fontWeight: 'normal',
                            borderRadius: 10,
                            background: 'transparent',
                            color: `${theme.colors.primary.main}`,
                            border: `${theme.colors.primary.main} solid 2px`,
                            transition: `${theme.transitions.create(['all'])}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.colors.primary.main}`,
                                transition: `${theme.transitions.create(['color'])}`
                            },

                            '&:hover': {
                                px: 3,
                                background: `${theme.colors.primary.main}`,
                                color: `${theme.palette.getContrastText(
                                    theme.colors.primary.dark
                                )}`,
                                boxShadow: `${theme.colors.shadows.primary}`,

                                '.MuiSvgIcon-root': {
                                    color: `${theme.palette.getContrastText(
                                        theme.colors.primary.dark
                                    )}`
                                }
                            },
                            '&:active': {
                                boxShadow: 'none'
                            }
                        }}
                        variant="contained"
                        endIcon={<ArrowForwardTwoToneIcon />}
                        color="primary"
                    >
                        View all
                    </Button>
                </Box> */}
            </Card>
        </>
    );
}

export default CardNotifications;
