import React, { useEffect } from 'react';
import { useMainStoreContext } from '../stores/OldStores/MainStore';

const OnlineStore: React.FC = () => {

    const { getCustomCheckoutUrl } = useMainStoreContext().shopifyStore;

    useEffect(() => {
        getCustomCheckoutUrl(`https://${process.env.REACT_APP_SHOPIFY_STORE_DOMAIN}`).then((url) => {
            window.location.href = url;
        });
        
    }, []);

    return (
        <div>
            Redirecting...
        </div>
    );
};

export default OnlineStore;