import { action, makeAutoObservable, observable } from 'mobx';
import jwt_decode from 'jwt-decode';
import { MainStore } from '../../stores/OldStores/MainStore';

export default class CustomFormsBL {
    @observable private _forms = {};
    @observable private _sections = 0;
    @observable private _questions = [];
    @observable private _hasLoaded = false;
    @observable private _activeTab = 0;
    userID: any;
    roles: any;

    @observable mainStore: MainStore;

    constructor(mainStore: MainStore) {
        this.mainStore = mainStore;
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    get forms() {
        return this._forms;
    }

    set forms(value: any) {
        this._forms = value;
    }

    get sections() {
        return this._sections;
    }

    set sections(value: any) {
        this._sections = value;
    }

    get questions() {
        return this._questions;
    }

    set questions(value: any) {
        this._questions = value;
    }

    get hasLoaded() {
        return this._hasLoaded;
    }

    set hasLoaded(value: any) {
        this._hasLoaded = value;
    }

    get activeTab() {
        return this._activeTab;
    }

    set activeTab(value: number) {
        this._activeTab = value;
    }

    @action
    setActiveTab = (event: React.SyntheticEvent, index: number) => {
        this.activeTab = index;
    };
}
