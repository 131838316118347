import { makeObservable, observable } from 'mobx';

export class TreatmentSleepDisorder {
    @observable id: number = 0;
    @observable treatmentPlanId: number = 0;
    @observable sleepDisorder: string = '';

    constructor() {
        makeObservable(this);
    }
}
