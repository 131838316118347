import { action, makeAutoObservable, observable } from "mobx";
import { MainStore } from "./MainStore";
import jwt_decode from 'jwt-decode';
import Laboratory from "../../entities/Laboratory";

export class LaboratoryStore{
    mainStore: MainStore;
    userId: number = 0;
    
    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    @observable pageTitle = 'Lab Dashboard';
    @observable searchParam = '';
    @observable isLeftNavExpanded: boolean = false;  
    @observable toggleShowFilterResults: boolean = false;     
    @observable patientData: Laboratory[] = [];
    @observable origPatientData: Laboratory[] = [];
    @observable timeOutEvent: NodeJS.Timeout | null = null;

    @action setOrigPatientData = newPatientData => (this.origPatientData = newPatientData);

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    @action toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };

    @action filterBySearchString = () => {
        this.patientData = this.origPatientData.filter(
            x =>
                x.id.toString().toLowerCase().includes(this.searchParam.toLowerCase())
                //APPLY FILTERS WHEN HAVE DATA 
        );
    };
    
    clearData = () => {
        this.patientData = this.origPatientData;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.patientData;
    };
}

