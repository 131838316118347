// Vendors
import react, { FC, useState } from 'react';
import {
    Grid,
    styled,
    List,
    ListItem,
    ListItemText,
    alpha,
    Typography,
    Box,
    Button,
} from '@mui/material';

// Components
import SectionContainer from '../../../UI/Containers/SectionContainer';

// Store
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';

interface IDiagnosisPage {
    patient?: any;
}

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

export const DentalConditions: FC<IDiagnosisPage> = observer(() => {
    const { treatmentPlan, setTreatmentPlan } = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;

    const handleDentalDiagnosisChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        treatmentPlan.dentalDiagnosisMalocclusion = event.currentTarget.value;
        setTreatmentPlan(treatmentPlan);
    };

    return (
        <Grid>
            <SectionContainer
                sectionTitle={'Dental Diagnosis'}
                cardContentHeight={'500px'}
                sectionComponent={
                    <Grid>
                        <ListWrapper>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Malocclusion
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Class I Malocclusion"
                                                            variant={treatmentPlan.dentalDiagnosisMalocclusion === "Class I Malocclusion" ? "contained" : "outlined"}
                                                            onClick={handleDentalDiagnosisChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Class I Malocclusion
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Class II Malocclusion (Div I)"
                                                            variant={treatmentPlan.dentalDiagnosisMalocclusion === "Class II Malocclusion (Div I)" ? "contained" : "outlined"}
                                                            onClick={handleDentalDiagnosisChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Class II Malocclusion (Div I)
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Class II Malocclusion (Div II)"
                                                            variant={treatmentPlan.dentalDiagnosisMalocclusion === "Class II Malocclusion (Div II)" ? "contained" : "outlined"}
                                                            onClick={handleDentalDiagnosisChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Class II Malocclusion (Div II)
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Class III Malocclusion"
                                                            variant={treatmentPlan.dentalDiagnosisMalocclusion === "Class III Malocclusion" ? "contained" : "outlined"}
                                                            onClick={handleDentalDiagnosisChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Class III Malocclusion
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Class I Normal Occlusion"
                                                            variant={treatmentPlan.dentalDiagnosisMalocclusion === "Class I Normal Occlusion" ? "contained" : "outlined"}
                                                            onClick={handleDentalDiagnosisChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Class I Normal Occlusion
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                            </ListItem>
                        </ListWrapper>
                    </Grid>
                }
            />
        </Grid>
    );
});
