// Vendor
import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

// Components
import FileAccordion from './FileAccordion';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// Entities
import DictionaryListItem from '../../entities/DictionaryListItem';

// API
import { observer } from 'mobx-react';
import PatientDocument from '../../entities/PatientDocument';
import _ from 'lodash';
import { GlobalUtils } from '../../api/GlobalUtils';


interface IProps {
    patientId: number
}

const AIReportsSleepTest: React.FC<IProps> = ({ patientId }) => {
    const intakeReportsStore = useMainStoreContext().intakeReportsStore;
    const aiReportEntryStore = useMainStoreContext().aiReportEntryStore;

    const [isLoading, setIsLoading] = React.useState(true);
    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            if (item.listItemKey === 'SLEEPTEST') {
                setReportTypes(prevState => [...prevState, item]);
            }
        });
    }, []);

    useEffect(() => {
        intakeReportsStore.reportFiles = [];
        intakeReportsStore.reportFileValues = {};

        aiReportEntryStore.loadAllReportsByPatient(patientId, 191).then(() => {
            intakeReportsStore.loadReportPropertiesData('sleepstudy').then(resp => {
                intakeReportsStore.reportValuesData = [];
                resp.map(r => intakeReportsStore.reportValuesData.push({ PropertyId: r.id, Name: r.name, Value: null }));
                aiReportEntryStore.allPatientFiles.filter(x => x.isActive).map((report, index) => {
                    intakeReportsStore.reportFiles.push(new PatientDocument({
                        id: report.id,
                        fileName: report.displayName,
                        pathUrl: report.fileUrl,
                        imageType: report.fileDescriptorId,
                        isActive: report.isActive
                    }))

                    var vPatientSleepStudies = report?.patientSleepStudies.filter(x => x.patientFileId === report.id && x.isActive) ?? [];
                    var vReportValuesData = intakeReportsStore.reportValuesData.map(element => {
                        const newValue = vPatientSleepStudies.find(val => val.propertyId === element.PropertyId)?.value;
                        return newValue !== undefined ? { ...element, Value: newValue } : element;
                    })

                    intakeReportsStore.reportFileValues = {
                        ...intakeReportsStore.reportFileValues,
                        [index]: vReportValuesData as any
                    };

                });
                setIsLoading(false);
            })
        })

    }, []);

    const handleDownload = (index: number) => {
        if (!_.isEmpty(intakeReportsStore.reportFiles[index].pathUrl)) {
            const pathUrl = intakeReportsStore.reportFiles[index].pathUrl;

            // Create a function to trigger the download
            const downloadFile = (url) => {
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.target = '_blank';
                anchor.download = ''; // Optionally, set a specific file name here
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            };

            // Trigger the download
            downloadFile(pathUrl);
        }
    }

    const handleSave = (index: number, data: any) => {
        const array = intakeReportsStore.reportValuesData.map((element, idx) => ({
            ...element,
            Value: idx === index ? data : element.Value
        }));
        intakeReportsStore.reportValuesData = array;
        intakeReportsStore.reportFileValues = {
            ...intakeReportsStore.reportFileValues,
            [index]: data
        };
        //visitStore.loadVisit();
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const pdfFiles = Array.from(e.target.files).filter(file => file.type === 'application/pdf');

            var patientFiles = await Promise.all(pdfFiles.map(async pdfFile => {
                return (new PatientDocument({
                    id: 0,
                    pathUrl: pdfFile.name,
                    fileName: pdfFile.name,
                    data64String: await convertBase64(pdfFile) as unknown as string,
                    imageType: reportTypes[0],
                    isActive: true
                }))
            }))

            //setFiles([...files, ...pdfFiles]);
            intakeReportsStore.reportFiles = [...intakeReportsStore.reportFiles, ...patientFiles];
        }
    };

    return (
        <Grid container >
            <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                Sleep Test
            </Typography>
            {isLoading &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>
            }
            {!isLoading && <Grid item xs={12}>
                <input type="file" accept=".pdf" multiple onChange={handleFileUpload} />
                {intakeReportsStore.reportFiles.map((file, index) => (
                    <Grid item xs={12}>
                        <FileAccordion key={index} filename={file.fileName} index={index} reportValues={_.isEmpty(intakeReportsStore.reportFileValues[index]) ? intakeReportsStore.reportValuesData : intakeReportsStore.reportFileValues[index]} onSave={handleSave} onDelete={() => { }} onDownload={handleDownload} isEditable={false} />
                    </Grid>
                ))}
            </Grid>
            }
        </Grid>
    );
};

export default observer(AIReportsSleepTest);
