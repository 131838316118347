// Vendors
import { Component } from 'react';
import { ListSubheader, alpha, Box, List, styled } from '@mui/material';

// Components
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from './items';
import LeftNavBL from '../../../../components/LeftNav/LeftNavBL';

// API
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { matchPath } from 'react-router-dom';
import * as _ from 'lodash';


const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev: JSX.Element[], item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = item.name;
  const exactMatch = item.link
    ? !!matchPath(
      {
        path: item.link,
        end: true
      },
      path
    )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
        {
          path: item.link,
          end: false
        },
        path
      )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
      />
    );
  }

  return ev;
};

interface LeftNavProps {
  // onToggle(toggleOn: boolean): any;
  defaultPage?: string;
  bl?: any;

}

@observer
class SidebarMenu extends Component<LeftNavProps> {
  @observable currentNavItem: number = 0;
  @observable hasloaded: boolean = false;


  leftNavBL: LeftNavBL;
  constructor(props: any) {
    super(props);
    makeObservable(this);
    this.leftNavBL = new LeftNavBL();
  }

  componentDidMount() {
    this.leftNavBL.checkRoles();
    if (this.props.defaultPage) {
      this.currentNavItem = _.indexOf(this.leftNavBL.navItems, this.props.defaultPage);
      this.leftNavBL.currentNavItem = this.currentNavItem;
    }

    if (this.leftNavBL.isProvider) {
      this.leftNavBL.getProviderData();
    }

    this.hasloaded = true;
  }




  render() {
    // const { location } = this.props;
    // Filter the menu items based on the user type
    const sectionsMap = {
      isProvider: ['Dashboards', 'Patient Management', 'Practice Management', 'Help Center'],
      isClinicalAdvisor: ['Dashboards', 'Patient Management', 'Clinical Advisory', 'Help Center'],
      isVivosAdmin: ['Admin Management', 'Vivos Management', 'Report Management', 'Team Management'],
      isAISAdminManager: ['Vivos Management', 'Team Management'],
      isAISAdminManager_Lab: ['Production Management', 'Vivos Management', 'Team Management'],
      isAISAdmin: ['Vivos Management'],
      isAISSupervisor: ['Vivos Management'],
      isLabAdmin: ['Production Management'],
      isLabTech: ['Production Management'],
      isLabManager: ['Production Management'],
      isAiReportTech: ['Reports', 'Report Management'],
      isAiReportAdmin: ['Reports', 'Report Management'],
      isMyoCorrectAdmin: ['MyoCorrect Management', 'Team Management'],
      isMyoCorrectAdvisor: ['MyoCorrect Management'],
      isMyoCorrectAdvocate: ['MyoCorrect Management'],
      isExternalLab: ['Production Management'],
      isTreatmentNav: ['TX Nav Management'],
      isAISSpecialist: ['Dashboards', 'Patient Management', 'Help Center']
    };

    const getSectionsToShow = () => {
      let sectionsToShow = new Set();

      // Iterate over all roles and add corresponding sections
      Object.keys(sectionsMap).forEach(role => {
        if (this.leftNavBL[role]) {
          sectionsMap[role].forEach(section => sectionsToShow.add(section));
        }
      });

      return Array.from(sectionsToShow);
    };

    const sectionsToShow = getSectionsToShow();

    const filteredMenuItems = menuItems.filter(section => sectionsToShow.includes(section.heading)).map(section => ({
      ...section,
      items: section.items
    }));

    return (
      <>
        {filteredMenuItems.map((section) => (
          <MenuWrapper key={section.heading}>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {(section.heading)}
                </ListSubheader>
              }
            >
              {renderSidebarMenuItems({
                items: section.items,
                // path: this.currentLocation.pathname
                path: 'Temp - Must Fix Location'
              })}
            </List>
          </MenuWrapper>
        ))}
      </>
    );
  }


}

export default SidebarMenu;
