// Vendors
import react, { FC, useEffect, useState } from 'react';
import {
    Grid,
    styled,
    List,
    ListItem,
    ListItemText,
    alpha,
    Typography,
    Box,
    Button,
} from '@mui/material';

// Components
import SectionContainer from '../../../UI/Containers/SectionContainer';

// Store
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';

// API
import { observer } from 'mobx-react';
import { DictionaryListItem } from '../../../../models/state_models/dictionarylistitem';
import { GlobalUtils } from '../../../../api/GlobalUtils';
import { TreatmentWorkingDiagnosis } from '../../../../models/state_models/treatmentWorkingDiagnosis ';


interface IDiagnosisPage {
    patient?: any;
}

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

export const WorkingDiagnosis: FC<IDiagnosisPage> = observer(() => {
    /* const {
        intraoralDiagnosis,
        intraoralDiagnosisNotes,
        setIntraoralDiagnosis,
        setIntraoralDiagnosisNotes,
        intraoralCondition,
        intraoralRelationship,
    } = useMainStoreContext().patientTreatmentStore; */

    const {treatmentPlan, setTreatmentPlan} = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;

    const [selectedDiagnoses, setSelectedDiagnoses] = useState<string[]>([]);
    const [workingDiagnoses, setWorkingDiagnoses] = useState<DictionaryListItem[]>([]);

    useEffect(()=>{
        GlobalUtils.getWorkingDiagnosisItems().then((values)=>{
            if(values){
                setWorkingDiagnoses(values);
            }
        })
    },[]);

    const handleConditionChange = (diagnosis) => {
        var conditionPresent = treatmentPlan.treatmentWorkingDiagnoses.find(x=> x.diagnosis == diagnosis.name);
        if(conditionPresent){
            treatmentPlan.treatmentWorkingDiagnoses = treatmentPlan.treatmentWorkingDiagnoses.filter(x=> x.diagnosis != diagnosis.name);
        }
        else{
            var newCondition = new TreatmentWorkingDiagnosis();
            newCondition.treatmentPlanId = treatmentPlan.id;
            newCondition.diagnosis = diagnosis.name;
            treatmentPlan.treatmentWorkingDiagnoses.push(newCondition);
        }

        setTreatmentPlan(treatmentPlan);
    };

    return (
        <Grid>
            <SectionContainer
                sectionTitle={'Working Diagnosis'}
                cardContentHeight={'500px'}
                sectionComponent={
                    <Grid>
                        <ListWrapper>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Select all that apply
                                        </Typography>
                                    }
                                    secondary={
                                        <Box mt={0.7} display="flex" alignItems="center">
                                            <Grid container spacing={1}>
                                                {workingDiagnoses.map((diagnosis) => (
                                                    <Grid item xs={9} key={diagnosis.listItemKey}>
                                                        <Button
                                                            variant={treatmentPlan.treatmentWorkingDiagnoses.find(x=> x.diagnosis == diagnosis.name) ? "contained" : "outlined"}
                                                            onClick={() => handleConditionChange(diagnosis)}
                                                            fullWidth
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            {diagnosis.name}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        </ListWrapper>
                    </Grid>
                }
            />
        </Grid>
    );
});
