
import {
    useTheme,
    CardActionArea,
    Card,
    alpha,
    styled,
    CardMedia,
    Box,
    IconButton,
    Grid,
    Avatar,
    Typography,
    Button,
    darken
} from '@mui/material';

import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';

const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      bottom: ${theme.spacing(2)};
      z-index: 7;
    `
);

const Label = styled(Box)(
    ({ theme }) => `
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 1.2)};
      border-radius: 50px;
    `
);

const BgComposed = styled(Box)(
    ({ theme }) => `
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transition: ${theme.transitions.create(['opacity'])};
      background: ${darken(alpha(theme.colors.primary.main, 0.9), 0.8)};
      z-index: 6;
      opacity: 0;
      box-shadow: inset 0 0 2.3rem 0.5rem ${darken(
        theme.colors.primary.main,
        0.9
    )};
    `
);


function HotSpotMenuItem({ image, title, subTitle, buttonText, url, isSecondButton, buttonTextTwo, urlTwo }) {
    const theme = useTheme();

    const handleButtonClick = (url) => {
        window.open(url, '_blank', 'noopener noreferrer');
    }

    return (
        <Grid>
            <Card
                sx={{
                    textAlign: 'center',
                    position: 'relative',
                    transition: `${theme.transitions.create([
                        'box-shadow',
                        'transform'
                    ])}`,
                    transform: 'translateY(0px)',

                    '&:hover': {
                        transform: `translateY(-${theme.spacing(0.5)})`,
                        boxShadow: `0 2rem 8rem 0 ${alpha(
                            theme.colors.alpha.black[100],
                            0.05
                        )}, 
                        0 0.6rem 1.6rem ${alpha(
                            theme.colors.alpha.black[100],
                            0.15
                        )}, 
                        0 0.2rem 0.2rem ${alpha(
                            theme.colors.alpha.black[100],
                            0.1
                        )}`,

                        '& .MuiBgComposed': {
                            opacity: 1
                        }
                    }
                }}
            >
                <BgComposed
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    className="MuiBgComposed"
                >
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            color: `${theme.colors.alpha.trueWhite[100]}`
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            mb: 1,
                            color: `${theme.colors.alpha.trueWhite[70]}`
                        }}
                    >
                        {subTitle}
                    </Typography>
                    <Grid container spacing={2} justifyContent={'center'} paddingLeft={2} paddingRight={2}>
                        <Grid item>
                            <Button
                                onClick={() => handleButtonClick(url)}
                                sx={{
                                    px: 2.5,
                                    borderRadius: 10,
                                    transform: 'scale(1)',
                                    transition: `${theme.transitions.create(['all'])}`,
                                    boxShadow: `${theme.colors.shadows.info}`,

                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: `${theme.colors.shadows.info}`
                                    },
                                    '&:active': {
                                        boxShadow: 'none'
                                    }
                                }}
                                variant="contained"
                                color="info"
                            >
                                {buttonText}
                            </Button>
                        </Grid>
                        {isSecondButton &&
                            <Grid item>
                                <Button
                                    onClick={() => handleButtonClick(urlTwo)}
                                    sx={{
                                        px: 2.5,
                                        borderRadius: 10,
                                        transform: 'scale(1)',
                                        transition: `${theme.transitions.create(['all'])}`,
                                        boxShadow: `${theme.colors.shadows.info}`,

                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: `${theme.colors.shadows.info}`
                                        },
                                        '&:active': {
                                            boxShadow: 'none'
                                        }
                                    }}
                                    variant="contained"
                                    color="info"
                                >
                                    {buttonTextTwo}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </BgComposed>
                <CardMedia
                    component="img"
                    height="260"
                    sx={{
                        objectFit: 'contain',
                        borderRadius: 'inherit',
                        position: 'relative',
                        zIndex: 5
                    }}
                    image={image}
                    alt="..."
                />
                <CardActions
                    sx={{
                        bottom: 'auto',
                        top: `${theme.spacing(2)}`,
                        right: 'auto',
                        left: `${theme.spacing(2)}`
                    }}
                >
                    {/* <Label
                        sx={{
                            background: `${theme.palette.success.main}`,
                            color: `${theme.palette.success.contrastText}`
                        }}
                    >
                        {tagValue}
                    </Label> */}
                </CardActions>
            </Card>
        </Grid>
    );
}

export default HotSpotMenuItem;
