// Vendor
import { observer } from "mobx-react";
import { FC, Fragment } from "react";
import React from "react";
import { Grid } from "@mui/material";

// Components
import PageHeader from "./components/PageHeader";
import PageTitleWrapper from "../../components/UI/PageTitleWrapper";
import Banners from "./components/Banner";
import HotSpotMenu from "./components/HotspotMenu";
import Footer from "../../components/UI/Footer";

// Store
import { useMainStoreContext } from "../../stores/OldStores/MainStore";



interface IGIProps {
}

export const MainDashboard: FC<IGIProps> = observer(() => {
    const store = useMainStoreContext().mainDashboardStore;

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>
            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="left"
                spacing={4}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
                    <Banners />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                    <CardNotifications />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                    <HotSpotMenu />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Statistics />
                </Grid> */}
            </Grid>
            <Footer />
        </Fragment>
    )
})