import * as React from 'react';
import _ from "lodash";
import { observer } from "mobx-react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { isMinor } from '../../api/getAge';
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { AddressType, stateLabelValues } from "../../entities/Enums"
import LegalGuardian from "../LegalGuardian";
import LegalGuardianData from "../../models/state_models/legalGuardian"
import moment from 'moment';
import { useCreatePatientMainStoreContext, store } from '../../stores/Patient/Create/CreatePatientMainStore';
import { useUserStoreMainContext } from '../../stores/User/UserStore';

const LGDEFAULTVALUEPARENT = 18;

interface AutoCompleteOptionType {
  inputValue?: string;
  name: string;
}

const roles = [
  { label: 'Administrator', value: 'admin' },
  { label: 'Subscriber', value: 'subscriber' },
  { label: 'Customer', value: 'customer' }
];

const PatientPersonalInformation = () => {
  const { personStore, patientStore, legalGuardians } = useCreatePatientMainStoreContext();

  const genders = [
    { id: 22, label: "Female" },
    { id: 23, label: "Male" },
  ];

  const ethnicities = [
    { id: 844, label: "American Indian or Alaska Native" },
    { id: 27, label: "Asian" },
    { id: 28, label: "Black or African America" },
    { id: 24, label: "Caucasian (European, Middle Eastern, or North African Ancestry)" },
    { id: 25, label: "Hispanic or Latino" },
    { id: 29, label: "Native Hawaiian or Other Pacific Islander" },
    { id: 30, label: "Prefer Not To Say" },
  ];

  const { getGuardians, removeGuardian, guardiansReset } = useMainStoreContext().createLegalGuardianStore;

  const storeProvider = useMainStoreContext().providerStore;

  const filter = createFilterOptions<AutoCompleteOptionType>();
  const MAX_LEGAL_GUARDIAN: number = 2;

  const [constructorHasRun, setConstructorHasRun] = React.useState(false);
  const [legalGuardianElems, setLegalGuardiansElems] = React.useState([]);

  const [isAdultValidation, setIsAdultValidation] = React.useState(false);
  const [isLinCareProvider, setIsLinCareProvider] = React.useState(false);

  const [practiceLocationAddresses, setPracticeLocationAddresses] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    constructor();
    if (moment(personStore.person.dateOfBirth).isSame(new Date(), "day")) {
      setDateOptions(personStore.person.dateOfBirth);
    }

    storeProvider.getProviderTypes().then(() => {
      setIsLinCareProvider(storeProvider.providerTypes.filter(x => x.listItemKey === 'LINCARE').length === 1);
    });
  }, []);

  const setGenderAutoComplete = (genId: number): string => {
    var foundgender = genders.find(f => {
      return f.id == genId;
    });
    if (foundgender) {
      return foundgender.label;
    }
    return "";
  };

  React.useMemo(() => {
    storeProvider.getProviderDetails().then(() => {
      var practiceLocationAddressesTemp = storeProvider?.providerData?.addresses?.filter(x => x.addressTypeId === AddressType["Practice Address"])
        .map((address) => { return Object.assign({ id: address.id }, { label: address.company.companyName }) });
      setPracticeLocationAddresses(practiceLocationAddressesTemp);
      setIsLoading(false);
    });
  }, [])

  const setEthincityAutoComplete = (ethId: number): string => {
    var foundethnicity = ethnicities.find(f => {
      return f.id == ethId;
    });
    if (foundethnicity) {
      return foundethnicity.label;
    }
    return "";
  };

  const setPracticeLocationAutoComplete = (practiceLocationId: number | null): string => {
    if (_.isNull(practiceLocationId)) {
      return "";
    }
    var foundPracticeLocation = practiceLocationAddresses.find(f => {
      return f.id == practiceLocationId;
    });
    if (foundPracticeLocation) {
      return foundPracticeLocation.label;
    }
    return "";
  };

  const getAge = (dateOfBirth: Date): number => {
    const today = new Date();
    const date = new Date(dateOfBirth);
    let age = today.getFullYear() - date.getFullYear();
    const months = today.getMonth() - date.getMonth();
    if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
      age--;
    }
    return age;
  };

  const constructor = () => {
    if (constructorHasRun) { return; }

    setConstructorHasRun(true);
  }

  const stateMap = (): any => {
    var states = _.map(stateLabelValues, (s, i) => {
      return { id: s.value, label: s.label }
      //return <MenuItem key={i} value={s.value}>{s.label}</MenuItem>
    });
    return states;
  }

  // const handleConcernOtherChange = (value: any) => {
  //   setAreasOfConcern(value);
  // }

  const deleteGuardian = (index) => {
    if (isMinor(personStore.person.dateOfBirth) && legalGuardians.length>1) {
      //removeGuardian(index);
      legalGuardians.splice(index, 1);
      //setLegalGuardians(getGuardians());
      generateLegalGuardianElems();
      setConstructorHasRun(true);
    }
  }

  const generateLegalGuardianElems = (): JSX.Element[] => {
    var guardians: any = legalGuardians.map((lgu, index) => {
      return <Grid><LegalGuardian index={index} onDelete={deleteGuardian} /></Grid>
    });
    setLegalGuardiansElems(guardians);    
    return guardians;
  }

  const setDateOptions = (newValue: any) => {
    if (isMinor(newValue)) {
      setIsAdultValidation(false);
      /*setCity("");
      setState("");
      setPostalCode("");
      setEmailAddress("")
      setPrimaryPhone("");
      setAltPhone("");*/
    }
    else {
      setIsAdultValidation(true);
    }

    if (isMinor(newValue) && legalGuardians.length === 0) {
      legalGuardians.push(new LegalGuardianData());
    } else if (!isMinor(newValue)) {
      guardiansReset();
    }
    //setLegalGuardians(getGuardians());
    generateLegalGuardianElems();
    //setDateOfBirth(newValue as Date);
    personStore.person.dateOfBirth = newValue as Date;
  }

  const phoneRegExp = /^[0-9]{10}$/; // Example: 1234567890 (10 digits)

  return (
    <Grid>
      {isLoading &&
        <div style={{
          // do your styles depending on your needs.
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}>
          {
            <Grid container direction="row" justifyContent="center">
              <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

              <CircularProgress sx={{
                width: "90%",
                height: "90%"
              }} />
            </Grid>
          }
        </div>
      }
      {!isLoading && <Grid paddingLeft={3} paddingRight={3} paddingTop={3} sx={{ flexGrow: 1 }}>
        <Typography variant='h3'>Patient Information</Typography>
        <Formik
          initialValues={{
            person: personStore.person,
            // gender: '',
            // ethnicity: '',
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .max(255)
              .required('Field is required'),
            lastName: Yup.string()
              .max(255)
              .required('Field is required'),
            emailAddress: Yup.string()
              .email('The email provided should be a valid email address')
              .max(255)
              .notRequired(),
            dateOfBirth: Yup.date()
              .nullable()
              .required('The birthday field is required')
              .max(new Date(), 'The birthday cannot be in the future'),
            gender: Yup.string() // Define validation rules for the gender field
              .required('Gender is required'),
            primaryPhone: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid') //Currently not activating
              .notRequired(),
            altPhone: Yup.string()
              .matches(phoneRegExp, 'Phone number is not valid') //Currently not activating
              .notRequired(),
          })}
          onSubmit={() => { }}
        >
          {/* formik props */}
          {({
            errors,
            handleBlur,
            handleChange,
            // handleSubmit,
            // isSubmitting,
            touched,
            values
          }) => (
            <form autoComplete='patient-personal-information'>
              <Grid container spacing={3} paddingLeft={3} paddingRight={3} paddingTop={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(
                      touched.person?.firstName && errors.person?.firstName
                    )}
                    fullWidth
                    helperText={touched.person?.firstName && errors.person?.firstName}
                    label='First Name'
                    name="firstName"
                    required={true}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      //setFirstName(event.target.value);
                      personStore.person.firstName = event.target.value;
                    }}
                    value={personStore.person.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(
                      touched.person?.lastName && errors.person?.lastName
                    )}
                    fullWidth
                    required={true}
                    helperText={touched.person?.lastName && errors.person?.lastName}
                    label='Last name'
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      //setLastName(event.target.value);
                      personStore.person.lastName = event.target.value;
                    }}
                    value={personStore.person.lastName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="gender"
                    autoComplete={false}
                    freeSolo
                    autoSelect={false}
                    options={genders}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={setGenderAutoComplete(personStore.person.genderId)}
                    onChange={(event, value: unknown) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      var val = value as any;
                      //setGenderId(val.id);
                      personStore.person.genderId = val.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="gender"
                        name='gender'
                        label='Gender'
                        required={true}
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder="Select Gender..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={10} sm={10}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Date of Birth"
                          value={personStore.person.dateOfBirth || null}

                          openTo="day"
                          disableFuture={true}
                          views={['year', 'month', 'day']}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              id="date-of-birth"
                              label="Date of Birth"
                              name='dateOfBirth'
                              fullWidth
                              required={true}
                              value={personStore.person.dateOfBirth || null}
                            />}
                          onChange={(newValue) => {
                            setDateOptions(newValue);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <TextField
                        id="age"
                        label="Age"
                        fullWidth
                        disabled
                        value={personStore.person.dateOfBirth ? getAge(personStore.person.dateOfBirth) : ''} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="patientadd.cbo.ethnicityid"
                    autoComplete={false}
                    freeSolo
                    autoSelect={false}
                    options={ethnicities}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={setEthincityAutoComplete(personStore.person.ethnicityId)}
                    onChange={(event, value: unknown) => {
                      // Call handleChange to handle Formik's state
                      handleChange(event);
                      // Call patient creation store update
                      var val = value as any;
                      //setEthnicityId(val.id);
                      personStore.person.ethnicityId = val.id;
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="patientadd.txt.ethnicityid"
                        label='Ethnicity'
                        required={false}
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder="Select an Ethnicity..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="patientadd.cbo.practiceLocationId"
                    autoComplete={false}
                    freeSolo
                    autoSelect={false}
                    options={practiceLocationAddresses}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    value={setPracticeLocationAutoComplete(patientStore.patient.companyId)}
                    onChange={(event, value: unknown) => {
                      handleChange(event);
                      var val = value as any;
                      patientStore.patient.companyId = val.id;
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="patientadd.txt.practiceLocationId"
                        label='Practice Location'
                        required={false}
                        autoComplete='new-password'
                        variant="outlined"
                        placeholder="Select a Practice Location..."
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        error={Boolean(touched.person?.emailAddress && errors.person?.emailAddress)}
                        fullWidth
                        helperText={touched.person?.emailAddress && errors.person?.emailAddress}
                        label='Email address'
                        name="emailAddress"
                        required={false}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Call handleChange to handle Formik's state
                          handleChange(event);
                          // Call patient creation store update
                          //setEmailAddress(event.target.value);
                          personStore.person.emailAddress = event.target.value;
                        }}
                        value={personStore.person.emailAddress}
                        type="email"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        error={Boolean(touched.person?.primaryPhone && touched.person?.primaryPhone)}
                        helperText={touched.person?.primaryPhone && touched.person?.primaryPhone}
                        id="home-phone" //Home and primary interchangeable?
                        label="Primary Phone" //Home and primary interchangeable?
                        name="primaryPhone"
                        type='tel'
                        fullWidth
                        required={false}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Call handleChange to handle Formik's state
                          handleChange(event);
                          // Call patient creation store update
                          //setPrimaryPhone(event.target.value);
                          personStore.person.primaryPhone = event.target.value;
                        }}
                        value={personStore.person.primaryPhone} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        error={Boolean(touched.person?.altPhone && touched.person?.altPhone)}
                        helperText={touched.person?.altPhone && touched.person?.altPhone}
                        id="mobile-phone"
                        label="Mobile Phone"
                        name="altPhone"
                        type='tel'
                        fullWidth
                        required={false}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          // Call handleChange to handle Formik's state
                          handleChange(event);
                          // Call patient creation store update
                          //setAltPhone(event.target.value);
                          personStore.person.altPhone = event.target.value;
                        }}
                        value={personStore.person.altPhone} />
                    </Grid>
                    
                    {/*isLinCareProvider &&
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={patientStore.patient.isLincare}
                              onChange={(event) => {
                                // Call handleChange to handle Formik's state
                                handleChange(event);
                                // Call patient creation store update
                                patientStore.patient.isLincare = event.target.checked;
                              }}
                              name="isLincarePatient"
                              color="primary"
                            />
                          }
                          label="Is this a Lincare Patient?"
                        />
                      </Grid>
                    */}
                    
                  </Grid>
                </Grid>
              </Grid>

            </form>
          )}
        </Formik>
      </Grid>}
      {(isMinor(personStore.person.dateOfBirth) && getAge(personStore.person.dateOfBirth ? personStore.person.dateOfBirth : new Date()) != 0 && getGuardians().length <= MAX_LEGAL_GUARDIAN)
        ?
        <Grid container spacing={3} padding={3}>
          <Grid item xs={12}>
            {/* <Grid container direction="column"> */}
            <hr />
            {
              legalGuardianElems
            }
            {/* </Grid> */}
          </Grid>
          <Grid item justifyContent={"left"}>
            <Button variant='contained'
              hidden={legalGuardians.length >= MAX_LEGAL_GUARDIAN}
              onClick={() => {
                if (legalGuardians.length < MAX_LEGAL_GUARDIAN) {
                  legalGuardians.push(new LegalGuardianData());
                  generateLegalGuardianElems();
                }
              }} >
              {'[ + ] Add Legal Guardian'}
            </Button>
          </Grid>
        </Grid>
        : ''}
    </Grid>
  );
};
export default observer(PatientPersonalInformation);


