// Vendors
import {
    Box,
    Button,
    Grid,
    Typography,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TableContainer,
    styled,
    TextField
} from "@mui/material";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Enums
import { RxAppliances, PotrusionPosition } from "../../../entities/Enums";

// API
import { observer } from "mobx-react";
import CurrencyFormatter from "../../../components/formatters/currencyformatter";
import { useEffect } from "react";
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ShopifyCartReview from "../../../components/shopify/ShopifyCartReview";

const TableWrapper = styled(Box)(
    ({ theme }) => `
    border: 1px solid ${theme.colors.alpha.black[10]};
    border-bottom: 0;
    margin: ${theme.spacing(4)} 0;
  `
);

const RxFinalReview = () => {
    const {
        selectedLab,
        selectedAppliance,
        productsToAdd,
        fabricationTime,
        productionRushProduct,
        shouldCancelOrder,
        isUpdating,
        neededByDate,
        prescription,
        setNeededByDate,
        isClonedPrescription,
        selectedLabLogo,
    } = usePrescriptionStore().prescriptionStore;
    const { setLabAdjustConstructionBite, setPotrusionPositionId, setPotrusionDistance,
        labAdjustConstructionBite, potrusionPositionId, potrusionDistance, isSpeakWithLab, setIsSpeakWithLab } = useMainStoreContext().occlusionStore;
    const visitStore = useMainStoreContext().visitRecordStore;
    /* const handleBack = (): void => {
        ((selectedAppliance === RxAppliances.GUIDEVG) || (selectedAppliance === RxAppliances.GUIDEVGX) || (selectedAppliance === RxAppliances.GUIDEVW)) ? setStep(false) : setStep(false);
    };
    const handleNext = (): void => {
        setStep(true);
    }; */
    const { cart } = useMainStoreContext().shopifyStore;

    useEffect(() => {

        if (isUpdating) {
            setLabAdjustConstructionBite(visitStore.patientsImages[0]?.labAdjustConstructionBite);
            setIsSpeakWithLab(visitStore.patientsImages[0]?.isSpeakWithLab);
            setPotrusionPositionId(visitStore.patientsImages[0]?.potrusionPositionId);
            setPotrusionDistance(visitStore.patientsImages[0]?.potrusionDistance);
        }
    }, [isClonedPrescription, selectedLabLogo])

    const totalPrice: number = productsToAdd.reduce((accumulator, currentItem) => {
        const itemWithLowestPriority = currentItem.eCommerceItem.providerTypePrices.reduce((lowest, item) => {
            return item.priority < lowest.priority ? item : lowest;
        }, currentItem.eCommerceItem.providerTypePrices[0]);

        if (itemWithLowestPriority && itemWithLowestPriority.price) {
            return accumulator + itemWithLowestPriority.price;
        }

        // Check if currentItem has a valid variantSelectedShopify and price
        if (currentItem.variantSelectedShopify && currentItem.variantSelectedShopify.price['amount']) {
            // Add the price of the current item to the accumulator
            return accumulator + parseFloat(currentItem.variantSelectedShopify.price['amount']);
        } else {
            // If price is missing or invalid, return the accumulator unchanged
            return accumulator;
        }
    }, 0);

    return (
        <Grid container spacing={3} justifyContent={'center'}>
            {!shouldCancelOrder &&
                <>
                    <Grid item xs={12} padding={2}>
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <Box>
                                <Typography variant="h1" gutterBottom>
                                    Final Review
                                </Typography>
                                {/* <Typography variant="h3" color="text.secondary">
                            Order Review
                        </Typography> */}
                            </Box>
                        </Box>
                        <Divider
                            sx={{
                                my: 2
                            }}
                        />

                        {/* Additional Information Needed */}
                        {(selectedAppliance?.name.trim() === RxAppliances.DNA ||
                            selectedAppliance?.name.trim() === RxAppliances.MRNA ||
                            selectedAppliance?.name.trim() === RxAppliances.MMRNA ||
                            selectedAppliance?.name.trim() === RxAppliances.VIDA ||
                            selectedAppliance?.name.trim() === RxAppliances.VIDASLEEP ||
                            selectedAppliance?.name.trim() === RxAppliances.VERSA ||
                            selectedAppliance?.name.trim() === RxAppliances.PEX
                        ) && !isClonedPrescription && <>
                                <Grid item xs={12}>
                                    <Typography variant="h3" sx={{ fontSize: '1.4rem' }}>
                                        Additional Information Needed:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} container>
                                    <Grid xs={6} paddingX={4} paddingBottom={2}>
                                        <Typography variant="h5">
                                            Lab is approved to modify/adjust my construction bite up to 2mm in a vertical dimension only as needed.
                                        </Typography>
                                        <Grid container spacing={2} padding={1}>
                                            <Grid item xs={2}>
                                                <Button size="small" fullWidth variant={labAdjustConstructionBite ? 'contained' : 'outlined'} onClick={() => setLabAdjustConstructionBite(true)}>Yes</Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button size="small" fullWidth variant={labAdjustConstructionBite ? 'outlined' : 'contained'} onClick={() => setLabAdjustConstructionBite(false)}>No</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={6} paddingX={4} paddingBottom={2}>
                                        <Typography variant="h5">
                                            The lower midline compared to the upper midline at protrusion should be:
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} textAlign='center'>
                                                <Button size="small" variant={potrusionPositionId === PotrusionPosition.Left ? 'contained' : 'outlined'} onClick={() => setPotrusionPositionId(PotrusionPosition.Left)}>Left</Button>
                                            </Grid>
                                            <Grid item xs={4} textAlign='center'>
                                                <Button size="small" variant={potrusionPositionId === PotrusionPosition.Center ? 'contained' : 'outlined'} onClick={() => setPotrusionPositionId(PotrusionPosition.Center)}>Center</Button>
                                            </Grid>
                                            <Grid item xs={4} textAlign='center'>
                                                <Button size="small" variant={potrusionPositionId === PotrusionPosition.Right ? 'contained' : 'outlined'} onClick={() => setPotrusionPositionId(PotrusionPosition.Right)}>Right</Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            width: '50%',
                                                            display: 'flex',
                                                        }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={2}>
                                                                <Typography variant="caption">
                                                                    By
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                <TextField
                                                                    type="number"
                                                                    size="small"
                                                                    variant="outlined"
                                                                    value={potrusionDistance}
                                                                    onChange={(ev) => setPotrusionDistance(parseInt(ev.target.value))} />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="caption">
                                                                    mm
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={6} paddingX={4} paddingBottom={2}>
                                        <Typography variant="h5">
                                            {/* Would you like to speak to a laboratory specialist from the {selectedLab?.name} lab before this appliance is manufactured? */}
                                            Would you like to speak to a laboratory specialist before this appliance is manufactured?
                                        </Typography>

                                        <Grid container spacing={2} padding={1}>
                                            <Grid item xs={2}>
                                                <Button
                                                    onClick={() => setIsSpeakWithLab(true)}
                                                    size="small" fullWidth
                                                    variant={isSpeakWithLab ? 'contained' : 'outlined'}>
                                                    Yes
                                                </Button>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    onClick={() => setIsSpeakWithLab(false)}
                                                    size="small" fullWidth
                                                    variant={isSpeakWithLab ? 'outlined' : 'contained'}>
                                                    No
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>}

                        {/* Needed By Date */}
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h3" sx={{ fontSize: '1.4rem' }}>
                                    Date Prescription Needed:
                                </Typography>
                            </Grid>
                            <Box display="flex" alignItems="center" justifyContent="flex-start" paddingX={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Needed by"
                                        value={neededByDate ?? prescription.neededByDate ?? ''}
                                        openTo="day"
                                        minDate={new Date()}
                                        views={['year', 'month', 'day']}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                id="needed-by"
                                                label="Needed by"
                                                fullWidth
                                                required={false}
                                                value={neededByDate ?? ''}
                                                error={false}
                                                sx={{ maxWidth: 250 }}
                                            />
                                        )}
                                        onChange={setNeededByDate}
                                        disabled={false}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>

                        {/* Cost Calculation Table */}
                        <TableWrapper>
                            <Grid item xs={12}>
                                <Typography variant="h3" sx={{ fontSize: '1.4rem' }}>
                                    Cost Overview:
                                </Typography>
                            </Grid>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Appliance</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align='right'>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productsToAdd && productsToAdd.filter(p => p.shopifyProduct !== undefined).map((currentProduct, index, arr) => {
                                            const itemWithLowestPriority = currentProduct.eCommerceItem.providerTypePrices.reduce((lowest, item) => {
                                                return item.priority < lowest.priority ? item : lowest;
                                            }, currentProduct.eCommerceItem.providerTypePrices[0]);
                                            return (

                                                <TableRow >
                                                    <TableCell>
                                                        {currentProduct.isAditionalItem && currentProduct.shopifyProduct && currentProduct.shopifyProduct.title ? '' : currentProduct.shopifyProduct.title}
                                                    </TableCell>
                                                    <TableCell>{!currentProduct.isAditionalItem && currentProduct.shopifyProduct && currentProduct.shopifyProduct.title ? '' : currentProduct.shopifyProduct.title}</TableCell>
                                                    <TableCell align='right'>
                                                        {itemWithLowestPriority &&
                                                            <CurrencyFormatter amount={itemWithLowestPriority.price} />
                                                        }
                                                        {!itemWithLowestPriority &&
                                                            <CurrencyFormatter amount={currentProduct.variantSelectedShopify?.price['amount']} />
                                                        }

                                                    </TableCell>
                                                </TableRow>

                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fabrication Time</TableCell>
                                            <TableCell> </TableCell>
                                            <TableCell align='right'>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableCell>{fabricationTime}
                                        </TableCell>
                                        <TableCell> </TableCell>
                                        <TableCell align='right'>{fabricationTime === 'Standard' ? 0 : productionRushProduct?.variants[0]?.price?.amount}</TableCell>
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell align="right">
                                                <Typography
                                                    variant='h2'
                                                    gutterBottom
                                                    color="text.secondary"
                                                    fontWeight="bold"
                                                >
                                                    Total (Excluding Tax & Shipping):
                                                </Typography>
                                            </TableCell>

                                            <TableCell align='right'>
                                                <Typography variant="h3" fontWeight="bold">
                                                    <CurrencyFormatter amount={fabricationTime === 'Standard' ? totalPrice.toString() : (totalPrice + Number(productionRushProduct?.variants[0]?.price?.amount)).toString()} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </TableWrapper>
                        {/* <ShopifyCartReview checkoutData={cart} /> */}
                    </Grid>

                </>
            }
            {shouldCancelOrder && (
                <>
                    <Grid item xs={12} padding={2}>
                        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                            <Box>
                                <Typography variant="h1" gutterBottom>
                                    Cancel Prescription
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ my: 4 }} />
                        <Box mt={2}>
                            <Typography variant="body1" padding={1}>
                                A recent adjustment to the prescription has resulted in a change to the pricing. This may be due to one of the following reasons:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body2">A modification to the selected appliance.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">A change in the fabrication time selection.</Typography>
                                </li>
                                <li>
                                    <Typography variant="body2">An update to the appliance construction type (e.g., switching from dual arch to single arch).</Typography>
                                </li>
                            </ul>
                            <Typography variant="body1" padding={1}>
                                To proceed with these updates, the current prescription must be canceled, and a new prescription reflecting the desired changes will need to be created.
                            </Typography>
                            <Typography variant="body1" padding={1}>
                                If you believe this change is in error or need assistance, please don’t hesitate to contact our Airway Intelligence Service for support. We’re here to help ensure the process is as smooth as possible.
                            </Typography>
                            <Typography variant="body2" padding={1}>
                                <strong>Email:</strong> rx@vivoslife.com
                            </Typography>
                            <Typography variant="body2" padding={1}>
                                <strong>Phone:</strong> 720-738-3663
                            </Typography>
                        </Box>
                    </Grid>
                </>
            )}


        </Grid>
    );
};
export default observer(RxFinalReview);
