// Vendors
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  FormControl,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  TextField,
  Grid,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  styled,
  Popover,
  Box
} from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

// Components
import Upload3DImgMultiDragDrop from "../UploadDragAndDrop/Upload3DImgMultiDragDrop";

// Entities
import PatientVisit from "../../entities/PatientVisit";
import { ConstructionBiteInformation, ConstructionBiteType, OcclusionModelType, PotrusionPosition } from "../../entities/Enums";

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { observer } from "mobx-react";
import * as _ from 'lodash';

// Styling
import 'react-dropzone-uploader/dist/styles.css';
import '../../scss/FilesDragAndDrop.scss';
import CssTextField from "../CssTextField";
import CssSelectField from "../CssSelectField";
import 'react-calendar/dist/Calendar.css';
import DictionaryListItem from "../../entities/DictionaryListItem";
import { GlobalUtils } from "../../api/GlobalUtils";
import PatientImage from "../../entities/PatientImage";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";

interface IProps {
  isIntake: boolean;
  visit: PatientVisit | undefined;
  isRework: boolean;
  isPrescription: boolean;
  vivosId: string;
  images?: PatientImage[];
  handleImagesChange?: (images: PatientImage[]) => void;
}

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
      background: transparent;
      transition: ${theme.transitions.create(['all'])};
      color: ${theme.colors.warning.light};
      border-radius: 50px;
  
      &:hover {
        background: transparent;
        color: ${theme.colors.warning.dark};
      }
  `
);

const ScanOcclusion: React.FC<IProps> = ({ isIntake, visit, isRework, isPrescription, vivosId, images, handleImagesChange }) => {
  const { patient, setOcclusion3dMachineBrandId, } = useMainStoreContext().createPatientStore;
  const { constructionBite,
    modelSource,
    setConstructionBite,
    setModelSource,
  } = usePrescriptionStore().prescriptionStore;
  const { getVisitId, imagesToDelete } = useMainStoreContext().visitRecordStore;
  const {
    staffmember,
    impressiondate,
    lg_machinetype,
    setRMRelation,
    setLMRelation,
    setStaffMember,
    setImpressionDate,
    setMachineType,
    setOcclusionModelTypeId,
    setConstructionBiteInformationId,
    setConstructionBiteType,
    loadConstructionBiteValues,
    occlusionModelTypeId,
    constructionBiteType,
  } = useMainStoreContext().occlusionStore;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [state2Dor3D, setState2Dor3D] = React.useState(0);
  const [selectedBiteType, setSelectedBiteType] = useState('');
  const providerStore = useMainStoreContext().providerStore;
  const [machineBrand3DType, setMachineBrand3DType] = useState<DictionaryListItem[]>([]);
  const [patientOcclusionImageType, setpatientOcclusionImageType] = useState<DictionaryListItem[]>([]);

  // The key part: decide if inputs should be disabled/read-only
  const isReadOnly = false;

  const handleConstructionTypeChange = (event: ConstructionBiteType) => {
    setConstructionBite(event === ConstructionBiteType.Digital ? 'Digital' : event === ConstructionBiteType.Physical ? 'Physical' : 'None');
    setConstructionBiteType(event);

  };

  const handle3D = (notUsed1: string, notUsed2: string, option: number) => {
    setState2Dor3D(2);
  };
  const handle2D = (notUsed1: string, notUsed2: string, option: number) => {
    setState2Dor3D(1);
  };

  const getModelAge = (impressiondate: Date): number => {
    const today = new Date();
    const date = new Date(impressiondate);
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const timeDifference = today.getTime() - date.getTime();
    const modelAgeInDays = Math.floor(timeDifference / millisecondsPerDay);

    return modelAgeInDays;
  };

  const [extImages, setExtImages] = useState<PatientImage[]>([]);

  const deleteCallback = (itemOption: number) => {
    imagesToDelete.push(itemOption);
  };

  useMemo(() => {
    const machineBrands = GlobalUtils.get3DMachineBrands();
    const occlusionImageTypes = GlobalUtils.getPatientOcclusionImageTypes().filter((x) =>
      x.name.includes("3D")
    );

    setMachineBrand3DType(machineBrands);
    setpatientOcclusionImageType(occlusionImageTypes);
  }, []);

  useEffect(() => {
    loadConstructionBiteValues();
    if (visit) {
      loadVisitValues(visit);
    }
    if (occlusionModelTypeId === 0) {
      setOcclusionModelTypeId(OcclusionModelType.Digital);
    }

    if (constructionBiteType === 0) {
      setConstructionBiteType(ConstructionBiteType.Digital);
    }


    if (!_.isUndefined(providerStore.providerPreferences.cbctsbrand)) {
      setMachineType(
        lg_machinetype
          ? lg_machinetype
          : (machineBrand3DType?.find(x => x.id === providerStore.providerPreferences.cbctsbrand) as unknown as DictionaryListItem) ??
          machineBrand3DType[0]
      );
    }
  }, [visit]);

  const loadVisitValues = (visit: PatientVisit): any => {
    if (visit.patientsImages) {
      setRMRelation(
        visit.patientsImages[0]?.occlusionRmolarRelationship
          ? visit.patientsImages[0].occlusionRmolarRelationship
          : 0
      );
      setLMRelation(
        visit.patientsImages[0]?.occlusionLmolarRelationship
          ? visit.patientsImages[0].occlusionLmolarRelationship
          : 0
      );
      setStaffMember(
        visit.patientsImages[0]?.occlusionMemberTookImpression
          ? visit.patientsImages[0].occlusionMemberTookImpression
          : ''
      );
      setMachineType(
        visit.patientsImages[0]?.occlusion3dmachineBrandId
          ? visit.patientsImages[0].occlusion3dmachineBrandId
          : 0
      );
      setImpressionDate(
        visit.patientsImages[0]?.occlusionImpressionDate
          ? visit.patientsImages[0].occlusionImpressionDate
          : null
      );
      setConstructionBiteType(
        visit.patientsImages[0]?.constructionBiteType
          ? visit.patientsImages[0].constructionBiteType
          : 0
      );
      setOcclusionModelTypeId(
        visit.patientsImages[0]?.modelTypeId
          ? visit.patientsImages[0].modelTypeId
          : 0
      );

      setConstructionBite(occlusionModelTypeId === ConstructionBiteType.Digital ? 'Digital' : occlusionModelTypeId === ConstructionBiteType.Physical ? 'Physical' : 'None');
      setModelSource(occlusionModelTypeId === OcclusionModelType.Digital ? 'Digital' : 'Physical');


      if (machineBrand3DType && machineBrand3DType.length > 0 && visit.patientsImages[0]?.occlusion3dmachineBrandId) {
        const foundedType = machineBrand3DType.find(
          (x) => x.id.toString() === visit.patientsImages[0].occlusion3dmachineBrandId.toString()
        );

        if (foundedType) {
          setMachineType(foundedType);
          setOcclusion3dMachineBrandId(foundedType.id);
        }
      }
    }

    if (images) {
      setExtImages(images);
    }

  };

  const handleconstructionBiteInformationChange = (event) => {
    setConstructionBiteInformationId(event.target.value);
  };

  const handleModelTypeToggle = (type: OcclusionModelType) => {
    setModelSource(type === OcclusionModelType.Digital ? 'Digital' : 'Physical');
    setOcclusionModelTypeId(type);
  };

  const handleBiteTypeToggle = (type: string) => {
    setSelectedBiteType(type);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  return (
    <Grid>
      <form id="scan">
        <Grid paddingTop={1}>
          <Typography variant="h3">Select Impression Type</Typography>
          <Grid container direction="row" padding={1} spacing={1}>
            <Grid item>
              <Button
                variant={
                  occlusionModelTypeId === OcclusionModelType.Digital
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleModelTypeToggle(OcclusionModelType.Digital)}
                disabled={isReadOnly}
              >
                Digital
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={
                  occlusionModelTypeId === OcclusionModelType.Physical
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleModelTypeToggle(OcclusionModelType.Physical)}
                disabled={isReadOnly}
              >
                Physical
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            direction="row"
            paddingTop={1}
            paddingLeft={3}
            maxWidth={"800px"}
          >
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Staff Member Who Took the Impression"
                  required={false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setStaffMember(event.target.value);
                  }}
                  value={staffmember}
                  variant="outlined"
                  disabled={isReadOnly}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Impression Date"
                    value={impressiondate}
                    openTo="day"
                    disableFuture={true}
                    views={["year", "month", "day"]}
                    disabled={isReadOnly}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Impression Date"
                        fullWidth
                        required={true}
                        value={impressiondate}
                        onKeyDown={onKeyDown}
                      />
                    )}
                    onChange={(value) => {
                      if (!isReadOnly && value !== null) {
                        setImpressionDate(value);
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="modelAge"
                label="Days Since Scan"
                fullWidth
                disabled
                value={impressiondate ? getModelAge(impressiondate) : ""}
              />
            </Grid>
            <Grid item>
              {getModelAge(impressiondate) >= 60 && (
                <Typography variant="h5">
                  *Due to the age of the impressions, it will be recommended that
                  you retake them prior to submitting prescriptions for this
                  patient. Some appliances may have a lesser age requirement.
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container direction="row" paddingTop={2}>
            <Grid item xs={12}>
              <Typography variant="h3">Select Construction Bite Type</Typography>
            </Grid>
            <Grid item xs={12} padding={1} container spacing={1}>
              <Grid item>
                <Button
                  variant={
                    constructionBiteType === ConstructionBiteType.Digital
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    !isReadOnly && handleConstructionTypeChange(ConstructionBiteType.Digital)
                  }
                  disabled={isReadOnly}
                >
                  Digital
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={
                    constructionBiteType === ConstructionBiteType.Physical
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    !isReadOnly && handleConstructionTypeChange(ConstructionBiteType.Physical)
                  }
                  disabled={isReadOnly}
                >
                  Physical
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={
                    constructionBiteType ===
                      ConstructionBiteType["No Construction Bite"]
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    !isReadOnly &&
                    handleConstructionTypeChange(
                      ConstructionBiteType["No Construction Bite"]
                    )
                  }
                  disabled={isReadOnly}
                >
                  No Construction Bite
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid paddingTop={2}>
          <Divider />
          {occlusionModelTypeId === OcclusionModelType.Digital ? (
            <Grid container direction="column">
              <Typography variant="h3" paddingTop={1}>
                Upload 3D Digital Impressions
              </Typography>
              <Grid xs={4} paddingBottom={2} paddingLeft={3} paddingTop={2}>
                <Typography variant="subtitle1">
                  Select the brand of Machine used to create models
                </Typography>
                <TextField
                  fullWidth
                  id="selectImage"
                  select
                  onChange={(event: any) => {
                    if (!isReadOnly) {
                      const foundedType = machineBrand3DType.find(
                        (x) => x.id.toString() === event.target.value.toString()
                      );
                      if (foundedType) {
                        setMachineType(foundedType);
                        setOcclusion3dMachineBrandId(foundedType.id);
                      }
                    }
                  }}
                  value={
                    lg_machinetype.id
                      ? lg_machinetype.id
                      : lg_machinetype
                  }
                  disabled={isReadOnly}
                >
                  {machineBrand3DType.map((currentVal) => (
                    <MenuItem key={currentVal.id} value={currentVal.id}>
                      {currentVal.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Typography variant="caption" paddingTop={1} paddingBottom={1} align="center">
                Please upload your 3D Digital impression STL files below.
              </Typography>


              <Grid container justifyContent={"center"} paddingTop={2}>
                <Upload3DImgMultiDragDrop
                  disabled={isReadOnly}
                  extImages={extImages}
                  allowMultiple={true}
                  typeOptions={patientOcclusionImageType}
                  visitId={getVisitId()}
                  parentCallback={handle3D}
                  vivosId={vivosId}
                  deleteCallback={deleteCallback}
                  isRework={isRework}
                  handleImagesChange={handleImagesChange}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid />
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default React.memo(observer(ScanOcclusion));
