// Vendors
import React, { FC, useState, ChangeEvent } from "react";
import {
    Card,
    Box,
    CardHeader,
    List,
    IconButton,
    Typography,
    ListItemText,
    ListItemButton,
    styled,
    useTheme,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    ListItem,
    DialogContent
} from '@mui/material';

// API
import { observer } from "mobx-react-lite"

interface IGIProps {
    claimData: any,
}

const RootWrapper = styled(Card)(
    ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    color: ${theme.colors.alpha.trueWhite[100]};
    
    .MuiList-root {
      margin-bottom: ${theme.spacing(2)};

      .MuiButtonBase-root {
        color: ${theme.colors.alpha.trueWhite[70]};
      
        &:hover, &:active, .active {
            background: ${theme.colors.alpha.trueWhite[10]};
            color: ${theme.colors.alpha.trueWhite[100]};
        }
      }
      

    }
`
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
`
);

export const LabRemake: FC<IGIProps> = observer(({ claimData }) => {
    const [remakeCoverage, setRemakeCoverage] = useState('');

    return (

        <RootWrapper
            sx={{
                height: '100%'
            }}
        >
            <Grid padding={2}>
                <TypographyPrimary variant="h4">Remake Overview</TypographyPrimary>
                <TypographyPrimary variant="h5">Vivos ONLY</TypographyPrimary>
            </Grid>
            <Grid container paddingX={2}>
                <TypographyPrimary variant="h5">How will the remake be covered?</TypographyPrimary>
            </Grid>
            <Grid container justifyContent={'center'} spacing={2}>
                <Grid item>
                    <Button
                        color={'success'}
                        variant={remakeCoverage === 'underWarranty' ? "contained" : "outlined"}
                        onClick={() => setRemakeCoverage('underWarranty')}
                    >
                        Under Warranty
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color={'success'}
                        variant={remakeCoverage === 'newRxSubmission' ? "contained" : "outlined"}
                        onClick={() => setRemakeCoverage('newRxSubmission')}
                    >
                        New RX Submission
                    </Button>
                </Grid>
            </Grid>
            <Grid container justifyContent={'center'} padding={2}>
                <Grid item><Button variant="contained" color="warning">Request Provider Remake</Button></Grid>
            </Grid>
            <Grid paddingBottom={1} container spacing={1}>
                <Grid item container justifyContent={'center'}>
                    <Typography variant="h5" textAlign={'center'}>
                        Warranty Remake RxID: <b></b>
                    </Typography>
                </Grid>
                <Grid item container justifyContent={'center'}>
                    <Typography variant="h5" textAlign={'center'}>
                        Warranty Remake Status: <b></b>
                    </Typography>
                </Grid>
                <Grid item container justifyContent={'center'}>
                    <Typography variant="h5" textAlign={'center'}>
                        Warranty Remake Shipped: <b></b>
                    </Typography>
                </Grid>
            </Grid>


        </RootWrapper>
    )
})