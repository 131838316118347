// Vendors
import React, { useEffect } from 'react';
import { FC, useState } from 'react';
import { Grid, MenuItem, TextField, Typography, Box, ButtonGroup, Button } from '@mui/material';


// Entities
import AIReport from '../../../entities/AIReports';
import MyoCorrectPatient from '../../../entities/MyCorrectPatient';

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';

// Styling
import '../../../scss/_PatientEdit.scss';
import '../../../scss/_global.scss';


interface IGIProps {
    submission: any;
    aiReport: AIReport;
    patient: MyoCorrectPatient;
}

export const AIReportHeaderDetails: FC<IGIProps> = observer(({ submission, aiReport, patient }) => {
    const { switchCurrentVisit } = useMainStoreContext().aiReportEntryStore;
    const { aiStatus, handleStatusChange, selectedVisitRecordId, setSelectedVisitRecordId } = useMainStoreContext().aiReportsStore;

    useEffect(() => {
        if (submission) {
            setSelectedVisitRecordId(submission?.visitRecordId || submission?.compareVisitRecordOneId || 0);
        }
    }, []);

    return submission ? (
        <Grid container height={'100%'}>
            <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'}>
                <Grid container>
                    <Grid item xs={12} paddingBottom={1}>
                        <Typography variant='h2' color={'white'} textAlign={'center'}>
                            AI Report Request Details
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="h5" color={'white'}>
                            Report Status:
                        </Typography>
                        <TextField
                            fullWidth
                            // sx={{ width: '200px' }}
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'aireportheader.statusId'}
                            select
                            defaultValue="0"
                            value={submission.statusId ? submission.statusId : 0}
                            onChange={e => {
                                handleStatusChange(submission.id, e.target.value);
                            }}
                        >
                            {aiStatus.map((item: any) => (
                                <MenuItem key={item.id + item.name} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} container justifyContent={'center'} paddingTop={3}>
                        <ButtonGroup variant="outlined" aria-label="outlined primary button group" style={{ backgroundColor: 'white' }}>
                            <Button
                                onClick={() => {
                                    setSelectedVisitRecordId(submission?.visitRecordId);
                                    if (!_.isNull(submission.compareVisitRecordTwoId)) {
                                        switchCurrentVisit(true);
                                    }
                                }
                                }
                                variant={selectedVisitRecordId === submission?.visitRecordId ? 'contained' : 'outlined'}
                            >
                                Visit Record {submission?.visitRecordId}
                            </Button>
                            {!_.isNull(submission?.compareVisitRecordTwoId) && <Button
                                onClick={() => {
                                    setSelectedVisitRecordId(submission?.compareVisitRecordTwoId);
                                    switchCurrentVisit(false);
                                }}
                                variant={selectedVisitRecordId === submission?.compareVisitRecordTwoId ? 'contained' : 'outlined'}
                            >
                                Visit Record {submission?.compareVisitRecordTwoId}
                            </Button>}
                        </ButtonGroup>
                    </Grid>

                </Grid>
            </Box>
        </Grid>
    ) : (
        <></>
    );
});
