import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { Grid, Divider, Typography, Box, CircularProgress } from '@mui/material';
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import UploadImgDragDrop from "../UploadDragAndDrop/UploadImgDragDrop";
import Upload3DImgDragDrop from "../UploadDragAndDrop/Upload3DImgDragDrop";
import { GlobalUtils } from '../../api/GlobalUtils';

const style = {
    top: 0,
    left: 0,
    width: '500px',
    height: '500px',
};
interface IProps {
    isIntake: boolean;
    isVisit: boolean;
}
const ExtraoralImages: React.FC<IProps> = ({ isIntake, isVisit }) => {
    const { patient, patientExtraoralImages, patientExtraoral3DImages } = useMainStoreContext().createPatientStore;
    const { getVisitId, visitExtraoral2dimages, visitExtraoral3dimages, imagesToDelete, isLoading } = useMainStoreContext().visitRecordStore;
    const { extraoral2dimages, extraoral3dimages, vivosId } = useMainStoreContext().aiReportOrder;
    const [extraoral2DImagesTypes, setExtraoral2DImagesTypes] = React.useState<any>([]);
    const [extraoral3DImagesTypes, setExtraoral3DImagesTypes] = React.useState<any>();
    const [hasLoaded, setHasLoaded] = React.useState(false);

    const deleteCallback = (itemOption: number) => {
        imagesToDelete.push(itemOption);
        const indexToRemove = visitExtraoral2dimages.findIndex(x => x.imageType.id === itemOption);
        if (indexToRemove !== -1) {
            visitExtraoral2dimages.splice(indexToRemove, 1);
        }
    }

    useEffect(() => {
        let list = GlobalUtils.getExtraOralImages();
        setExtraoral2DImagesTypes(list.filter(x => { return x.id !== 187 }));
        setExtraoral3DImagesTypes(list.find(x => { return x.id === 187 }));
        setHasLoaded(true);
    }, [setExtraoral2DImagesTypes, setExtraoral3DImagesTypes]);

    return (
        <Grid className="formBasics">
            {isLoading &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>
            }
            {!isLoading && <Grid paddingBottom={10}>
                <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                    Extraoral Images
                </Typography>
                {hasLoaded && <Box paddingTop={2} sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                        <Grid item xs direction="column">
                            <Grid container direction="column" flex-wrap={4} alignContent="flex-start">
                                <Grid container xs={12} direction="column"> 
                                    <Typography variant="body1" paddingBottom={2}>
                                    Locate images on your device, then click to select or drag your files into the box below. After uploading, please label each image accordingly.
                                    </Typography>
                                    <UploadImgDragDrop extImages={isIntake ? patientExtraoralImages : (isVisit) ? visitExtraoral2dimages : extraoral2dimages}
                                        type="extraoral" parentCallback={() => { }}
                                        typeOptions={extraoral2DImagesTypes}
                                        vivosId={isIntake ? patient.vivosId : vivosId}
                                        visitId={getVisitId()}
                                        deleteCallback={deleteCallback} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column">
                        <Divider />
                        <Typography variant="h6" paddingLeft={3} paddingTop={1}>3D Face Scan .stl file</Typography>
                        <Upload3DImgDragDrop extImages={isIntake ? patientExtraoral3DImages : (isVisit) ? visitExtraoral3dimages : extraoral3dimages}
                            allowMultiple={false}
                            imageOption={extraoral3DImagesTypes}
                            parentCallback={() => { }}
                            vivosId={isIntake ? patient.vivosId : vivosId}
                            visitId={getVisitId()}
                            deleteCallback={deleteCallback}
                        />
                    </Grid>
                </Box>}
            </Grid>}
        </Grid>
    );
};

export default observer(ExtraoralImages);
