// Vendors
import React from "react";
import { Box, Button, Grid, Step, StepLabel, Stepper, Typography, styled } from "@mui/material";

// Components
import { ConfirmRxStep } from "./SubmitReworkModals/ConfirmRxStep";
import { RecordsStep } from "./SubmitReworkModals/RecordsStep";
import { ReviewSubmitStep } from "./SubmitReworkModals/ReviewSubmitStep";
import { ReworkInfoStep } from "./SubmitReworkModals/ReworkInfoStep";

// Entities
import ProviderPatient from "../../../entities/ProviderPatient";
import { ClaimStatus, ProductionStatus, ClaimType, ErfStatus, CcrStatus } from "../../../entities/Enums";

// Stores
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react-lite";
import { Prescription } from "../../../models/state_models/prescription";

interface IGIProps {
    patient: ProviderPatient;
    claim: any;
    onClose(): any;
    isEdit: boolean;
    prescription: Prescription;
}

const BoxBorderBottom = styled(Box)(
    () => `
          border-bottom: transparent 5px solid;
    `
);

export const SubmitReworkClaimWizard: React.FC<IGIProps> = observer(({ patient, claim, onClose, isEdit, prescription }) => {

    const steps = ['Confirm RX', 'Rework Info', 'Records', 'Review & Submit'];
    const store = useMainStoreContext().reworkClaimStore;
    const [activeStep, setActiveStep] = React.useState(0);

    const CONFIRMRX = 0;
    const REWORKINFO = 1;
    const RECORDS = 2;
    const REVIEWANDSUBMIT = 3;

    const handleNext = () => {
        const updatedClaim = claim;
        if (activeStep === REWORKINFO) {
            const initialValue = 0;
            const sumWithInitial = store.reworkSelection.reduce(
                (accumulator, element) => accumulator + parseInt(element.value),
                initialValue
            );
            updatedClaim.requestIds = sumWithInitial;
            updatedClaim.providerNotes = store.providerNotes;
        }
        else if (activeStep === RECORDS) {

        }
        else if (activeStep === REVIEWANDSUBMIT) {

            if (isEdit) {
                store.updateClaimChanges(updatedClaim);
            }
            else {
                let newClaim = initializeClaim(updatedClaim);
                store.createNewClaim(newClaim);
            }
            store.setActivePatientApplianceTab(1);

            onClose();
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const initializeClaim = (updatedClaim) => {
        let applianceCount = store.claimData.filter(x => x.patientId == patient.id).length + 1;
        let rxCount = applianceCount < 10 ? 0 + applianceCount.toString() : applianceCount.toString();
        let claim = new Object({
            rxId: `${updatedClaim.rxId}-C${rxCount}`,
            providerNotes: updatedClaim.providerNotes,
            requestIds: updatedClaim.requestIds,
            applianceId: updatedClaim.appliance.id,
            prescriptionId: updatedClaim.id,
            labId: updatedClaim.labId,
            patientId: patient.id,
            providerId: updatedClaim.providerId,
            submissionDate: new Date,
            reviewedOn: new Date,
            hasAdverseEvents: false,
            hasPatientComplaint: false,
            labRemakeNeeded: false,
            labReworkApproved: false,
            isActive: true,
            claimStatus: ClaimStatus["New Submission"],
            productionStatus: ProductionStatus.Pending,
            claimType: ClaimType.Pending,
            erfStatus: ErfStatus["Pending ERF"],
            ccrStatus: CcrStatus["CCR Status 1"]
        })

        return claim;
    }

    return (
        // <Grid className="formBasics">
        <Grid className="stepper-wizard">
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {steps.map((label) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>

                {activeStep === CONFIRMRX ? (<Box>
                    <ConfirmRxStep claim={claim} patient={patient} isEdit={isEdit} prescription={prescription} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === REWORKINFO ? (<Box>
                    <ReworkInfoStep claim={claim} isEdit={isEdit} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === RECORDS ? (<Box>
                    <RecordsStep claim={claim} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === REVIEWANDSUBMIT ? (<Box>
                    <ReviewSubmitStep patient={patient} claim={claim} isEdit={isEdit} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                <BoxBorderBottom
                    padding={1}
                    sx={(theme) => ({
                        borderBottomColor: theme.palette.primary.main,
                        width: '100%',
                        backgroundColor: theme.colors.alpha.black[5],
                        position: 'absolute',
                        bottom: '0',
                    })}>
                    <Grid container justifyContent={'right'} alignContent={'center'}>
                        {activeStep !== 0 && <Button
                            variant="contained"
                            color='inherit'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{
                                mt: { xs: 2, sm: 0, margin: 5 }
                            }}
                        >
                            Back
                        </Button>}
                        <Button sx={{
                            mt: { xs: 2, sm: 0, margin: 5 }
                        }} variant="contained" color="primary" onClick={() => { handleNext() }} >
                            {activeStep === steps.length - 1 ? 'Submit Rework Claim' : 'Save & Continue'}
                        </Button>
                    </Grid>
                </BoxBorderBottom>

            </React.Fragment>
        </Grid>
        // </Grid>
    )
}
);
