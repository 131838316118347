// Vendors
import { GridFilterModel } from '@mui/x-data-grid/models';

// Components

// Stores
import { MainStore } from "./MainStore";

// Entities
import { ClaimStatus, ClaimType, ReworkClaimFilters } from "../../entities/Enums";
import Claim from "../../entities/Claim";
import DictionaryListItem from "../../entities/DictionaryListItem";
import ShipmentLabel from "../../entities/ShipmentLabel";
import Patient from "../../entities/Patient";

// API
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import buildQuery from 'odata-query';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';
import _ from "lodash";
import moment from "moment";
import { GlobalUtils } from '../../api/GlobalUtils';
import { AxiosRequestConfig } from "axios";
import { saveAs } from 'file-saver';
import { GridSortModel } from '@mui/x-data-grid-pro';


export default class ReworkClaimStore {

    @observable mainStore: MainStore;
    @observable toggleShowFilterResults: boolean = false;
    @observable isLeftNavExpanded: boolean = false;
    @observable showSubmitReworkWizard: boolean = false;

    @observable showRxNewTab: boolean = false;
    @observable showActionRequired: boolean = false;
    @observable showNewRx: boolean = false;

    @observable pageTitle = "Main Dashboard";
    @observable claim: any = {};
    @observable vwClaim: any = {};
    @observable shipmentLabel: any = {};
    @observable newRecordSubmitted: boolean = false;
    @observable claimId: number = 0;
    @observable isLoadedShipmentLabel = false;
    @observable isLoadingClaims = false;
    @observable isLoadingAllClaimDocuments = false;
    @observable didInitClaims = false;
    @observable filter: any = {};
    @observable sort: any;
    @observable claimData: any[] = [];
    @observable origClaimData: any[] = [];
    @observable loadingClaimsProgress = 0;
    @observable loadingAllClaimDocumentsProgress = 0;
    @observable searchParam = '';
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable currentRow: any = {};
    @observable numNewSubmissions: number = 0;
    @observable numInProgressWarranty: number = 0;
    @observable numInProgressRepair: number = 0;
    @observable numPendingApproval: number = 0;
    @observable numCompleted: number = 0;
    @observable numApproved: number = 0;
    @observable numAllClaims: number = 0;
    @observable teamMembers: any[] = [];
    @observable processedImages: any[] = [];
    @observable processedRecordImages: any[] = [];
    @observable userID: number = 0;
    @observable claimStatusValues: DictionaryListItem[] = [];
    @observable productionStatusValues: DictionaryListItem[] = [];
    @observable reworkRequestValues: any[] = [];
    @observable allReworkRequestValues: any[] = [];
    @observable reworkSelection: any[] = [];
    @observable providerNotes = '';
    @observable filesToUpload = false;
    @observable requestIds = '';
    @observable activePatientApplianceTab = 0;

    @observable patient: any = {};
    @observable patientId = 0;

    @observable userFullName = "";
    @observable claimReworkDocumentTypes: DictionaryListItem[] = [];
    @observable claimFiles: any[] = [];
    @observable managerClaimFiles: any[] = [];
    @observable claimStatus: number = 0;

    @observable labClaimMaterialModelStatusValues: DictionaryListItem[] = [];
    @observable labClaimMaterialApplianceStatusValues: DictionaryListItem[] = [];
    @observable labClaimMaterialBiteStatusValues: DictionaryListItem[] = [];
    @observable repairOptionsValues: DictionaryListItem[] = [];
    @observable repairOptions: DictionaryListItem[] = [];
    @observable labReworkMaterialBiteStatusValues: DictionaryListItem[] = [];
    @observable labReworkMaterialModelStatusValues: DictionaryListItem[] = [];
    @observable deniedReasonValues: DictionaryListItem[] = [];
    @observable erfStatusValues: DictionaryListItem[] = [];
    @observable financialLiabilityValues: DictionaryListItem[] = [];
    @observable claimClassificationSubCategoriesValues: DictionaryListItem[] = [];
    @observable claimAddCategorizatioRxNotFollowedValues: DictionaryListItem[] = [];
    @observable claimAddCategorizationNonFunctionalAssemblyValues: DictionaryListItem[] = [];
    @observable complaintMethodValues: DictionaryListItem[] = [];
    @observable complaintTypeValues: DictionaryListItem[] = [];
    @observable trackingNumber = '';

    private _currentPage: number = 0;
    private _isAISAdminManager = false;
    private _isAISSupervisor = false;
    private _isAISAdmin = false;
    private _isComplianceManager = false;
    private _isExternalLab = false;
    private _totalPages: number = 1;
    private _currentFilter: ReworkClaimFilters = ReworkClaimFilters.AllClaims;
    private _currentSubNavItem: number = 0;
    pageSize: number = 100;
    roles: any = [];

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;

        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; userFullName } = jwt_decode(token);
            this.userID = tokenData.id;
            this.userFullName = tokenData.userFullName;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.checkRoles();
    }

    get currentPage(): number {
        return this._currentPage;
    }

    set currentPage(value: any) {
        this._currentPage = value;
    }

    get isAISAdminManager(): any {
        return this._isAISAdminManager;
    }

    set isAISAdminManager(value: any) {
        this._isAISAdminManager = value;
    }

    get isAISSupervisor(): any {
        return this._isAISSupervisor;
    }

    set isAISSupervisor(value: any) {
        this._isAISSupervisor = value;
    }

    get isAISAdmin(): any {
        return this._isAISAdmin;
    }

    set isAISAdmin(value: any) {
        this._isAISAdmin = value;
    }

    get isComplianceManager(): any {
        return this._isComplianceManager;
    }

    set isComplianceManager(value: any) {
        this._isComplianceManager = value;
    }

    get isExternalLab(): any {
        return this._isExternalLab;
    }

    set isExternalLab(value: any) {
        this._isExternalLab = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: any) {
        this._totalPages = value;
    }

    get currentFilter(): ReworkClaimFilters {
        return this._currentFilter;
    }

    set currentFilter(value: any) {
        this._currentFilter = value;
    }

    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }



    @action setClaimId = (value: number) => this.claimId = value;
    @action setClaim = (value: any) => this.claim = value;
    @action setVwClaim = (value: any) => this.vwClaim = value;
    @action setShipmentLabel = (value: any) => this.shipmentLabel = value;
    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    }
    @action setProviderNotes = (value: string) => { this.providerNotes = value; };
    @action setRequestId = (value: string) => { this.requestIds = value; };
    @action setReworkSelection = (value: any[]) => { this.reworkSelection = value; }
    @action toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    }

    @action setTeamMembersData = teamMembers => (this.teamMembers = teamMembers);
    @action setClaimData = newClaimData => (this.claimData = newClaimData);
    @action setOrigClaimData = newClaimData => (this.origClaimData = newClaimData);
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);
    @action setClaimStatusValues = (value: DictionaryListItem[]) => { this.claimStatusValues = value; }
    @action setProductionStatusValues = (value: DictionaryListItem[]) => { this.productionStatusValues = value; }
    @action setReworkRequestValues = (value: any) => { this.reworkRequestValues = value; }
    @action setAllReworkRequestValues = (value: any[]) => { this.allReworkRequestValues = value; }
    @action setProcessedImages = (value: any[]) => { this.processedImages = value; }
    @action setProcessedRecordImages = (value: any[]) => { this.processedRecordImages = value; }
    @action setFilesToUpload = (value: boolean) => { this.filesToUpload = value; }
    @action setLoadedShipmentLabel = (value: boolean) => { this.isLoadedShipmentLabel = value; }
    @action setClaimReworkDocumentTypes = (value: DictionaryListItem[]) => { this.claimReworkDocumentTypes = value; }
    @action setClaimFiles = (files: any[]) => { this.claimFiles = files; };
    @action setManagerClaimFiles = (files: any[]) => { this.managerClaimFiles = files; };
    @action setIsLoadindAllClaimDocuments = (value: any) => { this.isLoadingAllClaimDocuments = value; };
    @action setShowSubmitReworkWizard = (value: boolean) => { this.showSubmitReworkWizard = value; }
    @action setShowRxNewTab = (value: boolean) => { this.showRxNewTab = value; }
    @action setNewRecordSubmitted = (value: boolean) => { this.newRecordSubmitted = value; }
    @action setClaimStatus = (value: number) => { this.claimStatus = value; }
    @action setActivePatientApplianceTab = (value: number) => { this.activePatientApplianceTab = value; }
    @action setShowActionRequired = (value: boolean) => { this.showActionRequired = value; }
    @action setShowNewRx = (value: boolean) => { this.showNewRx = value; }
    @action setLabClaimMaterialApplianceStatusValues = (value: DictionaryListItem[]) => { this.labClaimMaterialApplianceStatusValues = value }
    @action setLabClaimMaterialModelStatusValues = (value: DictionaryListItem[]) => { this.labClaimMaterialModelStatusValues = value }
    @action setLabClaimMaterialBiteStatusValues = (value: DictionaryListItem[]) => { this.labClaimMaterialBiteStatusValues = value }
    @action setRepairOptionsValues = (value: DictionaryListItem[]) => { this.repairOptionsValues = value }
    @action setLabReworkMaterialBiteStatusValues = (value: DictionaryListItem[]) => { this.labReworkMaterialBiteStatusValues = value }
    @action setLabReworkMaterialModelStatusValues = (value: DictionaryListItem[]) => { this.labReworkMaterialModelStatusValues = value }
    @action setDeniedReasonValues = (value: DictionaryListItem[]) => { this.deniedReasonValues = value }
    @action setRepairOptions = (value: DictionaryListItem[]) => { this.repairOptions = value }
    @action setErfStatusValues = (value: DictionaryListItem[]) => { this.erfStatusValues = value }
    @action setFinancialLiabilityValues = (value: DictionaryListItem[]) => { this.financialLiabilityValues = value }
    @action setClaimClassificationSubCategoriesValues = (value: DictionaryListItem[]) => { this.claimClassificationSubCategoriesValues = value }
    @action setClaimAddCategorizatioRxNotFollowedValues = (value: DictionaryListItem[]) => { this.claimAddCategorizatioRxNotFollowedValues = value }
    @action setClaimAddCategorizationNonFunctionalAssemblyValues = (value: DictionaryListItem[]) => { this.claimAddCategorizationNonFunctionalAssemblyValues = value }
    @action setComplaintMethodValues = (value: DictionaryListItem[]) => { this.complaintMethodValues = value }
    @action setComplaintTypeValues = (value: DictionaryListItem[]) => { this.complaintTypeValues = value }

    @action setPatient = (value: any) => {
        this.patient = value;
    };
    @action setPatientId = (value: number) => {
        this.patientId = value;
    };

    @action setTrackingNumber = (value: string) => {
        this.trackingNumber = value;
    };

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) {
                this.isAISAdminManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR) {
                this.isAISSupervisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN) {
                this.isAISAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_COMPLIANCE_MANAGER) {
                this.isComplianceManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_EXTERNAL_LAB) {
                this.isExternalLab = true;
            }
        }
        return;
    };

    loadPage = async () => {
        runInAction(() => {
            this.isLoadingClaims = true;
        });
        let skip = this.currentPage * this.pageSize;

        const query = buildQuery({ top: this.pageSize, skip: skip, filter: this.filter, count: true, orderBy: ['daysInQueue desc'] });
        var url = `odata/reworkClaims/${this.userID}` + query;
        const resp = await Gateway.get(url);
        let data = resp['value'];
        const totalClaims = resp['@odata.count'] ? resp['@odata.count'] : resp['value']?.length;
        this.totalPages = totalClaims;

        runInAction(() => {
            if (this.didInitClaims) {
                this.didInitClaims = false;
            }
            this.isLoadingClaims = false;

            this.claimData = data?.map(data => {
                const newData = { ...data };
                newData.name = data.firstName + ' ' + data.lastName;
                return newData;
            });
            this.origClaimData = this.claimData;
        });
    };

    doSaveWork = (fileContent: any, reworkClaimId: number, reworkDocumentType: number, isManagerView: boolean) => {
        Gateway.post(`/reworkclaim/${this.userID}/${reworkClaimId}/documents/${isManagerView}/${reworkDocumentType}`, fileContent).then(() => { });
    };

    optionChecked = (options: number, option: number): boolean => {
        return (options & option) === option;
    }

    @action loadReworkRequestValues = async (claimData) => {
        this.setReworkRequestValues([]);
        this.setReworkSelection([]);
        GlobalUtils.getReworkRequests().then(resp => {
            _.forEach(resp, item => {
                if (this.optionChecked(claimData.requestIds, parseInt(item.value))) {
                    this.reworkRequestValues.push(item);
                    this.reworkSelection.push(item);
                }
            })
        })
    };

    @action
    uploadProcessedImages = (reworkClaimId: number, images: any[]) => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forEach(images, async img => {
                promises.push(this.doSaveWork(img, reworkClaimId, img.imageType.id, false));
            });
            Promise.all(promises).then(() => {
                resolve(true);
            });
        });
    };

    @action
    uploadManagementFiles = (reworkClaimId: number) => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forEach(this.managerClaimFiles.filter(x => x.data64String), async file => {
                var fileUpload = {
                    fileName: file.pathUrl,
                    dataBody: file.data64String.split(',')[1],
                }
                promises.push(this.doSaveWork(fileUpload, reworkClaimId, file.imageType.id, true));
            });
            Promise.all(promises).then(() => {
                resolve(true);
            });
        });
    };

    @action
    removeManagerFiles = (reworkClaimId: number) => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forEach(this.processedImages.filter(x => x.isManagerView), async storedFile => {
                const found = this.managerClaimFiles.some(r => storedFile.id === r.id);
                if (!found) {
                    promises.push(this.removeFile(storedFile, reworkClaimId));
                }
            })

            Promise.all(promises).then(() => {
                resolve(true);
            });
        });


    };

    @action
    removeFile = async (file, reworkClaimId) => {
        if (_.isUndefined(file.id)) {
            this.processedImages.splice(this.processedImages.findIndex(x => x.fileName === file.fileName), 1);
        } else {
            Gateway.delete(`/reworkclaim/${this.userID}/${reworkClaimId}`, file).then(() => {
                this.processedImages.splice(this.processedImages.findIndex(x => x.id === file.id), 1);
            });
        }
    };

    loadAISUsers = (): any => {
        Gateway.get(`lab/${this.userID}/aisusers`).then(resp => {
            this.teamMembers = [];
            _.forEach(resp, user => {
                this.teamMembers.push(user);
            });

            this.teamMembers.sort((a, b) => a.firstName.localeCompare(b.firstName));
        });

        return;
    };

    loadReworkDocuments = (reworkClaimId): any => {
        this.processedImages.length = 0;
        this.claimFiles.length = 0;
        this.managerClaimFiles.length = 0;
        Gateway.get(`reworkClaim/${reworkClaimId}/documents`).then(resp => {
            _.forEach(resp, doc => {
                this.processedImages.push(doc);
                this.claimFiles.push({
                    pathUrl: doc.url,
                    data64Url: doc.url,
                    imageType: {
                        id: doc.claimDocumentType
                    },
                    id: doc.id
                })
                if (doc.isManagerView) {
                    this.managerClaimFiles.push({
                        pathUrl: doc.url.split('/').pop().split('?')[0],
                        data64Url: doc.url,
                        imageType: {
                            id: doc.claimDocumentType
                        },
                        id: doc.id
                    })
                }
            });
        });

        return;
    };

    handleAssignToChange = (changedClaim, event) => {
        var url = '/reworkClaim/' + changedClaim.id + '/assigned/' + event.target.value;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.claimData, c => {
                    return c.id === respData['id'];
                });
                var newClaimData = this.claimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.assignedToId = respData['assignedTo'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);

                this.setClaimData(newClaimData);

                ind = _.findIndex(this.origClaimData, c => {
                    return c.id === respData['id'];
                });
                newClaimData = this.origClaimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.assignedToId = respData['assignedTo'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);
                this.setOrigClaimData(newClaimData);
            }
        });
    };

    handleClaimStatusChange = (changedClaim, event) => {
        var url = '/reworkClaim/' + changedClaim.id + '/claimStatus/' + event;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.claimData, c => {
                    return c.id === respData['id'];
                });
                var newClaimData = this.claimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.claimStatus = respData['claimStatus'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);

                this.setClaimData(newClaimData);

                ind = _.findIndex(this.origClaimData, c => {
                    return c.id === respData['id'];
                });
                newClaimData = this.origClaimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.claimStatus = respData['claimStatus'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);
                this.setOrigClaimData(newClaimData);
                this.setClaim(respData);
            }
        });
    };

    handleErfStatusChange = (changedClaim, event) => {
        var url = '/reworkClaim/' + changedClaim.id + '/erfStatus/' + event;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.claimData, c => {
                    return c.id === respData['id'];
                });
                var newClaimData = this.claimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.erfStatus = respData['erfStatus'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);

                this.setClaimData(newClaimData);

                ind = _.findIndex(this.origClaimData, c => {
                    return c.id === respData['id'];
                });
                newClaimData = this.origClaimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.erfStatus = respData['erfStatus'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);
                this.setOrigClaimData(newClaimData);
                this.setClaim(respData);
            }
        });
    };

    handleProductionStatusChange = (changedClaim, event) => {
        var url = '/reworkClaim/' + changedClaim.id + '/productionStatus/' + event;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.claimData, c => {
                    return c.id === respData['id'];
                });
                var newClaimData = this.claimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.productionStatus = respData['productionStatus'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);

                this.setClaimData(newClaimData);

                ind = _.findIndex(this.origClaimData, c => {
                    return c.id === respData['id'];
                });
                newClaimData = this.origClaimData.reduce((newData, currentClaim, index) => {
                    const copiedClaim: any = { ...currentClaim };
                    if (ind === index) {
                        copiedClaim.productionStatus = respData['productionStatus'];
                    }
                    return [...newData, copiedClaim];
                }, [] as any[]);
                this.setOrigClaimData(newClaimData);
                this.setClaim(respData);
            }
        });
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 1500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 1500);
        }
        return this.claimData;
    };

    @action getFilterNums = () => {
        var url = 'reworkClaim/filterCounters/' + this.userID;
        Gateway.getOne(url).then(resp => {
            runInAction(() => {
                this.numNewSubmissions = resp.newSubmission;
                this.numInProgressWarranty = resp.inProgressWarranty;
                this.numInProgressRepair = resp.inProgressRepair;
                this.numPendingApproval = resp.pendingApproval;
                this.numCompleted = resp.completed;
                this.numApproved = resp.approved;
                this.numAllClaims = resp.allClaims;
            });
        });
    };

    @action filterBySearchString = () => {
        let searchFilter = { or: [{ vivosId: { contains: this.searchParam.toLowerCase() } }, { name: { contains: this.searchParam.toLowerCase() } }] };
        runInAction(() => {
            if (Object.keys(this.filter).length > 0) {
                this.filter = {
                    and: [this.filter, searchFilter],
                };
            } else {
                this.filter = searchFilter;
            }
            this.didInitClaims = true;
        });
    };

    @action loadClaim = async () => {
        var url = `/reworkClaim/${this.claimId}`;
        Gateway.get(url).then(async resp => {
            this.setClaim(resp as unknown as Claim);
            Gateway.get(`/patient/${resp['patientId']}`).then(patient => {
                this.setPatient(patient as unknown as Patient);
            });
        });
    };

    @action
    updateClaimChanges = async (claim) => {
        var url = `/reworkClaim/${claim.id}/${this.userID}`;
        return Gateway.put(url, claim)
            .then(async (respData) => {
                if (respData) {
                    this.loadClaim();
                    let skip = this.currentPage * this.pageSize;
                    const query = buildQuery({ top: this.pageSize, skip: skip, filter: this.filter, count: this.didInitClaims, orderBy: ['daysInQueue desc'] });
                    var url = `odata/reworkClaims/${this.userID}` + query;
                    const resp = await Gateway.get(url);
                    return resp;
                }
            });

    };

    @action
    getClaimsByPatientId = async (patientId) => {
        this.claimData.length = 0;
        runInAction(() => {
            this.isLoadingClaims = true;
        });
        var url = `/reworkClaim/patient/${patientId}`;
        const resp = await Gateway.get(url);

        runInAction(() => {
            if (this.didInitClaims) {
                this.didInitClaims = false;
            }
            this.isLoadingClaims = false;
            this.claimData = resp.map(data => {
                return data;
            });
        });
    };

    @action
    createNewClaim = (claim) => {
        var url = `/reworkClaim/${this.userID}`;
        Gateway.post(url, claim)
            .then((respData) => {
                if (respData) {
                    this.setClaim(respData as unknown as Claim);
                    if (this.filesToUpload) {
                        this.uploadProcessedImages(respData['id'], this.claimFiles);
                    }
                }
                this.getClaimsByPatientId(claim.patientId);
            })
    };

    @action
    getShipmentLabel = () => {
        var url = `shipping/shipmentLabel/${this.userID}`;
        Gateway.get(url)
            .then((respData) => {
                this.isLoadedShipmentLabel = true;
                this.setShipmentLabel(respData as unknown as ShipmentLabel)
            });
    };

    @action loadClaimStatusValues = async () => {
        GlobalUtils.getClaimStatus().then(resp => {
            this.setClaimStatusValues(resp.filter(x => x.isActive).sort((a, b) =>
                a.name > b.name ? 1 : -1));
        })
    };

    @action loadProductionStatusValues = async () => {
        GlobalUtils.getProductionStatus().then(resp => {
            this.setProductionStatusValues(resp);
        })
    };

    @action loadClaimReviewDocumentTypes = async () => {
        GlobalUtils.getClaimReviewDocumentTypes().then(resp => {
            this.setClaimReworkDocumentTypes(resp);
        })
    };

    @action loadAllReworkRequestValues = async () => {
        GlobalUtils.getReworkRequests().then(resp => {
            this.setAllReworkRequestValues(resp);
        })
    };

    @action loadLabClaimMaterialModelStatusValues = async () => {
        GlobalUtils.getLabClaimMaterialModelStatus().then(resp => {
            this.setLabClaimMaterialModelStatusValues(resp.sort((a, b) =>
                a.name > b.name ? 1 : -1));
        })
    };

    @action loadLabClaimMaterialApplianceStatusValues = async () => {
        GlobalUtils.getLabClaimMaterialApplianceStatus().then(resp => {
            this.setLabClaimMaterialApplianceStatusValues(resp.sort((a, b) =>
                a.name > b.name ? 1 : -1));
        })
    };

    @action loadLabClaimMaterialBiteStatusValues = async () => {
        GlobalUtils.getLabClaimMaterialBiteStatus().then(resp => {
            this.setLabClaimMaterialBiteStatusValues(resp.sort((a, b) =>
                a.name > b.name ? 1 : -1));
        })
    };

    @action loadRepairOptions = async (claimData) => {
        this.setRepairOptions([]);
        GlobalUtils.getRepairOptions().then(resp => {
            this.setRepairOptionsValues(resp);
            _.forEach(resp, item => {
                if (this.optionChecked(claimData.labReworkRepairOptions, parseInt(item.value))) {
                    this.repairOptions.push(item);
                }
            })
        })
    };

    @action loadLabReworkMaterialBiteStatusValues = async () => {
        GlobalUtils.getLabReworkMaterialBiteStatuses().then(resp => {
            this.setLabReworkMaterialBiteStatusValues(resp);
        })
    };

    @action loadLabReworkMaterialModelStatusValues = async () => {
        GlobalUtils.getLabReworkMaterialModelStatuses().then(resp => {
            this.setLabReworkMaterialModelStatusValues(resp);
        })
    };

    @action loadDeniedReasonValues = async () => {
        GlobalUtils.getCancellationReasons().then(resp => {
            this.setDeniedReasonValues(resp);
        })
    };

    @action loadErfStatusValues = async () => {
        GlobalUtils.getErfStatusValues().then(resp => {
            this.setErfStatusValues(resp);
        })
    };

    @action loadFinancialLiabilityValues = async () => {
        GlobalUtils.getFinancialLiabilityValues().then(resp => {
            this.setFinancialLiabilityValues(resp);
        })
    };

    @action loadClaimClassificationSubCategoriesValues = async () => {
        GlobalUtils.getClaimClassificationSubCategoriesValues().then(resp => {
            this.setClaimClassificationSubCategoriesValues(resp);
        })
    };

    @action loadClaimAddCategorizatioRxNotFollowedValues = async () => {
        GlobalUtils.getClaimAdditionalCategorizationRxWasNotFollowedValues().then(resp => {
            this.setClaimAddCategorizatioRxNotFollowedValues(resp);
        })
    };

    @action loadClaimAddCategorizationNonFunctionalAssemblyValues = async () => {
        GlobalUtils.getClaimAdditionalCategorizationNonFunctionalAssemblyValues().then(resp => {
            this.setClaimAddCategorizationNonFunctionalAssemblyValues(resp);
        })
    };

    @action loadComplaintMethodValues = async () => {
        GlobalUtils.getComplaintMethodValues().then(resp => {
            this.setComplaintMethodValues(resp);
        })
    };

    @action loadComplaintTypeValues = async () => {
        GlobalUtils.getComplaintTypeValues().then(resp => {
            this.setComplaintTypeValues(resp);
        })
    };

    @action loadClaimDictionaryItemsValues = async () => {
        this.loadLabClaimMaterialModelStatusValues();
        this.loadLabClaimMaterialApplianceStatusValues();
        this.loadLabClaimMaterialBiteStatusValues();
        //this.loadRepairOptions(this.claim);
        this.loadLabReworkMaterialBiteStatusValues();
        this.loadLabReworkMaterialModelStatusValues();
        this.loadDeniedReasonValues();
        this.loadErfStatusValues();
        this.loadFinancialLiabilityValues();
        this.loadClaimClassificationSubCategoriesValues();
        this.loadClaimAddCategorizatioRxNotFollowedValues();
        this.loadClaimAddCategorizationNonFunctionalAssemblyValues();
        this.loadComplaintMethodValues();
        this.loadComplaintTypeValues();
    }

    downloadImages = async (claimData, directoryPath) => {
        this.setIsLoadindAllClaimDocuments(true);
        runInAction(() => {
            this.isLoadingAllClaimDocuments = true;
        });

        let options: AxiosRequestConfig = {
            url: `global/download/allFiles`,
            method: 'POST',
            responseType: 'blob'
        };
        var file = await Gateway.postWithOptions(`global/download/allFiles`, {
            "Id": claimData.id,
            "BlobDirectoryPath": directoryPath,
            "BlobContainer": process.env.REACT_APP_PATIENT_CONTAINER,
            "VivosId": claimData.vivosId,
            "PatientFirstName": claimData.patientFirstName.replace(/\s+/g, ''),
            "PatientLastName": claimData.patientLastName.replace(/\s+/g, '')
        }, options);
        this.setIsLoadindAllClaimDocuments(false);
        runInAction(() => {
            this.isLoadingAllClaimDocuments = false;
        });

        let filename = file.headers['content-disposition'].split(';').find(n => n.includes('filename='))?.replace('filename=', '').trim();
        let url = window.URL
            .createObjectURL(new Blob([file.data], { type: 'application/octet-stream' }));

        saveAs(url, filename);
    };

    clearData = () => {
        runInAction(() => {
            this.currentRow = {};
            this.claimData = this.origClaimData;
            this.filter = {};
        });
    };

    onFilter = (value, filter: ReworkClaimFilters) => {
        if (value) {
            this.setClaimData(this.filterReworkClaims(this.origClaimData, filter));
            this.currentFilter = filter;
        } else {
            this.currentFilter = ReworkClaimFilters.AllClaims;
            this.claimData = this.origClaimData;
            this.filter = {};
        }
    };

    onSortChange = (sortModel: GridSortModel): any => {
        runInAction(() => {
            if (Object.keys(sortModel).length > 0) {
                runInAction(() => {
                    this.sort = [sortModel[0].field + ' ' + sortModel[0].sort];
                });
            } else {
                this.sort = {};
            }
        });
    };

    onFilterChange = (filterModel: GridFilterModel): any => {
        runInAction(() => {
            let searchFilter = this.buildFilter(filterModel);
            if (Object.keys(searchFilter).length > 0) {
                runInAction(() => {
                    this.filter = searchFilter;
                    this.didInitClaims = true;
                });
            } else {
                this.filter = {};
            }
        });
    };

    onSubNavClick = (filter: ReworkClaimFilters, index: number) => {
        this.setClaimData(this.filterReworkClaims(this.origClaimData, filter));
        this.currentSubNavItem = index;
    };

    filterReworkClaims = (claimData: any, filter: ReworkClaimFilters) => {
        let data: [] = [];
        switch (filter) {
            case ReworkClaimFilters.NewSubmission:
                runInAction(() => {
                    this.filter = {
                        claimStatusId: { eq: ClaimStatus['New Submission'] }
                    };
                });
                break;
            case ReworkClaimFilters.InProgressWarranty:
                runInAction(() => {
                    this.filter = {
                        and: [
                            { claimTypeId: { eq: ClaimType.Warranty } },
                            {
                                or: [
                                    { claimStatusId: { eq: ClaimStatus['Waiting on Clase Materials'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending Lab Investigation'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending Lab ERF'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending ERF Approval'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending DOF + ESD'] } },
                                    { claimStatusId: { eq: ClaimStatus['Waiting on Case to Ship'] } }
                                ]
                            }
                        ]
                    };
                });
                break;
            case ReworkClaimFilters.InProgressRepair:
                runInAction(() => {
                    this.filter = {
                        and: [
                            { claimTypeId: { eq: ClaimType.Repair } },
                            {
                                or: [
                                    { claimStatusId: { eq: ClaimStatus['Waiting on Clase Materials'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending Lab Investigation'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending Lab ERF'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending ERF Approval'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending DOF + ESD'] } },
                                    { claimStatusId: { eq: ClaimStatus['Waiting on Case to Ship'] } },
                                    { claimStatusId: { eq: ClaimStatus['Pending ERF Review'] } }
                                ]
                            }
                        ]
                    };
                });
                break;
            case ReworkClaimFilters.PendingApproval:
                runInAction(() => {
                    this.filter = {
                        or: [
                            { claimStatusId: { eq: ClaimStatus['Pending Remake RX'] } },
                            { claimStatusId: { eq: ClaimStatus['Pending CCR Form'] } },
                            { claimStatusId: { eq: ClaimStatus['Pending Manager Review'] } }
                        ]
                    };
                });
                break;
            case ReworkClaimFilters.Completed:
                runInAction(() => {
                    this.filter = {
                        or: [
                            { claimStatusId: { eq: ClaimStatus.Completed } },
                            { claimStatusId: { eq: ClaimStatus.Cancelled } }
                        ]
                    };
                });
                break;
            case ReworkClaimFilters.AllClaims:
                runInAction(() => {
                    this.filter = {};
                });
                break;
            default:
                runInAction(() => {
                    this.filter = {};
                });
                break;
        }
        return data;
    };


    buildFilter = (filterModel: GridFilterModel): any => {
        let customFilter = {};
        let linkOperator = filterModel.linkOperator !== undefined ? filterModel.linkOperator : '';
        customFilter[linkOperator] = [];
        filterModel.items.forEach(filter => {
            let currentOdata = {};
            let operator = {};
            let operatorValue = filter.operatorValue ? filter.operatorValue.toLowerCase() : '';
            let shouldNotAdd = true;
            let funcProperty = filter.columnField;
            let typeColumn = 'string';
            let actualValue;

            if (filter.columnField.toLowerCase().includes('date')) {
                typeColumn = 'date';
            }

            if ((filter.columnField.includes('id') || filter.columnField.includes('days')) && !filter.columnField.includes('provider')) {
                typeColumn = 'number';
            }

            if (filter.columnField.includes('Id') && !filter.columnField.includes('provider')) {
                switch (filter.columnField) {
                    case 'assignedToId':
                        if (filter.value) {
                            actualValue = this.teamMembers.filter(
                                x =>
                                    x.firstName === filter.value.split(' ')[0] &&
                                    x.lastName === filter.value.split(' ')[1],
                            )[0].userID;
                            typeColumn = 'list';
                        }
                        break;
                    default:
                        break;
                }
            }
            if (typeColumn === 'date') {
                switch (filter.operatorValue) {
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'not':
                        operator['ne'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'after':
                        operator['gt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrAfter':
                        operator['ge'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'before':
                        operator['lt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrBefore':
                        operator['le'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'list') {
                switch (filter.operatorValue) {
                    case 'is':
                        operator['eq'] = actualValue;
                        break;
                    case 'not':
                        operator['ne'] = actualValue;
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'number' && filter.value) {

                switch (filter.operatorValue) {
                    case '=':
                        operator['eq'] = { type: 'raw', value: filter.value };
                        break;
                    case '!=':
                        operator['ne'] = { type: 'raw', value: filter.value };
                        break;
                    case '>':
                        operator['gt'] = { type: 'raw', value: filter.value };
                        break;
                    case '>=':
                        operator['ge'] = { type: 'raw', value: filter.value };
                        break;
                    case '<':
                        operator['lt'] = { type: 'raw', value: filter.value };
                        break;
                    case '<=':
                        operator['le'] = { type: 'raw', value: filter.value };
                        break;
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    default:
                        break;
                }
            } else {
                switch (filter.operatorValue) {
                    case 'contains':
                    case 'startsWith':
                    case 'endsWith':

                        operator[operatorValue] = filter.value;
                        break;
                    case 'equals':
                        operator['eq'] = filter.value;
                        break;
                    case 'isEmpty':
                        shouldNotAdd = false;
                        operator['lt'] = 1;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isNotEmpty':
                        shouldNotAdd = false;
                        operator['gt'] = 0;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = filter.value;
                        break;
                    case 'not':
                        operator['ne'] = filter.value;
                        break;
                    default:
                        break;
                }
            }

            if (shouldNotAdd) {
                currentOdata[funcProperty] = operator;
            }
            customFilter[linkOperator].push(currentOdata);
        });
        return customFilter;
    };
}