import React, { Fragment, useContext, useEffect } from 'react';
import {
    Grid,
    Button,
    Box
} from '@mui/material';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import AssignmentTurnedInTwoToneIcon from '@mui/icons-material/AssignmentTurnedInTwoTone';

// Components
import DataGrid from '../../components/DataGrid/DataGrid';
import { CreatePatientWizard } from '../../components/PatientIntake/CreatePatientWizard';
import { GetAuthToken } from '../../components/GetAuthToken';
import { ModalContainer } from '../../components/ModalContainer';
import { GridColDef, GridFilterItem, GridFilterInputValue } from '@mui/x-data-grid-pro';
import { PatientEdit } from '../../components/PatientProfile/PatientEdit';
import { QuickAddModal } from '../../components/PatientIntake/QuickAddModal';
import View from '../../components/ViewCustomFormResponse/View';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from './components/PageHeader';
import DashboardInfoCard from '../../components/UI/Cards/DashboardInfoCard';
import Label from '../../components/UI/Label';
import { RxStatusDetails } from '../../components/PatientProfile/PatientApplianceComponents/SubmitReworkModals/RxStatusDetails';
import { CreateRxWizard } from '../../modals/PrescriptionWizard/CreateRxWizard';

// Entities
import { TreatmentStatus, MyoCorrectStatus, PatientFilters, AddressType } from '../../entities/Enums';

// Store
import { MainStoreContext, useMainStoreContext } from '../../stores/OldStores/MainStore';
import ShopifyStore from '../../stores/OldStores/ShopifyStore';

// API
import { observer } from 'mobx-react';
import Moment from 'moment';
import _ from 'lodash';

// Styling
import '../../scss/_Patients.scss';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';

const getMyoStatusLabel = (myoStatus: MyoCorrectStatus): JSX.Element => {
    const map = {
        'Enrolled': {
            text: 'Enrolled',
            color: 'warning'
        },
        'In Progress': {
            text: 'In Progress',
            color: 'primary'
        },
        'Complete': {
            text: 'Complete',
            color: 'success'
        },
        Refunded: {
            text: 'Refunded',
            color: 'error'
        },
        Cancelled: {
            text: 'Cancelled',
            color: 'error'
        },
        'default': {
            text: 'Not Enrolled',
            color: 'secondary'
        }
    };
    const { text, color }: any = map[myoStatus] || map['default'];
    return (
        <Label color={color}>
            <b>{text}</b>
        </Label>
    );
};

const getPatientStatusLabel = (patientStatus: TreatmentStatus): JSX.Element => {
    const map = {
        'Pending Treatment': {
            text: 'Pending Treatment',
            color: 'warning'
        },
        'Prospect': {
            text: 'Prospect',
            color: 'info'
        },
        'In Treatment': {
            text: 'In Treatment',
            color: 'primary'
        },
        Completed: {
            text: 'Completed',
            color: 'success'
        },
        'default': {
            text: '',
            color: 'inherit'
        }
    };
    const { text, color }: any = map[patientStatus] || map['default'];
    return (
        <Label >
            <b>{text}</b>
        </Label>
    );
};

interface PatientsProps {
    userRoles: any;
}

let userRoles: any = [];
let provId = -1;

const Patients: React.FC<PatientsProps> = observer(() => {
    const { patientStore, customFormStore, prescriptionStore } = useContext(MainStoreContext);
    const { providerStore } = useMainStoreContext();
    const shopifyStore = new ShopifyStore();
    const [isEditPrescription, setIsEditPrescription] = React.useState<boolean>(false);
    const [showConfirmClosePrescriptionForm, setShowConfirmClosePrescriptionForm] = React.useState(false);
    const [isAISSpecialist, setIsAISSpecialist] = React.useState<boolean>(false);
    const [openModalMissingLocation, setOpenModalMissingLocation] = React.useState(false);

    useEffect(() => {
        patientStore.didInitPatients = true;
        patientStore.userRoles = userRoles
        setIsAISSpecialist(JSON.parse(userRoles).some(role => role.Name === 'AISSpecialist'));
        patientStore.loadPatients();

        /*  const urlParams = new URLSearchParams(window.location.search)
         if (urlParams.get('success') === 'true') {
             shopifyStore.client.checkout.fetch(sessionStorage.getItem('Cart')).then((checkout) => {
                 prescriptionStore.getPrescription(sessionStorage.getItem('PrescriptionId')).then(() => {
                     prescriptionStore.updatePrescription(checkout).then(() => {
                         sessionStorage.removeItem('Cart');
                         sessionStorage.removeItem('PrescriptionId');
                     });
                 })
             });
         } */
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            type: 'string',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.row.Id}
                                variant="text"
                                onClick={() => {
                                    patientStore.selectedVivosId = params.row.vivosId;
                                    patientStore.currentPatientId = params.row.id;
                                    patientStore.currentRow = params.row;
                                    patientStore.toggleEditPatientModal();
                                }}
                            >
                                {params.row.vivosId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'firstName',
            flex: 1,
            headerName: 'First Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid container justifyContent={'center'}>{params.row.firstName}</Grid>;
            },
        },
        {
            field: 'lastName',
            flex: 1,
            headerName: 'Last Name',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid container justifyContent={'center'}>{params.row.lastName}</Grid>;
            },
        },
        {
            field: 'createdOn',
            flex: 1,
            headerName: 'Added',
            type: 'datetime',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid container justifyContent={'center'}>{Moment(params.row.createdOn).format('MM/DD/yyyy')}</Grid>;
            },
        },
        {
            field: 'treatmentStatusId',
            flex: 1,
            headerName: 'Patient Status',
            type: 'singleselect',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            valueOptions: Object.keys(TreatmentStatus).filter((v) =>
                isNaN(Number(v)),
            ),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            return TreatmentStatus[value] !== undefined;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            renderCell: (params) => {
                const patientStatus = TreatmentStatus[params.row.treatmentStatusId];
                return (
                    <Grid container justifyContent={'center'}>
                        {getPatientStatusLabel(patientStatus as any)}
                    </Grid>
                )
            },
        },
        {
            field: 'myoCorrectStatusId',
            flex: 1,
            headerName: 'MyoCorrect',
            type: 'singleselect',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: (params) => {
                const myoStatus = MyoCorrectStatus[params.row.myoCorrectStatusId];
                return (
                    <Grid container justifyContent={'center'}>
                        {getMyoStatusLabel(myoStatus as any)}
                    </Grid>
                )
            },
            valueOptions: Object.keys(MyoCorrectStatus).filter((v) =>
                isNaN(Number(v)),
            ),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            return MyoCorrectStatus[value] !== undefined;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
        },
        /*
        {
            field: 'aiReportType',
            flex: 1,
            headerName: 'AI Report',
            type: 'boolean',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.aiReportType ? (
                            <>
                                <Link
                                    id={'patients.dashboard.cell.patientsAiReport' + params.row.Id}
                                    href={""}
                                >
                                    <LinkIcon id={'patients.dashboard.cell.patientsAiReport' + params.row.Id} />
                                </Link>
                            </>
                        ) : (
                            <></>
                        )}
                    </Grid>
                );
            },
        },
        */
        /*
        {
            field: 'practiceLocation',
            flex: 1,
            headerName: 'Location',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                return <Grid container justifyContent={'center'}>{params.row.practiceLocation}</Grid>;
            },
        },
        */
        {
            field: 'provider',
            flex: 1,
            headerName: 'Provider',
            sortable: true,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            hide: !isAISSpecialist,
            renderCell: params => {
                return <Grid container justifyContent={'center'}>{params.row.provider}</Grid>;
            },
        },
        {
            field: 'id',
            headerName: '',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                const handleNewPrescription = () => {
                    if (!isAISSpecialist) {
                        var hasAddressLocations = providerStore.providerData.addresses.filter(x => x.addressTypeId === AddressType['Shipping Address'] && !_.isEmpty(x.country));
                        if (hasAddressLocations.length === 0) {
                            setOpenModalMissingLocation(true);
                            return;
                        }
                    }
                    patientStore.setCurrentPatientId(params.row.id);
                    patientStore.currentRow = params.row;
                    patientStore.togglePrescriptionModal();
                };
                const handlEditPrescription = () => {
                    setIsEditPrescription(true);
                    prescriptionStore.getPrescription(params.row.rxId).then(resp => {
                        patientStore.currentRow = params.row;
                        prescriptionStore.setShowRxStatusDetails(true);
                    });

                }
                return (
                    <div>
                        {params.row.rxId === 0 ? (
                            (
                                <Button variant='outlined' onClick={handleNewPrescription} size='small'>
                                    NEW Rx
                                </Button>
                            )
                        ) : (
                            <Button variant='outlined' onClick={handlEditPrescription} size='small'>
                                EDIT Rx
                            </Button>
                        )}
                    </div>
                );
            },
        },
    ];

    const infoBoxes: any = [
        <DashboardInfoCard cardLabelText={"Pending Treatment"} cardNumberValue={patientStore.numPendingTreatment} icon={<PendingActionsTwoToneIcon />} filter={PatientFilters.PendingTreatment} currentFilter={patientStore.currentFilter} onFilter={(value) => { patientStore.onFilter(value, PatientFilters.PendingTreatment) }} />,
        <DashboardInfoCard cardLabelText={"Active"} cardNumberValue={patientStore.numActivePatients} icon={<PendingActionsTwoToneIcon />} filter={PatientFilters.ActiveTreatment} currentFilter={patientStore.currentFilter} onFilter={(value) => { patientStore.onFilter(value, PatientFilters.ActiveTreatment) }} />,
        <DashboardInfoCard cardLabelText={"Appliance Therapy"} cardNumberValue={patientStore.numAppliancePatients} icon={<PendingActionsTwoToneIcon />} filter={PatientFilters.ApplianceTreatment} currentFilter={patientStore.currentFilter} onFilter={(value) => { patientStore.onFilter(value, PatientFilters.ApplianceTreatment) }} />,
        <DashboardInfoCard cardLabelText={"Myofunctional Therapy"} cardNumberValue={patientStore.numMyoPatients} icon={<PendingActionsTwoToneIcon />} filter={PatientFilters.MyoPatients} currentFilter={patientStore.currentFilter} onFilter={(value) => { patientStore.onFilter(value, PatientFilters.MyoPatients) }} />,
        <DashboardInfoCard cardLabelText={"Treatment Completed"} cardNumberValue={patientStore.numCompletedPatients} icon={<AssignmentTurnedInTwoToneIcon />} filter={PatientFilters.CompletedPatients} currentFilter={patientStore.currentFilter} onFilter={(value) => { patientStore.onFilter(value, PatientFilters.CompletedPatients) }} />,
    ];

    const handleConfirmClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
        patientStore.toggleShowPrescriptionModal = false;
        patientStore.loadPatients();
    };

    const handleCancelClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
    };

    const handleCloseModal = () => {
        setShowConfirmClosePrescriptionForm(true);
    };

    return (
        <>
            <GetAuthToken>
                {({ roles, providerId }) => {
                    userRoles = roles;
                    provId = providerId;
                    return <div></div>;
                }}
            </GetAuthToken>
            <Fragment>
                <PageTitleWrapper>
                    <PageHeader
                        onNewPatientClick={patientStore.toggleAddPatientModal}
                        onChange={patientStore.getSearchData}
                        onClear={patientStore.loadPatients}
                        onCancel={patientStore.onFilterCancel}
                        onFilter={(value) => { patientStore.onFilter(value, patientStore.onFilter) }}
                    />
                </PageTitleWrapper>
                <Grid container>
                    <Grid item xs={12} container spacing={2} paddingX={2} paddingBottom={1}>
                        {infoBoxes.map((box, index) => (
                            <Grid item xs={12} sm={6} md={3} lg={12 / 5} key={index}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'  // This ensures the flex items (cards) are stacked vertically
                                }}>
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>  {/* This Box wraps the card and allows it to grow */}
                                    {box}
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} className='grid'>
                        <DataGrid
                            data={patientStore.patientData}
                            columns={columns}
                            loading={patientStore.isLoadingPatients}
                            onPageChange={(pageNumber) => {
                                patientStore.currentPage = pageNumber;
                                patientStore.loadPatients();
                            }}
                            onSortModelChange={(sortObj) => {
                                patientStore.onSortChange(sortObj);
                                patientStore.loadPatients();
                            }}
                            onFilterChange={(filterObj) => {
                                patientStore.onFilterChange(filterObj);
                                patientStore.loadPatients();
                            }}
                            pagination={true}
                            pageSize={patientStore.pageSize}
                            rowCount={patientStore.totalRows}
                            serverSidePagination={true}
                        />
                    </Grid>
                </Grid>
            </Fragment>
            <QuickAddModal isOpen={patientStore.toggleShowQuickddPatientsModal} onClose={patientStore.toggleQuickAddModal} />
            <ModalContainer
                show={patientStore.toggleShowAddPatientsModal}
                title="ADD PATIENT"
                onClose={patientStore.handleCancelClose}
                onClick={patientStore.handleCancelClose}
            >
                <CreatePatientWizard
                    onClose={() => {
                        patientStore.handleCancelClose(true);
                        patientStore.loadPatients();
                    }}
                />
            </ModalContainer>
            <ModalContainer
                show={patientStore.toggleShowPatientEditModal}
                noScroll={true}
                title=""
                hideTitlebar={true}
                onClose={() => { }}
                onClick={patientStore.toggleEditPatientModal.bind(patientStore)}
            >
                <PatientEdit
                    asProvider={true}
                    patientID={patientStore.currentPatientId}
                    patient={patientStore.currentRow}
                    onClose={() => {
                        patientStore.toggleEditPatientModal();
                        patientStore.loadPatients();
                    }}
                />
            </ModalContainer>
            {prescriptionStore.showRxStatusDetails && <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={prescriptionStore.showRxStatusDetails}
                title={"Rx Status Details"}
                onClick={() => { }}
                onClose={() => {
                    prescriptionStore.setShowRxStatusDetails(false);
                }}>
                <RxStatusDetails
                    patient={patientStore.currentRow}
                    prescription={prescriptionStore.prescription}
                />
            </ModalContainer>}
            <ModalContainer
                show={patientStore.toggleShowPrescriptionModal}

                title={`Prescription for ${patientStore.currentRow?.firstName ?? ""} ${patientStore.currentRow?.lastName ?? ""} - ${patientStore.currentRow?.vivosId ?? ""}`}
                onClose={handleCloseModal}
                onClick={patientStore.togglePrescriptionModal.bind(patientStore)}
            >
                {console.log(patientStore.currentRow)}
                {console.log(patientStore._currentRow)}
                <CreateRxWizard patientId={patientStore.currentPatientId} isEdit={false} sendOnCloseEvent={() => {
                    patientStore.toggleShowPrescriptionModal = false;
                    patientStore.loadPatients();
                }} />
            </ModalContainer>
            <ConfirmDialog
                title="Confirm Exit"
                open={showConfirmClosePrescriptionForm}
                onClose={handleCancelClosePrescriptionForm}
                onConfirm={handleConfirmClosePrescriptionForm}
                message="Are you sure you want to close without saving?"
            />
            <ModalContainer
                show={customFormStore.toggleShowCustomFormViewModal}
                title=""
                onClose={customFormStore.toggleCustomFormModal.bind(customFormStore)}
                onClick={customFormStore.toggleCustomFormModal.bind(customFormStore)}
            >
                <View />
            </ModalContainer>
            <ConfirmDialog
                title="Missing Account Address Information"
                open={openModalMissingLocation}
                onClose={() => setOpenModalMissingLocation(false)}
                onConfirm={() => setOpenModalMissingLocation(false)}
                textOnClose="Close"
                textOnConfirm="Confirm"
                message="To continue with the Prescription Process, please update your 'Account Address Information' in the provider settings."

            />
        </>
    );
});

export default Patients;
