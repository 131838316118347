// Vendors
import { useEffect, FC, useState } from "react";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { Button, Grid, MenuItem, IconButton } from "@mui/material";
import { GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

// Components
import DataGrid from '../../../components/DataGrid/DataGrid';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';

// Styling
import CssSelectField from '../../../components/CssSelectField';
import CssTextField from "../../../components/CssTextField";

interface IGIProps {
    submissionId: number;
    dataLogValues: any[];
}

export const AIReportDatalog: FC<IGIProps> = observer(
    ({ submissionId, dataLogValues }) => {
        const [hasLoaded, setHasLoaded] = useState(false);
        const [rows, setRows] = useState<any>(dataLogValues);
        const [tempCount, setTempCount] = useState<number>(0);
        const measurementStore = useMainStoreContext().measurementsData;
        const store = useMainStoreContext().aiReportsStore;
        useEffect(() => {
            // setMeasurementSubmissionId(submissionId);

        }, [])

        useEffect(() => {
            setHasLoaded(false);
            setRows(dataLogValues);
            setHasLoaded(true);
        }, [measurementStore.measurementProperties, dataLogValues]);

        const handleSaveValues = () => {
            measurementStore.savePropertyValues(rows);
        }

        const handleValueChange = (id, value) => {
            const updatedRows = rows.map(row => {
                if (row.id === id && row.valueX !== value) {
                    return { ...row, valueX: value };
                }
                return row;
            });

            setRows(updatedRows);
        };

        const handleVivosPropertyChange = (id, value) => {
            const updatedRows = rows.map(row => {
                if (row.id === id && row.vivosPropertyId !== value) {
                    return { ...row, vivosPropertyId: value };
                }
                return row;
            });
            setRows(updateVivosMeasurementNames(updatedRows));
        };

        // const handleDolphinPropertyChange = (id, value) => {
        //     let updatedRows = rows.map(row => {
        //         if (row.propertyId === id && row.propertyId !== value) {
        //             return { ...row, propertyId: value, name: measurementStore.measurementProperties.find(m => m.id === value).name };
        //         }
        //         return row;
        //     });
        //     updatedRows = updateVivosMeasurementNames(updatedRows);
        //     setRows(updatedRows);
        // };

        const handleDelete = (id) => {
            setRows(prevRows => { return prevRows.map(row => { if (row.id === id) { return { ...row, isActive: false }; } return row; }); });
        }


        const handleAddRow = () => {
            // const existingNames = rows.map(row => row.name);
            // const availableMeasurements = measurementStore.measurementProperties.filter(
            //     measurement => !existingNames.includes(measurement.name)
            // );

            // if (availableMeasurements.length > 0) {
            const newRow = {
                propertyId: null,
                visitRecordId: store.selectedVisitRecordId,
                submissionId: submissionId,
                name: 'Miscellaneous',
                valueX: 0,
                vivosPropertyId: null,
                isActive: true,
                id: tempCount
            };
            setRows([...rows, newRow]);
            setTempCount(tempCount + 1);
            // } else {
            //     alert('No more measurements available to add.');
            // }
        };

        const aiReportsCustomFooter = () => {
            return (
                <GridFooterContainer>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            id="aiReports.btn.deleteAll"
                            onClick={() => {
                                measurementStore.deleteAllDataValues(store.selectedVisitRecordId);
                            }}
                            style={{ padding: 0, marginRight: 4 }}
                        >
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                        <IconButton
                            id="aiReports.btn.addOne"
                            onClick={handleAddRow}
                            style={{ padding: 0 }}
                        >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </div>
                    <GridFooter
                        sx={{
                            border: 'none',
                        }}
                    />
                </GridFooterContainer>
            );
        };

        const updateVivosMeasurementNames = (updatedRows) => {
            return updatedRows.map(row => {
                let menuItems = measurementStore.vivosPropertyNames;
                if (row.name.includes("2D Area") && row.name.includes("Axial")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Area") && p.value.includes("Axial"));
                } else if (row.name.includes("2D Area") && row.name.includes("Coronal")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Area") && p.value.includes("Coronal"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("2D Line") && row.name.includes("Sagittal")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Line") && p.value.includes("Sagittal"));
                } else if (row.name.includes("2D Line") && row.name.includes("Volume")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Line") && p.value.includes("Volume"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("2D Line") && row.name.includes("Axial")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Line") && p.value.includes("Axial"));
                } else if (row.name.includes("2D Line") && row.name.includes("Coronal")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Line") && p.value.includes("Coronal"));
                } else if (row.name.includes("2D Angle") && row.name.includes("Sagittal")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Angle") && p.value.includes("Sagittal"));
                    if (row.valueX > 50) {
                        row.vivosPropertyId = menuItems[1].id;
                    } else {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("2D Angle") && row.name.includes("Volume")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("2D Angle") && p.value.includes("Volume"));
                } else if (row.name.includes("Condylion (R)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("Co, R"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Condylion (L)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("Co, L"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Jugal (R)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("J, R"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Jugal (L)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("J, L"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name === ("Notch (R)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("AG, R"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name === ("Notch (L)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("AG, L"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Midline Discrepancy")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("Mx/Md MidLines Discrepancy"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Condyle to Notch (R)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("Co-AG, R"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Condyle to Notch (L)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("Co-AG, L"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Notch Menton (R)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("AG-Me, R"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                } else if (row.name.includes("Notch Menton (L)")) {
                    menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => p.value.includes("AG-Me, L"));
                    if (menuItems.length > 0 && row.vivosPropertyId !== menuItems[0].id) {
                        row.vivosPropertyId = menuItems[0].id;
                    }
                }
                return row;
            });
        };

        const columns = [
            {
                field: 'delete',
                headerName: '',
                flex: .25,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: (params) => {
                    return (
                        <Grid><Button onClick={() => { handleDelete(params.row.id) }}><DoNotDisturbOnIcon color="disabled" /></Button></Grid>
                    )
                }
            },
            {
                field: 'name',
                headerName: 'Dolphin Measurement Name',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'left',
                renderCell: (params) => {
                    return (
                        <Grid>
                            <CssTextField

                                value={params.row.name}
                            >

                            </CssTextField>
                        </Grid>
                    );
                }
            },
            {
                field: 'valueX',
                headerName: 'Value',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: (params) => {
                    return (
                        <Grid>
                            <CssTextField
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleValueChange(params.row.id, e.target.value);
                                }}
                                value={params.row.valueX}
                            >
                            </CssTextField>
                        </Grid>
                    )
                }
            },
            {
                field: 'vivosName',
                headerName: 'Vivos Measurement Name',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center',
                renderCell: (params) => {
                    var menuItems: any = measurementStore.vivosPropertyNames;
                    if (params.row.name.includes("2D Area") && params.row.name.includes("Axial")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Area") && p.value.includes("Axial")) });
                    } else if (params.row.name.includes("2D Area") && params.row.name.includes("Coronal")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Area") && p.value.includes("Coronal")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Sagittal")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Sagittal")) });
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Volume")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Volume")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Axial")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Axial")) });
                    } else if (params.row.name.includes("2D Line") && params.row.name.includes("Coronal")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Line") && p.value.includes("Coronal")) });
                    } else if (params.row.name.includes("2D Angle") && params.row.name.includes("Sagittal")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Angle") && p.value.includes("Sagittal")) });
                        if (params.row.valueX > 50) {
                            params.row.vivosPropertyId = menuItems[1].id;
                        } else {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    } else if (params.row.name.includes("2D Angle") && params.row.name.includes("Volume")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("2D Angle") && p.value.includes("Volume")) });
                    } else if (params.row.name.includes("Condylion (R)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("Co, R")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Condylion (L)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("Co, L")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Jugal (R)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("J, R")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Jugal (L)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("J, L")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name === ("Notch (R)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("AG, R")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name === ("Notch (L)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("AG, L")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Midline Discrepancy")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("Mx/Md MidLines Discrepancy")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Condyle to Notch (R)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("Co-AG, R")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Condyle to Notch (L)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("Co-AG, L")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Notch Menton (R)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("AG-Me, R")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }
                    else if (params.row.name.includes("Notch Menton (L)")) {
                        menuItems = _.filter(measurementStore.vivosPropertyNames, (p) => { return (p.value.includes("AG-Me, L")) });
                        if (menuItems.length > 0 && params.row.vivosPropertyId !== menuItems[0].id) {
                            params.row.vivosPropertyId = menuItems[0].id;
                        }
                    }


                    return (
                        <Grid>
                            <CssSelectField
                                id={'aiReportStatus.dashboard.cell.vivosPropertyName.' + params.row.ID}
                                sx={{ border: '0px' }}
                                defaultValue=""
                                label="Status"
                                value={params.row.vivosPropertyId ? params.row.vivosPropertyId : ''}
                                onChange={e => {
                                    handleVivosPropertyChange(params.row.id, e.target.value);
                                }}
                            >
                                {menuItems.map((item: any) => (
                                    <MenuItem key={item.id + item.name} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </CssSelectField>
                        </Grid>
                    );
                }
            },
        ];

        return (
            <Grid className="modalPageHeight autoScroll" container padding={2} >
                {hasLoaded && <Grid item xs={12}>
                    <Button variant="contained" onClick={() => { handleSaveValues(); }} >Save</Button>
                    <DataGrid
                        columns={columns}
                        data={rows.filter(x => x.isActive === true)}
                        loading={!hasLoaded}
                        pageSize={100}
                        hideFooter={!hasLoaded}
                        customFooter={aiReportsCustomFooter}
                    />
                </Grid>}
            </Grid>

        );
    });


