import { observable } from 'mobx';
import ProviderTypePriceDto from '../providerTypePrice';
import ProviderTypeEcomDto from '../providerTypeEcomDto';

class EcommerceItem {
  @observable id: number = 0;
  @observable ecommerceId: string = '';
  @observable name: string = '';
  @observable isLincare: boolean | null = null;
  @observable imageUrl: string = '';
  @observable itemFamily: string = '';
  @observable constructionTypeId: number = 550;
  @observable providerTypePrices: ProviderTypePriceDto[] = [];
  @observable providerTypeEcomPrices: ProviderTypeEcomDto[] = [];
}

export default EcommerceItem;
