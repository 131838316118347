// Vendors
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
    styled,
    CircularProgress,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Link,
    Checkbox,
    Alert,
    useTheme
} from "@mui/material";
import { LoadingButton } from '@mui/lab';

// Components
import RxStepController from "./RxStepComponents/RxStepController";

// Stores
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react-lite";
import { OcclusionModelType, RxAppliances } from "../../entities/Enums";
import { GlobalUtils } from "../../api/GlobalUtils";
import CloseIcon from '@mui/icons-material/Close';
import _ from "lodash";
import { Gateway } from "../../api/Gateway";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";
import { Prescription } from "../../models/state_models/prescription";
import { isValid } from "date-fns";

interface IGIProps {
    patientId: number;
    isEdit: boolean;
    sendOnCloseEvent(): any;
    prescription?: any;
    clonedPrescription?: any;
}

const BoxBorderBottom = styled(Box)(
    () => `
          border-bottom: transparent 5px solid;
    `
);

export const CreateRxWizard: React.FC<IGIProps> = observer((props) => {
    const theme = useTheme();
    const patietnStore = useMainStoreContext().patientStore;
    const steps = ['Patient Information', 'Lab Selection', 'Teeth Charting', 'Construction', 'Impressions', 'Final Review'];

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    const isStepOptional = (step: number) => {
        return step === step + 1;
    };


    const {
        currentPatient,
        isSavingPatient,
        selectedAppliance,
        selectedLab,
        isSavingPrescription,
        isLoading,
        step,
        previousStep,
        isUpdating,
        setIsUpdating,
        roles,
        updatePerson,
        setIsSavingPatient,
        reset,
        setStep,
        loadPage,
        setIsSavingPrescription,
        createPrescription,
        cancelPrescription,
        productsToAdd,
        providerType,
        getUserId,
        updatePrescription,
        loadEcommerceData,
        shouldCancelOrder,
        setCheckoutExternalId,
        setIsLoading,
        productionRushProduct,
        setProductionRushProduct,
        selectedImpressions,
        fabricationTime,
        setIsLoadingAppliances,
        isLoadingAppliances,
        variantsSelected,
        prescription,
        setShouldCancelOrder,
        setIsClonedPrescription,
        setClonedPrescription,
        isClonedPrescription,
        clonedPrescription,
        isAISAdmin,
        isAISAdminManager,
        isAISSupervisor,
        checkRoles,
        isConstructionComplete
    } = usePrescriptionStore().prescriptionStore;
    const { getHistory } = useMainStoreContext().patientEditStore;
    const { upsertPatientHistory } = useMainStoreContext().patientEditStore;
    const { saveTeethChart } = useMainStoreContext().teethChartStore;
    const { client, cart, addVariantToCart, setProducts, setCartOpen, getCheckoutUrl, fetchProducts, addMultipleVariantsToCart, emptyCart } = useMainStoreContext().shopifyStore;
    const occlusionStore = useMainStoreContext().occlusionStore;
    const visitStore = useMainStoreContext().visitRecordStore;
    const { providerData } = useMainStoreContext().providerStore;

    const [openCancelConfirmationModal, setOpenCancelConfirmationModal] = useState(false);
    const [skipped, setSkipped] = useState(new Set<number>());
    const [isSavingTeethChart, setIsSavingTeethChart] = useState(false);
    const [isAIS, setIsAIS] = useState<boolean>(false);
    const [isTCChecked, setIsTCChecked] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isAISSpecialist, setIsAISSpecialist] = useState<boolean>(false);
    const [finalVisitId, setFinalVisitId] = useState<number>(0);


    const handleTCCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTCChecked(event.target.checked);
    };

    useEffect(() => {
        checkRoles();
        setClonedPrescription(false);
        if (props.isEdit) {
            setIsUpdating(true);
        }
        setIsLoading(true);
        setIsAISSpecialist(JSON.parse(roles).some(role => role.Name === 'AISSpecialist'));
        setIsAIS(!_.isUndefined(props.prescription));
        setIsLoadingAppliances(true);

        loadPage(props.patientId);
        getHistory(props.patientId);


        if (client)
            fetchProducts().then((products) => {
                setProductionRushProduct(products.find(x => x.title.includes('Rush')));
                setProducts(products);
                setCartOpen(true);
                if (!_.isEmpty(props.clonedPrescription)) {
                    setIsClonedPrescription(true);
                    setClonedPrescription(props.clonedPrescription);
                }

                const fetchData = async () => {
                    await loadEcommerceData(products);
                };

                fetchData().then(() => {
                    if (!_.isEmpty(props.clonedPrescription)) {
                        setStep(true);
                    }
                });
            });

        return () => {
            if (!(roles.includes(process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) || roles.includes(process.env.REACT_APP_VIVOS_AIS_ADMIN))) {
                reset();
                occlusionStore.resetToInitialValues();
            }

        };
    }, []);

    /* useEffect(() => {
        createPatientStore.setpatientOcclusionImageType(GlobalUtils.getPatientOcclusionImageTypes());
    }, [createPatientStore]); */

    useEffect(() => {
        if (step === steps.length - 1 && previousStep < steps.length - 1) {
            // Moving forward to the last step, start adding products to the cart
            // Add your logic to add products to the cart here

            const variantsToAdd = productsToAdd
                .filter(item => !item.addedToCart)
                .map(item => {
                    const product = item.shopifyProduct;
                    const variant = product.variants.find((y: any) =>
                        y.selectedOptions.every(
                            (opt: any) => item.variantsSelected[opt.name] === opt.value
                        )
                    );
                    if (!variant) {
                        throw new Error(`No variant found for product ${product.id}`);
                    }

                    const price = item.eCommerceItem.providerTypePrices.length > 0
                        ? item.eCommerceItem.providerTypePrices.reduce(
                            (lowest, current) => current.priority < lowest.priority ? current : lowest,
                            item.eCommerceItem.providerTypePrices[0]
                        ).price
                        : undefined;

                    return { variantId: variant.id, quantity: 1, price };
                });

            addMultipleVariantsToCart(variantsToAdd);

        } else if (previousStep === steps.length - 1 && step < steps.length - 1) {
            // Moving back from the last step, empty the cart
            emptyCart();
        }
    }, [step, previousStep]);

    const handleNext = () => {
        setStep(true);
    }

    const handleBack = () => {
        setStep(false);
    };

    const handleUpdatePatient = async () => {
        try {
            setIsSavingPatient(true);
            await updatePerson(currentPatient, props.patientId);
            await upsertPatientHistory(props.patientId);
            handleNext();
        } catch (error) {
            setError('Error updating patient information');
            console.error(error);
        } finally {
            setIsSavingPatient(false);
        }
    };

    const handleTeethChart = async () => {
        try {
            setIsSavingTeethChart(true);
            await saveTeethChart();
            handleNext();
        } catch (error) {
            setError('Error saving teeth chart');
            console.error(error);
        } finally {
            setIsSavingTeethChart(false);
        }
    };

    const handleVisitSave = async () => {
        try {
            if (visitStore.getVisitId() <= 0) {
                visitStore.patientId = props.patientId;
                const resp = await visitStore.saveVisit();
                if (visitStore.currentVisit) {
                    const images = visitStore.visitOcclusion2dimages.concat(visitStore.visitOcclusion3dimages);
                    await Promise.all(images.map(async img => {
                        await Gateway.post(`/patient/${props.patientId}/${visitStore.currentVisit.id}/${getUserId()}/addpatientimage`, img);
                    }));
                    if (images.length > 0) {
                        handleOcclusionSave();
                    }
                    visitStore.setImages([]);
                    visitStore.loadVisit();
                }
            } else {
                if (visitStore.patientsImages.length > 0) {
                    occlusionStore.lg_machinetype = visitStore.patientsImages[0]?.occlusion3dmachineBrand;
                    occlusionStore.impressiondate = visitStore.patientsImages[0]?.occlusionImpressionDate;
                    occlusionStore.staffmember = visitStore.patientsImages[0]?.occlusionMemberTookImpression;
                    occlusionStore.occlusionModelTypeId = visitStore.patientsImages[0]?.modelTypeId;
                    occlusionStore.constructionBiteType = visitStore.patientsImages[0]?.constructionBiteType;
                    handleOcclusionSave();
                    visitStore.deleteImages(visitStore.currentVisit.id);
                }
            }
        } catch (error) {
            setError('Error saving visit');
            console.error(error);
        }
    }

    const [isImpressionsValid, setIsImpressionsValid] = useState(false);
    const validateImpressions = () => {
        if (!occlusionStore?.impressiondate || !isValid(new Date(occlusionStore.impressiondate))) {
            setIsImpressionsValid(false);
            return;
        }
        // if (selectedImpressions === 'new') {
        if (occlusionStore.occlusionModelTypeId === OcclusionModelType.Digital) {
            // const isConstructionBiteTypeInvalid = _.isUndefined(occlusionStore.constructionBiteType) || _.isNull(occlusionStore.constructionBiteType);
            // const isLgMachineTypeInvalid = _.isUndefined(occlusionStore.lg_machinetype) || _.isNull(occlusionStore.lg_machinetype);
            const isVisitOcclusion3dImagesInvalid = visitStore.visitOcclusion3dimages.length <= 0 || visitStore.visitOcclusion3dimages.some(image => !image.imageType);


            setIsImpressionsValid(!isVisitOcclusion3dImagesInvalid);
        } else if (occlusionStore.occlusionModelTypeId === OcclusionModelType.Physical) {
            // const isConstructionBiteTypeInvalid = _.isUndefined(occlusionStore.constructionBiteType) || _.isNull(occlusionStore.constructionBiteType);

            setIsImpressionsValid(true);
        } else {
            setIsImpressionsValid(false);
        }
        // } else {
        //     // const isVisitIdInvalid = visitStore.getVisitId() <= 0;

        //     setIsImpressionsValid(isVisitIdInvalid);
        // }
    }

    useEffect(() => {
        validateImpressions();
    }, [
        selectedImpressions,
        occlusionStore.occlusionModelTypeId,
        occlusionStore.constructionBiteType,
        occlusionStore.lg_machinetype,
        occlusionStore.impressiondate,
        visitStore.visitOcclusion3dimages,
        visitStore.getVisitId()
    ]);

    const handleOcclusionSave = async () => {
        try {
            await occlusionStore.upsertOcclusionDetails(visitStore.getVisitId());
            handleNext();
        } catch (error) {
            setError('Error saving occlusion details');
            console.error(error);
        } finally {
            occlusionStore.setIsSavingOcclusion(false);
            setIsSavingPrescription(false);
        }
    };

    const cancelOrder = async () => {
        try {
            setIsSavingPrescription(true);
            setOpenCancelConfirmationModal(false);
            if (isUpdating && shouldCancelOrder) {
                await cancelPrescription();
                setIsSavingPrescription(false);
                props.sendOnCloseEvent();
            }
        } catch (error) {
            setError('Error canceling order');
            console.error(error);
        } finally {
            setIsSavingPrescription(false);
        }
    }

    const [rushProductAdded, setRushProductAdded] = useState(false);

    const openCheckout = async () => {
        // If the function is called again while it's already processing,
        // short-circuit to prevent a duplicate add.
        if (isSavingPrescription) {
            return;
        }

        try {
            setIsSavingPrescription(true);

            if (isUpdating) {
                await cancelPrescription();
                setIsSavingPrescription(false);
                return;
            }

            // We'll track if the Rush product has been added
            // Loop through productsToAdd



            setCheckoutExternalId(cart.id);

            // If "Rush" is selected, check whether we've already added it
            if (fabricationTime === 'Rush' && !rushProductAdded) {
                await addVariantToCart(
                    productionRushProduct.variants[0]?.id,
                    1,
                    productionRushProduct.variants[0]?.price?.amount
                );
                setRushProductAdded(true);
            }

            if (!isClonedPrescription) {
                //await handleVisitSave();
                const response = await createPrescription(finalVisitId);
                occlusionStore.setPrescriptionId(response.id);
                occlusionStore.upsertOcclusionDetails(finalVisitId);
            }

            if (isClonedPrescription) {
                clonedPrescription.order.externalEcommerceCheckoutId = cart.id;
                await Gateway.putStrongType<Prescription>(
                    `/prescription/${getUserId()}/clone`,
                    clonedPrescription
                );
            }

            if (!currentPatient.isLincare) {
                sessionStorage.setItem('Cart', cart.id);
                sessionStorage.setItem(
                    'PrescriptionId',
                    prescription?.id !== 0
                        ? prescription?.id?.toString() || ''
                        : clonedPrescription?.id?.toString() || ''
                );
                const url = await getCheckoutUrl();
                window.open(url, '_self');
            } else {
                props.sendOnCloseEvent();
            }
        } catch (error) {
            setError('Error during checkout process');
            console.error(error);
        } finally {
            setIsSavingPrescription(false);
        }
    };



    const handleTCDocumentLink = () => {
        switch (selectedAppliance?.name.trim()) {
            case RxAppliances.PEX:
            case RxAppliances.PEXD:
            case RxAppliances.PEXL:
            case RxAppliances.PEXU:
                return "https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Warranty Repair T&C/2024.04 - T&C - Vivos PEx - Warranty - CLEAN.pdf";
            case RxAppliances.DNA:
            case RxAppliances.DNADUAL:
            case RxAppliances.DNASAL:
            case RxAppliances.DNASAU:
            case RxAppliances.MMRNA:
            case RxAppliances.MMRNASAL:
            case RxAppliances.MMRNASAU:
            case RxAppliances.MMRNAV:
            case RxAppliances.MRNA:
            case RxAppliances.MRNASAL:
            case RxAppliances.MRNASAU:
            case RxAppliances.MRNAV:
                return "https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Warranty Repair T&C/2024.04 - T&C - CARE Appliances - Warranty - CLEAN.pdf";
            case RxAppliances.VIDA:
                return "https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Warranty Repair T&C/2024.04 - T&C - Vivos Vida - Warranty - CLEAN.pdf";
            case RxAppliances.VIDASLEEP:
                return "https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Warranty Repair T&C/2024.04 - T&C - Vivos Vida Sleep - Warranty - CLEAN.pdf";
            case RxAppliances.GUIDE:
            case RxAppliances.GUIDEVG:
            case RxAppliances.GUIDEVGLincare:
            case RxAppliances.GUIDEVGX:
            case RxAppliances.GUIDEVGXLincare:
            case RxAppliances.GUIDEVW:
            case RxAppliances.GUIDEVWLincare:
                return "https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Warranty Repair T&C/2024.04 - T&C - Vivos Guides - Warranty - CLEAN.pdf";
            case RxAppliances.VERSA:
            case RxAppliances.VERSA9:
            case RxAppliances.VERSANYLONEMA:
                return "https://prodimagesvaire.blob.core.windows.net/sharedresources/Business Documents/Warranty Repair T&C/2024.04 - T&C - Vivos Versa - Warranty - CLEAN.pdf";
            default:
                return "";
        }
    };

    const updatePrescriptionCloseModal = () => {
        try {
            setIsSavingPrescription(true);
            // Ensure the updatePrescription function can handle undefined values safely
            updatePrescription(undefined, isAIS, finalVisitId).then(() => {
                setIsSavingPrescription(false);
                props.sendOnCloseEvent();
            }).catch(() => {
                setIsSavingPrescription(false);
                props.sendOnCloseEvent();
            });
        } catch (error) {
            console.error('Error updating prescription:', error);
        }
    }

    const handleGoCancel = () => {
        setShouldCancelOrder(true);
        handleNext();

    }


    const handleImpressionsSave = async () => {
        // Set loading state
        setIsLoading(true);
        try {
            if (visitStore.getVisitId() <= 0) {
                visitStore.setVisitPatientId(props.patientId);
                await visitStore.saveVisit();
                if (visitStore.currentVisit) {
                    occlusionStore.setVisitId(visitStore.currentVisit.id);
                    await occlusionStore.upsertOcclusionDetails(visitStore.getVisitId());
                    const images = visitStore.visitOcclusion2dimages.concat(visitStore.visitOcclusion3dimages);
                    await Promise.all(images.map(img =>
                        Gateway.post(`/patient/${props.patientId}/${visitStore.currentVisit.id}/${visitStore.userId}/addpatientimage`, img)
                    ));
                }
                await visitStore.loadVisit();
            } else {
                occlusionStore.setVisitId(visitStore.getVisitId());
                const images = visitStore.visitOcclusion2dimages.concat(visitStore.visitOcclusion3dimages);
                await Promise.all(images.map(img =>
                    Gateway.post(`/patient/${props.patientId}/${visitStore.currentVisit.id}/${visitStore.userId}/addpatientimage`, img)
                ));
                await occlusionStore.upsertOcclusionDetails(visitStore.getVisitId());
                await visitStore.deleteImages(visitStore.getVisitId());
            }
        } catch (error) {
            console.error('Error saving impressions:', error);
        } finally {
            setIsLoading(false);
            setFinalVisitId(_.isNull(visitStore.getVisitId()) || _.isUndefined(visitStore.getVisitId()) || _.isEmpty(visitStore.getVisitId()) ? visitStore.currentVisit.id : visitStore.getVisitId());
        }

        // Move to next step
        setStep(true);
    };



    return (
        <Grid className="stepper-wizard">
            <Dialog
                open={openCancelConfirmationModal}
                onClose={() => { }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid display="flex" alignItems="center">
                        <Grid flexGrow={1}>Invalid Patient Data</Grid>
                        <Grid>
                            <IconButton className="closeButton" onClick={() => { setOpenCancelConfirmationModal(false) }}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid marginBottom={3}>
                        <Grid marginBottom={1}>The changes you are requesting can't be done. By confirming, this prescription will be canceled and you will need to create a new Prescription:</Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: "end" }}>
                        <Button onClick={() => { setOpenCancelConfirmationModal(false); }} variant="contained">Cancel</Button>
                        <Button onClick={() => { cancelOrder(); }} variant="contained">Confirm</Button>
                    </Grid>
                </DialogContent>
            </Dialog>

            {error && (
                <Box mb={2}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            )}

            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};
                    if (isStepOptional(index)) { }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                {step === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button className='vivButton large'>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : ((isLoading || isLoadingAppliances) ?
                    <Box sx={{ width: '100%', height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} />
                    </Box>
                    :
                    <Box padding={2}>
                        <RxStepController />
                    </Box>
                )}

                <BoxBorderBottom
                    padding={1}
                    sx={{
                        borderBottomColor: theme.palette.primary.main,
                        width: '100%',
                        backgroundColor: theme.colors.alpha.black[5],
                        position: 'absolute',
                        bottom: '0',
                    }}>
                    <Grid container justifyContent={step === 0 ? 'right' : 'space-between'} alignContent={'center'}>

                        {step !== 0 && <Button
                            variant="contained"
                            color='inherit'
                            size="small"
                            disabled={isClonedPrescription}
                            onClick={handleBack}
                        >
                            Back
                        </Button>}
                        {step != 5 && isUpdating &&
                            <LoadingButton
                                size="small"
                                variant='contained'
                                onClick={handleGoCancel}
                            >
                                Cancel Order
                            </LoadingButton>

                        }
                        {step === 0 &&
                            <LoadingButton
                                loading={isSavingPatient}
                                disabled={selectedAppliance?.id === 0 || (isAISSpecialist && !isUpdating)}
                                size="small"
                                variant='contained'
                                onClick={handleUpdatePatient}
                            >
                                Confirm & Continue
                            </LoadingButton>
                        }
                        {step === 1 &&
                            <LoadingButton
                                loading={false}
                                disabled={!selectedLab || (isAISSpecialist && !isUpdating)}
                                size="small"
                                variant='contained'
                                onClick={() => { handleNext() }}
                            >
                                Confirm & Continue
                            </LoadingButton>
                        }
                        {step === 2 &&
                            <LoadingButton
                                loading={isSavingTeethChart}
                                disabled={selectedLab.id === -1 || (isAISSpecialist && !isUpdating)}
                                size="small"
                                variant='contained'
                                onClick={handleTeethChart}
                            >
                                Confirm & Continue
                            </LoadingButton>
                        }
                        {step === 3 &&
                            <LoadingButton
                                disabled={_.isEmpty(variantsSelected) || (isAISSpecialist && !isUpdating) || !isConstructionComplete}
                                size="small"
                                variant='contained'
                                onClick={handleNext}
                            >
                                Confirm & Continue
                            </LoadingButton>
                        }
                        {step === 4 &&
                            <LoadingButton
                                disabled={!isImpressionsValid || (isAISSpecialist && !isUpdating)}
                                size="small"
                                variant='contained'
                                onClick={handleImpressionsSave}
                            >
                                Confirm & Continue
                            </LoadingButton>
                        }
                        {step === 5 &&
                            <Box flexDirection={'row'} display={'flex'} justifyContent={'center'}>
                                {(selectedAppliance?.name.trim() !== "emaNow") &&
                                    <>
                                        <Box paddingRight={.5} alignContent={'center'}>
                                            <Typography variant="caption">
                                                <Link href={handleTCDocumentLink()} target="_blank" color={isTCChecked ? 'success' : 'error'}>
                                                    <u>I agree to the Terms and Conditions</u>
                                                </Link>
                                            </Typography>
                                        </Box>
                                        <Box paddingRight={3} alignContent={'center'}>
                                            <Checkbox
                                                checked={isTCChecked}
                                                onChange={handleTCCheckboxChange}
                                                color={isTCChecked ? 'success' : 'error'}
                                            />
                                        </Box>
                                    </>
                                }
                                <LoadingButton
                                    loading={isSavingPrescription}
                                    disabled={(() => {
                                        if (isAISSpecialist && !(isAISAdminManager || isAISSupervisor || isAISAdmin)) {
                                            return true
                                        }
                                        if (selectedAppliance?.name.trim() === "emaNow") {
                                            return false;
                                        }
                                        return (selectedLab?.id === -1) || (!isTCChecked);
                                    })()}
                                    variant='contained'
                                    color="primary"
                                    sx={{ borderRadius: '30px' }}
                                    onClick={() => {
                                        isUpdating ? (shouldCancelOrder ? cancelOrder() : updatePrescriptionCloseModal()) : openCheckout()
                                    }}
                                >
                                    {isUpdating ? (shouldCancelOrder ? 'Cancel Prescription' : 'Confirm & Resubmit') : 'Confirm & Submit'}
                                </LoadingButton>
                            </Box>
                        }
                    </Grid>
                </BoxBorderBottom>
            </React.Fragment>
        </Grid>
    );
});
