import { MainStore } from './MainStore';
import { observable, action, makeObservable, runInAction } from 'mobx';
import TeethChart from '../../entities/TeethChart';
import TeethChartTooth from '../../entities/TeethChartTooth';
import { Gateway } from '../../api/Gateway';
import * as _ from 'lodash';
import { NumberingSystem, SupernumeraryPositions, TeethSet, UneruptedConditions } from '../../entities/Enums';
import { ToothDetail } from '../../components/TeethCharting/ToothDetail';
import { GlobalUtils } from '../../api/GlobalUtils';


const DECIDUOUS: string = "Deciduous;";
const UNERUPTEDPOORPATH: string = "Unerupted: Poor Eruption Path; "
const UNERUPTEDIMPACTED: string = "Unerupted: Impacted;"
const UNERUPTEDEMBEDDED: string = "Unerupted: Embedded;"
const MISSINGREASONIDMIN = 113;
const MISSINGREASONIDUNERUPTED = 114;
//const MISSINGREASONIDMAX = 121;
const PRESENTCONDITIONIDMIN = 122;
//const SUPERNUMERARYOFFSET = 50;
const FDITOPLEFTOFFSET = 19;
const FDITOPRIGHTOFFSET = 11;
const FDIBOTTOMLEFTOFFSET = 16;
//const FDIBOTTOMRIGHT = 39;
const PALMERUROFFSET = 8;
const PALMERBLOFFSET = 24;



const defaultChart = {
  id: 0,
  patientId: 0,
  notes: "",
  teethChartTeeth: [],
  isActive: true
}

const defaultTooth = {
  id: 0,
  chartId: 0,
  toothNumber: 0,
  notes: "",
  isDeciduous: false,
  supernumeraryPosition: 0,
  conditions: [],
  uneruptedReason: 119,
  presentConditions: 0,
  presentConditionsArray: [122],
  isActive: true
}
export default class TeethChartStore {
  mainStore: MainStore;

  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;

  }

  @observable teethChart: TeethChart = new TeethChart(defaultChart);
  @observable originalTeethChart: TeethChart = new TeethChart(defaultChart);
  @observable patientId: number = 0;
  @observable id: number = 0;
  @observable notes: string = "";
  @observable teethChartTeeth: TeethChartTooth[] = [];
  @observable deletedTeeth: TeethChartTooth[] = [];
  @observable currentToothNum: number = 0;
  @observable currentNumberingSystem: string = NumberingSystem.UNIVERSAL;
  @observable currentTeethSet: string = TeethSet.ALL;
  @observable openUneruptedModal: boolean = false;
  @observable openSupernumeraryModal: boolean = false;
  @observable toothDetails: ToothDetail[] = [];
  @observable showWarningModal: boolean = false;
  @observable isChangesSaved: number = 0;

  @action setTeethChart = (newValue: TeethChart) => { this.teethChart = newValue }
  @action setOriginalTeethChart = (newValue: TeethChart) => { this.originalTeethChart = newValue }
  @action setPatientId = (newValue: number) => { this.patientId = newValue }
  @action setId = (newValue: number) => { this.id = newValue }
  @action setNotes = (newValue: string) => { this.notes = newValue }
  @action setTeeth = (newValue: TeethChartTooth[]) => { this.teethChartTeeth = newValue }
  @action setDeletedTeeth = (newValue: TeethChartTooth[]) => { this.deletedTeeth = newValue }
  @action setCurrentToothNum = (newValue: number) => { this.currentToothNum = newValue }
  @action setCurrentNumberingSystem = (newValue: string) => { this.currentNumberingSystem = newValue }
  @action setCurrentTeethSet = (newValue: string) => { this.currentTeethSet = newValue }
  @action setOpenSupernumeraryModal = (newValue: boolean) => { this.openSupernumeraryModal = newValue }
  @action setOpenUneruptedModal = (newValue: boolean) => { this.openUneruptedModal = newValue }
  @action setToothDetails = (newValue: ToothDetail[]) => { this.toothDetails = newValue }

  @action setShowWarningModal = (value: boolean) => { this.showWarningModal = value; }
  @action setIsChangesSaved = (value: number) => { this.isChangesSaved = value; }

  @action
  resetTeethChart = () => {
    this.setTeethChart(new TeethChart(defaultChart));
    this.setOriginalTeethChart(new TeethChart(defaultChart));
    this.setId(0);
    this.setNotes("");
    this.setTeeth([]);
    this.setDeletedTeeth([]);
  }

  @action loadChart = async (): Promise<any> => {

    Gateway.getOne("teethChart/" + this.patientId).then((data) => {
      if (data) {
        var chart = data as unknown as TeethChart;              
        chart.teethChartTeeth = _.filter(chart.teethChartTeeth, (t) => { return t.isActive === true });
        this.setTeethChart(chart);        
        this.setOriginalTeethChart(chart);
      } else {
        this.setTeethChart(new TeethChart(defaultChart));
        this.setOriginalTeethChart(new TeethChart(defaultChart));
      }
      this.setId(this.teethChart.id);
      this.setNotes(this.teethChart?.notes ?? '');
      this.setConditionsOnTeeth();
      this.setTeeth(
        _.sortBy(
          _.filter(this.teethChart.teethChartTeeth, (t) => { return t.isActive === true })
          , ['toothNumber']));
    }).catch(() => {
      this.setTeethChart(new TeethChart(defaultChart));
      this.setOriginalTeethChart(new TeethChart(defaultChart));
    })
  }

  @action saveTeethChart = async (): Promise<any> => {
    this.teethChart.id = this.id;
    this.teethChart.patientId = this.patientId;
    this.teethChart.notes = this.notes;
    this.teethChart.teethChartTeeth = this.getTeethToSave();

    var teethChartToSave = this.teethChart;
    _.forEach(this.deletedTeeth, (d) => {
      teethChartToSave.teethChartTeeth.push(d);
    })
    this.resetTeethChart();
    Gateway.post("teethChart", teethChartToSave).then((data) => {
      var chart = data as unknown as TeethChart;
      chart.teethChartTeeth = _.filter(chart.teethChartTeeth, (t) => { return t.isActive === true });
      this.setTeethChart(chart);
      this.setNotes(chart.notes);
      this.setConditionsOnTeeth();
      this.setTeeth(
        _.sortBy(
          _.filter(this.teethChart.teethChartTeeth, (t) => { return t.isActive === true })
          , ['toothNumber']));
      this.setOriginalTeethChart(data as unknown as TeethChart);
      this.isChangesSaved = 1;
    }).catch(() => {
      this.setTeethChart(new TeethChart(defaultChart));
      this.setOriginalTeethChart(new TeethChart(defaultChart));
    })
    return this.teethChart;
  }

  @action
  setConditionsOnTeeth() {
    var conditions = GlobalUtils.getPresentConditions();
    _.forEach(this.teethChart.teethChartTeeth, (t) => {
      if (!t.missingReason) {
        t.missingReason = MISSINGREASONIDMIN;
      }
      if (t.presentConditionsId && t.presentConditionsId > 0) {
        t.presentConditionsArray = [];
        _.forEach(conditions, (c) => {
          var val = Number(c.value);
          if (t.presentConditionsId && val > 0 && ((t.presentConditionsId & val) === val)) {
            t.presentConditionsArray?.push(c.id);
          }
        })
      } else {
        t.presentConditionsArray = [PRESENTCONDITIONIDMIN];
      }
    })
  }

  @action
  getTeethToSave = () => {
    var conditions = GlobalUtils.getPresentConditions();
    _.forEach(this.teethChartTeeth, (t) => {
      if (t.id <= 0) {
        t.id = 0;
      }
      var toothConditions = 0;
      _.forEach(t.presentConditionsArray, (p) => {
        var condition = _.find(conditions, (c) => {
          return c.id === p;
        });
        toothConditions = toothConditions | (condition ? Number(condition.value) : 0);
      })
      t.presentConditionsId = toothConditions;
    })

    return this.teethChartTeeth;
  }

  @action onToothClick = (toothNum: number, isDeciduous: boolean, isUpdate: boolean) => {
    let index = _.findIndex(this.teethChartTeeth, (t: TeethChartTooth) => {
      return t.toothNumber === toothNum;
    })
    if (this.currentTeethSet === TeethSet.DECIDUOUS) {
      isDeciduous = true;
    }

    var tooth = {} as TeethChartTooth;
    if (index >= 0) { 
      tooth = this.teethChartTeeth[index]; 

      var deletedIndex = this.deletedTeeth.findIndex(x => x.toothNumber === tooth.toothNumber);
      // === to !=
      if (deletedIndex != -1){
        this.deletedTeeth.splice(deletedIndex, 1);
      }
    }
    
    if (index >= 0 && !isUpdate) {
      if (tooth.id > 0) {
        this.onDeleteTooth(toothNum);
      }else{        
        //tooth = this.teethChartTeeth[index];                 
        tooth.isActive = tooth.isActive === null ? true : !tooth.isActive;//!tooth.isActive ;
        this.teethChartTeeth.splice(index, 1);
        if(tooth.isActive){
          this.teethChartTeeth.push(tooth);
        }          
      }      
    } else if (index >= 0 && isUpdate) {
      this.teethChartTeeth.splice(index, 1);
      var updateTooth = new TeethChartTooth(defaultTooth);
      updateTooth.id = (-1 * toothNum);
      updateTooth.toothNumber = toothNum;
      updateTooth.isDeciduous = isDeciduous;
      updateTooth.presentConditionsArray = [PRESENTCONDITIONIDMIN];
      updateTooth.notes = this.updateDeciduousNote(updateTooth, isUpdate);
      this.teethChartTeeth.push(updateTooth);
    } else {
      var newTooth = new TeethChartTooth(defaultTooth);
      newTooth.id = (-1 * toothNum);
      newTooth.toothNumber = toothNum;
      newTooth.isDeciduous = isDeciduous;
      newTooth.presentConditionsArray = [PRESENTCONDITIONIDMIN];
      newTooth.notes = this.updateDeciduousNote(newTooth, isUpdate);
      this.teethChartTeeth.push(newTooth);
    }
    var sortedTeeth = _.sortBy(this.teethChartTeeth, ['toothNumber']);
    this.teethChart.teethChartTeeth = _.filter(sortedTeeth, (t) => { return t.isActive === true });
    this.setTeethChart(this.teethChart);
    this.setTeeth(sortedTeeth);
    //console.log(sortedTeeth);
    console.log(this.teethChartTeeth);
  }

  @action addSupernumerary = (toothNum: number, isDeciduous: boolean, position: number) => {
    var newTooth = new TeethChartTooth(defaultTooth);
    newTooth.id = (-1 * (toothNum));
    newTooth.toothNumber = toothNum;
    newTooth.isDeciduous = isDeciduous;
    newTooth.supernumeraryPosition = position;
    newTooth.presentConditionsArray = [PRESENTCONDITIONIDMIN];
    newTooth.notes = "Postion: " + SupernumeraryPositions[position] + "; ";
    newTooth.notes = this.updateDeciduousNote(newTooth, true);
    this.teethChartTeeth.push(newTooth);

    this.teethChart.teethChartTeeth = this.teethChartTeeth;
    this.setTeeth(_.sortBy(this.teethChartTeeth, ['toothNumber']));
  }

  @action addUnerupted = (toothNum: number, reason: number) => {
    var toothInList = _.findIndex(this.teethChartTeeth, (t) => {
      return (t.toothNumber === toothNum)
    });

    this.teethChartTeeth[toothInList].uneruptedReason = Number(reason);
    this.teethChartTeeth[toothInList].notes = this.updateUneruptedReasonNote(this.teethChartTeeth[toothInList], false);
    this.setTeeth(_.sortBy(this.teethChartTeeth, ['toothNumber']));
  }

  @action
  onDeleteTooth = (toothNum: number) => {    
    let index = _.findIndex(this.teethChartTeeth, (t: TeethChartTooth) => {
      return t.toothNumber === toothNum;
    })
    if (index >= 0) {
      if (this.teethChartTeeth[index].id > 0) {
        this.teethChartTeeth[index].isActive = false;
        this.teethChart.teethChartTeeth = this.teethChartTeeth;
        this.deletedTeeth.push(this.teethChartTeeth[index]);
        this.setId(this.teethChart.id);
        this.setNotes(this.teethChart.notes);
        this.setConditionsOnTeeth();
        var sortedTeeth = _.sortBy(this.teethChart.teethChartTeeth, ['toothNumber']);                
        this.setTeeth(_.filter(sortedTeeth, (t) => { return t.isActive === true }));
                
      } else {
        this.teethChartTeeth.splice(index, 1);
      }
    }
  }

  @action
  onToothNoteChange = (event: any, toothNum: number) => {
    let index = _.findIndex(this.teethChartTeeth, (t: TeethChartTooth) => {
      return t.toothNumber === toothNum;
    })

    this.teethChartTeeth[index].notes = event.target.value;
    this.setTeeth(_.sortBy(this.teethChartTeeth, ['toothNumber']));
  }

  @action
  onChangeMissingReason = (tooth: TeethChartTooth, value) => {
    tooth.missingReason = value;

    var toothInList = _.findIndex(this.teethChartTeeth, (t) => {
      return (t.id === tooth.id && t.toothNumber === tooth.toothNumber)
    });

    if (value === MISSINGREASONIDUNERUPTED) {
      this.setCurrentToothNum(tooth.toothNumber);
      this.setOpenUneruptedModal(true);
    } else {
      tooth.notes = this.updateUneruptedReasonNote(tooth, true);
    }
    this.teethChartTeeth[toothInList] = tooth;
    this.setTeeth(_.sortBy(this.teethChartTeeth, ['toothNumber']));
  }

  @action
  onChangePresentConditions = (tooth: TeethChartTooth, values) => {
    var toothInList = _.findIndex(this.teethChartTeeth, (t) => {
      return (t.id === tooth.id && t.toothNumber === tooth.toothNumber)
    });
    if (values && values.length > 0) {
      values = _.filter(values, (v) => {
        return (v !== PRESENTCONDITIONIDMIN);
      });
    }

    tooth.presentConditionsArray = values.length === 0 ? [PRESENTCONDITIONIDMIN] : values;
    this.teethChartTeeth[toothInList].presentConditionsArray = tooth.presentConditionsArray;
    console.log('Updated conditions for tooth:', this.teethChartTeeth[toothInList]);
    this.setTeeth(_.sortBy(this.teethChartTeeth, ['toothNumber']));
    
    const updatedTeethChart = [...this.teethChartTeeth]; // Clone the array
    updatedTeethChart[toothInList] = {
      ...updatedTeethChart[toothInList], // Clone the object
      presentConditionsArray: tooth.presentConditionsArray, // Update the property
    };
    this.teethChartTeeth = updatedTeethChart; // Trigger re-render

  }

  @action
  openSupernumeraryModel = (open: boolean) => {
    runInAction(() => {
      this.openSupernumeraryModal = open;
    })
  }

  @action
  handleSetNotes = (event: any) => {
    this.setNotes(event.target.value);
  }

  @action
  hasDetails = (toothNum: number): TeethChartTooth | null => {
    if(this.teethChart.teethChartTeeth){
      var tooth = _.find(this.teethChartTeeth, (t) => {
        return t.toothNumber === toothNum;
      });
      return tooth ? tooth : null;
    }
    //return tooth ? tooth : null;
    return null;
  }

  private updateUneruptedReasonNote(tooth: TeethChartTooth, isDelete: boolean) {
    var notes = tooth.notes;

    notes = notes ? notes.replace(UNERUPTEDPOORPATH, "") : "";
    notes = notes ? notes.replace(UNERUPTEDIMPACTED, "") : "";
    notes = notes ? notes.replace(UNERUPTEDEMBEDDED, "") : "";

    if (!isDelete) {
      switch (tooth.uneruptedReason) {
        case UneruptedConditions["Poor Eruption Path"]:
          notes += UNERUPTEDPOORPATH;
          break;
        case UneruptedConditions["Impacted"]:
          notes += UNERUPTEDIMPACTED;
          break;
        case UneruptedConditions["Embedded"]:
          notes += UNERUPTEDEMBEDDED;
          break;
      }
    }
    return notes;
  }

  private updateDeciduousNote(tooth: TeethChartTooth, isUpdate: boolean) {
    var notes = tooth.notes;
    if (tooth.notes.indexOf(DECIDUOUS) >= 0) {
      if (!isUpdate) {
        notes = "";
      } else {
        notes = notes.replace(DECIDUOUS, "");
      }
    } else {
      if (tooth.isDeciduous) {
        notes += DECIDUOUS;
      } else {
        notes = notes.replace(DECIDUOUS, "");
      }
    }
    return notes;
  }

  @action
  softValidateTeethChart = (): string[] => {
    let fieldValidationList: string[] = [];

    if (this.teethChartTeeth.length === 0) {
      fieldValidationList.push("Please make sure there's nothing to mark.");
    }

    return fieldValidationList;
  }

  @action
  handleNumberingSystemClick = () => {
    switch (this.currentNumberingSystem) {
      case NumberingSystem.UNIVERSAL:
        this.setCurrentNumberingSystem(NumberingSystem.PALMER);
        break;
      case NumberingSystem.PALMER:
        this.setCurrentNumberingSystem(NumberingSystem.FDI);
        break;
      case NumberingSystem.FDI:
        this.setCurrentNumberingSystem(NumberingSystem.UNIVERSAL);
        break;
    }
  }

  @action
  handleTeethSetClick = () => {    
    switch (this.currentTeethSet) {
      case TeethSet.DECIDUOUS:
        this.setCurrentTeethSet(TeethSet.ADULT);
        break;
      case TeethSet.ADULT:
        this.setCurrentTeethSet(TeethSet.ALL);
        break;
      case TeethSet.ALL:
        this.setCurrentTeethSet(TeethSet.DECIDUOUS);        
        break;
    }
  }


  @action
  getDeciduousToothIndex = (index: number) => {

    if (this.currentNumberingSystem === NumberingSystem.UNIVERSAL) {
      if (index <= 13) {
        return (index + 6).toString(36).toUpperCase();
      } else {
        return (index).toString(36).toUpperCase();
      }
    }
    else if (this.currentNumberingSystem === NumberingSystem.PALMER) {
      if (index <= 8) {
        var palmerNum = index;
        switch (index) {
          case 8:
            palmerNum = 4;
            break;
          case 7:
            palmerNum = 5;
            break;
          case 6:
            palmerNum = 6;
            break;
          case 5:
            palmerNum = 7;
            break;
          case 4:
            palmerNum = 8;
            break;
        }
        //console.log("Received index:" + index + " palmerNum:" + (palmerNum + 6) + " return: " + (palmerNum + 6).toString(36).toUpperCase());
        return (palmerNum + 6).toString(36).toUpperCase();
      }
      if (index >= 9 && index <= 16) {
        //console.log("Received index:" + index + " palmerNum:" + (index - PALMERUROFFSET + 9) + " return: " + (index - PALMERUROFFSET + 9).toString(36).toUpperCase());
        return (index - PALMERUROFFSET + 9).toString(36).toUpperCase();
      }
      if (index >= 17 && index <= 24) {
        var num = index;
        switch (index) {
          case 17:
            num = 8;
            break;
          case 18:
            num = 7;
            break;
          case 19:
            num = 6;
            break;
          case 20:
            num = 5;
            break;
          case 21:
            num = 4;
            break;
          case 22:
            num = 3;
            break;
          case 23:
            num = 2;
            break;
          case 24:
            num = 1;
            break;
        }
        //console.log("Received index:" + index + " palmerNum:" + (num + 9) + " return: " + (num + 9).toString(36).toUpperCase());
        return (num + 9).toString(36).toUpperCase();
      }
      if (index >= 25 && index <= 32) {
        //console.log("Received index:" + index + " palmerNum:" + (index - PALMERBLOFFSET + 9) + " return: " + (index - PALMERBLOFFSET + 9).toString(36).toUpperCase());
        return (index - PALMERBLOFFSET + 9).toString(36).toUpperCase();
      }
    }
    else {
      if (index <= 8) {
        return (FDITOPLEFTOFFSET - index) + 40;
      }
      if (index >= 9 && index <= 16) {
        return (FDITOPRIGHTOFFSET + index) + 41;
      }
      if (index >= 17 && index <= 24) {
        var fdiNum = index;
        switch (index) {
          case 17:
            fdiNum = 38;
            break;
          case 18:
            fdiNum = 37;
            break;
          case 19:
            fdiNum = 36;
            break;
          case 20:
            fdiNum = 35;
            break;
          case 21:
            fdiNum = 34;
            break;
          case 22:
            fdiNum = 33;
            break;
          case 23:
            fdiNum = 32;
            break;
          case 24:
            fdiNum = 31;
            break;
          default:
            return index;
        }
        return fdiNum + 40;
      }
      if (index >= 25 && index <= 32) {
        return (FDIBOTTOMLEFTOFFSET + index) + 40;
      }
    }
  }

  @action
  getIndexByNumberingSystem = (index: number) => {
    if (this.currentNumberingSystem === NumberingSystem.UNIVERSAL) {
      return index;
    }
    else if (this.currentNumberingSystem === NumberingSystem.PALMER) {
      if (index <= 8) {
        switch (index) {
          case 1:
            return 8;
          case 2:
            return 7;
          case 3:
            return 6;
          case 4:
            return 5;
          case 5:
            return 4;
          case 6:
            return 3;
          case 7:
            return 2;
          case 8:
            return 1;
        }
      }
      if (index >= 9 && index <= 16) {
        return index - PALMERUROFFSET;
      }
      if (index >= 17 && index <= 24) {
        switch (index) {
          case 17:
            return 8;
          case 18:
            return 7;
          case 19:
            return 6;
          case 20:
            return 5;
          case 21:
            return 4;
          case 22:
            return 3;
          case 23:
            return 2;
          case 24:
            return 1;
        }
      }
      if (index >= 25 && index <= 32) {
        return index - PALMERBLOFFSET;
      }
    }
    else {
      if (index <= 8) {
        return FDITOPLEFTOFFSET - index;
      }
      if (index >= 9 && index <= 16) {
        return FDITOPRIGHTOFFSET + index;
      }
      if (index >= 17 && index <= 24) {
        switch (index) {
          case 17:
            return 38;
          case 18:
            return 37;
          case 19:
            return 36;
          case 20:
            return 35;
          case 21:
            return 34;
          case 22:
            return 33;
          case 23:
            return 32;
          case 24:
            return 31;
        }
      }
      if (index >= 25 && index <= 32) {
        return FDIBOTTOMLEFTOFFSET + index;
      }
    }
  }
}
