// Vendors
import { FC } from 'react';
import { Grid } from '@mui/material';

// Components
import { DiagnosisFromCBCT } from './DiagnosisFromCBCT';
import { ExtraoralObjectives } from './ExtraoralObjectives';
import { IntraoralObjectives } from './IntraoralConditions';
import { SleepObjectives } from './SleepObjectives';
import { TreatmentObjectives } from './TreatmentObjectives';

// Store
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

interface ITreatmentObjectivesPage {
    patient?: any;
}

export const TreatmentObjectivesPage: FC<ITreatmentObjectivesPage> = observer(({ patient }) => {

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={6}>
                <TreatmentObjectives />
            </Grid>
        </Grid>
    );
});
