import { action, makeObservable, observable, computed } from 'mobx';

import Team from '../../entities/Team';
import { MainStore } from './MainStore';
import { Gateway } from '../../api/Gateway';
import User from '../../entities/UserMV';

export class TeamAdminStore {
    mainStore: MainStore;
    constructor(mainstore: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
    }

    @observable toggleShowSearch: boolean = false;
    @observable toggleShowFilterResults: boolean = false;
    @observable isLeftNavExpanded: boolean = false;
    @observable pageTitle = 'Team Administration';
    @observable open: boolean = false;
    @observable teamsData: Team[] = [];
    @observable _availableUserData: User[] = [];
    @observable _toggleShowNewTeamModal: boolean = false;

    @action setTeamsData = (value: any) => {
        this.teamsData = value;
    };

    @computed
    get availableUserData(): User[] {
        return this._availableUserData;
    }

    @action setAvailableUser = (value: any) => {
        this._availableUserData = value;
    };

    @action setOpen = (newVal: boolean) => (this.open = newVal);
    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    @action toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };

    @computed
    get toggleShowNewTeam() {
        return this._toggleShowNewTeamModal;
    }

    @action
    toggleShowNewTeamModal = () => {
        this._toggleShowNewTeamModal = !this.toggleShowNewTeam;
    };

    @action
    loadData = (teamtype: number) =>{
        Gateway.get('teams/type/' + teamtype)
            .then(respData => {
                this.teamsData = respData;
            })
            .finally(() => {});
    };
}
