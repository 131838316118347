// Vendors
import React, { FC } from 'react';

// Components
import { ModalContainer } from '../../components/ModalContainer';
import { VisitRecord } from './VisitRecord';
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    visit?: any;
    patient?: any;
}

export const VisitModal: FC<IProps> = ({ isOpen, patient, visit, onClose }) => {
    const { resetVisitRecord } = useMainStoreContext().aiReportOrder;

    return (
        <ModalContainer
            show={isOpen}
            onClose={() => {
                onClose();
                resetVisitRecord();
            }}
            title={`Visit Record - ${patient?.firstName} ${patient?.lastName} - ${patient?.vivosId}`}

            onClick={() => {
                onClose();
                resetVisitRecord();
            }}
        >
            <VisitRecord patient={patient} visit={visit} onClose={onClose} />
        </ModalContainer >
    );
};
