// Vendors
import * as react from 'react';
import { FC, useEffect, useState } from "react";
import {
    Box,
    Grid,
    styled,
    Typography,
    Avatar,
    Card,
    Tabs,
    Tab
} from "@mui/material";
import jwt_decode from 'jwt-decode';

// Components
import { Oval } from 'react-loader-spinner';
import Label from '../../../components/UI/Label';

// Entities
import Patient from "../../../entities/Patient";
import { Gender, Ethnicity } from "../../../entities/Enums";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react";
import moment from "moment";
import * as _ from 'lodash';
import { ProviderTabs } from '../ProviderTabs';

const RootWrapper = styled(Box)(
    ({ theme }) => `
          background: ${theme.colors.gradients.black2};
          border-radius: ${theme.general.borderRadius};
  `
);


const TabsContainerWrapper = styled(Box)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      justifyContent: 'space-evenly',
    `
);

const LabelBASIC = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.warning.main};
      color: ${theme.palette.warning.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

const LabelVIP = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.success.main};
      color: ${theme.palette.success.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);
const LabelGGD = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);
const LabelLIFELINE = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.info.main};
      color: ${theme.palette.info.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

const LabelLincare = styled(Box)(
    ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.grey[900]};
      color: ${theme.palette.info.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadius};
    `
);

interface HeaderProps {
    provider: any;
    tabs: any[];
    activeTab: number;
    handleTabChange: any;
    isLoading: boolean;
}

const ProviderHeaderLayout: FC<HeaderProps> = (props: HeaderProps) => {
    const [providerTypes, setProviderTypes] = useState<any[]>([]);
    let decodedToken: any = [];

    const store = useMainStoreContext().providerStore;

    useEffect(() => {
        store.loadProfileImage();
        var token = sessionStorage.getItem('token');
        if (token) {
            decodedToken = jwt_decode(token);
            setProviderTypes(JSON.parse(decodedToken.providerTypes));
        }
    }, []);

    return (
        props.isLoading ? (
            <Grid style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        ) :
            <RootWrapper>
                <Grid container paddingX={4} paddingTop={2}>
                    {/* Profile Image and Details */}
                    <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'right' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flex: 2,
                                alignItems: 'center',
                                height: { xs: 'auto', xl: 175 }
                            }}
                        >
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: { xs: '5vw', sm: '6vw', md: '7vw', lg: '8vw', xl: 175 },
                                    height: { xs: '5vw', sm: '6vw', md: '7vw', lg: '8vw', xl: 175 },
                                    border: 2,
                                    borderColor: 'white',
                                    alignSelf: 'center'
                                }}
                                src={props.provider.imageProfileUrl}
                            />
                            <Grid container direction={'column'} paddingLeft={2}>
                                <Typography variant="h5" color={'white'}>
                                    {props.provider.firstName}
                                </Typography>
                                <Typography gutterBottom variant="h3" color={'white'}>
                                    {props.provider.lastName}
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>

                    {/*Provider Type Labels*/}
                    <Grid item xs={12} md={6} container justifyContent={'flex-end'} spacing={1}>
                        <Grid item>
                            {providerTypes.map((providerType) => {

                                if (providerType.includes('Basic')) {
                                    return <LabelBASIC>Basic</LabelBASIC>
                                } else
                                    if (providerType.includes('VIP')) {
                                        return <LabelVIP> Vivos Integrated Provider </LabelVIP>;
                                    } else {
                                        if (providerType.includes('Guided') || providerType.includes('GGD')) {
                                            return <LabelGGD>Guided Growth & Development</LabelGGD>
                                        } else
                                            if (providerType.includes('Lifeline')) {
                                                return <LabelLIFELINE>Lifeline</LabelLIFELINE>
                                            } else {
                                                return <LabelLincare>{providerType}</LabelLincare>
                                            }
                                    }
                            })}
                        </Grid>
                    </Grid>

                    {/* Tabs Bar */}
                    <Grid item xs={12}>
                        <TabsContainerWrapper sx={{ py: { xs: .5, sm: 1, md: 1, lg: 2 } }}>
                            <Card
                                sx={{ py: { xs: .25, sm: .5, md: .5, lg: 1 } }}
                            >
                                <Grid container justifyContent="space-evenly">
                                    <Tabs
                                        onChange={props.handleTabChange}
                                        value={props.activeTab}
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                    >
                                        {_.map(props.tabs, (t, key: number) => (
                                            <Tab
                                                key={key}
                                                label={props.tabs[key]}
                                                sx={{
                                                    fontSize: { xs: '0.5rem', sm: '.6rem', md: '.7rem', lg: '.8rem', xl: '1rem' }
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </Grid>
                            </Card>
                        </TabsContainerWrapper>
                    </Grid>
                </Grid>
            </RootWrapper>
    )
}

export default observer(ProviderHeaderLayout);