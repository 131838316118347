import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Grid, Button, IconButton } from '@mui/material';
import { FileUploader } from "react-drag-drop-files";
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import _ from "lodash";
import DictionaryListItem from "../../entities/DictionaryListItem";
import { StlViewer } from "react-stl-viewer";
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import { GlobalUtils } from "../../api/GlobalUtils";
import EditRotateCropper from "./EditRotateCropper";
import "../../scss/_AIReportEntry.scss"
import { FolderZip } from "@mui/icons-material";
import { Link } from "react-router-dom";

interface IUploaderProps {
    images: any[];
    imageTypes?: DictionaryListItem[];
    vivosId: string;
    visitId: number;
    isEditable: boolean;
    maxheight?: number;
    maxwidth?: number;
}
const MAXWIDTH = 250;
const MAXHEIGHT = 200;

const UploadImgViewer: React.FC<IUploaderProps> =
    ({ images, imageTypes, vivosId, visitId, isEditable, maxheight, maxwidth }) => {
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
        const [key, setKey] = React.useState(0);

        const { patient, currentVisit: visit, getTypeFromName } = useMainStoreContext().aiReportEntryStore;

        useEffect(() => {
            //setHasLoaded(true);
        }, [])

        useEffect(() => {
            if (patient.vivosId != "" && visit.id > 0) {
                setHasLoaded(true);
            }

            if (visitId > 0 && vivosId != "") {
                setHasLoaded(true);
            }
        }, [visitId, vivosId])


        const style = {
            top: 0,
            left: 0,
            width: maxwidth ? maxwidth : MAXWIDTH,
            height: maxheight ? maxheight : MAXHEIGHT,
            border: '3px solid $brand_darkergrey',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px'

        }

        const handleRemove = (index: number) => {
            images.splice(index, 1);
            // setImages(images);
        }

        return (
            hasLoaded ?
                <Grid xs={12} className="formBasic uploadArea">
                    <Grid xs={12} container direction="row" alignItems={"center"} justifyContent={"flex-start"} justifyItems="flex-start">
                        <Grid xs={12} container direction="row" className="viewImageContainer">
                            {
                                _.map(images, (img, i) => {
                                    return <Grid xs={4} item >
                                        {(img.data64Url && (img.type == "2D" || img.type == "appliance")) &&
                                            <Grid>
                                                <Grid item >
                                                    <EditRotateCropper
                                                        key={key + i}
                                                        index={key + i}
                                                        cropperIndex={i}
                                                        getCropDataCallBack={() => { }}
                                                        handleRemove={() => { handleRemove(i) }}
                                                        handleOptionSelect={() => { }}
                                                        typeOptions={imageTypes ? imageTypes : []}
                                                        selectedOptions={[]}
                                                        imageOption={(img && img.imageType) ? img.imageType.id : -1}
                                                        images={images}
                                                        isEditable={isEditable}
                                                        vivosId={patient.vivosId ? patient.vivosId : vivosId}
                                                        container={process.env.REACT_APP_VISITRECORD_CONTAINER as string}
                                                        containerId={visit.id ? visit.id : visitId}
                                                        setImages={() => { }} />
                                                </Grid>
                                                {/* <Grid className="viewImgLabel">{img.imageType ? img.imageType.name : ""}</Grid> */}
                                            </Grid>
                                        }
                                        {(img.value && img.type == "3D") &&
                                            <Grid container direction="column" className="stlviewer">
                                                <Grid><StlViewer style={style} orbitControls shadows url={GlobalUtils.getVisitRecordUrl(img.value, patient.vivosId, visit.id)} /></Grid>
                                                <Grid className="viewImgLabel">{getTypeFromName(img.key)?.name}</Grid>
                                            </Grid>
                                        }
                                        {(img.value && img.type == "zip") &&
                                            <Button href={img.value} target="_blank">
                                                <Grid>
                                                <Grid item><FolderZip className="uploadImg shadow" /></Grid>                                                
                                                <Grid className="viewImgLabel">{"CBCT Scan"}</Grid>
                                                </Grid>
                                            </Button>                                        
                                        }
                                        {(!img.data64Url && img.type == "2D") &&
                                            <Grid>
                                                <Grid item>
                                                    <Grid item  ><FileUploader children={
                                                        <Grid className="dragNDrop shadow" sx={{ width: "250px" }}>
                                                            <IconButton><PhotoCameraIcon className="uploadImg" /></IconButton>
                                                            <Grid item>Drag & Drop Image or</Grid>
                                                            <Grid item>Click to Select Image</Grid>
                                                        </Grid>
                                                    } /></Grid></Grid>
                                                <Grid className="viewImgLabel">{getTypeFromName(img.key)}</Grid>
                                            </Grid>
                                        }
                                        {(!img.value && img.type == "3D") &&
                                            <Grid>
                                                <Grid item>
                                                    <Grid item><FileUploader children={
                                                        <Grid className="dragNDrop shadow" sx={{ width: "250px" }}>
                                                            <IconButton><ThreeDRotationIcon className="uploadImg" /></IconButton>
                                                            <Grid item>Drag & Drop Image or</Grid>
                                                            <Grid item>Click to Select Image</Grid>
                                                        </Grid>} /></Grid></Grid>
                                                <Grid className="viewImgLabel">{getTypeFromName(img.key)}</Grid>
                                            </Grid>
                                        }
                                        {(!img.value && img.type == "zip") &&
                                            <Grid>
                                                <Grid item>
                                                    <Grid item><FileUploader children={
                                                        <Grid className="dragNDrop shadow" sx={{ width: "250px" }}>
                                                            <IconButton><FolderZipIcon className="uploadImg" /></IconButton>
                                                            <Grid item>Drag & Drop Image or</Grid>
                                                            <Grid item>Click to Select Image</Grid>
                                                        </Grid>} /></Grid></Grid>
                                                <Grid className="viewImgLabel">{getTypeFromName(img.key)}</Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                })
                            }
                        </Grid>
                    </Grid>

                </Grid> : null
        )
    }

export default observer(UploadImgViewer);