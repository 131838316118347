import { AppBar, Badge, Box, Drawer, Grid, IconButton, Menu, Popover, Toolbar, Typography } from "@mui/material";
import { LeftNav } from "../../components/LeftNav/LeftNav";
import { useEffect, useState, Fragment } from "react";
import { GridHeader } from "../../components/GridHeader/GridHeader";
import Products from "../../components/shopify/Products";
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import { ShoppingCart } from "@material-ui/icons";
import Cart from "../../components/shopify/Cart";
import React from "react";
import { observer } from "mobx-react";
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from "./components/PageHeader";
import ProductFiltering from "../../components/shopify/ProductFiltering";
import Footer from "../../components/UI/Footer";

const Catalog = () => {
	const [isLeftNavExpanded, setIsLeftNavExpanded] = useState(false);
	// const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

	const toggleLeftNav = () => {
		setIsLeftNavExpanded(!isLeftNavExpanded);
	};

	const defaultPage = "CATALOG";
	const { client, setProducts, setCartOpen, isCartModalOpen, setCartModalOpen, cartCount, fetchProducts } = useMainStoreContext().shopifyStore;

	useEffect(() => {
		if (client)
			fetchProducts().then((products) => {
				setProducts(products);
				setCartOpen(true);
			});
		/**
		client.product.fetchQuery({first: 100, query: 'title:*VGx*'}).then((products) => {
			console.log(products);
		}); */
	}, []);

	return (
		<Fragment>
			<PageTitleWrapper>
				<PageHeader />
			</PageTitleWrapper>
			<Grid
				sx={{
					px: 4
				}}
				container
				direction="row"
				justifyContent="center"
				alignItems="stretch"
				spacing={4}
			>
				{/* <Grid
					item
					xs={12}
					md={3}
					sx={{
						display: { xs: 'none', md: 'block' }
					}}
				>
					<ProductFiltering />
				</Grid> */}
				<Grid item xs={12}>
					<Products />
				</Grid>
			</Grid>
			<Footer />
		</Fragment>
	);
};

export default observer(Catalog);