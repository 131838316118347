// Vendors
import react, { FC, useState } from 'react';
import {
    Grid,
    styled,
    List,
    ListItem,
    ListItemText,
    alpha,
    Typography,
    Box,
    Button,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,

} from '@mui/material';

// Components
import SectionContainer from '../../../../UI/Containers/SectionContainer';
import { EstimatedTime } from './EstimatedTreatment';

// Store
import { useMainStoreContext } from '../../../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import moment from "moment";
import * as _ from "lodash";
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';
import DateFormatUS from '../../../../formatters/dateFormatUS';

interface IDiagnosisPage {
    patient?: any;
}

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

export const ReviewTreatmentPlan: FC<IDiagnosisPage> = observer(() => {
    const { treatmentPlan } = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;

    function  calculateTreatmentPlanMonths() {
        
        let duration = 0;
        if(treatmentPlan!=null && treatmentPlan.treatmentPlanPhases!=null){
            try{
                var startDate = moment(treatmentPlan.treatmentPlanPhases[0].startDate);
                var endDate = moment(treatmentPlan.treatmentPlanPhases[0].endDate);
                if(treatmentPlan.treatmentPlanPhases?.length>1){
                    endDate = moment(treatmentPlan.treatmentPlanPhases[treatmentPlan.treatmentPlanPhases.length-1].endDate);
                    //duration = moment(treatmentPlan.treatmentPlanPhases[treatmentPlan.treatmentPlanPhases.length-1].endDate).diff(treatmentPlan.treatmentPlanPhases[0].startDate);                
                    //return moment.duration(moment(treatmentPlan.treatmentPlanPhases[treatmentPlan.treatmentPlanPhases.length-1].endDate).diff(treatmentPlan.treatmentPlanPhases[0].startDate)).months;
                }
                endDate = endDate.add(1, 'd');
                duration = endDate.diff(startDate);
            }
            catch{

            }
        }
        if(duration>0){
            return (moment.duration(duration).months());
        }
        
        return 0;         
    }

    return (
        <Grid>
            <SectionContainer
                sectionTitle={'Treatment Plan'}
                cardContentHeight={'500px'}
                sectionComponent={
                    <Grid>
                        <ListWrapper>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Grid container alignContent={'center'}>
                                            <Grid item xs={6}>
                                                {/* <Typography variant="h4">
                                                    Treatment Plan Review
                                                </Typography> */}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h6" textAlign={'right'}>
                                                    Estimated Treatment Time (in months): &nbsp;
                                                    {calculateTreatmentPlanMonths()}
                                                </Typography>
                                            </Grid>

                                        </Grid>
                                    }
                                    secondary={
                                        <Grid container>
                                            {treatmentPlan.treatmentPlanPhases.map((p, i, a) => {
                                                return (
                                                    <Grid item xs={12}>
                                                        <Box mt={0.7} display="flex" alignItems="center">
                                                            <Grid container>
                                                                <Grid item xs={12}><Typography>Phase {i}</Typography></Grid>
                                                                <Grid item xs={12}>
                                                                    <Table>
                                                                        <TableHead>
                                                                            <TableCell>Plan Component</TableCell>
                                                                            <TableCell>Starting Date</TableCell>
                                                                            <TableCell>Ending Date</TableCell>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {p.treatmentPlanPhasesApplianceTypes.map((ap, y, arr) => {
                                                                                return (
                                                                                    <TableRow>
                                                                                        <TableCell>{ap.applianceType.name}</TableCell>
                                                                                        <TableCell><DateFormatUS date={p.startDate}/></TableCell>
                                                                                        <TableCell><DateFormatUS date={p.endDate}/></TableCell>
                                                                                    </TableRow>
                                                                                )
                                                                            })}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Grid>
                                                            </Grid>

                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                            }
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        </ListWrapper>
                    </Grid>
                }
            />
        </Grid>
    );
});
