// Vendors
import { FC, useEffect, useState } from "react";
import {
    Button,
    Box,
    Stepper,
    Step,
    StepLabel,
    Typography,
    CircularProgress,
} from "@mui/material";

// Components
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";

// Store
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react";
import { Gateway } from "../../api/Gateway";

interface IProps {
    patientId: number;
}

const MyoCorrectEnrollment: FC<IProps> = (props: IProps) => {
    const { setCurrentPatient, step, setStep, loadMyoCorrectTherapyPlans } = useMainStoreContext().myoCorrectEnrollmentStore;
    const {client, fetchProducts,setCartOpen, setProducts } = useMainStoreContext().shopifyStore;
    const steps = ['Verify Information', 'MyoSync Enrollment', 'Final Review'];

    useEffect(() => {
        Gateway.get('/patient/' + props.patientId).then((res) => {
            setCurrentPatient(res as any);
        });

        loadMyoCorrectTherapyPlans();

        if (client) {
			fetchProducts().then((products) => {
				//var product = products.find(x => x.id.toString().includes(selectedPlanECom?.ecommerceItem.ecommerceId.toString()));
				setCartOpen(true);
                setProducts(products);
				//setProductSelected(product);
			});
		}
    }, []);

    return (
        <Box>
            <Stepper activeStep={step}
                alternativeLabel={true}
            >
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                            {/* <StepConnector className="line"></StepConnector> */}
                        </Step>
                    );
                })}
            </Stepper>

            {step === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className='vivButton large' onClick={() => setStep(0)}>Reset</Button>
                    </Box>
                </>
            ) : (false ?
                <>
                    <Box sx={{ width: '100%', height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} />
                    </Box>
                </>
                :
                <>
                    {step === 0 && <StepOne />}
                    {step === 1 && <StepTwo />}
                    {step === 2 && <StepThree />}
                </>
            )}
        </Box>
    )
}
export default observer(MyoCorrectEnrollment);