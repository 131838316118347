import { Grid, MenuItem } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import CssSelectField from "../../CssSelectField";
import { MyoCorrectSessionStatus } from "../../../entities/Enums";
import AppointmentsBL from "../AppointmentsBL";
import Label from "../../UI/Label";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

interface ScheduledStatusProps {
	params: GridRenderCellParams,
	setCurrentRow: any,
	handleScheduleStatusChange: any
	gridBL: AppointmentsBL;
}

export const ScheduledStatus = ({ params, setCurrentRow, handleScheduleStatusChange, gridBL }: ScheduledStatusProps) => {
	const myoCorrectStore = useMainStoreContext().myoCorrectStore;
	return (
		<Grid>
			<CssSelectField
				id={"myocorrecttherapist.dashboard.cell.scheduleStatus." + params.row.ID}
				sx={{ border: "0px" }}
				defaultValue={params.row.scheduleStatus}
				//disabled={gridBL.availableToSchedule(params.row) || params.row.scheduleDateTime === null || Moment().isBefore(Moment.utc(params.row.scheduleDateTime).toDate())}
				//disabled={gridBL.availableToSchedule(params.row) || myoCorrectStore.isProvider}
				disabled={gridBL.availableToSchedule(params.row) || !myoCorrectStore.isMyoCorrectAdmin}
				value={params.row.scheduleStatus}
				onChange={(event) => {
					setCurrentRow(params.row);
					handleScheduleStatusChange(params.row, event.target.value)
				}}
			>
				<MenuItem key={0} value={0}>{"Select a Status..."}</MenuItem>
				<MenuItem key={1} value={MyoCorrectSessionStatus[35]}><Label color={'warning'}>{MyoCorrectSessionStatus[35]}</Label></MenuItem>
				<MenuItem key={2} value={MyoCorrectSessionStatus[36]}><Label color={'primary'}>{MyoCorrectSessionStatus[36]}</Label></MenuItem>
				<MenuItem key={3} value={MyoCorrectSessionStatus[37]}><Label color={'primary'}>{MyoCorrectSessionStatus[37]}</Label></MenuItem>
				<MenuItem key={5} value={MyoCorrectSessionStatus[33]}><Label color={'success'}>{MyoCorrectSessionStatus[33]}</Label></MenuItem>
				<MenuItem key={4} value={MyoCorrectSessionStatus[34]}><Label color={'error'}>{MyoCorrectSessionStatus[34]}</Label></MenuItem>
				<MenuItem key={6} value={MyoCorrectSessionStatus[32]}><Label color={'error'}>{MyoCorrectSessionStatus[32]}</Label></MenuItem>
			</CssSelectField>
		</Grid>)
}