import { observable } from 'mobx';
import { Appliance } from './appliance';
import { DictionaryListItem } from './dictionarylistitem';
import { Order } from './order';
import Patient from './patient';
import PatientVisit from './patientVisit';
import { ReworkClaim } from './reworkClaim';
import { Lab } from './lab';

export class Prescription {
    @observable id: number = 0;
    @observable patientId: number = 0;
    @observable providerId: number = 0;
    @observable assignedAdmin: number = 0;
    @observable applianceId: number = 0;
    @observable assignedDate: Date | null = null;
    @observable rxId: string = '';
    @observable submittedDate: Date | null = null;
    @observable labReceivedDate: Date | null = null;
    @observable neededByDate: Date | null = null;
    @observable diagramUrl: string = '';
    @observable prescriptionUrl: string = '';
    @observable rxStatus: number = 0;
    @observable qcstatus: number = 0;
    @observable latestQcstatus: Date = new Date();
    @observable qcnotes: string = '';
    @observable qcchecklist: number | null = null;
    @observable upperArchUrl: string = '';
    @observable lowerArchUrl: string = '';
    @observable constructedBiteUrl: string = '';
    @observable pexDesignFileUrl: string = '';
    @observable modelStatus: number = 0;
    @observable modelDateReceived: Date | null = null;
    @observable physicalToDigitalStatus: number = 0;
    @observable convertedToDigitalDate: Date | null = null;
    @observable digitalDesignStatus: number | null = null;
    @observable digitalDateDesignCompleted: Date | null = null;
    @observable labModelStatus: number | null = null;
    @observable labModelReceived: Date | null = null;
    @observable labBiteStatus: number | null = null;
    @observable labBiteReceived: Date | null = null;
    @observable productionStatus: number = 0;
    @observable estimateShippedDate: Date | null = null;
    @observable fabricationStartDate: Date | null = null;
    @observable trackingNo: string = '';
    @observable shippedDate: Date | null = null;
    @observable deliveredDate: Date | null = null;
    @observable shippingStatus: number | null = null;
    @observable labCaseNotes: string = '';
    @observable labId: number | null = null;
    @observable shippingIdentificationNumber: string = '';
    @observable migrationId: number | null = null;
    @observable orderId: number | null = null;
    @observable createdOn: Date = new Date();
    @observable createdBy: number = 0;
    @observable modifiedOn: Date | null = null;
    @observable modifiedBy: number | null = null;
    @observable isActive: boolean | null = null;
    @observable isLincarePrescription: boolean = false;
    @observable isLincareApproved: boolean | null = null;
    @observable lincareApprovalCode: string = '';
    @observable constructionComments: string = '';
    @observable visitId: number | null = null;
    @observable fabricationTime: number | null = null;
    // Add other properties as needed

    @observable appliance: Appliance | null = null;
    /* @observable assignedAdminNavigation: User | null = null; */
    @observable digitalDesignStatusNavigation: DictionaryListItem | null = null;
    @observable lab: Lab | null = null;
    @observable labBiteStatusNavigation: DictionaryListItem | null = null;
    @observable modelStatusNavigation: DictionaryListItem | null = null;
    @observable order: Order | null = null;
    @observable orders: Order[] = [];
    @observable patient: Patient | null = null;
    @observable physicalToDigitalStatusNavigation: DictionaryListItem | null = null;
    @observable productionStatusNavigation: DictionaryListItem | null = null;
    /* @observable provider: Provider | null = null; */
    @observable qcstatusNavigation: DictionaryListItem | null = null;
    @observable reworkClaims: ReworkClaim[] = [];
    @observable rxStatusNavigation: DictionaryListItem | null = null;
    @observable shippingStatusNavigation: DictionaryListItem | null = null;
    @observable visit: PatientVisit | null = null;
    @observable connectedRxRework: string = '';
    @observable markedReadyDate: Date | null = null;
}