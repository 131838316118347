import { Ethnicity } from '../entities/Enums';
import { Gateway } from './Gateway';
import * as _ from 'lodash';
import * as __ from 'lodash-contrib';
import axios from 'axios';
import DictionaryList from '../entities/DictionaryList';
import DictionaryListItem from '../entities/DictionaryListItem';

const MEDICALHX = 'MEDICALHX';
const DENTALHX = 'DENTALHX';
const CPAPMADHX = 'CPAPMADHX';
const ALLERGYHX = 'ALLERGYHX';
const MYOCORRECTSTATUS = 'MYOCORRECTSTATUS';
const THERAPYSTATUSES = 'THERAPYSTATUSES';
const MYOCORRECTTHERAPYSTATUS = 'MYOCORRECTTHERAPYSTATUS';
const AIREPORTSTATUSES = 'AIREPORTSTATUSES';
const ADDONTYPE = 'ADDONTYPE';
const GENDER = 'GENDER';
const ETHNICITY = 'ETHNICITY';
const INTRAORALIMAGES = 'INTRAORALIMAGETYPE';
const EXTRAORALIMAGES = 'EXTRAORALIMAGETYPE';
const GUARDIANRELATIONSHIPTYPE = 'GUARDIANRELATIONSHIPTYPE';
const INTAKEREPORTS = 'INTAKEREPORTS';
const DICTIONARYLISTS = 'DICTIONARYLISTS';
const MISSINGREASONS = 'MISSINGREASONS';
const PRESENTCONDITIONS = 'PRESENTCONDITIONS';
const CBCTMACHINEBRAND = 'CBCTMACHINEBRAND';
const OCCLUSION3DMACHINEBRAND = 'OCCLUSION3DMACHINEBRAND';
const PATIENTOCCLUSION = 'PATIENTOCCLUSION';
const PATIENTIMAGETYPES = 'PATIENTIMAGETYPES';
const AIREPORTPROCESSEDIMAGETYPES = 'AIREPORTPROCESSEDIMAGETYPES';
const SLEEPCONDITIONS = 'SLEEPCONDITIONS';
const EXTRAORALCONDITIONS = 'EXTRAORALCONDITIONS';
const INTRAORALCONDITIONS = 'INTRAORALCONDITIONS';
const CBCTDIAGNOSIS = 'CBCTDIAGNOSIS';
const TREATMENTPLANOPTIONSPRIMARY = 'TREATMENTPLANOPTIONSPRIMARY';
const TREATMENTPLANOPTIONSSUPPLEMENTAL = 'TREATMENTPLANOPTIONSSUPPLEMENTAL';
const TREATMENTPLANOPTIONSDENTAL = 'TREATMENTPLANOPTIONSDENTAL';
const TREATMENTPLANOPTIONSCOLLABORATION = 'TREATMENTPLANOPTIONSCOLLABORATION';
const TREATMENTPLANOPTIONSSURGERY = 'TREATMENTPLANOPTIONSSURGERY';
const TREATMENTPLANOPTIONSOTHER = 'TREATMENTPLANOPTIONSOTHER';
const VIVOSAPPLIANCES = 'VIVOSAPPLIANCES';
const MOLARRELATIONSHIPS = 'MOLARRELATIONSHIPS';
const AIREPORTTYPES = 'AIREPORTTYPES';
const CALENDLYTOPICS = 'CALENDLYTOPICS';
const TREATMENTOBJECTIVES = 'TREATMENTOBJECTIVES';
const CONSULTATIONSTATUS = 'CONSULTATIONSTATUS';
const HEADNECKPROPERTIES = 'HEADNECKPROPERTIES';
const MAPPINGTABLES = 'MAPPINGTABLES';
const PROPERTYTABLES = 'PROPERTYTABLES';
const PRIMARYCATEGORY = 'PRIMARYCATEGORY';
const SECONDARYCATEGORY = 'SECONDARYCATEGORY';
const PRIMARYCATEGORIES = 'PRIMARYCATEGORIES';
const SECONDARYCATEGORIES = 'SECONDARYCATEGORIES';
const VIVOSMEASUREMENTPROPERTIES = 'VIVOSMEASUREMENTPROPERTIES';
const CLAIMSTATUS = 'CLAIMSTATUS';
const PRODUCTIONSTATUS = 'PRODUCTIONSTATUS';
const REWORKREQUESTS = 'REWORKREQUESTS';
const CLAIMREVIEWDOCUMENTTYPE = 'CLAIMREVIEWDOCUMENTTYPE';
const ROLES = 'ROLES';
const PATIENTVISITSTATUS = 'VISITSTATUS';
const ORDERSTATUS = 'ORDERSTATUS';
const ADDRESSTYPE = 'ADDRESSTYPE';
const TEETHSET = 'TEETHSET';
const TEETHNUMBERINGSYSTEM = 'TEETHNUMBERINGSYSTEM';
const LINCARESTATUS = 'LINCARESTATUS';
const GUIDESERIES = 'GUIDESERIES';
const APPLIANCESIZES = 'APPLIANCESIZES';
const APPLIANCECOLORS = 'APPLIANCECOLORS';
const VERTICALTHICKNESS = 'VERTICALTHICKNESS';
const UNILATERALBITEBLOCK = 'UNILATERALBITEBLOCK';
const PRESCRIPTIONSTATUS = 'PRESCRIPTIONSTATUS';
const QCSTATUS = 'QCSTATUS';
const MODELSTATUS = 'MODELSTATUS';
const PHYSICALTODIGITALSTATUS = 'PHYSICALTODIGITALSTATUS';
const DIGITALDESIGNSTATUS = 'DIGITALDESIGNSTATUS';
const LABCLAIMMATERIALMODELSTATUS = 'LABCLAIMMATERIALMODELSTATUS';
const BITESTATUS = 'BITESTATUS';
const SHIPPINGSTATUS = 'SHIPPINGSTATUS';
const WORKINGDIAGNOSIS = 'WORKINGDIAGNOSIS';
const TREATMENTOBJECTIVESLIST = 'TREATMENTOBJECTIVESLIST';
const PREVENTPHASEOPTIONS = 'PREVENTPHASEOPTIONS'
const MANAGEPHASEOPTIONS = 'MANAGEPHASEOPTIONS';
const REHABILITATEPHASEOPTIONS = 'REHABILITATEPHASEOPTIONS';
const SUPLEMENTALTHERAPIESPHASEOPTIONS = 'SUPLEMENTALTHERAPIESPHASEOPTIONS';
const DENTALPHASEOPTIONS = 'DENTALPHASEOPTIONS';
const SURGERYPHASEOPTIONS = 'SURGERYPHASEOPTIONS';
const OTHERPHASEOPTIONS = 'OTHERPHASEOPTIONS';
const REFERRALPHASEOPTIONS = 'REFERRALPHASEOPTIONS';
const CONSTRUCTIONBITEINFORMATION = 'CONSTRUCTIONBITEINFORMATION';
const PATIENTOCCLUSIONTYPE = 'PATIENTOCCLUSIONTYPE';
const CONSTRUCTIONBITETYPE = 'CONSTRUCTIONBITETYPE';
const POTRUSIONPOSITION = 'POTRUSIONPOSITION';
const TEMPORARYINITIALPHASEOPTIONS = 'TEMPORARYINITIALPHASEOPTIONS';
const VIVOSCAREPHASEOPTIONS = 'VIVOSCAREPHASEOPTIONS';
const VIVOSGUIDEPHASEOPTIONS = 'VIVOSGUIDEPHASEOPTIONS';
const TMJBRUXISMPHASEOPTIONS = 'TMJBRUXISMPHASEOPTIONS';
const MANDIBULARADVANCEMENTPHASEOPTIONS = 'MANDIBULARADVANCEMENTPHASEOPTIONS';
const ORTHODONTICSPHASEOPTIONS = 'ORTHODONTICSPHASEOPTIONS';
const OTHERTREATMENTSPHASEOPTIONS = 'OTHERTREATMENTSPHASEOPTIONS';
const APPLIANCESYSTEM_UPPERSYSTEM_BASE = 'APPLIANCESYSTEM_UPPERSYSTEM_BASE';
const APPLIANCESYSTEM_LOWERSYSTEM_BASE = 'APPLIANCESYSTEM_LOWERSYSTEM_BASE';
const APPLIANCESYSTEM_UPPERSYSTEM_BOW = 'APPLIANCESYSTEM_UPPERSYSTEM_BOW';
const APPLIANCESYSTEM_LOWERSYSTEM_BOW = 'APPLIANCESYSTEM_LOWERSYSTEM_BOW';
const APPLIANCESYSTEM_UPPERSYSTEM_MANDIBULARADVANCEMENTOPTION = 'APPLIANCESYSTEM_UPPERSYSTEM_MANDIBULARADVANCEMENTOPTION';
const APPLIANCESYSTEM_UPPERSYSTEM_SCREW = 'APPLIANCESYSTEM_UPPERSYSTEM_SCREW';
const APPLIANCESYSTEM_LOWERSYSTEM_SCREW = 'APPLIANCESYSTEM_LOWERSYSTEM_SCREW';
const MATERIALBASE = 'MATERIALBASE';
const LABCLAIMMATERIALAPPLIANCESTATUS = 'LABCLAIMMATERIALAPPLIANCESTATUS';
const LABCLAIMMATERIALBITESTATUS = 'LABCLAIMMATERIALBITESTATUS';
const REPAIROPTIONS = 'REPAIROPTIONS';
const LABREWORKMATERIALMODELSTATUS = 'LABREWORKMATERIALMODELSTATUS';
const LABREWORKMATERIALBITESTATUS = 'LABREWORKMATERIALBITESTATUS';
const CANCELLATIONREASON = 'CANCELLATION REASON';
const ERFSTATUS = 'ERFSTATUS';
const FINANCIALLIABILITY = 'FINANCIALLIABILITY';
const CLAIMCLASSIFICATIONSUBCATEGORIES = 'CLAIMCLASSIFICATIONSUBCATEGORIES';
const CLAIMADDITIONALCATEGORIZATIONRXWASNOTFOLLOWED = 'CLAIMADDITIONALCATEGORIZATIONRXWASNOTFOLLOWED';
const CLAIMADDITIONALCATEGORIZATIONONFUNCTIONALASSEMBLY = 'CLAIMADDITIONALCATEGORIZATIONONFUNCTIONALASSEMBLY';
const COMPLAINTMETHOD = 'COMPLAINT METHOD';
const COMPLAINTTYPE = 'COMPLAINT TYPE';
const FABRICATIONTIMETYPES = 'FABRICATIONTIMETYPES';

export class GlobalUtils {

    static async getAndCreateOptions(): Promise<any[]> {
        return await Gateway.get('global/lists').then(respData => {
            var medicalhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === MEDICALHX;
            });
            localStorage.setItem(MEDICALHX, JSON.stringify(medicalhxlist));

            var dentalhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === DENTALHX;
            });
            localStorage.setItem(DENTALHX, JSON.stringify(dentalhxlist));

            var cpapMadhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === CPAPMADHX;
            });
            localStorage.setItem(CPAPMADHX, JSON.stringify(cpapMadhxlist));

            var allergyhxlist = _.filter(respData, item => {
                return _.toUpper(item.listKey) === ALLERGYHX;
            });
            localStorage.setItem(ALLERGYHX, JSON.stringify(allergyhxlist));

            var mycorrectStatusesList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === MYOCORRECTSTATUS;
            });
            localStorage.setItem(MYOCORRECTSTATUS, JSON.stringify(mycorrectStatusesList));

            var mycorrectTherapyStatusesList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === MYOCORRECTTHERAPYSTATUS;
            });
            localStorage.setItem(MYOCORRECTTHERAPYSTATUS, JSON.stringify(mycorrectTherapyStatusesList));

            var treatmentStatusesList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === THERAPYSTATUSES;
            });
            localStorage.setItem(THERAPYSTATUSES, JSON.stringify(treatmentStatusesList));

            var aiReportStatusList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === AIREPORTSTATUSES;
            });
            localStorage.setItem(AIREPORTSTATUSES, JSON.stringify(aiReportStatusList));

            var addOnTypeList = _.filter(respData, item => {
                return _.toUpper(item.listKey) === ADDONTYPE;
            });
            localStorage.setItem(ADDONTYPE, JSON.stringify(addOnTypeList));

            var genderList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === GENDER) return item.dictionaryListItems;
            });
            localStorage.setItem(GENDER, JSON.stringify(genderList));

            var ethnicityList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === ETHNICITY) return item.dictionaryListItems;
            });
            localStorage.setItem(ETHNICITY, JSON.stringify(ethnicityList));

            var intraoralimageList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === INTRAORALIMAGES) return item.dictionaryListItems;
            });
            localStorage.setItem(INTRAORALIMAGES, JSON.stringify(intraoralimageList));

            var extraoralimageList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === EXTRAORALIMAGES) return item.dictionaryListItems;
            });
            localStorage.setItem(EXTRAORALIMAGES, JSON.stringify(extraoralimageList));

            var cbctMachineBrandList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CBCTMACHINEBRAND) return item.dictionaryListItems;
            });
            localStorage.setItem(CBCTMACHINEBRAND, JSON.stringify(cbctMachineBrandList));

            var machineBrand3DList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === OCCLUSION3DMACHINEBRAND) return item.dictionaryListItems;
            });
            localStorage.setItem(OCCLUSION3DMACHINEBRAND, JSON.stringify(machineBrand3DList));

            var patientOcclusionList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === PATIENTOCCLUSION) return item.dictionaryListItems;
            });
            localStorage.setItem(PATIENTOCCLUSION, JSON.stringify(patientOcclusionList));

            var patientImageTypesList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === PATIENTIMAGETYPES) return item.dictionaryListItems;
            });
            localStorage.setItem(PATIENTIMAGETYPES, JSON.stringify(patientImageTypesList));

            var relationshiptypeList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === GUARDIANRELATIONSHIPTYPE) return item.dictionaryListItems;
            });
            localStorage.setItem(GUARDIANRELATIONSHIPTYPE, JSON.stringify(relationshiptypeList));

            var missingReasonsList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === MISSINGREASONS) return item.dictionaryListItems;
            });
            localStorage.setItem(MISSINGREASONS, JSON.stringify(missingReasonsList));

            var presendConditionsList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === PRESENTCONDITIONS) return item.dictionaryListItems;
            });
            localStorage.setItem(PRESENTCONDITIONS, JSON.stringify(presendConditionsList));

            var intakeReportsList = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === INTAKEREPORTS) return item.dictionaryListItems;
            });
            localStorage.setItem(INTAKEREPORTS, JSON.stringify(intakeReportsList));

            var processedImageTypes = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === AIREPORTPROCESSEDIMAGETYPES) return item.dictionaryListItems;
            });
            localStorage.setItem(AIREPORTPROCESSEDIMAGETYPES, JSON.stringify(processedImageTypes));

            let sleepConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === SLEEPCONDITIONS) return item.dictionaryListItems;
            });
            localStorage.setItem(SLEEPCONDITIONS, JSON.stringify(sleepConditions));

            let extraoralConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === EXTRAORALCONDITIONS) return item.dictionaryListItems;
            });
            localStorage.setItem(EXTRAORALCONDITIONS, JSON.stringify(extraoralConditions));

            let intraoralConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === INTRAORALCONDITIONS) return item.dictionaryListItems;
            });

            localStorage.setItem(INTRAORALCONDITIONS, JSON.stringify(intraoralConditions));

            let cbctConditions = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CBCTDIAGNOSIS) return item.dictionaryListItems;
            });

            localStorage.setItem(CBCTDIAGNOSIS, JSON.stringify(cbctConditions));

            let treatmentPlanPrimaryTherapy = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSPRIMARY) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTPLANOPTIONSPRIMARY, JSON.stringify(treatmentPlanPrimaryTherapy));

            let suplementalTherapy = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSSUPPLEMENTAL) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTPLANOPTIONSSUPPLEMENTAL, JSON.stringify(suplementalTherapy));

            let dentalTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSDENTAL) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTPLANOPTIONSDENTAL, JSON.stringify(dentalTherapies));

            let collaborationTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSCOLLABORATION) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTPLANOPTIONSCOLLABORATION, JSON.stringify(collaborationTherapies));

            let surgeryTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSSURGERY) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTPLANOPTIONSSURGERY, JSON.stringify(surgeryTherapies));

            let otherTherapies = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTPLANOPTIONSOTHER) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTPLANOPTIONSOTHER, JSON.stringify(otherTherapies));

            let vivosAppliances = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === VIVOSAPPLIANCES) return item.dictionaryListItems;
            });

            localStorage.setItem(VIVOSAPPLIANCES, JSON.stringify(vivosAppliances));

            let molarRelationships = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === MOLARRELATIONSHIPS) return item.dictionaryListItems;
            });
            localStorage.setItem(MOLARRELATIONSHIPS, JSON.stringify(molarRelationships));

            let calendlyTopics = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CALENDLYTOPICS) return item.dictionaryListItems;
            });
            localStorage.setItem(CALENDLYTOPICS, JSON.stringify(calendlyTopics));
            let treatmentObjectives = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === TREATMENTOBJECTIVES) return item.dictionaryListItems;
            });

            localStorage.setItem(TREATMENTOBJECTIVES, JSON.stringify(treatmentObjectives));

            let consultationStatus = _.filter(respData, item => {
                if (_.toUpper(item.listKey) === CONSULTATIONSTATUS) return item.dictionaryListItems;
            });

            localStorage.setItem(CONSULTATIONSTATUS, JSON.stringify(consultationStatus));

            let primaryCategories = _.filter(respData, item => {
                if (__.strContains(item.listKey, PRIMARYCATEGORY)) return item.dictionaryListItems;
            });

            localStorage.setItem(PRIMARYCATEGORIES, JSON.stringify(primaryCategories));

            let secondaryCategories = _.filter(respData, item => {
                if (__.strContains(item.listKey, SECONDARYCATEGORY)) return item.dictionaryListItems;
            });

            localStorage.setItem(SECONDARYCATEGORIES, JSON.stringify(secondaryCategories));

            let vivosmeasurementprops = _.filter(respData, item => {
                if (__.strContains(item.listKey, VIVOSMEASUREMENTPROPERTIES)) return item.dictionaryListItems;
            });

            localStorage.setItem(VIVOSMEASUREMENTPROPERTIES, JSON.stringify(vivosmeasurementprops));

            let claimStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(CLAIMSTATUS, JSON.stringify(claimStatus));

            let orderStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, ORDERSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(ORDERSTATUS, JSON.stringify(orderStatus));

            let productionStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, PRODUCTIONSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(PRODUCTIONSTATUS, JSON.stringify(productionStatus));

            let reworkRequests = _.filter(respData, item => {
                if (__.strContains(item.listKey, REWORKREQUESTS)) return item.dictionaryListItems;
            });

            localStorage.setItem(REWORKREQUESTS, JSON.stringify(reworkRequests));

            let claimReviewDocumentTypes = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMREVIEWDOCUMENTTYPE)) return item.dictionaryListItems;
            });

            localStorage.setItem(CLAIMREVIEWDOCUMENTTYPE, JSON.stringify(claimReviewDocumentTypes));

            let patientVisitStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, PATIENTVISITSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(PATIENTVISITSTATUS, JSON.stringify(patientVisitStatus));

            let addressType = _.filter(respData, item => {
                if (__.strContains(item.listKey, ADDRESSTYPE)) return item.dictionaryListItems;
            });

            localStorage.setItem(ADDRESSTYPE, JSON.stringify(addressType));

            let teethSetType = _.filter(respData, item => {
                if (__.strContains(item.listKey, TEETHSET)) return item.dictionaryListItems;
            });

            localStorage.setItem(TEETHSET, JSON.stringify(teethSetType));

            let teethNumSystemType = _.filter(respData, item => {
                if (__.strContains(item.listKey, TEETHNUMBERINGSYSTEM)) return item.dictionaryListItems;
            });

            localStorage.setItem(TEETHNUMBERINGSYSTEM, JSON.stringify(teethNumSystemType));

            let licareStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, LINCARESTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(LINCARESTATUS, JSON.stringify(licareStatus));

            let guideSeries = _.filter(respData, item => {
                if (__.strContains(item.listKey, GUIDESERIES)) return item.dictionaryListItems;
            });

            localStorage.setItem(GUIDESERIES, JSON.stringify(guideSeries));

            let applianceSizes = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESIZES)) return item.dictionaryListItems;
            });

            localStorage.setItem(APPLIANCESIZES, JSON.stringify(applianceSizes));

            let applianceColors = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCECOLORS)) return item.dictionaryListItems;
            });

            localStorage.setItem(APPLIANCECOLORS, JSON.stringify(applianceColors));

            let applianceVerticalThickness = _.filter(respData, item => {
                if (__.strContains(item.listKey, VERTICALTHICKNESS)) return item.dictionaryListItems;
            });

            localStorage.setItem(VERTICALTHICKNESS, JSON.stringify(applianceVerticalThickness));

            let applianceUnilateralBiteBlock = _.filter(respData, item => {
                if (__.strContains(item.listKey, UNILATERALBITEBLOCK)) return item.dictionaryListItems;
            });

            localStorage.setItem(UNILATERALBITEBLOCK, JSON.stringify(applianceUnilateralBiteBlock));

            let prescriptionStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, PRESCRIPTIONSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(PRESCRIPTIONSTATUS, JSON.stringify(prescriptionStatus));

            let qcStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, QCSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(QCSTATUS, JSON.stringify(qcStatus));

            let modelStatus = _.filter(respData, item => {
                if (item.listKey === MODELSTATUS) return item.dictionaryListItems;
            });

            localStorage.setItem(MODELSTATUS, JSON.stringify(modelStatus));

            let physicalToDigitalStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, PHYSICALTODIGITALSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(PHYSICALTODIGITALSTATUS, JSON.stringify(physicalToDigitalStatus));

            let digitalDesignStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, DIGITALDESIGNSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(DIGITALDESIGNSTATUS, JSON.stringify(digitalDesignStatus));

            let labClaimMaterialModelStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, LABCLAIMMATERIALMODELSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(LABCLAIMMATERIALMODELSTATUS, JSON.stringify(labClaimMaterialModelStatus));

            let labClaimMaterialApplianceStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, LABCLAIMMATERIALAPPLIANCESTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(LABCLAIMMATERIALAPPLIANCESTATUS, JSON.stringify(labClaimMaterialApplianceStatus));

            let labClaimMaterialBiteStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, LABCLAIMMATERIALBITESTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(LABCLAIMMATERIALBITESTATUS, JSON.stringify(labClaimMaterialBiteStatus));

            let biteStatus = _.filter(respData, item => {
                if (item.listKey === BITESTATUS) return item.dictionaryListItems;
            });

            localStorage.setItem(BITESTATUS, JSON.stringify(biteStatus));

            let shippingStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, SHIPPINGSTATUS)) return item.dictionaryListItems;
            });

            localStorage.setItem(SHIPPINGSTATUS, JSON.stringify(shippingStatus));
            // let mappingTables = _.filter(respData, item => {
            //     if (_.toUpper(item.listKey) === MAPPINGTABLES) return item.dictionaryListItems;
            // });

            // localStorage.setItem(MAPPINGTABLES, JSON.stringify(mappingTables));

            let workingDiagnosis = _.filter(respData, item => {
                if (__.strContains(item.listKey, WORKINGDIAGNOSIS)) return item.dictionaryListItems;
            });
            localStorage.setItem(WORKINGDIAGNOSIS, JSON.stringify(workingDiagnosis));

            let treatmentObjectiveList = _.filter(respData, item => {
                if (__.strContains(item.listKey, TREATMENTOBJECTIVESLIST)) return item.dictionaryListItems;
            });
            localStorage.setItem(TREATMENTOBJECTIVESLIST, JSON.stringify(treatmentObjectiveList));

            let preventPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, PREVENTPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(PREVENTPHASEOPTIONS, JSON.stringify(preventPhaseOptions));

            let managePhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, MANAGEPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(MANAGEPHASEOPTIONS, JSON.stringify(managePhaseOptions));

            let rehabilitatePhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, REHABILITATEPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(REHABILITATEPHASEOPTIONS, JSON.stringify(rehabilitatePhaseOptions));

            let supplementalPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, SUPLEMENTALTHERAPIESPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(SUPLEMENTALTHERAPIESPHASEOPTIONS, JSON.stringify(supplementalPhaseOptions));

            let dentalPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, DENTALPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(DENTALPHASEOPTIONS, JSON.stringify(dentalPhaseOptions));

            let surgeryPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, SURGERYPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(SURGERYPHASEOPTIONS, JSON.stringify(surgeryPhaseOptions));

            let otherPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, OTHERPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(OTHERPHASEOPTIONS, JSON.stringify(otherPhaseOptions));

            let referralPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, REFERRALPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(REFERRALPHASEOPTIONS, JSON.stringify(referralPhaseOptions));

            let occlusionModelType = _.filter(respData, item => {
                if (__.strContains(item.listKey, PATIENTOCCLUSIONTYPE)) return item.dictionaryListItems;
            });
            localStorage.setItem(PATIENTOCCLUSIONTYPE, JSON.stringify(occlusionModelType));

            let constructionBiteInformation = _.filter(respData, item => {
                if (__.strContains(item.listKey, CONSTRUCTIONBITEINFORMATION)) return item.dictionaryListItems;
            });
            localStorage.setItem(CONSTRUCTIONBITEINFORMATION, JSON.stringify(constructionBiteInformation));

            let constructionBiteType = _.filter(respData, item => {
                if (__.strContains(item.listKey, CONSTRUCTIONBITETYPE)) return item.dictionaryListItems;
            });
            localStorage.setItem(CONSTRUCTIONBITETYPE, JSON.stringify(constructionBiteType));

            let potrusionPosition = _.filter(respData, item => {
                if (__.strContains(item.listKey, POTRUSIONPOSITION)) return item.dictionaryListItems;
            });
            localStorage.setItem(POTRUSIONPOSITION, JSON.stringify(potrusionPosition));

            //3305 - Treatment plan grouping
            let temporaryInitialPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, TEMPORARYINITIALPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(TEMPORARYINITIALPHASEOPTIONS, JSON.stringify(temporaryInitialPhaseOptions));

            let vivosCAREPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, VIVOSCAREPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(VIVOSCAREPHASEOPTIONS, JSON.stringify(vivosCAREPhaseOptions));

            let vivosGuidePhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, VIVOSGUIDEPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(VIVOSGUIDEPHASEOPTIONS, JSON.stringify(vivosGuidePhaseOptions));

            let tmjBruxismPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, TMJBRUXISMPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(TMJBRUXISMPHASEOPTIONS, JSON.stringify(tmjBruxismPhaseOptions));

            let mandibularAdvancementPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, MANDIBULARADVANCEMENTPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(MANDIBULARADVANCEMENTPHASEOPTIONS, JSON.stringify(mandibularAdvancementPhaseOptions));

            let orthodonticsPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, ORTHODONTICSPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(ORTHODONTICSPHASEOPTIONS, JSON.stringify(orthodonticsPhaseOptions));

            let otherTreatmentPhaseOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, OTHERTREATMENTSPHASEOPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(OTHERTREATMENTSPHASEOPTIONS, JSON.stringify(otherTreatmentPhaseOptions));

            let applianceUpperBase = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_UPPERSYSTEM_BASE)) return item.dictionaryListItems;
            });

            localStorage.setItem(APPLIANCESYSTEM_UPPERSYSTEM_BASE, JSON.stringify(applianceUpperBase));

            let applianceLowerBase = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_LOWERSYSTEM_BASE)) return item.dictionaryListItems;
            });
            localStorage.setItem(APPLIANCESYSTEM_LOWERSYSTEM_BASE, JSON.stringify(applianceLowerBase));

            let applianceUpperBow = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_UPPERSYSTEM_BOW)) return item.dictionaryListItems;
            });
            localStorage.setItem(APPLIANCESYSTEM_UPPERSYSTEM_BOW, JSON.stringify(applianceUpperBow));

            let applianceLowerBow = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_LOWERSYSTEM_BOW)) return item.dictionaryListItems;
            });
            localStorage.setItem(APPLIANCESYSTEM_LOWERSYSTEM_BOW, JSON.stringify(applianceLowerBow));

            let applianceUpperMandibularAdvancement = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_UPPERSYSTEM_MANDIBULARADVANCEMENTOPTION)) return item.dictionaryListItems;
            });
            localStorage.setItem(APPLIANCESYSTEM_UPPERSYSTEM_MANDIBULARADVANCEMENTOPTION, JSON.stringify(applianceUpperMandibularAdvancement));

            let applianceUpperScrew = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_UPPERSYSTEM_SCREW)) return item.dictionaryListItems;
            });
            localStorage.setItem(APPLIANCESYSTEM_UPPERSYSTEM_SCREW, JSON.stringify(applianceUpperScrew));

            let applianceLowerScrew = _.filter(respData, item => {
                if (__.strContains(item.listKey, APPLIANCESYSTEM_LOWERSYSTEM_SCREW)) return item.dictionaryListItems;
            });
            localStorage.setItem(APPLIANCESYSTEM_LOWERSYSTEM_SCREW, JSON.stringify(applianceLowerScrew));

            let materialbase = _.filter(respData, item => {
                if (__.strContains(item.listKey, MATERIALBASE)) return item.dictionaryListItems;
            });
            localStorage.setItem(MATERIALBASE, JSON.stringify(materialbase));

            let repairOptions = _.filter(respData, item => {
                if (__.strContains(item.listKey, REPAIROPTIONS)) return item.dictionaryListItems;
            });
            localStorage.setItem(REPAIROPTIONS, JSON.stringify(repairOptions));

            let labReworkMaterialBiteStatuses = _.filter(respData, item => {
                if (__.strContains(item.listKey, LABREWORKMATERIALBITESTATUS)) return item.dictionaryListItems;
            });
            localStorage.setItem(LABREWORKMATERIALBITESTATUS, JSON.stringify(labReworkMaterialBiteStatuses));

            let labReworkMaterialModelStatuses = _.filter(respData, item => {
                if (__.strContains(item.listKey, LABREWORKMATERIALMODELSTATUS)) return item.dictionaryListItems;
            });
            localStorage.setItem(LABREWORKMATERIALMODELSTATUS, JSON.stringify(labReworkMaterialModelStatuses));

            let cancellationReasons = _.filter(respData, item => {
                if (__.strContains(item.listKey, CANCELLATIONREASON)) return item.dictionaryListItems;
            });
            localStorage.setItem(CANCELLATIONREASON, JSON.stringify(cancellationReasons));

            let erfStatus = _.filter(respData, item => {
                if (__.strContains(item.listKey, ERFSTATUS)) return item.dictionaryListItems;
            });
            localStorage.setItem(ERFSTATUS, JSON.stringify(erfStatus));

            let financialLiability = _.filter(respData, item => {
                if (__.strContains(item.listKey, FINANCIALLIABILITY)) return item.dictionaryListItems;
            });
            localStorage.setItem(FINANCIALLIABILITY, JSON.stringify(financialLiability));

            let claimClassificationSubcategories = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMCLASSIFICATIONSUBCATEGORIES)) return item.dictionaryListItems;
            });
            localStorage.setItem(CLAIMCLASSIFICATIONSUBCATEGORIES, JSON.stringify(claimClassificationSubcategories));

            let claimAdditionalCategorizationRxWasNotFollowed = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMADDITIONALCATEGORIZATIONRXWASNOTFOLLOWED)) return item.dictionaryListItems;
            });
            localStorage.setItem(CLAIMADDITIONALCATEGORIZATIONRXWASNOTFOLLOWED, JSON.stringify(claimAdditionalCategorizationRxWasNotFollowed));

            let claimAdditionalCategorizationFunctionalAssembly = _.filter(respData, item => {
                if (__.strContains(item.listKey, CLAIMADDITIONALCATEGORIZATIONONFUNCTIONALASSEMBLY)) return item.dictionaryListItems;
            });
            localStorage.setItem(CLAIMADDITIONALCATEGORIZATIONONFUNCTIONALASSEMBLY, JSON.stringify(claimAdditionalCategorizationFunctionalAssembly));

            let complaintMethod = _.filter(respData, item => {
                if (__.strContains(item.listKey, COMPLAINTMETHOD)) return item.dictionaryListItems;
            });
            localStorage.setItem(COMPLAINTMETHOD, JSON.stringify(complaintMethod));

            let complaintType = _.filter(respData, item => {
                if (__.strContains(item.listKey, COMPLAINTTYPE)) return item.dictionaryListItems;
            });
            localStorage.setItem(COMPLAINTTYPE, JSON.stringify(complaintType));

            let fabricationTimeTypes = _.filter(respData, item => {
                if (__.strContains(item.listKey, FABRICATIONTIMETYPES)) return item.dictionaryListItems;
            });
            localStorage.setItem(FABRICATIONTIMETYPES, JSON.stringify(fabricationTimeTypes));

            var lists: DictionaryList[] = [];
            _.forEach(respData, r => {
                if (r != null) {
                    lists.push(r);
                }
            });
            //localStorage.setItem(DICTIONARYLISTS, JSON.stringify(lists));
            return lists;
        });
    };

    static getFormProperties = async () => {
        Gateway.get('questionnaire/form/properties/headneck').then(respData => {
            localStorage.setItem(HEADNECKPROPERTIES, JSON.stringify(respData));
        })
    }

    static getHeadNeckProperties = async () => {
        var hx: string | null = localStorage.getItem(HEADNECKPROPERTIES);
        if (hx === null || hx === '[]') {
            await this.getFormProperties();
            return JSON.parse(localStorage.getItem(HEADNECKPROPERTIES) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getMedicalHxOptions = async () => {
        var hx: string | null = localStorage.getItem(MEDICALHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(MEDICALHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getConsultationStatus() {
        let resp = localStorage.getItem(CONSULTATIONSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CONSULTATIONSTATUS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getDentalHxOptions = async () => {
        var hx: string | null = localStorage.getItem(DENTALHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(DENTALHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getCpapMadHxOptions = async () => {
        var hx: string | null = localStorage.getItem(CPAPMADHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(CPAPMADHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getAllergyHxOptions = async () => {
        var hx: string | null = localStorage.getItem(ALLERGYHX);
        if (hx === null || hx === '[]') {
            await this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(ALLERGYHX) as string);
        }
        return hx ? JSON.parse(hx) : [];
    };

    static getMyoCorrectStatuses() {
        var resp: string | null = localStorage.getItem(MYOCORRECTSTATUS);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getMyoCorrectTherapyStatuses() {
        var resp: string | null = localStorage.getItem(MYOCORRECTTHERAPYSTATUS);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getTreatmentStatuses() {
        var resp: string | null = localStorage.getItem(THERAPYSTATUSES);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getAIReportStatuses() {
        let resp: string | null = localStorage.getItem(AIREPORTSTATUSES);
        if (resp === null) {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(AIREPORTSTATUSES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getAIReportTypes() {
        let resp: string | null = localStorage.getItem(AIREPORTTYPES);
        if (resp === null) {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(AIREPORTTYPES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getAddOnTypes() {
        var resp: string | null = localStorage.getItem(ADDONTYPE);
        if (resp === null) {
            this.getAndCreateOptions();
        }
        return resp ? JSON.parse(resp) : [];
    }

    static getAddOnTypeList() {
        let resp: string | null = localStorage.getItem(ADDONTYPE);
        if (resp === null) {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(ADDONTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getGenders() {
        var resp = localStorage.getItem(GENDER);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(GENDER) as string)[0].dictionaryListItems;
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getEthnicities() {
        var resp: string | null = localStorage.getItem(ETHNICITY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(ETHNICITY) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getIntraOralImages() {
        var resp: string | null = localStorage.getItem(INTRAORALIMAGES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(INTRAORALIMAGES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getExtraOralImages() {
        var resp: string | null = localStorage.getItem(EXTRAORALIMAGES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(EXTRAORALIMAGES) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getCBCTMachineBrands() {
        var resp: string | null = localStorage.getItem(CBCTMACHINEBRAND);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(CBCTMACHINEBRAND) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static get3DMachineBrands() {
        var resp: string | null = localStorage.getItem(OCCLUSION3DMACHINEBRAND);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(OCCLUSION3DMACHINEBRAND) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPatientOcclusionImageTypes() {
        var resp: string | null = localStorage.getItem(PATIENTOCCLUSION);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(PATIENTOCCLUSION) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPatientImageTypes() {
        var resp: string | null = localStorage.getItem(PATIENTIMAGETYPES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(() => {
                return JSON.parse(localStorage.getItem(PATIENTIMAGETYPES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getRelationshipTypes() {
        var resp = localStorage.getItem(GUARDIANRELATIONSHIPTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(GUARDIANRELATIONSHIPTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getMissingReasons() {
        var resp = localStorage.getItem(MISSINGREASONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(MISSINGREASONS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPresentConditions(): DictionaryListItem[] {
        var resp = localStorage.getItem(PRESENTCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PRESENTCONDITIONS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getIntakeReports() {
        var resp = localStorage.getItem(INTAKEREPORTS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(INTAKEREPORTS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getProcessedImageTypes() {
        var resp = localStorage.getItem(AIREPORTPROCESSEDIMAGETYPES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(AIREPORTPROCESSEDIMAGETYPES) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getSleepConditions() {
        let resp = localStorage.getItem(SLEEPCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(SLEEPCONDITIONS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getExtraoralConditions() {
        let resp = localStorage.getItem(EXTRAORALCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(EXTRAORALCONDITIONS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getIntraoralConditions() {
        let resp = localStorage.getItem(INTRAORALCONDITIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(INTRAORALCONDITIONS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getCbctConditions() {
        let resp = localStorage.getItem(CBCTDIAGNOSIS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CBCTDIAGNOSIS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getPrimaryTherapy() {
        let resp = localStorage.getItem(TREATMENTPLANOPTIONSPRIMARY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTPLANOPTIONSPRIMARY) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getSuplementalTherapy() {
        let resp = localStorage.getItem(TREATMENTPLANOPTIONSSUPPLEMENTAL);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTPLANOPTIONSSUPPLEMENTAL) as string)[0]
                    .dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getDentalThearpy() {
        let resp = localStorage.getItem(TREATMENTPLANOPTIONSDENTAL);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTPLANOPTIONSDENTAL) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getCollaborationTherapy() {
        let resp = localStorage.getItem(TREATMENTPLANOPTIONSCOLLABORATION);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTPLANOPTIONSCOLLABORATION) as string)[0]
                    .dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static surgeryTherapy() {
        let resp = localStorage.getItem(TREATMENTPLANOPTIONSSURGERY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTPLANOPTIONSSURGERY) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getOtherTherapy() {
        let resp = localStorage.getItem(TREATMENTPLANOPTIONSOTHER);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTPLANOPTIONSOTHER) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getVivosAppliances() {
        let resp = localStorage.getItem(VIVOSAPPLIANCES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(VIVOSAPPLIANCES) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getMolarRelationships() {
        let resp = localStorage.getItem(MOLARRELATIONSHIPS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(MOLARRELATIONSHIPS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getTreatmentObjectives() {
        let resp = localStorage.getItem(TREATMENTOBJECTIVES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTOBJECTIVES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getMappingTables = async () => {
        let resp = localStorage.getItem(MAPPINGTABLES);
        if (resp === null || resp === '[]') {
            Gateway.get(`questionnaire/form/mappingtables`).then(resp => {
                localStorage.setItem(MAPPINGTABLES, JSON.stringify(resp));
                return JSON.stringify(resp);
            });
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getPrimaryCategories = async () => {
        let resp = localStorage.getItem(PRIMARYCATEGORIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PRIMARYCATEGORIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getSecondaryCategories = async () => {
        let resp = localStorage.getItem(SECONDARYCATEGORIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(SECONDARYCATEGORIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getVivosMeasurementProperties = async () => {
        let resp = localStorage.getItem(VIVOSMEASUREMENTPROPERTIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(VIVOSMEASUREMENTPROPERTIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getPrescriptionStatus = async () => {
        let resp = localStorage.getItem(PRESCRIPTIONSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PRESCRIPTIONSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getQCStatus = async () => {
        let resp = localStorage.getItem(QCSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(QCSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getModelStatus = async () => {
        let resp = localStorage.getItem(MODELSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(MODELSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getPhysicalToDigitalStatus = async () => {
        let resp = localStorage.getItem(PHYSICALTODIGITALSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PHYSICALTODIGITALSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getDigitalDesignStatus = async () => {
        let resp = localStorage.getItem(DIGITALDESIGNSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(DIGITALDESIGNSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLabClaimMaterialModelStatus = async () => {
        let resp = localStorage.getItem(LABCLAIMMATERIALMODELSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(LABCLAIMMATERIALMODELSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLabClaimMaterialApplianceStatus = async () => {
        let resp = localStorage.getItem(LABCLAIMMATERIALAPPLIANCESTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(LABCLAIMMATERIALAPPLIANCESTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLabClaimMaterialBiteStatus = async () => {
        let resp = localStorage.getItem(LABCLAIMMATERIALBITESTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(LABCLAIMMATERIALBITESTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getBiteStatus = async () => {
        let resp = localStorage.getItem(BITESTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(BITESTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getShippingStatus = async () => {
        let resp = localStorage.getItem(SHIPPINGSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(SHIPPINGSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimStatus = async () => {
        let resp = localStorage.getItem(CLAIMSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CLAIMSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getOrderStatus = async () => {
        let resp = localStorage.getItem(ORDERSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(ORDERSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getProductionStatus = async () => {
        let resp = localStorage.getItem(PRODUCTIONSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PRODUCTIONSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getReworkRequests = async () => {
        let resp = localStorage.getItem(REWORKREQUESTS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(REWORKREQUESTS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimReviewDocumentTypes = async () => {
        let resp = localStorage.getItem(CLAIMREVIEWDOCUMENTTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CLAIMREVIEWDOCUMENTTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getPropertyTables = async () => {
        let resp = localStorage.getItem(PROPERTYTABLES);
        if (resp === null || resp === '[]') {
            Gateway.get(`global/propertytables`).then(resp => {
                localStorage.setItem(PROPERTYTABLES, JSON.stringify(resp));
                return JSON.stringify(resp);
            });
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getRoles = async () => {
        let resp = localStorage.getItem(ROLES);
        if (resp === null || resp === '[]') {
            Gateway.get(`global/roles`).then(resp => {
                localStorage.setItem(ROLES, JSON.stringify(resp));
                return JSON.stringify(resp);
            });
        }
        return resp ? JSON.parse(resp) : [];
    };

    static getPatientVisitStatus = async () => {
        let resp = localStorage.getItem(PATIENTVISITSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PATIENTVISITSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    /* static getDictionaryLists = async () => {
        return await GlobalUtils.getAndCreateOptions();
    }; */

    static async getDictionaryLists(): Promise<any[]> {
        return GlobalUtils.getAndCreateOptions();
    }

    static getCalendlyTopics() {
        let resp = localStorage.getItem(CALENDLYTOPICS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CALENDLYTOPICS) as string)[0].dictionaryListItems;
            });
        }

        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    }

    static getWorkingDiagnosisItems = async () => {
        let resp = localStorage.getItem(WORKINGDIAGNOSIS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(WORKINGDIAGNOSIS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static gettreatmentObjectivesItems = async () => {
        let resp = localStorage.getItem(TREATMENTOBJECTIVESLIST);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TREATMENTOBJECTIVESLIST) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getPreventPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(PREVENTPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PREVENTPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getManagePhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(MANAGEPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(MANAGEPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getRehabilitatePhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(REHABILITATEPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(REHABILITATEPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getSupplementalTherapiesPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(SUPLEMENTALTHERAPIESPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(SUPLEMENTALTHERAPIESPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getDentalPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(DENTALPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(DENTALPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getSurgeryPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(SURGERYPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(SURGERYPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getOtherPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(OTHERPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(OTHERPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getReferralPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(REFERRALPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(REFERRALPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static getOcclusionModelType = async () => {
        const occlusionModelType = localStorage.getItem(PATIENTOCCLUSIONTYPE);
        if (occlusionModelType === null || occlusionModelType === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(PATIENTOCCLUSIONTYPE) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(occlusionModelType || '')[0].dictionaryListItems;
    };

    static getConstructionBiteInformation = async () => {
        const constructionBiteInformation = localStorage.getItem(CONSTRUCTIONBITEINFORMATION);
        if (constructionBiteInformation === null || constructionBiteInformation === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CONSTRUCTIONBITEINFORMATION) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(constructionBiteInformation || '')[0].dictionaryListItems;
    };

    static getConstructionBiteType = async () => {
        const constructionBiteType = localStorage.getItem(CONSTRUCTIONBITETYPE);
        if (constructionBiteType === null || constructionBiteType === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CONSTRUCTIONBITETYPE) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(constructionBiteType || '')[0].dictionaryListItems;
    };

    static getPotrusionPosition = async () => {
        const potrusionPosition = localStorage.getItem(POTRUSIONPOSITION);
        if (potrusionPosition === null || potrusionPosition === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(POTRUSIONPOSITION) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(potrusionPosition || '')[0].dictionaryListItems;
    };

    static getTemporaryInitialPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(TEMPORARYINITIALPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TEMPORARYINITIALPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };
    static getVivosCAREPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(VIVOSCAREPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(VIVOSCAREPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };
    static getVivosGuidePhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(VIVOSGUIDEPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(VIVOSGUIDEPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };
    static getTMJBruxismPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(TMJBRUXISMPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(TMJBRUXISMPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };
    static getMandibularAdvancementPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(MANDIBULARADVANCEMENTPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(MANDIBULARADVANCEMENTPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };
    static getOrthodonticsPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(ORTHODONTICSPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(ORTHODONTICSPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };
    static getOtherTreatmentsPhaseOptions = async () => {
        const phaseOptions = localStorage.getItem(OTHERTREATMENTSPHASEOPTIONS);
        if (phaseOptions === null || phaseOptions === '[]') {
            return this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(OTHERTREATMENTSPHASEOPTIONS) || '')[0].dictionaryListItems;
            });
        }
        return JSON.parse(phaseOptions || '')[0].dictionaryListItems;
    };

    static reloadDictionaryLists = async () => {
        return await this.getDictionaryLists();
    };

    static validateEmail = (email: string) => {
        return /^([a-zA-Z]*)([0-9]*)[\w.]+[\w-]+[\w_]+(@)([a-zA-Z]*)([0-9]*).([a-zA-Z]*)([0-9]*).([a-zA-Z]*)([0-9]*)$/.test(email);
    };

    static validateAlpha = (value: string) => {
        return /^[A-Za-z ]+$/.test(value);
    };

    static validateNumeric = (value: string) => {
        return /^[1-9]\d*$/.test(value);
    };

    static validateAlphanumeric = (value: string) => {
        return /[\w\s]*/.test(value);
    };

    static validatePhoneNumber = (value: string) => {
        return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value);
    };

    static validatePhoneCharacter = (value: string) => {
        return /^[0-9-\s\.()]{1}$/.test(value);
    };

    static getMyoCorrectUrl = (filename: string, id: string): string => {
        if (filename.startsWith('https')) {
            return filename;
        } else {
            return `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${id}/${process.env.REACT_APP_MYOCORRECT_CONTAINER}/${filename}`;
        }
    };

    static getVisitRecordUrl = (filename: string, vivosId: string, recordId): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (filename && vivosId && recordId) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${process.env.REACT_APP_VISITRECORD_CONTAINER}/${recordId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getAIReportUrl = (filename: string, vivosId: string, reportId): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (vivosId && reportId && filename) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${reportId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getClaimDocumentUrl = (filename: string, vivosId: string, prescriptionId, claimId): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (vivosId && prescriptionId && claimId && filename) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${process.env.REACT_APP_PRESCRIPTIONS_CONTAINER}/${prescriptionId}/${process.env.REACT_APP_REWORKCLAIMS_CONTAINER}/${claimId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getPatientProfileUrl = (filename: string, vivosId: string): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (filename && vivosId) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${vivosId}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getProviderUrl = (filename: string, id: number): string => {
        if (filename) {
            if (filename.startsWith('https') || filename.startsWith('blob')) {
                return decodeURI(filename);
            } else {
                if (filename && id) {
                    return decodeURI(
                        `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PROVIDER_CONTAINER}/${id}/${filename}`,
                    );
                } else {
                    return '';
                }
            }
        }
        return '';
    };

    static getAddresstype = async () => {
        let resp = localStorage.getItem(ADDRESSTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(ADDRESSTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getTeethSetType() {
        let resp = localStorage.getItem(TEETHSET);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(TEETHSET) as string)[0].dictionaryListItems;
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getTeethNumSystemType() {
        let resp = localStorage.getItem(TEETHNUMBERINGSYSTEM);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions();
            return JSON.parse(localStorage.getItem(TEETHNUMBERINGSYSTEM) as string)[0].dictionaryListItems;
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLincareStatus = async () => {
        let resp = localStorage.getItem(LINCARESTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(LINCARESTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getGuideSeries = async () => {
        let resp = localStorage.getItem(GUIDESERIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(GUIDESERIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceSizes = async () => {
        let resp = localStorage.getItem(APPLIANCESIZES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESIZES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceColors = async () => {
        let resp = localStorage.getItem(APPLIANCECOLORS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCECOLORS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getVerticalThickness = async () => {
        let resp = localStorage.getItem(VERTICALTHICKNESS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(VERTICALTHICKNESS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getUnilateralBiteBlock = async () => {
        let resp = localStorage.getItem(UNILATERALBITEBLOCK);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(UNILATERALBITEBLOCK) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceUpperBase = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_BASE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_BASE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceLowerBase = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_LOWERSYSTEM_BASE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_LOWERSYSTEM_BASE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceUpperBow = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_BOW);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_BOW) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceLowerBow = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_LOWERSYSTEM_BOW);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_LOWERSYSTEM_BOW) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceUpperMandibularAdvancement = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_MANDIBULARADVANCEMENTOPTION);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_MANDIBULARADVANCEMENTOPTION) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceUpperScrew = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_SCREW);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_UPPERSYSTEM_SCREW) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getApplianceLowerScrew = async () => {
        let resp = localStorage.getItem(APPLIANCESYSTEM_LOWERSYSTEM_SCREW);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(APPLIANCESYSTEM_LOWERSYSTEM_SCREW) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getMaterialBase = async () => {
        let resp = localStorage.getItem(MATERIALBASE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(MATERIALBASE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getRepairOptions = async () => {
        let resp = localStorage.getItem(REPAIROPTIONS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(REPAIROPTIONS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLabReworkMaterialBiteStatuses = async () => {
        let resp = localStorage.getItem(LABREWORKMATERIALBITESTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(LABREWORKMATERIALBITESTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getLabReworkMaterialModelStatuses = async () => {
        let resp = localStorage.getItem(LABREWORKMATERIALMODELSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(LABREWORKMATERIALMODELSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getCancellationReasons = async () => {
        let resp = localStorage.getItem(CANCELLATIONREASON);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CANCELLATIONREASON) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getErfStatusValues = async () => {
        let resp = localStorage.getItem(ERFSTATUS);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(ERFSTATUS) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getFinancialLiabilityValues = async () => {
        let resp = localStorage.getItem(FINANCIALLIABILITY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(FINANCIALLIABILITY) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimClassificationSubCategoriesValues = async () => {
        let resp = localStorage.getItem(CLAIMCLASSIFICATIONSUBCATEGORIES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CLAIMCLASSIFICATIONSUBCATEGORIES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimAdditionalCategorizationRxWasNotFollowedValues = async () => {
        let resp = localStorage.getItem(CLAIMADDITIONALCATEGORIZATIONRXWASNOTFOLLOWED);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CLAIMADDITIONALCATEGORIZATIONRXWASNOTFOLLOWED) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getClaimAdditionalCategorizationNonFunctionalAssemblyValues = async () => {
        let resp = localStorage.getItem(CLAIMADDITIONALCATEGORIZATIONONFUNCTIONALASSEMBLY);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(CLAIMADDITIONALCATEGORIZATIONONFUNCTIONALASSEMBLY) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getComplaintMethodValues = async () => {
        let resp = localStorage.getItem(COMPLAINTMETHOD);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(COMPLAINTMETHOD) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getComplaintTypeValues = async () => {
        let resp = localStorage.getItem(COMPLAINTTYPE);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(COMPLAINTTYPE) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };

    static getFabricationTimeTypes = async () => {
        let resp = localStorage.getItem(FABRICATIONTIMETYPES);
        if (resp === null || resp === '[]') {
            this.getAndCreateOptions().then(resp => {
                return JSON.parse(localStorage.getItem(FABRICATIONTIMETYPES) as string)[0].dictionaryListItems;
            });
        }
        return resp ? JSON.parse(resp)[0].dictionaryListItems : [];
    };
}
