// Vendors
import { FC } from 'react';
import {
    Grid,
    Typography,
    Button,
    TextField
} from '@mui/material';

// Components
import SectionContainer from '../../UI/Containers/SectionContainer';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';

// API
import { observer } from 'mobx-react';
import { TreatmentComplaint } from '../../../models/state_models/treatmentComplain';


interface IPatientComplaints {
    complaint?: number;
    complaintNote?: string;
}

export const PatientComplaints: FC<IPatientComplaints> = observer(({ complaint, complaintNote }) => {
    /* const { complaints, setComplaints, complaintsNotes, setComplaintsNotes } =
        useMainStoreContext().patientTreatmentStore; */
    
    const {treatmentPlan,setTreatmentPlan} = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore

    const CONCERN_SLEEP: number = 1;
    const CONCERN_FUNCTION: number = 2;
    const CONCERN_PAIN: number = 4;
    const CONCERN_COSMETICS: number = 8;
    const CONCERN_OTHER: number = 16;

    const complaintsChange = (option: {label:string, value:number}) => {
        var treatmentComplain = treatmentPlan.treatmentComplaints.find(x=> x.areaOfComplaint.includes(option.label))
        if(treatmentComplain){
            treatmentPlan.treatmentComplaints = treatmentPlan.treatmentComplaints.filter(item => item !== treatmentComplain);
        }
        else{
            let newComplain = new TreatmentComplaint();
            newComplain.areaOfComplaint = option.label;
            newComplain.treatmentPlanId = treatmentPlan.id;
            treatmentPlan.treatmentComplaints.push(newComplain);
        }
        setTreatmentPlan(treatmentPlan);
    };

    const optionChecked = (options, option) => {
        return (options & option) === option;
    };

    return (
        <Grid container padding={5}>
            <SectionContainer
                sectionTitle={"Patient Overview"}
                cardContentHeight={"340px"}
                sectionComponent={
                    <Grid container padding={5}>
                        <Grid item xs={6} paddingX={3}>
                            <Typography variant='h5' >
                                Type of Case
                            </Typography>
                            <Grid container spacing={1} >
                                {[
                                    { label: 'Sleep', value: CONCERN_SLEEP },
                                    { label: 'Function', value: CONCERN_FUNCTION },
                                    { label: 'Pain', value: CONCERN_PAIN },
                                    { label: 'Cosmetics', value: CONCERN_COSMETICS },
                                    { label: 'Other', value: CONCERN_OTHER }
                                ].map(option => (
                                    <Grid key={option.label} xs={3} item>
                                        <Button
                                            fullWidth
                                            variant={treatmentPlan.treatmentComplaints.find(x=> x.areaOfComplaint.includes(option.label)) ? 'contained' : 'outlined'}
                                            color="primary"
                                            onClick={() => complaintsChange(option)}
                                        >
                                            {option.label}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} paddingX={3}>
                            <Typography variant='h5' >
                                Chief Complaint
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline
                                minRows={7}
                                maxRows={15}
                                autoComplete="new-password"
                                size="medium"
                                placeholder="Please describe patient complaints in detail"
                                required={false}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    treatmentPlan.complaintNotes = event.target.value
                                    setTreatmentPlan(treatmentPlan);
                                }}
                                value={treatmentPlan.complaintNotes}
                            />

                        </Grid>
                    </Grid>
                }
            />
        </Grid>



    );
});
