import { FC } from 'react';
import {
    // FormControl, 
    // FormHelperText, 
    Grid,
    IconButton,
    // MenuItem,
    // SelectChangeEvent, 
    Typography,
    TextField,
    Autocomplete
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { observer } from 'mobx-react';
// import CssTextField from './CssTextField';
// import Item from './Item';
import React, { useEffect } from 'react';
import { useMainStoreContext } from '../stores/OldStores/MainStore';
import LegalGuardianData from "../models/state_models/legalGuardian";
// import CssSelectField from './CssSelectField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {store} from "../stores/Patient/Create/CreatePatientMainStore";

interface ILegalGuardianProps {
    index: any;
    guardian?: any;
    onDelete(index: number): any;
}
const LegalGuardianForm: FC<ILegalGuardianProps> = ({ index, guardian, onDelete }) => {
    /*const { setGuardian, removeGuardian, getGuardians,
        helperFirstNameText, helperLastNameText, helperEmailText, helperPhoneText,
        error, setError } = useMainStoreContext().createLegalGuardianStore;*/
    const { legalGuardians } = store;
    const [cIndex, setCIndex] = React.useState(-1);
    const [lg_firstName, setFirstName] = React.useState('');
    const [lg_lastName, setLastName] = React.useState('');
    const [lg_relationship, setRelationship] = React.useState(147);
    const [lg_emailAddress, setEmailAddress] = React.useState('');
    const [lg_primaryPhone, setPrimaryPhone] = React.useState('');
    const [lg_altPhone, setAltPhone] = React.useState('');

    const handleonBlur = () => {
        var guardian = new LegalGuardianData();
        //setGuardian(index, guardian);
    };

    useEffect(() => {
        setCIndex(index);
    }, []);

    const relationships = [
        { id: 147, label: "Parent" },
        { id: 148, label: "Guardian" },
        { id: 282, label: "Other" },
    ];

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant='h4'>Legal Guardian Information</Typography>
                    <IconButton
                        color='error'
                        hidden={legalGuardians.length <= 1}
                        onClick={() => {
                            onDelete(index);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Formik
                    initialValues={{
                        firstName: lg_firstName,
                        lastName: lg_lastName,
                        relationship: '',
                        emailAddress: lg_emailAddress,
                        primaryPhone: lg_primaryPhone,
                        altPhone: lg_altPhone,
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string()
                            .max(255)
                            .notRequired(),
                        lastName: Yup.string()
                            .max(255)
                            .notRequired(),
                        relationship: Yup.string()
                            .notRequired(),
                        emailAddress: Yup.string()
                            .email('The email provided should be a valid email address')
                            .max(255)
                            .notRequired(),
                        primaryPhone: Yup.string()
                            .notRequired(),
                        altPhone: Yup.string()
                            .notRequired(),
                    })}
                    onSubmit={() => {
                     }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        // handleSubmit,
                        // isSubmitting,
                        touched,
                        values
                    }) => (
                        <form>
                            <Grid container rowSpacing={1} columnSpacing={3} padding={3} direction="row">
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.firstName && errors.firstName
                                        )}
                                        fullWidth
                                        helperText={touched.firstName && errors.firstName}
                                        label='First Name'
                                        name="firstName"
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setFirstName(event.target.value);
                                            legalGuardians[cIndex].firstName = event.target.value;
                                        }}
                                        value={values.firstName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(
                                            touched.lastName && errors.lastName
                                        )}
                                        fullWidth
                                        helperText={touched.lastName && errors.lastName}
                                        label='Last Name'
                                        name="lastName"
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setLastName(event.target.value);
                                            legalGuardians[cIndex].lastName = event.target.value;
                                        }}
                                        value={values.lastName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        id="relationship"
                                        autoComplete={false}
                                        freeSolo
                                        autoSelect={false}
                                        options={relationships}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, value: unknown) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Call patient creation store update
                                            var val = value as any;
                                            setRelationship(val.id);
                                            legalGuardians[cIndex].relationshipTypeId = val.id;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label='Relationship'
                                                name="relationship"
                                                required={false}
                                                variant="outlined"
                                                placeholder='Select Relationship'
                                            />
                                        )}
                                    />

                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.emailAddress && errors.emailAddress)}
                                        fullWidth
                                        helperText={touched.emailAddress && errors.emailAddress}
                                        label='Email address'
                                        name="emailAddress"
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setEmailAddress(event.target.value);
                                            legalGuardians[cIndex].emailAddress = event.target.value;
                                        }}
                                        value={values.emailAddress}
                                        type="email"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        error={Boolean(touched.primaryPhone && errors.primaryPhone)}
                                        helperText={touched.primaryPhone && errors.primaryPhone}
                                        id="home-phone"
                                        label="Primary Phone"
                                        name="primaryPhone"
                                        type='tel'
                                        fullWidth
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setPrimaryPhone(event.target.value);
                                            legalGuardians[cIndex].primaryPhone = event.target.value;
                                        }}
                                        value={values.primaryPhone} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        error={Boolean(touched.altPhone && errors.altPhone)}
                                        helperText={touched.altPhone && errors.altPhone}
                                        id="mobile-phone"
                                        label="Mobile Phone"
                                        name="altPhone"
                                        type='tel'
                                        fullWidth
                                        required={false}
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            // Call handleChange to handle Formik's state
                                            handleChange(event);
                                            // Update Parent/Guardian Values
                                            setAltPhone(event.target.value);
                                            legalGuardians[cIndex].altPhone = event.target.value;
                                        }}
                                        value={values.altPhone} />
                                </Grid>
                            </Grid>
                        </form>
                    )}

                </Formik>
            </Grid>
        </Grid>
    );
};

export default observer(LegalGuardianForm);
