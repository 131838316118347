import { FC } from 'react';
import * as React from 'react';
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { GridColDef, GridFilterInputValue, GridFilterItem, GridFooter, GridFooterContainer } from '@mui/x-data-grid-pro';
import { Button, Grid, IconButton, Link, MenuItem, TextField } from "@mui/material";
import DataGrid from "../DataGrid/DataGrid";
import Moment from 'moment';
import CssSelectField from "../CssSelectField";
import { isNull, isUndefined } from "lodash";
import { RemoveCircleOutline, AddCircleOutline, AddCommentOutlined, CalendarMonthRounded, CommentOutlined, Mode, Download } from "@mui/icons-material";
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import AppointmentsBL from "./AppointmentsBL";
import { NotesModal } from '../../components/NotesModal';
import { SessionStatusModal } from "../SessionStatusModal";
import { RecurringAppointmentModal, RescheduleAppointmentModal, RescheduleCalendlyModal, ChangeSessionDateCalendly } from '../AppointmentModal';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AlertDialog } from '../AlertDialog';
import CssAutoComplete from '../CssAutoComplete';
import { MyoCorrectSessionStatus, MyoCorrectEvaluationType } from '../../entities/Enums';
import CssTextField from '../CssTextField';
import { DateTimeEditModal } from '../DateTimeModal';
import { GlobalUtils } from '../../api/GlobalUtils';
import { CircularProgress } from '@material-ui/core';
import { ScheduleDateTime } from './Columns/ScheduleDateTime';
import { AppointmentType } from './Columns/AppointmentType';
import { EvaluationType } from './Columns/EvaluationType';
import { ScheduledStatus } from './Columns/ScheduledStatus';
import { sortComparator } from './Columns/AssignedTherapist';
import { MyoCorrectStore } from '../../api/MyoCorrectStore';

const ROUND_ROBIN = process.env.REACT_APP_ROUNDROBIN;

interface IAppointmentProps {
    sessions: any;
    therapists: any;
    vivosId: string;
    assignedTherapist: number;
    patientId: number;
    myoCorrectPlanId: number;
    myoCorrectEnrollmentDate: Date;
    dataLoaded: boolean;
}

const Appointments: FC<IAppointmentProps> = (props: IAppointmentProps) => {
    const {
        addSession,
        removeSession,
        handleAppointmentTypeChange,
        handleEvaluationTypeChange,
        handleScheduleStatusChange,
        handleSessionUpdate,
        loadPlan,
        loadCurrentSessions,
        setCurrentRow,
        setPatientIdentification,
        setIsLoadingSessions,
        setAssignedTherapist,
        patientIdentification,
        sessions,
        isLoadingSessions,
    } = useMainStoreContext().sessionsStore;

    const {
        openRecurring,
        openReschedule,
        openRescheduleCalendly,
        openCalendlyChangeSession,
        setOpenCalendlyChangeSession,
        setOpenRecurring,
        setOpenReschedule,
        setOpenRescheduleCalendly,
        setPreviousDateReschedule,
    } = useMainStoreContext().myoCorrectAdminStore;

    const {
        isMyoCorrectAdvocate,
        currentRow: currentPatient,
        getPatientDataItem,
        advisors,
        advocates,
        isProvider,
        isMyoCorrectAdmin,
        isMyoCorrectAdvisor
    } = useMainStoreContext().myoCorrectStore;

    const {
        handleOldNotesUpload,
        setCurrentPatientId,
        setCurrentSession,
        setCurrentVivosID,
        setInitialSessionId,
        setPrevSessionUrl,
        toggleNoShowModal,
        toggleOpenAdditionalNotesModal,
        toggleOpenSessionNotesModal,
        setEditedAfterTimePeriod,
        setPrevAdditionalNotes,
        setIsLoading,
        isLoading,
        loadingText,
        currentSession,
        showNoShowModal,
        showNotesModal,
        prevAdditionalNotes,
    } = useMainStoreContext().createAppointmentNotesStore;

    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
    const [minDate, setMinDate] = React.useState(new Date());
    const [gridBL] = React.useState(new AppointmentsBL());
    const [currentButton] = React.useState(' ');
    const [therapistList, setTherapistList] = React.useState<JSX.Element[]>([]);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [showDateTimeModal, setShowDateTimeModal] = React.useState(false);
    const [currentRow, setCurrentParamsRow] = React.useState({});
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [modalFieldName, setModalfieldName] = React.useState("");
    const [prevModalValue, setPrevModalValue] = React.useState("");


    const handleClick = event => {
        if (hiddenFileInput.current != null) {
            setCurrentSession(event);
            hiddenFileInput.current.click();
        }
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        handleOldNotesUpload(fileUploaded).then(() => {
            loadData();
        });
    };

    const closeCalendlyChangeSession = () => {
        setTimeout(() => {
            setOpenCalendlyChangeSession(false);
            setPreviousDateReschedule('');
            if (patientIdentification) {
                setIsLoadingSessions(true);
                setTimeout(() => loadCurrentSessions(patientIdentification), 2000);
            }
        }, 200);
    };

    const onSave = (value: Date) => {
        currentRow[modalFieldName] = value;
        setShowDateTimeModal(false);
        handleSessionUpdate(currentRow, currentRow["assignedTherapistId"]);
    }

    React.useEffect(() => {
        loadData();
        gridBL.checkRoles();
    }, []);

    React.useEffect(() => {
        if (sessions) {
            const sessionZero = sessions.find(session => session.sessionIndexNumber === 0);
            if (sessionZero && sessionZero.scheduleDateTime) {
                const sessionDate = Moment.utc(sessionZero.scheduleDateTime).add(1, 'day').toDate();
                setMinDate(sessionDate);
            }
        }
    }, [sessions]);

    React.useEffect(() => {
        let therapists = [...advocates].map((th) =>
            (<MenuItem key={th.userID + th.lastName} value={th.userID}>{th.firstName + ' ' + th.lastName}</MenuItem>))
        setTherapistList(therapists);
    }, [advisors, advocates]);

    const loadData = () => {
        setPatientIdentification(props.patientId);
        setAssignedTherapist(props.assignedTherapist);
        gridBL.patientId = props.patientId;
        loadPlan(props.myoCorrectPlanId).then(() => {
            loadCurrentSessions(props.patientId).then(() => {
                setDataLoaded(true);
            });
        });
    }

    const handleOpenModal = async (row) => {

        if (row.evaluationType.toString() !== 'Initial Evaluation') {
            setInitialSessionId(sessions[0].id);
            var index = _.findIndex(sessions, (s) => {
                return s.sessionIndexNumber == (row.sessionIndexNumber - 1);
            });
            var url = (!_.isNull(sessions[index].sessionNotesPdf) || !_.isUndefined(sessions[index].sessionNotesPdf)) ? "" : sessions[index].sessionNotesPdf;
            setPrevSessionUrl(url);
        }
        await setCurrentSession(row);

        setCurrentVivosID(props.vivosId);
        setCurrentPatientId(props.patientId);
        toggleOpenSessionNotesModal();
    }

    const handleOpenNotesModal = (row) => {
        setCurrentSession(row);
        setPrevAdditionalNotes(row.additionalNotes);
        toggleOpenAdditionalNotesModal();
    }

    const newPatient = sessions && sessions.findIndex(session => session.sessionIndexNumber === 1 && session.scheduleDateTime === null) > -1 &&
        sessions.findIndex(session => session.sessionIndexNumber !== 1 && session.sessionIndexNumber !== 0 && session.scheduleDateTime !== null) === -1;

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: '', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", maxWidth: 45,
            renderCell: (params) => {
                return (!isProvider && <IconButton id="appointments.btn.removeSessions" onClick={() => {
                    removeSession(params.row.id);
                }}><RemoveCircleOutline /></IconButton>)
            }
        },
        { field: 'sessionIndexNumber', headerName: 'Appointment', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => { return (<Grid id="appointments.cell.sessionIndexNumber">{params.row.sessionIndexNumber}</Grid>) } },
        {
            field: 'appointmentType', flex: 1, headerName: 'Appointment Type', headerAlign: "center", headerClassName: "colHeader", align: "left", renderCell: (params) => {
                return <AppointmentType params={params} />
            }
        },
        {
            field: 'evaluationType', flex: 1, headerName: 'Evaluation Type', headerAlign: "center", headerClassName: "colHeader", align: "left",
            renderCell: (params) => {
                return (<EvaluationType params={params} setCurrentRow={setCurrentRow} handleEvaluationTypeChange={handleEvaluationTypeChange} />)
            }
        },
        {
            field: 'scheduleDateTime', flex: 1.3, headerName: 'Scheduled For', headerAlign: "center", headerClassName: "colHeader", align: "center",
            renderCell: (params) => {
                return (
                    <ScheduleDateTime
                        params={params}
                        setAlertTitle={setAlertTitle}
                        setAlertMessage={setAlertMessage}
                        setOpenAlertMessage={setOpenAlertMessage}
                        setPrevModalValue={setPrevModalValue}
                        setModalfieldName={setModalfieldName}
                        setShowDateTimeModal={setShowDateTimeModal}
                        setCurrentParamsRow={setCurrentParamsRow} minDate={minDate} />
                );
            }
        },
        {
            field: 'scheduleStatus', flex: 1, headerName: 'Status', type: 'number', headerAlign: "center", headerClassName: "colHeader", align: "left"
            , renderCell: (params) => {
                return (<ScheduledStatus params={params} gridBL={gridBL} handleScheduleStatusChange={handleScheduleStatusChange} setCurrentRow={setCurrentRow} />)
            }
        },
        {
            field: 'assignedTherapistId', headerName: 'Therapist', flex: 1, headerAlign: "center", headerClassName: "colHeader", type: 'actions', align: "left",
            sortComparator: (v1, v2, param1, param2) => sortComparator(v1, v2, param1, param2, props.therapists),
            valueOptions: props.therapists.map((a) => { return a.firstName + " " + a.lastName }),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            var advocate = _.find(props.therapists, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
                            if (!_.isNull(advocate) && !_.isUndefined(advocate)) {
                                return advocate.userID === value;
                            }
                            return false;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            renderCell: (params) => {
                return (
                    <Grid>
                        <CssSelectField
                            id={"myocorrecttherapist.dashboard.cell.assignedTherapistId." + params.row.ID}
                            sx={{ border: "0px" }}
                            defaultValue='0'
                            //disabled={isMyoCorrectAdvocate || isProvider}
                            disabled={(isMyoCorrectAdvocate || isProvider) && !isMyoCorrectAdmin}
                            value={(!_.isUndefined(params.row.assignedTherapistId) ? params.row.assignedTherapistId : 0)}
                            onChange={(event) => {
                                setCurrentRow(params.row);
                                handleSessionUpdate(params.row, event.target.value);
                            }}
                        >
                            <MenuItem key={0} value={0}>{"Select an Advocate..."}</MenuItem>
                            {therapistList}
                        </CssSelectField>
                    </Grid>
                )
            }
        },
        {
            field: 'sessionEvaluation', flex: 0.8, headerName: 'Clinical Notes', headerAlign: "center", headerClassName: "colHeader", align: "center",
            renderCell: (params) => {
                var elemType = gridBL.getNotesElem(params.row);
                return (
                    <Grid sx={{ cursor: "pointer" }}>
                        {elemType === "new" && (!isProvider || isMyoCorrectAdmin || isMyoCorrectAdvocate || isMyoCorrectAdvisor) &&
                            <IconButton id="appointments.cell.btn.sessionEvaluation" onClick={() => {
                                setEditedAfterTimePeriod(false);
                                handleOpenModal(params.row);
                            }}
                            ><AddCircleOutline /></IconButton>
                        }
                        {elemType === "show" &&
                            <Link href={`${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${params.row.vivosId}/${process.env.REACT_APP_MYOCORRECT_CONTAINER}/${params.row.sessionNotesPdf}`} target="_blank" rel="noopener noreferrer" sx={{ color: 'black' }} underline="none"><Download /></Link>
                        }
                        {elemType === "edit" &&
                            <Mode id="appointments.cell.btn.sessionEvaluation" onClick={() => {
                                setEditedAfterTimePeriod(false);
                                handleOpenModal(params.row);
                            }}></Mode>
                        }
                        {elemType === "editAfterPeriod" &&
                            <Mode id="appointments.cell.btn.sessionEvaluationAfter" onClick={() => {
                                setEditedAfterTimePeriod(true);
                                handleOpenModal(params.row);
                            }}></Mode>
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'oldnotes', flex: 1, headerName: 'Historical Notes', headerAlign: "center", headerClassName: "colHeader", align: "center", hide: gridBL.availableToSchedule({ scheduleDateTime: props.myoCorrectEnrollmentDate }),
            renderCell: (params) => {
                var elemType = gridBL.getOldNotesElem(params.row);
                return (
                    <Grid >
                        {elemType == "new" && (!isProvider || isMyoCorrectAdmin || isMyoCorrectAdvisor || isMyoCorrectAdvocate) &&
                            <div><IconButton id="appointments.cell.btn.notes" onClick={() => { setCurrentSession(params.row); handleClick(params.row); }}>{currentButton === params.row ? <CommentOutlined /> : <AddCircleOutline />}</IconButton><input type="file" accept="application/pdf,application/zip, application/x-zip-compressed, multipart/x-zip" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} /></div>
                        }
                        {elemType == "show" &&
                            <div>{!isMyoCorrectAdmin && <IconButton id="appointments.cell.btn.notes" onClick={() => { setCurrentSession(params.row); handleClick(params.row); }}>{currentButton === params.row ? <CommentOutlined /> : <AddCircleOutline />}</IconButton>}<input type="file" accept="application/pdf,application/zip, application/x-zip-compressed, multipart/x-zip" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />{!isProvider && <label>/&nbsp;</label>}
                                <Link href={GlobalUtils.getMyoCorrectUrl(params.row.oldNotesUrl, props.vivosId)} target="_blank" rel="noopener noreferrer" sx={{ color: 'black' }} underline="none"><Download /></Link></div>
                        }
                    </Grid>
                )
            }
        },
        {
            field: 'notes', flex: 1, headerName: 'Additional Notes', headerAlign: "center", headerClassName: "colHeader", align: "center",
            renderCell: (params) => {
                return (
                    <Grid>
                        <IconButton id="appointments.cell.btn.notes" onClick={() => { handleOpenNotesModal(params.row) }}>{currentButton === params.row ? <CommentOutlined /> : <AddCommentOutlined className={(params.row.additionalNotes != null && params.row.additionalNotes != "") ? "hasNotes" : ""} />}</IconButton>
                    </Grid>
                )
            }
        }
    ]

    const CustomFooter = () => {
        return (
            <GridFooterContainer>
                <IconButton id="appointments.btn.addSessions" onClick={() => {
                    addSession();
                }}><AddCircleOutline /></IconButton>
                <GridFooter sx={{
                    border: 'none'
                }} />
            </GridFooterContainer>
        )
    }

    return (
        <Grid container>
            {!isLoading && !isLoadingSessions &&
                <Grid item xs={12}>
                    <DataGrid
                        columns={columns}
                        loading={!dataLoaded}
                        data={!dataLoaded ? [] : _.sortBy(sessions, (session) => session.sessionIndexNumber)}
                        hideFooter={false}
                        customFooter={CustomFooter}
                    />
                    <NotesModal open={showNotesModal} onClose={() => { currentSession.additionalNotes = prevAdditionalNotes; toggleOpenAdditionalNotesModal(); }} />
                    <SessionStatusModal open={showNoShowModal} onClose={toggleNoShowModal} />
                    <RecurringAppointmentModal minDate={minDate} loadData={loadData} open={openRecurring} onClose={() => setOpenRecurring(false)} />
                    <RescheduleAppointmentModal loadData={loadData} open={openReschedule} onClose={() => setOpenReschedule(false)} />
                    <ChangeSessionDateCalendly loadData={loadData} open={openCalendlyChangeSession} onClose={closeCalendlyChangeSession} />
                    <RescheduleCalendlyModal loadData={loadData} open={openRescheduleCalendly} onClose={() => setOpenRescheduleCalendly(false)} />
                    <AlertDialog close={() => setOpenAlertMessage(false)} open={openAlertMessage} title={alertTitle} message={alertMessage} />

                    <DateTimeEditModal title="Session Date/Time" fieldName={modalFieldName} isOpen={showDateTimeModal} currentObj={currentRow}
                        onClose={() => {
                            currentRow[modalFieldName] = prevModalValue;
                            setShowDateTimeModal(false);
                        }} onSave={onSave} />
                </Grid>}
            {(isLoading || isLoadingSessions) &&
                <Grid item xs={12}>
                    <Grid container direction="row" justifyItems="center" textAlign='center'>
                        <Grid item xs={12}>
                            {isLoading && "Saving Historical Note..."}
                            {isLoadingSessions && "Loading Sessions..."}
                        </Grid>
                        <Grid item xs={12}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}
export default observer(Appointments);