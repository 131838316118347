import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import * as _ from 'lodash';

import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';
import EditPatient from '../../entities/EditPatient';
import LegalGuardianData from '../../entities/LegalGuardian';
import PatientHistory from '../../entities/PatientHistory';
import ProviderPatient from '../../entities/ProviderPatient';
import { store } from '../../stores/OldStores/MainStore';

export default class PatientEditBL {
    @observable private _patientData: ProviderPatient = {} as ProviderPatient;
    @observable private _patientMyocorrectRegistration = {};
    @observable private _patientId = 0;
    @observable private _addresses: any[] = [];
    @observable private _legalGuardians: LegalGuardianData[] = [];
    @observable private _patientHistory: any = {};
    @observable private _activeTab = 0;
    @observable private _treatmentStatusId = 0;
    @observable private _isEditMode = false;
    @observable private _isOpenModal = false;

    @observable private _openAlertMessage = false;
    @observable private _alertMessage = '';
    @observable private _alertTitle = '';

    isloading = false;
    userID: any;
    roles: any;
    providerId: any;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; providerId } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
            this.providerId = tokenData.providerId;
        }
        this.clearPatient();
    }

    firstName = '';
    middleName = '';
    lastName = '';
    dateOfBirth = new Date(2022, 12, 12, 12, 12, 12, 0);
    gender = 22;

    setisloading(value: boolean) {
        this.isloading = value;
    }

    get openAlertMessage() {
        return this._openAlertMessage;
    }

    @action
    set openAlertMessage(value: any) {
        this._openAlertMessage = value;
    }

    get alertMessage() {
        return this._alertMessage;
    }

    @action
    set alertMessage(value: any) {
        this._alertMessage = value;
    }

    get alertTitle() {
        return this._alertTitle;
    }

    @action
    set alertTitle(value: any) {
        this._alertTitle = value;
    }

    get patientData() {
        return this._patientData;
    }

    @action
    set patientData(value: any) {
        this._patientData = value;
    }

    get patientMyocorrectRegistration() {
        return this._patientMyocorrectRegistration;
    }

    @action
    set patientMyocorrectRegistration(value: any) {
        this._patientMyocorrectRegistration = value;
    }

    get patientId() {
        return this._patientId;
    }

    @action
    set patientId(value: any) {
        this._patientId = value;
    }

    get addresses() {
        return this._addresses;
    }

    @action
    set addresses(value: any) {
        this._addresses = value;
    }

    get patientHistory(): PatientHistory {
        return this._patientHistory;
    }

    @action
    set patientHistory(value: PatientHistory) {
        this._patientHistory = value;
    }

    get legalGuardians() {
        return this._legalGuardians;
    }

    @action
    set legalGuardians(value: LegalGuardianData[]) {
        this._legalGuardians = value;
    }

    get activeTab() {
        return this._activeTab;
    }

    @action
    set activeTab(value: number) {
        this._activeTab = value;
    }

    get isEditMode() {
        return this._isEditMode;
    }

    @action
    set isEditMode(value: boolean) {
        this._isEditMode = value;
    }

    get isOpenModal() {
        return this._isOpenModal;
    }

    get treatmentStatusId() {
        return this._treatmentStatusId;
    }

    @action
    set treatmentStatusId(value: number) {
        this._treatmentStatusId = value;
    }

    @action
    set isOpenModal(value: boolean) {
        this._isOpenModal = value;
    }

    setToggleOnEdit(value: boolean) {
        this._isEditMode = !this._isEditMode;
    }

    @action
    handleClickModal = () => {
        this.isOpenModal = !this.isOpenModal;
    };

    handleCloseModal = () => {
        this.isOpenModal = false;
    };

    setPatientId = () => {
        this.patientId = this.patientData.id || this.patientData.personId;

        return this.patientId;
    };

    @action
    loadDetails = async () => {
        this.patientId = this.patientData.personId || this.patientData.id;
        var url = '/patient/' + this.patientId + '/details';
        await Gateway.get(url).then(data => {
            var patient = data as unknown as EditPatient;
            this._addresses = patient.addresses;
            if(patient.legalGuardians!=null){
                this._legalGuardians = patient.legalGuardians.map( lg => ({...lg, deleted : false})); 
            }
            this._patientHistory = patient.patientHistory;
        });
    };

    @action
    loadAllReports = async (): Promise<any> => {
        var url = '/aireports/patient/' + this.patientData.id;
        return await Gateway.get(url);
    };

    @action
    loadMyocorrectRegistration = async (id: number): Promise<any> => {
        await Gateway.get(`myocorrect/patients/registration/${id}`).then(data => {
            this._patientMyocorrectRegistration = data;
        });
    };

    @action
    getItemFromList = (list: any[], id: string, comparetor: number, responseField: string) => {
        var item = _.findIndex(list, f => {
            return f[id] == comparetor;
        });
        return item >= 0 ? list[item][responseField] : '';
    };

    @action
    setActiveTab = (index: number) => {
        this.activeTab = index;
    };

    @action
    setAddressOne = (event: any) => {
        if (this.addresses[0] != null) {
            this.addresses[0].address1 = event.target.value;
        } else {
            const address = {};
            this.addresses[0] = address;
            this.addresses[0].address1 = event.target.value;
        }
    }

    @action
    onEditChange = (event: any) => {
        this.isEditMode = event.target.checked;
    };


    @action
    setLegalGuardianFirstName = (value: string, id:number) => {      
        var itemIndex = this.legalGuardians.findIndex(x=> x.id == id);  
        if (itemIndex != null && itemIndex >=0) {
            this.legalGuardians[itemIndex].firstName = value;
        } else {
            const legalGuardian = new LegalGuardianData({
                id:0,
                firstName: "",
                lastName: "",
                relationshipTypeId: 18,
                emailAddress: "",
                primaryPhone: "",
                altPhone: "",
                deleted: false,
                patientID: 0
            });
            
            legalGuardian.id = id;
            legalGuardian.firstName = value;
            //this.patientData.legalGuardians.push(legalGuardian);
            this.legalGuardians.push(legalGuardian);            
        }
    }

    @action
    onGuardianFirstNameChange = (value:string, id:number) => {
        var itemIndex = this.legalGuardians.findIndex( x => x.id==id);        
        /*
        var lg = this.legalGuardians[itemIndex];
        console.log("onGuardianFirstNameChange",lg.firstName);
        lg.firstName = lg.firstName + value;
        this.legalGuardians.splice(itemIndex, 1, lg);
        */
        
        this.legalGuardians[itemIndex].firstName = value;
        console.log("onGuardianFirstNameChange",value);
        //console.log("onGuardianFirstNameChange",lg.firstName);
    }

    @action
    onEdit = async () => {

        //deleted legal guardians
        this.legalGuardians.filter(lg => lg.deleted && lg.id > 0).forEach((lg) => {
            console.log("delete guardian", lg.id);
            var urlDelete = `Patient/guardian/` + lg.id;
            Gateway.delete(urlDelete, null).then();
        });
        
        var url = 'patient/' + this.patientData.id;
        this.patientData.addresses = this.addresses;        
        this.patientData.legalGuardians = this.legalGuardians.filter(lg => !lg.deleted);        
        console.log("edit guardians", this.patientData.legalGuardians);
        this.setToggleOnEdit(false);
        await Gateway.post(url, this.patientData).then(            
        );  
        await store.patientStore.handleTreatmentStatusChange(this.patientData.id, this.patientData.treatmentStatusId);      
    };

    @action
    healthOptionExists = (options: number, option: number): boolean => {
        return (options | option) === 1 ? true : false;
    };

    @action
    onSaveGuardians = () => { 
        console.log("save guardians");
    };

    @action
    onGuardianDelete = async (id: number) => { 
        console.log("Delete guardian: " + id);
        var itemIndex = this.legalGuardians.findIndex( x => x.id==id);        
        var lg = this.legalGuardians[itemIndex];
        lg.deleted = true;
        this.legalGuardians.splice(itemIndex, 1, lg);
        
        /*
        this.setisloading(true);
        var url = `Patient/guardian/` + id;

        await Gateway.delete(url, null).then(res => {
            runInAction(() => {
                this.setisloading(false);
                this.legalGuardians = this.legalGuardians.filter(x => x.id==id);
            });
        });
        */

    };

    @action
    onEmptyGuardianAdd = async() => { 
        
        var randomId =  (Math.floor(Math.random()* (99999 - 1 + 1)) + 1) * -1;
        console.log("add empty guardian", randomId );
        var lg = new LegalGuardianData({id: randomId, firstName: "", lastName: "", relationshipTypeId: 18, altPhone: "",  primaryPhone: "", emailAddress: "", deleted: false, patientID:this.patientData.personId});
        this.legalGuardians = [...this.legalGuardians, lg];                
        
        /*
        var randomId =  (Math.floor(Math.random()* (99999 - 1 + 1)) + 1) * -1;
        console.log("add empty guardian", randomId );
        var lg = new LegalGuardianData({id: randomId, firstName: "", lastName: "", relationshipTypeId: 18, 
            altPhone: "",  primaryPhone: "", emailAddress: "", deleted: false, patientID: this.patientData.patientID });
        //this.legalGuardians = [...this.legalGuardians, lg];                

        var url = 'patient/guardian';
        this.setToggleOnEdit(false);
        await Gateway.post(url, lg).then();        
        this.legalGuardians = [...this.legalGuardians, lg]; 
        */
    };

    @action
    onGuardianAdd = (guardianToAdd: LegalGuardianData) => { 
        console.log("add guardian");
    };

    @action
    clearPatient = () => {
        this.patientData = null;
        this.legalGuardians = [];
        this.addresses = [];
    };

    @action
    sendCustomForm = async (patientForms: any) => {
        this.setisloading(true);
        var url = `Patient/${patientForms.patientId}/customforms/send`;

        await Gateway.post(url, patientForms).then(res => {
            runInAction(() => {
                this.setisloading(false);
            });
        });
    };
}
