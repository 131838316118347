// Vendor
import React, { Fragment, useEffect, useState } from 'react';
import {
    Box,
    Grid,
    IconButton,
    LinearProgress,
    Button,
    Modal,
    TextareaAutosize,
    Typography,
    styled,
    MenuItem
} from '@mui/material';
import {
    GridFilterItem, GridFilterInputValue
} from '@mui/x-data-grid';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Close, FileDownload, FileUpload } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

// Components
import DataGrid from '../../components/DataGrid/DataGrid';
import RadiologySvg from '../../imgs/thumbnails/radiology report_tn.jpg';
import FrontalAnalysis from '../../imgs/thumbnails/Frontal-Analysis_tn.jpg';
import SassouniImg from '../../imgs/thumbnails/Sassouni_tn.jpg';
import Print3DSvg from '../../imgs/thumbnails/3d-printed-airway_tn.jpg';
import ComparisonSvg from '../../imgs/thumbnails/Comparison-AI-Report_tn.jpg';
import StandardSvg from '../../imgs/thumbnails/Standard-AI-Report_tn.jpg';
import ModalContainer from '../../components/ModalContainer';
import { AIReportEntry } from '../../modals/AIReportEntry/AIReportEntry';
import SearchBar from '../../components/UI/SearchBar';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from './components/PageHeader';
import { TechInfoCards } from './components/TechInfoCards';
import Label from '../../components/UI/Label';

// Entities
import { AIReportStatus, AIReportsFilters } from '../../entities/Enums';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import _ from 'lodash';
import { Gateway } from '../../api/Gateway';
import { GlobalUtils } from '../../api/GlobalUtils';
import Papa from 'papaparse';
import moment from 'moment';

// Styling
import '../../scss/_AIReport.scss';
import CssSelectField from '../../components/CssSelectField';



const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    boxShadow: 24,
    bgcolor: 'background.paper',
    p: 2,
};

const getAiReportStatusLabel = (aiReportStatus: AIReportStatus): JSX.Element => {
    const map = {
        'Pending Information': {
            text: 'Pending Information',
            color: 'warning'
        },
        'Pending Review': {
            text: 'Pending Review',
            color: 'warning'
        },
        'Ready To Submit': {
            text: 'Pending Information',
            color: 'warning'
        },
        Submitted: {
            text: 'Submitted',
            color: 'primary'
        },
        Assigned: {
            text: 'Assigned',
            color: 'info'
        },
        Processing: {
            text: 'Processing',
            color: 'info'
        },
        Completed: {
            text: 'Completed',
            color: 'success'
        }
    };
    const { text, color }: any = map[aiReportStatus];

    return (
        <Label color={color}>
            <b>{text}</b>
        </Label>
    );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const DAYSINQUEUEOK = 3;
const DAYSINQUEUEWARN = 6;
interface csvValues {
    name: string;
    xValue?: string;
    yValue?: string;
    zValue?: string;
}

interface IReportProps {
    userRoles: any[];
}

export const AIReportsComponent = (props: IReportProps) => {
    const [modalText, setModalText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [selectedSubmissionId, setSelectedSubmissionId] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const store = useMainStoreContext().aiReportsStore;

    const { loadDataValues } = useMainStoreContext().measurementsData;
    const { resetSubmission, triggerShowAiReportModal, setTriggerShowAiReportModal, setSubmissionId } =
        useMainStoreContext().aiReportEntryStore;

    useEffect(() => {
        store.didInitAIReports = true;
        store.loadAIReports();
        store.loadTechnicianData();
        store.setAiReportStatus(GlobalUtils.getAIReportStatuses());
    }, [store.loadAIReports, store.setAiReportStatus]);

    useEffect(() => {
        store.loadAIReports();
    }, [store.filter])

    const downloadImages = async submissionId => {
        const urls = await Gateway.get(`aireports/images/${submissionId}`);
        urls.forEach(url =>
            setTimeout(function () {
                var a = document.createElement('a');
                a.setAttribute('href', url);
                document.body.appendChild(a);
                a.setAttribute('download', '');
                a.setAttribute('target', '_blank');
                a.click();
                a.remove();
            }, 500),
        );
    };

    const handleModalClose = () => {
        resetSubmission();
    };

    const handleUpload = async submissionId => {
        var fileUpload = document.createElement('input');
        fileUpload.setAttribute('type', 'file');
        document.body.appendChild(fileUpload);
        fileUpload.addEventListener('change', ev => {
            uploadFile(fileUpload, ev, submissionId);
        });
        fileUpload.click();
    };

    const uploadFile = (uploadElement: HTMLInputElement, ev: any, submissionId: number) => {
        var reader = new FileReader();
        reader.onload = async event => {
            if (event.target) {
                await Gateway.post(`aireports/images/${submissionId}`, { file: event.target?.result });
            }
            uploadElement.remove();
        };
        reader.readAsText(ev.target.files[0]);
    };

    const showCSVModal = (submissionId: number) => {
        setSelectedSubmissionId(submissionId);
        setShowModal(true);
    };
    const parseCSV = async modalText => {
        setIsSaving(true);
        const rows = modalText.split('\n');
        let values: csvValues[] = [];
        Papa.parse(modalText, {
            header: true,
            skipEmptyLines: 'greedy',
            complete: function (results) {
                let index = 0;
                let dataSet = results.data.map(obj => {
                    return {
                        name: obj[Object.keys(obj)[0]],
                        xValue: obj[Object.keys(obj)[1]] !== undefined ? obj[Object.keys(obj)[1]] : 'N/A',
                        yValue: obj[Object.keys(obj)[2]] !== undefined ? obj[Object.keys(obj)[2]] : 'N/A',
                        zValue: obj[Object.keys(obj)[3]] !== undefined ? obj[Object.keys(obj)[3]] : 'N/A',
                    };
                });
                values = dataSet;
            },
        });

        await Gateway.post(`aireports/${selectedSubmissionId}/visit/${store.selectedVisitRecordId}/csv/${store.userID}`, values).then(() => {
            setIsSaving(false);
            setModalText('');
            setShowModal(false);
            loadDataValues(selectedSubmissionId);
        });
    };


    const columns = [
        {
            field: 'id',
            headerName: 'Report ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            type: 'number',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'aireport.dashboard.cell.toggleAIReportModel.' + params.row.id}
                                variant="text"
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setSubmissionId(params.row.id);
                                    setSelectedSubmissionId(params.row.id);
                                    setTriggerShowAiReportModal(true);
                                }}
                            >
                                {params.row.id}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patientName',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'providerName',
            headerName: 'Provider',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                var a1 = _.find(store.aiStatus, (a) => { return a.name === param1.value });
                var a2 = _.find(store.aiStatus, (a) => { return a.name === param2.value });
                var ad1 = a1 ? (a1?.name) : "";
                var ad2 = a2 ? (a2?.name) : "";
                return ad1.localeCompare(ad2);
            },
            valueOptions: store.aiStatus.map((a) => { return a.name }),
            filterOperators: [
                {
                    value: 'is',

                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            var status = _.find(store.aiStatus, (a) => { return (a.name) === filterItem.value });
                            if (!_.isNull(status) && !_.isUndefined(status)) {
                                return status.name === value;
                            }
                            return false;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            renderCell: params => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'aiReportStatus.dashboard.cell.statusId.' + params.row.ID}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Status"
                            value={params.row.statusId ? params.row.statusId : 0}
                            onChange={e => {
                                params.row.statusId = e.target.value;
                                store.handleStatusChange(params.row.id, e.target.value);
                            }}
                        >
                            {store.aiStatus.map((item: any) => (
                                <MenuItem key={item.id + item.name} value={item.id}>
                                    {getAiReportStatusLabel(item.name)}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            },
        },
        {
            field: 'reportTypeStatus',
            headerName: 'Report Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <figure style={{ zIndex: 100 }} className="gridFigure">
                        {params.row.reportType === 'Standard AI Report' && (
                            <img src={StandardSvg} style={{ width: "35px", height: "35px" }} className={'svgGrid'} />
                        )}
                        {params.row.reportType === 'Comparison AI Report' && (
                            <img
                                src={ComparisonSvg}
                                className={'svgGrid '}
                                style={{ filter: 'hue-rotate(90deg)', width: "35px", height: "35px" }}
                            />
                        )}
                        <figcaption>
                            <b>{params.row.reportType}</b>
                            <br />
                            <b>Accepted On:</b> {params.row.createdOn}
                            <br />
                            <b>Updated On:</b> {params.row.modifiedOn}
                            <br />
                            <b>Status:</b> {params.row.status}
                        </figcaption>
                    </figure>
                );
            },
        },
        {
            field: 'aiReport',
            headerName: 'AddOn Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                let addons = params.row.addOns ? JSON.parse(params.row.addOns) : null;
                if (addons === null) {
                    addons = [];
                }
                return (
                    <figure style={{ zIndex: 100 }}>
                        {addons.map((item: any) => {
                            if (item.IsActive === true) {
                                if (item.AddOnName === 'Radiology') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >
                                            <img style={{ width: "35px", height: "35px" }} src={RadiologySvg} />
                                        </HtmlTooltip>
                                    );
                                }

                                if (item.AddOnName === 'Sassouni') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >
                                            <img style={{ width: "35px", height: "35px" }} src={SassouniImg} />
                                            {/* <SassouniSvg width={35} height={35}></SassouniSvg> */}
                                        </HtmlTooltip>
                                    );
                                }

                                if (item.AddOnName === '3D Printed Airway') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >

                                            <img style={{ width: "35px", height: "35px" }} src={Print3DSvg} />
                                        </HtmlTooltip>
                                    );
                                }

                                if (item.AddOnName === 'Frontal Analysis') {
                                    return (
                                        <HtmlTooltip
                                            title={
                                                <React.Fragment>
                                                    <b>{item.AddOnName}</b>
                                                    <br />
                                                    <b>Accepted On:</b> {params.row.createdOn}
                                                    <br />
                                                    <b>Updated On:</b> {params.row.modifiedOn}
                                                    <br />
                                                    <b>Status:</b> {item.AddOnStatusName}
                                                </React.Fragment>
                                            }
                                        >
                                            <img style={{ width: "35px", height: "35px" }} src={FrontalAnalysis} />
                                        </HtmlTooltip>
                                    );
                                }
                            }
                        })}
                    </figure>
                );
            },
        },
        {
            field: 'technicianId',
            type: 'singleselect',
            sortComparator: (v1, v2, param1, param2) => {
                var a1 = _.find(store.technician, (a) => { return a.userID == param1.value });
                var a2 = _.find(store.technician, (a) => { return a.userID == param2.value });
                var ad1 = (a1?.firstName + " " + a1?.lastName);
                var ad2 = (a2?.firstName + " " + a2?.lastName);
                return ad1.localeCompare(ad2);
            },
            valueOptions: store.technician.map((a) => { return a.firstName + " " + a.lastName }),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            var advisor = _.find(store.technician, (a) => { return (a.firstName + " " + a.lastName) === filterItem.value });
                            if (!_.isNull(advisor) && !_.isUndefined(advisor)) {
                                return advisor.userID === value;
                            }
                            return false;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            headerName: 'Technician',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <CssSelectField
                            id={'aiReportTechnician.dashboard.cell.assignedTechnicianId.' + params.row.ID}
                            sx={{ border: '0px' }}
                            defaultValue="0"
                            label="Select a Technician"
                            value={params.row.technicianId ? params.row.technicianId : 0}
                            onChange={e => {
                                params.row.technicianId = e.target.value;
                                store.handleChangeTechnician(params.row.id, e.target.value);
                            }}
                        >
                            <MenuItem key={0} value={0}>
                                {'Select a Technician...'}
                            </MenuItem>
                            {store.technician.map((item: any) => (
                                <MenuItem
                                    key={item.userID + item.lastName}
                                    value={item.userID}
                                >
                                    {item.firstName + ' ' + item.lastName}
                                </MenuItem>
                            ))}
                        </CssSelectField>
                    </Grid>
                );
            },
        },
        // {
        //     field: 'radiologist',
        //     headerName: 'Radiologist',
        //     flex: 1,
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     align: 'center',
        // },
        {
            field: 'daysInQueue',
            headerName: 'Days in Queue',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            type: 'number',
            renderCell: params => {
                const days = moment(new Date()).diff(params.row.createdOn, "days");
                const statusstyle = days <= DAYSINQUEUEOK ? "statusgreen" : (days > DAYSINQUEUEOK && days <= DAYSINQUEUEWARN) ? "statusorange" : "statusred";
                return (
                    <Grid className={statusstyle}>
                        {moment(new Date()).diff(params.row.createdOn, "days")}
                    </Grid>
                );
            },
        },
    ];

    return (
        <>
            <PageTitleWrapper>
                <PageHeader
                    numCompletedReports={0}
                    onCompletedFilterClick={undefined}
                    completedFilter={0}
                    currentFilter={undefined}
                >
                </PageHeader>
            </PageTitleWrapper>
            <Grid container paddingX={4} >
                <Grid item xs={12}>
                    <TechInfoCards />
                </Grid>
                <Grid item xs={12} padding={2}>
                    <SearchBar
                        onChange={store.getSearchData}
                        onClear={store.clearData}
                        onCancel={store.clearData}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid id={'aireporttech.dashboard' + store.userID} paddingX={1}>
                        {store.isLoadingAIReports && <LinearProgress variant="determinate" value={store.loadingProgress} />}
                        <DataGrid
                            data={store.aiReportData}
                            columns={columns}
                            loading={store.isLoadingAIReports}
                            onPageChange={(pageNumber) => {
                                store.currentPage = pageNumber;
                                store.loadAIReports();
                            }}
                            onSortModelChange={(sortObj) => {
                                store.onSortChange(sortObj);
                                store.loadAIReports();
                            }}
                            onFilterChange={(filterObj) => {
                                store.onFilterChange(filterObj);
                                store.loadAIReports();
                            }}
                            pagination={true}
                            pageSize={store.pageSize}
                            rowCount={store.totalAIReports}
                            serverSidePagination={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <ModalContainer
                noScroll={true}
                show={triggerShowAiReportModal}
                title={'AI Report Data Entry'}
                onClose={() => {
                    setTriggerShowAiReportModal(false);
                    handleModalClose();
                }}
                onClick={() => { }}
            >
                <AIReportEntry
                    aiReport={selectedRow}
                    submissionId={selectedSubmissionId}
                    onClose={() => { }}
                    showCSVModal={showCSVModal}
                />
            </ModalContainer>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box sx={modalStyle}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h6">Please Paste the Dolphin values</Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="right">
                            <IconButton onClick={() => setShowModal(false)}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box
                        style={{
                            width: '100%',
                            backgroundColor: 'white',
                            height: '100%',
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <TextareaAutosize
                            onChange={ev => setModalText(ev.target.value)}
                            style={{ width: 700 }}
                            minRows={17}
                            maxRows={17}
                        />
                    </Box>
                    <Grid container style={{ backgroundColor: 'white' }}>
                        <Grid item xs={12} display="flex" justifyContent="right">
                            {isSaving &&
                                <LoadingButton className={"vivButton large"}
                                    color="secondary"
                                    variant="outlined"
                                    loading={isSaving}
                                    loadingPosition="start"
                                    loadingIndicator="Parsing CSV Data"
                                >

                                </LoadingButton>}
                            {!isSaving &&
                                <Box padding={1}>
                                    <Button variant='contained' onClick={() => {

                                        parseCSV(modalText);
                                    }}>
                                        Save
                                    </Button></Box>}

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
export const AIReports = observer(AIReportsComponent);
