import React, { useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    ListItemButton,
    Tab,
    Tabs,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    Divider,
    ListItemText,
    Grid,
    useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from '@mui/lab';

// Document Links
import {
    turningScrew,
    carePredelivery,
    careAdjustments,
    careOrderingRequirements,
    applianceBrochure,
    careIFU,
    guidesITD,
    guidesIFU,
    vidaITD,
    vidaIFU,
    versaEmaIFU,
    versa9IFU,
    pexITD,
    pexRemakePolicy,
    guideMeasurement,
    carevidaSleepITD,
    careRxDesign,
    pexIFU,
    vidaSleepIFU,
} from './Helper/documents';

const allProviderTypes = [
    "VIP",
    "Lifeline",
    "Guided Growth and Development",
    "C.A.R.E",
    "Airway Health Solutions",
    "Sleep Well Journey",
    "Noum",
    "Lincare",
    "Certified Legacy Provider",
    "Colorado Sleep Institute",
    "Clinical Advisor"
]

const BoxComposed = styled(Box)(
    ({ theme }) => `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${theme.palette.common.white}; // Set background to white
    border: 1px solid ${theme.palette.divider}; // Add a border
    border-radius: ${theme.general.borderRadius};
    min-height: 200px;
    max-width: 300px; // Reduce the width of the box
    margin: auto; // Center the box horizontally
    box-shadow: 0px 4px 6px ${theme.palette.action.disabledBackground}; // Optional shadow for depth
  `
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
    position: relative;
    z-index: 7;
    padding: ${theme.spacing(2)}; // Reduced padding for better fit
    text-align: center;
    color: ${theme.palette.primary.contrastText};
  `
);

const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
    overflow: visible;
    .MuiTabs-scroller {
      overflow: visible !important;
    }
    .MuiButtonBase-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(12)};
      margin-right: ${theme.spacing(2)};
    }
  `
);

const ListItemButtonWrapper = styled(ListItemButton)(
    ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
        transform: scale(1);
        background: ${theme.colors.alpha.white[100]};
        position: relative;
        z-index: 5;
    &:hover {
        border-radius: ${theme.general.borderRadius};
                    background: ${theme.colors.secondary.lighter};
                    z-index: 6;
                    box-shadow: 
                        0 0.56875rem 3.3rem ${(theme.colors.alpha.black[100], 0.05)},
                        0 0.9975rem 2.4rem ${(theme.colors.alpha.black[100], 0.07)},
                        0 0.35rem 1rem ${(theme.colors.alpha.black[100], 0.1)},
                        0 0.225rem 0.8rem ${(theme.colors.alpha.black[100], 0.15)};
         transform: scale(1.03);
    }
  `
);
const documentGroups = [
    // DNA Document Group
    {
        name: "DNA",
        titleDisplay: {
            type: "image", // Specifies this is an image
            value: "/logos/appliances/DNA.png"},
        providerAccess: ["VIP", "C.A.R.E", "Noum", "Lincare", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/DNA.png",
        documents: [
            {
                id: 1,
                name: "What is the Vivos DNA Appliance?",
                link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/DNA/2024.4_FINALWhat is the Vivos DNA _Educational Flyer.pdf", type: "link",
                description: ''
            },
            { id: 2, name: "C.A.R.E. Appliance Ordering Requirements", link: careOrderingRequirements, type: "link", description: '' },
            //{ id: 3, name: "Predelivery Checklist: CARE Appliances", link: carePredelivery , type: "link", description: '' },
            { id: 3, name: "Adjusting a C.A.R.E. Appliance: Video Tutorial", link: careAdjustments, type: "link", description: '' },
            { id: 4, name: "DNA Appliances: Provider Instructions", link: carevidaSleepITD, type: "link", description: '' },
            { id: 5, name: "DNA Appliances: Patient Instructions for Use", link: careIFU, type: "link", description: '' },
            { id: 6, name: "C.A.R.E. Appliance Design Reference Sheet", link: careRxDesign, type: "link", description: '' },
            {
                id: 7, name: "Commonly Asked Questions", link: "", type: "popup",
                description: () => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography lineHeight="2" variant="h4">
                                How do I expand/turn the screw on a C.A.R.E. Appliance Device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                To expand a C.A.R.E. Appliance (non-wireframe), locate the expansion screw, insert the key
                                into the keyhole, and turn the key in the direction of the arrows.
                                Click <a href={turningScrew} target="_blank"><b>HERE</b></a> for an
                                informative video on this process.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="1" variant="h4">
                                How often should patients turn their expansion screws on a DNA Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Turning should depend on actual wear time. If a patient gets 12 hours of appliance wear time
                                per day, on average the turns should be 1 turn every week, or a ½ turn per week if wear time
                                is less. Anterior turning responds more slowly, so on average, anterior turns on a 12-hour per
                                day basis should be a ½ turn every week, or 1 turn every other week.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                How often should patients wear their C.A.R.E. Appliance device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Wear time protocols vary from patient to patient; however, common practice is to wear C.A.R.E. Appliances for 10-12 hours a day.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
    // MRNA Document Group
    {
        name: "mRNA",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/mRNA.png"},
        providerAccess: ["VIP", "C.A.R.E", "Noum", "Lincare", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/mRNA.png",
        documents: [
            { id: 10, name: "What is the Vivos mRNA Appliance?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/mRNA/2024.4_FINALWhat is the Vivos mRNA _Educational Flyer.pdf", type: "link", description: '' },
            { id: 11, name: "What is the Dorsal Style mRNA?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/mRNA/2022.12 - mRNA - Intro to Dorsal Option Video.mp4", type: "link", description: '' },
            { id: 12, name: "C.A.R.E. Appliance Ordering Requirements", link: careOrderingRequirements, type: "link", description: '' },
            //{ id: 2, name: "Predelivery Checklist: CARE Appliances", link: carePredelivery , type: "link", description: '' },
            { id: 13, name: "Adjusting a C.A.R.E. Appliance: Video Tutorial", link: careAdjustments, type: "link", description: '' },
            { id: 14, name: "mRNA Appliances: Provider Instructions", link: carevidaSleepITD, type: "link", description: '' },
            { id: 15, name: "mRNA Appliances: Patient Instructions for Use", link: careIFU, type: "link", description: '' },
            { id: 16, name: "C.A.R.E. Appliance Design Reference Sheet", link: careRxDesign, type: "link", description: '' },
            {
                id: 17, name: "Commonly Asked Questions", link: "", type: "popup",
                description: () => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography lineHeight="2" variant="h4">
                                How do I titrate an mRNA Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Beginning on either side of the appliance, insert the key into the titration screw. Move the key in the direction of the arrow shown on the device. Repeat this process on the opposite side for an equal adjustment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="1" variant="h4">
                                How do I expand/turn the screw on a C.A.R.E. Appliance Device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                To expand a C.A.R.E. Appliance (non-wireframe), locate the expansion screw, insert the key into the
                                keyhole, and turn the key in the direction of the arrows. Click <a href={turningScrew} target="_blank"><b>HERE</b></a> for an informative video on this process.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                How often should patients turn their expansion screws on an mRNA Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Turning should depend on actual wear time. If a patient gets 12 hours of appliance wear time per
                                day, on average the turns should be 1 turn every week, or a ½ turn per week if wear time is less.
                                Anterior turning responds more slowly, so on average, anterior turns on a 12-hour per day basis
                                should be a ½ turn every week, or 1 turn every other week.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                How often should patients wear their C.A.R.E. Appliance device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Wear time protocols vary from patient to patient; however, common practice is to wear C.A.R.E.
                                Appliances for 10-12 hours a day.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
    // MMRNA Document Group
    {
        name: "mmRNA",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/mmRNA.png"},
        providerAccess: ["VIP", "C.A.R.E", "Noum", "Lincare", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/mmRNA.png",
        documents: [
            { id: 20, name: "What is the Vivos mmRNA Appliance?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/mmRNA/2024.4_FINALWhat is the Vivos mmRNA _Educational Flyer.pdf", type: "link", description: '' },
            { id: 21, name: "C.A.R.E. Appliance Ordering Requirements", link: careOrderingRequirements, type: "link", description: '' },
            //{ id: 23, name: "Predelivery Checklist: CARE Appliances", link: carePredelivery , type: "link", description: '' },
            { id: 22, name: "Delivery Tips for the mmRNA", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/CARE Appliances/mmRNA/2023.7 Delivery Tips for the mmRNA Appliance.pdf", type: "link", description: '' },
            { id: 23, name: "Adjusting a C.A.R.E. Appliance: Video Tutorial", link: careAdjustments, type: "link", description: '' },
            { id: 24, name: "mmRNA Appliances: Provider Instructions", link: carevidaSleepITD, type: "link", description: '' },
            { id: 25, name: "mmRNA Appliances: Patient Instructions for Use", link: careIFU, type: "link", description: '' },
            { id: 26, name: "C.A.R.E. Appliance Design Reference Sheet", link: careRxDesign, type: "link", description: '' },
            {
                id: 27, name: "Commonly Asked Questions", link: "", type: "popup",
                description: () => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography lineHeight="2" variant="h4">
                                How do I titrate an mmRNA Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Beginning on either side of the appliance, insert the key into the Herbst telescopic arm next to the
                                negative (-) sign. Move the key up towards the plus sign (+). Repeat this process on the opposite side
                                for an equal adjustment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="1" variant="h4">
                                How do I expand/turn the screw on a C.A.R.E. Appliance Device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                To expand a C.A.R.E. Appliance (non-wireframe), locate the expansion screw, insert the key into the keyhole,
                                and turn the key in the direction of the arrows. Click <a href={turningScrew} target="_blank"><b>HERE</b></a> for an informative video on this process.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                How often should patients turn their expansion screws on an mmRNA Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Turning should depend on actual wear time. If a patient gets 12 hours of appliance wear time per day, on
                                average the turns should be 1 turn every week, or a ½ turn per week if wear time is less. Anterior turning
                                responds more slowly, so on average, anterior turns on a 12-hour per day basis should be a ½ turn every
                                week, or 1 turn every other week.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                How often should patients wear their C.A.R.E. Appliance device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Wear time protocols vary from patient to patient; however, common practice is to wear C.A.R.E. Appliances
                                for 10-12 hours a day.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
    // Guide Document Group
    {
        name: "Guide",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/Guide.png"},
        providerAccess: ["VIP", "Guided Growth and Development", "Lincare", "Airway Health Solutions", "Sleep Well Journey", "Noum", "Certified Legacy Provider", "Lifeline", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/Guides.png",
        documents: [
            //{ id: 10, name: "What appliances are offered in the Vivos Guide System?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Guides/2023.09 - Vivos Guide Product Overview.pdf", type: "link", description: '' },
            { id: 30, name: "Vivos Guide Measurement Guide", link: guideMeasurement, type: "link", description: '' },
            { id: 31, name: "What is the Vivos VGx", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Guides/2023.06 - VGx Introduction Video to VIPs.mp4", type: "link", description: '' },
            { id: 32, name: "Guides: Provider Instructions", link: guidesITD, type: "link", description: '' },
            { id: 33, name: "Guides: Patient Instructions for Use", link: guidesIFU, type: "link", description: '' },
            {id: 35, name: "Commonly Asked Questions", link: "", type: "popup",
                description: () => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography lineHeight="2" variant="h4">
                                When should I move my patient out of their vStarter Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                The vStarter appliance is important in establishing tongue posture, nasal breathing, and peaceful sleep,
                                as well as being instrumental in breaking a bedwetting habit. Once all of these situations are adequetly
                                addressed, and the patient has all four upper incisors erupted, it would be appropriate to move them into
                                the proper size VG/VGx Guide.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="1" variant="h4">
                                My patient has a crossbite. Will Vivos Guide appliances work for them?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                A Vivos vStarter can correct a one-sided crossbite or a mild anterior crossbite if worn properly. Some
                                cases may require the addition of composite risers for effectiveness. Your Advisor can help you make
                                the decision on a case-by-case basis.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                My patient has severe malocclusion. Will Vivos Guide appliances work for them?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                There are some patients who present too crowded and narrow to tolerate the wear of a Guide. This could
                                be an opportunity to develop the patient with a PEx bonded appliance, to increase transpalatal width and
                                potentially alleviate much of the anterior crowding. At the end of PEx treatment (usually 6-8 months),
                                we recommend about 4 weeks of passive wear after the final turn, and then removal of the PEx and placing
                                the patient in a Guide. Depending on tongue posture, ability to nasal breathe, and depth of bite or
                                presence of any crossbites, the patient could then move into a vStarter or VG, depending on where they
                                are in the process.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
    // PEx Document Group
    {
        name: "PEx",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/PEx.png"},
        providerAccess: ["VIP", "Guided Growth and Development", "Lincare", "Noum", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/PEx.png",
        documents: [
            { id: 40, name: "What is the Vivos PEx?", date: "04.24", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/PEx/2024.4_FINALWhat is the Vivos PEx _Educational Flyer.pdf", type: "link", description: '' },
            { id: 41, name: "PEx Remake Policy", link: pexRemakePolicy, type: "link", description: '' },
            { id: 42, name: "The PEx: Provider Instructions", link: pexITD, type: "link", description: '' },
            { id: 43, name: "The PEx: Patient Instructions for Use", link: pexIFU, type: "link", description: '' },
            { id: 44, name: "PEx Appliance Design Reference Sheet", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/PEx/FINAL_PEx Appliance Design Reference Sheet_07.2024.pdf", type: "link", description: '' },
            {
                id: 45, name: "Commonly Asked Questions", link: "", type: "popup",
                description: () => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography lineHeight="2" variant="h4">
                                When should I use a PEx instead of the Vivos Guide Series?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                PEx appliances are great for patients who are intolerant to Vivos Guides or removable appliances. They
                                are also great options for patients who struggle with compliance, or parents who are less engaged and
                                may struggle to control the child’s compliance.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="1" variant="h4">
                                Are PEx’s available for both maxillary and mandibular arches?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Yes, PEx options are available for both upper and lower arches.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                My patient is currently in a Vivos Guide appliance, but I plan to order a PEx device for them. Should
                                they stop wearing their Guide appliance to avoid changes in their dentition prior to the delivery of the PEx?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                It may be wise as we recommend scans for PEx’s are taken as close to the case submission date as possible,
                                and the delivery should occur as soon as the appliance is returned to your practice. Delays have been shown
                                to cause fit issues in growing children.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                How often should I turn the expansion screw in a PEx device?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Our recommendation is one turn per week. If a child is in a growth spurt, 2 turns over 7 days is acceptable
                                if you are confident that your patient can tolerate it and it is appropriate. Any other choice of multiple
                                turns is considered off-label use for Vivos.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
    // Versa Document Group
    {
        name: "Versa",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/Versa.png"},
        providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Lincare", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/Versa.png",
        documents: [
            { id: 50, name: "What is the Vivos Versa?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Versa/2024.4_FINAL_What is the Versa _Educational Flyer.pdf", type: "link", description: '' },
            { id: 51, name: "The Versa: In Depth Overview", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Versa/2023.8 - In Depth FAQ_What is the Vivos Versa.pdf", type: "link", description: '' },
            { id: 52, name: "The Versa Nylon EMA: Patient Instructions for Use", link: versaEmaIFU, type: "link", description: '' },
            { id: 53, name: "Versa Cleaning Instructions", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Versa/FINAL_Versa Appliance Cleaning Instructions 6.19.24.pdf", type: "link", description: '' },
            { id: 54, name: "The Versa 9: Patient Instructions for Use", link: versa9IFU, type: "link", description: '' },
            { id: 55, name: "The Versa 9: Titration Guide", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Versa/FINAL Versa 9 Titration Guide 2024.06.3.pdf", type: "link", description: '' },
            { id: 56, name: "The Versa 9: Elastics", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Versa/FINAL_Versa 9 Elastics 6.24.pdf", type: "link", description: '' },
        ]
    },
    // Vida Document Group
    {
        name: "Vida",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/Vida.png"},
        providerAccess: ["VIP", "Lifeline", "Noum", "Certified Legacy Provider", "Lincare", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/Vida.png",
        documents: [
            { id: 60, name: "What is the Vivos Vida Appliance?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Vida/2024.4_FINALWhat is the Vida_Educational Flyer.pdf", type: "link", description: '' },
            { id: 61, name: "The Vida: Provider Overview", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Vida/2023.8.17 - Introducing Vida_Provider Overview.pdf", type: "link", description: '' },
            { id: 62, name: "The Vida: Provider Instructions", link: vidaITD, type: "link", description: '' },
            { id: 63, name: "The Vida: Patient Instructions for Use", link: vidaIFU, type: "link", description: '' },
            {
                id: 64, name: "Appliance Design Tips", link: "", type: "popup", description: () => (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontSize={18}>
                                Please schedule an AIS consultation for advice on how to design a Vivos Vida Appliance.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
            {
                id: 65, name: "Commonly Asked Questions", link: "", type: "popup",
                description: () => (
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography lineHeight="2" variant="h4">
                                Can you wear a CPAP with a Vida Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                Yes, you can!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="1" variant="h4">
                                How often should patients wear their Vida Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                We recommend that patients wear their Vida appliance at night when sleeping.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                Can the Vida be worn with other Vivos Appliances?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                The Vivos Vida was made as a stand-alone appliance for the treatment of bruxism, TMD/TMJ, and facial muscle
                                pain and is not recommended to be combined with other appliances.

                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                Which side should I place the unilateral bite block on my Vida Appliance?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                We recommend placing the bite block on the LESS developed side of the patient. If there are missing teeth,
                                implants, or bridges on the less developed side, you would switch to the opposite side as the proprioception
                                factor requires vital teeth for signaling.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                Can I put the unilateral bite block on the patient's more developed side?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                We recommend, if possible, to place the unilateral bite block on the patient’s less developed side. The
                                presence of implants, bridges or missing teeth on that side will require switching sides to allow proper
                                proprioception. The main goal of the Vida device remains to alleviate symptoms of bruxism and TMD/TMJ.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingTop={2}>
                            <Typography lineHeight="2" variant="h4">
                                Can the Vida Appliance help with Tinnitus?
                            </Typography>
                        </Grid>
                        <Grid item xs={12} paddingLeft={4} paddingRight={4}>
                            <Typography lineHeight="2" fontWeight="normal" variant="h4" textAlign={'justify'}>
                                The Vida appliance is not cleared to treat tinnitus. This being said, providers have seen anecdotal improvements
                                of Tinnitus symptoms in their patients while using the Vida Appliance.
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
    // VidaSleep Document Group
    {
        name: "Vida Sleep",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/VidaSleep.png"},
        providerAccess: ["VIP", "Lifeline", "Noum", "Certified Legacy Provider", "Lincare", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/VidaSleep.png",
        documents: [
            { id: 70, name: "What is the Vivos Vida Sleep?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Vida Sleep/2024.4_FINALWhat is the Vida Sleep_Educational Flyer.pdf", type: "link", description: '' },
            { id: 71, name: "Vida Sleep: Provider Instructions", link: carevidaSleepITD, type: "link", description: '' },{ id: 71, name: "Vida Sleep: Provider Instructions", link: carevidaSleepITD, type: "link", description: '' },
            { id: 72, name: "Vida Sleep: Patient Instructions for Use", link: vidaSleepIFU, type: "link", description: '' }
        ]
    },
    // Rescue Document Group
    {
        name: "Rescue",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/Rescue.png"},
        providerAccess: ["VIP", "Lifeline", "Noum", "Airway Health Solutions", "Lincare","Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/Rescue.png",
        documents: [
            { id: 80, name: "What is the Vivos Rescue Appliance?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/Rescue/2024.4_FINALWhat is the Vivos Rescue_Educational Flyer.pdf", type: "link", description: '' },
        ]
    },
    // EMANow Document Group
    {
        name: "EMA-NOW",
        titleDisplay: {
            type: "image",
            value: "/logos/appliances/emaNow.png"},
        providerAccess: ["VIP", "Lifeline", "Airway Health Solutions", "Sleep Well Journey", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"],
        imageLoc: "/images/general/knowledgeBase/EMAnow.png",
        documents: [
            { id: 90, name: "What is the Vivos EMA-NOW Appliance?", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/EMA Now/FINAL_What is the EMA_Now_Educational Flyer_7.25.24.pdf", type: "link", description: '' },
            { id: 91, name: "Delivery Tutorial", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/EMA Now/2022.06 - EMA Now Delivery tutorial Video.mp4", type: "link", description: '' },
        ]
    },
    // General Document Group
    {
        name: "General",
        providerAccess: allProviderTypes,
        imageLoc: "/logos/vivos.png",
        documents: [
            { id: 110, name: "Vivos Appliances", link: applianceBrochure, type: "link", description: '' },
            // { id: 111, name: "Vivos Appliances: Canada Only", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Vivos Appliances/General/2023.12 - CANADAONLY Vivos Appliances Brochure.pdf", type: "link", description: '' },
            {
                id: 112, name: "Where can I find the Material Safety Data Sheets (MSDS) for Vivos Appliances", link: "", type: "popup", description: () => (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography fontSize={18}>
                                Please email our RX Team at rx@vivoslife.com for Material Safety Data Sheets (MSDS).
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            },
        ]
    },
];
function VivosAppliancesContent({ providerTypes, isProvider }) {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState("0");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleTabsChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleDocumentClick = (doc) => {
        if (doc.link) {
            window.open(doc.link, '_blank', 'noopener noreferrer');
        } else {
            setDialogContent(doc.description);
            setOpenDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    // Filter groups based on provider types with substring matching
    const accessibleDocumentGroups = isProvider
        ? documentGroups.filter(group =>
            group.providerAccess.some(accessType =>
                providerTypes.some(providerType => providerType.includes(accessType))
            )
        )
        : documentGroups; // Bypass filtering and show all groups if isProvider is false
        

    return (
        <Box>
            <TabContext value={activeTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabsChange} aria-label="Document Groups Tabs">
                    {accessibleDocumentGroups.map((group, index) => (
                        <Tab
                            label={group.name}
                            value={String(index)}
                            key={index}
                            sx={{
                                fontSize: '16px', // Increase font size
                                // fontWeight: 'bold', // Optional: Make the text bold
                                padding: theme.spacing(1, 3), // Add more padding for spacing
                                textTransform: 'none', // Prevent capitalization
                                minWidth: '200px', // Consistent tab width
                                WebkitFontSmoothing: 'antialiased', // Improves font rendering on webkit browsers
                                MozOsxFontSmoothing: 'grayscale', // Improves font rendering on Firefox
                            }}
                        />
                    ))}
                </TabList>

                </Box>
                {accessibleDocumentGroups.map((group, index) => (
                    <TabPanel value={String(index)} key={index}>
                        <Grid
                            container
                            spacing={1.6}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            {/* Left Column: Image Box */}
                            <Grid item xs={12} md={3}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'left',
                                        backgroundColor: theme.palette.background.paper,
                                        padding: theme.spacing(0),
                                        // borderRight: `1px solid ${theme.palette.divider}`,
                                        minHeight: '340px',
                                    }}
                                >
                                    <Avatar
                                        variant="square"
                                        sx={{
                                            width: '93%',
                                            height: 'auto',
                                            '& img': { objectFit: 'contain' },
                                        }}
                                        src={group.imageLoc}
                                        alt={group.name}
                                    />
                                </Box>
                            </Grid>

                            {/* Right Column: Content */}
                            <Grid item xs={12} md={9}>
                                <Box
                                    sx={{
                                        padding: theme.spacing(3),
                                        backgroundColor: theme.palette.common.white,
                                        border: `1px solid ${theme.palette.divider}`,
                                        borderRadius: theme.general.borderRadius,
                                        boxShadow: theme.shadows[2],
                                        minHeight: '400px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: theme.spacing(2),
                                        }}
                                    >
                                        {group.titleDisplay && group.titleDisplay.type === "image" ? (
                                            <img
                                                src={group.titleDisplay.value}
                                                alt={group.name}
                                                style={{
                                                    height: '70px', // Adjust the height as necessary
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        ) : (
                                            <Typography
                                                variant="h5"
                                                fontWeight="bold"
                                                
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {group.titleDisplay?.value || group.name}
                                            </Typography>
                                        )}
                                    </Box>

                                    <Divider sx={{ marginY: theme.spacing(2) }} />
                                    <List>
                                        {group.documents.map((doc) => (
                                            <ListItemButtonWrapper
                                                key={doc.id}
                                                onClick={() => handleDocumentClick(doc)}
                                                sx={{
                                                    marginBottom: theme.spacing(1),
                                                    padding: theme.spacing(1.5),
                                                    border: `1px solid ${theme.palette.divider}`,
                                                    borderRadius: theme.shape.borderRadius,
                                                    boxShadow: `0px 2px 4px ${theme.palette.action.disabledBackground}`,
                                                }}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography
                                                            variant="h5"
                                                        >
                                                            {doc.name}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButtonWrapper>
                                        ))}
                                    </List>
                                </Box>
                            </Grid>
                        </Grid>
                    </TabPanel>
                ))}
            </TabContext>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography variant="h6">Information</Typography>
                </DialogTitle>
                <DialogContent>{dialogContent}</DialogContent>
            </Dialog>
        </Box>
    );
}

export default VivosAppliancesContent;
