import { makeObservable, observable } from 'mobx';
import { DictionaryListItem } from './dictionarylistitem';

export class TreatmentObjective {
    @observable id: number = 0;
    @observable treatmentPlanId: number = 0;
    @observable objectiveId: number = 0;
    @observable value: string = '';
    @observable objective: DictionaryListItem;

    constructor() {
        makeObservable(this);
        this.objective = new DictionaryListItem();
    }
}
