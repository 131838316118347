import { observable, makeObservable } from "mobx";

/**
 * Utility: Use this pattern in each class to let MobX track fields via decorators.
 * Alternatively, you can use `makeAutoObservable(this)` but shown here is the classic approach.
 */

/** Represents a "money" object { currencyCode, amount }. */
export class Money {
  @observable currencyCode: string | null = null;
  @observable amount: number = 0;

  constructor(data?: Partial<Money>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
    }
  }
}

/** Represents a "money set" object { shopMoney, presentmentMoney }. */
export class MoneySet {
  @observable shopMoney: Money = new Money();
  @observable presentmentMoney: Money = new Money();

  constructor(data?: Partial<MoneySet>) {
    makeObservable(this);
    if (data) {
      if (data.shopMoney) {
        this.shopMoney = new Money(data.shopMoney);
      }
      if (data.presentmentMoney) {
        this.presentmentMoney = new Money(data.presentmentMoney);
      }
    }
  }
}

/** Represents the tax lines on items/shipping. */
export class TaxLine {
  @observable price: number = 0;
  @observable rate: number = 0;
  @observable title: string | null = null;
  @observable priceSet: MoneySet = new MoneySet();

  constructor(data?: Partial<TaxLine>) {
    makeObservable(this);
    if (data) {
      this.price = data.price ?? 0;
      this.rate = data.rate ?? 0;
      this.title = data.title ?? null;
      if (data.priceSet) {
        this.priceSet = new MoneySet(data.priceSet);
      }
    }
  }
}

/** Represents an address object (billing/shipping). */
export class Address {
  @observable address1: string | null = null;
  @observable address2: string | null = null;
  @observable city: string | null = null;
  @observable company: string | null = null;
  @observable country: string | null = null;
  @observable countryCode: string | null = null;
  @observable countryName: string | null = null;
  @observable default: boolean | null = null;
  @observable firstName: string | null = null;
  @observable lastName: string | null = null;
  @observable latitude: number | null = null;
  @observable longitude: number | null = null;
  @observable name: string | null = null;
  @observable phone: string | null = null;
  @observable province: string | null = null;
  @observable provinceCode: string | null = null;
  @observable zip: string | null = null;
  @observable id: number | null = null;
  @observable adminGraphQLAPIId: string | null = null;

  constructor(data?: Partial<Address>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
    }
  }
}

/** Minimal placeholder, since discountAllocations can be an empty array or more complex. */
export class DiscountAllocation {
  constructor(data?: Partial<DiscountAllocation>) {
    // Potential fields go here
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
    }
  }
}

/** Represents a single line item in the order. */
export class LineItem {
  @observable fulfillableQuantity: number = 0;
  @observable fulfillmentService: string | null = null;
  @observable fulfillmentStatus: string | null = null;
  @observable grams: number = 0;
  @observable price: number = 0;
  @observable productId: number | null = null;
  @observable quantity: number = 0;
  @observable requiresShipping: boolean = false;
  @observable sku: string | null = null;
  @observable title: string | null = null;
  @observable variantId: number | null = null;
  @observable variantTitle: string | null = null;
  @observable name: string | null = null;
  @observable vendor: string | null = null;
  @observable giftCard: boolean = false;
  @observable taxable: boolean = false;
  @observable taxLines: TaxLine[] = [];
  @observable tipPaymentGateway: string | null = null;
  @observable tipPaymentMethod: string | null = null;
  @observable tipPaymentGatewaySpecified: boolean = false;
  @observable totalDiscount: number = 0;
  @observable totalDiscountSet: MoneySet = new MoneySet();
  @observable discountAllocations: DiscountAllocation[] = [];
  @observable properties: any[] = [];
  @observable variantInventoryManagement: string | null = null;
  @observable productExists: boolean = false;
  @observable priceSet: MoneySet = new MoneySet();
  @observable preTaxPrice: number | null = null;
  @observable preTaxPriceSet: MoneySet | null = null;
  @observable duties: any[] = [];
  @observable fulfillmentLineItemId: number | null = null;
  @observable attributedStaffs: any[] = [];
  @observable id: number | null = null;
  @observable adminGraphQLAPIId: string | null = null;

  constructor(data?: Partial<LineItem>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
      if (data.taxLines) {
        this.taxLines = data.taxLines.map(t => new TaxLine(t));
      }
      if (data.totalDiscountSet) {
        this.totalDiscountSet = new MoneySet(data.totalDiscountSet);
      }
      if (data.priceSet) {
        this.priceSet = new MoneySet(data.priceSet);
      }
      if (data.preTaxPriceSet) {
        this.preTaxPriceSet = new MoneySet(data.preTaxPriceSet);
      }
    }
  }
}

/** Represents a fulfillment object and its associated line items. */
export class Fulfillment {
  @observable createdAt: string | null = null;
  @observable lineItems: LineItem[] = [];
  @observable orderId: number | null = null;
  @observable receipt: any = {};
  @observable status: string | null = null;
  @observable locationId: number | null = null;
  @observable email: string | null = null;
  @observable notifyCustomer: boolean | null = null;
  @observable destination: any = null;
  @observable trackingCompany: string | null = null;
  @observable trackingNumber: string | null = null;
  @observable trackingNumbers: string[] = [];
  @observable trackingUrl: string | null = null;
  @observable trackingUrls: string[] = [];
  @observable updatedAt: string | null = null;
  @observable variantInventoryManagement: string | null = null;
  @observable service: string | null = null;
  @observable shipmentStatus: string | null = null;
  @observable name: string | null = null;
  @observable originAddress: Address | null = null;
  @observable id: number | null = null;
  @observable adminGraphQLAPIId: string | null = null;

  constructor(data?: Partial<Fulfillment>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
      if (data.lineItems) {
        this.lineItems = data.lineItems.map(li => new LineItem(li));
      }
      if (data.originAddress) {
        this.originAddress = new Address(data.originAddress);
      }
    }
  }
}

/** Represents client details about the browser, userAgent, etc. */
export class ClientDetails {
  @observable acceptLanguage: string | null = null;
  @observable browserHeight: number | null = null;
  @observable browserIp: string | null = null;
  @observable browserWidth: number | null = null;
  @observable sessionHash: string | null = null;
  @observable userAgent: string | null = null;

  constructor(data?: Partial<ClientDetails>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
    }
  }
}

/** Represents note attributes (name/value pairs). */
export class NoteAttribute {
  @observable name: string | null = null;
  @observable value: string | null = null;

  constructor(data?: Partial<NoteAttribute>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
    }
  }
}

/** Represents a customer's email marketing consent. */
export class EmailMarketingConsent {
  @observable state: string | null = null;
  @observable optInLevel: string | null = null;
  @observable consentUpdatedAt: string | null = null;

  constructor(data?: Partial<EmailMarketingConsent>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
    }
  }
}

/** Represents a customer object with nested defaultAddress, etc. */
export class Customer {
  @observable addresses: Address[] | null = null;
  @observable createdAt: string | null = null;
  @observable currency: string | null = null;
  @observable defaultAddress: Address | null = null;
  @observable email: string | null = null;
  @observable firstName: string | null = null;
  @observable multipassIdentifier: string | null = null;
  @observable lastName: string | null = null;
  @observable lastOrderId: number | null = null;
  @observable lastOrderName: string | null = null;
  @observable note: string | null = null;
  @observable ordersCount: number | null = null;
  @observable phone: string | null = null;
  @observable state: string | null = null;
  @observable tags: string | null = null;
  @observable taxExempt: boolean = false;
  @observable taxExemptions: any[] = [];
  @observable totalSpent: number | null = null;
  @observable updatedAt: string | null = null;
  @observable verifiedEmail: boolean = false;
  @observable smsMarketingConsent: any | null = null;
  @observable metafields: any | null = null;
  @observable emailMarketingConsent: EmailMarketingConsent | null = null;
  @observable id: number | null = null;
  @observable adminGraphQLAPIId: string | null = null;

  constructor(data?: Partial<Customer>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
      if (data.defaultAddress) {
        this.defaultAddress = new Address(data.defaultAddress);
      }
      if (data.emailMarketingConsent) {
        this.emailMarketingConsent = new EmailMarketingConsent(
          data.emailMarketingConsent
        );
      }
      if (data.addresses) {
        this.addresses = data.addresses.map(a => new Address(a));
      }
    }
  }
}

/** Represents a shipping line (i.e., selected shipping method). */
export class ShippingLine {
  @observable carrierIdentifier: string | null = null;
  @observable code: string | null = null;
  @observable phone: string | null = null;
  @observable price: number = 0;
  @observable discountedPrice: number = 0;
  @observable discountAllocations: DiscountAllocation[] = [];
  @observable source: string | null = null;
  @observable title: string | null = null;
  @observable taxLines: TaxLine[] = [];
  @observable priceSet: MoneySet = new MoneySet();
  @observable discountedPriceSet: MoneySet = new MoneySet();
  @observable id: number | null = null;
  @observable adminGraphQLAPIId: string | null = null;

  constructor(data?: Partial<ShippingLine>) {
    makeObservable(this);
    if (data) {
      Object.assign(this, data);
      if (data.taxLines) {
        this.taxLines = data.taxLines.map(t => new TaxLine(t));
      }
      if (data.priceSet) {
        this.priceSet = new MoneySet(data.priceSet);
      }
      if (data.discountedPriceSet) {
        this.discountedPriceSet = new MoneySet(data.discountedPriceSet);
      }
      if (data.discountAllocations) {
        this.discountAllocations = data.discountAllocations.map(
          d => new DiscountAllocation(d)
        );
      }
    }
  }
}

/** The main ShopifyOrder class that stitches everything together. */
export class ShopifyOrder {
  @observable appId: number = 0;
  @observable billingAddress: Address = new Address();
  @observable browserIp: string | null = null;
  @observable buyerAcceptsMarketing: boolean = false;
  @observable cancelReason: string | null = null;
  @observable cancelledAt: string | null = null;
  @observable cartToken: string | null = null;
  @observable checkoutToken: string | null = null;
  @observable checkoutId: number | null = null;
  @observable clientDetails: ClientDetails = new ClientDetails();
  @observable closedAt: string | null = null;
  @observable confirmed: boolean = false;
  @observable createdAt: string | null = null;
  @observable currency: string | null = null;
  @observable customer: Customer | null = null;
  @observable customerLocale: string | null = null;
  @observable deviceId: string | null = null;
  @observable discountCodes: any[] = [];
  @observable discountApplications: any[] = [];
  @observable email: string | null = null;
  @observable financialStatus: string | null = null;
  @observable fulfillments: Fulfillment[] = [];
  @observable fulfillmentStatus: string | null = null;
  @observable phone: string | null = null;
  @observable tags: string | null = null;
  @observable landingSite: string | null = null;
  @observable lineItems: LineItem[] = [];
  @observable locationId: number | null = null;
  @observable name: string | null = null;
  @observable note: string | null = null;
  @observable noteAttributes: NoteAttribute[] = [];
  @observable number: number = 0;
  @observable orderNumber: number = 0;
  @observable orderStatusUrl: string | null = null;
  @observable paymentGatewayNames: string[] = [];
  @observable processedAt: string | null = null;
  @observable processingMethod: string | null = null;
  @observable referringSite: string | null = null;
  @observable refunds: any[] = [];
  @observable shippingAddress: Address = new Address();
  @observable shippingLines: ShippingLine[] = [];
  @observable sourceIdentifier: string | null = null;
  @observable sourceName: string | null = null;
  @observable subtotalPrice: number = 0;
  @observable taxLines: TaxLine[] = [];
  @observable taxesIncluded: boolean = false;
  @observable test: boolean = false;
  @observable token: string | null = null;
  @observable totalDiscounts: number = 0;
  @observable totalLineItemsPrice: number = 0;
  @observable totalTipReceived: number = 0;
  @observable totalPrice: number = 0;
  @observable totalTax: number = 0;
  @observable totalWeight: number = 0;
  @observable updatedAt: string | null = null;
  @observable userId: number | null = null;
  @observable transactions: any[] | null = null;
  @observable metafields: any[] | null = null;
  @observable currentTotalDutiesSet: any = null;
  @observable originalTotalDutiesSet: any = null;
  @observable presentmentCurrency: string | null = null;
  @observable totalLineItemsPriceSet: MoneySet = new MoneySet();
  @observable totalDiscountsSet: MoneySet = new MoneySet();
  @observable totalShippingPriceSet: MoneySet = new MoneySet();
  @observable subtotalPriceSet: MoneySet = new MoneySet();
  @observable totalPriceSet: MoneySet = new MoneySet();
  @observable totalOutstanding: string | null = null;
  @observable totalTaxSet: MoneySet = new MoneySet();
  @observable estimatedTaxes: boolean = false;
  @observable currentSubtotalPrice: number = 0;
  @observable currentSubtotalPriceSet: MoneySet = new MoneySet();
  @observable currentTotalDiscounts: number = 0;
  @observable currentTotalDiscountsSet: MoneySet = new MoneySet();
  @observable currentTotalPrice: number = 0;
  @observable currentTotalPriceSet: MoneySet = new MoneySet();
  @observable currentTotalTax: number = 0;
  @observable currentTotalTaxSet: MoneySet = new MoneySet();
  @observable paymentTerms: any = null;
  @observable currentTotalAdditionalFeesSet: any = null;
  @observable originalTotalAdditionalFeesSet: any = null;
  @observable poNumber: string | null = null;
  @observable taxExempt: boolean = false;
  @observable company: string | null = null;
  @observable id: number | null = null;
  @observable adminGraphQLAPIId: string | null = null;

  constructor(data?: Partial<ShopifyOrder>) {
    makeObservable(this);
    if (data) {
      // Bulk-assign all simple fields:
      Object.assign(this, data);

      // Then fix up nested objects/arrays:
      if (data.billingAddress) {
        this.billingAddress = new Address(data.billingAddress);
      }
      if (data.clientDetails) {
        this.clientDetails = new ClientDetails(data.clientDetails);
      }
      if (data.customer) {
        this.customer = new Customer(data.customer);
      }
      if (data.fulfillments) {
        this.fulfillments = data.fulfillments.map(f => new Fulfillment(f));
      }
      if (data.lineItems) {
        this.lineItems = data.lineItems.map(li => new LineItem(li));
      }
      if (data.noteAttributes) {
        this.noteAttributes = data.noteAttributes.map(n => new NoteAttribute(n));
      }
      if (data.shippingAddress) {
        this.shippingAddress = new Address(data.shippingAddress);
      }
      if (data.shippingLines) {
        this.shippingLines = data.shippingLines.map(s => new ShippingLine(s));
      }
      if (data.taxLines) {
        this.taxLines = data.taxLines.map(t => new TaxLine(t));
      }
      if (data.totalLineItemsPriceSet) {
        this.totalLineItemsPriceSet = new MoneySet(data.totalLineItemsPriceSet);
      }
      if (data.totalDiscountsSet) {
        this.totalDiscountsSet = new MoneySet(data.totalDiscountsSet);
      }
      if (data.totalShippingPriceSet) {
        this.totalShippingPriceSet = new MoneySet(data.totalShippingPriceSet);
      }
      if (data.subtotalPriceSet) {
        this.subtotalPriceSet = new MoneySet(data.subtotalPriceSet);
      }
      if (data.totalPriceSet) {
        this.totalPriceSet = new MoneySet(data.totalPriceSet);
      }
      if (data.totalTaxSet) {
        this.totalTaxSet = new MoneySet(data.totalTaxSet);
      }
      if (data.currentSubtotalPriceSet) {
        this.currentSubtotalPriceSet = new MoneySet(data.currentSubtotalPriceSet);
      }
      if (data.currentTotalDiscountsSet) {
        this.currentTotalDiscountsSet = new MoneySet(
          data.currentTotalDiscountsSet
        );
      }
      if (data.currentTotalPriceSet) {
        this.currentTotalPriceSet = new MoneySet(data.currentTotalPriceSet);
      }
      if (data.currentTotalTaxSet) {
        this.currentTotalTaxSet = new MoneySet(data.currentTotalTaxSet);
      }
    }
  }
}
