
import { observer } from "mobx-react";
import { Button, Grid, LinearProgress } from "@mui/material";
import ProviderPatient from "../../../../entities/ProviderPatient";
import React, { Fragment, useState } from "react";
import DataGrid from "../../../DataGrid/DataGrid";
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";
import LinkIcon from '@mui/icons-material/Link';

import moment from "moment";
import ModalContainer from "../../../ModalContainer";
import { SubmitReworkClaimWizard } from "../SubmitReworkClaimWizard";
import { ReworkActionRequiredModal } from "../ReworkActionRequiredModal";
import { ClaimStatus, ErfStatus } from "../../../../entities/Enums";
import { PatientStore } from "../../../../api/PatientStore";
import CommentsEditStore from "../../../CommentsPanel/CommentsEditStore";
import { CommentsPanel } from "../../../CommentsPanel/CommentsPanel";
import _ from "lodash";
import { CreateRxWizard } from "../../../../modals/PrescriptionWizard/CreateRxWizard";
import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog";
import { Prescription } from "../../../../models/state_models/prescription";

interface IGIProps {
    patient: ProviderPatient;
}


export const PatientReworkDashboard: React.FC<IGIProps> = observer(({ patient }) => {

    const [selectedRow, setSelectedRow] = useState<any>({});
    let store = useMainStoreContext().reworkClaimStore;
    const patientStore = useMainStoreContext().patientStore;
    const prescriptionstore = useMainStoreContext().prescriptionStore;
    const [clonedPrescription, setClonedPrescription] = useState<any>({});
    let commentsEdit = new CommentsEditStore();

    React.useEffect(() => {
        store.loadClaimStatusValues();
        store.loadProductionStatusValues();
        store.getClaimsByPatientId(patient.id);
    }, []);

    React.useEffect(() => {
        if (store.isLoadedShipmentLabel)
            downloadPDF(store.shipmentLabel.labelData);
    }, [store.isLoadedShipmentLabel]);

    function downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = `ShipmentLabel_${patient.vivosId}.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        store.setLoadedShipmentLabel(false);
    }

    const [showConfirmClosePrescriptionForm, setShowConfirmClosePrescriptionForm] = React.useState(false);

    const handleCloseModal = () => {
        setShowConfirmClosePrescriptionForm(true);
    };

    const handleCancelClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
    };

    const handleConfirmClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
        patientStore.toggleShowPrescriptionModal = false;
    };

    const handleClonePrescription = (prescriptionId: number) => {
        prescriptionstore.clonePrescription(prescriptionId).then((resp) => {
            setClonedPrescription(resp);
            patientStore.setCurrentPatientId(patient.id);
            patientStore.togglePrescriptionModal();
        })
    };

    const columns = [
        {
            field: 'rxId',
            headerName: 'RX Claim ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={"reworkClaim.dashboard.cell.rxId" + params.row.id}
                                variant="text"
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    store.setShowSubmitReworkWizard(true);
                                }}
                                disabled={params.row.claimStatus !== ClaimStatus["New Submission"]}
                            >
                                {params.row.rxId}
                            </Button>
                        }
                    </Grid>
                );
            },
        },
        {
            field: 'submissionDate',
            headerName: 'Claim Submitted',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.submissionDate" + params.row.id}>
                    {params.row.submissionDate && moment(params.row.submissionDate).format('MM/DD/yyyy')}</Grid>)
            }
        },
        {
            field: 'reviewedOn',
            headerName: 'Reviewed Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.dateSubmitted" + params.row.id}>
                    {params.row.reviewedOn && moment(params.row.reviewedOn).format('MM/DD/yyyy')}</Grid>)
            }
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.appliance" + params.row.id}>{params.row.appliance?.name}</Grid>)
            }
        },
        {
            field: 'claimStatus',
            headerName: 'Claim Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const claimStatus = store.claimStatusValues.find(x => x.id === params.row.claimStatus)?.name;

                return (
                    <Grid id={"reworkClaim.dashboard.cell.claimStatus" + params.row.id}>
                        {claimStatus === "Pending CCR Form" ? "Completed" : claimStatus}
                    </Grid>
                );

            }
        },
        {
            field: 'productionStatus',
            headerName: 'Production Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.productionStatus" + params.row.id}>{store.productionStatusValues.find(x => x.id === params.row.productionStatus)?.name}</Grid>)
            }
        },
        {
            field: 'shippedOn',
            headerName: 'Shipped Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (<Grid id={"reworkClaim.dashboard.cell.dateSubmitted" + params.row.id}>
                    {params.row.shippedOn && moment(params.row.shippedOn).format('MM/DD/yyyy')}</Grid>)
            }
        },
        // {
        //     field: 'documentation',
        //     headerName: 'Documentation',
        //     flex: 1,
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <Grid>
        //                 <Button
        //                     id={"reworkClaim.dashboard.cell.documentation" + params.row.id}
        //                 >
        //                     <LinkIcon id={"reworkClaim.dashboard.cell.documentation" + params.row.id} />
        //                 </Button>
        //             </Grid>
        //         );
        //     },
        // },
        // {
        //     field: 'shippinglabel',
        //     headerName: 'Shipping Label',
        //     flex: 1,
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <Grid>
        //                 <Button
        //                     id={"reworkClaim.dashboard.cell.shippinglabel" + params.row.id}
        //                     onClick={() => {
        //                         store.getShipmentLabel();
        //                     }
        //                     }
        //                 >
        //                     <LinkIcon id={"reworkClaim.dashboard.cell.shippinglabel" + params.row.id} />
        //                 </Button>
        //             </Grid>
        //         );
        //     },
        // },
        {
            field: 'erf',
            headerName: 'ERF',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid container direction="row" justifyContent="center"
                        alignItems="center">
                        <Grid item>
                            {_.isNull(params.row?.erftotalAmount) ? '' : `$${params.row?.erftotalAmount}`}
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid container direction="row" justifyContent="center"
                        alignItems="center">
                        <Grid item>
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    store.handleClaimStatusChange(params.row, ClaimStatus["Pending DOF + ESD"]);
                                    store.handleErfStatusChange(params.row, ErfStatus["Provider Approval"]);
                                    let claim = {
                                        ...params.row,
                                        erfApprovedOn: new Date()
                                    };
                                    store.updateClaimChanges(claim);
                                }}
                                disabled={params.row.claimStatus !== ClaimStatus["Pending ERF Approval"]}
                                sx={{ mr: 1 }}
                            >
                                Approve ERF
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        },
        // {
        //     field: 'action2',
        //     headerName: '',
        //     flex: 1,
        //     headerAlign: 'center',
        //     headerClassName: 'colHeader',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <Grid container direction="row" justifyContent="center"
        //                 alignItems="center">
        //                 {selectedRow.id === params.row.id &&
        //                     <CommentsPanel
        //                         commentType={15552}
        //                         significantId={params.row.id}
        //                         isOpen={commentsEdit.isOpenModal}
        //                         onClose={commentsEdit.handleCloseModal.bind(this)}
        //                     />
        //                 }

        //                 <Button id={"reworkClaim.dashboard.cell.action" + params.row.id}
        //                     onClick={() => {
        //                         setSelectedRow(params.row);
        //                         store.setReworkSelection([]);
        //                         store.setClaimFiles([]);
        //                         store.setProviderNotes("");
        //                         commentsEdit.handleClickModal();
        //                     }}
        //                 >
        //                     Add Comment
        //                 </Button>

        //             </Grid>
        //         );
        //     },
        // },
        {
            field: 'action3',
            headerName: 'Warranty',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid container direction="row" justifyContent="center"
                        alignItems="center">
                        <Grid item>
                            <Button
                                variant={"contained"}
                                onClick={() => {
                                    handleClonePrescription(params.row.prescriptionId);
                                }}
                                disabled={params.row.claimStatus !== ClaimStatus["Completed: Warranty Remake"]}
                                sx={{ mr: 1 }}
                            >
                                Start Warranty RX
                            </Button>
                        </Grid>
                    </Grid>
                );
            },
        }

    ]

    return (
        <Grid>
            <Grid className="grid" id={'aireporttech.dashboard' + 1}>
                {store.isLoadingClaims && <LinearProgress variant="determinate" value={store.loadingClaimsProgress} />}
                <DataGrid
                    columns={columns}
                    loading={store.isLoadingClaims}
                    data={store.claimData}
                />
            </Grid>
            {store.showSubmitReworkWizard && <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={store.showSubmitReworkWizard}
                title={"Submit Rework Claim"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowSubmitReworkWizard(false);
                    store.loadPage();
                }}>
                <SubmitReworkClaimWizard
                    patient={patient}
                    claim={selectedRow}
                    isEdit={true}
                    prescription={prescriptionstore.prescription}
                    onClose={() => {
                        store.setShowSubmitReworkWizard(false);
                        store.loadPage();
                    }} />
            </ModalContainer>}
            {store.showActionRequired && <ModalContainer
                noScroll={true}
                mediumScreen={true}
                extSx={{
                    '& .MuiPaper-root': {
                        height: '300px',
                        width: '500px'
                    }
                }}
                show={store.showActionRequired}
                title={"Action Required"}
                onClick={() => { }}
                onClose={() => {
                    store.setShowActionRequired(false);
                }}>
                <ReworkActionRequiredModal
                    patient={patient}
                    claim={selectedRow}
                    patientStore={patientStore}
                    onClose={() => {
                        store.setShowActionRequired(false);
                    }} />
            </ModalContainer>}
            <Grid>
                <Fragment>
                    <ModalContainer
                        show={patientStore.toggleShowPrescriptionModal}
                        title="PRESCRIPTION"
                        onClose={handleCloseModal}
                        onClick={patientStore.togglePrescriptionModal.bind(this)}
                    >
                        <CreateRxWizard patientId={patientStore.currentPatientId} isEdit={false} clonedPrescription={clonedPrescription} sendOnCloseEvent={() => {
                            patientStore.toggleShowPrescriptionModal = false;
                        }} />
                        <ConfirmDialog
                            open={showConfirmClosePrescriptionForm}
                            onClose={handleCancelClosePrescriptionForm}
                            onConfirm={handleConfirmClosePrescriptionForm}
                            message="Are you sure you want to close without saving?"
                        />
                    </ModalContainer>
                </Fragment>

            </Grid>
        </Grid>
    )
}
);

