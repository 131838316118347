import React, { Fragment, useState } from 'react';
import {
  Typography,
  Card,
  Box,
  styled,
  Grid,
  alpha,
  Divider,
  List,
  ListItemButton,
  useTheme,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Masonry } from '@mui/lab';
import {
  careIFU,
  carevidaSleepITD,
  guidesIFU,
  guidesITD,
  pexIFU,
  pexITD,
  versa9IFU,
  versaEmaIFU,
  vidaIFU,
  vidaITD,
  vidaSleepIFU,
} from './Helper/documents';

// Styled Components
const BoxWrapper = styled(Box)(({ theme }) => `
  width: 100%;
  background: ${theme.colors.alpha.black[10]};
  border-radius: ${theme.general.borderRadius};
  padding: ${theme.spacing(3)};
`);

const ListItemButtonWrapper = styled(ListItemButton)(
  ({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
    transform: scale(1);
    background: ${theme.colors.alpha.white[100]};
    position: relative;
    z-index: 5;

    &:hover {
      border-radius: ${theme.general.borderRadius};
      background: ${theme.colors.alpha.white[100]};
      z-index: 6;
      box-shadow: 
        0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
        0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
        0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
        0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
      transform: scale(1.05);
    }
  `
);

const allProviderTypes = [
  "VIP",
  "Lifeline",
  "Guided Growth and Development",
  "C.A.R.E",
  "Airway Health Solutions",
  "Sleep Well Journey",
  "Noum",
  "Lincare",
  "Certified Legacy Provider",
  "Colorado Sleep Institute",
  "Clinical Advisor"
];

const coloringPages = [
    { id: 1, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Bluey", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Bluey Coloring Sheet 8.5x11.pdf" },
    { id: 2, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Buzz Lightyear", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Buzz Lightyear coloring sheet 11x14.pdf" },
    { id: 3, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Cars", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Cars Coloring Sheet 11x14.pdf" },
    { id: 4, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Cute Owl", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Cute Owl Coloring Sheet 11x14.pdf" },
    { id: 5, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Fish Under Sea", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Fish Under the Sea 11x14.pdf" },
    { id: 6, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Frozen: Elsa", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Frozen Elsa Coloring Sheet 11x14.pdf" },
    { id: 7, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Frozen: Olaf", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Frozen Olaf Coloring Sheet 11x14.pdf" },
    { id: 8, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Hello Kitty", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Hello Kitty Coloring Sheet 11x14.pdf" },
    { id: 9, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Jungle", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Jungle Coloring sheet 11x14.pdf" },
    { id: 10, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Luigi", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Luigi Coloring Sheet 11x14.pdf" },
    { id: 11, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Minion", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Minion Coloring Sheet 11x14.pdf" },
    { id: 12, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Monster Truck", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Monster Truck Coloring Sheet 11x14.pdf" },
    { id: 13, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Ocean Animals", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Ocean Animals 11x14.pdf" },
    { id: 14, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Paw Patrol", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Paw Patrol Coloring Sheet 8.5x11.pdf" },
    { id: 15, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Spidey", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Coloring & Appliance Tracker Sheets/Spidey Coloring Page 8.5x11.pdf" },
]

const documentGroups: { name: string; documents: { id: number; providerAccess: string[]; type: string; name: string; link: string; description?: JSX.Element; }[] }[] = [
    //Patient Forms: Adults
    {
        name: "Patient Forms: Adults",
        documents: [
            { id: 1, providerAccess: allProviderTypes, type: "link", name: "Sleep & Breathing Questionnaire", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Adults/2021.1 [Current 2024.3] Adult Sleep and Breathing Questionnaire.pdf" },
            { id: 2, providerAccess: allProviderTypes, type: "link", name: "Health History", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Adults/2023.11 Health History Form- Adults.pdf" },
            { id: 3, providerAccess: allProviderTypes, type: "link", name: "HIT-6 Headache Impact Test", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Adults/2001.1 HIT6 Questionnaire.pdf" },
            { id: 4, providerAccess: allProviderTypes, type: "link", name: "Stop Bang Questionnaire", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Adults/2023.10 Stop Bang Questionnaire.pdf" },
            { id: 5, providerAccess: allProviderTypes, type: "link", name: "Epworth Sleepiness Scale", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Adults/2023.10 - Epworth Sleepiness Scale - Adults.pdf" },
            { id: 6, providerAccess: allProviderTypes, type: "link", name: "Berlin Questionnaire", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Adults/2020.1 Berlin Questionnaire.pdf" },
        ]
    },
    //Patient Forms: Children
    {
        name: "Patient Forms: Children",
        documents: [
            { id: 10, providerAccess: allProviderTypes, type: "link", name: "Sleep, Breathing, & Habit Questionnaire", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Children/2020.1 Children and Adolescents-Sleep Breathing Questionnaire.pdf" },
            { id: 11, providerAccess: allProviderTypes, type: "link", name: "Health History", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Children/2020.1 Health History form - Child.pdf" },
            { id: 12, providerAccess: allProviderTypes, type: "link", name: "Epworth Sleepiness Scale", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Children/2023.10 - Epworth Sleepiness Scale - Children.pdf" },
            { id: 13, providerAccess: allProviderTypes, type: "link", name: "Kids Contract for Success", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Forms/Children/2023.11 - Kids Contract For Success.pdf" },
        ]
    },
    //Provider Exam Forms
    {
        name: "Provider Exam Forms",
        documents: [
            { id: 20, providerAccess: allProviderTypes, type: "link", name: "Clinical Evaluation (Head & Neck Exam): Adult", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Exam Forms/2023.11 Clinical Exam Form - Head and Neck - Adult.pdf" },
            { id: 21, providerAccess: allProviderTypes, type: "link", name: "Clinical Evaluation (Head & Neck Exam): Child", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Exam Forms/2023.11 Head and Neck Form - Pediatric - Version 1.4.pdf" },
            { id: 22, providerAccess: allProviderTypes, type: "link", name: "Treatment Planning Form - Fillable", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Provider Exam Forms/Treatment Planning Form_Fillable_Final_9.6.24_Rev2.pdf" },
        ]
    },
    //Provider Instructions for Vivos Appliances"
    {
        name: "Provider Instructions for Vivos Appliances",
        documents: [
            { id: 61, providerAccess: ["VIP", "C.A.R.E", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "C.A.R.E. & VidaSleep Appliances: Provider Instructions", link: carevidaSleepITD },
            { id: 62, providerAccess: ["VIP", "Lifeline", "Guided Growth and Development", "Airway Health Solutions", "Sleep Well Journey", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Vivos Guides: Provider Instructions", link: guidesITD },
            { id: 63, providerAccess: ["VIP", "Guided Growth and Development", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Vivos PEx: Provider Instructions", link: pexITD },
            { id: 64, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Vivos Vida: Provider Instructions", link: vidaITD },

        ]
    },
    //Patient Instructions for Vivos Appliances
    {
        name: "Patient Instructions for Vivos Appliances",
        documents: [
            { id: 71, providerAccess: ["VIP", "C.A.R.E", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "C.A.R.E. Appliances: Patient Instructions", link: careIFU },
            { id: 72, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Versa Nylon EMA: Patient Instructions", link: versaEmaIFU },
            { id: 73, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Versa 9: Patient Instructions", link: versa9IFU },
            { id: 74, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Vivos Vida: Patient Instructions", link: vidaIFU },
            { id: 75, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Vida Sleep: Patient Instructions", link: vidaSleepIFU },
            { id: 76, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "The PEx: Patient Instructions", link: pexIFU },
            { id: 77, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "Guides: Patient Instructions", link: guidesIFU },
        ]
    },
    {
        name: "Release and Transfer Forms",
        documents: [
            { id: 81, providerAccess: allProviderTypes, type: "link", name: "Provider to Provider Transfer Release Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Transfer Forms/2020.1 Provider to Provider Transfer Release Form.pdf" },
            { id: 82, providerAccess: allProviderTypes, type: "link", name: "Patient Release & Transfer Request", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Patient Transfer Forms/2020.1 Patient Release and Transfer Request.pdf" },
            { id: 83, providerAccess: allProviderTypes, type: "link", name: "Patient Photography and Video Release Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.06 Unbranded Photography Video Release.pdf" },
            { id: 84, providerAccess: allProviderTypes, type: "link", name: "Picture Release Form (Unbranded)", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.09_Unbranded Photo Release.pdf" },
            { id: 85, providerAccess: allProviderTypes, type: "link", name: "Picture Release Form (Unbranded) - Fillable", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.09_Unbranded Photo Release - Fillable.pdf" },
            { id: 86, providerAccess: allProviderTypes, type: "link", name: "Picture Release Form (Vivos)", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.09_Vivos Photo Release - Fillable.pdf" },
            { id: 87, providerAccess: allProviderTypes, type: "link", name: "Picture Release Form (Vivos) - Fillable", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.09_Vivos Photo Release - Fillable.pdf" },
            { id: 88, providerAccess: allProviderTypes, type: "link", name: "Patient Release and Transfer of Records Authorization Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.01_Patient Release or Transfer of Records Authorization Form Fillable.pdf" },
        ]
    },
    //Consent Forms
    {
        name: "Consent Forms",
        documents: [
            { id: 40, providerAccess: allProviderTypes, type: "link", name: "Oral Appliance Therapy Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2022.05_Oral Appliance Consent Form.pdf" },
            { id: 41, providerAccess: allProviderTypes, type: "link", name: "TMD Oral Appliance Therapy Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.10 - Informed Consent - TMD Oral Appliance.pdf" },
            { id: 42, providerAccess: allProviderTypes, type: "link", name: "MAD Intolerance Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.07 - Informed Consent - MAD Oral Appliance.pdf" },
            { id: 43, providerAccess: allProviderTypes, type: "link", name: "(Kids) Appliance Therapy Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.9 Kids Appliance Therapy - Informed Consent.pdf" },
            { id: 44, providerAccess: allProviderTypes, type: "link", name: "CBVT Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.01_Unbranded CBVT Informed Consent.pdf" },
            { id: 45, providerAccess: allProviderTypes, type: "link", name: "General Laser Procedures Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.10 - Informed Consent - General Laser Procedures.pdf" },
            { id: 46, providerAccess: allProviderTypes, type: "link", name: "Laser Treatment for Snoring Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.10 - Informed Consent - Laser Treatment for Snoring.pdf" },
            { id: 47, providerAccess: allProviderTypes, type: "link", name: "Laser Periodontal Treatment Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.10 - Informed Consent - Laser Periodontal Therapy.pdf" },
            { id: 48, providerAccess: allProviderTypes, type: "link", name: "Intro and Consent to Laser Bacterial Reduction", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2024.01_Intro and Consent to Laser Bacterial Reduction a.pdf" },
            { id: 49, providerAccess: allProviderTypes, type: "link", name: "General Frenectomy Informed Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2020.01_Frenectomy General Informed Consent.pdf" },
            { id: 50, providerAccess: allProviderTypes, type: "link", name: "Adult Frenectomy Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.10 - Informed Consent - Frenectomy for Adults.pdf" },
            { id: 51, providerAccess: allProviderTypes, type: "link", name: "Infant/Child Frenectomy Consent Form", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Provider & Practice Forms/Consent Forms/2023.10 - Informed Consent - Frenectomy for Infant and Child.pdf" },
        ]
    },
    //Coloring Sheet
    {
        name: "Coloring & Tracking Sheets",
        documents: [
            {
                id: 91,
                type: "popup",
                name: "Coloring & Tracking Sheets",
                providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider","Airway Health Solutions", "Colorado Sleep Institute", "Clinical Advisor"],
                link: "",
                description: (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <List>
                                {coloringPages.map(page => (
                                    <ListItemButtonWrapper key={page.id} onClick={() => window.open(page.link, '_blank')}>
                                        <ListItemText primary={page.name} />
                                    </ListItemButtonWrapper>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                )
            }
        ]
    }
];

function ProviderPracticeForms({ providerTypes, isProvider }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);
  
    const handleDocumentClick = (doc) => {
      if (doc.type === 'link') {
        window.open(doc.link, '_blank', 'noopener noreferrer');
      } else if (doc.type === 'popup') {
        setDialogContent(doc.description);
        setOpenDialog(true);
      }
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    return (
      <Grid container spacing={2}>
        {documentGroups.map((group, index) => {
          const filteredDocuments = (!providerTypes || providerTypes.length === 0)
          ? group.documents
          : group.documents.filter((doc) =>
              doc.providerAccess.some((provider) => {
                return providerTypes.some((p) => p.includes(provider));
              })
            );        
  
          if (filteredDocuments.length === 0) return null;
          console.log("Provider Types in ProviderPracticeForms:", providerTypes);

  
          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  overflow: 'visible',
                  backgroundColor: theme.palette.background.default,
                  border: `1px solid ${theme.palette.divider}`,
                  boxShadow: `
                    0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                    0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                    0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                    0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)}
                  `,
                  borderRadius: `${theme.shape.borderRadius}px`,
                }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '75px',
                    borderRadius: `${theme.shape.borderRadius}px`,
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                    padding: theme.spacing(2),
                  }}
                >
                  <Typography variant="h5">{group.name}</Typography>
                </Box>
                <List disablePadding sx={{ flexGrow: 1 }}>
                  {filteredDocuments.map((doc) => (
                    <Fragment key={doc.id}>
                      <Divider />
                      <ListItemButtonWrapper
                        sx={{
                          display: { xs: 'block', sm: 'flex' },
                          py: 2,
                          px: 2.5,
                        }}
                        onClick={() => handleDocumentClick(doc)}
                      >
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography color="text.primary" variant="h5">
                              {doc.name}
                            </Typography>
                          }
                        />
                      </ListItemButtonWrapper>
                    </Fragment>
                  ))}
                </List>
              </Card>
            </Grid>
          );
        })}
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography variant="h5">Coloring & Tracking Sheets</Typography>
          </DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
        </Dialog>
      </Grid>
    );
  }
  
  export default ProviderPracticeForms;