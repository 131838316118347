// Vendors
import React, { useEffect, useState } from "react";
import {
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
    Box,
    List,
    ListItem,
    Divider,
    ListItemText,
    styled,
    IconButton,
    Avatar,
    alpha,
    CircularProgress
} from "@mui/material";
import DownloadTwoToneIcon from "@mui/icons-material/DownloadTwoTone";

// Components
import SectionContainer from "../../../components/UI/Containers/SectionContainer";

// Entities

// Stores
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react-lite";
import moment from "moment";
import _ from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CssTextField from "../../../components/CssTextField";
import { LabBiteStatus, LabModelStatus } from "../../../entities/Enums";
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
          background: ${theme.colors.alpha.white[100]};
          width: auto;
          height: ${theme.spacing(9)};
          padding: ${theme.spacing(1.8)};
          box-shadow: 0 0.180rem .3rem ${alpha(
        theme.colors.alpha.black[100],
        0.3
    )}, 0 .326rem 3rem ${alpha(theme.colors.alpha.black[100], 0.2)};
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
    `
);

interface IGIProps {
    prescription: any;
    patient: any;
    labAccessed: boolean;
}

export const RxManagementDetails: React.FC<IGIProps> = observer(({ prescription, patient, labAccessed }) => {
    const store = usePrescriptionStore().prescriptionStore;
    const visitStore = useMainStoreContext().visitRecordStore;
    const [labModelReceivedDate, setLabModelReceivedDate] = useState<Date | null>(prescription?.labModelReceived);
    const [labBiteReceivedDate, setLabBiteReceivedDate] = useState<Date | null>(prescription?.labBiteReceived);
    const [fabricationStartDate, setFabricationStartDate] = useState<Date | null>(prescription?.fabricationStartDate);
    const [estimateShippedDate, setEstimateShippedDate] = useState<Date | null>(prescription?.estimateShippedDate);
    const [trackingNo, setTrackingNo] = useState<string | null>(prescription?.trackingNo);
    const [shippedDate, setShippedDate] = useState<Date | null>(prescription?.shippedDate);
    const [labModelStatusId, setLabModelStatusId] = useState<any>(_.isUndefined(store.modelStatusValues.find(x => x.id === prescription?.labModelStatus)?.id) ? LabModelStatus.Pending : store.modelStatusValues.find(x => x.id === prescription?.labModelStatus)?.id)
    const [labBiteStatusId, setLabBiteStatusId] = useState<any>(_.isUndefined(store.biteStatusValues.find(x => x.id === prescription?.labBiteStatus)?.id) ? LabBiteStatus.Pending : store.biteStatusValues.find(x => x.id === prescription?.labBiteStatus)?.id)
    const [hasLoaded, setHasLoaded] = React.useState(false);

    useEffect(() => {
        if (visitStore.currentVisit.patientsImages) {
            setHasLoaded(true);
        }
    }, [visitStore.currentVisit]);

    if (!prescription || !prescription.appliance || !prescription.appliance.name) {
        return null;
    }


    const updatedPrescription = {
        ...prescription,
        labModelStatus: labModelStatusId,
        labBiteStatus: labBiteStatusId,
        labBiteReceived: labBiteReceivedDate,
        labModelReceived: labModelReceivedDate,
        fabricationStartDate: fabricationStartDate,
        estimateShippedDate: estimateShippedDate,
        shippedDate: shippedDate,
        trackingNo: trackingNo,
    }

    const updatePrescription = () => {
        var updateObj = {
            ...prescription,
            labModelStatus: labModelStatusId,
            labBiteStatus: labBiteStatusId,
            labBiteReceived: labBiteReceivedDate,
            labModelReceived: labModelReceivedDate,
            fabricationStartDate: fabricationStartDate,
            estimateShippedDate: estimateShippedDate,
            shippedDate: shippedDate,
            trackingNo: trackingNo,
        }
        store.setPrescription(updateObj);
        store.updatePrescription(undefined, false);
    };

    if (!prescription || !prescription.appliance) {
        return null;
    }

    return (
        <div>
            {hasLoaded ? <Grid container padding={1} justifyContent={'center'}>
                <Grid xs={12} item container justifyContent={'flex-end'} padding={1} spacing={1}>
                    <Grid item>
                        <Button variant="outlined" onClick={() => { updatePrescription() }}> Save Changes </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} paddingRight={1}>
                    <SectionContainer
                        cardContentHeight={'500px'}
                        sectionTitle={'Overall Status'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem sx={{ alignItems: 'flex-start', p: 1 }}>
                                        <ListItemText
                                            primary={
                                                <Grid container justifyContent={'space-evenly'}>
                                                    <AvatarWrapper
                                                        variant="rounded"
                                                        alt="Appliance"
                                                        src={store.applianceOptions.find(a => a.constructId === prescription?.appliance?.applianceType)?.logo}
                                                    />
                                                    <AvatarWrapper
                                                        variant="rounded"
                                                        alt="Appliance"
                                                        src={store.labsImages.find(lab => lab.name === prescription?.lab?.name)?.logo}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">Prescription:</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {prescription?.appliance?.name}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">Prescription Type:</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                New
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    {<><ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">QC Status</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.qcStatusValues.find(x => x.id === prescription?.qcstatus)?.name}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                        <Divider />
                                    </>}
                                    {prescription?.appliance?.base}
                                    {prescription?.appliance?.name.toLowerCase().includes("pex") && <><ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">PEx Digital Design Status</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.digitalDesignStatusValues.find(x => x.id === prescription?.digitalDesignStatus)?.name}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                        <Divider />
                                    </>}
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">RX Timeline</Typography>}
                                            secondary={
                                                <Grid container paddingTop={1} spacing={1} paddingLeft={3}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Submitted On:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Typography variant="h4">{prescription?.submittedDate ? moment(prescription?.submittedDate).format('MM/DD/yyyy') : ''}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Needed by:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Typography variant="h4">{prescription?.neededByDate ? moment(prescription?.neededByDate).format('MM/DD/yyyy') : ''}</Typography>
                                                    </Grid>
                                                    {<><Grid item xs={6}>
                                                        <Typography variant="h4">Vivos Admin Assigned:</Typography>
                                                    </Grid>
                                                        <Grid item xs={6} container justifyContent={'right'}>
                                                            <Typography variant="h4">{prescription?.assignedDate ? moment(prescription?.assignedDate).format('MM/DD/yyyy') : ''}</Typography>
                                                        </Grid></>}
                                                    {prescription?.appliance?.name.toLowerCase().includes("pex") && <><Grid item xs={6}>
                                                        <Typography variant="h4">Digital Design Ready On:</Typography>
                                                    </Grid>
                                                        <Grid item xs={6} container justifyContent={'right'}>
                                                            <Typography variant="h4">{prescription?.digitalDateDesignCompleted ? moment(prescription?.digitalDateDesignCompleted).format('MM/DD/yyyy') : ''}</Typography>
                                                        </Grid></>}
                                                    {<><Grid item xs={6}>
                                                        <Typography variant="h4">Marked Ready:</Typography>
                                                    </Grid>
                                                        <Grid item xs={6} container justifyContent={'right'}>
                                                            <Typography variant="h4">{prescription?.markedReadyDate ? moment(prescription?.markedReadyDate).format('MM/DD/yyyy') : ''}</Typography>
                                                        </Grid></>}
                                                    {/* <Grid item xs={6}>
                                                        <Typography variant="h4">Lab Received on:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Typography variant="h4">{prescription?.labReceivedDate ? moment(prescription?.labReceivedDate).format('MM/DD/yyyy') : ''}</Typography>
                                                    </Grid> */}
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Fabrication Started On:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    className="DateBox"
                                                                    openTo="day"
                                                                    views={['year', 'month', 'day']}
                                                                    value={fabricationStartDate}
                                                                    onChange={(value) => {
                                                                        setFabricationStartDate(value);
                                                                    }}
                                                                    renderInput={params => (
                                                                        <CssTextField
                                                                            disabled={false}
                                                                            {...params}
                                                                            sx={{
                                                                                '& input': {
                                                                                    backgroundColor: 'white',
                                                                                    color: '#050505',
                                                                                    fontFamily: 'Proxima Nova',
                                                                                    fontSize: '16px',
                                                                                },
                                                                            }}
                                                                            className="TextBox"
                                                                            size="small"
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Estimated to Ship On:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    className="DateBox"
                                                                    openTo="day"
                                                                    views={['year', 'month', 'day']}
                                                                    value={estimateShippedDate}
                                                                    onChange={(value) => {
                                                                        setEstimateShippedDate(value);
                                                                    }}
                                                                    renderInput={params => (
                                                                        <CssTextField
                                                                            disabled={false}
                                                                            {...params}
                                                                            sx={{
                                                                                '& input': {
                                                                                    backgroundColor: 'white',
                                                                                    color: '#050505',
                                                                                    fontFamily: 'Proxima Nova',
                                                                                    fontSize: '16px',
                                                                                },
                                                                            }}
                                                                            className="TextBox"
                                                                            size="small"
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Shipped On:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DatePicker
                                                                    className="DateBox"
                                                                    openTo="day"
                                                                    views={['year', 'month', 'day']}
                                                                    value={shippedDate}
                                                                    onChange={(value) => {
                                                                        setShippedDate(value);
                                                                    }}
                                                                    renderInput={params => (
                                                                        <CssTextField
                                                                            disabled={false}
                                                                            {...params}
                                                                            sx={{
                                                                                '& input': {
                                                                                    backgroundColor: 'white',
                                                                                    color: '#050505',
                                                                                    fontFamily: 'Proxima Nova',
                                                                                    fontSize: '16px',
                                                                                },
                                                                            }}
                                                                            className="TextBox"
                                                                            size="small"
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Delivered On:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <Typography variant="h4">{prescription?.deliveredDate ? moment(prescription?.deliveredDate).format('MM/DD/yyyy') : ''}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h4">Tracking Number:</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} container justifyContent={'right'}>
                                                        <TextField
                                                            id="trackingNo"
                                                            label=""
                                                            fullWidth
                                                            value={prescription.trackingNo}
                                                            onChange={(event) => {
                                                                var value = event.target.value;
                                                                console.log(value);
                                                                setTrackingNo(value);
                                                                prescription.trackingNo = value;
                                                                console.log(prescription.trackingNo);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        }
                    />
                </Grid>
                {prescription.appliance.name !== "Guide" && <Grid item xs={12} md={6} lg={4} paddingX={1}>

                    <SectionContainer
                        sectionTitle={'Material Status'}
                        cardContentHeight={'500px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    {(prescription.appliance.name.includes('Versa') || prescription.appliance.name.toLowerCase().includes('pex')) && <><ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">Physical to Digital Status</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.physicalToDigitalStatusValues.find(x => x.id === prescription?.physicalToDigitalStatus)?.name}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                        <Divider />
                                        <ListItem
                                            sx={{
                                                alignItems: 'flex-start',
                                                p: 1
                                            }}
                                        >
                                            <ListItemText
                                                primary={<Typography variant="subtitle2">Digital Conversion Date</Typography>}
                                            />
                                            <Box alignSelf="center">
                                                <Typography variant="h4">
                                                    {prescription?.convertedToDigitalDate ? moment(prescription?.convertedToDigitalDate).format('MM/DD/yyyy') : ''}
                                                </Typography>
                                            </Box>
                                        </ListItem>
                                        <Divider /></>}
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Material Source</Typography>}
                                            secondary={
                                                <Grid container spacing={1} paddingTop={1}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h5">Model</Typography>
                                                        <Typography variant="h5" textAlign={'center'}>{!_.isEmpty(visitStore.currentVisit.patientsImages[0]) ? visitStore.currentVisit.patientsImages[0]?.modelType?.name : ''}</Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h5">Bite</Typography>
                                                        <Typography variant="h5" textAlign={'center'}>{!_.isEmpty(visitStore.currentVisit.patientsImages[0]) ? visitStore.currentVisit.patientsImages[0]?.constructionBiteTypeNavigation?.name : ''}</Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Material Status</Typography>}
                                            secondary={
                                                <Grid container spacing={1} paddingTop={1}>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h5">Model</Typography>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            value={labModelStatusId}
                                                            onChange={event => {
                                                                setLabModelStatusId(event.target.value)
                                                            }}
                                                        >
                                                            {store.modelStatusValues.filter(x => x.value !== '').map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="h5">Bite</Typography>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            size="small"
                                                            value={labBiteStatusId}
                                                            onChange={event => {
                                                                setLabBiteStatusId(event.target.value)
                                                            }}
                                                        >
                                                            {store.biteStatusValues.filter(x => x.value !== '').map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Date received model materials</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            className="DateBox"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            value={labModelReceivedDate}
                                                            onChange={(value) => {
                                                                setLabModelReceivedDate(value);
                                                            }}
                                                            renderInput={params => (
                                                                <CssTextField
                                                                    disabled={false}
                                                                    {...params}
                                                                    sx={{
                                                                        '& input': {
                                                                            backgroundColor: 'white',
                                                                            color: '#050505',
                                                                            fontFamily: 'Proxima Nova',
                                                                            fontSize: '16px',
                                                                        },
                                                                    }}
                                                                    className="TextBox"
                                                                    size="small"
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Date received bite materials</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            className="DateBox"
                                                            openTo="day"
                                                            views={['year', 'month', 'day']}
                                                            value={labBiteReceivedDate}
                                                            onChange={(value) => {
                                                                setLabBiteReceivedDate(value);
                                                            }}
                                                            renderInput={params => (
                                                                <CssTextField
                                                                    disabled={false}
                                                                    {...params}
                                                                    sx={{
                                                                        '& input': {
                                                                            backgroundColor: 'white',
                                                                            color: '#050505',
                                                                            fontFamily: 'Proxima Nova',
                                                                            fontSize: '16px',
                                                                        },
                                                                    }}
                                                                    className="TextBox"
                                                                    size="small"
                                                                />
                                                            )}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        }
                    />

                </Grid>}
                {prescription.appliance.name != "Guide" && <Grid item xs={12} md={6} lg={4} paddingLeft={1}>
                    <SectionContainer
                        cardContentHeight={'500px'}
                        sectionTitle={'Associated Files'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Grid container justifyContent={'space-between'} alignContent={'center'}>
                                                    <Typography variant="subtitle2">Upper Arch</Typography>
                                                    <IconButton
                                                        aria-label="download"
                                                        disabled={visitStore.currentVisit.patientsImages ? _.isEmpty(visitStore.currentVisit.patientsImages[0]?.occlusion3dupperArch) : true}
                                                        onClick={() => window.open(visitStore.currentVisit.patientsImages[0]?.occlusion3dupperArch, '_blank')}
                                                    >
                                                        <DownloadTwoToneIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Grid container justifyContent={'space-between'} alignContent={'center'}>
                                                    <Typography variant="subtitle2">Lower Arch</Typography>
                                                    <IconButton
                                                        aria-label="download"
                                                        disabled={visitStore.currentVisit.patientsImages ? _.isEmpty(visitStore.currentVisit.patientsImages[0]?.occlusion3dlowerArch) : true}
                                                        onClick={() => window.open(visitStore.currentVisit.patientsImages[0]?.occlusion3dlowerArch, '_blank')}
                                                    >
                                                        <DownloadTwoToneIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Grid container justifyContent={'space-between'} alignContent={'center'}>
                                                    <Typography variant="subtitle2">Construction Bite</Typography>
                                                    <IconButton
                                                        aria-label="download"
                                                        disabled={visitStore.currentVisit.patientsImages ? _.isEmpty(visitStore.currentVisit.patientsImages[0]?.occlusion3dconstructedBite) : true}
                                                        onClick={() => window.open(visitStore.currentVisit.patientsImages[0]?.occlusion3dconstructedBite, '_blank')}
                                                    >
                                                        <DownloadTwoToneIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Grid container justifyContent={'space-between'} alignContent={'center'}>
                                                    <Typography variant="subtitle2">RX Diagram</Typography>
                                                    <IconButton
                                                        aria-label="download"
                                                        disabled={_.isEmpty(prescription?.diagramUrl) || prescription?.diagramUrl.startsWith('?')}
                                                        onClick={() => window.open(prescription?.diagramUrl, '_blank')}
                                                    >
                                                        <DownloadTwoToneIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    {(prescription.appliance.name.toLowerCase().includes("pex") && !_.isEmpty(prescription?.pexDesignFileUrl)) && <><ListItem>
                                        <ListItemText
                                            primary={
                                                <Grid container justifyContent={'space-between'} alignContent={'center'}>
                                                    <Typography variant="subtitle2">PEx Digital Design</Typography>
                                                    <IconButton
                                                        aria-label="download"
                                                        disabled={_.isEmpty(prescription?.pexDesignFileUrl)}
                                                        onClick={() => window.open(prescription?.pexDesignFileUrl, '_blank')}
                                                    >
                                                        <DownloadTwoToneIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                        <Divider /></>}
                                </List>
                            </Grid>
                        }
                    />
                </Grid>}
            </Grid> :
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>}
        </div>
    )
}
);
