// Vendors
import { useRef, useState, useEffect, Component, FC } from 'react';
import {
  Avatar,
  Box,
  alpha,
  IconButton,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';

// Components
import LeftNavBL from '../../../../components/LeftNav/LeftNavBL';
import { ProviderSettings } from '../../../../modals/ProviderSettings/ProviderSettings';
import ModalContainer from '../../../../components/ModalContainer';

// API
import jwt_decode from 'jwt-decode';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';

const IconButtonAccountSettings = styled(IconButton)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(0)};
    color: ${theme.colors.alpha.trueWhite[70]};
    top: ${theme.spacing(0)};
    background: ${theme.colors.alpha.trueWhite[10]};
    &:hover {
      color: ${theme.colors.alpha.trueWhite[100]};
      background: ${alpha(theme.colors.alpha.trueWhite[100], 0.2)};
    }
  `
);

interface IGIProps {
  bl?: any;
}

export const SidebarTopSection: FC<IGIProps> = observer(() => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [toggleShowProviderSettings, setToggleShowProviderSettings] = useState(false);
  const leftNavBL = new LeftNavBL();
  const providerStore = useMainStoreContext().providerStore;

  const handleShowProviderSettings = () => {
    setToggleShowProviderSettings(!toggleShowProviderSettings);
  };

  useEffect(() => {
    setHasLoaded(false);
    providerStore.getProviderDetails().then(() => {
      setHasLoaded(true);
    });
  }, []);

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 2,
        pt: 1,
        position: 'relative'
      }}
    >
      {hasLoaded && <Avatar
        sx={{
          width: 68,
          height: 68,
          mb: 2,
          mx: 'auto'
        }}
        alt={leftNavBL.username}
        src={providerStore.providerData?.imageProfileUrl}
      />}

      <Typography
        variant="h4"
        sx={{ color: (theme) => theme.colors.alpha.trueWhite[100] }}
      >
        {leftNavBL.username}
      </Typography>
      {/* <Typography
        variant="subtitle1"
        sx={{ color: (theme) => theme.colors.alpha.trueWhite[70] }}
      >
        [Insert User Type]
      </Typography> */}
      <IconButtonAccountSettings
        size="small"
        onClick={handleShowProviderSettings}
      >
        <ManageAccountsTwoToneIcon fontSize="small" />
      </IconButtonAccountSettings>
      {toggleShowProviderSettings && (
        <ModalContainer
          show={toggleShowProviderSettings}
          noScroll={true}
          title="Provider Settings"
          hideTitlebar={true}
          onClose={handleShowProviderSettings}
          onClick={handleShowProviderSettings}
        >
          <ProviderSettings />
        </ModalContainer>
      )}
    </Box>
  );
});