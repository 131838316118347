// Vendors
import react, { FC, useEffect, useState } from 'react';
import {
    Grid,
    styled,
    List,
    ListItem,
    ListItemText,
    alpha,
    Typography,
    Button,
} from '@mui/material';

// Components
import SectionContainer from '../../../UI/Containers/SectionContainer';

// Store
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';

// Entities
import { IntraoralCondition } from '../../../../entities/Enums';

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from '../../../../api/GlobalUtils';
import { DictionaryListItem } from '../../../../models/state_models/dictionarylistitem';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';
import { TreatmentObjective } from '../../../../models/state_models/treatmentObjective';

interface IDiagnosisPage {
    patient?: any;
}

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);



export const TreatmentObjectives: FC<IDiagnosisPage> = observer(() => {
    const {treatmentPlan,setTreatmentPlan} = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;
    const [objectives, setObjectives] = useState<DictionaryListItem[]>([]);

    const handleObjectiveChange = (conditionKey:DictionaryListItem, objectiveId) => {
        var objectivePresent = treatmentPlan.treatmentObjectives.find(x=> x.objectiveId == conditionKey.id);
        if(objectivePresent){
            if(objectivePresent.value !== objectiveId){
                objectivePresent.treatmentPlanId = treatmentPlan.id;
                objectivePresent.objective = conditionKey;
                objectivePresent.value = objectiveId
                objectivePresent.objectiveId = conditionKey.id;
            }
            treatmentPlan.treatmentObjectives = treatmentPlan.treatmentObjectives.filter(x=> x.objectiveId != conditionKey.id);
            treatmentPlan.treatmentObjectives.push(objectivePresent);
        }
        else{
            var newCondition = new TreatmentObjective();
            newCondition.treatmentPlanId = treatmentPlan.id;
            newCondition.value = objectiveId;
            newCondition.objective = conditionKey;
            newCondition.objectiveId = conditionKey.id;
            treatmentPlan.treatmentObjectives.push(newCondition);
        }

        setTreatmentPlan(treatmentPlan);
    };

    useEffect(()=>{
        GlobalUtils.gettreatmentObjectivesItems().then((values)=>{
            if(values){
                setObjectives(values);
            }
        })
    },[]);

    const isObjectiveSelected = (conditionKey:DictionaryListItem, objectiveId) => {
        var objective = treatmentPlan.treatmentObjectives.find(x=> x.objectiveId == conditionKey.id);
        return objective && objective.value === objectiveId;
    };

    return (
        <Grid>
            <SectionContainer
                sectionTitle={'Treatment Objectives'}
                cardContentHeight={'800px'}
                sectionComponent={
                    <Grid>
                        <ListWrapper>
                            {objectives.map((objective, index) => (
                                <ListItem key={index}
                                    sx={(theme) => ({
                                        p: 1,
                                        borderBottom: 4,
                                        borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                    })}>
                                    <ListItemText
                                        primary={
                                            <Grid container>
                                                <Grid item xs={6} alignSelf={'center'} paddingRight={2}>
                                                    <Typography variant="h5" textAlign={'right'}>
                                                        {objective.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        size='small'
                                                        variant={isObjectiveSelected(objective, 'improveId') ? "contained" : "outlined"}
                                                        onClick={() => handleObjectiveChange(objective, 'improveId')}
                                                    >
                                                        Improve
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button
                                                        size='small'
                                                        variant={isObjectiveSelected(objective, 'maintainId') ? "contained" : "outlined"}
                                                        onClick={() => handleObjectiveChange(objective, 'maintainId')}
                                                    >
                                                        Maintain
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </ListWrapper>
                    </Grid>
                }
            />
        </Grid>
    );
});
