// Vendor
import React, { useEffect } from 'react';
import {
    Grid,
    LinearProgress,
    IconButton,
    Link,
    Tooltip,
} from '@mui/material';
import {
    GridFilterItem, GridFilterInputValue
} from '@mui/x-data-grid';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Components
import DataGrid from '../../components/DataGrid/DataGrid';
import ComparisonSvg from '../../imgs/thumbnails/Comparison-AI-Report_tn.jpg';
import StandardSvg from '../../imgs/thumbnails/Standard-AI-Report_tn.jpg';
import SearchBar from '../../components/UI/SearchBar';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeader from './components/PageHeader';


// Entities
import { AIReportProcessedImagesTypes, AIReportStatus, AddOnType } from '../../entities/Enums';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import _ from 'lodash';
import { GlobalUtils } from '../../api/GlobalUtils';
import Label from '../../components/UI/Label';
import { format } from 'date-fns';

const getAiReportStatusLabel = (aiReportStatus: AIReportStatus): JSX.Element => {
    const map = {
        'Pending Information': {
            text: 'Pending Information',
            color: 'warning'
        },
        'Pending Review': {
            text: 'Pending Review',
            color: 'warning'
        },
        'Ready To Submit': {
            text: 'Pending Information',
            color: 'warning'
        },
        Submitted: {
            text: 'Submitted',
            color: 'primary'
        },
        Assigned: {
            text: 'Assigned',
            color: 'info'
        },
        Processing: {
            text: 'Processing',
            color: 'info'
        },
        Completed: {
            text: 'Completed',
            color: 'success'
        }
    };
    const { text, color }: any = map[aiReportStatus];

    return (
        <Label color={color}>
            <b>{text}</b>
        </Label>
    );
};


const DAYSINQUEUEOK = 3;
const DAYSINQUEUEWARN = 6;
interface csvValues {
    name: string;
    xValue?: string;
    yValue?: string;
    zValue?: string;
}

interface IReportProps {
    userRoles: any[];
}

export const AIReportsComponent = (props: IReportProps) => {
    const store = useMainStoreContext().aiReportsStore;


    useEffect(() => {
        store.didInitAIReports = true;
        store.currentFilter = 5;
        store.loadAIReports();
        store.loadTechnicianData();
        store.setAiReportStatus(GlobalUtils.getAIReportStatuses());
    }, [store.loadAIReports, store.setAiReportStatus]);

    useEffect(() => {
        store.loadAIReports();
    }, [store.filter])

    const columns = [
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patientName',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'createdOn',
            headerName: 'Date Submitted',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',

            renderCell: (params) => {
                const dateSubmitted = new Date(params.row.createdOn);
                return (
                    <Grid>
                        {format(dateSubmitted, 'MMMM dd, yyyy')}
                    </Grid>

                )
            },

        },
        {
            field: 'statusId',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            sortComparator: (v1, v2, param1, param2) => {
                var a1 = _.find(store.aiStatus, (a) => { return a.name == param1.value });
                var a2 = _.find(store.aiStatus, (a) => { return a.name == param2.value });
                var ad1 = a1 ? (a1?.name) : "";
                var ad2 = a2 ? (a2?.name) : "";
                return ad1.localeCompare(ad2);
            },
            valueOptions: store.aiStatus.map((a) => { return a.name }),
            filterOperators: [
                {
                    value: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (filterItem.value == null || filterItem.value == '') {
                            return null;
                        }

                        return ({ value }): boolean => {
                            var advisor = _.find(store.aiStatus, (a) => { return (a.name) === filterItem.value });
                            if (!_.isNull(advisor) && !_.isUndefined(advisor)) {
                                return advisor.id === value;
                            }
                            return false;
                        };
                    },
                    InputComponent: GridFilterInputValue,
                    InputComponentProps: { type: 'singleSelect' },
                }],
            renderCell: (params) => {
                const statusName = params.row.status;
                return getAiReportStatusLabel(statusName);

            },
        },
        {
            field: 'reportTypeStatus',
            headerName: 'Report Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <figure style={{ zIndex: 100 }} className="gridFigure">
                        {params.row.reportType === 'Standard AI Report' && (
                            <img src={StandardSvg} style={{ width: "35px", height: "35px" }} className={'svgGrid'} />
                        )}
                        {params.row.reportType === 'Comparison AI Report' && (
                            <img
                                src={ComparisonSvg}
                                className={'svgGrid '}
                                style={{ filter: 'hue-rotate(90deg)', width: "35px", height: "35px" }}
                            />
                        )}
                        <figcaption>
                            <b>{params.row.reportType}</b>
                            <br />
                            <b>Accepted On:</b> {params.row.createdOn}
                            <br />
                            <b>Updated On:</b> {params.row.modifiedOn}
                            <br />
                            <b>Status:</b> {params.row.status}
                        </figcaption>
                    </figure>
                );
            },
        },
        {
            field: 'AiGenerated',
            headerName: 'Generated AI Report',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',

            renderCell: (params) => {
                // Determine if the link is undefined
                const isUndefined = !params.row.link;

                // Construct the URL
                const url = decodeURI(
                    `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${params.row.vivosId}/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${params.row.id}/${params.row.link}`
                );
                var radiologyReportUrl = "";
                if (params.row.addOns) {
                    var radiologyReportAddOn = JSON.parse(params.row.addOns).find(x => x.AddOnType === AddOnType.Radiology);
                    radiologyReportUrl = radiologyReportAddOn?.Url ? radiologyReportAddOn.Url : "";
                }


                return (
                    <Grid container spacing={1}>
                        <Grid item>
                            <Tooltip title="Billing Radiographic Images">
                                <IconButton>
                                    <Link target="_blank" rel="noopener noreferrer"
                                        onClick={() => {
                                            store.getProcessedCompressedImages(params.row.vivosId, params.row.id, AIReportProcessedImagesTypes['Billing Radiographic Image'], AIReportProcessedImagesTypes[AIReportProcessedImagesTypes['Billing Radiographic Image']])
                                        }
                                        }
                                    >
                                        <FileDownloadIcon />
                                    </Link>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {!_.isEmpty(radiologyReportUrl) &&
                            <Grid item>
                                <Tooltip title="Radiology Report">
                                    <IconButton>
                                        <Link href={radiologyReportUrl} target="_blank" rel="noopener noreferrer">
                                            <FileDownloadIcon />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        {/* Conditional rendering for the download button */}
                        {!isUndefined && (
                            <Grid item>
                                <Tooltip title="AI Report">
                                    <IconButton>
                                        <Link href={url} target="_blank" rel="noopener noreferrer">
                                            <DownloadTwoToneIcon />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}

                        {/* Always render the AI Report link */}
                        <Grid item>
                            <IconButton>
                                <Link
                                    href={params.row.aiReport}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LinkTwoToneIcon />
                                </Link>
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            }

        },
    ];

    return (
        <>
            <PageTitleWrapper>
                <PageHeader
                    numCompletedReports={0}
                    onCompletedFilterClick={undefined}
                    completedFilter={0}
                    currentFilter={undefined}
                >
                </PageHeader>
            </PageTitleWrapper>
            <Grid container paddingX={4} >
                <Grid item xs={12} padding={2}>
                    <SearchBar
                        onChange={store.getSearchData}
                        onClear={store.clearData}
                        onCancel={store.clearData}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid id={'aireportprovider.dashboard' + store.userID} paddingX={1}>
                        {store.isLoadingAIReports && <LinearProgress variant="determinate" value={store.loadingProgress} />}
                        <DataGrid
                            data={store.aiReportData}
                            columns={columns}
                            pagination={true}
                            pageSize={store.pageSize}
                            loading={store.isLoadingAIReports}
                            rowCount={store.totalAIReports}
                            serverSidePagination={true}
                            onSortModelChange={(sortObj) => {
                                store.onSortChange(sortObj);
                                store.loadAIReports();
                            }}
                            onFilterChange={(filterObj) => {
                                store.onFilterChange(filterObj);
                                store.loadAIReports();
                            }}
                            onPageChange={(pageNumber) => {
                                store.currentPage = pageNumber;
                                store.loadAIReports();
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export const AIReportOrders = observer(AIReportsComponent);
