import { action, observable } from "mobx";
import { DictionaryListItem } from "./dictionarylistitem";

class CustomForm {
  @observable id: number = 0;
  @observable name: string = '';
  @observable title: string = '';
  @observable description: string = '';
  @observable isStandardForm: boolean | null = null;
  @observable isPatientForm: boolean | null = null;
  @observable canEdit: boolean | null = null;
  @observable categoryId: number | null = null;
  @observable isActive: boolean | null = null;
  @observable createdBy: number = 0;
  @observable createdOn: Date = new Date();
  @observable modifiedBy: number | null = null;
  @observable modifiedOn: Date | null = null;
  @observable category: DictionaryListItem | null = null;
  @observable selected: boolean = false;
}

export default CustomForm;
