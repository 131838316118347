import * as _ from 'lodash';
import moment from 'moment';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';

import { MyoCorrectFilters, MyoCorrectPlan, MyoCorrectSessionStatus, MyoCorrectStatus } from '../entities/Enums';
import MyoCorrectTherapist from '../entities/MyoCorrectTherapist';
import MyoCorrectPatient from '../entities/MyCorrectPatient';

import MyoCorrectSession from '../entities/MyoCorrectSession';
import { Gateway } from './Gateway';
import jwt_decode from 'jwt-decode';
import { MainStore } from '../stores/OldStores/MainStore';
import { breadcrumbsClasses, fabClasses } from '@mui/material';
import buildQuery from 'odata-query';
import { GridFilterModel } from '@mui/x-data-grid/models';
import { GridFilterMenuItem, GridLinkOperator, GridSortModel } from '@mui/x-data-grid-pro';
import { ActionContainer } from 'survey-core';

const SEVENDAYS: number = 7;
export class MyoCorrectStore {
    mainStore: MainStore;
    private _advocates: MyoCorrectTherapist[] = [];
    private _advisors: MyoCorrectTherapist[] = [];
    private _totalPages: number = 1;
    private _currentPage: number = 0;
    private _currentSubNavItem: number = 0;
    private _currentFilter: MyoCorrectFilters | -1 = -1;
    private _toggleShowAddPatientsModal: boolean = false;
    private _toggleShowDetailsPanelModal: boolean = false;
    private _hasMore: boolean = true;
    private _firstPageLoaded: boolean = false;
    private _open: boolean = false;
    private _isMyoCorrectAdvisor = false;
    private _isMyoCorrectAdvocate = false;
    private _isMyoCorrectAdmin = false;
    private _isVivosAdmin = false;
    private _isProvider = false;
    @observable numTodaysAppts: number = 0;
    @observable numActivePatients: number = 0;
    @observable numNewEvals: number = 0;
    @observable numUnassigned: number = 0;
    @observable numMissingNotes: number = 0;
    @observable numCompleted: number = 0;
    @observable numFrenectomyCases: number = 0;
    @observable numMyCases: number = 0;
    @observable numInitalEvalNotScheduled: number = 0;
    @observable numWithin7Days: number = 0;
    @observable didInitPatients = false;
    @observable isLoadingPatients = false;
    @observable loadingPatientsProgress = 0;
    @observable searchParam = '';
    @observable filter: any = {};
    userID: number = 0;
    pageSize: number = 100;

    private _totalRows: number = 0;
    get totalRows(): number {
        return this._totalRows;
    }
    set totalRows(value: any) {
        this._totalRows = value;
    }

    roles: any = [];

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.checkRoles();
    }

    get open(): any {
        return this._open;
    }

    set open(value: any) {
        this._open = value;
    }

    @observable filterNav: string = '*';
    @action setFilterNav = (newFilter: string) => (this.filterNav = newFilter);

    @observable origPatientData: MyoCorrectPatient[] = [];
    @action setOrigPatientData = newPatientData => (this.origPatientData = newPatientData);

    @observable patientData: MyoCorrectPatient[] = [];
    @action setPatientData = newPatientData => (this.patientData = newPatientData);

    @observable advocates: MyoCorrectTherapist[] = [];
    @action setAdvocates = (newAdvocates: MyoCorrectTherapist[]) => (this.advocates = newAdvocates);

    get toggleShowAddPatientsModal() {
        return this._toggleShowAddPatientsModal;
    }

    set toggleShowAddPatientsModal(value: any) {
        this._toggleShowAddPatientsModal = value;
    }

    get toggleShowDetailsPanelModal() {
        return this._toggleShowDetailsPanelModal;
    }

    set toggleShowDetailsPanelModal(value: any) {
        this._toggleShowDetailsPanelModal = value;
    }

    get advocateList() {
        return _.map(this._advocates, a => {
            return { value: a.userID, label: a.firstName + ' ' + a.lastName };
        });
    }

    @observable advisors: MyoCorrectTherapist[] = [];
    @action setAdvisors = (newAdvisors: MyoCorrectTherapist[]) => (this.advisors = newAdvisors);

    get currentFilter(): MyoCorrectFilters | -1 {
        return this._currentFilter;
    }

    set currentFilter(value: any) {
        this._currentFilter = value;
    }

    get hasMore(): boolean {
        return this._hasMore;
    }

    set hasMore(value: any) {
        this._hasMore = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: any) {
        this._totalPages = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    set currentPage(value: any) {
        this._currentPage = value;
    }

    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }

    @observable currentRow: any = {};
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);

    get firstPageLoaded(): boolean {
        return this._firstPageLoaded;
    }

    set firstPageLoaded(value: any) {
        this._firstPageLoaded = value;
    }

    get isMyoCorrectAdvisor(): any {
        return this._isMyoCorrectAdvisor;
    }

    set isMyoCorrectAdvisor(value: any) {
        this._isMyoCorrectAdvisor = value;
    }

    get isMyoCorrectAdvocate(): any {
        return this._isMyoCorrectAdvocate;
    }

    set isMyoCorrectAdvocate(value: any) {
        this._isMyoCorrectAdvocate = value;
    }

    get isMyoCorrectAdmin(): any {
        return this._isMyoCorrectAdmin;
    }

    set isMyoCorrectAdmin(value: any) {
        this._isMyoCorrectAdmin = value;
    }

    get isVivosAdmin(): any {
        return this._isVivosAdmin;
    }

    set isVivosAdmin(value: any) {
        this._isVivosAdmin = value;
    }
    get isProvider(): any {
        return this._isProvider;
    }

    set isProvider(value: any) {
        this._isProvider = value;
    }

    addAdvocate(user: any) {
        var userID = user.userID;
        var fname = user.firstName;
        var lname = user.lastName;
        var type = user.isSupervisor === true ? 1 : 2;
        var peopleID = user.peopleID;
        this.advocates.push(
            new MyoCorrectTherapist({
                userID: userID,
                firstName: fname,
                lastName: lname,
                myoCorrectTherapistType: type,
                personID: peopleID,
            }),
        );
    }

    addAdvisor(user: any) {
        var userID = user.userID;
        var fname = user.firstName;
        var lname = user.lastName;
        var type = user.isSupervisor === true ? 1 : 2;
        var peopleID = user.peopleID;
        this.advisors.push(
            new MyoCorrectTherapist({
                userID: userID,
                firstName: fname,
                lastName: lname,
                myoCorrectTherapistType: type,
                personID: peopleID,
            }),
        );
    }

    loadAdvocates = (): any => {
        this.advocates = [];
        this.advisors = [];
        this.loadAdvisors();
        if (this.isMyoCorrectAdmin === true) {
            Gateway.get('teams/users/admin/MyoCorrect').then(resp => {
                _.forEach(resp, user => {
                    this.addAdvocate(user);
                });
            });
            return;
        }
        else {
            Gateway.get('teams/user/' + this.userID).then(resp => {
                _.forEach(resp, team => {
                    let url = 'teams/users/' + team.teamId;
                    Gateway.get(url).then(users => {
                        _.forEach(users, user => {
                            this.addAdvocate(user);
                        });
                    });
                });
            });
        }
    };

    loadAdvisors = (): any => {
        Gateway.get('teams/advisors').then(resp => {
            _.forEach(resp, advisor => {
                this.addAdvisor(advisor);
            });
        });

        return;
    };

    loadPatient = async (vivosId: string) => {
        runInAction(() => {
            this.isLoadingPatients = true;
        });
        const url = `myocorrect/patient/${this.userID}/${vivosId}`;
        const resp = await Gateway.getOne(url);
        if (resp) {
            let patientIndex = this.origPatientData.findIndex(patient => patient.vivosId === vivosId);
            const newOrigPatientData = [...this.origPatientData];
            newOrigPatientData[patientIndex] = resp;
            this.setOrigPatientData(newOrigPatientData);
            patientIndex = this.patientData.findIndex(patient => patient.vivosId === vivosId);
            if (patientIndex > -1) {
                const newPatientData = [...this.patientData];
                newPatientData[patientIndex] = resp;
                this.setPatientData(newPatientData);
            }
        }
        runInAction(() => {
            this.isLoadingPatients = false;
        });
        return resp;
    };

    loadData = (): any => {
        this.loadAll();
        var url = 'myocorrect/patients/' + this.userID + '/' + 0 + '/' + this.pageSize;
        Gateway.get(url).then(resp => {
            this.totalPages = resp['pageCount'];
            this.setPatientData(
                _.sortBy(resp['results'], p => {
                    if (this.isMyoCorrectAdvisor) {
                        return _.isUndefined(p['assignedTherapistId']) || _.isNull(p['assignedTherapistId']) ? 0 : 1;
                    } else if (this.isMyoCorrectAdmin) {
                        return _.isUndefined(p['advisorId']) || _.isNull(p['advisorId']) ? 0 : 1;
                    } else {
                        return p;
                    }
                }).map(data => {
                    const newData = { ...data };
                    newData.name = data.firstName + ' ' + data.lastName;
                    return newData;
                }),
            );
            this.origPatientData = this.patientData;
            this.firstPageLoaded = true;
            this.setNumPatientValues();
        });
    };

    loadFilterNavData = (): any => {
        switch (this.filterNav) {
            case 'MyCases':
                this.patientData = this.filterPatients(this.origPatientData, MyoCorrectFilters.MyCases);
                break;
            case 'AllCases':
                this.patientData = this.filterPatients(this.origPatientData, MyoCorrectFilters.AllCases);
                break;
            case 'MyTeamCases':
                this.patientData = this.filterPatients(this.origPatientData, MyoCorrectFilters.MyTeamCases);
                break;
        }
        this.origPatientData = this.patientData;
    };

    saveEvent = async (
        vivosId: string,
        date: Date,
        time: string,
        email: string,
        sessionIndexNumber: number,
        calendlyUri: string,
        calendlyRescheduleUri: string,
    ) => {
        const url = 'myocorrect/appointment';
        let hour = parseInt(time.split(':')[0]) + (time.includes('pm') ? 12 : 0);
        if (hour === 24) {
            hour = 12;
        }
        const minute = parseInt(time.split(':')[1]);
        date.setHours(hour);
        date.setMinutes(minute);
        date.setSeconds(0);
        date.setMilliseconds(0);
        await Gateway.post(url, {
            date: date,
            vivosId: vivosId,
            email: email,
            sessionIndexNumber: sessionIndexNumber,
            CalendlyUri: calendlyUri,
            CalendlyRescheduleUri: calendlyRescheduleUri,
        });
    };

    updateEvent = async (vivosId: string, date: Date, time: string, sessionIndexNumber: number) => {
        const url = 'myocorrect/appointment';
        let hour = parseInt(time.split(':')[0]) + (time.includes('pm') ? 12 : 0);
        if (hour === 24) {
            hour = 12;
        }
        const minute = parseInt(time.split(':')[1]);
        date.setHours(hour);
        date.setMinutes(minute);
        date.setSeconds(0);
        date.setMilliseconds(0);
        await Gateway.put(url, { date: date, vivosId: vivosId, sessionIndexNumber: sessionIndexNumber });
    };

    loadMoreItems = () => {
        if (this.currentPage + 1 < this.totalPages) {
            let curpage = this.currentPage < this.totalPages ? this.currentPage + 1 : this.currentPage;
            this.currentPage = this.currentPage + 1;
            var url = 'myocorrect/patients/' + this.userID + '/' + curpage + '/' + this.pageSize;
            Gateway.get(url).then(resp => {
                this.setPatientData(
                    _.sortBy(this.patientData.concat(resp['results']), p => {
                        return _.isUndefined(p['assignedTherapistId']) || _.isNull(p['assignedTherapistId']) ? 0 : 1;
                    }).map(data => {
                        const newData = { ...data };
                        newData.name = data.firstName + ' ' + data.lastName;
                        return newData;
                    }),
                );
                this.origPatientData = this.patientData;
            });
        } else {
            this.hasMore = false;
        }
    };

    loadAll = async () => {
        runInAction(() => {
            this.didInitPatients = true;
            this.isLoadingPatients = true;
        });
        const patients: MyoCorrectPatient[] = [];
        var url = `myocorrect/patients/${this.userID}/${0}/${this.pageSize}`;
        const resp = await Gateway.get(url);
        const pages = resp['pageCount'];
        const totalPatients = resp['rowCount'];
        patients.push(...resp['results']);
        if (totalPatients !== 0) {
            runInAction(() => {
                this.loadingPatientsProgress = (patients.length / totalPatients) * 100;
            });
        }

        const promises: Promise<any[]>[] = [];
        let currentPage = 1;
        while (currentPage <= pages) {
            //    promises.push(
            Gateway.get(`myocorrect/patients/${this.userID}/${currentPage}/${this.pageSize}`).then(response => {
                patients.push(...response['results']);
                runInAction(() => {
                    this.loadingPatientsProgress = (patients.length / totalPatients) * 100;
                });
                return response;
            });
            //);
            currentPage++;
        }
        //  await Promise.allSettled(promises);

        runInAction(() => {
            this.isLoadingPatients = false;
            this.patientData = _.sortBy(patients, p => {
                if (this.isMyoCorrectAdvisor) {
                    return _.isUndefined(p.assignedTherapistId) ||
                        _.isNull(p.assignedTherapistId) ||
                        p.assignedTherapistId == 0
                        ? 0
                        : 1;
                } else if (this.isMyoCorrectAdmin) {
                    return _.isUndefined(p.advisorId) || _.isNull(p.advisorId) || p.advisorId == 0 ? 0 : 1;
                } else {
                    return p;
                }
            }).map(data => {
                const newData = { ...data };
                newData.name = data.firstName + ' ' + data.lastName;
                return newData;
            });
            this.origPatientData = this.patientData;
            this.setNumPatientValues();
        });
    };

    loadPage = async () => {
        runInAction(() => {
            this.isLoadingPatients = true;
        });
        let skip = this.currentPage * this.pageSize;

        var count = this.didInitPatients ? '$count=true&' : '';
        const query = buildQuery({ 
            top: this.pageSize, 
            skip: skip, 
            filter: this.filter ? this.filter : {}, 
            count: true,//this.didInitPatients, 
            orderBy: this.sort ? this.sort : {},
        });
        
        var url = `odata/patients/${this.userID}` + query;
        const resp = await Gateway.get(url);

        const totalPatients = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;
        let pages = (totalPatients + this.pageSize - 1) / this.pageSize;
        this.totalRows = totalPatients;
        let data = resp['value'];

        runInAction(() => {
            if (this.didInitPatients) {
                this.didInitPatients = false;
            }
            this.isLoadingPatients = false;
            this.totalPages = totalPatients;
            this.patientData = data.map(data => {
                const newData = { ...data };
                newData.name = data.firstName + ' ' + data.lastName;
                return newData;
            });
            this.origPatientData = this.patientData;
        });
    };

    setNumPatientValues = async () => {
        _.forEach(this.patientData, p => {
            if (
                p.myoCorrectStatusId === MyoCorrectStatus.Enrolled ||
                p.myoCorrectStatusId === MyoCorrectStatus['In Progress']
            ) {
                this.numActivePatients += 1;
            }
            if (p.myoCorrectStatusId === MyoCorrectStatus.Complete) {
                this.numCompleted += 1;
            }
            if (p.myoCorrectStatusId === MyoCorrectStatus.Enrolled) {
                this.numNewEvals += 1;
            }
            if (this.isMyoCorrectAdmin) {
                if ((_.isNull(p.advisorId) || _.isUndefined(p.advisorId) || p.advisorId == 0) && 
                (p.myoCorrectStatusId != 101 && p.myoCorrectStatusId !=102))
                {
                    this.numUnassigned += 1;
                }
            }
            if (this.isMyoCorrectAdvisor) {
                if (
                    (_.isNull(p.assignedTherapistId) ||
                    _.isUndefined(p.assignedTherapistId) ||
                    p.assignedTherapistId == 0) && (
                        p.myoCorrectStatusId != 101 && p.myoCorrectStatusId !=102
                    )
                ) {                    
                    this.numUnassigned += 1;
                }
            }
            if (!_.isNull(p.frenectomyStatuses)) {
                this.numFrenectomyCases += 1;
            }
            if (p.numMissingNotes) {
                this.numMissingNotes += p.numMissingNotes;
            }
            if (p.therapistAssignedDate) {
                var now = moment();
                if (now.diff(p.therapistAssignedDate, 'days') <= 7) {
                    this.numWithin7Days += 1;
                }
            }
            if (moment(p.nextSessionDate).format('MM/DD/yyyy') === moment().format('L')) {
                this.numTodaysAppts += 1;
            }
            if (p.assignedTherapistId == this.userID) {
                this.numMyCases += 1;
            }
        });
        this.loadFilterNavData();
    };

    setNumPatientValuesRequest = async () => { };

    onSubNavClick = (filter: MyoCorrectFilters, index: number) => {
        this.setPatientData(this.filterPatients(this.origPatientData, filter));
        this.currentSubNavItem = index;
    };

    filterPatients = (patientData: any, filter: MyoCorrectFilters) => {
        let data: MyoCorrectPatient[] = [];
        var now = moment();
        switch (filter) {
            case MyoCorrectFilters.TodayAppts:
                runInAction(() => {
                    this.filter = {
                        nextSessionDate: {
                            ge: { type: 'raw', value: moment().format('yyyy-MM-DD') },
                            le: { type: 'raw', value: moment().add(1, 'd').format('yyyy-MM-DD') },
                        },
                    };
                });
                break;
            case MyoCorrectFilters.AllActive:
                runInAction(() => {
                    this.filter = {
                        or: [
                            { myocorrectStatusId: { eq: MyoCorrectStatus.Enrolled } },
                            { myocorrectStatusId: { eq: MyoCorrectStatus['In Progress'] } },
                        ],
                    };
                });
                break;
            case MyoCorrectFilters.NewEvals:
                runInAction(() => {
                    this.filter = { myocorrectStatusId: { eq: MyoCorrectStatus.Enrolled } };
                });

                break;
            case MyoCorrectFilters.Unassigned:
                runInAction(() => {
                    if (this.isMyoCorrectAdmin) {
                        this.filter = {
                            and: [
                                {
                                    or: [
                                        { advisorId: { ne: { type: 'raw', value: 'null' } } },
                                        { advisorId: { eq: 0 } },
                                    ],
                                },
                                {
                                    or: [
                                        { assignedTherapistId: { eq: { type: 'raw', value: 'null' } } },
                                        { assignedTherapistId: { eq: 0 } },
                                    ],
                                }, 
                                {   and: [
                                        { myoCorrectStatusId : {ne : {type: 'raw', value: 101}}}
                                ]},
                                {   and: [
                                    { myoCorrectStatusId : {ne : {type: 'raw', value: 102}}}
                                ]}
                            ],                            
                        };
                    }
                    if (this.isMyoCorrectAdvisor && !this.isMyoCorrectAdmin) {
                        this.filter = {
                            or: [
                                { assignedTherapistId: { eq: { type: 'raw', value: 'null' } } },
                                { assignedTherapistId: { eq: 0 } },
                            ],
                            and:[
                                {   and: [
                                    { myoCorrectStatusId : {ne : {type: 'raw', value: 101}}}
                                ]},
                                {   and: [
                                    { myoCorrectStatusId : {ne : {type: 'raw', value: 102}}}
                                ]}
                            ]                            
                        };
                    }                    
                });
                break;
            case MyoCorrectFilters.MissingNotes:
                runInAction(() => {
                    this.filter = { numMissingNotes: { gt: 0 } };
                });
                break;
            case MyoCorrectFilters.Within7Days:
                runInAction(() => {
                    var sevendays = moment().subtract(7, "days");
                    this.filter = this.filter = {
                        and: [
                            { therapistAssignedDate: { ne: { type: 'raw', value: 'null' } } },
                            { '{CONVERT(datetime, therapistAssignedDate)}': { le: `${sevendays}` } }
                        ]
                    };
                });
                break;
            case MyoCorrectFilters.FrenectomyCases:
                runInAction(() => {
                    this.filter = this.filter = {
                        and: [
                            { frenectomyStatuses: { ne: { type: 'raw', value: 'null' } } },
                            { 'length(frenectomyStatuses)': { gt: 0 } },
                        ],
                    };
                });
                break;
            case MyoCorrectFilters.Completed:
                runInAction(() => {
                    this.filter = { statusId: { eq: MyoCorrectStatus.Complete } };
                });
                break;
            case MyoCorrectFilters.AllCases:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        data.push(rec);
                    }
                });
                break;

            case MyoCorrectFilters.MyCases:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (Number(rec.assignedTherapistId) === Number(this.userID)) {
                            data.push(rec);
                        }
                    }
                });
                break;
            case MyoCorrectFilters.MyTeamCases:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        _.forEach(this.advocates, adv => {
                            if (
                                Number(rec.assignedTherapistId) !== Number(this.userID) &&
                                Number(rec.assignedTherapistId) === Number(adv.userID)
                            ) {
                                data.push(rec);
                            }
                        });
                    }
                });
                break;
            case MyoCorrectFilters.InitalEvalNotScheduled:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        //if(rec.)
                    }
                });
                break;
            default:
                runInAction(() => {
                    this.filter = {};
                });
                break;
        }
        return data;
    };

    onFilter = (value, filter: MyoCorrectFilters) => {
        if (value) {
            this.setPatientData(this.filterPatients(this.origPatientData, filter));
            this.currentFilter = filter;
        } else {
            this.currentFilter = -1;
            this.patientData = this.origPatientData;
            this.filter = {};
        }
    };

    toggleAddPatientModal = () => {
        runInAction(() => {
            this.toggleShowAddPatientsModal = !this.toggleShowAddPatientsModal;
        });
    };

    toggleDetailsModal = () => {
        runInAction(() => {
            this.toggleShowDetailsPanelModal = !this.toggleShowDetailsPanelModal;
            if (!this.toggleShowDetailsPanelModal) {
                this.patientData = this.origPatientData;
            }
        });
    };

    closeDetailsModal = () => {
        runInAction(() => {
            this.clearData();
            this.toggleShowDetailsPanelModal = false;
        });
    };

    clearData = () => {
        runInAction(() => {
            this.currentRow = {};
            this.patientData = this.origPatientData;
            this.filter = {};
        });
    };

    @observable timeOutEvent: NodeJS.Timeout | null = null;

    @observable sort:any;
    onSortChange = (sortModel: GridSortModel): any => {
        runInAction(() => {
            if (Object.keys(sortModel).length > 0) {
                runInAction(() => {
                    this.sort = [sortModel[0].field  + ' ' +  sortModel[0].sort];                    
                });
            } else {
                this.sort = {};
            }
        });
    };

    onFilterChange = (filterModel: GridFilterModel): any => {
        runInAction(() => {
            let searchFilter = this.buildFilter(filterModel);
            if (Object.keys(searchFilter).length > 0) {
                runInAction(() => {
                    this.filter = searchFilter;
                    this.didInitPatients = true;
                });
            } else {
                this.filter = {};
            }
        });
    };

    buildFilter = (filterModel: GridFilterModel): any => {
        let customFilter = {};
        let linkOperator = filterModel.linkOperator !== undefined ? filterModel.linkOperator : '';
        customFilter[linkOperator] = [];
        filterModel.items.forEach(filter => {
            let currentOdata = {};
            let operator = {};
            let operatorValue = filter.operatorValue ? filter.operatorValue.toLowerCase() : '';
            let shouldNotAdd = true;
            let funcProperty = filter.columnField;
            let typeColumn = 'string';
            let actualValue;

            if (filter.columnField.includes('Date')) {
                typeColumn = 'date';
            }

            if (filter.columnField.includes('Id')) {
                switch (filter.columnField) {
                    case 'advisorId':
                        if (filter.value) {
                            actualValue = this.advisors.filter(
                                x =>
                                    x.firstName === filter.value.split(' ')[0] &&
                                    x.lastName === filter.value.split(' ')[1],
                            )[0].userID;
                            typeColumn = 'list';
                        }
                        break;
                    case 'assignedTherapistId':
                        if (filter.value) {
                            actualValue = this.advocates.filter(
                                x =>
                                    x.firstName === filter.value.split(' ')[0] &&
                                    x.lastName === filter.value.split(' ')[1],
                            )[0].userID;
                            typeColumn = 'list';
                        }
                        break;
                    case 'myoCorrectPlanId':
                        if (filter.value) {
                            actualValue = MyoCorrectPlan[filter.value];
                            typeColumn = 'list';
                        }
                        break;
                    default:
                        break;
                }
            }

            if (typeColumn === 'date') {
                switch (filter.operatorValue) {
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'not':
                        operator['ne'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'after':
                        operator['gt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrAfter':
                        operator['ge'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'before':
                        operator['lt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrBefore':
                        operator['le'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'list') {
                switch (filter.operatorValue) {
                    case 'is':
                        operator['eq'] = actualValue;
                        break;
                    case 'not':
                        operator['ne'] = actualValue;
                        break;
                    default:
                        break;
                }
            } else {
                switch (filter.operatorValue) {
                    case 'contains':
                    case 'startsWith':
                    case 'endsWith':
                        operator[operatorValue] = filter.value;
                        break;
                    case 'equals':
                        operator['eq'] = filter.value;
                        break;
                    case 'isEmpty':
                        shouldNotAdd = false;
                        operator['lt'] = 1;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isNotEmpty':
                        shouldNotAdd = false;
                        operator['gt'] = 0;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = filter.value;
                        break;
                    case 'not':
                        operator['ne'] = filter.value;
                        break;
                    default:
                        break;
                }
            }

            if (shouldNotAdd) {
                currentOdata[funcProperty] = operator;
            }

            customFilter[linkOperator].push(currentOdata);
        });
        return customFilter;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 1500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 1500);
        }
        return this.patientData;
    };

    @action filterBySearchString = () => {
        let searchFilter = { or: [{ vivosId: { contains: this.searchParam.toLowerCase() } }, { name: { contains: this.searchParam.toLowerCase() } }] };
        runInAction(() => {
            if (Object.keys(this.filter).length > 0) {
                this.filter = {
                    and: [this.filter, searchFilter],
                };
            } else {
                this.filter = searchFilter;
            }
            this.didInitPatients = true;
        });

        /*
        this.patientData = this.origPatientData.filter(
            x =>
                x.name.toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.vivosId.toLowerCase().includes(this.searchParam.toLowerCase()),
        );*/
    };

    getNextUncompletedSession(patientData: any) {
        let sessionData: MyoCorrectSession[] = [];

        for (var i = 0; i < patientData.length; i++) {
            sessionData = patientData[i].schedule
                .sort(a => a.scheduleStatus !== MyoCorrectSessionStatus.Completed)
                .sort((a, b) => {
                    return a.sessionIndexNumber - b.sessionIndexNumber;
                });
            patientData[i].schedule = sessionData;
        }
    }

    @action getFilterNums = () => {
        var url = 'myocorrect/patientscount/' + this.userID;
        Gateway.getOne(url).then(resp => {
            runInAction(() => {
                this.numTodaysAppts = resp.todayAppointments;
                this.numActivePatients = resp.activePatients;
                this.numNewEvals = resp.newEvals;
                this.numUnassigned = resp.unassigned;
                this.numMissingNotes = resp.missingNotes;
                this.numCompleted = resp.completed;
                this.numFrenectomyCases = resp.frenectomyCases;
                this.numWithin7Days = resp.assignedLast7Days;
            });
        });
    };

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                this.isVivosAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_PROVIDER) {
                this.isProvider = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVISOR) {
                this.isMyoCorrectAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE) {
                this.isMyoCorrectAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADMIN) {
                this.isMyoCorrectAdmin = true;
            }
        }
        return;
    };

    handleChange = (changedPatient: MyoCorrectPatient, event) => {
        var url = '/myocorrect/patients/' + changedPatient.id + '/advocates/' + event.target.value;
        var ind = -1;
        Gateway.post(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.patientData, p => {
                    return p.id == respData['patientId'];
                });
                var newPatientData = this.patientData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: MyoCorrectPatient = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.assignedTherapistId = respData['assignedTherapistId'];
                    }
                    return [...newData, copiedPatient];
                }, [] as MyoCorrectPatient[]);

                this.setPatientData(newPatientData);

                ind = _.findIndex(this.origPatientData, p => {
                    return p.id == respData['patientId'];
                });
                newPatientData = this.origPatientData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: MyoCorrectPatient = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.assignedTherapistId = respData['assignedTherapistId'];
                    }
                    return [...newData, copiedPatient];
                }, [] as MyoCorrectPatient[]);
                this.setOrigPatientData(newPatientData);
            }
        });
    };

    handleAdvisorChange = (changedPatient: MyoCorrectPatient, event) => {
        var url = '/myocorrect/patients/' + changedPatient.id + '/advisors/' + event.target.value;
        var ind = -1;
        Gateway.post(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.patientData, p => {
                    return p.id == respData['patientId'];
                });
                var newPatientData = this.patientData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: MyoCorrectPatient = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.advisorId = respData['advisorId'];
                    }
                    return [...newData, copiedPatient];
                }, [] as MyoCorrectPatient[]);

                this.setPatientData(newPatientData);

                ind = _.findIndex(this.origPatientData, p => {
                    return p.id == respData['patientId'];
                });
                newPatientData = this.origPatientData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: MyoCorrectPatient = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.advisorId = respData['advisorId'];
                    }
                    return [...newData, copiedPatient];
                }, [] as MyoCorrectPatient[]);
                this.setOrigPatientData(newPatientData);
            }
        });
    };

    updatePatientDataItem = (id: number, value: string, fieldName: string, row: any) => {
        const idx = _.findIndex(this.patientData, p => {
            return p.id === id;
        });
        var newPatientData = this.patientData.reduce((newData, currentPatient, index) => {
            const copiedPatient: MyoCorrectPatient = { ...currentPatient };
            if (idx === index) {
                copiedPatient[fieldName] = value;
            }
            return [...newData, copiedPatient];
        }, [] as MyoCorrectPatient[]);

        this.setPatientData(newPatientData);
    }

    getPatientDataItem = (id: number) => {
        return this.patientData.find(p => p.id === id);
    }
}
