// Vendors
import React, { useEffect, useState } from "react";
import { Card, LinearProgress, Button, Grid, Typography, List, ListItem, ListItemText, styled, Avatar, alpha, Divider, Box, linearProgressClasses } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ConnectingAirportsOutlined, StickyNote2Rounded } from "@mui/icons-material";

// Components
import { CreateRxWizard } from "../../../../modals/PrescriptionWizard/CreateRxWizard";
import ModalContainer from "../../../ModalContainer";
import { ViewRx } from "../../../ViewRx/ViewRx";

// Entities
import { ProductionStatus } from "../../../../entities/Enums";

// Stores
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";
import { PatientStore } from "../../../../api/PatientStore";
import CommentsEditStore from '../../../CommentsPanel/CommentsEditStore';

// API
import { observer } from "mobx-react-lite";
import moment from "moment";
import _, { first } from "lodash";
import { usePrescriptionStore } from "../../../../stores/Prescription/CreateEdit/MainStoreContext";
import ConfirmDialog from "../../../ConfirmDialog/ConfirmDialog";
import { ReworkClaim } from "../../../../models/state_models/reworkClaim";
import { CommentsPanel } from "../../../CommentsPanel/CommentsPanel";

interface IGIProps {
    prescription: any;
    patient: any;
}

const LinearProgressInfo = styled(LinearProgress)(
    ({ theme }) => `
          height: 8px;
          border-radius: ${theme.general.borderRadiusLg};
  
          &.${linearProgressClasses.colorPrimary} {
              background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.2)};
          }
          
          & .${linearProgressClasses.bar} {
              border-radius: ${theme.general.borderRadiusLg};

          }
      `
);

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
          background: ${theme.colors.alpha.white[100]};
          width: auto;
          height: ${theme.spacing(9)};
          padding: ${theme.spacing(1.8)};
          box-shadow: 0 0.180rem .3rem ${alpha(
        theme.colors.alpha.black[100],
        0.3
    )}, 0 .326rem 3rem ${alpha(theme.colors.alpha.black[100], 0.2)};
    `
);

export const RxStatusDetails: React.FC<IGIProps> = observer(({ prescription, patient }) => {
    const [commentsEdit] = useState(() => new CommentsEditStore());
    const store = usePrescriptionStore().prescriptionStore;
    const patientStore = useMainStoreContext().patientEditStore;
    const labManagementStore = useMainStoreContext().labManagementStore;
    const shopifyStore = useMainStoreContext().shopifyStore;
    const visitStore = useMainStoreContext().visitRecordStore;
    const [isEdit, setIsEdit] = useState<any>();
    const [canEdit, setCanEdit] = useState<any>(false);
    const [isAIS, setIsAIS] = useState<boolean>(false);
    const [prodStatusId, setProdStatusId] = useState(prescription.productionStatus ? prescription.productionStatus : 0);
    const [componentVariance, setComponentVariance] = useState<Record<string, string>>({})
    const [showViewRx, setShowViewRx] = useState<boolean>(false)
    const [showConfirmClosePrescriptionForm, setShowConfirmClosePrescriptionForm] = useState(false);

    useEffect(() => {
        let canEditFlag = prodStatusId === ProductionStatus.Pending ||
            prodStatusId === ProductionStatus.Hold;
        setCanEdit(canEditFlag);
        setIsAIS(store.roles.includes(process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) || store.roles.includes(process.env.REACT_APP_VIVOS_AIS_ADMIN));
        store.setPrescription(prescription);
        visitStore.setVisitPatientId(patient.id);
        visitStore.setId(prescription.visitId);
        visitStore.loadVisit();

        if (shopifyStore.client) {
            shopifyStore.fetchProducts().then((products) => {
                shopifyStore.setProducts(products);
                shopifyStore.setCartOpen(true);
                store.loadEcommerceData(products);
            });
        }
    }, []);

    useEffect(() => {

    }, [store.prescription])

    const handleUpdatePrescription = () => {
        store.setAppliance(prescription.appliance);
        store.setPrescription(prescription);
        setIsEdit(true);
        store.setIsUpdating(true);
        if (shopifyStore.client) {
            //store.setStep(true);
            store.setSelectedLab(store.prescription.lab);
            store.setSelectedApplianceLogo(store.applianceOptions.find(x => x.id === store.prescription.appliance?.name)?.logo ?? '');
            store.setSelectedLabLogo(store.labsImages.find(lab => lab.name === store.prescription.lab?.name)?.logo ?? '');
            //store.setSelectedAppliance(store.applianceFamiliesSetups.find(x=> ))
            store.setApplianceConstructionDiagramFilename(store.prescription.diagramUrl);

            for (let index = 0; index < store.applianceFamiliesSetups.length; index++) {
                var currentFamily = store.applianceFamiliesSetups[index];
                var applianceSetup = currentFamily.applianceItemSetups.find(x => x.applianceType == store.prescription.appliance?.applianceType);
                if (applianceSetup) {

                    var variantItemSet = applianceSetup.shopifyProduct.variants
                        .find(x => store.prescription.order?.orderDetails[0].externalEcommerceItemId == x.id);
                    if (variantItemSet) {
                        let variants: Record<string, any> = variantItemSet.selectedOptions
                            .reduce((acc: Record<string, any>, item) => {
                                acc[item.name] = item.value;
                                return acc;
                            }, {});
                        if (variants) {
                            //store.variantsSelected(variants);
                            applianceSetup.eCommerceShopifyItem.variantsSelected = variants;
                            store.setVariantsSelected(applianceSetup.eCommerceShopifyItem.variantsSelected);

                        }
                    }
                    store.setSelectedAppliance(currentFamily);
                    store.setSelectedeCommerceItem(applianceSetup);
                }

            }
            //store.setStep(true);
        }
    }

    const handleConfirmClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
        store.setIsUpdating(false);
        setIsEdit(false);
        store.resetStep();
    };

    const handleCancelClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
    };

    const handleCloseModal = () => {
        setShowConfirmClosePrescriptionForm(true);
    };

    return (
        <Grid className="step-wizard-container">
            <Grid className="box-wizard-container">
                <Grid container paddingBottom={2}>
                    <Grid item xs={3} className="title-wizard">RX Status Details:</Grid>
                    <Grid item xs={9} container justifyContent={'right'} alignContent={'center'} spacing={1}>
                        <Grid item>
                            <Button
                                variant={'outlined'}
                                onClick={commentsEdit.handleClickModal}
                                color="primary"
                            >
                                Rx Comments
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'outlined'}
                                onClick={() => { setShowViewRx(true) }}
                                color="primary"
                            >
                                <VisibilityIcon /> View RX
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'outlined'}
                                onClick={() => { handleUpdatePrescription() }}
                                color="primary"
                                disabled={!canEdit}>
                                <EditIcon /> Edit RX
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Card
                        sx={(theme) => ({
                            pt: 2.5,
                            pb: 2,
                            px: 2.5,
                            flexGrow: 1,
                            background: `${theme.colors.gradients.blue3}`
                        })}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            flex={1}
                            mb={1}
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Box flex={1}>
                                <LinearProgressInfo
                                    variant="determinate"
                                    sx={(theme) => ({
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor:
                                                ProductionStatus[prescription.productionStatus] === "Ready" ? `${theme.colors.info.main}`
                                                    : ProductionStatus[prescription.productionStatus] === "In Fabrication" ? `${theme.colors.info.main}`
                                                        : ProductionStatus[prescription.productionStatus] === "Shipped" ? `${theme.colors.success.main}`
                                                            : ProductionStatus[prescription.productionStatus] === "Cancelled" ? `${theme.colors.error.main}`
                                                                : `${theme.colors.warning.main}`
                                        }
                                    })}
                                    value={
                                        ProductionStatus[prescription.productionStatus] === "Pending" ? 0
                                            : ProductionStatus[prescription.productionStatus] === "Ready" ? 33
                                                : ProductionStatus[prescription.productionStatus] === "In Fabrication" ? 66
                                                    : ProductionStatus[prescription.productionStatus] === "Shipped" ? 100 : 100
                                    }
                                />
                            </Box>
                        </Box>
                        <Typography
                            variant="subtitle2"
                            sx={(theme) => ({
                                color: `${theme.colors.alpha.trueWhite[70]}`
                            })}
                            noWrap
                        >
                            Status: {ProductionStatus[prescription.productionStatus]}
                        </Typography>
                    </Card>
                </Grid>
                <Grid>
                    <Grid container direction={'column'} spacing={1} padding={2}>
                        <List
                            sx={{
                                py: 0
                            }}
                        >
                            <ListItem sx={{ alignItems: 'flex-start', p: 1 }}>
                                <ListItemText
                                    primary={
                                        <Grid container justifyContent={'space-evenly'}>
                                            <AvatarWrapper
                                                variant="rounded"
                                                alt="Appliance"
                                                src={store.applianceOptions.find(a => a.constructId === prescription?.appliance?.applianceType)?.logo}
                                            />
                                            <AvatarWrapper
                                                variant="rounded"
                                                alt="Appliance"
                                                src={store.labsImages.find(lab => lab.name === prescription?.lab?.name)?.logo}
                                            />
                                        </Grid>
                                    }
                                />
                            </ListItem>
                            <Divider />
                            <ListItem
                                sx={{
                                    alignItems: 'flex-start',
                                    p: 1
                                }}
                            >
                                <ListItemText
                                    primary={<Typography variant="subtitle2">Prescription</Typography>}
                                />
                                <Box alignSelf="center">
                                    <Typography variant="h4">
                                        {prescription?.appliance?.name}
                                    </Typography>
                                </Box>
                            </ListItem>
                            <Divider />

                            {prescription?.appliance?.base}
                            {prescription?.appliance?.name?.includes("PEx") && <><ListItem
                                sx={{
                                    alignItems: 'flex-start',
                                    p: 1
                                }}
                            >
                                <ListItemText
                                    primary={<Typography variant="subtitle2">PEx Digital Design Status</Typography>}
                                />
                                <Box alignSelf="center">
                                    <Typography variant="h4">
                                        {store.digitalDesignStatusValues.find(x => x.id === prescription?.digitalDesignStatus)?.name}
                                    </Typography>
                                </Box>
                            </ListItem>
                                <Divider />
                            </>}
                            <ListItem
                                sx={{
                                    alignItems: 'flex-start',
                                    p: 1
                                }}
                            >
                                <ListItemText
                                    primary={<Typography variant="subtitle2">RX Timeline</Typography>}
                                    secondary={
                                        <Grid container paddingTop={1} spacing={1} paddingLeft={3}>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Submitted On:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.submittedDate ? moment(prescription?.submittedDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Needed by:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.neededByDate ? moment(prescription?.neededByDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Lab Received On:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.latestQcstatus ? moment(prescription?.latestQcstatus).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <Typography variant="h4">Lab Received on:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.labReceivedDate ? moment(prescription?.labReceivedDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid> */}
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Fabrication Started On:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.fabricationStartDate ? moment(prescription?.fabricationStartDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Estimated to Ship On:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.estimateShippedDate ? moment(prescription?.estimateShippedDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Shipped On:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.shippedDate ? moment(prescription?.shippedDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Connected RX / Rework:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.connectedRxRework ? prescription?.connectedRxRework : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h4">Tracking number:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription.trackingNo ? prescription?.trackingNo : ''}</Typography>
                                            </Grid>
                                            {/* <Grid item xs={6}>
                                                <Typography variant="h4">Delivered On:</Typography>
                                            </Grid>
                                            <Grid item xs={6} container justifyContent={'right'}>
                                                <Typography variant="h4">{prescription?.deliveredDate ? moment(prescription?.deliveredDate).format('MM/DD/yyyy') : ''}</Typography>
                                            </Grid> */}
                                        </Grid>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </List>
                    </Grid>
                </Grid>
                {/* <Grid container direction="column" spacing={1} padding={2}>
                    <List sx={{ py: 0 }}>
                        <ListItem sx={{ alignItems: "flex-start", p: 1 }}>
                            <ListItemText
                                primary={
                                    <Grid container justifyContent="space-evenly">
                                        <AvatarWrapper
                                            variant="rounded"
                                            alt="Appliance"
                                            src={store.applianceOptions.find(a => a.id === prescription.appliance.name)?.logo}
                                        />
                                        <AvatarWrapper
                                            variant="rounded"
                                            alt="Appliance"
                                            src={store.labsImages.find(lab => lab.name === prescription.lab.name)?.logo}
                                        />
                                    </Grid>
                                }
                            />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ alignItems: "flex-start", p: 1 }}>
                            <ListItemText
                                primary={<Typography variant="subtitle2">Prescription Type</Typography>}
                            />
                            <Box alignSelf="center">
                                <Typography variant="h4">[Rework/New]</Typography>
                            </Box>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ alignItems: "flex-start", p: 1 }}>
                            <ListItemText
                                primary={<Typography variant="subtitle2">QC Status</Typography>}
                            />
                            <Box alignSelf="center">
                                <Typography variant="h4">
                                    {store.qcStatusValues.find(x => x.id === prescription?.qcstatus)?.name}
                                </Typography>
                            </Box>
                        </ListItem>
                        <Divider />
                        {prescription.appliance.base}
                        {prescription.appliance.name.includes("PEx") && (
                            <>
                                <ListItem sx={{ alignItems: "flex-start", p: 1 }}>
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">PEx Digital Design Status</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {store.digitalDesignStatusValues.find(x => x.id === prescription?.digitalDesignStatus)?.name}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                            </>
                        )}
                        <ListItem sx={{ alignItems: "flex-start", p: 1 }}>
                            <ListItemText
                                primary={<Typography variant="subtitle2">RX Timeline</Typography>}
                                secondary={
                                    <Timeline position="alternate">
                                        {timelineEvents.map((event, index) => (
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent>
                                                    <Typography variant="body2" color="textSecondary">
                                                        {event.date ? moment(event.date).format('MM/DD/yyyy') : 'N/A'}
                                                    </Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot />
                                                    {index < timelineEvents.length - 1 && <TimelineConnector />}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant="h6" component="span">
                                                        {event.label}
                                                    </Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </List>
                </Grid> */}



                {/* <Grid container>
                    <Grid item xs={6}>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Patient:</Typography></Grid>
                            <Typography>{isAIS ? patient?.name : patient?.firstName + " " + patient?.lastName}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Assigned Admin:</Typography></Grid>
                            <Typography>{prescription?.assignedAdminNavigation?.usersDetailUsers[0]?.lastName + ', ' + prescription?.assignedAdminNavigation?.usersDetailUsers[0]?.firstName + ' ' +
                                (_.isNull(prescription?.assignedAdminNavigation?.usersDetailUsers[0]?.middleName) ? '' : prescription?.assignedAdminNavigation?.usersDetailUsers[0]?.middleName)}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">RX Status:</Typography></Grid>
                            <Typography>{store.prescriptionStatusValues.find(x => x.id === prescription?.rxStatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">QC Status:</Typography></Grid>
                            <Typography>{store.qcStatusValues.find(x => x.id === prescription?.qcstatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">QC Notes:</Typography></Grid>
                            <Typography>{prescription?.qcnotes}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Latest QC Status Date:</Typography></Grid>
                            <Typography>{prescription?.latestQcstatus ? moment(prescription?.latestQcstatus).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Production Status:</Typography></Grid>
                            {isAIS ?
                                <TextField
                                    id="select-dropdown"
                                    select
                                    label="Production Status"
                                    value={prodStatusId}
                                    disabled={prodStatusId === ProductionStatus["In Fabrication"]}
                                    onChange={event => {
                                        setProdStatusId(event.target.value as unknown as number);
                                        labManagementStore.handleProductionStatusChange(prescription, event.target.value as unknown as number);
                                    }
                                    }
                                >
                                    {store.productionStatusValues.map((ps) =>
                                        (<MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>))}
                                </TextField>
                                :
                                <Typography>{store.productionStatusValues.find(x => x.id === prescription?.productionStatus)?.name}</Typography>}
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Assigned Date:</Typography></Grid>
                            <Typography>{prescription?.assignedDate ? moment(prescription?.assignedDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Needed On:</Typography></Grid>
                            <Typography>{prescription?.neededByDate ? moment(prescription?.neededByDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Prescription URL:</Typography></Grid>
                            <Typography><a href={prescription?.prescriptionUrl} target="_blank">{prescription?.prescriptionUrl ? 'Link' : ''}</a></Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lower Arch URL:</Typography></Grid>
                            <Typography><a href={prescription?.lowerArchUrl} target="_blank">{prescription?.lowerArchUrl ? 'Link' : ''}</a></Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">PEx Design URL:</Typography></Grid>
                            <Typography><a href={prescription?.pexDesignFileUrl} target="_blank">{prescription?.pexDesignFileUrl ? 'Link' : ''}</a></Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Digital Design Status:</Typography></Grid>
                            <Typography>{store.digitalDesignStatusValues.find(x => x.id === prescription?.digitalDesignStatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab Model Status:</Typography></Grid>
                            <Typography>{store.labModelStatusValues.find(x => x.id === prescription?.labModelStatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Bite Status:</Typography></Grid>
                            <Typography>{store.biteStatusValues.find(x => x.id === prescription?.labBiteStatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Estimated Shipped Date:</Typography></Grid>
                            <Typography>{prescription?.estimateShippedDate ? moment(prescription?.estimateShippedDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Tracking No:</Typography></Grid>
                            <Typography>{prescription?.trackingNo}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab:</Typography></Grid>
                            <Typography>{prescription?.lab?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab Case Notes:</Typography></Grid>
                            <Typography>{prescription?.labCaseNotes}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Appliance:</Typography></Grid>
                            <Typography>{prescription?.appliance.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">RX ID:</Typography></Grid>
                            <Typography>{prescription?.rxId}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Submitted Date:</Typography></Grid>
                            <Typography>{prescription?.submittedDate ? moment(prescription?.submittedDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Model Status:</Typography></Grid>
                            <Typography>{store.modelStatusValues.find(x => x.id === prescription?.modelStatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Model Received Date:</Typography></Grid>
                            <Typography>{prescription?.modelDateReceived ? moment(prescription?.modelDateReceived).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Physical to Digital Status:</Typography></Grid>
                            <Typography>{store.physicalToDigitalStatusValues.find(x => x.id === prescription?.physicalToDigitalStatus)?.name}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Digital Converted Date:</Typography></Grid>
                            <Typography>{prescription?.convertedToDigitalDate ? moment(prescription?.convertedToDigitalDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Shipped Date:</Typography></Grid>
                            <Typography>{prescription?.shippedDate ? moment(prescription?.shippedDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab Received Date:</Typography></Grid>
                            <Typography>{prescription?.labReceivedDate ? moment(prescription?.labReceivedDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Diagram URL:</Typography></Grid>
                            <Typography><a href={prescription?.diagramUrl} target="_blank">{prescription?.diagramUrl ? 'Link' : ''}</a></Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Upper Arch URL:</Typography></Grid>
                            <Typography><a href={prescription?.upperArchUrl} target="_blank">{prescription?.upperArchUrl ? 'Link' : ''}</a></Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Constructed Bite URL:</Typography></Grid>
                            <Typography><a href={prescription?.constructedBiteUrl} target="_blank">{prescription?.constructedBiteUrl ? 'Link' : ''}</a></Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Digital Design Completed On:</Typography></Grid>
                            <Typography>{prescription?.digitalDateDesignCompleted ? moment(prescription?.digitalDateDesignCompleted).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab Model Received On:</Typography></Grid>
                            <Typography>{prescription?.labModelReceived ? moment(prescription?.labModelReceived).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Lab Bite Received On:</Typography></Grid>
                            <Typography>{prescription?.labBiteReceived ? moment(prescription?.labBiteReceived).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Fabrication Start Date:</Typography></Grid>
                            <Typography>{prescription?.fabricationStartDate ? moment(prescription?.fabricationStartDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Delivered Date:</Typography></Grid>
                            <Typography>{prescription?.deliveredDate ? moment(prescription?.deliveredDate).format('MM/DD/yyyy') : ''}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Shipping Status:</Typography></Grid>
                            <Typography>{store.shippingStatusValues.find(x => x.id === prescription?.shippingStatus)?.name}</Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
            </Grid>
            <ModalContainer
                show={isEdit}
                title={`Prescription for ${patient?.firstName ?? ""} ${patient?.lastName ?? ""} - ${patient?.vivosId ?? ""}`}
                onClose={handleCloseModal}
                onClick={() => {
                    store.setIsUpdating(false);
                    setIsEdit(false);
                    store.resetStep();
                }}
            >
                <CreateRxWizard patientId={patient?.id} isEdit={true} sendOnCloseEvent={() => {
                    store.setIsUpdating(false);
                    store.setShowRxStatusDetails(false);
                    setIsEdit(false);
                    store.resetStep();
                }} prescription={prescription} />
                <ConfirmDialog
                    title="Confirm Exit"
                    open={showConfirmClosePrescriptionForm}
                    onClose={handleCancelClosePrescriptionForm}
                    onConfirm={handleConfirmClosePrescriptionForm}
                    message="Are you sure you want to close without saving?"
                />
            </ModalContainer>
            <ModalContainer
                show={showViewRx}
                title="PRESCRIPTION"
                onClose={() => {
                    setShowViewRx(false)
                }}
                onClick={() => {
                    setShowViewRx(false)
                }}
            >
                <ViewRx patient={patient} prescription={prescription} />
            </ModalContainer>
            <CommentsPanel
                commentType={15551}
                significantId={prescription.id}
                isOpen={commentsEdit.isOpenModal}
                onClose={commentsEdit.handleCloseModal.bind(this)}
            />
        </Grid>
    )
}
);
