// Vendors
import { Grid, Avatar, Typography, styled, Box, Alert } from "@mui/material";
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { useDropzone } from "react-dropzone";
import { Gateway } from "../../../api/Gateway";
import { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";

const BoxUploadWrapper = styled(Box)(
	({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);

const AvatarWrapper = styled(Avatar)(
	({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};

  `
);

const RxPExDropzone = () => {

	var errorText = "Invalid file type";
	const [isInvalidFileType, setIsInvalidFileType] = useState<boolean>(false);
	const prescriptionStore = usePrescriptionStore().prescriptionStore;

	useEffect(() => {
		setIsInvalidFileType(false);
	}, [])

	const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
		if (fileRejections.length > 0 && acceptedFiles == 0) {
			setIsInvalidFileType(true);
		} else {
			setIsInvalidFileType(false);
			const file = acceptedFiles[0];
			const body = new FormData();
			const base64 = await fileToBase64(file);

			body.append('FileContent', base64.substring(base64.indexOf(',') + 1));
			body.append('FileName', file.name);

			var updatedPrescription = await Gateway.putStrongType<any>(`/prescription/${prescriptionStore.getUserId()}/${prescriptionStore.prescription.id}/PExDesign`, body);
			prescriptionStore.setPExDesignFilename(updatedPrescription.pexDesignFileUrl);
			prescriptionStore.setPrescription(updatedPrescription);
		}
	}, []);

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				if (reader.readyState === FileReader.DONE) {
					const base64 = reader.result as string;
					resolve(base64);
				}
			};

			reader.onerror = () => {
				reject(new Error('Error reading file'));
			};

			reader.readAsDataURL(file);
		});
	}

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		'accept': {
			'application/stl': ['.stl', '.STL']
		},
	});
	return (
		<Grid>
			{prescriptionStore.isUpdating && <Typography
				sx={{
					mt: 2
				}}
			>
				{prescriptionStore.pexDesignFilename}
			</Typography>}
			{!prescriptionStore.isUpdating && <Grid container {...getRootProps()}>
				<Box paddingX={2}>
					<BoxUploadWrapper>
						<input {...getInputProps()} />
						<AvatarWrapper variant="rounded">
							<CloudUploadTwoToneIcon />
						</AvatarWrapper>
						<Typography
							sx={{
								mt: 2
							}}
						>
							{prescriptionStore.pexDesignFilename ?
								'' : 'Upload PEx Design'}
						</Typography>
						{isInvalidFileType && <Box paddingY={2} paddingX={1} >
							<Alert severity="error" onClose={() => { }}>
								{errorText}
							</Alert>
						</Box>}
						{!isInvalidFileType && prescriptionStore.pexDesignFilename && <Box paddingY={2} paddingX={1} >
							<Alert severity="success" onClose={() => { }}>
								Upload Successful on {moment(prescriptionStore.prescription.digitalDateDesignCompleted).format('MM/DD/yyyy')}
							</Alert>
						</Box>}
					</BoxUploadWrapper>
				</Box>
			</Grid>}
		</Grid>
	);
};

export default observer(RxPExDropzone);