
interface PatientDocumentData {
    id?: number,
    pathUrl?: string,
    data64String?: string,
    imageType: any,
    isActive: boolean,
    fileName: string
}

interface PatientDocument extends PatientDocumentData { }

class PatientDocument {
    constructor({ id, pathUrl, data64String, imageType, isActive, fileName }: PatientDocumentData) {
        this.id = id;
        this.pathUrl = pathUrl;
        this.data64String = data64String;
        this.imageType = imageType;
        this.isActive = isActive;
        this.fileName = fileName;
    }

    toObject(): PatientDocumentData {
        return { ...this };
    }
}
export default PatientDocument;