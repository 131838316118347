import React, { ChangeEvent, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    useTheme,
    Grid,
    alpha,
    Avatar,
    Button,
    Tabs,
    Tab,
    Divider,
    Link
} from "@mui/material";

const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);
const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
          overflow: visible !important;
  
          .MuiTabs-scroller {
              overflow: visible !important;
          }
  
          .MuiButtonBase-root {
              text-transform: uppercase;
              font-size: ${theme.typography.pxToRem(12)};
  
              &:last-child {
                  margin-right: 0;
              }
          }
      `
);
const ButtonWrapper = styled(Button)(
    ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
    `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
          background-color: ${theme.colors.success.main};
          color: ${theme.palette.getContrastText(theme.colors.success.dark)};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          box-shadow: ${theme.colors.shadows.success};
          top: -${theme.spacing(4)};
          position: absolute;
          left: ${theme.spacing(3)};
    `
);

function SupportContent() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const tabs = [
        { value: 'overview', label: 'AIS Overview' },
        // { value: 'paoverview', label: 'PA Overview' },
        { value: 'consultations', label: 'Consultations' },
        // { value: 'faqs', label: 'FAQs' }
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };


    return (
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={12} xl={5}>
                    <BoxComposed
                        display="flex"
                        alignItems="center"
                        sx={{
                            minHeight: '100%',
                            background: `${theme.colors.gradients.black1}`
                        }}
                    >
                        <BoxComposedContent px={8} pt={8} pb={4}>
                            <Avatar
                                variant="square"
                                sx={{
                                    mx: 'auto',
                                    mb: 2,
                                    width: 'auto',
                                    height: 400,
                                    '& img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                src="/logos/AIS.png"
                            />
                            <Typography lineHeight="1.5" fontWeight="normal" variant="h5">

                            </Typography>
                        </BoxComposedContent>
                    </BoxComposed>
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Box py={3} px={4}>
                        <TabsWrapper
                            centered
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                    </Box>

                    <Divider />
                    {currentTab === 'overview' && (
                        <>
                            <Box p={4}>
                                <Grid container>
                                    <Grid item xs={12} paddingX={4}>
                                        <Typography lineHeight="2" fontWeight="normal" variant="h5" textAlign='justify'>
                                        The Airway Intelligence Service (AIS) is a premier multidisciplinary division of Vivos, committed to 
                                        providing an extensive range of appliances designed to meet the diverse needs of patients. As a leader 
                                        in innovation, AIS offers Vivos Practitioners access to a cutting-edge Provider Portal, enabling vital 
                                        insights and a comprehensive patient management platform. AIS is renowned for producing in-depth reports 
                                        enriched with proprietary diagnostic analyses, alongside expert support and advisory services for Vivos 
                                        Practitioners worldwide.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} paddingX={4} paddingY={2}>
                                        <Typography lineHeight="2" fontWeight="normal" variant="h5" textAlign='justify'>
                                            The Airway Intelligence Service (AIS) is available for all providers utilizing Vivos and is part
                                            of all Vivos Provider Programs. With a dedicated team, AIS supports you and your patients throughout 
                                            the entire treatment journey. If you have questions regarding the following, AIS is here to assist:
                                            </Typography>
                                     </Grid>

                                     <Grid item xs={12} paddingX={4} paddingY={2}>
                                        <ul>
                                            <li>vCloud Provider Portal</li>
                                            <li>Appliances</li>
                                            <li>Airway Intelligence Reports</li>
                                            <li>Invoices</li>
                                            <li>Orders</li>
                                            <li>Warranty and Repair Inquiries</li>
                                            <li>VivoScore Ring Lease or Subscriptions</li>
                                            <li>General appliance design or clinical applications</li>
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} paddingX={4} paddingY={2}>
                                        <Typography lineHeight="2" fontWeight="normal" variant="h5" textAlign='justify'>
                                        In addition, AIS offers advanced consultation services through our AIS Specialist Consultants. 
                                        If the general support system doesn’t fully address your needs, we recommend utilizing this 
                                        advanced service, available on a per-patient basis. These consultations are exclusively for 
                                        Vivos clinicians and are designed to enhance clinical confidence by reinforcing the core 
                                        Vivos education and the “Vivos Method.” This service serves as an extension of Vivos training, 
                                        providing clinicians with the opportunity to deepen their knowledge and experience in patient 
                                        care.

                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {/* {currentTab === 'paoverview' && (
                        <>
                            <Box p={4}>
                                <Grid container>
                                    <Grid item xs={12} paddingX={4}>
                                        <Typography lineHeight="2" fontWeight="normal" variant="h5" textAlign='justify'>
                                        The Airway Intelligence Service (AIS) is a premier multidisciplinary division of Vivos, committed to 
                                        providing an extensive range of appliances designed to meet the diverse needs of patients. As a leader 
                                        in innovation, AIS offers Vivos Practitioners access to a cutting-edge Provider Portal, enabling vital 
                                        insights and a comprehensive patient management platform. AIS is renowned for producing in-depth reports 
                                        enriched with proprietary diagnostic analyses, alongside expert support and advisory services for Vivos 
                                        Practitioners worldwide.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )} */}
                    {currentTab === 'consultations' && (
                        <>
                            <Box p={4}>
                                <Grid container>
                                    <Grid item xs={12} padding={4} container>
                                        {/* <Grid item xs={12}>
                                            <Typography lineHeight="2" fontWeight="normal" variant="h3">
                                                How do I schedule an Airway Intelligence Service (AIS) consultation?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} paddingLeft={4}>
                                            <Typography lineHeight="2" fontWeight="normal" variant="h4">
                                                <Link>
                                                    Click Here to watch a short how to video
                                                </Link>
                                            </Typography>
                                        </Grid> */}
                                        <Grid item xs={12} paddingTop={2}>
                                            <Typography lineHeight="2" fontWeight="normal" variant="h3">
                                                What are the requirements before scheduling an AIS consultation?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} paddingLeft={4}>
                                            <Typography lineHeight="2" fontWeight="normal" variant="h4">
                                                There are three requirements for scheduling an AIS consulation:
                                                <ol>
                                                    <li>The Patient has a completed Airway Intelligence Report. </li>

                                                    <li>The tooth charting diagram is filled out correctly in Vivos Aire.</li>

                                                    <li>The Treatment Planning section in vCloud has been completed
                                                        (this includes "Patient Complaints", "Diagnosis" "Objectives", and "Treatment Plan").
                                                    </li>

                                                </ol>
                                                {/* Options 2 and 3 can be treated as highly recommended, but are not required. However, they
                                                will highly improve the outcome of your consultation. */}
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xs={12} paddingTop={2}>
                                            <Typography lineHeight="2" fontWeight="normal" variant="h3">
                                                Are AIS consultations focused on appliance design required before submitting an RX?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} paddingLeft={4}>
                                            <Typography lineHeight="2" fontWeight="normal" variant="h4">
                                                No, they are not required to submit an RX. We recommend scheduling a consultation if you
                                                are a newer provider, have questions about a Vivos process or product, or have a complex
                                                case that requires appliance design assistance.
                                            </Typography>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {/* {currentTab === 'faqs' && (
                        <>
                            <Box p={4}>
                                <Grid container>
                                    <Grid item xs={12} padding={4} container justifyContent={'center'}>
                                        <Typography lineHeight="2" fontWeight="normal" variant="h3" textAlign='justify'>
                                            AIS FAQs Coming Soon
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )} */}

                </Grid>
            </Grid>
        </Card>
    );
}

export default SupportContent;
