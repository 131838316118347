import {
    Card,
    CardActionArea,
    CardMedia,
    alpha,
    useTheme,
    styled
} from '@mui/material';
import { Link } from 'react-router-dom';

const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  `
);

function BannerCard({ image }) {
    // function BannerCard({ image, link, target }) {
    const theme = useTheme();

    return (

        <Card
            sx={{
                height: '19vw',
                mt: 2,
                alignContent: 'center',
                textAlign: 'center',
                transition: `${theme.transitions.create([
                    'box-shadow',
                    'transform'
                ])}`,
                transform: 'translateY(0px)',

                '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                    )},
                  0 0.6rem 1.6rem ${alpha(
                        theme.colors.alpha.black[100],
                        0.2
                    )},
                  0 0.2rem 0.2rem ${alpha(
                        theme.colors.alpha.black[100],
                        0.15
                    )}`
                }
            }}
        >
            {/* <Link to={link} style={{ textDecoration: 'none' }} target={target}> */}
            <CardActionAreaWrapper>
                <CardMedia
                    component="img"
                    sx={{ objectFit: 'fit' }}
                    image={image}
                    alt="..."

                />
            </CardActionAreaWrapper>
            {/* </Link> */}
        </Card>

    );
}

export default BannerCard;
