// Vendors
import {
    Box,
    CardHeader,
    Card,
    ListItemText,
    Typography,
    Avatar,
    List,
    Divider,
    ListItem,
    Link,
    IconButton,
    alpha,
    styled,
    useTheme
} from '@mui/material';
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

// Components
import Scrollbar from '../../../components/UI/Scrollbar';
import CompletedIcon from '../../../components/UI/Icons/CompletedIcon';
import RequiredIcon from '../../../components/UI/Icons/RequiredIcon';
import { useEffect } from 'react';

const CardContentWrapper = styled(Box)(
    ({ theme }) => `
      background: ${theme.colors.alpha.white[100]};
      border-radius: ${theme.general.borderRadius};
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
    `
);

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

function RxConstructionContainer({ rxConstructionTitle, rxConstructionComponent, rxComplete }) {
    const theme = useTheme();


    return (
        <Card
            variant="outlined"
            sx={{
                background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
            }}
        >
            <CardHeader
                sx={{
                    p: 3
                }}
                disableTypography
                action={
                    rxComplete ? (
                        <CompletedIcon />
                    ) : (
                        <RequiredIcon />
                    )
                }
                title={<Typography variant="h4">{rxConstructionTitle}</Typography>}
            />
            <CardContentWrapper
                sx={{
                    mx: 3,
                    mb: 3,
                    height: 444
                }}
            >
                <Scrollbar>
                    {rxConstructionComponent}
                </Scrollbar>
            </CardContentWrapper>
        </Card>
    );
}

export default RxConstructionContainer;
