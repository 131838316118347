import { AddCircleOutline, Close } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton } from "@mui/material";
import { GridColDef, GridFooter, GridFooterContainer } from "@mui/x-data-grid-pro";
import { makeObservable, observable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Component } from "react";
import { AdminBL } from "../../../api/AdminBL";
import DictionaryList from "../../../entities/DictionaryList";
import DictionaryListItem from "../../../entities/DictionaryListItem";
import CssTextField from "../../CssTextField";
import Item from "../../Item";


interface IModalProps {
    bl: AdminBL;
    isOpen: boolean;
    title: string;
    onSave(item: DictionaryListItem): any;
    onClose: (onClose: boolean) => void;
}

const defaultListItem: any = {
    id: -1,
    name: "",
    listItemKey: "",
    value: "",
    dictionaryListId: -1,
    isActive: false
}

@observer
export class DictionaryListItemModal extends Component<IModalProps> {
    @observable item: any = defaultListItem;

    constructor(props: any) {
        super(props);
    }

    handleOnClick = () => {
        this.item = new DictionaryListItem(defaultListItem);
        this.props.onClose(false);
    };

    columns: GridColDef[] = [
        {
            field: 'name', headerName: 'Name', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (<Grid> {params.row.name}</Grid>)
            }
        },
        {
            field: 'listItemKey', headerName: 'List Item Key', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (<Grid>  {params.row.listItemKey} </Grid>)
            }
        },
        {
            field: 'value', headerName: 'Value', flex: 1, type: 'string', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (<Grid>{params.row.value}</Grid>)
            }
        },
        {
            field: 'isActive', headerName: 'Active', flex: 1, type: 'boolean', sortable: true, headerAlign: "center", headerClassName: "colHeader", renderCell: (params) => {
                return (<Grid>{params.row.isActive}</Grid>)
            }
        },
    ];

    render() {
        if (!this.props.isOpen) {
            return null;
        }

        return (
            <Dialog open={this.props.isOpen} fullWidth={true} maxWidth={"xl"} >
                <DialogTitle id='form-dialog-title' className='Title'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            sx={{ height: '30px' }}
                            flexGrow={1}
                            fontSize='24px'
                            fontWeight={900}
                            fontFamily='Proxima Nova'>
                            {this.props.title}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={this.handleOnClick}
                                sx={{
                                    fontSize: 25,
                                    backgroundColor: 'transparent',
                                    color: 'blue'
                                }}>
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <Grid xs container direction="column" className="formBasics" sx={{ margin: "25px 0px 0px 0px" }}>
                        <Grid container direction="row">
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Name</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="dictionarylistitem.txt.name"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            focused={true}
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.props.bl.currentListItem.name = event.target.value;
                                                this.props.bl.currentListItem.listItemKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                            }}
                                            value={this.props.bl.currentListItem.name}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Dictionary List Key</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="dictionarylistitem.txt.listkey"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                                this.props.bl.currentListItem.listItemKey = event.target.value.toUpperCase().replaceAll(" ", "");
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.props.bl.currentListItem.listItemKey = event.target.value;
                                            }}
                                            value={this.props.bl.currentListItem.listItemKey}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid xs container>
                                <FormControl>
                                    <Item>
                                        <Grid container direction={"row"} className="formLabel" ><Grid>Value</Grid></Grid>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <CssTextField id="dictionarylistitem.txt.listitemkey"
                                            variant="outlined"
                                            className="TextBox"
                                            size="small"
                                            fullWidth
                                            type={"text"}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                this.props.bl.currentListItem.value = event.target.value;
                                            }}
                                            value={this.props.bl.currentListItem.value}
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                            <Grid xs container alignContent={"center"}>
                                <FormControl>
                                    <Item>
                                        <Grid className="formLabel" id="hb">{""}</Grid>
                                        <FormControlLabel
                                            onChange={(event: any) => {
                                                this.props.bl.currentListItem.isActive = event.target.checked;
                                            }}
                                            control={<Checkbox id="dictionarylistitem.cbx.isactive" checked={this.props.bl.currentListItem.isActive} />}
                                            label="Is Active"
                                        />
                                    </Item>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" xs justifyContent={"flex-end"} >
                            <Grid><Button className="vivButton large invert" onClick={() => { this.handleOnClick() }}>Cancel</Button></Grid>
                            <Grid><Button className="vivButton large invert" onClick={() => { this.props.onSave(this.props.bl.currentListItem); this.handleOnClick(); }}>Save</Button></Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog >
        )
    }
}