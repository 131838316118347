import jwt_decode from 'jwt-decode';
import { makeAutoObservable } from 'mobx';
import { Gateway } from '../../api/Gateway';
import { MainStore } from './MainStore';
import { v4 as uuidv4 } from 'uuid';
import buildQuery from 'odata-query';
import Consultation from '../../entities/Consultations';

export default class AIReportDataLogMeasurementsStore {
    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    mainStore: MainStore;
    roles: any[] = [];
    userId = 0;
    consultationData: any = [];
    isLeftNavExpanded: boolean = false;
    toggleShowPatientEdit: boolean = false;
    patientConsultation: any = {};
    patientId = 0;

    setPatientId = (newValue: number) => {
        this.patientId = newValue;
    };

    setToggleShowPatientEdit = (newValue: boolean) => {
        this.toggleShowPatientEdit = newValue;
    };

    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    setConsultationData = (value: any) => {
        this.consultationData = value.map((item: any) => {
            return {
                ...item,
                networkId: uuidv4(),
            };
        });
    };

    resetConsultationData = () => {
        this.patientConsultation = {};
    };

    loadPatient = async () => {
        await Gateway.get('/patient/' + this.patientId).then(data => {
            this.patientConsultation = data;
        });
    };

    loadDataValues = async () => {
        
        let filter ={ or: [{ consultantId: { eq: { type: 'raw', value: this.userId } } }, { advocateId: { eq: { type: 'raw', value: this.userId } } }] };
        const query = buildQuery({filter: filter});
        const url = `/consultations/MyNetwork`+ query;
        await Gateway.get(url).then(resp => {
            this.setConsultationData(resp);
        });
    };

    handleConsultationStatusChange = (changedConsultation: Consultation, event) => {
        var url = `/P2PConsultations/${changedConsultation.id}/setstatus/${event.target.value}`;
        Gateway.post(url, undefined).then(respData => {
            if (respData) {
                this.loadDataValues();
            }
        });
    };
}
