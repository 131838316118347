import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Gateway } from '../../api/Gateway';

import jwt_decode from 'jwt-decode';

import { MainStore } from './MainStore';

export class CustomFormBuilderStore {
    mainStore: MainStore;

    @observable _sectionsData: any = [];
    @observable _isLeftNavExpanded: boolean = false;
    @observable _questionId: number = 0;
    @observable loadSectionsData = 0;

    roles: any = [];
    userID: number = 0;
    pageSize: number = 200;

    constructor(mainStore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;

        const token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    @computed
    get isLeftNavExpanded() {
        return this._isLeftNavExpanded;
    }

    set isLeftNavExpanded(value: any) {
        this._isLeftNavExpanded = value;
    }

    @action
    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this._isLeftNavExpanded = isLeftNavExpanded;
    };

    @computed
    get sectionsData(): any[] {
        return this._sectionsData;
    }

    set sectionsData(value: any[]) {
        this._sectionsData = value;
    }

    @action
    loadQuestionnaire = async () => {
        const newUrl = 'questionnaire/sections/json';

        // const url = `questionaire/QuestionsBySection/${this.userID}/0/${this.pageSize}`;
        const sections: any[] = [];
        // const resp = await Gateway.get(url);
        // sections.push(resp);

        await Gateway.get(newUrl).then(resp => {
            const totalSections = resp ? resp.length : 0;
            sections.push(resp);
            if (totalSections !== 0) {
                runInAction(() => {
                    this.loadSectionsData = (sections.length / totalSections) * 100;
                });
            }
        });

        runInAction(() => {
            this.sectionsData = sections;
        });

        return this.sectionsData;
    };
}
