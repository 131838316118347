import { observable } from 'mobx';
import { DictionaryListItem } from './dictionarylistitem';
import { DentalPiece } from './dentalPiece';
import { PartDentalPiece } from './partDentalPiece';
import { ReworkClaim } from './reworkClaim';
import { WarrantyClaim } from './warrantyClaim';

export class Appliance {
    @observable id: number = 0;
    @observable patientId: number = 0;
    @observable name: string = '';
    @observable applianceType: number = 0;
    @observable colorId: number | null = null;
    @observable sizeId: number | null = null;
    @observable verticalThicknessId: number | null = null;
    @observable customThickness: number | null = null;
    @observable unilateralBiteBlockId: number | null = null;
    @observable migrationId: number | null = null;
    @observable createdOn: Date = new Date();
    @observable createdBy: number = 0;
    @observable modifiedOn: Date | null = null;
    @observable modifiedBy: number | null = null;
    @observable appliancesystemUppersystemRetentionoptionId: number | null = null;
    @observable appliancesystemLowersystemWilliansStyleId: number | null = null;
    @observable appliancesystemLowersystemOsaextensionId: number | null = null;
    @observable appliancesystemLowersystemHeadgearId: number | null = null;
    @observable appliancesystemUppersystemMandibularadvancementId: number | null = null;
    @observable appliancesystemVivosguideforuppersystemGuideidId: number | null = null;
    @observable appliancesystemUppersystemBaseId: number | null = null;
    @observable appliancesystemLowersystemBaseId: number | null = null;
    @observable appliancesystemUppersystemMandibularadvancementoptionId: number | null = null;
    @observable appliancesystemLowersystemBiteblockverticalthicknessoptionId: number | null = null;
    @observable appliancesystemLowersystemUnilateralbiteblocklocationoptionId: number | null = null;
    @observable appliancesystemUppersystemBowId: number | null = null;
    @observable appliancesystemLowersystemBowId: number | null = null;
    @observable labsShippingmethodidId: number | null = null;
    @observable appliancesystemLowersystemBiteblockverticalthicknesscustomId: number | null = null;
    @observable appliancesystemLowersystemRetentionoptionId: number | null = null;
    @observable appliancesystemLowersystemVerticalhooksoptionBool: boolean | null = null;
    @observable appliancesystemUppersystemVerticalhooksoptionBool: boolean | null = null;
    @observable appliancesystemUppersystemUpperAnteriorWhipLapBool: boolean | null = null;
    @observable appliancesystemLowersystemLowerAnteriorWhipLapBool: boolean | null = null;
    @observable applianceSystemUpperSystemScrewId: number | null = null;
    @observable applianceSystemLowerSystemScrewId: number | null = null;
    @observable applianceSystemUpperSystemElasticHooksClassIIBool: boolean | null = null;
    @observable applianceSystemLowerSystemElasticHooksClassIIBool: boolean | null = null;
    @observable applianceSystemUpperSystemElasticHooksVerticalBool: boolean | null = null;
    @observable applianceSystemLowerSystemElasticHooksVerticalBool: boolean | null = null;
    @observable applianceSystemUpperSystemOsaExtensionBool: boolean | null = null;
    @observable applianceSystemUpperSystemOsaExtensionLengthInt: number | null = null;
    @observable applianceSystemMaterialBaseId: number | null = null;
    @observable applianceSystemUpperSystemReversePullFacemaskAttachmentsBool: boolean | null = null;
    @observable applianceSystemUpperSystemAnteriorIndexRampBool: boolean | null = null;
    @observable applianceSystemUpperSystemAnteriorBitePlaneBool: boolean | null = null;

    @observable applianceTypeNavigation: DictionaryListItem | null = null;
    @observable color: DictionaryListItem | null = null;
    @observable dentalPieces: DentalPiece[] = [];
    @observable partDentalPieces: PartDentalPiece[] = [];
    /* @observable prescriptions: Prescription[] = []; */
    @observable reworkClaims: ReworkClaim[] = [];
    @observable size: DictionaryListItem | null = null;
    @observable unilateralBiteBlock: DictionaryListItem | null = null;
    @observable verticalThickness: DictionaryListItem | null = null;
    @observable warrantyClaims: WarrantyClaim[] = [];

    // Local ids to handle Construction step
    @observable applianceSystemUpperSystemClaspsId: string | null = null;
    @observable applianceSystemLowerSystemClaspsId: string | null = null;
    @observable applianceSystemUpperSystemAxialSpringsId: string | null = null;
    @observable applianceSystemLowerSystemAxialSpringsId: string | null = null;

    // Comments
    @observable applianceSystemUpperSystemComments: string | null = null;
    @observable applianceSystemLowerSystemComments: string | null = null;
}
