import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Grid, IconButton } from '@mui/material';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { observer } from 'mobx-react';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridFilterModel, GridRowId, GridRowParams, GridSortModel, gridDetailPanelExpandedRowIdsSelector, gridDetailPanelExpandedRowsContentCacheSelector, gridRowsLookupSelector, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-pro';
import '../../scss/_DataGrid.scss';
import { DataGridBL } from './DataGridBL';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { toJS } from 'mobx';

window.moment = moment;
/* function CustomDetailPanelHeader() {
    const apiRef = useGridApiContext();
  
    const expandedRowIds = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowIdsSelector,
    );
    const rowsWithDetailPanels = useGridSelector(
      apiRef,
      gridDetailPanelExpandedRowsContentCacheSelector,
    );
  
    const noDetailPanelsOpen = expandedRowIds.length === 0;
  
    const expandOrCollapseAll = () => {
      const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
      const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(
        rowsWithDetailPanels,
      ).map((key) => dataRowIdToModelLookup[key]);//.getRowId(dataRowIdToModelLookup[key]));
  
      apiRef.current.setExpandedDetailPanels(
        noDetailPanelsOpen ? allRowIdsWithDetailPanels : [],
      );
    };
  
    const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;
  
    return (
      <IconButton
        size="small"
        tabIndex={-1}
        onClick={expandOrCollapseAll}
        aria-label={noDetailPanelsOpen ? 'Expand All' : 'Collapse All'}
      >
        <Icon fontSize="inherit" />
      </IconButton>
    );
  } */

const gridStyle = { height: '100%', marginBottom: 10, marginLeft: 5, marginRight: 5, textAlign: 'center' };
interface IProps {
    data: any;
    size?: "small" | "large",
    pageSize?: number;
    columns: any;
    hideFooter?: boolean;
    customFooter?: any;
    customSorting?: any;
    getRowId?: any;
    loading?: boolean;
    pagination?: boolean;
    rowCount?: number;
    serverSidePagination?: boolean;
    onPageChange?: (newPage) => void;
    onFilterChange?: (filterModel: GridFilterModel) => void;
    onSortModelChange?: (sortModel: GridSortModel) => void;
    rowEditCommit?: (id, key, value) => void;
    getDetailPanelContent?: (row:GridRowParams) => any;
    panelDetailHeigh?: number;
    columnExpandedHeader?:any;// {headerName, headerAlign, headerClassName};

}
const DataGrid: React.FC<IProps> = (props: IProps) => {
    let gridBL: DataGridBL = new DataGridBL();
    const apiRef = useGridApiRef();

    const [rows, setRows] = useState<any[]>([]);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(5);

    useEffect(() => {
        gridBL.apiRef = apiRef;
    }, []);

    useEffect(() => {
        if (props.data) {
            setRows(toJS(props.data));
        }
    }, [props.data]);

    const handleRowEditCommit = (params) => {
        if (props.rowEditCommit)
            props.rowEditCommit(params.id, params.field, params.value);
    };

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        if (props.onFilterChange) {
            props.onFilterChange(filterModel);
        }
    }, []);

    const handleSortChange = React.useCallback((sortModel: GridSortModel) => {
        if (props.onSortModelChange) {
            props.onSortModelChange(sortModel);
        }
    }, []);
    
    const CustomColumnMenuIcon = () => (
        <IconButton
            sx={{
                color: 'white',
            }}
        >
            <MoreVertTwoToneIcon />
        </IconButton>
    );

    const detailPanelToggleColumn = {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        ...props.columnExpandedHeader
    };

    let columns = props.getDetailPanelContent ?  [detailPanelToggleColumn, ...props.columns] : props.columns;

    return (
        <Grid id="datagrid_grid" className={"dataGrid" + (props.size == "small" ? "" : " standard")} sx={{ gridStyle }}>
            <DataGridPro
                experimentalFeatures={{ newEditingApi: true }}
                rowCount={props.rowCount ? props.rowCount : undefined}
                paginationMode={props.serverSidePagination ? (props.serverSidePagination ? "server" : "client") : "client"}
                filterMode={props.serverSidePagination ? (props.serverSidePagination ? "server" : "client") : "client"}
                page={page}
                apiRef={apiRef}
                className="dataGrid"
                pagination={props.pagination ? props.pagination : undefined}
                onFilterModelChange={onFilterChange}
                onCellFocusOut={handleRowEditCommit}
                onSortModelChange={handleSortChange}
                columns={columns ? columns : []}
                components={{ Footer: props.customFooter ? props.customFooter : undefined, ColumnMenuIcon: CustomColumnMenuIcon }}
                //components={props.customFooter ? { Footer: props.customFooter } : undefined}
                hideFooter={props.hideFooter}
                initialState={{
                    sorting: props.customSorting ? props.customSorting : undefined,
                }}
                getDetailPanelContent={props.getDetailPanelContent}
                getDetailPanelHeight={()=> {return props.panelDetailHeigh ? props.panelDetailHeigh : 0}}
                getRowId={props.getRowId}
                loading={props.loading}
                rows={rows ? rows : []}
                pageSize={props.pageSize ? props.pageSize : 15}
                onPageChange={(newPage) => {
                    setPage(newPage);
                    if (props.onPageChange) {
                        props.onPageChange(newPage);
                    }
                }}
                onCellKeyDown={(_cell, event) => {
                    if (event.key === ' ') {
                        event.stopPropagation()
                    }
                }
                }
                
            />
        </Grid>
    );
};

export default observer(DataGrid);
