import { AIReportType } from "./Enums";

export const AIReporteCommTypes = [
    {
        type: AIReportType.Standard,
        eCommerceItemId: 9542552158492
    },
    {
        type: AIReportType.Comparison,
        eCommerceItemId: 9542552289564
    },
    {
        type: AIReportType["Free Standard"],
        //eCommerceItemId: 0
        eCommerceItemId: 9542552158492
    }
]

export const shopifyItems = [
    { name: 'Radiology Report', eCommerceItemId: 9542552420636, dictionaryListId: 105 },
    // { name: 'Sassouni', eCommerceItemId: 9542550913308, dictionaryListId: 106 },
    // { name: 'Frontal Analysis', eCommerceItemId: 9542552944924, dictionaryListId: 107 },
    { name: '3D Printed Airway', eCommerceItemId: 9542551470364, dictionaryListId: 108 },
    { name: 'Sassouni + Analysis', eCommerceItemId: 9542550749468, dictionaryListId: 1308 },
    { name: 'Faciometric Findings', eCommerceItemId: 9542552813852, dictionaryListId: 1309 },
    { name: 'Cranial Base Measurements', eCommerceItemId: 9542552650012, dictionaryListId: 1310 },
    { name: 'Cervical Measurements', eCommerceItemId: 9542552518940, dictionaryListId: 1311 },
    { name: 'RG and RP Anterior to Posterior Distance', eCommerceItemId: 9542550421788, dictionaryListId: 1312 },
    { name: 'RG and RP Area Measurements', eCommerceItemId: 9542550520092, dictionaryListId: 1313 },
    { name: 'Posterior Nasal Aperture Measurements', eCommerceItemId: 9542550225180, dictionaryListId: 1314 },
    { name: 'Inferior Nasal Concha Space Measurements', eCommerceItemId: 9542553075996, dictionaryListId: 1315 },
    { name: 'Treatment Plan Sequencing', eCommerceItemId: 9611148263708, dictionaryListId: 1574 }
];

export const MyoCorrectTherapyPlans = [
    { name: 'MyoCorrect Therapy Plan', eCommerceItemId: 9542552256796, dictionaryListId: 1316, index: 1 },
    { name: 'MyoCorrect+ Therapy Plan', eCommerceItemId: 9542552584476, dictionaryListId: 1317, index: 2 },
    { name: 'Myofunctional Therapy Additional Session', eCommerceItemId: 9542552781084, dictionaryListId: 1318, index: 3 }
]

export enum MyoCorrectTherapyPlanType {
    'MyoCorrect Therapy Plan' = 2,
    'MyoCorrect+ Therapy Plan' = 3,
    'Myofunctional Therapy Additional Session' = 4
}

export const AvialableLabsForFamily = [
    // Guide
    { applaianceFamilyId: 1, LabId: 1 },   // Guide mapped to Five Star Orthodontics
    { applaianceFamilyId: 1, LabId: 2 },   // Guide mapped to John's Dental Lab
    { applaianceFamilyId: 1, LabId: 3 },   // Guide mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 1, LabId: 4 },   // Guide mapped to Space Maintainers Lab
    { applaianceFamilyId: 1, LabId: 5 },   // Guide mapped to STAA Lab
    { applaianceFamilyId: 1, LabId: 6 },   // Guide mapped to Vivos
    { applaianceFamilyId: 1, LabId: 7 },   // Guide mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 1, LabId: 8 },   // Guide mapped to Apex Dental Sleep Lab
    { applaianceFamilyId: 1, LabId: 9 },   // Guide mapped to Test Lab Non-Guides
    { applaianceFamilyId: 1, LabId: 10 },  // Guide mapped to Versa Lab
    { applaianceFamilyId: 1, LabId: 11 },  // Guide mapped to PEx Lab
    { applaianceFamilyId: 1, LabId: 12 },  // Guide mapped to Airway Intelligence Center
    { applaianceFamilyId: 1, LabId: 13 },  // Guide mapped to Airway Intelligence CARE

    // Versa
    { applaianceFamilyId: 2, LabId: 1 },   // Versa mapped to Five Star Orthodontics
    { applaianceFamilyId: 2, LabId: 2 },   // Versa mapped to John's Dental Lab
    { applaianceFamilyId: 2, LabId: 3 },   // Versa mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 2, LabId: 4 },   // Versa mapped to Space Maintainers Lab
    { applaianceFamilyId: 2, LabId: 5 },   // Versa mapped to STAA Lab
    { applaianceFamilyId: 2, LabId: 6 },   // Versa mapped to Vivos
    { applaianceFamilyId: 2, LabId: 7 },   // Versa mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 2, LabId: 8 },   // Versa mapped to Apex Dental Sleep Lab
    { applaianceFamilyId: 2, LabId: 9 },   // Versa mapped to Test Lab Non-Guides
    { applaianceFamilyId: 2, LabId: 10 },  // Versa mapped to Versa Lab
    { applaianceFamilyId: 2, LabId: 11 },  // Versa mapped to PEx Lab
    { applaianceFamilyId: 2, LabId: 12 },  // Versa mapped to Airway Intelligence Center
    { applaianceFamilyId: 2, LabId: 13 },  // Versa mapped to Airway Intelligence CARE

    // Vida
    { applaianceFamilyId: 3, LabId: 1 },   // Vida mapped to Five Star Orthodontics
    { applaianceFamilyId: 3, LabId: 2 },   // Vida mapped to John's Dental Lab
    { applaianceFamilyId: 3, LabId: 3 },   // Vida mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 3, LabId: 4 },   // Vida mapped to Space Maintainers Lab
    { applaianceFamilyId: 3, LabId: 5 },   // Vida mapped to STAA Lab
    { applaianceFamilyId: 3, LabId: 6 },   // Vida mapped to Vivos
    { applaianceFamilyId: 3, LabId: 7 },   // Vida mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 3, LabId: 8 },   // Vida mapped to Apex Dental Sleep Lab
    { applaianceFamilyId: 3, LabId: 9 },   // Vida mapped to Test Lab Non-Guides
    { applaianceFamilyId: 3, LabId: 10 },  // Vida mapped to Versa Lab
    { applaianceFamilyId: 3, LabId: 11 },  // Vida mapped to PEx Lab
    { applaianceFamilyId: 3, LabId: 12 },  // Vida mapped to Airway Intelligence Center
    { applaianceFamilyId: 3, LabId: 13 },  // Vida mapped to Airway Intelligence CARE

    // VidaSleep
    { applaianceFamilyId: 4, LabId: 1 },   // VidaSleep mapped to Five Star Orthodontics
    { applaianceFamilyId: 4, LabId: 2 },   // VidaSleep mapped to John's Dental Lab
    { applaianceFamilyId: 4, LabId: 3 },   // VidaSleep mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 4, LabId: 4 },   // VidaSleep mapped to Space Maintainers Lab
    { applaianceFamilyId: 4, LabId: 5 },   // VidaSleep mapped to STAA Lab
    { applaianceFamilyId: 4, LabId: 6 },   // VidaSleep mapped to Vivos
    { applaianceFamilyId: 4, LabId: 7 },   // VidaSleep mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 4, LabId: 8 },   // VidaSleep mapped to Apex Dental Sleep Lab
    { applaianceFamilyId: 4, LabId: 9 },   // VidaSleep mapped to Test Lab Non-Guides
    { applaianceFamilyId: 4, LabId: 10 },  // VidaSleep mapped to Versa Lab
    { applaianceFamilyId: 4, LabId: 11 },  // VidaSleep mapped to PEx Lab
    { applaianceFamilyId: 4, LabId: 12 },  // VidaSleep mapped to Airway Intelligence Center
    { applaianceFamilyId: 4, LabId: 13 },  // VidaSleep mapped to Airway Intelligence CARE

    // DNA
    { applaianceFamilyId: 5, LabId: 1 },   // DNA mapped to Five Star Orthodontics
    { applaianceFamilyId: 5, LabId: 2 },   // DNA mapped to John's Dental Lab
    { applaianceFamilyId: 5, LabId: 3 },   // DNA mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 5, LabId: 4 },   // DNA mapped to Space Maintainers Lab
    { applaianceFamilyId: 5, LabId: 5 },   // DNA mapped to STAA Lab
    //{ applaianceFamilyId: 5, LabId: 6 },   // DNA mapped to Vivos
    { applaianceFamilyId: 5, LabId: 7 },   // DNA mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 5, LabId: 8 },   // DNA mapped to Apex Dental Sleep Lab
    //{ applaianceFamilyId: 5, LabId: 9 },   // DNA mapped to Test Lab Non-Guides
    //{ applaianceFamilyId: 5, LabId: 10 },  // DNA mapped to Versa Lab
    //{ applaianceFamilyId: 5, LabId: 11 },  // DNA mapped to PEx Lab
    //{ applaianceFamilyId: 5, LabId: 12 },  // DNA mapped to Airway Intelligence Center
    { applaianceFamilyId: 5, LabId: 13 },  // DNA mapped to Airway Intelligence CARE

    // mRNA
    { applaianceFamilyId: 6, LabId: 1 },   // mRNA mapped to Five Star Orthodontics
    { applaianceFamilyId: 6, LabId: 2 },   // mRNA mapped to John's Dental Lab
    { applaianceFamilyId: 6, LabId: 3 },   // mRNA mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 6, LabId: 4 },   // mRNA mapped to Space Maintainers Lab
    { applaianceFamilyId: 6, LabId: 5 },   // mRNA mapped to STAA Lab
    //{ applaianceFamilyId: 6, LabId: 6 },   // mRNA mapped to Vivos
    { applaianceFamilyId: 6, LabId: 7 },   // mRNA mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 6, LabId: 8 },   // mRNA mapped to Apex Dental Sleep Lab
    //{ applaianceFamilyId: 6, LabId: 9 },   // mRNA mapped to Test Lab Non-Guides
    //{ applaianceFamilyId: 6, LabId: 10 },  // mRNA mapped to Versa Lab
    //{ applaianceFamilyId: 6, LabId: 11 },  // mRNA mapped to PEx Lab
    //{ applaianceFamilyId: 6, LabId: 12 },  // mRNA mapped to Airway Intelligence Center
    { applaianceFamilyId: 6, LabId: 13 },  // mRNA mapped to Airway Intelligence CARE

    // mmRNA
    { applaianceFamilyId: 7, LabId: 1 },   // mmRNA mapped to Five Star Orthodontics
    { applaianceFamilyId: 7, LabId: 2 },   // mmRNA mapped to John's Dental Lab
    { applaianceFamilyId: 7, LabId: 3 },   // mmRNA mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 7, LabId: 4 },   // mmRNA mapped to Space Maintainers Lab
    { applaianceFamilyId: 7, LabId: 5 },   // mmRNA mapped to STAA Lab
    //{ applaianceFamilyId: 7, LabId: 6 },   // mmRNA mapped to Vivos
    { applaianceFamilyId: 7, LabId: 7 },   // mmRNA mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 7, LabId: 8 },   // mmRNA mapped to Apex Dental Sleep Lab
    //{ applaianceFamilyId: 7, LabId: 9 },   // mmRNA mapped to Test Lab Non-Guides
    //{ applaianceFamilyId: 7, LabId: 10 },  // mmRNA mapped to Versa Lab
    //{ applaianceFamilyId: 7, LabId: 11 },  // mmRNA mapped to PEx Lab
    //{ applaianceFamilyId: 7, LabId: 12 },  // mmRNA mapped to Airway Intelligence Center
    { applaianceFamilyId: 7, LabId: 13 },  // mmRNA mapped to Airway Intelligence CARE

    // PEx
    { applaianceFamilyId: 8, LabId: 1 },   // PEx mapped to Five Star Orthodontics
    { applaianceFamilyId: 8, LabId: 2 },   // PEx mapped to John's Dental Lab
    { applaianceFamilyId: 8, LabId: 3 },   // PEx mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 8, LabId: 4 },   // PEx mapped to Space Maintainers Lab
    { applaianceFamilyId: 8, LabId: 5 },   // PEx mapped to STAA Lab
    { applaianceFamilyId: 8, LabId: 6 },   // PEx mapped to Vivos
    { applaianceFamilyId: 8, LabId: 7 },   // PEx mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 8, LabId: 8 },   // PEx mapped to Apex Dental Sleep Lab
    { applaianceFamilyId: 8, LabId: 9 },   // PEx mapped to Test Lab Non-Guides
    { applaianceFamilyId: 8, LabId: 10 },  // PEx mapped to Versa Lab
    { applaianceFamilyId: 8, LabId: 11 },  // PEx mapped to PEx Lab
    { applaianceFamilyId: 8, LabId: 12 },  // PEx mapped to Airway Intelligence Center
    { applaianceFamilyId: 8, LabId: 13 },  // PEx mapped to Airway Intelligence CARE

    // emaNow
    { applaianceFamilyId: 9, LabId: 1 },   // emaNow mapped to Five Star Orthodontics
    { applaianceFamilyId: 9, LabId: 2 },   // emaNow mapped to John's Dental Lab
    { applaianceFamilyId: 9, LabId: 3 },   // emaNow mapped to Gergen's Orthodontic Lab
    { applaianceFamilyId: 9, LabId: 4 },   // emaNow mapped to Space Maintainers Lab
    { applaianceFamilyId: 9, LabId: 5 },   // emaNow mapped to STAA Lab
    { applaianceFamilyId: 9, LabId: 6 },   // emaNow mapped to Vivos
    { applaianceFamilyId: 9, LabId: 7 },   // emaNow mapped to Aurum Dental Laboratories
    { applaianceFamilyId: 9, LabId: 8 },   // emaNow mapped to Apex Dental Sleep Lab
    { applaianceFamilyId: 9, LabId: 9 },   // emaNow mapped to Test Lab Non-Guides
    { applaianceFamilyId: 9, LabId: 10 },  // emaNow mapped to Versa Lab
    { applaianceFamilyId: 9, LabId: 11 },  // emaNow mapped to PEx Lab
    { applaianceFamilyId: 9, LabId: 12 },  // emaNow mapped to Airway Intelligence Center
    { applaianceFamilyId: 9, LabId: 13 }   // emaNow mapped to Airway Intelligence CARE
];


