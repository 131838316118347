import { MainStore } from './MainStore';
import { action, makeAutoObservable } from 'mobx';
import { Gateway } from '../../api/Gateway';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { GlobalUtils } from '../../api/GlobalUtils';

export interface IConsultationNotesById {
    scheduledDateTime: Date;
    consultationId: number;
    consultationStatus: string;
    consultationType: null;
    consultantId: number;
    additionalNotes: string;
    calendlyUri: string;
    calendlyRescheduleUri: string;
    providerId: number;
    topics: number;
    questions: string[];
}

export default class PatientConsultationStore {
    mainStore: MainStore;
    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    consultationNotes: any[] = [];
    consultationNotesById: IConsultationNotesById = {
        scheduledDateTime: new Date(),
        consultationId: 0,
        consultationStatus: '',
        consultationType: null,
        consultantId: 0,
        additionalNotes: '',
        calendlyUri: '',
        calendlyRescheduleUri: '',
        providerId: 0,
        topics: 0,
        questions: [],
    };
    consultationNotesId: number = 0;
    patientId = 0;
    userId = 0;
    roles: any[] = [];
    questions: string = '';
    scheduledDateTime: Date = new Date();
    status: number = 582;
    note: string = '';
    date: Date = new Date();
    time: Date = new Date();
    consultationId: number = 0;
    notes = '';

    currentConsultation = {};

    setNotes = (newValue: string) => {
        this.notes = newValue;
    };

    setConsultationId = (newValue: number) => {
        this.consultationId = newValue;
    };

    setCurrentConsultation = (consultation: any) => {
        this.currentConsultation = consultation;
        this.status = _.find(GlobalUtils.getConsultationStatus(), (s) => {return s.name == consultation.consultationStatus}).id;
        this.notes = consultation.additionalNotes;
        this.questions = consultation.questions;
    };

    setConsultationNotesId = (newValue: number) => {
        this.consultationNotesId = newValue;
    };

    @action setConsultationNotesById = newValue => {
        this.consultationNotesById = newValue;
    };

    @action setConsultationNotes = (newValue: any[]) => {
        this.consultationNotes = newValue;
    };

    @action setPatientId = (newValue: number) => {
        this.patientId = newValue;
    };
    @action setQuestions = (newValue: string) => {
        this.questions = newValue;
    };

    @action setStatus = (newValue: number) => {
        this.status = newValue;
    };

    isClinicalAdvocate = () => {
        return _.find(this.roles, (r) => {
            return (r.Name == process.env.REACT_APP_VIVOS_CLINICAL_ADVOCATE || r.Name == process.env.REACT_APP_VIVOS_CLINICAL_ADVISOR)
        })
    }

    @action
    loadData = async () => {
        await Gateway.get(`provider/${this.patientId}/p2pconsultations`).then(resp => {
            this.setConsultationNotes(resp);
        });
    };

    loadConsultationById = async () => {
        await Gateway.get(`provider/p2pconsultations/${this.consultationNotesId}`).then(resp => {
            this.setConsultationNotesById(resp);
        });
    };

    resetConsultationNotes = () => {
        this.consultationNotesId = 0;
        this.notes = '';
        this.status = 582;
    };

    saveConsultationNotes = async () => {
        let consultationNotes = {
            consultationId: this.consultationNotesId,
            consultationNotes: this.notes,
            consultationStatus: this.status,
        };

        await Gateway.post(`P2PConsultations/consultations/notes/update`, consultationNotes).then(resp => {
            this.loadData();
        });
    };
}
