// Vendors
import React from 'react';
import { Grid, Typography } from "@mui/material";

const VersaApplianceInstructions: React.FC = () => {
    return (
        <Grid className="box-wizard-container">
            <Grid container>
                <Grid item xs={12} className="title-wizard-container">
                    <Typography variant='h4'>Versa Appliance Rework Instructions</Typography>
                </Grid>
                <Grid item xs={12} padding={2}>
                    <Grid container>
                        <Grid item xs={12} paddingBottom={1}>
                            <Typography variant='body1'>
                                Upon submission of the rework, you will be provided with a shipping
                                label to send all case materials back to the lab.
                                {/* You will be able to
                                download it on the rework claims dashboard after the claim creation. */}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>
                                Your claim will be submitted to the Vivos RX team for an initial
                                impression and then submitted to the lab for investigation. Between
                                both the Vivos RX team and the Lab, it will be decided if this is
                                covered under warranty, which will then be initiated into the rework
                                process at no cost to you. If this claim is a repair, then an estimated
                                repair fee will be sent to you for approval.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default VersaApplianceInstructions;
