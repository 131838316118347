//Components
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { FC } from 'react';
import {
  Fab,
  Box,
  Drawer,
  Typography,
  styled,
  List,
  ListItem,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  Tooltip,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import jwt_decode from 'jwt-decode';
import { observer } from 'mobx-react';

//Icons
import HideIcon from '@mui/icons-material/Close';
import NewIcon from '@mui/icons-material/NewReleases';
import SearchIcon from '@mui/icons-material/Search';
import ShowIcon from '@mui/icons-material/Chat';
import CommentIcon from '@mui/icons-material/RateReview';
//Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { usePrescriptionStore } from '../../stores/Prescription/CreateEdit/MainStoreContext';
import _ from 'lodash';
import { Roles } from '../../entities/Enums';

//API
import DOMpurify from 'dompurify';

interface ICommentsProps {
  commentType: number;
  significantId: number;
  isOpen: boolean;
  onClose: () => void;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.grey[100],
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const useStyles = makeStyles({
  drawer: {
    position: 'absolute',
    float: 'right',
    marginLeft: 'initial',
    top: 72,
    right: 25,
    height: 650,
    width: (props: { width: number }) => props.width,
    '& .MuiBackdrop-root': {
      display: 'none',
    },
    '& .MuiDrawer-paper': {
      border: '3px solid #1B4070',
      borderRadius: 5,
      // padding: 12,
      width: (props: { width: number }) => props.width,
      position: 'absolute',
    },
  },
  input: {
    fontSize: 16,
  },
});

export const CommentsPanel: React.FC<ICommentsProps> = observer(
  ({ commentType, significantId, isOpen, onClose }) => {
    const [isAdding, setIsAdding] = React.useState(false);
    const [isSearch, setIsSearch] = React.useState(false);
    const [panelWidth, setPanelWidth] = React.useState(0);
    const [showTypes, setShowTypes] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [filterNew, setFilterNew] = React.useState(false);
    const [selectedType, setSelectedType] = React.useState('COMMENTSGENERALVISIBILITY');
    const [newComment, setNewComment] = React.useState('');
    const [searchedComment, setSearchedComment] = React.useState('');
    const [commentList, setCommentList] = React.useState<any[]>([]);
    const [primeCommentList, setPrimeCommentList] = React.useState<any[]>([]);
    const classes = useStyles({ width: panelWidth });
    const [userRoles, setUserRoles] = React.useState([]);
    const [userId, setUserId] = React.useState(0);
    const [userFullName, setUserFullName] = React.useState('');

    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const aiReportStore = useMainStoreContext().aiReportEntryStore;
    const reworkStore = useMainStoreContext().reworkClaimStore;
    const { setCommentData, saveCommentData, getCommentsBySignificant, deleteComment } = useMainStoreContext().commentStore;

    const buttonStyle = {
      sx: { position: 'absolute', top: 70, right: 22, backgroundColor: '#1B4070' },
      sm: { position: 'absolute', top: 70, right: 350, backgroundColor: '#1B4070' },
      sb: { position: 'absolute', top: 132, right: 350, backgroundColor: '#1B4070' },
      iconShow: <ShowIcon />,
      iconHide: <HideIcon />,
      iconAdd: <CommentIcon />,
      label: 'Show Comments',
    };

    React.useEffect(() => {
      setPanelWidth(isOpen ? 640 : 0);
      getCommentsBySignificant(commentType, significantId, userId).then(res => {
        setCommentList(res);
      });

      userRoles.forEach((role: any) => {
        if (
          role.Name === Roles.AIREPORTADMIN ||
          role.Name === Roles.AIREPORTTECH ||
          role.Name === Roles.VIVOSADMIN ||
          role.Name === Roles.AISADMINMANAGER ||
          role.Name === Roles.AISSUPERVISOR ||
          role.name === Roles.AISADMIN ||
          role.Name === Roles.AISSPECIALIST
        ) {
          setShowTypes(true);
        }
      });
    }, [isOpen === true]);

    React.useEffect(() => {
      var token = sessionStorage.getItem('token');
      if (token) {
        var tokenData: { roles; id; userFullName } = jwt_decode(token);
        setUserRoles(JSON.parse(tokenData.roles));
        setUserId(Number(tokenData.id));
        setUserFullName(tokenData.userFullName);
      }
    }, []);

    const saveComment = async () => {
      if (!significantId) {
        setShowModal(true);
      } else {
        if (newComment.length > 0) {
          var commentData = _.isEmpty(reworkStore?.claim?.rxId) ? _.isUndefined(aiReportStore.submissionId) || aiReportStore?.submissionId === 0 ? _.isEmpty(prescriptionStore.prescription.rxId) ? newComment : prescriptionStore.prescription.rxId + ' - ' + newComment : aiReportStore.submissionId + ' - ' + newComment : reworkStore.claim.rxId + ' - ' + newComment;
          commentData = userFullName + '- ' + commentData;
          setCommentData(commentType, significantId, selectedType, commentData);
          await saveCommentData();

          getCommentsBySignificant(commentType, significantId, 0).then(res => {
            if (res != null && res[0] != null) {
              res[0].isNew = true;
            }
            setCommentList(res);
            setIsAdding(false);
          });
        }
      }
    };

    const filterNewComments = () => {
      if (!filterNew) {
        setPrimeCommentList(commentList);
        let filterComments = commentList.map((comment: any, index: number) => comment);
        setCommentList(filterComments.filter(comment => comment.isNew === true));
        setFilterNew(true);
      }
    }

    const searchComment = (searchText) => {
      if (searchText.length == 0) {
        setCommentList(primeCommentList);
      }
      setSearchedComment(searchText);
      setCommentList(primeCommentList.filter(comment => comment.commentText.includes(searchText)));
    }

    const handleDeleteComment = (commentId) => {
      deleteComment(commentId, userId).then(resDc => {
        getCommentsBySignificant(commentType, significantId, 0).then(res => {
          if (res != null && res[0] != null) {
            res[0].isNew = true;
          }
          setCommentList(res);
          setIsAdding(false);
        });
      });
    }

    return (
      <>
        <Dialog open={showModal} onClose={() => { }} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <Grid display="flex" alignItems="center">
              <Grid flexGrow={1}>Save changes before adding comments</Grid>
              <Grid>
                {<IconButton className="closeButton" onClick={() => { setShowModal(false) }}>
                  <HideIcon />
                </IconButton>}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setShowModal(false) }}>OK</Button></Grid>
          </DialogContent>
        </Dialog>

        {/* To open modal with icons : 

          <Fab sx={!isOpen ? buttonStyle.sx : buttonStyle.sm} aria-label={buttonStyle.label} color="primary" onClick={() => { setIsOpen(!isOpen); setIsAdding(false); }}>
            {!isOpen && 
            <Tooltip title="Show Comments">
              {buttonStyle.iconShow}
            </Tooltip>
            }
            {isOpen && buttonStyle.iconHide}
          </Fab>
          {isOpen && 
            <Fab sx={buttonStyle.sb} aria-label={buttonStyle.label} color="primary" onClick={() => { setIsAdding(true) }}>
              <Tooltip title="Add Comment">
                {isOpen && buttonStyle.iconAdd}
              </Tooltip>
            </Fab>
          } */}

        {isOpen && userId !== 0 &&
          <div>
            <Drawer className={classes.drawer} variant="persistent" anchor="right" open={isOpen} >
              <DrawerHeader>
                <Box textAlign={'center'} width={'100%'} paddingTop={1}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>COLLABORATION FEED</Typography>
                  <Grid container>
                    <Grid item xs={11}>
                      <RadioGroup defaultValue="all" row>
                        <FormControlLabel value="all" control={<Radio />} onClick={() => { setCommentList(primeCommentList); setFilterNew(false) }} label="All" />
                        <FormControlLabel value="new" control={<Radio />} onClick={() => filterNewComments()} label="New" />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={1} padding={1}>
                      <SearchIcon sx={{ color: '#1B4070', fontSize: 'large' }} onClick={() => { setIsSearch(!isSearch); setPrimeCommentList(commentList) }} />
                    </Grid>
                    {isSearch && <TextField fullWidth inputProps={{ className: classes.input }} style={{ margin: 4 }} value={searchedComment}
                      placeholder="Search for a Comment" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        searchComment(event.target.value);
                      }}></TextField>}
                  </Grid>
                  <Divider style={{ margin: 5 }} />
                  {isAdding &&
                    <Grid textAlign={'right'} width={'100%'}>
                      <TextField multiline fullWidth inputProps={{ className: classes.input }} style={{ margin: 4 }} value={newComment}
                        placeholder="Make a Comment" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setNewComment(event.target.value);
                        }}></TextField>
                      <Grid direction="row" width={'100%'} paddingBottom={1}>
                        {showTypes &&
                          <Select fullWidth style={{ width: '45%', height: 30, margin: 2, textAlign: 'left' }}
                            value={selectedType} onChange={(event: SelectChangeEvent<string>) => {
                              setSelectedType(event.target.value.toString())
                            }}>
                            <MenuItem value="COMMENTSVIVOSVISIBILITY">Internal</MenuItem>
                            <MenuItem value="COMMENTSLIMITEDVISIBILITY">Limited</MenuItem>
                            <MenuItem value="COMMENTSGENERALVISIBILITY">General</MenuItem>
                          </Select>
                        }
                        <Button style={{ width: '15%', margin: 2 }} className='vivButton normal' onClick={() => { setIsAdding(false); }}>Cancel</Button>
                        <Button style={{ width: '15%', margin: 2 }} className='vivButton normal' onClick={saveComment}>Submit</Button>
                      </Grid>
                    </Grid>
                  }
                </Box>
              </DrawerHeader>
              <Grid height={'78%'} overflow={'auto'} paddingBottom={2} padding={2}>
                <List>
                  {commentList.length > 0 && commentList.map((comment: any, index: number) => (
                    <ListItem key={comment.id} disablePadding>
                      <Box width={'100%'}>
                        <Grid container >
                          <Grid item xs={11}>
                            <Typography sx={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }} variant="subtitle2">{new Date(comment.createdOn).toLocaleDateString("en-US", { hour: "2-digit", minute: "2-digit" })}</Typography>
                            {/* TO DO ADD IN WHO THE COMMENT CREATED BY */}
                            <Typography sx={{ fontSize: 12, color: '#1B4070', fontWeight: 'bold' }} variant="subtitle2">{
                              (comment.permissions === "COMMENTSVIVOSVISIBILITY" ? "Internal" : (comment.permissions === "COMMENTSLIMITEDVISIBILITY" ? "Limited" : "General"))
                            }</Typography>
                          </Grid>
                          <Grid item xs={1}>
                            {comment.isNew && <NewIcon sx={{ fontSize: 'small', color: 'orangered' }} />}
                          </Grid>
                        </Grid>
                        <Typography
                          variant="subtitle2"
                          dangerouslySetInnerHTML={{
                            __html: DOMpurify.sanitize(comment.commentText || "")
                          }}
                        />
                        <Divider style={{ margin: 5 }} />
                        <Button onClick={() => handleDeleteComment(comment.id)}>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </ListItem>
                  ))
                  }
                  {commentList.length === 0 &&
                    <Box textAlign="center">
                      <Typography variant="subtitle2">No comments yet</Typography>
                      <Typography sx={{ fontSize: 12, color: 'gray', fontWeight: 'bold' }} variant="subtitle2">Give feedback, ask questions, or start a discussion in the comments</Typography>
                    </Box>
                  }
                </List>
              </Grid>
              <DrawerFooter>
                <Grid container direction="row" xs justifyContent={'flex-end'} spacing={2}>
                  <Grid item>
                    <Button id="cancel.form.button" variant='outlined' onClick={onClose}>
                      Close
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      id="send.form.button"
                      variant='contained'
                      onClick={() => {
                        setIsAdding(!isAdding);
                        setNewComment('');
                      }}
                    >
                      Add Comment
                    </Button>
                  </Grid>
                </Grid>
              </DrawerFooter>
            </Drawer>
          </div>
        }
      </>
    );
  });