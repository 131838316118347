// Vendors
import { FC, useEffect, useState } from "react"
import {
    Button,
    Divider,
    Grid,
    TextField,
    List,
    ListItem,
    ListItemText,
    Typography,
    Box,
    styled,
    Avatar,
    Card
} from "@mui/material";
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';

// Components
import SectionContainer from "../../../components/UI/Containers/SectionContainer";
import UploadDragDrop from "../../../components/UploadDragAndDrop/UploadDragDrop";

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { ClaimCategory, ClaimClassificationSubcategories, Roles } from "../../../entities/Enums";

// API
import { observer } from "mobx-react-lite"
import moment from "moment";
import _ from "lodash";

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.main};
        color: ${theme.palette.success.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.success};
  `
);
const AvatarError = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.error.main};
        color: ${theme.palette.error.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.error};
  `
);

const RootWrapper = styled(Card)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${theme.colors.gradients.blue3};
        color:  ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(2)};
  `
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};
  `
);



interface IGIProps {
    claim: any;
    claimData: any;
    roles?: any;
}

export const ManagementReview: FC<IGIProps> = observer(({ claim, claimData, roles }) => {
    const store = useMainStoreContext().reworkClaimStore;

    const fileTypes = ["JPG", "PNG", "JPEG"];
    const CLAIMDOCUMENTTYPE = 789;
    const [filesContent, setFilesContent] = useState<File[]>([]);
    const [claimApprovedByFullName, setClaimApprovedByFullName] = useState(store.vwClaim.claimApprovedByFirstName + ' ' + store.vwClaim.claimApprovedByLastName);
    const [claimApprovedOn, setClaimApprovedOn] = useState(store.vwClaim.claimApprovedOn ? moment(store.vwClaim.claimApprovedOn).format('MM/DD/yyyy') : '');
    const [isApproved, setIsApproved] = useState(store.vwClaim.claimApprovedBy ? true : false);
    const [claimCategoryId, setClaimCategoryId] = useState(store.vwClaim?.claimCategoryId);
    const [claimSubCategoryId, setClaimSubCategoryId] = useState(claim?.claimSubCategoryId);
    const [claimAdditionalCategorizationId, setClaimAdditionalCategorizationId] = useState(claim?.claimAdditionalCategorizationId);
    const [caseCommentText, setCaseCommentText] = useState(claim?.comments);
    const [financialLiabilityId, setFinancialLiabilityId] = useState<number | null>(claim?.financialLiabilityId)
    const [labInvoiceNumber, setLabInvoiceNumber] = useState(claim?.labInvoiceNumber);
    const [labInvoiceAmount, setLabInvoiceAmount] = useState(claim?.labInvoiceAmount);
    const [providerInvoiceId, setProviderInvoiceId] = useState(claim?.providerInvoiceId);
    const [amountToChargeProvider, setAmountToChargeProvider] = useState(claim?.amountToChargeProvider);

    const [complaintMethodId, setComplaintMethodId] = useState(claim?.complaintMethod ?? 1512); // Default Website
    const [complaintTypeId, setComplaintTypeId] = useState(claim?.complaintType);
    const [isAisAdmin, setIsAisAdmin] = useState<any>([]);
    const [isVivosAdmin, setIsVivosAdmin] = useState<any>([]);

    useEffect(() => {
        setIsAisAdmin(roles.find(x => x.Name === Roles.AISADMIN));
        setIsVivosAdmin(roles.find(x => x.Name === Roles.VIVOSADMIN));
    }, [store.claim])


    const saveChanges = () => {
        store.removeManagerFiles(store.vwClaim.id);
        if (store.managerClaimFiles.length > 0) {
            store.uploadManagementFiles(store.vwClaim.id);
            store.loadPage();
        }

        let updateClaim = {
            ...store.claim,
            claimCategoryId: claimCategoryId,
            claimSubCategoryId: claimSubCategoryId,
            claimAdditionalCategorizationId: claimAdditionalCategorizationId,
            complaintMethod: complaintMethodId,
            complaintType: complaintTypeId,
            comments: caseCommentText,
            financialLiabilityId: financialLiabilityId,
            labInvoiceNumber: labInvoiceNumber,
            labInvoiceAmount: labInvoiceAmount,
            amountToChargeProvider: amountToChargeProvider,
            providerInvoiceId: providerInvoiceId
        }
        store.updateClaimChanges(updateClaim);
    };

    const approveClaim = async () => {
        setIsApproved(true);
        let updateClaim = {
            ...claim,
            claimApprovedBy: store.userID,
            claimApprovedOn: new Date()
        };

        store.updateClaimChanges(updateClaim).then((resp: any) => {
            store.vwClaim = resp.value.find(x => x.id === store.claimId);
            store.loadReworkDocuments(store.vwClaim.id);
            setClaimApprovedByFullName(store.vwClaim.claimApprovedByFirstName + ' ' + store.vwClaim.claimApprovedByLastName);
            setClaimApprovedOn(moment(store.vwClaim.claimApprovedOn).format('yyyy-MM-DD'));
        })

    }

    const handleChangeFile = (files) => {
        setFilesContent([...files]);
        var imglist: FileReader[] = store.processedImages;
        var promises: any[] = [];
        _.forEach(files, async (file) => {
            const promise = doWork(file, imglist);
            promises.push(promise);
        })
        Promise.all(promises).then(() => {
            store.setProcessedImages(imglist);
        })
    };
    async function doWork(file, imglist) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileUpload = {
                fileName: file.name,
                dataBody: (reader.result as string).split(',')[1],
                active: true,
                imageType: {
                    id: CLAIMDOCUMENTTYPE
                }
            };
            imglist.push(fileUpload);
        }
    }
    const deleteFile = (file) => {
        store.removeFile(file, claim.id);
    };

    const getSubCategoryOptions = () => {
        switch (claimCategoryId) {
            case ClaimCategory.Manufacturing:
                return store.claimClassificationSubCategoriesValues.filter(x => x.id === ClaimClassificationSubcategories["Rx was not followed"] || x.id === ClaimClassificationSubcategories["Nonfunctional Assembly"]);
            case ClaimCategory.Material:
                return store.claimClassificationSubCategoriesValues.filter(x => x.id === ClaimClassificationSubcategories["Chemical Degradation"] || x.id === ClaimClassificationSubcategories.Corrosion
                    || x.id === ClaimClassificationSubcategories.Discoloration || x.id === ClaimClassificationSubcategories["Inadequate Hardness"] || x.id === ClaimClassificationSubcategories["Surface Irregularities"]
                    || x.id === ClaimClassificationSubcategories.Porosity);
            case ClaimCategory.Other:
                return store.claimClassificationSubCategoriesValues.filter(x => x.id === ClaimClassificationSubcategories["Ordering system"] || x.id === ClaimClassificationSubcategories.Shipment);
            default:
                return [];
        }
    };

    const handleClaimCategory = (newClaimCategoryId) => {
        if (claimCategoryId !== newClaimCategoryId) {
            setClaimSubCategoryId(null);
            setClaimCategoryId(newClaimCategoryId);
        }
    }


    //const rxNotFollowedOptions = ['Wrong Models Used', 'Wrong Bite Used', 'Physical RX', 'Digital Design'];
    //const nonFunctionalAssemblyOptions = ['Acrylic', 'Clasping', '3D Axial Springs', 'Expansion Screw', 'MAD', 'Misalignment of Components'];
    //const complaintMethodOptions = ['Website', 'Email', 'Letter', 'Fax', 'In Person/Office']
    //const complaintTypeOptions = ['Performance', 'Sterility', 'Packaging', 'Labeling', 'Other']


    return (
        <Grid container direction={'row'} className="principal-claim-container" justifyContent={'center'}>
            <Grid xs={12} item container justifyContent={'flex-end'} padding={1}>
                <Button variant="outlined" onClick={() => { saveChanges() }}> Save Changes </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingRight={1}>
                <Box>
                    <SectionContainer
                        sectionTitle={'Final Review'}
                        cardContentHeight={'500px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Rework ID</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.vwClaim.id}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Rework Type</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.vwClaim.claimType}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">ERF Total</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.vwClaim.erfTotalAmount ?? 0}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Remake</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.vwClaim.labRemakeNeeded ? 'Yes' : 'No'}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Adverse Event</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.vwClaim.hasAdverseEvents ? 'Yes' : 'No'}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Patient Complaint</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {store.vwClaim.hasPatientComplaint ? 'Yes' : 'No'}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Rework Appliance Ship Date</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {moment(store.vwClaim.shippedOn).format('MM/DD/yyyy')}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Investigation Results</Typography>}
                                            secondary={
                                                <Typography variant="subtitle1">
                                                    {claim.labInvestigationNotes}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Rework Results</Typography>}
                                            secondary={
                                                <Typography variant="subtitle1">
                                                    {claim.labReworkNotes}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingX={1}>
                <Box>
                    <SectionContainer
                        sectionTitle={'Claim Classification'}
                        cardContentHeight={'500px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Category</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory.Manufacturing ? "contained" : "outlined"}
                                                            onClick={() => handleClaimCategory(ClaimCategory.Manufacturing)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {ClaimCategory[ClaimCategory.Manufacturing]}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory.Material ? "contained" : "outlined"}
                                                            onClick={() => handleClaimCategory(ClaimCategory.Material)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {ClaimCategory[ClaimCategory.Material]}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory["Provider/Patient"] ? "contained" : "outlined"}
                                                            onClick={() => handleClaimCategory(ClaimCategory["Provider/Patient"])}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {ClaimCategory[ClaimCategory["Provider/Patient"]]}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory.Other ? "contained" : "outlined"}
                                                            onClick={() => handleClaimCategory(ClaimCategory.Other)}
                                                        >
                                                            {ClaimCategory[ClaimCategory.Other]}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Sub-Categories</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    {getSubCategoryOptions().map((option, index) => (
                                                        <Grid item xs={6} key={index}>
                                                            <Button
                                                                size="small"
                                                                fullWidth
                                                                variant={claimSubCategoryId === option.id ? "contained" : "outlined"}
                                                                onClick={() => setClaimSubCategoryId(option.id)}
                                                            >
                                                                {option.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    {claimSubCategoryId === ClaimClassificationSubcategories["Rx was not followed"] && claimCategoryId === ClaimCategory.Manufacturing &&
                                        <>
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Additional Categorization</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                            {store.claimAddCategorizatioRxNotFollowedValues.map((option, index) => (
                                                                <Grid item xs={6} key={index}>
                                                                    <Button
                                                                        size="small"
                                                                        fullWidth
                                                                        variant={claimAdditionalCategorizationId === option.id ? "contained" : "outlined"}
                                                                        onClick={() => setClaimAdditionalCategorizationId(option.id)}
                                                                    >
                                                                        {option.name}
                                                                    </Button>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    }
                                    {claimSubCategoryId === ClaimClassificationSubcategories["Nonfunctional Assembly"] && claimCategoryId === ClaimCategory.Manufacturing &&
                                        <>
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Additional Categorization</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                            {store.claimAddCategorizationNonFunctionalAssemblyValues.map((option, index) => (
                                                                <Grid item xs={6} key={index}>
                                                                    <Button
                                                                        size="small"
                                                                        fullWidth
                                                                        variant={claimAdditionalCategorizationId === option.id ? "contained" : "outlined"}
                                                                        onClick={() => setClaimAdditionalCategorizationId(option.id)}
                                                                    >
                                                                        {option.name}
                                                                    </Button>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    }
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Complaint Method</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    {store.complaintMethodValues.map((option, index) => (
                                                        <Grid item xs={4} key={index}>
                                                            <Button
                                                                size="small"
                                                                fullWidth
                                                                variant={complaintMethodId === option.id ? "contained" : "outlined"}
                                                                onClick={() => setComplaintMethodId(option.id)}
                                                            >
                                                                {option.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Complaint Type</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    {store.complaintTypeValues.map((option, index) => (
                                                        <Grid item xs={4} key={index}>
                                                            <Button
                                                                size="small"
                                                                fullWidth
                                                                variant={complaintTypeId === option.id ? "contained" : "outlined"}
                                                                onClick={() => setComplaintTypeId(option.id)}
                                                            >
                                                                {option.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Claim Comments</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <TextField
                                                        multiline
                                                        onChange={(e) => { setCaseCommentText(e.target.value) }}
                                                        style={{ width: "-webkit-fill-available" }}
                                                        minRows={4}
                                                        maxRows={4}
                                                        value={caseCommentText}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingX={1}>
                <Box>
                    {/* THIS WILL NEED TO BE UPDATED AT A LATER TIME TO ACCOMMODATE MORE ROLES
                    FOR NOW WE WILL LET ANYONE WHO HAS ACCESS TO THE MANAGEMENT REVIEW TAB HAVE ACCESS TO THIS SECTION */}
                    {/* {isVivosAdmin && <SectionContainer */}
                    {<SectionContainer
                        sectionTitle={'Financial Responsibility'}
                        cardContentHeight={'500px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Financial Liability</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    {store.financialLiabilityValues.map((option, index) => (
                                                        <Grid item xs={6} key={index}>
                                                            <Button
                                                                size="small"
                                                                fullWidth
                                                                variant={financialLiabilityId === option.id ? "contained" : "outlined"}
                                                                onClick={() => setFinancialLiabilityId(option.id)}
                                                            >
                                                                {option.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Lab Invoice Number</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} paddingTop={1}>
                                                    <TextField
                                                        // style={{ width: "-webkit-fill-available" }}
                                                        size="small"
                                                        placeholder=""
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setLabInvoiceNumber(event.target.value);
                                                        }}
                                                        value={labInvoiceNumber}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Lab Invoice Amount</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} paddingTop={1}>
                                                    <TextField
                                                        // style={{ width: "-webkit-fill-available" }}
                                                        size="small"
                                                        placeholder=""
                                                        required={true}
                                                        type="number"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            let value = parseFloat(event.target.value);
                                                            value = Math.max(0, Math.round(value * 100) / 100);
                                                            setLabInvoiceAmount(value);
                                                        }}
                                                        value={labInvoiceAmount}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Amount to Charge Provider</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} paddingTop={1} justifyContent={'space-between'}>
                                                    <TextField
                                                        // style={{ width: "-webkit-fill-available" }}
                                                        size="small"
                                                        placeholder=""
                                                        required={true}
                                                        type="number"
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            let value = parseFloat(event.target.value);
                                                            value = Math.max(0, Math.round(value * 100) / 100);
                                                            setAmountToChargeProvider(value);
                                                            //setAmountToChargeProvider(Math.max(0, parseFloat(event.target.value)));
                                                        }}
                                                        value={amountToChargeProvider}
                                                    />
                                                    <Button variant="contained" color="warning" disabled={true}>Charge Provider</Button>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Provider Invoice ID</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} paddingTop={1}>
                                                    <TextField
                                                        // style={{ width: "-webkit-fill-available" }}
                                                        size="small"
                                                        placeholder=""
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setProviderInvoiceId(event.target.value);
                                                        }}
                                                        value={providerInvoiceId}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </Grid>
                        }
                    />}
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} paddingLeft={1}>
                <Grid container direction={'column'}>
                    <Grid paddingY={2}>
                        <Box>
                            <SectionContainer
                                sectionTitle={'Additional Documentation'}
                                cardContentHeight={'400px'}
                                sectionComponent={
                                    <Grid container direction={'column'} spacing={1} padding={2}>
                                        <List
                                            sx={{
                                                py: 0
                                            }}
                                        >

                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="subtitle2">Upload any documentation that supports your investigation results</Typography>}
                                                    secondary={
                                                        <Grid >
                                                            <UploadDragDrop itemsToUpload={store.managerClaimFiles}
                                                                type="multi" parentCallback={() => { }}
                                                                typeOptions={store.claimReworkDocumentTypes}
                                                                vivosId={store.vwClaim.vivosId}
                                                                containerId={store.vwClaim.id}
                                                                parentContainerId={store.vwClaim.prescriptionId} />
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    </Grid>
                                }
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingLeft={1}>
                <Grid container direction={'column'}>
                    <Grid paddingY={2}>
                        <RootWrapper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'block', sm: 'flex' },
                                    width: '100%',
                                }}
                            >
                                <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
                                    <AvatarSuccess sx={{ mr: 2 }} variant="rounded">
                                        <AddTaskTwoToneIcon />
                                    </AvatarSuccess>
                                    <Box>
                                        <TypographyPrimary variant="h5">
                                            Review of claim complete?
                                        </TypographyPrimary>
                                        <TypographySecondary variant="subtitle2">
                                            I have reviewed all information regarding this complaint for accuracy and completion.
                                        </TypographySecondary>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: { xs: 2, sm: 0 } }}>
                                    <Button variant="contained" color="info" disabled={isApproved} onClick={() => { approveClaim(); }}> Approve & Sign</Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    pt: { xs: 2, sm: 2 },
                                }}
                            >
                                <Typography variant="h6">Manager Approval By: <b>{claimApprovedByFullName}</b></Typography>
                                <Typography variant="h6">Date Recorded & Signed: <b>{claimApprovedOn}</b></Typography>
                            </Box>
                        </RootWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
})