// Vendors
import { FC } from 'react';
import { Divider, Grid } from '@mui/material';

// Components
import { SleepConditions } from './DiagnosisFromSleep';
import { ExtraoralConditions } from './DiagnosisFromExtraoral';
import { IntraoralConditions } from './DiagnosisFromIntraolal';
import { DiagnosisCBCT } from './DiagnosisFromCBCT';
import { DentalConditions } from './DiagnosisDental';
import { WorkingDiagnosis } from './DiagnosisWorking';

// API
import { observer } from 'mobx-react';




interface IDiagnosisPage {
    patient?: any;
}

export const DiagnosisPage: FC<IDiagnosisPage> = observer(({ patient }) => {
    return (
        <Grid container spacing={1} padding={5}>
            <Grid item xs={12} md={6} lg={4}>
                <SleepConditions />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <DentalConditions />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <WorkingDiagnosis />
            </Grid>

            {/* <Divider />
            <ExtraoralConditions />
            <Divider />
            <IntraoralConditions />
            <Divider />
            <DiagnosisCBCT />
            <Divider /> */}
        </Grid>
    );
});
