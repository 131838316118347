// Vendors
import {
    Box,
    Grid,
    Typography,
    Avatar,
    styled,
    alpha,
} from '@mui/material';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { RxAppliances } from '../../../entities/Enums';

// API
import { observer } from 'mobx-react';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
          background: ${theme.colors.alpha.white[100]};
          width: 100%;
          height: 85px;
          padding: ${theme.spacing(1.8)};
          box-shadow: 0 0.180rem .3rem ${alpha(
        theme.colors.alpha.black[100],
        0.3
    )}, 0 .326rem 3rem ${alpha(theme.colors.alpha.black[100], 0.2)};
    `
);

const StyledAvatar = styled('img')(
    ({ theme }) => `
          width: 100%;
          height: 100%;
          object-fit: contain;
    `
);


const LabSelection = () => {
    const { selectedAllergyList } = useMainStoreContext().patientEditStore;
    const {
        step,
        selectedLab,
        selectedAppliance,
        versaTypeSelected,
        constructionBite,
        constructionBiteType,
        modelSource,
        fabricationTime,
        selectedVerticalThickness,
        selectedBiteLocation,
        selectedApplianceLogo,
        selectedLabLogo,
        selectedeCommerceItem
    } = usePrescriptionStore().prescriptionStore;



    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <AvatarWrapper variant="rounded">
                        <StyledAvatar
                            alt="Appliance"
                            src={selectedApplianceLogo}
                        />
                    </AvatarWrapper>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {selectedLabLogo != undefined &&
                        <AvatarWrapper variant="rounded">
                            <StyledAvatar
                                alt="Lab"
                                src={selectedLabLogo}
                            />
                        </AvatarWrapper>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction={'column'}>
                    <Box>
                        <Typography variant='h4'>
                            Appliance Details:
                        </Typography>
                    </Box>
                    <Box paddingX={2}>

                        <Typography>
                            <b>Appliance:</b> {selectedAppliance?.name}
                        </Typography>
                        {selectedAppliance?.name === RxAppliances.VERSA &&
                            <Typography>
                                <b>Versa Type Selected:</b> {selectedeCommerceItem.id}
                            </Typography>}
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction={'column'}>
                    <Box>
                        <Typography variant='h4'>
                            Lab Details:
                        </Typography>
                    </Box>
                    <Box paddingX={2}>
                        <Typography>
                            <b>Lab:</b> {selectedLab?.name}
                        </Typography>
                        <Typography>
                            <b>Fabrication Time:</b> {fabricationTime}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {step > 3 && (
                <Grid item xs={12}>
                    <Grid container direction={'column'}>
                        <Box>
                            <Typography variant='h4'>
                                Impression & Bite Source:
                            </Typography>
                        </Box>
                        <Box paddingX={2}>
                            {selectedAppliance?.name === RxAppliances.VIDA && (
                                <>
                                    <Typography>
                                        <b>Vertical Thickness:</b> {selectedVerticalThickness?.name}
                                    </Typography>
                                    <Typography>
                                        <b>Unilateral BiteBlock:</b> {selectedBiteLocation?.name}
                                    </Typography>
                                </>
                            )}
                            <Typography>
                                <b>Impression Source:</b> {modelSource}
                            </Typography>
                            <Typography>
                                <b>Bite Source:</b> {constructionBite}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12}>
                <Grid container direction={'column'}>
                    <Box>
                        <Typography variant='h4'>
                            General Notes:
                        </Typography>
                    </Box>
                    <Box paddingX={2}>
                        <Typography variant='subtitle1'>
                            <b>Allergies:</b> {selectedAllergyList.join(', ')}
                        </Typography>
                        {step > 2 &&
                            <Typography variant='subtitle1'>
                                <b>Teeth Charting:</b> Confirmed
                            </Typography>}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default observer(LabSelection);