import { MainStore } from './MainStore';
import { observable, action, makeObservable, computed } from 'mobx';
import ScanData from '../../entities/ScanData';
import ImageItem from '../../entities/ImageItems'
import DictionaryListItem from '../../entities/DictionaryListItem';
import { Gateway } from '../../api/Gateway';
import jwt_decode from 'jwt-decode';
import { GlobalUtils } from '../../api/GlobalUtils';

const defaultDictionaryListItem = new DictionaryListItem({
  id: 0,
  name: "",
  listItemKey: "",
  value: "",
  isActive: true,
  dictionaryListId: 0
});
const chunkSize = 1048576 * 10;//its 10MB, increase the number measure in mb

export default class OcclusionStore {
  mainStore: MainStore;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;

    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      this.userId = tokenData.id;
    }
  }

  @observable userId = 0;
  @observable patientId = 0;
  @observable visitId = 0;
  @observable rmrelation = -1;
  @observable lmrelation = -1;
  @observable staffmember = '';
  @observable lg_3Dscantype = defaultDictionaryListItem;
  @observable lg_machinetype = defaultDictionaryListItem;
  @observable lg_2dImages = defaultDictionaryListItem;
  @observable impressiondate: Date = new Date(Date.now());
  @observable scansdata: ScanData[] = [];
  @observable imageitem3D: ImageItem[] = [];
  @observable occlusionModelTypeId: number = 0;
  @observable constructionBiteInformationId: number = 0;
  @observable constructionBiteType: number = 0;
  @observable occlusionModelTypes: any[] = [];
  @observable constructionBiteInformationList: any[] = [];
  @observable constructionBiteTypes: any[] = [];
  @observable isSavingOcclusion: boolean = false;
  @observable labAdjustConstructionBite: boolean = true;
  @observable isSpeakWithLab: boolean = false;
  @observable potrusionPositionId: number = 0;
  @observable potrusionDistance: number = 0;
  @observable prescriptionId: number = 0;
  @action setStaffMember = (staffmember: string) => { this.staffmember = staffmember };
  @action setLMRelation = (lmrelation: number) => { this.lmrelation = lmrelation };
  @action setRMRelation = (rmrelation: number) => { this.rmrelation = rmrelation };
  @action set3DScanType = (lg_3Dscantype: DictionaryListItem) => { this.lg_3Dscantype = lg_3Dscantype };
  @action setMachineType = (lg_machinetype: DictionaryListItem) => { this.lg_machinetype = lg_machinetype };
  @action set2DImages = (lg_2dImages: DictionaryListItem) => { this.lg_2dImages = lg_2dImages };
  @action setScansdata = (newScanValue: ScanData[]) => { this.scansdata = newScanValue };
  @action setImageItem3D = (newImageItem3D: ImageItem[]) => { this.imageitem3D = newImageItem3D };
  @action setPatientId = (newValue: number) => { this.patientId = newValue };
  @action setVisitId = (newValue: number) => { this.visitId = newValue };
  @action setOcclusionModelTypeId = (newValue: number) => { this.occlusionModelTypeId = newValue };
  @action setConstructionBiteInformationId = (newValue: number) => { this.constructionBiteInformationId = newValue };
  @action setConstructionBiteType = (newValue: number) => { this.constructionBiteType = newValue };
  @action setOcclusionModelTypes = (newValue: any[]) => { this.occlusionModelTypes = newValue };
  @action setConstructionBiteInformationList = (newValue: any[]) => { this.constructionBiteInformationList = newValue };
  @action setConstructionBiteTypes = (newValue: any[]) => { this.constructionBiteTypes = newValue };
  @action setIsSavingOcclusion = (newValue: boolean) => { this.isSavingOcclusion = newValue };
  @action setLabAdjustConstructionBite = (newValue: boolean) => { this.labAdjustConstructionBite = newValue };
  @action setIsSpeakWithLab = (newValue: boolean) => { this.isSpeakWithLab = newValue };
  @action setPotrusionPositionId = (newValue: number) => { this.potrusionPositionId = newValue };
  @action setPotrusionDistance = (newValue: number) => { this.potrusionDistance = newValue };
  @action setPrescriptionId = (newValue: number) => { this.prescriptionId = newValue };

  @action addScan = (scan: ScanData) => { this.scansdata = [...this.scansdata, scan]; }
  @action addImage = (imageitem3D: ImageItem) => {
    imageitem3D.imgtype = this.lg_3Dscantype.listItemKey;
    imageitem3D.machine = this.lg_machinetype.listItemKey;

    if (imageitem3D.imgtype.length > 0 && imageitem3D.machine.length > 0) {
      this.imageitem3D = [...this.imageitem3D, imageitem3D];
    }
  }
  @action setScanData = (index: number, scan: ScanData) => { this.scansdata[index] = scan; }
  @action setImageItems = (index: number, image: ImageItem) => { this.imageitem3D[index] = image; }
  @action setImpressionDate = (impressiondate: Date) => { this.impressiondate = impressiondate };

  @computed get getScans(): ScanData[] {
    return this.scansdata;
  }

  @computed get getImageItem(): ImageItem[] {
    return this.imageitem3D;
  }

  @action upsertOcclusionDetails = (visitId: number) => {
    return new Promise((resolve, reject) => {
      var promises: any[] = [];
      var details = {
        machineBrandId: this.lg_machinetype?.id ?? null,
        leftMolarRelationship: this.lmrelation === -1 || this.lmrelation === 0 ? null : this.lmrelation,
        rightMolarRelationship: this.rmrelation === -1 || this.rmrelation === 0 ? null : this.rmrelation,
        impressionDate: this.impressiondate,
        staffWhoTookImpression: this.staffmember,
        modelTypeId: this.occlusionModelTypeId,
        constructionBiteInformationId: this.constructionBiteInformationId === 0 ? null : this.constructionBiteInformationId,
        constructionBiteType: this.constructionBiteType === 0 ? null : this.constructionBiteType,
        labAdjustConstructionBite: this.labAdjustConstructionBite,
        isSpeakWithLab: this.isSpeakWithLab,
        potrusionDistance: this.potrusionDistance,
        potrusionPositionId: this.potrusionPositionId === 0 ? null : this.potrusionPositionId,
        prescriptionId: this.prescriptionId === 0 ? null : this.prescriptionId
      }
      promises.push(Gateway.post(`visitrecord/${visitId}/occlusiondetails`, details).then(() => { }));

      Promise.all(promises).then(() => {
        resolve(true);
      })

    });
  }

  @action resetScan = () => {
    new ScanData({
      title: ' ',
      scantype: ' ',
      notes: '',
      imageUrl: '',
      rdbVenus: '',
      rdbSelera: '',
      rdbHead: '',
      rdbOpen: '',
      rdbIncomp: '',
      rdbMicrogenia: '',
      rdbMacrogenia: ''
    })
  }

  @action
  updateOcclusionModelType = async () => {
    await Gateway.put(`/patient/${this.patientId}/occlusionModelType/${this.occlusionModelTypeId}/${this.userId}`, {}).then(() => {
    });
  }

  @action
  loadConstructionBiteValues = async () => {
    if (this.occlusionModelTypes.length === 0) {
      const occlusionModelTypes = await GlobalUtils.getOcclusionModelType();
      this.setOcclusionModelTypes(occlusionModelTypes);
    }

    if (this.constructionBiteInformationList.length === 0) {
      const constructionBiteInformationList = await GlobalUtils.getConstructionBiteInformation();
      this.setConstructionBiteInformationList(constructionBiteInformationList);
    }

    if (this.constructionBiteTypes.length === 0) {
      const constructionBiteTypes = await GlobalUtils.getConstructionBiteType();
      this.setConstructionBiteTypes(constructionBiteTypes);
    }
  }

  @action
resetToInitialValues = () => {
  this.userId = 0;
  this.patientId = 0;
  this.visitId = 0;
  this.rmrelation = -1;
  this.lmrelation = -1;
  this.staffmember = '';
  this.lg_3Dscantype = defaultDictionaryListItem;
  this.lg_machinetype = defaultDictionaryListItem;
  this.lg_2dImages = defaultDictionaryListItem;
  this.impressiondate = new Date(Date.now());
  this.scansdata = [];
  this.imageitem3D = [];
  this.occlusionModelTypeId = 0;
  this.constructionBiteInformationId = 0;
  this.constructionBiteType = 0;
  this.occlusionModelTypes = [];
  this.constructionBiteInformationList = [];
  this.constructionBiteTypes = [];
  this.isSavingOcclusion = false;
  this.labAdjustConstructionBite = true;
  this.isSpeakWithLab = false;
  this.potrusionPositionId = 0;
  this.potrusionDistance = 0;
  this.prescriptionId = 0;
}


}
