import AddOn from "./AddOn";

interface PatientImagesData {
    id: number,
    patientId: number,
    patientVisitId: number,
    cbctScan: string,
    cbctMachineBrandId: number,
    cbctFov01: number,
    cbctFov02: number,
    extraoralFrontRelaxed: string,
    extraoralBigSmile: string,
    extraoralRightProfile: string,
    extraoralSubmentoVertex: string,
    extraoralFullBodyPostAnterior: string,
    extraoralFullBodyPostRight: string,
    extraoral3DFaceScan: string,
    intraoralUpperArch: string,
    intraoralLowerArch: string,
    intraoralAnteriorOcclusion: string,
    intraoralLeftOcclusion: string,
    intraoralRightOcclusion: string,
    intraoralLingualFrenum: string,
    intraoralSurfaceTongue: string,
    occlusionRMolarRelationship: string,
    occlusionLMolarRelationship: string,
    occlusionMemberTookImpression: string,
    occlusionImpressionDate: Date,
    occlusion3DUpperArch: string,
    occlusion3DLowerArch: string,
    occlusion3DConstructedBite: string,
    occlusion3DMachineBrandId: number,
    occlusionUpperArch: string,
    occlusionLowerArch: string,
    occlusionAnterior: string,
    occlusionLeft: string,
    occlusionRight: string,
    isActive: boolean
}

interface PatientImages extends PatientImagesData { }

class PatientImages {
    constructor({ id, patientId, patientVisitId, cbctScan, cbctMachineBrandId, cbctFov01, cbctFov02, extraoralFrontRelaxed, extraoralBigSmile, extraoralRightProfile,
        extraoralSubmentoVertex, extraoralFullBodyPostAnterior, extraoralFullBodyPostRight, extraoral3DFaceScan, intraoralUpperArch, intraoralLowerArch, 
        intraoralAnteriorOcclusion, intraoralLeftOcclusion, intraoralRightOcclusion, intraoralLingualFrenum, intraoralSurfaceTongue, occlusionRMolarRelationship,
        occlusionLMolarRelationship, occlusionMemberTookImpression, occlusionImpressionDate, occlusion3DUpperArch,occlusion3DLowerArch,occlusion3DConstructedBite,
        occlusion3DMachineBrandId, occlusionUpperArch, occlusionLowerArch, occlusionAnterior,  occlusionLeft, occlusionRight,isActive }: PatientImagesData) {
        this.id = id;
        this.patientId = patientId;
        this.patientVisitId = patientVisitId;
        this.cbctScan = cbctScan;
        this.cbctMachineBrandId = cbctMachineBrandId;
        this.cbctFov01 = cbctFov01;
        this.cbctFov02 = cbctFov02;
        this.extraoralFrontRelaxed = extraoralFrontRelaxed;
        this.extraoralBigSmile = extraoralBigSmile;
        this.extraoralRightProfile = extraoralRightProfile;
        this.extraoralSubmentoVertex = extraoralSubmentoVertex;
        this.extraoralFullBodyPostAnterior = extraoralFullBodyPostAnterior;
        this.extraoralFullBodyPostRight = extraoralFullBodyPostRight;
        this.extraoral3DFaceScan = extraoral3DFaceScan;
        this.intraoralUpperArch =intraoralUpperArch;
        this.intraoralLowerArch = intraoralLowerArch;
        this.intraoralAnteriorOcclusion = intraoralAnteriorOcclusion;
        this.intraoralLeftOcclusion = intraoralLeftOcclusion;
        this.intraoralRightOcclusion = intraoralRightOcclusion;
        this.intraoralLingualFrenum = intraoralLingualFrenum;
        this.intraoralSurfaceTongue = intraoralSurfaceTongue;
        this.occlusionRMolarRelationship = occlusionRMolarRelationship;
        this.occlusionLMolarRelationship = occlusionLMolarRelationship;
        this.occlusionMemberTookImpression = occlusionMemberTookImpression;
        this.occlusionImpressionDate = occlusionImpressionDate;
        this.occlusion3DUpperArch = occlusion3DUpperArch;
        this.occlusion3DLowerArch = occlusion3DLowerArch;
        this.occlusion3DConstructedBite = occlusion3DConstructedBite;
        this.occlusion3DMachineBrandId = occlusion3DMachineBrandId;
        this.occlusionUpperArch = occlusionUpperArch;
        this.occlusionLowerArch = occlusionLowerArch;
        this.occlusionAnterior = occlusionAnterior;
        this.occlusionLeft = occlusionLeft;
        this.occlusionRight = occlusionRight;
        this.isActive = isActive;
    }

    toObject(): PatientImagesData {
        return { ...this };
    }
}
export default PatientImages;