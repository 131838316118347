import Compliance from "./Compliance";
import PatientEpworth from "./PatientEpworth";
import PatientFile from "./PatientFIle";
import PatientHeadNeck from "./PatientHeadNeck";
import PatientImages from "./PatientImages";
import PatientSleepStudy from "./PatientSleepStudy";
import VitalsAndMeasurments from "./VitalsAndMeasurments";

interface PatientVisitData {
    id: number,
    patientId: number,
    isActive: boolean,
    createdOn?: Date;
    patientFiles: PatientFile[],
    patientEpworth: PatientEpworth,
    patientsImages: PatientImages,
    patientVitalsAndMeasurements: VitalsAndMeasurments[],
    patientSleepStudies: PatientSleepStudy[],
    patientHeadNecks: PatientHeadNeck[]
    patientCompliances: Compliance[]
}

interface PatientVisit extends PatientVisitData { }

class PatientVisit {
    constructor({ id, patientId, patientFiles, patientEpworth, patientsImages, patientVitalsAndMeasurements, patientSleepStudies,
        patientHeadNecks, patientCompliances, isActive, createdOn }: PatientVisitData) {
        this.id = id;
        this.patientId = patientId;
        this.patientFiles = patientFiles;
        this.patientEpworth = patientEpworth;
        this.patientsImages = patientsImages;
        this.patientVitalsAndMeasurements = patientVitalsAndMeasurements;
        this.patientSleepStudies = patientSleepStudies;
        this.patientHeadNecks = patientHeadNecks;
        this.patientCompliances = patientCompliances;
        this.isActive = isActive;
        this.createdOn = createdOn;
    }

    toObject(): PatientVisitData {
        return { ...this };
    }
}
export default PatientVisit;