// Vendor
import React, { useEffect } from 'react';
import {
    Grid, Box
} from '@mui/material';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
import LoopTwoToneIcon from '@mui/icons-material/LoopTwoTone';


// Components
import DashboardInfoCard from '../../../components/UI/Cards/DashboardInfoCard';

// Entities
import { AIReportsFilters } from '../../../entities/Enums';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import _ from 'lodash';
import { GlobalUtils } from '../../../api/GlobalUtils';



export const TechInfoCards = () => {
    const store = useMainStoreContext().aiReportsStore;

    useEffect(() => {
        store.didInitAIReports = true;
        store.loadAIReports();
        store.loadTechnicianData();
        store.setAiReportStatus(GlobalUtils.getAIReportStatuses());
    }, [store.loadAIReports, store.setAiReportStatus]);

    useEffect(() => {
        store.loadAIReports();
    }, [store.filter])

    const infoBoxes: any = [];
    infoBoxes.push(
        // <Grid key={'dib0'}>
        <DashboardInfoCard
            cardLabelText={'Pending Cases'}
            cardNumberValue={store.numPendingCases}
            icon={<PendingActionsTwoToneIcon />}
            filter={AIReportsFilters.PendingCases}
            currentFilter={store.currentFilter}
            onFilter={value => {
                store.currentFilter = AIReportsFilters.PendingCases;
                store.loadAIReports();
            }}
        />
        // </Grid>,
    );
    infoBoxes.push(
        // <Grid container key={'dib1'}>
        <DashboardInfoCard
            cardLabelText={'In Progress'}
            cardNumberValue={store.numInProgress}
            icon={<LoopTwoToneIcon />}
            filter={AIReportsFilters.CasesInProgress}
            currentFilter={store.currentFilter}
            onFilter={value => {
                store.currentFilter = AIReportsFilters.CasesInProgress;
                store.loadAIReports();
            }}
        />
        // </Grid>
    );
    infoBoxes.push(
        // <Grid key={'dib2'}>
        <DashboardInfoCard
            cardLabelText={'Cases To Be Assigned'}
            filter={AIReportsFilters.ToBeAssigned}
            cardNumberValue={store.numToBeAssigned}
            icon={<LoopTwoToneIcon />}
            currentFilter={store.currentFilter}
            onFilter={value => {
                store.currentFilter = AIReportsFilters.ToBeAssigned;
                store.loadAIReports();
            }}
        />
        // </Grid>,
    );
    infoBoxes.push(
        // <Grid key={'dib3'}>
        <DashboardInfoCard
            cardLabelText={'Cases Assigned'}
            filter={AIReportsFilters.Assigned}
            icon={<LoopTwoToneIcon />}
            cardNumberValue={store.numAssigned}
            currentFilter={store.currentFilter}
            onFilter={value => {
                store.currentFilter = AIReportsFilters.Assigned;
                store.loadAIReports();
            }}
        />
        // </Grid>,
    );
    infoBoxes.push(
        // <Grid key={'dib4'}>
        <DashboardInfoCard
            cardLabelText={'Completed Rolling 30 Days'}
            filter={AIReportsFilters.Completed}
            icon={<LoopTwoToneIcon />}
            cardNumberValue={store.numCompleted}
            currentFilter={store.currentFilter}
            onFilter={value => {
                store.currentFilter = AIReportsFilters.Completed;
                store.loadAIReports();
            }}
        />
        // </Grid>,
    );

    return (
        <Grid container item xs={12} spacing={2} paddingX={2} paddingBottom={1}>
            {infoBoxes.map((box, index) => (
                <Grid item xs={12} sm={6} md={3} lg={12 / 5} key={index}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'  // This ensures the flex items (cards) are stacked vertically
                    }}>
                    <Box sx={{ flexGrow: 1, display: 'flex' }}>  {/* This Box wraps the card and allows it to grow */}
                        {box}
                    </Box>
                </Grid>
            ))}
        </Grid>

    );
};

