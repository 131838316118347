// Vendors
import react, { FC } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Switch,
    Card,
    Box,
    Typography,
    Divider,
    CardContent,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// Components
import Text from "../../../components/UI/Text";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import { AddressType, countryLabelValues, stateLabelValues } from "../../../entities/Enums";

// API
import { observer } from "mobx-react";
import _ from "lodash";
import ModalContainer from "../../../components/ModalContainer";
import { AddProviderLocation } from "./AddProviderLocation";

export const ProviderLocations: FC = observer(() => {

    const store = useMainStoreContext().providerStore;
    const [selectedPracticeAddressId, setSelectedPracticeAddressId] = react.useState<any>();

    const stateMap = (): any => {
        var states = _.map(stateLabelValues, (s, i) => {
            return (
                <MenuItem key={i} value={s.value}>
                    {s.label}
                </MenuItem>
            );
        });
        return states;
    };

    const onClose = async () => {
        store.handleShowAddPracticeLocation();
        await store.getProviderDetails();
    }

    const countryMap = (): any => {
        var countries = _.map(countryLabelValues, (s, i) => {
            return (
                <MenuItem key={i} value={s.value}>
                    {s.label}
                </MenuItem>
            );
        });
        return countries;
    };

    const handleAccountSaveButton = () => {
        store.saveProvider();
        store.setAccountAddressToggleEditMode(!store.accountAddressEditMode);
    }

    const handlePracticeSaveButton = () => {
        store.saveProvider();
        store.setPracticeAddressToggleEditMode(!store.practiceAddressEditMode);
    }

    return (
        <Grid container sx={{ overflowY: 'auto', maxHeight: '58vh' }}>
            {/* Account Address Details */}
            <Grid item xs={12} md={6} lg={6} padding={2}>
                <Card>
                    <Grid container paddingX={2} paddingY={1}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom>
                                Account Address Information
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                If you have not set up practice locations for your account this address may be utilized to determine account settings throughout the system.
                                In the future this address may become obsolete as we further transition to practice location address utilization
                            </Typography>
                        </Grid>
                        <Box height={2} width="100%" bgcolor='lightgray' px={5} my={1} />
                        {/* Mailing Address */}
                        <Grid item xs={12} container spacing={1} paddingBottom={1}>
                            <Grid item xs={9}>
                                <Typography variant="h5">Account Address</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {!store.accountAddressEditMode && (
                                    <Grid>
                                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                                            <Button size={'small'} variant={'outlined'} onClick={() => { store.setAccountAddressToggleEditMode(!store.accountAddressEditMode) }}>
                                                Edit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                                {store.accountAddressEditMode && (
                                    <Grid>
                                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                                            <Button size={'small'} variant={'contained'} onClick={() => { handleAccountSaveButton() }}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                    Address 1
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    {store.accountAddressEditMode ? <TextField
                                        variant="outlined"
                                        size="small"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])) ||
                                                _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]))) {
                                                store.providerData.addresses.addressTypeId = AddressType["Shipping Address"];
                                                store.providerData.addresses.address1 = event.target.value;
                                            } else {
                                                store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]).address1 = event.target.value;
                                            }
                                        }}
                                        value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.address1}
                                    /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.address1}</b></Text>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                    Address 2
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} >
                                <FormControl fullWidth>
                                    {store.accountAddressEditMode ? <TextField
                                        variant="outlined"
                                        size="small"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])) ||
                                                _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]))) {
                                                store.providerData.addresses.addressTypeId = AddressType["Shipping Address"];
                                                store.providerData.addresses.address2 = event.target.value;
                                            } else {
                                                store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]).address2 = event.target.value;
                                            }
                                        }}
                                        value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.address2}
                                    /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.address2}</b></Text>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                    City
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    {store.accountAddressEditMode ? <TextField
                                        variant="outlined"
                                        size="small"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])) ||
                                                _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]))) {
                                                store.providerData.addresses.addressTypeId = AddressType["Shipping Address"];
                                                store.providerData.addresses.city = event.target.value;
                                            } else {
                                                store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]).city = event.target.value;
                                            }
                                        }}
                                        value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.city}
                                    /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.city}</b></Text>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                    State
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    {store.accountAddressEditMode ? <TextField

                                        variant="outlined"
                                        size="small"
                                        select
                                        required={true}
                                        onChange={(event: any) => {
                                            if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])) ||
                                                _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]))) {
                                                store.providerData.addresses.addressTypeId = AddressType["Shipping Address"];
                                                store.providerData.addresses.state = event.target.value;
                                            } else {
                                                store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]).state = event.target.value;
                                            }
                                        }}
                                        value={
                                            _.find(stateLabelValues, st => {
                                                return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.state;
                                            })?.value
                                        }
                                    >{stateMap()}</TextField> : <Text color="black"><b>{_.find(stateLabelValues, st => {
                                        return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.state;
                                    })?.label
                                    }</b></Text>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                    Postal Code
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    {store.accountAddressEditMode ? <TextField
                                        variant="outlined"
                                        size="small"
                                        required={true}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])) ||
                                                _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]))) {
                                                store.providerData.addresses.addressTypeId = AddressType["Shipping Address"];
                                                store.providerData.addresses.postalCode = event.target.value;
                                            } else {
                                                store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]).postalCode = event.target.value;
                                            }
                                        }}
                                        value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.postalCode}
                                    /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.postalCode}</b></Text>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                    Country
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    {store.accountAddressEditMode ? <TextField
                                        variant="outlined"
                                        size="small"
                                        select
                                        required={true}
                                        onChange={(event: any) => {
                                            if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])) ||
                                                _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]))) {
                                                store.providerData.addresses.addressTypeId = AddressType["Shipping Address"];
                                                store.providerData.addresses.country = event.target.value;
                                            } else {
                                                store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]).country = event.target.value;
                                            }
                                        }}
                                        value={
                                            _.find(countryLabelValues, st => {
                                                return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.country;
                                            })?.value
                                        }
                                    >{countryMap()}</TextField> : <Text color="black"><b>{
                                        _.find(countryLabelValues, st => {
                                            return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"])?.country;
                                        })?.label
                                    }</b></Text>}
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            {/* Practice Location Details */}
            <Grid item xs={12} md={6} lg={6} padding={2}>
                <Card>
                    <Grid container paddingX={2} paddingY={1}>
                        <Grid item xs={9}>
                            <Typography variant="h4" gutterBottom>
                                Practice Details
                            </Typography>
                        </Grid>
                        <Grid item xs={3} justifyContent="flex-end">
                            <Button variant="outlined" size="small" onClick={() => { store.handleShowAddPracticeLocation(); }}>
                                Add Location (+)
                            </Button>
                        </Grid>
                        <Grid>
                            <Typography variant="subtitle2">
                                Manage information related to your practice(s)
                            </Typography>
                        </Grid>

                        <Box height={2} width="100%" bgcolor='lightgray' px={5} my={1} />

                        <Grid item xs={12} container spacing={1} paddingBottom={1}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Select Practice Location to Review</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    size="small"
                                    fullWidth
                                    placeholder="Select Practice Location"
                                    onChange={(event) => { setSelectedPracticeAddressId(event.target.value) }}
                                >
                                    {store.providerData.addresses.filter(x => x.addressTypeId === AddressType["Practice Address"]).map((address) => (
                                        <MenuItem key={address.id} value={address.id}>
                                            {address.company.companyName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6} justifyContent={'flex-end'} alignContent={'flex-end'} container>
                                {!store.practiceAddressEditMode && selectedPracticeAddressId && (
                                    <Grid>
                                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                                            <Button size={'small'} variant={'outlined'} onClick={() => { store.setPracticeAddressToggleEditMode(!store.practiceAddressEditMode) }}>
                                                Edit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                                {store.practiceAddressEditMode && (
                                    <Grid>
                                        <Grid container direction={'row'} justifyContent={'flex-end'}>
                                            <Button size={'small'} variant={'contained'} onClick={() => { handlePracticeSaveButton() }}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {selectedPracticeAddressId &&
                                    (<Typography variant="subtitle2">
                                        <Grid container paddingY={1} paddingRight={3}>
                                            <Grid item xs={12} container spacing={1}>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Practice Name
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                store.providerData.addresses.find(x => x.id === selectedPracticeAddressId).company.companyName = event.target.value;
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.id === selectedPracticeAddressId)?.company?.companyName}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.id === selectedPracticeAddressId)?.company?.companyName}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Practice Email
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                store.providerData.addresses.find(x => x.id === selectedPracticeAddressId).emailAddress = event.target.value;
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.id === selectedPracticeAddressId)?.emailAddress}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.id === selectedPracticeAddressId)?.emailAddress}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Website
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                store.providerData.addresses.find(x => x.id === selectedPracticeAddressId).website = event.target.value;
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.id === selectedPracticeAddressId)?.website}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.id === selectedPracticeAddressId)?.website}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container paddingTop={1}>
                                            {/* Practice Address */}
                                            <Grid item xs={12} container spacing={1} paddingRight={3}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5">Practice Location Address</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Address 1
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"])) ||
                                                                    _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]))) {
                                                                    store.providerData.addresses.addressTypeId = AddressType["Practice Address"];
                                                                    store.providerData.addresses.address1 = event.target.value;
                                                                } else {
                                                                    store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId).address1 = event.target.value;
                                                                }
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                && x.id === selectedPracticeAddressId)?.address1}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                            && x.id === selectedPracticeAddressId)?.address1}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Address 2
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8} >
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"])) ||
                                                                    _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]))) {
                                                                    store.providerData.addresses.addressTypeId = AddressType["Practice Address"];
                                                                    store.providerData.addresses.address2 = event.target.value;
                                                                } else {
                                                                    store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId).address2 = event.target.value;
                                                                }
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                && x.id === selectedPracticeAddressId)?.address2}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                            && x.id === selectedPracticeAddressId)?.address2}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        City
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"])) ||
                                                                    _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]))) {
                                                                    store.providerData.addresses.addressTypeId = AddressType["Practice Address"];
                                                                    store.providerData.addresses.city = event.target.value;
                                                                } else {
                                                                    store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId).city = event.target.value;
                                                                }
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                && x.id === selectedPracticeAddressId)?.city}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                            && x.id === selectedPracticeAddressId)?.city}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        State
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField

                                                            variant="outlined"
                                                            size="small"
                                                            select
                                                            required={true}
                                                            onChange={(event: any) => {
                                                                if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"])) ||
                                                                    _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]))) {
                                                                    store.providerData.addresses.addressTypeId = AddressType["Practice Address"];
                                                                    store.providerData.addresses.state = event.target.value;
                                                                } else {
                                                                    store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId).state = event.target.value;
                                                                }
                                                            }}
                                                            value={
                                                                _.find(stateLabelValues, st => {
                                                                    return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId)?.state;
                                                                })?.value

                                                            }
                                                        >{stateMap()}</TextField> : <Text color="black"><b>{_.find(stateLabelValues, st => {
                                                            return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                && x.id === selectedPracticeAddressId)?.state;
                                                        })?.label
                                                        }</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Postal Code
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"])) ||
                                                                    _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]))) {
                                                                    store.providerData.addresses.addressTypeId = AddressType["Practice Address"];
                                                                    store.providerData.addresses.postalCode = event.target.value;
                                                                } else {
                                                                    store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId).postalCode = event.target.value;
                                                                }
                                                            }}
                                                            value={store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                && x.id === selectedPracticeAddressId)?.postalCode}
                                                        /> : <Text color="black"><b>{store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                            && x.id === selectedPracticeAddressId)?.postalCode}</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Box pr={3} height={'100%'} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                                                        Country
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <FormControl fullWidth>
                                                        {store.practiceAddressEditMode ? <TextField
                                                            variant="outlined"
                                                            size="small"
                                                            select
                                                            required={true}
                                                            onChange={(event: any) => {
                                                                if (_.isNull(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"])) ||
                                                                    _.isUndefined(store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]))) {
                                                                    store.providerData.addresses.addressTypeId = AddressType["Practice Address"];
                                                                    store.providerData.addresses.country = event.target.value;
                                                                } else {
                                                                    store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId).country = event.target.value;
                                                                }
                                                            }}
                                                            value={
                                                                _.find(countryLabelValues, st => {
                                                                    return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                        && x.id === selectedPracticeAddressId)?.country;
                                                                })?.value
                                                            }
                                                        >{countryMap()}</TextField> : <Text color="black"><b>{_.find(countryLabelValues, st => {
                                                            return st.value == store.providerData.addresses.find(x => x.addressTypeId === AddressType["Practice Address"]
                                                                && x.id === selectedPracticeAddressId)?.country;
                                                        })?.label
                                                        }</b></Text>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Typography>)
                                }
                            </Grid>


                        </Grid>
                    </Grid>
                </Card>
            </Grid>



            {/* {store.toggleShowAddPracticeLocation && <ModalContainer smallScreen
                show={store.toggleShowAddPracticeLocation}
                noScroll={true}
                title="Add Practice Location"
                hideTitlebar={false}
                onClose={async () => { onClose(); }}
                onClick={async () => { onClose(); }}
            >
                <AddProviderLocation onClose={onClose} />
            </ModalContainer>
            } */}
            {store.toggleShowAddPracticeLocation && <Dialog
                open={store.toggleShowAddPracticeLocation}
                onClose={() => { }}
                aria-labelledby="form-dialog-title"
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid display="flex" alignItems="center">
                        <Grid flexGrow={1} >Add Practice Location</Grid>
                        <Grid>
                            {<IconButton className="closeButton" onClick={() => { onClose(); }}>
                                <CloseIcon />
                            </IconButton>}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <AddProviderLocation onClose={onClose} />
                </DialogContent>
            </Dialog>
            }
        </Grid >
    );

});