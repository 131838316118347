import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, Paper, Grid, Typography, Button, styled, Card, TextFieldProps } from '@mui/material';

// Components 
import AddIcon from '../../../UI/Icons/AddIcon';
import { DictionaryList, DictionaryListItem } from '../../../../models/state_models/dictionarylistitem';
import { GlobalUtils } from '../../../../api/GlobalUtils';
import { TreatmentPlanPhase, TreatmentPlanPhasesApplianceType } from '../../../../models/state_models/treatmentPlanPhase';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CssTextField from '../../../CssTextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const containerStyle = {
  padding: '20px',
  minHeight: '150px',
  backgroundColor: '#f0f0f0',
};

const containerStylePlan = {
  padding: '20px',
  minHeight: '150px',
  border: '2px solid #333',
};

const BoxWrapperSelected = styled(Box)(
  ({ theme }) => `
  background: ${theme.colors.primary.main};
  height: 40px;
  color: ${theme.palette.primary.contrastText};
  margin: ${theme.spacing(0.5)};
  border-radius: ${theme.general.borderRadiusXl};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${theme.spacing(2)};
`
);
const BoxWrapperOption = styled(Box)(
  ({ theme }) => `
  background: ${theme.palette.primary.contrastText};
  height: 45px;
  color: ${theme.colors.primary.main};
  font-weight: bold;
  border: 1px solid ${theme.colors.primary.main}; 
  margin: ${theme.spacing(0.5)};
  border-radius: ${theme.general.borderRadiusXl};
  max-width: 380px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: ${theme.spacing(2)};
`
);


interface Item {
  id: string;
  content: string;
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TreatmentPlanningBoard = () => {
  const [optionsPrevent, setOptionsPrevent] = useState<DictionaryListItem[]>();
  const [optionsManage, setOptionsManage] = useState<DictionaryListItem[]>();
  const [optionsRehabilatate, setOptionsRehabilatate] = useState<DictionaryListItem[]>();
  const [optionsSupplemental, setOptionsSupplemental] = useState<DictionaryListItem[]>();
  const [optionsDental, setOptionsDental] = useState<DictionaryListItem[]>();
  const [optionsSurgery, setOptionsSurgery] = useState<DictionaryListItem[]>();
  const [optionsOther, setOptionsOther] = useState<DictionaryListItem[]>();
  const [allOptions, setAllOptions] = useState<DictionaryListItem[]>([]);
  const [phases, setPhases] = useState<TreatmentPlanPhase[]>([]);
  const [optionsTemporaryInitial, setOptionsTemporaryInitial] = useState<DictionaryListItem[]>();
  const [optionsVivosCARE, setOptionsVivosCARE] = useState<DictionaryListItem[]>();
  const [optionsVivosGuide, setOptionsVivosGuide] = useState<DictionaryListItem[]>();
  const [optionsTMJBruxism, setOptionsTMJBruxism] = useState<DictionaryListItem[]>();
  const [optionsMandibularAdvancement, setOptionsMandibularAdvancement] = useState<DictionaryListItem[]>();
  const [optionsOrthodontics, setOptionsOrthodontics] = useState<DictionaryListItem[]>();
  const [optionsOtherTreatments, setOptionsOtherTreatments] = useState<DictionaryListItem[]>();

  const { treatmentPlan, setTreatmentPlan } = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;


  const addPhase = () => {
    if (treatmentPlan.treatmentPlanPhases.length < 4) {
      var plan = new TreatmentPlanPhase();
      plan.treatmentPlanId = treatmentPlan.id;
      phases.push(plan);
      treatmentPlan.treatmentPlanPhases.push(plan);
      setTreatmentPlan(treatmentPlan);
      setPhases([...phases]);
    }
  };

  useEffect(() => {
    GlobalUtils.getPreventPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsPrevent(values);
      }
    });

    GlobalUtils.getManagePhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsManage(values);
      }
    });

    GlobalUtils.getRehabilitatePhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsRehabilatate(values);
      }
    });

    GlobalUtils.getSupplementalTherapiesPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsSupplemental(values);
      }
    });

    GlobalUtils.getDentalPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsDental(values);
      }
    });

    GlobalUtils.getOtherPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsOther(values);
      }
    });

    GlobalUtils.getSurgeryPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsSurgery(values);
      }
    });

    GlobalUtils.getTemporaryInitialPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsTemporaryInitial(values);
      }
    });
    GlobalUtils.getVivosCAREPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsVivosCARE(values);
      }
    });
    GlobalUtils.getVivosGuidePhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsVivosGuide(values);
      }
    });
    GlobalUtils.getTMJBruxismPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsTMJBruxism(values);
      }
    });
    GlobalUtils.getMandibularAdvancementPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsMandibularAdvancement(values);
      }
    });
    GlobalUtils.getOrthodonticsPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsOrthodontics(values);
      }
    });
    GlobalUtils.getOtherTreatmentsPhaseOptions().then((values) => {
      if (values) {
        setAllOptions(prevOptions => [...prevOptions, ...values]);
        setOptionsOtherTreatments(values);
      }
    });

    setPhases([...treatmentPlan.treatmentPlanPhases]);

  }, []);

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId.includes('phase')) {
      var index = parseInt(destination.droppableId.replace('phase', ''));
      var destinationPhase = phases[index];
      var dli = allOptions?.find(x => x.id == parseInt(draggableId.split('-')[1]));


      if (dli !== undefined) {
        var typeExists = destinationPhase.treatmentPlanPhasesApplianceTypes?.find(x => x.applianceTypeId == dli?.id)
        if (!typeExists) {
          var newApplianceType = new TreatmentPlanPhasesApplianceType();
          newApplianceType.applianceTypeId = dli?.id
          newApplianceType.applianceCategory = dli.dictionaryListId
          newApplianceType.applianceType = dli;
          destinationPhase.treatmentPlanPhasesApplianceTypes.push(newApplianceType);
        }

      }

    }

    if (source.droppableId.includes('phase')) {
      var index = parseInt(source.droppableId.replace('phase', ''));
      var destinationPhase = phases[index];
      var dli = allOptions?.find(x => x.id == parseInt(draggableId.split('-')[1]));
      if (dli !== undefined) {
        destinationPhase.treatmentPlanPhasesApplianceTypes = destinationPhase.treatmentPlanPhasesApplianceTypes.filter(x => x.applianceTypeId != dli?.id)
      }
    }

    setTreatmentPlan(treatmentPlan);
    setPhases([...phases]);
  };

  const removeSpecificPhase = (index: number) => {
    const updatedPhases = phases.filter((_, phaseIndex) => phaseIndex !== index);
    treatmentPlan.treatmentPlanPhases = updatedPhases;
    setPhases([...updatedPhases]);
    setTreatmentPlan(treatmentPlan);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container spacing={1} paddingX={5} paddingTop={2}>
        <Grid item xs={12}>
          <Typography variant={'h3'}>Treatment Planning</Typography>
        </Grid>
        <Grid xs={12}>
          <Grid container justifyContent={'right'} alignContent={'end'}>
            <Button onClick={addPhase} variant={'outlined'} disabled={treatmentPlan.treatmentPlanPhases.length >= 4}
              endIcon={<AddIcon />}
            >Add Phase</Button>
          </Grid>
        </Grid>
        <Grid item xs={8} paddingRight={1}>
          <Typography textAlign={'center'} variant='h4'>Treatment Options to Select From</Typography>
          <Grid container spacing={2} paddingTop={2}>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsTemporaryInitial">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">Temporary/Initial</Typography>
                    <Grid container direction={'column'}>
                      {optionsTemporaryInitial && optionsTemporaryInitial.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>

                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsVivosCARE">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">Vivos C.A.R.E.</Typography>
                    <Grid container direction={'column'}>
                      {optionsVivosCARE && optionsVivosCARE.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsVivosGuide">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">Vivos Guide</Typography>
                    <Grid container direction={'column'}>
                      {optionsVivosGuide && optionsVivosGuide.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsTMJBruxism">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">TMJ/Bruxism</Typography>
                    <Grid container direction={'column'}>
                      {optionsTMJBruxism && optionsTMJBruxism.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsMandibularAdvancement">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">Mandibular Advancement</Typography>
                    <Grid container direction={'column'}>
                      {optionsMandibularAdvancement && optionsMandibularAdvancement.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsOrthodontics">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">Orthodontics</Typography>
                    <Grid container direction={'column'}>
                      {optionsOrthodontics && optionsOrthodontics.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={8} md={6} lg={3}>
              <Droppable droppableId="optionsOtherTreatments">
                {(provided) => (
                  <Paper style={containerStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    <Typography variant="h4">Other Treatments</Typography>
                    <Grid container direction={'column'}>
                      {optionsOtherTreatments && optionsOtherTreatments.map((item, index) => (
                        <Draggable key={item.id} draggableId={`${item.listItemKey}-${item.id}`} index={index}>
                          {(provided) => (
                            <BoxWrapperOption ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.name}
                            </BoxWrapperOption>
                          )}
                        </Draggable>
                      ))}
                    </Grid>
                    {provided.placeholder}
                  </Paper>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} paddingLeft={1} >
          <Typography textAlign={'center'} variant='h4'>Phase Planning (Drag Options into Desired Box)</Typography>
          <Grid container spacing={2} direction="row" paddingTop={2}>
            {phases.map((phase, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} key={`${index}-${phase.treatmentPlanId}`} minHeight={'200px'}>
                <Droppable droppableId={`phase${index}`}>
                  {(provided) => (
                    <Paper style={containerStylePlan} {...provided.droppableProps} ref={provided.innerRef}>
                      <Grid id="datetimepicker" container direction="row" justifyContent={'space-between'} alignItems="center" marginBottom={2}>
                        <Grid item xs={2}>
                          <Typography variant="h5">{`Phase ${index + 1}`}</Typography>
                        </Grid>
                        <Grid item xs={5} paddingRight={1}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label='Start Date'
                              value={phase.startDate}
                              className="DateBox"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              onChange={(value) => {
                                if (value) {
                                  phase.startDate = value;
                                  treatmentPlan.treatmentPlanPhases = phases;
                                  setPhases([...phases]);
                                  setTreatmentPlan(treatmentPlan);
                                }
                              }}
                              renderInput={params => (
                                <CssTextField
                                  disabled={true}
                                  {...params}
                                  sx={{
                                    '& input': {
                                      backgroundColor: '#f0f0f0',
                                      color: '#050505',
                                      fontFamily: 'Proxima Nova',
                                      fontSize: '16px',
                                    },
                                  }}
                                  className="TextBox"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={5} paddingLeft={1}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              label='End Date'
                              value={phase.endDate}
                              className="DateBox"
                              openTo="day"
                              views={['year', 'month', 'day']}
                              onChange={(value) => {
                                if (value) {
                                  phase.endDate = value;
                                  treatmentPlan.treatmentPlanPhases = phases;
                                  setPhases([...phases]);
                                  setTreatmentPlan(treatmentPlan);
                                }
                              }}
                              renderInput={params => (
                                <CssTextField
                                  disabled={true}
                                  {...params}
                                  sx={{
                                    '& input': {
                                      backgroundColor: '#f0f0f0',
                                      color: '#050505',
                                      fontFamily: 'Proxima Nova',
                                      fontSize: '16px',
                                    },
                                  }}
                                  className="TextBox"
                                  size="small"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <Grid container justifyContent="center" paddingTop={2}>
                        <Button
                          onClick={() => removeSpecificPhase(index)}
                          variant="outlined"
                          color="secondary"
                        >
                          Remove Phase
                        </Button>
                      </Grid>


                      {phase.treatmentPlanPhasesApplianceTypes && phase.treatmentPlanPhasesApplianceTypes.map((item, itemIndex) => (

                        <Draggable key={`${itemIndex}-${item.applianceType.id}`} draggableId={`${item.applianceType.listItemKey}-${item.applianceType.id}-${itemIndex}`} index={itemIndex}>
                          {(provided) => (
                            <BoxWrapperSelected ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              {item.applianceType.name}
                            </BoxWrapperSelected>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Paper>
                  )}
                </Droppable>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default TreatmentPlanningBoard;
