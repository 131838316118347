// Vendors
import * as React from 'react';
import { FC, useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    MenuItem,
    SelectChangeEvent,
    IconButton,
    styled,
    Typography,
    Avatar,
    TextField
} from "@mui/material";

// Components
import Appointments from '../../components/Appointments/Appointments';
import { SingleLineTextModal } from "../../components/SingleLineTextModal";
import PatientEditBL from '../../components/PatientProfile/PatientEditBL';
import PatientHeaderLayout from '../../components/PatientProfile/PatientDetailComponents/PatientHeaderLayout';
import { MyoPatientActionButtons } from './MyoPatientActionButtons';
import { MyoHeaderDetails } from './MyoHeaderDetails';
import { PatientDetailsTwo } from '../../components/PatientProfile/PatientDetailComponents/PatientDetailsTwo';
import { PatientVisitRecords } from '../../components/PatientProfile/PatientVisitRecordComponents/PatientVisitRecords';
import { PatientTeeth } from '../../components/PatientProfile/PatientTeethComponents/PatientTeeth';
import { PatientAIReports } from '../../components/PatientProfile/PatientAIReportComponents/PatientAIReports';
import { PatientApplianceTherapy } from '../../components/PatientProfile/PatientApplianceComponents/PatientApplianceTherapy';
import { DigitalFormResponses } from '../../components/PatientProfile/PatientModalCustomForm/DigitalFormResponses';

// Store
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import { PatientStore } from "../../api/PatientStore";

// Entities

// API
import _ from "lodash";
import moment from "moment";
import { observer } from "mobx-react";

interface IDetailProps {
    therapists: any;
    onClose(): any;
}
const patientTabs = [
    'MyoSync',
    'Patient Details',
    'Visit Records',
    'Teeth Charting',
    'AI Reports',
    'Appliance Therapy',
    //"Form Responses"
];


const DetailPanel: FC<IDetailProps> = (props: IDetailProps) => {

    const {
        clearSessionData,
        setMyoCorrectStatus,
        setSelectedFrenectomy,
        updateFrenectomyStatus } = useMainStoreContext().sessionsStore;
    const { currentRow, clearData, setCurrentRow, updatePatientDataItem } = useMainStoreContext().myoCorrectStore;
    const { currentVisit } = useMainStoreContext().visitRecordStore;
    const [patientStore, setPatientStore] = React.useState(new PatientStore());
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [modalType, setModalType] = React.useState("");
    const [modalLabel, setModalLabel] = React.useState("");
    const [prevModalValue, setPrevModalValue] = React.useState("");
    const [modalFieldName, setModalfieldName] = React.useState("");
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [tabs, setTabs] = React.useState<any>();
    const [editBL] = React.useState(new PatientEditBL());

    const handleTabChange = (_event: any, newValue): void => {
        editBL.setActiveTab(newValue)
    }

    const handleLabelClick = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    useEffect(() => {
        setPatientStore(new PatientStore());
        setTabs(patientTabs);
        setMyoCorrectStatus(currentRow.myoCorrectStatusId);
        setDataLoaded(true);
    }, []);

    const onSave = (value: string) => {
        updateFrenectomyStatus(currentRow.id);
        currentRow[modalFieldName] = value;
        setShowModal(false);
        patientStore.updatePerson(currentRow);
        setCurrentRow(currentRow);
        updatePatientDataItem(currentRow.id, value, modalFieldName, currentRow);
    }

    return (
        <>
            <Grid>
                <Grid xs={12}>
                    <PatientHeaderLayout
                        patient={currentRow}
                        patientImageUrl={currentRow.profileImageUrl}
                        provider={currentRow.provider}
                        providerPhone={currentRow.providerPhone}
                        providerEmail={currentRow.providerEmail}
                        statusColumnContent={<MyoHeaderDetails patient={currentRow} />}
                        buttonColumnContent={
                            <MyoPatientActionButtons
                                patient={currentRow}
                                bl={editBL}
                            // onClose={props.onClose} 
                            />
                        }
                        tabs={tabs}
                        activeTab={editBL.activeTab}
                        handleTabChange={handleTabChange}
                        isLoading={false}
                    />
                </Grid>
                <Grid style={{ overflowY: 'auto' }}>
                    {editBL.activeTab === 0 && (
                        <Grid container paddingTop={2}>
                            {
                                <Grid item xs={12}>
                                    <Appointments
                                        dataLoaded={dataLoaded}
                                        patientId={currentRow.id}
                                        vivosId={currentRow.vivosId}
                                        sessions={currentRow.patientSessions}
                                        assignedTherapist={currentRow.assignedTherapistId}
                                        therapists={props.therapists}
                                        myoCorrectPlanId={currentRow.myoCorrectPlanId}
                                        myoCorrectEnrollmentDate={currentRow.myoCorrectEnrollmentDate} />
                                </Grid>
                            }
                        </Grid>

                    )}
                    {editBL.activeTab === 1 && (
                        <PatientDetailsTwo
                            isClinicalAdvocate={false}
                            patient={currentRow}
                            bl={editBL}
                        />
                    )}
                    {editBL.activeTab === 2 && (
                        <PatientVisitRecords patient={currentRow} />
                    )}
                    {editBL.activeTab === 3 && <PatientTeeth patient={currentRow} />}
                    {editBL.activeTab === 4 && <PatientAIReports patient={currentRow} bl={editBL} />}
                    {editBL.activeTab === 5 && <PatientApplianceTherapy patient={currentRow} />}
                    {editBL.activeTab === 6 && <DigitalFormResponses isIntake={false} visit={currentVisit} isPatientFormStep={false} patient={currentRow} />}
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
                <Button id="detailpanel-mod.btn.close" className="vivButton large" onClick={
                    () => {
                        clearData();
                        clearSessionData();
                        props.onClose();
                    }
                }>Close</Button>
            </Grid>
            <SingleLineTextModal fieldName={modalFieldName} isOpen={showModal} title={modalLabel} type={modalType} currentObj={currentRow} onSave={onSave}
                onClose={() => {
                    currentRow[modalFieldName] = prevModalValue;
                    setShowModal(false);
                }} />
        </>
    )
}

export default observer(DetailPanel);
