// Vendors
import React from "react";
import { Button, Grid, TextareaAutosize, Typography } from "@mui/material";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';

// Components

// Entities
import Claim from "../../../../entities/Claim";

// Stores
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";

// API
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { FileUploader } from "react-drag-drop-files";

interface IGIProps {
    claim: Claim;
    isEdit: boolean;
}

export const ReworkInfoStep: React.FC<IGIProps> = observer(({ claim, isEdit }) => {

    const store = useMainStoreContext().reworkClaimStore;
    const fileTypes = ["JPG", "PNG", "JPEG", "PDF", "MP4", "MOV", "AVI"];
    const CLAIMDOCUMENTTYPE = 789;

    React.useEffect(() => {
        store.loadAllReworkRequestValues();
        if (isEdit) {
            store.loadReworkRequestValues(claim);
            store.loadReworkDocuments(claim.id);
        }
    }, []);

    async function doWork(file, imglist) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileUpload = {
                fileName: file.name,
                dataBody: (reader.result as string).split(',')[1],
                active: true,
                imageType: {
                    id: CLAIMDOCUMENTTYPE
                }
            };
            imglist.push(fileUpload);
        }
    }

    const handleChangeFile = (files) => {
        store.setFilesToUpload(true);
        var imglist: FileReader[] = store.claimFiles;
        var promises: any[] = [];
        _.forEach(files, async (file) => {
            const promise = doWork(file, imglist);
            promises.push(promise);
        })
        Promise.all(promises).then(() => {
            store.setClaimFiles(imglist);
        })
    };

    const deleteFile = (file) => {
        store.removeFile(file, claim.id);
    };

    const handleRequestSelected = (e) => {
        let newList: any = [];

        if ([...store.reworkSelection].some(x => x.id === e.id)) {
            newList = [...store.reworkSelection].filter(x => x.id !== e.id);
        }
        else {
            newList = [...store.reworkSelection, e];
        }

        store.setReworkSelection(newList);

    };

    return (
        <Grid>
            {/* <Grid xs={12} className="box-wizard-container">
                <Grid xs={12} container direction='row' className="title-wizard">
                    <Grid xs={11}>Rework Request</Grid>
                    <Grid xs={1} style={{ color: 'orange', fontSize: '8px' }}>  </Grid>
                </Grid>
                <Grid container direction='row'>
                    {store.allReworkRequestValues.map(x => (
                        <button className={store.reworkSelection.some(y => y.id === x.id) ? "rework-request-item-selected" : "rework-request-item"} onClick={() => handleRequestSelected(x)}> {x.name} </button>
                    ))}
                </Grid>
            </Grid> */}

            <Grid className="box-wizard-container">
                <Grid container className="title-wizard"> Upload supporting documentation, videos and/or images </Grid>
                <Grid container padding={1}>
                    <Typography variant='body1'>
                        To help streamline the processing of your claim, we kindly request that you include photos when submitting
                        your claim. As Vivos collaborates with various contract manufacturers for device fabrication, the addition
                        of photos greatly assists us in comprehending the repair request and effectively communicating with the
                        relevant lab. While photos are not mandatory, we highly recommend them.
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <FileUploader
                        label="Upload or drop a image here"
                        name="file"
                        handleChange={handleChangeFile}
                        multiple={true}
                        types={fileTypes}
                        children={
                            <Grid container direction="column" className="wizardUploadImgContainer">
                                <>
                                    <Grid item>Drag & Drop Image(s) or</Grid>
                                    <Grid item>Click to Select Image(s)</Grid>
                                </>
                            </Grid >
                        } />
                    {store.claimFiles.length > 0 ? (store.claimFiles.filter(x => x.imageType.id === CLAIMDOCUMENTTYPE).map(f => (
                        <Grid xs={12} container direction="row" className="claimuploadImgWizard">
                            <Grid xs={10}>{f.pathUrl ? f.pathUrl : f.fileName}</Grid>
                            <Grid xs={2}>
                                <Button onClick={() => { deleteFile(f) }}><DoNotDisturbOnIcon /></Button>
                            </Grid>
                        </Grid>
                    ))) : <> </>
                    }
                </Grid>
                <Grid className="title-wizard"> Please describe the issue regarding the appliance (Please be detailed with pertinent information): </Grid>
                <TextareaAutosize
                    className="claim-textarea"
                    onChange={(e) => { store.setProviderNotes(e.target.value) }}
                    style={{ width: "-webkit-fill-available" }}
                    minRows={10}
                    maxRows={20}
                    value={store.providerNotes}
                />
            </Grid>
        </Grid>
    )
}
);
