// Vendors
import React from 'react';
import {
    Typography,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@mui/material';

// API
import { observer } from 'mobx-react-lite';

// Components
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

interface ConstructionTablesProps {
    prescription: any;
}

export const GuideConstructionTable: React.FC<ConstructionTablesProps> = observer(({ prescription }) => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    return (
        <Grid>
            <Grid>
                <Typography variant="h3">
                    Construction
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>{prescription.appliance?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Size</TableCell>
                                <TableCell>{prescription.appliance?.size?.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Color</TableCell>
                                <TableCell>{prescription.appliance?.color?.name}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
});
