// Vendors
import React from 'react';
import {
    Typography,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@mui/material';

// API
import { observer } from 'mobx-react-lite';

// Components
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { ConstructionTypes } from '../../../entities/Enums';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

interface ConstructionTablesProps {
    prescription: any;
}

export const PExConstructionTable: React.FC<ConstructionTablesProps> = observer(({ prescription }) => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    const babyUpperTeethMap = { 33: 'A', 34: 'B', 41: 'I', 37: 'J' };
    const babyLowerTeethMap = { 42: 'T', 43: 'S', 39: 'L', 38: 'K' };

    const mapToBabyTeeth = (toothNumbers: number[], mapping: Record<number, string>) => {
        return toothNumbers.map((tooth) => mapping[tooth] ?? tooth).join(', ');
    };

    return (
        <Grid>
            <Grid>
                <Typography variant="h3">
                    Construction
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell>Expansion Screw</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    {(() => {
                                        const screwName =
                                            prescriptionStore.upperScrewValues.find(
                                                (x) => x.id === prescription.appliance.applianceSystemUpperSystemScrewId
                                            )?.name ?? 'No';
                                        return screwName.toLowerCase().includes('leaf') ? 'Self-Expander' : screwName;
                                    })()}
                                </TableCell>
                            </TableRow>
                            {(
                                prescription.appliance.applianceType === ConstructionTypes["PEx-D"] ||
                                prescription.appliance.applianceType === ConstructionTypes["PEx-U"]
                            ) &&
                                <TableRow>
                                    <TableCell>Upper Anterior Lingual Supports</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.appliancesystemUppersystemUpperAnteriorWhipLapBool ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            }
                            {(
                                prescription.appliance.applianceType === ConstructionTypes["PEx-D"] ||
                                prescription.appliance.applianceType === ConstructionTypes["PEx-L"]
                            ) &&
                                <TableRow>
                                    <TableCell>Lower Anterior Lingual Supports</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.appliancesystemLowersystemLowerAnteriorWhipLapBool ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            }
                            {(
                                prescription.appliance.applianceType === ConstructionTypes["PEx-D"] ||
                                prescription.appliance.applianceType === ConstructionTypes["PEx-L"]
                            ) &&
                                <TableRow>
                                    <TableCell>Reverse Pull Facemask Attachments</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemReversePullFacemaskAttachmentsBool ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            }

                            {(
                                prescription.appliance.applianceType === ConstructionTypes["PEx-D"] ||
                                prescription.appliance.applianceType === ConstructionTypes["PEx-U"]
                            ) &&
                                <TableRow>
                                    <TableCell>Upper Bands</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        {mapToBabyTeeth(
                                            prescription.appliance.dentalPieces
                                                .filter(
                                                    (x) =>
                                                        x.partId ===
                                                        prescriptionStore.dentalPartValues.find(
                                                            (x) =>
                                                                x.partName === 'Band'
                                                        )?.id &&
                                                        x.arch === 'Upper'
                                                )
                                                .map((dentalPiece) => dentalPiece.toothBegin),
                                            babyUpperTeethMap
                                        )}
                                    </TableCell>
                                </TableRow>
                            }
                            {(
                                prescription.appliance.applianceType === ConstructionTypes["PEx-D"] ||
                                prescription.appliance.applianceType === ConstructionTypes["PEx-L"]
                            ) &&
                                <TableRow>
                                    <TableCell>Lower Bands</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        {mapToBabyTeeth(
                                            prescription.appliance.dentalPieces
                                                .filter(
                                                    (x) =>
                                                        x.partId ===
                                                        prescriptionStore.dentalPartValues.find(
                                                            (x) =>
                                                                x.partName === 'Band'
                                                        )?.id &&
                                                        x.arch === 'Lower'
                                                )
                                                .map((dentalPiece) => dentalPiece.toothBegin),
                                            babyLowerTeethMap
                                        )}
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
});
