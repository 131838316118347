import { makeObservable, observable } from "mobx";
import { BaseMainStore } from "../base/MainStore";
import Patient from "../../models/state_models/patient";

export  class PatientStore {
    mainStore: BaseMainStore;

    constructor(mainstore: BaseMainStore) {
      makeObservable(this);
      this.mainStore = mainstore;
    }

    @observable patient: Patient = new Patient();
}