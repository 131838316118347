import { createContext, useContext } from 'react';
import { ImpressionStore } from '../../Impressions.tsx/ImpressionStore';
import { BaseMainStore } from '../../base/MainStore';
import PrescriptionStore from '../PrescriptionStore';


export class CreateEditRxMainStore extends BaseMainStore {
    impressionStore: ImpressionStore;
    prescriptionStore: PrescriptionStore;

    constructor() {
        super();
        this.prescriptionStore = new PrescriptionStore(this);
        this.impressionStore = new ImpressionStore(this);
        
    }
}

export const store = new CreateEditRxMainStore();
export const MainStoreContext = createContext<CreateEditRxMainStore>(store);
export const usePrescriptionStore = () => {
    return useContext(MainStoreContext);
};
