import { observable } from "mobx";
import EcommerceItem from "./ecommerceItem";
import { ECommerceShopifyItem } from "./eCommerceShopifyItem";
import { DictionaryListItem } from "./dictionarylistitem";

export class ApplianceItemSetup {
    @observable id: number = 0;
    @observable applianceFamily: number = 0;
    @observable applianceType: number = 0;
    @observable ecommerceItemId: number = 0;
    @observable applianceFamilyNavigation: any = null; // Add type as needed
    @observable applianceTypeNavigation: DictionaryListItem | null = null;
    @observable ecommerceItem: EcommerceItem | null = null;
    @observable shopifyProduct: any | undefined = undefined; 
    @observable eCommerceShopifyItem: ECommerceShopifyItem = new ECommerceShopifyItem();
}