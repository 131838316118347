import { Close } from "@material-ui/icons";
import { Box, Fade, Grid, IconButton, Modal, Typography } from "@mui/material"
import Backdrop from '@mui/material/Backdrop';
import { useEffect } from "react";
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1100,
	height: 700,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
	
  };

interface ComponentProps {
	open : boolean
	onClose: () => void
	loadData: () => void
}

const AppModal = (props:ComponentProps) =>{
	const { open, onClose, loadData } = props;

	const { calendlyRescheduleUri, previousDateReschedule, selectedSessionNumber } = useMainStoreContext().myoCorrectAdminStore;

	const closeModal = async () => {
		loadData();
		onClose();
	}

	const calendlyURL = calendlyRescheduleUri + `?utm_source='reschedule'`

	useEffect(()=> {
		const head = document.querySelector('head');
		if(open) {
			const script = document.createElement('script');
			script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
			script.setAttribute('id', 'calendly-script');
			if(head) {
				head.appendChild(script);
			}
		}
		else {
			if(head) {
				const script = head.querySelector('#calendly-script');
				if(script) {
					head.removeChild(script);
				}
			}
		}
	},[open]);

	return (
		<Modal 
		  aria-labelledby="transition-modal-title"
		  aria-describedby="transition-modal-description"
		  open={open}
		  closeAfterTransition
		  BackdropComponent={Backdrop}
		  BackdropProps={{
			timeout: 500,
		  }}
		>
		  <Fade in={open}>
			<Box className="box-calendly">
				<Grid container>
					<Grid item xs={11} display='flex' alignItems='center'>
		  				<Typography variant='h5' component='h5'>Reschedule Session {selectedSessionNumber} - Previous date: {previousDateReschedule}</Typography>
					</Grid>
		  			<Grid item xs={1} >
						<IconButton onClick={closeModal} >
							<Close />
						</IconButton>
					</Grid>
				</Grid>
				
				<div className="calendly-inline-widget" data-url={calendlyURL} style={{height:'700px'}}></div>
			</Box>
		  </Fade>
		</Modal>)
}

export default AppModal;