import { MainStore } from './MainStore';
import { observable, action, makeObservable } from 'mobx';
import LegalGuardianData from '../../entities/LegalGuardian';
import * as _ from 'lodash';
import { GlobalUtils } from '../../api/GlobalUtils';
export default class CreateLegalGuardianStore {
    mainStore: MainStore;
    constructor(mainstore: MainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
    }

    @observable legalGuardians: LegalGuardianData[] = [];

    @observable error = true;
    @action setError = (error: boolean) => { this.error = error }

    @observable helperFirstNameText = "";
    @observable helperLastNameText = "";
    @observable helperEmailText = "";
    @observable helperPhoneText = "";

    @action setHelperFirstNameText = (newValue: string) => { this.helperFirstNameText = newValue };
    @action setHelperLastNameText = (newValue: string) => { this.helperLastNameText = newValue };
    @action setHelperEmailText = (newValue: string) => { this.helperEmailText = newValue };
    @action setHelperPhoneText = (newValue: string) => { this.helperPhoneText = newValue };

    @action addGuardian = (guardian: LegalGuardianData) => {
        this.legalGuardians.push(
            guardian
        );
    }

    @action removeGuardian = (index: number) => {
        this.legalGuardians.splice(index, 1);
    }

    @action setGuardian = (index: number, guardian: LegalGuardianData) => {
        this.legalGuardians[index] = guardian;
    }

    getGuardians = (): LegalGuardianData[] => {
        return this.legalGuardians;
    }

    getGuardian = (index: number): LegalGuardianData => {
        return this.legalGuardians[index];
    }

    @action guardiansReset = () => {
        this.legalGuardians = [];
        this.helperFirstNameText = "";
        this.helperLastNameText = "";
        this.helperEmailText = "";
        this.helperPhoneText = "";
    }

    @action setGuardians = (guardians: LegalGuardianData[]) => {

        if (guardians && guardians.length > 0) {
            _.forEach(guardians, (l) => {
                this.addGuardian(l);
            });
        }
    }

    @action
    validateGuardians = (): string[] => {
        let fieldValidationList: string[] = [];
        _.forEach(this.legalGuardians, (lg) => { 
            if (lg.firstName !== "" && !GlobalUtils.validateAlpha(lg.firstName)) {
                fieldValidationList.push("First Name (Legal Guardian) has invalid characters.");
                this.setHelperFirstNameText("Invalid characters");
            }

            if (lg.lastName !== "" && !GlobalUtils.validateAlpha(lg.lastName)) {
                fieldValidationList.push("Last Name (Legal Guardian) has invalid characters.");
                this.setHelperLastNameText("Invalid characters");
            }

            if (lg.emailAddress !== "" && !GlobalUtils.validateEmail(lg.emailAddress)) {
                fieldValidationList.push("Invalid Email Format  (Legal Guardian)");
                this.setHelperEmailText("Invalid Email Format");
            }
        })
        return fieldValidationList;
    }
}