import React, { Fragment, useState, useEffect } from 'react';
import {
    Typography, Card, Box, styled, useTheme, Grid, Avatar, Button, Divider, Dialog, DialogTitle, DialogContent,
} from "@mui/material";
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import PageHeaderAdvisory from './components/PageHeaderAdvisory';
import { InlineWidget } from 'react-calendly';


const CardBorderTop = styled(Card)(
    () => `
          border-top: transparent 5px solid;
    `
);

const advisory = [
    {
        id: 1,
        advisor: "Tara Griffin",
        imageLoc: "/avatars/4.jpg",
        link: "https://calendly.com/corbydixon/15min",
        specialties: ["Severe Obstructive Sleep Apnea", "Biomimetic oral appliance therapy"],
        bio: "Dr. Tara Griffin has been a leading expert in sleep apnea and TMJ pain relief for over a decade. She pioneered the use of biomimetic oral appliance therapy to treat severe cases, achieving remarkable success rates."
    },
    {
        id: 2,
        advisor: "Kevin Goles",
        imageLoc: "/avatars/4.jpg",
        link: "https://example.com/article2",
        specialties: ["TMJ Disorders", "Craniofacial Pain"],
        bio: "With a focus on TMJ disorders and craniofacial pain, Dr. Kevin Goles has contributed to numerous advances in patient care, integrating holistic approaches with traditional treatment methods."
    },
    {
        id: 3,
        advisor: "Susan Park",
        imageLoc: "/avatars/4.jpg",
        link: "https://example.com/article3",
        specialties: ["Oral Appliances", "Long-term treatment effects"],
        bio: "Dr. Susan Park has extensively researched the long-term effects of oral appliances in sleep apnea treatment, offering insights that have reshaped protocols and patient expectations."
    },
    {
        id: 4,
        advisor: "John Doe",
        imageLoc: "/avatars/4.jpg",
        link: "https://example.com/article4",
        specialties: ["Dental Orthopedics", "Innovative Techniques"],
        bio: "An innovator in dental orthopedics, Dr. John Doe's work with new techniques has improved outcomes for patients with complex orthopedic needs."
    },
    {
        id: 5,
        advisor: "Emily Stone",
        imageLoc: "/avatars/4.jpg",
        link: "https://www.canva.com/design/DAGBSyXtHD4/mr0SqMm7kkV73wv10QL6TQ/view?utm_content=DAGBSyXtHD4&utm_campaign=designshare&utm_medium=link&utm_source=editor",
        specialties: ["Dental Implants", "Aesthetic Reconstruction"],
        bio: "Specializing in dental implants and aesthetic reconstruction, Dr. Emily Stone has enhanced the smiles and lives of her patients through cutting-edge technologies and compassionate care."
    }
];

const useScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

const useStyle = (url) => {
    useEffect(() => {
        const link = document.createElement('link');
        link.href = url;
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, [url]);
};


function AdvisoryConsultationsPage() {
    const theme = useTheme();
    const [openBio, setOpenBio] = useState(false);
    const [selectedAdvisor, setSelectedAdvisor] = useState("");
    const [calendlyOpen, setCalendlyOpen] = useState(false);
    const [calendlyUrl, setCalendlyUrl] = useState("");

    const handleOpenAdvisorBio = (bio) => {
        setSelectedAdvisor(bio);
        setOpenBio(true);
    };

    const handleCloseBio = () => {
        setOpenBio(false);
    };

    const handleOpenCalendly = (link) => {
        setCalendlyUrl(link);
        setCalendlyOpen(true);
    };

    const handleCloseCalendly = () => {
        setCalendlyOpen(false);
    };

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeaderAdvisory />
            </PageTitleWrapper>
            <Grid container spacing={2}>
                {advisory.map((doc) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={doc.id}>
                        <CardBorderTop
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: 2,
                                marginBottom: 2,
                                borderTopColor: `${theme.colors.primary.main}`
                            }}>
                            <Avatar
                                alt={doc.advisor}
                                src={doc.imageLoc}
                                sx={{ width: 140, height: 140, marginBottom: 2 }}
                                variant="square"
                            />
                            <Typography gutterBottom variant="h6" align="center">
                                {doc.advisor}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" align="center">
                                {doc.bio.substring(0, 100)}...
                            </Typography>
                            <Grid container justifyContent={'space-evenly'}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleOpenCalendly(doc.link)}
                                >
                                    Schedule
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleOpenAdvisorBio(doc.bio)}
                                >
                                    View Advisor Bio
                                </Button>
                            </Grid>
                            <Divider sx={{ width: '100%', marginBottom: 1 }} />
                            <Typography variant="subtitle2" align="center" sx={{ width: '100%' }}>
                                <strong>Specialties:</strong> {doc.specialties.join(', ')}
                            </Typography>
                        </CardBorderTop>
                    </Grid>
                ))}
                <Dialog open={calendlyOpen} onClose={handleCloseCalendly} fullWidth={true} maxWidth="lg">
                    <DialogTitle>Schedule an Appointment</DialogTitle>
                    <DialogContent>
                        <InlineWidget url={calendlyUrl} styles={{ height: '800px' }} />
                    </DialogContent>
                </Dialog>
                <Dialog open={openBio} onClose={handleCloseBio}>
                    <DialogTitle>Advisor Bio</DialogTitle>
                    <DialogContent>
                        <Typography>{selectedAdvisor}</Typography>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Fragment>
    );
}

export default AdvisoryConsultationsPage;
