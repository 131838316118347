import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import { settings as sett1, Serializer } from 'survey-core';
import { settings } from 'survey-creator-core';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import React, { useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import * as _ from 'lodash';

interface SurveyCreatorWidgetProps {
    sections: any;
    onClose: (onClose: boolean) => void;
}

export const SurveyCreatorCustomForm: React.FC<SurveyCreatorWidgetProps> = ({ sections, onClose }) => {
    let { saveQuestionnaire, loadQuestionnaireCustomFormSingle, categories, formTypes } = useMainStoreContext().customFormStore;

    const [sectionData, setSectionData] = React.useState<any>([]);
    useMemo(() => {
        var sectionData = sections.flatMap(section => toJS(section));
        setSectionData(_.orderBy(sectionData, ['categoryId'], ['asc']));
    }, [])

    settings.designer.showAddQuestionButton = false;
    sett1.allowShowEmptyTitleInDesignMode = false;

    const creator = useMemo(() => {
        const options = {
            showLogicTab: false,
            showJSONEditorTab: false,
            questionTypes: [''],
            showSidebar: false,
            isAutoSave: false,
            showSurveyTitle: true,
            showAddQuestionButton: false,
            showTestSurveyTab: false,
        };

        return new SurveyCreator(options);
    }, []);

    creator.toolbox.forceCompact = false;
    creator.pageEditMode = 'standard';

    var propertyStopList = ['formType', 'category'];

    creator.onShowingProperty.add(function (sender, options) {
        options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
    });

    const convertTypes = (type: string) => {
        switch (type) {
            case 'SINGLELINETEXT':
                return 'text';
            case 'textarea':
                return 'text';
            case 'CHECKBOX':
                return 'checkbox';
            case 'RADIOBTNGRP':
                return 'radiogroup';
            case 'DATETIME':
                return 'text';
            default:
                return 'text';
        }
    };

    const convertDisplayType = (type: string) => {
        switch (type) {
            case 'DATETIME':
                return 'date';
        }
    };

    useMemo(() => {
        const defaultJson = {
            survey: [
                {
                    title: 'Custom Form Title',
                    description: 'Description',
                },
            ],
        };
        creator.text = window.localStorage.getItem('survey-json') || JSON.stringify(defaultJson);
    }, []);

    Serializer.addProperty('question', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('panel', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('page', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('survey', {
        name: 'category:dropdown',
        category: 'general',
        choices: categories,
    });

    Serializer.addProperty('survey', {
        name: 'formType:dropdown',
        category: 'general',
        choices: formTypes,
        visibleIndex: 1,
        default: formTypes[1].value
    });

    Serializer.removeProperty('page', 'category');
    Serializer.removeProperty('question', 'category');

    useEffect(() => {
        let showPanel = creator.toolbarItems.filter(x => x.id === 'svd-settings')[0];
        if (showPanel) {
            creator.toolbarItems.splice(creator.toolbarItems.indexOf(showPanel), 1);
        }

        creator.survey.title = 'Custom Form Title';
        sectionData.forEach((section: any) => {
            return creator.toolbox.addItem({
                id: section.id.toString(),
                name: section.text,
                title: section.title,
                category: section.categoryId ? _.find(categories, (c) => { return c.id === section.categoryId }).value : 'Others',
                isCopied: true,
                iconName: 'icon-default',
                json: {
                    type: section.type,
                    title: section.title,
                    name: section.text,
                    id: section.id.toString(),
                    description: section.description,
                    titleLocation: 'left',
                    elements: section.elements.map((question: any) => {
                        return {
                            type: convertTypes(question.type),
                            name: question.text,
                            title: question.title,
                            id: question.id,
                            inputType: convertDisplayType(question.type),
                            isRequired: question.isRequired,
                            showLabel: question.showLabel,
                            titleLocation: 'left',
                            startWithNewLine: question.startWithNewLine,
                            choices: question.choices,
                        };
                    }),
                },
            });
        });
    }, []);

    useEffect(() => {
        creator.onPanelAdded.add((sender, object) => {
            const json = sender.JSON;
            const addedOnPage = json.pages[object.page.num - 1];
            const lastElementAdded = addedOnPage.elements.pop();
            loadQuestionnaireCustomFormSingle(lastElementAdded.id).then((returndata) => {
                json.pages = [...json.pages.filter(page => page.elements.length > 0)];
                const isSectionAlreadyInForm = json.pages.filter(page => page.id === lastElementAdded.id).length > 0;

                if (!isSectionAlreadyInForm) {
                    var element = returndata.pop();
                    json.pages.push({
                        id: element.id,
                        name: element.title,
                        title: element.title,
                        elements: element.elements,
                    });
                } else {
                    //TODO: Notify Error
                }
                sender.changeText(JSON.stringify(json), true);
            });

        });
    }, []);

    creator.onElementAllowOperations.add(function (_, options) {
        var obj = options.obj;
        if (!obj || !obj.page) return;

        options.allowEdit = false;
        options.allowDragging = false;
        options.allowDelete = false;
        //disable change require
        options.allowChangeRequired = false;
        //disable change show/hide title
        options.allowShowHideTitle = false;
        options.allowCopy = false;
        //disable changing type
        options.allowChangeType = false;
    });

    creator.saveSurveyFunc = (saveNo: any, callback: any) => {
        saveQuestionnaire(creator).then(() => {
            onClose(false);
        });
        callback(saveNo, true);
    };

    return <SurveyCreatorComponent creator={creator} />;
};
