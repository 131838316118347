// Vendors
import { Box, Grid } from '@mui/material';

// Component
import RxBlock1 from '../RxFrameworkComponents/RxBlock1';
import RxBlock2 from '../RxFrameworkComponents/RxBlock2';
import RxFinalReview from './RxFinalReview';
import RxOverview from './RxOverview';

// Store

// Entities

// API
import { observer } from 'mobx-react';

const RxStepSix = () => {

    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={12} sm={9}>
                <RxBlock1
                    RxBlock1Content={<RxFinalReview />}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <RxBlock2
                    RxBlock2Content={<RxOverview />}
                />
            </Grid>
        </Grid>
    );
};
export default observer(RxStepSix);