import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

const CompletedIcon = () => {
    const theme = useTheme();

    return (
        <Avatar
            sx={{
                mr: 0.5,
                width: 24,
                height: 24,
                background: `${theme.colors.success.main}`,
                color: `${theme.palette.success.contrastText}`
            }}
        >
            <CheckTwoToneIcon
                sx={{
                    fontSize: `${theme.typography.pxToRem(16)}`
                }}
            />
        </Avatar>
    );
};

export default CompletedIcon;
