// Vendors
import React, { FC, useState, useEffect } from 'react';
import { Grid, MenuItem, TextField, Typography, Box } from '@mui/material';

// Components
import Text from '../../../components/UI/Text';

// Entities
import { ClaimStatus, ClaimType } from "../../../entities/Enums";

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import moment from "moment";

// Styling
import '../../../scss/_PatientEdit.scss';
import '../../../scss/_global.scss';


interface IGIProps {
    claimViewData: any;
    // patient: Patient;
}

export const ClaimHeaderDetails: FC<IGIProps> = observer(({ claimViewData }) => {
    const [claimStatusId, setClaimStatusId] = useState(claimViewData.claimStatusId ? claimViewData.claimStatusId : 0);
    const [prodStatusId, setProdStatusId] = useState(claimViewData.productionStatusId ? claimViewData.productionStatusId : 0);

    const store = useMainStoreContext().reworkClaimStore;

    useEffect(() => {
        store.setClaimStatus(claimStatusId);
        store.loadClaimStatusValues();
        store.loadProductionStatusValues();
        store.loadClaimReviewDocumentTypes();
        store.loadReworkRequestValues(claimViewData);
        store.loadReworkDocuments(claimViewData.id);
    }, []);

    return (
        <Grid container height={'100%'}>
            <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'}>
                <Grid container>
                    <Grid item xs={12} paddingBottom={1}>
                        <Typography variant='h2' color={'white'} textAlign={'center'}>
                            Rework Overview
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingBottom={1} container direction={'column'}>
                        <Typography variant='h5' color={'white'} textAlign={'center'}>
                            Claim Submitted: <b>{claimViewData.dateSubmitted && moment(claimViewData.dateSubmitted).format('MM/DD/yyyy')}</b>
                        </Typography>
                        <Typography variant='h5' color={'white'} textAlign={'center'}>
                            Claim ID: <b>{claimViewData.rxClaimId}</b>
                        </Typography>
                    </Grid>
                    {(store.isAISAdminManager || store.isAISSupervisor || store.isAISAdmin) && <Grid item xs={6} paddingRight={1}>
                        <Typography variant="h5" color={'white'}>
                            Overall Status:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'claimStatus'}
                            select
                            defaultValue="0"
                            value={store.claimStatus}
                            onChange={event => {
                                const statusId = Number(event.target.value);
                                store.setClaimStatus(statusId);
                                store.handleClaimStatusChange(claimViewData, statusId)
                            }}
                        >
                            {
                                store.claimStatusValues.map((cs) =>
                                    (<MenuItem key={cs.id} value={cs.id} disabled={cs.id === ClaimStatus.Approved ? store.claim.claimType === ClaimType.Pending : false}>{cs.name}</MenuItem>))
                            }
                        </TextField>
                    </Grid>}
                    <Grid item xs={6} paddingLeft={1}>
                        <Typography variant="h5" color={'white'}>
                            Production Status:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'claimStatus'}
                            select
                            defaultValue="0"
                            value={prodStatusId}
                            onChange={event => {
                                const statusId = Number(event.target.value);
                                setProdStatusId(statusId);
                                store.handleProductionStatusChange(claimViewData, statusId)
                            }}
                        >
                            {
                                store.productionStatusValues.map((ps) =>
                                    (<MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>))
                            }
                        </TextField>
                    </Grid>
                    {/* <Grid item xs={8} pl={2}>
                        <Typography variant="h5" color={'white'} textAlign={"left"}>
                            Add-Ons Requested:
                        </Typography>

                        <Box height={'50px'} sx={{ background: 'white' }} border={2} borderRadius={1} borderColor={'white'}>
                            <Grid container direction="row" >
                                {
                                    _.map(submission.addOns, ((item) => {
                                        if (item.addOnType === 105) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid> <RadiologySvg width={50} height={50} className={'svgGrid status-' + item.statusId}></RadiologySvg></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 106) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid><img style={{ width: "50px", height: "50px" }} src={SassouniImg} className={'svgGrid status-' + item.statusId} /></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 108) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid> <Print3DSvg width={50} height={50} className={'svgGrid status-' + item.statusId}></Print3DSvg></Grid>
                                                </Grid>
                                            );
                                        }

                                        if (item.addOnType === 107) {
                                            return (
                                                <Grid sx={{ margin: "0px 5px" }}>
                                                    <Grid><FrontalAnalysis width={50} height={50} className={'svgGrid status-' + item.statusId}></FrontalAnalysis></Grid>
                                                </Grid>
                                            );
                                        }
                                    }))
                                }
                            </Grid>
                        </Box>

                    </Grid> */}
                </Grid>
            </Box>
        </Grid>
    )
});
