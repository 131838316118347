import React, { Fragment, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    ListItemButton,
    useTheme,
    ListItemText,
    Grid,
    alpha,
    Divider,
    List,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import { Masonry } from '@mui/lab';
import { airRecommendedRecords, constructionBite101, constructionBiteCare } from './Helper/documents';

// Styled Components
const ListItemButtonWrapper = styled(ListItemButton)(({ theme }) => `
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};
    transform: scale(1);
    background: ${theme.colors.alpha.white[100]};
    position: relative;
    z-index: 5;
    padding: ${theme.spacing(1.5)};

    &:hover {
        border-radius: ${theme.general.borderRadius};
        background: ${theme.colors.alpha.white[100]};
        z-index: 6;
        box-shadow: 
            0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
            0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
            0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
            0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
        transform: scale(1.05);
    }
`);

const BoxComposedContent = styled(Box)(({ theme }) => `
    position: relative;
    z-index: 7;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
`);

// Constants
const allProviderTypes = [
    "VIP",
    "Lifeline",
    "Guided Growth and Development",
    "C.A.R.E",
    "Airway Health Solutions",
    "Sleep Well Journey",
    "Noum",
    "Lincare",
    "Certified Legacy Provider",
    "Colorado Sleep Institute",
    "Clinical Advisor"
];

// Document Groups
const exportCBCT = [
    { id: 1, type: "link", name: "Acteon", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Acteon.pdf" },
    { id: 2, type: "link", name: "Anatomage_Invivo_TxStudio", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Anatomage_Invivo_TxStudio.pdf" },
    { id: 3, type: "link", name: "Carestream CS3D", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Carestream CS3D.pdf" },
    { id: 4, type: "link", name: "Genoray (Triana)", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Genoray (Triana).pdf" },
    { id: 5, type: "link", name: "HDX WILL", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from HDX WILL.pdf" },
    { id: 6, type: "link", name: "i-CAT Vision", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from i-CAT Vision.pdf" },
    { id: 7, type: "link", name: "J.Morita", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from J.Moritapdf.pdf" },
    { id: 8, type: "link", name: "KaVo", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from KaVo.pdf" },
    { id: 9, type: "link", name: "Midmark 3DV", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Midmark 3DV.pdf" },
    { id: 10, type: "link", name: "NewTom NNT", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from NewTom_NNT.pdf" },
    { id: 11, type: "link", name: "Owandy", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Owandy.pdf" },
    { id: 12, type: "link", name: "Pancorp", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Pancorp.pdf" },
    { id: 13, type: "link", name: "Planmeca Romexis", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Planmeca Romexis.pdf" },
    { id: 14, type: "link", name: "PreXion", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from PreXion.pdf" },
    { id: 15, type: "link", name: "Rayscan", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Rayscan.pdf" },
    { id: 16, type: "link", name: "Sirona", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Sirona.pdf" },
    { id: 17, type: "link", name: "Vatech", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/Exporting CBCT Scans/2023.8 - How to Save a CBCT scan from Vatech.pdf" },
]

const exportImpressions = [
    { id: 21, type: "link", name: "3M", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/Exporting Digital Models/2023.7 - Exporting Digital Models_3M.pdf" },
    { id: 22, type: "link", name: "3Shape Trios", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/Exporting Digital Models/2023.7 - Exporting Digital Models_3Shape Trios.pdf" },
    { id: 23, type: "link", name: "Carestream", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/Exporting Digital Models/2023.7 - Exporting Digital Models_Carestream.pdf" },
    { id: 24, type: "link", name: "Cerec / Sirona", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/Exporting Digital Models/2023.7 - Exporting Digital Models_Cerec_Sirona.pdf" },
    { id: 25, type: "link", name: "iTero", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/Exporting Digital Models/2023.7 - Exporting Digital Models_iTero.pdf" },
    { id: 26, type: "link", name: "Planmeca", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/Exporting Digital Models/2023.7 - Exporting Digital Models_Planmeca.pdf" },
]

const documentGroups = [
    {
        name: "Impressions",
        documents: [
            { id: 31, providerAccess: allProviderTypes, type: "link", name: "Model/Impression Requirements", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Models/2024.4_FINALModel Requirements.pdf" },
            { id: 32, providerAccess: allProviderTypes, type: "link", name: "Construction Bites 101", link: constructionBite101 },
            { id: 33, providerAccess: ["VIP", "Lifeline", "Certified Legacy Provider", "Noum", "Colorado Sleep Institute", "Clinical Advisor"], type: "link", name: "C.A.R.E. Construction Bites: Video Tutorial", link: constructionBiteCare },
        ]
    },
    {
        name: "Exporting Digital Impressions",
        documents: [
            {
                id: 42, // Use a unique id to avoid conflicts
                type: "popup",
                name: "Click HERE to select your Scanner type and learn how to export your digital impressions.",
                link: "",
                description: (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <List>
                                {exportImpressions.map(page => ( // Use exportImpressions here
                                    <ListItemButtonWrapper key={page.id} onClick={() => window.open(page.link, '_blank')}>
                                        <ListItemText primary={page.name} />
                                    </ListItemButtonWrapper>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                )
            }
        ]
    },
    {
        name: "CBCT Scans",
        documents: [
            { id: 50, providerAccess: allProviderTypes, type: "link", name: "CBCT Best Practices", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/2023.6 CBCT Best Practices.pdf" },
            { id: 51, providerAccess: allProviderTypes, type: "link", name: "CBCT Required Landmarks", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/FINAL_CBCT Landmark Requirements 05.2024.pdf" },
            { id: 52, providerAccess: allProviderTypes, type: "link", name: "CBCT Stitching Checklist", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/2021.7 - Stitching Checklist.pdf" },
            { id: 53, providerAccess: allProviderTypes, type: "link", name: "CBCT: Scanning Adults", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/2022.7 - Scanning Patients - CBCT.mp4" },
            { id: 54, providerAccess: allProviderTypes, type: "link", name: "CBCT: Scanning Children", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/CBCT/2022.7 - Scanning Children - CBCT.mp4" },
        ]
    },
    {
            name: "Exporting CBCT Scans",
            documents: [
                {
                    id: 61,
                    type: "popup",
                    name: "Click HERE to select your CBCT Machine and learn how to export your scans properly",
                    link: "",
                    description: (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <List>
                                    {exportCBCT.map(page => (
                                        <ListItemButtonWrapper key={page.id} onClick={() => window.open(page.link, '_blank')}>
                                            <ListItemText primary={page.name} />
                                        </ListItemButtonWrapper>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    )
                }
            ]
        },
        {
            name: "Photos",
            documents: [
                { id: 70, providerAccess: allProviderTypes, type: "link", name: "Photo Requirements", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Photos/2022.1 - Patient Photo Requirements.jpeg" },
                { id: 71, providerAccess: allProviderTypes, type: "link", name: "Taking 2D Records", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Record Taking/Photos/2022.7 - Taking 2D Records Video Overview.mp4" },
            ]
        },
        {
            name: "Recommended Records",
            documents: [
                { id: 80, providerAccess: allProviderTypes, type: "link", name: "Recommend Records for Airway Intelligence Reports", link: airRecommendedRecords, },
            ]
        },
];

// Component
function PatientRecordsContent({ providerTypes, isProvider }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleDocumentClick = (doc) => {
        if (doc.type === 'link') {
            window.open(doc.link, '_blank', 'noopener noreferrer');
        } else if (doc.type === 'popup') {
            setDialogContent(doc.description);
            setOpenDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid container spacing={2}>
            {documentGroups.map((group, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            overflow: 'visible',
                            backgroundColor: theme.palette.background.default,
                            border: `1px solid ${theme.palette.divider}`,
                            boxShadow: `
                                0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                                0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                                0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                                0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)}
                            `,
                            borderRadius: `${theme.shape.borderRadius}px`,
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '75px',
                                borderRadius: `${theme.shape.borderRadius}px`,
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.contrastText,
                                padding: theme.spacing(2),
                            }}
                        >
                            <Typography variant="h5">{group.name}</Typography>
                        </Box>
                        <List disablePadding sx={{ flexGrow: 1 }}>
                            {group.documents.map((doc) => (
                                <Fragment key={doc.id}>
                                    <Divider />
                                    <ListItemButtonWrapper
                                        sx={{
                                            display: { xs: 'block', sm: 'flex' },
                                            py: 2,
                                            px: 2.5,
                                        }}
                                        onClick={() => handleDocumentClick(doc)}
                                    >
                                        <ListItemText
                                            disableTypography
                                            primary={
                                                <Typography color="text.primary" variant="h5">
                                                    {doc.name}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButtonWrapper>
                                </Fragment>
                            ))}
                        </List>
                    </Card>
                </Grid>
            ))}
            <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                            <DialogTitle>
                                <Typography variant="h5">Exporting Instructions:</Typography>
                            </DialogTitle>
                            <DialogContent>{dialogContent}</DialogContent>
                        </Dialog>
        </Grid>
    );
}

export default PatientRecordsContent;
