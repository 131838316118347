import * as React from "react";
import LineItem from "./LineItemComponent";
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import { observer } from "mobx-react";
import { Grid, Card, Typography, List, ListItem, CardActions, CardContent, Button, Box, Divider, IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";

function Cart() {
    const { cart, setCartModalOpen, isCartModalOpen, getCheckoutUrl, openCheckoutUrl ,urlMultipass } = useMainStoreContext().shopifyStore;

    return (
        <Grid container sx={{ minWidth: '400px' }}>
            <Card sx={{ minWidth: '400px' }}>
                <CardContent>
                    <Box display='flex'>
                        <Typography variant="h4" component="span" sx={{ flexGrow: '1' }}>
                            Your cart
                        </Typography>
                        <IconButton hidden={!isCartModalOpen} onClick={() => setCartModalOpen(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Divider />
                    <List className="Cart__line-items">
                        {cart?.lineItems.length === 0 && (
                            <ListItem className="Cart__empty-text">
                                Your cart is currently empty.
                            </ListItem>)
                        }
                        {cart?.lineItems.map((lineItem) => {
                            return (
                                <LineItem
                                    key={lineItem.id.toString()}
                                    lineItem={lineItem}
                                />
                            );
                        })}
                    </List>
                </CardContent>
                <CardActions>
                    <Box sx={{ width: '100%', textAlign: 'right', flexDirection: 'column', justifyContent: 'right', display: 'flex' }}>
                        <Divider />
                        <Typography variant="h6" component="span">
                            Total: $ {parseFloat(cart?.subtotalPrice.amount).toFixed(2)}
                        </Typography>
                        {isCartModalOpen &&
                            <Button
                                variant="contained"
                                disabled={cart?.lineItems.length === 0}
                                onClick={openCheckoutUrl}>
                                Checkout
                            </Button>
                        }
                    </Box>
                </CardActions>
            </Card>
        </Grid>
    );
}
export default observer(Cart);