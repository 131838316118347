// Vendors
import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { RxAppliances } from '../../../entities/Enums';

// API
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { AvialableLabsForFamily } from '../../../entities/const';
import { filter } from 'lodash';
import Laboratory from '../../../entities/Laboratory';
import { GlobalUtils } from '../../../api/GlobalUtils';
import { DictionaryListItem } from '../../../models/state_models/dictionarylistitem';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';
import jwt_decode from 'jwt-decode';

const LabSelection = () => {
    const { setShouldCancelOrder, selectedLab, selectedAppliance, labs, isUpdating, fabricationTime, labsImages,
        setSelectedLab, setFabricationTime, setSelectedLabLogo, productionRushProduct, prescription, currentPatient
    } = usePrescriptionStore().prescriptionStore;
    const providerStore = useMainStoreContext().providerStore;
    const [labsFiltered, setLabsFiltered] = useState<Laboratory[]>([]);
    const [fabricationTimes, setFabricationTimes] = useState<DictionaryListItem[]>([]);
    const [loadedValues, setLoadedValues] = useState(false);

    useMemo(async () => {
        setFabricationTimes(await GlobalUtils.getFabricationTimeTypes());
        setLoadedValues(true);
    }, []);


    const handleLabChange = (event: any) => {
        const lab = labs.find((lab) => lab.id === event.target.value);
        setSelectedLab(lab);
        setSelectedLabLogo(labsImages.find(l => l.name === lab?.name)?.logo ?? '');
    };

    const handleFabricationTimeChange = (event, newFabTime) => {
        // Avoid unselecting all buttons
        if (newFabTime !== null) {
            setFabricationTime(newFabTime);
        }

        if (isUpdating) {
            setShouldCancelOrder(true);
        }
    };

    const isLabSelectionDisabled = () => {
        return !(
            selectedAppliance.name.trim() === RxAppliances.DNA ||
            selectedAppliance.name.trim() === RxAppliances.MRNA ||
            selectedAppliance.name.trim() === RxAppliances.MMRNA
        );
    };

    const isLincare = () => {
        return (
            selectedAppliance.name.trim() === RxAppliances.DNA ||
            selectedAppliance.name.trim() === RxAppliances.MRNA ||
            selectedAppliance.name.trim() === RxAppliances.MMRNA
        );
    };

    useEffect(() => {
        if (selectedAppliance && selectedAppliance.id > 0) {
            var filters = AvialableLabsForFamily.filter(item => item.applaianceFamilyId === selectedAppliance.id);
            var matchedLabs = labs.filter(lab => filters.some(filteredLab => filteredLab.LabId === lab.id));
            if(isLincare()){
                var token = sessionStorage.getItem('token');
                if (token) {
                    var tokenData: { roles; providerId } = jwt_decode(token);
                    providerStore.ProviderID = tokenData.providerId;
                    providerStore.getProviderDetails().then(() => {
                        if(providerStore.providerData){
                            if(providerStore.providerData.addresses.filter(x=> x.addressTypeId === 921 && x.isActive === true)?.[0]?.country.toLowerCase() === 'ca'){
                                matchedLabs = labs.filter(x => x.name.toLowerCase().includes('aurum'));
                            }
                        }
                        setLabsFiltered(matchedLabs);
                    });                
                }
            }else{
                setLabsFiltered(matchedLabs);
            }
            
        }
    }, [selectedAppliance, labs])

   
    useEffect(() => {

        var fabricationTimeFind = fabricationTimes.find(f => f.id === prescription.fabricationTime);
        if (fabricationTimeFind) {
            setFabricationTime(fabricationTimeFind.name);
        }

    }, [loadedValues])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h2">Review Lab Selection and Fabrication Time</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    Lab Selection
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    id="labSelection"
                    label="Lab"
                    fullWidth
                    disabled={isLabSelectionDisabled()}
                    value={selectedLab?.id !== -1 ? selectedLab?.id : null}
                    onChange={handleLabChange}
                    select>
                    {labsFiltered.map((lab) => (
                        <MenuItem key={lab.id} value={lab.id}>
                            {lab.name}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} hidden={selectedLab == null}>
                <Typography>

                    The Vivos certified lab you have selected has a total turnaround time of approximately&nbsp;
                    <b><u>{selectedLab ? (selectedLab.fabricationTimeStandard ? selectedLab.fabricationTimeStandard : 0) : 0} calendar days for standard production,
                        and {selectedLab ? (selectedLab.fabricationTimeRush ? selectedLab.fabricationTimeRush : 0) : 0} calendar days for rushed production.</u></b>&nbsp;
                    Please note that these turnaround times can vary from provider and case and is based on the assumption that the lab will receive a complete set of records in a timely manner in order to meet these turnaround times.&nbsp;
                    If you have any questions about our turnaround times, please feel free to reach out to us at rx@vivoslife.com or 720-738-3663.&nbsp;

                </Typography>
            </Grid>
            <Grid item xs={12} hidden={selectedLab == null}>
                <Typography variant="h6">
                    Fabrication Time
                </Typography>
            </Grid>
            <Grid item xs={4} hidden={selectedLab == null}>
                <Grid container spacing={1} paddingX={2}>
                    <Grid item>
                        <Button
                            variant={fabricationTime === "Standard" ? 'contained' : 'outlined'}
                            onClick={(event) => { handleFabricationTimeChange(event, "Standard") }}
                            fullWidth>
                            Standard
                        </Button>
                    </Grid>
                    <Grid item>
                        {/* NOTE: IMPLEMENT DOES LAB OFFER RUSH */}
                        <Button
                            variant={fabricationTime === "Rush" ? 'contained' : 'outlined'}
                            onClick={(event) => { handleFabricationTimeChange(event, "Rush") }}
                            fullWidth>
                            Rush
                        </Button>
                    </Grid>
                    <Grid item>
                        {/* NOTE: IMPLEMENT DOES LAB OFFER RUSH */}
                        Order can be rushed for an additional fee of {productionRushProduct?.variants[0]?.price?.amount}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default observer(LabSelection);

<Grid item xs={3}>

</Grid>