import React from 'react';

const DateFormatUS = ({ date }) => {
  // Convert the string to a Date object
  const parsedDate = new Date(date);

  // Format the date in the US date format (MM/DD/YYYY)
  const formattedDate = `${parsedDate.getMonth() + 1}/${parsedDate.getDate()}/${parsedDate.getFullYear()}`;

  return <span>{formattedDate}</span>;
};

export default DateFormatUS;