// Vendors
import React, { useMemo, useState } from 'react';
import {
    Button,
    Divider,
    Grid,
    Typography,
    Box,
    styled,
    IconButton,
    CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';

// Components
import UploadDocDragDrop from '../UploadDragAndDrop/UploadDocDragDrop';
import DataGrid from '../DataGrid/DataGrid';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { useCreatePatientMainStoreContext } from '../../stores/Patient/Create/CreatePatientMainStore';

// Entities
import DictionaryListItem from '../../entities/DictionaryListItem';
import PatientFile from '../../entities/PatientFIle';
import CustomForm from '../../models/state_models/customForm';

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from '../../api/GlobalUtils';
import moment from 'moment';

interface IProps {
    isIntake: boolean;
    visit: any;
    isPatientFormStep: boolean;
    patient?: any;
}

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
);

const HelpIconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        background: transparent;
        transition: ${theme.transitions.create(['all'])};
        color: ${theme.colors.warning.light};
        border-radius: 50px;
    
        &:hover {
          background: transparent;
          color: ${theme.colors.warning.dark};
        }
    `
);

const CardContentWrapper = styled(Box)(
    ({ theme }) => `
      background: ${theme.colors.alpha.white[100]};
      border-radius: ${theme.general.borderRadius};
    `
);

const Documents: React.FC<IProps> = ({ isIntake, visit, isPatientFormStep, patient }) => {

    /* const { patient, patientDocuments } = useMainStoreContext().createPatientStore; */
    const { patientFiles, patientUploadFiles, setPatientUploadFiles, deleteFile, setDocumentFiles, documentsFiles, setPatientFiles } = useMainStoreContext().visitRecordStore;
    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
    const [fileName, setFileName] = useState<string>('');
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [formsSelected, setFormsSelected] = React.useState<CustomForm[]>([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { visitStore, patientStore, customFormStore } = useCreatePatientMainStoreContext();

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            setReportTypes(prevState => [...prevState, item].filter(x => x.listItemKey !== "SLEEPTEST"));
            setIsLoading(false);
        });
        // customFormStore.getCustomFormType(isPatientFormStep, isIntake).then((forms) => {
        //     if (forms) {
        //         setFormsSelected(forms);
        //         setIsLoading(false);
        //     }

        // });
    }, []);

    React.useEffect(() => {
        setDocumentFiles(patientFiles.filter(x => x.fileDescriptorId !== 191));
    }
        , [patientFiles]);

    const columns = [
        {
            field: 'id',
            headerName: 'Document Id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.id}
                    </Grid>
                )
            }

        },
        {
            field: 'displayName',
            headerName: 'File Name',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.displayName}
                    </Grid>
                )
            }

        },
        {
            field: 'fileDescriptor',
            headerName: 'File Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.fileDescriptor.name}
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.createdOn ? moment(params.row.createdOn).format('MM/DD/yyyy') : ""}
                    </Grid>
                )
            }

        },
        {
            field: 'action',
            headerName: 'Actions',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button onClick={() => handleDownloadFile(params.row.fileUrl)}>
                            <DownloadIcon />
                        </Button>
                        <Button onClick={() => handleDeleteFile(params.row.id)}>
                            <DeleteIcon />
                        </Button>
                    </Grid>
                )
            }
        },
    ]

    const handleUpload = data => {
        let patientFileToSet: PatientFile = {
            id: 0,
            patientId: patientStore.patient.id,
            visitId: visitStore.visit.id,
            fileDescriptorId: data.reportId,
            fileUrl: data.fileName,
            displayName: data.reportType,
            isActive: true,
        } as PatientFile;

        let currentPatientFileArray = patientUploadFiles.filter(x => x.displayName !== data.reportType);
        currentPatientFileArray.push(patientFileToSet);
        setFileName(data.reportType);
        setPatientUploadFiles(currentPatientFileArray);
    };

    const handleDownloadFile = (url) => {
        window.open(url, '_blank');
    }

    const handleDeleteFile = (fileId) => {
        deleteFile(fileId, visit?.id).then(resp => {
            setPatientFiles(resp);
        });
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={isIntake ? "100%" : "100vh"}>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                    <Grid container direction="row" justifyContent="center">
                        <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>
                        <CircularProgress sx={{ width: "90%", height: "90%" }} />
                    </Grid>
                </Box>
            ) : (
                // <Box width="100%">
                <Grid container spacing={2} justifyContent="center">
                    {isIntake
                        ?
                        <Grid item xs={12} padding={2}>
                            <Typography variant="h2" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                                Documents
                            </Typography>
                            <Typography variant="h4" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                                Upload any documents associated to the patients visit. Refer to the document guidelines if necessary.
                            </Typography>
                        </Grid>
                        :
                        <Grid item xs={12} padding={2}>
                            <Typography variant="h3" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                                Documents
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={isIntake ? 8 : 12}>
                        <UploadDocDragDrop parentCallback={handleUpload} extFiles={patientUploadFiles} typeOptions={reportTypes} />
                    </Grid>
                    {isIntake &&
                        <Grid item xs={4}>
                            <Grid container padding={5} direction={"column"}>
                                <Typography variant="h4">
                                    Vivos document guidelines for the most complete records.
                                </Typography>
                                <Grid container padding={1} direction={"column"}>
                                    <Typography variant="body1">
                                        The following documents are required:
                                    </Typography>
                                    <Grid paddingLeft={2}>
                                        <Typography variant="h5">Clinical Exam (Head & Neck)</Typography>
                                        <Typography variant="h5">Patient History</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container padding={1} direction={"column"}>
                                    <Typography variant="body1">
                                        The following documents are optional:
                                    </Typography>
                                    <Grid paddingLeft={2}>
                                        <Typography variant="h5">Sleep & Breathing Questionnaire</Typography>
                                        <Typography variant="h5">Epworth</Typography>
                                        <Typography variant="h5">Hit-6</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    }
                    {!isIntake && (
                        <Grid item xs={12}>
                            <Divider />
                            <Typography variant="h6" paddingLeft={3} paddingTop={1}>Uploaded Files</Typography>
                            <Box padding={4}>
                                <DataGrid
                                    columns={columns}
                                    data={documentsFiles}
                                    loading={hasLoaded}
                                    size={"small"}
                                    pageSize={10}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
                // </Box>
            )}
        </Box>
    );
};

export default observer(Documents);
