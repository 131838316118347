import { observable } from 'mobx';
import Address from './address';
import Patient from './patient';
import LegalGuardian from './legalGuardian';

class Person {
  @observable id: number = 0;
  @observable firstName: string = '';
  @observable middleName: string | null = null;
  @observable lastName: string = '';
  @observable dateOfBirth: Date | undefined;
  @observable ethnicityId: number  = 0;
  @observable genderId: number  = 0;
  @observable emailAddress: string = '';
  @observable secondaryEmailAddress: string = '';
  @observable primaryPhone: string = '';
  @observable altPhone: string = '';
  //@observable personTypeId: number | null = null;
  //@observable extId: string = '';
  //@observable schedulerLink: string = '';
  //@observable migrationId: number | null = null;
  @observable createdOn: Date = new Date();
  @observable createdBy: number = 0;
  @observable modifiedOn: Date | null = new Date();
  @observable modifiedBy: number | null = 0;
  @observable isActive: boolean = true;

  @observable patients: Patient[] = [];
  @observable addresses: Address[] = [];
  @observable legalGuardians: LegalGuardian[] = [];
}

export default Person;
