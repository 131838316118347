import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import PriorityHighTwoToneIcon from '@mui/icons-material/PriorityHighTwoTone';

const RequiredIcon = () => {
    const theme = useTheme();

    return (
        <Avatar
            sx={{
                mr: 0.5,
                width: 24,
                height: 24,
                background: `${theme.colors.warning.main}`,
                color: `${theme.palette.warning.contrastText}`
            }}
        >
            <PriorityHighTwoToneIcon
                sx={{
                    fontSize: `${theme.typography.pxToRem(16)}`
                }}
            />
        </Avatar>
    );
};

export default RequiredIcon;
