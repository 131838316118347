// Vendors
import React from 'react';
import {
    Typography,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@mui/material';

// API
import { observer } from 'mobx-react-lite';

// Components
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { ConstructionTypes } from '../../../entities/Enums';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

interface ConstructionTablesProps {
    prescription: any;
}

export const CareConstructionTable: React.FC<ConstructionTablesProps> = observer(({ prescription }) => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    return (
        <Grid>
            {(
                prescription.appliance.applianceType === ConstructionTypes["Vivos Dual DNA System"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos Single Arch DNA Upper"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mRNA System"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mRNA Single Arch Upper"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mmRNA System"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mmRNA Single Arch Upper"]
            ) &&
                <Grid>
                    <Grid paddingBottom={1}>
                        <Typography variant="h4">
                            Upper Arch Construction
                        </Typography>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': { padding: '4px 8px' },
                        }} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Base</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.upperBaseValues.find(x => x.id === prescription.appliance.appliancesystemUppersystemBaseId)?.name ?? 'No'}</TableCell>
                                    <TableCell>Screw</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        {(prescriptionStore.upperScrewValues.find(x => x.id === prescription.appliance.applianceSystemUpperSystemScrewId)?.name ?? '').includes('Transverse') && (
                                            <Typography variant="h5">
                                                Transverse
                                            </Typography>
                                        )}
                                        {(prescriptionStore.upperScrewValues.find(x => x.id === prescription.appliance.applianceSystemUpperSystemScrewId)?.name ?? '').includes('Wireframe') && (
                                            <Typography variant="h5">
                                                Wireframe
                                            </Typography>
                                        )}
                                        {(prescriptionStore.upperScrewValues.find(x => x.id === prescription.appliance.applianceSystemUpperSystemScrewId)?.name ?? '').includes('Standard') && (
                                            <Typography variant="h5">
                                                Y Split Standard (
                                                {prescription.appliance.dentalPieces
                                                    .filter(
                                                        x =>
                                                            x.partId === prescriptionStore.dentalPartValues.find(y => y.partName === 'Y Split Standard')?.id &&
                                                            x.arch === 'Upper'
                                                    )
                                                    ?.sort((a, b) => a.toothBegin - b.toothBegin)
                                                    .map(dentalPiece => dentalPiece.toothBegin)
                                                    .join(', ')}
                                                )
                                            </Typography>
                                        )}
                                        {(prescriptionStore.upperScrewValues.find(x => x.id === prescription.appliance.applianceSystemUpperSystemScrewId)?.name ?? '').includes('Y Split Asymmetrical') && (
                                            <Typography variant="h5">
                                                Y Split Asymmetrical (
                                                {prescription.appliance.dentalPieces
                                                    .filter(
                                                        x =>
                                                            x.partId === prescriptionStore.dentalPartValues.find(y => y.partName === 'Y Split Asymmetrical')?.id &&
                                                            x.arch === 'Upper'
                                                    )
                                                    ?.sort((a, b) => a.toothBegin - b.toothBegin)
                                                    .map(dentalPiece => dentalPiece.toothBegin)
                                                    .join(', ')}
                                                )
                                            </Typography>
                                        )}
                                        {(prescriptionStore.upperScrewValues.find(x => x.id === prescription.appliance.applianceSystemUpperSystemScrewId)?.name ?? '').includes('Cruciform') && (
                                            <Typography variant="h5">
                                                Cruciform (
                                                {prescription.appliance.dentalPieces
                                                    .filter(
                                                        x =>
                                                            x.partId === prescriptionStore.dentalPartValues.find(y => y.partName === 'Cruciform')?.id &&
                                                            x.arch === 'Upper'
                                                    )
                                                    ?.sort((a, b) => a.toothBegin - b.toothBegin)
                                                    .map(dentalPiece => dentalPiece.toothBegin)
                                                    .join(', ')}
                                                )
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Adams Clasps</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Adams Clasp')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Ball Clasps Distal To</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Ball Clasp')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>C Clasps</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'C Clasp')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Arrow Clasps Distal To</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Arrow Clasp')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>3D Axial Springs</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === '3D Axial Springs')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>3D C-loop Axial Springs</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'C Loop 3D Axial Springs')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Occlusal Acrylic Coverage</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Occlusal Acrylic Coverage')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Eruption Friendly</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Eruption Friendly')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bow</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.upperBowValues.find(x => x.id === prescription.appliance.appliancesystemUppersystemBowId)?.name ?? 'No'}</TableCell>
                                    <TableCell>OSA Extension</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        {prescription.appliance.applianceSystemUpperSystemOsaExtensionBool
                                            ? `Yes (${prescription.appliance.applianceSystemUpperSystemOsaExtensionLengthInt} mm)`
                                            : 'No'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Elastic Hooks Vertical</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemElasticHooksVerticalBool ? 'Yes' : 'No'}</TableCell>
                                    {/* <TableCell>Elastic Hooks</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemElasticHooksVerticalBool ? 'Vertical' : prescription.appliance.applianceSystemUpperSystemElasticHooksClassIIBool ? 'Class II' : 'No'}</TableCell> */}
                                    <TableCell>Distal Stop</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Stops Distal')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Elastic Hooks Class II</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemElasticHooksClassIiBool ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>Mesial Stop</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Stops Mesial')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Facemask Hooks</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Facemask Hooks')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Occlusal Stop</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Stops Occlusal')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                {prescription.appliance.applianceType === ConstructionTypes["Vivos Single Arch DNA Upper"] && (
                                    <TableRow>
                                        <TableCell>Anterior Index Ramp</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemAnteriorIndexRampBool ? 'Yes' : 'No'}</TableCell>
                                        <TableCell>Anterior Bite Plane</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemAnteriorBitePlaneBool ? 'Yes' : 'No'}</TableCell>
                                    </TableRow>
                                )
                                }
                                {(prescription.appliance.applianceType === ConstructionTypes["Vivos mRNA System"] ||
                                    prescription.appliance.applianceType === ConstructionTypes["Vivos mRNA Single Arch Upper"]) && (
                                        <TableRow>
                                            <TableCell>Mandibular Advancement Option</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.mandibularAdvancementValues.find(x => x.id === prescription.appliance.appliancesystemUppersystemMandibularadvancementId)?.name ?? ''}</TableCell>
                                        </TableRow>
                                    )
                                }


                                {/* LEGACY FIELDS FOR HISTORICAL PURPOSES HIDE FOR GO LIVE */}
                                {/* <TableRow>
                                    <TableCell>Partial Occlusal Acrylic Coverage</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Partial Occlusal Acrylic Coverage')?.id && x.arch === 'Upper')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow> */}
                                {/* <TableRow>
                                    <TableCell>Comments</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemUpperSystemComments ?? ''}</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }

            {(
                prescription.appliance.applianceType === ConstructionTypes["Vivos Dual DNA System"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos Single Arch DNA Lower"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mRNA System"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mRNA Single Arch Lower"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mmRNA System"] ||
                prescription.appliance.applianceType === ConstructionTypes["Vivos mmRNA Single Arch Lower"]
            ) &&
                <Grid>
                    <Grid paddingY={1}>
                        <Typography variant="h4">
                            Lower Arch Construction
                        </Typography>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': { padding: '4px 8px' },
                        }} aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Base</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.lowerBaseValues.find(x => x.id === prescription.appliance.appliancesystemLowersystemBaseId)?.name ?? 'No'}</TableCell>
                                    <TableCell>Bow</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescriptionStore.lowerBowValues.find(x => x.id === prescription.appliance.appliancesystemLowersystemBowId)?.name ?? 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Adams Clasps</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Adams Clasp')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Ball Clasps Distal To</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Ball Clasp')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>C Clasps</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'C Clasp')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Arrow Clasps Distal To</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Arrow Clasp')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Elastic Hooks Vertical</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemLowerSystemElasticHooksVerticalBool ? 'Yes' : 'No'}</TableCell>
                                    <TableCell>Elastic Hooks Class II</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemLowerSystemElasticHooksClassIiBool ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Occlusal Acrylic Coverage</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Occlusal Acrylic Coverage')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Eruption Friendly on #s</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Eruption Friendly')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>3D Axial Springs</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === '3D Axial Springs')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>3D C-loop Axial Springs</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'C Loop 3D Axial Springs')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Distal Stop</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Stops Distal')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                    <TableCell>Mesial Stop</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Stops Mesial')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Occlusal Stop</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Stops Occlusal')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>


                                {/* LEGACY FIELDS FOR HISTORICAL PURPOSES HIDE FOR GO LIVE */}
                                {/* <TableRow>
                                    <TableCell>Partial Occlusal Acrylic Coverage</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.dentalPieces.filter(x => x.partId === prescriptionStore.dentalPartValues.find(x => x.partName === 'Partial Occlusal Acrylic Coverage')?.id && x.arch === 'Lower')?.sort((a, b) => a.toothBegin - b.toothBegin).map((dentalPiece) => { return dentalPiece.toothBegin }).toString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Comments</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>{prescription.appliance.applianceSystemLowerSystemComments ?? ''}</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Grid>
    );
});
