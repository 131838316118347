// Vendors
import { FC, useEffect, useState } from "react"
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    Box,
    IconButton,
    Link
} from "@mui/material"
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
// Components
import VivosApplianceImg from '../../../imgs/thumbnails/Vivos-Appliance-1_tn.jpg';
import SectionContainer from "../../../components/UI/Containers/SectionContainer";
import Text from "../../../components/UI/Text";

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from "mobx-react-lite"
import moment from "moment";
import ModalContainer from "../../../components/ModalContainer";
import { ViewRx } from "../../../components/ViewRx/ViewRx";
import _ from "lodash";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';




interface IGIProps {
    claim: any,
    claimData: any;
    patient: any;
    prescription: any;
}

export const ClaimInformation: FC<IGIProps> = observer(({ claim, claimData, patient, prescription }) => {
    const [uri, setUri] = useState('');
    const [toggleViewRxModal, setToggleViewRxModal] = useState(false);
    const store = useMainStoreContext().reworkClaimStore;
    const visitStore = useMainStoreContext().visitRecordStore;

    useEffect(() => {
        if (visitStore.id === 0) {
            visitStore.setVisitPatientId(patient.id);
            visitStore.setId(prescription.visitId);
            visitStore.loadVisit();
        }
        setUri(
            decodeURI(
                `/${claimData.vivosId}/${process.env.REACT_APP_PRESCRIPTIONS_CONTAINER}/${claimData.prescriptionId}/${process.env.REACT_APP_REWORKCLAIMS_CONTAINER}/${claimData.id}/`,
            ),
        );
    },
        [claimData.vivosId, claimData.prescriptionId, claimData.id, visitStore.currentVisit]);

    const onDownload = () => {
        const link = document.createElement('a');
        if (prescription.diagramUrl) {
            link.download = prescription.diagramUrl;
            link.href = prescription.diagramUrl;
            link.click();
        }
    };

    async function downloadAndCompressImpressions() {
        const zip = new JSZip();
        if (visitStore.currentVisit?.patientsImages[0]?.occlusion3dconstructedBite) {
            const response = await fetch(visitStore.currentVisit?.patientsImages[0]?.occlusion3dconstructedBite);
            const blob = await response.blob();
            const fileName = decodeURIComponent(visitStore.currentVisit?.patientsImages[0]?.occlusion3dconstructedBite.split('/').pop()?.split('?')[0] || 'file.stl');
            zip.file(fileName, blob);
        }
        if (visitStore.currentVisit?.patientsImages[0]?.occlusion3dlowerArch) {
            const response = await fetch(visitStore.currentVisit?.patientsImages[0]?.occlusion3dlowerArch);
            const blob = await response.blob();
            const fileName = decodeURIComponent(visitStore.currentVisit?.patientsImages[0]?.occlusion3dlowerArch.split('/').pop()?.split('?')[0] || 'file.stl');
            zip.file(fileName, blob);
        }
        if (visitStore.currentVisit?.patientsImages[0]?.occlusion3dupperArch) {
            const response = await fetch(visitStore.currentVisit?.patientsImages[0]?.occlusion3dupperArch);
            const blob = await response.blob();
            const fileName = decodeURIComponent(visitStore.currentVisit?.patientsImages[0]?.occlusion3dupperArch.split('/').pop()?.split('?')[0] || 'file.stl');
            zip.file(fileName, blob);
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, claimData.visitId + ' - ImpressionsFiles.zip');
    }


    const openViewRxModal = () => {
        setToggleViewRxModal(true);
    }

    const closeViewRxModal = () => {
        setToggleViewRxModal(false);
    }

    return (
        <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={6} lg={4}>
                <SectionContainer
                    sectionTitle={'Claim Details'}
                    cardContentHeight={'500px'}
                    sectionComponent={
                        <Grid container direction={'column'} spacing={1} padding={2}>
                            <List
                                sx={{
                                    py: 0
                                }}
                            >
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Submission Date</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claimData.dateSubmitted && moment(claimData.dateSubmitted).format('MM/DD/yyyy')}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Patient Name</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claimData.patientName}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Rx ID</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claim.rxId}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Appliance</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claimData.appliance}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Lab</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claimData.labName}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Date of Orignal RX Shipment</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claimData.prescriptionShippedDate ? moment(claimData.prescriptionShippedDate).format('MM/DD/yyyy') : ''}
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <Divider />
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Days since Original RX Shipment</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            <Text color={claimData.daysRXShipped > 365 ? "error" : "success"}>{(claimData.daysRXShipped === -1) ? '' : claimData.daysRXShipped}</Text>
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <ListItem
                                    sx={{
                                        alignItems: 'flex-start',
                                        p: 2
                                    }}
                                >
                                    <ListItemText
                                        primary={<Typography variant="subtitle2">Associated Visit Id</Typography>}
                                    />
                                    <Box alignSelf="center">
                                        <Typography variant="h4">
                                            {claimData.visitId}
                                        </Typography>
                                        <Button
                                            onClick={() => {
                                                downloadAndCompressImpressions();
                                            }}>
                                            <DownloadIcon />
                                        </Button>
                                    </Box>
                                </ListItem>
                            </List>
                            {/* <Grid container direction={'row'} className="claim-info">
                                <Grid xs={6}>
                                    <Grid>Request</Grid>
                                    {store.reworkRequestValues.map(rr =>
                                        <Grid className="claim-data">{rr.name}</Grid>)}
                                </Grid>
                            </Grid> */}
                        </Grid>
                    }
                />
            </Grid>
            <Grid item container xs={12} md={6} lg={8}>
                <Grid item xs={12}>
                    <Box height={'50%'} paddingBottom={1}>
                        <SectionContainer
                            sectionTitle={'Nature of the claim as indicated by the provider.'}
                            cardContentHeight={'175px'}
                            sectionComponent={
                                <Grid container direction={'column'} spacing={1} padding={2}>
                                    <List
                                        sx={{
                                            py: 0
                                        }}
                                    >
                                        <ListItem
                                            sx={{
                                                alignItems: 'flex-start',
                                                p: 2
                                            }}
                                        >
                                            <ListItemText
                                                primary={<Typography variant="body1">{claimData.providerNotes}</Typography>}
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>
                            }
                        />
                    </Box>
                    <Box height={'50%'} paddingTop={1}>
                        <SectionContainer
                            sectionTitle={'Claim Documentation'}
                            cardContentHeight={'175px'}
                            sectionComponent={
                                <Grid container direction={'row'} className="claim-info">
                                    <Grid container direction="column" className="claimuploadImgContainer">

                                        <Button
                                            onClick={() => {
                                                //store.downloadImages(claimData, uri);
                                                openViewRxModal();
                                            }}
                                        //disabled={store.isLoadingAllClaimDocuments}
                                        >
                                            <PictureAsPdfIcon style={{ width: "50px", height: "50px" }} />
                                        </Button>
                                        {/* {store.isLoadingAllClaimDocuments &&
                                            <CircularProgress
                                                sx={{
                                                    position: 'absolute',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        } */}
                                    </Grid>
                                    {prescription.diagramUrl && <Grid container direction="column" className="claimuploadImgContainer">
                                        <Button
                                            onClick={() => {
                                                onDownload();
                                            }}>
                                            <img alt="" style={{ width: "50px", height: "50px" }} src={VivosApplianceImg} />
                                        </Button>

                                    </Grid>}

                                    {store.processedImages.filter(x => !x.isManagerView).length > 0 ? (
                                        store.processedImages.filter(x => !x.isManagerView).map((f, index) => {
                                            const url = decodeURI(
                                                `${f.url}`
                                            );
                                            return (
                                                <Grid container direction="column" className="claimuploadImgContainer" key={index}>
                                                    {!_.isEmpty(f.url) && (
                                                        <>
                                                            <IconButton>
                                                                <Link href={url} target="_blank">
                                                                    <DownloadIcon />
                                                                </Link>
                                                            </IconButton>
                                                        </>
                                                    )}
                                                    Doctor Documentation
                                                </Grid>
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )}


                                </Grid>
                            }
                        />
                    </Box>
                    <ModalContainer
                        show={toggleViewRxModal}
                        title="VIEW PRESCRIPTION"
                        onClose={() => {
                            closeViewRxModal();
                        }}
                        onClick={() => {
                            closeViewRxModal();
                        }}
                    >
                        <ViewRx patient={patient} prescription={prescription} />
                    </ModalContainer>
                </Grid>
            </Grid>
        </Grid>
    )
})