// Vendors
import React, { FC, Fragment, useEffect, useState } from "react";
import { Button, Grid, Box, CardMedia, Typography } from "@mui/material";

// Components
import DataGrid from "../../components/DataGrid/DataGrid";
import ModalContainer from "../../components/ModalContainer";
import { PatientEdit } from "../../components/PatientProfile/PatientEdit";
import { TrackOrder } from "../../components/Orders/TrackOrder";
import PageHeader from "./components/PageHeader";
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import OrdersTable from "./components/OrdersTable";
import { RxStatusDetails } from "../../components/PatientProfile/PatientApplianceComponents/SubmitReworkModals/RxStatusDetails";
import Label from "../../components/UI/Label";

// Store
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// Entities
import ProviderPatient from "../../entities/ProviderPatient";
import { RxAppliances, ProductionStatus } from "../../entities/Enums";

// API
import { observer } from "mobx-react";
import moment from "moment";
import { subHours } from 'date-fns';


// Deprecate
import CssSelectField from "../../components/CssSelectField";
import { Appliance } from "../../models/appliance";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";



interface IGIProps {
}

export const Orders: FC<IGIProps> = observer(() => {
    const store = useMainStoreContext().orderStore;
    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const [selectedOrderStatus, setSelectedOrderStatus] = useState(0);
    const [selectedPatientRow, setSelectedPatientRow] = useState<any>();

    useEffect(() => {
        store.getOrders().then(() => {
            store.setCounters();
            store.loadOrderStatusValues();
        });
    }, [store.pendingProductionStatusCount]);

    const getProductionStatusLabel = (productionStatus: ProductionStatus): JSX.Element => {
        
        const map = {
            Pending: {
                text: 'Pending',
                color: 'warning'
            },
            Hold: {
                text: 'Hold',
                color: 'warning'
            },
            'In Fabrication': {
                text: 'In Fabrication',
                color: 'info'
            },
            Shipped: {
                text: 'Shipped',
                color: 'success'
            },
            Cancelled: {
                text: 'Cancelled',
                color: 'error'
            },
            Remake: {
                text: 'Remake',
                color: 'error'
            },
            Ready: {
                text: 'Ready',
                color: 'primary'
            },
            '': {
                text: '',
                color: 'inherit'
            },
            'default': {
                text: '',
                color: 'inherit'
            }            
        };

        const { text, color }: any = map[productionStatus];
        return (
            <Label color={color}>
                <b>{text}</b>
            </Label>
        );
    };

    const columns = [
        {
            field: 'orderCreatedOn',
            headerName: 'Created Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            sort: 'desc',
            renderCell: params => {
                return (
                    <Grid>{params.row.orderCreatedOn && moment(params.row.orderCreatedOn).format('MM/DD/yyyy')}</Grid>
                );
            },
        },
        {
            field: 'patientVivosId',
            headerName: 'Patient Vivos ID ',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {
                            <Button
                                id={'provider.dashboard.cell.toggleEditPatientModal.' + params.row.ID}
                                variant="text"
                                onClick={() => {
                                    store.getPatientData(params.row.patientId).then(() => {
                                        store.currentPatientId = params.row.patientId;
                                        store.toggleEditPatientModal();
                                    });
                                }}
                            >
                                {params.row.patientVivosId}
                            </Button>}
                    </Grid>
                );
            },
        },
        {
            field: 'patientName',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {params.row.patientName}
                    </Grid>
                );
            },
        },
        {
            field: 'rxId',
            headerName: 'Rx ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        {<Button
                            onClick={() => {
                                store.getPatientData(params.row.patientId).then(() => {
                                    setSelectedPatientRow(store.patientData);
                                    prescriptionStore.getPrescription(params.row.ordRxId).then(() => {
                                        store.currentPatientId = params.row.patientId;
                                        prescriptionStore.setShowRxStatusDetails(true);
                                    })
                                });
                            }
                            }>
                            {params.row.rxId}
                        </Button>}
                    </Grid>
                );
            },
        },
        {
            field: 'orderStatusName',
            headerName: 'Order Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Grid>
                        {params.row.orderStatusName}
                    </Grid>
                )
            }
        },
        {
            field: 'applianceTypeName',
            headerName: 'Appliance Type',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row }) => {
                const applianceLogo = prescriptionStore.applianceOptions.find(a => a.constructId === row.applianceTypeId)?.logo
                return (
                    <Box display="flex" alignItems="center" height={30}>
                        {applianceLogo ? (
                            <CardMedia
                                component="img"
                                src={applianceLogo}
                                style={{ maxHeight: '100%', width: 'auto' }}
                            />
                        ) : (
                            <Typography>{row.applianceTypeName}</Typography> // Fallback if no logo is found
                        )}
                    </Box>
                )
            },
        },
        {
            field: 'labName',
            headerName: 'Laboratory',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row }) => {
                const labLogo = prescriptionStore.labsImages.find(lab => lab.name === row.labName)?.logo;
                return (
                    <Box display="flex" alignItems="center" height={30}>
                        {labLogo ? (
                            <CardMedia
                                component="img"
                                src={labLogo}
                                style={{ maxHeight: '100%', width: 'auto' }}
                            />
                        ) : (
                            <Typography>{row.lab}</Typography> // Fallback if no logo is found
                        )}
                    </Box>
                )
            },
        },
        {
            field: 'productionStatusName',
            headerName: 'Production Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                const productionStatus = params.row.productionStatusName;                
                return (
                    <Grid container justifyContent={'center'}>
                        {params.row.productionStatusName}
                    </Grid>
                )
            
            },
        },



    ]

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>
            <Grid
                item xs={12} className="grid" id={'orders.grid' + store.userID}
            >
                
                <DataGrid 
                        pagination={true}
                        pageSize={store.pageSize}
                        rowCount={store.totalRows}
                        serverSidePagination={true}
                        columns={columns}
                        loading={store.isLoadingOrders}
                        data={store.ordersData}
                        onSortModelChange={(sortObj) => {
                            store.onSortChange(sortObj);
                            store.getOrders();
                          }}
                        onFilterChange={(filterObj) => {
                            store.onFilterChange(filterObj);
                            store.getOrders();
                          }}
                        onPageChange={(pageNumber) => {
                            store.currentPage = pageNumber;
                            store.getOrders();
                          }}
                />
            </Grid>
            <ModalContainer
                noScroll={false}
                mediumScreen={true}
                show={prescriptionStore.showRxStatusDetails}
                title={"Rx Details"}
                onClick={() => { }}
                onClose={() => {
                    prescriptionStore.setShowRxStatusDetails(false);
                }}>
                <RxStatusDetails
                    patient={selectedPatientRow as unknown as ProviderPatient}
                    prescription={prescriptionStore.prescription}
                />
            </ModalContainer>
            <ModalContainer
                show={store.toggleShowPatientEditModal}
                noScroll={true}
                title=""
                hideTitlebar={true}
                onClose={() => { }}
                onClick={store.toggleEditPatientModal.bind(this)}
            >
                {!store.isLoadingPatient && <PatientEdit
                    asProvider={true}
                    patientID={store.currentPatientId}
                    patient={store.patientData}
                    onClose={() => {
                        store.toggleEditPatientModal();
                    }}
                />}
            </ModalContainer>
        </Fragment>

        // <Fragment>
        //     <Grid xs={12} item container direction="row" className="page">
        //         {/* <LeftNav onToggle={store.toggleLeftNav} /> */}
        //         <Grid className={'content' + (store.isLeftNavExpanded ? ' small' : ' big')}>
        //             <Grid>
        //                 <MyoCorrectGridHeader
        //                     parentId={'aireport.dashboard'}
        //                     id={store.userID}
        //                     onChange={store.getSearchData}
        //                     onClear={store.clearData}
        //                     title={store.pageTitle}
        //                     numResults={numResults}
        //                     buttons={hdrButtons}
        //                 />
        //             </Grid>
        //             <Grid className="grid">
        //                 <DataGrid columns={columns} loading={store.isLoadingOrders} data={store.ordersData} />
        //             </Grid>
        //         </Grid>
        //     </Grid>

        //     <ModalContainer
        //         show={store.toggleShowOrderStatusModal}
        //         noScroll={true}
        //         title="Track Order"
        //         hideTitlebar={false}
        //         smallScreen={true}
        //         onClose={() => { store.toggleOrderStatusModal() }}
        //         onClick={store.toggleOrderStatusModal.bind(this)}
        //     >
        //         <TrackOrder orderStatusId={selectedOrderStatus} />
        //     </ModalContainer>
        // </Fragment>
    )
})