// Vendors
import React, { Fragment } from "react";
import { Grid, Button } from "@mui/material";

// Components
import { PatientReworkDashboard } from "./SubmitReworkModals/PatientReworkDashboard";
import { PatientPrescriptionDashboard } from "./SubmitReworkModals/PatientPrescriptionDashboard";

// Stores
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import ProviderPatient from "../../../entities/ProviderPatient";

// API
import { observer } from "mobx-react";
import _ from "lodash";
import { PatientStore } from "../../../api/PatientStore";
import ModalContainer from "../../ModalContainer";
import { CreateRxWizard } from "../../../modals/PrescriptionWizard/CreateRxWizard";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import PrescriptionStore from "../../../stores/OldStores/PrescriptionStore";
import { AddressType } from "../../../entities/Enums";

interface IGIProps {
    patient: ProviderPatient;
}


export const PatientApplianceTherapy: React.FC<IGIProps> = observer(({ patient }) => {
    let patientStore = useMainStoreContext().patientStore;
    const { providerStore } = useMainStoreContext();
    const tabs = [
        'Prescriptions',
        'Rework Claims'
    ];
    let store = useMainStoreContext().reworkClaimStore;
    const [openModalMissingLocation, setOpenModalMissingLocation] = React.useState(false);

    const onClick = () => {
        var hasAddressLocations = providerStore.providerData.addresses.filter(x => x.addressTypeId === AddressType["Practice Address"]);
        if (hasAddressLocations.length === 0) {
            setOpenModalMissingLocation(true);
            return;
        }
        patientStore.setCurrentPatientId(patient.id);
        patientStore.togglePrescriptionModal();
    };

    const [showConfirmClosePrescriptionForm, setShowConfirmClosePrescriptionForm] = React.useState(false);

    const handleConfirmClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
        patientStore.toggleShowPrescriptionModal = false;
    };

    const handleCancelClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
    };

    const handleCloseModal = () => {
        setShowConfirmClosePrescriptionForm(true);
    };
    interface UserRole {
        Name: string;
    }
    return (
        <Grid container>
            <Grid item xs={3} className="tabSubSection" paddingLeft={3}>
                <Grid item xs={11} container direction={'row'}>
                    {_.map(tabs, (t, key: number) => {
                        return (
                            <Grid
                                padding={1}
                                key={key}
                                className={'tab' + (store.activePatientApplianceTab === key ? ' active' : '')}
                                onClick={() => {
                                    store.setActivePatientApplianceTab(key);
                                }}
                            >
                                <Button
                                    variant={store.activePatientApplianceTab === key ? 'contained' : 'outlined'}
                                >
                                    {t}
                                </Button>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <Grid item xs={9} container justifyContent="right" padding={1} paddingRight={5}>
                {(() => {
                    let userRoles: UserRole[] = [];
                    try {
                        userRoles = JSON.parse(patientStore.userRoles ?? "[]") as UserRole[]; // Cast JSON.parse result to UserRole[]
                    } catch (error) {
                        console.error("Error parsing userRoles JSON:", error);
                        userRoles = []; // Fallback to empty array if parsing fails
                    }

                    return !userRoles.some(role => role.Name === 'AISSpecialist') && (
                        <Button variant="contained" size="small" onClick={onClick}>
                            New RX
                        </Button>
                    );
                })()}
            </Grid>
            <Grid item className={"tabContent"} xs={12} >
                {store.activePatientApplianceTab === 0 && (
                    <PatientPrescriptionDashboard patient={patient} />
                )}
                {store.activePatientApplianceTab === 1 && (
                    <PatientReworkDashboard patient={patient} />
                )}
            </Grid>
            <Grid>
                <Fragment>
                    <ModalContainer
                        show={patientStore.toggleShowPrescriptionModal}
                        title="PRESCRIPTION"
                        onClose={handleCloseModal}
                        onClick={patientStore.togglePrescriptionModal.bind(this)}
                    >
                        <CreateRxWizard patientId={patientStore.currentPatientId} isEdit={false} sendOnCloseEvent={() => {
                            patientStore.toggleShowPrescriptionModal = false;
                        }} />
                        <ConfirmDialog
                            title="Confirm Exit"
                            open={showConfirmClosePrescriptionForm}
                            onClose={handleCancelClosePrescriptionForm}
                            onConfirm={handleConfirmClosePrescriptionForm}
                            message="Are you sure you want to close without saving?"
                        />
                    </ModalContainer>
                </Fragment>

            </Grid>
            <ConfirmDialog
                title="Missing Account Address Information"
                open={openModalMissingLocation}
                onClose={() => setOpenModalMissingLocation(false)}
                onConfirm={() => setOpenModalMissingLocation(false)}
                textOnClose="Close"
                textOnConfirm="Confirm"
                message="To continue with the Prescription Process, please update your 'Account Address Information' in the provider settings."

            />
            <Grid>

            </Grid>
        </Grid>
    )
}
);

