import React, { Fragment, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    ListItemButton,
    useTheme,
    ListItemText,
    Grid,
    alpha,
    Divider,
    List,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";

// Import missing constants or helpers
import {
    dashboardIntro,
    quickCreatePt,
    newPtCreation,
    txPlanTutorial,
    orderRxDoc,
    careRxOrder,
    lifelineRxOrder,
    editRxTutorial,
    manageRxOrders,
    submitRepairVideo,
    myoEnrollmentVideo,
    clinicalConsultationRequest,
    toothchartingtutorial,
} from './Helper/documents';

// Define allProviderTypes
const allProviderTypes = [
    "VIP",
    "Lifeline",
    "Guided Growth and Development",
    "C.A.R.E",
    "Airway Health Solutions",
    "Sleep Well Journey",
    "Noum",
    "Lincare",
    "Certified Legacy Provider",
    "Colorado Sleep Institute",
    "Clinical Advisor",
];

// Styled Components
const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
    `
);

const ListItemButtonWrapper = styled(ListItemButton)(
    ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'box-shadow'])};
        transform: scale(1);
        background: ${theme.colors.alpha.white[100]};
        position: relative;
        z-index: 5;

        &:hover {
            border-radius: ${theme.general.borderRadius};
            background: ${theme.colors.alpha.white[100]};
            z-index: 6;
            box-shadow: 
                0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
            transform: scale(1.05);
        }
    `
);

// Document Groups
const documentGroups = [
    {
        name: "Patient Management",
        documents: [
            { id: 21, providerAccess: allProviderTypes, name: "Quick Create Patient", link: quickCreatePt, type: "link" },
            { id: 22, providerAccess: allProviderTypes, name: "New Patient Creation", link: newPtCreation, type: "link" },
            { id: 23, providerAccess: allProviderTypes, name: "Tooth Charting Tutorial", link: toothchartingtutorial, type: "link" },
            { id: 24, providerAccess: allProviderTypes, name: "Creating a Treatment Plan", link: txPlanTutorial, type: "link" },
            { id: 25, providerAccess: allProviderTypes, name: "Adding a New Visit Record", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/Adding New Visit Records.mp4", type: "link" },
        ],
    },
    {
        name: "Manage Appliances and Therapy",
        documents: [
            { id: 30, providerAccess: ["VIP", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], name: "Ordering a New Rx: Quick Use Guide", link: orderRxDoc, type: "link" },
            { id: 31, providerAccess: ["VIP", "C.A.R.E", "Certified Legacy Provider", "Colorado Sleep Institute", "Clinical Advisor"], name: "Ordering a New Rx Video Tutorial: C.A.R.E. appliances", link: careRxOrder, type: "link" },
            { id: 32, providerAccess: ["Lifeline"], name: "Ordering a New Rx Video Tutorial: Lifeline Providers", link: lifelineRxOrder, type: "link" },
            { id: 33, providerAccess: allProviderTypes, name: "How to Add or Edit an RX", link: editRxTutorial, type: "link" },
            { id: 34, providerAccess: allProviderTypes, name: "Managing Appliance Orders", link: manageRxOrders, type: "link" },
            { id: 35, providerAccess: allProviderTypes, name: "Submitting a Warranty/Repair Claim", link: submitRepairVideo, type: "link" },
            { id: 36, providerAccess: allProviderTypes, name: "Enrolling Patient into MyoSync", link: myoEnrollmentVideo, type: "link" },
        ],
    },
    {
        name: "Report Management",
        documents: [
            { id: 40, providerAccess: allProviderTypes, name: "Ordering an Airway Intelligence Report", link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Navigating vCloud/How To Videos/114 RequestAIR Rev1.mp4", type: "link" },
        ],
    },
    {
        name: "Consultations",
        documents: [
            { id: 50, providerAccess: allProviderTypes, name: "Requesting a Clinical Consultation", link: clinicalConsultationRequest, type: "link" },
            { id: 51, providerAccess: allProviderTypes, name: "Requesting an AIS Consultation", link: clinicalConsultationRequest, type: "link" }
        ],
    },
];

function NavigatingvCloudContent({ providerTypes, isProvider }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleDocumentClick = (doc) => {
        if (doc.type === 'link') {
            window.open(doc.link, '_blank', 'noopener noreferrer');
        } else {
            setDialogContent(doc.description);
            setOpenDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid container spacing={2}>
                {/* Intro to vCloud row */}
                <Grid item xs={12}>
                    <Card>
                        <BoxWrapper>
                            <Typography variant="h4" textAlign="center" color="text.primary" gutterBottom>
                                Welcome to vCloud! Click on any tutorial to learn how to navigate the new website!
                            </Typography>
                            <Box mb={3} /> {/* Added spacing below the welcome text */}
                            <ListItemButtonWrapper
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: theme.spacing(2),
                                overflow: 'visible',
                                boxShadow: `
                                    0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                                    0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                                    0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                                    0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)}
                                `,
                                borderRadius: theme.general.borderRadius,
                                '&:hover': {
                                    transform: 'scale(1.02)', // Reduced hover scaling effect
                                    boxShadow: `
                                        0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                                        0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                                        0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.12)},
                                        0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.18)}
                                    `,
                                },
                            }}
                            onClick={() => window.open(dashboardIntro, '_blank', 'noopener,noreferrer')}
                        >
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography variant="h5">
                                        Watch vCloud Dashboard 101
                                    </Typography>
                                }
                            />
                        </ListItemButtonWrapper>
                        </BoxWrapper>
                    </Card>
                </Grid>

            {/* Document groups */}
            {documentGroups.map((group, index) => (
                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                    <Card
                                    sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    height: '100%', // Ensures equal height
                                    overflow: 'visible',
                                    backgroundColor: theme.palette.background.default,
                                    border: `1px solid ${theme.palette.divider}`,
                                    boxShadow: `
                                        0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                                        0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                                        0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                                        0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)}
                                        `,
                                    borderRadius: `${theme.shape.borderRadius}px`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '75px',
                                            borderRadius: `${theme.shape.borderRadius}px`,
                                            backgroundColor: theme.palette.primary.dark,
                                            color: theme.palette.primary.contrastText,
                                        }}
                                    >
                                        <Typography variant="h5">{group.name}</Typography>
                                    </Box>
                    
                                    <List sx={{ flexGrow: 1 }}>
                                        {group.documents
                                            .filter((doc) => {
                                                if (!isProvider) return true;
                                                return doc.providerAccess.some((accessType) =>
                                                    providerTypes.some((providerType) => providerType.includes(accessType))
                                                );
                                            })
                                            .map((doc) => (
                                                <Fragment key={doc.id}>
                                                    <Divider />
                                                    <ListItemButtonWrapper
                                                        sx={{
                                                            display: { xs: 'block', sm: 'flex' },
                                                            py: 2,
                                                            px: 2.5,
                                                        }}
                                                        onClick={() => handleDocumentClick(doc)}
                                                    >
                                                        <ListItemText
                                                            disableTypography
                                                            primary={
                                                                <Typography color="text.primary" variant="h5">
                                                                    {doc.name}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButtonWrapper>
                                                </Fragment>
                                            ))}
                                    </List>
                                </Card>
                </Grid>
            ))}

            {/* Dialog for additional details */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Details</DialogTitle>
                <DialogContent>{dialogContent}</DialogContent>
            </Dialog>
        </Grid>
    );
}

export default NavigatingvCloudContent;
