import React, { useEffect, useState } from 'react';
import {
    Button, Grid, Switch,
    Box,
    Card,
    Typography,
    alpha,
    Divider,
    LinearProgress,
    styled,
    linearProgressClasses,
    Alert
} from '@mui/material';

import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { Oval } from 'react-loader-spinner';

import ProviderPatient from '../../../entities/ProviderPatient';
import PatientEditBL from '../PatientEditBL';
import { PatientGeneralInfoTwo } from './PatientGeneralInfoTwo';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { PatientHealthHistoryTwo } from './PatientHealthHistoryTwo';
import { PatientLegalGuardian } from './PatientLegalGuardians';
import moment from 'moment';
import Item from '../../Item';
import CssTextField from '../../CssTextField';

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
    isClinicalAdvocate?: boolean;
}

const LinearProgressPrimary = styled(LinearProgress)(
    ({ theme }) => `
          height: 6px;
          border-radius: ${theme.general.borderRadiusLg};
  
          &.${linearProgressClasses.colorPrimary} {
              background-color: ${alpha(theme.colors.primary.main, 0.1)};
          }
          
          & .${linearProgressClasses.bar} {
              border-radius: ${theme.general.borderRadiusLg};
              background-color: ${theme.colors.primary.main};
          }
      `
);

const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(1.5)};
      top: ${theme.spacing(1.5)};
      z-index: 7;
    `
);

export const PatientDetailsTwo: React.FC<IGIProps> = observer(({ bl, patient, isClinicalAdvocate }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = useState<string | null>(null);

    const patientHistoryEditStore = useMainStoreContext().patientEditStore;

    useEffect(() => {
        bl.patientData = patient;

        bl.loadDetails().then(() => {
            patientHistoryEditStore.setPatientHistory(bl.patientHistory);
            patientHistoryEditStore.setHistory(bl.patientHistory);
            patientHistoryEditStore.setLegalGuardians(bl.legalGuardians);
            setIsLoaded(true);
        });

        setExpanded('panel1');
    }, []);

    const handleChange = (current: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? current : false);
    };

    const handleSave = () => {
        setError(null);
        if (!_.isEmpty(bl.patientData.emailAddress)) {
            patientHistoryEditStore.upsertPatientHistory(patient.id);
            bl.onEdit();
        } else {
            setError('Error updating the Patient Data. Email address is required.');
        }
    }

    if (!isLoaded) {

        return (
            <Grid container sx={{ marginTop: '50px', justifyContent: 'center' }}>
                <Oval
                    height={60}
                    width={60}
                    color="#FF7C00"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#480141"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                />
            </Grid>
        );
    } else {
        return (
            <Grid container spacing={3} className="patientDetailsTab">
                <Grid item xs={4}>
                    <Card
                        sx={{
                            position: 'relative',
                            p: 3,
                            mt: 5
                        }}
                    >
                        {error && (
                            <Box mb={2}>
                                <Alert severity="error">{error}</Alert>
                            </Box>
                        )}
                        <Box mb={2} display="flex" alignItems="center" >
                            <Box
                                sx={{
                                    width: '100%'
                                }}
                                ml={1.5}
                            >
                                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Grid item xs={8}>
                                        <Typography gutterBottom variant="h4">
                                            General Information
                                        </Typography>
                                    </Grid>
                                    {bl.isEditMode
                                        ? <Button onClick={handleSave} variant="contained">
                                            Save
                                        </Button>
                                        : ''}
                                    <Switch edge="end" color="primary" onChange={bl.onEditChange} checked={bl.isEditMode} />
                                </Grid>
                            </Box>
                        </Box>
                        <Divider />
                        <PatientGeneralInfoTwo bl={bl} patient={patient} />



                        <Grid container sx={{ justifyContent: 'flex-end' }}>
                            {moment().diff(patient.dateOfBirth, 'years') < 18 && bl.isEditMode && bl.legalGuardians.filter(x => !x.deleted).length < 3 && (
                                <Button
                                    sx={{ width: '200px' }}
                                    className="vivButton large invert"
                                    onClick={() => { bl.onEmptyGuardianAdd() }}
                                >
                                    Add Legal Guardian
                                </Button>
                            )}
                        </Grid>

                    </Card>
                </Grid>

                <Grid item xs={8}>
                    <Card
                        sx={{
                            position: 'relative',
                            p: 3,
                            mt: 5
                        }}
                    >
                        <Box mb={2} display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: '100%'
                                }}
                                ml={1.5}
                            >
                                <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    <Typography gutterBottom variant="h4">
                                        Health History
                                    </Typography>
                                    <Switch edge="end" color="primary" onChange={bl.onEditChange} checked={bl.isEditMode} />
                                </Grid>

                            </Box>
                        </Box>
                        <Divider />
                        <PatientHealthHistoryTwo bl={bl} patient={patient} />
                    </Card>
                </Grid>
            </Grid>
        );
    }
});
