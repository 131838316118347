import { Button, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react";
import { FC } from "react";
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import React from "react";
import { showModal } from "survey-react-ui";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";

interface IGIProps {
    id: any;
    showModal?: boolean;
    onClose():any;
}

export const ApprovalCode: FC<IGIProps> = observer((props) => {
    const [approvalCode, setApprovalCode] = React.useState('')
    const {setApprovalLincareCode} = usePrescriptionStore().prescriptionStore;
    const handleOnSubmit = () => {
        //let inTreatment = store.lincareStatusValues.find(x => x.listItemKey === 'INTREATMENT');
        setApprovalLincareCode(props.id, approvalCode).then((result)=>{
            if(result){
                //props.showModal = !result;
                props.onClose();
            }
            props.onClose();
        });
    };

  return (
    <Grid xs={12} container direction={'row'} padding={'30px 30px 30px 30px'}>
    <Grid className="formLabel" fontWeight={'bold'}>
        <Grid paddingBottom={'10px'}>Approval Code:</Grid>
        <TextField
                variant="outlined"
                size="small"
                placeholder=""
                required={true}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setApprovalCode(event.target.value);
                }}
                value={approvalCode}
            /> 
    </Grid>
        <Grid container direction={'row'} justifyContent={'flex-end'}>
            <Button className="vivButton large invert" onClick={() => {handleOnSubmit()}} disabled={!(approvalCode.length > 10)}>
                Save
            </Button>
        </Grid>
    </Grid>
  )
})