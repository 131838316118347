import React, { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    CircularProgress
} from '@mui/material';

// Components
import RxBlock1 from '../RxFrameworkComponents/RxBlock1';
import RxBlock2 from '../RxFrameworkComponents/RxBlock2';
import RxOverview from './RxOverview';
import { VisitRecordSectionModule } from '../../VisitRecords/components/VisitRecordSelectionModule';
import Impressions from '../../../components/Impressions/Impressions';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';
import PatientVisit from '../../../entities/PatientVisit';
import { defaultVisit } from '../../../stores/OldStores/VisitStore';
import ScanOcclusion from '../../../components/ImagesAndDocs/ScanOcclusion';
import { over } from 'lodash';
import { vi } from 'date-fns/locale';

const RxStepFive = () => {
    const visitStore = useMainStoreContext().visitRecordStore;
    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const occlusionStore = useMainStoreContext().occlusionStore;
    const [isloadingVisits, setIsLoadingVisits] = useState(false);

    // State for selected visit
    const [selectedVisit, setSelectedVisit] = useState<any>(null);

    const { patientId } = prescriptionStore;

    useEffect(() => {
        //visitStore.visitImagesToComplianceImages();

        visitStore.visitImagesToPatientImages();
    }, [selectedVisit]);

    useEffect(() => {
        setIsLoadingVisits(true);
        visitStore.setVisitPatientId(patientId);
        visitStore.loadVisits().then(() => {
            if (prescriptionStore.isUpdating) {
                visitStore.setId(prescriptionStore.prescription.visitId ?? 0);
                visitStore.loadVisit();
                visitStore.visitImagesToComplianceImages();
                visitStore.visitImagesToPatientImages();
                setSelectedVisit(visitStore.currentVisit);
            } else {
                visitStore.resetVisit();
                setSelectedVisit(null); // Reset if no visit is selected
            }
            setIsLoadingVisits(false);
        });
    }, [visitStore, patientId, prescriptionStore]);

    const handleVisitSelection = (visit: any) => {
        if (!visit) {
            return;
        }
        setSelectedVisit(visit);
        visitStore.setVisit(visit);
        occlusionStore.setVisitId(visit.id);
        visitStore.visitImagesToPatientImages();
        if(visit >0){
            prescriptionStore.selectedImpressions = 'existing';
        }
        
    };

    const handleImagesChange = (images: any) => {
        visitStore.visitOcclusion3dimages =images;
    }


    const handleAddNewVisit = () => {
        const newVisit = new PatientVisit(defaultVisit);
        const updatedVisits = [...visitStore.visitRecords, newVisit];
        visitStore.setVisitRecords(updatedVisits);
        visitStore.setVisit(newVisit); // Update the store
        visitStore.clearImages();
        setSelectedVisit(newVisit); // Set the new visit as the selected visit
        prescriptionStore.selectedImpressions = 'new';
    };

    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={12} sm={9}>
                {isloadingVisits && (
                    <Grid container direction="row" justifyContent="center">
                        <Grid container justifyContent="center">
                            <Typography>Loading...</Typography>
                        </Grid>
                        <CircularProgress sx={{ width: "90%", height: "90%" }} />
                    </Grid>
                )}
                {!isloadingVisits && (
                    <RxBlock1
                        RxBlock1Content={
                            <Grid container spacing={3}>
                                <Grid item xs={12} container spacing={2} >
                                    {/* Render VisitRecordSectionModule smaller and Impressions larger */}
                                    <Grid item xs={12} sx={{ overflow: 'auto' }}>
                                        <VisitRecordSectionModule
                                            visits={visitStore.visitRecords}
                                            singleSelect={true}
                                            rx={true}
                                            height='350px'
                                            onSelectVisit={handleVisitSelection}
                                            onAddNewVisit={handleAddNewVisit}
                                            onVisitsChange={(visits) => {
                                                visitStore.setVisitRecords(visits);
                                            }}
                                            shouldAddImpressionButton={true}
                                        />
                                    </Grid>
                                    {selectedVisit && selectedVisit !== null &&
                                        <Grid item xs={12}>
                                            <ScanOcclusion
                                                isIntake={false}
                                                isRework={false}
                                                visit={selectedVisit}
                                                isPrescription={true}
                                                vivosId={prescriptionStore.currentPatient.vivosId}
                                                images={visitStore.visitOcclusion3dimages}
                                                handleImagesChange={handleImagesChange}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={3}>
                <RxBlock2 RxBlock2Content={<RxOverview />} />
            </Grid>
        </Grid>
    );
};

export default observer(RxStepFive);
