// Vendor
import React, { useMemo, useState, useEffect } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

// Components
import FileAccordion from './FileAccordion';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// Entities
import DictionaryListItem from '../../entities/DictionaryListItem';
import PatientFile from '../../entities/PatientFIle';
import PatientVisit from '../../entities/PatientVisit';

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from "../../api/GlobalUtils";
import { IntakeReports } from '../../entities/Enums';
import PatientDocument from '../../entities/PatientDocument';
import _ from 'lodash';
import { Gateway } from '../../api/Gateway';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

interface IProps {
    visit: PatientVisit | undefined;
}

const SleepTest: React.FC<IProps> = ({ visit }) => {
    const { patient } = useMainStoreContext().createPatientStore;

    const visitStore = useMainStoreContext().visitRecordStore;
    const intakeReportsStore = useMainStoreContext().intakeReportsStore;

    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [reportValues, setReportValues] = useState<any>([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [currentIndexToDelete, setCurrentIndexToDelete] = useState<number>(0);

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            if (item.listItemKey === 'SLEEPTEST') {
                setReportTypes(prevState => [...prevState, item]);
            }
        });
    }, []);

    useEffect(() => {
        visitStore.setIsLoading(true);
        intakeReportsStore.reportFiles = [];
        intakeReportsStore.reportFileValues = {};

        intakeReportsStore.loadReportPropertiesData('sleepstudy').then(resp => {
            intakeReportsStore.reportValuesData = [];
            resp.map(r => intakeReportsStore.reportValuesData.push({ PropertyId: r.id, Name: r.name, Value: null }));
            setReportValues(resp);
            /*
            visitStore.patientFiles.filter(x => x.fileDescriptorId === IntakeReports['Sleep Test'] && x.isActive).map((report, index) => {
                intakeReportsStore.reportFiles.push(new PatientDocument({
                    id: report.id,
                    fileName: report.displayName,
                    pathUrl: report.fileUrl,
                    imageType: report.fileDescriptorId,
                    isActive: report.isActive
                }))

                var vPatientSleepStudies = visitStore.currentVisit?.patientSleepStudies.filter(x => x.patientFileId === report.id && x.isActive) ?? [];
                var vReportValuesData = intakeReportsStore.reportValuesData.map(element => {
                    const newValue = vPatientSleepStudies.find(val => val.propertyId === element.PropertyId)?.value;
                    return newValue !== undefined ? { ...element, Value: newValue } : element;
                })

                intakeReportsStore.reportFileValues = {
                    ...intakeReportsStore.reportFileValues,
                    [index]: vReportValuesData as any
                };

            });
            */
            if (visitStore?.currentVisit?.patientFiles) {
                visitStore.currentVisit.patientFiles
                    .filter(x => x.fileDescriptorId === IntakeReports['Sleep Test'] && x.isActive)
                    .forEach((report, index) => {
                        // Check for duplicates before pushing
                        const isDuplicate = intakeReportsStore.reportFiles.some(existingFile => existingFile.id === report.id);

                        if (!isDuplicate) {
                            intakeReportsStore.reportFiles.push(new PatientDocument({
                                id: report.id,
                                fileName: report.displayName,
                                pathUrl: report.fileUrl,
                                imageType: report.fileDescriptorId,
                                isActive: report.isActive
                            }));
                        }

                        const vPatientSleepStudies = visitStore.currentVisit?.patientSleepStudies.filter(x => x.patientFileId === report.id && x.isActive) ?? [];
                        const vReportValuesData = intakeReportsStore.reportValuesData.map(element => {
                            const newValue = vPatientSleepStudies.find(val => val.propertyId === element.PropertyId)?.value;
                            return newValue !== undefined ? { ...element, Value: newValue } : element;
                        });

                        intakeReportsStore.reportFileValues = {
                            ...intakeReportsStore.reportFileValues,
                            [index]: vReportValuesData as any
                        };
                    });

                visitStore.setIsLoading(false);
                setIsLoading(false);
            }
        })
    }, [visitStore.currentVisit]);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const pdfFiles = Array.from(e.target.files).filter(file => file.type === 'application/pdf');

            var patientFiles = await Promise.all(pdfFiles.map(async pdfFile => {
                return (new PatientDocument({
                    id: 0,
                    pathUrl: pdfFile.name,
                    fileName: pdfFile.name,
                    data64String: await convertBase64(pdfFile) as unknown as string,
                    imageType: reportTypes[0],
                    isActive: true
                }))
            }))

            //setFiles([...files, ...pdfFiles]);
            intakeReportsStore.reportFiles = [...intakeReportsStore.reportFiles, ...patientFiles];
        }
    };

    const handleSave = (index: number, data: any) => {
        const array = intakeReportsStore.reportValuesData.map((element, idx) => ({
            ...element,
            Value: idx === index ? data : element.Value
        }));
        intakeReportsStore.reportValuesData = array;
        intakeReportsStore.reportFileValues = {
            ...intakeReportsStore.reportFileValues,
            [index]: data
        };
        //visitStore.loadVisit();
    };

    const handleDelete = () => {
        if (!_.isEmpty(intakeReportsStore.reportFiles[currentIndexToDelete].data64String)) {
            const array = intakeReportsStore.reportValuesData.map(element => ({
                ...element,
                Value: null
            }))
            intakeReportsStore.reportValuesData = array;
            delete intakeReportsStore.reportFileValues[currentIndexToDelete];
            intakeReportsStore.reportFiles.splice(currentIndexToDelete, 1);
            intakeReportsStore.reportFiles = [...intakeReportsStore.reportFiles];
            setOpenConfirmDialog(false);
        } else {
            Gateway.delete(`/patient/deletefile/${intakeReportsStore.reportFiles[currentIndexToDelete].id}/${visitStore.currentVisit.id}/${intakeReportsStore.userId}`, {}).then((resp) => {
                const array = intakeReportsStore.reportFileValues[currentIndexToDelete].map(element => ({
                    ...element,
                    Value: null
                }))
                var reportInfo = {
                    visitId: visitStore.currentVisit.id,
                    reportValues: array,
                    patientFileId: intakeReportsStore.reportFiles[currentIndexToDelete].id,
                    patientId: patient.id

                }

                /*
                Gateway.post(`/patient/reportinfo/sleepstudy/${intakeReportsStore.userId}`, reportInfo).then(() => {
                    delete intakeReportsStore.reportFileValues[currentIndexToDelete];
                    intakeReportsStore.reportFiles.splice(currentIndexToDelete, 1);
                    intakeReportsStore.reportFiles = [...intakeReportsStore.reportFiles];
                    var vPatientFiles = visitStore.patientFiles;
                    vPatientFiles.push(resp as any as PatientFile);
                    visitStore.setPatientFiles(vPatientFiles);
                    visitStore.setPatientFiles([]);
                    visitStore.setPatientUploadFiles([]);
                    setOpenConfirmDialog(false);
                    visitStore.loadVisit();
                })
                 */
                Gateway.post(`/patient/reportinfo/sleepstudy/${intakeReportsStore.userId}`, reportInfo).then(() => {
                    delete intakeReportsStore.reportFileValues[currentIndexToDelete];
                    const removedFile = intakeReportsStore.reportFiles.splice(currentIndexToDelete, 1)[0];
                    intakeReportsStore.reportFiles = [...intakeReportsStore.reportFiles];

                    // Filter out the file with the same filename from vPatientFiles
                    var vPatientFiles = visitStore.patientFiles.filter(file => file.id !== removedFile.id);

                    // Push the new response object
                    vPatientFiles.push(resp as any as PatientFile);

                    // Update the store
                    visitStore.setPatientFiles(vPatientFiles);
                    visitStore.setPatientUploadFiles([]);
                    setOpenConfirmDialog(false);

                    // Reload the visit
                    visitStore.loadVisit();
                });

            })
        }
    }

    const handleOpenConfirmDialog = (index: number) => {
        setCurrentIndexToDelete(index);
        setOpenConfirmDialog(true);
    };

    const handleDownload = (index: number) => {
        if (!_.isEmpty(intakeReportsStore.reportFiles[index].pathUrl)) {
            const pathUrl = intakeReportsStore.reportFiles[index].pathUrl;

            // Create a function to trigger the download
            const downloadFile = (url) => {
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.target = '_blank';
                anchor.download = ''; // Optionally, set a specific file name here
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
            };

            // Trigger the download
            downloadFile(pathUrl);
        } /*else {
            Gateway.delete(`/patient/deletefile/${intakeReportsStore.reportFiles[currentIndexToDelete].id}/${visitStore.currentVisit.id}/${intakeReportsStore.userId}`, {}).then((resp) => {
                const array = intakeReportsStore.reportFileValues[currentIndexToDelete].map(element => ({
                    ...element,
                    Value: null
                }))
                var reportInfo = {
                    visitId: visitStore.currentVisit.id,
                    reportValues: array,
                    patientFileId: intakeReportsStore.reportFiles[currentIndexToDelete].id,
                    patientId: patient.id
                }
                Gateway.post(`/patient/reportinfo/sleepstudy/${intakeReportsStore.userId}`, reportInfo).then(() => {
                    delete intakeReportsStore.reportFileValues[currentIndexToDelete];
                    intakeReportsStore.reportFiles.splice(currentIndexToDelete, 1);
                    var vPatientFiles = visitStore.patientFiles;
                    vPatientFiles.push(resp as any as PatientFile);
                    visitStore.setPatientFiles(vPatientFiles);
                    visitStore.setPatientUploadFiles([]);
                    setOpenConfirmDialog(false);
                })
            })
        }*/
    };

    const handleCloseConfirmDialog = () => {
        setCurrentIndexToDelete(0);
        setOpenConfirmDialog(false);
    };

    return (
        <Grid container >
            <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                Sleep Test
            </Typography>
            {/* <Grid item xs={9}>
                <UploadDocDragDrop parentCallback={handleUpload} extFiles={patientUploadFiles} typeOptions={reportTypes} />
            </Grid> */}
            {visitStore.isLoading &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>
            }
            {!visitStore.isLoading && <Grid item xs={12}>
                <input type="file" accept=".pdf" multiple onChange={handleFileUpload} />
                {intakeReportsStore.reportFiles.map((file, index) => (
                    <Grid item xs={12}>
                        <FileAccordion
                            key={index}
                            filename={file.fileName}
                            index={index}
                            reportValues={_.isEmpty(intakeReportsStore.reportFileValues[index]) ? intakeReportsStore.reportValuesData : intakeReportsStore.reportFileValues[index]}
                            onSave={handleSave}
                            onDelete={handleOpenConfirmDialog}
                            onDownload={handleDownload}
                            isEditable={true}

                        />
                    </Grid>
                ))}
                {/* <Button onClick={handleSubmit}>Submit</Button> */}
            </Grid>}
            <ConfirmDialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} onConfirm={handleDelete} />
            {/* <SleepStudy visitId={visit?.id ?? 0}/> */}
            {/* <Box sx={{ flexGrow: 1 }}> */}
            {/* <Grid xs={12}>
                <UploadSleepDocDragDrop
                    parentCallback={handleUpload}
                    extFiles={patientUploadFiles}
                    typeOptions={reportTypes}
                    values={values}
                    updateValues={updateValues}
                />
            </Grid> */}

            {/* {!isIntake && <Grid xs={12}>
                <Grid container direction="column" >
                    <Divider />
                    <Typography variant="h6" paddingLeft={3} paddingTop={1}>Uploaded Files</Typography>
                </Grid>
                <Grid xs={12} padding={4}>
                    <DataGrid
                        columns={columns}
                        data={sleepTestFiles}
                        loading={hasLoaded}
                        size={"small"}
                        pageSize={10}
                    />
                </Grid>
                <Grid xs={12} padding={4}>
                    <DataGrid
                        columns={historicalColumns}
                        data={currentVisit?.patientSleepStudies}
                        loading={isLoading}
                        size={"small"}
                        pageSize={10}
                    />
                </Grid>
            </Grid>} */}
            {/* </Box> */}

        </Grid>
    );
};

export default observer(SleepTest);
