// Vendors
import React, { FC, useEffect, useMemo, useState } from "react";
import {
    Divider,
    Grid,
    MenuItem,
    TextareaAutosize,
    Button,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    styled,
    Avatar,
    Card,
    Link,
    IconButton
} from "@mui/material";
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import AddTaskTwoToneIcon from '@mui/icons-material/AddTaskTwoTone';
import DownloadIcon from '@mui/icons-material/Download';

// Components
import SectionContainer from "../../../components/UI/Containers/SectionContainer";

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import Claim from "../../../entities/Claim";
import { ClaimType, ClaimCategory, LabClaimMaterialApplianceStatus, LabClaimMaterialBiteStatus, LabClaimMaterialModelStatus } from "../../../entities/Enums";

// API
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { observer } from "mobx-react-lite"
import moment from "moment";
import { FileUploader } from "react-drag-drop-files";
import _ from "lodash";

import CssTextField from "../../../components/CssTextField";
import { GlobalUtils } from "../../../api/GlobalUtils";


const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.main};
        color: ${theme.palette.success.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.success};
  `
);
const AvatarError = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.error.main};
        color: ${theme.palette.error.contrastText};
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        box-shadow: ${theme.colors.shadows.error};
  `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
    `
);

const RootWrapper = styled(Card)(
    ({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${theme.colors.gradients.blue3};
        color:  ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(2)};
  `
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[70]};
  `
);

interface IGIProps {
    claim: Claim;
    claimData: any;
}

export const LabInvestigation: FC<IGIProps> = observer(({ claim, claimData }) => {
    const store = useMainStoreContext().reworkClaimStore;

    const fileTypes = ["JPG", "PNG", "JPEG"];
    const CLAIMDOCUMENTTYPE = 789;
    const [filesContent, setFilesContent] = useState<File[]>([]);
    const [showTextArea, setShowTextArea] = useState(claim.labRemakeNeeded ? claim.labRemakeNeeded : false);
    const [dateValueLabClaimMaterialApplianceReceivedOn, setDateValueLabClaimMaterialApplianceReceivedOn] = useState<Date | null>(claim.labClaimMaterialApplianceReceivedOn);
    const [dateValueLabClaimMaterialModelReceivedOn, setDateValueLabClaimMaterialModelReceivedOn] = useState<Date | null>(claim.labClaimMaterialModelReceivedOn);
    const [dateValueLabClaimMaterialBiteReceivedOn, setDateValueLabClaimMaterialBiteReceivedOn] = useState<Date | null>(claim.labClaimMaterialBiteReceivedOn);
    const [investigationText, setInvestigationText] = useState(claim.labInvestigationNotes ? claim.labInvestigationNotes : '');
    const [remakeText, setRemakeText] = useState(claim.labRemakeNotes ? claim.labRemakeNotes : '');
    const [applianceStatus, setApplianceStatus] = useState<any>(claim.labClaimMaterialApplianceStatus ? claim.labClaimMaterialApplianceStatus : LabClaimMaterialApplianceStatus.Pending);
    const [modelStatus, setModelStatus] = useState<any>(claim.labClaimMaterialModelStatus ? claim.labClaimMaterialModelStatus : LabClaimMaterialModelStatus.Pending);
    const [biteStatus, setBiteStatus] = useState<any>(claim.labClaimMaterialBiteStatus ? claim.labClaimMaterialBiteStatus : LabClaimMaterialBiteStatus.Pending);
    const [labInvRecordedBy, setlabInvRecordedBy] = useState(store.vwClaim.investigationRecordedByFirstName + ' ' + store.vwClaim.investigationRecordedByLastName);
    const [labInvRecordedOn, setLabInvRecordedOn] = useState(store.vwClaim.labInvestigationRecordedOn ? moment(store.vwClaim.labInvestigationRecordedOn).format('MM/DD/yyyy') : '');
    const [otherIssueText, setOtherIssueText] = useState(store.vwClaim.initialImpressionOtherNotes ? store.vwClaim.initialImpressionOtherNotes : '');
    const [claimCategoryId, setClaimCategoryId] = useState(store.vwClaim.claimCategoryId ? store.vwClaim.claimCategoryId : null);
    const [isLoading, setIsLoading] = useState(false);
    const [shippedOn, setShippedOn] = useState<Date | null>(claim.shippedOn);
    const [trackingNoMaterialsToLab, setTrackingNoMaterialsToLab] = useState(claim.trackingNoMaterialsToLab ? claim.trackingNoMaterialsToLab : '');
    useEffect(() => {
        store.loadReworkDocuments(claim.id);
    }, [store.claim]);

    const updatedClaim = {
        ...claim,
        labInvestigationNotes: investigationText,
        labRemakeNotes: remakeText,
        labRemakeNeeded: showTextArea,
        labClaimMaterialApplianceStatus: applianceStatus,
        labClaimMaterialModelStatus: modelStatus,
        labClaimMaterialBiteStatus: biteStatus,
        labClaimMaterialApplianceReceivedOn: dateValueLabClaimMaterialApplianceReceivedOn,
        labClaimMaterialModelReceivedOn: dateValueLabClaimMaterialModelReceivedOn,
        labClaimMaterialBiteReceivedOn: dateValueLabClaimMaterialBiteReceivedOn,
        shippedOn: shippedOn,
        trackingNoMaterialsToLab: trackingNoMaterialsToLab,
        claimCategoryId: claimCategoryId
    }

    const saveClaim = (isSentToVivos?: boolean) => {
        let updateClaim = isSentToVivos ? {
            ...updatedClaim,
            labInvestigationRecordedOn: new Date(),
            labInvestigationRecordedBy: store.userID,
        } : updatedClaim;
        store.updateClaimChanges(updateClaim).then((resp: any) => {
            store.vwClaim = resp.value.find(x => x.id === store.claimId);
            if (isSentToVivos) {
                setlabInvRecordedBy(store.vwClaim.investigationRecordedByFirstName + ' ' + store.vwClaim.investigationRecordedByLastName);
                setLabInvRecordedOn(moment(store.vwClaim.labInvestigationRecordedOn).format('MM/DD/yyyy'));
            }
        });
        uploadFile();
    }

    // const sendResultsToVivos = () => {
    //     let duplicatedClaim = 
    //     {
    //         ...updatedClaim,
    //         labInvestigationRecordedOn: new Date(),
    //         labInvestigationRecordedBy: store.userID,
    //     }

    //     // setInitialImpressionByFullName(store.vwClaim.initialImpressionByFirstName + ' ' + store.vwClaim.initialImpressionByLastName);
    //     //     setInitialImpressionOn(moment(store.vwClaim.initialImpressionOn).format('MM/DD/yyyy'));
    //         store.loadPage();

    //     // if (claim.claimType === 590) // Warranty 
    //     // {
    //     //     duplicatedClaim.rxId = duplicatedClaim.rxId + "-1";
    //     //     store.createNewClaim(duplicatedClaim);
    //     //     //TODO: Notify provider
    //     // }
    //     // else if (claim.claimType === 591)  // repair
    //     // {
    //     //     duplicatedClaim.claimStatus = 594; //Cancel
    //     //     store.updateClaimChanges(duplicatedClaim);
    //     //     //TODO: Notify provider
    //     // }
    // }

    const uploadFile = () => {
        if (filesContent.length > 0) {
            store.uploadProcessedImages(claim.id, store.processedImages);
        }
    };

    const handleChangeFile = (files) => {
        setFilesContent([...files]);
        var imglist: FileReader[] = store.processedImages;
        var promises: any[] = [];
        _.forEach(files, async (file) => {
            const promise = doWork(file, imglist);
            promises.push(promise);
        })
        Promise.all(promises).then(() => {
            store.setProcessedImages(imglist);
        })
    };

    async function doWork(file, imglist) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            let fileUpload = {
                fileName: file.name,
                dataBody: (reader.result as string).split(',')[1],
                active: true,
                imageType: {
                    id: CLAIMDOCUMENTTYPE
                }
            };
            imglist.push(fileUpload);
        }
    }

    const deleteFile = (file) => {
        store.removeFile(file, claim.id);
    };

    // const applianceStatusOptions = [
    //     { key: 595, label: "Pending", color: "info" },
    //     { key: 596, label: "Needed", color: "warning" },
    //     { key: 597, label: "Waiting on Opposing Appliance", color: "warning" },
    //     { key: 598, label: "Received", color: "success" },
    //     { key: 599, label: "Cancelled", color: "error" },
    // ];
    // const modelStatusOptions = [
    //     { key: 712, label: "Pending", color: "info" },
    //     { key: 713, label: "Hold", color: "warning" },
    //     { key: 714, label: "Received", color: "success" },
    //     { key: 715, label: "Cancelled", color: "error" },
    // ];
    // const biteStatusOptions = [
    //     { key: 708, label: "Pending", color: "info" },
    //     { key: 709, label: "Hold", color: "warning" },
    //     { key: 710, label: "Received", color: "success" },
    //     { key: 711, label: "Cancelled", color: "error" },
    //     { key: 711, label: "N/A", color: "error" },
    // ];

    return (
        <Grid container padding={1} justifyContent={'center'}>
            <Grid xs={12} item container justifyContent={'flex-end'} padding={1}>
                <Button variant="outlined" onClick={() => { saveClaim(false) }}> Save Changes </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingRight={1}>
                <Box>
                    <SectionContainer
                        sectionTitle={'Material Return Tracker'}
                        cardContentHeight={'400px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">Date Material Shipped Back</Typography>}
                                        />

                                        <Box alignSelf="center">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    className="DateBox"
                                                    openTo="day"
                                                    views={['year', 'month', 'day']}
                                                    value={shippedOn}
                                                    onChange={(value) => {
                                                        setShippedOn(value);
                                                    }}
                                                    renderInput={params => (
                                                        <CssTextField
                                                            disabled={false}
                                                            {...params}
                                                            sx={{
                                                                '& input': {
                                                                    backgroundColor: 'white',
                                                                    color: '#050505',
                                                                    fontFamily: 'Proxima Nova',
                                                                    fontSize: '14px',
                                                                },
                                                            }}
                                                            className="TextBox"
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            {/*
                                            <Typography variant="h4">
                                                {claim.shippedOn && moment(claim.shippedOn).format('MM/DD/yyyy')}
                                            </Typography>
                                            */}
                                        </Box>
                                    </ListItem>

                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">Tracking #</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <TextField
                                                onChange={(e) => { setTrackingNoMaterialsToLab(e.target.value) }}
                                                style={{ width: "-webkit-fill-available" }}
                                                minRows={4}
                                                maxRows={4}
                                                value={claim.trackingNoMaterialsToLab}
                                            />
                                            {/*
                                            <Typography variant="h4">
                                                {claim.trackingNoMaterialsToLab}
                                            </Typography>
                                            */}
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Material Return Status</Typography>}
                                            secondary={
                                                <Grid container spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Appliance(s)</Typography>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            defaultValue={LabClaimMaterialApplianceStatus.Pending}
                                                            value={applianceStatus}
                                                            onChange={event => setApplianceStatus(event.target.value)}
                                                        >
                                                            {store.labClaimMaterialApplianceStatusValues.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Impression(s)</Typography>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            defaultValue={LabClaimMaterialModelStatus.Pending}
                                                            value={modelStatus}
                                                            onChange={event => {
                                                                setModelStatus(event.target.value)
                                                            }}>
                                                            {store.labClaimMaterialModelStatusValues.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Bite</Typography>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            sx={{
                                                                '& .MuiOutlinedInput-notchedOutline': {
                                                                    border: 'none', // Removes the border
                                                                }
                                                            }}
                                                            size="small"
                                                            defaultValue={LabClaimMaterialBiteStatus.Pending}
                                                            value={biteStatus}
                                                            onChange={event => {
                                                                setBiteStatus(event.target.value)
                                                            }}
                                                        >
                                                            {store.labClaimMaterialBiteStatusValues.map((option) => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    <LabelWrapper
                                                                        sx={(theme) => ({
                                                                            background: theme.palette[option.value].main,
                                                                            color: theme.palette.getContrastText(theme.palette[option.value].dark),
                                                                        })}
                                                                    >
                                                                        {option.name}
                                                                    </LabelWrapper>
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Date received materials</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Appliance(s)</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="DateBox"
                                                                openTo="day"
                                                                views={['year', 'month', 'day']}
                                                                value={dateValueLabClaimMaterialApplianceReceivedOn}
                                                                onChange={(value) => {
                                                                    setDateValueLabClaimMaterialApplianceReceivedOn(value);
                                                                }}
                                                                renderInput={params => (
                                                                    <CssTextField
                                                                        disabled={false}
                                                                        {...params}
                                                                        sx={{
                                                                            '& input': {
                                                                                backgroundColor: 'white',
                                                                                color: '#050505',
                                                                                fontFamily: 'Proxima Nova',
                                                                                fontSize: '14px',
                                                                            },
                                                                        }}
                                                                        className="TextBox"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Impression(s)</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="DateBox"
                                                                openTo="day"
                                                                views={['year', 'month', 'day']}
                                                                value={dateValueLabClaimMaterialModelReceivedOn}
                                                                onChange={(value) => {
                                                                    setDateValueLabClaimMaterialModelReceivedOn(value);
                                                                }}
                                                                renderInput={params => (
                                                                    <CssTextField
                                                                        disabled={false}
                                                                        {...params}
                                                                        sx={{
                                                                            '& input': {
                                                                                backgroundColor: 'white',
                                                                                color: '#050505',
                                                                                fontFamily: 'Proxima Nova',
                                                                                fontSize: '14px',
                                                                            },
                                                                        }}
                                                                        className="TextBox"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography variant="h5">Bite</Typography>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="DateBox"
                                                                openTo="day"
                                                                views={['year', 'month', 'day']}
                                                                value={dateValueLabClaimMaterialBiteReceivedOn}
                                                                onChange={(value) => {
                                                                    setDateValueLabClaimMaterialBiteReceivedOn(value);
                                                                }}
                                                                renderInput={params => (
                                                                    <CssTextField
                                                                        disabled={false}
                                                                        {...params}
                                                                        sx={{
                                                                            '& input': {
                                                                                backgroundColor: 'white',
                                                                                color: '#050505',
                                                                                fontFamily: 'Proxima Nova',
                                                                                fontSize: '14px',
                                                                            },
                                                                        }}
                                                                        className="TextBox"
                                                                        size="small"
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />

                                </List>
                            </Grid>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingX={1}>
                <Box>
                    <SectionContainer
                        sectionTitle={'Lab Investigation Results'}
                        cardContentHeight={'400px'}
                        sectionComponent={
                            <Grid container direction={'column'} spacing={1} padding={2}>
                                <List
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="subtitle2">Date manufacturer received claim</Typography>}
                                        />
                                        <Box alignSelf="center">
                                            <Typography variant="h4">
                                                {claim.labClaimReceivedOn && moment(claim.labClaimReceivedOn).format('MM/DD/yyyy')}
                                            </Typography>
                                        </Box>
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Classify Claim Category</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory.Manufacturing ? "contained" : "outlined"}
                                                            onClick={() => setClaimCategoryId(ClaimCategory.Manufacturing)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {ClaimCategory[ClaimCategory.Manufacturing]}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory.Material ? "contained" : "outlined"}
                                                            onClick={() => setClaimCategoryId(ClaimCategory.Material)}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {ClaimCategory[ClaimCategory.Material]}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory["Provider/Patient"] ? "contained" : "outlined"}
                                                            onClick={() => setClaimCategoryId(ClaimCategory["Provider/Patient"])}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            {ClaimCategory[ClaimCategory["Provider/Patient"]]}
                                                        </Button>
                                                    </Grid>
                                                    {/* <Grid item xs={4}>
                                                                <Button
                                                                    size="small"
                                                                    fullWidth
                                                                    variant={initialImpressionId === InitialImpressionIssue["Patient Issue"] ? "contained" : "outlined"}
                                                                    onClick={() => setInitialImpressionId(InitialImpressionIssue["Patient Issue"])}
                                                                    sx={{ mr: 1 }}
                                                                >
                                                                    Patient
                                                                </Button>
                                                            </Grid> */}
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={claimCategoryId === ClaimCategory.Other ? "contained" : "outlined"}
                                                            onClick={() => setClaimCategoryId(ClaimCategory.Other)}
                                                        >
                                                            {ClaimCategory[ClaimCategory.Other]}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    {(claimCategoryId === ClaimCategory.Other) &&
                                        <>
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">If other, explain:</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} className="claim-info" spacing={1} paddingTop={1}>
                                                            <TextField
                                                                onChange={(e) => { setOtherIssueText(e.target.value) }}
                                                                style={{ width: "100%" }}
                                                                minRows={4}
                                                                maxRows={4}
                                                                value={otherIssueText}
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    }
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Describe investigation results and outcome.</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <TextField
                                                        multiline
                                                        onChange={(e) => { setInvestigationText(e.target.value) }}
                                                        style={{ width: "-webkit-fill-available" }}
                                                        minRows={4}
                                                        maxRows={4}
                                                        value={investigationText}
                                                    />
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    <ListItem
                                        sx={{
                                            alignItems: 'flex-start',
                                            p: 1
                                        }}
                                    >
                                        <ListItemText
                                            primary={<Typography variant="h4">Does the claim require a complete remake?</Typography>}
                                            secondary={
                                                <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={showTextArea === true ? "contained" : "outlined"}
                                                            onClick={() => { setShowTextArea(true) }}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Button
                                                            size="small"
                                                            fullWidth
                                                            variant={showTextArea === false ? "contained" : "outlined"}
                                                            onClick={() => { setShowTextArea(false) }}
                                                            sx={{ mr: 1 }}
                                                        >
                                                            No
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                    {showTextArea &&
                                        <>
                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="h4">Describe the reason for remake.</Typography>}
                                                    secondary={
                                                        <Grid container direction={'row'} spacing={1} paddingTop={1}>
                                                            <TextField
                                                                multiline
                                                                onChange={(e) => { setRemakeText(e.target.value) }}
                                                                style={{ width: "-webkit-fill-available" }}
                                                                minRows={4}
                                                                maxRows={4}
                                                                value={remakeText}
                                                            />
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </>
                                    }
                                </List>
                            </Grid>
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingLeft={1}>
                <Grid container direction={'column'}>
                    <Grid paddingBottom={1}>
                        <Box>
                            <SectionContainer
                                sectionTitle={'Additional Documentation'}
                                cardContentHeight={'225px'}
                                sectionComponent={
                                    <Grid container direction={'column'} spacing={1} padding={2}>
                                        <List
                                            sx={{
                                                py: 0
                                            }}
                                        >

                                            <ListItem
                                                sx={{
                                                    alignItems: 'flex-start',
                                                    p: 1
                                                }}
                                            >
                                                <ListItemText
                                                    primary={<Typography variant="subtitle2">Upload any documentation that supports your investigation results</Typography>}
                                                    secondary={
                                                        <Grid >

                                                            <Grid>
                                                                <FileUploader
                                                                    label="Upload or drop a image here"
                                                                    name="file"
                                                                    handleChange={handleChangeFile}
                                                                    multiple={true}
                                                                    types={fileTypes}
                                                                    children={
                                                                        <Grid xs={12} container direction="column" className="claimuploadImgContainer">
                                                                            <>
                                                                                <Grid item>Drag & Drop Image(s) or</Grid>
                                                                                <Grid item>Click to Select Image(s)</Grid>
                                                                            </>
                                                                        </Grid >
                                                                    } />
                                                            </Grid>
                                                            {store.processedImages.filter(x => !x.isManagerView).length > 0 ? (store.processedImages.filter(x => !x.isManagerView).map((f, index) => (
                                                                <Grid xs={12} container direction="row" className="claimuploadImg">
                                                                    <Grid xs={6}>{f.fileName ? f.fileName : f.url.split('/').pop().split('?')[0]}</Grid>
                                                                    {/* <Grid xs={6}>{"File " + (Number(index) + 1) + ": "}</Grid> */}
                                                                    <Grid xs={4}>
                                                                        {!_.isEmpty(f.url) && <>
                                                                            <IconButton>
                                                                                <Link href={f.url}>
                                                                                    <DownloadIcon />
                                                                                </Link>
                                                                            </IconButton> / </>}
                                                                        <Button onClick={() => { deleteFile(f) }}><DoNotDisturbOnIcon /></Button>
                                                                    </Grid>
                                                                </Grid>
                                                            ))) : <></>}
                                                        </Grid>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider />
                                        </List>
                                    </Grid>
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid paddingTop={1}>
                        <RootWrapper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'block', sm: 'flex' },
                                    width: '100%',
                                }}
                            >
                                <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
                                    <AvatarSuccess sx={{ mr: 2 }} variant="rounded">
                                        <AddTaskTwoToneIcon />
                                    </AvatarSuccess>
                                    <Box>
                                        <TypographyPrimary variant="h5">
                                            Lab investigation complete?
                                        </TypographyPrimary>
                                        <TypographySecondary variant="subtitle2">
                                            Rework Request ID: {claim.id}
                                        </TypographySecondary>
                                    </Box>
                                </Box>
                                <Box sx={{ pt: { xs: 2, sm: 0 } }}>
                                    <Button
                                        variant="contained"
                                        //color="info"
                                        disabled={(claim.claimType === ClaimType.Pending) || store.vwClaim.labInvestigationRecordedBy}
                                        onClick={() => { saveClaim(true) }}>
                                        Send Results to Vivos
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    pt: { xs: 2, sm: 2 },
                                }}
                            >
                                <Typography variant="h6">Investigation Recorded By: <b>{labInvRecordedBy}</b></Typography>
                                <Typography variant="h6">Date Recorded: <b>{labInvRecordedOn}</b></Typography>
                            </Box>
                        </RootWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
})