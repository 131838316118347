// Vendors
import { FC, useEffect, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';

// Components
import { EstimatedTime } from './Components/EstimatedTreatment';
import SectionContainer from '../../../UI/Containers/SectionContainer';
import { ReviewTreatment } from './Components/ReviewTreatment';
import { ReviewTreatmentPlan } from './Components/ReviewTreatmentPlan';

// Store
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import moment from "moment";
import * as _ from "lodash";



interface IEstimatedTreatmentTime {
    patient?: any;
}

const defaultValues = {
    startDate: '',
    endDate: '',
    therapyOptions: 0,
};

export const EstimatedTreatmentTime: FC<IEstimatedTreatmentTime> = observer(({ patient }) => {
    return (
        <Grid container paddingX={5} paddingTop={2} spacing={2}>
            <Grid item xs={6}>
                <ReviewTreatment />
            </Grid>
            <Grid item xs={6}>
                <ReviewTreatmentPlan />
            </Grid>
        </Grid>
    );
});
