import {
    Box,
    Card,
    Typography,
    Grid,
    Tooltip,
    alpha,
    CardActionArea,
    IconButton,
    styled,
    useTheme,
    Button
} from '@mui/material';

import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import FeedbackTwoToneIcon from '@mui/icons-material/FeedbackTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const CardActions = styled(Box)(
    ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(1.5)};
      top: ${theme.spacing(1.5)};
      z-index: 7;
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(13)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
    `
);

const CardWrapper = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.gradients.blue3};
    `
);

const CardActionAreaWrapper = styled(CardActionArea)(
    ({ theme }) => `
          text-align: center;
          background: ${alpha(theme.colors.primary.main, 0.03)};
  
          .MuiTouchRipple-root {
            opacity: .2;
          }
    
          .MuiCardActionArea-focusHighlight {
            background: ${theme.colors.primary.main};
          }
    
          &:hover {
            .MuiCardActionArea-focusHighlight {
              opacity: .05;
            }
          }
    `
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
          transform: translateY(0px);
          transition: ${theme.transitions.create(['color', 'transform'])};
  
          .MuiSvgIcon-root {
              transform: scale(1);
              transition: ${theme.transitions.create(['transform'])};
          }
  
          &:hover {
              transform: translateY(-2px);
      
              .MuiSvgIcon-root {
                  transform: scale(1.3);
              }
          }
    `
);

function ContactUs() {
    const theme = useTheme();

    return (
        <Card variant="outlined" sx={{ margin: 10 }}>
            <CardWrapper
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    pt: 3,
                    px: 3,
                    pb: 7
                }}
            >
                <img
                    src="/logos/vCloud-White_Blue.png"
                    alt="vCloud Logo"
                    style={{
                        display: 'block',
                        maxHeight: '300px',
                        width: 'auto',
                        marginBottom: '0.5rem',
                    }}
                />
                <Box mt={1} mb={1.5}>
                    <LabelWrapper
                        sx={{
                            background: `${theme.colors.primary.main}`,
                            color: `${theme.palette.getContrastText(theme.colors.primary.dark)}`
                        }}
                    >
                        Contact Us
                    </LabelWrapper>
                </Box>
                <Typography variant="subtitle2" color={'white'}>
                    Have questions or need assistance? Feel free to reach out to us – we're here to help!
                </Typography>
            </CardWrapper>
            <Box p={3}>
                <Box
                    sx={{
                        textAlign: 'center',
                        mt: `-${theme.spacing(7)}`,
                        mb: 3
                    }}
                >
                    {/* <Tooltip arrow placement="top" title="Facebook">
                        <IconButtonWrapper
                            sx={{
                                boxShadow: `${theme.colors.shadows.primary}`,
                                background: `${theme.colors.primary.main}`,
                                color: `${theme.palette.getContrastText(
                                    theme.colors.primary.main
                                )}`,

                                '&:hover': {
                                    background: `${theme.colors.primary.main}`,
                                    color: `${theme.palette.getContrastText(
                                        theme.colors.primary.main
                                    )}`
                                },
                                borderRadius: 50,
                                width: theme.spacing(8),
                                height: theme.spacing(8)
                            }}
                            color="primary"
                            size="large"
                        >
                            <FacebookIcon fontSize="small" />
                        </IconButtonWrapper>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="Twitter">
                        <IconButtonWrapper
                            sx={{
                                boxShadow: `${theme.colors.shadows.primary}`,
                                background: `${theme.colors.primary.main}`,
                                color: `${theme.palette.getContrastText(
                                    theme.colors.primary.main
                                )}`,

                                '&:hover': {
                                    background: `${theme.colors.primary.main}`,
                                    color: `${theme.palette.getContrastText(
                                        theme.colors.primary.main
                                    )}`
                                },
                                borderRadius: 50,
                                mx: 1.5,
                                width: theme.spacing(8),
                                height: theme.spacing(8)
                            }}
                            color="primary"
                            size="large"
                        >
                            <TwitterIcon fontSize="small" />
                        </IconButtonWrapper>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="Instagram">
                        <IconButtonWrapper
                            sx={{
                                boxShadow: `${theme.colors.shadows.primary}`,
                                background: `${theme.colors.primary.main}`,
                                color: `${theme.palette.getContrastText(
                                    theme.colors.primary.main
                                )}`,

                                '&:hover': {
                                    background: `${theme.colors.primary.main}`,
                                    color: `${theme.palette.getContrastText(
                                        theme.colors.primary.main
                                    )}`
                                },
                                borderRadius: 50,
                                width: theme.spacing(8),
                                height: theme.spacing(8)
                            }}
                            color="primary"
                            size="large"
                        >
                            <InstagramIcon fontSize="small" />
                        </IconButtonWrapper>
                    </Tooltip> */}
                </Box>
                <Box p={2}>
                    <Grid container spacing={4} paddingTop={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card variant="outlined">
                                <CardActionAreaWrapper sx={{ p: 2, minHeight: '175px' }}>
                                    <PhoneTwoToneIcon sx={{ mb: 1 }} />
                                    <Typography variant="h4">By Phone</Typography>
                                    <Typography variant="subtitle2" noWrap>
                                        1-866-908-4867
                                    </Typography>
                                    <Typography variant="subtitle2" noWrap>
                                        (Monday to Friday 7 am to 5 pm MST)
                                    </Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card variant="outlined">
                                <CardActionAreaWrapper sx={{ p: 2, minHeight: '175px' }}>
                                    <EmailTwoToneIcon sx={{ mb: 1 }} />
                                    <Typography variant="h4">By Email</Typography>
                                    <Typography variant="subtitle2" noWrap>
                                        helpme@vivoslife.com
                                    </Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card variant="outlined">
                                <CardActionAreaWrapper sx={{ p: 2, minHeight: '175px' }}>
                                    <FeedbackTwoToneIcon sx={{ mb: 1 }} />
                                    <Typography variant="h4">Have Feedback?</Typography>
                                    <Typography variant="subtitle2" noWrap>
                                        Have feedback about Vivos Therapeutics? Submit through the form below
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2 }}
                                        onClick={() =>
                                            window.open(
                                                'https://forms.office.com/Pages/ResponsePage.aspx?id=cqIrtcRXDU6CGGl_Iu5AdBFbeOer2g5BgAzHELIdqmdUNEs3NlJISDU0TVYyNzdTOUNVSlU1WkJJQS4u',
                                                '_blank'
                                            )
                                        }
                                    >
                                        Submit Feedback
                                    </Button>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Card>
    );
}

export default ContactUs;
