import React, { ChangeEvent, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    alpha,
    Avatar,
    Button,
    Tabs,
    Tab,
    Grid,
    useTheme,
    Divider,
    Link
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);
const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);
const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
          overflow: visible !important;
  
          .MuiTabs-scroller {
              overflow: visible !important;
          }
  
          .MuiButtonBase-root {
              text-transform: uppercase;
              font-size: ${theme.typography.pxToRem(12)};
  
              &:last-child {
                  margin-right: 0;
              }
          }
      `
);
const ButtonWrapper = styled(Button)(
    ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
    `
);

const AvatarSuccess = styled(Avatar)(
    ({ theme }) => `
          background-color: ${theme.colors.success.main};
          color: ${theme.palette.getContrastText(theme.colors.success.dark)};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          box-shadow: ${theme.colors.shadows.success};
          top: -${theme.spacing(4)};
          position: absolute;
          left: ${theme.spacing(3)};
    `
);

function EducationContent() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const tabs = [
        { value: 'overview', label: 'Overview' },
        { value: 'ceCourses', label: 'Courses & Registration' },
        { value: 'absorb', label: 'Vivos Learning Platform' },
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    return (
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={12} xl={5}>
                    <BoxComposed
                        display="flex"
                        alignItems="center"
                        sx={{
                            minHeight: '100%',
                            background: `${theme.colors.gradients.black1}`
                        }}
                    >
                        <BoxComposedContent px={8} pt={8} pb={4}>
                            <Avatar
                                variant="square"
                                sx={{
                                    mx: 'auto',
                                    mb: 2,
                                    width: 'auto',
                                    height: 200,
                                    '& img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                src="/logos/VivosInstituteLogo.jpg"
                            />
                        </BoxComposedContent>
                    </BoxComposed>
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Box py={3} px={4}>
                        <TabsWrapper
                            centered
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                    </Box>

                    <Divider />
                    {currentTab === 'overview' && (
                        <>
                            <Box py={2}>
                                <Card
                                    sx={{
                                        p: 2,
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    {/* Extended Banner Image */}
                                    {/* This Box contains the banner image with reduced margin below */}
                                    <Box
                                        mb={2} // Reduced margin below the banner
                                        sx={{
                                            width: '100%',
                                            height: 'auto',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                       <img
                                            src="/images/general/knowledgeBase/TVIBannerwebp.webp"
                                            style={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                height: 'auto',
                                                objectFit: 'cover',
                                            }}
                                            />
                                    </Box>

                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        gutterBottom
                                        textAlign="center"
                                    >
                                        Overview
                                    </Typography>
                                    <Divider
                                        sx={{
                                            my: 1.5,
                                            borderColor: 'primary.main',
                                            borderWidth: 1,
                                            width: '50%',
                                            mx: 'auto',
                                        }}
                                    />
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        textAlign="justify"
                                    >
                                        <Typography
                                            variant="body1"
                                            lineHeight={1.5}
                                            gutterBottom
                                            sx={{ maxWidth: 800, textAlign: 'justify' }}
                                        >
                                            The Vivos Institute, officially known as The Vivos Institute Toward the Advancement of Sleep Therapeutics, is a premier private research and training institution located in Denver, Colorado.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            lineHeight={1.5}
                                            gutterBottom
                                            sx={{ maxWidth: 800, textAlign: 'justify' }}
                                        >
                                            Spanning 18,800 square feet, the campus boasts state-of-the-art lecture halls, meeting rooms, and clinical training facilities. The institute offers advanced post-graduate courses, clinical training, and hands-on practical education accredited for continuing education (CE) credits. With over 20 experts on its academic staff, the Vivos Institute has provided more than 17,000 hours of CE to over 500 clinicians and their teams.
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            lineHeight={1.5}
                                            gutterBottom
                                            sx={{ maxWidth: 800, textAlign: 'justify' }}
                                        >
                                            Founded in 2016, the Vivos Institute is dedicated to advancing education in sleep diagnostics and therapeutics. Its Academic Advisory and Curriculum Committee includes professionals from diverse fields across the healthcare ecosystem, ensuring a multidisciplinary approach to education and training.
                                        </Typography>
                                    </Box>

                                    {/* Learn More Button */}
                                    <Box
                                        sx={{
                                            mt: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            href="https://thevivosinstitute.com/"
                                            target="_blank"
                                            sx={{
                                                textTransform: 'none',
                                                px: 3,
                                            }}
                                        >
                                            Learn More About The Vivos Institute
                                        </Button>
                                    </Box>
                                </Card>
                            </Box>
                        </>
                    )}
                    {currentTab === 'ceCourses' && (
                        <>
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    {/* Upcoming Events */}
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 3,
                                                boxShadow: 2,
                                                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                    boxShadow: 4,
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    fontWeight="bold"
                                                    sx={{ color: theme.palette.primary.main, mb: 2 }}
                                                >
                                                    Access Upcoming Events
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    lineHeight="1.6"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Explore and register for upcoming CE courses and events hosted by The Vivos Institute.
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    href="https://tvi.zohobackstage.com/events"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{ textTransform: 'none' }}
                                                >
                                                    View & Register for Events
                                                </Button>
                                            </Box>
                                        </Card>
                                    </Grid>

                                    {/* CE Credits Issue */}
                                    <Grid item xs={12} md={6}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                                padding: 3,
                                                bgcolor: 'background.paper',
                                                borderRadius: 3,
                                                boxShadow: 2,
                                                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
                                                '&:hover': {
                                                    transform: 'scale(1.05)',
                                                    boxShadow: 4,
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography
                                                    variant="h5"
                                                    fontWeight="bold"
                                                    sx={{ color: theme.palette.primary.main, mb: 2 }}
                                                >
                                                    CE Credit Issue?
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    lineHeight="1.6"
                                                    color="text.secondary"
                                                    gutterBottom
                                                >
                                                    Didn't receive your CE credits after attending a course? Submit a ticket to resolve the issue quickly.
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    mt: 2,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    href="https://forms.office.com/Pages/ResponsePage.aspx?id=cqIrtcRXDU6CGGl_Iu5AdBFbeOer2g5BgAzHELIdqmdUQ0RONzNXUU05T1k2QVBGRFZKVzFCSVowUi4u"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{ textTransform: 'none' }}
                                                >
                                                    Submit a Ticket
                                                </Button>
                                            </Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'absorb' && (
    <>
        <Box p={3}>
            <Grid container spacing={3} direction="column">
                {/* What is the Vivos Learning Platform */}
                <Grid item xs={12}>
                    <Card
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            padding: 3,
                            bgcolor: 'background.paper',
                            borderRadius: 3,
                            boxShadow: 2,
                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: 4,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ color: theme.palette.primary.main, mb: 2 }}
                            >
                                What is the Vivos Learning Platform?
                            </Typography>
                            <Typography
                                variant="body2"
                                lineHeight="1.6"
                                color="text.secondary"
                                gutterBottom
                            >
                                The Vivos Educational Platform is a comprehensive learning tool designed to educate and empower Vivos providers. It offers resources and knowledge to successfully implement Vivos solutions in your practice.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                href="https://learning.thevivosinstitute.com/users/sign_in"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ textTransform: 'none' }}
                            >
                                Access the Vivos Learning Platform
                            </Button>
                        </Box>
                    </Card>
                </Grid>

                {/* How to Access the Platform */}
                <Grid item xs={12}>
                    <Card
                        variant="outlined"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            padding: 3,
                            bgcolor: 'background.paper',
                            borderRadius: 3,
                            boxShadow: 2,
                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                boxShadow: 4,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ color: theme.palette.primary.main, mb: 2 }}
                            >
                                How to Access the Platform
                            </Typography>
                            <Typography
                                variant="body2"
                                lineHeight="1.6"
                                color="text.secondary"
                                gutterBottom
                            >
                                If you experience issues logging in, please contact our support team for assistance. They are ready to help you gain access to the platform and all its resources.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                href="mailto:helpme@vivoslife.com"
                                sx={{ textTransform: 'none' }}
                            >
                                Contact Support
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    </>
)}

                </Grid>
            </Grid>
        </Card>
    );
}

export default EducationContent;
