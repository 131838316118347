import { MainStore } from './MainStore';
import { observable, action, makeObservable, computed } from 'mobx';
import ScanData from '../../entities/ScanData';
import ImageItem from '../../entities/ImageItems'
import DictionaryListItem from '../../entities/DictionaryListItem';
import { v4 as uuidv4 } from 'uuid';
import { Gateway } from '../../api/Gateway';
import jwt_decode from 'jwt-decode';

const defaultDictionaryListItem = new DictionaryListItem({
  id: 0,
  name: "",
  listItemKey: "",
  value: "",
  isActive: true,
  dictionaryListId: 0
});
const chunkSize = 1048576 * 10;//its 10MB, increase the number measure in mb

export default class ScanStore {
  mainStore: MainStore;
  constructor(mainstore: MainStore) {
    makeObservable(this);
    this.mainStore = mainstore;

    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      this.userId = tokenData.id;
    }
  }

  @observable userId = 0;
  @observable patientId = 0;
  @observable visitId = 0;
  @observable lg_3Dscantype = defaultDictionaryListItem;
  @observable lg_machinetype = defaultDictionaryListItem;
  @observable lg_2dImages = defaultDictionaryListItem;
  @observable scansdata: ScanData[] = [];
  @observable imageitem3D: ImageItem[] = [];
  @observable showProgress: boolean = false;
  @observable fileSize: number = 0;
  @observable chunkCount: number = 0;
  @observable fileToBeUpload: File = {} as File;
  @observable counter: number = 1;
  @observable beginingOfTheChunk: number = 0;
  @observable endOfTheChunk: number = chunkSize;
  @observable progress: number = 0;
  @observable fileGuid: string = "";  
  @observable cbctMachineBrand: number = 0;
  @observable fov01: number = 0;
  @observable fov02: number = 0;
  @action set3DScanType = (lg_3Dscantype: DictionaryListItem) => { this.lg_3Dscantype = lg_3Dscantype };
  @action setMachineType = (lg_machinetype: DictionaryListItem) => { this.lg_machinetype = lg_machinetype };
  @action set2DImages = (lg_2dImages: DictionaryListItem) => { this.lg_2dImages = lg_2dImages };
  @action setScansdata = (newScanValue: ScanData[]) => { this.scansdata = newScanValue };
  @action setImageItem3D = (newImageItem3D: ImageItem[]) => { this.imageitem3D = newImageItem3D };
  @action setShowProgress = (newValue: boolean) => { this.showProgress = newValue };
  @action setFileSize = (newValue: number) => { this.fileSize = newValue };
  @action setChunkCount = (newValue: number) => { this.chunkCount = newValue };
  @action setFileToBeUpload = (newValue: File) => { this.fileToBeUpload = newValue };
  @action setCounter = (newValue: number) => { this.counter = newValue };
  @action setBeginingOfTheChunk = (newValue: number) => { this.beginingOfTheChunk = newValue };
  @action setEndOfTheChunk = (newValue: number) => { this.endOfTheChunk = newValue };
  @action setProgress = (newValue: number) => { this.progress = newValue };
  @action setFileGuid = (newValue: string) => { this.fileGuid = newValue };
  @action setPatientId = (newValue: number) => { this.patientId = newValue };
  @action setVisitId = (newValue: number) => { this.visitId = newValue };
  @action setCbctMachineBrand = (newValue: number) => { this.cbctMachineBrand = newValue }
  @action setFov01 = (newValue: number) => { this.fov01 = newValue }
  @action setFov02 = (newValue: number) => { this.fov02 = newValue }


  @action addScan = (scan: ScanData) => {
    this.scansdata = [...this.scansdata, scan];
  }
  @action addImage = (imageitem3D: ImageItem) => {
    imageitem3D.imgtype = this.lg_3Dscantype.listItemKey;
    imageitem3D.machine = this.lg_machinetype.listItemKey;

    if (imageitem3D.imgtype.length > 0 && imageitem3D.machine.length > 0) {
      this.imageitem3D = [...this.imageitem3D, imageitem3D];
    }
  }
  @action setScanData = (index: number, scan: ScanData) => {
    this.scansdata[index] = scan;
  }
  @action setImageItems = (index: number, image: ImageItem) => {
    this.imageitem3D[index] = image;
  }

  @computed get getImageItem(): ImageItem[] {
    return this.imageitem3D;
  }

  @action updateCbctDetails = () => {
    return new Promise((resolve, reject) => {
      var promises: any[] = [];
      var details = {
        cbctFov01: this.fov02,
        cbctFov02: this.fov01,
        machineBrandId: this.cbctMachineBrand
      }
      promises.push(Gateway.post(`visitrecord/${this.visitId}/cbctdetails`, details).then(() => { }));

      Promise.all(promises).then(() => {
        resolve(true);
      })

    });
  }

  @action resetScan = () => {
    new ScanData({
      title: ' ',
      scantype: ' ',
      notes: '',
      imageUrl: '',
      rdbVenus: '',
      rdbSelera: '',
      rdbHead: '',
      rdbOpen: '',
      rdbIncomp: '',
      rdbMicrogenia: '',
      rdbMacrogenia: ''
    })
  }

  uploadScan = async (patientId, visitId) => {
    this.setPatientId(patientId);
    this.setVisitId(visitId);
    var p = Promise.resolve(); // Q() in q

    if (this.fileSize > 0) {
      while (this.counter >= this.chunkCount) {
        p = p.then(() => this.fileUpload(this.counter));
      }
       return await p;
    }
  }

  @action getFileContext = (e) => {
    this.resetChunkProperties();
    const _file = e;
    this.setFileSize(_file.size);
    const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
    this.setChunkCount(_totalCount);
    this.setFileToBeUpload(_file);
    const _fileID = uuidv4() + "." + _file.name.split('.').pop();
    this.setFileGuid(_fileID);
  }

  @action resetChunkProperties = () => {
    this.setShowProgress(true)
    this.setProgress(0)
    this.setCounter(1)
    this.setBeginingOfTheChunk(0)
    this.setEndOfTheChunk(chunkSize)
  }

  @action fileUpload = (counter: number) => {
    this.setCounter(counter + 1);
    if (counter <= this.chunkCount) {
      var chunk = this.fileToBeUpload.slice(this.beginingOfTheChunk, this.endOfTheChunk);
      this.uploadChunk(chunk)
    }
  }
  @action convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  @action uploadChunk = async (chunk) => {
    var url = `patient/${this.patientId}/${this.visitId}/${this.userId}/fileuploadchunk`;
    const enc = new TextDecoder('utf-8');
    var chunkyData = {
      chunkNumber: this.counter,
      fileName: this.fileGuid,
      data: await this.convertBase64(chunk)
    };
    const response = await Gateway.post(url, chunkyData).then(async (response) => {
      const data = response as unknown as any;
      if (data.isSuccess) {
        this.setBeginingOfTheChunk(this.endOfTheChunk);
        this.setEndOfTheChunk(this.endOfTheChunk + chunkSize);
        if (this.counter == this.chunkCount) {
          console.log('Process is complete, counter', this.counter)
          await this.uploadCompleted();
        } else {
          var percentage = (this.counter / this.chunkCount) * 100;
          this.setProgress(percentage);
        }
      } else {
        console.log('Error Occurred:', data.errorMessage)
      }
    });

  }

  @action uploadCompleted = async () => {
    var url = `patient/${this.patientId}/${this.visitId}/${this.userId}/fileuploadchunkcomplete`;
    var formData = {
      filename: this.fileGuid,
    };
    var chunkyData = {
      fileName: this.fileGuid,
      data: ""
    };
    await Gateway.post(url, chunkyData).then((response) => {
      const data = response as unknown as any;
      if (data.isSuccess) {
        this.setProgress(100);
      }
    })
  }

}
