import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { MainStore } from "./MainStore";

import jwt_decode from 'jwt-decode';
import { Gateway } from "../../api/Gateway";
import Laboratory from "../../entities/Laboratory";
import { ClaimType, LabManagementFilters } from '../../entities/Enums';
import _ from "lodash";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import moment from "moment";
import buildQuery from 'odata-query';

export class LabManagementStore {
    @observable teamMembers: any[] = [];
    mainStore: MainStore;
    userId: number = 0;
    roles: any = [];
    pageSize: number = 200;

    private _isAISAdminManager = false;
    private _isAISSupervisor = false;
    private _isAISAdmin = false;
    private _totalRows: number = 0;
    private _totalPages: number = 1;
    private _currentPage: number = 0;
    private _currentRow: any = {};
    private _currentFilter: LabManagementFilters | -1 = -1;
    private _currentSubNavItem: number = 0;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.checkRoles();
    }
    @observable pageTitle = 'Laboratory Management Dashboard';
    @observable isLoadingData = false;
    @action setisLoadingData = (newValue: boolean) => {
        this.isLoadingData = newValue;
    }
    @observable didInitLabManagement = false;
    @observable searchParam = '';
    //@observable filter: LabManagementFilters | -1 = -1;
    @observable filter: any;
    @observable sort: any;
    @observable isLeftNavExpanded: boolean = false;
    @observable toggleShowFilterResults: boolean = false;
    //@observable labManagementData: Laboratory[] = [];
    //@observable origLabManagementData: Laboratory[] = [];
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable numProductionReady: number = 0;
    @observable numShipped: number = 0;
    @observable numInFabrication: number = 0;
    @observable numCasesOverSevenDays: number = 0;
    @observable numNewPrescriptions: number = 0;
    @observable numBitesOnHold: number = 0;
    @observable numImpressionsOnHold: number = 0;
    @observable numWarranties: number = 0;
    @observable numRepairs: number = 0;
    @observable numDays = 5;
    @observable statusTitle = 'Since Yesterday';

    @observable origLabManagementData: Laboratory[] = [];
    @action setOrigLabManagementData = newLabManagementData => (this.origLabManagementData = newLabManagementData);

    @observable labManagementData: Laboratory[] = [];
    @action setLabManagementData = newLabManagementData => (this.labManagementData = newLabManagementData);

    @observable currentRow: any = {};
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);
    //@action setOrigPatientData = newPatientData => (this.origLabManagementData = newPatientData);

    @observable treatmentDashboardData: any[] = [];
    @action setTreatmentDashboardData = (data: any) => {
        this.treatmentDashboardData = data;
    }
    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };
    @action toggleFilterResultsExpand = () => {
        this.toggleShowFilterResults = !this.toggleShowFilterResults;
    };

    @observable AISUsers: any[] = [];
    @action setAISUsers = (newValue: any[]) => {
        this.AISUsers = newValue;
    }

    get isAISAdminManager(): any {
        return this._isAISAdminManager;
    }
    set isAISAdminManager(value: any) {
        this._isAISAdminManager = value;
    }
    get isAISSupervisor(): any {
        return this._isAISSupervisor;
    }
    set isAISSupervisor(value: any) {
        this._isAISSupervisor = value;
    }
    get isAISAdmin(): any {
        return this._isAISAdmin;
    }
    set isAISAdmin(value: any) {
        this._isAISAdmin = value;
    }
    get totalRows(): number {
        return this._totalRows;
    }
    set totalRows(value: any) {
        this._totalRows = value;
    }
    set totalPages(value: any) {
        this._totalPages = value;
    }
    get currentPage(): number {
        return this._currentPage;
    }
    set currentPage(value: any) {
        this._currentPage = value;
    }
    get currentFilter(): LabManagementFilters | -1 {
        return this._currentFilter;
    }
    set currentFilter(value: any) {
        this._currentFilter = value;
    }
    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }
    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) {
                this.isAISAdminManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR) {
                this.isAISSupervisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN) {
                this.isAISAdmin = true;
            }
        }
        return;
    };
    loadFilterCount = async () => {
        const sevenDays = new Date();
        sevenDays.setDate(sevenDays.getDate() - 7);
        let query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ submissionDate: { le: sevenDays } }, { productionStatus: { ne: 'Cancelled' } }, { productionStatus: { ne: 'Shipped' } }] },
            count: true,
        });
        let url = `odata/labDashboard/${this.userId}?${query}`;
        let resp = await Gateway.get(url);
        const casesOver7Days = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ productionStatus: { eq: 'In Fabrication' } }] },
            count: true,
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const inFabrication = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ productionStatus: { eq: 'Shipped' } }] },
            count: true,
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const shipped = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ productionStatus: { eq: 'Ready' } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const productionReady = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ DLILabBiteStatus: { eq: 'Hold' } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const biteHolds = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { and: [{ dliModelStatus: { eq: 'Hold' } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const impressionHolds = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: {
                and: [
                    { rxStatus: { eq: 'Pending' } },
                    {
                        or: [
                            { dliQcstatus: { eq: 'Pending' } },
                            { dliQcstatus: { eq: 'Hold' } }
                        ]
                    }
                ]
            },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const newRx = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { or: [{ claimTypeId: { eq: ClaimType.Warranty } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const warranties = resp['@odata.count'];

        query = buildQuery({
            top: 0,
            skip: 0,
            filter: { or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] },
            count: true
        });

        url = `odata/labDashboard/${this.userId}?${query}`;
        resp = await Gateway.get(url);
        const repairs = resp['@odata.count'];

        runInAction(() => {
            this.numCasesOverSevenDays = casesOver7Days;
            this.numInFabrication = inFabrication;
            this.numShipped = shipped;
            this.numProductionReady = productionReady;
            this.numNewPrescriptions = newRx;
            this.numBitesOnHold = biteHolds;
            this.numImpressionsOnHold = impressionHolds;
            this.numWarranties = warranties;
            this.numRepairs = repairs;
        });
    }

    loadTreatmentPage = async (): Promise<any> => {
        let skip = this.currentPage * this.pageSize;
        let query = buildQuery({
            top: this.pageSize,
            skip,
            //filter: this.searchParam ? textSearch : {},
            count: true,
            orderBy: ['productionStatusId', 'modifiedOn asc', 'createdOn asc']
        });
        const url = `odata/labDashboard/${this.userId}?${query}`;
        const resp = await Gateway.get(url);
        const totalRowsFromService = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;
        let data = resp['value'];
        data.forEach((item: any) => {
            const today = new Date();
            const submission = new Date(item.submissionDate)
            const daysInQueue = Math.ceil((Math.abs(today.getTime() - submission.getTime())) / (1000 * 60 * 60 * 24)) - 1;
            item.daysInQueue = daysInQueue;
        });
        runInAction(() => {
            this.totalRows = totalRowsFromService;
            this.totalPages = totalRowsFromService;
            this.setTreatmentDashboardData(data);
        });
        return data;

    };

    loadPage = async () => {
        runInAction(() => {
            this.isLoadingData = true;
        });
        let skip = this.currentPage * this.pageSize;
        let query = '';

        const textSearch = [{ or: [{ rxId: { contains: this.searchParam.toLowerCase() } }, { patient: { contains: this.searchParam.toLowerCase() } }, { provider: { contains: this.searchParam.toLowerCase() } }] }];


        switch (this.currentFilter) {
            case LabManagementFilters.CasesOver7Days:
                const sevenDays = new Date();
                sevenDays.setDate(sevenDays.getDate() - 7);
                query = buildQuery({
                    top: this.pageSize,
                    orderBy: this.sort ? this.sort : {},
                    skip: skip,
                    filter: this.searchParam ?
                        { and: [{ submissionDate: { le: sevenDays } }, { productionStatus: { ne: 'Shipped' } }, { productionStatus: { ne: 'Cancelled' } }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { and: [{ submissionDate: { le: sevenDays } }, { productionStatus: { ne: 'Shipped' } }, { productionStatus: { ne: 'Cancelled' } }, this.filter ? this.filter : {}] },
                    count: true,
                });

                break;
            case LabManagementFilters.InFabrication:
                query = buildQuery({
                    top: this.pageSize,
                    orderBy: this.sort ? this.sort : {},
                    skip,
                    filter: this.searchParam ?
                        { and: [{ productionStatus: { eq: 'In Fabrication' } }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { and: [{ productionStatus: { eq: 'In Fabrication' } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.Shipped:
                query = buildQuery({
                    top: this.pageSize,
                    orderBy: this.sort ? this.sort : {},
                    skip,
                    filter: this.searchParam ?
                        { and: [{ productionStatus: { eq: 'Shipped' } }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { and: [{ productionStatus: { eq: 'Shipped' } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.ProductionReady:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ productionStatus: { eq: 'Ready' } }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { and: [{ productionStatus: { eq: 'Ready' } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.BitesOnHold:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ DLILabBiteStatus: { eq: 'Hold' } }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { and: [{ DLILabBiteStatus: { eq: 'Hold' } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.ImpressionsOnHold:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ dliModelStatus: { eq: 'Hold' } }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { and: [{ dliModelStatus: { eq: 'Hold' } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.NewPrescriptions:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam
                        ? {
                            and: [
                                { rxStatus: { eq: 'Pending' } },
                                ...(this.filter ? [this.filter] : []),
                                {
                                    or: [
                                        { dliQcstatus: { eq: 'Pending' } },
                                        { dliQcstatus: { eq: 'Hold' } }
                                    ]
                                },
                                ...textSearch
                            ]
                        }
                        : {
                            and: [
                                { rxStatus: { eq: 'Pending' } },
                                ...(this.filter ? [this.filter] : []),
                                {
                                    or: [
                                        { dliQcstatus: { eq: 'Pending' } },
                                        { dliQcstatus: { eq: 'Hold' } }
                                    ]
                                }
                            ]
                        },
                    count: true,
                });
                break;
            case LabManagementFilters.Warranty:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ or: [{ claimTypeId: { eq: ClaimType.Warranty } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { or: [{ claimTypeId: { eq: ClaimType.Warranty } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.Repair:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }] }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            case LabManagementFilters.AllClaims:
                query = buildQuery({
                    orderBy: this.sort ? this.sort : {},
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ?
                        { and: [{ or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }, { claimTypeId: { eq: ClaimType.Warranty } }] }, [this.filter ? this.filter : {}, ...textSearch]] } :
                        { or: [{ claimTypeId: { eq: ClaimType.Repair } }, { claimTypeId: { eq: ClaimType["Warranty & Repair"] } }, { claimTypeId: { eq: ClaimType.Warranty } }, this.filter ? this.filter : {}] },
                    count: true,
                });
                break;
            default:
                query = buildQuery({
                    top: this.pageSize,
                    skip,
                    filter: this.searchParam ? [this.filter ? this.filter : {}, ...textSearch] : this.filter ? this.filter : {},
                    count: true,
                    orderBy: this.sort ? this.sort : ['productionStatusId', 'modifiedOn asc', 'createdOn asc']
                });
                break;
        }
        const url = `odata/labDashboard/${this.userId}?${query}`;
        //const url = `odata/labDashboard/40?${query}`;
        const resp = await Gateway.get(url);
        this.totalRows = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;
        let data = resp['value'];
        /*
        if (data !== undefined) {
            data.forEach((item: any) => {
                const today = new Date();
                const submission = new Date(item.submissionDate)
                const daysInQueue = Math.ceil((Math.abs(today.getTime() - submission.getTime())) / (1000 * 60 * 60 * 24)) - 1;
                item.daysInQueue = daysInQueue;
            });
        }
        */

        runInAction(() => {
            if (this.didInitLabManagement) {
                this.didInitLabManagement = false;
            }
            this.isLoadingData = false;
            this.totalPages = this.totalRows / this.pageSize;
            this.labManagementData = data;
            this.origLabManagementData = this.labManagementData;
        });
    };

    loadAISUsers = (): any => {
        Gateway.get(`lab/${this.userId}/aisusers`).then(resp => {
            this.setAISUsers(resp);
        });

        return;
    };

    loadClaimData = async (claimId: string) => {
        runInAction(() => { this.isLoadingData = true; });
        const query = buildQuery({ filter: { id: { eq: claimId } } });
        const url = `odata/reworkClaims/${this.userId}?${query}`;
        const resp = await Gateway.get(url);
        let data = resp['value'];
        runInAction(() => { this.isLoadingData = false; });
        return data[0];
    };

    clearData = () => {
        runInAction(() => {
            this.searchParam = '';
            this.currentFilter = -1;
            this.loadPage();
        });
    };

    loadLabData = async (rxId: string) => {
        runInAction(() => { this.isLoadingData = true; });
        const query = buildQuery({ filter: { id: { eq: rxId } } });
        const url = `odata/labDashboard/${this.userId}?${query}`;
        const resp = await Gateway.get(url);
        let data = resp['value'];
        runInAction(() => { this.isLoadingData = false; });
        return data[0];
    };

    onSubNavClick = (filter: LabManagementFilters, index: number) => {
        runInAction(() => {
            this.currentFilter = filter;
            this.currentPage = 0;
            this.loadPage();
            this.currentSubNavItem = index;
        });
    };

    getSearchData = (searchParam: string): any => {
        runInAction(() => {
            this.searchParam = searchParam;
        });
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.loadPage();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.loadPage();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.labManagementData;
    };

    onFilter = (value, filter: LabManagementFilters) => {
        if (value) {
            this.currentFilter = filter;
        } else {
            this.currentFilter = -1;
            this.labManagementData = this.origLabManagementData;
            this.filter = -1;
        }
        this.currentPage = 0;
        this.loadPage();
    };

    handleProductionStatusChange = (changedPrescription: any, event: any) => {
        var url = '/prescription/' + this.userId + '/' + changedPrescription.id + '/productionStatusWithException/' + event;
        var ind = -1;

        Gateway.put(url, undefined).then((respData: any | any[]) => {
            if (Array.isArray(respData)) {
                // Assume each item in the array is of a specific structure
                const errorObject = respData.find((item: { errorMessage?: string }) => item.errorMessage);
                if (errorObject) {
                    alert(`Error: ${errorObject.errorMessage}`);
                    return; // Stop further processing
                }
            } else if (respData.errorMessage) {
                // If respData is a single object
                alert(`Error: ${respData.errorMessage}`);
                return; // Stop further processing
            }

            ind = _.findIndex(this.labManagementData, c => {
                return c.id === respData['id'];
            });
            var newLabData = this.labManagementData.reduce((newData, currentLabData, index) => {
                const copiedPrescription: any = { ...currentLabData };
                if (ind === index) {
                    copiedPrescription.productionStatusId = respData['productionStatus'];
                }
                return [...newData, copiedPrescription];
            }, [] as any[]);

            this.setLabManagementData(newLabData);

            ind = _.findIndex(this.origLabManagementData, c => {
                return c.id === respData['id'];
            });
            newLabData = this.origLabManagementData.reduce((newData, currentLabData, index) => {
                const copiedPrescription: any = { ...currentLabData };
                if (ind === index) {
                    copiedPrescription.productionStatusId = respData['productionStatus'];
                }
                return [...newData, copiedPrescription];
            }, [] as any[]);
            this.setOrigLabManagementData(newLabData);
        });
    };


    /*
    handleProductionStatusChange = (changedPrescription, event) => {
        var url = '/prescription/' + this.userId + '/' + changedPrescription.id + '/productionStatusWithException/' + event;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.labManagementData, c => {
                    return c.id === respData['id'];
                });
                var newLabData = this.labManagementData.reduce((newData, currentLabData, index) => {
                    const copiedPrescription: any = { ...currentLabData };
                    if (ind === index) {
                        copiedPrescription.productionStatusId = respData['productionStatus'];
                    }
                    return [...newData, copiedPrescription];
                }, [] as any[]);

                this.setLabManagementData(newLabData);

                ind = _.findIndex(this.origLabManagementData, c => {
                    return c.id === respData['id'];
                });
                newLabData = this.origLabManagementData.reduce((newData, currentLabData, index) => {
                    const copiedPrescription: any = { ...currentLabData };
                    if (ind === index) {
                        copiedPrescription.productionStatusId = respData['productionStatus'];
                    }
                    return [...newData, copiedPrescription];
                }, [] as any[]);
                this.setOrigLabManagementData(newLabData);
            }
        });
    };
    */

    handleQCUserChange = (changedItem, event) => {
        var isReworkClaim = changedItem.rxId.includes('C') || changedItem.rxId.includes('W') || changedItem.rxId.includes('R');
        var url = isReworkClaim ?
            '/reworkClaim/' + this.userId + '/' + changedItem.id + '/assignedQC/' + event.target.value :
            '/prescription/' + this.userId + '/' + changedItem.id + '/assignedQC/' + event.target.value;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.labManagementData, c => {
                    return c.id === respData['id'];
                });
                var assignedToUser = this.AISUsers.find(x => x.userID === (isReworkClaim ? respData['assignedTo'] : respData['assignedAdmin']));
                var newLabData = this.labManagementData.reduce((newData, currentLabData, index) => {
                    const copiedPrescription: any = { ...currentLabData };
                    if (ind === index) {
                        copiedPrescription.assignedTo = assignedToUser.userID;
                        copiedPrescription.assignedToName = assignedToUser.firstName + ' ' + assignedToUser.lastName;
                    }
                    return [...newData, copiedPrescription];
                }, [] as any[]);
                this.setLabManagementData(newLabData);

                ind = _.findIndex(this.origLabManagementData, c => {
                    return c.id === respData['id'];
                });
                newLabData = this.origLabManagementData.reduce((newData, currentLabData, index) => {
                    const copiedPrescription: any = { ...currentLabData };
                    if (ind === index) {
                        copiedPrescription.assignedTo = assignedToUser.userID;
                        copiedPrescription.assignedToName = assignedToUser.firstName + ' ' + assignedToUser.lastName;
                    }
                    return [...newData, copiedPrescription];
                }, [] as any[]);

                this.setOrigLabManagementData(newLabData);
            }
        });
    };


    onSortChange = (sortModel: GridSortModel): any => {
        runInAction(() => {
            if (Object.keys(sortModel).length > 0) {
                runInAction(() => {
                    this.sort = [sortModel[0].field + ' ' + sortModel[0].sort];
                });
            } else {
                this.sort = {};
            }
        });
    };

    @observable didInitClaims = false;

    onFilterChange = (filterModel: GridFilterModel): any => {
        runInAction(() => {
            let searchFilter = this.buildFilter(filterModel);
            if (Object.keys(searchFilter).length > 0) {
                runInAction(() => {
                    this.filter = searchFilter;
                    this.didInitClaims = true;
                });
            } else {
                this.filter = {};
            }
        });
    };

    buildFilter = (filterModel: GridFilterModel): any => {
        let customFilter = {};
        let linkOperator = filterModel.linkOperator !== undefined ? filterModel.linkOperator : '';
        customFilter[linkOperator] = [];
        filterModel.items.forEach(filter => {
            let currentOdata = {};
            let operator = {};
            let operatorValue = filter.operatorValue ? filter.operatorValue.toLowerCase() : '';
            let shouldNotAdd = true;
            let funcProperty = filter.columnField;
            let typeColumn = 'string';
            let actualValue;

            if (filter.columnField.toLowerCase().includes('date')) {
                typeColumn = 'date';
            }

            if ((filter.columnField.includes('id') || filter.columnField.includes('days')) && !filter.columnField.includes('provider')) {
                typeColumn = 'number';
            }

            if (filter.columnField.includes('Id') && !filter.columnField.includes('provider')) {
                switch (filter.columnField) {
                    case 'assignedToId':
                        if (filter.value) {
                            actualValue = this.teamMembers.filter(
                                x =>
                                    x.firstName === filter.value.split(' ')[0] &&
                                    x.lastName === filter.value.split(' ')[1],
                            )[0].userID;
                            typeColumn = 'list';
                        }
                        break;
                    default:
                        break;
                }
            }
            if (typeColumn === 'date') {
                switch (filter.operatorValue) {
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'not':
                        operator['ne'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'after':
                        operator['gt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrAfter':
                        operator['ge'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'before':
                        operator['lt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrBefore':
                        operator['le'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'list') {
                switch (filter.operatorValue) {
                    case 'is':
                        operator['eq'] = actualValue;
                        break;
                    case 'not':
                        operator['ne'] = actualValue;
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'number' && filter.value) {

                switch (filter.operatorValue) {
                    case '=':
                        operator['eq'] = { type: 'raw', value: filter.value };
                        break;
                    case '!=':
                        operator['ne'] = { type: 'raw', value: filter.value };
                        break;
                    case '>':
                        operator['gt'] = { type: 'raw', value: filter.value };
                        break;
                    case '>=':
                        operator['ge'] = { type: 'raw', value: filter.value };
                        break;
                    case '<':
                        operator['lt'] = { type: 'raw', value: filter.value };
                        break;
                    case '<=':
                        operator['le'] = { type: 'raw', value: filter.value };
                        break;
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    default:
                        break;
                }
            } else {
                switch (filter.operatorValue) {
                    case 'contains':
                    case 'startsWith':
                    case 'endsWith':

                        operator[operatorValue] = filter.value;
                        break;
                    case 'equals':
                        operator['eq'] = filter.value;
                        break;
                    case 'isEmpty':
                        shouldNotAdd = false;
                        operator['lt'] = 1;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isNotEmpty':
                        shouldNotAdd = false;
                        operator['gt'] = 0;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = filter.value;
                        break;
                    case 'not':
                        operator['ne'] = filter.value;
                        break;
                    default:
                        break;
                }
            }

            if (shouldNotAdd) {
                currentOdata[funcProperty] = operator;
            }
            customFilter[linkOperator].push(currentOdata);
        });
        return customFilter;
    };
}