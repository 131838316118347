// @vendors
import React from "react";
import {observer} from "mobx-react";
import { Grid, Button } from "@mui/material";
import { GridColDef } from '@mui/x-data-grid-pro';

// @components
import { LeftNav } from "../components/LeftNav/LeftNav";
import {GridHeaderTitle} from "../components/GridHeader/GridHeaderTitle";
import DataGrid from "../components/DataGrid/DataGrid";

// @stores
import {useMainStoreContext} from "../stores/OldStores/MainStore";
import {DashboardInfoBox} from "../components/DashboardInfoBox/DashboardInfoBox";


export const PendingCases: React.FC = observer( () => {

    const {
        pageTitle,
        toggleLeftNav,
        isLeftNavExpanded
    } = useMainStoreContext().pendingCasesStore;

    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Case Number', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'provider', headerName: 'Provider', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'patient', headerName: 'Patient', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'status', headerName: 'Status', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'technician', headerName: 'Technician', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'days', headerName: 'Days in Queue', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
        { field: 'report', headerName: 'Report Submit Date', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "center" },
    ]


    const hdrButtons: any = [
        (<Button className='vivButton large' onClick={() => { }}>FILTER RESULTS</Button>)
    ];


    return (
        <>
            <Grid xl={12} container direction="row" className='page'>
                <LeftNav onToggle={toggleLeftNav} />
                <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')} >
                    <Grid >
                        <GridHeaderTitle
                            title={pageTitle}
                            onChange={() => { }}
                            filterClass='filter'
                            onClear={() => { }}
                            onCancel={() => { }}
                            onFilter={() => { }}
                            numResults={0}

                            buttons={hdrButtons}
                        />
                    </Grid>

                    <Grid container direction="row" sx={{ marginBottom: "15px;" }} justifyContent={"space-between"}>
                        <DashboardInfoBox title={"Pending"} filter={0} value={0} currentFilter={0} onFilter={() => {  }} />
                    </Grid>

                    <Grid className='grid'>
                        <DataGrid
                            data={[]}
                            columns={columns}
                        />
                </Grid>
            </Grid>
        </Grid>
        </>
    )
})
