
interface PatientEpworthData {
    id: number,
    patientId: number,
    visitId: number,
    epworthPropertyId: number,
    value: number,
    displayName: string,
    isActive: boolean,
}

interface PatientEpworth extends PatientEpworthData {}

class PatientEpworth {
    constructor({id,patientId,visitId,epworthPropertyId, value,isActive}: PatientEpworthData) {
        this.id = id;
        this.patientId = patientId;
        this.visitId= visitId;
        this.epworthPropertyId = epworthPropertyId;
        this.value = value;
        this.isActive = isActive;
    }

    toObject(): PatientEpworthData {
        return {...this};
    }
}
export default PatientEpworth;