import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, TextField, Grid, IconButton, Typography, FormControlLabel, Checkbox, Card } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Delete, Download, Save } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SleepStudyProperties } from '../../entities/Enums';

interface FileAccordionProps {
  filename: string;
  index: number;
  reportValues: any[];
  onSave: (index: number, data: any) => void;
  onDelete: (index: number) => void;
  onDownload: (index: number) => void;
  isEditable?: boolean;
}

const FileAccordion: React.FC<FileAccordionProps> = ({ filename, index, reportValues, isEditable, onSave, onDelete, onDownload }) => {
  const [localReportValues, setLocalReportValues] = useState([...reportValues]);
  const [checkboxState, setCheckboxState] = useState(
    localReportValues.reduce((acc, report) => {
      acc[report.PropertyId] = report.Value !== null ? report.Value.toString() : report.Value;
      return acc;
    }, {})
  );

  const handleCheckboxChange = (event, propertyId) => {
    const isChecked = event.target.checked;
    const newState = {
      ...checkboxState,
      [propertyId]: isChecked.toString(),
    };

    if (propertyId === SleepStudyProperties['No Diagnosis'] && isChecked) {
      ['Obstructive Sleep Apnea', 'Upper Airway Resistence Syndrome', 'Central Sleep Apnea', 'Other'].forEach((id) => {
        newState[SleepStudyProperties[id]] = "false";
      });
    } else if (
      ['Obstructive Sleep Apnea', 'Upper Airway Resistence Syndrome', 'Central Sleep Apnea', 'Other']
        .some((id) => propertyId === SleepStudyProperties[id] && isChecked)
    ) {
      newState[SleepStudyProperties['No Diagnosis']] = "false";
    }

    setCheckboxState(newState);

    const updatedValues = localReportValues.map((report) => {
      if (report.PropertyId === propertyId) {
        return { ...report, Value: isChecked.toString() };
      }

      if (propertyId === SleepStudyProperties['No Diagnosis'] && isChecked) {
        if (['Obstructive Sleep Apnea', 'Upper Airway Resistence Syndrome', 'Central Sleep Apnea', 'Other'].includes(report.Name)) {
          return { ...report, Value: "false" };
        }
      }

      if (
        ['Obstructive Sleep Apnea', 'Upper Airway Resistence Syndrome', 'Central Sleep Apnea', 'Other']
          .some((id) => propertyId === SleepStudyProperties[id] && isChecked)
      ) {
        if (report.Name === 'No Diagnosis') {
          return { ...report, Value: "false" };
        }
      }

      return report;
    });

    setLocalReportValues(updatedValues);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValues = localReportValues.map(report =>
      report.PropertyId === Number(e.target.name) ? { ...report, Value: e.target.value } : report
    );
    setLocalReportValues(updatedValues);
  };

  const handleDateChange = (event, propertyId) => {
    const updatedValues = localReportValues.map(report =>
      report.PropertyId === Number(propertyId) ? { ...report, Value: event } : report
    );
    setLocalReportValues(updatedValues);
  };

  const handleDownload = () => {
    onDownload(index);
  };
  const handleSave = () => {
    onSave(index, localReportValues);
  };

  const handleDelete = () => {
    onDelete(index);
  }

  useEffect(() => {
    setLocalReportValues([...reportValues]);
  }, [reportValues]);

  return (
    <Grid padding={1}>
      <Card>
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              backgroundColor: "#e3f2fd",
              padding: "12px 16px",
              "&:hover": {
                backgroundColor: "#d0ebff"
              }
            }}
            aria-controls={`panel${index}-content`} id={`panel${index}-header`}
          >
            {filename}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid xs={12} paddingLeft={3}>
                <Grid container padding={5}>
                  <Grid item xs={12}>
                    <Grid container spacing={3} paddingLeft={3} paddingRight={3} paddingTop={1} marginTop={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Sleep Study Data
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={9}>
                        <Grid container spacing={3}>
                          {localReportValues
                            .sort((a, b) => a.id - b.id)
                            .map((report, i) => {
                              if (
                                report.PropertyId === SleepStudyProperties['Sleep Study Date'] ||
                                report.PropertyId === SleepStudyProperties['Patient Records Date'] ||
                                report.PropertyId === SleepStudyProperties['AHI 3%'] ||
                                report.PropertyId === SleepStudyProperties['AHI 4%'] ||
                                report.PropertyId === SleepStudyProperties['RDI 3%'] ||
                                report.PropertyId === SleepStudyProperties['RDI 4%'] ||
                                report.PropertyId === SleepStudyProperties['ODI 3%'] ||
                                report.PropertyId === SleepStudyProperties['ODI 4%'] ||
                                report.PropertyId === SleepStudyProperties['Sleep Test Type'] ||
                                report.PropertyId === SleepStudyProperties['Sleep Min'] ||
                                report.PropertyId === SleepStudyProperties['Treatment Stage']
                              ) {
                                const inputComponent = report.Name.toLowerCase().includes('date') ? (
                                  <Grid item xs={12} md={4} key={`datepicker${index}-${i}`}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        label={report.Name}
                                        value={report.Value}
                                        openTo="day"
                                        disableFuture={true}
                                        views={['year', 'month', 'day']}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={report.Name}
                                            name={report.PropertyId.toString()}
                                            fullWidth
                                            required={report.Name === 'Sleep Study Date'}
                                            value={report.Value}
                                          />
                                        )}
                                        onChange={(event) => { handleDateChange(event, report.PropertyId) }}
                                      //disabled={!isEditable}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} md={4} key={`text-${report.Name}${index}-${i}`}>
                                    <TextField
                                      label={report.Name}
                                      name={report.PropertyId.toString()}
                                      value={report.Value}
                                      onChange={handleTextChange}
                                      fullWidth
                                    // disabled={!isEditable}
                                    />
                                  </Grid>
                                );
                                return inputComponent;
                              }
                            })}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <Grid container direction={"column"}>
                          <Grid item paddingBottom={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkboxState[SleepStudyProperties['This sleep test was done using an oral appliance']] === "true"}
                                  onChange={(event) => handleCheckboxChange(event, SleepStudyProperties['This sleep test was done using an oral appliance'])}
                                  name="checkbox.oralAppliance"
                                />
                              }
                              label="This sleep test was done using an oral appliance"
                            />
                          </Grid>
                          <Grid item paddingBottom={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkboxState[SleepStudyProperties['Read by Qualified Sleep Physician']] === "true"}
                                  onChange={(event) => handleCheckboxChange(event, SleepStudyProperties['Read by Qualified Sleep Physician'])}
                                  name="checkbox.qualifiedPhysician"
                                />
                              }
                              label="Read by Qualified Sleep Physician"
                            />
                          </Grid>
                          {checkboxState[SleepStudyProperties['Read by Qualified Sleep Physician']] === "true" && (
                            <Grid container direction={"column"} paddingLeft={3}>
                              {localReportValues
                                .sort((a, b) => a.id - b.id)
                                .map((report, i) => {
                                  if (
                                    report.PropertyId !== SleepStudyProperties['This sleep test was done using an oral appliance'] &&
                                    report.PropertyId !== SleepStudyProperties['Read by Qualified Sleep Physician'] &&
                                    (
                                      report.PropertyId === SleepStudyProperties['No Diagnosis'] ||
                                      report.PropertyId === SleepStudyProperties['Obstructive Sleep Apnea'] ||
                                      report.PropertyId === SleepStudyProperties['Upper Airway Resistence Syndrome'] ||
                                      report.PropertyId === SleepStudyProperties['Central Sleep Apnea'] ||
                                      report.PropertyId === SleepStudyProperties.Other
                                    )
                                  ) {
                                    return (
                                      <Grid item key={`checkbox-${i}`} paddingBottom={1}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={checkboxState[report.PropertyId] === "true"}
                                              onChange={(event) => handleCheckboxChange(event, report.PropertyId)}
                                              name={"checkbox." + i}
                                            />
                                          }
                                          label={report.Name}
                                        />
                                      </Grid>
                                    );
                                  }
                                })}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} paddingLeft={3} paddingRight={3} paddingTop={1} marginTop={1}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Advanced Data
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={9}>
                        <Grid container spacing={3}>
                          {localReportValues
                            .sort((a, b) => a.id - b.id)
                            .map((report, i) => {
                              if (
                                report.PropertyId === SleepStudyProperties['Average Oxygen Saturation'] ||
                                report.PropertyId === SleepStudyProperties['Average BPM'] ||
                                report.PropertyId === SleepStudyProperties['Average Snore DB'] ||
                                report.PropertyId === SleepStudyProperties.REM ||
                                report.PropertyId === SleepStudyProperties['O2 Nadir'] ||
                                report.PropertyId === SleepStudyProperties['Sleep Latency']
                              ) {
                                const inputComponent =
                                  <Grid item xs={12} md={4} key={`text-${report.Name}${index}-${i}`}>
                                    <TextField
                                      label={report.Name}
                                      name={report.PropertyId.toString()}
                                      value={report.Value}
                                      onChange={handleTextChange}
                                      fullWidth
                                    //disabled={!isEditable}
                                    />
                                  </Grid>
                                  ;
                                return inputComponent;
                              }
                            })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>



            <>
              <IconButton onClick={handleDownload}>
                <Download className="cornerBtn" />
              </IconButton>
              <IconButton onClick={handleSave}>
                <Save className="cornerBtn" />
              </IconButton>
              {isEditable && <IconButton onClick={handleDelete}>
                <Delete className="cornerBtn" />
              </IconButton>}
            </>

          </AccordionDetails>
        </Accordion >
      </Card>
    </Grid>

  );
};

export default FileAccordion;