// Vendors
import react, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Typography,
    TextField,
    styled,
    List,
    alpha,
    ListItem,
    ListItemText,
    Button
} from '@mui/material';

// Component
import VidaConstruction from './Appliances/VidaConstruction';
import VidaSleepConstruction from './Appliances/VidaSleepConstruction';
import PexConstruction from './Appliances/PexConstruction';
import CareConstruction from './Appliances/CareConstruction';
import RxDiagramDropzone from './RxDiagramDropzone';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { RxAppliances } from '../../../entities/Enums';

// API
import { observer } from 'mobx-react';
import RxConstructionContainer from "./RxConstructionContainer";
import { ApplianceItemSetup } from "../../../models/state_models/applianceItemSetup";
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";


const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
      position: absolute;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(1)};
    `
);



const RxConstruction = () => {
    //const { selectedAppliance, selectedeCommerceItem, setSelectedeCommerceItem, providerType,setVariantsSelected, isUpdating,setConstructionComments,setIsConstructionTypeApplicable,setConstructionType } = usePrescriptionStore().prescriptionStore;
    const { isUpdating, selectedAppliance, selectedeCommerceItem, constructionComments, setConstructionComments, setSelectedeCommerceItem, providerType, setVariantsSelected,
        selectedConstructionType, setSelectedConstructionType, applianceFamiliesSetups, clearProductsToCart, setShouldCancelOrder, variantsSelected, prescription,
        setApplianceConstructionDiagramFilename, setIsConstructionComplete } = usePrescriptionStore().prescriptionStore;
    const teethChartStore = useMainStoreContext().teethChartStore;
    //const [constructionType, setConstructionType] = useState('');
    const [isConstructionTypeApplicable, setIsConstructionTypeApplicable] = react.useState(false);
    const [componentVariance, setComponentVariance] = useState<Record<string, string>>({})

    useEffect(() => {
        if (isUpdating) {
            setComponentVariance(selectedeCommerceItem.eCommerceShopifyItem.variantsSelected);
            setSelectedeCommerceItem(selectedeCommerceItem);
            SetSelectedVariantLogic(selectedeCommerceItem, selectedeCommerceItem.eCommerceShopifyItem.variantsSelected);
            setConstructionComments(prescription?.constructionComments);
            setApplianceConstructionDiagramFilename(prescription?.diagramUrl);
            if (selectedAppliance.name.trim() === RxAppliances.DNA ||
                selectedAppliance.name.trim() === RxAppliances.MRNA ||
                selectedAppliance.name.trim() === RxAppliances.MMRNA ||
                selectedAppliance.name.trim() === RxAppliances.VIDA ||
                selectedAppliance.name.trim() === RxAppliances.VIDASLEEP ||
                selectedAppliance.name.trim() === RxAppliances.VERSA ||
                selectedAppliance.name.trim() === RxAppliances.PEX
            ) {
                setSelectedConstructionType(selectedeCommerceItem.ecommerceItem?.name.trim() ?? '');
                setIsConstructionTypeApplicable(true);
            }
        }
        // Automatically "click" the first item when the component mounts with appliances that only have one Construction Type
        if (selectedAppliance.name.trim() === RxAppliances.VIDA ||
            selectedAppliance.name.trim() === RxAppliances.VIDASLEEP
        ) {
            if (selectedAppliance?.applianceItemSetups?.length > 0) {
                const firstItem = selectedAppliance.applianceItemSetups[0];
                LoadSelectedConstructionType(firstItem);
            }
        }
        //setComponentVariance(selectedeCommerceItem.variantsSelected);

    }, [])

    const handleVariantOptionChange = (option, value) => {
        var selectedValue = value;
        selectedeCommerceItem.eCommerceShopifyItem.variantsSelected[option.name] = selectedValue;
        setComponentVariance(prevState => ({
            ...prevState, // Preserve previous state
            [option.name]: value // Update the specific property
        }));
        SetSelectedVariantLogic(selectedeCommerceItem, selectedeCommerceItem.eCommerceShopifyItem.variantsSelected);

    }

    function SetSelectedVariantLogic(appliance: ApplianceItemSetup, variantSelected: Record<string, any>) {
        variantSelected["Title"] = 'Default Title';
        appliance.eCommerceShopifyItem.variantsSelected = variantSelected;

        setVariantsSelected(variantSelected);
        if (appliance.shopifyProduct && appliance.shopifyProduct.variants) {
            var variant = appliance.shopifyProduct.variants.find(y => y.selectedOptions.every((opt, ind) => appliance.eCommerceShopifyItem.variantsSelected[opt.name] == opt.value ? true : false));

            appliance.eCommerceShopifyItem.variantSelectedShopify = variant;
            setSelectedeCommerceItem(appliance);
        }
        else {
            setSelectedeCommerceItem(appliance);
        }
    }

    function LoadSelectedConstructionType(ecomitem) {
        if (selectedAppliance.name.trim() === RxAppliances.DNA ||
            selectedAppliance.name.trim() === RxAppliances.MRNA ||
            selectedAppliance.name.trim() === RxAppliances.MMRNA ||
            selectedAppliance.name.trim() === RxAppliances.VIDA ||
            selectedAppliance.name.trim() === RxAppliances.VIDASLEEP ||
            selectedAppliance.name.trim() === RxAppliances.VERSA ||
            selectedAppliance.name.trim() === RxAppliances.PEX
        ) {
            setIsConstructionTypeApplicable(true);
            setSelectedConstructionType(ecomitem.ecommerceItem?.name.trim() ?? '');
        }
        if (ecomitem.shopifyProduct && ecomitem.shopifyProduct.variants) {
            if (ecomitem.shopifyProduct.variants.length === 1) {
                var variant = ecomitem.shopifyProduct.variants[0];
                ecomitem.eCommerceShopifyItem.variantSelectedShopify = variant;
                ecomitem.eCommerceShopifyItem.variantsSelected["Title"] = 'Default Title';
                setVariantsSelected(ecomitem.eCommerceShopifyItem.variantsSelected);
            }
        }

        setComponentVariance(ecomitem.eCommerceShopifyItem.variantsSelected);
        setSelectedeCommerceItem(ecomitem);
        SetSelectedVariantLogic(ecomitem, ecomitem.eCommerceShopifyItem.variantsSelected);
        clearProductsToCart();
    }

    useEffect(() => {
        if (selectedAppliance.name.trim() === RxAppliances.GUIDE) {
            const allOptionsSelected = selectedeCommerceItem?.shopifyProduct?.options
                .filter(option => option.name !== 'Title')
                .every(option => selectedeCommerceItem.eCommerceShopifyItem.variantsSelected[option.name]);
            setIsConstructionComplete(allOptionsSelected);
        }
        if (selectedAppliance.name.trim() === RxAppliances.VERSA) {
            setIsConstructionComplete(true);
        }

    }, [selectedeCommerceItem.eCommerceShopifyItem.variantsSelected, SetSelectedVariantLogic]);

    return (
        <Grid container spacing={1}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h2">Appliance Construction</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {selectedAppliance && selectedAppliance.applianceItemSetups && selectedAppliance.applianceItemSetups.length > 0 &&
                                <Grid container>
                                    <Grid item xs={12}>

                                        <Box mt={0.7} display="flex" alignItems="center">
                                            <Grid container padding={2} borderRadius={2} marginBottom={2}
                                                sx={
                                                    (theme) => ({
                                                        backgroundColor: theme.colors.alpha.black[10]
                                                    })
                                                }
                                            >
                                                <Grid item xs={12}>
                                                    <Typography variant={'h4'}>
                                                        Select Construction Type:
                                                    </Typography>
                                                </Grid>
                                                {selectedAppliance?.applianceItemSetups?.map((ecomitem) => (
                                                    <Grid item xs={3} padding={1}>
                                                        <Button
                                                            fullWidth
                                                            value={ecomitem.id}
                                                            disabled={
                                                                // 1) If `isUpdating` is true, always disable
                                                                isUpdating ||
                                                                // 2) If we *do* have providerTypeEcomPrices, check if *all* are `enable = false`.
                                                                (
                                                                    ecomitem.ecommerceItem?.providerTypeEcomPrices &&
                                                                    ecomitem.ecommerceItem?.providerTypeEcomPrices?.length > 0 &&
                                                                    ecomitem.ecommerceItem?.providerTypeEcomPrices?.every((pt) => pt.enable === false)
                                                                )
                                                            }
                                                            //variant={versaTypeSelected === 'Versa 9' ? 'contained' : 'outlined'}
                                                            variant={selectedeCommerceItem?.ecommerceItem?.id === ecomitem.ecommerceItem?.id ? 'contained' : 'outlined'}
                                                            /*
                                                            onClick={() => {
                                                                if (selectedAppliance.name.trim() === RxAppliances.DNA ||
                                                                    selectedAppliance.name.trim() === RxAppliances.MRNA ||
                                                                    selectedAppliance.name.trim() === RxAppliances.MMRNA ||
                                                                    selectedAppliance.name.trim() === RxAppliances.VIDA ||
                                                                    selectedAppliance.name.trim() === RxAppliances.VIDASLEEP ||
                                                                    selectedAppliance.name.trim() === RxAppliances.VERSA ||
                                                                    selectedAppliance.name.trim() === RxAppliances.PEX
                                                                ) {
                                                                    setIsConstructionTypeApplicable(true);
                                                                    setSelectedConstructionType(ecomitem.ecommerceItem?.name.trim() ?? '');
                                                                }
                                                                if (ecomitem.shopifyProduct && ecomitem.shopifyProduct.variants) {
                                                                    if (ecomitem.shopifyProduct.variants.length === 1) {
                                                                        var variant = ecomitem.shopifyProduct.variants[0];
                                                                        ecomitem.eCommerceShopifyItem.variantSelectedShopify = variant;
                                                                        ecomitem.eCommerceShopifyItem.variantsSelected["Title"] = 'Default Title';
                                                                        setVariantsSelected(ecomitem.eCommerceShopifyItem.variantsSelected);
                                                                    }
                                                                }

                                                                setComponentVariance(ecomitem.eCommerceShopifyItem.variantsSelected);
                                                                setSelectedeCommerceItem(ecomitem);
                                                                SetSelectedVariantLogic(ecomitem, ecomitem.eCommerceShopifyItem.variantsSelected);
                                                                clearProductsToCart();
                                                            }}
                                                            */
                                                            onClick={() => { LoadSelectedConstructionType(ecomitem) }}
                                                            //onClick={() => { setVersaTypeSelected('Versa 9'); setSelectedApplianceType(ConstructionTypes["Versa-9"]) }}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            {ecomitem.applianceTypeNavigation?.name.toString()}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>
                                        {/* <RxConstructionContainer
                                            rxConstructionTitle={selectedAppliance.name.toString()}
                                            rxComplete={selectedeCommerceItem.ecommerceItem?.id}
                                            rxConstructionComponent={
                                                <ListWrapper disablePadding>
                                                    <ListItem sx={(theme) => ({
                                                        p: 2,
                                                        borderBottom: 4,
                                                        borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                                    })}>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="h5">
                                                                    Select {selectedAppliance.name} Type
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Box mt={0.7} display="flex" alignItems="center">
                                                                    <Grid container spacing={1}>
                                                                        {selectedAppliance?.applianceItemSetups?.map((ecomitem) => (
                                                                            <Grid item xs={9}>
                                                                                <Button
                                                                                    fullWidth
                                                                                    value={ecomitem.id}
                                                                                    //variant={versaTypeSelected === 'Versa 9' ? 'contained' : 'outlined'}
                                                                                    variant={selectedeCommerceItem?.ecommerceItem?.id === ecomitem.ecommerceItem?.id ? 'contained' : 'outlined'}
                                                                                    onClick={() => {
                                                                                        if (selectedAppliance.name.trim() === RxAppliances.DNA || selectedAppliance.name.trim() === RxAppliances.MRNA
                                                                                            || selectedAppliance.name.trim() === RxAppliances.MMRNA) {
                                                                                            setIsConstructionTypeApplicable(true);
                                                                                            setSelectedConstructionType(ecomitem.ecommerceItem?.name.trim() ?? '');
                                                                                        }
                                                                                        if (ecomitem.shopifyProduct && ecomitem.shopifyProduct.variants) {
                                                                                            if(ecomitem.shopifyProduct.variants.length===1){
                                                                                                var variant = ecomitem.shopifyProduct.variants[0];        
                                                                                                ecomitem.eCommerceShopifyItem.variantSelectedShopify = variant;
                                                                                                ecomitem.eCommerceShopifyItem.variantsSelected["Title"] = 'Default Title';
                                                                                                setVariantsSelected(ecomitem.eCommerceShopifyItem.variantsSelected);
                                                                                            }
                                                                                        }
                                                                                        
                                                                                        setComponentVariance(ecomitem.eCommerceShopifyItem.variantsSelected);
                                                                                        setSelectedeCommerceItem(ecomitem);
                                                                                        SetSelectedVariantLogic(ecomitem, ecomitem.eCommerceShopifyItem.variantsSelected);
                                                                                        clearProductsToCart();
                                                                                        if(isUpdating){
                                                                                            setShouldCancelOrder(true);
                                                                                        }
                                                                                    }}
                                                                                    //disabled={isUpdating}
                                                                                    //onClick={() => { setVersaTypeSelected('Versa 9'); setSelectedApplianceType(ConstructionTypes["Versa-9"]) }}
                                                                                    sx={{
                                                                                        justifyContent: 'flex-start'
                                                                                    }}
                                                                                >
                                                                                    {ecomitem.applianceTypeNavigation?.name.toString()}
                                                                                </Button>
                                                                            </Grid>
                                                                        ))}
                                                                    </Grid>
                                                                </Box>
                                                            }
                                                        />
                                                        {!selectedeCommerceItem.eCommerceShopifyItem?.variantSelectedShopify && <Box alignSelf="flex-start">
                                                            <LabelWrapper
                                                                sx={(theme) => ({
                                                                    background: `${theme.colors.warning.main}`,
                                                                    color: `${theme.palette.getContrastText(
                                                                        theme.colors.warning.dark
                                                                    )}`
                                                                })}
                                                            >
                                                                Required
                                                            </LabelWrapper>
                                                        </Box>}

                                                    </ListItem>
                                                </ListWrapper>
                                            }
                                        /> */}
                                    </Grid>
                                    {selectedeCommerceItem?.shopifyProduct?.options.filter(option => option.name !== 'Title').map(option => (
                                        <Grid item xs={12 / (selectedeCommerceItem?.shopifyProduct?.options.filter(option => option.name !== 'Title').length)}>
                                            <RxConstructionContainer
                                                rxConstructionTitle={option.name}
                                                rxComplete={selectedeCommerceItem.eCommerceShopifyItem.variantsSelected[option.name]}
                                                rxConstructionComponent={
                                                    <ListWrapper disablePadding key={selectedeCommerceItem.eCommerceShopifyItem.variantsSelected[option.name]}>
                                                        <ListItem sx={(theme) => ({
                                                            p: 2,
                                                            borderBottom: 4,
                                                            borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                                        })}>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="h5">
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Box mt={0.7} display="flex" alignItems="center">
                                                                        <Grid container spacing={1}>
                                                                            {option.values.map(value => (
                                                                                <Grid item xs={12}>
                                                                                    <Button
                                                                                        key={value}
                                                                                        fullWidth
                                                                                        value={value}
                                                                                        variant={componentVariance[option.name] === value ? 'contained' : 'outlined'}
                                                                                        onClick={() => {
                                                                                            handleVariantOptionChange(option, value);
                                                                                        }}
                                                                                        sx={{
                                                                                            justifyContent: 'flex-start'
                                                                                        }}
                                                                                    >
                                                                                        {value}
                                                                                    </Button>
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    </Box>
                                                                }
                                                            />
                                                            {!selectedeCommerceItem.eCommerceShopifyItem.variantsSelected[option.name] && <Box alignSelf="flex-start">
                                                                <LabelWrapper
                                                                    sx={(theme) => ({
                                                                        background: `${theme.colors.warning.main}`,
                                                                        color: `${theme.palette.getContrastText(
                                                                            theme.colors.warning.dark
                                                                        )}`
                                                                    })}
                                                                >
                                                                    Required
                                                                </LabelWrapper>
                                                            </Box>}

                                                        </ListItem>
                                                    </ListWrapper>
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} paddingTop={2.5} paddingLeft={2}>
                                {isConstructionTypeApplicable && (
                                    <>
                                        {selectedAppliance.name.trim() === RxAppliances.DNA ||
                                            selectedAppliance.name.trim() === RxAppliances.MRNA ||
                                            selectedAppliance.name.trim() === RxAppliances.MMRNA ? (
                                            <CareConstruction constructionType={selectedConstructionType} />
                                        ) : selectedAppliance.name.trim() === RxAppliances.VIDA ? (
                                            <VidaConstruction />
                                        ) : selectedAppliance.name.trim() === RxAppliances.VIDASLEEP ? (
                                            <VidaSleepConstruction />
                                        ) : selectedAppliance.name.trim() === RxAppliances.PEX ? (
                                            <PexConstruction constructionType={selectedConstructionType} />
                                        ) : null}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {isConstructionTypeApplicable && <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="h6">
                                        Construction Diagram
                                    </Typography>
                                    <RxDiagramDropzone />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Typography variant="h6">
                                        Construction Comments
                                    </Typography>
                                    <Box paddingLeft={2}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={5}
                                            maxRows={20}
                                            value={constructionComments}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setConstructionComments(event.target.value);
                                            }}
                                            disabled={isUpdating}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>




            </Grid>
        </Grid>
    );
};
export default observer(RxConstruction);