import { makeAutoObservable, observable } from 'mobx';

class AuthEntity {
  @observable iss: string = '';
  @observable nbf: number = 0;
  @observable iat: number = 0;
  @observable exp: number = 0;
  @observable aud: string = '';
  @observable scope: string[] = [];
  @observable amr: string[] = [];
  @observable client_id: string = '';
  @observable sub: string = '';
  @observable auth_time: number = 0;
  @observable idp: string = '';
  @observable id: string = '';
  @observable isSysAdmin: boolean = false;
  @observable organizationId: string = '';
  @observable userFullName: string = '';
  @observable userName: string = '';
  @observable roles: string = '';
  @observable providerId: string = '';
  @observable providerTypes: string[] = [];
  @observable peopleTypes: string[] = [];
  @observable providerTier: string[] = [];
  @observable sid: string = '';
  @observable jti: string = '';

  update(data: any) {
    // Update each property based on the data object
    Object.keys(this).forEach((key) => {
      if (data.hasOwnProperty(key)) {
        // Update only if the property exists in the data
        // This prevents updating properties that are not in the data
        this[key] = data[key];
      }
    });
  }

}

export default AuthEntity;
