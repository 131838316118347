import React, { useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, SelectChangeEvent, Select, Checkbox, Typography, Box, ToggleButton, Switch, MenuItem, CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';

import CssTextField from "../CssTextField";
import Item from "../Item";
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { AlertDialog } from '../AlertDialog';

interface ComponentProps {
    visit: any
}

const HeadNeck = (props: ComponentProps) => {
    const { visit } = props;
    const [hasLoaded, setHasLoaded] = useState(false);
    const { loadReportPropertiesData, saveReportInfo } = useMainStoreContext().intakeReportsStore;
    const [reportValues, setReportValues] = useState<any>([]);
    const [disabled, setDisabled] = useState(false);
    const [alertMessage, setAlertMessage] = React.useState('');
    const [alertTitle, setAlertTitle] = React.useState('');
    const [openAlertMessage, setOpenAlertMessage] = React.useState(false);
    const [current, setCurrent] = useState<any>({
        index: "",
        value: ""
    });
    const store = useMainStoreContext().aiReportsStore;
    const aiReportEntryStore = useMainStoreContext().aiReportEntryStore;
    const [aiReportOnly, setAiReportOnly] = useState(true);

    const classesList = [
        {
            value: "NA",
            label: "NA"
        },
        {
            value: "Class I",
            label: "Class I"
        },
        {
            value: "Class II",
            label: "Class II"
        },
        {
            value: "Class III",
            label: "Class III"
        }
    ];

    const biteDetailsList = [
        {
            value: "Not Included",
            label: "Not Included"
        },
        {
            value: "Bite-Wing Scan",
            label: "Bite-Wing Scan"
        },
        {
            value: "Inadecuate Spacing",
            label: "Inadecuate Spacing"
        },
        {
            value: "Natural Bite",
            label: "Natural Bite"
        }
    ];

    const tongueConditionsList = [
        {
            value: "WNL",
            label: "WNL"
        },
        {
            value: "Fissured",
            label: "Fissured"
        },
        {
            value: "Scalloped",
            label: "Scalloped"
        },
        {
            value: "Glossitis/Geographic",
            label: "Glossitis/Geographic"
        },
        {
            value: "Macroglossia",
            label: "Macroglossia"
        }
    ];

    useEffect(() => {
        setReportValues([]);
        loadReportPropertiesData('headneck').then(resp => {
            var rValues: any = [];
            resp.map(r => {
                var value = "false";
                if (visit.patientHeadNecks != null && !_.isUndefined(visit.patientHeadNecks.find(x => x.propertyId === r.id)?.value)) {
                    value = visit.patientHeadNecks.find(x => x.propertyId === r.id).value.toString();
                } else {
                    if (r.name.includes("Molar Relationship") || r.name === "Malocclusion") {
                        value = "NA";
                    }

                    if (r.name === "Tongue Condition") {
                        value = "WNL";
                    }

                    if (r.name === "Construction Bite Details") {
                        value = "Not Included";
                    }

                    if (r.name === "BMI" || r.name === "TMJ ROM Measurement"
                        || r.name.includes("Maxillary Sinus") || r.name.includes("Angle")
                        || r.name.includes("Width")) {
                        value = "0";
                    }
                }
                rValues.push({ PropertyId: r.id, Name: r.name, Value: value })
            });

            setHasLoaded(true);
            setReportValues(rValues);

        })
    }, [store.selectedVisitRecordId]);

    const questions = (index) => {
        var conditionTitle =
            <FormGroup>
                <FormControlLabel className="sectionHdr" control={<Grid>{reportValues[index]?.Name}</Grid>} label="" />
            </FormGroup>;
        var conditionElement =
            <FormControlLabel
                control={<Checkbox
                    checked={(reportValues[index]?.Value.toLowerCase() === "true")}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        var value = event.target.checked;
                        reportValues[index].Value = String(value);
                        setCurrent({ index: index, value: value });
                    }}
                />} label="Condition Present" />;

        if (reportValues[index]?.Name.includes("Molar Relationship") || reportValues[index]?.Name === "Malocclusion") {
            conditionElement =
                <Select
                    id={`select${reportValues[index]?.Name}`}
                    sx={{ width: '300px', height: '30px' }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        var selected = classesList.filter(x => x.value.toString() === event.target.value.toString())[0];
                        reportValues[index].Value = selected.value.toString();
                        setCurrent({ index: index, value: selected.value });
                    }}
                    value={reportValues[index]?.Value.toString() === "" ? "NA" : reportValues[index]?.Value.toString()}
                >
                    {classesList.map((currentVal) => (
                        <MenuItem value={currentVal.value}>{`${currentVal.label}`}</MenuItem>
                    ))}
                </Select>;
        }

        if (reportValues[index]?.Name === "Tongue Condition") {
            conditionElement =
                <Select
                    id={`select${reportValues[index]?.Name}`}
                    sx={{ width: '300px', height: '30px' }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        var selected = tongueConditionsList.filter(x => x.value.toString() === event.target.value.toString())[0];
                        reportValues[index].Value = selected.value.toString();
                        setCurrent({ index: index, value: selected.value });
                    }}
                    value={reportValues[index]?.Value.toString()}
                >
                    {tongueConditionsList.map((currentVal) => (
                        <MenuItem value={currentVal.value}>{`${currentVal.label}`}</MenuItem>
                    ))}
                </Select>;
        }

        if (reportValues[index]?.Name === "Construction Bite Details") {
            conditionElement =
                <Select
                    id={`select${reportValues[index]?.Name}`}
                    sx={{ width: '300px', height: '30px' }}
                    onChange={(event: SelectChangeEvent<string>) => {
                        var selected = biteDetailsList.filter(x => x.value.toString() === event.target.value.toString())[0];
                        reportValues[index].Value = selected.value.toString();
                        setCurrent({ index: index, value: selected.value });
                    }}
                    value={reportValues[index]?.Value.toString()}
                >
                    {biteDetailsList.map((currentVal) => (
                        <MenuItem value={currentVal.value}>{`${currentVal.label}`}</MenuItem>
                    ))}
                </Select>;
        }

        if (reportValues[index]?.Name === "BMI" || reportValues[index]?.Name === "TMJ ROM Measurement"
            || reportValues[index]?.Name.includes("Maxillary Sinus") || reportValues[index]?.Name.includes("Angle")
            || reportValues[index]?.Name.includes("Width")) {
            conditionElement =
                <CssTextField
                    variant="outlined"
                    className="TextBox"
                    size="small"
                    type="number"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        reportValues[index].Value = event.target.value.toString();
                        setCurrent({ index: index, value: event.target.value });
                    }}
                    value={reportValues[index]?.Value}
                />;
        }

        return (<Grid xs={12} alignItems="center" container direction={"row"}>
            <Grid item xs={4}>
                {conditionTitle}
            </Grid>
            <Grid item xs={8}>
                <FormControl>
                    <Item>
                        {conditionElement}
                    </Item>
                </FormControl>
            </Grid>
        </Grid>
        );
    }

    const handleClick = () => {
        setDisabled(true);
        saveReportInfo(store.selectedVisitRecordId, reportValues, 'headneck').then((resp) => {
            if (resp === true) {
                setAlertTitle("Report saved succesfully.");
                setAlertMessage("Report saved succesfully.");
                setOpenAlertMessage(true);
                aiReportEntryStore.loadReport();
            } else {
                setDisabled(false);
                setAlertTitle("Report not saved.");
                setAlertMessage("There was an error saving the report. Please try again.");
                setOpenAlertMessage(true);
            }
        });
    }

    const headAndFacePropertyIds = [90, 91, 92, 93, 94, 67, 66, 95, 96, 97, 98, 99, 100];
    const otherExtraoralPropertyIds = [105, 106, 107, 79, 80, 82,];
    const myoPropertyIds = [86, 111, 112, 113, 34, 83, 84, 85, 54, 73, 32]
    const generalHealthPropertyIds = [89, 22, 21];
    const dentalPropertyIds = [108, 109, 72, 16, 19, 57, 58, 53, 4, 60, 14, 15, 35];
    const ctPropertyIds = [81, 78, 43, 40, 41, 42, 39, 37, 38, 36, 5];
    const aiReportSurgeryPropertyIds = [1, 88, 69, 29];
    const aiReportSleepProperetyIds = [17, 50, 51, 71, 8, 65, 26];
    const aiReportPropertyIds =
        [1, 88, 2, 17, 50, 51, 73, 26, 24, 25, 71, 8, 65, 80, 11, 75, 69, 29, 61, 6, 28, 77, 56, 31, 18, 86, 83, 32,
            85, 10, 3, 16, 72, 35, 53, 21, 66, 67, 63, 64, 33, 46, 37, 38, 41, 40, 36, 42, 5, 39, 43
        ]


    // Function to filter property IDs based on otherAiReportPropertyIds
    const filterPropertyIds = (propertyIds) => {
        return propertyIds.filter(id => aiReportPropertyIds.includes(id));
    };

    const filteredHeadAndFacePropertyIds = filterPropertyIds(headAndFacePropertyIds);
    const filteredOtherExtraoralPropertyIds = filterPropertyIds(otherExtraoralPropertyIds);
    const filteredMyoPropertyIds = filterPropertyIds(myoPropertyIds);
    const filteredGeneralHealthPropertyIds = filterPropertyIds(generalHealthPropertyIds);
    const filteredDentalPropertyIds = filterPropertyIds(dentalPropertyIds);
    const filteredCtPropertyIds = filterPropertyIds(ctPropertyIds);

    // Combine all other property ID arrays
    const allOtherPropertyIds = [
        ...headAndFacePropertyIds,
        ...otherExtraoralPropertyIds,
        ...myoPropertyIds,
        ...generalHealthPropertyIds,
        ...dentalPropertyIds,
        ...ctPropertyIds,
        ...aiReportSleepProperetyIds,
        ...aiReportSurgeryPropertyIds
    ];

    // Create a Set for unique values in all other property IDs
    const uniqueOtherPropertyIds = new Set(allOtherPropertyIds);

    // Filter aiReportPropertyIds to remove values that exist in uniqueOtherPropertyIds
    const filteredAiReportPropertyIds = aiReportPropertyIds.filter(id => !uniqueOtherPropertyIds.has(id));

    const toggleAiReportOnly = () => {
        setAiReportOnly(prev => !prev);
    };

    return (
        <Grid >
            {!hasLoaded &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>
            }
            {hasLoaded ?
                <Grid container direction={"row"} className="formSectionLabel">
                    <Grid item xs={12} paddingBottom={2}>
                        <FormControlLabel
                            control={<Switch checked={aiReportOnly} onChange={toggleAiReportOnly} />}
                            label="AI Report Only Fields"
                        />
                    </Grid>
                    <Grid item xs={12} paddingBottom={2}>
                        <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                            <Typography variant="h3" textAlign="center">Head & Neck Exam Extraoral</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Typography variant="h4">Head & Face</Typography>
                        <Grid container spacing={2} paddingX={5}>
                            {(aiReportOnly ? filteredHeadAndFacePropertyIds : headAndFacePropertyIds).map((propertyId) => (
                                <Grid item xs={4} key={propertyId}>
                                    {questions(propertyId - 1)}
                                </Grid>
                            ))}
                        </Grid>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Typography variant="h4">Other Extraoral</Typography>
                        <Grid container spacing={2} paddingX={5}>
                            {(aiReportOnly ? filteredOtherExtraoralPropertyIds : otherExtraoralPropertyIds).map((propertyId) => (
                                <Grid item xs={4} key={propertyId}>
                                    {questions(propertyId - 1)}
                                </Grid>
                            ))}
                        </Grid>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                    </Grid>
                    <Grid item xs={12} padding={2}>
                        <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                            <Typography variant="h3" textAlign="center">Head & Neck Exam Intraoral</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Typography variant="h4">Myo Health</Typography>
                        <Grid container spacing={2} paddingX={5}>
                            {(aiReportOnly ? filteredMyoPropertyIds : myoPropertyIds).map((propertyId) => (
                                <Grid item xs={4} key={propertyId}>
                                    {questions(propertyId - 1)}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Typography variant="h4">General Oral Health</Typography>
                        <Grid container spacing={2} paddingX={5}>
                            {(aiReportOnly ? filteredGeneralHealthPropertyIds : generalHealthPropertyIds).map((propertyId) => (
                                <Grid item xs={4} key={propertyId}>
                                    {questions(propertyId - 1)}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Grid item xs={12} padding={2}>
                            <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                                <Typography variant="h3" textAlign="center">Dental Exam</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                            <Grid container spacing={2} paddingX={5}>
                                {(aiReportOnly ? filteredDentalPropertyIds : dentalPropertyIds).map((propertyId) => (
                                    <Grid item xs={4} key={propertyId}>
                                        {questions(propertyId - 1)}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Grid item xs={12} padding={2}>
                            <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                                <Typography variant="h3" textAlign="center">CT Evaluation</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Grid container spacing={2} paddingX={5}>
                            {(aiReportOnly ? filteredCtPropertyIds : ctPropertyIds).map((propertyId) => (
                                <Grid item xs={4} key={propertyId}>
                                    {questions(propertyId - 1)}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Grid item xs={12} padding={2}>
                            <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                                <Typography variant="h3" textAlign="center">Surgeries</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                            <Grid container spacing={2} paddingX={5}>
                                {aiReportSurgeryPropertyIds.map((propertyId) => (
                                    <Grid item xs={4} key={propertyId}>
                                        {questions(propertyId - 1)}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Grid item xs={12} padding={2}>
                            <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                                <Typography variant="h3" textAlign="center">Sleep Related Health</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                            <Grid container spacing={2} paddingX={5}>
                                {aiReportSleepProperetyIds.map((propertyId) => (
                                    <Grid item xs={4} key={propertyId}>
                                        {questions(propertyId - 1)}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                        <Grid item xs={12} padding={2}>
                            <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                                <Typography variant="h3" textAlign="center">Other fields utilized in AI Report not in Head & Neck</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box height={3} sx={{ backgroundColor: "gray", width: "90%" }}></Box>
                            <Grid container spacing={2} paddingX={5}>
                                {filteredAiReportPropertyIds.map((propertyId) => (
                                    <Grid item xs={4} key={propertyId}>
                                        {questions(propertyId - 1)}
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction={"row"} justifyContent={"flex-end"}><Button className="vivButton large invert" onClick={handleClick} disabled={disabled}>Save</Button></Grid>
                    </Grid>

                    {/* <Grid item xs={12} padding={2}>
                        <Box sx={{ backgroundColor: "gray", width: "90%", borderRadius: 5 }}>
                            <Typography variant="h3" textAlign="center">Other Fields</Typography>
                        </Box>
                    </Grid>
                    <Grid sx={{ margin: "25px 25px", padding: "0px 35px" }} className="formBasics">
                        <Grid className="formBasics" >
                            <Grid container spacing={2}>
                                {reportValues.map((r, i) => (
                                    <Grid item xs={4} key={i}>
                                        {questions(i)}
                                    </Grid>
                                ))}
                            </Grid>
                           
                        </Grid>
                    </Grid> */}

                </Grid> : <></>}
            <Grid>
                <AlertDialog
                    close={() => setOpenAlertMessage(false)}
                    open={openAlertMessage}
                    title={alertTitle}
                    message={alertMessage}
                />
            </Grid>
        </Grid>
    )
};

export default observer(HeadNeck);
