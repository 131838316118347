import { observable } from 'mobx';
import { Node, Edge } from './node';

export class MoneyV2 {
  @observable amount: string = "0.0";
  @observable currencyCode: string = "USD";
}

export class LineItem {
  @observable id: string = "";
  @observable title: string = "";
  @observable quantity: number = 0;
  @observable originalTotalPrice: MoneyV2 = new MoneyV2();
}


export class Order {
  @observable id: string = "";
  @observable name: string = "";
  @observable financialStatus: string = "";
  @observable fulfillmentStatus: string = "";
  @observable processedAt: string = new Date().toISOString();
  @observable totalPrice: MoneyV2 = new MoneyV2();
  @observable lineItems: Edge<LineItem> = new Edge<LineItem>();
  @observable currentTotalTax: MoneyV2 = new MoneyV2();
  @observable totalShippingPrice: MoneyV2 = new MoneyV2();
  @observable totalTax: MoneyV2 = new MoneyV2();
}

export class Orders {
  @observable edges: Edge<Order>[] = [];
}

class OrderData {
  @observable orders: Orders = new Orders();
}

export default OrderData;
