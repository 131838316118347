import {
    Typography,
    Box,
    styled,
    Avatar,
    lighten,
    alpha,
    Stack,
    Divider,
    useTheme,
    Card,
    Grid,
    IconButton,
    Badge,
    Popover
} from '@mui/material';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import React from "react";
import { useEffect, useState } from "react";
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import Cart from '../../../components/shopify/Cart';

const AvatarPageTitle = styled(Avatar)(
    ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);

function PageHeader() {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { setCartOpen, isCartModalOpen, setCartModalOpen, cartCount } = useMainStoreContext().shopifyStore;

    const onCartClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setCartModalOpen(!isCartModalOpen);
    };
    const closeModal = () => {
        setCartModalOpen(false);
        setAnchorEl(null);
    }

    return (
        <Box
            display="flex"
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
        >
            <Box display="flex" alignItems="center">
                <AvatarPageTitle variant="rounded">
                    <ShoppingCartTwoToneIcon fontSize="large" />
                </AvatarPageTitle>
                <Box>
                    <Typography variant="h3" component="h3" gutterBottom>
                        Vivos Store
                    </Typography>
                    <Typography variant="subtitle2">
                        Shop for all your Practice needs.
                    </Typography>
                </Box>
            </Box>
            <Card
                sx={{
                    mt: { xs: 3, lg: 0 }
                }}
            >
                <Stack
                    direction="row"
                    divider={
                        <Divider
                            sx={{
                                background: `${theme.colors.alpha.black[10]}`
                            }}
                            orientation="vertical"
                            flexItem
                        />
                    }
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={0}
                >
                    <Box px={3} py={2} textAlign="center">
                        <Grid container direction={'column'}>
                            <Typography variant="h4">Cart</Typography>
                        </Grid>
                    </Box>
                    <Box px={3} py={2} textAlign="center">
                        <IconButton onClick={onCartClick}>
                            <Badge badgeContent={cartCount} color="primary">
                                <ShoppingCartTwoToneIcon />
                            </Badge>
                            <Popover
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top',
                                }}
                                open={!!anchorEl && isCartModalOpen}
                                onClose={closeModal}
                            >
                                <Box sx={{ width: '400px' }}>
                                    <Cart />
                                </Box>
                            </Popover>
                        </IconButton>

                    </Box>

                </Stack>
            </Card>
        </Box>
    );
}

export default PageHeader;
