// Vendors
import {
    Box,
    CardHeader,
    Card,
    ListItemText,
    Typography,
    Avatar,
    List,
    Divider,
    ListItem,
    Link,
    IconButton,
    alpha,
    styled,
    useTheme
} from '@mui/material';


// Components
import Scrollbar from '../Scrollbar';

const CardContentWrapper = styled(Box)(
    ({ theme }) => `
      background: ${theme.colors.alpha.white[100]};
      border-radius: ${theme.general.borderRadius};
    `
);

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

function SectionContainer({ sectionTitle, sectionComponent, cardContentHeight }) {
    const theme = useTheme();

    return (
        <Card
            variant="outlined"
            sx={{
                background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
            }}
        >
            <CardHeader
                sx={{
                    p: 3
                }}
                disableTypography
                title={<Typography variant="h3">{sectionTitle}</Typography>}
            />
            <CardContentWrapper
                sx={{
                    mx: 3,
                    mb: 3,
                    height: cardContentHeight
                }}
            >
                <Scrollbar>
                    {sectionComponent}
                </Scrollbar>
            </CardContentWrapper>
        </Card>
    );
}

export default SectionContainer;
