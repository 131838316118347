// Vendors
import React, { FC, useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Checkbox,
    Popover
} from '@mui/material';
import CheckboxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import moment from "moment";

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

interface IReportVisit {
    visits?: any;
    singleSelect: boolean;
    onSelectVisit: (visit: any) => void;  // New callback prop for selected visit
}

export const AiReportSelectVisit: FC<IReportVisit> = ({ visits, singleSelect, onSelectVisit }) => {
    const { setVisit } = useMainStoreContext().visitRecordStore;
    const store = useMainStoreContext().aiReportOrder;
    const [isChecked, setIsChecked] = useState(visits.slice().fill(false));
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        // Reset isChecked state to all false when singleSelect changes
        setIsChecked(isChecked.map(() => false));
    }, [singleSelect]);

    const toggleCheckboxValue = (index: number, event) => {
        const updatedCheckedState = isChecked.map((item, i) =>
            i === index ? !item : item
        );

        if (singleSelect) {
            // Allow only one checkbox to be selected
            setIsChecked(updatedCheckedState.map((_, i) => i === index ? !isChecked[index] : false));

            // Trigger the callback with the selected visit or null if unselected
            if (updatedCheckedState[index]) {
                onSelectVisit(visits[index]); // Send the selected visit to parent
            } else {
                onSelectVisit(null); // No visit selected
            }
        } else {
            const selectedCount = updatedCheckedState.filter(v => v).length;
            if (selectedCount <= 2) {
                setIsChecked(updatedCheckedState);

                // Send the selected visits to parent if exactly two are selected
                if (selectedCount === 2) {
                    const selectedVisits = visits.filter((_, i) => updatedCheckedState[i]);
                    onSelectVisit(selectedVisits); // Send the selected visits to parent
                } else if (selectedCount === 1) {
                    const selectedVisit = visits.find((_, i) => updatedCheckedState[i]);
                    onSelectVisit([selectedVisit]); // Send the single selected visit to parent
                } else {
                    onSelectVisit([]); // No visits selected
                }
            } else {
                setAnchorEl(event.currentTarget);
                setPopoverOpen(true);
                return;
            }
        }
    };

    const handleVisits = (event: React.ChangeEvent<HTMLInputElement>) => {
        var id = Number(event.target.id);
        store.visits.includes(id)
            ? store.visits.splice(store.visits.indexOf(id), 1)
            : store.visits.push(id);
    };

    const hasForms = (row: any): boolean => {
        return row.patientSleepStudies?.length > 0 ||
            row.patientEpworths?.length > 0 ||
            row.patientHeadNecks?.length > 0 ||
            row.patientHeadacheImpacts?.length > 0 ||
            row.patientPerceivedStresses?.length > 0 ||
            row.patientStopBangs?.length > 0;
    };

    const hasImages = (row: any): boolean => {
        const images = row.patientsImages?.[0] || {};
        return Object.keys(images).some(key => images[key] !== "");
    };

    const hasCBCT = (row: any): boolean => {
        const cbctScan = row.patientsImages?.[0]?.cbctScan;
        return cbctScan && cbctScan !== "";
    };

    const hasModels = (row: any): boolean => {
        const images = row.patientsImages?.[0] || {};
        return images.occlusion3dlowerArch || images.occlusion3dupperArch || images.occlusion3dconstructedBite;
    };

    return (
        <Grid xs={12} padding={1}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">Select</TableCell>
                            <TableCell align='center'>Visit Date</TableCell>
                            <TableCell align='center'>Forms</TableCell>
                            <TableCell align='center'>Images</TableCell>
                            <TableCell align='center'>CBCT</TableCell>
                            <TableCell align='center'>Models</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visits.map((currentVal, index) => (
                            <TableRow hover key={currentVal.id}>
                                <TableCell padding="checkbox" align='center'>
                                    <Checkbox
                                        sx={{ padding: 0.5 }}
                                        key={index}
                                        checked={isChecked[index]}
                                        onChange={handleVisits}
                                        id={currentVal.id.toString()}
                                        onClick={(event) => toggleCheckboxValue(index, event)}
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Box pb={0.5} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" noWrap>
                                            {`${moment(currentVal.createdOn).format("MM/DD/yyyy")}`}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell align='center'>
                                    {hasForms(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell align='center'>
                                    {hasImages(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell align='center'>
                                    {hasCBCT(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell align='center'>
                                    {hasModels(currentVal) ? <CheckboxTwoToneIcon color="success" /> : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Popover
                open={popoverOpen}
                anchorEl={anchorEl}
                onClose={() => setPopoverOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography sx={{ p: 2 }}>You can only select a maximum of two visits for comparison reports.</Typography>
            </Popover>
        </Grid>
    );
};
