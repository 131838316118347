// Vendors
import React, { useState } from 'react';
import { Grid, Typography, Popover, IconButton, styled } from "@mui/material";
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

// Components
import CareApplianceInstructions from "./Instruction Components/CareApplianceInstruction";
import EstimatedRepairFee from "./Instruction Components/EstimatedRepairFee";
import GergenDetails from './Instruction Components/GergenDetails';
import ApexDetails from './Instruction Components/ApexDetails';
import JdlDetails from './Instruction Components/JdlDetails';
import AurumDetails from './Instruction Components/AurumDetails';
import SmlDetails from './Instruction Components/SmlDetails';
import FiveStarDetails from './Instruction Components/FiveStarDetials';
import StaaDetails from './Instruction Components/StaaDetails';
import VivosDetails from './Instruction Components/VivosDetails';
import VersaApplianceInstructions from './Instruction Components/VersaApplianceInstruction';
import PexApplianceInstructions from './Instruction Components/PexApplianceInstruction';
import GuideApplianceInstructions from './Instruction Components/GuideApplianceInstruction';

// Store
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";

// Entities
import ProviderPatient from "../../../../entities/ProviderPatient";

// API
import { observer } from "mobx-react-lite";
import _ from 'lodash';


interface IGIProps {
    patient: ProviderPatient;
    claim: any;
    isEdit: boolean;
}

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        background: transparent;
        transition: ${theme.transitions.create(['all'])};
        color: ${theme.colors.warning.light};
        border-radius: 50px;
    
        &:hover {
          background: transparent;
          color: ${theme.colors.warning.dark};
        }
    `
);

export const ReviewSubmitStep: React.FC<IGIProps> = observer(({ patient, claim, isEdit }) => {
    const store = useMainStoreContext().reworkClaimStore;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Grid className="step-wizard-container" >
            <Grid className="box-wizard-container">
                <Grid className="title-wizard">Rework Claim Overview </Grid>
                <Grid container>
                    <Grid xs={6}>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Patient:</Typography></Grid>
                            <Typography>{patient.firstName + " " + patient.lastName}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">RX ID:</Typography></Grid>
                            <Typography>{claim.rxId}</Typography>
                        </Grid>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">RX Rework ID:</Typography></Grid>
                            <Typography>{claim.Id}</Typography>
                        </Grid>
                        {/* <Grid container direction={'row'}>
                            <Grid className="claim-data"> {isEdit ? "RX Claim ID" : "RX ID"} </Grid>
                            <Grid> {claim.rxId}</Grid>
                        </Grid> */}
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">Appliance:</Typography></Grid>
                            {/* <Typography>{isEdit ? claim.appliance.applianceTypeNavigation.name : claim.appliance.name}</Typography> */}
                            <Typography>{claim.appliance?.name}</Typography>
                        </Grid>

                        <Grid container padding={1}>
                            <Grid item xs={12} container alignItems="flex-start">
                                <Typography variant="h4" style={{ marginRight: '8px' }}>Lab:</Typography>
                                <Typography style={{ position: 'relative' }}>{claim.lab.name}
                                    <IconButtonWrapper
                                        onClick={handlePopoverOpen}
                                        style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            right: '-30px', // Adjust this value as needed
                                            color: 'orange',
                                            fontSize: '8px'
                                        }}
                                    >
                                        <HelpTwoToneIcon fontSize="small" />
                                    </IconButtonWrapper>
                                </Typography>
                                <Popover
                                    id="mouse-over-popover"
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    PaperProps={{ style: { width: '500px', padding: '20px' } }}
                                >
                                    {/* HELP Still need to implement the actual names of the labs to match the database strings. */}
                                    {(() => {
                                        switch (claim.lab.name) {
                                            case "Gergen":
                                                return <GergenDetails />;
                                            case "Apex":
                                                return <ApexDetails />;
                                            case "Jdl":
                                                return <JdlDetails />;
                                            case "Aurum":
                                                return <AurumDetails />;
                                            case "SML":
                                                return <SmlDetails />;
                                            case "Five Star":
                                                return <FiveStarDetails />;
                                            case "STAA":
                                                return <StaaDetails />;
                                            default:
                                                return <VivosDetails />;
                                        }
                                    })()}
                                </Popover>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={6}>
                        <Grid container padding={1}>
                            <Grid marginRight={1}><Typography variant="h4">New Impressions Submitted:</Typography></Grid>
                            <Typography>{store.newRecordSubmitted ? "Yes" : "No"}</Typography>
                        </Grid>
                        <Grid container padding={1} direction={'column'}>
                            <Grid marginRight={1}><Typography variant="h4">Rework Description:</Typography></Grid>
                            <Grid paddingX={1}><Typography>{claim.providerNotes}</Typography></Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <EstimatedRepairFee />
            {(() => {
                const applianceName = isEdit ? claim.appliance.applianceTypeNavigation.name.toLowerCase() : _.isNull(claim.appliance.name) ? "" : claim.appliance.name.toLowerCase();

                if (applianceName.includes("dna") || applianceName.includes("mrna")) {
                    return <CareApplianceInstructions />;
                }
                else if (applianceName.includes("versa")) {
                    return <VersaApplianceInstructions />;
                }
                else if (applianceName.includes("pex")) {
                    return <PexApplianceInstructions />;
                }
                else if (applianceName.includes("guide")) {
                    return <GuideApplianceInstructions />;
                }
                else {
                    return <></>; // Renders nothing for the default case
                }
            })()}

        </Grid>
    )
}
);
