// Vendors
import React, { Component, useEffect } from "react";
import { Button, Grid, styled, Box } from "@mui/material";

// Entities
import ProviderPatient from "../../../entities/ProviderPatient";

// API
import { observer } from "mobx-react";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";
import { LoadingButton } from "@mui/lab";

const BoxBorderBottom = styled(Box)(
    () => `
          border-bottom: transparent 5px solid;
    `
);

interface IGIProps {
    children: any;
    onClick(): void;
}

export const VisitTab: React.FC<IGIProps> = observer(
    ({ children, onClick }) => {

        const [hasLoaded, setHasLoaded] = React.useState(false);
        const store = useMainStoreContext().visitRecordStore;
        useEffect(() => {
            setHasLoaded(true);
        }, [])

        return (
            <Grid>
                <Grid>
                    {children}
                </Grid>
                <BoxBorderBottom
                    padding={1}
                    zIndex={1000}
                    sx={(theme) => ({
                        borderBottomColor: theme.palette.primary.main,
                        width: '100%',
                        backgroundColor: theme.colors.alpha.black[5],
                        position: 'absolute',
                        bottom: '0',
                    })}>
                    <Grid container justifyContent={'right'} alignContent={'center'}>
                        <LoadingButton
                            loading={store.isLoading}
                            //sx={confirmButton}
                            //disabled={date === null || time === null || email === null || !isValidEmail}
                            onClick={onClick}
                            variant="contained"
                        >Save</LoadingButton>
                        {/* <Button variant="contained" onClick={onClick} >Save</Button> */}
                    </Grid>
                </BoxBorderBottom>

            </Grid>
        )
    }
);
