interface IMyoCorrectTherapist {
    userID: number,
    firstName: string,
    lastName: string,
    myoCorrectTherapistType?: number, 
    personID: number;
}

interface MyoCorrectTherapist extends IMyoCorrectTherapist{}

class MyoCorrectTherapist{
    constructor({userID, firstName, lastName, myoCorrectTherapistType, personID}: IMyoCorrectTherapist) {
        this.userID = userID;
        this.firstName = firstName;
        this.lastName = lastName;
        this.myoCorrectTherapistType = myoCorrectTherapistType;
        this.personID = personID;
    }
    toObject(): IMyoCorrectTherapist {
        return {...this};
    }
}

export default MyoCorrectTherapist;