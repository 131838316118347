// Vendors
import React from 'react';
import {
    Grid,
    CircularProgress
} from "@mui/material";

// Components
import Header from './components/header';
import Sidebar from './components/sidebar';
import TopFaqContent from './content/2_topFaqContent';
import NavigatingvCloudContent from './content/1_navigatingvCloudContent';
import VivosAppliancesContent from './content/3_vivosAppliancesContent';
import ProviderPracticeForms from './content/4_ProviderPracticeForms';
import PatientRecordsContent from './content/5_recordTakingContent';
import AIReportContent from './content/6_aIReportContent';
import RxProcessingContent from './content/7_rxProcessesContent';
import MyoCorrectContent from './content/8_myoCorrectContent';
import OnDemandOrthoContent from './content/9_onDemandOrthoContent';
import EducationContent from './content/10_educationEventsContent';
import PricingBillingContent from './content/11_pricingBillingContent';
import ResearchContent from './content/12_researchPublications';
import SupportContent from './content/13_supportContent';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { useUserStoreMainContext } from '../../stores/User/UserStore';

// API
import jwt_decode from 'jwt-decode';

const faqTabs = [
    'Navigating vCloud',
    'Top FAQs',
    'Vivos Appliances',
    'Provider & Practice Forms',
    'Record-Taking',
    'Airway Intelligence Reports',
    'RX Processes',
    'MyoSync',
    'On Demand Orthodontist',
    'Education and Events',
    'Pricing and Billing',
    'Research & Publications',
    'Support',

];



const KnowledgeBase: React.FC = () => {
    const store = useMainStoreContext().providerStore;
    const { setAuthData } = useUserStoreMainContext();
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(0);
    const [providerTypes, setProviderTypes] = React.useState([]);
    const [isProvider, setIsProvider] = React.useState(false);

    let decodedToken: any = [];
    let rolesDecoded: any[] = [];

    const handleChange = (newValue: number) => {
        setActiveTab(newValue);
    };

    React.useEffect(() => {
        store.getProviderDetails();
        var token = sessionStorage.getItem('token');
        if (token) {
            decodedToken = jwt_decode(token);
            setProviderTypes(JSON.parse(decodedToken.providerTypes));
            setAuthData(decodedToken);
            const rolesDecoded: any[] = JSON.parse(decodedToken.roles);
            const providerCheck = rolesDecoded.some(role => role.Name === process.env.REACT_APP_VIVOS_PROVIDER);
            setIsProvider(providerCheck);
        }
        setIsLoaded(true);
    }, [store]);

    const renderTabContent = () => {
        switch (faqTabs[activeTab]) {
            case 'Top FAQs':
                return <TopFaqContent />;
            case 'Navigating vCloud':
                return <NavigatingvCloudContent providerTypes={providerTypes} isProvider={isProvider} />;
            case 'Vivos Appliances':
                return <VivosAppliancesContent providerTypes={providerTypes} isProvider={isProvider} />;
            case 'Provider & Practice Forms':
                return <ProviderPracticeForms providerTypes={providerTypes} isProvider={isProvider} />;
            case 'Record-Taking':
                return <PatientRecordsContent providerTypes={providerTypes} isProvider={isProvider} />;
            case 'Airway Intelligence Reports':
                return <AIReportContent />;
            case 'RX Processes':
                return <RxProcessingContent providerTypes={providerTypes} isProvider={isProvider} />;
            case 'MyoSync':
                return <MyoCorrectContent />;
            case 'On Demand Orthodontist':
                return <OnDemandOrthoContent />;
            case 'Education and Events':
                return <EducationContent />;
            case 'Pricing and Billing':
                return <PricingBillingContent providerTypes={providerTypes} isProvider={isProvider} />;
            case 'Research & Publications':
                return <ResearchContent />;
            case 'Support':
                return <SupportContent />;
            default:
                return null;
        }
    };

    if (!isLoaded) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container paddingX={5} justifyContent={'center'}>
            <Grid item xs={12}>
                <Header />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3} xl={2}>
                <Sidebar
                    tabs={faqTabs}
                    activeTab={activeTab}
                    handleTabChange={handleChange}
                />
            </Grid>
            <Grid item xs={12} sm={8} md={9} lg={9} xl={10} paddingTop={2} paddingLeft={2}>
                {renderTabContent()}
            </Grid>
        </Grid>

    );
};

export default KnowledgeBase;
