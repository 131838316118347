// Stores/PrescriptionStore.ts
import { action, makeAutoObservable, observable } from 'mobx';
import { ConstructionBiteType, OcclusionModelType } from '../../entities/Enums';
import { DictionaryListItem } from '../../models/state_models/dictionarylistitem';
import { GlobalUtils } from '../../api/GlobalUtils';
import PatientVisit from '../../models/state_models/patientVisit';
import { BaseMainStore } from '../base/MainStore';
import ScanData from '../../entities/ScanData';
import { Gateway } from '../../api/Gateway';

export class ImpressionStore {
    @observable machineBrand3DType: DictionaryListItem[] = [];
    @observable patientOcclusionImages: any[] = [];
    @observable molarRelationshipType: any[] = [];
    @observable lg_machinetype: DictionaryListItem | undefined = undefined;
    @observable rmrelation: string = '';
    @observable lmrelation: string = '';
    @observable staffmember: string = '';
    @observable impressiondate: Date | null = null;
    @observable occlusionModelTypeId: OcclusionModelType = OcclusionModelType.Digital;
    @observable constructionBiteType: ConstructionBiteType = ConstructionBiteType.Digital;
    @observable state2Dor3D: number = 0;
    @observable occlusion3dimages: any[] = [];
    @observable selectedConstructionType: string = '';
    @observable imagesToDelete: number[] = [];
    @observable selectedBiteType: string = '';
    @observable constructionBiteInformationId: number | null = null;
    @observable labAdjustConstructionBite: boolean = false;
    @observable isSpeakWithLab: boolean = false;
    @observable potrusionDistance: number | null = null;
    @observable potrusionPositionId: number | null = null;
    @observable prescriptionId: number = 0;
    @observable isSavingOcclusion: boolean = false;
    
    mainStore: BaseMainStore;

    constructor(mainstore: BaseMainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        this.initializeMachineBrands();
    }

    initializeMachineBrands() {
        this.machineBrand3DType = GlobalUtils.get3DMachineBrands();
    }

    loadVisitValues(visit: PatientVisit) {
        if (visit.patientsImages) {
            const foundType = this.machineBrand3DType.find(x => x.id === visit.patientsImages[0]?.occlusion3dmachineBrandId);
            this.rmrelation = visit.patientsImages[0]?.occlusionRmolarRelationship || '';
            this.lmrelation = visit.patientsImages[0]?.occlusionLmolarRelationship || '';
            this.staffmember = visit.patientsImages[0]?.occlusionMemberTookImpression || '';
            this.lg_machinetype = foundType || undefined;
            this.impressiondate = visit.patientsImages[0]?.occlusionImpressionDate || null;
        }
    }

    @action setPrescriptionId = (newValue: number) => { this.prescriptionId = newValue };
    
    @action setIsSavingOcclusion = (newValue: boolean) => { this.isSavingOcclusion = newValue };

    @action upsertOcclusionDetails = (visitId: number) => {
        return new Promise((resolve, reject) => {
            const details = {
                machineBrandId: this.lg_machinetype?.id ?? null,
                leftMolarRelationship: this.lmrelation === '-1' || this.lmrelation === '0' 
                    ? null 
                    : parseInt(this.lmrelation, 10) || null,
                rightMolarRelationship: this.rmrelation === '-1' || this.rmrelation === '0' 
                    ? null 
                    : parseInt(this.rmrelation, 10) || null,
                impressionDate: this.impressiondate,
                staffWhoTookImpression: this.staffmember,
                modelTypeId: this.occlusionModelTypeId,
                constructionBiteInformationId: this.constructionBiteInformationId,
                constructionBiteType: this.constructionBiteType,
                labAdjustConstructionBite: this.labAdjustConstructionBite,
                isSpeakWithLab: this.isSpeakWithLab,
                potrusionDistance: this.potrusionDistance,
                potrusionPositionId: this.potrusionPositionId,
                prescriptionId: this.prescriptionId || null,
            };
            

            Gateway.post(`visitrecord/${visitId}/occlusiondetails`, details)
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    @action handleConstructionTypeChange(type: ConstructionBiteType) {
        this.constructionBiteType = type;
    }

    @action handle3DUpload(notUsed1: string, notUsed2: string, option: number) {
        this.state2Dor3D = 2;
    }

    getModelAge(impressionDate: Date | null): number {
        if (!impressionDate) return 0;
        const today = new Date();
        const date = new Date(impressionDate);
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const timeDifference = today.getTime() - date.getTime();
        return Math.floor(timeDifference / millisecondsPerDay);
    }

    @action deleteImage(itemOption: number) {
        this.imagesToDelete.push(itemOption);
    }

    @action toggleModelType(type: OcclusionModelType) {
        this.occlusionModelTypeId = type;
    }

    @action updateStaffMember(name: string) {
        this.staffmember = name;
    }

    @action updateImpressionDate(date: Date | null) {
        this.impressiondate = date;
    }

    @action updateMachineType(machineTypeId: number) {
        const foundType = this.machineBrand3DType.find(x => x.id === machineTypeId);
        if (foundType) {
            this.lg_machinetype = foundType;
        }
    }

    @action resetScan = () => {
        new ScanData({
            title: ' ',
            scantype: ' ',
            notes: '',
            imageUrl: '',
            rdbVenus: '',
            rdbSelera: '',
            rdbHead: '',
            rdbOpen: '',
            rdbIncomp: '',
            rdbMicrogenia: '',
            rdbMacrogenia: ''
        });
    };

    @action resetToInitialValues = () => {
        this.rmrelation = '';
        this.lmrelation = '';
        this.staffmember = '';
        this.lg_machinetype = new DictionaryListItem();
        this.impressiondate = new Date(Date.now());
        this.constructionBiteInformationId = 0;
        this.constructionBiteType = ConstructionBiteType.Digital;
        this.isSavingOcclusion = false;
        this.labAdjustConstructionBite = true;
        this.isSpeakWithLab = false;
        this.potrusionPositionId = 0;
        this.potrusionDistance = 0;
        this.prescriptionId = 0;
    }
}
