interface PatientData {
    id: number,
    orgId: number,
    providerId: number,
    vivosId: string,
    firstName: string,
    middleName: string,
    lastName: string,
    dateOfBirth: Date,
    ethnicityId: number,
    isProspect: boolean,
    isLincare: boolean,
    genderId: number,
    ehrsId: number,
    primaryPhone: string,
    altPhone : string,
    emailAddress: string,
    treatmentStatusId: number
}

interface Patient extends PatientData {}

class Patient {
    constructor({ id, orgId, providerId, vivosId, firstName, middleName, lastName, dateOfBirth, ethnicityId, 
        isProspect, isLincare, genderId, ehrsId, primaryPhone, altPhone, emailAddress, treatmentStatusId}: PatientData) {
        this.id = id;
        this.orgId = orgId;
        this.providerId= providerId;
        this.vivosId = vivosId;
        this.firstName = firstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.dateOfBirth= dateOfBirth;
        this.ethnicityId = ethnicityId;
        this.isProspect = isProspect;
        this.isLincare = isLincare;
        this.genderId = genderId;
        this.ehrsId = ehrsId;
        this.primaryPhone = primaryPhone;
        this.altPhone = altPhone;
        this.emailAddress = emailAddress;
        this.treatmentStatusId = treatmentStatusId;
    }

    toObject(): PatientData {
        return {...this};
    }
}
export default Patient;