// Vendor
import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Typography,
    IconButton,
    Link,
    Box,
} from '@mui/material';
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';

// Components
import { AiReportModal } from '../../AiReportOrder';
import DataGrid from "../../DataGrid/DataGrid";

// Entities
import ProviderPatient from '../../../entities/ProviderPatient';

// Store
import { PatientStore } from '../../../api/PatientStore';
import PatientEditBL from '../PatientEditBL';

// API
import { observer } from 'mobx-react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import moment from "moment";
import { report } from 'process';
import reportWebVitals from '../../../reportWebVitals';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { AIReportStatus, AIReportType } from '../../../entities/Enums';
import _, { isUndefined } from 'lodash';
import { useCreateAIReportMainStore } from '../../../stores/AiReport/CreateAIReportMainStore';

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
}

const NoDocument = () => {
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item className="noDocument">
                Ai Report has not yet been generated
            </Grid>
        </Grid>
    );
};

const MyLoadingRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || '';

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item>Loading AiReport...</Grid>
        </Grid>
    );
};

export const PatientAIReports = observer(({ patient, bl }: IGIProps) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [reports, setReports] = useState<any>([]);
    const [store] = useState(new PatientStore());
    const [openAIReportModal, setOpenAIReportModal] = useState(false);
    const { setReportsList } = useCreateAIReportMainStore().aiReportOrder;

    useEffect(() => {
        bl.loadAllReports().then(resp => {
            setReports(resp);
            setReportsList(resp);
            setHasLoaded(true);
        });
    }, [])

    const handleonClick = () => {
        store.toggleAiReportOrderModal();
    }

    const handleChange = (current: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? current : false);
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Report ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button variant="text" onClick={() => {

                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'aiReportType',
            flex: 1,
            headerName: 'Report Type',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            renderCell: params => {
                var ReportType = AIReportType[params.row.reportTypeId] === "Free Standard" ? "Standard" : AIReportType[params.row.reportTypeId]
                return (
                    <Grid container justifyContent={'center'}>
                        <Typography variant='h5'>
                            {ReportType}
                        </Typography>
                    </Grid>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                // var status = patientVisitStatus.find(x => x.id === params.row.statusId);
                return (
                    <Grid>
                        <Typography variant='subtitle1'>
                            {AIReportStatus[params.row.statusId]}
                        </Typography>
                    </Grid>
                )
            }

        },
        {
            field: 'requestedDate',
            headerName: 'Requested Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button variant="text" onClick={() => {
                        }}>{_.isUndefined(params.row.requestDate) || _.isNull(params.row.requestDate) ? '' : moment(params.row.requestDate).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'completedDate',
            headerName: 'Completed Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button variant="text" onClick={() => {
                            //  setViewOnly(true);
                            //  setNoteDetails(params.row);
                            // setIsOpen(true);
                        }}>{_.isUndefined(params.row.originalCompletion) || _.isNull(params.row.originalCompletion) ? '' : moment(params.row.originalCompletion).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'radiologyReport',
            headerName: 'Radiology Report',
            flex: 1.0,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                <Grid>
                    <CheckBoxIcon className="lightgreen" />
                </Grid>
            }
        },
        {
            field: 'AiGenerated',
            headerName: 'Generated AI Report',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                var isUndefined = params.row.reports[0] ? false : true;
                /*
                var url = decodeURI(
                    `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${patient.vivosId
                    }/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${params.row.id}/${params.row.reports[0]?.link}`,
                );
                */
                var url = params.row.reports[0]?.link?.includes("http")
                ? decodeURI(params.row.reports[0]?.link)
                : decodeURI(
                      `${process.env.REACT_APP_BLOBSTORAGE_URL}/${process.env.REACT_APP_PATIENT_CONTAINER}/${patient.vivosId
                      }/${process.env.REACT_APP_AIREPORTS_CONTAINER}/${params.row.id}/${params.row.reports[0]?.link}`
                  );
                return (
                    (!isUndefined &&
                        <Grid>
                            <IconButton >
                                <Link
                                    href={url}
                                >
                                    <DownloadTwoToneIcon />
                                </Link>

                            </IconButton>
                            {/* /&nbsp;
                            <IconButton onClick={() => { window.open(url, '_blank') }}>
                                <LinkTwoToneIcon />
                            </IconButton> */}
                        </Grid>)
                )
            }
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                {
                    <Grid>
                        <CheckBoxIcon className="green" />
                    </Grid>
                }
            }
        },

    ];

    const accordionReports = (indexSubmission, indexReport) => {
        var uri = decodeURI(
            `${reports[indexSubmission].reports[indexReport].link}`,
        );

        return (
            <>
                <Accordion expanded={expanded === `panel${indexSubmission}`} onChange={handleChange(`panel${indexSubmission}`)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid item xs={12} container direction={"row"} className="headerLine ">
                            <Grid item xs={8} className="label">
                                <Grid>{reports[indexSubmission].reports[indexReport].link}</Grid>
                            </Grid>
                            <Grid item xs={4} className="label">
                                <Grid>{moment(reports[indexSubmission].reports[indexReport].createdOn).format("MM/DD/yyyy hh:mm:ss")}</Grid>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails >
                        <Grid item className="viewBody" xs={12} container>
                            {patient.aiReport ? (
                                <DocViewer
                                    pluginRenderers={DocViewerRenderers}
                                    config={{
                                        loadingRenderer: {
                                            overrideComponent: MyLoadingRenderer,
                                        },
                                        noRenderer: {
                                            overrideComponent: MyLoadingRenderer,
                                        },
                                    }}
                                    documents={[
                                        {
                                            uri: uri,
                                        },
                                    ]}
                                />
                            ) : (
                                <NoDocument />
                            )}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <AiReportModal
                    isOpen={store.toggleShowAiReportOrderModal}
                    patient={patient}
                    submission={reports[0].submission}
                    reportsOrdered={reports.length}
                    onClose={store.toggleAiReportOrderModal.bind(this)}
                />
            </>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} padding={1}>
                <Button
                    variant="contained"
                    disabled={!hasLoaded}
                    onClick={() => {
                        setOpenAIReportModal(true);
                    }}
                >
                    Request New AI Report
                </Button>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    columns={columns}
                    data={reports}
                    loading={!hasLoaded}

                    size={"small"}
                    pageSize={10}
                />
            </Grid>
            <AiReportModal
                isOpen={openAIReportModal}
                patient={patient}
                reportsOrdered={reports.length}
                // submission={reports[0].submission}
                // onClose={store.toggleAiReportOrderModal.bind(this)}
                onClose={() => {
                    setOpenAIReportModal(false);
                    // setHasLoaded(false);
                    // loadVisits().then(() => {
                    //     setHasLoaded(true);
                    // });
                }}
            />
            <Grid item container xs={12} style={{ maxHeight: "calc(100vh - 600px)", minHeight: "100px", overflowY: "auto", overflowX: "hidden" }}>
                {hasLoaded ? (reports.length > 0 ? reports.map((r, indexSubmission) => { r.reports.length > 0 ? r.reports.map((rr, indexReport) => { return accordionReports(indexSubmission, indexReport); }) : <></> }) :
                    <NoDocument />) : <></>}
            </Grid>
        </Grid>


    );
});
