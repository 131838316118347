import { Mode, CalendarMonthRounded } from "@mui/icons-material";
import { IconButton, Grid } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { isNull, isUndefined } from "lodash";
import { Button } from "reactstrap";
import Moment from 'moment';
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

interface ScheduleDateTimeProps {
	params: GridRenderCellParams,
	setAlertTitle: (alertTitle: string) => void,
	setAlertMessage: (alertMessage: string) => void,
	setOpenAlertMessage: (openAlertMessage: boolean) => void,
	setPrevModalValue: (prevModalValue: any) => void,
	setModalfieldName: (modalfieldName: string) => void,
	setShowDateTimeModal: (showDateTimeModal: boolean) => void,
	setCurrentParamsRow: (currentParamsRow: any) => void,
	minDate: any,
}

export const ScheduleDateTime = ({ params, setAlertTitle, setAlertMessage, setOpenAlertMessage, setModalfieldName, setPrevModalValue, setShowDateTimeModal, setCurrentParamsRow, minDate }: ScheduleDateTimeProps) => {
	const {
		setOpenCalendly,
		setOpenCalendlyChangeSession,
		setSchedulerLink,
		setSelectedSessionNumber,
		setSelectedSessionStatus,
		setOpenRescheduleCalendly,
		setCalendlyRescheduleUri,
		setPreviousDateReschedule,
	} = useMainStoreContext().myoCorrectAdminStore;
	const myoCorrectStore = useMainStoreContext().myoCorrectStore;

	const handleReschedule = (row: any) => {
		if (row.assignedTherapistId) {
			if (row.calendlyRescheduleUri) {
				setTimeout(() => {
					setCalendlyRescheduleUri(row.calendlyRescheduleUri);
					setSelectedSessionNumber(row.sessionIndexNumber);
					setSelectedSessionStatus(row.scheduleStatus);
					setPreviousDateReschedule(Moment(Moment.utc(row.scheduleDateTime).toDate()).format('MM/DD/yyyy @ hh:mmA'));
					setOpenRescheduleCalendly(true);

				}, 200);
			} else {
				setTimeout(() => {
					setSchedulerLink(row.schedulerLink);
					setSelectedSessionNumber(row.sessionIndexNumber);
					setSelectedSessionStatus(row.scheduleStatus);
					setPreviousDateReschedule(Moment(Moment.utc(row.scheduleDateTime).toDate()).format('MM/DD/yyyy @ hh:mmA'));
					setOpenCalendlyChangeSession(true);

				}, 200);
			}
		}
		else {
			setAlertTitle('');
			setAlertMessage('There is no assigned Therapist');
			setOpenAlertMessage(true);
		}
	}

	const formatedDate = Moment(Moment.utc(params.row.scheduleDateTime).toDate()).format('MM/DD/yyyy @ hh:mmA');

	const isRescheduleable = ((!isUndefined(params.row.scheduleStatus) && !isNull(params.row.scheduleStatus)) &&
		(params.row.scheduleStatus === 'Scheduled' || params.row.scheduleStatus === 'Rescheduled') &&
		(!isUndefined(params.row.scheduleDateTime) && !isNull(params.row.scheduleDateTime)));

	const isCompleted = ((!isUndefined(params.row.scheduleStatus) && !isNull(params.row.schedleStatus)) &&
		(params.row.scheduleStatus === 'Complete'));

	const readyToSchedule = (((!isNull(params.row.assignedTherapistId) && params.row.assignedTherapistId !== 0)) &&
		(isUndefined(params.row.scheduleDateTime) || isNull(params.row.scheduleDateTime)));

	const sessionZeroOrAssigned = params.row.assignedTherapistId || params.row.sessionIndexNumber === 0;

	const isSessionZeroOrOne = params.row.sessionIndexNumber === 0 || params.row.sessionIndexNumber === 1;

	const schedullerLinkUnasigned = !params.row.schedulerLink;

	return (
		<Grid>
			{
				isRescheduleable &&
				<>
					<Button onClick={() => handleReschedule(params.row)} disabled={myoCorrectStore.isProvider}>

						{formatedDate}
					</Button>
					{!myoCorrectStore.isProvider && <Mode id="appointments.cell.btn.manualSchedule" className="icon small" onClick={() => {
						setCurrentParamsRow(params.row);
						setPrevModalValue(params.row.scheduleDateTime);
						setModalfieldName("scheduleDateTime");
						setShowDateTimeModal(true);
					}}
					></Mode>}
				</>

			}
			{
				isCompleted &&
				<Button onClick={() => {
					setCurrentParamsRow(params.row);
					setPrevModalValue(params.row.scheduleDateTime);
					setModalfieldName("scheduleDateTime");
					setShowDateTimeModal(true);
				}}
					disabled={myoCorrectStore.isProvider}>
					{formatedDate}
				</Button>
			}
			{
				readyToSchedule &&
				<Grid>
					<IconButton id="appointments.cell.btn.scheduleDateTime" 
					//disabled={myoCorrectStore.isProvider} 
					disabled={!myoCorrectStore.isMyoCorrectAdmin} 
					onClick={() => {
						if (sessionZeroOrAssigned) {
							if (isCompleted) {
								setCurrentParamsRow(params.row);
								setModalfieldName("scheduleDateTime");
								setPrevModalValue(params.row.scheduleDateTime);
								setShowDateTimeModal(true);
							}
							else if (isSessionZeroOrOne) {
								if (schedullerLinkUnasigned) {
									setAlertTitle('Therapist link not assigned');
									setAlertMessage('Please contact your administrator to assign a therapist link');
									setOpenAlertMessage(true);
								} else {
									setTimeout(() => {
										setSchedulerLink(params.row.schedulerLink);
										//setSchedulerLink(params.row.sessionIndexNumber === 0 ? ROUND_ROBIN : params.row.schedulerLink);
										setSelectedSessionNumber(params.row.sessionIndexNumber);
										setOpenCalendly(true);
									}, 200);
								}
							} else {
								if (minDate) {
									setTimeout(() => {
										setSchedulerLink(params.row.schedulerLink);
										setSelectedSessionNumber(params.row.sessionIndexNumber);
										setSelectedSessionStatus(params.row.scheduleStatus);
										setSelectedSessionStatus(params.row.scheduleStatus);
										setOpenCalendlyChangeSession(true);
									}, 200);
								} else {
									setAlertTitle('Not Available');
									setAlertMessage('Please schedule the initial session first');
									setOpenAlertMessage(true);
								}
							}
						}
						else {
							setAlertTitle('');
							setAlertMessage('There is no assigned Therapist');
							setOpenAlertMessage(true);
						}
					}}>
						<CalendarMonthRounded />
					</IconButton>

					{!myoCorrectStore.isProvider && <Mode id="appointments.cell.btn.manualSchedule" className="icon small" onClick={() => {
						setCurrentParamsRow(params.row);
						setPrevModalValue(params.row.scheduleDateTime);
						setModalfieldName("scheduleDateTime");
						setShowDateTimeModal(true);
					}}></Mode>}
				</Grid>
			}
		</Grid>
	);
}