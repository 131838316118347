import React from 'react';
import { Box, useTheme } from '@mui/material';

const MoreInfoIndicator = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                mr: 0.5,
                width: 10,
                height: 10,
                backgroundColor: theme.colors.warning.main,
                borderRadius: '50%',
                display: 'inline-block'
            }}
        />
    );
};

export default MoreInfoIndicator;