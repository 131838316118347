interface IQuestionaireMetaData{
    id: number,
    value: string,
    defaultValue: string
}

interface QuestionaireMetaData  extends IQuestionaireMetaData {}

class QuestionaireMetaData{
    
    constructor(id: number, value: string, defaultValue: string ) {
        this.id = id;
        this.value = value;
        this.defaultValue = defaultValue;
    }

}
export default QuestionaireMetaData;