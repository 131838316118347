// Vendors
import React, { FC, useState, useEffect } from 'react';
import { Grid, MenuItem, TextField, Typography, Box, styled } from '@mui/material';

// Components

// Entities

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import moment from "moment";
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
    `
);

interface IGIProps {
    prescriptionData: any;
    labAccessed: boolean;
}

export const RxHeaderDetails: FC<IGIProps> = observer(({ prescriptionData, labAccessed }) => {
    const store = usePrescriptionStore().prescriptionStore;
    const labManagementStore = useMainStoreContext().labManagementStore;
    const [prodStatusId, setProdStatusId] = useState(prescriptionData.productionStatus ? prescriptionData.productionStatus : 0);


    useEffect(() => {
        store.setPrescription(prescriptionData);
    }, []);

    return (
        <Grid container height={'100%'}>
            <Box height={'175px'} display={'flex'} flex={2} alignSelf={'center'}>
                <Grid container>
                    <Grid item xs={12} paddingBottom={1} position={'relative'}>
                        <Typography variant='h2' color={'white'} textAlign={'center'}>
                            Rx Overview
                        </Typography>
                        {/* {true && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    bgcolor: 'red',
                                    color: 'white',
                                    fontWeight: 'bold',
                                    padding: '4px 8px',
                                    borderTopRightRadius: 8,
                                    borderBottomLeftRadius: 8
                                }}
                            >
                                <LabelWrapper
                                    sx={(theme) => ({
                                        background: theme.palette.error.main,
                                        color: theme.palette.getContrastText(theme.palette.error.dark),
                                    })}
                                >
                                    Rush
                                </LabelWrapper>
                            </Box>
                        )} */}
                    </Grid>
                    <Grid item xs={12} paddingBottom={1} container direction="column">
                        <Typography variant="h4" color="white" textAlign="center">
                            {/* If prescriptionData or appliance is null, display an empty string as a fallback */}
                            <b>{prescriptionData?.appliance?.name ?? ''}</b>
                        </Typography>

                        <Typography variant="h5" color="white" textAlign="center">
                            Rx Submitted:{' '}
                            <b>
                                {/* Only format date if submittedDate exists, otherwise show empty string */}
                                {prescriptionData?.submittedDate
                                    ? moment(prescriptionData.submittedDate).format('MM/DD/yyyy')
                                    : ''}
                            </b>
                        </Typography>

                        <Typography variant="h5" color="white" textAlign="center">
                            Rx ID:{' '}
                            {/* Fallback to empty string if rxId is not present */}
                            <b>{prescriptionData?.rxId ?? ''}</b>
                        </Typography>
                    </Grid>
                    {!labAccessed && <Grid item xs={6} paddingRight={1}>
                        <Typography variant="h5" color={'white'}>
                            QC Status:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'claimStatus'}
                            select
                            defaultValue="0"
                            value={prescriptionData?.qcstatus}
                            onChange={e => {
                                var newValue = parseInt(e.target.value);

                                store.updateQcStatus(newValue);//(submission.id, e.target.value);
                            }}
                        >
                            {
                                store.qcStatusValues.map((ps) =>
                                    (<MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>))
                            }
                        </TextField>
                    </Grid>}
                    <Grid item xs={6} paddingLeft={1}>
                        <Typography variant="h5" color={'white'}>
                            Production Status:
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'productionStatus'}
                            select
                            defaultValue="0"
                            value={prodStatusId}
                            onChange={event => {
                                setProdStatusId(event.target.value as unknown as number);
                                labManagementStore.handleProductionStatusChange(prescriptionData, event.target.value as unknown as number);
                            }}
                        >
                            {
                                store.productionStatusValues.map((ps) =>
                                    (<MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>))
                            }
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
});
