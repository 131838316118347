// Vendors
import { useEffect, useState } from "react";
import { Grid, Tab, Tabs, Typography, Box, ButtonGroup, Button } from "@mui/material";

// Components
import Epworth from "../../../components/IntakeReports/Epworth";
import HeadacheImpact from "../../../components/IntakeReports/HeadacheImpact";
import HeadNeck from "../../../components/IntakeReports/HeadNeck";
import PerceivedStress from "../../../components/IntakeReports/PerceivedStress";
import StopBang from "../../../components/IntakeReports/StopBang";
import SleepStudy from "../../../components/IntakeReports/SleepStudy";
import SleepTest from "../../../components/ImagesAndDocs/SleepTest";
import { PatientHealthHistoryTwo } from "../../../components/PatientProfile/PatientDetailComponents/PatientHealthHistoryTwo";
import PatientEditBL from "../../../components/PatientProfile/PatientEditBL";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";
import AIReportsSleepTest from "../../../components/ImagesAndDocs/AIReportsSleepTest";

interface ComponentProps {
  visit: any,
  patient: any
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`report-info-tabpanel-${index}`}
      aria-labelledby={`report-info-tab-${index}`}
      style={{ maxHeight: "calc(100vh - 600px)", minHeight: "100px", overflowY: "auto", overflowX: "hidden" }}
      {...other}
    >
      {value === index && (
        <Grid sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Grid>
      )}
    </div>
  );
}


function a11yProps(index: number) {
  return {
    id: `report-info-tab-${index}`,
    'aria-controls': `report-info-tabpanel-${index}`,
  };
}

const PATIENTINTAKE = 0;
const HEADNECK = 1;
const SLEEPSTUDY = 2;
// const EPWORTH = 3;
// const PSQ = 4;
// const STOPBANG = 5;
// const HIT6 = 6;


function AIReportInfo(props: ComponentProps): JSX.Element {
  const [editBL] = useState(new PatientEditBL());
  const patientHistoryEditStore = useMainStoreContext().patientEditStore;
  const { visit } = props;
  const [value, setValue] = useState(0);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    editBL.patientData = props.patient;

    editBL.loadDetails().then(() => {
      patientHistoryEditStore.setPatientHistory(editBL.patientHistory);
      patientHistoryEditStore.setHistory(editBL.patientHistory);
    });
  })

  return (
    <Grid>
      <Box sx={{ width: "100%", height: "100%" }} padding={2}>
        <Box sx={{ borderBottom: 0 }}>
          <Tabs className="tabs"
            value={value}
            onChange={handleChange}
            aria-label="inner report info tabs"
          >
            <Tab label="Patient Intake" {...a11yProps(PATIENTINTAKE)} />
            <Tab label="Head & Neck" {...a11yProps(HEADNECK)} />
            <Tab label="Sleep Study" {...a11yProps(SLEEPSTUDY)} />
            {/* <Tab label="Epworth" {...a11yProps(EPWORTH)} />
            <Tab label="PSQ" {...a11yProps(PSQ)} />
            <Tab label="Stop-Bang" {...a11yProps(STOPBANG)} />
            <Tab label="HIT-6" {...a11yProps(HIT6)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={PATIENTINTAKE}>
          <PatientHealthHistoryTwo bl={new PatientEditBL()} patient={props.patient} />
        </TabPanel>
        <TabPanel value={value} index={HEADNECK}>
          <HeadNeck visit={visit} />
        </TabPanel>
        <TabPanel value={value} index={SLEEPSTUDY}>
          {/* <SleepStudy visitId={visitId} /> */}
          <AIReportsSleepTest patientId={props.patient.id} />
        </TabPanel>
        {/* <TabPanel value={value} index={EPWORTH}>
          <Epworth visitId={visit.id} />
        </TabPanel>
        <TabPanel value={value} index={PSQ}>
          <PerceivedStress visitId={visit.id} />
        </TabPanel>
        <TabPanel value={value} index={STOPBANG}>
          <StopBang visitId={visit.id} />
        </TabPanel>
        <TabPanel value={value} index={HIT6}>
          <HeadacheImpact visitId={visit.id} />
        </TabPanel> */}
      </Box>
    </Grid>
  );
}

export default AIReportInfo;
