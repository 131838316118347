import { useEffect, useMemo, useState } from "react";
import DataGrid from "../../DataGrid/DataGrid";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

import {
    Button,
    Divider,
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    Box,
    styled,
    IconButton,
    Tooltip,
    Card,
    alpha,
    Popover,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import TabletMacTwoToneIcon from '@mui/icons-material/TabletMacTwoTone';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import CustomForm from "../../../models/state_models/customForm";
import { useCreatePatientMainStoreContext } from "../../../stores/Patient/Create/CreatePatientMainStore";
import { GlobalUtils } from "../../../api/GlobalUtils";
import DictionaryListItem from "../../../entities/DictionaryListItem";
import { AlertDialog } from "../../AlertDialog";
import PatientEditBL from "../PatientEditBL";
import Documents from "../../ImagesAndDocs/Documents";

interface IProps {
    isIntake: boolean;
    visit: any;
    isPatientFormStep: boolean;
    patient?: any;
}

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
);

const HelpIconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
        background: transparent;
        transition: ${theme.transitions.create(['all'])};
        color: ${theme.colors.warning.light};
        border-radius: 50px;
    
        &:hover {
          background: transparent;
          color: ${theme.colors.warning.dark};
        }
    `
);

const CardContentWrapper = styled(Box)(
    ({ theme }) => `
      background: ${theme.colors.alpha.white[100]};
      border-radius: ${theme.general.borderRadius};
    `
);

export const DigitalFormResponses: React.FC<IProps> = ({ isIntake, visit, isPatientFormStep, patient }) => {
    const store = useMainStoreContext().customFormStore;

    const [data, setData] = useState<any[]>([]);
    const [formsSelected, setFormsSelected] = useState<CustomForm[]>([]);
    const [reportTypes, setReportTypes] = useState<DictionaryListItem[]>([]);
    const { visitStore, patientStore, customFormStore } = useCreatePatientMainStoreContext();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [editBL] = useState(new PatientEditBL());

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const [anchorE2, setAnchorE2] = useState<HTMLElement | null>(null);
    const open2 = Boolean(anchorE2);

    useMemo(() => {
        GlobalUtils.getIntakeReports().map(item => {
            setReportTypes(prevState => [...prevState, item].filter(x => x.listItemKey !== "SLEEPTEST"));
        });
        customFormStore.getCustomFormType(isPatientFormStep, isIntake).then((forms) => {
            if (forms) {
                setFormsSelected(forms);
                setIsLoading(false);
            }

        });
    }, []);

    useEffect(() => {
        store.loadResponsesQuestionaireData(patient.id).then((response) => {
            setData(response);
        })
    }, [])
    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'ID', flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {

                return (
                    <Grid>
                        <Button className="MuiButton-textPrimary" id={"patientEdit.Responses." + params.row.ID} variant="text" onClick={() => {
                            store.viewQuestionaireId = params.row.questionaireId;
                            store.viewResponseQuestionaireId = params.row.id;
                            store.toggleCustomFormModal();
                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }
        },
        {
            field: 'questionaire.name', headerName: 'Form Name', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
            renderCell: (params) => {
                return (<Grid>{params.row.questionaire.name}</Grid>)
            }
        },
        {
            field: 'createdOn', type: 'date', headerName: 'Response Date', flex: 1, headerAlign: "center", headerClassName: "colHeader", align: "left",
            valueFormatter: (params: GridValueFormatterParams<Date>) => {
                if (params.value == null) {
                    return '';
                }

                const valueFormatted = new Date(params.value).toLocaleDateString();
                return `${valueFormatted}`;
            }
        },
    ]


    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen2 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorE2(event.currentTarget);
    };

    const handlePopoverClose2 = () => {
        setAnchorE2(null);
    };

    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    async function handleSendEmails(): Promise<void> {
        if (isIntake) {
            customFormStore.sendCustomForm(formsSelected.filter(x => x.isPatientForm), patientStore.patient.id, patientStore.patient.providerId ? patientStore.patient.providerId : 0, visitStore.visit.id).then(() => {
                alert("Custom Form has been sent to Patient");
            })
        }
        else {
            customFormStore.sendCustomForm(formsSelected.filter(x => x.isPatientForm), patient.id, patient.providerId, visit.id).then(() => {
                alert("Custom Form has been sent to Patient");
            })
        }
        handleConfirmDialogClose();

    }

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    async function handleShowUrl(): Promise<void> {
        if (isIntake) {
            customFormStore.getFormUrl(patientStore.patient.id, patientStore.patient.providerId ?? 0, visitStore.visit.id, formsSelected.filter(x => !x.isPatientForm && x.selected));
        } else {
            customFormStore.getFormUrl(patient.id, patient.providerId, visit.id, formsSelected.filter(x => !x.isPatientForm && x.selected));
        }
    }

    return (
        <Grid container>
            {isIntake
                ?
                <Grid item xs={12} padding={2}>
                    <Typography variant="h2" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                        Digital Forms
                    </Typography>
                    <Typography variant="body1" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                        <i>*Step is optional, to upload completed documents and files proceed to the next step.</i>
                    </Typography>
                    <Typography variant="h4" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                        Select the forms below that you would like to email to your patient or select the
                        clinical forms you would like to fill out in another browser tab.
                    </Typography>
                </Grid>
                :
                <Grid item xs={12} padding={2}>
                    <Typography variant="h3" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                        Initiate new forms to be associated to this Visit Record
                    </Typography>
                </Grid>
            }
            <Grid container justifyContent={'center'}>
                <Grid item xs={3} container padding={2} paddingBottom={10}>
                    {/* DIGITAL PATIENT FORMS CARD */}
                    <Card sx={(theme) => ({
                        padding: 1,
                        background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                    })}>
                        <Grid item>
                            <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                                Digital Patient Forms
                                <HelpIconButtonWrapper
                                    onClick={handlePopoverOpen}
                                    sx={{
                                        marginLeft: 1
                                    }}
                                >
                                    <HelpTwoToneIcon fontSize="small" />
                                </HelpIconButtonWrapper>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" paddingLeft={3} paddingTop={1} alignItems="center">
                                Please select the forms you would like to <strong> email </strong> your patient to complete prior to their appointment or in
                                conjunction with their visit.
                            </Typography>
                        </Grid>
                        <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{ style: { width: '500px', padding: '20px' } }}
                        >
                            <Box
                                pt={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    noWrap
                                    variant="h4"

                                >
                                    Welcome to Patient Digital Forms
                                </Typography>
                                <Divider
                                    sx={{
                                        mt: 3
                                    }}
                                    flexItem
                                />
                                <Box
                                    p={2}
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography variant='subtitle2' textAlign={'left'}>
                                                Digital forms will be on a continual refinement cycle to help improve your vCloud Provider Portal experience.
                                                Utilizing digital forms improves patient data collection and minimizes the need for patients to complete paper forms.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='subtitle2' textAlign={'left'}>
                                                Please note that digital forms do not replace your standard paper form procedures or other form processes.
                                                You can still upload traditional forms. Digital forms will be sent to patients via email, so it is important
                                                to collect the preferred email address from each patient. Once completed, the digital forms will be returned
                                                and available in the visit records or under the completed forms section in the patient profile.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Popover>
                        <CardContentWrapper>
                            <Grid container paddingLeft={4} paddingRight={5} marginLeft={5} style={{ overflow: 'auto' }}>
                                {formsSelected.filter(x => x.isPatientForm).map((csForm, index, csFArray) => {
                                    return (
                                        <Grid key={csForm.id} item xs={12}><FormControlLabel label={csForm.title}
                                            control={
                                                <Checkbox value={csForm.selected} onChange={(e, check) => { csForm.selected = check }} />
                                            } /></Grid>
                                    )
                                })}
                            </Grid>
                        </CardContentWrapper>
                        <Grid container paddingTop={5} spacing={2} direction={'column'} justifyItems={'center'}>
                            <Box display={'flex'} justifyContent={'right'} alignItems={'center'} paddingRight={1}>
                                <Tooltip title={'Email'} arrow>
                                    <IconButtonWrapper
                                        style={{ alignSelf: "center" }}
                                        sx={(theme) => ({
                                            ml: 1,
                                            backgroundColor: theme.colors.info.lighter,
                                            color: theme.colors.info.main,
                                            transition: theme.transitions.create(['all']),

                                            '&:hover': {
                                                backgroundColor: theme.colors.info.main,
                                                color: theme.palette.getContrastText(
                                                    theme.colors.info.main
                                                )
                                            }
                                        })
                                        }
                                        onClick={handleConfirmDialogOpen}
                                    >
                                        <EmailTwoToneIcon fontSize="large" />
                                    </IconButtonWrapper>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={3} container padding={2} paddingBottom={10}>
                    {/* DIGITAL CLINICAL FORMS CARD*/}
                    <Card sx={(theme) => ({
                        padding: 1,
                        background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                    })}>

                        <Grid item>
                            <Typography variant="h3" paddingLeft={3} paddingTop={1}>
                                Digital Clinical Forms
                                <HelpIconButtonWrapper
                                    onClick={handlePopoverOpen2}
                                    sx={{
                                        marginLeft: 1
                                    }}
                                >
                                    <HelpTwoToneIcon fontSize="small" />
                                </HelpIconButtonWrapper>
                            </Typography>
                        </Grid>
                        <Typography variant="subtitle1" paddingLeft={3} paddingTop={1}>
                            Please select a form you would like to digitally complete now regarding this patient in a new browser tab.
                        </Typography>
                        <Popover
                            id="mouse-over-popover"
                            open={open2}
                            anchorEl={anchorE2}
                            onClose={handlePopoverClose2}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{ style: { width: '500px', padding: '20px' } }}
                        >
                            <Box
                                pt={4}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    noWrap
                                    variant="h4"

                                >
                                    Welcome to Clinical Digital Forms
                                </Typography>
                                <Divider
                                    sx={{
                                        mt: 3
                                    }}
                                    flexItem
                                />
                                <Box
                                    p={2}
                                    sx={{
                                        width: '100%'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography variant='subtitle2' textAlign={'left'}>
                                                Digital forms will be on a continual refinement cycle to help improve your vCloud Provider Portal experience.
                                                Utilizing digital forms improves patient data collection and minimizes the need to complete paper forms.
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='subtitle2' textAlign={'left'}>
                                                Please note that digital forms do not replace your standard paper form procedures or other form processes.
                                                You can still upload traditional forms. Clinical and provider indicated forms can be completed on a mobile device
                                                or on your computer. Once completed, the digital forms will be returned  and available in the visit records
                                                or under the completed forms section in the patient profile.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Popover>
                        <CardContentWrapper>
                            <Grid container paddingLeft={4} paddingRight={5} marginLeft={5}>
                                {formsSelected.filter(x => !x.isPatientForm).map((csForm, index, csFArray) => {
                                    return (
                                        <Grid key={csForm.id} item xs={12}><FormControlLabel label={csForm.title}
                                            control={
                                                <Checkbox value={csForm.selected} onChange={(e, check) => { csForm.selected = check }} />
                                            } /></Grid>
                                    )
                                })}
                            </Grid>
                        </CardContentWrapper>
                        <Grid container paddingTop={5} spacing={2} direction={'column'} justifyItems={'center'}>
                            <Box display={'flex'} justifyContent={'right'} alignItems={'center'} paddingRight={1}>
                                <Tooltip title={'Digitally fill out forms now.'} arrow>
                                    <IconButtonWrapper
                                        style={{ alignSelf: "center" }}
                                        sx={(theme) => ({
                                            ml: 1,
                                            backgroundColor: theme.colors.info.lighter,
                                            color: theme.colors.info.main,
                                            transition: theme.transitions.create(['all']),

                                            '&:hover': {
                                                backgroundColor: theme.colors.info.main,
                                                color: theme.palette.getContrastText(
                                                    theme.colors.info.main
                                                )
                                            }
                                        })
                                        }
                                        onClick={handleShowUrl}
                                    >
                                        <TabletMacTwoToneIcon fontSize="large" />
                                    </IconButtonWrapper>
                                </Tooltip>
                            </Box>
                        </Grid>
                        <AlertDialog
                            close={() => editBL.openAlertMessage(false)}
                            open={editBL.openAlertMessage}
                            title={editBL.alertTitle}
                            message={editBL.alertMessage}
                        />
                    </Card>
                </Grid>
            </Grid>

            {/* DATA GRID TO HOLD ALL FILLED FORMS */}
            {!isIntake &&
                <Grid item xs={12}>
                    <Grid paddingBottom={1}>
                        <Typography variant="h3" paddingLeft={3} paddingTop={1} textAlign={'center'}>
                            Below you will find forms completed in association to this Visit Record.
                        </Typography>
                    </Grid>
                    <DataGrid
                        columns={columns}
                        data={data}
                        size={"small"}
                        pageSize={10}
                    />
                </Grid>
            }

            {/* CONFIRM THE FORM SEND VIA EMAIL DIALOG */}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleConfirmDialogClose}
                aria-labelledby="confirm-form-dialog"
            >
                <DialogTitle id="confirm-form-dialog">Confirm Forms to Send to Patient</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">Selected Forms:</Typography>
                    <ul>
                        {formsSelected.filter(form => form.selected && form.isPatientForm).map((form) => (
                            <li key={form.id}>{form.title}</li>
                        ))}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Button onClick={handleConfirmDialogClose} color="inherit" variant='contained'>
                            Exit
                        </Button>
                        <Button onClick={handleSendEmails} color="primary" variant='contained'>
                            Send Forms
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Grid>
    )
};



{/* {isIntake && <Grid container>
                <Grid item xs={3} container direction={'column'} padding={2} paddingBottom={10}>
                    <Typography variant="h3" paddingLeft={3} paddingTop={1}>Documents</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Documents isIntake={true} visit={visitStore.visit} isPatientFormStep={true} />
                </Grid>
            </Grid>} */}