// Vendors
import React from 'react';
import { Component, useState, ChangeEvent } from 'react';
import {
    Button,
    Dialog,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Box,
    IconButton,
    Radio,
    RadioGroup,
    FormControlLabel,
    styled,
    Avatar,
} from '@mui/material';
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { Close } from '@mui/icons-material';

// Components
import { CommentsPanel } from '../../../components/CommentsPanel/CommentsPanel';
import PatientEditBL from '../PatientEditBL';
import { PatientCustomFormModal } from '../PatientModalCustomForm';

// Entities
import MyoCorrectPatient from '../../../entities/MyCorrectPatient';
import ProviderPatient from '../../../entities/ProviderPatient';

// Stores
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import CommentsEditStore from '../../CommentsPanel/CommentsEditStore';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import { InlineWidget } from 'react-calendly';
import Logo from '../../../../public/logos/AIS.png'
import { PatientStore } from '../../../api/PatientStore';


const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);
const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

const DialogContentWithLogo = styled(DialogContent)(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.grey[300],
    '&::before': { // 
        content: '""',
        position: 'absolute',
        left: theme.spacing(5),
        top: theme.spacing(0),
        width: '20%',
        height: '20%',
        backgroundImage: 'url("/logos/AIS.png")',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
    }
}));

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
    onClose();
}

interface IState {
    calendlyOpen: boolean;
    currentStep: number;
    questionAnswer: string | null;
    toggleUploadModal: boolean;
    reports: any[];
}

// USED AN INTERVAL CHECK METHOD TO KEEP CHECKING TO SEE IF THE PatientEditBL
// HAS BEEN LOADED FULLY BEFORE CHECKING THE AMOUNT OF AIREPORTS EXIST FOR THE
// SPECIFIC PATIENT. (NOT SURE IF THIS IS THE BEST PRACTICE)

@observer
export class PatientProfileActionButtons extends Component<IGIProps, IState> {
    commentsEdit = new CommentsEditStore();
    store = new PatientStore();
    intervalId: NodeJS.Timeout | null = null;
    constructor(props: IGIProps) {
        super(props);
        this.state = {
            calendlyOpen: false,
            currentStep: 1,
            questionAnswer: null,
            toggleUploadModal: false,
            reports: [],
        };
    }


    componentDidMount() {
        this.checkIfLoaded();
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    checkIfLoaded = () => {
        this.intervalId = setInterval(() => {
            if (!this.props.bl.isloading) {
                clearInterval(this.intervalId!);
                this.props.bl.loadAllReports().then(resp => {
                    this.setState({ reports: resp });
                });
            }
        }, 100);
    };

    openDialog = () => {
        this.setState({ toggleUploadModal: true });
    };

    closeDialog = () => {
        this.setState({ toggleUploadModal: false });
    }

    openCalendlyDialog = () => {
        this.setState({ calendlyOpen: true });
    };

    closeCalendlyDialog = () => {
        this.setState({ calendlyOpen: false, currentStep: 1, questionAnswer: null });
    };

    handleNextStep = () => {
        this.setState({ currentStep: 2 });
    };

    handlePreviousStep = () => {
        this.setState({ currentStep: 1 });
    };

    handleQuestionChange = (newAnswer) => {
        this.setState({ questionAnswer: newAnswer });
    };

    renderQuestionStep = () => (
        <DialogContentWithLogo>
            <Grid height={"800px"} alignContent={'center'} justifyContent={'center'} container direction={'column'}>
                <Grid width={'500px'} container>
                    <Grid item paddingBottom={1} paddingTop={2}>
                        <Typography variant="h3">Airway Intelligence Service Consultation</Typography>
                    </Grid>
                    <Grid item paddingBottom={1}>
                        <Typography variant="subtitle1" textAlign={'justify'} paddingY={3}>
                            <strong>Reminder:</strong>
                            <ul>
                                <li>To achieve the most out of your patient specific consultation we recommend that your
                                    Airway Intelligence Report is ready, teeth charting is completed, and the initial steps of
                                    treatment planning are filled out.
                                </li>
                            </ul>
                        </Typography>
                    </Grid>
                    <Grid item paddingTop={1}>
                        <Typography variant="h5">Please Select:</Typography>
                    </Grid>
                    <Grid item spacing={1} container>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant={this.state.questionAnswer === 'option1' ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => this.handleQuestionChange('option1')}
                                sx={{
                                    justifyContent: 'flex-start',
                                    paddingLeft: 2,
                                }}
                            >
                                AIS Clinical and Appliance Support
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                variant={this.state.questionAnswer === 'option2' ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => this.handleQuestionChange('option2')}
                                sx={{
                                    justifyContent: 'flex-start',
                                    paddingLeft: 2,
                                }}
                            >
                                AIS Clinical and Appliance Support (Comprehensive)
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DialogActions>
                <Button onClick={this.handleNextStep} variant="contained" color="primary">
                    Next
                </Button>
            </DialogActions>
        </DialogContentWithLogo>
    );

    renderCalendlyStep = () => {
        const { provider, providerEmail, vivosId } = this.props.patient;
        console.log(providerEmail)
        const baseQuery = `name=${encodeURIComponent(provider)}&email=${encodeURIComponent(providerEmail)}&a2=${encodeURIComponent(vivosId)}`;

        const url = this.state.questionAnswer === 'option1'
            ? `https://calendly.com/aisspecialist/airway-intelligence-service-30?${baseQuery}`
            : `https://calendly.com/aisspecialist/airway-intelligence-service-60?${baseQuery}`;

        return (
            <DialogContent>
                <InlineWidget url={url} styles={{ height: '800px' }} />
                <DialogActions>
                    <Grid container>
                        <Button onClick={this.handlePreviousStep} variant="contained" color="primary">
                            Back
                        </Button>
                    </Grid>
                </DialogActions>
            </DialogContent>
        );
    };


    render() {
        const { calendlyOpen, currentStep } = this.state;

        return (
            <Grid container direction="column" spacing={1}>
                {/* <PatientCustomFormModal
                    isOpen={this.headerBL.isOpenModal}
                    onClose={this.headerBL.handleCloseModal.bind(this)}
                    patientId={this.props.patient.id}
                    providerId={this.headerBL.providerId}
                /> */}
                <CommentsPanel
                    commentType={15553}
                    significantId={this.props.patient?.id}
                    isOpen={this.commentsEdit.isOpenModal}
                    onClose={this.commentsEdit.handleCloseModal.bind(this)}
                />
                <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                    <IconButton className="vivButton invert" onClick={this.props.onClose}>
                        <Close />
                    </IconButton>
                </Box>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        onClick={this.commentsEdit.handleClickModal}
                        variant='contained'
                    >
                        Patient Collaboration
                    </Button>
                </Grid>
                {/* <Grid item xs={12}>
                    <Button
                        startIcon={<DynamicFormTwoToneIcon />}
                        variant="contained"
                        onClick={this.headerBL.handleClickModal}
                        fullWidth
                    >
                        Send Forms
                    </Button>
                </Grid> */}
                {this.state.reports.length > 0 && <Grid item xs={12}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={this.openCalendlyDialog}
                    >
                        Schedule Consultation
                    </Button>
                </Grid>}

                {/* <Grid item xs={12}>
                    <Button
                        fullWidth
                        startIcon={<DynamicFormTwoToneIcon />}
                        variant="contained"
                        onClick={this.openDialog}
                    >
                        Upload Vivos Forms
                    </Button>
                </Grid> */}
                <Dialog
                    open={calendlyOpen}
                    onClose={this.closeCalendlyDialog}
                    fullWidth={true}
                    maxWidth="lg"
                >
                    {/* <DialogTitle>Schedule a Consultation</DialogTitle> */}
                    {currentStep === 1 ? this.renderQuestionStep() : this.renderCalendlyStep()}
                </Dialog>
                <Dialog open={this.state.toggleUploadModal} onClose={this.closeDialog}>
                    <DialogTitle
                        sx={{
                            p: 3
                        }}
                    >
                        <Typography variant="h3" gutterBottom>
                            Upload Vivos Forms
                        </Typography>
                        <Typography variant="subtitle2">
                            Upload your forms and click 'Parse' to automatically populate all patient fields by having the system read the data.
                        </Typography>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        sx={{
                            p: 3,
                        }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Box width={'100%'}>
                                        <BoxUploadWrapper >


                                            <AvatarWrapper variant="rounded">
                                                <CloudUploadTwoToneIcon />
                                            </AvatarWrapper>
                                            <Typography
                                                sx={{
                                                    mt: 2
                                                }}
                                            >
                                                Drag & drop files here
                                            </Typography>


                                        </BoxUploadWrapper>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>

                    </DialogContent>
                    <DialogActions>

                        <Grid container direction="row" item xs justifyContent={'space-between'}>
                            <Grid item xs={2}>
                                <Button id="cancel.form.button" variant="contained" color="inherit"
                                    onClick={this.closeDialog}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container spacing={2} justifyContent={'right'}>

                                    <Grid item>
                                        <Button
                                            id="send.form.button"
                                            variant="contained"
                                        >
                                            Parse Forms
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
};
