// Vendors
import react, { useEffect, useMemo, useState } from "react";
import {
    Grid,
    Typography,
    Box,
    Button,
    alpha,
    styled,
    List,
    ListItem,
    ListItemText
} from "@mui/material"

// Components
import RxConstructionContainer from "../RxConstructionContainer";

// Store
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";

// Entities
import { RxAppliances, ConstructionTypes } from "../../../../entities/Enums"

// API
import { observer } from 'mobx-react';
import DictionaryListItem from "../../../../entities/DictionaryListItem";
import { GlobalUtils } from "../../../../api/GlobalUtils";
import { usePrescriptionStore } from "../../../../stores/Prescription/CreateEdit/MainStoreContext";

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
      position: absolute;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(1)};
    `
);

const VidaSleepConstruction = () => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const [materialBase, setMaterialBase] = useState('');
    const [mandibularAdvancementOption, setMandibularAdvancementOption] = useState('');
    const [includeVerticalHooks, setIsVerticalHooks] = useState('');
    const [upperMandibularAdvancementValues, setUpperMandibularAdvancementValues] = useState<DictionaryListItem[]>([]);
    const [materialBaseValues, setMaterialBaseValues] = useState<DictionaryListItem[]>([]);
    const [loadedValues, setLoadedValues] = useState(false);

    useMemo(async () => {
        setUpperMandibularAdvancementValues(await GlobalUtils.getApplianceUpperMandibularAdvancement());
        setMaterialBaseValues(await GlobalUtils.getMaterialBase());
        prescriptionStore.loadVidaConstructionValues().then(() => {
            if (prescriptionStore.isUpdating && !prescriptionStore.isConstructionFormInProgress) {
                prescriptionStore.setConstructionAttributes();
            }
            setLoadedValues(true);
            if (prescriptionStore.isUpdating) {
                prescriptionStore.setIsConstructionFormInProgress(true)
            }
        });
    }, [])

    useEffect(() => {
        if (loadedValues) {
            // Set Material Base
            const materialBaseId = prescriptionStore.appliance.applianceSystemMaterialBaseId;
            const materialBaseName = materialBaseValues.find(x => x.id === materialBaseId)?.name || '';
            setMaterialBase(materialBaseName);

            // Set Mandibular Advancement Option
            const mandibularAdvancementId = prescriptionStore.appliance.appliancesystemUppersystemMandibularadvancementoptionId;
            const mandibularAdvancementName = upperMandibularAdvancementValues.find(x => x.id === mandibularAdvancementId)?.name || '';
            setMandibularAdvancementOption(mandibularAdvancementName);

            // Set Vertical Hooks
            const verticalHooksUpper = prescriptionStore.appliance.appliancesystemUppersystemVerticalhooksoptionBool;
            const verticalHooksLower = prescriptionStore.appliance.appliancesystemLowersystemVerticalhooksoptionBool;
            setIsVerticalHooks(verticalHooksUpper && verticalHooksLower ? 'Yes' : 'No');
        }
    }, [loadedValues]);



    useEffect(() => {
        prescriptionStore.setSelectedApplianceType(ConstructionTypes["VidaSleep"]);
    }, [prescriptionStore.unilateralBiteBlock]);


    const handleBiteLocationChange = (id) => {
        prescriptionStore.setSelectedBiteLocation(prescriptionStore.unilateralBiteBlock.find(vg => vg.id === id) ?? null);
    };
    const handleMaterialBaseChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.applianceSystemMaterialBaseId = materialBaseValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        setMaterialBase(event.currentTarget.value);
    };
    const handleVerticalHooksChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemUppersystemVerticalhooksoptionBool = event.currentTarget.value === 'Yes' ? true : false;
        prescriptionStore.appliance.appliancesystemLowersystemVerticalhooksoptionBool = event.currentTarget.value === 'Yes' ? true : false;
        setIsVerticalHooks(event.currentTarget.value);
    };
    const handleMandibularAdvancementChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemUppersystemMandibularadvancementoptionId = upperMandibularAdvancementValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        setMandibularAdvancementOption(event.currentTarget.value);
    };

    const rxComplete =
        materialBase !== '' &&
        mandibularAdvancementOption !== '' &&
        includeVerticalHooks !== '' &&
        prescriptionStore.selectedBiteLocation?.id;

    useEffect(() => {
        prescriptionStore.setIsConstructionComplete(rxComplete);
    }, [rxComplete, prescriptionStore.selectedConstructionType]);

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6} padding={1}>
                <RxConstructionContainer
                    rxComplete={rxComplete}
                    rxConstructionTitle={"VidaSleep Construction"}
                    rxConstructionComponent={
                        <ListWrapper disablePadding>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Material Base
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Thermoform"
                                                            variant={materialBase === "Thermoform" ? "contained" : "outlined"}
                                                            onClick={handleMaterialBaseChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Thermoform
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Acrylic"
                                                            variant={materialBase === "Acrylic" ? "contained" : "outlined"}
                                                            onClick={handleMaterialBaseChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Acrylic
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!materialBase && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Mandibular Advancement
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Dorsal"
                                                            variant={mandibularAdvancementOption === "Dorsal" ? "contained" : "outlined"}
                                                            onClick={handleMandibularAdvancementChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Dorsal
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!mandibularAdvancementOption && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Vertical Hooks
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Yes"
                                                            variant={includeVerticalHooks === "Yes" ? "contained" : "outlined"}
                                                            onClick={handleVerticalHooksChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Yes
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="No"
                                                            variant={includeVerticalHooks === "No" ? "contained" : "outlined"}
                                                            onClick={handleVerticalHooksChange}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            No
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!includeVerticalHooks && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Unilateral BiteBlock Location
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    {prescriptionStore.unilateralBiteBlock.map((vg) => (
                                                        <Grid item xs={9}>
                                                            <Button
                                                                key={vg.id}
                                                                variant={prescriptionStore.selectedBiteLocation?.id === vg.id ? "contained" : "outlined"}
                                                                onClick={() => handleBiteLocationChange(vg.id)}
                                                                fullWidth
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                {vg.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!prescriptionStore.selectedBiteLocation?.id && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>


                        </ListWrapper>
                    }
                />
            </Grid>
        </Grid>
    )
}; export default observer(VidaSleepConstruction)