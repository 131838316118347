import { SurveyCreatorComponent, SurveyCreator } from 'survey-creator-react';
import { Serializer, settings as sett1 } from 'survey-core';
import { settings } from 'survey-creator-core';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { isNull, isUndefined } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CustomFormModal } from '../CustomFormsModal';
import _ from 'lodash';

const allowedQuestionTypes = [
    'text',
    'checkbox',
    'radiogroup',
    'comment',
    'rating',
    'signaturepad',
    'imagepicker',
    'panel',
    'paneldynamic',
    'ranking'
];

interface ComponentProps {
    questions: any;
    section: any;
    onClose: (value: boolean) => void;
}

export function SurveyCreatorWidget(props: ComponentProps) {
    let { editSection, categories } = useMainStoreContext().customFormStore;
    const { questions, section } = props;
    const [showModal, setShowModal] = useState(false);
    settings.designer.defaultAddQuestionType = 'panel';
    settings.designer.showAddQuestionButton = false;
    sett1.allowShowEmptyTitleInDesignMode = false;


    const creator = useMemo(() => {
        const creatorOptions = {
            showLogicTab: false,
            showJSONEditorTab: false,
            questionTypes: ['panel'],
            showSidebar: false,
            isAutoSave: false,
            showSurveyTitle: false,
            showAddQuestionButton: false,
            showTestSurveyTab: true,
        };

        return new SurveyCreator(creatorOptions);
    }, []);

    creator.toolbox.forceCompact = false;
    creator.pageEditMode = 'single';

    var propertyStopList = ['category'];

    creator.onShowingProperty.add(function (sender, options) {
        options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
    });

    const json = useMemo(() => {
        const defaultJson = {
            pages: [
                {
                    name: 'ParentSection',
                    title: 'Parent Section Name',
                    description: 'Parent Section Display Header',
                    category: section.categoryId ? _.find(categories, (c) => { return c.id === section.categoryId }).value : null,
                    elements: section.elements,
                },
            ],
        };

        creator.text = window.localStorage.getItem('survey-json') || JSON.stringify(defaultJson);

        return defaultJson;
    }, []);

    if (isNull(json.pages[0].elements) || isUndefined(json.pages[0].elements)) {
        json.pages[0].elements = section.elements;
        creator.text = window.localStorage.getItem('survey-json') || JSON.stringify(json);
    }

    creator.currentPage.title = section.title;
    creator.currentPage.description = section.description;
    creator.currentPage.id = section.id;

    Serializer.addProperty('question', {
        name: 'id',
        default: null,
        category: 'general',
    });

    Serializer.addProperty('survey', {
        name: 'category:dropdown',
        category: 'general',
        choices: categories,
        default: section.categoryId ? _.find(categories, (c) => { return c.id === section.categoryId }).value : null
    });

    Serializer.removeProperty('page', 'category');
    Serializer.removeProperty('question', 'category');

    useEffect(() => {
        var showPanel = creator.toolbarItems.filter(x => x.id === 'svd-settings')[0];
        if (showPanel) {
            creator.toolbarItems.splice(creator.toolbarItems.indexOf(showPanel), 2);
        }

        for (var i = 0; i < questions.length; i++) {
            if (!isNull(questions[i].displayType) && allowedQuestionTypes.includes(questions[i].displayType)) {
                creator.toolbox.addItem({
                    id: questions[i].questionId,
                    name: questions[i].questionId,
                    iconName: 'icon-default',
                    json: {
                        id: questions[i].questionId,
                        type: questions[i].displayType,
                        inputType: questions[i].displayType,
                        title: questions[i].question,
                        titleLocation: 'left',
                        choices: questions[i].options,
                        rateMax: questions[i].rateMax,
                    },
                    title: questions[i].question,
                    isCopied: false,
                    category: questions[i].category ? questions[i].category : 'Others',
                });
            }
        }
    }, []);

    Serializer.findProperty('checkbox', 'hasSelectAll').visible = false;
    Serializer.findProperty('checkbox', 'hasNone').visible = false;
    Serializer.findProperty('checkbox', 'hasOther').visible = false;
    Serializer.findProperty('checkbox', 'hasComment').visible = false;
    Serializer.findProperty('radiogroup', 'hasNone').visible = false;
    Serializer.findProperty('radiogroup', 'hasOther').visible = false;
    Serializer.findProperty('radiogroup', 'hasComment').visible = false;

    //var propertyStopList = ['startWithNewLine'];

    // creator.onShowingProperty.add(function (sender, options) {
    //     options.canShow = propertyStopList.indexOf(options.property.name) !== -1;
    // });

    creator.onElementAllowOperations.add(function (editor, options) {
        var obj = options.obj;

        if (!obj || !obj.page) return;

        //enable/disable edit
        if (obj.getType() === 'panel' || obj.getType() === 'paneldynamic') {
            options.allowEdit = true;
        } else {
            options.allowEdit = false;
        }
        //disable change show/hide title
        options.allowShowHideTitle = false;
        //disable copying
        options.allowCopy = false;
        //disable changing type
        options.allowChangeType = false;
        //Enable/disable element drag&drop
        options.allowDragging = true;
    });

    creator.onDefineElementMenuItems.add((sender, options) => {
        var obj = options.obj;

        if (obj.getType() === 'panel' && _.isEmpty(obj.title)) {
            obj.title = 'SubSection';
            //obj.name = "SubSection";
            return;
        }
    });

    creator.saveSurveyFunc = (saveNo: any, callback: any) => {
        if (creator.currentPage.hasTitle) {
            editSection(creator).then(() => {
                props.onClose(false);
            });
            callback(saveNo, true);
        } else {
            setShowModal(true);
            creator.currentPage.title = 'Parent Section Name';
        }
    };
    return (
        <>
            <SurveyCreatorComponent creator={creator} />
            <CustomFormModal isOpen={showModal} onClose={() => setShowModal(false)} title="Error">
                {'Parent Section Name is required.'}
            </CustomFormModal>
        </>
    );
}
