// Vendors
import React from 'react';
import { ChangeEvent, useState } from 'react';
import {
    Box,
    Grid,
    Card,
    Tooltip,
    Avatar,
    AvatarGroup,
    CardMedia,
    CardActionArea,
    LinearProgress,
    Typography,
    Tabs,
    IconButton,
    Divider,
    TextField,
    Button,
    Link,
    InputAdornment,
    Tab,
    Checkbox,
    FormControlLabel,
    alpha,
    styled,
    useTheme
} from "@mui/material";
import { myoEnrollmentVideo, myoPatientForm } from './Helper/documents';

const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
          overflow: visible !important;
  
          .MuiTabs-scroller {
              overflow: visible !important;
          }
  
          .MuiButtonBase-root {
              text-transform: uppercase;
              font-size: ${theme.typography.pxToRem(12)};
  
              &:last-child {
                  margin-right: 0;
              }
          }
      `
);

const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);

const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

function MyoCorrectContent() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const tabs = [
        { value: 'overview', label: 'Overview' },
        { value: 'programs', label: 'Programs' },
        { value: 'enrollment', label: 'Enrollment' },
        { value: 'patientManagement', label: 'Patient Care' }
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };
    return (
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={12} xl={5}>
                    <BoxComposed
                        display="flex"
                        alignItems="center"
                        sx={{
                            minHeight: '100%',
                            background: `${theme.colors.gradients.blue3}`
                        }}
                    >
                        <BoxComposedContent px={8} pt={8} pb={4}>
                            <Avatar
                                variant="square"
                                sx={{
                                    mx: 'auto',
                                    mb: 2,
                                    width: 'auto',
                                    height: 200,
                                    '& img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                src="/logos/MyoCorrect.png"
                            />

                        </BoxComposedContent>
                    </BoxComposed>
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Box py={3} px={4}>
                        <TabsWrapper
                            centered
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                    </Box>
                    <Divider />
                    {currentTab === 'overview' && (
                        <>
                            <Box p={4}>
                                <Card
                                    sx={{
                                        p: 3,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        bgcolor: 'background.default',
                                        border: `1px solid ${theme.palette.divider}`,
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        gutterBottom
                                        textAlign="center"
                                        sx={{ color: theme.palette.primary.main }}
                                    >
                                        MyoSync Overview
                                    </Typography>
                                    <Divider
                                        sx={{
                                            my: 2,
                                            borderColor: theme.palette.primary.main,
                                            borderWidth: 2,
                                            width: '60%',
                                            mx: 'auto',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        lineHeight={1.8}
                                        textAlign="justify"
                                        gutterBottom
                                        sx={{ mb: 3 }}
                                    >
                                        MyoSync (formerly known as MyoCorrect) is an exercise-based program focused on improving orofacial function and nasal breathing. With a comprehensive evaluation and personalized sessions, it empowers patients to achieve optimal results in an 8–12 month timeframe.
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        fontWeight="bold"
                                        gutterBottom
                                        sx={{ mt: 3, color: theme.palette.secondary.main }}
                                    >
                                        Key Highlights:
                                    </Typography>
                                    <ul style={{ paddingLeft: theme.spacing(4), lineHeight: 2 }}>
                                        <li>
                                            Comprehensive evaluation followed by 20- to 30-minute therapy sessions.
                                        </li>
                                        <li>
                                            Tailored for children and adults, focusing on awareness, posture, and tongue function.
                                        </li>
                                        <li>
                                            Incorporates proven techniques such as Buteyko breathing for enhanced nasal breathing.
                                        </li>
                                        <li>
                                            Complements Vivos treatment systems with trained therapists who understand patient needs.
                                        </li>
                                    </ul>

                                    <Typography
                                        variant="body1"
                                        lineHeight={1.8}
                                        textAlign="justify"
                                        sx={{ mt: 3 }}
                                    >
                                        Acting as an extension of the Vivos Provider team, MyoSync is designed to support treatment goals and enhance patient outcomes.
                                    </Typography>

                                    <Box
                                        sx={{
                                            mt: 4,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href="https://myosyncomt.com/about-us/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                textTransform: 'none',
                                                borderRadius: 2,
                                                px: 4,
                                                py: 1,
                                            }}
                                        >
                                            Learn More About MyoSync
                                        </Button>
                                    </Box>
                                </Card>
                            </Box>
                        </>
                    )}
                    {currentTab === 'programs' && (
                        <>
                            <Box p={4}>
                                <Card
                                    sx={{
                                        p: 3,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        gutterBottom
                                        textAlign="center"
                                        sx={{ color: theme.palette.primary.main }}
                                    >
                                        MyoSync Programs
                                    </Typography>
                                    <Divider
                                        sx={{
                                            my: 2,
                                            borderColor: theme.palette.primary.main,
                                            borderWidth: 1,
                                            width: '50%',
                                            mx: 'auto',
                                        }}
                                    />
                                    <Box sx={{ mb: 3 }}>
                                        <Typography
                                            variant="body1"
                                            textAlign="center"
                                            sx={{ mb: 2 }}
                                        >
                                            Explore tailored programs designed to address various orofacial and breathing-related challenges.
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            textAlign="center"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {/* <Link
                                                href="https://prodimagesvaire.blob.core.windows.net/sharedresources/MyoCorrect/General/2024.3_FinalNewMyoCorrectProgramFlyer_03.20.24.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                underline="hover"
                                            >
                                                View and download the MyoSync Program Flyer
                                            </Link> */}
                                        </Typography>
                                    </Box>

                                    {[
                                        {
                                            title: "MyoSync +",
                                            sessions: "16 Sessions",
                                            details: [
                                                "Addresses awareness, posture, nasal breathing, proper tongue placement, and lip closure.",
                                                "Includes Buteyko Breathing for improved nasal breathing.",
                                                "Suitable for ages 5 and older with Complex Orofacial Myofunctional Disorders (OMD).",
                                                "Addresses symptoms like TMD, jaw pain, mouth breathing, tongue thrusting, and frenectomies.",
                                                "Designed for Moderate to Severe Obstructive Sleep Apnea cases.",
                                            ],
                                        },
                                        {
                                            title: "MyoSync",
                                            sessions: "12 Sessions",
                                            details: [
                                                "Focuses on awareness, posture, nasal breathing, and proper tongue placement.",
                                                "Includes Buteyko Breathing for nasal breathing enhancement.",
                                                "Ideal for ages 5+ with mild Orofacial Myofunctional Disorders (OMD).",
                                                "Targets correct tongue posture, mouth closure, and nasal breathing.",
                                                "Designed for mild Obstructive Sleep Apnea cases.",
                                            ],
                                        },
                                        {
                                            title: "Tongue Release Program",
                                            sessions: "8 Sessions",
                                            details: [
                                                "Focuses on pre and post-stretching exercises, along with post-healing practices.",
                                                "Designed for severe tongue and/or lip ties, enabling transition to speech or appliance therapy.",
                                            ],
                                        },
                                    ].map((program, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                mb: 2, // Reduced margin-bottom for compactness
                                                p: 2.25,
                                                border: `1px solid ${theme.palette.divider}`,
                                                borderRadius: 2,
                                                boxShadow: 1,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.01)',
                                                    backgroundColor: alpha(theme.palette.primary.light, 0.1),
                                                },
                                            }}
                                        >
                                            <Grid container alignItems="center">
                                                <Grid item xs={6}>
                                                    <Typography
                                                        variant="h5"
                                                        fontWeight="bold"
                                                        sx={{ color: theme.palette.primary.main }}
                                                    >
                                                        {program.title}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} textAlign="right">
                                                    <Typography
                                                        variant="h5"
                                                        fontWeight="bold"
                                                        sx={{ color: theme.palette.secondary.main }}
                                                    >
                                                        {program.sessions}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{ mt: 1 }}>
                                                <ul style={{ paddingLeft: theme.spacing(3), margin: 0 }}>
                                                    {program.details.map((detail, detailIndex) => (
                                                        <li key={detailIndex}>
                                                            <Typography
                                                                variant="body2"
                                                                lineHeight={1.5}
                                                                sx={{ color: theme.palette.text.primary }}
                                                            >
                                                                {detail}
                                                            </Typography>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Box>
                                        </Box>
                                    ))}
                                </Card>
                            </Box>
                        </>
                    )}
                    {currentTab === 'enrollment' && (
                        <>
                            <Box p={4}>
                                <Card
                                    sx={{
                                        p: 3,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight="bold"
                                        gutterBottom
                                        textAlign="center"
                                        sx={{ color: theme.palette.primary.main }}
                                    >
                                        MyoSync Enrollment
                                    </Typography>
                                    <Divider
                                        sx={{
                                            my: 2,
                                            borderColor: theme.palette.primary.main,
                                            borderWidth: 1,
                                            width: '50%',
                                            mx: 'auto',
                                        }}
                                    />
                                    <Typography
                                        variant="body1"
                                        textAlign="center"
                                        sx={{ mb: 3, color: theme.palette.text.secondary }}
                                    >
                                        Enroll your patients into the MyoSync program and schedule their evaluations with ease. To watch a video tutorial on this process click <a href={myoEnrollmentVideo} target="_blank" rel="noopener noreferrer"><strong>HERE</strong></a>
                                    </Typography>

                                    <Grid container spacing={1}>
                                        {/* Enrollment Steps */}
                                        {[
                                            {
                                                step: 1,
                                                description: "Click on the 'Patients' tab in the left-hand dashboard menu.",
                                            },
                                            {
                                                step: 2,
                                                description: "Find the patient you want to enroll and open their dashboard.",
                                            },
                                            {
                                                step: 3,
                                                description: "Navigate to the 'MyoSync' tab.",
                                            },
                                            {
                                                step: 4,
                                                description: "Select the 'Enroll into MyoSync' button and complete the process.",
                                            },
                                        ].map(({ step, description }, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Card
                                                    variant="outlined"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: 1,
                                                        boxShadow: 1,
                                                        transition: 'all 0.2s ease-in-out',
                                                        '&:hover': {
                                                            boxShadow: 4,
                                                            transform: 'scale(1.02)',
                                                        },
                                                    }}
                                                >
                                                    <Avatar
                                                        sx={{
                                                            bgcolor: theme.palette.primary.main,
                                                            color: theme.palette.primary.contrastText,
                                                            mr: 2,
                                                            width: 28,
                                                            height: 28,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {step}
                                                    </Avatar>
                                                    <Typography
                                                        variant="body2"
                                                        lineHeight="1.4"
                                                        sx={{ color: theme.palette.text.primary }}
                                                    >
                                                        {description}
                                                    </Typography>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>

                                    {/* Additional Space After Steps */}
                                    <Box mt={4}>
                                        <Typography
                                            variant="h5"
                                            fontWeight="bold"
                                            gutterBottom
                                            textAlign="center"
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            Hassle-Free Evaluation Scheduling
                                        </Typography>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                boxShadow: 1,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                                backgroundColor: alpha(theme.palette.primary.light, 0.1),
                                            }}
                                        >
                                            <Typography variant="body2" lineHeight="1.5">
                                            After your patient is enrolled, the MyoSync team takes care of the scheduling of their evaluation, utilizing the contact information provided in vCloud to ensure a seamless process.
                                            </Typography>
                                        </Card>
                                    </Box>

                                    {/* Evaluation Details */}
                                    <Box mt={4}>
                                        <Typography
                                            variant="h5"
                                            fontWeight="bold"
                                            gutterBottom
                                            textAlign="center"
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            What Does the Initial Evaluation Include?
                                        </Typography>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                boxShadow: 1,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                                backgroundColor: alpha(theme.palette.primary.light, 0.1),
                                            }}
                                        >
                                            <Typography variant="body2" lineHeight="1.5">
                                                <ul style={{ margin: 0, paddingLeft: theme.spacing(3) }}>
                                                    <li>
                                                    <strong>Purpose & Education:</strong> Discuss the goals of Myofunctional Therapy and answer any patient questions.
                                                    </li>
                                                    <li>
                                                    <strong>Medical History & Assessment:</strong> Review the patient’s medical history, symptoms, and evaluate the need for therapy.
                                                    </li>
                                                    <li>
                                                    <strong>Comprehensive Evaluation:</strong> Assess the function of the tongue, lips, cheeks, and jaw, including TMJ concerns, harmful habits, and range of motion.
                                                    </li>
                                                    <li>
                                                    <strong>Personalized Plan:</strong> Share evaluation findings, schedule weekly sessions, and set the foundation for a tailored Myotherapy treatment plan.
                                                    </li>
                                                </ul>
                                            </Typography>
                                        </Card>
                                    </Box>
                                </Card>
                            </Box>
                        </>
                    )}
                    {currentTab === 'patientManagement' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={3}>
                                    {/* Download Patient Questionnaire */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 2,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 5,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                Download the MyoSync Patient Questionnaire
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.7}
                                                textAlign="left"
                                            >
                                                Click <a href={myoPatientForm} target="_blank" rel="noopener noreferrer"><strong>HERE</strong></a> to download the MyoSync Patient Questionnaire and assist your patients in completing it before their therapy sessions.
                                            </Typography>
                                        </Card>
                                    </Grid>

                                    {/* Viewing Patient Notes and Progress */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 2,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 5,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                Viewing Patient Notes and Progress
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.7}
                                                textAlign="left"
                                            >
                                                <ol style={{ margin: 0, paddingLeft: '1.5em' }}>
                                                    <li>Click on the <strong>"Patients"</strong> tab in the left-hand dashboard menu.</li>
                                                    <li>Locate and select the patient enrolled in MyoSync.</li>
                                                    <li>Navigate to the <strong>"MyoSync"</strong> tab within the patient’s dashboard.</li>
                                                    <li>Here, you'll find notes and progress updates added by the therapist or advocate.</li>
                                                </ol>
                                            </Typography>
                                        </Card>
                                    </Grid>

                                    {/* Additional Session Information */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 2,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 5,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                Additional Sessions for Patients
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.7}
                                                textAlign="left"
                                            >
                                                MyoSync offers a <strong>4-Session Add-On Program</strong> tailored to meet the needs of patients requiring additional therapy. This program includes:
                                                <ul style={{ margin: 0, paddingLeft: '1.5em' }}>
                                                    <li>Comprehensive support for specific areas of concern.</li>
                                                    <li>Four additional Myofunctional Therapy sessions.</li>
                                                    <li>Optimized care for patients enrolled in MyoSync or MyoSync+ programs.</li>
                                                </ul>
                                            </Typography>
                                        </Card>
                                    </Grid>
                                    {/* Contacting MyoSync Support */}
                                    <Grid item xs={12}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 2,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 2,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 5,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="h6"
                                                fontWeight="bold"
                                                gutterBottom
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                Contacting MyoSync Support
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                lineHeight={1.7}
                                                textAlign="left"
                                            >
                                                For support, contact a MyoSync Support Specialist by phone at:<br />
                                                <strong>(888) 719-7797</strong>
                                            </Typography>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
}

export default MyoCorrectContent;
