// Vendors
import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Box, Typography } from '@mui/material'

// Components
import { Tooth } from "./Tooth";
import DataGrid from '../DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { UneruptedModal } from './UneruptedReasonsModal';

// Entities
import Patient from '../../entities/Patient';
import { NumberingSystem, PresentConditions, TeethSet } from '../../entities/Enums';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { GlobalUtils } from '../../api/GlobalUtils';
import { toJS } from 'mobx';

// Styling
import "../../scss/_Tooth.scss";
import "../../scss/_global.scss";
import CssSelectField from '../CssSelectField';
import CssTextField from '../CssTextField';


interface TeethChartingProps {
    existingPatient?: Patient,
    id?: number
}

const MISSINGREASONDEFAULT = 113;
const PRESENTCONDITIONDEFAULT = 122;

export const PrintTeethChartingSummary: React.FC<TeethChartingProps> = observer(
    ({ existingPatient, id }) => {
        const { openUneruptedModal, teethChartTeeth, addUnerupted, hasDetails,
            resetTeethChart, setOpenUneruptedModal, setPatientId, loadChart, getIndexByNumberingSystem, getDeciduousToothIndex, setCurrentTeethSet, setCurrentNumberingSystem } = useMainStoreContext().teethChartStore;
        const { patient } = useMainStoreContext().createPatientStore
        const storeProvider = useMainStoreContext().providerStore;
        const [presentConditions, setPresentConditions] = React.useState<JSX.Element[]>([]);
        const [missingTeeth, setMissingTeeth] = useState<number[]>([]);  // Explicitly set type to number[]
        const [crownTeeth, setCrownTeeth] = useState<number[]>([]);      // Explicitly set type to number[]
        const [implantsTeeth, setImplantsTeeth] = useState<number[]>([]); // Explicitly set type to number[]
        const [bridgeTeeth, setBridgeTeeth] = useState<number[]>([]);     // Explicitly set type to number[]
        const [impactedTeeth, setImpactedTeeth] = useState<number[]>([]); // Explicitly set type to number[]
        const [deciduousTeeth, setDeciduousTeeth] = useState<number[]>([]);

        useEffect(() => {
            resetTeethChart();
            var presentCond = GlobalUtils.getPresentConditions();
            setPresentConditions(_.map(presentCond, (m, i) => {
                return <MenuItem key={i} className='gridDropDown' value={m.id}>{m.name}</MenuItem>
            }));

            if (existingPatient) {
                setPatientId(existingPatient.id);
            } else {
                if (id !== undefined) {
                    setPatientId(id);
                } else {
                    setPatientId(patient ? patient.id : 0);
                }
            }
            loadChart().then(() => {
                categorizeTeeth();
            });

            storeProvider.getProviderPreferences().then(() => {
                let teethSetType = GlobalUtils.getTeethSetType();
                let numericSystemType = GlobalUtils.getTeethNumSystemType();

                let foundedSetType = teethSetType?.find(x => x.id === storeProvider.providerPreferences.teethSetPref);
                setCurrentTeethSet(foundedSetType ? foundedSetType.listItemKey : TeethSet.ALL);

                let foundedSystemType = numericSystemType?.find(x => x.id === storeProvider.providerPreferences.teethSystemPref);
                setCurrentNumberingSystem(foundedSystemType ? foundedSystemType.listItemKey : NumberingSystem.UNIVERSAL);
            });

        }, []);

        const categorizeTeeth = () => {
            teethChartTeeth.forEach(tooth => {
                const conditions = tooth.presentConditionsArray ?? [];

                conditions.forEach(condition => {
                    switch (condition) {
                        case PresentConditions.Missing:
                            missingTeeth.push(tooth.toothNumber);
                            break;
                        case PresentConditions.Crown:
                            crownTeeth.push(tooth.toothNumber);
                            break;
                        case PresentConditions.Implants:
                            implantsTeeth.push(tooth.toothNumber);
                            break;
                        case PresentConditions.Bridge:
                            bridgeTeeth.push(tooth.toothNumber);
                            break;
                        case PresentConditions.Impacted:
                            impactedTeeth.push(tooth.toothNumber);
                            break;
                        // case PresentConditions.Deciduous:
                        //     deciduousTeeth.push(tooth.toothNumber);
                        //     break;
                        default:
                            break;
                    }
                });
            });
        };



        return (

            <Grid container>
                {console.log(teethChartTeeth)}
                <Grid item xs={3}>
                    <Typography variant="h6">Teeth Charting Summary</Typography>
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body1"><strong>Missing:</strong> {missingTeeth.join(', ')}</Typography>
                            <Typography variant="body1"><strong>Implants:</strong> {implantsTeeth.join(', ')}</Typography>
                            <Typography variant="body1"><strong>Impacted:</strong> {impactedTeeth.join(', ')}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1"><strong>Crown:</strong> {crownTeeth.join(', ')}</Typography>
                            <Typography variant="body1"><strong>Bridge:</strong> {bridgeTeeth.join(', ')}</Typography>
                            <Typography variant="body1"><strong>Deciduous:</strong> {deciduousTeeth.join(', ')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

);