import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { MainStore } from "./MainStore";
import { Gateway } from "../../api/Gateway";
import jwt_decode from 'jwt-decode';
import { GlobalUtils } from "../../api/GlobalUtils";
import DictionaryListItem from "../../entities/DictionaryListItem";
import _ from "lodash";
import { ProductionStatus } from "../../entities/Enums";
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import moment from "moment";
import buildQuery from 'odata-query';

export default class OrderStore {

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; userFullName; providerId } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
            this.providerId = tokenData.providerId;
        }
        this.checkRoles();
    }

    pageSize: number = 200;
    private _totalRows: number = 0;
    private _totalPages: number = 1;
    private _currentPage: number = 0;
    private _currentRow: any = {};
    get totalRows(): number {
        return this._totalRows;
    }
    set totalRows(value: any) {
        this._totalRows = value;
    }
    get currentPage(): number {
        return this._currentPage;
    }
    set currentPage(value: any) {
        this._currentPage = value;
    }
    @observable sort: any;
    onSortChange = (sortModel: GridSortModel): any => {
        runInAction(() => {
            if (Object.keys(sortModel).length > 0) {
                runInAction(() => {
                    this.sort = [sortModel[0].field + ' ' + sortModel[0].sort];
                });
            } else {
                this.sort = {};
            }
        });
    };


    @observable filter: any;
    onFilterChange = (filterModel: GridFilterModel): any => {
        runInAction(() => {
            let searchFilter = this.buildFilter(filterModel);
            if (Object.keys(searchFilter).length > 0) {
                runInAction(() => {
                    this.filter = searchFilter;
                });
            } else {
                this.filter = {};
            }
        });
    };
    buildFilter = (filterModel: GridFilterModel): any => {
        let customFilter = {};
        let linkOperator = filterModel.linkOperator !== undefined ? filterModel.linkOperator : '';
        customFilter[linkOperator] = [];
        filterModel.items.forEach(filter => {
            let currentOdata = {};
            let operator = {};
            let operatorValue = filter.operatorValue ? filter.operatorValue.toLowerCase() : '';
            let shouldNotAdd = true;
            let funcProperty = filter.columnField;
            let typeColumn = 'string';
            let actualValue;

            if (filter.columnField.toLowerCase().includes('date')) {
                typeColumn = 'date';
            }

            if ((filter.columnField.includes('id') || filter.columnField.includes('days')) && !filter.columnField.includes('provider')) {
                typeColumn = 'number';
            }


            if (typeColumn === 'date') {
                switch (filter.operatorValue) {
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'not':
                        operator['ne'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'after':
                        operator['gt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrAfter':
                        operator['ge'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'before':
                        operator['lt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrBefore':
                        operator['le'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'list') {
                switch (filter.operatorValue) {
                    case 'is':
                        operator['eq'] = actualValue;
                        break;
                    case 'not':
                        operator['ne'] = actualValue;
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'number' && filter.value) {

                switch (filter.operatorValue) {
                    case '=':
                        operator['eq'] = { type: 'raw', value: filter.value };
                        break;
                    case '!=':
                        operator['ne'] = { type: 'raw', value: filter.value };
                        break;
                    case '>':
                        operator['gt'] = { type: 'raw', value: filter.value };
                        break;
                    case '>=':
                        operator['ge'] = { type: 'raw', value: filter.value };
                        break;
                    case '<':
                        operator['lt'] = { type: 'raw', value: filter.value };
                        break;
                    case '<=':
                        operator['le'] = { type: 'raw', value: filter.value };
                        break;
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    default:
                        break;
                }
            } else {
                switch (filter.operatorValue) {
                    case 'contains':
                    case 'startsWith':
                    case 'endsWith':

                        operator[operatorValue] = filter.value;
                        break;
                    case 'equals':
                        operator['eq'] = filter.value;
                        break;
                    case 'isEmpty':
                        shouldNotAdd = false;
                        operator['lt'] = 1;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isNotEmpty':
                        shouldNotAdd = false;
                        operator['gt'] = 0;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = filter.value;
                        break;
                    case 'not':
                        operator['ne'] = filter.value;
                        break;
                    default:
                        break;
                }
            }

            if (shouldNotAdd) {
                currentOdata[funcProperty] = operator;
            }
            customFilter[linkOperator].push(currentOdata);
        });
        return customFilter;
    };


    @observable mainStore: MainStore;
    @observable pageTitle = "Orders Dashboard";
    @observable ordersData: any[] = [];
    @observable origOrdersData: any[] = [];
    @observable isLoadingOrders: boolean = false;
    @observable isLoadingPatient: boolean = false;
    @observable userID: number = 0;
    @observable isLeftNavExpanded: boolean = false;
    @observable searchParam = '';
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable _toggleShowPatientEditModal: boolean = false;
    @observable _toggleShowOrderStatusModal: boolean = false;
    @observable _currentPatientId: number = 0;
    @observable patientData: any;
    @observable orderStatusValues: DictionaryListItem[] = [];
    @observable providerId: number = 0;
    @observable pendingProductionStatusCount: number = 0;
    @observable inFabricationProductionStatusCount: number = 0;
    @observable shippedProductionStatusCount: number = 0;
    @observable onHoldProductionStatusCount: number = 0;

    roles: any = [];
    isAISAdmin = false;
    isAISSpecialist = false;

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                this.isAISAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SPECIALIST) {
                this.isAISSpecialist = true;
            }
        }
        return;
    };

    @action setOrderStatusValues = (value: DictionaryListItem[]) => { this.orderStatusValues = value; }
    @action setOrderData = (value: any[]) => { this.ordersData = value; }
    @action setOrigOrdersData = (value: any[]) => { this.origOrdersData = value; }
    @action setPendingProductionStatusCount = (value: number) => { this.pendingProductionStatusCount = value; }
    @action setInFabricationProductionStatusCount = (value: number) => { this.inFabricationProductionStatusCount = value; }
    @action setShippedProductionStatusCount = (value: number) => { this.shippedProductionStatusCount = value; }
    @action setOnHoldProductionStatusCount = (value: number) => { this.onHoldProductionStatusCount = value; }

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    @action setPatientData = (value: any) => this.patientData = value;

    get toggleShowPatientEditModal() {
        return this._toggleShowPatientEditModal;
    }

    @action
    set toggleShowPatientEditModal(value: any) {
        this._toggleShowPatientEditModal = value;
    }

    get toggleShowOrderStatusModal() {
        return this._toggleShowOrderStatusModal;
    }

    @action
    set toggleShowOrderStatusModal(value: any) {
        this._toggleShowOrderStatusModal = value;
    }

    get currentPatientId() {
        return this._currentPatientId;
    }

    @action
    set currentPatientId(value: any) {
        this._currentPatientId = value;
    }

    @action
    toggleEditPatientModal = () => {
        runInAction(() => {
            this.toggleShowPatientEditModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    toggleOrderStatusModal = () => {
        runInAction(() => {
            this.toggleShowOrderStatusModal = !this.toggleShowOrderStatusModal;
        });
    };

    @action
    setCounters = () => {
        this.setInFabricationProductionStatusCount(this.ordersData.filter(x => x.productionStatusId === ProductionStatus["In Fabrication"]).length);
        this.setShippedProductionStatusCount(this.ordersData.filter(x => x.productionStatusId === ProductionStatus.Shipped).length);
        this.setOnHoldProductionStatusCount(this.ordersData.filter(x => x.productionStatusId === ProductionStatus.Hold).length);
        this.setPendingProductionStatusCount(this.ordersData.filter(x => x.productionStatusId === ProductionStatus.Pending).length);
    }

    @action
    getOrders = async () => {
        this.ordersData.length = 0;
        runInAction(() => {
            this.isLoadingOrders = true;
        });
        let skip = this.currentPage * this.pageSize;
        var query = buildQuery({
            top: this.pageSize,
            skip: skip,
            filter: this.filter ? this.filter : {},
            count: true,
            orderBy: this.sort ? this.sort : 'orderCreatedOn DESC'
        });

        var url = this.isAISAdmin || this.isAISSpecialist ? `/odata/provider/orders/-1` : `/odata/provider/orders/${this.providerId}`;
        const resp = await Gateway.get(url + query);

        //total 
        var queryTotal = buildQuery({
            filter: this.filter ? this.filter : {},
            count: true,
            orderBy: this.sort ? this.sort : 'orderCreatedOn DESC'
        });
        const respTotal = await Gateway.get(url + queryTotal);
        this.totalRows = respTotal.length;

        runInAction(() => {
            this.isLoadingOrders = false;
            this.ordersData = resp.map(data => {
                const newData = { ...data };
                newData.id = data.orderId;
                newData.patientName = data.patientName + " " + data.patientLastName;
                //newData.providerName = data.providerName + " " + data.providerLastName;
                return newData;
            });
        });
        this.origOrdersData = this.ordersData;

    };

    @action
    getPatientData = async (patientId) => {
        runInAction(() => {
            this.isLoadingPatient = true;
        });

        var url = `/patient/${patientId}`;
        return Gateway.get(url).then(resp => {
            this.setPatientData(resp as unknown);
            this.isLoadingPatient = false;
        });
    };

    clearData = () => {
        this.ordersData = this.origOrdersData;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.ordersData;
    };

    @action filterBySearchString = () => {
        this.ordersData = this.origOrdersData.filter(
            x =>
                x.id.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.providerName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.providerLastName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.patientVivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.patientName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.patientLastName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.ordRxId.toString().toLowerCase().includes(this.searchParam.toLowerCase())
        );
    };

    handleOrderStatusChange = (changedOrder, event) => {
        var url = `/provider/${changedOrder.id}/orderStatus/${event.target.value}/${this.userID}`;
        var ind = -1;
        Gateway.put(url, undefined).then(respData => {
            if (respData) {
                ind = _.findIndex(this.ordersData, c => {
                    return c.id === respData['id'];
                });
                var newOrderData = this.ordersData.reduce((newData, currentOrder, index) => {
                    const copiedOrder: any = { ...currentOrder };
                    if (ind === index) {
                        copiedOrder.ordStatusId = respData['orderStatusId'];
                    }
                    return [...newData, copiedOrder];
                }, [] as any[]);

                this.setOrderData(newOrderData);

                ind = _.findIndex(this.origOrdersData, c => {
                    return c.id === respData['id'];
                });
                newOrderData = this.origOrdersData.reduce((newData, currentOrder, index) => {
                    const copiedOrder: any = { ...currentOrder };
                    if (ind === index) {
                        copiedOrder.ordStatusId = respData['orderStatusId'];
                    }
                    return [...newData, copiedOrder];
                }, [] as any[]);
                this.setOrigOrdersData(newOrderData);
            }
        });
    };

    @action loadOrderStatusValues = async () => {
        GlobalUtils.getOrderStatus().then(resp => {
            this.setOrderStatusValues(resp);
        })
    };

}



