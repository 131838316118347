// Vendors
import { useEffect, useMemo, useState } from "react";
import {
    Grid,
    Typography,
    Box,
    TextField,
    Button,
    alpha,
    styled,
    List,
    ListItem,
    ListItemText,
} from "@mui/material"

// Components
import RxTeethSelection from "../RxTeethSelection";
import RxConstructionContainer from "../RxConstructionContainer";

// Store
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";

// Entities
import { RxAppliances } from "../../../../entities/Enums"

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from "../../../../api/GlobalUtils";
import DictionaryListItem from "../../../../entities/DictionaryListItem";
import { Gateway } from "../../../../api/Gateway";
import { Part } from "../../../../models/state_models/part";
import { usePrescriptionStore } from "../../../../stores/Prescription/CreateEdit/MainStoreContext";

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
      position: absolute;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(1)};
    `
);

const PexConstruction = ({ constructionType }) => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    // Fields that will be needed in database to store these values
    const [selectedUpperBands, setSelectedUpperBands] = useState<number[]>([]);
    const [selectedLowerBands, setSelectedLowerBands] = useState<number[]>([]);
    const [expansionScrew, setExpansionScrew] = useState('');
    const [upperAnteriorWhipSprings, setUpperAnteriorWhipSprings] = useState('');
    const [lowerAnteriorWhipSprings, setLowerAnteriorWhipSprings] = useState('');
    const [reversePullMaskAttachment, setReversePullMaskAttachment] = useState('');

    const [upperScrewValues, setUpperScrewValues] = useState<DictionaryListItem[]>([]);
    const [dentalPartsValues, setDentalPartsValues] = useState<Part[]>([]);
    const [loadedValues, setLoadedValues] = useState(false);
    /*
        const babyUpperTeethMap = { 'A': 33, 'B': 34, 'C': 35, 'D': 36 };
        const babyLowerTeethMap = { 'J': 37, 'K': 38, 'L': 39, 'M': 40 };
    */
    const babyUpperTeethMap = { 'A': 33, 'B': 34, 'I': 41, 'J': 37 };
    const babyLowerTeethMap = { 'T': 42, 'S': 43, 'L': 39, 'K': 38 };

    useMemo(async () => {
        setUpperScrewValues(await GlobalUtils.getApplianceUpperScrew());
        const dentalPartsValues = await Gateway.get('/prescription/appliance/parts')
        setDentalPartsValues(dentalPartsValues);

        if (prescriptionStore.isUpdating && !prescriptionStore.isConstructionFormInProgress) {
            prescriptionStore.setPexConstructionAttributes(dentalPartsValues);
            prescriptionStore.populateDentalPiecesMap();
        }

        setLoadedValues(true);

        if (prescriptionStore.isUpdating) {
            prescriptionStore.setIsConstructionFormInProgress(true)
        }
    }, [])

    useEffect(() => {
        if (loadedValues) {
            // Set Expansion Screw value
            const upperScrewId = prescriptionStore.appliance.applianceSystemUpperSystemScrewId;
            const screwName = upperScrewValues.find(x => x.id === upperScrewId)?.name || '';
            setExpansionScrew(screwName);

            // Set Upper Anterior Whip Springs
            const upperAnteriorWhip = prescriptionStore.appliance.appliancesystemUppersystemUpperAnteriorWhipLapBool;
            setUpperAnteriorWhipSprings(upperAnteriorWhip === true ? 'Yes' : upperAnteriorWhip === false ? 'No' : '');

            // Set Lower Anterior Whip Springs
            const lowerAnteriorWhip = prescriptionStore.appliance.appliancesystemLowersystemLowerAnteriorWhipLapBool;
            setLowerAnteriorWhipSprings(lowerAnteriorWhip === true ? 'Yes' : lowerAnteriorWhip === false ? 'No' : '');

            // Set Reverse Pull Mask Attachment
            const reversePullAttachment = prescriptionStore.appliance.applianceSystemUpperSystemReversePullFacemaskAttachmentsBool;
            setReversePullMaskAttachment(reversePullAttachment === true ? 'Yes' : reversePullAttachment === false ? 'No' : '');

            // Set Upper Bands (Teeth)
            const upperBandEntry = prescriptionStore.dentalPiecesMap.get((dentalPartsValues.find(x => x.partName === 'Band')?.id ?? -1) + ',Upper');
            if (upperBandEntry) {
                setSelectedUpperBands(upperBandEntry.teethNumbers || []);
            }

            // Set Lower Bands (Teeth)
            const lowerBandEntry = prescriptionStore.dentalPiecesMap.get((dentalPartsValues.find(x => x.partName === 'Band')?.id ?? -1) + ',Lower');
            if (lowerBandEntry) {
                setSelectedLowerBands(lowerBandEntry.teethNumbers || []);
            }
        }
    }, [loadedValues]);

    const handleExpansionScrewChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.applianceSystemUpperSystemScrewId = upperScrewValues.find(x => x.name === event.currentTarget.value)?.id ?? null;
        setExpansionScrew(event.currentTarget.value);
    };
    const handleUpperAnteriorWhipSpringsChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemUppersystemUpperAnteriorWhipLapBool = event.currentTarget.value === 'Yes' ? true : false;
        setUpperAnteriorWhipSprings(event.currentTarget.value);
    };
    const handleLowerAnteriorWhipSpringsChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.appliancesystemLowersystemLowerAnteriorWhipLapBool = event.currentTarget.value === 'Yes' ? true : false;
        setLowerAnteriorWhipSprings(event.currentTarget.value);
    };
    const handleReversePullMaskAttachmentChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        prescriptionStore.appliance.applianceSystemUpperSystemReversePullFacemaskAttachmentsBool = event.currentTarget.value === 'Yes' ? true : false;
        setReversePullMaskAttachment(event.currentTarget.value);
    };
    const handleUpperBandSelection = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Band')?.id ?? -1) + ',Upper', { teethNumbers: teethNumbers, arch: 'Upper' });
        setSelectedUpperBands(teethNumbers);
    };
    const handleLowerBandSelection = (teethNumbers: number[]) => {
        prescriptionStore.dentalPiecesMap.set((dentalPartsValues.find(x => x.partName === 'Band')?.id ?? -1) + ',Lower', { teethNumbers: teethNumbers, arch: 'Lower' });
        setSelectedLowerBands(teethNumbers);
    };

    const rxUpperComplete =
        expansionScrew !== '' &&
        upperAnteriorWhipSprings !== '' &&
        reversePullMaskAttachment !== '' &&
        selectedUpperBands.length > 1; // Minimum two teeth to be selected before moving forward

    const rxLowerComplete =
        lowerAnteriorWhipSprings !== '' &&
        selectedLowerBands.length > 1; // Minimum two teeth to be selected before moving forward

    useEffect(() => {
        if (constructionType === RxAppliances.PEXU && rxUpperComplete) {
            prescriptionStore.setIsConstructionComplete(true);
        }
        else if (constructionType === RxAppliances.PEXL && rxLowerComplete) {
            prescriptionStore.setIsConstructionComplete(true);
        }
        else if (constructionType === RxAppliances.PEXD && rxUpperComplete && rxLowerComplete) {
            prescriptionStore.setIsConstructionComplete(true);
        } else {
            prescriptionStore.setIsConstructionComplete(false);
        }
    }, [rxUpperComplete, rxLowerComplete, prescriptionStore.selectedConstructionType]);

    return (
        <Grid container justifyContent={'center'}>
            {(constructionType === RxAppliances.PEXD || constructionType === RxAppliances.PEXU) &&
                <Grid item xs={12} md={6} padding={1}>
                    <RxConstructionContainer
                        rxComplete={rxUpperComplete}
                        rxConstructionTitle={"Upper Construction"}
                        rxConstructionComponent={
                            <ListWrapper disablePadding>
                                <ListItem sx={(theme) => ({
                                    p: 2,
                                    borderBottom: 4,
                                    borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                })}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                Expansion Screw
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box mt={0.7} display="flex" alignItems="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="Standard"
                                                                variant={expansionScrew === "Standard" ? "contained" : "outlined"}
                                                                onClick={handleExpansionScrewChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                Standard
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="Leaf"
                                                                variant={expansionScrew === "Leaf" ? "contained" : "outlined"}
                                                                onClick={handleExpansionScrewChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                Self-Expander
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="Barrel"
                                                                variant={expansionScrew === "Barrel" ? "contained" : "outlined"}
                                                                onClick={handleExpansionScrewChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                Barrel
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    />
                                    {!expansionScrew && <Box alignSelf="flex-start">
                                        <LabelWrapper
                                            sx={(theme) => ({
                                                background: `${theme.colors.warning.main}`,
                                                color: `${theme.palette.getContrastText(
                                                    theme.colors.warning.dark
                                                )}`
                                            })}
                                        >
                                            Required
                                        </LabelWrapper>
                                    </Box>}
                                </ListItem>
                                <ListItem sx={(theme) => ({
                                    p: 2,
                                    borderBottom: 4,
                                    borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                })}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                Anterior "Whip" or "Lap" Springs
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box mt={0.7} display="flex" alignItems="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="Yes"
                                                                variant={upperAnteriorWhipSprings === "Yes" ? "contained" : "outlined"}
                                                                onClick={handleUpperAnteriorWhipSpringsChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                Yes
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="No"
                                                                variant={upperAnteriorWhipSprings === "No" ? "contained" : "outlined"}
                                                                onClick={handleUpperAnteriorWhipSpringsChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                No
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </Box>
                                            </>
                                        }
                                    />
                                    {!upperAnteriorWhipSprings && <Box alignSelf="flex-start">
                                        <LabelWrapper
                                            sx={(theme) => ({
                                                background: `${theme.colors.warning.main}`,
                                                color: `${theme.palette.getContrastText(
                                                    theme.colors.warning.dark
                                                )}`
                                            })}
                                        >
                                            Required
                                        </LabelWrapper>
                                    </Box>}
                                </ListItem>
                                <ListItem sx={(theme) => ({
                                    p: 2,
                                    borderBottom: 4,
                                    borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                })}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                Reverse Pull Facemask Attachments
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box mt={0.7} display="flex" alignItems="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="Yes"
                                                                variant={reversePullMaskAttachment === "Yes" ? "contained" : "outlined"}
                                                                onClick={handleReversePullMaskAttachmentChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                Yes
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="No"
                                                                variant={reversePullMaskAttachment === "No" ? "contained" : "outlined"}
                                                                onClick={handleReversePullMaskAttachmentChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                No
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    />
                                    {!reversePullMaskAttachment && <Box alignSelf="flex-start">
                                        <LabelWrapper
                                            sx={(theme) => ({
                                                background: `${theme.colors.warning.main}`,
                                                color: `${theme.palette.getContrastText(
                                                    theme.colors.warning.dark
                                                )}`
                                            })}
                                        >
                                            Required
                                        </LabelWrapper>
                                    </Box>}
                                </ListItem>
                                <ListItem sx={(theme) => ({
                                    p: 2,
                                    borderBottom: 4,
                                    borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                })}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                Upper Bands
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box mt={0.7} display="flex" alignItems="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                fullWidth
                                                                value={
                                                                    selectedUpperBands.length > 0
                                                                        ? selectedUpperBands.map(tooth => {
                                                                            const letter = Object.keys(babyUpperTeethMap).find(
                                                                                key => babyUpperTeethMap[key] === tooth
                                                                            );
                                                                            return letter || tooth;
                                                                        }).join(', ')
                                                                        : 'None'
                                                                }
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                variant="outlined"
                                                            /></Grid>
                                                        <Grid item xs={12}>
                                                            <Box paddingY={2} paddingX={1}>
                                                                <RxTeethSelection
                                                                    onTeethSelected={handleUpperBandSelection}
                                                                    displayLower={false}
                                                                    displayUpper={true}
                                                                    displayUpperBabyTeeths={true}
                                                                    ruleDisabledTeeth={[6, 7, 8, 9, 10, 11]}
                                                                    patientDisabledTeeth={[]}
                                                                    currentTeeth={selectedUpperBands} />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    />
                                    {!(selectedUpperBands.length > 1) && <Box alignSelf="flex-start">
                                        <LabelWrapper
                                            sx={(theme) => ({
                                                background: `${theme.colors.warning.main}`,
                                                color: `${theme.palette.getContrastText(
                                                    theme.colors.warning.dark
                                                )}`
                                            })}
                                        >
                                            Required
                                        </LabelWrapper>
                                    </Box>}
                                </ListItem>
                            </ListWrapper>
                        }
                    />
                </Grid>
            }
            {(constructionType === RxAppliances.PEXD || constructionType === RxAppliances.PEXL) &&
                <Grid item xs={12} md={6} padding={1}>
                    <RxConstructionContainer
                        rxComplete={rxLowerComplete}
                        rxConstructionTitle={"Lower Construction"}
                        rxConstructionComponent={
                            <ListWrapper disablePadding>
                                <ListItem sx={(theme) => ({
                                    p: 2,
                                    borderBottom: 4,
                                    borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                })}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                Anterior "Whip" or "Lap" Springs
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box mt={0.7} display="flex" alignItems="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="Yes"
                                                                variant={lowerAnteriorWhipSprings === "Yes" ? "contained" : "outlined"}
                                                                onClick={handleLowerAnteriorWhipSpringsChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                Yes
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Button
                                                                fullWidth
                                                                value="No"
                                                                variant={lowerAnteriorWhipSprings === "No" ? "contained" : "outlined"}
                                                                onClick={handleLowerAnteriorWhipSpringsChange}
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                No
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </Box>
                                            </>
                                        }
                                    />
                                    {!lowerAnteriorWhipSprings && <Box alignSelf="flex-start">
                                        <LabelWrapper
                                            sx={(theme) => ({
                                                background: `${theme.colors.warning.main}`,
                                                color: `${theme.palette.getContrastText(
                                                    theme.colors.warning.dark
                                                )}`
                                            })}
                                        >
                                            Required
                                        </LabelWrapper>
                                    </Box>}
                                </ListItem>
                                <ListItem sx={(theme) => ({
                                    p: 2,
                                    borderBottom: 4,
                                    borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                                })}>
                                    <ListItemText
                                        primary={
                                            <Typography variant="h5">
                                                Lower Bands
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Box mt={0.7} display="flex" alignItems="center">
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={9}>
                                                            <TextField
                                                                fullWidth
                                                                value={
                                                                    selectedLowerBands.length > 0
                                                                        ? selectedLowerBands.map(tooth => {
                                                                            const letter = Object.keys(babyLowerTeethMap).find(
                                                                                key => babyLowerTeethMap[key] === tooth
                                                                            );
                                                                            return letter || tooth;
                                                                        }).join(', ')
                                                                        : 'None'
                                                                }
                                                                InputProps={{
                                                                    readOnly: true,
                                                                }}
                                                                variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box paddingY={2} paddingX={1}>
                                                                <RxTeethSelection
                                                                    onTeethSelected={handleLowerBandSelection}
                                                                    displayLower={true}
                                                                    displayUpper={false}
                                                                    displayLowerBabyTeeths={true}
                                                                    ruleDisabledTeeth={[22, 23, 24, 25, 26, 27]}
                                                                    patientDisabledTeeth={[]}
                                                                    currentTeeth={selectedLowerBands} />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        }
                                    />
                                    {!(selectedLowerBands.length > 1) && <Box alignSelf="flex-start">
                                        <LabelWrapper
                                            sx={(theme) => ({
                                                background: `${theme.colors.warning.main}`,
                                                color: `${theme.palette.getContrastText(
                                                    theme.colors.warning.dark
                                                )}`
                                            })}
                                        >
                                            Required
                                        </LabelWrapper>
                                    </Box>}
                                </ListItem>
                            </ListWrapper>
                        }
                    />
                </Grid>
            }
        </Grid>
    )
}; export default observer(PexConstruction)