import { observable, makeObservable } from 'mobx';
import PatientFile from './patientFile';
import PatientImages from './patientImage';

class PatientVisit {
  @observable id: number = 0;
  @observable patientId: number = 0;
  @observable statusId: number | null = 0;
  @observable isActive: boolean | null = true;
  @observable createdOn: Date = new Date();
  @observable createdBy: number = 0;
  @observable modifiedOn: Date | null = new Date();
  @observable modifiedBy: number | null = 0;
  @observable patientFiles: PatientFile[] = [];
  @observable patientsImages: PatientImages[] = [];

  constructor() {
    makeObservable(this); // Register observables
  }

  toObject() {
    return { ...this };
}
}

export default PatientVisit;
