import { Close } from '@material-ui/icons';
import { Box, Fade, Grid, IconButton, Modal, AppBar, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { observer } from 'mobx-react';
import { SurveyCreatorWidget } from './Creator';

interface ComponentProps {
    open: boolean;
    onClose: (onClose: boolean) => void;
    section: any;
    questions: any;
}

const EditSectionsModal = (props: ComponentProps) => {
    const { open, onClose, section, questions } = props;

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        setOpen(open);
    }, [open]);

    let { setCurrentRow } = useMainStoreContext().customFormStore;

    useEffect(() => {
        setCurrentRow(section);
    }, []);

    const handleOnClick = () => {
        onClose(false);
    };

    return (
        <Modal open={isOpen} className="modalContainer"  style={{height:'100vh'}}>
            <Fade in={isOpen}>
                <Box className="modalQuestion">
                    <Grid container direction="row" justifyContent={'space-between'}>
                        <AppBar position="static" className="modalHeader" color="inherit">
                            <Toolbar>
                                <Typography
                                    id={'modal.editSections.title'}
                                    variant="h6"
                                    component="div"
                                    sx={{ flexGrow: 1 }}
                                    className="title"
                                >
                                    {'Edit Section'}
                                </Typography>
                                <IconButton onClick={handleOnClick}>
                                    <Close />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </Grid>
                    <br />
                    <br />
                    <SurveyCreatorWidget onClose={setOpen} questions={questions} section={section} />
                </Box>
            </Fade>
        </Modal>
    );
};

export default observer(EditSectionsModal);
