import { makeObservable, observable } from "mobx";
import { BaseMainStore } from "../base/MainStore";
import PatientVisit from "../../models/state_models/patientVisit";

export  class VisitStore {
    mainStore: BaseMainStore;

    constructor(mainstore: BaseMainStore) {
      makeObservable(this);
      this.mainStore = mainstore;
    }

    @observable visit: PatientVisit = new PatientVisit();
}