import { makeAutoObservable, toJS } from 'mobx';
import jwt_decode from 'jwt-decode';

import { Gateway } from '../../api/Gateway';
import { Provider } from '../../entities/Provider';
import { MainStore } from './MainStore';

export class CustomFormToProviderStore {
    mainStore: MainStore;

    constructor(mainStore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
    }

    userId = 0;
    roles: any[] = [];
    providerIds: any[] = [];
    providersByForm: Provider[] = [];
    customFormId: number = 0;
    isLoadingSearchProviders = false;

    providers: Provider[] = [];

    setProvidersByForm = (value: any) => {
        this.providersByForm = value;
    };

    setCustomFormId = (value: any) => {
        this.customFormId = value;
    };

    setProviderIds = (value: any) => {
        this.providerIds = value;
    };

    setProviders = (value: any) => {
        this.providers = value;
    };

    setIsLoadingSearchProviders = (value: any) => {
        this.isLoadingSearchProviders = value;
    };

    resetCustomFormToProviderStore = () => {
        this.providerIds = [];
        this.customFormId = 0;
        this.providers = [];
    };

    loadProvidersByCustomForm = async () => {
        const url = `/Questionnaire/providerByForm/${this.customFormId}`;
        await Gateway.get(url).then(response => {
            this.setProvidersByForm(response);
        });
    };

    searchProviders = async (search: string) => {
        this.setIsLoadingSearchProviders(true);
        const url = `/patient/getproviderbyname/${search}`;
        await Gateway.get(url).then(response => {
            this.setProviders(response);
            this.setIsLoadingSearchProviders(false);
        });
    };

    sendCustomFormToProviders = async () => {
        const url = `/Questionnaire/upsertcustomformassignation`;
        const data = {
            customFormId: this.customFormId,
            providerIds: this.providerIds,
        };

        const response = await Gateway.post(url, data);

        return response;
    };
}
