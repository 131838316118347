// Vendors
import React, { useEffect } from "react";
import { Grid, Button, useTheme } from '@mui/material';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { ApplianceFamily } from "../../../models/state_models/applianceFamily";
import { AddressType, countryLabelValues } from "../../../entities/Enums";
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";

const RxApplianceOptions = () => {
    const theme = useTheme();
    const {
        selectedAppliance,
        setSelectedAppliance,
        applianceFamiliesSetups,
        setSelectedApplianceLogo,
        isLoadingAppliances,
        isUpdating,
        setSelectedeCommerceItem,
        setShouldCancelOrder,
        setVariantsSelected
    } = usePrescriptionStore().prescriptionStore;

    const { providerData } = useMainStoreContext().providerStore;

    const [defaultAddressCountryCode, setDefaultAddressCountryCode] = React.useState('');

    useEffect(() => {
        if (isUpdating) {
            setSelectedApplianceLogo(selectedAppliance?.imageUrl)
        }
    }, [applianceFamiliesSetups, isLoadingAppliances])

    useEffect(() => {
        if (!providerData || !providerData.addresses) {
            console.error("providerData or addresses not available");
            return;
        }

        const defaultAdd = providerData.addresses.find(x => x.addressTypeId === AddressType["Shipping Address"]);

        if (defaultAdd && defaultAdd.country) {
            const code = countryLabelValues.find(x => x.codeISO === defaultAdd.country || x.value === defaultAdd.country);
            if (code && code.codeISO) {
                setDefaultAddressCountryCode(code.codeISO);
            } else {
                console.error("Country code not found in countryLabelValues");
            }
        } else {
            console.error("Default address or country not found");
        }
    }, [providerData, countryLabelValues]);

    const handleChangeAppliance = (appliance: ApplianceFamily) => {
        setSelectedAppliance(appliance);
        if (appliance.applianceItemSetups.length === 1) {
            var applianceSetup = appliance.applianceItemSetups[0];
            if (applianceSetup.shopifyProduct && applianceSetup.shopifyProduct.variants) {
                if (applianceSetup.shopifyProduct.variants.length === 1) {
                    var variant = applianceSetup.shopifyProduct.variants[0];
                    applianceSetup.eCommerceShopifyItem.variantSelectedShopify = variant;
                    applianceSetup.eCommerceShopifyItem.variantsSelected["Title"] = 'Default Title';
                    setVariantsSelected(applianceSetup.eCommerceShopifyItem.variantsSelected);
                    setSelectedeCommerceItem(applianceSetup);
                }
            }
        }
        setSelectedApplianceLogo(appliance.imageUrl);

        if (isUpdating) {
            setShouldCancelOrder(true);
        }
    }

    const generateButtonStyle = (theme, isSelected, applianceType) => ({
        borderColor: isSelected ? theme.palette.primary.main : theme.palette.grey[300],
        background: isSelected ? theme.palette.secondary.light : 'transparent',
        '& img': {
            opacity: isSelected ? 1 : 0.7,
        },
        transition: 'all 0.3s ease',
        ...(isSelected ? {
            '&:hover': {
                borderColor: theme.palette.primary.main,
                background: theme.palette.secondary.light,
            },
        } : {
            '&:hover': {
                borderColor: theme.palette.primary.light,
                background: theme.palette.action.hover,
            },
        }),
    });

    const filterApplianceFamilies = (applianceFamilies, defaultAddressCountryCode) => {
        return applianceFamilies.filter(family => {
            // Check familyCountrySetups
            const countrySetupEnabled = family.familyCountrySetups.every(setup => {
                return (setup.countryCode === defaultAddressCountryCode && setup.enable !== false) || setup.countryCode !== defaultAddressCountryCode;
            });

            // If countrySetupEnabled is false, return false immediately
            if (!countrySetupEnabled) {
                return false;
            }

            // Check familyProviderTypeSetups
            const providerSetupEnabled = family.familyProviderTypeSetups.length === 0 || family.familyProviderTypeSetups.some(setup => setup.enable === true);

            // Return true if both conditions are met
            return providerSetupEnabled;
        });
    };


    return (
        <Grid>
            {<Grid container spacing={1}>
                {filterApplianceFamilies(applianceFamiliesSetups, defaultAddressCountryCode).map((appliance) => (

                    <Grid item xs={6} key={appliance.id}>
                        <Button
                            variant={selectedAppliance?.id === appliance.id ? 'contained' : 'outlined'}
                            fullWidth
                            //disabled={isUpdating}
                            onClick={() => handleChangeAppliance(appliance)}
                            sx={generateButtonStyle(theme, selectedAppliance?.id === appliance.id, appliance.id)}
                        >
                            <img src={appliance.imageUrl} style={{ height: '30px' }} />
                        </Button>
                    </Grid>
                )
                )}
            </Grid>}
        </Grid>


    )
}; export default RxApplianceOptions;