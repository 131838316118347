import { MainStore } from '../../stores/OldStores/MainStore';
import { action, makeAutoObservable, toJS, flow, observable } from 'mobx';
import { AddOns } from '../../entities/AiReportOrder';
import jwt_decode from 'jwt-decode';
import { Gateway } from '../../api/Gateway';
import * as _ from 'lodash';
import { GlobalUtils } from '../../api/GlobalUtils';
import DictionaryListItem from '../../entities/DictionaryListItem';
import PatientImage from '../../entities/PatientImage';
import PatientVisit from '../../entities/PatientVisit';
import { isEmpty } from 'lodash';
import { AIReportType } from '../../entities/Enums';
import { BaseMainStore } from '../../stores/base/MainStore';
import { AddonsSetup } from '../../models/state_models/addonsSetup';
import { AIReportSetup } from '../../models/state_models/aireportsetup';

export class AiReportOrderStore {
    mainStore: BaseMainStore;

    constructor(mainStore: BaseMainStore) {
        makeAutoObservable(this);
        this.mainStore = mainStore;
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
        }
        this.loadTypes();
    }

    userId = 0;
    roles: any[] = [];
    visitId = 0;
    providerId = 0;
    patientId = 0;
    @observable reportTypeId = 0;
    @observable reportsList: any[] = [];
    addOns: AddonsSetup[] = [];
    aiReportSetup: AIReportSetup[] = [];
    aiReportSetupSelected: AIReportSetup = new AIReportSetup();
    vivosId: string = '';
    patient: any = {};
    visit: any = {};
    visits: any[] = [];
    intraoralImageTypes: DictionaryListItem[] = [];
    extraoralImageTypes: DictionaryListItem[] = [];
    occlusionImageTypes: DictionaryListItem[] = [];
    extraoral2dimages: any[] = [];
    extraoral3dimages: any[] = [];
    intraoral2dimages: any[] = [];
    occlusion2dimages: any[] = [];
    occlusion3dimages: any[] = [];
    cbctscan: any = {};
    baseSubmission: any = {};
    submission: any = {};
    availableAddOns: AddonsSetup[] = [];

    @action setAiReportSetupSelected = (value: AIReportSetup) => { this.aiReportSetupSelected = value; };
    @action setExtraoral2dImages = (value: any[]) => { this.extraoral2dimages = value; }
    @action setExtraoral3dImages = (value: any[]) => { this.extraoral3dimages = value; }
    @action setintraoral2dImages = (value: any[]) => { this.intraoral2dimages = value; }
    @action setOcclusion2dImages = (value: any[]) => { this.occlusion2dimages = value; }
    @action setOcclusion3dImages = (value: any[]) => { this.occlusion3dimages = value; }
    @action setExtraoralImageTypes = (value: any[]) => { this.extraoralImageTypes = value; }
    @action setIntraoralImageTypes = (value: any[]) => { this.intraoralImageTypes = value; }
    @action setOcclusionImageTypes = (value: any[]) => { this.occlusionImageTypes = value; }
    @action setCbctScan = (value: any) => { this.cbctscan = value; }
    @action setVisitId = (visitId: any) => { this.visitId = visitId; };
    @action setVisit = (visit: PatientVisit) => { this.visit = visit; };
    @action setVisits = (visits: any[]) => { this.visits = visits; };
    @action setPatient = (patient: any) => { this.patient = patient; };
    @action setVivosId = (vivosId: string) => { this.vivosId = vivosId; };
    @action setPatientId = (patientId: any) => { this.patientId = patientId; };
    @action setProviderId = (providerId: any) => { this.providerId = providerId; };
    @action setReportTypeId = (reportType: any) => { this.reportTypeId = reportType; };
    @action setAddOns = (addOns: any[]) => { this.addOns = addOns; }
    @action setAvailableAddOns = (value: any[]) => { this.availableAddOns = value; };
    @action setReportsList = (value: any[]) => { this.reportsList = value; };
    @action loadTypes = () => {
        this.setIntraoralImageTypes(GlobalUtils.getIntraOralImages());
        this.setExtraoralImageTypes(GlobalUtils.getExtraOralImages());
        this.setOcclusionImageTypes(GlobalUtils.getPatientOcclusionImageTypes());
    }
    @action setBaseSubmission = (value: any) => { this.baseSubmission = value; }
    @action setSubmission = (value: any) => { this.submission = value; }

    @action resetVisitRecord = () => {
        this.setVisit({} as PatientVisit);
    };

    @action setAiReportOrderData = (
        visitId: number,
        patientId: number,
        providerId: number,
        reportType: number,
        baseSubmission?: any
    ) => {
        if (reportType === null || reportType === 0) {
            this.setVisitId(visitId);
            this.setPatientId(patientId);
            this.setProviderId(providerId);
            this.setReportTypeId(AIReportType.Standard);
        } else if (reportType === AIReportType.Standard) {
            this.setVisitId(visitId);
            this.setPatientId(patientId);
            this.setProviderId(providerId);
            this.setReportTypeId(AIReportType.Comparison);
        } else {
            this.setVisitId(visitId);
            this.setPatientId(patientId);
            this.setProviderId(providerId);
            this.setReportTypeId(AIReportType.Comparison);
        }
        if (baseSubmission !== null) {
            this.setBaseSubmission(baseSubmission);
        }
    };

    @action resetAiReportOrderData = () => {
        this.visits = [];
        this.visitId = 0;
        this.patientId = 0;
        this.providerId = 0;
        this.reportTypeId = 0;
        this.addOns = [];
        this.baseSubmission = {};
    };

    @action saveAiReportOrder = async (checkoutUrl?: string): Promise<any> => {
        let body = {
            visitRecordId: this.visits[0].id,
            patientId: this.patientId,
            providerId: this.providerId,
            reportTypeId: this.reportTypeId,
            addons: this.addOns.map(addon => addon.dictionaryListId),
            isComparativeReport: !isEmpty(this.baseSubmission),
            baseSubmissionId: isEmpty(this.baseSubmission) ? null : this.baseSubmission.id,
            userId: this.userId,
            compareVisitRecordOneId: this.visits[0].id,
            compareVisitRecordTwoId: _.isUndefined(this.visits[1]) ? null : this.visits[1].id,
            checkoutExternalId: checkoutUrl
        };

        try {
            const resp = await Gateway.post('/AIReports', body);
            this.setSubmission(resp);
            return { success: true, data: resp };
        } catch (error) {
            console.error("Error saving AI report order:", error);
            return { success: false, error: error };
        }
    };

    @action
    clearImages = () => {
        this.setExtraoral2dImages([]);
        this.setExtraoral3dImages([]);
        this.setintraoral2dImages([]);
        this.setOcclusion3dImages([]);
        this.setOcclusion2dImages([]);
        this.setCbctScan({});
    };

    @action
    visitImagesToPatientImages = () => {
        this.clearImages();
        return new Promise((resolve, reject) => {
            let promises: any[] = [];
            _.forOwn(this.visit?.patientsImages[0], async (val, k) => {
                if (val) {
                    let img = {} as PatientImage;
                    img.id = 0;
                    img.data64Url = decodeURI(val);
                    img.pathUrl = decodeURI(val);
                    img['filename'] = k;
                    let opt: DictionaryListItem | undefined = this.getTypeFromName(k);
                    if (opt) {
                        img.imageType = opt;
                    }
                    if (k.toString().toLowerCase().indexOf('extraoral') >= 0) {
                        if (k.toString().toLowerCase().indexOf('3d') >= 0) {
                            this.extraoral3dimages.push(img);
                        } else {
                            this.extraoral2dimages.push(img);
                        }
                    } else if (k.toString().toLowerCase().indexOf('intraoral') >= 0) {
                        this.intraoral2dimages.push(img);
                    } else if (k.toString().toLowerCase().indexOf('cbctscan') >= 0) {
                        this.cbctscan = img;
                    } else if (k.toString().toLowerCase().indexOf('occlusion') >= 0) {
                        if (
                            k.toString().toLowerCase().indexOf('3dupperarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('3dlowerarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('3dconstructedbite') >= 0
                        ) {
                            this.occlusion3dimages.push(img);
                        } else if (
                            k.toString().toLowerCase().indexOf('occlusionupperarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionlowerarch') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionanterior') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionleft') >= 0 ||
                            k.toString().toLowerCase().indexOf('occlusionright') >= 0
                        ) {
                            this.occlusion2dimages.push(img);
                        }
                    }
                }
            });

            Promise.all(promises).then(() => {
                this.setExtraoral2dImages(this.extraoral2dimages);
                this.setExtraoral3dImages(this.extraoral3dimages);
                this.setintraoral2dImages(this.intraoral2dimages);
                this.setOcclusion2dImages(this.occlusion2dimages);
                this.setOcclusion3dImages(this.occlusion3dimages);
                this.setCbctScan(this.cbctscan);
                resolve(true);
            });
        });
    };
    async doSaveImagesWork(img) {
        if (!img.data64String && img.data64Url.startsWith('blob:')) {
            img.data64String = await this.convertBase64(img.data64Url).then(() => {
                Gateway.post(`/patient/${this.patient.id}/${this.visit.id}/${this.userId}/addpatientimage`, img).then(
                    () => { },
                );
            });
        } else if ((img.data64String && img.data64Url.startsWith('blob:')) || img['edited'] === true) {
            Gateway.post(`/patient/${this.patient.id}/${this.visit.id}/${this.userId}/addpatientimage`, img).then(
                () => { },
            );
        }
    }

    convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    @action
    uploadImages = () => {
        return new Promise((resolve, reject) => {
            var promises: any[] = [];
            _.forEach(this.extraoral2dimages, async img => {
                if (img['edited'] === true || img['new'] === true) {
                    promises.push(this.doSaveImagesWork(img));
                }
            });
            _.forEach(this.intraoral2dimages, async img => {
                if (img['edited'] === true || img['new'] === true) {
                    promises.push(this.doSaveImagesWork(img));
                }
            });
            _.forEach(this.extraoral3dimages, async img => {
                if (img['edited'] === true || img['new'] === true) {
                    promises.push(this.doSaveImagesWork(img));
                }
            });
            _.forEach(this.occlusion2dimages, async img => {
                if (img['edited'] === true || img['new'] === true) {
                    promises.push(this.doSaveImagesWork(img));
                }
            });
            _.forEach(this.occlusion3dimages, async img => {
                if (img['edited'] === true || img['new'] === true) {
                    promises.push(this.doSaveImagesWork(img));
                }
            });
            Promise.all(promises).then(() => {
                //  this.loadReport().then(() => {
                resolve(true);
                //  });
            });
        });
    };

    @action
    getTypeFromName = (name: any) => {
        switch (name.toLowerCase()) {
            // case "cbctScan":
            //     return "CBCT Scan";
            //     break;
            case 'extraoralfrontrelaxed':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 182;
                }); // "Front Relaxed";
                break;
            case 'extraoralbigsmile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 181;
                }); // "Big Smile";
                break;
            case 'extraoralrightprofile':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 183;
                }); // "Right Profile";
                break;
            case 'extraoralsubmentovertex':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 184;
                }); // "Submento Vertex";
                break;
            case 'extraoralfullbodypostanterior':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 185;
                }); // "Full Body Post Anterior";
                break;
            case 'extraoralfullbodypostright':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 186;
                }); // "Full Body Post Right";
                break;
            case 'extraoral3dfacescan':
                return this.extraoralImageTypes.find(f => {
                    return f.id == 187;
                }); // "3D Face Scan";
                break;
            case 'intraoralupperarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 170;
                }); //  "Upper Arch";
                break;
            case 'intraorallowerarch':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 171;
                }); // "Lower Arch";
                break;
            case 'intraoralanteriorocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 172;
                }); // "Anterior Occlusion";
                break;
            case 'intraoralleftocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 173;
                }); //  "Left Occlusion";
                break;
            case 'intraoralrightocclusion':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 174;
                }); //  "right Occlusion";
                break;
            case 'intraorallingualfrenum':
                return this.intraoralImageTypes.find(f => {
                    return f.id == 220;
                }); // "Lingual Frenum";
                break;
            case 'occlusion3dupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 221;
                }); // "3D Upper Arch";
                break;
            case 'occlusion3dlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 222;
                }); //  "3D Lower Arch";
                break;
            case 'occlusion3dconstructedbite':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 223;
                }); //  "3D Constructed Bite";
                break;
            case 'occlusionupperarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 224;
                }); // "Upper Arch";
                break;
            case 'occlusionlowerarch':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 225;
                }); //  "Lower Arch";
                break;
            case 'occlusionanterior':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 226;
                }); //  "Anterior";
                break;
            case 'occlusionleft':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 227;
                }); //  "Left";
                break;
            case 'occlusionright':
                return this.occlusionImageTypes.find(f => {
                    return f.id == 228;
                }); //  "Right";
                break;
            default:
                return;
        }
    };

    @action
    validateAiReport = () => {
        //extra 181-196
        //intra 170-174, 220
        //cbct
    }

    
}
