import React, { Fragment, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    ListItemButton,
    useTheme,
    ListItemText,
    Grid,
    alpha,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    Link as MuiLink,
    CardHeader,
    CardContent,
    List,
} from '@mui/material';
import { Masonry } from '@mui/lab';
import { ahsPricingGuide, caPricingGuide, ggdPricingGuide, lifelinePricingGuide, swjPricingGuide, vipPricingGuide } from './Helper/documents';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.palette.background.paper};
        border-radius: ${theme.shape.borderRadius}px;
        padding: ${theme.spacing(3)};
    `
);

const ListItemButtonWrapper = styled(ListItemButton)(
    ({ theme }) => `
        transition: transform 0.15s ease, box-shadow 0.15s ease;
        transform: scale(1);
        background: ${theme.palette.background.paper};
        position: relative;
        z-index: 5;
        &:hover {
            border-radius: ${theme.shape.borderRadius}px;
            background: ${theme.palette.action.hover};
            z-index: 6;
            box-shadow: 
                0 0.1rem 0.4rem ${alpha(theme.palette.common.black, 0.05)},
                0 0.2rem 0.6rem ${alpha(theme.palette.common.black, 0.07)};
            transform: scale(1.02); /* Subtle scaling for a refined hover effect */
        }
    `
);


const allProviderTypes = [
    "VIP",
    "Lifeline",
    "Guided Growth and Development",
    "C.A.R.E",
    "Airway Health Solutions",
    "Sleep Well Journey",
    "Noum",
    "Lincare",
    "Certified Legacy Provider",
    "Colorado Sleep Institute",
    "Clinical Advisor"
];

const documentGroups = [
    {
        name: "Pricing",
        documents: [
            { id: 1, providerAccess: ["VIP", "Lincare", "C.A.R.E", "Certified Legacy Provider"], name: "Vivos Provider Pricing Guide", link: vipPricingGuide, type: "link", description: '' },
            { id: 2, providerAccess: ["Guided Growth and Development"], name: "Vivos Pricing Guide: GGD", link: ggdPricingGuide, type: "link", description: '' },
            { id: 3, providerAccess: ["Lifeline"], name: "Vivos Pricing Guide: Lifeline", link: lifelinePricingGuide, type: "link", description: '' },
            { id: 4, providerAccess: ["Airway Health Solutions"], name: "Vivos Pricing Guide: Airway Health Solutions", link: ahsPricingGuide, type: "link", description: '' },
            { id: 5, providerAccess: ["Clinical Advisor"], name: "Vivos Pricing Guide: Clinical Advisor", link: caPricingGuide, type: "link", description: '' },
            { id: 6, providerAccess: ["Sleep Well Journey"], name: "Vivos Pricing Guide: Sleep Well Journey", link: swjPricingGuide, type: "link", description: '' },
            { id: 7, 
                providerAccess: ["Noum", "Colorado Sleep Institute", "Lincare"], 
                name: (
                    <>
                        Please contact our Vivos Support Team for details on your specific pricing. <br /> <br />
                        Phone: 1-866-908-4867. <br />
                        Email: helpme@vivoslife.com.
                    </>
                ),
                type: "text", 
                description: '' 
              }
              
        ]
    },
    {
        name: "Billing",
        documents: [
            { id: 10, providerAccess: allProviderTypes, name: "What is the Billing Intelligence Service?", link: "https://vivos.com/learn-more-about-bis/", type: "link", description: '' },
            { id: 11, providerAccess: allProviderTypes, name: "What is AireO2?", link: "https://vivos.com/aireo2/", type: "link", description: '' },
            { id: 12, providerAccess: allProviderTypes, name: "What billing plans are offered?", link: "https://vivosbis.com/select-your-plan/", type: "link", description: '' },
        ]
    },
];

function PricingBillingContent({ providerTypes, isProvider }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleDocumentClick = (doc) => {
        if (doc.type === 'link') {
            window.open(doc.link, '_blank', 'noopener noreferrer');
        } else if (doc.type === 'popup') {
            setDialogContent(doc.description);
            setOpenDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <BoxWrapper>
                        <Typography variant='body1' textAlign='center'>
                            This page will guide you through Vivos pricing and Billing Intelligence Services (BIS).
                        </Typography>
                    </BoxWrapper>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Masonry columns={{ xs: 1, sm: 1, md: 2 }} spacing={2}>
                    {documentGroups.map((group, index) => (
                        <Card key={index}>
                            <CardHeader
                                title={group.name}
                                titleTypographyProps={{ variant: 'h5', textAlign: 'center' }}
                                sx={{
                                    backgroundColor: theme.palette.primary.dark,
                                    left: `0`,
                                    top: `0`,
                                    height: `60px`,
                                    color: theme.palette.primary.contrastText,
                                }}
                            />
                            <CardContent>
                                <List disablePadding>
                                    {group.documents.filter(doc => {
                                        if (!isProvider) return true;
                                        return doc.providerAccess.some(accessType =>
                                            providerTypes.some(providerType => providerType.includes(accessType))
                                        );
                                    }).map((doc) => (
                                        <Fragment key={doc.id}>
                                            <Divider />
                                            {doc.type === "link" ? (
                                                <ListItemButtonWrapper onClick={() => handleDocumentClick(doc)}>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="h6" color="textPrimary">
                                                                {doc.name}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButtonWrapper>
                                            ) : (
                                                <Typography variant="h6" color="textSecondary" textAlign="center">
                                                    {doc.name}
                                                </Typography>
                                            )}
                                        </Fragment>
                                    ))}
                                </List>
                            </CardContent>
                        </Card>
                    ))}
                </Masonry>
            </Grid>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography variant='h5'>Information</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography>{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default PricingBillingContent;
