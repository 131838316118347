import { makeObservable, observable } from 'mobx';

export class TreatmentWorkingDiagnosis {
    @observable id: number = 0;
    @observable treatmentPlanId: number = 0;
    @observable diagnosis: string = '';

    constructor() {
        makeObservable(this);
    }
}
