import React, { ChangeEvent, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    useTheme,
    Grid,
    alpha,
    Avatar,
    Button,
    Tabs,
    Tab,
    Divider
} from "@mui/material";

import QuizIcon from '@mui/icons-material/Quiz';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DescriptionIcon from '@mui/icons-material/Description';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { odoPricingGuide } from './Helper/documents';
import { link } from 'fs';


const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
    `
);
const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
          overflow: visible !important;
  
          .MuiTabs-scroller {
              overflow: visible !important;
          }
  
          .MuiButtonBase-root {
              text-transform: uppercase;
              font-size: ${theme.typography.pxToRem(12)};
  
              &:last-child {
                  margin-right: 0;
              }
          }
      `
);

function OnDemandOrthoContent() {
    const theme = useTheme();
    const [currentTab, setCurrentTab] = useState<string>('overview');

    const tabs = [
        { value: 'overview', label: 'Overview & FAQs' },
        { value: 'gettingStarted', label: 'Getting Started' },
        { value: 'pricing', label: 'Pricing & Packages' }
    ];

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    return (
        <Card>
            <Grid container spacing={0}>
                <Grid item xs={12} xl={5}>
                    <BoxComposed
                        display="flex"
                        alignItems="center"
                        sx={{
                            minHeight: '100%',
                            background: `${theme.colors.gradients.black1}`
                        }}
                    >
                        <BoxComposedContent px={8} pt={8} pb={4}>
                            <Avatar
                                variant="square"
                                sx={{
                                    mx: 'auto',
                                    mb: 2,
                                    width: 'auto',
                                    height: 200,
                                    '& img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                src="/logos/ODO.png"
                            />
                            <Typography lineHeight="1.5" fontWeight="normal" variant="h5">
                                On Demand Orthodontist (ODO) is a team of virtual orthodontic associates lead by Dr. John Warford
                                (Ormco & Dental Monitoring KOL) that has collectively designed and refined over 15k cases.Clients
                                range from Orthodontic practices running hot to airway doctors requiring expansion/protraction mechanics.
                            </Typography>
                        </BoxComposedContent>
                    </BoxComposed>
                </Grid>
                <Grid item xs={12} xl={7}>
                    <Box py={3} px={4}>
                        <TabsWrapper
                            centered
                            onChange={handleTabsChange}
                            value={currentTab}
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            {tabs.map((tab) => (
                                <Tab key={tab.value} label={tab.label} value={tab.value} />
                            ))}
                        </TabsWrapper>
                    </Box>

                <Divider />
                {currentTab === 'overview' && (
                    <>
                        <Box p={4}>
                            <Grid container spacing={3}>
                                {/* Overview Heading */}
                                <Grid item xs={12}>
                                    <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
                                        Overview
                                    </Typography>
                                    <Typography variant="body1" lineHeight="1.8" textAlign="center" color="text.secondary">
                                        On Demand Orthodontist (ODO) is a team of virtual orthodontic associates led by Dr. John Warford. We have
                                        collectively designed and refined over 15,000 cases, catering to orthodontic practices and airway doctors.
                                    </Typography>
                                </Grid>

                                {/* Links Section */}
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        gutterBottom
                                        sx={{ textAlign: 'center', mt: 3 }}
                                    >
                                        Click Below to Learn More About On Demand Orthodontist (ODO):
                                    </Typography>
                                </Grid>

                                {[
                                    {
                                        title: "What is On Demand Orthodontist (ODO) ?",
                                        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/ODO/Final What is ODO 1.2024pdf.pdf",
                                        icon: <AccountCircleIcon fontSize="large" />,
                                    },
                                    {
                                        title: "What does On Demand Orthodontist provide?",
                                        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/ODO/Final What does ODO Provide 1.2024.pdf",
                                        icon: <HandshakeIcon fontSize="large" />,
                                    },
                                    {
                                        title: "Advantages of On Demand Orthodontist",
                                        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/ODO/Final Advantages of ODO 1.2024.pdf",
                                        icon: <StarIcon fontSize="large" />,
                                    },
                                    {
                                        title: "On Demand Orthodontist: FAQ's Sheet",
                                        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/ODO/Final ODOFAQandHandOut 11.7.23.pdf",
                                        icon: <QuizIcon fontSize="large" />,
                                    },
                                ].map((item, index) => (
                                    <Grid item xs={12} md={6} key={index}>
                                        <Card
                                            variant="outlined"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: 2,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2,
                                                boxShadow: 1,
                                                transition: 'all 0.2s ease-in-out',
                                                '&:hover': {
                                                    boxShadow: 4,
                                                    transform: 'scale(1.02)',
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    bgcolor: theme.palette.primary.main,
                                                    color: theme.palette.primary.contrastText,
                                                    borderRadius: '50%',
                                                    width: 48,
                                                    height: 48,
                                                    mr: 2,
                                                }}
                                            >
                                                {item.icon}
                                            </Box>
                                            <Typography
                                                variant="body1"
                                                component="a"
                                                href={item.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{ textDecoration: 'none', color: 'inherit' }}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </>
                )}
                    {currentTab === 'gettingStarted' && (
                        <>
                            <Box p={4}>
                                <Grid container spacing={1}>
                                    {[
                                        {
                                            step: 1,
                                            description: (
                                                <>
                                                    To get started, fill out this form by clicking{" "}
                                                    <strong>
                                                        <a
                                                            href="https://forms.monday.com/forms/49a8a1cd119710766242197fbc03fc26?r=use1"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            HERE
                                                        </a>
                                                    </strong>, which will prompt a meeting invitation with our customer onboarding specialist.
                                                </>
                                            ),
                                        },
                                        {
                                            step: 2,
                                            description: "A demo will be scheduled, and Spark & Dental Monitoring accounts will be set up through ODO (45 min).",
                                        },
                                        {
                                            step: 3,
                                            description: "A Local Spark rep will join the demo and visit the office locally, defining the role in the process.",
                                        },
                                        {
                                            step: 4,
                                            description: "After the demo, the provider and an assistant will be invited to upload a case with an ODO dashboard walkthrough (30 min).",
                                        },
                                        {
                                            step: 5,
                                            description: "The Vivos provider will go through a decision tree of clinical consultation requirements and meet 1:1 with an ODO Orthodontist, if required.",
                                        },
                                        {
                                            step: 6,
                                            description: "ODO Onboarding for start day with instructions, clinical tips, dental monitoring, and resources (45 min).",
                                        },
                                        {
                                            step: 7,
                                            description: "Follow-up from start day (15 min).",
                                        },
                                        {
                                            step: 8,
                                            description: "ODO will continue to collaborate with the provider on patient treatment as needed throughout the case.",
                                        },
                                    ].map(({ step, description }, index) => (
                                        <Grid item xs={12} key={index}>
                                            <Card
                                                variant="outlined"
                                                sx={{
                                                    padding: 2, // Reduced padding inside the card
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        bgcolor: theme.palette.primary.main,
                                                        color: theme.palette.primary.contrastText,
                                                        mr: 2,
                                                        width: 32,
                                                        height: 32, // Adjusted size to be smaller
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {step}
                                                </Avatar>
                                                <Typography lineHeight="1.5" fontWeight="normal" variant="body2">
                                                    {description}
                                                </Typography>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    )}
                    {currentTab === 'pricing' && (
                    <>
                        <Box p={4}>
                            <Grid container justifyContent="center">
                                {/* Pricing Guide Card */}
                                <Grid item xs={12} sm={8} md={6}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: 3,
                                            borderRadius: 3,
                                            boxShadow: 2,
                                            backgroundColor: 'background.paper',
                                            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                boxShadow: 4,
                                            },
                                        }}
                                    >
                                        <RequestQuoteIcon
                                            sx={{
                                                fontSize: 50,
                                                color: theme.palette.primary.main,
                                                marginBottom: 2,
                                            }}
                                        />
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                            textAlign="center"
                                            gutterBottom
                                        >
                                            ODO & Spark Pricing Guide
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            href={odoPricingGuide}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ textTransform: 'none' }}
                                        >
                                            View Pricing Guide
                                        </Button>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}

                </Grid>
            </Grid>
        </Card>
    );
}

export default OnDemandOrthoContent;
