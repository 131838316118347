// Vendors
import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';

// Components
import { PatientTreatmentModal } from './PatientTreatmentModal';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// API
import { observer } from 'mobx-react';
import { GlobalUtils } from '../../../api/GlobalUtils';
import { toJS } from 'mobx';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';
import { EstimatedTreatmentTime } from './EstimatedTreatmentTime';

interface IPatientTreatmentPlanning {
    patient: any;
}

export const PatientTreatmentPlanning: React.FC<IPatientTreatmentPlanning> = observer(({ patient }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { loadTreatment } = useCreateUpdateTreatmentPlanMainStoreContext();
    const { treatmentPlan, setTreatmentPlan } = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;
    const { toggleIsLoading, setLoadingText } = useCreateUpdateTreatmentPlanMainStoreContext();

    const handleOpen = () => setIsOpen(true);

    const onClosed = () => setIsOpen(false);

    useEffect(() => {
        treatmentPlan.patientId = patient.id;
        toggleIsLoading();
        setLoadingText('Loading...');
        setTreatmentPlan(treatmentPlan);
        loadTreatment().then(() => {
            toggleIsLoading();
        });
    }, [patient.id]);

    return (
        <Grid container sx={{ overflowY: 'auto' }}>
            <Grid item xs={12} padding={2}>
                <Button variant="contained" onClick={handleOpen}>
                    {/* The following will check to see if there is a truthy value for created by. */}
                    {treatmentPlan.createdBy ? 'Edit Treatment Plan' : 'NEW Treatment Plan'}
                </Button>
            </Grid>
            <Grid item xs={12}>
                {treatmentPlan.createdBy && <EstimatedTreatmentTime />}
            </Grid>
            <Grid item xs={12}>
                <PatientTreatmentModal patient={patient} isOpen={isOpen} onClose={onClosed} />
            </Grid>
        </Grid>
    );
});
