// Vendors
import { Grid, Avatar, Typography, styled, Box, Button } from "@mui/material";
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { useDropzone } from "react-dropzone";
import { Gateway } from "../../../api/Gateway";
import { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";
import _ from "lodash";

const BoxUploadWrapper = styled(Box)(
	({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);

const AvatarWrapper = styled(Avatar)(
	({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};

  `
);

const MyDropzone = () => {

	const { currentPatient, setApplianceConstructionDiagramFilename, applianceConstructionDiagramFilename, isUpdating, prescription } = usePrescriptionStore().prescriptionStore;

	const onDrop = useCallback(async (acceptedFiles) => {
		const file = acceptedFiles[0];
		const body = new FormData();
		const base64 = await fileToBase64(file);

		body.append('diagram', base64.substring(base64.indexOf(',') + 1));
		body.append('name', file.name);

		var diagramUrl = await Gateway.post(`/patient/appliances/${currentPatient.id}/diagram`, body);
		setApplianceConstructionDiagramFilename(diagramUrl);
	}, []);

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				if (reader.readyState === FileReader.DONE) {
					const base64 = reader.result as string;
					resolve(base64);
				}
			};

			reader.onerror = () => {
				reject(new Error('Error reading file'));
			};

			reader.readAsDataURL(file);
		});
	}

	const onDelete = async () => {
		const deleteBlobBody = new FormData();
		deleteBlobBody.append('blobUrl', applianceConstructionDiagramFilename ?? '');
		await Gateway.delete(`/patient/appliances/diagram`, deleteBlobBody);

		if (prescription?.id) {
			const updateDiagramBody = new FormData();
			updateDiagramBody.append('diagramUrl', '');
			await Gateway.put(`/prescription/${prescription.id}/appliances/diagram`, updateDiagramBody);
		}
		setApplianceConstructionDiagramFilename('');
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
	return (
		<Grid>
			{<Grid container {...getRootProps()}>
				<Box paddingX={2}>
					<BoxUploadWrapper>
						<input {...getInputProps()} />
						<AvatarWrapper variant="rounded">
							<DescriptionTwoToneIcon />
						</AvatarWrapper>
						<Typography sx={{ mt: 2 }}>
							{applianceConstructionDiagramFilename ? applianceConstructionDiagramFilename.split('/').pop() : 'Upload Appliance Construction Diagram'}
						</Typography>
					</BoxUploadWrapper>

				</Box>
			</Grid>}
			{applianceConstructionDiagramFilename ? (
				<>
					<Button
						variant="contained"
						color="primary"
						onClick={() => window.open(applianceConstructionDiagramFilename, '_blank')}
					>
						Download
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={onDelete}
					>
						Delete
					</Button>
				</>
			) : (
				''
			)}
		</Grid>
	);
};

export default observer(MyDropzone);