// Vendors
import React, { useEffect } from 'react';
import { Grid, TextField, Button, MenuItem, Box, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// Components
import { Tooth } from "./Tooth";
import DataGrid from '../DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { SupernumeraryModal } from './SupernumeraryModal';
import { UneruptedModal } from './UneruptedReasonsModal';

// Entities
import Patient from '../../entities/Patient';
import { NumberingSystem, PresentConditions, TeethSet } from '../../entities/Enums';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { GlobalUtils } from '../../api/GlobalUtils';
import { toJS } from 'mobx';

// Styling
import "../../scss/_Tooth.scss";
import "../../scss/_global.scss";
import CssSelectField from '../CssSelectField';
import CssTextField from '../CssTextField';

interface TeethChartingProps {
    existingPatient?: Patient;
    id?: number;
}

const gridStyle = { marginTop: 10 };
const MISSINGREASONDEFAULT = 113;
const PRESENTCONDITIONDEFAULT = 122;

export const TeethCharting: React.FC<TeethChartingProps> = observer(
    ({ existingPatient, id }) => {
        const {
            openSupernumeraryModal,
            openUneruptedModal,
            teethChartTeeth,
            patientId,
            teethChart,
            notes,
            currentNumberingSystem,
            currentTeethSet,
            showWarningModal,
            addSupernumerary,
            addUnerupted,
            handleSetNotes,
            onChangeMissingReason,
            onChangePresentConditions,
            hasDetails,
            onDeleteTooth,
            onToothClick,
            onToothNoteChange,
            handleNumberingSystemClick,
            resetTeethChart,
            setOpenUneruptedModal,
            setPatientId,
            loadChart,
            handleTeethSetClick,
            getIndexByNumberingSystem,
            getDeciduousToothIndex,
            setCurrentTeethSet,
            setCurrentNumberingSystem
        } = useMainStoreContext().teethChartStore;

        const { patient } = useMainStoreContext().createPatientStore;
        const storeProvider = useMainStoreContext().providerStore;
        const [missingReasons, setMissingReasons] = React.useState<JSX.Element[]>([]);
        const [presentConditions, setPresentConditions] = React.useState<JSX.Element[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);

        // Validation helper for indices
        const isIndexValid = (index: number) => {
            const isTeethChartDefined = !!teethChartTeeth; // Check if teethChartTeeth is defined
            const isTeethChartArray = Array.isArray(teethChartTeeth); // Check if it's an array
            const isIndexInRange = index >= 0 && index < (teethChartTeeth?.length ?? 0); // Check if index is within range
            
            //console.log(`Index: ${index}`);
            //console.log(`teethChartTeeth Defined: ${isTeethChartDefined}`);
            //console.log(`teethChartTeeth is Array: ${isTeethChartArray}`);
            //console.log(`Index in Range: ${isIndexInRange}`);
            //console.log(`teethChartTeeth Length: ${teethChartTeeth?.length ?? 'undefined'}`);
        
            const isValid = isTeethChartDefined && isTeethChartArray && isIndexInRange;
            //console.log(`IsValid: ${isValid}`);
            return isValid;
        };

        const getPresentConditions = (index: number) => {
            if(isIndexValid(index)){
                return teethChartTeeth[index]?.presentConditionsArray ?? []
            }
            return [];
        };

        useEffect(() => {
            resetTeethChart();
            setMissingReasons(_.map(GlobalUtils.getMissingReasons(), (m, i) => (
                <MenuItem key={i} className='gridDropDown' value={m.id}>{m.name}</MenuItem>
            )));

            const presentCond = GlobalUtils.getPresentConditions();
            const missing = presentCond.findIndex((x) => x.id === PresentConditions.Missing);
            if (missing >= 0) presentCond.unshift(presentCond.splice(missing, 1)[0]);

            setPresentConditions(_.map(presentCond, (m, i) => (
                <MenuItem key={i} className='gridDropDown' value={m.id}>{m.name}</MenuItem>
            )));

            if (existingPatient) {
                setPatientId(existingPatient.id);
            } else {
                setPatientId(id ?? patient?.id ?? 0);
            }

            loadChart().then(() => setHasLoaded(true));

            storeProvider.getProviderPreferences().then(() => {
                const teethSetType = GlobalUtils.getTeethSetType() ?? []; // Fallback to empty array if undefined
                const numericSystemType = GlobalUtils.getTeethNumSystemType() ?? []; // Fallback to empty array if undefined
            
                // Ensure teethSetType and numericSystemType are arrays and check bounds
                if (!Array.isArray(teethSetType)) {
                    console.warn("teethSetType is not a valid array");
                }
                if (!Array.isArray(numericSystemType)) {
                    console.warn("numericSystemType is not a valid array");
                }
            
                const foundedSetType = teethSetType?.find(
                    (x) => x.id === storeProvider.providerPreferences?.teethSetPref
                );
                setCurrentTeethSet(foundedSetType ? foundedSetType.listItemKey : TeethSet.ALL);
            
                const foundedSystemType = numericSystemType?.find(
                    (x) => x.id === storeProvider.providerPreferences?.teethSystemPref
                );
                setCurrentNumberingSystem(foundedSystemType ? foundedSystemType.listItemKey : NumberingSystem.UNIVERSAL);
            }).catch((error) => {
                console.error("Error fetching provider preferences or setting teeth/numeric system types:", error);
            });
        }, []);

        const columns: GridColDef[] = [
            {
                field: 'toothNumber',
                headerName: 'Tooth ##',
                type: 'number',
                headerAlign: 'center',
                headerClassName: "colHeader",
                align: "left",
                renderCell: (params) => (
                    <Grid>
                        {params.row.isDeciduous
                            ? getDeciduousToothIndex(params.row.toothNumber)
                            : getIndexByNumberingSystem(params.row.toothNumber)}
                    </Grid>
                )
            },
            {
                field: 'PresentCondition',
                flex: 1,
                headerName: 'Condition',
                headerAlign: 'center',
                headerClassName: "colHeader",
                align: "left",
                renderCell: (params) => (
                    <CssSelectField
                        className='gridDropDown'
                        id={"teethChartGrid.ddl.presentConditions" + params.row.toothNumber}
                        defaultValue={PRESENTCONDITIONDEFAULT}
                        multiple
                        value={params.row.presentConditionsArray ?? []}
                        onChange={(event) => onChangePresentConditions(params.row, event.target.value)}>
                        {presentConditions}
                    </CssSelectField>
                )
            },
            {
                field: 'missingReason',
                flex: 1,
                headerName: 'Missing Reason',
                type: 'number',
                headerAlign: 'center',
                headerClassName: "colHeader",
                align: "left",
                renderCell: (params) => (
                    <CssSelectField
                        className='gridDropDown'
                        id={"teethChartGrid.ddl.missingReasons" + params.row.toothNumber}
                        disabled={!params.row.presentConditionsArray?.includes(PresentConditions.Missing)}
                        defaultValue={MISSINGREASONDEFAULT}
                        value={params.row.missingReason ?? MISSINGREASONDEFAULT}
                        onChange={(event) => onChangeMissingReason(params.row, event.target.value)}>
                        {missingReasons}
                    </CssSelectField>
                )
            },
            {
                field: 'Notes',
                flex: 2,
                headerName: 'Notes',
                headerAlign: "left",
                headerClassName: "colHeader",
                align: "left",
                renderCell: (params) => (
                    <CssTextField
                        id={"teethChartGrid.txt.notes" + params.row.toothNumber}
                        className="gridTextField"
                        variant="standard"
                        value={params.row.notes ?? ''}
                        onChange={(event) => onToothNoteChange(event, params.row.toothNumber)}
                    />
                )
            },
            {
                field: 'IsActive',
                flex: 0.5,
                headerName: ' ',
                headerAlign: 'center',
                headerClassName: "colHeader",
                align: "left",
                renderCell: (params) => (
                    <Grid onClick={() => onDeleteTooth(params.row.toothNumber)}>
                        <RemoveCircleIcon color="error" />
                    </Grid>
                )
            },
        ];

        return (

            <Grid xs={12} container direction="column" className='teethCharting' rowSpacing={{ xl: 1 }}>
                <Grid xs={12} container direction="column">
                    <Grid container alignItems={"center"}>
                        <Grid item xs={5}><Typography variant='h5'>Select a tooth then select conditions in the table below.</Typography></Grid>
                        <Grid item xs={7} display={'flex'} container justifyContent={'flex-end'} alignItems={"center"}>
                            <Box paddingRight={1}><Typography>Click to adjust teeth chart settings:</Typography></Box>
                            <Box width={150} paddingRight={1}><Button fullWidth variant='outlined' onClick={() => { handleTeethSetClick() }}>{currentTeethSet}</Button></Box>
                            <Box width={150}><Button fullWidth variant='outlined' onClick={() => { handleNumberingSystemClick() }}>{currentNumberingSystem}</Button></Box>
                            {/* <button className="vivButton orange" onClick={() => { openSupernumeraryModel(true) }}>[+] SUPERNUMERARY</button> */}
                        </Grid>
                        <Grid item xs={12} paddingTop={1}>
                            <Box display="flex" justifyContent="flex-end" alignItems="center" >
                                {/* Missing Legend */}
                                <Box display="flex" alignItems="center" paddingRight={2}>
                                    <Box width={15} height={15} bgcolor="#8996a0" marginRight={1} borderRadius="50%" /> {/* Adjust color as per your missing condition color */}
                                    <Typography>Missing</Typography>
                                </Box>
                                {/* Condition Legend */}
                                <Box display="flex" alignItems="center">
                                    <Box width={15} height={15} bgcolor="#ff3e00" marginRight={1} borderRadius="50%" /> {/* Adjust color as per your condition color */}
                                    <Typography>Condition</Typography>
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>
                    <Grid container

                    >
                        <Grid xs={12} container justifyContent="center" className="gridDirections"><span>UPPER</span></Grid>
                        <Grid xs={12} container direction="row" justifyContent="center">
                            <Grid xs={.2} container alignContent="center" className="gridDirections"><span>R</span></Grid>
                            {/* <Grid xs={10.6} container direction="row" className="toothChart"> */}
                            <Grid xs={10.6} container direction="row">
                                <Grid container                                >
                                    <Grid container direction="row" xs={6} className="borderBottomRight">
                                        {[1,2,3,4,5,6,7,8].map((index) =>                                            
                                            <Tooth
                                                onClick={onToothClick}
                                                index={index}
                                                tooth={hasDetails(index)}
                                                currentPresentConditions={getPresentConditions(index)}
                                            />                                        
                                        )}                                         
                                    </Grid>
                                    <Grid container direction="row" xs={6} className="borderBottomLeft">
                                        {[9,10,11,12,13,14,15,16].map((index) =>
                                            
                                            <Tooth
                                                onClick={onToothClick}
                                                index={index}
                                                tooth={hasDetails(index)}
                                                currentPresentConditions={getPresentConditions(index)}
                                            />                                        
                                        )}                                       
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid container direction="row" xs={6} className="borderTopRight">
                                        {[32,31,30,29,28,27,26,25].map((index) =>
                                            
                                            <Tooth
                                                onClick={onToothClick}
                                                index={index}
                                                tooth={hasDetails(index)}
                                                currentPresentConditions={getPresentConditions(index)}
                                            />                                        
                                        )}                                          
                                    </Grid>
                                    <Grid container direction="row" xs={6} className="borderTopLeft">
                                        {[24,23,22,21,20,19,18,17].map((index) =>                                            
                                            <Tooth
                                                onClick={onToothClick}
                                                index={index}
                                                tooth={hasDetails(index)}
                                                currentPresentConditions={getPresentConditions(index)}
                                            />                                        
                                        )}                                         
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={.2} container alignContent="center" className="gridDirections"><span>L</span></Grid>
                        </Grid>
                        <Grid xs={12} container justifyContent="center" className="gridDirections"><span>LOWER</span></Grid>
                    </Grid>
                    <Grid >
                        {
                            <DataGrid
                                columns={columns}
                                data={toJS(teethChartTeeth)}
                                hideFooter={true}
                                size={"small"}
                                pageSize={7}
                            />
                        }
                    </Grid>
                    <Grid container padding={1}>
                        <TextField
                            label="Other teeth condition notes:"
                            fullWidth
                            multiline
                            minRows={3}
                            value={notes}
                            onChange={handleSetNotes}
                        />
                    </Grid>
                </Grid>
                {/* <SupernumeraryModal isOpen={openSupernumeraryModal} onClose={() => { openSupernumeraryModel(false); }} onSave={addSupernumerary} /> */}
                <UneruptedModal isOpen={openUneruptedModal} onClose={() => { setOpenUneruptedModal(false); }} onSave={addUnerupted} />
            </Grid>
        )
    }

);