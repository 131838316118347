// Vendors
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    Typography,
    styled,
    Checkbox,
    FormControlLabel,
    Avatar,
    Slider
} from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { usePDF } from 'react-to-pdf';

// API
import { observer } from "mobx-react-lite";
import _ from "lodash";
import moment from "moment";

// Components
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import { PrintTeethCharting } from "../TeethCharting/PrintTeethCharting";
import { PrintTeethChartingSummary } from "../TeethCharting/PrintTeethChartingSummary";
import { CareConstructionTable } from "./components/CareConstructionTable"
import { VidaSleepConstructionTable } from "./components/VidaSleepConstructionTable";
import { VidaConstructionTable } from "./components/VidaConstructionTable";
import { PExConstructionTable } from "./components/PExConstructionTable";
import { GuideConstructionTable } from "./components/GuideConstructionTable";

// Entities
import { ConstructionTypes } from "../../entities/Enums";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";
import { FabricationTime } from "../../entities/Enums";

interface IGIProps {
    prescription: any;
    patient: any;
}

const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
          width: ${theme.spacing(25)};
          height: auto;
          padding: ${theme.spacing(1.8)};
    `
);

export const ViewRx: React.FC<IGIProps> = observer(({ prescription, patient }) => {

    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const visitStore = useMainStoreContext().visitRecordStore;
    const [scale, setScale] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const { toPDF, targetRef } = usePDF({
        filename: prescription.rxId + '.pdf',
        page: { orientation: 'landscape' }
    });
    const handleScaleChange = (event: Event, newValue: number | number[]) => {
        setScale(newValue as number);
    };

    useEffect(() => {
        prescriptionStore.loadPrintRxValues();
        if (prescriptionStore.prescription.order?.externalEcommerceOrderId) {
            prescriptionStore.getShopifyOrder(prescriptionStore.prescription.order?.externalEcommerceOrderId);
            console.log(prescriptionStore.shopifyOrder);
        }
        console.log(prescriptionStore.dentalPartValues);
    }, []);

    const handleRxTableToShow = () => {

        switch (prescription.appliance?.applianceType) {
            case ConstructionTypes["PEx-D"]:
            case ConstructionTypes["PEx-L"]:
            case ConstructionTypes["PEx-U"]:
                return <PExConstructionTable prescription={prescription} />;
            case ConstructionTypes["Vivos Dual DNA System"]:
            case ConstructionTypes["Vivos Single Arch DNA Lower"]:
            case ConstructionTypes["Vivos Single Arch DNA Upper"]:
            case ConstructionTypes["Vivos mRNA System"]:
            case ConstructionTypes["Vivos mRNA Single Arch Lower"]:
            case ConstructionTypes["Vivos mRNA Single Arch Upper"]:
            case ConstructionTypes["Vivos mmRNA System"]:
            case ConstructionTypes["Vivos mmRNA Single Arch Lower"]:
            case ConstructionTypes["Vivos mmRNA Single Arch Upper"]:
                return <CareConstructionTable prescription={prescription} />;
            case ConstructionTypes.Vida:
                return <VidaConstructionTable prescription={prescription} />;
            case ConstructionTypes.VidaSleep:
                return <VidaSleepConstructionTable prescription={prescription} />;
            case ConstructionTypes["Versa-9"]:
                return (
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Typography variant="caption">
                            Versa-9
                        </Typography>
                    </Grid>
                );
            case ConstructionTypes["Versa-EMA"]:
                return (
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Typography variant="caption">
                            Versa-EMA
                        </Typography>
                    </Grid>
                );
            case ConstructionTypes["Versa Oral Appliance"]:
            case ConstructionTypes.emaNow:
                return (
                    <Grid container justifyContent={'center'} alignContent={'center'}>
                        <Typography variant="caption">
                            {prescription.appliance?.name}
                        </Typography>
                    </Grid>
                );
            case ConstructionTypes.GuideVG:
            case ConstructionTypes.GuideVGx:
            case ConstructionTypes.GuideVW:
            case ConstructionTypes["Vivos System Guide"]:
                return <GuideConstructionTable prescription={prescription} />;
            default:
                return <GuideConstructionTable prescription={prescription} />;
        }
    };

    return (
        <Grid xs={12} paddingX={4} paddingBottom={2}>
            {!isLoading && <Grid>
                <Grid container spacing={2} padding={1}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            fullWidth
                            startIcon={<PrintIcon />}
                            variant="contained"
                            //onClick={openViewRxModal}
                            onClick={() => {
                                toPDF();
                            }}
                        >
                            Print Rx PDF
                        </Button>
                    </Grid>
                </Grid>
                {/* Scale Slider */}
                <Grid container spacing={2} padding={1}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="body1">Adjust Scale:</Typography>
                        <Slider
                            value={scale}
                            min={0.5}
                            max={2}
                            step={0.1}
                            onChange={handleScaleChange}
                            valueLabelDisplay="auto"
                            aria-labelledby="scale-slider"
                            sx={{ width: 200, marginLeft: 2 }}
                        />
                    </Grid>
                </Grid>
                <div ref={targetRef} >
                    <Grid container paddingX={5} style={{ transform: `scale(${scale})`, transformOrigin: 'center' }}>
                        <Grid item xs={6} paddingRight={3}>
                            <Grid container spacing={2} padding={1}>
                                <Grid item xs style={{ display: "flex", justifyContent: "center" }}>
                                    <AvatarWrapper
                                        variant="rounded"
                                        alt="Appliance"
                                        src={prescriptionStore.labsImages.find(lab => lab.name === prescription.lab?.name)?.logo}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} padding={1}>
                                <Grid item xs={5}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                            {prescription?.rxId}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} paddingTop={3}>
                                        <Typography variant="h5" color="grey">
                                            Patient:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" >
                                            {patient?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} paddingTop={3}>
                                        <Typography variant="h5" color="grey">
                                            Doctor Name:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                            {prescription.provider?.person?.firstName} {prescription.provider?.person?.lastName}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={2}>
                                </Grid>

                                <Grid item xs={5}>
                                    <Box sx={{ backgroundColor: 'grey.300', padding: 2, borderRadius: 1 }}>
                                        <Grid item xs={12}>
                                            <Typography variant="h4" paddingBottom={2}>
                                                Provider Info:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} paddingLeft={3}>
                                            <Typography variant="h5">
                                                <Grid>Dr. {prescription.provider?.person?.firstName} {prescription.provider?.person?.lastName}</Grid>
                                                {/* Render shipping address if shopifyOrder and shippingAddress exist */}
                                                <Grid>
                                                    {prescriptionStore.shopifyOrder?.shippingAddress ? (
                                                        <>
                                                            {/* For example, render each address field in separate lines */}
                                                            <div>{prescriptionStore.shopifyOrder.shippingAddress.name}</div>
                                                            <div>{prescriptionStore.shopifyOrder.shippingAddress.address1}</div>
                                                            {prescriptionStore.shopifyOrder.shippingAddress.address2 && (
                                                                <div>{prescriptionStore.shopifyOrder.shippingAddress.address2}</div>
                                                            )}
                                                            <div>
                                                                {prescriptionStore.shopifyOrder.shippingAddress.city},{" "}
                                                                {prescriptionStore.shopifyOrder.shippingAddress.province}{" "}
                                                                {prescriptionStore.shopifyOrder.shippingAddress.zip}
                                                            </div>
                                                            <div>{prescriptionStore.shopifyOrder.shippingAddress.country}</div>
                                                        </>
                                                    ) : (
                                                        // Fallback if no shipping address
                                                        <div>No shipping address found</div>
                                                    )}
                                                </Grid>
                                                <Grid paddingTop={1}>Phone: {prescription.provider?.person?.practicePhone}</Grid>
                                                <Grid>Email: {prescription.provider?.person?.emailAddress}</Grid>
                                            </Typography>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box height={5} sx={{ backgroundColor: 'grey.300' }}></Box>
                            <Grid container paddingTop={4}>
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant="h2">
                                        Prescription Detail
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} alignContent="center" container justifyContent="center">
                                    <AvatarWrapper
                                        variant="rounded"
                                        alt="Appliance"
                                        src={prescriptionStore.applianceOptions.find(a => a.constructId === prescription.appliance?.applianceType)?.logo}
                                    />
                                </Grid>
                                <Grid container xs={8} paddingTop={2}>
                                    <Box sx={{ backgroundColor: 'grey.300', padding: 2, borderRadius: 1, width: "100%" }}>
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Date Submitted: {prescription?.submittedDate ? moment(prescription?.submittedDate).format('MM/DD/yyyy') : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Fabrication Time: {FabricationTime[prescription?.fabricationTime]}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Needed By: {prescription?.neededByDate ? moment(prescription?.neededByDate).format('MM/DD/yyyy') : ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1">
                                                    Shipping Method:{" "}
                                                    {prescriptionStore.shopifyOrder?.shippingLines &&
                                                        prescriptionStore.shopifyOrder.shippingLines.length > 0 ? (
                                                        // Display the title or code of the first shipping line
                                                        <>{prescriptionStore.shopifyOrder.shippingLines[0].title}</>
                                                    ) : (
                                                        // Fallback if no shipping lines found
                                                        "No shipping method"
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    Model Type: {visitStore.currentVisit.patientsImages?.[0]?.modelType?.name ?? ''}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    {/* Bite Type: {prescriptionStore.constructionBiteTypeValues.find(x => x.id === visitStore.currentVisit.patientsImages[0].constructionBiteType)?.name} */}
                                                    Bite Type: {visitStore.currentVisit.patientsImages?.[0]?.constructionBiteTypeNavigation?.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    {/* Upper midline at protrusion: {prescriptionStore.protrusionPositionValues.find(x => x.id === visitStore.currentVisit.patientsImages[0].potrusionPositionId)?.name} */}
                                                    Upper midline at protrusion: {visitStore.currentVisit.patientsImages?.[0]?.potrusionPosition?.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    Approved to adjust 2mm Vertically : {visitStore.currentVisit.patientsImages?.[0]?.labAdjustConstructionBite ? 'Yes' : 'No'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={4}>
                                <Grid container justifyContent="center">
                                    <Box
                                        sx={{
                                            padding: 2,
                                            borderRadius: 1,
                                            width: "100%",
                                            minHeight: "300px",
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid
                                            item
                                            paddingRight={1}
                                            xs={6}
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            paddingX={2}
                                        >
                                            <Typography variant="h3">
                                                Upper
                                            </Typography>
                                            {visitStore.currentVisit?.patientsImages?.[0]?.intraoralUpperArch && (
                                                <img
                                                    src={visitStore.currentVisit?.patientsImages?.[0]?.intraoralUpperArch}
                                                    style={{ height: '250px', borderRadius: "8px" }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            paddingLeft={1}
                                            item
                                            xs={6}
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            paddingX={2}
                                        >
                                            <Typography variant="h3">
                                                Lower
                                            </Typography>
                                            {visitStore.currentVisit?.patientsImages?.[0]?.intraoralLowerArch && (
                                                <img
                                                    src={visitStore.currentVisit?.patientsImages?.[0]?.intraoralLowerArch}
                                                    style={{ height: '250px', borderRadius: "8px" }}
                                                />
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid paddingBottom={1}>
                                {/* <PrintTeethCharting existingPatient={patient} /> */}
                                <PrintTeethChartingSummary existingPatient={patient} />
                            </Grid>
                            <Box height={5} sx={{ backgroundColor: 'grey.300' }}></Box>
                        </Grid>

                        <Grid item xs={6} paddingLeft={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" textAlign="center">
                                    {prescription?.rxId}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container paddingY={1}>
                                <Grid item xs={6} container direction="column" paddingLeft={14}>
                                    <Typography variant="h5" color="grey">
                                        Patient:
                                    </Typography>
                                    <Typography variant="h4">
                                        {patient?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} container direction="column" paddingLeft={14}>
                                    <Typography variant="h5" color="grey">
                                        Doctor Name:
                                    </Typography>
                                    <Typography variant="h4">
                                        {prescription.provider?.person?.firstName} {prescription.provider?.person?.lastName}
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid paddingY={1}>
                                {handleRxTableToShow()}
                            </Grid>
                            <Grid>
                                <Grid container justifyContent={'space-between'} alignItems="center">
                                    <Grid item>
                                        <Typography variant="h4">
                                            Construction Comments:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!_.isEmpty(visitStore.currentVisit.patientsImages?.[0]) ? visitStore.currentVisit.patientsImages?.[0].isSpeakWithLab : false}
                                                    name="isSpeakWithLab"
                                                    color="primary"
                                                    disabled={true}
                                                />
                                            }
                                            label="Consultation with Lab representative"
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ backgroundColor: 'grey.300', padding: 2, borderRadius: 1 }}>
                                    <Typography variant="body1">
                                        {prescription.constructionComments}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid>
                                {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            //FIX SOMETHING WITH ISSPEAKWITHLAB WILL PERIODICALLY CAUSE THE SCREEN TO WHITE OUT
                                            // checked={visitStore.currentVisit.patientsImages[0].isSpeakWithLab}
                                            disabled={true}
                                            name="isSpeakwithAIRSpecialist"
                                            color="primary"
                                        />
                                    }
                                    label="Consultation with Vivos Airway Intelligence Specialist"
                                /> */}
                            </Grid>
                            <Grid container paddingTop={2}>
                                <Grid item xs={5}>
                                    <Typography variant="h5" style={{ fontFamily: 'Brush Script MT, cursive' }} fontSize={24}>
                                        {prescription.provider?.person?.firstName} {prescription.provider?.person?.lastName}
                                    </Typography>
                                    <Box height={2} sx={{ backgroundColor: 'black', width: '80%' }} ></Box>
                                </Grid>
                                <Grid item xs={5} container alignContent='flex-end'>
                                    <Box height={2} sx={{ backgroundColor: 'black', width: '80%' }} ></Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h5">
                                        License Number
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Dr. Signature
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="h5">
                                        {prescription.provider?.dentalLicense}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={4}>
                                <Grid item xs={5} container alignContent='flex-end'>
                                    <Box height={2} sx={{ backgroundColor: 'black', width: '80%' }} ></Box>
                                </Grid>
                                <Grid item xs={5} container alignContent='flex-end'>
                                    <Box height={2} sx={{ backgroundColor: 'black', width: '80%' }} ></Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Manager Approval Signature
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>
                            </Grid>
                            <Grid container paddingTop={4}>
                                <Grid item xs={5} container alignContent='flex-end'>
                                    <Box height={2} sx={{ backgroundColor: 'black', width: '80%' }} ></Box>
                                </Grid>
                                <Grid item xs={5} container alignContent='flex-end'>
                                    <Box height={2} sx={{ backgroundColor: 'black', width: '80%' }} ></Box>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Welding Verification Signature
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Date
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Grid container paddingTop={1}>
                                <Grid xs={12}>
                                    <Typography variant="h5">
                                        For Lab Use Only
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography variant="body1">
                                        Pan Number:
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography variant="body1">
                                        Lab Case ID:
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography variant="body1">
                                        Tracking Number: {prescription.trackingNo}
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </div>
            </Grid >}
        </Grid >
    )
}
);

