// Vendors
import react, { FC, useEffect, useState } from 'react';
import {
    Grid,
    styled,
    List,
    ListItem,
    ListItemText,
    alpha,
    Typography,
    Box,
    Button,
} from '@mui/material';

// Components
import SectionContainer from '../../../../UI/Containers/SectionContainer';

// API
import { observer } from 'mobx-react';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';

interface IDiagnosisPage {
    patient?: any;
}

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

export const ReviewTreatment: FC<IDiagnosisPage> = observer(() => {
    const { treatmentPlan } = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;
    return (
        <Grid>
            <SectionContainer
                sectionTitle={'Review'}
                cardContentHeight={'500px'}
                sectionComponent={
                    <Grid>
                        <ListWrapper>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Case Type
                                        </Typography>
                                    }
                                    secondary={
                                        <Box mt={0.7} display="flex" alignItems="center">
                                            <Grid container spacing={1} direction={'column'} paddingX={3}>
                                                {treatmentPlan.treatmentComplaints.map((complaint, ind, arr) => {
                                                    return (<Typography>{complaint.areaOfComplaint}</Typography>)
                                                })}
                                            </Grid>
                                        </Box>
                                    }
                                />
                            </ListItem>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Diagnosis
                                        </Typography>
                                    }
                                    secondary={
                                        <Box mt={0.7} display="flex" alignItems="center">
                                            <Grid container spacing={1} direction={'column'} paddingX={3}>
                                                <Typography>Sleep Conditions (Dx by MD): {treatmentPlan.treatmentSleepDisorders.map((x, i, ar)=> { return x.sleepDisorder}).join(", ")}</Typography>
                                                <Typography>Dental Diagnosis: {treatmentPlan.dentalDiagnosisMalocclusion}</Typography>
                                                <Typography>Working Diagnosis: {treatmentPlan.treatmentWorkingDiagnoses.map((x, i, ar)=> { return x.diagnosis}).join(", ")}</Typography>
                                            </Grid>
                                        </Box>
                                    }
                                />
                            </ListItem>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Objectives
                                        </Typography>
                                    }
                                    secondary={
                                        <Box mt={0.7} display="flex" alignItems="center">
                                            <Grid container spacing={1} direction={'column'} paddingX={3}>
                                                <Typography>Improve: {treatmentPlan.treatmentObjectives.filter(x=> x.value.includes('improveId')).map((x, i, ar)=> { return x.objective.name}).join(", ")}</Typography>
                                                <Typography>Maintain: {treatmentPlan.treatmentObjectives.filter(x=> x.value.includes('maintainId')).map((x, i, ar)=> {return x.objective.name}).join("," )}</Typography>
                                            </Grid>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        </ListWrapper>
                    </Grid>
                }
            />
        </Grid>
    );
});
