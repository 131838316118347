import React, { useEffect, useState } from "react";
import { Button, FormControl, FormControlLabel, FormGroup, Grid, Checkbox, SelectChangeEvent, Switch, Typography, TextField } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';

import CssTextField from "../../CssTextField";
import Item from "../../Item";
import { GlobalUtils } from "../../../api/GlobalUtils";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";
import { SecurityUpdateWarningOutlined } from "@mui/icons-material";
import ProviderPatient from '../../../entities/ProviderPatient';
import PatientEditBL from '../PatientEditBL';

// interface IGIProps {
//     defaultEditMode?: boolean;
// }

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
}

export const PatientHealthHistoryTwo: React.FC<IGIProps> = observer
    (({ bl, patient }) => {
        // (({ defaultEditMode }) => {
        const [toggleOnEdit, setToggleOnEdit] = useState(bl.isEditMode);
        const { allergyOptions, cpapmadOptions, dentalOptions, isEditMode, medicalOptions, patientHistory,
            medicalList, dentalList, cpapList, allergyList,
            allergyHxNotes, allergyhxOnChange, setAllergyHxNotes, setMedicalList,
            cpapMadHxNotes, cpapMadhxOnChange, setCpapMadHxNotes, setDentalList,
            dentalHxNotes, dentalhxOnChange, setDentalHxNotes, setCpapList,
            medicalHxNotes, medicalhxOnChange, setMedicalHxNotes, setAllergyList,
            optionChecked,
            setIsEditMode, setPatientId, setPatientHistory } = useMainStoreContext().patientEditStore;
        // const { patient } = useMainStoreContext().createPatientStore;
        const [medicalhxValues, setMedicalHxValues] = React.useState<any>({});
        const [medList, setMedList] = React.useState<any[]>([]);
        const [dentList, setDentList] = React.useState<any[]>([]);
        const [cpapMadList, setcpcpList] = React.useState<any[]>([]);
        const [allerList, setallerList] = React.useState<any[]>([]);
        const TONSILEREMOVED = 1;
        const ADENOIDSREMOVED = 2;
        const NECKSURGERY = 4;
        const BACKSURGERY = 8;
        const CHEMOTHERAPY = 16;
        const HYPOTHYROIDISM = 32;
        const MEDOTHER = 64;
        const BRACES = 1;
        const CLEARALIGNERS = 2;
        const IPR = 4;
        const TORISURGERY = 8;
        const JAWSURGERY = 16;
        const JOINTSURGERY = 32;
        const PERIODISEASE = 64;
        const DENTOTHER = 128;
        const CPAPPRESCRIBED = 1;
        const CPAPINTOLERANT = 2;
        const CPAPNONCOMPLIANT = 4;
        const MADPRESCRIBED = 8;
        const MADNONCOMPLIANT = 16;
        const MADINTOLERANT = 32;
        const ACRYLIC = 1;
        const METALS = 2;
        const PLASTIC = 4;
        const LATEX = 8;
        const FOOD = 16;
        const PAINMEDICATIONS = 32;
        const ALLEROTHER = 64;

        //  useEffect(() => {
        //      if (defaultEditMode) {
        //          setbl.isEditMode(defaultEditMode);
        //      }
        //     setPatientHistory(bl.patientHistory);
        //     setHistory(bl.patientHistory);
        //  }, [])

        // useEffect(() => {
        //     if (defaultEditMode) {
        //         setbl.isEditMode(defaultEditMode);
        //     }
        //     getLists();
        //     setPatientId(patient.id);
        // }, [])

        const getLists = () => {
            var medhxopts: any[] = [];
            GlobalUtils.getMedicalHxOptions().then((data) => {
                _.forEach(data[0].dictionaryListItems, (s) => {
                    medhxopts[s.listItemKey] = s.value;
                });
                setMedList(medhxopts);
                setMedicalList(medhxopts);
            });

        }
        // useEffect(() => {
        //     getLists();
        // }, [medList, dentList, cpapMadList, allerList])


        return (
            <Grid container>
                <Typography variant="h5" paddingBottom={1}>Medical Hx</Typography>
                <Grid container>
                    {(!bl.isEditMode
                        && !optionChecked(medicalOptions, TONSILEREMOVED)
                        && !optionChecked(medicalOptions, NECKSURGERY)
                        && !optionChecked(medicalOptions, CHEMOTHERAPY)
                        && !optionChecked(medicalOptions, MEDOTHER)
                        && !optionChecked(medicalOptions, ADENOIDSREMOVED)
                        && !optionChecked(medicalOptions, BACKSURGERY)
                        && !optionChecked(medicalOptions, HYPOTHYROIDISM)
                        && !medicalHxNotes)
                        && <Grid item xs={12}>
                            <Typography variant="subtitle1" padding={2}>
                                No medical history recorded.
                            </Typography>
                        </Grid>

                    }
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={1}>
                            {bl.isEditMode || optionChecked(medicalOptions, TONSILEREMOVED)
                                ? <Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, TONSILEREMOVED) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, TONSILEREMOVED) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}
                                    >
                                        Tonsils Removed
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(medicalOptions, NECKSURGERY)
                                ? <Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, NECKSURGERY) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, NECKSURGERY) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Neck Surgery
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(medicalOptions, CHEMOTHERAPY)
                                ? <Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, CHEMOTHERAPY) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, CHEMOTHERAPY) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}
                                    >
                                        Chemotherapy
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(medicalOptions, MEDOTHER)
                                ? <Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, MEDOTHER) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, MEDOTHER) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Other
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(medicalOptions, ADENOIDSREMOVED)
                                ? <Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, ADENOIDSREMOVED) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, ADENOIDSREMOVED) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Adenoids Removed
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(medicalOptions, BACKSURGERY)
                                ? < Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, BACKSURGERY) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, BACKSURGERY) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Back Surgery
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(medicalOptions, HYPOTHYROIDISM)
                                ? <Grid item xs={4} md={3}>

                                    <Button
                                        variant={optionChecked(medicalOptions, HYPOTHYROIDISM) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { medicalhxOnChange(event, HYPOTHYROIDISM) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Hypothyroidism
                                    </Button>

                                </Grid> : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} paddingLeft={2}>
                        {(bl.isEditMode
                            || medicalHxNotes) && <TextField
                                style={{ width: '100%', height: '100px' }}
                                multiline
                                minRows={3}
                                maxRows={3}
                                variant="outlined"
                                disabled={!bl.isEditMode}
                                label="Medical Hx Notes"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setMedicalHxNotes(event.target.value);
                                }}
                                value={medicalHxNotes}
                            />}
                    </Grid>
                </Grid>
                <Typography variant="h5" paddingBottom={1} paddingTop={3}>Dental Hx</Typography>
                <Grid container spacing={3}>
                    {!bl.isEditMode
                        && !optionChecked(dentalOptions, BRACES)
                        && !optionChecked(dentalOptions, TORISURGERY)
                        && !optionChecked(dentalOptions, PERIODISEASE)
                        && !optionChecked(dentalOptions, DENTOTHER)
                        && !optionChecked(dentalOptions, CLEARALIGNERS)
                        && !optionChecked(dentalOptions, JAWSURGERY)
                        && !optionChecked(dentalOptions, JOINTSURGERY)
                        && !optionChecked(dentalOptions, IPR)
                        && !dentalHxNotes
                        ? <Grid item xs={12}>
                            <Typography variant="subtitle1" padding={2}>
                                No dental history recorded.
                            </Typography>
                        </Grid> : ''}
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={1}>
                            {bl.isEditMode || optionChecked(dentalOptions, BRACES)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(dentalOptions, BRACES) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, BRACES) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Braces
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, TORISURGERY)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, TORISURGERY) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, TORISURGERY) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Tori Surgery
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, PERIODISEASE)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, PERIODISEASE) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, PERIODISEASE) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Perio Disease
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, DENTOTHER)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, DENTOTHER) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, DENTOTHER) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Other
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, CLEARALIGNERS)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, CLEARALIGNERS) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, CLEARALIGNERS) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Clear Aligners
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, JAWSURGERY)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, JAWSURGERY) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, JAWSURGERY) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Jaw Surgery
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, JOINTSURGERY)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, JOINTSURGERY) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, JOINTSURGERY) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Joint Surgery
                                    </Button>

                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(dentalOptions, IPR)
                                ? <Grid item xs={3}>

                                    <Button
                                        variant={optionChecked(dentalOptions, IPR) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { dentalhxOnChange(event, IPR) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        IPR
                                    </Button>

                                </Grid> : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {(bl.isEditMode
                            || dentalHxNotes) && <TextField
                                style={{ width: '100%', height: '100px' }}
                                multiline
                                minRows={3}
                                maxRows={3}
                                variant="outlined"
                                disabled={!bl.isEditMode}
                                label="Dental Hx Notes"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setDentalHxNotes(event.target.value);
                                }}
                                value={dentalHxNotes}
                            />}
                    </Grid>
                </Grid>
                <Typography variant="h5" paddingBottom={1} paddingTop={3}>Sleep Hx</Typography>
                <Grid container spacing={3}>
                    {!bl.isEditMode
                        && !optionChecked(cpapmadOptions, CPAPPRESCRIBED)
                        && !optionChecked(cpapmadOptions, CPAPNONCOMPLIANT)
                        && !optionChecked(cpapmadOptions, CPAPINTOLERANT)
                        && !optionChecked(cpapmadOptions, MADPRESCRIBED)
                        && !optionChecked(cpapmadOptions, MADNONCOMPLIANT)
                        && !optionChecked(cpapmadOptions, MADINTOLERANT)
                        && !cpapMadHxNotes
                        ? <Grid item xs={12}>
                            <Typography variant="subtitle1" padding={2}>
                                No sleep history recorded.
                            </Typography>
                        </Grid> : ''}
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={1}>
                            {bl.isEditMode
                                || optionChecked(cpapmadOptions, CPAPPRESCRIBED)
                                || optionChecked(cpapmadOptions, CPAPNONCOMPLIANT)
                                || optionChecked(cpapmadOptions, CPAPINTOLERANT)
                                ? <Grid container spacing={1} paddingBottom={1} paddingTop={1}>
                                    <Grid item xs={3}>
                                        <Typography variant="h6" textAlign={'right'} >cPAP</Typography>
                                    </Grid>

                                    {bl.isEditMode
                                        || optionChecked(cpapmadOptions, CPAPPRESCRIBED)
                                        ? <Grid item xs={3}>
                                            <Button
                                                variant={optionChecked(cpapmadOptions, CPAPPRESCRIBED) ? 'contained' : 'outlined'}
                                                disabled={!bl.isEditMode}
                                                color="primary"
                                                onClick={(event) => { cpapMadhxOnChange(event, CPAPPRESCRIBED) }}
                                                fullWidth
                                                sx={(theme) => ({
                                                    '&.Mui-disabled': {
                                                        background: theme.palette.primary.light,
                                                        color: 'white'
                                                    },
                                                })}>
                                                Prescribed
                                            </Button>
                                        </Grid> : ''}
                                    {bl.isEditMode
                                        || optionChecked(cpapmadOptions, CPAPNONCOMPLIANT)
                                        ? <Grid item xs={3}>
                                            <Button
                                                variant={optionChecked(cpapmadOptions, CPAPNONCOMPLIANT) ? 'contained' : 'outlined'}
                                                disabled={!bl.isEditMode}
                                                color="primary"
                                                onClick={(event) => { cpapMadhxOnChange(event, CPAPNONCOMPLIANT) }}
                                                fullWidth
                                                sx={(theme) => ({
                                                    '&.Mui-disabled': {
                                                        background: theme.palette.primary.light,
                                                        color: 'white'
                                                    },
                                                })}>
                                                Non-Compliant
                                            </Button>
                                        </Grid> : ''}
                                    {bl.isEditMode
                                        || optionChecked(cpapmadOptions, CPAPINTOLERANT)
                                        ? <Grid item xs={3}>
                                            <Button
                                                variant={optionChecked(cpapmadOptions, CPAPINTOLERANT) ? 'contained' : 'outlined'}
                                                disabled={!bl.isEditMode}
                                                color="primary"
                                                onClick={(event) => { cpapMadhxOnChange(event, CPAPINTOLERANT) }}
                                                fullWidth
                                                sx={(theme) => ({
                                                    '&.Mui-disabled': {
                                                        background: theme.palette.primary.light,
                                                        color: 'white'
                                                    },
                                                })}>
                                                Intolerant
                                            </Button>

                                        </Grid> : ''}
                                </Grid> : ''}
                            {bl.isEditMode
                                || optionChecked(cpapmadOptions, MADPRESCRIBED)
                                || optionChecked(cpapmadOptions, MADNONCOMPLIANT)
                                || optionChecked(cpapmadOptions, MADINTOLERANT)
                                ? <Grid container spacing={1} paddingBottom={1} paddingTop={1}>
                                    <Grid item xs={3}>
                                        <Typography variant="h6" textAlign={'right'} >MAD</Typography>
                                    </Grid>
                                    {bl.isEditMode
                                        || optionChecked(cpapmadOptions, MADPRESCRIBED)
                                        ? <Grid item xs={3}>
                                            <Button
                                                variant={optionChecked(cpapmadOptions, MADPRESCRIBED) ? 'contained' : 'outlined'}
                                                disabled={!bl.isEditMode}
                                                color="primary"
                                                onClick={(event) => { cpapMadhxOnChange(event, MADPRESCRIBED) }}
                                                fullWidth
                                                sx={(theme) => ({
                                                    '&.Mui-disabled': {
                                                        background: theme.palette.primary.light,
                                                        color: 'white'
                                                    },
                                                })}>
                                                Prescribed
                                            </Button>
                                        </Grid> : ''}
                                    {bl.isEditMode
                                        || optionChecked(cpapmadOptions, MADNONCOMPLIANT)
                                        ? <Grid item xs={3}>
                                            <Button
                                                variant={optionChecked(cpapmadOptions, MADNONCOMPLIANT) ? 'contained' : 'outlined'}
                                                disabled={!bl.isEditMode}
                                                color="primary"
                                                onClick={(event) => { cpapMadhxOnChange(event, MADNONCOMPLIANT) }}
                                                fullWidth
                                                sx={(theme) => ({
                                                    '&.Mui-disabled': {
                                                        background: theme.palette.primary.light,
                                                        color: 'white'
                                                    },
                                                })}>
                                                Non-Compliant
                                            </Button>
                                        </Grid> : ''}
                                    {bl.isEditMode
                                        || optionChecked(cpapmadOptions, MADINTOLERANT)
                                        ? <Grid item xs={3}>
                                            <Button
                                                variant={optionChecked(cpapmadOptions, MADINTOLERANT) ? 'contained' : 'outlined'}
                                                disabled={!bl.isEditMode}
                                                color="primary"
                                                onClick={(event) => { cpapMadhxOnChange(event, MADINTOLERANT) }}
                                                fullWidth
                                                sx={(theme) => ({
                                                    '&.Mui-disabled': {
                                                        background: theme.palette.primary.light,
                                                        color: 'white'
                                                    },
                                                })}>
                                                Intolerant
                                            </Button>

                                        </Grid> : ''}
                                </Grid> : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {(bl.isEditMode
                            || cpapMadHxNotes) && <TextField
                                style={{ width: '100%', height: '100px' }}
                                multiline
                                minRows={3}
                                maxRows={3}
                                variant="outlined"
                                disabled={!bl.isEditMode}
                                label="cPAP and MAD Hx Notes"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCpapMadHxNotes(event.target.value);
                                }}
                                value={cpapMadHxNotes}
                            />}
                    </Grid>
                </Grid>
                <Typography variant="h5" paddingBottom={1} paddingTop={3}>Allergy Hx</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={1}>
                            {!bl.isEditMode
                                && !optionChecked(allergyOptions, ACRYLIC)
                                && !optionChecked(allergyOptions, PLASTIC)
                                && !optionChecked(allergyOptions, FOOD)
                                && !optionChecked(allergyOptions, ALLEROTHER)
                                && !optionChecked(allergyOptions, METALS)
                                && !optionChecked(allergyOptions, LATEX)
                                && !optionChecked(allergyOptions, PAINMEDICATIONS)
                                && !allergyHxNotes
                                ? <Grid item xs={12}>
                                    <Typography variant="subtitle1" padding={2}>
                                        No allergy history recorded.
                                    </Typography>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, ACRYLIC)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, ACRYLIC) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, ACRYLIC) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Acrylic
                                    </Button>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, PLASTIC)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, PLASTIC) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, PLASTIC) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Plastics
                                    </Button>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, FOOD)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, FOOD) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, FOOD) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Food
                                    </Button>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, ALLEROTHER)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, ALLEROTHER) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, ALLEROTHER) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Other
                                    </Button>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, METALS)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, METALS) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, METALS) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Metals
                                    </Button>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, LATEX)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, LATEX) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, LATEX) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Latex
                                    </Button>
                                </Grid> : ''}
                            {bl.isEditMode || optionChecked(allergyOptions, PAINMEDICATIONS)
                                ? <Grid item xs={3}>
                                    <Button
                                        variant={optionChecked(allergyOptions, PAINMEDICATIONS) ? 'contained' : 'outlined'}
                                        disabled={!bl.isEditMode}
                                        color="primary"
                                        onClick={(event) => { allergyhxOnChange(event, PAINMEDICATIONS) }}
                                        fullWidth
                                        sx={(theme) => ({
                                            '&.Mui-disabled': {
                                                background: theme.palette.primary.light,
                                                color: 'white'
                                            },
                                        })}>
                                        Pain Medications
                                    </Button>
                                </Grid> : ''}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        {(bl.isEditMode
                            || allergyHxNotes) && <TextField
                                style={{ width: '100%', height: '100px' }}
                                multiline
                                minRows={3}
                                maxRows={3}
                                variant="outlined"
                                disabled={!bl.isEditMode}
                                label="Allergy Hx Notes"
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setAllergyHxNotes(event.target.value);
                                }}
                                value={allergyHxNotes}
                            />}
                    </Grid>
                </Grid>
            </Grid >
        )
    }
    );
