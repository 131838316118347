// Vendors
import React, { useEffect } from "react";
import {
    Grid,
    Button,
    SelectChangeEvent,
    IconButton,
    TextField,
    MenuItem,
    styled,
    Tooltip,
    Box
} from '@mui/material';
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Delete } from "@material-ui/icons";

// API
import { observer } from "mobx-react";
import _ from "lodash";
import { StlViewer } from "react-stl-viewer";
import { AnonymousCredential } from "@azure/storage-blob";
import { GlobalUtils } from "../../api/GlobalUtils";

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
      transition: ${theme.transitions.create(['transform', 'background'])};
      transform: scale(1);
      transform-origin: center;
  
      &:hover {
          transform: scale(1.1);
      }
    `
);


const fileTypes = ["JPG", "PNG"];
const MAXWIDTH = 430;
const MAXHEIGHT = 250;
// const MAXWIDTH = 350;
// const MAXHEIGHT = 350;
const MAXCROPPERWIDTH = 100;
const MAXCROPPERHEIGHT = 100;
interface IUploaderProps {
    index: number;
    stlIndex: number;
    parentCallback(data64string: string, dataPathUrl: string, option: any): any;
    handleRemove(image: any, itemOption: number): any;
    handleOptionSelect(event: any, oldOption: number, index: number, isRemoval: boolean): any;
    typeOptions: any[];
    selectedOptions: number[];
    images: any[];
    image: any;
    imageOption?: number;
    vivosId: string;
    container: string,
    containerId: number;
    disabled?: boolean;
}

const Stl3DViewerWithTypes: React.FC<IUploaderProps> =
    ({ image, index, stlIndex, typeOptions, vivosId, images, imageOption, selectedOptions,
        container, containerId, handleOptionSelect, handleRemove, parentCallback, disabled }) => {
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [options, setOptions] = React.useState<any[]>([]);
        const [option, setOption] = React.useState<number>(-1);
        const [imageLoading, setImageLoading] = React.useState(true);

        useEffect(() => {
            if (typeOptions) {
                setOptions(typeOptions);
                generateOptions(typeOptions, selectedOptions);
                setHasLoaded(true);
            }
        }, [])
        useEffect(() => {
            generateOptions(typeOptions, selectedOptions);
        }, [index])

        const handleReady = () => {
            setImageLoading(false);
            parentCallback("", "", 0);
        }

        const generateOptions = (list: any[], selectedList: number[]) => {
            var newList = _.map(list, (option) => {
                var index = _.findIndex(selectedList, (l) => { return option.id === l })
                return (
                    <MenuItem disabled={index >= 0} value={option.id}>{option.name}</MenuItem>)
            })
            setOptions(newList);
        }

        const getSource = () => {
            var src = container == process.env.REACT_APP_AIREPORTS_CONTAINER ?
                GlobalUtils.getAIReportUrl(image.data64Url ? image.data64Url : image, vivosId, containerId) :
                GlobalUtils.getVisitRecordUrl(image.data64Url ? image.data64Url : image, vivosId, containerId);
            return src;
        }

        const style = {
            top: 0,
            left: 0,
            width: MAXWIDTH,
            height: MAXHEIGHT,
            border: '1px solid #7E9DBB',
        }

        const handleSelection = (event) => {
            var opts = handleOptionSelect(event, option, stlIndex, false);
            generateOptions(typeOptions, opts);
            setOption(Number(event.target.value));
        }

        return (
            hasLoaded ?
                <Grid key={index} container direction="column" margin={3}>
                    <Grid container justifyContent={'center'}>
                        <Box position="relative">
                            <StlViewer
                                style={style}
                                orbitControls
                                shadows
                                url={getSource()}
                                onFinishLoading={handleReady}
                            />
                            {!disabled ?
                                <Tooltip title={'Delete'} arrow>
                                    <IconButton
                                        onClick={(event: any) => {
                                            handleRemove(images[index], imageOption ? imageOption : (images[stlIndex] && images[stlIndex].imageType ? images[stlIndex].imageType.id : -1));
                                        }}
                                        sx={{
                                            position: 'absolute',
                                            top: -20,
                                            right: -18,
                                            backgroundColor: theme => theme.colors.error.lighter,
                                            color: theme => theme.colors.error.main,
                                            '&:hover': {
                                                backgroundColor: theme => theme.colors.error.main,
                                                color: theme => theme.palette.getContrastText(theme.colors.error.main)
                                            }
                                        }}
                                    >
                                        <DeleteTwoToneIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip> : null}
                        </Box>
                    </Grid>
                    {(!imageLoading && typeOptions && typeOptions.length > 0) &&
                        // {(true) &&
                        <Grid container paddingTop={2} justifyContent={'center'}>
                            <Box width="300px">
                                <TextField
                                    fullWidth
                                    label="Model Type"
                                    select
                                    key={index}
                                    onChange={(event: any) => {
                                        handleSelection(event);
                                    }}
                                    disabled={disabled ? disabled : false}
                                    value={imageOption ? imageOption : (images[stlIndex] && images[stlIndex].imageType ? images[stlIndex].imageType.id : -1)}
                                >
                                    {options}
                                </TextField>
                            </Box>
                        </Grid>}
                </Grid>
                : null
        )
    }
export default observer(Stl3DViewerWithTypes);