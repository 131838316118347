import { Gateway } from '../../api/Gateway';
import * as _ from 'lodash';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';
import ProviderPatient from '../../entities/ProviderPatient';
import Patient from '../../entities/Patient';

export class ClinicalAdvocateStore {
    @observable private _basePatientData: any[] = [];
    @observable private _patientData: any[] = [];
    @observable private _resultCount: number = 0;
    @observable private _toggleShowPatientEditModal: boolean = false;
    @observable private _currentPatientId: number = 0;
    @observable private _currentPatient: {} = {};
    @observable private _totalPages: number = 1;
    @observable private _isLeftNavExpanded: boolean = false;
    @observable private _hasMore: boolean = true;
    @observable private _firstPageLoaded: boolean = false;
    @observable private _currentPage: number = -1;
    @observable private _selectedVivosId: string = '';
    @observable private _currentRow: any;
    @observable private _currentSubNavItem: number = 0;
    @observable didInitPatients = false;
    @observable isLoadingPatients = false;
    @observable loadingPatientsProgress = 0;
    userId: number = 0;
    userRoles: any;
    pageSize: number = 200;

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userId = tokenData.id;
        }
    }

    get origPatientData(): ProviderPatient[] {
        return this._basePatientData;
    }

    @action
    set origPatientData(value: any[]) {
        this._basePatientData = value;
    }

    get selectedVivosId(): string {
        return this._selectedVivosId;
    }

    @action
    set selectedVivosId(value: string) {
        this._selectedVivosId = value;
    }

    get currentRow(): any {
        return this._currentRow;
    }

    @action
    set currentRow(value: any) {
        this._currentRow = value;
    }
    get patientData(): ProviderPatient[] {
        return this._patientData;
    }

    @action
    set patientData(value: any[]) {
        this._patientData = value;
    }

    get firstPageLoaded(): boolean {
        return this._firstPageLoaded;
    }

    @action
    set firstPageLoaded(value: any) {
        this._firstPageLoaded = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    @action
    set currentPage(value: any) {
        this._currentPage = value;
    }

    get currentSubNavItem(): number {
        return this._currentSubNavItem;
    }

    @action
    set currentSubNavItem(value: any) {
        this._currentSubNavItem = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    @action
    set totalPages(value: any) {
        this._totalPages = value;
    }

    get resultCount() {
        return this._resultCount;
    }

    @action
    set resultCount(value: any) {
        this._resultCount = value;
    }

    get toggleShowPatientEditModal() {
        return this._toggleShowPatientEditModal;
    }

    @action
    set toggleShowPatientEditModal(value: any) {
        this._toggleShowPatientEditModal = value;
    }

    get isLeftNavExpanded() {
        return this._isLeftNavExpanded;
    }

    @action
    set isLeftNavExpanded(value: any) {
        this._isLeftNavExpanded = value;
    }

    get currentPatientId() {
        return this._currentPatientId;
    }

    @action
    set currentPatientId(value: any) {
        this._currentPatientId = value;
    }

    get currentPatient() {
        return this._currentPatient;
    }

    @action
    set currentPatient(value: any) {
        this._currentPatient = value;
    }

    get hasMore(): boolean {
        return this._hasMore;
    }

    @action
    set hasMore(value: any) {
        this._hasMore = value;
    }

    @action
    toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this._isLeftNavExpanded = isLeftNavExpanded;
    };

    @action
    toggleEditPatientModal = () => {
        runInAction(() => {
            this.toggleShowPatientEditModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    setCurrentPatientId = id => {
        runInAction(() => {
            this._currentPatientId = id;
        });
    };

    loadAll = async () => {
        runInAction(() => {
            this.didInitPatients = true;
            this.isLoadingPatients = true;
        });
        const patients: ProviderPatient[] = [];
        var url = '/patient/clinicaladvocate/' + this.userId + '/' + 0 + '/' + this.pageSize;
        const resp = await Gateway.get(url);
        const pages = resp['pageCount'];
        const totalPatients = resp['rowCount'];
        patients.push(...resp['results']);
        if (totalPatients !== 0) {
            runInAction(() => {
                this.loadingPatientsProgress = (patients.length / totalPatients) * 100;
            });
        }
        //this.getFilterNums();

        const promises: Promise<any[]>[] = [];
        let currentPage = 1;
        while (currentPage <= pages) {
            promises.push(
                Gateway.get(`/patient/clinicaladvocate/${this.userId}/${currentPage}/${this.pageSize}`).then(response => {
                    patients.push(...response['results']);
                    runInAction(() => {
                        this.loadingPatientsProgress = (patients.length / totalPatients) * 100;
                    });
                    return response;
                }),
            );
            currentPage++;
        }
        await Promise.all(promises);

        runInAction(() => {
            this.isLoadingPatients = false;
            this.patientData = patients;
            this.origPatientData = this.patientData;
            //this.setNumPatientValues();
        });
    };

    loadData = (): any => {
        this.loadAll();
        var url = 'patient/clinicaladvocate/' + this.userId + '/' + 0 + '/' + this.pageSize;

        Gateway.get(url).then(resp => {
            this.totalPages = resp['pageCount'];
            this.patientData = resp['results'];
            this.origPatientData = this.patientData;
            this.firstPageLoaded = true;
        });
    };

    @action
    loadPatient = () => {
        Gateway.get('/patient/' + this.currentPatientId).then(data => {
            this.currentPatient = data;
        });
    };

    @action
    currentRowToPerson = (currentRow: any) => {
        var person = new Patient({
            id: currentRow.id,
            orgId: currentRow.organizationId,
            providerId: currentRow.providerId,
            vivosId: currentRow.vivosId,
            firstName: currentRow.firstName,
            middleName: currentRow.middleName,
            lastName: currentRow.lastName,
            dateOfBirth: currentRow.dateOfBirth,
            ethnicityId: currentRow.ethnicityId,
            isProspect: currentRow.isProspect,
            isLincare: currentRow.isLincare,
            genderId: currentRow.genderId,
            ehrsId: currentRow.ehrsId,
            primaryPhone: currentRow.primaryPhone,
            altPhone: currentRow.altPhone,
            emailAddress: currentRow.emailAddress,
            treatmentStatusId: currentRow.treatmentStatusId,
        });
        return person;
    };

    @action
    updatePerson = (currentRow: any) => {
        var person = this.currentRowToPerson(currentRow);
        Gateway.post('/patient/person/' + person.id, person).then();
    };
}
