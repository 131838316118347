import {
    Box,
    Card,
    Typography,
    alpha,
    styled,
    useTheme
} from '@mui/material';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);


const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const BoxComposedImage = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      filter: grayscale(80%);
      background-size: cover;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const BoxComposedBg = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

function RxBlock2({ RxBlock2Content }) {
    const theme = useTheme();
    const {
        currentPatient,
    } = usePrescriptionStore().prescriptionStore;

    return (
        <Card
            sx={{
                p: 2
            }}
        >
            <BoxComposed
                sx={{
                    borderRadius: `${theme.general.borderRadius}`,
                    background: `${theme.colors.gradients.black2}`
                }}
            >
                <BoxComposedBg
                    sx={{
                        opacity: 0.1,
                        background: `${theme.colors.gradients.black1}`
                    }}
                />
                <BoxComposedContent py={3}>
                    <Typography
                        textAlign="center"
                        sx={{
                            pb: 1
                        }}
                        variant="h3"
                    >
                        Prescription Summary
                    </Typography>
                    <Typography textAlign="center" fontWeight="normal" variant="h4">
                        {currentPatient.firstName} {currentPatient.lastName}
                    </Typography>
                </BoxComposedContent>
            </BoxComposed>
            <Box
                py={2}
            // sx={{
            //     height: 493
            // }}
            >
                {RxBlock2Content}
            </Box>
        </Card>
    );
}

export default RxBlock2;
