// Vendors
import React, { useEffect } from "react";
import { Grid, IconButton, Link } from '@mui/material';
import CollectionsIcon from '@mui/icons-material/Collections';
import DownloadIcon from '@mui/icons-material/Download';

// Components
import UploadRotateCropper from "./UploadRotateCropper";
import DocUploaderGeneral from "./DocUploaderGeneral";

// Entities
import PatientImage from "../../entities/PatientImage";

// API
import { observer } from "mobx-react";
import { FileUploader } from "react-drag-drop-files";
import _ from "lodash";
import { GlobalUtils } from "../../api/GlobalUtils";



const fileTypes = ["JPG", "PNG", "PDF", "DOCX", "DOC", "PPT", "PPTX"];
const imageFileTypes = ["JPG", "PNG"];
interface IUploaderProps {
    type: string;
    typeOptions: any[];
    vivosId: string;
    containerId: number;
    parentContainerId: number;
    itemsToUpload: any[];
    parentCallback(data64string: string, dataPathUrl: string, imageType: any): any;

}

const UploadDragDrop: React.FC<IUploaderProps> =
    ({ parentCallback, typeOptions, type, vivosId, containerId, itemsToUpload, parentContainerId }) => {

        const [isUpload, setIsUpload] = React.useState(false);
        const [isPreview, setIsPreview] = React.useState(false);
        const [items, setItems] = React.useState<any[]>([]);
        const [options, setOptions] = React.useState<JSX.Element[]>([]);
        const [selectedOptions, setSelectedOptions] = React.useState<number[]>([]);
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [key, setKey] = React.useState(0);

        useEffect(() => {
            setOptions(typeOptions);
            if (itemsToUpload && itemsToUpload.length > 0) {
                _.forEach(itemsToUpload, (i, index) => {
                    i["extIndex"] = index;
                    if (i.imageType) {
                        selectedOptions.push(i.imageType.id);
                    }
                })
                setItems(itemsToUpload);
                setSelectedOptions(selectedOptions);
            }
            setHasLoaded(true);
        }, [])

        const convertBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file)
                fileReader.onload = () => {
                    resolve(fileReader.result);
                }
                fileReader.onerror = (error) => {
                    reject(error);
                }
            })
        }

        async function doWork(file, imglist) {
            var item = {} as PatientImage;
            var itemUrl = URL.createObjectURL(file);
            item.data64String = await convertBase64(file) as unknown as string;
            item.data64Url = decodeURI(itemUrl);
            item.pathUrl = decodeURI(file.name);
            item["new"] = true;
            item["extIndex"] = itemsToUpload.length;
            itemsToUpload.push(item);
        }

        const handleImageSet = (files) => {
            setIsPreview(false);
            var itemlist: PatientImage[] = items;
            var promises: any[] = [];
            _.forEach(files, async (file) => {
                const promise = doWork(file, itemlist);
                promises.push(promise);
            })
            Promise.all(promises).then(() => {
                setItems(itemlist.length > 0 ? itemlist : itemsToUpload);
                setIsPreview(true);
                setIsUpload(false);
            })
        };

        const handleRemove = (index: number) => {
            var item = items[index];
            var extImgIndex = item["extIndex"];
            if (extImgIndex) {
                itemsToUpload.splice(extImgIndex, 1);
            }
            items.splice(index, 1);
            setItems(items);
        }

        const handleOptionSelect = (event: any, oldOption: number, index: number) => {
            var selOpts = selectedOptions;
            _.remove(selOpts, (o) => { return o == oldOption });
            selOpts.push(event.target.value);
            setSelectedOptions(selOpts);
            var type = _.find(typeOptions, (t) => { return t.id == event.target.value });
            itemsToUpload[items[index]["extIndex"]].imageType = type;
            items[index].imageType = type;
            items[index]["edited"] = true;
            setKey(Math.floor(Math.random() * 42));
            if (!items[index].data64String) {
                //doImagesWork(index);
            }
            setItems(items);
            return selOpts;
        }

        const doImagesWork = async (index) => {
            await fetch(GlobalUtils.getVisitRecordUrl(items[index].pathUrl, vivosId, containerId)).then(r => {
                r.blob().then(async blobFile => {
                    await convertBase64(blobFile).then(resp => {
                        items[index].data64String = resp as unknown as string;
                    });
                });
            });
        }

        return (
            hasLoaded ?
                <Grid xs={12} className="formBasics">
                    <Grid xs={12} container direction="row" alignItems={"center"} justifyContent={"flext-start"} justifyItems="flex-start">
                        <Grid xs={4} item>
                            <FileUploader label="Upload or drop a image here" handleChange={handleImageSet} types={fileTypes}
                                multiple={true}
                                children={
                                    <Grid xs={12} container direction="column" className="uploadImgContainer">

                                        <Grid item><IconButton><CollectionsIcon className="uploadImg" /></IconButton></Grid>

                                        <Grid item>Drag & Drop or</Grid>
                                        <Grid item>Click to Select</Grid>
                                    </Grid >
                                } name="file" />
                        </Grid>
                        {
                            _.map(items, (item, i) => {
                                if (imageFileTypes.includes(item.pathUrl.split('.').pop())) {
                                    return <Grid container>
                                        <Grid xs={4} item >
                                            <UploadRotateCropper
                                                index={key + i}
                                                cropperIndex={i}
                                                parentCallback={parentCallback}
                                                handleRemove={() => { handleRemove(i) }}
                                                handleOptionSelect={handleOptionSelect}
                                                typeOptions={options}
                                                selectedOptions={selectedOptions}
                                                images={items}
                                                vivosId={vivosId}
                                                container={process.env.REACT_APP_REWORKCLAIMS_CONTAINER as string}
                                                containerId={containerId}
                                                parentContainerId={parentContainerId}
                                                setImages={() => { }}
                                            />
                                        </Grid>
                                        {!_.isEmpty(item.data64Url) && <Grid xs={2} item>
                                            <IconButton>
                                                <Link href={item.data64Url}>
                                                    <DownloadIcon />
                                                </Link>
                                            </IconButton>
                                        </Grid>}
                                    </Grid>
                                } else {
                                    return <Grid container><Grid xs={4} item >
                                        <DocUploaderGeneral
                                            file={item}
                                            fileName={item.pathUrl}
                                            index={key + i}
                                            docIndex={i}
                                            parentCallback={parentCallback}
                                            handleOptionSelect={handleOptionSelect}
                                            handleRemove={handleRemove}
                                            typeOptions={options}
                                            selectedOptions={selectedOptions}
                                            setFiles={setItems}
                                            files={items}
                                        />
                                    </Grid>
                                        {!_.isEmpty(item.data64Url) && <Grid xs={2} item>
                                            <IconButton>
                                                <Link href={item.data64Url}>
                                                    <DownloadIcon />
                                                </Link>
                                            </IconButton>
                                        </Grid>}
                                    </Grid>
                                }
                            })
                        }
                    </Grid>
                </Grid> : null
        )
    }

export default observer(UploadDragDrop);