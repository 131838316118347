import React, { useEffect, useState } from 'react';
import { Grid, Button, Box } from '@mui/material';

const RxTeethSelection = ({
    onTeethSelected,
    displayUpper,
    displayLower,
    displayUpperBabyTeeths = false,
    displayLowerBabyTeeths = false,
    ruleDisabledTeeth,
    patientDisabledTeeth,
    currentTeeth,
}: {
    onTeethSelected: (teeth: number[]) => void;
    displayUpper: boolean;
    displayLower: boolean;
    displayUpperBabyTeeths?: boolean;
    displayLowerBabyTeeths?: boolean;
    ruleDisabledTeeth: number[];
    patientDisabledTeeth: number[];
    currentTeeth: number[];
}) => {
    /*
    const babyUpperTeethMap = { 'A': 33, 'B': 34, 'C': 35, 'D': 36 };
    const babyLowerTeethMap = { 'J': 37, 'K': 38, 'L': 39, 'M': 40 };
    */
    const babyUpperTeethMap = { 'A': 33, 'B': 34, 'C': -1, 'D': -2, 'E': -3, 'F': -4, 'G': -5, 'H': -6,  'I': 41, 'J': 37 };
    const babyLowerTeethMap = { 'T': 42, 'S': 43, 'R': -1, 'Q':-2, 'P':-3,'O':-4,'N':-5,'M':-6, 'L': 39, 'K': 38 };


    const selectedBabyTeeths = {
        ...(displayUpperBabyTeeths ? babyUpperTeethMap : {}),
        ...(displayLowerBabyTeeths ? babyLowerTeethMap : {})
    };

    const [selectedTeeth, setSelectedTeeth] = useState<number[]>(currentTeeth);

    useEffect(() => {
        setSelectedTeeth(currentTeeth);
    }, [currentTeeth]);

    const isToothDisabled = (toothNumber: number) => {
        return (ruleDisabledTeeth.includes(toothNumber) || patientDisabledTeeth.includes(toothNumber));
    };

    const handleToothToggle = (toothNumber: number) => {
        if (ruleDisabledTeeth.includes(toothNumber) || patientDisabledTeeth.includes(toothNumber)) {
            return; // Do nothing if the tooth is disabled
        }

        setSelectedTeeth(prev => {
            const newSelection = prev.includes(toothNumber)
                ? prev.filter(num => num !== toothNumber)
                : [...prev, toothNumber];
            onTeethSelected(newSelection);
            return newSelection;
        });
    };

    const renderQuadrant = (start, end) => (
        Array.from({ length: end - start + 1 }, (_, i) => start + i).map(toothNumber => (
            <Button
                key={toothNumber}
                sx={{
                    width: '25px',
                    height: '25px',
                    minWidth: '25px', // Override the minimum width
                    padding: '0px', // Reduce padding
                    fontSize: '0.7rem' // Adjust font size
                }}
                variant={selectedTeeth.includes(toothNumber) ? 'contained' : 'outlined'}
                onClick={() => handleToothToggle(toothNumber)}
                disabled={isToothDisabled(toothNumber)}
            // size="small" // Smaller button size
            >
                {toothNumber}
            </Button>
        ))
    );
    const renderQuadrantReverse = (start, end) => (
        Array.from({ length: end - start + 1 }, (_, i) => end - i).map(toothNumber => (
            <Button
                key={toothNumber}
                sx={{
                    width: '25px',
                    height: '25px',
                    minWidth: '25px',
                    padding: '0px',
                    fontSize: '0.7rem'
                }}
                variant={selectedTeeth.includes(toothNumber) ? 'contained' : 'outlined'}
                onClick={() => handleToothToggle(toothNumber)}
                disabled={isToothDisabled(toothNumber)}
            >
                {toothNumber}
            </Button>
        ))
    );

    
    

    const renderBabyTeeth = () => (
        <Grid container justifyContent="center" spacing={1}>            
            {Object.entries(selectedBabyTeeths).map(([letter, toothNumber]) => (
                <Button
                    key={toothNumber}
                    sx={{
                        width: '25px',
                        height: '25px',
                        minWidth: '25px',
                        padding: '0px',
                        fontSize: '0.7rem',
                        mt: 1
                    }}
                    variant={selectedTeeth.includes(toothNumber) ? 'contained' : 'outlined'}
                    onClick={() => handleToothToggle(toothNumber)}
                    disabled={isToothDisabled(toothNumber) || toothNumber < 0}
                >
                    {letter}
                </Button>
            ))}
        </Grid>
    );

    return (
        <Grid container>
            {displayUpper &&
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Grid container justifyContent={'right'}>
                                {renderQuadrant(1, 8)}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                {renderQuadrant(9, 16)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {displayLower &&
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Grid container justifyContent={'right'}>
                                {renderQuadrantReverse(25, 32)}
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container >
                                {renderQuadrantReverse(17, 24)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {selectedBabyTeeths && Object.keys(selectedBabyTeeths).length > 0 && (
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container justifyContent="center" spacing={1}>
                        {renderBabyTeeth()}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default RxTeethSelection;
