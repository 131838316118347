import React, { useEffect, useState } from "react";
import { FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Box, Typography, CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import * as _ from 'lodash';
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";
import PatientVisit from "../../../entities/PatientVisit";

interface IGIProps {
    visit: PatientVisit | undefined;
}

export const PatientVitalsAndMeasurements: React.FC<IGIProps> = observer(
    ({ visit }) => {

        const UNIT_FORMAT_US = false;
        const UNIT_FORMAT_METRIC = true;

        const { heartbeats,
            bloodPressure,
            temperature,
            respirations,
            oxygenSaturation,
            height,
            weight,
            headCircumference,
            neckCircumference,
            waistCircumference,
            notes,
            unitOfMeasure,
            setHeartbeats,
            setBloodPressure,
            setTemperature,
            setRespirations,
            setOxygenSaturation,
            setHeight,
            setWeight,
            setHeadCircumference,
            setNeckCircumference,
            setWaistcircumference,
            setNotes,
            setUnitOfMeasure,
            setVisitId,
            setVitalsId,
            setVitalsPatientId } = useMainStoreContext().vitalsAndMeasurementsStore;

        const { patient } = useMainStoreContext().createPatientStore;
        const visitStore = useMainStoreContext().visitRecordStore;
        const { isLoading } = useMainStoreContext().visitRecordStore;
        const storeProvider = useMainStoreContext().providerStore;
        const { loadReportPropertiesData } = useMainStoreContext().intakeReportsStore;
        const [headNeckReportValues, setHeadNeckReportValues] = useState<any>([]);
        const [measurementsReportValues, setMeasurementsReportValues] = useState<any>([]);
        const [currentVisit, setCurrentVisit] = useState<any>(visit);


        useEffect(() => {
            if (!visit || visit?.patientVitalsAndMeasurements?.length === 0) {
                storeProvider.getProviderPreferences().then(() => {
                    setUnitOfMeasure(storeProvider.providerPreferences.unitType ? true : false);
                });
            }

            if (patient) {
                setVitalsPatientId(patient.id);
                setVisitId(visitStore.getVisitId());
                if (visit) {
                    loadVisitValues(visit);
                }
            }
            loadReportPropertiesData('headneck').then(headNeckResp => {
                setHeadNeckReportValues(headNeckResp);
                loadReportPropertiesData('measurements').then(measurementsResp => {
                    setMeasurementsReportValues(measurementsResp);
                })
            })
        }, [visitStore.currentVisit])

        const loadVisitValues = (visit): any => {
            if (visit.patientVitalsAndMeasurements && visit.patientVitalsAndMeasurements.length > 0) {
                setVitalsId(visit.patientVitalsAndMeasurements[0].id ? visit.patientVitalsAndMeasurements[0].id : 0)
                setHeartbeats(visit.patientVitalsAndMeasurements[0].heartbeats ? visit.patientVitalsAndMeasurements[0].heartbeats : 0);
                setBloodPressure(visit.patientVitalsAndMeasurements[0].bloodPressure ? visit.patientVitalsAndMeasurements[0].bloodPressure : '0');
                setTemperature(visit.patientVitalsAndMeasurements[0].temperature ? visit.patientVitalsAndMeasurements[0].temperature : 0);
                setRespirations(visit.patientVitalsAndMeasurements[0].respirations ? visit.patientVitalsAndMeasurements[0].respirations : 0);
                setOxygenSaturation(visit.patientVitalsAndMeasurements[0].oxygenSaturation ? visit.patientVitalsAndMeasurements[0].oxygenSaturation : 0);
                setHeight(visit.patientVitalsAndMeasurements[0].height ? visit.patientVitalsAndMeasurements[0].height : 0);
                setWeight(visit.patientVitalsAndMeasurements[0].weight ? visit.patientVitalsAndMeasurements[0].weight : 0);
                setHeadCircumference(visit.patientVitalsAndMeasurements[0].headCircumference ? visit.patientVitalsAndMeasurements[0].headCircumference : 0);
                setNeckCircumference(visit.patientVitalsAndMeasurements[0].neckCircumference ? visit.patientVitalsAndMeasurements[0].neckCircumference : 0);
                setWaistcircumference(visit.patientVitalsAndMeasurements[0].waistCircumference ? visit.patientVitalsAndMeasurements[0].waistCircumference : 0);
                setNotes(visit.patientVitalsAndMeasurements[0].notes ? visit.patientVitalsAndMeasurements[0].notes : '');
                setUnitOfMeasure(visit.patientVitalsAndMeasurements[0].unitOfMeasure);
            }
        };

        const headNeckColumns = [
            {
                field: 'id',
                headerName: 'ID',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.id}
                        </Grid>
                    )
                }
            },
            {
                field: 'propertyId',
                headerName: 'Property ID',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.propertyId}
                        </Grid>
                    )
                }
            },
            {
                field: 'propertyName',
                headerName: 'Property Name',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {headNeckReportValues.find(x => x.id === params.row.propertyId)?.name ?? ''}
                        </Grid>
                    )
                }
            },
            {
                field: 'propertyValue',
                headerName: 'Value',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.value}
                        </Grid>
                    )
                }
            }
        ]

        const measurementsColumns = [
            {
                field: 'id',
                headerName: 'ID',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.id}
                        </Grid>
                    )
                }
            },
            {
                field: 'propertyId',
                headerName: 'Property ID',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.propertyId}
                        </Grid>
                    )
                }
            },
            {
                field: 'propertyName',
                headerName: 'Property Name',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {measurementsReportValues.find(x => x.id === params.row.propertyId)?.name ?? ''}
                        </Grid>
                    )
                }
            },
            {
                field: 'propertyValue',
                headerName: 'Value',
                flex: 1,
                headerAlign: 'center',
                headerClassName: 'colHeader',
                align: 'center', renderCell: (params) => {
                    return (
                        <Grid>
                            {params.row.value}
                        </Grid>
                    )
                }
            }
        ]

        return (
            <Grid className="formBasics">
                {isLoading &&
                    <div style={{
                        // do your styles depending on your needs.
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                    }}>
                        {
                            <Grid container direction="row" justifyContent="center">
                                <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                                <CircularProgress sx={{
                                    width: "90%",
                                    height: "90%"
                                }} />
                            </Grid>
                        }
                    </div>
                }
                {!isLoading && <Grid container direction="row" paddingLeft={5} paddingRight={5} paddingBottom={10}>
                    <Grid item xs={12} lg={8}>
                        <Grid container paddingRight={1}>
                            <Grid item xs={12}
                                component="form"
                                noValidate width="100%">
                                <Grid container justifyContent={"center"}>
                                    <RadioGroup
                                        row
                                        defaultValue={unitOfMeasure}
                                        name="radio-buttons-measure"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setUnitOfMeasure(event.target.value.toLowerCase() === "true");
                                        }}
                                        value={unitOfMeasure}  >
                                        <Grid item><FormControlLabel sx={{ marginLeft: "10px" }} font-size='5px' value={UNIT_FORMAT_US} control={<Radio />} label="US" /></Grid>
                                        <Grid item><FormControlLabel sx={{ marginLeft: "10px" }} font-size='5px' value={UNIT_FORMAT_METRIC} control={<Radio />} label="Metric" /></Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between" spacing={1}>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Heart Beats</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">bpm</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setHeartbeats(parseInt(event.target.value));
                                                    }}
                                                    value={heartbeats}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Blood Pressure</Typography>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setBloodPressure(event.target.value);
                                                    }}
                                                    value={bloodPressure}
                                                />
                                            </Grid>
                                            {/* <Grid item xs={6} md={2} lg={2}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4" fontSize={14}>Blood Pressure</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setHeartbeats(parseInt(event.target.value));
                                                        }}
                                                        value={heartbeats}
                                                    />

                                                </Grid>
                                                <Grid xs={1} paddingTop={1}>
                                                    <Grid container justifyContent={'center'} alignContent={'center'}>
                                                        <Typography variant="h1">/</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={7}>
                                                    <TextField
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="start">mmHg</InputAdornment>,
                                                        }}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setBloodPressure(event.target.value);
                                                        }}
                                                        value={bloodPressure}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Temperature</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    defaultValue={unitOfMeasure === UNIT_FORMAT_US ? '98.6' : '35.5'}
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{unitOfMeasure === UNIT_FORMAT_US ? 'F' : 'C'}</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setTemperature(Number(event.target.value));
                                                    }}
                                                    value={temperature}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={2} >
                                                <Typography variant="h4" fontSize={14}>Respirations</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">/min</InputAdornment>
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setRespirations(parseInt(event.target.value));
                                                    }}
                                                    value={respirations}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Oxygen Saturation</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setOxygenSaturation(parseInt(event.target.value));
                                                    }}
                                                    value={oxygenSaturation}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between" spacing={1}>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Height</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{unitOfMeasure === UNIT_FORMAT_US ? 'in' : 'cm'}</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setHeight(Number(event.target.value));
                                                    }}
                                                    value={height} />
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Weight</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{unitOfMeasure === UNIT_FORMAT_US ? 'lbs' : 'kg'}</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setWeight(Number(event.target.value));
                                                    }}
                                                    value={weight} />
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Head Circumference</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{unitOfMeasure === UNIT_FORMAT_US ? 'in' : 'cm'}</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setHeadCircumference(Number(event.target.value));
                                                    }}
                                                    value={headCircumference} />
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Waist Circumference</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{unitOfMeasure === UNIT_FORMAT_US ? 'in' : 'cm'}</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setWaistcircumference(Number(event.target.value));
                                                    }}
                                                    value={waistCircumference} />
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography variant="h4" fontSize={14}>Neck Circumference</Typography>
                                                <TextField
                                                    type="number"
                                                    fullWidth
                                                    inputProps={{ step: ".1" }}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">{unitOfMeasure === UNIT_FORMAT_US ? 'in' : 'cm'}</InputAdornment>,
                                                    }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setNeckCircumference(Number(event.target.value));
                                                    }}
                                                    value={neckCircumference} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container paddingTop={2} alignContent={'center'} padding={2}>
                            <Box width={'100%'} alignContent={'center'} sx={{ mt: '40px' }}>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={5}
                                    label="Vitals & Measurement Notes"
                                    required={false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setNotes(event.target.value);
                                    }}
                                    value={notes} />
                            </Box>
                        </Grid>
                    </Grid>
                    {/* <Grid xs={12} padding={4}>
                    <Typography variant="h4" fontSize={14}>Head & Neck</Typography>
                    <DataGrid
                        columns={headNeckColumns}
                        data={currentVisit?.patientHeadNecks}
                        loading={isLoading}
                        size={"small"}
                        pageSize={10}
                    />
                </Grid> */}
                    {/* <Grid xs={12} padding={4}>
                    <Typography variant="h4" fontSize={14}>Measurements</Typography>
                    <DataGrid
                        columns={measurementsColumns}
                        data={currentVisit?.patientMeasurements}
                        loading={isLoading}
                        size={"small"}
                        pageSize={10}
                    />
                </Grid> */}
                </Grid>}
            </Grid>
        )
    }
);
