import { useContext } from "react";
import { createContext } from "react";
import { BaseMainStore } from "../base/MainStore";
import ShopifyStore from "../OldStores/ShopifyStore";
import VisitStore from "../OldStores/VisitStore";
import { AiReportOrderStore } from "../../components/AiReportOrder/AiReportOrderStore";
import { action, observable } from "mobx";
import { AddonsSetup } from "../../models/state_models/addonsSetup";
import { Gateway } from "../../api/Gateway";
import { AIReportSetup } from "../../models/state_models/aireportsetup";

export class CreateAIReportMainStore extends BaseMainStore {

    visitRecordStore: VisitStore;
    shopifyStore: ShopifyStore;
    aiReportOrder: AiReportOrderStore;

    @action
    getAvailableAddOns = async () => {
        try {
            const resAddons = await Gateway.getStrongType<AddonsSetup[]>("Global/Eccomerce/Aireport/Addon");
            const restAIReport = await Gateway.getStrongType<AIReportSetup[]>("Global/Eccomerce/Aireport");
            this.aiReportOrder.availableAddOns = resAddons;
            this.aiReportOrder.aiReportSetup = restAIReport;

            // Fetch all products from ShopifyStore if not already fetched
            if (this.shopifyStore.client && (!this.shopifyStore.products || this.shopifyStore.products.length === 0)) {
                const products = await this.shopifyStore.fetchProducts();
                this.shopifyStore.setProducts(products);
            }

            // Map available add-ons to Shopify products
            this.aiReportOrder.availableAddOns.forEach(addon => {
                const matchingProduct = this.shopifyStore.products.find(product => product.id.includes(addon.ecommerceItem.ecommerceId));
                if (matchingProduct) {
                    addon.shopifyProduct = matchingProduct;
                }
            });

            // Map available AI reports to Shopify products
            this.aiReportOrder.aiReportSetup.forEach(aiReport => {
                const matchingProduct = this.shopifyStore.products.find(product => product.id.includes(aiReport.ecommerceItem.ecommerceId));
                if (matchingProduct) {
                    aiReport.shopifyProduct = matchingProduct;
                }
            });
        } catch (error) {
            console.error("Failed to load addons or products", error);
        }
    }

    

    constructor() {
        super();
        this.visitRecordStore = new VisitStore(this);
        this.shopifyStore = new ShopifyStore(this);
        this.aiReportOrder = new AiReportOrderStore(this);
        
    }


    resetStores = () => {
        this.visitRecordStore = new VisitStore(this);
        this.shopifyStore = new ShopifyStore(this);
        this.aiReportOrder = new AiReportOrderStore(this);
    }
}


export const store = new CreateAIReportMainStore();
export const MainStoreContext = createContext<CreateAIReportMainStore>(store);
export const useCreateAIReportMainStore = () => {
    return useContext(MainStoreContext);
};