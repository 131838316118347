import _ from "lodash";
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { observer } from "mobx-react";
import * as React from 'react';
import { Gateway } from '../../api/Gateway';
import ReactQuill from "react-quill"
import {
  Grid,
  Typography,
  TextField
} from "@mui/material";
import MyoGradingComponent from "./NoteComponents.tsx/MyoGradingComponent";
import TongueGradingComponent from "./NoteComponents.tsx/TongueGradingComponent";
import MuscleAssessmentComponent from "./NoteComponents.tsx/MuscleAssessment";
import ButtonGroupingOptions from "./NoteComponents.tsx/ButtonGroupingOptions";
import OralHabitDetailsComponent from "./NoteComponents.tsx/OralHabitDetails";



const MyoFunctionalEvaluation = (props) => {
  const { currentAppointmentNote, prevSessionUrl, setCurrentAppointmentNoteProp, setCurrentAppointmentNotePropCheckbox, currentSession, initialSessionId, currentPatientId, idChiefComplaint } = useMainStoreContext().createAppointmentNotesStore;
  const [dataInitialEvaluation, setDataInitialEvaluation] = React.useState('');

  React.useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {

    var url = "/questionaire/response/" + initialSessionId + "/" + currentPatientId + "/" + idChiefComplaint;

    Gateway.get(url)
      .then((respData) => {
        setDataInitialEvaluation(respData['response']);

      })
  }

  const handleChangeEditor = async (editor, propertyToUpdate: string) => {
    let newValue = editor.getText();
    let oldValue = currentAppointmentNote['assessmentnotes']?.response
    if (newValue !== oldValue) {
      setCurrentAppointmentNoteProp(newValue, propertyToUpdate)
    }
  };

  return (
    <Grid container >
      <Grid container marginBottom={"25px"} paddingLeft={4} paddingRight={4} columnSpacing={0} >
        <Grid item xs={12} mt={1}>
          <Typography variant="h3">MyoFunctional Therapy Evaluation</Typography>
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <MyoGradingComponent
            valueString='breathing'
            title="Breathing"
            valueNoteString="breathingnotes"
            includeNotes={true}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "Quiet Nasal Breathing. breathing 2 minutes or more",
              "Mouth/Nose Breathing. Nose breathing 1-2 minutes",
              "Mouth breathing"
            ]}
            buttonColors={['success', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <MyoGradingComponent
            valueString='lip'
            title="Lip Frenulum"
            valueNoteString="lipnotes"
            includeNotes={true}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "No Lip frenectomy",
              "Re-evaluate the need for a lip frenectomy in 6-8 sessions",
              "Lip frenectomy needed"
            ]}
            buttonColors={['success', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <MyoGradingComponent
            valueString='buccal'
            title="Buccal Frenulum"
            valueNoteString="buccalnotes"
            includeNotes={true}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "No Buccal frenectomy",
              "Re-evaluate the need for Buccal frenectomy in 6-8 sessions",
              "Buccal frenectomy needed"
            ]}
            buttonColors={['success', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <MyoGradingComponent
            valueString='frenulum'
            title="Lingual Frenulum"
            valueNoteString="frenulumnotes"
            includeNotes={true}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "No Lingual frenectomy",
              "Re-evaluate the need for Lingual frenectomy in 6-8 sessions",
              "Lingual frenectomy needed"
            ]}
            buttonColors={['success', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <TongueGradingComponent
            valueString='tongue'
            title="Tongue"
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <MuscleAssessmentComponent
            title="Muscle Assessment"
            optionTitles={[
              'Posture', 'Function', 'Mobility',
              'Posture', 'Function', 'Mobility',
              'Posture', 'Function', 'Mobility'
            ]}
            valueStringTitles={['Tongue', 'Lip', 'Jaw']}
            valueStringOptions={[
              'tongueposture', 'tonguefunction', 'tonguemobility',
              'lipposture', 'lipfunction', 'lipmobility',
              'jawposture', 'jawfunction', 'jawmobility'
            ]}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
          />
        </Grid>
        <Grid item xs={12} lg={4} padding={2}>
          <MyoGradingComponent
            valueString='swallowingpattern'
            title="Swallowing Pattern"
            valueNoteString=""
            includeNotes={false}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "Normal",
              "Atypical Swallow",
              "Anterior Tongue Thrust",
              "Bi-Lateral Tongue Thrust"
            ]}
            buttonColors={['success', 'warning', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={4} padding={2}>
          <MyoGradingComponent
            valueString='chewingpattern'
            title="Chewing Pattern"
            valueNoteString=""
            includeNotes={false}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "Normal",
              "Favor one side for chewing",
              "Open mouth posture for chewing",
            ]}
            buttonColors={['success', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={4} padding={2}>
          <MyoGradingComponent
            valueString='speech'
            title="Speech"
            valueNoteString=""
            includeNotes={false}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            optionStrings={[
              "Normal",
              "Altered (slight misplacement of tongue)",
              "Severe Misarticulation",
            ]}
            buttonColors={['success', 'warning', 'error']}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <MuscleAssessmentComponent
            title="Head and Face Assessment"
            optionTitles={[
              'Facial Symmetry', 'Head Posture',
            ]}
            valueStringTitles={['General']}
            valueStringOptions={[
              'facialsymmetry', 'headposture',

            ]}
            currentAppointmentNote={currentAppointmentNote}
            setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
          />
        </Grid>
        <Grid item xs={12} lg={6} padding={2}>
          <Grid container direction={'column'} pl={2} >
            <ButtonGroupingOptions
              title="Oral Habit"
              options={[
                'Finger Sucking',
                'Pacifier',
                'Sucking on Lips',
                'Lip Biting',
                'Nail Biting'
              ]}
              values={[
                'fingersucking',
                'pacifer',
                'suckingonlips',
                'lipbiting',
                'nailbiting'
              ]}
              currentAppointmentNote={currentAppointmentNote}
              setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
            />
            <Grid m={1} spacing={2} direction={'column'}>
              {parseInt(currentAppointmentNote['fingersucking']?.response) === 1 &&
                <OralHabitDetailsComponent
                  title="Finger Sucking Details:"
                  valueStrings={['fingersuckingoften', 'fingersuckinglong']}
                  currentAppointmentNote={currentAppointmentNote}
                  setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                />
              }
              {parseInt(currentAppointmentNote['pacifier']?.response) === 1 &&
                <OralHabitDetailsComponent
                  title="Pacifier Details:"
                  valueStrings={['paciferoften', 'paciferlong']}
                  currentAppointmentNote={currentAppointmentNote}
                  setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                />
              }
              {parseInt(currentAppointmentNote['suckingonlips']?.response) === 1 &&
                <OralHabitDetailsComponent
                  title="Sucking on Lips Details:"
                  valueStrings={['suckingonlipsoften', 'suckingonlipslong']}
                  currentAppointmentNote={currentAppointmentNote}
                  setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                />
              }
              {parseInt(currentAppointmentNote['lipbiting']?.response) === 1 &&
                <OralHabitDetailsComponent
                  title="Lip Biting Details:"
                  valueStrings={['lipbitingoften', 'lipbitinglong']}
                  currentAppointmentNote={currentAppointmentNote}
                  setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                />
              }
              {parseInt(currentAppointmentNote['nailbiting']?.response) === 1 &&
                <OralHabitDetailsComponent
                  title="Nail Biting Details:"
                  valueStrings={['nailbitingoften', 'nailbitinglong']}
                  currentAppointmentNote={currentAppointmentNote}
                  setCurrentAppointmentNoteProp={setCurrentAppointmentNoteProp}
                />
              }

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="h3">MyoFunctional Therapy SOAP Notes</Typography>
        </Grid>
        <Grid item xs={12} padding={2}>
          <Grid className="subheader" >
            {(!_.isNull(prevSessionUrl) && prevSessionUrl != "") && <a href={prevSessionUrl} target="_blank" rel="noopener noreferrer">Previous Appointment Remarks</a>}
            {(_.isNull(prevSessionUrl) || prevSessionUrl == "") && <Typography variant="h4">Previous Appointment Remarks</Typography>}
          </Grid>
          <TextField
            required={false}
            fullWidth
            multiline
            minRows="3"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentAppointmentNoteProp(event.target.value, 'previoussessionremarks');
            }}
            value={currentAppointmentNote['previoussessionremarks']?.response}
            inputProps={{
              style: {
                color: currentAppointmentNote['previoussessionremarks']?.markAsEdited ? 'red' : 'Black',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} padding={2}>
          <Typography variant="h4">Subjective Notes</Typography>
          <ReactQuill
            theme='snow'
            style={{ height: '200px', marginBottom: '25px', width: '100%' }}
            onBlur={(content, delta, editor) => handleChangeEditor(editor, "subjectivenotes")}

            value={currentAppointmentNote['subjectivenotes']?.response || ''}
          />
        </Grid>
        <Grid item xs={12} padding={2}>
          <Typography variant="h4">Objective Notes</Typography>
          <ReactQuill
            theme='snow'
            style={{ height: '200px', marginBottom: '25px' }}
            onBlur={(content, delta, editor) => handleChangeEditor(editor, "objectivenotes")}

            value={currentAppointmentNote['objectivenotes']?.response || ''}
          />
        </Grid>
        <Grid item xs={12} padding={2}>
          <Typography variant="h4">Assessment Notes</Typography>
          <ReactQuill
            theme='snow'
            style={{ height: '200px', marginBottom: '25px' }}
            onBlur={(content, delta, editor) => handleChangeEditor(editor, "assessmentnotes")}
            value={currentAppointmentNote['assessmentnotes']?.response || ''}
          />
        </Grid>
        <Grid item xs={12} padding={2}>
          <Typography variant="h4">Treatment Goals</Typography>
          <ReactQuill
            theme='snow'
            style={{ height: '200px', marginBottom: '25px' }}
            onBlur={(content, delta, editor) => handleChangeEditor(editor, "treatmentgoals")}

            value={currentAppointmentNote['treatmentgoals']?.response || ''}
          />
        </Grid>
      </Grid>


      {/* OLD CODE HERE FOR REFERENCE: */}
      {/* <Grid item xs={6}>
          <Box sx={{ width: '90%' }}>
            <Item>
              <Grid container direction={"row"} className="subheader" ><Grid>Breathing</Grid></Grid>
              <RadioGroup
                row
                aria-labelledby="Breathing-group-label"
                name="radio-buttons-Breathing"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'breathing');
                }}
                value={parseInt(currentAppointmentNote['breathing']?.response)}  >
                <Grid container direction="column">
                  <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['breathing']?.markAsEdited && currentAppointmentNote['breathing']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>Quiet Nasal Breathing. breathing 2 minutes or more</Grid></Grid>
                  <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['breathing']?.markAsEdited && currentAppointmentNote['breathing']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Mouth/Nose Breathing. Nose breathing 1-2 minutes</Grid></Grid>
                  <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['breathing']?.markAsEdited && currentAppointmentNote['breathing']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Mouth breathing</Grid></Grid>
                </Grid>
              </RadioGroup>
              <FormControl sx={{ width: '90%' }} >
                <Item>
                  <Grid container direction={"row"} className="formLabel" id="aireid"><Grid>Breathing Notes</Grid></Grid>
                  <ReactQuill
                    theme='snow'
                    style={{ height: '200px', marginBottom: '25px' }}
                    onBlur={(content, delta, editor) => handleChangeEditor(editor, "breathingNotes")}

                    value={currentAppointmentNote['breathingNotes']?.response || ''}
                  />
                </Item>
              </FormControl>
            </Item>
          </Box>
        </Grid> */}
      {/* <Grid item xs={6}>
          <Box sx={{ width: '90%' }}>
            <Item>
              <Grid container direction={"row"} className="subheader" ><Grid>Lip Frenulum</Grid></Grid>
              <RadioGroup
                row
                aria-labelledby="Lip-group-label"
                defaultValue="0"
                name="radio-buttons-Lip"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'lip');
                }}
                value={parseInt(currentAppointmentNote['lip']?.response)}  >
                <Grid container direction="column">
                  <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['lip']?.markAsEdited && currentAppointmentNote['lip']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>No Lip frenectomy</Grid></Grid>
                  <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['lip']?.markAsEdited && currentAppointmentNote['lip']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Re-evaluate the need for a lip frenectomy in 6-8 sessions</Grid></Grid>
                  <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['lip']?.markAsEdited && currentAppointmentNote['lip']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Lip frenectomy needed</Grid></Grid>
                </Grid>
              </RadioGroup>
              <FormControl sx={{ width: '90%' }} >
                <Item>
                  <Grid container direction={"row"} className="formLabel" id="aireid"><Grid>Lip Notes</Grid></Grid>
                  <ReactQuill
                    theme='snow'
                    style={{ height: '200px', marginBottom: '25px' }}
                    onBlur={(content, delta, editor) => handleChangeEditor(editor, "lipNotes")}

                    value={currentAppointmentNote['lipNotes']?.response || ''}
                  />
                </Item>
              </FormControl>
            </Item>
          </Box>
        </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid item xs={6}>
          <Box sx={{ width: '90%' }}>
            <Item>
              <Grid container direction={"row"} className="subheader" ><Grid>Buccal Frenulum</Grid></Grid>
              <RadioGroup
                row
                aria-labelledby="Bucal-group-label"
                defaultValue="0"
                name="radio-buttons-Bucal"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'buccal');
                }}
                value={parseInt(currentAppointmentNote['buccal']?.response)}  >
                <Grid container direction="column">
                  <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['buccal']?.markAsEdited && currentAppointmentNote['buccal']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>No Bucal frenectomy</Grid></Grid>
                  <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['buccal']?.markAsEdited && currentAppointmentNote['buccal']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Re-evaluate the need for Bucal frenectomy in 6-8 sessions</Grid></Grid>
                  <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['buccal']?.markAsEdited && currentAppointmentNote['buccal']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Bucal frenectomy needed</Grid></Grid>
                </Grid>
              </RadioGroup>
              <FormControl sx={{ width: '90%' }} >
                <Item>
                  <Grid container direction={"row"} className="formLabel" id="aireid"><Grid>Bucal Notes</Grid></Grid>
                  <ReactQuill
                    theme='snow'
                    style={{ height: '200px', marginBottom: '25px' }}
                    onBlur={(content, delta, editor) => handleChangeEditor(editor, "buccalNotes")}

                    value={currentAppointmentNote['buccalNotes']?.response || ''}
                  />

                </Item>
              </FormControl>
            </Item>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ width: '90%' }} >
            <Item>
              <Grid container direction={"row"} className="subheader" ><Grid>Lingual Frenulum</Grid></Grid>
              <RadioGroup
                row
                aria-labelledby="Frenulum-group-label"
                defaultValue="0"
                name="radio-buttons-Frenulum"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentAppointmentNoteProp(event.target.value.toString(), 'frenulum');
                }}
                value={parseInt(currentAppointmentNote['frenulum']?.response)}  >
                <Grid container direction="column">
                  <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['frenulum']?.markAsEdited && currentAppointmentNote['frenulum']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>No Lingual frenectomy</Grid></Grid>
                  <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['frenulum']?.markAsEdited && currentAppointmentNote['frenulum']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Re-evaluate the need for Lingual frenectomy in 6-8 sessions</Grid></Grid>
                  <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['frenulum']?.markAsEdited && currentAppointmentNote['frenulum']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Lingual frenectomy needed</Grid></Grid>
                </Grid>
              </RadioGroup>
              <FormControl sx={{ width: '90%' }} >
                <Item>
                  <Grid container direction={"row"} className="formLabel" id="aireid"><Grid>Fremium Notes</Grid></Grid>
                  <ReactQuill
                    theme='snow'
                    style={{ height: '200px', marginBottom: '25px' }}
                    onBlur={(content, delta, editor) => handleChangeEditor(editor, "frenulumNotes")}

                    value={currentAppointmentNote['frenulumNotes']?.response || ''}
                  />
                </Item>
              </FormControl>
            </Item>
          </Box>
        </Grid>
      </Grid> */}
      {/* <Grid container xs={12} marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <FormControl sx={{ width: '90%' }} >
          <Item>
            <Grid container direction={"row"} className="subheader" ><Grid>Tongue</Grid></Grid>
            <RadioGroup
              row
              aria-labelledby="Tongue-group-label"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentAppointmentNoteProp(event.target.value.toString(), 'tongue');
              }}
              value={parseInt(currentAppointmentNote['tongue']?.response)}
              name="radio-buttons-Tongue">
              <Grid container direction="row"
                className="tongueList">
                <Grid xs={2}><Grid className={currentAppointmentNote['tongue']?.markAsEdited && currentAppointmentNote['tongue']?.response === '0' ? "redTextNotes" : ""}><Radio value="0" />Level 0</Grid><Grid><img height="107" width="69" src={tongue0} /></Grid></Grid>
                <Grid xs={2}><Grid className={currentAppointmentNote['tongue']?.markAsEdited && currentAppointmentNote['tongue']?.response === '1' ? "redTextNotes" : ""}><Radio value="1" />Level 1</Grid><Grid><img height="107" width="69" src={tongue1} /></Grid></Grid>
                <Grid xs={2}><Grid className={currentAppointmentNote['tongue']?.markAsEdited && currentAppointmentNote['tongue']?.response === '2' ? "redTextNotes" : ""}><Radio value="2" />Level 2</Grid><Grid><img height="107" width="69" src={tongue2} /></Grid></Grid>
                <Grid xs={2}><Grid className={currentAppointmentNote['tongue']?.markAsEdited && currentAppointmentNote['tongue']?.response === '3' ? "redTextNotes" : ""}><Radio value="3" />Level 3</Grid><Grid><img height="107" width="69" src={tongue3} /></Grid></Grid>
                <Grid xs={2}><Grid className={currentAppointmentNote['tongue']?.markAsEdited && currentAppointmentNote['tongue']?.response === '4' ? "redTextNotes" : ""}><Radio value="4" />Level 4</Grid><Grid><img height="107" width="69" src={tongue4} /></Grid></Grid>
                <Grid xs={2}><Grid className={currentAppointmentNote['tongue']?.markAsEdited && currentAppointmentNote['tongue']?.response === '5' ? "redTextNotes" : ""}><Radio value="5" />Level 5</Grid><Grid><img height="107" width="69" src={tongue5} /></Grid></Grid>
              </Grid>
            </RadioGroup>
          </Item>
        </FormControl>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Box sx={{ width: '90%' }}>
          <Item>
            <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Muscle Assessment</Grid></Grid>
            <Grid xs={12} container direction="row">
              <Grid xs={9}>
                <Grid>
                  <Grid xs={12}>Tongue:</Grid>
                  <Grid xs={12} sx={{ paddingLeft: "15px" }} container direction="row">
                    <Grid xs={4}>
                      <Grid>Posture</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'tonguePosture');
                          }}
                          value={parseInt(currentAppointmentNote['tonguePosture']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['tonguePosture']?.markAsEdited && currentAppointmentNote['tonguePosture']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['tonguePosture']?.markAsEdited && currentAppointmentNote['tonguePosture']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['tonguePosture']?.markAsEdited && currentAppointmentNote['tonguePosture']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['tonguePosture']?.markAsEdited && currentAppointmentNote['tonguePosture']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid>Function</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'tongueFunction');
                          }}
                          value={parseInt(currentAppointmentNote['tongueFunction']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['tongueFunction']?.markAsEdited && currentAppointmentNote['tongueFunction']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['tongueFunction']?.markAsEdited && currentAppointmentNote['tongueFunction']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['tongueFunction']?.markAsEdited && currentAppointmentNote['tongueFunction']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['tongueFunction']?.markAsEdited && currentAppointmentNote['tongueFunction']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid>Mobility</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'tongueMobility');
                          }}
                          value={parseInt(currentAppointmentNote['tongueMobility']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['tongueMobility']?.markAsEdited && currentAppointmentNote['tongueMobility']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['tongueMobility']?.markAsEdited && currentAppointmentNote['tongueMobility']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['tongueMobility']?.markAsEdited && currentAppointmentNote['tongueMobility']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['tongueMobility']?.markAsEdited && currentAppointmentNote['tongueMobility']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid xs={12}>Lip:</Grid>
                  <Grid xs={12} sx={{ paddingLeft: "15px" }} container direction="row">
                    <Grid xs={4}>
                      <Grid>Posture</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'lipPosture');
                          }}
                          value={parseInt(currentAppointmentNote['lipPosture']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['lipPosture']?.markAsEdited && currentAppointmentNote['lipPosture']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['lipPosture']?.markAsEdited && currentAppointmentNote['lipPosture']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['lipPosture']?.markAsEdited && currentAppointmentNote['lipPosture']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['lipPosture']?.markAsEdited && currentAppointmentNote['lipPosture']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid>Function</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'lipFunction');
                          }}
                          value={parseInt(currentAppointmentNote['lipFunction']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['lipFunction']?.markAsEdited && currentAppointmentNote['lipFunction']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['lipFunction']?.markAsEdited && currentAppointmentNote['lipFunction']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['lipFunction']?.markAsEdited && currentAppointmentNote['lipFunction']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['lipFunction']?.markAsEdited && currentAppointmentNote['lipFunction']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid>Mobility</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'lipMobility');
                          }}
                          value={parseInt(currentAppointmentNote['lipMobility']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['lipMobility']?.markAsEdited && currentAppointmentNote['lipMobility']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['lipMobility']?.markAsEdited && currentAppointmentNote['lipMobility']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['lipMobility']?.markAsEdited && currentAppointmentNote['lipMobility']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['lipMobility']?.markAsEdited && currentAppointmentNote['lipMobility']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid xs={12}>Jaw:</Grid>
                  <Grid xs={12} sx={{ paddingLeft: "15px" }} container direction="row">
                    <Grid xs={4}>
                      <Grid>Posture</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'jawPosture');
                          }}
                          value={parseInt(currentAppointmentNote['jawPosture']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['jawPosture']?.markAsEdited && currentAppointmentNote['jawPosture']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['jawPosture']?.markAsEdited && currentAppointmentNote['jawPosture']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['jawPosture']?.markAsEdited && currentAppointmentNote['jawPosture']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['jawPosture']?.markAsEdited && currentAppointmentNote['jawPosture']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid>Function</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'jawFunction');
                          }}
                          value={parseInt(currentAppointmentNote['jawFunction']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['jawFunction']?.markAsEdited && currentAppointmentNote['jawFunction']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['jawFunction']?.markAsEdited && currentAppointmentNote['jawFunction']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['jawFunction']?.markAsEdited && currentAppointmentNote['jawFunction']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['jawFunction']?.markAsEdited && currentAppointmentNote['jawFunction']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid xs={4}>
                      <Grid>Mobility</Grid>
                      <Grid>
                        <RadioGroup
                          defaultValue="0"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNoteProp(event.target.value.toString(), 'jawMobility');
                          }}
                          value={parseInt(currentAppointmentNote['jawMobility']?.response)}
                          name="radio-buttons-Tongue">
                          <Grid container direction="row">
                            <Grid className="green"><Radio className={currentAppointmentNote['jawMobility']?.markAsEdited && currentAppointmentNote['jawMobility']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                            <Grid className="orange"><Radio className={currentAppointmentNote['jawMobility']?.markAsEdited && currentAppointmentNote['jawMobility']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                            <Grid className="pink"><Radio className={currentAppointmentNote['jawMobility']?.markAsEdited && currentAppointmentNote['jawMobility']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                            <Grid className="red"><Radio className={currentAppointmentNote['jawMobility']?.markAsEdited && currentAppointmentNote['jawMobility']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={3}>
                  <Grid>
                    <Grid xs={12}>Posture</Grid>
                    <Grid>Resting postion and appearance</Grid>
                  </Grid>
                  <Grid>
                    <Grid xs={12}>Functions</Grid>
                    <Grid>Swallowing Dysfunction is present whe compensatory muscles are active.  In some cases head movement will be present or not.</Grid>
                  </Grid>
                  <Grid>
                    <Grid xs={12}>Mobility</Grid>
                    <Grid>Dysfunction or alteration were considerd to be present when lack of precision in the movement, tremor, associated movements of other components (e.g., lips accompanying the movements of the tongue) and inability to perform the movement were. </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Item>
        </Box>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid xs={4}>
          <FormControl sx={{ width: '90%' }} >
            <Item>
              <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Swallowing Pattern</Grid></Grid>
              <Grid>
                <RadioGroup
                  defaultValue="0"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentAppointmentNoteProp(event.target.value.toString(), 'swallowingPattern');
                  }}
                  value={parseInt(currentAppointmentNote['swallowingPattern']?.response)}
                  name="radio-buttons-Tongue">
                  <Grid container direction="column">
                    <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['swallowingPattern']?.markAsEdited && currentAppointmentNote['swallowingPattern']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>Normal</Grid></Grid>
                    <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['swallowingPattern']?.markAsEdited && currentAppointmentNote['swallowingPattern']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Atypical Swallow</Grid></Grid>
                    <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['swallowingPattern']?.markAsEdited && currentAppointmentNote['swallowingPattern']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Anterior Tongue Thrust</Grid></Grid>
                    <Grid container direction="row" className="notesRow blue"><Grid xs={2} className="noteRdo"><Radio value="3" /></Grid><Grid xs={10} className={currentAppointmentNote['swallowingPattern']?.markAsEdited && currentAppointmentNote['swallowingPattern']?.response === '3' ? "noteLabel redTextNotes" : "noteLabel"}>Bi-Lateral Tongue Thrust</Grid></Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Item>
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl sx={{ width: '90%' }} >
            <Item>
              <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Chewing Pattern</Grid></Grid>
              <Grid>
                <RadioGroup
                  defaultValue="0"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentAppointmentNoteProp(event.target.value.toString(), 'chewingPattern');
                  }}
                  value={parseInt(currentAppointmentNote['chewingPattern']?.response)}
                  name="radio-buttons-Tongue">
                  <Grid container direction="column">
                    <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['chewingPattern']?.markAsEdited && currentAppointmentNote['chewingPattern']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>Normal</Grid></Grid>
                    <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['chewingPattern']?.markAsEdited && currentAppointmentNote['chewingPattern']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Favor one side for chewing</Grid></Grid>
                    <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['chewingPattern']?.markAsEdited && currentAppointmentNote['chewingPattern']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Open mouth posture for chewing</Grid></Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Item>
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl sx={{ width: '90%' }} >
            <Item>
              <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Speech</Grid></Grid>
              <Grid>
                <RadioGroup
                  defaultValue="0"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCurrentAppointmentNoteProp(event.target.value.toString(), 'speech');
                  }}
                  value={parseInt(currentAppointmentNote['speech']?.response)}
                  name="radio-buttons-Tongue">
                  <Grid container direction="column">
                    <Grid container direction="row" className="notesRow green"><Grid xs={2} className="noteRdo"><Radio value="0" /></Grid><Grid xs={10} className={currentAppointmentNote['speech']?.markAsEdited && currentAppointmentNote['speech']?.response === '0' ? "noteLabel redTextNotes" : "noteLabel"}>Normal</Grid></Grid>
                    <Grid container direction="row" className="notesRow orange"><Grid xs={2} className="noteRdo"><Radio value="1" /></Grid><Grid xs={10} className={currentAppointmentNote['speech']?.markAsEdited && currentAppointmentNote['speech']?.response === '1' ? "noteLabel redTextNotes" : "noteLabel"}>Altered (slight misplacement of tongue)</Grid></Grid>
                    <Grid container direction="row" className="notesRow pink"><Grid xs={2} className="noteRdo"><Radio value="2" /></Grid><Grid xs={10} className={currentAppointmentNote['speech']?.markAsEdited && currentAppointmentNote['speech']?.response === '2' ? "noteLabel redTextNotes" : "noteLabel"}>Severe Misarticulation</Grid></Grid>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Item>
          </FormControl>
        </Grid>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid xs={3}>
          <Grid>
            <FormControl sx={{ width: '90%' }} >
              <Item>
                <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Facial Symmetry</Grid></Grid>
                <Grid>
                  <RadioGroup
                    defaultValue="0"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCurrentAppointmentNoteProp(event.target.value.toString(), 'facialSymmetry');
                    }}
                    value={parseInt(currentAppointmentNote['facialSymmetry']?.response)}
                    name="radio-buttons-Tongue">
                    <Grid container direction="row">
                      <Grid className="green"><Radio className={currentAppointmentNote['facialSymmetry']?.markAsEdited && currentAppointmentNote['facialSymmetry']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                      <Grid className="orange"><Radio className={currentAppointmentNote['facialSymmetry']?.markAsEdited && currentAppointmentNote['facialSymmetry']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                      <Grid className="pink"><Radio className={currentAppointmentNote['facialSymmetry']?.markAsEdited && currentAppointmentNote['facialSymmetry']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                      <Grid className="red"><Radio className={currentAppointmentNote['facialSymmetry']?.markAsEdited && currentAppointmentNote['facialSymmetry']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Item>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl sx={{ width: '90%' }} >
              <Item>
                <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Head Posture</Grid></Grid>
                <Grid>
                  <RadioGroup
                    defaultValue="0"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setCurrentAppointmentNoteProp(event.target.value.toString(), 'headPosture');
                    }}
                    value={parseInt(currentAppointmentNote['headPosture']?.response)}
                    name="radio-buttons-Tongue">
                    <Grid container direction="row">
                      <Grid className="green"><Radio className={currentAppointmentNote['headPosture']?.markAsEdited && currentAppointmentNote['headPosture']?.response === '0' ? "redTextNotes" : ""} value="0" /></Grid>
                      <Grid className="orange"><Radio className={currentAppointmentNote['headPosture']?.markAsEdited && currentAppointmentNote['headPosture']?.response === '1' ? "redTextNotes" : ""} value="1" /></Grid>
                      <Grid className="pink"><Radio className={currentAppointmentNote['headPosture']?.markAsEdited && currentAppointmentNote['headPosture']?.response === '2' ? "redTextNotes" : ""} value="2" /></Grid>
                      <Grid className="red"><Radio className={currentAppointmentNote['headPosture']?.markAsEdited && currentAppointmentNote['headPosture']?.response === '3' ? "redTextNotes" : ""} value="3" /></Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Item>
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={9}>
          <Box sx={{ width: '90%' }}>
            <Item>
              <Grid xs={12} container direction={"row"} className="subheader" ><Grid>Oral Habit</Grid></Grid>
              <Grid>
                <FormGroup>
                  <Grid container direction="row" alignContent="center">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid xs={2.5}>
                        <FormControlLabel className={currentAppointmentNote['fingerSucking']?.markAsEdited ? "redTextNotes" : ""}
                          control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNotePropCheckbox('fingerSucking');
                          }}
                            checked={currentAppointmentNote['fingerSucking']?.response === 'on'} name="fingerSucking" />}
                          label="Finger Sucking" />
                      </Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Often</Grid>
                      <Grid xs={2} >
                        <CssSelectField
                          className={currentAppointmentNote['fingerSuckingOften']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'fingerSuckingOften');
                          }}
                          value={parseInt(currentAppointmentNote['fingerSuckingOften']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['fingerSucking']?.response !== 'on'}>

                          <MenuItem key="0day" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1day" value="1" >{"1x day"}</MenuItem>
                          <MenuItem key="2day" value="2" >{"2x day"}</MenuItem>
                          <MenuItem key="3day" value="3" >{"3x day"}</MenuItem>
                          <MenuItem key="4day" value="4" >{"4x day"}</MenuItem>
                          <MenuItem key="5day" value="5" >{"5x day"}</MenuItem>
                        </CssSelectField></Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Long</Grid>
                      <Grid xs={2}>
                        <CssSelectField
                          sx={{ border: "0px" }} className={currentAppointmentNote['fingerSuckingLong']?.markAsEdited ? "redTextNotes" : ""}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'fingerSuckingLong');
                          }}
                          value={parseInt(currentAppointmentNote['fingerSuckingLong']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['fingerSucking']?.response !== 'on'}>
                          <MenuItem key="0year" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1year" value="0" >{"1 year"}</MenuItem>
                          <MenuItem key="2year" value="1" >{"2 years"}</MenuItem>
                          <MenuItem key="3year" value="2" >{"3 years"}</MenuItem>
                          <MenuItem key="4year" value="3" >{"4 years"}</MenuItem>
                          <MenuItem key="5year" value="4" >{"5+ years"}</MenuItem>
                        </CssSelectField></Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignContent="center">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid xs={2.5}>
                        <FormControlLabel className={currentAppointmentNote['pacifer']?.markAsEdited ? "redTextNotes" : ""}
                          control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNotePropCheckbox('pacifer');
                          }}
                            checked={currentAppointmentNote['pacifer']?.response === 'on'} name="pacifer" />}
                          label="Pacifier" />
                      </Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Often</Grid>
                      <Grid xs={2} >
                        <CssSelectField className={currentAppointmentNote['paciferOften']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'paciferOften');
                          }}
                          value={parseInt(currentAppointmentNote['paciferOften']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['pacifer']?.response !== 'on'}>
                          <MenuItem key="0day" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1day" value="1" >{"1x day"}</MenuItem>
                          <MenuItem key="2day" value="2" >{"2x day"}</MenuItem>
                          <MenuItem key="3day" value="3" >{"3x day"}</MenuItem>
                          <MenuItem key="4day" value="4" >{"4x day"}</MenuItem>
                          <MenuItem key="5day" value="5" >{"5x day"}</MenuItem>
                        </CssSelectField></Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Long</Grid>
                      <Grid xs={2}>
                        <CssSelectField className={currentAppointmentNote['paciferLong']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'paciferLong');
                          }}

                          value={parseInt(currentAppointmentNote['paciferLong']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['pacifer']?.response !== 'on'}>
                          <MenuItem key="0year" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1year" value="0" >{"1 year"}</MenuItem>
                          <MenuItem key="2year" value="1" >{"2 years"}</MenuItem>
                          <MenuItem key="3year" value="2" >{"3 years"}</MenuItem>
                          <MenuItem key="4year" value="3" >{"4 years"}</MenuItem>
                          <MenuItem key="5year" value="4" >{"5+ years"}</MenuItem>
                        </CssSelectField></Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignContent="center">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid xs={2.5}>
                        <FormControlLabel className={currentAppointmentNote['suckingOnLips']?.markAsEdited ? "redTextNotes" : ""}
                          control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNotePropCheckbox('suckingOnLips');
                          }}
                            checked={currentAppointmentNote['suckingOnLips']?.response === 'on'} name="suckingOnLips" />}
                          label="Sucking on Lips" />
                      </Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Often</Grid>
                      <Grid xs={2} >
                        <CssSelectField className={currentAppointmentNote['suckingOnLipsOften']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'suckingOnLipsOften');
                          }}
                          value={parseInt(currentAppointmentNote['suckingOnLipsOften']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['suckingOnLips']?.response !== 'on'}>
                          <MenuItem key="0day" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1day" value="1" >{"1x day"}</MenuItem>
                          <MenuItem key="2day" value="2" >{"2x day"}</MenuItem>
                          <MenuItem key="3day" value="3" >{"3x day"}</MenuItem>
                          <MenuItem key="4day" value="4" >{"4x day"}</MenuItem>
                          <MenuItem key="5day" value="5" >{"5x day"}</MenuItem>
                        </CssSelectField></Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Long</Grid>
                      <Grid xs={2}>
                        <CssSelectField className={currentAppointmentNote['suckingOnLipsLong']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'suckingOnLipsLong');
                          }}
                          value={parseInt(currentAppointmentNote['suckingOnLipsLong']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['suckingOnLips']?.response !== 'on'}>
                          <MenuItem key="0year" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1year" value="0" >{"1 year"}</MenuItem>
                          <MenuItem key="2year" value="1" >{"2 years"}</MenuItem>
                          <MenuItem key="3year" value="2" >{"3 years"}</MenuItem>
                          <MenuItem key="4year" value="3" >{"4 years"}</MenuItem>
                          <MenuItem key="5year" value="4" >{"5+ years"}</MenuItem>
                        </CssSelectField></Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignContent="center">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid xs={2.5}>
                        <FormControlLabel className={currentAppointmentNote['lipBiting']?.markAsEdited ? "redTextNotes" : ""}
                          control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNotePropCheckbox('lipBiting');
                          }}
                            checked={currentAppointmentNote['lipBiting']?.response === 'on'} name="lipBiting" />}
                          label="Lip Biting" />
                      </Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Often</Grid>
                      <Grid xs={2} >
                        <CssSelectField className={currentAppointmentNote['lipBitingOften']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'lipBitingOften');
                          }}
                          value={parseInt(currentAppointmentNote['lipBitingOften']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['lipBiting']?.response !== 'on'}>
                          <MenuItem key="0day" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1day" value="1" >{"1x day"}</MenuItem>
                          <MenuItem key="2day" value="2" >{"2x day"}</MenuItem>
                          <MenuItem key="3day" value="3" >{"3x day"}</MenuItem>
                          <MenuItem key="4day" value="4" >{"4x day"}</MenuItem>
                          <MenuItem key="5day" value="5" >{"5x day"}</MenuItem>
                        </CssSelectField></Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Long</Grid>
                      <Grid xs={2}>
                        <CssSelectField className={currentAppointmentNote['lipBitingLong']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'lipBitingLong');
                          }}
                          value={parseInt(currentAppointmentNote['lipBitingLong']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['lipBiting']?.response !== 'on'}>
                          <MenuItem key="0year" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1year" value="0" >{"1 year"}</MenuItem>
                          <MenuItem key="2year" value="1" >{"2 years"}</MenuItem>
                          <MenuItem key="3year" value="2" >{"3 years"}</MenuItem>
                          <MenuItem key="4year" value="3" >{"4 years"}</MenuItem>
                          <MenuItem key="5year" value="4" >{"5+ years"}</MenuItem>
                        </CssSelectField></Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignContent="center">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid xs={2.5}>
                        <FormControlLabel className={currentAppointmentNote['nailBiting']?.markAsEdited ? "redTextNotes" : ""}
                          control={<Checkbox onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setCurrentAppointmentNotePropCheckbox('nailBiting');
                          }}
                            checked={currentAppointmentNote['nailBiting']?.response === 'on'} name="nailBiting" />}
                          label="Nail Biting" />
                      </Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Often</Grid>
                      <Grid xs={2} >
                        <CssSelectField className={currentAppointmentNote['nailBitingOften']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'nailBitingOften');
                          }}
                          value={parseInt(currentAppointmentNote['nailBitingOften']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['nailBiting']?.response !== 'on'}>
                          <MenuItem key="0day" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1day" value="1" >{"1x day"}</MenuItem>
                          <MenuItem key="2day" value="2" >{"2x day"}</MenuItem>
                          <MenuItem key="3day" value="3" >{"3x day"}</MenuItem>
                          <MenuItem key="4day" value="4" >{"4x day"}</MenuItem>
                          <MenuItem key="5day" value="5" >{"5x day"}</MenuItem>
                        </CssSelectField></Grid>
                      <Grid xs={2} sx={{ paddingLeft: "25px" }}>How Long</Grid>
                      <Grid xs={2}>
                        <CssSelectField className={currentAppointmentNote['nailBitingLong']?.markAsEdited ? "redTextNotes" : ""}
                          sx={{ border: "0px" }}
                          onChange={(event: any) => {
                            setCurrentAppointmentNoteProp(event.target.value, 'nailBitingLong');
                          }}
                          value={parseInt(currentAppointmentNote['nailBitingLong']?.response)}
                          defaultValue='-1' disabled={currentAppointmentNote['nailBiting']?.response !== 'on'}>
                          <MenuItem key="0year" value="-1" >{"Select"}</MenuItem>
                          <MenuItem key="1year" value="0" >{"1 year"}</MenuItem>
                          <MenuItem key="2year" value="1" >{"2 years"}</MenuItem>
                          <MenuItem key="3year" value="2" >{"3 years"}</MenuItem>
                          <MenuItem key="4year" value="3" >{"4 years"}</MenuItem>
                          <MenuItem key="5year" value="4" >{"5+ years"}</MenuItem>
                        </CssSelectField></Grid>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Grid>
            </Item>
          </Box>
        </Grid>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid className="subheader" >
          {(!_.isNull(prevSessionUrl) && prevSessionUrl != "") && <a href={prevSessionUrl} target="_blank" rel="noopener noreferrer">Previous Appointment Remarks</a>}
          {(_.isNull(prevSessionUrl) || prevSessionUrl == "") && <Grid>Previous Appointment Remarks</Grid>}
        </Grid>
        <FormControl>
          <Item>
            <CssTextField
              InputLabelProps={{ shrink: true }}
              id="outlined-basic"
              className={currentAppointmentNote['previousSessionRemarks']?.markAsEdited ? "TextBox redTextNotes" : "TextBox"}
              size="small"
              required={true}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentAppointmentNoteProp(event.target.value, 'previousSessionRemarks');
              }}
              value={currentAppointmentNote['previousSessionRemarks']?.response}
            />
          </Item>
          <FormHelperText>{""}</FormHelperText>
        </FormControl>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid container direction={"row"} className="formLabel" ><Grid className={currentAppointmentNote['subjectiveNotes']?.markAsEdited ? "redTextNotes" : ""}>Subjective Notes</Grid></Grid>
        <FormControl  >
        <Item>
        <ReactQuill
          theme='snow'
          style={{ height: '200px', marginBottom: '25px', width: '100%' }}
          onBlur={(content, delta, editor) => handleChangeEditor(editor, "subjectiveNotes")}

          value={currentAppointmentNote['subjectiveNotes']?.response || ''}
        />
        </Item>
        <FormHelperText>{""}</FormHelperText>
        </FormControl>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid container direction={"row"} className="formLabel" ><Grid className={currentAppointmentNote['objectiveNotes']?.markAsEdited ? "redTextNotes" : ""}>Objective Notes</Grid></Grid>
        <FormControl >
          <Item>
            <ReactQuill
              theme='snow'
              style={{ height: '200px', marginBottom: '25px' }}
              onBlur={(content, delta, editor) => handleChangeEditor(editor, "objectiveNotes")}

              value={currentAppointmentNote['objectiveNotes']?.response || ''}
            />
          </Item>
          <FormHelperText>{""}</FormHelperText>
        </FormControl>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid container direction={"row"} className="formLabel" ><Grid className={currentAppointmentNote['assessmentNotes']?.markAsEdited ? "redTextNotes" : ""}>Assessment Notes</Grid></Grid>
        <FormControl >
          <Item>
            <ReactQuill
              theme='snow'
              style={{ height: '200px', marginBottom: '25px' }}
              onBlur={(content, delta, editor) => handleChangeEditor(editor, "assessmentNotes")}
              value={currentAppointmentNote['assessmentNotes']?.response || ''}
            />
          </Item>
          <FormHelperText>{""}</FormHelperText>
        </FormControl>
      </Grid> */}
      {/* <Grid container xs={12} direction="row" marginBottom={"25px"} paddingLeft={4} columnSpacing={0} className="formBasics">
        <Grid container direction={"row"} className="formLabel" ><Grid className={currentAppointmentNote['treatmentGoals']?.markAsEdited ? "redTextNotes" : ""}>Treatment Goals</Grid></Grid>
        <FormControl  >
          <Item  >
            <ReactQuill
              theme='snow'
              style={{ height: '200px', marginBottom: '25px' }}
              onBlur={(content, delta, editor) => handleChangeEditor(editor, "treatmentGoals")}

              value={currentAppointmentNote['treatmentGoals']?.response || ''}
            />

          </Item>
          <FormHelperText>{""}</FormHelperText>
        </FormControl>
      </Grid> */}
    </Grid>


  )
}
export default observer(MyoFunctionalEvaluation);