import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';


const AddIcon = () => {
    const theme = useTheme();

    return (
        <Avatar
            sx={{
                mr: 0.5,
                width: 24,
                height: 24,
                background: `${theme.colors.primary.main}`,
                color: `${theme.palette.primary.contrastText}`
            }}
        >
            <AddTwoToneIcon
                sx={{
                    fontSize: `${theme.typography.pxToRem(16)}`
                }}
            />
        </Avatar>
    );
};

export default AddIcon;
