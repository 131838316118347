interface ILegalGuardianData {
    id: number,
    firstName: string,
    lastName: string,
    relationshipTypeId: number,
    emailAddress: string, 
    primaryPhone: string,
    altPhone: string,
    deleted: boolean,
    patientID: number,

}

interface LegalGuardian extends ILegalGuardianData {}

class LegalGuardian {
    constructor({ id, firstName, lastName, relationshipTypeId, emailAddress, primaryPhone, altPhone, deleted, patientID}: ILegalGuardianData) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.relationshipTypeId = relationshipTypeId;
        this.emailAddress = emailAddress;
        this.primaryPhone = primaryPhone;
        this.altPhone = altPhone;
        this.id = id;
        this.deleted = deleted;
        this.patientID = patientID;
    }

    toObject(): ILegalGuardianData {
        return {...this};
    }
}
export default LegalGuardian;