import { action, makeObservable, observable } from "mobx";
import { BaseMainStore } from "../base/MainStore";
import { TreatmentPlan } from "../../models/state_models/treatmentPlan";

export class TreatmentPlanStore {
    mainStore: BaseMainStore;
    @observable treatmentPlan: TreatmentPlan;

    @action setTreatmentPlan = (newPlan:TreatmentPlan)=>{
        this.treatmentPlan = newPlan;
    }

    constructor(mainstore: BaseMainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
        this.treatmentPlan = new TreatmentPlan();
    }
}