// Vendors
import { Fragment, useEffect, useState } from 'react';
import { AppBar, Button, Box, Grid, Toolbar, Typography, Card, CardContent } from '@mui/material'
import { AccountCircle, WhatsApp, AttachEmail } from '@mui/icons-material';
// Components
import Item from '../../components/Item';
import ClinicalConsultationCalendly from '../../components/AppointmentModal/ClinicalConsultationCalendly';
import PageHeader from './components/PageHeader';
import PageTitleWrapper from '../../components/UI/PageTitleWrapper';
import DataGrid from '../../components/DataGrid/DataGrid';

// Store 
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { Gateway } from '../../api/Gateway';
import jwt_decode from 'jwt-decode';


interface ConsultationData {
    clinicalAdvisor: any;
    clinicalAdvocate: any;
}

export const Consultations = () => {
    const [consultationData, setConsultationData] = useState<ConsultationData>();
    const [openAdvisor, setOpenAdvisor] = useState(false);
    const [openAdvocate, setOpenAdvocate] = useState(false);
    const [openContact, setOpenContact] = useState(false);

    useEffect(() => {
        if (!consultationData) {
            var token = sessionStorage.getItem('token');
            let userID = 0;
            if (token) {
                var tokenData: { id } = jwt_decode(token);
                userID = tokenData.id;
            }
            Gateway.getOne(`consultations/${userID}`).then(data => {
                setConsultationData(data);
            });
        }
    }, []);

    const { toggleLeftNav, isLeftNavExpanded } = useMainStoreContext().myoCorrectAdminStore;

    let store = useMainStoreContext().myoCorrectStore;

    const defaultPage = '';

    const columns = [
        {
            field: 'vivosId',
            headerName: 'Patient ID',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patientName',
            headerName: 'Patient',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'dateOfAppointment',
            headerName: 'Date of Appointment',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'statusId',
            headerName: 'Status',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'scheduledLink',
            headerName: 'Access Link',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'AiGenerated',
            headerName: 'Generated AI Report',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        },
    ];

    return (
        <Fragment>
            <PageTitleWrapper>
                <PageHeader />
            </PageTitleWrapper>
            <Grid xs={12} container direction="row" className="page">
                <Grid item xs={12}>
                    <DataGrid
                        data={1}
                        columns={columns}
                    />


                </Grid>

                {/* <Grid className={'content' + (isLeftNavExpanded === true ? ' small' : ' big')}>
                <Grid>
                    <Grid container direction="column" alignItems="center" className="gridHeader" xl={12}>
                        <Grid container className="placeholder" alignItems="center" alignContent="center" xl={12}>
                            <AppBar position="static" className="modalHeader" color="inherit">
                                <Toolbar>
                                    <Typography
                                        id={'consultations.hdr.lbl.title'}
                                        variant="h6"
                                        component="div"
                                        sx={{ flexGrow: 1 }}
                                        className="title"
                                    >
                                        CONSULTATIONS
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1}>
                    <Grid item xs={6}>
                        <Item justifyContent="center" display="flex">
                            <Card>
                                <CardContent>
                                    <Box height={150} alignItems="center" display="flex">
                                        <Box>
                                            {consultationData && consultationData.clinicalAdvocate && (
                                                <>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <AccountCircle />
                                                        &nbsp;
                                                        <Typography>
                                                            {consultationData.clinicalAdvocate.firstName +
                                                                ' ' +
                                                                (consultationData.clinicalAdvocate.middleName
                                                                    ? consultationData.clinicalAdvocate.middleName + ' '
                                                                    : '') +
                                                                consultationData.clinicalAdvocate.lastName}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <WhatsApp />
                                                        &nbsp;
                                                        <Typography>
                                                            {consultationData.clinicalAdvocate.primaryPhone}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <AttachEmail />
                                                        &nbsp;
                                                        <Typography>
                                                            {consultationData.clinicalAdvocate.emailAddress}
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                </>
                                            )}
                                            <Button
                                                onClick={() => setOpenAdvocate(true)}
                                                disabled={!consultationData || !consultationData.clinicalAdvocate}
                                                variant="contained"
                                            >
                                                Schedule Consultation with Clinical Advocate
                                            </Button>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item justifyContent="center" display="flex">
                            <Card>
                                <CardContent>
                                    <Box height={150} alignItems="center" display="flex">
                                        <Box>
                                            {consultationData && consultationData.clinicalAdvisor && (
                                                <>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <AccountCircle />
                                                        &nbsp;
                                                        <Typography>
                                                            {consultationData.clinicalAdvisor.firstName +
                                                                ' ' +
                                                                (consultationData.clinicalAdvisor.middleName
                                                                    ? consultationData.clinicalAdvisor.middleName + ' '
                                                                    : '') +
                                                                consultationData.clinicalAdvisor.lastName}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <WhatsApp />
                                                        &nbsp;
                                                        <Typography>
                                                            {consultationData.clinicalAdvisor.primaryPhone}
                                                        </Typography>
                                                    </div>
                                                    <div style={{ display: 'flex', margin: 5 }}>
                                                        <AttachEmail />
                                                        &nbsp;
                                                        <Typography>
                                                            {consultationData.clinicalAdvisor.emailAddress}
                                                        </Typography>
                                                    </div>
                                                    <br />
                                                </>
                                            )}
                                            <Button
                                                onClick={() => setOpenAdvisor(true)}
                                                disabled={!consultationData || !consultationData.clinicalAdvisor}
                                                variant="contained"
                                            >
                                                Schedule Appliance Design Consultation
                                            </Button>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Item>
                    </Grid>
                </Grid>
                <Grid position="absolute" right={0} bottom={30}>
                    <Button
                        id="patient.consultation.btn"
                        className="vivButton large"
                        onClick={() => setOpenContact(true)}
                    >
                        Contact Us
                    </Button>
                </Grid>

                <ContactInfo isOpen={openContact} onClose={() => setOpenContact(false)} />
            </Grid>
            {consultationData && (
                <>
                    <ClinicalConsultationCalendly
                        open={openAdvisor}
                        onClose={() => setOpenAdvisor(false)}
                        schedulerLink={consultationData.clinicalAdvisor.schedulerLink}
                    />
                    <ClinicalConsultationCalendly
                        open={openAdvocate}
                        onClose={() => setOpenAdvocate(false)}
                        schedulerLink={consultationData.clinicalAdvocate.schedulerLink}
                    />
                </>
            )} */}
            </Grid>
        </Fragment>
    );
};
