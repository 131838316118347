import React from 'react';
import { MenuItem, TextField, Box } from '@mui/material';
import { observer } from 'mobx-react';
import ProviderStore from '../../../../stores/OldStores/ProviderStore';
import { AddressType } from '../../../../entities/Enums';
import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';


const PracticeLocationSelector: React.FC = observer(() => {
    const providerStore = useMainStoreContext().providerStore
    React.useEffect(() => {
        if(providerStore.ProviderID && providerStore.ProviderID > 0){
            providerStore.getProviderDetails();
        }
        
        // Assuming getProviderDetails is a method that fetches the provider data.
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: { xs: 'none', md: 'inline-flex' }
                }}
            >
                <TextField
                    sx={{ width: 300 }}
                    select
                    size="small"
                    fullWidth
                    label="Practice Location"
                    onChange={(event) => { providerStore.setSelectedPracticeAddressId(event.target.value) }}
                >
                    {providerStore.providerData?.addresses?.length > 0 && providerStore.providerData.addresses.filter(x => x.addressTypeId === AddressType["Practice Address"]).map((address) => (
                        <MenuItem key={address.id} value={address.id}>
                            {address.company.companyName}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </>
    );
});

export default PracticeLocationSelector;
