import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
    Box,
    Divider,
    styled,
    Grid,
    alpha
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { applianceBrochure, clinicalConsultationRequest, constructionBite101, constructionBiteCare, guideMeasurement, myoEnrollmentVideo, submitRepairVideo } from './Helper/documents';


// Define a constant list of question-answer pairs
const faqList = [
    {
        question: "What's new with vCloud Provider Portal?",
        answer: () => (
            <Grid container>
                <Typography>
                    <ul>
                        <li>
                            Providers with multiple accounts to handle multiple locations will be consolidated into one account with location management capabilities.
                        </li>
                        <li>
                            Multiple dashboards for managing patients, appliances, reports, and consultations.
                        </li>
                        <li>
                            Improved teeth charting and treatment planning tools.
                        </li>
                        <li>
                            Improved payment processing for a smoother checkout experience.
                        </li>
                        <li>
                            Warranty & Repair requests will be managed through the portal. Removing the need to step out of this portal for Vivos process management.
                        </li>
                        <li>
                            3D digital  previews embedded in the portal.
                        </li>
                        <li>
                            Overall enhanced user accessibility and functionality!
                        </li>
                    </ul>
                </Typography>
            </Grid>
        ),
    },
    {
        question: "Who can I contact if I need assistance with the new platform?",
        answer: () => (
            <Grid container>
                <Typography>
                    Feel free to reach out to our Help Desk, who are on standby waiting to assist you.
                    <ul>
                        <li>
                            Phone: 1-866-908-4867
                        </li>
                        <li>
                            Email: helpme@vivoslife.com
                        </li>
                    </ul>
                </Typography>
            </Grid>
        ),
    },
    {
        question: "Why am I being charged at the time I submit an RX",
        answer: () => (
            <Grid container>
                <Typography>
                With the transition to the new vCloud system, several processes have been updated to 
                improve efficiency. Now, instead of waiting until an appliance enters fabrication, payment 
                is processed immediately upon appliance submission.
                </Typography>
            </Grid>
        )
    },
    {
        question: "Can I create draft prescriptions in vCloud?",
        answer: () => (
            <Grid container>
                <Typography>
                Currently, our system doesn’t support draft prescriptions. However, 
                we’re actively working on this feature to be added in the upcoming software updates.
                </Typography>
            </Grid>
        )
    },
    //Appliance Design Consultation Button?
    {
        question: `Where is the "Appliance Design Consultation button"?`,
        answer: () => (
            <Grid container>
                <Typography>
                Appliance Design Consultations have been renamed to Airway Intelligence Service (AIS) Consultations. 
                These can be scheduled after an Airway Intelligence Report (AIR) has been requested. Once the report 
                is ordered, a 'Schedule Consultation' button will appear in the top right corner of the patient's profile, 
                just below the patient collaboration button.
                </Typography>
            </Grid>
        )
    }
];
const faq2List = [
    //What appliances does Vivos offer?
    {
        question: "What type of Appliances does Vivos offer?",
        answer: () => (
            <Grid container>
                <Typography>
                    Vivos offers a variety of appliances including corrective airway
                    reposition and expansion (C.A.R.E.) devices, guided growth and
                    development, sleep/MAD related appliances, and TMJ/migraine/nasal
                    dilation appliances. For a more comprehensive look into all the appliances
                    Vivos offers <a href={applianceBrochure} target="_blank"><b>Click HERE</b></a>.
                    (Note: Vivos Appliance options may vary based on location and Provider type).
                </Typography>
            </Grid>
        ),
    },
    //Recommended Wear Times/Expansion rates for C.A.R.E.?
    {
        question: "What are the recommended wear time and expansion protocols for C.A.R.E. appliances (DNA, mRNA, mmRNA)?",
        answer: () => (
            <Grid container>
                <Typography>
                    C.A.R.E. device protocols vary according to each patient, their needs,
                    and their unique craniofacial complex. The average recommended wear
                    time with C.A.R.E. appliances ranges from 10-12 hours a day. Turning ratios for expansion are
                    completely dependent on wear time. If you are unsure what protocols
                    are right for your patient, please schedule a consultation with us.
                </Typography>
            </Grid>
        )
    },
    //VG vs. VW?
    {
        question: "When should I use a VG vs VW Guide appliance?",
        answer: () => (
            <Grid container>
                <Typography>
                    Generally, the Vivos “VG” series is used for ages 6-12 years of age,
                    once 6-year molars are fully erupted, and after centrals and
                    laterals erupt. The Vivos “VW” series focuses on children 12 years
                    and older, into adulthood. These are worn after 12-year molars erupt. For more
                    information, please schedule a consultation with us.
                </Typography>
            </Grid>
        )
    },
    // Warranty/Repair Process (! NEEDS UPDATE !)
    {
        question: "What is the warranty/repair process for Vivos Products?",
        answer: () => (
            <Grid container>
                <Typography>
                    Providers can submit warranty/repair claims on eligible Vivos
                    products by going to the 'Appliance Therapy' tab in the patient's profile and clicking
                    "Submit Claim". This digital form will include information about warranty time
                    frames for each Vivos appliance. 
                    <a href={submitRepairVideo} target="_blank">
                    <u>Click HERE</u></a> for a video tutorial on this process
                </Typography>
            </Grid>
        )
    },
    //Scheduling Clinical Consultation
    {
        question: "How do I schedule a Clinical Consultation?",
        answer: () => (
            <Grid container>
                <Typography>
                <a href={clinicalConsultationRequest}
                        target="_blank"><u>Click HERE</u></a> to
                    learn how to schedule a clinical consultation. Please verify that all
                    requirements are met before scheduling consultations.
                </Typography>
            </Grid>
        )
    },
    //Schedule AIS Consultation? ( ! NEEDS UPDATE !)
    // {
    //     question: "How do I schedule an Airway Intelligence Service Consultation?",
    //     answer: () => (
    //         <Grid container >
    //             <Typography>
    //                 <a href=""
    //                     target="_blank"><u>Click HERE</u></a>
    //                 to learn how to schedule an AIS (Appliance Design) appointment.
    //                 Please verify that all requirements are met before scheduling consultations.
    //             </Typography>
    //         </Grid>
    //     )
    // },
    //Measuring for VG or VW
    {
        question: "How do I measure a patient for the Vivos VG/VGx and VW appliances?",
        answer: () => (
            <Grid container>
                <Typography>
                    Begin by measuring the mesial to distal widths of the front four
                    upper incisors in millimeters. Combine these 4 individual widths to determine a total
                    sum. Using this sum, follow this <a href={guideMeasurement} target="_blank"><b>Measurement Guide</b></a> to
                    determine which size is best for your patient. (If your patient is in between sizes, we recommend sizing up).
                </Typography>
            </Grid>
        )
    },
    //Exporting Digital Impressions
    {
        question: "How do I export my digital impressions and bite registration from my scanner?",
        answer: () => (
            <Grid container>
                <Typography>
                    The process of exporting digital impressions and bite registrations varies depending on the scanner type.
                    Go to the "Record Taking" tab in this Knowledge Base and view "Export Instructions" for directions relating to your specific scanner type.
                </Typography>
            </Grid>
        )
    },
    // MyoSync and how to Enroll?
    {
        question: "What is MyoSync, and how do I enroll my patients?",
        answer: () => (
            <Grid container>
                <Typography>
                    MyoSync (formerly known as MyoCorrect) is a service Vivos offers to incorporate Myofunctional
                    Therapy into a patient’s treatment plan through trained Myofunctional therapists and
                    advocates.
                    <a href={myoEnrollmentVideo}
                        target="_blank" ><u>Click HERE</u></a>
                    to learn how to enroll your patients in Myosync.
                </Typography>
            </Grid>
        )
    },
    //Construction Bites?
    {
        question: "What is a construction bite and how do I take one?",
        answer: () => (
            <Grid container>
                <Typography>
                    <a href={constructionBite101} target="_blank"><b>Click HERE</b></a> to
                    watch a video on what a construction bite is and how to properly take one.
                </Typography>
            </Grid>
        )
    },
];

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
        }
  `
);

const BoxHeaderWrapper = styled(Box)(
    ({ theme }) => `
    position: relative;
    z-index: 7;

    .MuiTypography-root {
        color: ${theme.palette.primary.contrastText};

        & + .MuiTypography-root {
            color: ${alpha(theme.palette.primary.contrastText, 0.7)};
        }
    }
  `
);

function TopFaqContent () {
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} padding={1}>
                <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                    <Box
                        sx={(theme) => ({
                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '75px',
                                            borderRadius: `${theme.shape.borderRadius}px`,
                                            backgroundColor: theme.palette.primary.dark,
                                            color: theme.palette.primary.contrastText,
                        })}>
                        <BoxHeaderWrapper>
                            <Typography variant='h3'>Top vCloud Questions</Typography>
                        </BoxHeaderWrapper>
                    </Box>
                    <Grid container spacing={2} padding={2}>
                        {faqList.map((faq, index) => (
                            <Grid item xs={12}>
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}a-content`}
                                        id={`panel${index}a-header`}
                                    >
                                        <Box borderBottom={.5} width={'100%'} borderColor={'grey'}>
                                            <Typography variant='h4'>{faq.question}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <BoxWrapper>
                                            {faq.answer()}
                                        </BoxWrapper>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6} padding={1}>
                <Card sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
                    <Box
                        sx={(theme) => ({
                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '75px',
                                            borderRadius: `${theme.shape.borderRadius}px`,
                                            backgroundColor: theme.palette.primary.dark,
                                            color: theme.palette.primary.contrastText,
                        })}>
                        <BoxHeaderWrapper>
                            <Typography variant='h3'>Top General Questions</Typography>
                        </BoxHeaderWrapper>
                    </Box>
                    <Grid container spacing={2} padding={2}>
                        {faq2List.map((faq, index) => (
                            <Grid item xs={12}>
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}a-content`}
                                        id={`panel${index}a-header`}
                                    >
                                        <Box borderBottom={.5} width={'100%'} borderColor={'grey'}>
                                            <Typography variant='h4'>{faq.question}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <BoxWrapper>
                                            {faq.answer()}
                                        </BoxWrapper>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ))}
                    </Grid>
                </Card>
            </Grid>
        </Grid>

    );
}

export default TopFaqContent;
