import { Close } from "@material-ui/icons";
import { Box, Fade, FormControl, Grid, IconButton, Modal, Button, AppBar, Toolbar, Typography } from "@mui/material"
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from "react";
import CssTextField from "../CssTextField";
import Item from "../Item";
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { observer } from "mobx-react";


const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1100,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
};

interface ComponentProps {
	open: boolean
	onClose: () => void
}

const NotesModal = (props: ComponentProps) => {
	const { open, onClose } = props;
	let { currentSession, saveNote, setCurrentAdditionalNotes } = useMainStoreContext().createAppointmentNotesStore;
	const myoCorrectStore = useMainStoreContext().myoCorrectStore;
	useEffect(() => {

	}, [open]);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box sx={style}>
					<Grid container direction='row' justifyContent={"space-between"}><AppBar position="static" className="modalHeader" color="inherit">
						<Toolbar>
							<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="title">
								{"Additional Session Notes"}
							</Typography>
							<IconButton onClick={onClose} >
								<Close />
							</IconButton>
						</Toolbar>
					</AppBar>
					</Grid>
					<Grid xl={12}>
						<CssTextField
							sx={{ boxShadow: "3px 3px 6px #00000080", borderRadius: "3px", width: "100%" }}
							className="TextBox"
							size="medium"
							multiline
							minRows="10"
							required={true}
							disabled={myoCorrectStore.isProvider && !(myoCorrectStore.isMyoCorrectAdmin || myoCorrectStore.isMyoCorrectAdvisor || myoCorrectStore.isMyoCorrectAdvocate)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setCurrentAdditionalNotes(event.target.value);
							}}
							value={currentSession.additionalNotes}
						/>
					</Grid>
					{(!(myoCorrectStore.isProvider) || myoCorrectStore.isMyoCorrectAdmin || myoCorrectStore.isMyoCorrectAdvisor || myoCorrectStore.isMyoCorrectAdvocate) && <Grid container direction="row" justifyContent="flex-end">
						<Button className="vivButton large" onClick={saveNote}>Save</Button>
						<Button className="vivButton large" onClick={props.onClose}>Cancel</Button>
					</Grid>}
				</Box>
			</Fade>
		</Modal>)
}

export default observer(NotesModal);