// Vendors
import react, { useEffect, useMemo } from "react";
import {
    Grid,
    Typography,
    Box,
    Button,
    alpha,
    styled,
    List,
    ListItem,
    ListItemText,
    TextField
} from "@mui/material"

// Store
import { useMainStoreContext } from "../../../../stores/OldStores/MainStore";
import RxConstructionContainer from "../RxConstructionContainer";

// Entities
import { RxAppliances, ConstructionTypes } from "../../../../entities/Enums"

// API
import { observer } from 'mobx-react';
import { usePrescriptionStore } from "../../../../stores/Prescription/CreateEdit/MainStoreContext";

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      text-transform: uppercase;
      border-radius: ${theme.general.borderRadiusSm};
      padding: ${theme.spacing(0.9, 1.5, 0.7)};
      line-height: 1;
      position: absolute;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(1)};
    `
);

const VidaConstruction = () => {
    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const [isCustomVertical, setIsCustomVertical] = react.useState(false);
    const [loadedValues, setLoadedValues] = react.useState(false);

    useMemo(() => {
        prescriptionStore.loadVidaConstructionValues().then(() => {
            if (prescriptionStore.isUpdating && !prescriptionStore.isConstructionFormInProgress) {
                prescriptionStore.setConstructionAttributes();
            }
            setLoadedValues(true);
            if (prescriptionStore.isUpdating) {
                prescriptionStore.setIsConstructionFormInProgress(true)
            }
        });
    }, [])

    useEffect(() => {
        setIsCustomVertical(prescriptionStore.selectedVerticalThickness?.listItemKey === 'CUSTOMVERTICAL' ? true : false);
        prescriptionStore.setSelectedApplianceType(ConstructionTypes["Vida"]);
    }, [prescriptionStore.verticalThickness]);

    useEffect(() => {
        if (loadedValues) {
            // Set selected vertical thickness
            const selectedVertical = prescriptionStore.selectedVerticalThickness;
            if (selectedVertical) {
                setIsCustomVertical(selectedVertical.listItemKey === 'CUSTOMVERTICAL' ? true : false);
            }
        }
    }, [loadedValues]);


    const handleVerticalThicknessChange = (id) => {
        prescriptionStore.setSelectedVerticalThickness(prescriptionStore.verticalThickness.find(vg => vg.id === id));
        if (prescriptionStore.verticalThickness.find(vg => vg.id === id)?.name === "Fabricate to construction Bite") {
            prescriptionStore.setSelectedCustomThickness(0);
        }
    };

    const handleBiteLocationChange = (id) => {
        prescriptionStore.setSelectedBiteLocation(prescriptionStore.unilateralBiteBlock.find(vg => vg.id === id));
    };

    const rxComplete = prescriptionStore.selectedVerticalThickness?.id && prescriptionStore.selectedBiteLocation?.id;

    useEffect(() => {
        prescriptionStore.setIsConstructionComplete(rxComplete);
    }, [rxComplete, prescriptionStore.selectedConstructionType]);

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12} md={6} padding={1}>
                <RxConstructionContainer
                    rxComplete={rxComplete}
                    rxConstructionTitle={"Vida Construction"}
                    rxConstructionComponent={
                        <ListWrapper disablePadding>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Vertical Thickness
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    {prescriptionStore.verticalThickness.map((vg) => (
                                                        <Grid item xs={9}>
                                                            <Button
                                                                key={vg.id}
                                                                variant={prescriptionStore.selectedVerticalThickness?.id === vg.id ? "contained" : "outlined"}
                                                                onClick={() => handleVerticalThicknessChange(vg.id)}
                                                                fullWidth
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                {vg.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!prescriptionStore.selectedVerticalThickness?.id && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>
                            {prescriptionStore.selectedVerticalThickness && prescriptionStore.selectedVerticalThickness.name === "Custom vertical" && <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Custom Vertical Thickness (mm)
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            type="number"
                                                            size="small"
                                                            placeholder=""
                                                            required={true}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                prescriptionStore.setSelectedCustomThickness(event.target.value);
                                                            }}
                                                            value={prescriptionStore.selectedCustomThickness}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!prescriptionStore.selectedVerticalThickness?.id && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>}
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Unilateral BiteBlock Location
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    {prescriptionStore.unilateralBiteBlock.map((vg) => (
                                                        <Grid item xs={9}>
                                                            <Button
                                                                key={vg.id}
                                                                variant={prescriptionStore.selectedBiteLocation?.id === vg.id ? "contained" : "outlined"}
                                                                onClick={() => handleBiteLocationChange(vg.id)}
                                                                fullWidth
                                                                sx={{
                                                                    justifyContent: 'flex-start'
                                                                }}
                                                            >
                                                                {vg.name}
                                                            </Button>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                                {!prescriptionStore.selectedBiteLocation?.id && <Box alignSelf="flex-start">
                                    <LabelWrapper
                                        sx={(theme) => ({
                                            background: `${theme.colors.warning.main}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.warning.dark
                                            )}`
                                        })}
                                    >
                                        Required
                                    </LabelWrapper>
                                </Box>}
                            </ListItem>
                        </ListWrapper>
                    }
                />
            </Grid>
        </Grid>
    )
}; export default observer(VidaConstruction)