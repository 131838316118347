import { ArrowBackIos, ArrowForwardIos, Close } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { Box, Fade, Grid, IconButton, Modal, List, ListItem, Typography, ListItemButton, Button, TextField } from "@mui/material"
import Backdrop from '@mui/material/Backdrop';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import './RecurringAppointmentModal.css';

const style = {
	position: 'absolute' as 'absolute',
	display: 'flex',
	flexDirection: 'column',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 900,
	height: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
};
const calendarContainer = {
	height: 'calc(100% - 72px)'
}
const timeTextContainer = {
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'center',
	justifySelf: 'center',
	margin: '5px auto 5px auto',
	border: '#006edc 1px solid',
	borderRadius: '5px',
	width: '80%',
	height: '50px',
	color: '#006edc',
}
const selectedTimeTextContainer = {
	display: 'flex',
	alignItems: 'center',
	justifyItems: 'center',
	justifySelf: 'center',
	margin: '5px auto 5px auto',
	border: '#006edc 1px solid',
	background: '#006edc',
	borderRadius: '5px',
	width: '80%',
	height: '50px',
	color: '#fff',
}
const timeText = {
	fontWeight: '700',
	margin: 'auto'
}
const confirmButton = {
	background: '#006edc',
	color: '#fff',
	fontWeight: '700',
	width: 'calc(100%-30px)',
	':hover': {
		background: '#00376f'
	},
	':disabled': {
		background: '#9E9E9E'
	}
}

interface ComponentProps {
	open: boolean;
	onClose: () => void;
	loadData: () => void;
}

const AppModal = (props: ComponentProps) => {
	const { open, onClose, loadData } = props;
	const [date, setDate] = useState<Date | null>(null);
	const [time, setTime] = useState<string | null>(null);
	const [isSaving, setIsSaving] = useState(false);

	const { selectedVivosId, selectedSessionNumber, selectedSessionStatus, previousDateReschedule } = useMainStoreContext().myoCorrectAdminStore;
	const { updateEvent, saveEvent } = useMainStoreContext().myoCorrectStore;

	const appointmentTimes = useMemo(() => {
		const appTimes: string[] = [];
		const startTime = new Date();
		startTime.setHours(9, 0, 0)
		do {
			const hour = startTime.getHours() - (startTime.getHours() > 12 ? 12 : 0);
			const minutes = (startTime.getMinutes() < 10 ? '0' : '') + startTime.getMinutes().toString();
			const ampm = startTime.getHours() >= 12 ? 'pm' : 'am';
			appTimes.push(`${hour}:${minutes}${ampm}`);
			startTime.setMinutes(startTime.getMinutes() + 30);
		} while (appTimes[appTimes.length - 1] !== "4:30pm" || appTimes.length > 16)
		return appTimes;
	}, []);

	const confirmEvent = async () => {
		setIsSaving(true);

			await updateEvent(selectedVivosId, date ?? new Date(), time ?? '', selectedSessionNumber ?? 0);

		loadData();
		resetModal();
		onClose();
	}

	const closeModal = async () => {
		loadData();
		resetModal();
		onClose();
	}

	const resetModal = () => {
		setDate(null);
		setTime(null);
		setIsSaving(false);
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box className="box-calendly">
					<Grid container>
						<Grid item xs={11} display='flex' alignItems='center'>
							<Typography variant='h5' component='h5'>Reschedule Session {selectedSessionNumber} - {previousDateReschedule}</Typography>
						</Grid>
						<Grid item xs={1} >
							<IconButton disabled={isSaving} onClick={closeModal} >
								<Close />
							</IconButton>
						</Grid>
					</Grid>
					<Grid container style={calendarContainer}>
						<Grid item xs={6} alignItems='center' display='flex' marginLeft='50px'>
							<Calendar
								id="rescheduleModal.calendar"
								minDate={new Date()}
								nextLabel={<ArrowForwardIos />}
								prevLabel={<ArrowBackIos />}
								calendarType="US"
								onChange={setDate}
								value={date}
								tileDisabled={({ date }) => date.getDay() === 0 || date.getDay() === 6 || isSaving}
								showNeighboringMonth={false}
							/>
						</Grid>
						<Grid item xs={5} sx={{ overflowX: 'hide', overflowY: 'scroll' }} maxHeight="100%">
							<List sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
								{date &&
									appointmentTimes.map((appointmentTime, index) => (
										<ListItem id={`rescheduleModal.listItem.${index}`} key={`${index}-${appointmentTime}`}
											sx={time === appointmentTime ? selectedTimeTextContainer : timeTextContainer}>
											<ListItemButton disabled={isSaving} onClick={() => setTime(appointmentTime)}>
												<Typography sx={timeText}>
													{appointmentTime}
												</Typography>
											</ListItemButton>
										</ListItem>))
								}
							</List>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs={12} display='flex' justifyContent='center' >
							<LoadingButton
								loading={isSaving}
								sx={confirmButton}
								disabled={date === null || time === null}
								onClick={confirmEvent}>
								Confirm
							</LoadingButton>
						</Grid>
					</Grid>
				</Box>
			</Fade>
		</Modal>)
}

export default observer(AppModal);