import EcommerceItem from "./ecommerceItem";
import { Variant } from '../../stores/OldStores/ShopifyStore';
import { observable } from 'mobx';

export class ECommerceShopifyItem {
    @observable eCommerceItem: EcommerceItem = new EcommerceItem();
    @observable shopifyProduct: any | undefined = undefined; 
    @observable variantsSelected: Record<string,string> = {};
    @observable isAditionalItem:Boolean = false;
    @observable variantSelectedShopify: Variant | undefined = undefined;
    @observable alreadyAdded: boolean = false;
    @observable addedToCart: boolean = false;
}