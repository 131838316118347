import React, { FC, useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Checkbox
} from '@mui/material';
import CheckboxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import moment from 'moment';
import * as _ from 'lodash';

import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';
import { ConstructionBiteType, OcclusionModelType } from '../../../entities/Enums';
import { vi } from 'date-fns/locale';
import { Button } from 'reactstrap';
import { Delete } from '@material-ui/icons';
import { on } from 'events';

interface IReportVisit {
    visits?: any;
    singleSelect: boolean;
    onSelectVisit: (visit: any) => void;
    onVisitsChange?: (visits: any[]) => void;
}

export const RxImpressionSelectVisit: FC<IReportVisit> = ({
    visits = [],
    singleSelect,
    onSelectVisit,
    onVisitsChange
}) => {
    const [isChecked, setIsChecked] = useState<boolean[]>(
        visits.map(() => false)
    );
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    useEffect(() => {
        if (prescriptionStore.prescription?.visitId) {
            toggleCheckboxValue(visits.findIndex(x => x.id === prescriptionStore.prescription?.visitId))
        } else {
            if (visits.length > 0) {
                toggleCheckboxValue(visits.length - 1);
            } else {
                onSelectVisit(null);
                setIsChecked([]);
            }
        }
    }, [visits]);

    const toggleCheckboxValue = (index: number, event?: React.MouseEvent<HTMLButtonElement>) => {
        if (index < 0 || index >= visits.length || !visits[index]) {
            return;
        }

        const currentVisit = visits[index];

        if (singleSelect) {
            // Single-select logic
            const wasSelected = isChecked[index];

            // Prevent changing the current visit if the id is <= 0
            const currentVisitIndex = isChecked.findIndex(checked => checked);
            if (currentVisitIndex !== -1 && visits[currentVisitIndex]?.id <= 0) {
                return;
            }

            // Uncheck everything
            const newState = isChecked.map(() => false);

            if (!wasSelected) {
                newState[index] = true;
                onSelectVisit(currentVisit);
            } else {
                onSelectVisit(null);
            }
            setIsChecked(newState);
        } else {
            // Multi-select logic (or if the visit is new/invalid)
            const updatedCheckedState = isChecked.map((item, i) =>
                i === index ? !item : item
            );
            const selectedCount = updatedCheckedState.filter(v => v).length;

            // Limit multi-select to 2
            if (selectedCount <= 2) {
                setIsChecked(updatedCheckedState);
            }
        }
    };

    const handleDeleteVisit = (index: number) => {
        const updatedVisits = visits.filter((_, i) => i !== index);
        setIsChecked(updatedVisits.map(() => false));
        onSelectVisit(null);
        if (onVisitsChange) {
            onVisitsChange(updatedVisits);
        }

    };

    const hasDigUpperArch = (row: any): boolean => {
        const arch = row?.patientsImages?.[0]?.occlusion3dupperArch;
        const modelTypeId = row?.patientsImages?.[0]?.modelTypeId;
        return !!arch && modelTypeId === OcclusionModelType.Digital;
    };

    const hasDigLowerArch = (row: any): boolean => {
        const arch = row?.patientsImages?.[0]?.occlusion3dlowerArch;
        const modelTypeId = row?.patientsImages?.[0]?.modelTypeId;
        return !!arch && modelTypeId === OcclusionModelType.Digital;
    };

    const hasDigConstructBite = (row: any): boolean => {
        const arch = row?.patientsImages?.[0]?.occlusion3dconstructedBite;
        const constrBiteType = row?.patientsImages?.[0]?.constructionBiteType;
        return !!arch && constrBiteType === ConstructionBiteType.Digital;
    };

    const hasPhysImpressions = (row: any): boolean => {
        const modelTypeId = row?.patientsImages?.[0]?.modelTypeId;
        return modelTypeId === OcclusionModelType.Physical;
    };

    const hasPhysConstructBite = (row: any): boolean => {
        const constrBiteType = row?.patientsImages?.[0]?.constructionBiteType;
        return constrBiteType === ConstructionBiteType.Physical;
    };

    return (
        <Grid xs={12} padding={1}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">Select</TableCell>
                            <TableCell align='center'></TableCell>
                            <TableCell align='center'>Visit Date</TableCell>
                            <TableCell align='center'>Impression Date</TableCell>
                            <TableCell align='center'>Digital Upper Arch</TableCell>
                            <TableCell align='center'>Digital Lower Arch</TableCell>
                            <TableCell align='center'>Digital Bite</TableCell>
                            <TableCell align='center'>Physical Impression</TableCell>
                            <TableCell align='center'>Physical Bite</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visits.map((currentVal, index) => (
                            <TableRow hover key={currentVal.id}>
                                <TableCell padding="checkbox" align='center'>
                                    <Checkbox
                                        sx={{ padding: 0.5 }}
                                        checked={!!isChecked[index]}  // coerce to boolean
                                        onClick={(event) => toggleCheckboxValue(index, event)}
                                    />
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    {currentVal.id <= 0 &&
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography variant="h4" noWrap sx={{ marginRight: 1 }}>
                                                NEW
                                            </Typography>
                                            <Button onClick={() => handleDeleteVisit(index)} style={{ minWidth: 'auto', padding: 0 }}>
                                                <Delete />
                                            </Button>
                                        </Box>
                                    }
                                </TableCell>
                                <TableCell sx={{ textAlign: 'center' }}>
                                    <Box pb={0.5} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" noWrap>
                                            {moment(currentVal.createdOn).format("MM/DD/yyyy")}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                    <Box pb={0.5} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" noWrap>
                                            {currentVal?.patientsImages?.[0]?.occlusionImpressionDate
                                                ? moment(currentVal.patientsImages[0].occlusionImpressionDate).format("MM/DD/yyyy")
                                                : "No impression date"}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {hasDigUpperArch(currentVal)
                                        ? <CheckboxTwoToneIcon color="success" />
                                        : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {hasDigLowerArch(currentVal)
                                        ? <CheckboxTwoToneIcon color="success" />
                                        : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {hasDigConstructBite(currentVal)
                                        ? <CheckboxTwoToneIcon color="success" />
                                        : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {hasPhysImpressions(currentVal)
                                        ? <CheckboxTwoToneIcon color="success" />
                                        : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                                <TableCell padding="checkbox" align='center'>
                                    {hasPhysConstructBite(currentVal)
                                        ? <CheckboxTwoToneIcon color="success" />
                                        : <WarningTwoToneIcon color="warning" />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};
