import { createContext, useContext } from "react";
import { BaseMainStore } from "../../base/MainStore";
import { TreatmentPlanStore } from "../TreatmentPlan";
import { TreatmentPlan } from "../../../models/state_models/treatmentPlan";
import { action, observable } from "mobx";
import { Gateway } from "../../../api/Gateway";

export class CreateUpdateTreatmentPlanMainStore extends BaseMainStore {
    treatmentPlanStore: TreatmentPlanStore;
    isLoading:boolean = false;
    loadingText: string = '';
    constructor() {
        super();
        this.treatmentPlanStore = new TreatmentPlanStore(this);
    }

    @action toggleIsLoading = ()=>{
        this.isLoading = !this.isLoading
    };

    @action setLoadingText = (text:string) =>{
        this.loadingText = text;
    }

    saveTreatment = async () => {
        const url = 'patient/treatment';

        return Gateway.postStrongType<TreatmentPlan>(url, this.treatmentPlanStore.treatmentPlan);
    };

    loadTreatment = async () => {
        const url = `Patient/${this.treatmentPlanStore.treatmentPlan.patientId}/treatment`;
        await Gateway.getStrongType<TreatmentPlan>(url).then((response)=>{
            if(response){
                this.treatmentPlanStore.setTreatmentPlan(response);
            }
            else{
                var treatment = new TreatmentPlan();
                treatment.patientId = this.treatmentPlanStore.treatmentPlan.patientId;
                this.treatmentPlanStore.setTreatmentPlan(treatment);
            }
        });

    }
}

export const store = new CreateUpdateTreatmentPlanMainStore();
export const MainStoreContext = createContext<CreateUpdateTreatmentPlanMainStore>(store);
export const useCreateUpdateTreatmentPlanMainStoreContext = () => {
    return useContext(MainStoreContext);
};