import { Component, createRef, } from "react";
import { observer } from "mobx-react";
import { makeObservable, observable } from "mobx";
import { GridHeaderBL } from "../../../components/GridHeader/GridHeaderBL";
import {
    Typography,
    Box,
    styled,
    Avatar,
    lighten,
    alpha,
    IconButton,
    Grid,
    TextField,
    InputAdornment,
} from '@mui/material';
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

const AvatarPageTitle = styled(Avatar)(
    ({ theme }) => `
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
        color: ${theme.colors.primary.main};
        margin-right: ${theme.spacing(2)};
        background: ${theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[10]
            : theme.colors.alpha.white[50]
        };
        box-shadow: ${theme.palette.mode === 'dark'
            ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
            : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
        };
  `
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
          width: ${theme.spacing(4)};
          height: ${theme.spacing(4)};
          border-radius: ${theme.general.borderRadiusLg};
  `
);

interface PageHeaderProps {
    onChange(value: string): any;
    onClear(): any;
    onCancel(): any;
    onFilter(filters: any): any;
}

@observer
export class PageHeader extends Component<PageHeaderProps> {
    @observable store = new GridHeaderBL();

    constructor(props: any) {
        super(props);
        makeObservable(this);
        this.store.searchText = createRef();
    }

    render() {
        return (
            <Box
                display="flex"
                alignItems={{ xs: 'stretch', md: 'center' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center" flex={{ lg: '2', md: '2' }}                >
                    <AvatarPageTitle variant="rounded">
                        <BuildCircleTwoToneIcon fontSize="large" />
                    </AvatarPageTitle>
                    <Box>
                        <Typography variant="h3" component="h3" gutterBottom>
                            Lab Management
                        </Typography>
                        <Typography variant="subtitle2">
                            All Production Ready cases can be found below
                        </Typography>
                    </Box>
                </Box>
                <Box
                    py={2}
                    display="flex"
                    flex={{ lg: '2', md: '2' }}
                    alignItems={'center'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent="flex-end">
                    <Grid alignItems="center" container>
                        <Grid item xs={12} container justifyContent={'right'} paddingRight={1}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchTwoToneIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: this.store.textSearch ? (
                                        <InputAdornment position="end">
                                            <IconButtonWrapper >
                                                <IconButton onClick={() => {
                                                    this.store.clearTextInput();
                                                    this.props.onClear();
                                                }}>
                                                    <CancelTwoToneIcon />
                                                </IconButton>
                                            </IconButtonWrapper>
                                        </InputAdornment>
                                    ) : null
                                }}
                                sx={{
                                    m: 0,
                                }}
                                ref={this.store.searchText}
                                value={this.store.textSearch}
                                onChange={(event) => {
                                    this.props.onChange(event.target.value);
                                    this.store.onChange(event);
                                }}
                                placeholder={('Search patient by name or ID')}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        );
    }
}

export default PageHeader;
