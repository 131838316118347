// Vendors
import { Box, Grid } from '@mui/material';

// Component
import RxBlock1 from '../RxFrameworkComponents/RxBlock1';
import RxBlock2 from '../RxFrameworkComponents/RxBlock2';
import { TeethCharting } from '../../../components/TeethCharting/TeethCharting';
import RxOverview from './RxOverview';

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities

// API
import { observer } from 'mobx-react';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

const RxStepThree = () => {
    const { setStep, currentPatient, selectedLab, selectedAppliance, fabricationTime } = usePrescriptionStore().prescriptionStore;

    return (
        <Grid container spacing={3} padding={2}>
            <Grid item xs={12} sm={9}>
                <RxBlock1
                    RxBlock1Content={<TeethCharting existingPatient={currentPatient} />}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <RxBlock2
                    RxBlock2Content={<RxOverview />}
                />
            </Grid>
        </Grid>
    );
};
export default observer(RxStepThree);