// Vendors
import React, { Component, FC, useState, useEffect } from 'react';
import {
    Button,
    Grid,
    Dialog,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Avatar,
    styled,
} from '@mui/material';
import DynamicFormTwoToneIcon from '@mui/icons-material/DynamicFormTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Components
import { CommentsPanel } from '../../../components/CommentsPanel/CommentsPanel';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { CreateRxWizard } from "../../PrescriptionWizard/CreateRxWizard";

// Entities
import { OrderStatus, ProductionStatus, RxAppliances } from "../../../entities/Enums";

// Stores
import CommentEditStore from '../../../components/CommentsPanel/CommentsEditStore';
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import ModalContainer from '../../../components/ModalContainer';
import { ViewRx } from '../../../components/ViewRx/ViewRx';
import RxPExDropzone from './RxPExDropzone';

const BoxUploadWrapper = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(3)};
      background: ${theme.colors.alpha.black[5]};
      border: 1px dashed ${theme.colors.alpha.black[30]};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(['border', 'background'])};
  
      &:hover {
        background: ${theme.colors.alpha.white[100]};
        border-color: ${theme.colors.primary.main};
      }
  `
);
const AvatarWrapper = styled(Avatar)(
    ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(7)};
      height: ${theme.spacing(7)};
  `
);

interface IGIProps {
    patient: any;
    labAccessed: boolean;
    prescription?: any;
    // onClose();
}

let commentsEdit = new CommentEditStore();

export const RxActionButtons: FC<IGIProps> = observer(({ patient, labAccessed, prescription }) => {

    const [toggleUploadModal, setToggleUploadModal] = useState(false);
    const [toggleViewRxModal, setToggleViewRxModal] = useState(false);
    const shopifyStore = useMainStoreContext().shopifyStore;
    const store = usePrescriptionStore().prescriptionStore;
    const [isEdit, setIsEdit] = useState<any>();
    const [canEdit, setCanEdit] = useState<any>(false);
    const [isAIS, setIsAIS] = useState<boolean>(false);
    const [prodStatusId, setProdStatusId] = useState(prescription.productionStatus ? prescription.productionStatus : 0);

    useEffect(() => {
        setCanEdit(
            (prescription?.order.orderStatusId === OrderStatus.Placed || prescription?.order.orderStatusId === OrderStatus["Under Review"] || prescription?.order.orderStatusId === OrderStatus["On Hold"])
            && prescription.appliance.name !== RxAppliances.GUIDE && prodStatusId !== ProductionStatus["In Fabrication"] && prodStatusId !== ProductionStatus["Shipped"]);
        setIsAIS(store.roles.includes(process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) || store.roles.includes(process.env.REACT_APP_VIVOS_AIS_ADMIN));
        store.setPrescription(prescription);

        if (shopifyStore.client) {
            shopifyStore.fetchProducts().then((products) => {
                shopifyStore.setProducts(products);
                shopifyStore.setCartOpen(true);
                store.loadEcommerceData(products);
            });
        }
    }, []);

    const handleUpdatePrescription = () => {
        store.setAppliance(prescription.appliance);
        store.setPrescription(prescription);
        setIsEdit(true);
        store.setIsUpdating(true);
        if (shopifyStore.client) {
            //store.setStep(true);
            store.setSelectedLab(store.prescription.lab);
            store.setSelectedApplianceLogo(store.applianceOptions.find(x => x.id === store.prescription.appliance?.name)?.logo ?? '');
            store.setSelectedLabLogo(store.labsImages.find(lab => lab.name === store.prescription.lab?.name)?.logo ?? '');
            //store.setSelectedAppliance(store.applianceFamiliesSetups.find(x=> ))
            store.setApplianceConstructionDiagramFilename(store.prescription.diagramUrl);

            for (let index = 0; index < store.applianceFamiliesSetups.length; index++) {
                var currentFamily = store.applianceFamiliesSetups[index];
                var applianceSetup = currentFamily.applianceItemSetups.find(x => x.applianceType == store.prescription.appliance?.applianceType);
                if (applianceSetup) {

                    var variantItemSet = applianceSetup.shopifyProduct.variants
                        .find(x => store.prescription.order?.orderDetails[0].externalEcommerceItemId == x.id);
                    if (variantItemSet) {
                        let variants: Record<string, any> = variantItemSet.selectedOptions
                            .reduce((acc: Record<string, any>, item) => {
                                acc[item.name] = item.value;
                                return acc;
                            }, {});
                        if (variants) {
                            //store.variantsSelected(variants);
                            applianceSetup.eCommerceShopifyItem.variantsSelected = variants;
                            store.setVariantsSelected(applianceSetup.eCommerceShopifyItem.variantsSelected);

                        }
                    }
                    store.setSelectedAppliance(currentFamily);
                    store.setSelectedeCommerceItem(applianceSetup);
                }

            }
            store.setStep(true);
        }
    }

    const [showConfirmClosePrescriptionForm, setShowConfirmClosePrescriptionForm] = React.useState(false);

    const handleConfirmClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
        store.setIsUpdating(false);
        setIsEdit(false);
        store.resetStep();
    };

    const handleCancelClosePrescriptionForm = () => {
        setShowConfirmClosePrescriptionForm(false);
    };

    const handleCloseModal = () => {
        setShowConfirmClosePrescriptionForm(true);
    };




    const openDialog = () => {
        setToggleUploadModal(true);
    };

    const closeDialog = () => {
        setToggleUploadModal(false);
    }

    const openViewRxModal = () => {
        setToggleViewRxModal(true);
    }

    const closeViewRxModal = () => {
        setToggleViewRxModal(false);
    }

    // 1) Early Guard: if there's no prescription (or no appliance), return nothing.
    //    This ensures you won't call .name or .includes on undefined.
    if (!prescription || !prescription.appliance) {
        return null;
    }

    return (
        <Grid>
            <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                    <CommentsPanel
                        commentType={15553}
                        significantId={prescription.patientId}
                        isOpen={commentsEdit.isOpenModal}
                        onClose={commentsEdit.handleCloseModal.bind(this)}
                    />
                    <Button
                        fullWidth
                        startIcon={<ForumTwoToneIcon />}
                        onClick={commentsEdit.handleClickModal}
                        variant="contained"
                    >
                        Prescription Collaboration
                    </Button>
                </Grid>

                {/* 2) Use optional chaining + safe check for "includes('pex')" */}
                {prescription.appliance.name?.toLowerCase()?.includes("pex") && (
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            startIcon={<DynamicFormTwoToneIcon />}
                            variant="contained"
                            onClick={openDialog}
                        >
                            Upload PEx Design
                        </Button>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        startIcon={<VisibilityIcon />}
                        variant="contained"
                        onClick={openViewRxModal}
                    >
                        View Rx
                    </Button>
                </Grid>

                {!labAccessed && (
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            startIcon={<EditTwoToneIcon />}
                            variant="contained"
                            onClick={handleUpdatePrescription}
                            disabled={!canEdit}
                        >
                            Edit Prescription
                        </Button>
                    </Grid>
                )}
            </Grid>

            {/* Upload dialog */}
            <Dialog open={toggleUploadModal} onClose={closeDialog}>
                <DialogTitle sx={{ p: 3 }}>
                    <Typography variant="h3" gutterBottom>
                        Upload PEx Digital Design
                    </Typography>
                    <Typography variant="subtitle2">
                        Verify digital design associates to the patient before uploading.
                    </Typography>
                </DialogTitle>
                <DialogContent dividers sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid
                                container
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <RxPExDropzone />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" item xs justifyContent={"space-between"}>
                        <Grid item xs={2}>
                            <Button
                                id="cancel.form.button"
                                variant="contained"
                                color="inherit"
                                onClick={closeDialog}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            {/* View Rx modal */}
            <ModalContainer
                show={toggleViewRxModal}
                title="VIEW PRESCRIPTION"
                onClose={closeViewRxModal}
                onClick={closeViewRxModal}
            >
                <ViewRx patient={patient} prescription={prescription} />
            </ModalContainer>

            {/* Edit Rx modal */}
            <ModalContainer
                show={isEdit}
                title="PRESCRIPTION"
                onClose={handleCloseModal}
                onClick={() => {
                    store.setIsUpdating(false);
                    setIsEdit(false);
                    store.resetStep();
                }}
            >
                <CreateRxWizard
                    patientId={patient?.id}
                    isEdit={true}
                    sendOnCloseEvent={() => {
                        store.setIsUpdating(false);
                        setIsEdit(false);
                        store.resetStep();
                    }}
                    prescription={prescription}
                />
                <ConfirmDialog
                    title="Confirm Exit"
                    open={showConfirmClosePrescriptionForm}
                    onClose={handleCancelClosePrescriptionForm}
                    onConfirm={handleConfirmClosePrescriptionForm}
                    message="Are you sure you want to close without saving?"
                />
            </ModalContainer>
        </Grid>
    );
});
