import { action, makeObservable, observable, runInAction } from "mobx";
import { BaseMainStore } from "../base/MainStore";
import CustomForm from "../../models/state_models/customForm";
import { Gateway } from "../../api/Gateway";
import { Buffer } from 'buffer';
import jwt_decode from 'jwt-decode';

export class CustomFormStore {
    mainStore: BaseMainStore;
    rootPath: string = 'questionaire'
    providerId: number = -1;

    constructor(mainstore: BaseMainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
        this.sendCustomForm = this.sendCustomFormAction.bind(this);
        let token = sessionStorage.getItem('token');
        if (token) {
            let tokenData: { providerId } = jwt_decode(token);
            this.providerId = tokenData.providerId
        }
    }

    private async sendCustomFormAction(customFormsSelected: CustomForm[], patientId: number, providerId: number, visitId: number): Promise<boolean | null> {
        try {
            var patientForms = {
                patientId: patientId,
                customFormIds: customFormsSelected
                    .filter(form => form.selected)
                    .map(form => form.id),
                providerId: providerId,
                visitId: visitId
            };

            var url = `Patient/${patientForms.patientId}/customforms/send`;

            const res = await Gateway.postStrongType<boolean>(url, patientForms);

            if (res) {
                console.log(res);
                return res;
            }

            return false;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    @observable customForms: CustomForm[] = [];
    @observable customForm: CustomForm = new CustomForm();

    @action
    getCustomFormType = async (patientType?: boolean, isIntake?: boolean): Promise<CustomForm[] | null> => {
        var actionUrl = `questionnaire/provider/${this.providerId}/getForms?`;

        if (!isIntake) {
            actionUrl += `isIntake=${isIntake}`
        } else {
            actionUrl += `isPatientForm=${patientType}`
        }
        return Gateway.getStrongType<CustomForm[]>(actionUrl).then<CustomForm[] | null>((response): CustomForm[] | null => {
            if (response) {
                this.customForms = response;
                return response;
            }
            return null;
        });
    };

    sendCustomForm: (customFormsSelected: CustomForm[], patientId: number, providerId: number, visitId: number) => Promise<boolean | null>;

    @action
    getFormUrl = async (patientId: number, providerId: number, visitId: number, customFormsSelected: CustomForm[]) => {
        const baseUrl = `${new URL(window.location.href).origin}/form?details=`;
        let plainText = '';
        let base64Encoded = '';
        customFormsSelected.forEach(customForm => {
            plainText = `patientId=${patientId}&providerId=${providerId}&customFormId=${customForm.id}&visitId=${visitId}`;
            base64Encoded = Buffer.from(plainText).toString('base64');
            window.open(baseUrl + base64Encoded, "_blank");
        });

    }
}

