import { useEffect } from "react";
import { observer } from "mobx-react";
import moment from 'moment';
import * as _ from 'lodash';
import ProviderPatient from "../../../entities/ProviderPatient";
import DataGrid from "../../DataGrid/DataGrid";
import React from "react";
import { AiReportModal } from "../../AiReportOrder";
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";
import { VisitModal } from "../../../modals/VisitRecords/VisitModal";
import "../../../scss/_PatientEdit.scss";
import { GlobalUtils } from "../../../api/GlobalUtils";
import CheckboxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import {
    Button,
    Grid,
} from "@mui/material";

interface IGIProps {
    patient: ProviderPatient;
}

export const PatientVisitRecords: React.FC<IGIProps> = observer(({ patient }) => {
    const { visitRecords, topUpperImages, topLowerImages, bottomUpperImages, bottomLowerImages, setVisitPatientId, loadVisits,loadVisitsForGrid, setNewCompliance, resetVisit } = useMainStoreContext().visitRecordStore;

    const [openVisitRecordModal, setOpenVisitRecordModal] = React.useState(false);
    const [openAIReportModal, setOpenAIReportModal] = React.useState(false);
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [currentRow, setCurrentRow] = React.useState<any>({})
    const [patientVisitStatus, setPatientVisitStatus] = React.useState<{ id: number, name: string }[]>([]);
    useEffect(() => {
        setVisitPatientId(patient.id);
        loadVisitsForGrid().then(() => {
            setHasLoaded(true);
        });
        GlobalUtils.getPatientVisitStatus().then((list) => {
            setPatientVisitStatus(list);
        });

    }, [])

    const hasForms = (row: any): boolean => {
        if ((row.patientSleepStudies && row.patientSleepStudies.length > 0) ||
            (row.questionaireResponseInstances && row.questionaireResponseInstances.length > 0)
        ) {
            return true;
        }
        return false;
    }

    const hasImages = (row: any): boolean => {
        if (row.patientsImages && row.patientsImages.length > 0) {
            if ((row.patientsImages[0].extraoralFrontRelaxed && row.patientsImages[0].extraoralFrontRelaxed !== "")
                || (row.patientsImages[0].extraoralBigSmile && row.patientsImages[0].extraoralBigSmile !== "")
                || (row.patientsImages[0].extraoralRightProfile && row.patientsImages[0].extraoralRightProfile !== "")
                || (row.patientsImages[0].extraoralSubmentoVertex && row.patientsImages[0].extraoralSubmentoVertex !== "")
                || (row.patientsImages[0].extraoralFullBodyPostAnterior && row.patientsImages[0].extraoralFullBodyPostAnterior !== "")
                || (row.patientsImages[0].extraoralFullBodyPostRight && row.patientsImages[0].extraoralFullBodyPostRight !== "")
                || (row.patientsImages[0].intraoralUpperArch && row.patientsImages[0].intraoralUpperArch !== "")
                || (row.patientsImages[0].intraoralLowerArch && row.patientsImages[0].intraoralLowerArch !== "")
                || (row.patientsImages[0].intraoralAnteriorOcclusion && row.patientsImages[0].intraoralAnteriorOcclusion !== "")
                || (row.patientsImages[0].intraoralLeftOcclusion && row.patientsImages[0].intraoralLeftOcclusion !== "")
                || (row.patientsImages[0].intraoralRightOcclusion && row.patientsImages[0].intraoralRightOcclusion !== "")
                || (row.patientsImages[0].intraoralLingualFrenum && row.patientsImages[0].intraoralLingualFrenum !== "")
                || (row.patientsImages[0].intraoralSurfaceTongue && row.patientsImages[0].intraoralSurfaceTongue !== "")
                || (row.patientsImages[0].occlusionUpperArch && row.patientsImages[0].occlusionUpperArch !== "")
                || (row.patientsImages[0].occlusionAnterior && row.patientsImages[0].occlusionAnterior !== "")
                || (row.patientsImages[0].occlusionLeft && row.patientsImages[0].occlusionLeft !== "")
                || (row.patientsImages[0].occlusionRight && row.patientsImages[0].occlusionRight !== "")
                || (row.patientsImages[0].extraoralSubmentoVertex && row.patientsImages[0].extraoralSubmentoVertex !== "")
            )
                return true;
        }
        return false;
    }

    const hasCBCT = (row: any): boolean => {
        if (row.patientsImages && row.patientsImages.length > 0) {
            if (row.patientsImages[0].cbctScan !== null &&
                row.patientsImages[0].cbctScan !== "") {
                return true;
            }
        }
        return false;
    }

    const hasModels = (row: any): boolean => {
        if (row.patientsImages && row.patientsImages.length > 0) {
            if ((row.patientsImages[0].occlusion3dlowerArch && row.patientsImages[0].occlusion3dlowerArch != "")
                || (row.patientsImages[0].occlusion3dupperArch && row.patientsImages[0].occlusion3dupperArch != "")
                || (row.patientsImages[0].occlusion3dconstructedBite && row.patientsImages[0].occlusion3dconstructedBite != "")) {
                return true;
            }
        }
        return false;
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Visit Record Id',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button id={"visitrecords.dashboard.cell.toggleOpenModal." + params.row.ID} variant="text" onClick={() => {
                            setCurrentRow(params.row);
                            setOpenVisitRecordModal(true);
                        }}>{params.row.id}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'createdOn',
            headerName: 'Visit Date',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Button id={"visitrecords.dashboard.cell.toggleOpenModal." + params.row.ID} variant="text" onClick={() => {
                        }}>{moment(params.row.createdOn).format('l')}</Button>
                    </Grid>
                )
            }

        },
        {
            field: 'forms',
            headerName: 'Forms',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Grid>
                        {hasForms(params.row) && <CheckboxTwoToneIcon color="success" />}
                    </Grid>
                )
            }
        },
        {
            field: 'images',
            headerName: 'Images',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                return (
                    <Grid>
                        {hasImages(params.row) && <CheckboxTwoToneIcon color="success" />}
                    </Grid>
                );
            }
        },
        {
            field: 'cbct',
            headerName: 'CBCT',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                {
                    return (
                        <Grid>
                            {hasCBCT(params.row) && <CheckboxTwoToneIcon color="success" />}
                        </Grid>
                    );
                }
            }
        },
        {
            field: 'models',
            headerName: 'Models',
            flex: .5,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (params) => {
                {
                    return (
                        <Grid>
                            {hasModels(params.row) && <CheckboxTwoToneIcon color="success" />}
                        </Grid>
                    );
                }
            }
        }
    ];

    return (
        <Grid container>
            <Grid xs={12} padding={1}>
                <Button
                    variant="contained"
                    disabled={!hasLoaded}
                    onClick={() => {
                        setCurrentRow({});
                        setOpenVisitRecordModal(true);
                        setNewCompliance(true);
                    }
                    } >
                    Add Visit Record
                </Button>
            </Grid>
            <Grid xs={12}>
                <DataGrid
                    columns={columns}
                    data={visitRecords}
                    loading={!hasLoaded}

                    size={"small"}
                    pageSize={10}
                />
            </Grid>
            <Grid>
                <VisitModal patient={patient}
                    isOpen={openVisitRecordModal}
                    //visit={patient.isProspect ? null : JSON.stringify(currentRow) === '{}' ? null : currentRow}
                    visit={JSON.stringify(currentRow) === '{}' ? null : currentRow}
                    onClose={() => {
                        topUpperImages.length = topLowerImages.length = bottomUpperImages.length = bottomLowerImages.length = 0;
                        resetVisit();
                        setNewCompliance(false);
                        setOpenVisitRecordModal(false);
                        setHasLoaded(false);
                        loadVisits().then(() => {
                            setHasLoaded(true);
                        });
                    }} />
            </Grid>
        </Grid>
    )

})
