// Vendors
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';

// Components

// Store
import { MainStore } from './MainStore';

// Entities
import { AIReportsFilters } from '../../entities/Enums';
import AIReport from '../../entities/AIReports';
import { ITechnician } from '../../entities/Technician';
import DictionaryListItem from '../../entities/DictionaryListItem';
import PatientImage from '../../entities/PatientImage';

// API
import * as _ from 'lodash';
import moment from 'moment';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import jwt_decode from 'jwt-decode';
import buildQuery from 'odata-query';
import { Gateway } from '../../api/Gateway';
import { GlobalUtils } from '../../api/GlobalUtils';


export class AIReportStore {
    mainStore: MainStore;


    // Screen Dynamics
    @observable pageTitle = 'AI Report Dashboard';
    @observable isLeftNavExpanded: boolean = false;
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable teamMembers: any[] = [];
    // Data Table Dynamics
    private _resultCount: number = 0;
    private _totalAIReports: number = 0;
    private _totalPages: number = 1;
    private _currentPage: number = 0;
    private _hasMore: boolean = true;
    private _firstPageLoaded: boolean = false;
    private _currentFilter: AIReportsFilters = 0;
    @observable filter: any = {};
    @observable sort: any = {};
    @observable searchParam = '';
    @observable toggleShowFilterResults: boolean = false;
    userID: number = 0;
    pageSize: number = 200;
    pageOffSet: number = 0;

    // Data Retrieval
    @observable aiStatus: DictionaryListItem[] = [];
    @observable origAIReportData: AIReport[] = [];
    @observable origAIReportDataAll: AIReport[] = [];
    @observable aiReportData: AIReport[] = [];
    @observable aiReportDataAll: AIReport[] = [];
    @observable didInitAIReports = false;
    @observable isLoadingAIReports = false;
    @observable loadingProgress = 0;

    // Role Value
    private _isAiReportAdmin = false;
    private _isAiReportTech = false;
    private _isAisSpecialist = false;
    private _isProvider = false;
    roles: any = [];
    @observable technician: ITechnician[] = [];

    // Value Holders for Data Chips on Dashboard.
    @observable numPendingCases: number = 0;
    @observable numInProgress: number = 0;
    @observable numAssigned: number = 0;
    @observable numToBeAssigned: number = 0;
    @observable numCompleted: number = 0;

    // Other
    private _open: boolean = false;
    @observable selectedVisitRecordId: number = 0;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            this.userID = tokenData.id;
            this.roles = JSON.parse(tokenData.roles);
            //this.userID = 15;
        }
        this.checkRoles();
    }

    get open(): any {
        return this._open;
    }


    set open(value: any) {
        this._open = value;
    }

    @action setSelectedVisitRecordId = (newVisitRecordId) => (this.selectedVisitRecordId = newVisitRecordId);

    @action setTechnician = (technician: ITechnician[]) => (this.technician = technician);
    @action setAiReportStatus = (aiReportStatus: DictionaryListItem[]) => (this.aiStatus = aiReportStatus);
    @action setPatientData = newPatientData => (this.aiReportData = newPatientData);
    @action setOrigPatientData = newPatientData => (this.origAIReportData = newPatientData);

    @action addTechnician = (technician: ITechnician) => {
        this.technician.push(technician);
    };

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    get hasMore(): boolean {
        return this._hasMore;
    }

    set hasMore(value: any) {
        this._hasMore = value;
    }

    get totalPages(): number {
        return this._totalPages;
    }

    set totalPages(value: any) {
        this._totalPages = value;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    set currentPage(value: any) {
        this._currentPage = value;
    }

    get resultCount() {
        return this._resultCount;
    }

    @action
    set resultCount(value: any) {
        this._resultCount = value;
    }

    @observable currentRow: any;
    @action setCurrentRow = (newRow: any) => (this.currentRow = newRow);

    get firstPageLoaded(): boolean {
        return this._firstPageLoaded;
    }

    set firstPageLoaded(value: any) {
        this._firstPageLoaded = value;
    }

    get isAiReportAdmin(): any {
        return this._isAiReportAdmin;
    }

    set isAiReportAdmin(value: any) {
        this._isAiReportAdmin = value;
    }

    get isAiReportTech(): any {
        return this._isAiReportTech;
    }

    set isAiReportTech(value: any) {
        this._isAiReportTech = value;
    }

    get isAisSpecialist(): any {
        return this._isAisSpecialist;
    }

    set isAisSpecialist(value: any) {
        this._isAisSpecialist = value;
    }

    get isProvider(): any {
        return this._isProvider;
    }

    set isProvider(value: any) {
        this._isProvider = value;
    }

    get currentFilter(): AIReportsFilters {
        return this._currentFilter;
    }

    set currentFilter(value: any) {
        this._currentFilter = value;
    }

    get totalAIReports(): number {
        return this._totalAIReports;
    }

    @action
    set totalAIReports(value: number) {
        this._totalAIReports = value;
    }

    loadData = (): any => {
        var url = '';
        if (this.isAiReportAdmin) {
            var url = 'aireports/' + this.currentPage + '/' + this.pageSize;
        } else if (this.isAiReportTech) {
            var url = 'aireports/technician/' + this.userID + '/' + this.currentPage + '/' + this.pageSize;
        }
        this.numPendingCases = 0;
        this.numAssigned = 0;
        this.numCompleted = 0;
        this.numInProgress = 0;
        this.numToBeAssigned = 0;
        Gateway.get(url).then(resp => {
            this.totalAIReports = resp['rowCount'];
            if (resp['results'].length > 0) {
                this.totalPages = resp['pageCount'];
                this.setPatientData(resp['results']);
                this.origAIReportData = this.aiReportData;
                this.setNumPatientValues();
                this.setAiReportStatus(GlobalUtils.getAIReportStatuses());
                this.firstPageLoaded = true;
            } else {
                this.totalPages = 1;
                this.origAIReportData = this.aiReportData;
                this.setNumPatientValues();
            }
        });
    };

    loadAIReports = async () => {
        runInAction(() => {
            this.didInitAIReports = true;
            this.isLoadingAIReports = true;
        });
        let skip = this.currentPage * this.pageSize;
        let queryTotal = '';

        //for the total records used for the top cards
        queryTotal = buildQuery({
            //filter: this.filter ? this.filter : {}, 
            count: true
            //orderBy:  this.sort ? this.sort : 'statusId'
        });
        var urlTotal = this.isAiReportAdmin || this.isAisSpecialist ? `odata/aireports/-1` + queryTotal : `odata/aireports/${this.userID}` + queryTotal;
        const respTotal = await Gateway.get(urlTotal);
        //const totalAIReportsData = respTotal['@odata.count'] ? respTotal['@odata.count'] : this.totalPages;
        let dataTotal = respTotal['value'];
        this.numPendingCases = dataTotal.filter(x => x.status != 'Completed' && x.status != 'Completed - Incomplete').length;
        this.numToBeAssigned = dataTotal.filter(x => (x.status == 'Pending Information' || x.status == 'Pending' || x.status == 'Submitted') && x.technicianId == null).length;
        this.numAssigned = dataTotal.filter(x => x.status != 'Completed' && x.status != 'Completed - Incomplete'
            && x.status != 'Pending Information' && x.status != 'Pending' && x.technicianId != null).length;
        this.numInProgress = dataTotal.filter(x => x.status == 'Processing').length;
        this.numCompleted = dataTotal.filter(x => x.status == 'Completed' || x.status == 'Completed - Incomplete').length;
        let query = ''
        //these will be the filtered records that appear on the grid        
        switch (this.currentFilter) {
            case AIReportsFilters.PendingCases:
                query = buildQuery({
                    top: this.pageSize,
                    skip: skip,
                    filter: { and: [{ status: { ne: 'Completed' } }, { status: { ne: 'Completed - Incomplete' } }, this.filter ? this.filter : {}] },
                    count: true,
                    orderBy: this.sort ? this.sort : 'statusId'
                });
                break;
            case AIReportsFilters.ToBeAssigned:
                query = buildQuery({
                    top: this.pageSize,
                    skip: skip,
                    filter: {
                        and: [{ technicianId: { eq: null } }],
                        or: [{ status: { eq: 'Pending Information' } }, { status: { eq: 'Pending' } }, { status: { eq: 'Submitted' } }, this.filter ? this.filter : {}]
                    },
                    count: true,
                    orderBy: this.sort ? this.sort : 'statusId'
                });
                break;
            case AIReportsFilters.Assigned:
                query = buildQuery({
                    top: this.pageSize,
                    skip: skip,
                    filter: {
                        and: [
                            { status: { ne: 'Completed' } },
                            { status: { ne: 'Completed - Incomplete' } },
                            { status: { ne: 'Pending Information' } },
                            { status: { ne: 'Pending' } },
                            { technicianId: { ne: null } },
                            this.filter ? this.filter : {}]
                    },
                    count: true,
                    orderBy: this.sort ? this.sort : 'statusId'
                });
                break;
            case AIReportsFilters.CasesInProgress:
                query = buildQuery({
                    top: this.pageSize,
                    skip: skip,
                    filter: { and: [{ status: { eq: 'Processing' } }, this.filter ? this.filter : {}] },
                    count: true,
                    orderBy: this.sort ? this.sort : 'statusId'
                });
                break;
            case AIReportsFilters.Completed:
                query = buildQuery({
                    top: this.pageSize,
                    skip: skip,
                    filter: { or: [{ status: { eq: 'Completed' } }, { status: { eq: 'Completed - Incomplete' } }, this.filter ? this.filter : {}] },
                    count: true,
                    orderBy: this.sort ? this.sort : 'statusId'
                });
                break;
            default:
                query = buildQuery({
                    top: this.pageSize,
                    skip: skip,
                    filter: this.filter ? this.filter : {},
                    count: true,
                    orderBy: this.sort ? this.sort : 'statusId'
                });
                break;
        }

        // TODO ADD URL FOR PROVIDER SPECIFIC QUERY
        var url = this.isAiReportAdmin || this.isAisSpecialist ? `odata/aireports/-1` + query : `odata/aireports/${this.userID}` + query;
        // var url = true ? `odata/aireports/-1` + query : `odata/aireports/${this.userID}` + query;
        const resp = await Gateway.get(url);
        //const totalAIReports = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;
        let data = resp['value'];
        const totalAIReportsData = resp['@odata.count'] ? resp['@odata.count'] : this.totalPages;


        runInAction(() => {
            if (this.didInitAIReports) {
                this.didInitAIReports = false;
            }
            this.isLoadingAIReports = false;
            this.totalAIReports = totalAIReportsData;
            this.aiReportDataAll = dataTotal.map(dataTotal => {
                const newData = { ...dataTotal };
                newData.name = data.firstName + ' ' + data.lastName;
                return newData;
            });
            this.origAIReportDataAll = this.aiReportDataAll;
            this.aiReportData = data.map(data => {
                const newData = { ...data };
                newData.name = data.firstName + ' ' + data.lastName;
                return newData;
            });

            this.origAIReportData = this.aiReportData;
        });
        //this.totalAIReports = this.aiReportData.length;
        //this.setNumPatientValues();
    };

    onSortChange = (sortModel: GridSortModel): any => {
        runInAction(() => {
            if (Object.keys(sortModel).length > 0) {
                runInAction(() => {
                    this.sort = [sortModel[0].field + ' ' + sortModel[0].sort];
                });
            } else {
                this.sort = {};
            }
        });
    };

    onFilterChange = (filterModel: GridFilterModel): any => {
        runInAction(() => {
            let searchFilter = this.buildFilter(filterModel);
            if (Object.keys(searchFilter).length > 0) {
                runInAction(() => {
                    this.filter = searchFilter;
                    this.didInitAIReports = true;
                });
            } else {
                this.filter = {};
            }
        });
    };




    @action
    buildFilter = (filterModel: GridFilterModel): any => {
        let customFilter = {};
        let linkOperator = filterModel.linkOperator !== undefined ? filterModel.linkOperator : '';
        customFilter[linkOperator] = [];
        filterModel.items.forEach(filter => {
            let currentOdata = {};
            let operator = {};
            let operatorValue = filter.operatorValue ? filter.operatorValue.toLowerCase() : '';
            let shouldNotAdd = true;
            let funcProperty = filter.columnField;
            let typeColumn = 'string';
            let actualValue;

            if (filter.columnField.toLowerCase().includes('date')) {
                typeColumn = 'date';
            }

            if (filter.columnField.includes('id') || filter.columnField.includes('days')) { // || filter.columnField.includes('Id')) {
                typeColumn = 'number';
            }

            if (filter.columnField.toLowerCase().includes('name')) {
                typeColumn = 'string';
            }

            if (filter.columnField.includes('Id') && !filter.columnField.includes('provider')) {
                switch (filter.columnField) {
                    case 'assignedToId':
                        if (filter.value) {
                            actualValue = this.teamMembers.filter(
                                x =>
                                    x.firstName === filter.value.split(' ')[0] &&
                                    x.lastName === filter.value.split(' ')[1],
                            )[0].userID;
                            typeColumn = 'list';
                        }
                        break;
                    case 'technicianId':
                        if (filter.value) {
                            actualValue = this.technician.filter(
                                x =>
                                    x.firstName === filter.value.split(' ')[0] &&
                                    x.lastName === filter.value.split(' ')[1],
                            )[0].userID;
                            typeColumn = 'list';
                        }
                        break;

                    case 'statusId':
                        if (filter.value) {
                            actualValue = this.aiStatus.filter(
                                x =>
                                    x.name === filter.value
                            )[0]?.id;
                            typeColumn = 'list';
                        }

                        break;

                    default:
                        break;
                }
            }

            if (typeColumn === 'date') {
                switch (filter.operatorValue) {
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'not':
                        operator['ne'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'after':
                        operator['gt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrAfter':
                        operator['ge'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'before':
                        operator['lt'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    case 'onOrBefore':
                        operator['le'] = { type: 'raw', value: moment(filter.value).format('yyyy-MM-DD') };
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'list') {
                switch (filter.operatorValue) {
                    case 'is':
                        operator['eq'] = actualValue;
                        break;
                    case 'not':
                        operator['ne'] = actualValue;
                        break;
                    default:
                        break;
                }
            } else if (typeColumn === 'number' && filter.value) {
                switch (filter.operatorValue) {
                    case '=':
                        operator['eq'] = { type: 'raw', value: filter.value };
                        break;
                    case '!=':
                        operator['ne'] = { type: 'raw', value: filter.value };
                        break;
                    case '>':
                        operator['gt'] = { type: 'raw', value: filter.value };
                        break;
                    case '>=':
                        operator['ge'] = { type: 'raw', value: filter.value };
                        break;
                    case '<':
                        operator['lt'] = { type: 'raw', value: filter.value };
                        break;
                    case '<=':
                        operator['le'] = { type: 'raw', value: filter.value };
                        break;
                    case 'isEmpty':
                        operator['eq'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isNotEmpty':
                        operator['ne'] = { type: 'raw', value: 'null' };
                        break;
                    case 'isAnyOf':
                        break;
                    default:
                        break;
                }
            } else {
                switch (filter.operatorValue) {
                    case 'contains':
                    case 'startsWith':
                    case 'endsWith':
                        operator[operatorValue] = filter.value;
                        break;
                    case 'equals':
                        operator['eq'] = filter.value;
                        break;
                    case 'isEmpty':
                        shouldNotAdd = false;
                        operator['lt'] = 1;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isNotEmpty':
                        shouldNotAdd = false;
                        operator['gt'] = 0;
                        funcProperty = 'length(' + filter.columnField + ')';
                        currentOdata[funcProperty] = operator;
                        break;
                    case 'isAnyOf':
                        break;
                    case 'is':
                        operator['eq'] = filter.value;
                        break;
                    case 'not':
                        operator['ne'] = filter.value;
                        break;
                    default:
                        break;
                }
            }

            if (shouldNotAdd) {
                currentOdata[funcProperty] = operator;
            }
            customFilter[linkOperator].push(currentOdata);
        });
        return customFilter;
    };


    filterPatients = (patientData: any, filter: AIReportsFilters) => {
        let data: AIReport[] = [];
        switch (filter) {
            case AIReportsFilters.PendingCases:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status !== 'Completed' && rec.status !== 'Completed - Incomplete') {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.ToBeAssigned:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (
                            rec.status === 'Pending Information' ||
                            rec.status === 'Pending' ||
                            rec.status === 'Submitted'
                        ) {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.CasesInProgress:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status === 'Processing') {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.Assigned:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status === 'Assigned') {
                            data.push(rec);
                        }
                    }
                });
                break;
            case AIReportsFilters.Completed:
                _.forEach(patientData, rec => {
                    if (!_.isNull(rec) && !_.isUndefined(rec)) {
                        if (rec.status === 'Completed' || rec.status === 'Completed - Incomplete') {
                            data.push(rec);
                        }
                    }
                });
                break;
        }
        return data;
    };

    setNumPatientValues = async () => {
        _.forEach(this.aiReportDataAll, p => {
            if (p.status !== 'Completed' && p.status !== 'Completed - Incomplete') {
                //this.numPendingCases += 1;
            }
            if (p.status === 'Pending Information' || p.status === 'Pending' || p.status === 'Submitted') {
                //this.numToBeAssigned += 1;
            }
            if (p.status === 'Assigned') {
                //this.numAssigned += 1;
            }
            if (p.status === 'Processing') {
                //this.numInProgress += 1;
            }
            if (p.status === 'Completed' || p.status === 'Completed - Incomplete') {
                //this.numCompleted += 1;
            }
        });
    };

    loadMoreItems = () => {
        if (this.currentPage + 1 < this.totalPages) {
            let curpage = this.currentPage < this.totalPages ? this.currentPage + 1 : this.currentPage;
            this.currentPage = this.currentPage + 1;
            var url = 'aireports/' + curpage + '/' + this.pageSize;
            Gateway.get(url).then(resp => {
                this.setPatientData(resp['results']);
                this.origAIReportData = this.aiReportData;
            });
        } else {
            this.hasMore = false;
        }
    };

    loadTechnicianData = () => {
        Gateway.get('teams/user/' + this.userID).then(resp => {
            _.forEach(resp, team => {
                let url = `lab/${this.userID}/aireporttech`;
                Gateway.get(url).then(users => {
                    this.technician = [];
                    _.forEach(users, user => {
                        this.addTechnician(user);
                    });
                    this.technician.sort((a, b) => a.firstName.localeCompare(b.firstName));
                });
            });
        });
    };

    handleChangeTechnician = (submissionId, technicianId) => {
        let url = `AIReports/${submissionId}/set/${technicianId}`;

        Gateway.post(url, undefined).then(resp => { });

        if (submissionId) {
            setTimeout(() => {
                this.loadData();
            }, 1000);
        }
    };

    handleStatusChange = (submissionId, statusId) => {
        let url = `AIReports/${submissionId}/status/${statusId}`;

        Gateway.post(url, { templateID: 'd-6dcb6807f9614dc7a7d26693eb9320ab' }).then(resp => { });

        if (submissionId) {
            setTimeout(() => {
                this.loadData();
            }, 1000);
        }
    };

    clearData = () => {
        this.aiReportData = this.origAIReportData;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.aiReportData;
    };

    @action filterBySearchString = () => {
        this.aiReportData = this.origAIReportData.filter(
            x =>
                x.caseNumber.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.vivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.patientName.toString().toLowerCase().includes(this.searchParam.toLowerCase()),
        );
    };

    checkRoles = () => {
        for (let role of this.roles) {
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_ADMIN) {
                this.isAiReportAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_TECH) {
                this.isAiReportTech = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SPECIALIST) {
                this.isAisSpecialist = true;
            }
        }
        return;
    };

    @action
    async getProcessedCompressedImages(patientVivosId, submissionId, imageTypeId, imageType) {
        var result;
        await Gateway.getOne('/aiReports/' + submissionId + '/' + imageTypeId + '/download').then(resp => {
            result = resp;
        });

        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${patientVivosId}-${submissionId}-${imageType}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}
