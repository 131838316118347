import axios, { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import { ShopifyResponse } from '../models/shopifyResponse/ShopifyResponse';

//const mainAxios = axios.create({ baseURL: "https://localhost:8001" + '/api/v1/' });
const mainAxios = axios.create({ baseURL: process.env.REACT_APP_GATEWAY_URL + '/api/v1/' });
const airAxios = axios.create({ baseURL: process.env.REACT_APP_AIREPORTCREATION_SVC })
const identityAxios = axios.create({ baseURL: process.env.REACT_APP_AUTHORITY_URL + '/api/' });
const shopifyStoreFrontAxios = axios.create({
    baseURL: `https://${process.env.REACT_APP_SHOPIFY_STORE_DOMAIN}/api/2024-04/graphql.json`,
    headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': process.env.REACT_APP_SHOPIFY_API_TOKEN ? process.env.REACT_APP_SHOPIFY_API_TOKEN : '',
    },
});
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.common['Accept'] = 'application/json;charset=utf-8';

const token = sessionStorage.getItem('token');
var tokenData: { id };
if (token) {
    tokenData = jwt_decode(token);
}

let aconfig = {};
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    aconfig = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Methods": "*"
        },
    };
}

// Add interceptors to Axios instances to add the Authorization header
const addTokenToRequest = (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;

        let tokenData;
        tokenData = jwt_decode(token);

        if (config.method === 'post' && config.data) {
            if (_.isNull(config.data) || _.isUndefined(config.data)) {
                config.data = {};
            }
            if (tokenData && tokenData.id) {
                config.data['userId'] = tokenData.id;
            }
        }
    }



    return config;
};

mainAxios.interceptors.request.use(addTokenToRequest, error => Promise.reject(error));
airAxios.interceptors.request.use(addTokenToRequest, error => Promise.reject(error));

export class Gateway {

    static shopifyPost<T>(query: string, variables: any): Promise<T> {
        return shopifyStoreFrontAxios
            .post<T>('', { query, variables })
            .then((response: AxiosResponse<ShopifyResponse<T>>) => {
                if (response && response.data && response.data.data) {
                    const dataKey = Object.keys(response.data.data)[0] as keyof T;
                    return response.data.data[dataKey];
                }
                throw response.data.errors;
            })
            .catch((error) => {
                console.error(error);
                return undefined as any; // Return undefined if there's an error
            });
    }

    static get = (url: string) => {
        var data: any[] | PromiseLike<any[]> = [];
        return mainAxios
            .get(url, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static getStrongType<T>(url: string): Promise<T> {
        return mainAxios
            .get(url, aconfig)
            .then((response: AxiosResponse<T>) => response.data)
            .catch((error: Error) => {
                console.error(error);
                throw error;
            });
    };
    static getOne = (url: string) => {
        var data: any | PromiseLike<any> = null;
        return mainAxios
            .get<any>(url, {})
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = null;
            })
            .then(() => {
                return data;
            });
    };

    static post = (url: string, body: any) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any[] | PromiseLike<any[]> = [];
        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return mainAxios
            .post(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };



    static postWithOptions = (url: string, body: any, config) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any;
        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return mainAxios.post(url, body, config)
            .then(response => {
                data = response;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static postNonAudit = (url: string, body: any) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any[] | PromiseLike<any[]> | any = [];
        return mainAxios
            .post(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static putNonAudit = (url: string, body: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        return mainAxios
            .put(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static async putStrongType<T>(url: string, body: any): Promise<T> {
        var data: any[] | PromiseLike<any[]> = [];
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }

        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return mainAxios
            .put(url, body, aconfig)
            .then((response: AxiosResponse<T>) => response.data)
            .catch((error: Error) => {
                console.error(error);
                throw error;
            });
    }

    static async postStrongType<T>(url: string, body: any): Promise<T> {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }

        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
            if (body['createdBy'] || body['createdBy'] == '0') {
                body['createdBy'] = tokenData.id;
            }
        }

        return mainAxios
            .post(url, body, aconfig)
            .then((response: AxiosResponse<T>) => response.data)
            .catch((error: Error) => {
                console.error(error);
                throw error;
            });
    }

    static put = (url: string, body: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        body['userId'] = tokenData.id;
        return mainAxios
            .put(url, body, aconfig)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static delete = (url: string, body: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        return mainAxios
            .delete(url, {
                data: body,
            },)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };


    static fileUploader = async (url: string, fileName: string, file: any) => {
        var data: any[] | PromiseLike<any[]> = [];
        const postData = new FormData();
        postData.append('formFile', file);
        postData.append('fileName', fileName);
        postData.append('success', 'false');
        return mainAxios
            .post(url, postData)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static calendlyGet = (url: string) => {
        // var fullurl = "https://api.calendly.com/scheduled_events/" + url;
        var token = 'Bearer ' + process.env.REACT_APP_CALENDLY_AUTH_TOKEN;
        var config = { headers: { Authorization: token } };

        var data: any[] | PromiseLike<any[]> = [];
        return mainAxios
            .get(url, config)
            .then(response => {
                data = response.data.resource;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static generatedAiReport = (url: string, body: any) => {
        let token = process.env.REACT_APP_GENERATE_AIREPORT_AUTH_TOKEN!;
        let config = {
            headers: {
                authToken: token,
            },
        };

        let data: any[] | PromiseLike<any[]> = [];
        return airAxios
            .post(url, body, config)
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };

    static identityPost = (url: string, body: any) => {
        if (_.isNull(body) || _.isUndefined(body)) {
            body = {};
        }
        var data: any[] | PromiseLike<any[]> = [];
        if (tokenData && tokenData.id) {
            body['userId'] = tokenData.id;
        }

        return identityAxios
            .post(url, body, {})
            .then(response => {
                data = response.data;
            })
            .catch(error => {
                console.log(error);
                data = [];
            })
            .then(() => {
                return data;
            });
    };
}
