import { useState, useEffect } from 'react';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { GetAuthToken } from '../components/GetAuthToken';
import jwt_decode from "jwt-decode";

let userRoles: any = [];
const config = {
    authority: process.env.REACT_APP_AUTHORITY_URL,
    client_id: process.env.REACT_APP_AUTHORITY_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTHORITY_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_AUTHORITY_REDIRECT_URI,
    response_type: 'code',
    scope: 'openid profile api',
    post_logout_redirect_uri: process.env.REACT_APP_AUTHORITY_POSTLOGOUT_URI,
    userStore: new WebStorageStateStore({ store: window.sessionStorage })
};

const userManager = new UserManager(config);

// Generate a random code verifier
const generateCodeVerifier = () => {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
};

// Generate a code challenge based on the code verifier
const generateCodeChallenge = (codeVerifier: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    return window.crypto.subtle.digest('SHA-256', data).then((buffer) => {
        let hash = '';
        const view = new DataView(buffer);
        for (let i = 0; i < view.byteLength; i += 1) {
            hash += view.getUint8(i).toString(16).padStart(2, '0');
        }
        return hash;
    });
};

const signIn = async () => {
    try {
        // Generate a new code verifier and code challenge
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallenge(codeVerifier);

        // Store the code verifier for later use
        sessionStorage.setItem('code_verifier', codeVerifier);

        // Initiate the authorization request with the code challenge
        const state = { code_verifier: codeVerifier };
        await userManager.signinRedirect({ state, code_challenge: codeChallenge });
    } catch (err) {
        console.error(err);
    }
};

const signOut = async () => {
    sessionStorage.clear();
    //sessionStorage.removeItem('token');
    const user = await userManager.getUser();
    const logoutRequest = await userManager.createSignoutRequest({
        id_token_hint: user?.id_token,
        post_logout_redirect_uri: process.env.REACT_APP_AUTHORITY_REDIRECT_URI,
        automatic_redirect_after_sign_out: true
    });

    window.location.href = logoutRequest.url;
}

function LoginPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const parseQueryString = () => {
            const queryString = window.location.search.slice(1);
            const queryObject: { [key: string]: string } = {};

            queryString.split('&').forEach((pair) => {
                const [key, value] = pair.split('=');
                queryObject[key] = decodeURIComponent(value);
            });

            return queryObject;
        };

        const getToken = async () => {
            try {
                const query = parseQueryString();
                if (query.code) {

                    // Exchange the authorization code for an access token
                    const response = await userManager.signinRedirectCallback();
                    sessionStorage.removeItem('token');
                    sessionStorage.setItem('token', response['access_token']);
                    let decode = {};
                    if (response['access_token']) {
                        decode = jwt_decode(response['access_token'])
                    }
                    let userRoles = JSON.parse(decode['roles']);
                    //let userRoles: {Name:string}[] = [];
                    for (let role of userRoles) {
                        if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                            setTimeout(() => {
                                window.location.replace('/admin');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_CLINICAL_ADVISOR
                        ) {
                            setTimeout(() => {
                                window.location.replace('/patients');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_PROVIDER ||
                            role.Name === process.env.REACT_APP_VIVOS_AIS_SPECIALIST
                        ) {
                            setTimeout(() => {
                                window.location.replace('/dashboard');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_CLINICAL_ADVOCATE
                        ) {
                            setTimeout(() => {
                                window.location.replace('/clinicaladvocate');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVISOR ||
                            role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADMIN ||
                            role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE
                        ) {
                            setTimeout(() => {
                                window.location.replace('/myocorrect');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_ADMIN ||
                            role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_TECH
                        ) {
                            setTimeout(() => {
                                window.location.replace('/aiReports');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER ||
                            role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR ||
                            role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN ||
                            role.Name === process.env.REACT_APP_VIVOS_COMPLIANCE_MANAGER
                        ) {
                            setTimeout(() => {
                                window.location.replace('/qcmanagement');
                            }, 1000);
                            return;
                        }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_EXTERNAL_LAB ||
                            role.Name === process.env.REACT_APP_VIVOS_LAB_ADMIN ||
                            role.Name === process.env.REACT_APP_VIVOS_LAB_TECH ||
                            role.Name === process.env.REACT_APP_VIVOS_LAB_MANAGER
                        ) {
                            setTimeout(() => {
                                window.location.replace('/labmanagement');
                            }, 1000);
                            return;
                        }
                        // if (
                        //     role.Name === process.env.REACT_APP_VIVOS_LAB_ADMIN ||
                        //     role.Name === process.env.REACT_APP_VIVOS_LAB_TECH
                        // ) {
                        //     setTimeout(() => {
                        //         window.location.replace('/laboratory');
                        //     }, 1000);
                        //     return;
                        // }
                        if (
                            role.Name === process.env.REACT_APP_VIVOS_TREATMENT_NAV
                        ) {
                            setTimeout(() => {
                                window.location.replace('/treatment_navigator');
                            }, 1000);
                            return;
                        }
                        // if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE) {
                        //     setTimeout(() => {
                        //         window.location.replace('/myocorrectadvocate');
                        //     }, 1000);
                        //     return;
                        // }
                    }

                    setTimeout(() => {
                        window.location.replace('/dashboard');
                    }, 1000);
                    return;

                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');
        const logout = queryParams.get('logout');

        // Check if there was an error returned
        const error = queryParams.get('error');
        if (error) {
            setError(error);
            setIsLoading(false);
            return;
        }

        if (logout) {
            signOut();
        }
        else if (!code || !state) {
            signIn();
            return;
        }

        getToken();
    }, []);

    if (isLoading) {
        return <p>Loading...<GetAuthToken>
            {({ roles }) => {
                userRoles = roles;
                return <div></div>;
            }}
        </GetAuthToken></p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return <p>Logged in successfully!</p>;
}


export default LoginPage;


