// Vendors
import react, { FC, useCallback, useEffect, useState } from "react";
import {
    Grid,
    Card,
    Box,
    Typography,
    Divider,
    CardContent,
    TextField
} from "@mui/material";

// Components
import DataGrid from "../../../components/DataGrid/DataGrid";
import { GridRowParams } from "@mui/x-data-grid-pro";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react";
import { Orders } from "../../../models/shopifyResponse/orders";
import CurrencyFormatter from "../../../components/formatters/currencyformatter";
import moment from "moment";

interface IGIProps {
}


export const ProviderOrderHistory: FC<IGIProps> = observer(() => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const { getCustomerOrderHistory } = useMainStoreContext().shopifyStore;
    const store = useMainStoreContext().providerStore;
    const [localData, setLocalData] = useState<Orders>(new Orders());

    useEffect(() => {
        setHasLoaded(false);
        getCustomerOrderHistory().then((response) => {
            if (response) {
                setLocalData(response);
                setHasLoaded(true);
            }

        });
    }, [])

    const expandedColumHeader = {
        headerName: '',
        headerAlign: 'center',
        headerClassName: 'colHeader',
    }

    const columns = [
        {
            field: 'orderId',
            headerName: 'Order Number',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <Typography variant='h5'>
                            {params.row.node.name}
                        </Typography>
                    </Grid>
                )
            }

        },
        {
            field: 'financialStatus',
            flex: 1,
            headerName: 'Payment Status',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <Typography>
                            {params.row.node.financialStatus}
                        </Typography>
                    </Grid>
                );
            },
        },
        {
            field: 'fulfillmentStatus',
            flex: 1,
            headerName: 'Fulfillment Status',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                return (
                    <Grid>
                        <Typography>
                            {params.row.node.fulfillmentStatus}
                        </Typography>
                    </Grid>
                );
            },
        },
        {
            field: 'orderTotalPrice',
            headerName: 'Total Price',
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center', renderCell: (params) => {
                return (
                    <Grid>
                        <CurrencyFormatter amount={params.row.node.totalPrice.amount} />
                    </Grid>
                )
            }
        },
        {
            field: 'orderCreationDate',
            flex: 1,
            headerName: 'Creation Date',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: params => {
                const formattedDate = moment(params.row.node.processedAt).format("MM/DD/YYYY");
                return (
                    <Grid>
                        <Typography>
                            {formattedDate}
                        </Typography>
                    </Grid>
                );
            },
        }
    ];

    const getDetailPanelContent = useCallback(
        ({ row }: GridRowParams) => {
            const detailColumns = [
                {
                    headerName: 'Item',
                    renderCell: (params) => (
                        <td style={{ textAlign: 'center' }}>
                            {params.row.node.title}
                        </td>
                    )
                },
                {
                    headerName: 'Quantity',
                    renderCell: (params) => (
                        <td style={{ textAlign: 'center' }}>
                            {params.row.node.quantity}
                        </td>
                    )
                },
                {
                    headerName: 'Price',
                    renderCell: (params) => (
                        <td style={{ textAlign: 'center' }}>
                            <CurrencyFormatter amount={params.row.node.originalTotalPrice.amount} />
                        </td>
                    )
                }
            ];

            const renderRow = (edge) => (
                <tr key={edge.node.title}>
                    {detailColumns.map((column) => column.renderCell({ row: edge }))}
                </tr>
            );

            return (
                <div>
                    {row.node.lineItems.edges.length > 0 ? (
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    {detailColumns.map((column) => (
                                        <th
                                            key={column.headerName}
                                            style={{
                                                textAlign: "center",
                                                borderBottom: '1px solid #ccc',
                                                padding: '8px'
                                            }}
                                        >
                                            {column.headerName}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {row.node.lineItems.edges.map(renderRow)}
                                <tr>
                                    <td style={{ textAlign: 'center', borderTop: '1px solid #ccc', padding: '8px' }}>
                                        Shipping Charges
                                    </td>
                                    <td style={{ textAlign: 'center', borderTop: '1px solid #ccc', padding: '8px' }}>
                                        --
                                    </td>
                                    <td style={{ textAlign: 'center', borderTop: '1px solid #ccc', padding: '8px' }}>
                                        <CurrencyFormatter amount={row.node.totalShippingPrice.amount} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center', borderTop: '1px solid #ccc', padding: '8px' }}>
                                        Total Tax
                                    </td>
                                    <td style={{ textAlign: 'center', borderTop: '1px solid #ccc', padding: '8px' }}>
                                        --
                                    </td>
                                    <td style={{ textAlign: 'center', borderTop: '1px solid #ccc', padding: '8px' }}>
                                        <CurrencyFormatter amount={row.node.totalTax.amount} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <Box>No Data Found</Box>
                    )}
                </div>
            );
        },
        [],
    );


    return (
        <Grid container sx={{ overflowY: 'auto', maxHeight: '58vh' }}>
            <Card>
                <Box
                    p={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="h4" gutterBottom>
                                    Order History
                                </Typography>
                                <Typography variant="subtitle2">
                                    Review your order history here.
                                </Typography>
                            </Box>
                            <Box>
                                <DataGrid
                                    columns={columns}
                                    data={localData.edges}
                                    getRowId={(row) => row.node.id}
                                    loading={!hasLoaded}
                                    getDetailPanelContent={getDetailPanelContent}
                                    panelDetailHeigh={100}
                                    columnExpandedHeader={expandedColumHeader}
                                    size={"small"}
                                    pageSize={10}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        p: 4
                    }}
                >
                    <Typography variant="subtitle2">
                        <Grid container spacing={0}>

                        </Grid>
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )
});