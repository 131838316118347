// 
import React, { FC } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";

// Components
import PatientHeaderLayout from "../../components/PatientProfile/PatientDetailComponents/PatientHeaderLayout";
import { ClaimInformation } from "./components/ClaimInformation";
import { InitialImpression } from "./components/InitialImpression";
import { LabInvestigation } from "./components/LabInvestigation";
import { LabRework } from "./components/LabRework";
import { ManagementReview } from "./components/ManagementReview";
import { ClaimActionButtons } from "./components/ClaimActionButtons";
import { ClaimHeaderDetails } from "./components/ClaimHeaderDetails";

// Stores
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// Entities
import { Roles } from "../../entities/Enums";

// API
import { observer } from "mobx-react-lite"
import jwt_decode from 'jwt-decode';
import _ from "lodash";
import { usePrescriptionStore } from "../../stores/Prescription/CreateEdit/MainStoreContext";
import { string } from "prop-types";

interface IGIProps {
    claimViewData: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CLAIMINFORMATION = 0;
const INITIALIMPRESSION = 1;
const LABINVESTIGATION = 2;
const LABREWORK = 3;
const MANAGEMENTREVIEW = 4;
const CCR = 5;

const claimTabs = [
    'Claim Information',
    'Initial Impression',
    'Lab Investigation',
    'Lab Rework',
    'Management Review',
    'CCR',
];


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid>
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ClaimReview: FC<IGIProps> = observer(({ claimViewData }) => {

    const [value, setValue] = React.useState(0);
    const [roles, setRoles] = React.useState<any>([]);
    const [activeTab, setActiveTab] = React.useState(0);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const store = useMainStoreContext().reworkClaimStore;
    const providerStore = useMainStoreContext().providerStore;
    const labStore = useMainStoreContext().labCaseReviewStore;
    const prescriptionStore = usePrescriptionStore().prescriptionStore;

    React.useEffect(() => {
        store.setClaimId(claimViewData.id);
        store.loadClaimDictionaryItemsValues();
        store.loadClaim().then(() => {
            providerStore.ProviderID = claimViewData.providerId;
            providerStore.getProviderDetails().then(() => {
                labStore.setPatientId(claimViewData.patientId);
                labStore.loadPatient().then(() => {
                    prescriptionStore.getPrescription(claimViewData.prescriptionId).then(() => {
                        setIsLoaded(true);
                    })
                });
            });
        });
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id } = jwt_decode(token);
            setRoles(JSON.parse(tokenData.roles));
        }

    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    /*
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        const filteredClaimTabs = roles.find(x => x.Name === Roles.AISADMINMANAGER || x.Name === Roles.VIVOSADMIN)
        ? claimTabs
        : roles.find(x => x.Name === Roles.AISADMIN || x.Name === Roles.AISSUPERVISOR)
            ? claimTabs.filter(tab => tab !== 'Management Review' )
            : claimTabs.filter(tab => tab !== 'Management Review' && tab !== 'Initial Impression' && tab !== 'CCR');
          
      
        var tabName = event.currentTarget.textContent ? '' : event.currentTarget.textContent?.toString();
        newValue = filteredClaimTabs.indexOf(tabName);
        setValue(newValue);
        setActiveTab(newValue);
    };
 */
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        // Filter the tabs based on roles
        const filteredClaimTabs = roles.find(x => x.Name === Roles.AISADMINMANAGER || x.Name === Roles.VIVOSADMIN)
            ? claimTabs
            : roles.find(x => x.Name === Roles.AISADMIN || x.Name === Roles.AISSUPERVISOR)
                ? claimTabs.filter(tab => tab !== 'Management Review')
                : claimTabs.filter(tab => tab !== 'Management Review' && tab !== 'Initial Impression' && tab !== 'CCR');
        
        // Get the tab name from the event target
        const tabName = event.currentTarget.textContent;
    
        if (!tabName || !filteredClaimTabs.includes(tabName)) {
            console.warn("Invalid tab selected or tab not found in filteredClaimTabs.");
            return;
        }
    
        // Calculate the new tab index
        const updatedValue = filteredClaimTabs.indexOf(tabName);
    
        // Update the state
        setValue(updatedValue);
        setActiveTab(updatedValue);
        console.log(tabName + ": " + updatedValue);
    };

    // Filter the claimTabs based on roles
    const filteredClaimTabs = roles.find(x => x.Name === Roles.AISADMINMANAGER || x.Name === Roles.VIVOSADMIN)
        ? claimTabs
        : roles.find(x => x.Name === Roles.AISADMIN || x.Name === Roles.AISSUPERVISOR)
            ? claimTabs.filter(tab => tab !== 'Management Review' )
            : claimTabs.filter(tab => tab !== 'Management Review' && tab !== 'Initial Impression' && tab !== 'CCR');
    return (
        <Grid container>
            {!isLoaded &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>
            }
            {isLoaded && <Grid xs={12}>
                <PatientHeaderLayout
                    patient={store.patient}
                    patientImageUrl={store.patient?.profileImageUrl}
                    provider={claimViewData.providerName}
                    providerPhone={providerStore.providerData.primaryPhone}
                    providerEmail={providerStore.providerData.emailAddress}
                    statusColumnContent={<ClaimHeaderDetails claimViewData={claimViewData} />}
                    buttonColumnContent={<ClaimActionButtons claim={claimViewData} patient={store.patient} />}
                    // tabs={filteredClaimTabs}
                    tabs={filteredClaimTabs}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    isLoading={false}
                />
                <Grid>
                    <TabPanel index={value} value={CLAIMINFORMATION}>
                        <ClaimInformation claim={store.claim} claimData={claimViewData} patient={labStore.patient} prescription={prescriptionStore.prescription} />
                    </TabPanel>
                    <TabPanel index={value} value={INITIALIMPRESSION}>
                        <InitialImpression claim={store.claim} claimData={claimViewData} />
                    </TabPanel>
                    <TabPanel index={value} value={LABINVESTIGATION}>
                        <LabInvestigation claim={store.claim} claimData={claimViewData} />
                    </TabPanel>
                    <TabPanel index={value} value={LABREWORK}>
                        <LabRework claim={store.claim} claimData={claimViewData} roles={roles} />
                    </TabPanel>
                    {filteredClaimTabs.includes('Management Review') && (                    
                    <TabPanel index={value} value={MANAGEMENTREVIEW}>
                        <ManagementReview claim={store.claim} claimData={claimViewData} roles={roles} />
                    </TabPanel>
                    )}                 
                    
                </Grid>
            </Grid>}
        </Grid>
    )
})