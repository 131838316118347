// Vendors
import React, { FC } from 'react';

// Components
import { PatientTreatmentSteps } from './PatientTreatmentSteps';
import { ModalContainer } from '../../ModalContainer';

interface IPatientTreatmentModal {
    isOpen: boolean;
    onClose: () => void;
    patient?: any;
}

export const PatientTreatmentModal: FC<IPatientTreatmentModal> = ({ isOpen, patient, onClose }) => {
    return (
        <ModalContainer
            show={isOpen}
            onClose={onClose}
            onClick={() => onClose()}
            title={`Treatment Planning - ${patient?.firstName} ${patient?.lastName}`}
        >
            <PatientTreatmentSteps patient={patient} onClosed={onClose} />
        </ModalContainer>
    );
};
