// Vendors
import * as React from 'react';
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';

// Components
import PrescriptionForms from './forms/formIndex';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API
import { Gateway } from '../../api/Gateway';
import { observer } from "mobx-react";

// Styling
import '../../scss/main.scss';
import { usePrescriptionStore } from '../../stores/Prescription/CreateEdit/MainStoreContext';

const steps = ['Patient Information', 'Lab Selection', 'Teeth Charting', 'Construction', 'Bite and Models', 'Final Review'];

const PrescriptionHorizontal = (props) => {
    const { reset, isLoading, step, setStep, loadPage } = usePrescriptionStore().prescriptionStore;
    const { getHistory } = useMainStoreContext().patientEditStore;
    const [skipped, setSkipped] = React.useState(new Set<number>());

    React.useEffect(() => {
        loadPage(props.patientId);
        getHistory(props.patientId);
        return () => {
            reset();
        };
    }, []);
    const isStepOptional = (step: number) => {
        return step === step + 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    return (
        <Box>
            <Stepper activeStep={step}
                alternativeLabel={true}
            >
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    if (isStepOptional(index)) { }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                            {/* <StepConnector className="line"></StepConnector> */}
                        </Step>
                    );
                })}
            </Stepper>

            {step === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className='vivButton large' >Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (isLoading ?
                <>
                    <Box sx={{ width: '100%', height: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <CircularProgress size={60} />
                    </Box>
                </>
                :
                <>
                    <Box padding={2}>
                        <PrescriptionForms />
                    </Box>
                </>
            )}
        </Box>
    );
};
export default observer(PrescriptionHorizontal);