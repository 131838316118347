import { makeObservable, observable } from 'mobx';

export class TreatmentComplaint {
    @observable id: number = 0;
    @observable treatmentPlanId: number = 0;
    @observable areaOfComplaint: string = '';
    @observable areaOfComplainNote: string = '';
    @observable migrationId?: number; // Optional chaining for nullable property
    @observable isActive: boolean = false;
    @observable createdBy: number = 0;
    @observable createdOn: Date = new Date();
    @observable modifiedBy?: number; // Optional chaining for nullable property
    @observable modifiedOn?: Date; // Optional chaining for nullable property

    constructor() {
        makeObservable(this);
    }
}
