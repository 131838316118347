import { observable, action, makeObservable, runInAction } from 'mobx';
import Laboratory from '../../entities/Laboratory';
import Patient from '../../entities/Patient';
import { Gateway } from '../../api/Gateway';
import jwt_decode from "jwt-decode";
import { GlobalUtils } from '../../api/GlobalUtils';
import PatientHistory from '../../entities/PatientHistory';
import { OrderStatus, RxAppliances, ConstructionTypes } from '../../entities/Enums';
import _ from 'lodash';
import DictionaryListItem from '../../entities/DictionaryListItem';
import { ApplianceFamily } from '../../models/state_models/applianceFamily';
import { ApplianceItemSetup } from '../../models/state_models/applianceItemSetup';
import { ECommerceShopifyItem } from '../../models/state_models/eCommerceShopifyItem';
import EcommerceItem from '../../models/state_models/ecommerceItem';
import { Prescription } from '../../models/state_models/prescription';
import { OrderDetail } from '../../models/state_models/orderDetail';
import { Appliance } from '../../models/state_models/appliance';
import { BaseMainStore } from '../base/MainStore';
import { ShopifyOrder } from '../../models/state_models/shopifyModels';

const rootPathECommerce = 'Prescription/Appliance/Setup';

const defaultLab = {
    id: -1,
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postalCode: 0,
    phone: "",
    fax: "",
    email: "",
    fdaNumber: 0,
    shipName: "",
    createdOn: new Date(),
    createdBy: -1,
    modifiedOn: new Date(),
    modifiedBy: -1,
    isActive: false,
    fabricationTimeRush: 0,
    fabricationTimeStandard: 0
}

const defaultPatientValues = {
    id: -1,
    orgId: -1,
    providerId: -1,
    vivosId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: new Date(),
    ethnicityId: -1,
    isProspect: false,
    isLincare: false,
    genderId: -1,
    ehrsId: -1,
    primaryPhone: "",
    altPhone: "",
    emailAddress: "",
    treatmentStatusId: 0,
}

const defaultAppliance = {
    id: -1,
    name: "",
    listItemKey: "",
}

const defaultHistory = {
    id: null,
    patientId: -1,
    allergyOptions: 0,
    allergyHxNotes: "",
    cpapmadOptions: 0,
    cpapMadHxNotes: "",
    dentalOptions: 0,
    dentalHxNotes: "",
    medicalOptions: 0,
    medicalHxNotes: "",
    areasOfConcern: 0,
    areasOfConcernNotes: "",
    areasOfConcernOther: "",
    notes: "",
    headAndNeckExamUrl: "",
    healthIntakeFormUrl: "",
    userId: -1,
    createdBy: undefined,
    createdOn: undefined,
    modifiedBy: undefined,
    modifiedOn: undefined
};

export default class PrescriptionStore {
    mainStore?: BaseMainStore;
    @observable private userID = 0;
    private _isAISAdminManager = false;
    private _isAISSupervisor = false;
    private _isAISAdmin = false;
    private _isAISSpecialist = false;

    constructor(mainstore?: BaseMainStore) {
        makeObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles, id, providerId } = jwt_decode(token);
            this.userID = tokenData.id;
            this.ProviderID = tokenData.providerId;
            this.roles = tokenData.roles;
            this.checkRoles();
        }
        this.loadECommerceItems = this.loadECommerceItemsAction.bind(this);
    }

    get isAISAdminManager(): any {
        return this._isAISAdminManager;
    }

    set isAISAdminManager(value: any) {
        this._isAISAdminManager = value;
    }

    get isAISSupervisor(): any {
        return this._isAISSupervisor;
    }

    set isAISSupervisor(value: any) {
        this._isAISSupervisor = value;
    }

    get isAISAdmin(): any {
        return this._isAISAdmin;
    }

    set isAISAdmin(value: any) {
        this._isAISAdmin = value;
    }

    get isAISSpecialist(): any {
        return this._isAISSpecialist;
    }

    set isAISSpecialist(value: any) {
        this._isAISSpecialist = value;
    }

    checkRoles = () => {
        for (let role of JSON.parse(this.roles)) {
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) {
                this.isAISAdminManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR) {
                this.isAISSupervisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN) {
                this.isAISAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SPECIALIST) {
                this.isAISSpecialist = true;
            }
        }
        return;
    };

    @observable ProviderID: number = 0;

    @observable labsImages: any = [
        { name: 'Airway Intelligence Center', logo: '/logos/labs/AIC.png' },
        { name: 'Airway Intelligence CARE', logo: '/logos/labs/AIC.png' },
        { name: 'Apex Dental Sleep Lab', logo: '/logos/labs/Apex.jpg' },
        { name: 'Aurum Dental Laboratories', logo: '/logos/labs/Aurum.png' },
        { name: 'Five Star Orthodontics', logo: '/logos/labs/FiveStar.jpeg' },
        { name: "Gergen's Orthodontic Lab", logo: '/logos/labs/Gergens.jpeg' },
        { name: "John's Dental Lab", logo: '/logos/labs/JDL.png' },
        { name: "Space Maintainers Lab", logo: '/logos/labs/SML.png' },
        { name: "STAA Lab", logo: '/logos/labs/STAA.png' },
        { name: "PEx Lab", logo: '/logos/labs/AIC.png' },
        { name: "Versa Lab", logo: '/logos/labs/AIC.png' },
        { name: "Vivos", logo: '/logos/labs/AIC.png' },
    ];

    @observable clonedPrescription: any = [];
    @action setClonedPrescription = (value: any) => {
        this.clonedPrescription = value;
    }

    @observable step: number = 0;
    @observable previousStep: number = 0;
    @observable currentStep: number = 0;
    @action resetStep = () => {
        this.step = 0;
    }

    @action setStep = (forward: boolean) => {
        const getValidSteps = (): number[] => {
            const steps = [0];
            if (this.selectedAppliance.labSelection) steps.push(1);
            if (this.selectedAppliance.teethCharting) steps.push(2);
            if (this.selectedAppliance.construction) steps.push(3);
            if (this.selectedAppliance.bitesModels) steps.push(4);
            steps.push(5);
            return steps;
        };

        const validSteps = getValidSteps();
        const currentStepIndex = validSteps.indexOf(this.step);

        if (forward && this.shouldCancelOrder) {
            this.previousStep = this.step;
            this.step = 5;
            return;
        }

        if (this.isClonedPrescription) {
            const clonedAppliance = this.applianceFamiliesSetups.find(x => x.applianceItemSetups.find(y => y.applianceType === this.clonedPrescription?.appliance?.applianceType));
            this.setSelectedAppliance(clonedAppliance!);
            this.setSelectedApplianceLogo(clonedAppliance?.imageUrl!);
            const lab = this.labs.find((lab) => lab.id === this.clonedPrescription.labId);
            this.setSelectedLab(lab);
            this.setSelectedLabLogo(this.labsImages.find(l => l.name === lab?.name)?.logo ?? '');
            this.setSelectedeCommerceItem(clonedAppliance?.applianceItemSetups.find(x => x.applianceType === this.clonedPrescription.appliance.applianceType)!);
            this.selectedeCommerceItem.eCommerceShopifyItem.variantSelectedShopify = this.selectedeCommerceItem.shopifyProduct.variants.find(y => y.id === this.clonedPrescription.order.orderDetails[0].externalEcommerceItemId);
            this.addProductToCart(this.selectedeCommerceItem.eCommerceShopifyItem);
            GlobalUtils.getFabricationTimeTypes().then((resp) => {
                this.setFabricationTime(resp.find(f => f.id === this.clonedPrescription.fabricationTime).name);
            })
            this.selectedeCommerceItem.eCommerceShopifyItem.variantsSelected["Title"] = 'Default Title';

            this.previousStep = this.step;
            this.step = 5;
            return;
        }

        let newStep;
        if (this.step === 0) {
            let labFound: Laboratory | undefined;
            labFound = this.isUpdating ? this.labs.find((lab) => lab.id === this.prescription?.lab?.id) : this.labs.find(x => x.id === this.selectedAppliance.defaultLabSelected);

            this.setSelectedLab(labFound);
            let labImageSelected = this.labsImages.find(lab => lab.name === labFound?.name)?.logo;
            this.setSelectedLabLogo(labImageSelected ? labImageSelected : '');
        }

        if (forward) {
            newStep = currentStepIndex < validSteps.length - 1 ? validSteps[currentStepIndex + 1] : this.step;
        } else {
            newStep = currentStepIndex > 0 ? validSteps[currentStepIndex - 1] : this.step;
        }

        this.previousStep = this.step;
        this.step = newStep;

        if (this.step === 5) {
            this.addProductToCart(this.selectedeCommerceItem.eCommerceShopifyItem);
        }
    };

    @observable isConstructionComplete: boolean = false;
    @action setIsConstructionComplete = (value: boolean) => {
        this.isConstructionComplete = value;
    }

    @observable isLoading: boolean = false;
    @action setIsLoading = (value: boolean) => {
        this.isLoading = value;
    }

    @observable roles: any;
    @observable labs: Laboratory[] = [];
    @observable applianceSizes: any[] = [];
    @observable applianceColors: any[] = [];
    @observable verticalThickness: any[] = [];
    @observable unilateralBiteBlock: any[] = [];
    @observable selectedLab = new Laboratory(defaultLab);
    @observable isLoadingPrescriptions = false;
    @observable currentPatient = new Patient(defaultPatientValues);
    @observable selectedAppliance: ApplianceFamily = new ApplianceFamily();
    @action setSelectedAppliance = (value: ApplianceFamily) => {
        this.selectedAppliance = value;
    };

    @observable applianceType: string | null = null;
    @action setApplianceType = (value: string | null) => {
        this.applianceType = value;
    };

    @observable selectedApplianceType: number | null = null;
    @action setSelectedApplianceType = (value) => {
        this.selectedApplianceType = value
    }

    @observable selectedApplianceTypeName: string | null = null;
    @action setSelectedApplianceTypeName = (value) => { this.selectedApplianceTypeName = value }

    @observable connectedRxRework: string | null = null;
    @action setConnectedRxRework = (value) => { this.connectedRxRework = value }


    @observable selectedApplianceId: number = 0;
    @action setSelectedApplianceId = (value) => {
        this.selectedApplianceId = value
    }
    @observable isSavingPatient = false;
    @observable isSavingPrescription = false;

    @observable patientId = -1;
    @action setPatientId = (value: number) => {
        this.patientId = value;
    };
    @observable versaTypeSelected: 'Versa 9' | 'Versa Nylon EMA' = 'Versa 9'
    @action setVersaTypeSelected = (value: 'Versa 9' | 'Versa Nylon EMA') => {
        this.versaTypeSelected = value;
    }

    @observable constructionBite: 'Physical' | 'Digital' | 'None' = 'Physical'
    @action setConstructionBite = (value: 'Physical' | 'Digital' | 'None') => {
        this.constructionBite = value;
    }

    @observable constructionBiteType: 'Natural' | 'Neuromuscular' | 'Phonetic' | 'Protrusive' = 'Natural'
    @action setConstructionBiteType = (value: 'Natural' | 'Neuromuscular' | 'Phonetic' | 'Protrusive') => {
        this.constructionBiteType = value;
    }

    @observable protrusionPosition: 'Right' | 'Center' | 'Left' = 'Left'
    @action setProtrusionPosition = (value: 'Right' | 'Center' | 'Left') => {
        this.protrusionPosition = value;
    }
    @observable protrusionDistance = 0;
    @action setProtrusionDistance = (value: number) => {
        this.protrusionDistance = value;
    }

    @observable modelSource: 'Physical' | 'Digital' | 'None' = 'Physical'
    @action setModelSource = (value: 'Physical' | 'Digital' | 'None') => {
        this.modelSource = value;
    }

    @observable labAdjustBite: boolean = true;
    @action setLabAdjustBite = (value: boolean) => {
        this.labAdjustBite = value;
    }
    @observable digitalConstructionBiteEmbedded: boolean = true;
    @action setDigitalConstructionBiteEmbedded = (value: boolean) => {
        this.digitalConstructionBiteEmbedded = value;
    }

    @observable currentProductVariant: any;
    @action setCurrentProductVariant = (value) => {
        this.currentProductVariant = value;
    }

    @observable productionRushProduct: any;
    @action setProductionRushProduct = (value) => {
        this.productionRushProduct = value;
    }

    @observable selectedImpressions: any;
    @action setSelectedImpressions = (value) => {
        this.selectedImpressions = value;
    }

    @observable neededByDate: Date | null = null;
    @action setNeededByDate = (value) => {
        this.neededByDate = value;
        this.prescription.neededByDate = value;
    }

    @observable shopifyOrder: ShopifyOrder = new ShopifyOrder();
    @action getShopifyOrder = async (order: string) => {
        this.shopifyOrder = await Gateway.getStrongType<ShopifyOrder>(`/prescription/order/${order}/shopify`);
    }

    @action
    loadEcommerceData = async (products) => {
        this.setIsLoading(true);
        try {
            const data = await this.loadECommerceItems();
            if (data) {
                var applianceFamilies = data.map(family => {
                    var applianceItemSet = family.applianceItemSetups.filter(x => x.ecommerceItem && x.ecommerceItem.isLincare == this.currentPatient.isLincare).map(setup => {
                        if (setup.ecommerceItem) {
                            var shopifyProduct = products.find(x => x.id.toString().includes(setup.ecommerceItem?.ecommerceId));
                            setup.shopifyProduct = shopifyProduct;
                        }
                        setup.eCommerceShopifyItem = new ECommerceShopifyItem();
                        setup.eCommerceShopifyItem.eCommerceItem = setup.ecommerceItem ? setup.ecommerceItem : new EcommerceItem();
                        setup.eCommerceShopifyItem.isAditionalItem = false;
                        setup.eCommerceShopifyItem.shopifyProduct = setup.shopifyProduct;

                        return setup;
                    });

                    family.applianceItemSetups = applianceItemSet;
                    return family;
                });

                this.setApplianceFamiliesSetups(applianceFamilies);
            }
        } catch (error) {
            console.error('Error loading ecommerce data:', error);
        } finally {
            this.setIsLoading(false);
            this.setIsLoadingAppliances(false);
        }
    };

    @observable applianceOptions: any = [
        { id: RxAppliances.GUIDE, constructId: ConstructionTypes['Vivos System Guide'], logo: '/logos/appliances/Guide.png', eCommerceItems: undefined },
        { id: RxAppliances.GUIDEVG, constructId: ConstructionTypes['GuideVG'], logo: '/logos/appliances/Guide.png', eCommerceItems: undefined },
        { id: RxAppliances.GUIDEVGX, constructId: ConstructionTypes['GuideVGx'], logo: '/logos/appliances/Guide.png', eCommerceItems: undefined },
        { id: RxAppliances.GUIDEVW, constructId: ConstructionTypes['GuideVW'], logo: '/logos/appliances/Guide.png', eCommerceItems: undefined },
        { id: RxAppliances.VERSA, constructId: ConstructionTypes[''], logo: '/logos/appliances/Versa.png', eCommerceItems: undefined },
        { id: RxAppliances.VERSANYLONEMA, constructId: ConstructionTypes['Versa-EMA'], logo: '/logos/appliances/Versa.png', eCommerceItems: undefined },
        { id: RxAppliances.VERSA9, constructId: ConstructionTypes['Versa-9'], logo: '/logos/appliances/Versa.png', eCommerceItems: undefined },
        { id: RxAppliances.VIDA, constructId: ConstructionTypes['Vida'], logo: '/logos/appliances/Vida.png', eCommerceItems: undefined },
        { id: RxAppliances.VIDASLEEP, constructId: ConstructionTypes['VidaSleep'], logo: '/logos/appliances/VidaSleep.png', eCommerceItems: undefined },
        { id: RxAppliances.DNA, constructId: ConstructionTypes['Vivos Dual DNA System'], logo: '/logos/appliances/DNA.png', eCommerceItems: undefined },
        { id: RxAppliances.DNASAU, constructId: ConstructionTypes['Vivos Single Arch DNA Upper'], logo: '/logos/appliances/DNA.png', eCommerceItems: undefined },
        { id: RxAppliances.DNASAL, constructId: ConstructionTypes['Vivos Single Arch DNA Lower'], logo: '/logos/appliances/DNA.png', eCommerceItems: undefined },
        { id: RxAppliances.DNADUAL, constructId: ConstructionTypes['Vivos Dual DNA System'], logo: '/logos/appliances/DNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MRNA, constructId: ConstructionTypes['Vivos mRNA System'], logo: '/logos/appliances/mRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MRNAV, constructId: ConstructionTypes['Vivos mRNA System'], logo: '/logos/appliances/mRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MRNASAU, constructId: ConstructionTypes['Vivos mRNA Single Arch Upper'], logo: '/logos/appliances/mRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MRNASAL, constructId: ConstructionTypes['Vivos mRNA Single Arch Lower'], logo: '/logos/appliances/mRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MMRNA, constructId: ConstructionTypes['Vivos mmRNA System'], logo: '/logos/appliances/mmRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MMRNAV, constructId: ConstructionTypes['Vivos mmRNA System'], logo: '/logos/appliances/mmRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MMRNASAU, constructId: ConstructionTypes['Vivos mmRNA Single Arch Upper'], logo: '/logos/appliances/mmRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.MMRNASAL, constructId: ConstructionTypes['Vivos mmRNA Single Arch Lower'], logo: '/logos/appliances/mmRNA.png', eCommerceItems: undefined },
        { id: RxAppliances.PEX, constructId: ConstructionTypes[''], logo: '/logos/appliances/PEx.png', eCommerceItems: undefined },
        { id: RxAppliances.PEXU, constructId: ConstructionTypes['PEx-U'], logo: '/logos/appliances/PEx.png', eCommerceItems: undefined },
        { id: RxAppliances.PEXL, constructId: ConstructionTypes['PEx-L'], logo: '/logos/appliances/PEx.png', eCommerceItems: undefined },
        { id: RxAppliances.PEXD, constructId: ConstructionTypes['PEx-D'], logo: '/logos/appliances/PEx.png', eCommerceItems: undefined },
        { id: RxAppliances.EMANOW, constructId: ConstructionTypes[''], logo: '/logos/appliances/emaNow.png', eCommerceItems: undefined },
    ]
    @action setApplianceOptions = (newValue) => { this.applianceOptions = newValue }

    @action setLabs = (newValue: Laboratory[]) => { this.labs = newValue }
    @action setApplianceSizes = (newValue) => { this.applianceSizes = newValue }
    @action setApplianceColors = (newValue) => { this.applianceColors = newValue }
    @action setVerticalThickness = (newValue) => { this.verticalThickness = newValue }
    @action setUnilateralBiteBlock = (newValue) => { this.unilateralBiteBlock = newValue }


    @action setSelectedLab = (lab) => {
        this.selectedLab = lab;
    }

    @observable shippingMethodSelected: 'Standard' | 'Expedited' = 'Standard'
    @action setShippingMethodSelected = (value: 'Standard' | 'Expedited') => {
        this.shippingMethodSelected = value;
    }

    @observable speakWithLab: boolean = false;
    @action setSpeakWithLab = (value: boolean) => {
        this.speakWithLab = value;
    }

    @observable applianceConstructionDiagramFilename: string | null = null;
    @action setApplianceConstructionDiagramFilename = (value) => {
        this.applianceConstructionDiagramFilename = value;
    }

    @observable pexDesignFilename: string | null = null;
    @action setPExDesignFilename = (value) => {
        this.pexDesignFilename = value;
    }

    @action setCurrentPatient = (patient) => {
        this.currentPatient = patient;
    }

    @observable showPrescriptionWizard: boolean = false;
    @action setshowPrescriptionWizard = (value: boolean) => { this.showPrescriptionWizard = value; }

    @observable isUpdating: boolean = false;
    @action setIsUpdating = (value: boolean) => { this.isUpdating = value; }

    @observable isConstructionFormInProgress: boolean = false;
    @action setIsConstructionFormInProgress = (value: boolean) => { this.isConstructionFormInProgress = value; }

    @observable isLoadingAppliances: boolean = false;
    @action setIsLoadingAppliances = (value: boolean) => { this.isLoadingAppliances = value; }

    @observable shouldCancelOrder: boolean = false;
    @action setShouldCancelOrder = (value: boolean) => { this.shouldCancelOrder = value; }

    @observable isClonedPrescription: boolean = false;
    @action setIsClonedPrescription = (value: boolean) => { this.isClonedPrescription = value; }

    @observable prescriptionData: any[] = [];
    @action setPrescriptionData = newPrescriptionData => (this.prescriptionData = newPrescriptionData);

    @observable prescription: Prescription = new Prescription();
    @action setPrescription = (value: Prescription) => {
        this.prescription = value;
        this.setSelectedLab(this.labs.find((lab) => lab.id === this.prescription.labId));
        //this.setSelectedAppliance(this.prescription.appliance.name);
    }

    @observable selectedApplianceSize: any = {};
    @action setSelectedApplianceSize = (newValue) => (this.selectedApplianceSize = newValue);

    @observable selectedApplianceColor: any = {};
    @action setSelectedApplianceColor = newValue => (this.selectedApplianceColor = newValue);

    @observable constructionComments: string = '';
    @action setConstructionComments = newValue => (this.constructionComments = newValue);

    @observable selectedVerticalThickness: any = {};
    @action setSelectedVerticalThickness = newValue => (this.selectedVerticalThickness = newValue);

    @observable selectedCustomThickness: number = 5;
    @action setSelectedCustomThickness = newValue => (this.selectedCustomThickness = newValue);

    @observable selectedBiteLocation: any = {};
    @action setSelectedBiteLocation = newValue => (this.selectedBiteLocation = newValue);

    @observable selectedUpperSystemScrewId: string = '';
    @action setSelectedUpperSystemScrewId = newValue => (this.selectedUpperSystemScrewId = newValue);

    @observable selectedUpperAnteriorWhipSprings: string = '';
    @action setSelectedUpperAnteriorWhipSprings = newValue => (this.selectedUpperAnteriorWhipSprings = newValue);

    @observable selectedLowerAnteriorWhipSprings: string = '';
    @action setSelectedLowerAnteriorWhipSprings = newValue => (this.selectedLowerAnteriorWhipSprings = newValue);

    @observable selectedReversePullAttachment: string = '';
    @action setSelectedReversePullAttachment = newValue => (this.selectedReversePullAttachment = newValue);

    @observable selectedUpperBandEntry: number[] = [];
    @action setSelectedUpperBandEntry = newValue => (this.selectedUpperBandEntry = newValue);

    @observable selectedLowerBandEntry: number[] = [];
    @action setSelectedLowerBandEntry = newValue => (this.selectedLowerBandEntry = newValue);

    @observable prescriptionStatusValues: DictionaryListItem[] = [];
    @action setPrescriptionStatusValues = (value: DictionaryListItem[]) => { this.prescriptionStatusValues = value; }

    @observable qcStatusValues: DictionaryListItem[] = [];
    @action setQCStatusValues = (value: DictionaryListItem[]) => { this.qcStatusValues = value; }

    @observable modelStatusValues: DictionaryListItem[] = [];
    @action setModelStatusValues = (value: DictionaryListItem[]) => { this.modelStatusValues = value; }

    @observable physicalToDigitalStatusValues: DictionaryListItem[] = [];
    @action setPhysicalToDigitalStatusValues = (value: DictionaryListItem[]) => { this.physicalToDigitalStatusValues = value; }

    @observable productionStatusValues: DictionaryListItem[] = [];
    @action setProductionStatusValues = (value: DictionaryListItem[]) => { this.productionStatusValues = value; }

    @observable digitalDesignStatusValues: DictionaryListItem[] = [];
    @action setDigitalDesignStatusValues = (value: DictionaryListItem[]) => { this.digitalDesignStatusValues = value; }

    @observable labModelStatusValues: DictionaryListItem[] = [];
    @action setLabModelStatusValues = (value: DictionaryListItem[]) => { this.labModelStatusValues = value; }

    @observable biteStatusValues: DictionaryListItem[] = [];
    @action setBiteStatusValues = (value: DictionaryListItem[]) => { this.biteStatusValues = value; }

    @observable shippingStatusValues: DictionaryListItem[] = [];
    @action setShippingStatusValues = (value: DictionaryListItem[]) => { this.shippingStatusValues = value; }

    @observable showRxStatusDetails: boolean = false;
    @action setShowRxStatusDetails = (value: boolean) => { this.showRxStatusDetails = value; }


    @observable fabricationTimeTypes: DictionaryListItem[] = [];
    @action setFabricationTimeTypes = (value: DictionaryListItem[]) => { this.fabricationTimeTypes = value; }

    @observable checkoutExternalId: string = '';
    @action setCheckoutExternalId = (checkoutId: string) => {
        this.checkoutExternalId = checkoutId;
    }

    @observable appliance: Appliance = new Appliance();
    @action setAppliance = (newValue: Appliance) => {
        this.appliance = newValue;
    }

    @observable dentalPiecesMap = new Map<any, { teethNumbers: number[], arch: string }>();

    @action reset = () => {
        this.setSelectedLab(defaultLab);
        this.setSelectedAppliance(new ApplianceFamily());
        this.setFabricationTime('Standard');
        this.setApplianceConstructionDiagramFilename(null);
        this.setCurrentPatient(defaultPatientValues);
        this.setVersaTypeSelected('Versa 9');
        this.setConstructionBite('Physical');
        this.setConstructionBiteType('Natural');
        this.setProtrusionPosition('Left');
        this.setProtrusionDistance(0);
        this.setModelSource('Physical');
        this.setLabAdjustBite(true);
        this.setDigitalConstructionBiteEmbedded(true);
        this.setShippingMethodSelected('Standard');
        this.setSpeakWithLab(false);
        this.setSelectedApplianceColor(null);
        this.setSelectedApplianceSize(null);
        this.setIsUpdating(false);
        this.setIsConstructionFormInProgress(false);
        this.setSelectedApplianceType(null);
        this.setConstructionComments('');
        this.setNeededByDate(null);
        this.appliance = new Appliance();
        //this.setSelectedApplianceSetup()
        //this.setApplianceSetups([]);
        this.setVariantsSelected({});
        this.clearProductsToCart();
        this.setSelectedeCommerceItem(new ApplianceItemSetup());
        this.setShouldCancelOrder(false);
        this.dentalPiecesMap.clear();
        this.prescription = new Prescription();
        this.step = 0;
        this.selectedUpperSystemScrewId = '';
        this.selectedUpperAnteriorWhipSprings = '';
        this.selectedLowerAnteriorWhipSprings = '';
        this.selectedReversePullAttachment = '';
        this.selectedUpperBandEntry = [];
        this.selectedLowerBandEntry = [];
    }

    loadPage = async (patientId: number) => {
        this.setIsLoading(true);
        this.setPatientId(patientId);
        const patient = await Gateway.get('/patient/' + patientId);
        this.setCurrentPatient(patient);
        const labs = await Gateway.get('/lab');
        this.setLabs(labs);
        this.setIsLoading(false);
    }
    @action
    setIsSavingPatient = (isLoading: boolean) => {
        this.isSavingPatient = isLoading;
    };

    @action
    setIsSavingPrescription = (isLoading: boolean) => {
        this.isSavingPrescription = isLoading;
    };

    currentRowToPerson = (currentRow: any) => {
        var person = {
            id: currentRow.id,
            orgId: currentRow.organizationId,
            providerId: currentRow.providerId,
            vivosId: currentRow.vivosId,
            firstName: currentRow.firstName,
            middleName: currentRow.middleName,
            lastName: currentRow.lastName,
            dateOfBirth: currentRow.dateOfBirth,
            ethnicityId: currentRow.ethnicityId,
            isProspect: currentRow.isProspect,
            isLincare: currentRow.isLincare,
            genderId: currentRow.genderId,
            ehrsId: currentRow.ehrsId,
            primaryPhone: currentRow.primaryPhone,
            altPhone: currentRow.altPhone,
            emailAddress: currentRow.emailAddress,
            isActive: currentRow.person?.isActive ?? true,
            personTypeId: currentRow.personTypeId
        };
        return person;
    };

    @action
    updatePerson = async (currentRow: any, patientId: number) => {

        var person = this.currentRowToPerson(currentRow);
        await Gateway.post(`/patient/person/${patientId}`, person);
        this.setIsSavingPatient(false);
    };

    @action setApprovalLincareCode = async (rxId: number, approvalCode: string): Promise<boolean> => {
        var objectToSend = { RxId: rxId, ApprovalCode: approvalCode };
        var result = await Gateway.postStrongType<boolean>('/prescription/SetLincareApprovalCode', objectToSend);
        return result;
    }



    @action createPrescription = async (visitId?: number): Promise<Prescription> => {
        await this.loadFabricationTimeTypes();
        this.appliance.applianceType = this.selectedeCommerceItem.applianceType;
        this.appliance.patientId = this.patientId;
        this.appliance.colorId = this.selectedApplianceColor?.id;
        this.appliance.sizeId = this.selectedApplianceSize?.id;
        this.appliance.name = this.selectedeCommerceItem.applianceTypeNavigation?.name ?? '';
        this.appliance.verticalThicknessId = this.selectedVerticalThickness?.id;
        this.appliance.customThickness = this.selectedCustomThickness;
        this.appliance.unilateralBiteBlockId = this.selectedBiteLocation?.id;

        this.assignDentalPieces();

        var newPrescription = {
            "Id": 0,
            "RxId": this.currentPatient.vivosId,
            "LabId": this.selectedLab.id,
            "IsActive": true,
            "IsLincarePrescription": this.currentPatient.isLincare,
            "PatientId": this.patientId,
            "ProviderId": this.ProviderID,
            "SubmittedDate": Date.now(),
            "DiagramURL": this.applianceConstructionDiagramFilename,
            "ConstructionComments": this.constructionComments,
            "VisitId": visitId,
            "Appliance": this.appliance,
            "FabricationTime": this.fabricationTimeTypes.find(x => x.name === this.fabricationTime)?.id,
            "NeededByDate": this.neededByDate,
            "Order": {
                "OrderStatusId": OrderStatus.Placed,
                "PatientId": this.patientId,
                "ExternalEcommerceCheckoutId": this.checkoutExternalId,
                "OrderDetails": this.productsToAdd.map((item, ind, arr) => {
                    return ({
                        "ExternalECommerceItemId": item.variantSelectedShopify?.id,
                        "ExternalECommerceTitle": item.variantSelectedShopify?.title,
                        "Price": item.variantSelectedShopify?.price['amount'],
                        "Quantity": item.variantSelectedShopify?.quantity ? item.variantSelectedShopify?.quantity : 1
                    });
                })
            },
            "ConnectedRxRework": this.connectedRxRework
        }
        var result = await Gateway.postStrongType<Prescription>(`/prescription/${this.userID}`, newPrescription);
        this.setPrescription(result);
        return result;
    }

    @action
    clonePrescription = async (prescriptionId: number): Promise<Prescription> => {
        var result = await Gateway.postStrongType<Prescription>(`/prescription/${prescriptionId}/clone/${this.userID}`, {});
        return result;
    }

    assignDentalPieces = () => {
        var dentalPiece;
        this.dentalPiecesMap.forEach((dentalPieceMap, key) => {
            dentalPieceMap.teethNumbers.forEach(teeth => {
                dentalPiece = {
                    partId: key.split(',')[0],
                    toothBegin: teeth,
                    arch: dentalPieceMap.arch
                }
                this.appliance.dentalPieces.push(dentalPiece);
            });
        });
    }

    populateDentalPiecesMap = () => {
        // Clear the existing map before populating it
        this.dentalPiecesMap.clear();

        // Loop through each dentalPiece in appliance.dentalPieces
        this.appliance.dentalPieces.forEach(dentalPiece => {
            const { partId, toothBegin, arch } = dentalPiece;

            if (toothBegin) {
                // Construct the key as 'partId,arch' (e.g., '1,Upper')
                const key = `${partId},${arch}`;

                // If the key already exists in dentalPiecesMap, add the tooth to the existing array
                if (this.dentalPiecesMap.has(key)) {
                    this.dentalPiecesMap.get(key)?.teethNumbers.push(toothBegin);
                } else {
                    // If the key does not exist, create a new entry with the current toothBegin
                    this.dentalPiecesMap.set(key, {
                        teethNumbers: [toothBegin],
                        arch: arch
                    });
                }
            }
        });
    };


    @action cancelPrescription = async () => {
        /* this.prescription.labId = this.selectedLab.id; */
        /* var prescription = {
            "Id": this.prescription.id,
            "RxId": this.currentPatient.vivosId,
            "PatientId": this.patientId,
            "LabId": this.selectedLab.id,
            "ProviderId": this.currentPatient.providerId,
            "IsActive": true,
            "Order": {
                "ExternalECommerceOrderId": this.prescription.externalECommerceOrderId
            }
        } */
        try {
            let prescription = await Gateway.putStrongType<Prescription>(`/prescription/${this.userID}/cancel`, this.prescription);
            this.getPrescription(this.prescription.id);
        } catch (error) {
            console.log(error);
        }

    }

    @action updateQcStatus = async (newStatusId: number) => {
        this.setPrescription(await Gateway.putStrongType<Prescription>(`/prescription/${this.userID}/${this.prescription.id}/qcStatus/${newStatusId}`, null));
    }

    @action updatePrescription = async (checkout?: any, isEdit?, visitId?: number) => {
        await this.loadFabricationTimeTypes();
        if (_.isUndefined(checkout?.order?.orderNumber) || _.isNull(checkout?.order?.orderNumber)) {
        } else {
            if (this.prescription.order) {
                this.prescription.order.externalEcommerceOrderId = checkout.order.id;
                this.prescription.order.externalEcommerceOrderNumber = checkout.order.orderNumber.toString();
            }
            _.forEach(checkout.order.lineItems, lineItem => {
                var orderDetail = new OrderDetail();
                orderDetail.externalEcommerceItemId = lineItem.variant.id;
                orderDetail.externalEcommerceTitle = lineItem.variant.title;
                this.prescription.order?.orderDetails.push(orderDetail);
            })
        }
        if (isEdit) {
            this.prescription.labId = this.selectedLab.id ?? this.prescription.labId;
            this.prescription.isLincarePrescription = this.currentPatient.isLincare ?? this.prescription.isLincarePrescription;
            this.prescription.diagramUrl = this.applianceConstructionDiagramFilename ?? this.prescription.diagramUrl;
            this.prescription.neededByDate = this.neededByDate ?? this.prescription.neededByDate;
            this.prescription.visitId = visitId ?? null;
            if (this.prescription.appliance) {
                this.prescription.appliance.applianceType = this.selectedeCommerceItem.ecommerceItem?.constructionTypeId ?? this.prescription.appliance?.applianceType;
                this.prescription.appliance.name = this.selectedeCommerceItem && this.selectedeCommerceItem.applianceTypeNavigation ? this.selectedeCommerceItem.applianceTypeNavigation.name : this.prescription.appliance?.name;
                //this.appliance.applianceType = this.selectedeCommerceItem.applianceType;
                //this.appliance.patientId = this.patientId;
                this.appliance.colorId = this.selectedApplianceColor?.id;
                this.appliance.sizeId = this.selectedApplianceSize?.id;
                this.appliance.name = this.selectedeCommerceItem.applianceTypeNavigation?.name ?? '';
                this.appliance.verticalThicknessId = this.selectedVerticalThickness?.id;
                this.appliance.customThickness = this.selectedCustomThickness;
                this.appliance.unilateralBiteBlockId = this.selectedBiteLocation?.id;
                //await this.cancelPrescription();
            }
            this.appliance.dentalPieces = [];
            this.prescription.connectedRxRework = this.connectedRxRework ? this.connectedRxRework : '';
            this.assignDentalPieces();

        }
        await Gateway.putStrongType<Prescription>(`/prescription/${this.userID}`, this.prescription)
        await this.getPrescription(this.prescription.id);
    }

    /* @observable isEdit: boolean = false;

    @action setIsEdit = (newEdit: boolean) =>{
        this.isEdit = newEdit;
    } */

    @action
    getPrescriptionsByPatientId = async (patientId) => {
        this.prescriptionData.length = 0;
        runInAction(() => {
            this.isLoadingPrescriptions = true;
        });
        var url = `/prescription/patient/${patientId}`;
        const resp = await Gateway.get(url);

        runInAction(() => {
            this.isLoadingPrescriptions = false;
            this.prescriptionData = resp.map(data => {
                return data;
            });
        });
    };

    @action
    getPrescriptionsByPatientIdGrid = async (patientId) => {
        this.prescriptionData.length = 0;
        runInAction(() => {
            this.isLoadingPrescriptions = true;
        });
        var url = `/prescription/patient/grid/${patientId}`;
        const resp = await Gateway.get(url);

        runInAction(() => {
            this.isLoadingPrescriptions = false;
            this.prescriptionData = resp.map(data => {
                return data;
            });
        });
    };

    @action
    getUserId = () => {
        return this.userID;
    }

    @action
    getPrescription = async (prescriptionId) => {
        var url = `/prescription/${prescriptionId}`;
        this.setPrescription(await Gateway.getStrongType<Prescription>(url));
    };

    @action
    getProductVariantByOrder = async (orderId) => {
        var url = `/prescription/productVariant/order/${orderId}/shopify`;
        var productVariant = await Gateway.get(url);
        this.setCurrentProductVariant(productVariant);

    };

    @action
    loadAllPrescriptionsListItemValues = async () => {
        this.loadGuideSeries();
        this.loadVidaConstructionValues();
        this.loadPrescriptionStatusValues();
        this.loadQCStatusValues();
        this.loadModelStatusValues();
        this.loadPhysicalToDigitalStatusValues();
        this.loadProductionStatusValues();
        this.loadDigitalDesignStatusValues();
        this.loadFabricationTimeTypes();

        //TODO it should use the labmodelstatus instead of the labclaimstatus
        this.loadLabClaimMaterialModelStatusValues();
        this.loadBiteStatusValues();
        this.loadShippingStatusValues();
    }

    @action
    loadGuideSeries = async () => {
        GlobalUtils.getApplianceSizes().then(resp => {
            this.setApplianceSizes(resp);
        });
        GlobalUtils.getApplianceColors().then(resp => {
            this.setApplianceColors(resp);
        });
    }

    @action
    loadVidaConstructionValues = async () => {
        GlobalUtils.getVerticalThickness().then(resp => {
            this.setVerticalThickness(resp);
        })
        GlobalUtils.getUnilateralBiteBlock().then(resp => {
            this.setUnilateralBiteBlock(resp);
        });
    }

    @action
    loadFabricationTimeTypes = async () => {
        GlobalUtils.getFabricationTimeTypes().then(resp => {
            this.setFabricationTimeTypes(resp);
        })
    }

    @action
    loadPrescriptionStatusValues = async () => {
        GlobalUtils.getPrescriptionStatus().then(resp => {
            this.setPrescriptionStatusValues(resp);
        })
    };

    @action
    loadQCStatusValues = async () => {
        GlobalUtils.getQCStatus().then(resp => {
            this.setQCStatusValues(resp);
        })
    };

    @action
    loadModelStatusValues = async () => {
        GlobalUtils.getModelStatus().then(resp => {
            this.setModelStatusValues(resp);
        })
    };

    @action
    loadPhysicalToDigitalStatusValues = async () => {
        GlobalUtils.getPhysicalToDigitalStatus().then(resp => {
            this.setPhysicalToDigitalStatusValues(resp);
        })
    };

    @action
    loadProductionStatusValues = async () => {
        GlobalUtils.getProductionStatus().then(resp => {
            this.setProductionStatusValues(resp);
        })
    };

    @action
    loadDigitalDesignStatusValues = async () => {
        GlobalUtils.getDigitalDesignStatus().then(resp => {
            this.setDigitalDesignStatusValues(resp);
        })
    };

    @action
    loadLabClaimMaterialModelStatusValues = async () => {
        GlobalUtils.getLabClaimMaterialModelStatus().then(resp => {
            this.setLabModelStatusValues(resp);
        })
    };

    @action
    loadBiteStatusValues = async () => {
        GlobalUtils.getBiteStatus().then(resp => {
            this.setBiteStatusValues(resp);
        })
    };

    @action
    loadShippingStatusValues = async () => {
        GlobalUtils.getShippingStatus().then(resp => {
            this.setShippingStatusValues(resp);
        })
    };

    @action
    setGuidesAttributes = async () => {
        this.setSelectedApplianceSize(this.applianceSizes.find(x => x.id === this.prescription.appliance?.sizeId));
        this.setSelectedApplianceColor(this.applianceColors.find(x => x.id === this.prescription.appliance?.colorId));
    }

    @action
    setConstructionAttributes = async () => {
        this.setSelectedVerticalThickness(this.verticalThickness.find(x => x.id === this.prescription.appliance?.verticalThicknessId));
        this.setSelectedBiteLocation(this.unilateralBiteBlock.find(x => x.id === this.prescription.appliance?.unilateralBiteBlockId));
        this.setSelectedCustomThickness(this.prescription.appliance?.customThickness);
    }

    @action
    setPexConstructionAttributes = async (dentalPartsValues: any[]) => {
        this.setSelectedUpperSystemScrewId(this.appliance.applianceSystemUpperSystemScrewId);
        this.setSelectedUpperAnteriorWhipSprings(this.appliance.appliancesystemUppersystemUpperAnteriorWhipLapBool);
        this.setSelectedLowerAnteriorWhipSprings(this.appliance.appliancesystemLowersystemLowerAnteriorWhipLapBool);
        this.setSelectedReversePullAttachment(this.appliance.applianceSystemUpperSystemReversePullFacemaskAttachmentsBool);

        const upperBandId = dentalPartsValues.find(part => part.partName === 'Band')?.id ?? -1;
        const upperBandEntry = this.dentalPiecesMap.get(`${upperBandId},Upper`);
        this.setSelectedUpperBandEntry(upperBandEntry?.teethNumbers || []);

        const lowerBandId = dentalPartsValues.find(part => part.partName === 'Band')?.id ?? -1;
        const lowerBandEntry = this.dentalPiecesMap.get(`${lowerBandId},Lower`);
        this.setSelectedLowerBandEntry(lowerBandEntry?.teethNumbers || []);
    }

    @observable fabricationTime = 'Standard';
    @action setFabricationTime = (value: string) => {
        this.fabricationTime = value;
    };

    @action optionChecked = (options: number, option: number): boolean => {
        return (options & option) === option;
    }

    @observable patientHistory = new PatientHistory(defaultHistory);
    @action setPatientHistory = (value: PatientHistory) => {
        this.patientHistory = value;
    };

    @observable allergyOptions = this.patientHistory.allergyOptions;
    @action setAllergyOptions = (newValue: number) => { this.allergyOptions = newValue }

    @action allergyhxOnChange = (event, value) => {
        if (event.target.checked) {
            this.setAllergyOptions(this.allergyOptions | Number.parseInt(value));
        } else {
            this.setAllergyOptions(this.allergyOptions ^ Number.parseInt(value));
        }
    }

    /*   @observable selectedApplianceSetup: RxApplianceOption | undefined = undefined;
  
      @action setSelectedApplianceSetup = (newSetup: RxApplianceOption | undefined) => {
          this.selectedApplianceSetup = newSetup;
      }
  
      @observable applianceSetups: RxApplianceOption[] = [];
  
      @action setApplianceSetups = (newApplainceSetup: RxApplianceOption[]) => {
          this.applianceSetups = newApplainceSetup;
      } */

    @observable variantsSelected: Record<string, any> = {};

    @action setVariantsSelected = (newVariants: Record<string, any>) => {
        this.variantsSelected = newVariants;
    }

    @observable productsToAdd: ECommerceShopifyItem[] = [];

    @action addProductToCart = (newProduct: ECommerceShopifyItem) => {
        if (!newProduct.alreadyAdded) {
            this.productsToAdd.push(newProduct);
            newProduct.alreadyAdded = true;
        }

    }

    @action clearProductsToCart = () => {
        this.productsToAdd = [];
    }

    @observable applianceFamiliesSetups: ApplianceFamily[] = [];

    @action setApplianceFamiliesSetups = (newApplianceFamiliesSetups: ApplianceFamily[]) => {
        this.applianceFamiliesSetups = newApplianceFamiliesSetups;
    }

    @observable selectedeCommerceItem: ApplianceItemSetup = new ApplianceItemSetup();

    @action setSelectedeCommerceItem = (newItem: ApplianceItemSetup) => {
        this.selectedeCommerceItem = newItem;
    }

    @observable providerType: string = '';
    @action setProviderType = (newType: string) => {
        this.providerType = newType;
    }

    loadECommerceItems: () => Promise<ApplianceFamily[] | null>;

    private async loadECommerceItemsAction(): Promise<ApplianceFamily[] | null> {
        return Gateway.getStrongType<ApplianceFamily[] | null>(rootPathECommerce);
    }

    @observable selectedApplianceLogo: string = '';
    @observable selectedLabLogo: string = '';

    @action setSelectedApplianceLogo = (logo: string) => {
        this.selectedApplianceLogo = logo;
    }

    @action setSelectedLabLogo = (logo: string) => {
        this.selectedLabLogo = logo;
    }

    @observable selectedConstructionType: string = '';
    @action setSelectedConstructionType = (newType: string) => {
        this.selectedConstructionType = newType;
    }

    @observable protrusionPositionValues: DictionaryListItem[] = []
    @observable constructionBiteTypeValues: DictionaryListItem[] = [];
    @observable upperBaseValues: DictionaryListItem[] = [];
    @observable lowerBaseValues: DictionaryListItem[] = [];
    @observable upperBowValues: DictionaryListItem[] = [];
    @observable lowerBowValues: DictionaryListItem[] = [];
    @observable mandibularAdvancementValues: DictionaryListItem[] = [];
    @observable materialBaseValues: DictionaryListItem[] = [];
    @observable upperScrewValues: DictionaryListItem[] = [];
    @observable lowerScrewValues: DictionaryListItem[] = [];
    @observable dentalPartValues: any[] = [];

    @action loadPrintRxValues = async () => {
        this.protrusionPositionValues = await GlobalUtils.getPotrusionPosition();
        this.constructionBiteTypeValues = await GlobalUtils.getConstructionBiteType();
        this.dentalPartValues = await Gateway.get('/prescription/appliance/parts');
        this.upperBaseValues = await GlobalUtils.getApplianceUpperBase();
        this.lowerBaseValues = await GlobalUtils.getApplianceLowerBase();
        this.upperBowValues = await GlobalUtils.getApplianceUpperBow();
        this.lowerBowValues = await GlobalUtils.getApplianceLowerBow();
        this.mandibularAdvancementValues = await GlobalUtils.getApplianceUpperMandibularAdvancement();
        this.upperScrewValues = await GlobalUtils.getApplianceUpperScrew();
        this.lowerScrewValues = await GlobalUtils.getApplianceLowerScrew();
        this.materialBaseValues = await GlobalUtils.getMaterialBase();
    }
}

