import { observable } from 'mobx';
import { ApplianceItemSetup } from './applianceItemSetup';
import { familyProviderTypeSetups } from './familyProviderType';
import { familyCountrySetup } from './familyCountrySetups';

export class ApplianceFamily {
    @observable id: number = 0;
    @observable name: string = '';
    @observable imageUrl: string = '';
    @observable labSelection: boolean = false;
    @observable teethCharting: boolean = false;
    @observable construction: boolean = false;
    @observable bitesModels: boolean = false;
    @observable defaultLabSelected: number = 0;
    @observable standardProductionDays: number = 0;
    @observable rushProductionDays: number = 0;
    @observable applianceItemSetups: ApplianceItemSetup[] = [];
    @observable defaultLabSelectedNavigation: any = null; // Add type as needed
    @observable familyProviderTypeSetups:familyProviderTypeSetups[] = [];
    @observable familyCountrySetups: familyCountrySetup[] = [];
}