// Vendors
import React, { useRef, useState, Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigateFunction } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuList,
  alpha,
  IconButton,
  MenuItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';

// Components 
import LeftNavBL from '../../../../components/LeftNav/LeftNavBL';
import { ProviderSettings } from '../../../../modals/ProviderSettings/ProviderSettings';
import ModalContainer from '../../../../components/ModalContainer';

// API
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 90%;
        width: 90%;
        border-radius: ${theme.general.borderRadiusLg};
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

// Functional Component Wrapper
const HeaderUserboxWrapper = () => {
  const navigate = useNavigate();
  return <HeaderUserbox navigate={navigate} />;
}

interface HeaderUserboxProps {
  navigate: NavigateFunction;
}
// interface LeftNavProps {
//   bl?: any;
// }
// const [isOpen, setOpen] = useState<boolean>(false);

// const handleOpen = (): void => {
//   setOpen(true);
// };

// const handleClose = (): void => {
//   setOpen(false);
// };

// const ref = useRef<any>(null);


@observer
class HeaderUserbox extends Component<HeaderUserboxProps> {
  @observable hasloaded: boolean = false;
  @observable toggleShowProviderSettings: boolean = false;
  @observable toggleShowHeaderUserBox: boolean = false;

  @action
  handleShowProviderSettings = () => {
    this.toggleShowProviderSettings = !this.toggleShowProviderSettings;
  };

  @action
  handleShowHeaderUserBox = () => {
    this.toggleShowHeaderUserBox = !this.toggleShowHeaderUserBox;
  }

  @action
  handleBothActions = () => {
    this.toggleShowProviderSettings = !this.toggleShowProviderSettings;
    this.toggleShowHeaderUserBox = !this.toggleShowHeaderUserBox;
  }

  ref: React.RefObject<any>;
  leftNavBL: LeftNavBL;

  constructor(props: any) {
    super(props);
    makeObservable(this);
    this.leftNavBL = new LeftNavBL();
    this.ref = React.createRef();
  }

  handleSignOut = async (): Promise<void> => {
    try {
      await sessionStorage.removeItem('token');
      this.props.navigate('/?logout=true');
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    return (
      <>
        <UserBoxButton color="primary" ref={this.ref} onClick={this.handleShowHeaderUserBox}>
          {/* <UserAvatar alt={this.leftNavBL.username} src={user.avatar} /> */}
          <UserAvatar alt={this.leftNavBL.username} />
        </UserBoxButton>
        <Popover
          disableScrollLock
          anchorEl={this.ref.current}
          onClose={this.handleShowHeaderUserBox}
          open={this.toggleShowHeaderUserBox}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuUserBox
            sx={{
              minWidth: 210
            }}
            display="flex"
          >
            <Avatar variant="rounded" />
            <UserBoxText>
              <UserBoxLabel variant="body1">{this.leftNavBL.username}</UserBoxLabel>
              {/* <UserBoxDescription variant="body2">
                [Insert User Type]
              </UserBoxDescription> */}
            </UserBoxText>
          </MenuUserBox>
          <Divider
            sx={{
              mb: 0
            }}
          />
          <MenuListWrapperPrimary disablePadding >
            <MenuItem >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'h5'
                }}
                primary={('My Account')}
                onClick={this.handleBothActions}
              />
              <ChevronRightTwoToneIcon
                sx={{
                  color: (theme) => theme.colors.alpha.black[30],
                  opacity: 0.8
                }}
              />
            </MenuItem>
          </MenuListWrapperPrimary>
          <Divider />
          <Box m={1}>
            <Button color="primary" fullWidth onClick={this.handleSignOut}>
              <LockOpenTwoToneIcon
                sx={{
                  mr: 1
                }}
              />
              Sign out
            </Button>
          </Box>
        </Popover>
        {this.toggleShowProviderSettings && <ModalContainer
          show={this.toggleShowProviderSettings}
          noScroll={true}
          title="Provider Settings"
          hideTitlebar={false}
          onClose={() => { this.handleShowProviderSettings() }}
          onClick={() => { this.handleShowProviderSettings() }}
        >
          <ProviderSettings />
        </ModalContainer>}
      </>
    );
  }
}

export default HeaderUserboxWrapper;
