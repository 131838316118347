import { action, makeAutoObservable } from "mobx";
import { Provider } from "../../entities/Provider";
import ProviderPatient from "../../entities/ProviderPatient";
import { MainStore } from "./MainStore";
import * as _ from 'lodash';
import { Gateway } from "../../api/Gateway";
import { Buffer } from 'buffer';
import PatientVisit from "../../entities/PatientVisit";
import Patient from "../../entities/Patient";

export default class UserCustomFormStore {
    mainStore: MainStore;

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
    }

    public provider: Provider = {} as Provider;
    public patient: ProviderPatient = {} as ProviderPatient;
    public customForm: any = {};
    public visit: PatientVisit = {} as PatientVisit;
    private patientId: number = 0;
    private providerId: number = 0;
    private customFormId: number = 0;
    private visitId: number = 0;

    @action setProvider = (newValue: Provider) => { this.provider = newValue };
    @action setPatient = (newValue: ProviderPatient) => { this.patient = newValue };
    @action setCustomForm = (newValue: any) => { this.customForm = newValue };
    @action setVisit = (newValue: PatientVisit) => { this.visit = newValue };

    @action decodeAndLoad = (param: string) => {
        var data = Buffer.from(param, 'base64').toString().split('&');
        _.forEach(data, (d) => {
            var q = d.split('=');
            switch (q[0].toLowerCase()) {
                case "patientid": this.patientId = Number(q[1]); break;
                case "providerid": this.providerId = Number(q[1]); break;
                case "customformid": this.customFormId = Number(q[1]); break;
                case "visitid": this.visitId = Number(q[1]); break;
            }
        });

        Gateway.get(`patient/${this.patientId}`).then((result) => {
            this.setPatient(result as unknown as ProviderPatient);
        });

        Gateway.get('visitrecord/' + this.patientId + '/visit/' + this.visitId).then(resp => {
            this.setVisit(resp as unknown as PatientVisit);
        });

        Gateway.get(`provider/${this.providerId}`).then((result) => {
            this.setProvider(result as unknown as Provider);
        });

        Gateway.get(`Questionnaire/${this.customFormId}`).then((result) => {
            var form = JSON.parse(JSON.stringify(result).replaceAll("elements", "questions"));
            this.setCustomForm(form);
        });
    }

    @action saveResponses = (form: any) => {
        var url = `questionnaire/saveQuestionnaireResponse/${this.patientId}/0/${this.visitId}`;
        _.forEach(this.customForm.pages, (page) => {

            _.forEach(page.questions, (question) => {
                var match = _.find(Object.keys(form), (i) => {
                    return i.toString() === question.id.toString();
                })

                if (match) {
                    question.response = JSON.stringify(form[match]).replaceAll("\"", "");
                }
            })
            page.elements = page.questions;
            page.questions = [];
        })
        Gateway.post(url, this.customForm).then(() => {
        })
    }
}    