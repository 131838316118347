import React, { Fragment, useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    ListItemButton,
    useTheme,
    ListItemText,
    Grid,
    alpha,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    Link as MuiLink,
    CardHeader,
    CardContent,
    List,
} from '@mui/material';
import { Masonry } from '@mui/lab';
import {
    careOrderingRequirements,
    editRxTutorial,
    manageRxOrders,
    orderRxDoc,
    pexRemakePolicy,
    submitRepairDoc,
} from './Helper/documents';

const BoxWrapper = styled(Box)(
    ({ theme }) => `
        width: 100%;
        background: ${theme.colors.alpha.black[10]};
        border-radius: ${theme.general.borderRadius};
        padding: ${theme.spacing(3)};
    `
);

const ListItemButtonWrapper = styled(ListItemButton)(
    ({ theme }) => `
        transition: ${theme.transitions.create(['transform', 'box-shadow'])};
        transform: scale(1);
        background: ${theme.colors.alpha.white[100]};
        position: relative;
        z-index: 5;
  
        &:hover {
            border-radius: ${theme.general.borderRadius};
            background: ${theme.colors.alpha.white[100]};
            z-index: 6;
            box-shadow: 
                0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)};
            transform: scale(1.05);
        }
    `
);

const allProviderTypes = [
    'VIP',
    'Lifeline',
    'Guided Growth and Development',
    'C.A.R.E',
    'Airway Health Solutions',
    'Sleep Well Journey',
    'Noum',
    'Lincare',
    'Certified Legacy Provider',
    'Colorado Sleep Institute',
    'Clinical Advisor',
];

const documentGroups = [
    {
        name: 'Submitting an RX',
        documents: [
            {
                id: 1,
                providerAccess: allProviderTypes,
                name: 'How to Order an RX - Quick Use Guide',
                link: orderRxDoc,
                type: 'link',
                description: '',
            },
            {
                id: 2,
                providerAccess: ['VIP', 'C.A.R.E', 'Colorado Sleep Institute', 'Clinical Advisor'],
                name: 'Prerequisites for ordering a CARE appliance',
                link: careOrderingRequirements,
                type: 'link',
                description: '',
            },
        ],
    },
    {
        name: 'Managing an RX',
        documents: [
            {
                id: 10,
                providerAccess: allProviderTypes,
                name: 'Managing an RX',
                link: manageRxOrders,
                type: 'link',
                description: '',
            },
            {
                id: 11,
                providerAccess: allProviderTypes,
                name: 'How to Add or Edit an RX',
                link: editRxTutorial,
                type: 'link',
                description: '',
            },
        ],
    },
    {
        name: 'Warranties and Repairs',
        documents: [
            {
                id: 21,
                providerAccess: [
                    'VIP',
                    'Lifeline',
                    'Guided Growth and Development',
                    'Certified Legacy Provider',
                    'Colorado Sleep Institute',
                    'Clinical Advisor',
                ],
                name: 'PEx Remake Policy',
                link: pexRemakePolicy,
                type: 'link',
                description: '',
            },
            {
                id: 22,
                providerAccess: allProviderTypes,
                name: 'How to submit a Rework Claim for warranties/repairs',
                link: submitRepairDoc,
                type: 'link',
                description: '',
            },
        ],
    },
    {
        name: 'Lab Addresses',
        documents: [
            {
                id: 31,
                name: 'Airway Intelligence Center (AIC)',
                description: `199 W Center Street\nOrem, UT 84058`,
            },
            {
                id: 32,
                name: 'Apex',
                description: `7863 La Mesa Blvd. Suite 204,\nLa Mesa, CA 91942`,
            },
            {
                id: 33,
                name: 'Aurum',
                description: `115 17 Ave. SW\nCalgary, AB T2S 0A1\nCanada`,
            },
            {
                id: 34,
                name: 'Five Star',
                description: `2928 Metro St #102,\nDenton, TX 76207`,
            },
            {
                id: 35,
                name: "Gergen's",
                description: `1745 W. Deer Valley Road\nBuilding 1, Suite 112\nPhoenix, AZ 85027`,
            },
            {
                id: 36,
                name: "John's Dental Lab (JDL)",
                description: `423 S 13th St.\nTerre Haute, IN 47807`,
            },
            {
                id: 37,
                name: "Space Maintainer's Lab (SML)",
                description: `9129 Lurline Ave\nChatsworth, CA 91311`,
            },
            {
                id: 38,
                name: 'STAA',
                description: `908, Kolong Digital Tower 2\n27 Seongsui-ro 7-gil, Seongdong-gu\nSeoul, 04783\nSouth Korea`,
            },
        ],
    },
];

function RxProcessingContent({ providerTypes, isProvider }) {
    const theme = useTheme();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const handleDocumentClick = (doc) => {
        if (doc.type === 'link') {
            window.open(doc.link, '_blank', 'noopener noreferrer');
        } else if (doc.type === 'popup') {
            setDialogContent(doc.description);
            setOpenDialog(true);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <BoxWrapper>
                        <Typography variant="body1" textAlign="center">
                            This page provides information on creating and managing patient appliance prescriptions,
                            including warranty and repair processes, and lab addresses.
                        </Typography>
                    </BoxWrapper>
                </Card>
            </Grid>
            {documentGroups.map((group, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            overflow: 'visible',
                            backgroundColor: theme.palette.background.default,
                            border: `1px solid ${theme.palette.divider}`,
                            boxShadow: `
                                0 0.56875rem 3.3rem ${alpha(theme.colors.alpha.black[100], 0.05)},
                                0 0.9975rem 2.4rem ${alpha(theme.colors.alpha.black[100], 0.07)},
                                0 0.35rem 1rem ${alpha(theme.colors.alpha.black[100], 0.1)},
                                0 0.225rem 0.8rem ${alpha(theme.colors.alpha.black[100], 0.15)}
                            `,
                            borderRadius: `${theme.shape.borderRadius}px`,
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '75px',
                                borderRadius: `${theme.shape.borderRadius}px`,
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.contrastText,
                            }}
                        >
                            <Typography variant="h5">{group.name}</Typography>
                        </Box>
                        <List sx={{ flexGrow: 1 }}>
                            {group.documents.map((doc) => (
                                <Fragment key={doc.id}>
                                    <Divider />
                                    <ListItemButtonWrapper
                                                                        sx={{
                                                                            display: { xs: 'block', sm: 'flex' },
                                                                            py: 2,
                                                                            px: 2.5,
                                                                        }}
                                                                        onClick={() => handleDocumentClick(doc)}
                                                                    >
                                                                        <ListItemText
                                                                            disableTypography
                                                                            primary={
                                                                                <Typography color="text.primary" variant="h5">
                                                                                    {doc.name}
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItemButtonWrapper>
                                </Fragment>
                            ))}
                        </List>
                    </Card>
                </Grid>
            ))}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    <Typography variant="h5">{dialogContent}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ whiteSpace: 'pre-line' }}>{dialogContent}</Typography>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default RxProcessingContent;
