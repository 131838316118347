import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { MainStore } from "./MainStore";
import { Gateway } from "../../api/Gateway";
import jwt_decode from 'jwt-decode';
import { GlobalUtils } from "../../api/GlobalUtils";
import DictionaryListItem from "../../entities/DictionaryListItem";
import _ from "lodash";

export default class PatientLincare {

    constructor(mainstore: MainStore) {
        makeAutoObservable(this);
        this.mainStore = mainstore;
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; userFullName } = jwt_decode(token);
            this.userID = tokenData.id;
        }
    }

    @observable mainStore: MainStore;
    @observable pageTitle = "LinCare Patients Dashboard";
    @observable patientsData: any[] = [];
    @observable origPatientsData: any[] = [];
    @observable isLoadingPatients: boolean = false;
    @observable userID: number = 0;
    @observable isLeftNavExpanded: boolean = false;  
    @observable searchParam = '';
    @observable timeOutEvent: NodeJS.Timeout | null = null;
    @observable _toggleShowPatientEditModal: boolean = false;
    @observable _toggleShowApprovalCode: boolean = false;
    @observable _currentPatientId: number = 0;
    @observable patientData: any;
    @observable patientApprovalCode = '';
    @observable lincareStatusValues: DictionaryListItem[] = [];

    @action setPatientsData = (value: any[]) => { this.patientsData = value; }
    @action setPatientAppovalCode = (value: string) => { this.patientApprovalCode = value; }
    @action setOrigPatientsData = (value: any[]) => { this.origPatientsData = value; }
    @action setLincareStatusValues = (value: DictionaryListItem[]) => { this.lincareStatusValues = value; }

    @action toggleLeftNav = (isLeftNavExpanded: boolean) => {
        this.isLeftNavExpanded = isLeftNavExpanded;
    };

    @action setPatientData = (value: any) => this.patientData = value;

    get toggleShowPatientEditModal() {
        return this._toggleShowPatientEditModal;
    }

    @action
    set toggleShowPatientEditModal(value: any) {
        this._toggleShowPatientEditModal = value;
    }

    get toggleShowApprovalCodeModal() {
        return this._toggleShowApprovalCode;
    }

    @action
    set toggleShowApprovalCodeModal(value: any) {
        this._toggleShowApprovalCode = value;
    }

    get currentPatientId() {
        return this._currentPatientId;
    }

    @action
    set currentPatientId(value: any) {
        this._currentPatientId = value;
    }

    @action
    toggleEditPatientModal = () => {
        runInAction(() => {
            this.toggleShowPatientEditModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    toggleShowApprovalCodeModalAction = () => {
        runInAction(() => {
            this.toggleShowApprovalCodeModal = !this.toggleShowPatientEditModal;
        });
    };

    @action
    getPatients = async () => {
        this.patientsData.length = 0;
        runInAction(() => {
            this.isLoadingPatients = true;
        });

        const resp = await Gateway.get(`/patient/lincare`);
        runInAction(() => {
            this.isLoadingPatients = false;
            this.patientsData = resp.map(data => {
                let newPatient = {...data};
                newPatient.provider = data.providerName + ' ' + data.providerLastName;
                newPatient.lincareStatus = this.lincareStatusValues.find(x => x.id === data.lincareStatusId)?.name;
                return newPatient;
            });
        });
        this.origPatientsData = this.patientsData;
    };

    clearData = () => {
        this.patientsData = this.origPatientsData;
    };

    getSearchData = (searchParam: string): any => {
        this.searchParam = searchParam;
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.filterBySearchString();
                this.timeOutEvent = null;
            }, 500);
        }
        return this.patientsData;
    };

    @action filterBySearchString = () => {
        this.patientsData = this.origPatientsData.filter(
            x =>
                x.id.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.provider.toString().toLowerCase().includes(this.searchParam.toLowerCase()) ||
                x.vivosId.toString().toLowerCase().includes(this.searchParam.toLowerCase()) || 
                x.firstName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) || 
                x.lastName.toString().toLowerCase().includes(this.searchParam.toLowerCase()) || 
                x.lincareStatus.toString().toLowerCase().includes(this.searchParam.toLowerCase())
        );
    };

    @action loadLincareStatusValues = async () => {
        GlobalUtils.getLincareStatus().then(resp => {
            this.setLincareStatusValues(resp);
        })
    };

    handleLincareStatusChange = (lincareStatusId) => {
        var url = `/patient/lincareStatus`;
        var ind = -1;
        let patientStatusChange = {
            patientId: this.currentPatientId,
            lincareStatusId: lincareStatusId,
            lincareApprovalCode: this.patientApprovalCode,
            userId: this.userID
        }
        this.isLoadingPatients = true;
        Gateway.put(url, patientStatusChange).then(respData => {
            this.isLoadingPatients = false;
            if (respData) {
                ind = _.findIndex(this.patientsData, c => {
                    return c.id === respData['id'];
                });
                var newPatientsData = this.patientsData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: any = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.lincareStatusId = respData['lincareStatusId'];
                        copiedPatient.lincareStatus = this.lincareStatusValues.find(x => x.id === copiedPatient.lincareStatusId)?.name;                        
                    }
                    return [...newData, copiedPatient];
                }, [] as any[]);

                this.setPatientsData(newPatientsData);

                ind = _.findIndex(this.origPatientsData, c => {
                    return c.id === respData['id'];
                });
                newPatientsData = this.origPatientsData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: any = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.lincareStatusId = respData['lincareStatusId'];
                        copiedPatient.lincareStatus = this.lincareStatusValues.find(x => x.id === copiedPatient.lincareStatusId)?.name;
                    }
                    return [...newData, copiedPatient];
                }, [] as any[]);
                this.setOrigPatientsData(newPatientsData);
            }
        });
    };

    handleLincareActivation = (changedPatient) => {
        if (this.timeOutEvent !== null) {
            clearTimeout(this.timeOutEvent);
            this.timeOutEvent = setTimeout(() => {
                this.updateLincareActivation(changedPatient);
                this.timeOutEvent = null;
            }, 500);
        } else {
            this.timeOutEvent = setTimeout(() => {
                this.updateLincareActivation(changedPatient);
                this.timeOutEvent = null;
            }, 500);
        }
    };

    updateLincareActivation = (changedPatient) => {
        var url = `/patient/${changedPatient.id}/lincareActivation/${this.userID}`;
        var ind = -1;
        this.isLoadingPatients = true;
        Gateway.put(url, undefined).then(respData => {
            this.isLoadingPatients = false;
            if (respData) {
                ind = _.findIndex(this.patientsData, c => {
                    return c.id === respData['id'];
                });
                var newPatientsData = this.patientsData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: any = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.isLincareActive = respData['isLincareActive'];
                    }
                    return [...newData, copiedPatient];
                }, [] as any[]);

                this.setPatientsData(newPatientsData);

                ind = _.findIndex(this.origPatientsData, c => {
                    return c.id === respData['id'];
                });
                newPatientsData = this.origPatientsData.reduce((newData, currentPatient, index) => {
                    const copiedPatient: any = { ...currentPatient };
                    if (ind === index) {
                        copiedPatient.isLincareActive = respData['isLincareActive'];
                    }
                    return [...newData, copiedPatient];
                }, [] as any[]);
                this.setOrigPatientsData(newPatientsData);
            }
        });
    }
}

