import React, { Component, RefObject } from 'react';
import '../../scss/main.scss';
import NavItem from './NavItem';
import * as _ from 'lodash';
import { Button, Grid } from '@mui/material';
import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import LeftNavBL from './LeftNavBL';
import { WhatsApp, AttachEmail } from "@mui/icons-material";
import { MyoCorrectFilters, ReworkClaimFilters, TreatmentStatus, LabManagementFilters } from '../../entities/Enums';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ModalContainer from '../ModalContainer';
import { ProviderSettings } from '../../modals/ProviderSettings/ProviderSettings';

interface LeftNavProps {
   onToggle(toggleOn: boolean): any;
   defaultPage?: string;
   bl?: any;
}
@observer
export class LeftNav extends Component<LeftNavProps> {
   @observable toggleCollapse: boolean = false;
   @observable currentNavItem: number = 0;
   @observable hasloaded: boolean = false;
   @observable toggleShowProviderSettings: boolean = false;

   @action
   handleShowProviderSettings = () => {
      this.toggleShowProviderSettings = !this.toggleShowProviderSettings;
   };

   leftNavBL: LeftNavBL;
   leftNav: RefObject<HTMLDivElement>;

   constructor(props: any) {
      super(props);
      makeObservable(this);
      this.leftNav = React.createRef();
      this.leftNavBL = new LeftNavBL();
   }

   componentDidMount() {
      this.leftNavBL.checkRoles();
      if (this.props.defaultPage) {
         this.currentNavItem = _.indexOf(this.leftNavBL.navItems, this.props.defaultPage);
         this.leftNavBL.currentNavItem = this.currentNavItem;
      }

      if (this.leftNavBL.isProvider) {
         this.leftNavBL.getProviderData();
      }

      this.hasloaded = true;
   }

   render() {
      if (!this.hasloaded) {
         return null;
      }
      return (
         <Grid
            ref={this.leftNav}
            container
            direction="row"
            alignContent="flex-start"
            justifyItems={'center'}
            className={'nav' + (this.leftNavBL.toggleCollapse === true ? ' contract' : '')}
         >
            <Grid
               xl={12}
               container
               alignItems="center"
               justifyContent="center"
               className="leftnav-header"
            >
               <Grid onClick={this.toggleMenuCollapse}>
                  <div id="logo" />
               </Grid>
               <Grid justifyContent="center">
                  <Grid className="label" sx={{ paddingBottom: '10px' }}>
                     {!this.leftNavBL.toggleCollapse ?
                        <Grid>
                           {this.leftNavBL.isProvider && <Button onClick={() => { this.handleShowProviderSettings() }}>
                              <ManageAccountsIcon htmlColor='white' />
                           </Button>}
                           {this.leftNavBL.isProvider ? this.leftNavBL.providerFullName : this.leftNavBL.username}
                        </Grid>
                        :
                        <Grid>{''}</Grid>}
                  </Grid>
               </Grid>
            </Grid>
            <Grid container alignContent="flex-start" className="navSection">
               {this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={23}
                        text={this.leftNavBL.navItems[23]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(23)}
                        onAddClick={() => { }}
                        url={'/dashboard'}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        id={0}
                        text={this.leftNavBL.navItems[0]}
                        numItems={0}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        showAddNew="true"
                        addText="[+] ADD NEW PATIENT"
                        url={'/patients'}
                        selected={this.leftNavBL.getSelectedStatus(0)}
                        bl={this.props.bl}
                        subItems={[
                           { label: 'All Patients', filter: 0, count: 0 },
                           { label: 'Pending Action', filter: 'PendingAction', count: 0 },
                           { label: 'Pending Treatment', filter: 'PendingTreatment', count: 0 },
                           { label: 'Completed', filter: TreatmentStatus.Completed, count: 0 },
                           { label: 'Archive', filter: 'Archive', count: 0 },
                        ]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        onAddClick={this.props.bl ? this.props.bl.toggleAddPatientModal : () => { }}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isClinicalAdvisor ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        id={17}
                        text={this.leftNavBL.navItems[0]}
                        numItems={0}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        showAddNew="false"
                        addText=""
                        url={'/clinicaladvisor'}
                        selected={this.leftNavBL.getSelectedStatus(17)}
                        bl={this.props.bl}
                        subItems={[
                        ]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        onAddClick={() => { }}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isVivosAdmin ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={6}
                        text={this.leftNavBL.navItems[6]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(6)}
                        onAddClick={() => { }}
                        url="/admin"
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isProvider &&
                  <Grid>
                     <NavItem
                        isCollapsed={false}
                        url='/consultations'
                        selected={this.leftNavBL.getSelectedStatus(22)}
                        useLinks={true}
                        id={22}
                        subItems={[]}
                        text={this.leftNavBL.navItems[22]}
                        numItems={0}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        onAddClick={() => { }}
                     />
                  </Grid>}
               {this.leftNavBL.isProvider && this.leftNavBL.isClinicalAdvisor ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={6}
                        text={this.leftNavBL.navItems[6]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(6)}
                        onAddClick={() => { }}
                        url="/admin"
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {/* {this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={1}
                        text={this.leftNavBL.navItems[1]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(1)}
                        onAddClick={() => { }}
                        url={'/notifications'}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )} */}
               {this.leftNavBL.isVivosAdmin || this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={2}
                        text={this.leftNavBL.navItems[2]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(2)}
                        onAddClick={() => { }}
                        url={'/orders'}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={3}
                        text={this.leftNavBL.navItems[3]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(3)}
                        onAddClick={() => { }}
                        url={'/myPractice'}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isVivosAdmin || this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        id={12}
                        text={this.leftNavBL.navItems[12]}
                        numItems={0}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(12)}
                        onAddClick={() => { }}
                        url="/customForms"
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isProvider ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={4}
                        text={this.leftNavBL.navItems[4]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(4)}
                        onAddClick={() => { }}
                        url={'/reports'}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {(this.leftNavBL.isMyoCorrectAdmin && !this.leftNavBL.isMyoCorrectAdvisor) ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={8}
                        text={this.leftNavBL.navItems[8]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        bl={this.props.bl}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(8)}
                        onAddClick={() => { }}
                        url={'/myocorrect'}
                        subItems={[
                           { label: 'All Cases', filter: MyoCorrectFilters.AllCases, count: 0 },
                           { label: 'My Cases', filter: MyoCorrectFilters.MyCases, count: 0 },
                           { label: 'Completed', filter: MyoCorrectFilters.Completed, count: 0 },
                        ]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {(this.leftNavBL.isMyoCorrectAdvisor) ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={8}
                        text={this.leftNavBL.navItems[8]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        bl={this.props.bl}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.currentNavItem === 8}
                        onAddClick={() => { }}
                        url={'/myocorrect'}
                        subItems={[
                           { label: 'All Cases', filter: MyoCorrectFilters.AllCases, count: 0 },
                           { label: "My Team's Cases", filter: MyoCorrectFilters.MyTeamCases, count: 0 },
                           { label: 'My Cases', filter: MyoCorrectFilters.MyCases, count: 0 },
                           { label: 'Completed', filter: MyoCorrectFilters.Completed, count: 0 },
                        ]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isMyoCorrectAdvocate ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={8}
                        text={this.leftNavBL.navItems[8]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        bl={this.props.bl}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.currentNavItem === 8}
                        onAddClick={() => { }}
                        url={'/myocorrect'}
                        subItems={[
                           { label: 'All Cases', filter: MyoCorrectFilters.AllCases, count: 0 },
                           { label: 'Completed', filter: MyoCorrectFilters.Completed, count: 0 },
                        ]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isMyoCorrectAdmin ||
                  this.leftNavBL.isMyoCorrectAdvisor ||
                  this.leftNavBL.isMyoCorrectAdvocate ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={9}
                        text={this.leftNavBL.navItems[9]}
                        bl={this.props.bl}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.currentNavItem === 9}
                        onAddClick={() => { }}
                        url={''}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isMyoCorrectAdmin ||
                  this.leftNavBL.isMyoCorrectAdvisor ||
                  this.leftNavBL.isMyoCorrectAdvocate ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={10}
                        text={this.leftNavBL.navItems[10]}
                        bl={this.props.bl}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.currentNavItem === 10}
                        onAddClick={() => { }}
                        url={''}
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isClinicalAdvisor || this.leftNavBL.isClinicalAdvocate ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        id={12}
                        text={this.leftNavBL.navItems[18]}
                        numItems={0}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(18)}
                        onAddClick={() => { }}
                        url="/myNetwork"
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isMyoCorrectAdmin || this.leftNavBL.isAiReportAdmin ||
                  this.leftNavBL.isVivosAdmin || this.leftNavBL.isLabAdmin ||
                  this.leftNavBL.isAISAdminManager ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={13}
                        text={this.leftNavBL.navItems[13]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.currentNavItem === 13}
                        onAddClick={() => { }}
                        url="/admin/teams"
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isMyoCorrectAdmin ?
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={16}
                        text={this.leftNavBL.navItems[16]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.currentNavItem === 16}
                        onAddClick={() => { }}
                        url="/myocorrect/tools" subItems={[]}
                     /></Grid> : ""}
               {this.leftNavBL.isAiReportAdmin ? (
                  <Grid>
                     <NavItem
                        useLinks={true}
                        numItems={0}
                        id={14}
                        text={this.leftNavBL.navItems[14]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.currentNavItem === 0}
                        onAddClick={() => { }}
                        url="/pendingCases"
                        subItems={[]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isAISAdminManager || this.leftNavBL.isAISSupervisor || this.leftNavBL.isAISAdmin || this.leftNavBL.isAISSupervisor || this.leftNavBL.isExternalLab ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={19}
                        text={this.leftNavBL.navItems[19]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        bl={this.props.bl}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.currentNavItem === 19}
                        onAddClick={() => { }}
                        url={'/labmanagement'}
                        subItems={[
                           { label: 'All', filter: LabManagementFilters.All, count: 0 },
                           { label: 'New Prescriptions', filter: LabManagementFilters.NewPrescriptions, count: 0 },
                           { label: 'All Claims', filter: LabManagementFilters.AllClaims, count: 0 },
                           { label: 'Warranty', filter: LabManagementFilters.Warranty, count: 0 },
                           { label: 'Repair', filter: LabManagementFilters.Repair, count: 0 },
                           { label: 'Production Ready', filter: LabManagementFilters.ProductionReady, count: 0 },
                           { label: "In Fabrication", filter: LabManagementFilters.InFabrication, count: 0 },
                           { label: 'Shipped', filter: LabManagementFilters.Shipped, count: 0 },
                           { label: 'Cases on Queue Over 7 days', filter: LabManagementFilters.CasesOver7Days, count: 0 },
                        ]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isAISAdminManager || this.leftNavBL.isAISSupervisor || this.leftNavBL.isAISAdmin || this.leftNavBL.isComplianceManager || this.leftNavBL.isExternalLab ? (
                  <Grid>
                     <NavItem
                        useLinks={false}
                        numItems={0}
                        id={20}
                        text={this.leftNavBL.navItems[20]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        bl={this.props.bl}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        selected={this.leftNavBL.getSelectedStatus(20)}
                        onAddClick={() => { }}
                        url={'/reworkClaims'}
                        subItems={[
                           { label: 'Pending Claims', filter: ReworkClaimFilters.PendingApproval, count: 0 },
                           { label: 'Approved Claims', filter: ReworkClaimFilters.Approved, count: 0 },
                           { label: 'All Claims', filter: ReworkClaimFilters.AllClaims, count: 0 }
                        ]}
                     />
                  </Grid>
               ) : (
                  ''
               )}
               {this.leftNavBL.isProvider &&
                  (<Grid>
                     <NavItem
                        id={-12}
                        useLinks={true}
                        numItems={0}
                        text={this.leftNavBL.navItems[21]}
                        onClick={this.leftNavBL.onNavMenuItemClick}
                        onClickNew={() => { }}
                        isCollapsed={this.leftNavBL.toggleCollapse}
                        onAddClick={() => { }}
                        selected={this.leftNavBL.getSelectedStatus(21)}
                        url="/catalog"
                        subItems={[]}
                     />
                  </Grid>)
               }
               <Grid>
                  <NavItem
                     id={-1}
                     useLinks={true}
                     numItems={0}
                     text={this.leftNavBL.navItems[7]}
                     onClick={this.leftNavBL.onSignOut}
                     onClickNew={() => { }}
                     isCollapsed={this.leftNavBL.toggleCollapse}
                     onAddClick={() => { }}
                     selected={false}
                     url="/?logout=true"
                     subItems={[]}
                  />
               </Grid>
            </Grid>
            <Grid xl={12} className="pre-footer">
               <Grid><AttachEmail />&nbsp;&nbsp;Email: helpdesk@vivoslife.com</Grid>
               <Grid><WhatsApp />&nbsp;Phone: 720-399-9322</Grid>
            </Grid>
            <Grid xl={12} className="footer" justifyContent="center" alignItems="flex-end">
               <Grid className="label">&copy; 2021 Vivos, Inc | Terms &amp; Conditions</Grid>
            </Grid>

            {this.toggleShowProviderSettings && <ModalContainer
               show={this.toggleShowProviderSettings}
               noScroll={true}
               title="Provider Settings"
               hideTitlebar={true}
               onClose={() => { this.handleShowProviderSettings() }}
               onClick={() => { this.handleShowProviderSettings() }}
            >
               <ProviderSettings />
            </ModalContainer>}
         </Grid>
      );
   }

   toggleMenuCollapse = () => {
      this.leftNavBL.toggleMenuCollapse();
      this.props.onToggle(this.toggleCollapse);
   };
}
