// Vendors
import React, { Component } from 'react';
import { Grid, Tabs, Tab, styled, Box, Card } from '@mui/material';

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';

const TabsContainerWrapper = styled(Box)(
    ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      justifyContent: 'space-evenly',
    `
);

interface IGIProps {
    tabs: any;
    activeTab: number;
    onClick(index: number);
    onClose();
}

@observer
export class VisitHeader extends Component<IGIProps> {
    activeTab: number = 0;
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Grid container>
                <TabsContainerWrapper padding={1} width={'100%'}>
                    <Card sx={{
                        p: 1
                    }}>
                        <Grid container justifyContent="space-evenly">
                            <Tabs
                                value={this.props.activeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {_.map(this.props.tabs, (t, key: number) => (
                                    <Tab label={t} key={key} onClick={() => {
                                        this.props.onClick(key);
                                    }} />
                                ))}
                            </Tabs>
                        </Grid>
                    </Card>

                </TabsContainerWrapper>
            </Grid>
        );
    }
}
