// Vendors
import React from "react";
import { Grid, Box, Button, useTheme, Typography, TextField, MenuItem } from '@mui/material';

// Store
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';

// Entities
import { Allergies, Ethnicity, Gender } from '../../../entities/Enums';

// API
import { observer } from 'mobx-react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { usePrescriptionStore } from "../../../stores/Prescription/CreateEdit/MainStoreContext";
import { PrescriptionHeader } from "../../../components/Prescription/PrescriptionHeader";
import PrescriptionStore from "../../../stores/Prescription/PrescriptionStore";

const RxPatientInfo = () => {
    const genders = Object.values(Gender).filter(v => typeof (v) === 'string');
    const ethnicities = Object.values(Ethnicity).filter(v => typeof (v) === 'string');
    const {
        currentPatient,
        patientId,
        isSavingPatient,
        selectedAppliance,
        isUpdating,
        connectedRxRework,
        prescription,
        setSelectedAppliance,
        setCurrentPatient,
        setStep,
        updatePerson,
        setIsSavingPatient,
        setConnectedRxRework,
    } = usePrescriptionStore().prescriptionStore;
    const { allergyOptions, allergyhxOnChange, optionChecked, upsertPatientHistory } = useMainStoreContext().patientEditStore;

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.firstName = event.target.value;
        setCurrentPatient(patient);
    };
    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.lastName = event.target.value;
        setCurrentPatient(patient);
    };
    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.genderId = parseInt(event.target.value);
        setCurrentPatient(patient);
    };
    const handleEthnicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.ethnicityId = parseInt(event.target.value);
        setCurrentPatient(patient);
    };
    const handleDateOfBirthChange = (value: Date | null) => {
        if (!value) return;
        const patient = { ...currentPatient };
        patient.dateOfBirth = value;
        setCurrentPatient(patient);
    };
    const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.altPhone = event.target.value;
        setCurrentPatient(patient);
    };
    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.primaryPhone = event.target.value;
        setCurrentPatient(patient);
    }
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const patient = { ...currentPatient };
        patient.emailAddress = event.target.value;
        setCurrentPatient(patient);
    };

    const handleConnectedRxReworkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("connectedRxRework before", connectedRxRework);
        //console.log("received value", event.target.value);
        setConnectedRxRework(event.target.value);
        //console.log("connectedRxRework after", connectedRxRework);
    };

    const getAge = (dateOfBirth: Date): number => {
        const today = new Date();
        const date = new Date(dateOfBirth);
        let age = today.getFullYear() - date.getFullYear();
        const months = today.getMonth() - date.getMonth();
        if (months < 0 || (months === 0 && today.getDate() < date.getDate())) {
            age--;
        }
        return age;
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h2">Review Selected Patient</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Patient Information</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="first-name"
                    label="First Name"
                    fullWidth
                    value={currentPatient.firstName}
                    onChange={handleFirstNameChange}
                    disabled={isUpdating} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="last-name"
                    label="Last Name"
                    fullWidth
                    value={currentPatient.lastName}
                    onChange={handleLastNameChange}
                    disabled={isUpdating} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="gender"
                    select
                    label="Gender"
                    fullWidth
                    value={currentPatient.genderId}
                    onChange={handleGenderChange}
                    disabled={isUpdating}
                >
                    {genders.map((option) => (
                        <MenuItem key={Gender[option]} value={Gender[option]}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={3}>
                    <Grid item xs={8} sm={8}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date of Birth"
                                value={currentPatient.dateOfBirth ?? ''}
                                openTo="day"
                                disableFuture={true}
                                views={['year', 'month', 'day']}
                                renderInput={(params) => <TextField {...params} id="date-of-birth" label="Date of Birth" fullWidth value={currentPatient.dateOfBirth ?? ''} />}
                                onChange={handleDateOfBirthChange}
                                disabled={isUpdating}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextField
                            id="age"
                            label="Age"
                            fullWidth
                            disabled={isUpdating}
                            value={currentPatient.dateOfBirth ? getAge(currentPatient.dateOfBirth) : ''} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="ethnicity"
                    select
                    label="Ethnicity"
                    fullWidth
                    value={currentPatient.ethnicityId}
                    onChange={handleEthnicityChange}
                    disabled={isUpdating}
                >
                    {ethnicities.map((option) => (
                        <MenuItem key={Ethnicity[option]} value={Ethnicity[option]}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="email"
                    label="Email"
                    fullWidth
                    value={currentPatient.emailAddress}
                    onChange={handleEmailChange}
                    disabled={isUpdating} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="mobile-phone"
                    label="Mobile Phone"
                    fullWidth
                    value={currentPatient.altPhone}
                    onChange={handleCellPhoneChange}
                    disabled={isUpdating} />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    id="home-phone"
                    label="Home Phone"
                    fullWidth
                    value={currentPatient.primaryPhone}
                    onChange={handlePhoneChange}
                    disabled={isUpdating} />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '50px', marginBottom: '95px' }}>
                <Typography variant="h5">Allergies</Typography>
                <Grid container spacing={3} padding={1}>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies.Acrylic) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies.Acrylic) }}
                            fullWidth>
                            Acrylic
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies.Metals) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies.Metals) }}
                            fullWidth>
                            Metals
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies.Plastic) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies.Plastic) }}
                            fullWidth>
                            Plastic
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies.Latex) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies.Latex) }}
                            fullWidth>
                            Latex
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies.Food) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies.Food) }}
                            fullWidth>
                            Food
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies['Pain Medications']) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies['Pain Medications']) }}
                            fullWidth>
                            Pain Medications
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant={optionChecked(allergyOptions, Allergies.Other) ? 'contained' : 'outlined'}
                            onClick={(event) => { allergyhxOnChange(event, Allergies.Other) }}
                            fullWidth>
                            Other
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '50px', marginBottom: '95px' }}>
                <Typography variant="h5">Connected Rx/Rework</Typography>
                <Grid container spacing={3} padding={1}>
                    <Grid item xs={3}>
                        <TextField
                        id="connectedrx"
                        label=""
                        fullWidth
                        value={connectedRxRework}
                        onChange={handleConnectedRxReworkChange}
                         />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}; export default observer(RxPatientInfo)