// Vendors
import react, { FC, useEffect, useState } from 'react';
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    styled,
    List,
    ListItem,
    ListItemText,
    alpha,
    Typography,
    Box,
    Button,
    TextField,
    IconButton
} from '@mui/material';

// Components
import SectionContainer from '../../../UI/Containers/SectionContainer';
import AddIcon from '../../../UI/Icons/AddIcon';
import RemoveIcon from '../../../UI/Icons/RemoveIcon';

// API
import { observer } from 'mobx-react';



import Item from '../../../Item';
import CssTextField from '../../../CssTextField';

import { useMainStoreContext } from '../../../../stores/OldStores/MainStore';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';
import { TreatmentSleepDisorder } from '../../../../models/state_models/treatmentPlanSleepDisorder';

interface IDiagnosisPage {
    patient?: any;
}

const ListWrapper = styled(List)(
    () => `
      .MuiDivider-root:last-of-type {
          display: none;
      }
    `
);

export const SleepConditions: FC<IDiagnosisPage> = observer(() => {
    /* const { sleepDiagnosis, setSleepDiagnosis, sleepNotesDiagnosis, setSleepNotesDiagnosis, sleepCondition } =
        useMainStoreContext().patientTreatmentStore; */

    const { treatmentPlan, setTreatmentPlan} = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;

    const handleOsaDiagnosis = (event: React.MouseEvent<HTMLButtonElement>) => {
        treatmentPlan.obstructiveSleepApnea = event.currentTarget.value;
        setTreatmentPlan(treatmentPlan);
    };

    const [sleepDisorder, setSleepDisorder] = useState('');
    const [sleepDisorders, setSleepDisorders] = useState(['']);

    useEffect(()=>{
        var startedSleepDisorders = treatmentPlan.treatmentSleepDisorders.map((v,i,a)=>{
            return v.sleepDisorder;
        });
        if(startedSleepDisorders.length > 0){
            setSleepDisorders(startedSleepDisorders);
        }
        else{
            setSleepDisorders(['']);
        }
        
    },[])

    const handleSleepDisorderChange = (value: string, index: number) => {
        const updatedSleepDisorders = [...sleepDisorders];
        updatedSleepDisorders[index] = value;
        treatmentPlan.treatmentSleepDisorders = updatedSleepDisorders.map((values,ind, arr)=>{
            let disorder = new TreatmentSleepDisorder()
            disorder.sleepDisorder = values;
            disorder.treatmentPlanId = treatmentPlan.id;
            return disorder;
        });

        setTreatmentPlan(treatmentPlan);
        setSleepDisorders(updatedSleepDisorders);
    };

    const addSleepDisorder = () => {
        setSleepDisorders([...sleepDisorders, '']);
    };
    const removeSleepDisorder = (index: number) => {
        const updatedSleepDisorders = [...sleepDisorders];
        updatedSleepDisorders.splice(index, 1);
        treatmentPlan.treatmentSleepDisorders = updatedSleepDisorders.map((values,ind, arr)=>{
            let disorder = new TreatmentSleepDisorder()
            disorder.sleepDisorder = values;
            disorder.treatmentPlanId = treatmentPlan.id;
            return disorder;
        });

        setTreatmentPlan(treatmentPlan);
        setSleepDisorders(updatedSleepDisorders);
    };

    const handleConditionChange = (conditionId) => {
        /* if (sleepDiagnosis.some(el => el.conditionId === conditionId)) {
            setSleepDiagnosis(sleepDiagnosis.filter(el => el.conditionId !== conditionId));
        } else {
            const newCondition = {
                id: conditionId,
                name: sleepCondition.find(vg => vg.id === conditionId)?.name,
                conditionId: conditionId,
            };
            setSleepDiagnosis([...sleepDiagnosis, newCondition]);
        } */
    };

    return (
        <Grid>

            <SectionContainer
                sectionTitle={'Sleep Conditions (Dx by MD)'}
                cardContentHeight={'500px'}
                sectionComponent={
                    <Grid>
                        <ListWrapper disablePadding>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Obstructive Sleep Apnea
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Mild"
                                                            variant={treatmentPlan.obstructiveSleepApnea === "Mild" ? "contained" : "outlined"}
                                                            onClick={handleOsaDiagnosis}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Mild
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Moderate"
                                                            variant={treatmentPlan.obstructiveSleepApnea === "Moderate" ? "contained" : "outlined"}
                                                            color={treatmentPlan.obstructiveSleepApnea === "Moderate" ? "warning" : "primary"}
                                                            onClick={handleOsaDiagnosis}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Moderate
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Button
                                                            fullWidth
                                                            value="Severe"
                                                            variant={treatmentPlan.obstructiveSleepApnea === "Severe" ? "contained" : "outlined"}
                                                            color={treatmentPlan.obstructiveSleepApnea === "Severe" ? "error" : "primary"}
                                                            onClick={handleOsaDiagnosis}
                                                            sx={{
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            Severe
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                            </ListItem>
                            <ListItem sx={(theme) => ({
                                p: 2,
                                borderBottom: 4,
                                borderColor: `${alpha(theme.colors.alpha.black[100], 0.03)}`
                            })}>
                                <ListItemText
                                    primary={
                                        <Typography variant="h5">
                                            Other Sleep Disorders
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Box mt={0.7} display="flex" alignItems="center">
                                                <Grid container spacing={1} padding={1}>
                                                    {sleepDisorders.map((disorder, index) => (
                                                        <Grid container spacing={2} alignItems="center" key={index}>
                                                            <Grid item xs={9} padding={1}>
                                                                <TextField
                                                                    value={disorder}
                                                                    fullWidth
                                                                    placeholder='Type in Sleep Disorder'
                                                                    onChange={(e) => handleSleepDisorderChange(e.target.value, index)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                {index === sleepDisorders.length - 1 ? (
                                                                    <IconButton onClick={addSleepDisorder}>
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                ) : (
                                                                    <IconButton onClick={() => removeSleepDisorder(index)}>
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </>
                                    }
                                />
                            </ListItem>
                        </ListWrapper>
                    </Grid>
                }
            />
        </Grid>
    );
});
