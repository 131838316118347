export const CART_CREATE_OPERATION = `
mutation createCart($cartInput: CartInput) {
  cartCreate(input: $cartInput) {
    cart {
      id
      checkoutUrl
      lines(first: 10) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                product {
                  id
                  title
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
      cost {
        checkoutChargeAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
    }
  }
}`;

export const CART_ADD_LINES_OPERATION = `
mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
  cartLinesAdd(cartId: $cartId, lines: $lines) {
    cart {
      id
      checkoutUrl
      lines(first: 10) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                product {
                  id
                  title
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
      cost {
        checkoutChargeAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
    }
  }
}`;

export const CART_REMOVE_LINES_OPERATION = `
mutation removeCartItem($cartId: ID!, $lineIds: [ID!]!) {
  cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
    cart {
      id
      checkoutUrl
      lines(first: 10) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                product {
                  id
                  title
                }
                priceV2 {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
      cost {
        checkoutChargeAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalAmount {
          amount
          currencyCode
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}`;

export const CHECKOUT_QUERY = `
query getCheckout($checkoutId: ID!) {
  node(id: $checkoutId) {
    ... on Checkout {
      id
      lineItems(first: 10) {
        edges {
          node {
            id
            quantity
            variant {
              id
              priceV2 {
                amount
                currencyCode
              }
            }
          }
        }
      }
      totalPriceV2 {
        amount
        currencyCode
      }
    }
  }
}`;

export interface LineItem {
  title: string;
  merchandise: {
    product: {
      title: string
    };
    priceV2: {
      amount: string;
    };
  };
}

export interface CheckoutData {
  lines: {
    edges: {
      node: LineItem;
    }[];
  };
  cost: {
    checkoutChargeAmount: {
        amount: string;
    },
    totalAmount: {
        amount: string;
    }
  };
}