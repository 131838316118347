import { observable } from 'mobx';

class LegalGuardian {
  @observable id: number = 0;
  @observable personId: number = 0;
  @observable relationshipTypeId: number = 0;
  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable primaryPhone: string = '';
  @observable altPhone: string = '';
  @observable emailAddress: string = '';
  @observable extId: number | null = null;
  @observable createdOn: Date = new Date();
  @observable createdBy: number = 0;
  @observable modifiedOn: Date | null = null;
  @observable modifiedBy: number | null = null;
  @observable isActive: boolean | null = null;
}

export default LegalGuardian;