import { makeObservable, observable } from 'mobx';
import {DictionaryListItem, DictionaryList } from './dictionarylistitem';

export class TreatmentPlanPhase {
    @observable id: number = 0;
    @observable treatmentPlanId: number = 0;
    @observable startDate: Date = new Date();
    @observable endDate: Date = new Date();
    @observable migrationId?: number; // Optional chaining for nullable property
    @observable isActive: boolean = false;
    @observable createdBy: number = 0;
    @observable createdOn: Date = new Date();
    @observable modifiedBy?: number; // Optional chaining for nullable property
    @observable modifiedOn?: Date; // Optional chaining for nullable property
    @observable treatmentPlanPhasesApplianceTypes: TreatmentPlanPhasesApplianceType[] = []

    constructor() {
        makeObservable(this);
    }
}

export class TreatmentPlanPhasesApplianceType {
    @observable id: number = 0;
    @observable treatmentPlanPhaseId: number = 0;
    @observable applianceCategory: number = 0;
    @observable applianceTypeId: number = 0;
    @observable applianceCategoryNavigation: DictionaryList;
    @observable applianceType: DictionaryListItem;

    constructor() {
        makeObservable(this);
        this.applianceCategoryNavigation = new DictionaryList();
        this.applianceType = new DictionaryListItem();
    }
}
