import React, { useState } from 'react';
import {
    Typography,
    Card,
    Box,
    styled,
    useTheme,
    Grid,
    Avatar,
    Button,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material';

const HoverButton = styled(Button)(
    ({ theme }) => `
        transition: box-shadow 0.3s ease, transform 0.2s ease;
        &:hover {
            box-shadow: 0 4px 12px ${theme.palette.action.hover};
            transform: translateY(-2px);
        }
    `
);

const researchDocuments = [
    {
        id: 1,
        title: "A Novel Combined Protocol for the Resolution of Severe Obstructive Sleep Apnea",
        imageLoc: "/images/general/knowledgeBase/sleepGirl.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/A Novel Combined Protocol for the Resolution of Severe Obstructive Sleep Apnea.pdf",
        keywords: ["Severe Obstructive Sleep Apnea", "Biomimetic Oral Appliance Therapy"],
        abstract:
            "A 27-year-old woman was referred to our office for a dental assessment regarding temporomandibular joint pain. On initial screening, she was found to be at high risk for obstructive sleep apnea, and subsequently underwent polysomnography, which revealed an apnea-hypopnea index of 118 hr-1. Her condition was initially controlled with continuous positive airway pressure therapy, which she did not tolerate well. Therefore, treatment proceeded with biomimetic oral appliance therapy. After 10 months of combined continuous positive airway pressure therapy and biomimetic oral appliance therapy, the apnea-hypopnea index fell to 1 hr-1 and obstructive sleep apnea could not be observed with or without the appliances in situ. We conclude that combined continuous positive airway pressure therapy and biomimetic oral appliance therapy might represent a potential cure of severe cases obstructive sleep apnea in certain patients."
    },
    {
        id: 2,
        title: "Airway Changes in Obstructive Sleep Apnoea Patients Associated with a Supine versus an Upright Position Examined using Cone Beam Computed Tomography",
        imageLoc: "/images/general/knowledgeBase/STwithAirway.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Airway changes in obstructive sleep apnoea patients associated with a supine versus an upright position examined using cone beam computed tomography.pdf",
        keywords: ["Obstructive Sleep Apnea", "Cone Beam Computed Tomography", "Patient Positioning", "Supine Position", "Imaging", "Three-Dimensional", "Statistics & Numerical Data", "Pharynx", "Uvula", "Pathology"],
        abstract: `Objective: This study aimed to describe total volume and cross-sectional area measurement changes in obstructive sleep apnoea patients associated with a supine versus an upright position.
        \n\nMethod: A retrospective chart review of patients who underwent cone beam computed tomography in upright and supine positions was performed, and the images were analyzed. 
        \n\nResults: Five obstructive sleep apnoea patients (all male) underwent both upright and supine cone beam computed tomography imaging. Mean age was 35.0 ± 9.3 years, mean body mass index was 28.1 ± 2.7 kg/m2 and mean apnoea–hypopnoea index was 39.3 ± 23.0 per hour. The airway was smaller when patients were in a supine compared with an upright position, as reflected by decreases in the following airway measurements: total volume; posterior nasal spine, uvula tip, retrolingual and tongue base (not significant) cross-sectional areas; and site of the minimum cross-sectional area (of the overall airway). Total airway volume decreased by 32.6 percent and cross-sectional area measurements decreased between 32.3 and 75.9 per cent when patients were in a supine position.
        \n\nConclusion: In this case series, the airway of obstructive sleep apnoea patients was significantly smaller when patients were in a supine compared with an upright position.`
    },
    {
        id: 3,
        title: "Effectiveness of Early Intervention with a Monobloc Oral Appliance in Reducing Symptoms of Breathing Disorders at Sleep in Children with Dentofacial Anomalies Ages 5-12: A Retrospective, Multicenter Analysis",
        imageLoc: "/images/general/knowledgeBase/kidDentist.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Effectiveness of Early Intervention with a Monobloc Oral Appliance in Reducing Symptoms of Breathing Disorders at Sleep in Children with Dentofacial Anomalies Ages 5-12- A Retrospective, Multicenter Analysis.pdf",
        keywords: ["Sleep", "Sleep Disordered Breathing (SDB)", "Nasal Obstruction", "Obstructive Sleep Apnea (OSA)", "Pediatrics", "Repositioning Device", "Oral Appliance", "Monoblock", "Children"],
        abstract: "Objective and background: We aim to determine how early intervention of an MOA for dentofacial anomalies improves qualitative scores of Sleep Disordered Breathing (SDB) in school-aged children ages 5-12. \
        \n\nMethods: A review of historical secondary data was conducted from 1001 Sleep, Breathing and Habit Questionnaire of children ages 5-12 diagnosed with SDB in treatment with an MOA. A total of 44 sets of questionnaire scores met the inclusion criteria for the retrospective review. Eleven SDB symptom scores were reviewed at three endpoints: Initial, 2-6 months (SG1), and 7+ months (SG2). \
        \n\nResults: A total of 44 patient-directed sleep and breathing questionnaires from children ages 5 to 12 (female 55% (n=24), male 45% (n=20), mean age 7.54 years, ± 1.89) in treatment with an MOA for dentofacial anomalies reporting symptoms of SDB were included. Among the cohort, 90% (27 of 30) showed a statistically significant reduction of overall symptoms scored as resolved or improved from the initial visit to the 7+ months endpoint (M=15.29 months, SD ± 4.18); 71.4% (25 of 35) of the cohort showed a statistically significant reduction of overall symptoms within 2-6 months (M=4.22 months, SD ± 1.64). \
        \n\nConclusion: The improvement in 4.22 months shows MOA as an effective treatment for early intervention in treating the dual pathologies of dentofacial anomalies and SDB in school-aged children ages 5-12."

    },
    {
        id: 4,
        title: "A Novel Intervention to Simultaneously Address the Dual Pathologies of Breathing Disorders During Sleep and Undiagnosed Attention Deficit Hyperactivity Disorder in School-Aged Children Ages 5-12",
        imageLoc: "/images/general/knowledgeBase/ADHD.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/A Novel Intervention to Simultaneously Address the Dual Pathologies of Breathing Disorders During Sleep and Undiagnosed Attention Deficit Hyperactivity Disorder in School-Aged Children Ages 5-12.pdf",
        keywords: ["Attention Deficit Hyperactivity Disorder", "Sleep Disordered Breathing", "Obstructive Sleep Apnea", "Monoblock Appliance"],
        abstract: "Purpose: To evaluate the improvement of ADHD-related symptoms in school-aged children ages 5 to 12 in treatment with a monobloc appliance (MOA) for Sleep Disordered Breathing (SDB). \
        \n\nMethods: A retrospective review of questionnaire scores of ADHD symptoms from school-aged children being treated with a MOA for SDB. Data was obtained from parent survey questionnaires of 40 school-aged children in three dental offices in treatment with an MOA for SDB showing symptoms of ADHD yet to be confirmed with a formal diagnosis between 2019 and 2021. ADHD symptom scores were ascertained by a parent survey questionnaire completed at the initial visit before MOA treatment, and 2 to 6 months, and 7 + months during MOA treatment. \
        \n\nResults: At the 7 + month endpoint, 17 of the 28 (61%) children ages 5 to 12 saw at least a 1 –1-point drop in the sum of their questionnaire scores indicating an improvement in ADHD symptoms after initiating treatment with an MOA. Although there was a reduction of the overall average symptom score from the initial visit to 2 to 6 months (M=4.06, SD ± 1.55), a statistically significant improvement in ADHD symptoms occurred at the 7 + month endpoint (M = 15.29, SD ±  4.50) during MOA treatment. \
        \n\nConclusions: Treatment with an MOA may be highly effective in addressing the dual pathologies of SDB and ADHD in school-age children ages 5 to 12."

    },
    {
        id: 5,
        title: "Prevalence of obstructive sleep apnea (OSA) among preschool-aged children in the general population: A systematic review ",
        imageLoc: "/images/general/knowledgeBase/Preschoolclassroomgood.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Prevalence of obstructive sleep apnea (OSA) among preschool-aged children in the general population- A systematic review .pdf",
        keywords: ["Obstructive Sleep Apnea", "Sleep-Disordered Breathing", "Primary Snoring", "Habitual Snoring", "Prevalence", "Preschool-Aged Children"],
        abstract: "Untreated pediatric obstructive sleep apnea (OSA) is associated with significant morbidities affecting behavior, neurocognitive development, endocrine and metabolic health. This systematic review evaluated prevalence of OSA reported in population-based studies among preschoolers as early intervention may have positive effects on health and quality of life. \
        \n\nThirty studies were included. High degrees of heterogeneity in methods and definitions were observed between the studies. Seven studies confirmed OSA by implementing objective methods after screening for habitual snoring with only two studies utilizing polysomnography, the reference standard, testing 1.2% of the combined cohorts (n=82/4575) to confirm disease. Diagnosis of OSA was based on utilizing retired thresholds of the apnea-hypopnea-index (AHI) AHI 4%>5/hour of sleep (hrsleep), reporting prevalence of 1.8% and 6.4%, respectively. The remaining five studies implemented relatively insensitive objective recording methods to confirm disease in a limited number of children (n= 449/2486; 18.0%), estimating prevalence in the range of 0.7%-13%. The remaining literature is based on implementing questionnaires only to evaluate OSA. Studies published before 2014 reported 3.3%- 9.4% prevalence, while more recent studies published 2016-2023 report higher prevalence, 12.8%-20.4%, when excluding outliers. This trend suggests that prevalence of OSA may possible have been increasing in preschoolers over the past decade."
    },
    {
        id: 6,
        title: "The Association of Nocturnal Enuresis and Breathing Disorders in Children with Sleep Disordered Breathing: A Retrospective Review of Pediatric Cases Treated with a Preformed Monoblock Oral Appliance",
        imageLoc: "/images/general/knowledgeBase/SleepBoy.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/The Association of Nocturnal Enuresis and Breathing Disorders in Children with Sleep Disordered Breathing- A Retrospective Review of Pediatric Cases Treated with a Preformed Monoblock Oral Appliance.pdf",
        keywords: ["Sleep Disordered Breathing", "Nocturnal Enuresis", "Obstructive Sleep Apnea", "Bed Wetting", "Pediatric", "Oral Appliance"],
        abstract: "Objective: Nocturnal Enuresis (NE) frequently occurs in children with Sleep Disordered Breathing (SDB). Many options to address SDB and NE are available to include pharmaceuticals and surgery. This retrospective study examines the relationship between NE and dentofacial anomalies modified or remodeled by intervention with a pre-formed Mono-block Oral Appliance (MOA). \
        \n\nMethods: In a retrospective study using secondary data from a third-party source, 107 questionnaire scores of children ages 5-12 were reviewed. The secondary data score sources included a sleep, breathing and habit questionnaire; kids intake form; pediatric sleep questionnaire; head and neck exam; child home observation sheet and patient profile. The primary endpoints for the study were the number of days between the start date of MOA therapy and the resolve date of the bedwetting according to the age of the child. In a linear regression analysis, comparisons were made between the age and bedwetting resolved in days when using an MOA. \
        \n\nResults: A linear regression analysis was conducted to evaluate the prediction of resolving NE with an MOA. The scatterplot indicated an association between NE, therapy with an MOA and the age of the child. \
        \n\nConclusions: The study shows a strong predictability of resolving NE at an early age with the use of an MOA."

    },
    {
        id: 7,
        title: "Retrospective Analysis of Real-World Data for the Treatment of Obstructive Sleep Apnea with Slow Maxillary Expansion Using a Unique Expansion Dental Appliance (DNA)",
        imageLoc: "/images/general/knowledgeBase/DNA2.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Retrospective Analysis of Real-World Data for the Treatment of Obstructive Sleep Apnea with Slow Maxillary Expansion Using a Unique Expansion Dental Appliance (DNA).pdf",
        keywords: ["Obstructive Sleep Apnea", "Mandibular Advancement Device", "Maxillary Expansion Device"],
        abstract: "In addition to mandibular advancement devices, dental expansion appliances are an important clinical approach for achieving an increased intra-oral space that promotes airflow and lessens the frequency or severity of apneic events in patients diagnosed with obstructive sleep apnea (OSA). It has been thought that dental expansion in adults must be preceded by oral surgery; however, in this paper, we examine the results of a new technique for slow maxillary expansion without any surgical procedures. The palatal expansion device, DNA (Daytime-Nighttime Appliance), was reviewed in this retrospective study, particularly regarding its effects on measurements of transpalatal width, airway volume, and apnea-hypopnea indices (AHI) as well as its common modalities and complications. The DNA effectively reduced AHI by 46% (p = 0.00001) and significantly increased both airway volume and transpalatal width (p < 0.00001). After DNA treatment, 80% of patients showed some improvement in AHI scores with 28% of patients having their OSA symptoms completely resolved. Compared to the use of mandibular appliances, this approach is intended to create a sustained improvement in airway management that can reduce or eliminate dependence on continuous positive airway pressure (CPAP) or other OSA treatment devices."
    },
    {
        "id": 8,
        "title": "Use of the Complete Airway Repositioning and Expansion (CARE) approach in 220 patients with Obstructive Sleep Apnea (OSA): A retrospective cohort study",
        "imageLoc": "/images/general/knowledgeBase/care.png",
        "link": "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Use of the Complete Airway Repositioning and Expansion (CARE) approach in 220 patients with Obstructive Sleep Apnea (OSA)- A retrospective cohort study.pdf",
        "keywords": ["Sleep Apnea", "Dentistry", "Oral Appliance"],
        abstract: "Objective/Background: Obstructive sleep apnea (OSA) is a prevalent disease with significant health repercussions. While many effective OSA treatment modalities exist, Complete Airway Repositioning and Expansion (CARE) represents an emerging approach that leverages gradual airway expansion, with or without mandibular advancement. We conducted a retrospective study of patients who underwent CARE with a dental provider and examined how their sleep study data changed, with a focus on apnea-hypopnea index (AHI). \n\nPatients/Methods: A retrospective database of 220 adult patients was examined. Demographic data and radiographic and sleep study data were compared in patients before and following at least 6 months of treatment with one of two possible dental devices. \n\nResults: The median age of patients in this cohort was 50 years, and evenly split by gender. The median decrease in AHI was 49.0%, with a median pre-treatment AHI of 17.3 and median post-treatment AHI of 9.6 (p<0.001). Most participants (63.6%) demonstrated an improvement in their OSA severity class. Fifty-seven (25.9%) participants had complete resolution of their OSA. Post-treatment, 151 (68.6%) of patients had OSA severities of non or mild. Thirty-four (15.5%) of patients had in increase in AHI and 13 (6.0%) of these patients demonstrated an increase in OSA classification. One patient experienced an adverse event in the form of a loose molar tooth requiring repair. Overall findings were limited by missingness of BMI and clinical co-morbidity data, as well as quality of life measures. \n\nConclusion: In this large, but data limited retrospective series, CARE seems to be an effective and safe approach to OSA management that may be a useful alternative to current mainstays of OSA management. Further investigation is warranted."
    },
    {
        id: 9,
        title: "Combined DNA appliance and Invisalign Therapy without Interproximal Reduction: A Preliminary Case Series",
        imageLoc: "/images/general/knowledgeBase/PanoDental.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Combined DNA appliance and Invisalign Therapy without Interproximal Reduction.pdf",
        keywords: ["DNA Appliance", "Invisalign", "Dentistry", "Adults"],
        abstract: "Objective: To use a biomimetic appliance prior to the use of removable clear aligners to reduce the necessity for interproximal reduction in the correction of malocclusions. \n\nSubjects: Three adult females aged 23-38 years. Subject A had a narrow upper arch and mandibular retrognathia; Subject B had crowding and an increased overjet; Subject C had anterior and posterior crossbite. \n\nIntervention: All subjects were given optional treatment plans of DNA appliance therapy followed by Invisalign to improve the occlusion. \n\nResults: Subject A showed improved mandibular positioning, with improved facial soft tissues. Subject B showed improved posterior occlusion with an increase in upper and lower arch width, resulting in improved facial soft tissues and smile esthetics. Subject C showed improved posterior occlusion, correction of the anterior crossbite and a corrected midline. \n\nConclusions: All three subjects that complied with a non-fixed, DNA appliance/Invisalign protocol showed functional and cosmetic improvements in occlusion without any interproximal reduction."
    },
    {
        id: 10,
        title: "Corrosion of Orthodontic Appliances — Should We Care?",
        imageLoc: "/images/general/knowledgeBase/setup.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Corrosion of orthodontic appliances - should we care.pdf",
        keywords: ["Orthodontic Appliances", "Corrosion", "Oral Environment", "Materials", "Bonded Attachments", "Archwires"],
        abstract: "Contemporary orthodontics relies on various bonded attachments, archwires, and other devices to achieve tooth movement. These components are composed of varying materials with their own distinctive physical and mechanical properties. The demands made on them are complex because they are placed under many stresses in the oral environment. These include immersion in saliva and ingested fluids, temperature fluctuations, and masticatory and appliance loading. The combination of these materials in close proximity and in hostile conditions can result in corrosion. Our purpose in this article was to consider the literature to date with regard to potential mechanical, clinical, and health implications of orthodontic corrosion. "
    },
    {
        id: 11,
        title: "Case Presentation: Effect of Mandibular Tori Removal on Obstructive Sleep Apnea Parameters",
        imageLoc: "/images/general/knowledgeBase/Blurreddental.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Case Presentation Effect of Mandibular Tori Removal on Obstructive Sleep Apnea Parameters.pdf",
        keywords: ["Obstructive Sleep Apnea", "Tori Removal", "Torus"],
        abstract: "The relationship between palatal and mandibular tori and obstructive sleep apnea (OSA) remains tenuous. While evidence on an association between sleep bruxism and OSA is beginning to emerge, there is a dearth of evidence linking palatal and mandibular tori to OSA. This case report is perhaps the first to document that the effect of surgical torus mandibularis removal improves parameters correlated with OSA, but further studies on the relationship between palatal and mandibular tori and OSA are needed."
    },
    {
        id: 12,
        title: "Biomimetic Oral Appliance Therapy in Adults with Mild to Moderate Obstructive Sleep Apnea",
        imageLoc: "/images/general/knowledgeBase/DentistshowingAppliance.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Biomimetic Oral Appliance Therapy in Adults.pdf",
        keywords: ["Oral Appliance Therapy", "Biomimetic", "Obstructive Sleep Apnea", "Mandibular Advancement Device"],
        abstract: "Introduction: For the management of obstructive sleep apnea (OSA) in adults, some professionals prescribe continuous positive airway pressure (CPAP) while others prefer mandibular advancement devices (MADs). However, both CPAP and MADs represent life-long therapy. In this study, we investigated the use of a biomimetic oral appliance system (the DNA appliance® system) to test the hypothesis that the upper airway can be improved in adults that have been diagnosed with OSA. \n\nMethods and Sample: We recruited 10 consecutive adults for this study who underwent an overnight sleep study, which was interpreted by a sleep physician. Subjects diagnosed with mild to moderate OSA were treated using biomimetic oral appliance therapy (BOAT). Each subject had monthly follow-up visits, including examinations for progress and adjustments of the devices. The mean AHI of the sample was calculated prior to and after BOAT with no appliance in the mouth. The findings were subjected to statistical analysis. \n\nResults: The mean treatment time was 8.7 mos. ± 5.8. Prior to treatment the mean AHI was 13.2 ± 7.2. The mean AHI fell by 65.9% to 4.5 ± 3.6 (p =0.021) after BOAT with nothing in the mouth when the final overnight sleep study was performed. \n\nConclusion: This preliminary study suggests that BOAT may be able to reduce the AHI to within normal limits perhaps to the extent that life-long therapy may not potentially be necessary. However, long-term follow up is needed to determine whether these subjects need a maintenance program to retain their initial upper airway improvements."
    },
    {
        id: 13,
        title: "Changes in 3D Midfacial Parameters after Biomimetic Oral Appliance Therapy in Adults",
        imageLoc: "/images/general/knowledgeBase/FrontalSkullandST.jpg",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Changes in 3D Midfacial Parameters after Biomimetic Oral Appliance Therapy in Adults.pdf",
        keywords: ["Maxillary Expansion", "Palatal Expansion", "Adult", "Biomimetic Appliance"],
        abstract: "Background/objectives/aims: Controversy exists regarding maxillary bone changes in nongrowing adults. However, previous studies have relied on two-dimensional (2D) cephalometric analyses, which may be unable to capture three-dimensional (3D) phenomena. In this study, we investigated 2D and 3D parameters to test the null hypothesis that maxillary bone volume cannot be changed in nongrowing adults that had been diagnosed with midfacial underdevelopment. \n\nMethods: After obtaining informed consent, we undertook 3D cone beam computed tomography scans of 11 consecutive, adult patients prior to and after biomimetic, oral appliance therapy. The mean treatment time was 18.4 months ± 2.5 using the DNA appliance® system. The intramolar width and 3D volume of the midface was calculated prior to and after the midfacial redevelop- ment protocol. The findings were subjected to statistical analysis. \n\nResults: The mean intramolar increased from 33.5 mm ± 3.4 prior to treatment to 35.8 mm ± 2.9 after appliance therapy (p = 0.0003). Similarly, the mean midfacial bone volume was 17.4 cm3 ± 3.9 prior to treatment and increased to 19.1 cm3 ± 2.6 after appliance therapy (p = 0.0091). \n\nConclusion: These data support the notion that maxillary bone width and volume can be changed in nongrowing adults. Further- more, midfacial redevelopment may provide a potentially-useful method of managing adults diagnosed with obstructive sleep apnea, using biomimetic, oral appliances."
    },
    {
        id: 14,
        title: "Resolution of Sleep Bruxism using Biomimetic Oral Appliance Therapy: A Case Report",
        imageLoc: "/images/general/knowledgeBase/GuySmiling.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Resolution of Sleep Bruxism using Biomimetic Oral Appliance Therapy A Case Report.pdf",
        keywords: ["Sleep Bruxism", "Nocturnal Bruxism", "Obstructive Sleep Apnea", "Biomimetic; Oral Appliance"],
        abstract: "Background: Evidence suggests that sleep bruxism is centrally regulated, and that the highest risk factor associated with sleep bruxism is obstructive sleep apnea. Current treatments for sleep bruxism include dental night- guards or occlusal splints, which are often provided without upper airway or sleep assessments. \n\nMethods: In this case report, we used biomimetic oral appliance therapy to address sleep bruxism by redeveloping the maxilla and repositioning the mandible in a 17 yr. old, female patient. \n\nResults: The upper airway volume increased by 313% (from 7.7 cm3 to 24.1 cm3) and the minimum upper airway cross-sectional area increased by 230% from (120 mm2 to 276.5 mm2), which improved both sleep bruxism and orthodontic relapse. \n\nConclusion: We conclude that dentists and orthodontists can help in the recognition and treatment of both sleep bruxism and malocclusion, thereby preventing systemic co-morbidities associated with obstructive sleep apnea."
    },
    {
        id: 15,
        title: "Complete Airway Repositioning and Expansion (CARE): Novel Oral Appliance Treatment for Obstructive Sleep Apnea Administered By Dentists",
        imageLoc: "/images/general/knowledgeBase/Caredevices.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Complete Airway Repositioning and Expansion (CARE)- Novel Oral Appliance Treatment for Obstructive Sleep Apnea Administered By Dentists.pdf",
        keywords: ["Vivos", "CARE Appliances", "Obstructive Sleep Apnea"],
        abstract: "No abstract available",
      },
    {
        id: 16,
        title: "Influence of mouthwashes on the physical properties of orthodontic acrylic resin",
        imageLoc: "/images/general/knowledgeBase/mouthwash.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/Influence of mouthwashes on the physical properties of orthodontic acrylic resin.pdf",
        keywords: ["Acrylic Resins", "Mouthwashes", "Hardness"],
        abstract: "Aim: This study aimed to assess the impact of immersion in various mouthwashes on the hardness, roughness, and color of acrylic resin commonly used in orthodontic treatments. \n\nMethods: Specimens of Orto Clas, an orthodontic self-cured acrylic resin, were immersed in five different mouthwashes: Plax Classic, Plax alcohol-free, Listerine, Periogard, and Periogard alcohol-free. Nine immersion durations ranging from 1 hour to 7 days were examined, resulting in a total of 45 experimental groups. Evaluation methods included Knoop microhardness (n=5), roughness measured by Ra parameter (n=5), and colorimetric analysis using the CIElab system (n=3). \n\nResults: After 7 days of immersion, all mouthwashes led to softening of the acrylic resin. Plax alcohol-free exhibited consistent softening across different immersion times, while Listerine caused softening at all durations. Both Plax alcohol-free and Listerine resulted in significantly increased roughness values after 12 hours of immersion (p<0.05). Additionally, Listerine induced notable color variation after 12 hours of immersion. \n\nConclusions: The findings suggest that immersion in mouthwashes may alter the hardness, roughness, and color of acrylic resin used in orthodontic applications."
      },

    {
        id: 17,
        title: "To Soak or Not to Soak: That is the Question, Among Others",
        imageLoc: "/images/general/knowledgeBase/Appliancecare.png",
        link: "https://prodimagesvaire.blob.core.windows.net/sharedresources/Research Articles & Publications/To Soak or Not to Soak - That is the Question, Among Others - Dental Sleep Practice - Sleep Apnea Publication & Online CE.pdf",
        keywords: ["Appliance Cleaning","Appliance Care","Mandibular Advancement Device"],
        abstract: "No abstract available"
    },
             
    // Add more articles as needed
];

function ResearchContent() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [selectedAbstract, setSelectedAbstract] = useState('');

    const handleOpenAbstract = (abstract) => {
        setSelectedAbstract(abstract);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container spacing={2} alignItems="stretch">
            {researchDocuments.map((doc) => (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={3}
                    key={doc.id}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 2,
                            marginBottom: 2,
                            flexGrow: 1,
                        }}
                    >
                        <Avatar
                            alt={`Image for ${doc.title}`}
                            src={doc.imageLoc}
                            sx={{ width: '100%', height: 140, marginBottom: 2 }}
                            variant="square"
                        />
                        <Typography gutterBottom variant="h6" textAlign="center">
                            {doc.title}
                        </Typography>
                        <Box
                            sx={{
                                marginTop: 'auto', // Ensures alignment across rows
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <HoverButton
                                variant="contained"
                                onClick={() => window.open(doc.link, '_blank')}
                                aria-label={`Read full article: ${doc.title}`}
                            >
                                Read Full Article
                            </HoverButton>
                            <HoverButton
                                variant="outlined"
                                onClick={() => handleOpenAbstract(doc.abstract)}
                                sx={{ marginTop: 1 }}
                                aria-label={`View abstract for: ${doc.title}`}
                            >
                                View Abstract
                            </HoverButton>
                        </Box>
                        <Divider sx={{ width: '100%', marginY: 1 }} />
                        <Typography variant="subtitle2" fontSize={12} sx={{ width: '100%' }}>
                            <strong>Keywords:</strong> {doc.keywords.join(', ')}
                        </Typography>
                    </Card>
                </Grid>
            ))}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Abstract</DialogTitle>
                <DialogContent>
                <Typography
                    style={{
                        whiteSpace: 'pre-line',
                        fontSize: '1.25rem',
                        lineHeight: '1.25',
                    }}
                >
                    {selectedAbstract.split('\n\n').map((paragraph, index) => (
                        <span key={index} style={{ display: 'block', marginBottom: '1rem' }}>
                            {paragraph}
                        </span>
                    ))}
                </Typography>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default ResearchContent;