import { Close } from "@material-ui/icons";
import { Box, Fade, FormControl, Grid, IconButton, Modal, Button, AppBar, Toolbar, Typography, FormGroup, Checkbox, FormControlLabel } from "@mui/material"
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import CssTextField from "../CssTextField";
import Item from "../Item";
import { useMainStoreContext } from '../../stores/OldStores/MainStore';


const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 1100,
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 2,
};

interface ComponentProps {
	open: boolean
	onClose: () => void
}

const SessionStatusModal = (props: ComponentProps) => {
	const { open, onClose } = props;
	let { saveStatus } = useMainStoreContext().createAppointmentNotesStore;

	useEffect(() => {

	}, [open]);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<Box sx={style}>
					<Grid container direction='row' justifyContent={"space-between"}><AppBar position="static" className="modalHeader" color="inherit">
						<Toolbar>
							<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="title">
								{"Update Session Status"}
							</Typography>
							<IconButton onClick={onClose} >
								<Close />
							</IconButton>
						</Toolbar>
					</AppBar>
					</Grid>
					<Grid justifyContent={"center"}>
						<FormGroup>
							<FormControlLabel control={<Checkbox />} label="Mark as No Show" />
						</FormGroup>
					</Grid>
					<Grid container direction="row" justifyContent="flex-end">
						<Button className="vivButton large" onClick={saveStatus}>Save</Button>
						<Button className="vivButton large" onClick={props.onClose}>Cancel</Button>
					</Grid>
				</Box>
			</Fade>
		</Modal>)
}

export default observer(SessionStatusModal);