import React from "react";
import { FC, useState, ChangeEvent } from 'react';
import { useMainStoreContext } from "../../stores/OldStores/MainStore";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  styled,
  Slide,
  MenuItem,
  useTheme,
  Divider,
  Select,
  FormControl
} from "@mui/material";
import { Cancel } from "@material-ui/icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone';

interface IProductProps {
  product: any;
}
const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.sm}px) {
        display: none;
    }
    transition: ${theme.transitions.create(['background', 'color'])};
    color: ${theme.colors.primary.main};
    background: ${theme.colors.alpha.white[100]};
    position: absolute;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    top: 50%;
    margin-top: ${theme.spacing(-1.5)};
    border-radius: 100px;
    z-index: 5;

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      color: ${theme.colors.alpha.black[100]};
    }

    &.MuiSwipe-left {
      left: ${theme.spacing(0.5)};
    }
    
    &.MuiSwipe-right {
      right: ${theme.spacing(0.5)};
    }
`
);
const SwiperWrapper = styled(Box)(
  ({ theme }) => `
  .swiper-wrapper {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .swiper-container-thumbs {
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }

    .swiper-slide {
      width: 140px;
      display: flex;
      padding: 3px;

      img {
        width: 100%;
        height: auto;
        border-radius: ${theme.general.borderRadius};
        opacity: .7;
        transition: ${theme.transitions.create(['box-shadow', 'opacity'])};
      }

      &:hover {
        cursor: pointer;

        img {
          opacity: 1;
        }
      }

      &.swiper-slide-thumb-active {
        img {
          opacity: 1;
          box-shadow: 0 0 0 3px ${theme.colors.primary.main};
        }
      }
    }
  }
`
);

export default function ProductComponent(props: IProductProps) {
  const { product } = props;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const {
    addVariantToCart,
    selectedVariant,
    selectedVariantImage,
    selectedVariantQuantity,
  } = useMainStoreContext().shopifyStore;
  const [variantQuantity, setVariantQuantity] = React.useState(selectedVariantQuantity ?? 1);
  const [openModal, setOpenModal] = React.useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (newValue > 1) {
      setVariantQuantity(newValue);
    } else {
      setVariantQuantity(1);
    }
  };

  const variantImage = selectedVariantImage || product.images[0];
  const variant = selectedVariant || product.variants[0];
  return (
    <>
      <Card variant="outlined" raised>
        <CardActionArea onClick={toggleModal}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          {product.images.length > 0 ? (
            <CardMedia
              sx={{
                height: 200,
                width: 'auto',
                display: 'flex',
                justifySelf: 'center',
              }}
              component="img"
              image={variantImage.src}
              alt={`${product.title} product shot`}
            />
          ) :
            <Box
              sx={{
                height: 200,
                width: '100%',
                alignItems: 'center',
                display: 'flex'
              }}>
              <Typography sx={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }} variant="h5">
                No Image
              </Typography>
            </Box>
          }
          <CardContent
            sx={{
              width: '100%',
            }}
          >
            <Typography gutterBottom variant="h5" component="div">
              {product.title}
            </Typography>
            <Typography variant="h6" color="text.secondary">
              ${parseFloat(variant.price.amount).toFixed(2)}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{ justifyContent: 'space-between' }}>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                label="Quantity"
                variant="outlined"
                type="number"
                value={variantQuantity}
                onChange={handleQuantityChange}
              />
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'right', display: 'flex' }}>
              <Button
                size="small"
                variant="contained"
                onClick={() => addVariantToCart(variant.id, variantQuantity)}
              >Add To Cart</Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <Dialog
        open={openModal}
        onClose={toggleModal}
        maxWidth="lg">
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h3" gutterBottom>
            Product Overview
          </Typography>

        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0} minWidth={'1000px'}>
            <Grid
              xs={12}
              md={6}
              item
              sx={{
                position: 'relative'
              }}
            >
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', md: 'inline-block' }
                }}
              >
                <Divider
                  absolute
                  sx={{
                    height: '100%',
                    left: 'auto',
                    right: 0
                  }}
                  orientation="vertical"
                  flexItem
                />
              </Box>
              <Box p={6}>
                <SwiperWrapper>
                  <Box
                    mb={3}
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <Swiper
                      loop
                      autoHeight
                      spaceBetween={10}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      navigation={{
                        nextEl: '.MuiSwipe-right',
                        prevEl: '.MuiSwipe-left'
                      }}
                    >

                      {product.images.map((value) => {
                        return (
                          <SwiperSlide key={value}>
                            <img src={variantImage.src} alt="..." />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <SwipeIndicator className="MuiSwipe-root MuiSwipe-left">
                      <ChevronLeftTwoToneIcon />
                    </SwipeIndicator>
                    <SwipeIndicator className="MuiSwipe-root MuiSwipe-right">
                      <ChevronRightTwoToneIcon />
                    </SwipeIndicator>
                  </Box>

                  <Swiper
                    // onSwiper={setThumbsSwiper}
                    loop
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode
                    watchSlidesProgress
                    modules={[FreeMode, Navigation, Thumbs]}
                    navigation={{
                      nextEl: '.MuiSwipe-right',
                      prevEl: '.MuiSwipe-left'
                    }}
                    breakpoints={{
                      500: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 20
                      },
                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 30
                      }
                    }}
                    // @ts-ignore
                    pagination={{
                      clickable: true,
                      dynamicBullets: true
                    }}
                  >
                    {product.images.map((value) => {
                      return (
                        <SwiperSlide key={value}>
                          <img src={variantImage.src} alt="..." />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </SwiperWrapper>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={4} flex={1}>
                <Typography
                  variant="h3"
                  sx={{
                    pb: 2,
                    pt: 1
                  }}
                  component="h3"
                >
                  {product.title}
                </Typography>
                <Typography variant="subtitle2">
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </Typography>
                <Divider
                  sx={{
                    mt: 3
                  }}
                />
                <Box
                  pt={3}
                  pb={1}
                  sx={{
                    px: { xs: 0, md: 3 }
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={4} justifyContent="flex-end">
                      <Box
                        pr={3}
                        pt={1}

                        sx={{

                          pb: { xs: 1, md: 0 }
                        }}
                        alignSelf="center"
                      >
                        <b>Option 1:</b>
                      </Box>
                    </Grid>
                    <Grid
                      mb={2}
                      item
                      xs={12}
                      sm={8}
                      md={6}
                    >
                      <FormControl
                        placeholder={'Select ...'}
                        size="small"
                        fullWidth
                        variant="outlined"
                      >
                        {/* <Select value={option1} onChange={handleChange1}> */}
                        <Select>
                          <MenuItem value={10}>Option 1</MenuItem>
                          <MenuItem value={20}>Option 2</MenuItem>
                          <MenuItem value={30}>Option 3</MenuItem>
                          <MenuItem value={30}>Option 4</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Divider
                  sx={{
                    mb: 3
                  }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography component="div" variant="caption" gutterBottom>
                      Price
                    </Typography>

                    <Typography
                      component="span"
                      variant={'h3'}
                      sx={{
                        pr: 2,
                      }}
                    >
                      ${parseFloat(variant.price.amount).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justifyContent={'right'}>
            <Grid item>
              <Box width={'100px'}>
                <TextField
                  label="Quantity"
                  variant="outlined"
                  type="number"
                  value={variantQuantity}
                  onChange={handleQuantityChange}
                />
              </Box>
            </Grid>
            <Grid item>
              <Button
                startIcon={<AddShoppingCartTwoToneIcon />}
                variant="contained"
                onClick={() => addVariantToCart(variant.id, variantQuantity)}
                size="large"
              >
                Add to Cart
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}
