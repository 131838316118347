// vendors
import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    CircularProgress,
    Grid,
    StepLabel,
    Button,
    Step,
    Typography,
    Stepper,
    styled,
    Tooltip
} from '@mui/material';
import jwt_decode from 'jwt-decode';

// Components
import { AiReportAddons } from './AiReportAddons';
import { AiReportPayment } from './AiReportPayment';

// Pages

// Entities
import { PatientInformation } from '../../entities/AiReportOrder';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

// API

import { PatientStore } from '../../api/PatientStore';
import { observer } from 'mobx-react';
import PatientVisit from '../../entities/PatientVisit';

import _, { isEmpty } from 'lodash';

import { AIReportType } from '../../entities/Enums';
import { AIReporteCommTypes } from '../../entities/const';
import { Gateway } from '../../api/Gateway';
import { AIReportSetup } from '../../models/state_models/aireportsetup';
import { AddonsSetup } from '../../models/state_models/addonsSetup';
import { useCreateAIReportMainStore } from '../../stores/AiReport/CreateAIReportMainStore';

const BoxBorderBottom = styled(Box)(
    () => `
          border-bottom: transparent 5px solid;
    `
);

const steps = [
    'Report Setup',
    'Order Summary'
];

interface IAirReportSteps {
    patient: PatientInformation;
    submission?: any;
    onClosed: () => void;
    visit: PatientVisit;
    reportsOrdered: number;
}

export const AirReportSteps: FC<IAirReportSteps> = observer(({ patient, visit, submission, onClosed, reportsOrdered }) => {
    const { toggleAiReportOrderModal, loadData } = new PatientStore();

    const {
        addOns,
        reportTypeId,
        availableAddOns,
        reportsList,
        visits,
        setVisit,
        setAiReportOrderData,
        saveAiReportOrder,
        visitImagesToPatientImages,
        setPatient,
        setVivosId,
        resetVisitRecord,
        resetAiReportOrderData,
        setReportTypeId,
        aiReportSetupSelected
    } = useCreateAIReportMainStore().aiReportOrder;

    const { id, visitRecords, getVisitId, loadVisits, setVisitPatientId } = useCreateAIReportMainStore().visitRecordStore;
    const { client, cart,  setCartOpen, getCheckoutUrl, addMultipleVariantsToCart, emptyCart } = useCreateAIReportMainStore().shopifyStore;
    const { getAvailableAddOns } = useCreateAIReportMainStore();

    const [activeStep, setActiveStep] = useState(0);
    const [stepsToUse, setStepsToUse] = useState<any>(steps);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [visitRe, setVisitRe] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false);
    const [providerType, setProviderType] = useState();
    const [standardReportPrice, setStandardReportPrice] = useState<any>();

    let decodedToken: any = [];
    const REPORTSETUP = 0;
    const ORDERSUMMARY = 1;

    useEffect(() => {
        const initializeData = async () => {
            let isMounted = true;
            const token = sessionStorage.getItem('token');
            if (token) {
                try {
                    decodedToken = jwt_decode(token);
                    const providerTypes = JSON.parse(decodedToken.providerTypes);
                    if (providerTypes.includes('VIP')) {
                        isMounted && setProviderType(providerTypes.find((x: string) => x.includes('VIP')));
                    } else {
                        isMounted && setProviderType(providerTypes[0]);
                    }
                } catch (error) {
                    console.error('Error decoding token or parsing provider types:', error);
                }
            }

            if (client) {
                try {
                    if (isMounted) {
                        setCartOpen(true);
                    }
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            }

            getAvailableAddOns();
            setVisitPatientId(patient.id);
            try {
                const resp = await loadVisits();
                setVisit(resp[0]);
                setPatient(patient);
                setVivosId(patient.vivosId as string);
                await visitImagesToPatientImages();
                if (isEmpty(submission)) {
                    setAiReportOrderData(resp[0].id, patient.id, patient.providerId, patient.aiReportType, submission);
                }
                setHasLoaded(true);
            } catch (error) {
                console.error('Error loading visits or setting AI report order data:', error);
            }

            return () => {
                isMounted = false;
            };
        };

        initializeData();
    }, []);

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        if (activeStep === REPORTSETUP) {
            if (reportsList.length > 0 && reportTypeId === AIReportType.Standard) {
                setReportTypeId(AIReportType['Free Standard']);
            }

            const addonsToAdd = addOns.map((addon: AddonsSetup) => ({
                variantId: addon.shopifyProduct.variants[0].id,
                quantity: 1,
            }));

            const reportToAdd = {
                variantId: aiReportSetupSelected.shopifyProduct.variants[0].id,
                quantity: 1,
            };

            const itemsToAdd = [reportToAdd, ...addonsToAdd];

            addMultipleVariantsToCart(itemsToAdd);

            doNextStep();
        }
    };

    const handleBack = () => {
        if (activeStep === ORDERSUMMARY) {
            emptyCart();
        }
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const closeWizzard = () => {
        toggleAiReportOrderModal.bind(false);
    };

    const doNextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const openCheckout = async () => {
        setHasLoaded(false);
        try {
            var result = await saveAiReportOrder(cart.id);
            
            resetVisitRecord();
            resetAiReportOrderData();
            const url = await getCheckoutUrl();
            window.open(url, "_self");
        } catch (error) {
            console.error('Error during checkout process:', error);
        } finally {
            setHasLoaded(true);
        }
    };

    const reportSteps = () => {
        return (
            <Grid sx={{ border: '1px solid #ccc', }}>

                {activeStep === REPORTSETUP ? (
                    <Box>
                        <AiReportAddons visits={visitRecords} reportsOrdered={reportsOrdered} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}

                {activeStep === ORDERSUMMARY ? (
                    <Box>
                        <AiReportPayment providerType={providerType} />
                    </Box>
                ) : (
                    <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                )}
            </Grid>);
    };

    return (
        <Box >
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {stepsToUse.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};

                    if (patient?.aiReport) {
                        if (index === 7) {
                            return null;
                        }
                    }

                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <hr />
            {activeStep === stepsToUse.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>All steps completed</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button className="vivButton large" onClick={() => closeWizzard}>
                            RETURN TO PATIENT PORTAL
                        </Button>
                    </Box>
                </React.Fragment>
            ) : (
                <>
                    {hasLoaded
                        ? (isEmpty(submission)
                            && reportSteps())
                        : <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>}
                    {hasLoaded ?
                        <BoxBorderBottom
                            padding={1}
                            sx={(theme) => ({
                                borderBottomColor: theme.palette.primary.main,
                                width: '100%',
                                backgroundColor: theme.colors.alpha.black[5],
                                position: 'absolute',
                                bottom: '0',
                            })}>
                            <Grid container justifyContent={'right'} alignContent={'center'}>
                                {activeStep !== 0 && (
                                    <Button
                                        variant='contained'
                                        color='inherit'
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                )}
                                {activeStep === 0 &&
                                    <Tooltip
                                        placement='top-end'
                                        title={
                                            (reportTypeId === AIReportType.Comparison && visits.length < 2)
                                                ? 'You need to select 2 visit records for a comparison report.'
                                                : ((reportTypeId === AIReportType.Standard || reportTypeId === AIReportType['Free Standard']) && visits.length < 1)
                                                && 'You need to select a visit record for a standard report.'
                                        }
                                    >
                                        <div>
                                            <Button
                                                variant='contained'
                                                 disabled={(() => {
                                                     const isComparisonDisabled = reportTypeId === AIReportType.Comparison && visits.length < 2;
                                                     const isStandardDisabled = (reportTypeId === AIReportType.Standard || reportTypeId === AIReportType['Free Standard']) && visits.length < 1;
                                                     const isDisabled = isComparisonDisabled || isStandardDisabled;
                                                     return isDisabled;
                                                 })()}
                                                onClick={event => {
                                                    isEmpty(submission) && handleNext();
                                                }}
                                            >
                                                Confirm & Continue
                                            </Button>
                                        </div>
                                    </Tooltip>
                                }
                                {activeStep === 1 && <Button
                                    variant='contained'
                                    onClick={openCheckout}
                                >
                                    {'Finish'}
                                </Button>
                                }
                            </Grid>
                        </BoxBorderBottom> : <></>}
                </>
            )}
        </Box>
    );
});
