// Vendors
import React, { Component, useEffect } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";

// Components
import PatientEditBL from "../PatientEditBL";
import Appointments from "../../Appointments/Appointments";
import ModalContainer from "../../ModalContainer";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import ProviderPatient from "../../../entities/ProviderPatient";
import MyoCorrectTherapist from "../../../entities/MyoCorrectTherapist";

//API
import { observer } from "mobx-react";
import { Gateway } from "../../../api/Gateway";



interface IGIProps {
    patient: ProviderPatient;
}


const Comp: React.FC<IGIProps> =
    ({ patient }) => {

        const [editBl] = React.useState<PatientEditBL>(new PatientEditBL());
        const { advocates, loadAdvocates, setCurrentRow, advisors } = useMainStoreContext().myoCorrectStore;
        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [sessionsLoaded, setSessionsLoaded] = React.useState(false);
        const [therapists, setTherapists] = React.useState<MyoCorrectTherapist[]>([]);
        const { setToggleShowMyoCorrectModal, setToggleFirstSession } = useMainStoreContext().myoCorrectEnrollmentStore;

        setCurrentRow(patient);

        const {
            sessions,
            loadCurrentSessions,
        } = useMainStoreContext().sessionsStore;

        useEffect(() => {
            loadAdvocates();
            editBl.loadMyocorrectRegistration(patient.id);
            setHasLoaded(true);
            loadCurrentSessions(patient.id).then(() => {
                setSessionsLoaded(true);
            });
        }, []);

        useEffect(() => {
            setTherapists([...advisors, ...advocates]);
        }, [advocates, advisors]);

        return (
            <>
                {
                    !patient.myoCorrectRegistrationId ?
                        (<>
                            <Grid container>
                                <Grid xs={12} padding={1}>
                                    <Button
                                        variant="contained"
                                        disabled={!hasLoaded}
                                        onClick={() => setToggleShowMyoCorrectModal(true)}
                                    >
                                        Enroll into MyoSync
                                    </Button>
                                </Grid>
                            </Grid>
                        </>)
                        : !sessionsLoaded ?
                            (<>
                                <Grid container>
                                    <Grid item xs={12} textAlign='center'>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            </>)
                            : sessionsLoaded && sessions && !!sessions.length ?
                                (<>
                                    <Grid className="formBasics">
                                        <Appointments
                                            patientId={patient.id}
                                            therapists={therapists}
                                            vivosId={patient.vivosId}
                                            assignedTherapist={editBl.patientMyocorrectRegistration.assignedTherapistId}
                                            sessions={editBl.patientMyocorrectRegistration}
                                            myoCorrectEnrollmentDate={editBl.patientMyocorrectRegistration.myoCorrectEnrollmentDate}
                                            myoCorrectPlanId={editBl.patientMyocorrectRegistration.myoCorrectPlanId}
                                            dataLoaded={hasLoaded}
                                        />
                                    </Grid>
                                </>) :
                                (<>
                                    <Grid container>
                                        <Grid item xs={12} textAlign='right'>
                                            <Button variant="contained" onClick={() => setToggleFirstSession(true)}>Assign initial evaluation</Button>
                                        </Grid>
                                    </Grid>
                                </>)
                }
            </>
        )
    }
export const PatientMyoCorrect = observer(Comp);

