import {
    Box,
    Card,
    Typography,
    alpha,
    styled,
    useTheme
} from '@mui/material';

const BoxComposed = styled(Box)(
    () => `
      position: relative;
    `
);


const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

const BoxComposedImage = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      filter: grayscale(80%);
      background-size: cover;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

const BoxComposedBg = styled(Box)(
    () => `
      position: absolute;
      left: 0;
      top: 0;
      z-index: 6;
      height: 100%;
      width: 100%;
      border-radius: inherit;
    `
);

function RxBlock1({ RxBlock1Content }) {
    const theme = useTheme();

    return (
        <Card
            sx={{
                p: 2
            }}
        >
            <Box
                py={2}
            // sx={{
            //     height: 493
            // }}
            >
                {RxBlock1Content}
            </Box>
        </Card>
    );
}

export default RxBlock1;
