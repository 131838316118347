// Vendors
import React, { useEffect } from "react";
import {
  Grid,
  FormControl,
  Typography,
  Box,
  Button,
  IconButton,
  Link,
} from "@mui/material";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";

// Stores
import { useMainStoreContext } from "../../stores/OldStores/MainStore";

// Entities
import PatientVisit from "../../entities/PatientVisit";

// API
import * as _ from "lodash";
import { observer } from "mobx-react";
import { Gateway } from "../../api/Gateway";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  isIntake: boolean;
  visit: PatientVisit | undefined;
  visitId?: number;
  patientId?: number;
}

const ScanCBCT: React.FC<IProps> = ({ isIntake, visit, patientId, visitId }) => {
  const { setPatientImages } = useMainStoreContext().createPatientStore;
  const { cbctMachineBrand, fov01, fov02, setCbctMachineBrand, setFov01, setFov02, setVisitId } = useMainStoreContext().scanStore;

  const chunkSize = 1048576 * 10; // 10MB chunks
  const [showProgress, setShowProgress] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState<{ name: string; url: string } | null>(null);
  const [fileToBeUploaded, setFileToBeUploaded] = React.useState<File | null>(null);
  const [userId, setUserId] = React.useState(0);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      const tokenData: { roles: string[]; id: number } = jwt_decode(token);
      setUserId(tokenData.id);
    }

    if (visit) {
      loadVisitValues(visit);
    }
  }, [visit]);

  const loadVisitValues = (visit: PatientVisit): void => {
    setVisitId(visit.id);
    setFov01(visit.patientsImages?.[0]?.cbctFov01 || 0);
    setFov02(visit.patientsImages?.[0]?.cbctFov02 || 0);
    setCbctMachineBrand(visit.patientsImages?.[0]?.cbctMachineBrandId || 0);

    // Check for an existing CBCT scan file name
    if (visit.patientsImages?.[0]?.cbctScan) {
      setUploadedFile({
        name: visit.patientsImages[0].cbctScan,
        url: visit.patientsImages[0].pathUrl,
      });
    } else if (Array.isArray(visit.patientsImages)) {
      const cbctFile = visit.patientsImages.find(
        (img) => img.pathUrl && img.imageType?.listItemKey === "CBCTSCAN"
      );
      if (cbctFile) {
        setUploadedFile({
          name: cbctFile.fileName,
          url: cbctFile.pathUrl,
        });
      }
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      
      // Validate file type
      if (!selectedFile.name.toLowerCase().endsWith('.zip')) {
        alert("Only ZIP files are allowed. Please select a valid ZIP file.");
        return;
      }
  
      setFileToBeUploaded(selectedFile);
      await uploadFile(selectedFile);
    }
  };

  const uploadFile = async (file: File) => {
    setShowProgress(true);
    const totalChunks = Math.ceil(file.size / chunkSize);
    let chunkIndex = 0;
    const fileGuid = uuidv4();

    while (chunkIndex < totalChunks) {
      const start = chunkIndex * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      await uploadChunk(chunk, chunkIndex + 1, totalChunks, fileGuid);
      chunkIndex++;
    }

    await uploadCompleted(fileGuid, file.name);
    setShowProgress(false);
    setProgress(100);
  };

  const uploadChunk = async (
    chunk: Blob,
    chunkNumber: number,
    totalChunks: number,
    fileGuid: string
  ) => {
    const chunkData = {
      chunkNumber,
      fileName: fileGuid,
      data: await convertBase64(chunk),
    };

    const url = visitId
      ? `patient/${patientId}/${visitId}/${userId}/fileuploadchunk`
      : `patient/${visit?.patientId}/${visit?.id}/${userId}/fileuploadchunk`;

    await Gateway.post(url, chunkData).then(() => {
      setProgress((chunkNumber / totalChunks) * 100);
    });
  };

  const uploadCompleted = async (fileGuid: string, fileName: string) => {
    const url = visitId
      ? `patient/${patientId}/${visitId}/${userId}/fileuploadchunkcomplete`
      : `patient/${visit?.patientId}/${visit?.id}/${userId}/fileuploadchunkcomplete`;

    const formData = {
      fileName: fileGuid,
      data: "",
      CbctMachineBrandId: cbctMachineBrand,
      CbctFov01: fov01,
      CbctFov02: fov02,
    };

    await Gateway.post(url, formData).then((response) => {
      const data = response as any;
      if (data.isSuccess) {
        setUploadedFile({
          name: fileName,
          url: "#", // Replace with a real URL
        });
      } else {
        console.error("Error completing the upload", data.errorMessage);
      }
    });
  };

  const convertBase64 = (file: Blob) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <Grid className="modalPageHeight">
      <form id="scan">
        <Grid container direction="row" padding={3} justifyContent="center">
          <Grid item xs={6} paddingTop={5}>
            <FormControl sx={{ width: "80%" }}>
              <Button variant="contained" component="label">
                Click to select file
                <input type="file" hidden onChange={handleFileSelect} />
              </Button>
              {showProgress && (
                <Box sx={{ marginTop: 2 }}>
                  <CircularProgressWithLabel value={progress} />
                </Box>
              )}
              {uploadedFile && (
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  {uploadedFile.name.includes("Processing in background") ? (
                  <>
                    <CircularProgress size={20} sx={{ marginRight: 1 }} />
                    <Typography variant="body1">Processing in background...</Typography>
                  </>
                  ) : progress === 100 ? (
                  <>
                    <CheckCircleIcon sx={{ color: "green", marginRight: 1 }} />
                    <Typography variant="body1">{uploadedFile.name}</Typography>
                  </>
                  ) : (
                  <>
                    <DownloadIcon sx={{ marginRight: 1 }} />
                    <Link
                    href={uploadedFile.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                    >
                    Download CBCT
                    </Link>
                  </>
                  )}
                </Box>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container padding={5} direction={"column"}>
              <Typography variant="h4">
                Vivos CBCT guidelines for the most comprehensive Airway Analysis
              </Typography>
              <Box>
                <Grid container padding={1} direction={"column"}>
                  <Typography variant="body1">
                    For best results and support a CBCT scan should capture the following anatomy:
                  </Typography>
                  <Grid paddingLeft={2}>
                    <Typography variant="h5">Nasion</Typography>
                    <Typography variant="h5">Bottom of chin (soft tissue)</Typography>
                    <Typography variant="h5">Inferior border of C3 (hard tissue) C4 and C5</Typography>
                    <Typography variant="h5">Entire spine of C1 (hard tissue)</Typography>
                    <Typography variant="h5">Glabella</Typography>
                    <Typography variant="h5">Tip of nose (soft tissue)</Typography>
                  </Grid>
                </Grid>
                <Grid container padding={1} direction={"column"}>
                  <Typography variant="body1" align="justify" paddingBottom={1}>
                    For proper diagnostics, it is generally recommended that patients who meet the following criteria have the following X-Ray
                    imaging, are taken at the start of treatment, and at the end of each phase of treatment when appropriate.
                  </Typography>
                  <Typography variant="body1">Ages 8 and older:</Typography>
                  <Typography variant="h5" paddingLeft={2} paddingBottom={2}>
                    Full field of view CBCT Scans
                  </Typography>
                  <Typography variant="body1">Ages 7 and younger:</Typography>
                  <Typography variant="h5" paddingLeft={2}>
                    CBCT scan or Panoramic and Cephalometric X-Ray
                  </Typography>
                </Grid>
                <Grid container padding={1} direction={"column"}>
                  <Typography variant="h4" paddingBottom={2}>
                    Upload Instructions
                  </Typography>
                  <Typography variant="body1">
                    <strong>1. Preparing Your Files:</strong>
                  </Typography>
                  <Grid paddingLeft={2}>
                    <Typography variant="body1">
                      1. For CBCT: Take your exported CBCT and place files into a folder and zip it.
                    </Typography>
                    <Typography variant="body1">
                      2. For X-Rays: If you have X-rays instead of a CBCT, place all the images into a single folder and zip it.
                    </Typography>
                    <Typography variant="body1">
                      3. For Multiple CBCTs: If you have multiple CBCT files that need to be stitched together, put all the files into one folder
                      and zip it.
                    </Typography>
                  </Grid>
                  <Typography variant="body1">
                    <strong>2. Zipping Folders:</strong>
                  </Typography>
                  <Grid paddingLeft={2}>
                    <Typography variant="body1">
                      1. On Mac: Follow this link for instructions on how to zip folders.
                    </Typography>
                    <Typography variant="body1">
                      2. On Windows: Follow this link for instructions on how to zip folders.
                    </Typography>
                  </Grid>
                  <Typography variant="body1">
                    <strong>3. Uploading the Zipped Folder:</strong>
                  </Typography>
                  <Grid paddingLeft={2}>
                    <Typography variant="body1">
                      1. Once your files are zipped, return to this page and use the "Upload" button to select your zipped folder.
                    </Typography>
                    <Typography variant="body1">
                      2. Wait for the upload confirmation before navigating away from the page.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

        </Grid>
      </form>
    </Grid>
  );
};

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default observer(ScanCBCT);
