// Vendors
import React, { useEffect, useState } from "react";
import { Button, Grid, Box } from "@mui/material";

// Components
import { TeethCharting } from "../../TeethCharting/TeethCharting";
import ModalContainer from "../../ModalContainer";
import WarningModal from "../../TeethCharting/WarningModal";

// Entities
import Patient from "../../../entities/Patient";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react";
import jwt_decode from 'jwt-decode';

interface IGIProps {
    patient: Patient;
}

export const PatientTeeth: React.FC<IGIProps> = observer(
    ({ patient }) => {

        const { showWarningModal, saveTeethChart, setShowWarningModal, resetTeethChart } = useMainStoreContext().teethChartStore;
        const [isProvider, setIsProvider] = useState<any>([]);

        useEffect(() => {          
            var token = sessionStorage.getItem('token');
            if (token) {
                var tokenData: { roles; id } = jwt_decode(token);
                
                var roles = JSON.parse(tokenData.roles);
                var isProvider = false;
                if(roles.find(x => x.Name === "Provider")){
                    isProvider = true;
                }                
                setIsProvider(isProvider);
            }
            resetTeethChart();
        })

        return (
            <Grid className={"patientDetailsTab"} xs={12} >
                {isProvider ?
                <Grid container direction={"row"} justifyContent={"flex-start"} paddingLeft={2}>
                    <Box width={150}><Button fullWidth variant="contained" color='info' onClick={saveTeethChart}>Save</Button></Box>
                </Grid>
                : null}
                <Grid>
                    <TeethCharting existingPatient={patient} />
                </Grid>

                {showWarningModal && <ModalContainer
                    noScroll={false}
                    mediumScreen={true}
                    show={showWarningModal}
                    title={"Warning"}
                    onClick={() => { }}
                    onClose={() => {
                        setShowWarningModal(false);
                    }}>
                    <WarningModal />
                </ModalContainer>}
            </Grid>
        );
    }
);