// Vendors

// Components
import RxStepOne from './RxStep1';
import RxStepTwo from './RxStep2';
import RxStepThree from './RxStep3';
import RxStepFour from './RxStep4';
import RxStepFive from './RxStep5';
import RxStepSix from './RxStep6';


// API
import { observer } from 'mobx-react';
import { usePrescriptionStore } from '../../../stores/Prescription/CreateEdit/MainStoreContext';

const FormIndex = () => {
    const { step, isClonedPrescription } = usePrescriptionStore().prescriptionStore;

    return (
        <>
            {/* Patient Information */}
            {step === 0 && !isClonedPrescription && <RxStepOne />}
            {/* Lab Selection */}
            {step === 1 && <RxStepTwo  />}
            {/* Teeth Charting */}
            {step === 2 && <RxStepThree />}
            {/* Appliance Construction & Variance Selection */}
            {step === 3 && <RxStepFour />}
            {/* Bites & Models */}
            {step === 4 && <RxStepFive />}
            {/* Final Review */}
            {step === 5 && <RxStepSix />}
        </>

    )
}

export default observer(FormIndex);