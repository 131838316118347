// Vendors
import React, { FC, useEffect, useState } from "react";
import { FormControlLabel, Grid, ToggleButton, Typography, Button, Switch, ButtonGroup } from '@mui/material';

// Components 
import UploadImgViewer from "../../../components/UploadDragAndDrop/UploadImgViewer";

//Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import PatientImage from "../../../entities/PatientImage";
import DictionaryListItem from "../../../entities/DictionaryListItem";

// API
import { observer } from "mobx-react";
import _, { values } from "lodash";

const style = {
    top: 0,
    left: 0,
    width: '500px',
    height: '500px',
}

const fileTypes = ["PDF", "DOCX", "DOC"];
interface IGIProps {
    //submission: any;
}

export const AIReportImages: FC<IGIProps> = observer(
    ({ }) => {

        const { extraoralImageType, patientImages, setPatientImages } = useMainStoreContext().createPatientStore;
        const { getVisitId } = useMainStoreContext().visitRecordStore;
        const { intraoral2dimages, extraoral2dimages, extraoral3dimages,
            occlusion2dimages, occlusion3dimages, cbctscan,
            extraoralImageTypes, intraoralImageTypes, occlusionImageTypes, currentVisit: visit, patient,
            visitImagesToPatientImages, uploadSubmittedImages } = useMainStoreContext().aiReportEntryStore;
        const [currentSelectedType, setCurrentSelectedType] = React.useState<DictionaryListItem>(new DictionaryListItem({
            id: 0,
            name: "",
            listItemKey: "",
            value: "",
            isActive: true,
            dictionaryListId: 0
        }));

        const [hasLoaded, setHasLoaded] = React.useState(false);
        const [isEditable, setIsEditable] = React.useState(false);

        useEffect(() => {

            if (patient && visit && visit.patientsImages && visit.patientsImages[0]) {
                visitImagesToPatientImages().then(() => {
                    setHasLoaded(true);
                })
            }
        }, [visit])

        const handleSave = () => {
            uploadSubmittedImages();
        }

        const handleToggleEdit = () => {
            setIsEditable(!isEditable);
        }
        return (

            <Grid className="modalPageHeight autoScroll" padding={2}>
                {hasLoaded ?

                    <Grid sx={{ flexGrow: 1 }}>
                        <Grid container direction="row" style={{ alignContent: "center" }}>
                            <Grid container xs={12} alignItems="center" justifyContent="flex-end"><Grid><FormControlLabel label="Edit" control={<Switch onChange={handleToggleEdit} defaultChecked={false} />} /></Grid>
                                <Grid><Button className="vivButton large invert" onClick={handleSave}>Save Changes</Button></Grid></Grid>
                        </Grid>
                        {extraoral2dimages.length > 0 &&
                            <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Extraoral Images</Typography>
                                <UploadImgViewer isEditable={isEditable} images={extraoral2dimages} imageTypes={extraoralImageTypes} vivosId={patient.vivosId} visitId={getVisitId()} />
                            </Grid>
                        }
                        {extraoral3dimages.length > 0 &&
                            <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Extraoral 3D Scan</Typography>
                                <UploadImgViewer isEditable={false} images={extraoral3dimages} vivosId={patient.vivosId} visitId={getVisitId()} />
                            </Grid>
                        }
                        {intraoral2dimages.length > 0 &&
                            <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Intraoral Images</Typography>
                                <UploadImgViewer isEditable={isEditable} images={intraoral2dimages} imageTypes={intraoralImageTypes} vivosId={patient.vivosId} visitId={getVisitId()} />
                            </Grid>
                        }
                        {cbctscan &&
                            <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>CBCT Scan</Typography>
                                <UploadImgViewer isEditable={false} images={cbctscan} vivosId={patient.vivosId} visitId={getVisitId()} />
                            </Grid>
                        }

                        {occlusion2dimages.length > 0 &&
                            <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Patient Occlusion 2D Images</Typography>
                                <UploadImgViewer isEditable={isEditable} images={occlusion2dimages} imageTypes={occlusionImageTypes} vivosId={patient.vivosId} visitId={getVisitId()} />
                            </Grid>}
                        {occlusion3dimages.length > 0 &&
                            <Grid container spacing={1} direction="row" paddingTop={3} justifyContent="space-evenly" align-content="space-around">
                                <Typography variant="h6" paddingLeft={3} paddingTop={1}>Patient Occlusion 3D Images</Typography>
                                <UploadImgViewer isEditable={false} images={occlusion3dimages} vivosId={patient.vivosId} visitId={getVisitId()} />
                            </Grid>
                        }
                    </Grid> :
                    <></>
                }
            </Grid>


        )
    }
);