import React, { useState } from 'react';
import {
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Typography,
    TextField,
    ListItemButton
} from '@mui/material';
import moment from 'moment';
import { observer } from 'mobx-react';
import * as _ from 'lodash';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { countryLabelValues, stateLabelValues, Gender, Ethnicity, TreatmentStatus } from '../../../entities/Enums';
import { GlobalUtils } from '../../../api/GlobalUtils';
import ProviderPatient from '../../../entities/ProviderPatient';
import PatientEditBL from '../PatientEditBL';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import CssSelectField from '../../CssSelectField';
import { ListItemIcon } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import LegalGuardianData from '../../../entities/LegalGuardian';
import { treatmentStatusArr } from '../../../imgs/status';
import { PatientStore } from '../../../api/PatientStore';
import { globalState } from 'mobx/dist/internal';
import { Global } from '@emotion/react';
const stateMap = (): any => {
    var states = _.map(stateLabelValues, (s, i) => {
        return (
            <MenuItem key={i} value={s.value}>
                {s.label}
            </MenuItem>
        );
    });
    return states;
};

const countryMap = (): any => {
    var countries = _.map(countryLabelValues, (s, i) => {
        return (
            <MenuItem key={i} value={s.value}>
                {s.label}
            </MenuItem>
        );
    });
    return countries;
};

interface IGIProps {
    patient: ProviderPatient;
    bl: PatientEditBL;
    practiceLocations?: any;
}

export const PatientGeneralInfoTwo: React.FC<IGIProps> = observer(({ bl, patient, practiceLocations }) => {
    const providerStore = useMainStoreContext().providerStore;
    const [toggleOnEdit, setToggleOnEdit] = useState(bl.isEditMode);

    const [firstName, setFirstName] = useState(patient ? patient.firstName : '');
    const [middleName, setMiddleName] = useState(patient ? patient.middleName : '');
    const [lastName, setLastName] = useState(patient ? patient.lastName : '');
    const [dateOfBirth, setDateOfBirth] = useState(patient ? patient.dateOfBirth : '');
    const [genderId, setGenderId] = useState(patient ? patient.genderId : '');
    const [practiceLocationId, setPracticeLocationId] = useState(patient ? patient.companyId : '');
    const [treatmentStatusId, setTreatmentStatusId] = useState(patient ? patient.treatmentStatusId : '');
    const [ethnicityId, setEthnicityId] = useState(patient ? patient.ethnicityId : '');
    const [primaryPhone, setPrimaryPhone] = useState(patient ? patient.primaryPhone : '');
    const [altPhone, setAltPhone] = useState(patient ? patient.altPhone : '');
    const [emailAddress, setEmailAddress] = useState(patient ? patient.emailAddress : '');
    /*
    const [addressOne, setAddressOne] = useState(bl?.addresses[0] ? bl?.addresses[0]?.address1 : '');
    const [addressTwo, setAddressTwo] = useState(bl?.addresses[0] ? bl?.addresses[0]?.address2 : '');
    const [city, setCity] = useState(bl.addresses[0] ? bl.addresses[0].city : '');
    const [state, setState] = useState(bl.addresses[0] ? bl.addresses[0].state : '');
    const [postalCode, setPostalCode] = useState(bl.addresses[0] ? bl.addresses[0].postalCode : '');
    const [country, setCountry] = useState(bl.addresses[0] ? bl.addresses[0].country : '');
    */
    const hasFirstAddress = Array.isArray(bl?.addresses) && bl.addresses.length > 0;

    const [addressOne, setAddressOne] = useState(hasFirstAddress ? bl.addresses[0]?.address1 : '');
    const [addressTwo, setAddressTwo] = useState(hasFirstAddress ? bl.addresses[0]?.address2 : '');
    const [city, setCity] = useState(hasFirstAddress ? bl.addresses[0]?.city : '');
    const [state, setState] = useState(hasFirstAddress ? bl.addresses[0]?.state : '');
    const [postalCode, setPostalCode] = useState(hasFirstAddress ? bl.addresses[0]?.postalCode : '');
    const [country, setCountry] = useState(hasFirstAddress ? bl.addresses[0]?.country : '');


    const practiceLocationMap = (): any => {
        var practiceLocations = _.map(providerStore.practiceLocations, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['label']}
                </MenuItem>
            );
        });
        return practiceLocations;
    };

    let treatmentStatusValues = [];
    const treatmentStatusMap = (): any => {
        treatmentStatusValues = GlobalUtils.getTreatmentStatuses()[0]?.dictionaryListItems;
        let hidedStatuses = [
            4, // Archive
            6, // Technician Review
            1248, //Unknown
            1249, // Clinical Review
            1250, // More Info Needed
            1251, // All
            1252, // Final Review
            1253, // Ignore
            1254 // Pending
        ]

        var treatmentStatuses = _.map(treatmentStatusValues, (s, i) => {
            if (!hidedStatuses.includes(s['id'])) {
                return (
                    <MenuItem key={i} value={s['id']}>
                        {s['name']}
                    </MenuItem>
                );
            }
        });
        return treatmentStatuses;
    };

    let genderValues = [];
    const genderMap = (): any => {
        genderValues = GlobalUtils.getGenders();
        var genders = _.map(genderValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return genders;
    };

    let ethnicityValues = [];
    const ethnicityMap = (): any => {
        ethnicityValues = GlobalUtils.getEthnicities();
        var ethnicities = _.map(ethnicityValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return ethnicities;
    };


    const relationshipValues = GlobalUtils.getRelationshipTypes();
    const relationshipMap = (): any => {
        var relationships = _.map(relationshipValues, (s, i) => {
            return <MenuItem key={i} value={s["id"]}>{s["name"]}</MenuItem>
        });
        return relationships;
    }

    //legal guardian 1 
    const [LG1firstName, setLG1FirstName] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].firstName : '');
    const [LG1lastName, setLG1LastName] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].lastName : '');
    const [LG1Email, setLG1Email] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].emailAddress : '');
    const [LG1PrimaryPhone, setLG1PrimaryPhone] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].primaryPhone : '');
    const [LG1AltPhone, setLG1AltPhone] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].altPhone : '');
    const [LG1RelationShipType, setLG1RelationShipType] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].relationshipTypeId : 18);
    const [LG1Deleted, setLG1Deleted] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 0 ? bl.legalGuardians.filter(x => !x.deleted)[0].deleted : false);

    const handleLG1FirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1FirstName(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[0].firstName = event.target.value;
        }
    };
    const handleLG1LastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1LastName(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[0].lastName = event.target.value;
        }
    };
    const handleLG1EmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1Email(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[0].emailAddress = event.target.value;
        }
    };
    const handleLG1PrimaryPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1PrimaryPhone(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[0].primaryPhone = event.target.value;
        }
    };
    const handleLG1AltPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1AltPhone(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[0].altPhone = event.target.value;
        }
    };
    const handleLG1RelationShipTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1RelationShipType(Number(event.target.value));
            bl.legalGuardians.filter(x => !x.deleted)[0].relationshipTypeId = Number(event.target.value);
        }
    };
    const handleLG1DeletedChange = () => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 0) {
            setLG1Deleted(true);
            bl.legalGuardians.filter(x => !x.deleted)[0].deleted = true;
        }
    };

    //legal guardian 2 
    const [LG2firstName, setLG2FirstName] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].firstName : '');
    const [LG2lastName, setLG2LastName] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].lastName : '');
    const [LG2Email, setLG2Email] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].emailAddress : '');
    const [LG2PrimaryPhone, setLG2PrimaryPhone] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].primaryPhone : '');
    const [LG2AltPhone, setLG2AltPhone] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].altPhone : '');
    const [LG2RelationShipType, setLG2RelationShipType] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].relationshipTypeId : 18);
    const [LG2Deleted, setLG2Deleted] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 1 ? bl.legalGuardians.filter(x => !x.deleted)[1].deleted : false);

    const handleLG2FirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2FirstName(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[1].firstName = event.target.value;
        }
    };
    const handleLG2LastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2LastName(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[1].lastName = event.target.value;
        }
    };
    const handleLG2EmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2Email(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[1].emailAddress = event.target.value;
        }
    };
    const handleLG2PrimaryPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2PrimaryPhone(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[1].primaryPhone = event.target.value;
        }
    };
    const handleLG2AltPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2AltPhone(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[1].altPhone = event.target.value;
        }
    };
    const handleLG2RelationShipTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2RelationShipType(Number(event.target.value));
            bl.legalGuardians.filter(x => !x.deleted)[1].relationshipTypeId = Number(event.target.value);
        }
    };
    const handleLG2DeletedChange = () => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 1) {
            setLG2Deleted(true);
            bl.legalGuardians.filter(x => !x.deleted)[1].deleted = true;
        }
    };

    //legal guardian 3 
    const [LG3firstName, setLG3FirstName] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].firstName : '');
    const [LG3lastName, setLG3LastName] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].lastName : '');
    const [LG3Email, setLG3Email] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].emailAddress : '');
    const [LG3PrimaryPhone, setLG3PrimaryPhone] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].primaryPhone : '');
    const [LG3AltPhone, setLG3AltPhone] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].altPhone : '');
    const [LG3RelationShipType, setLG3RelationShipType] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].relationshipTypeId : 18);
    const [LG3Deleted, setLG3Deleted] = useState(bl.legalGuardians.filter(x => !x.deleted).length > 2 ? bl.legalGuardians.filter(x => !x.deleted)[2].deleted : false);

    const handleLG3FirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3FirstName(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[2].firstName = event.target.value;
        }
    };
    const handleLG3LastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3LastName(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[2].lastName = event.target.value;
        }
    };
    const handleLG3EmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3Email(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[2].emailAddress = event.target.value;
        }
    };
    const handleLG3PrimaryPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3PrimaryPhone(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[2].primaryPhone = event.target.value;
        }
    };
    const handleLG3AltPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3AltPhone(event.target.value);
            bl.legalGuardians.filter(x => !x.deleted)[2].altPhone = event.target.value;
        }
    };
    const handleLG3RelationShipTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3RelationShipType(Number(event.target.value));
            bl.legalGuardians.filter(x => !x.deleted)[2].relationshipTypeId = Number(event.target.value);
        }
    };
    const handleLG3DeletedChange = () => {
        if (bl.legalGuardians.filter(x => !x.deleted).length > 2) {
            setLG3Deleted(true);
            bl.legalGuardians.filter(x => !x.deleted)[2].deleted = true;
        }
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
        bl.patientData.firstName = event.target.value;
    };
    const handleMiddleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMiddleName(event.target.value);
        bl.patientData.middleName = event.target.value;
    };
    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
        bl.patientData.lastName = event.target.value;
    };
    const handleDateOfBirthChange = (value: Date | null) => {
        if (!value) return;
        setDateOfBirth(value.toISOString());
        bl.patientData.dateOfBirth = value;

    };
    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGenderId(event.target.value as unknown as number);
        bl.patientData.genderId = event.target.value as unknown as number;
    };
    const handlePracticeLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPracticeLocationId(event.target.value as unknown as number);
        bl.patientData.companyId = event.target.value as unknown as number;
    };
    const handleTreatmentStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTreatmentStatusId(event.target.value as unknown as number);
        bl.patientData.treatmentStatusId = event.target.value as unknown as number;
    };
    const handleEthnicityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEthnicityId(event.target.value as unknown as number);
        bl.patientData.ethnicityId = event.target.value as unknown as number;
    };
    const handleCellPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrimaryPhone(event.target.value);
        bl.patientData.primaryPhone = event.target.value;
    };
    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAltPhone(event.target.value);
        bl.patientData.altPhone = event.target.value;
    };
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(event.target.value);
        bl.patientData.emailAddress = event.target.value;
    };
    const handleAddressOneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddressOne(event.target.value);
        if (bl.addresses[0] != null) {
            bl.addresses[0].address1 = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].address1 = event.target.value;
        }
    };
    const handleAddressTwoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddressTwo(event.target.value);
        if (bl.addresses[0] != null) {
            bl.addresses[0].address2 = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].address2 = event.target.value;
        }
    };
    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
        if (bl.addresses[0] != null) {
            bl.addresses[0].city = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].city = event.target.value;
        }
    };
    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.value);
        if (bl.addresses[0] != null) {
            bl.addresses[0].state = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].state = event.target.value;
        }
    };
    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostalCode(event.target.value);
        if (bl.addresses[0] != null) {
            bl.addresses[0].postalCode = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].postalCode = event.target.value;
        }
    };
    const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCountry(event.target.value);
        if (bl.addresses[0] != null) {
            bl.addresses[0].country = event.target.value;
        } else {
            const address = {};
            bl.addresses[0] = address;
            bl.addresses[0].country = event.target.value;
        }
    };

    const addLegalGuardian = () => {
        var randomId = (Math.floor(Math.random() * (99999 - 1 + 1)) + 1) * -1;
        console.log("add empty guardian", randomId);
        var lg = new LegalGuardianData({ id: randomId, firstName: "", lastName: "", relationshipTypeId: 18, altPhone: "", primaryPhone: "", emailAddress: "", deleted: false, patientID: bl.patientData.personId });
        bl.legalGuardians = [...bl.legalGuardians, lg];
    };

    return (
        <List
            disablePadding
            sx={{
                my: 1.5
            }}
        >
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='First Name:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={firstName}
                        variant="outlined"
                        onChange={handleFirstNameChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{firstName}</Typography>}
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Middle Name:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={middleName}
                        variant="outlined"
                        onChange={handleMiddleNameChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{middleName}</Typography>}
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Last Name:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={lastName}
                        variant="outlined"
                        onChange={handleLastNameChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{lastName}</Typography>}
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Date of Birth:'
                />
                {bl.isEditMode
                    ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={moment(dateOfBirth?.toString().substring(0, 10)).format('MM/DD/yyyy')}
                            openTo="day"
                            disableFuture={true}
                            views={['year', 'month', 'day']}
                            onChange={handleDateOfBirthChange}
                            renderInput={(params) =>
                                <TextField {...params}
                                    sx={{
                                        width: '70%',
                                    }}
                                    variant="outlined"
                                    id="date-of-birth"
                                    fullWidth
                                    value={moment(dateOfBirth?.toString().substring(0, 10)).format('MM/DD/yyyy')}
                                />}

                        />
                    </LocalizationProvider>
                    : <Typography width={'70%'} variant="subtitle1">{moment(dateOfBirth?.toString().substring(0, 10)).format('MM/DD/yyyy')}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Gender:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={genderId}
                        variant="outlined"
                        onChange={handleGenderChange}
                    >
                        {genderMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{genderId ? Gender[genderId] : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Ethnicity:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={ethnicityId}
                        variant="outlined"
                        onChange={handleEthnicityChange}
                    >
                        {ethnicityMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{ethnicityId ? Ethnicity[ethnicityId] : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Mobile Phone:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={primaryPhone}
                        variant="outlined"
                        onChange={handleCellPhoneChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{primaryPhone}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Alternate Phone:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={altPhone}
                        variant="outlined"
                        onChange={handlePhoneChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{altPhone}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='E-mail:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={emailAddress}
                        variant="outlined"
                        onChange={handleEmailChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{emailAddress}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Address 1:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={addressOne}
                        variant="outlined"
                        onChange={handleAddressOneChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{addressOne}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Address 2:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={addressTwo}
                        variant="outlined"
                        onChange={handleAddressTwoChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{addressTwo}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='City:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={city}
                        variant="outlined"
                        onChange={handleCityChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{city}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='State:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={_.find(stateLabelValues, st => {
                            return st.value == state;
                        })?.value
                        }
                        variant="outlined"
                        onChange={handleStateChange}
                    >
                        {stateMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{_.find(stateLabelValues, st => {
                        return st.value == state;
                    })?.label
                    }</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Postal Code:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        required={true}
                        value={postalCode}
                        variant="outlined"
                        onChange={handlePostalCodeChange}
                    />
                    : <Typography width={'70%'} variant="subtitle1">{postalCode}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Country:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={_.find(countryLabelValues, st => {
                            return st.value == country;
                        })?.value
                        }
                        variant="outlined"
                        onChange={handleCountryChange}
                    >
                        {countryMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{_.find(countryLabelValues, st => {
                        return st.value == country;
                    })?.label
                    }</Typography>}

            </ListItem>

            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Practice Location:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={practiceLocationId}
                        variant="outlined"
                        onChange={handlePracticeLocationChange}
                    >
                        {practiceLocationMap()}
                    </TextField>

                    : <Typography width={'70%'} variant="subtitle1">{practiceLocationId ? providerStore.practiceLocations?.find(x => x.id === practiceLocationId)?.label : ''}</Typography>}

            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primaryTypographyProps={{
                        variant: 'h5',
                        textAlign: 'right',
                        paddingRight: 2
                    }}
                    primary='Treatment Status:'
                />
                {bl.isEditMode
                    ? <TextField sx={{
                        width: '70%',
                    }}
                        fullWidth
                        select
                        required={true}
                        value={treatmentStatusId}
                        variant="outlined"
                        onChange={handleTreatmentStatusChange}
                    >
                        {treatmentStatusMap()}
                    </TextField>
                    : <Typography width={'70%'} variant="subtitle1">{treatmentStatusId ? GlobalUtils.getTreatmentStatuses()[0]?.dictionaryListItems.find(x => x.id === treatmentStatusId)?.name : ''}</Typography>}

            </ListItem>

            {moment().diff(patient.dateOfBirth, 'years') < 18 && bl.legalGuardians.filter(x => !x.deleted).length > 0 && (
                <React.Fragment>
                    {bl.isEditMode && (
                        <ListItem>
                            <ListItemIcon onClick={() => {
                                handleLG1DeletedChange();
                            }}><Delete /></ListItemIcon>
                        </ListItem>
                    )}
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h4',
                                textAlign: 'left',
                                paddingRight: 2
                            }}
                            primary='Legal Guardian'
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='First Name:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG1firstName}
                                variant="outlined"
                                onChange={handleLG1FirstNameChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG1firstName}</Typography>}
                    </ListItem>

                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Last Name:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG1lastName}
                                variant="outlined"
                                onChange={handleLG1LastNameChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG1lastName}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Relationship:'
                        />
                        {bl.isEditMode ?
                            <CssSelectField
                                size="small"
                                className="DateBox"
                                sx={{ width: '100%' }}
                                onChange={(event: any) => {
                                    bl.legalGuardians.filter(x => !x.deleted)[0].relationshipTypeId = event.target.value as number;
                                }}
                                value={bl.legalGuardians.filter(x => !x.deleted)[0].relationshipTypeId ? bl.legalGuardians.filter(x => !x.deleted)[0].relationshipTypeId : ""}>
                                {relationshipMap()}
                            </CssSelectField>
                            : <Typography width={'70%'} variant="subtitle1">{bl.getItemFromList(relationshipValues, "id", bl.legalGuardians.filter(x => !x.deleted)[0].relationshipTypeId, "name")}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Email:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG1Email}
                                variant="outlined"
                                onChange={handleLG1EmailChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG1Email}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Primary Phone:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG1PrimaryPhone}
                                variant="outlined"
                                onChange={handleLG1PrimaryPhoneChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG1PrimaryPhone}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Alt Phone:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG1AltPhone}
                                variant="outlined"
                                onChange={handleLG1AltPhoneChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG1AltPhone}</Typography>}
                    </ListItem>
                </React.Fragment>
            )}

            {moment().diff(patient.dateOfBirth, 'years') < 18 && bl.legalGuardians.filter(x => !x.deleted).length > 1 && (
                <React.Fragment>
                    {bl.isEditMode && (
                        <ListItem>
                            <ListItemIcon onClick={() => {
                                handleLG2DeletedChange();
                            }}><Delete /></ListItemIcon>
                        </ListItem>
                    )}
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h4',
                                textAlign: 'left',
                                paddingRight: 2
                            }}
                            primary='Legal Guardian'
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='First Name:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG2firstName}
                                variant="outlined"
                                onChange={handleLG2FirstNameChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG2firstName}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Last Name:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG2lastName}
                                variant="outlined"
                                onChange={handleLG2LastNameChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG2lastName}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Relationship:'
                        />
                        {bl.isEditMode ?
                            <CssSelectField
                                size="small"
                                className="DateBox"
                                sx={{ width: '100%' }}
                                onChange={(event: any) => {
                                    bl.legalGuardians.filter(x => !x.deleted)[1].relationshipTypeId = event.target.value as number;
                                }}
                                value={bl.legalGuardians.filter(x => !x.deleted)[1].relationshipTypeId ? bl.legalGuardians.filter(x => !x.deleted)[1].relationshipTypeId : ""}>
                                {relationshipMap()}
                            </CssSelectField>
                            : <Typography width={'70%'} variant="subtitle1">{bl.getItemFromList(relationshipValues, "id", bl.legalGuardians.filter(x => !x.deleted)[1].relationshipTypeId, "name")}</Typography>}

                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Email:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG2Email}
                                variant="outlined"
                                onChange={handleLG2EmailChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG2Email}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Primary Phone:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG2PrimaryPhone}
                                variant="outlined"
                                onChange={handleLG2PrimaryPhoneChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG2PrimaryPhone}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Alt Phone:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG2AltPhone}
                                variant="outlined"
                                onChange={handleLG2AltPhoneChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG2AltPhone}</Typography>}
                    </ListItem>
                </React.Fragment>
            )}

            {moment().diff(patient.dateOfBirth, 'years') < 18 && bl.legalGuardians.filter(x => !x.deleted).length > 2 && (
                <React.Fragment>
                    {bl.isEditMode && (
                        <ListItem>
                            <ListItemIcon onClick={() => {
                                handleLG3DeletedChange();
                            }}><Delete /></ListItemIcon>
                        </ListItem>
                    )}
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h4',
                                textAlign: 'left',
                                paddingRight: 2
                            }}
                            primary='Legal Guardian'
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='First Name:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG3firstName}
                                variant="outlined"
                                onChange={handleLG3FirstNameChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG3firstName}</Typography>}
                    </ListItem>

                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Last Name:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG3lastName}
                                variant="outlined"
                                onChange={handleLG3LastNameChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG3lastName}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Relationship:'
                        />
                        {bl.isEditMode ?
                            <CssSelectField
                                size="small"
                                className="DateBox"
                                sx={{ width: '100%' }}
                                onChange={(event: any) => {
                                    bl.legalGuardians.filter(x => !x.deleted)[2].relationshipTypeId = event.target.value as number;
                                }}
                                value={bl.legalGuardians.filter(x => !x.deleted)[2].relationshipTypeId ? bl.legalGuardians.filter(x => !x.deleted)[2].relationshipTypeId : ""}>
                                {relationshipMap()}
                            </CssSelectField>
                            : <Typography width={'70%'} variant="subtitle1">{bl.getItemFromList(relationshipValues, "id", bl.legalGuardians.filter(x => !x.deleted)[2].relationshipTypeId, "name")}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Email:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG3Email}
                                variant="outlined"
                                onChange={handleLG3EmailChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG3Email}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Primary Phone:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG3PrimaryPhone}
                                variant="outlined"
                                onChange={handleLG3PrimaryPhoneChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG3PrimaryPhone}</Typography>}
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'h5',
                                textAlign: 'right',
                                paddingRight: 2
                            }}
                            primary='Alt Phone:'
                        />
                        {bl.isEditMode
                            ? <TextField sx={{
                                width: '70%',
                            }}
                                fullWidth
                                required={true}
                                value={LG3AltPhone}
                                variant="outlined"
                                onChange={handleLG3AltPhoneChange}
                            />
                            : <Typography width={'70%'} variant="subtitle1">{LG3AltPhone}</Typography>}
                    </ListItem>
                </React.Fragment>
            )}

            <ListItem>
                {moment().diff(patient.dateOfBirth, 'years') < 18 && bl.isEditMode && bl.legalGuardians.filter(x => !x.deleted).length < 3 && (
                    <ListItemButton
                        sx={{ width: '200px' }}
                        className="vivButton large invert"
                        onClick={() => { addLegalGuardian(); }}
                    >
                        Add Legal Guardian
                    </ListItemButton>
                )}
            </ListItem>
        </List>
    );
});
