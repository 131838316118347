import { Button, Grid } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { Gateway } from '../../api/Gateway';
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

import { ModalContainer } from '../ModalContainer';
import QuickAddPersonalInfo from './QuickAddPersonalInfo';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export const QuickAddModal: FC<IProps> = ({ isOpen, onClose }) => {

    const { getPatient, validatePatient } = useMainStoreContext().createPatientStore;
    const [validationList, setValidationList] = React.useState<any[]>([]);
    const [toggleValidateModal, setToggleValidateModal] = React.useState(false);

    const validate = (): any[] => {
        let validations = validatePatient();
        setValidationList(validations);
        return validations;
    }

    const handleSave = () => {
        const patient = getPatient();
        patient.isProspect = true;
        const validations = validate();
        if (validations.length <= 0) {
            Gateway.post(`/patient`, patient).then((resp) => {
                onClose();
            });
        } else {
            setToggleValidateModal(true);
            return false;
        }
    }

    return (
        <ModalContainer
            show={isOpen}
            onClose={() => {
                onClose();
            }}
            title={`Patient - Quick Add`}

            onClick={() => {
                onClose();
            }}

            extSx={{
                '& .MuiPaper-root': {
                    height: '800px',
                    width: '980px'
                }
            }}
        >
            <QuickAddPersonalInfo />
            <Grid container item xs={12} justifyContent="flex-end">
                <Button className="vivButton invert large" onClick={handleSave}>SAVE</Button>
            </Grid>
        </ModalContainer >
    );
};
