// Vendors
import { useEffect, useState } from "react";
import {
	Box,
	Grid,
	Typography,
	Card,
	Divider,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableFooter,
	TableContainer,
	styled,
	Button
} from '@mui/material';

import { LoadingButton } from "@mui/lab";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react"
import { Gateway } from "../../../api/Gateway";
import CurrencyFormatter from "../../../components/formatters/currencyformatter";
import ShopifyCartReview from "../../../components/shopify/ShopifyCartReview";

const TableWrapper = styled(Box)(
	({ theme }) => `
    border: 1px solid ${theme.colors.alpha.black[10]};
    border-bottom: 0;
    margin: ${theme.spacing(4)} 0;
  `
);

const BoxBorderBottom = styled(Box)(
	() => `
          border-bottom: transparent 5px solid;
    `
);

const StepThree = () => {
	const { currentPatient, selectedPlan, selectedPlanECom, userID, setToggleShowMyoCorrectModal, setStep } = useMainStoreContext().myoCorrectEnrollmentStore;
	const { loadPatient } = useMainStoreContext().consultationData;
	const { cart, getCheckoutUrl, emptyCart } = useMainStoreContext().shopifyStore;
	const [isEnrolling, setIsEnrolling] = useState(false);
	const [productSelected, setProductSelected] = useState<any>({});

	const handleBack = () => {
		emptyCart();
		setStep(1);
	}

	const openCheckout = async () => {
		try {


			if (!currentPatient) return;
			setIsEnrolling(true);

			Gateway.post('/patient/enroll', {
				patientId: currentPatient.id,
				planId: selectedPlan,
				userId: userID,
				checkoutId: cart.id
			}).then(async () => {
				const url = await getCheckoutUrl();
				window.open(url, "_self");
				setIsEnrolling(false);
			});


		} catch (error) {
			setIsEnrolling(true);
			console.error(error);
		} finally {
		}
	};

	const enroll = () => {
		if (!currentPatient) return;
		setIsEnrolling(true);
		Gateway.post('/patient/enroll', {
			patientId: currentPatient.id,
			planId: selectedPlan,
			userId: userID
		}).then(() => {
			loadPatient().then(() => {
				setToggleShowMyoCorrectModal(false);
			});
			setIsEnrolling(false);
		});
	};
	return (
		<Grid container justifyContent={'center'}>
			<Grid item xs={8} padding={2}>
				<Card
					sx={{
						p: 3,
						mb: 3
					}}
				>
					<Box
						display="flex"
						alignItems="flex-start"
						justifyContent="space-between"
					// height={'150px'}
					>
						<Box>
							<Typography variant="h1" gutterBottom>
								Myofunctional Therapy Enrollment
							</Typography>
							<Typography variant="h3" color="text.secondary">
								Order Review
							</Typography>
						</Box>
					</Box>
					<Divider
						sx={{
							my: 4
						}}
					/>

					<TableWrapper>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Program</TableCell>
										<TableCell align='right'>Price</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{selectedPlanECom &&

										<TableRow >

											<TableCell>
												{/* <Typography variant='h5'>{AIReportType[reportTypeId]}</Typography> */}
												{selectedPlanECom.name &&
													<Typography variant='h5'>{selectedPlanECom.name}</Typography>
												}
											</TableCell>
											<TableCell align='right'>
												{/* ${Number(shopifyReport?.price?.amount)} */}
												{/* {productSelected.variants && productSelected.variants[0] && productSelected.variants[0].price && productSelected.variants[0].price['amount'] &&
													<CurrencyFormatter amount={productSelected.variants[0].price['amount']} />
												} */}
												{selectedPlanECom.ecommerceItem.providerTypePrices[0].price &&
													<CurrencyFormatter amount={selectedPlanECom.ecommerceItem.providerTypePrices[0].price} />}
											</TableCell>
										</TableRow>
									}
								</TableBody>
							</Table>
						</TableContainer>
					</TableWrapper>

					{/* <ShopifyCartReview checkoutData={cart} /> */}
					<Typography variant="subtitle2" gutterBottom sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
						Please Proceed to Checkout for Final Pricing
					</Typography>
					<Typography variant="subtitle2" gutterBottom>
						Additional information:
					</Typography>
					<Grid padding={2}>
						<Typography variant="body2">
							Once submitted, our team of MyoSync professionals will assist in scheduling your patients for you.
							For any questions or you need to schedule the patient please reach out to MyoSync by Vivos phone
							number (888)-719-7797.
						</Typography>
					</Grid>
					<Grid container justifyContent={'right'}>
						<LoadingButton loading={isEnrolling} onClick={openCheckout} variant="contained">Enroll</LoadingButton>
					</Grid>
				</Card>
			</Grid>
			<BoxBorderBottom
				padding={1}
				sx={(theme) => ({
					borderBottomColor: theme.palette.primary.main,
					width: '100%',
					backgroundColor: theme.colors.alpha.black[5],
					position: 'absolute',
					bottom: '0',
				})}>
				<Grid container justifyContent={'space-between'}>
					<Grid item>
						<Button onClick={() => handleBack()} variant="contained" color="inherit" >Back</Button>
					</Grid>
				</Grid>
			</BoxBorderBottom>
		</Grid>

	)
}
export default observer(StepThree);

