// Vendors
import react, { FC } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Switch,
    Card,
    Box,
    Typography,
    Divider,
    CardContent,
    TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// Components
import Text from "../../../components/UI/Text";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// Entities
import { Ethnicity, Gender } from "../../../entities/Enums";

// API
import { observer } from "mobx-react";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import _ from "lodash";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GlobalUtils } from "../../../api/GlobalUtils";

import { CardFooter } from "reactstrap";




export const ProviderDetails: FC = observer(() => {


    const store = useMainStoreContext().providerStore;
    const [toggleValidateModal, setToggleValidateModal] = react.useState(false);
    const [validationList, setValidationList] = react.useState<any>([]);
    const [invalidEmail, setInvalidEmail] = react.useState(false);
    const resetPassword = () => {
        store.emailNotExist = false;
        store.emailSent = false;
        if (!GlobalUtils.validateEmail(store.emailReset ? store.emailReset : "")) {
            setInvalidEmail(true);
        }
        else {
            store.resetPassword();
            setInvalidEmail(false);
            store.emailReset = "";
        }
    }

    let genderValues = [];
    const genderMap = (): any => {
        genderValues = GlobalUtils.getGenders();
        var genders = _.map(genderValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return genders;
    };

    let ethnicityValues = [];
    const ethnicityMap = (): any => {
        ethnicityValues = GlobalUtils.getEthnicities();
        var ethnicities = _.map(ethnicityValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return ethnicities;
    };

    const validate = (): string[] => {
        let validations: string[] = [];
        if (store.providerData.firstName === "") {
            validations.push("First Name is required");
        }
        if (store.providerData.lastName === "") {
            validations.push("Last Name is required");
        }
        if (store.providerData.emailAddress === "") {
            validations.push("Contact E-mail is required");
        }
        else if (!GlobalUtils.validateEmail(store.providerData.emailAddress ? store.providerData.emailAddress : "") && !_.isEmpty(store.providerData.emailAddress)) {
            validations.push("Invalid Email Format");
        }
        setValidationList(validations);
        return validations;
    }

    const handleAccountDetailsSaveButton = () => {
        const validation = validate();
        if (validation.length > 0) {
            setToggleValidateModal(true);
        }
        else {
            store.saveProvider();
            store.setAccountDetailsToggleEditMode(!store.accountDetailsEditMode)
        }
    }

    const handleAboutYouSaveButton = () => {
        const validation = validate();
        if (validation.length > 0) {
            setToggleValidateModal(true);
        }
        else {
            store.saveProvider();
            store.setAboutYouToggleEditMode(!store.aboutYouEditMode)
        }
    }

    return (
        <Grid container sx={{ overflowY: 'auto', maxHeight: '58vh' }}>

            {/* Account Details */}
            <Grid item xs={12} md={12} lg={6} padding={2}>
                <Card>
                    <Grid container paddingX={2} paddingY={1}>
                        <Grid item xs={9}>
                            <Typography variant="h4" gutterBottom>
                                Account Details
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {!store.accountDetailsEditMode && (
                                <Grid>
                                    <Grid container direction={'row'} justifyContent={'flex-end'}>
                                        <Button size={'small'} variant={'outlined'} onClick={() => { store.setAccountDetailsToggleEditMode(!store.accountDetailsEditMode) }}>
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {store.accountDetailsEditMode && (
                                <Grid>
                                    <Grid container direction={'row'} justifyContent={'flex-end'}>
                                        <Button size={'small'} variant={'contained'} onClick={() => { handleAccountDetailsSaveButton() }}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                Manage information related to your account.
                            </Typography>
                        </Grid>
                        <Box height={2} width="100%" bgcolor='lightgray' px={5} my={1} />
                        <Grid item xs={12} container spacing={1} paddingBottom={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    <Grid container spacing={0}>
                                        <Grid item xs={6} container>
                                            <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    First Name
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <FormControl>
                                                    {store.accountDetailsEditMode ? <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            store.providerData.firstName = event.target.value;
                                                        }}
                                                        value={store.providerData.firstName}
                                                    /> : <Text color="black"><b>{store.providerData.firstName}</b></Text>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    Middle Name
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <FormControl>
                                                    {store.accountDetailsEditMode ? <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            store.providerData.middleName = event.target.value;
                                                        }}
                                                        value={store.providerData.middleName}
                                                    /> : <Text color="black"><b>{store.providerData.middleName}</b></Text>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    Last Name
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <FormControl>
                                                    {store.accountDetailsEditMode ? <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            store.providerData.lastName = event.target.value;
                                                        }}
                                                        value={store.providerData.lastName}
                                                    /> : <Text color="black"><b>{store.providerData.lastName}</b></Text>}
                                                </FormControl>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                        <Box pr={3} pb={2}>
                                            Date of Birth
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl>
                                            {store.editMode ? <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    className="DateBox"
                                                    openTo="day"
                                                    views={['year', 'month', 'day']}
                                                    value={store.providerData.dateOfBirth}
                                                    onChange={newValue => {
                                                        if (newValue != null) {
                                                            store.providerData.dateOfBirth = moment(newValue).toDate();
                                                        }
                                                    }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                '& input': {
                                                                    backgroundColor: 'white',
                                                                    color: '#050505',
                                                                    fontFamily: 'Proxima Nova',
                                                                    fontSize: '16px',
                                                                },
                                                            }}
                                                            size="small"
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider> : <Text color="black"><b>{store.providerData.dateOfBirth ? moment(store.providerData.dateOfBirth).format('MM/DD/yyyy') : ''}</b></Text>}
                                        </FormControl>
                                    </Grid> */}
                                            {/* <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                        <Box pr={3} pb={2}>
                                            Gender
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl>
                                            {store.editMode ? <TextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                required={true}
                                                select
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    store.providerData.genderId = event.target.value as unknown as number;
                                                }}
                                                value={store.providerData.genderId ? Gender[store.providerData.genderId] : ''}
                                            >
                                                {genderMap()}
                                            </TextField> : <Text color="black"><b>{store.providerData.genderId ? Gender[store.providerData.genderId] : ''}</b></Text>}
                                        </FormControl>
                                    </Grid> */}
                                            {/* <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                        <Box pr={3} pb={2}>
                                            Ethnicity
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <FormControl>
                                            {store.editMode ? <TextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                required={true}
                                                select
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    store.providerData.ethnicityId = event.target.value as unknown as number;
                                                }}
                                                value={store.providerData.ethnicityId ? Ethnicity[store.providerData.ethnicityId] : ''}
                                            >
                                                {ethnicityMap()}
                                            </TextField> : <Text color="black"><b>{store.providerData.ethnicityId ? Ethnicity[store.providerData.ethnicityId] : ''}</b></Text>}
                                        </FormControl>
                                    </Grid> */}
                                            <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    Email
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <FormControl>
                                                    <Text color="black"><b>{store.providerData.emailAddress}</b></Text>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={4} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    Primary Phone
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <FormControl>
                                                    {store.accountDetailsEditMode ? <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            store.providerData.primaryPhone = event.target.value;
                                                        }}
                                                        value={store.providerData.primaryPhone}
                                                    /> : <Text color="black"><b>{store.providerData.primaryPhone}</b></Text>}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} container alignContent={'flex-start'}>
                                            <Grid item xs={12} sm={5} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    Dental License
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                                <FormControl>
                                                    {store.accountDetailsEditMode ? <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            store.providerData.providerPeople[0].dentalLicense = event.target.value;
                                                        }}
                                                        value={store.providerData.providerPeople[0].dentalLicense}
                                                    /> : <Text color="black"><b>{store.providerData.providerPeople[0].dentalLicense}</b></Text>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={5} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    License State
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                                <FormControl>
                                                    {store.accountDetailsEditMode ? <TextField
                                                        variant="outlined"
                                                        size="small"
                                                        required={true}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            store.providerData.providerPeople[0].licenseState = event.target.value;
                                                        }}
                                                        value={store.providerData.providerPeople[0].licenseState}
                                                    /> : <Text color="black"><b>{store.providerData.providerPeople[0].licenseState}</b></Text>}
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <CardFooter>
                        <Grid container direction={'column'}>
                            <Typography variant="h4">
                                Password Reset
                            </Typography>
                            <Typography>
                                Please enter your email address to start the password reset process.
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid xs={6} container direction={'column'}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    placeholder=""
                                    onChange={(event: any) => { store.emailReset = event.target.value }}
                                    value={store.emailReset}
                                />
                            </Grid>
                            <Grid xs={6} container justifyContent={'flex-end'}>
                                <Button variant="contained" size="small" onClick={() => { resetPassword() }}>
                                    Reset Password
                                </Button>
                            </Grid>
                            <Grid item xs={12} paddingX={2}>
                                {invalidEmail && <Typography color={"red"}>
                                    Invalid email address.
                                </Typography>}
                                {store.emailNotExist && <Typography color={"red"}>
                                    The user does not exist.
                                </Typography>}
                                {store.emailSent && <Typography color={"red"}>
                                    Mail sent successfully.
                                </Typography>}
                            </Grid>
                        </Grid>
                    </CardFooter>
                </Card>
            </Grid>

            {/* About You Details */}
            <Grid item xs={12} md={12} lg={6} padding={2}>
                <Card>
                    <Grid container paddingX={2} paddingY={1}>
                        <Grid item xs={9}>
                            <Typography variant="h4" gutterBottom>
                                About You
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {!store.aboutYouEditMode && (
                                <Grid>
                                    <Grid container direction={'row'} justifyContent={'flex-end'}>
                                        <Button size={'small'} variant={'outlined'} onClick={() => { store.setAboutYouToggleEditMode(!store.aboutYouEditMode) }}>
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {store.aboutYouEditMode && (
                                <Grid>
                                    <Grid container direction={'row'} justifyContent={'flex-end'}>
                                        <Button size={'small'} variant={'contained'} onClick={() => { handleAboutYouSaveButton() }}>
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                Manage information about you.
                            </Typography>
                        </Grid>
                        <Box height={2} width="100%" bgcolor='lightgray' px={5} my={1} />
                        <Grid item xs={12} container spacing={1} paddingBottom={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                Alma Mater
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <FormControl>
                                                {store.aboutYouEditMode ? <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    required={true}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        store.providerData.providerPeople[0].almaMatter = event.target.value;
                                                    }}
                                                    value={store.providerData.providerPeople[0].almaMatter}
                                                /> : <Text color="black"><b>{store.providerData.providerPeople[0].almaMatter}</b></Text>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                Graduation Year
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <FormControl>
                                                {store.aboutYouEditMode ? <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    required={true}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        store.providerData.providerPeople[0].graduationYear = event.target.value;
                                                    }}
                                                    value={store.providerData.providerPeople[0].graduationYear}
                                                /> : <Text color="black"><b>{store.providerData.providerPeople[0].graduationYear}</b></Text>}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                Bio
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <FormControl fullWidth>
                                                {store.aboutYouEditMode ? <TextField
                                                    variant="outlined"
                                                    size="small"
                                                    required={true}
                                                    multiline
                                                    minRows={10}
                                                    fullWidth
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        store.providerData.providerPeople[0].about = event.target.value;
                                                    }}
                                                    value={store.providerData.providerPeople[0].about}
                                                /> : <Text color="black"><b>{store.providerData.providerPeople[0].about}</b></Text>}
                                            </FormControl>
                                        </Grid>

                                    </Grid>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid>
                <Dialog
                    open={toggleValidateModal}
                    onClose={() => { }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        <Grid display="flex" alignItems="center">
                            <Grid flexGrow={1} >Invalid Provider Data</Grid>
                            <Grid>
                                {<IconButton className="closeButton" onClick={() => { setToggleValidateModal(false) }}>
                                    <CloseIcon />
                                </IconButton>}
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid>
                            <Grid>Please correct the following:</Grid>
                            <Grid>{_.map(validationList, (vl) => {
                                return <Grid>{vl}</Grid>
                            })}</Grid>
                        </Grid>
                        <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setToggleValidateModal(false) }}>OK</Button></Grid>
                    </DialogContent>
                </Dialog>
            </Grid>
        </Grid >
    );

});