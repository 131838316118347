// Vendors
import react, { FC } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    Grid,
    MenuItem,
    Typography,
    Card,
    Box,
    Divider,
    CardContent,
    TextField
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Components
import Item from "../../../components/Item";
import UploadImgDragDrop from "../../../components/UploadDragAndDrop/UploadImgDragDrop";

// Store
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

// API
import { observer } from "mobx-react";
import { GlobalUtils } from "../../../api/GlobalUtils";
import _ from "lodash";


import CssSelectField from "../../../components/CssSelectField";
import { FormLabel } from "@material-ui/core";

interface IGIProps {
}


export const ProviderPreferences: FC<IGIProps> = observer(() => {
    const store = useMainStoreContext().providerStore;
    const [expanded, setExpanded] = react.useState<string | false>(false);
    const [unitType, setUnitType] = react.useState(0);

    react.useEffect(() => {
        setExpanded('panel1');
        store.getProviderPreferences().then(() => {
            setUnitType(store.providerPreferences.unitType ? 1 : 2);
        });
    }, []);

    react.useEffect(() => {
        store.providerPreferences.unitType = unitType == 1 ? true : false;
    }, [unitType]);

    const handleChange = (current: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? current : false);
    };

    let teethSetValues: any = [];
    const teethSetMap = (): any => {
        teethSetValues = GlobalUtils.getTeethSetType();
        var teethSet = _.map(teethSetValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return teethSet;
    };

    let teethNumSystemValues: any = [];
    const teethNumSystemMap = (): any => {
        teethNumSystemValues = GlobalUtils.getTeethNumSystemType();
        var teethNumSystem = _.map(teethNumSystemValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return teethNumSystem;
    };

    let cbctMacBrandValues: any = [];
    const cbctBrandMap = (): any => {
        cbctMacBrandValues = GlobalUtils.getCBCTMachineBrands();
        var cbctBrand = _.map(cbctMacBrandValues, (s, i) => {
            return (
                <MenuItem key={i} value={s['id']}>
                    {s['name']}
                </MenuItem>
            );
        });
        return cbctBrand;
    };

    return (
        <Grid container sx={{ overflowY: 'auto', maxHeight: '58vh' }}>
            <Grid item xs={12} padding={1}>
                <Card>
                    <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid container>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        Settings
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Set your vCloud Provider Portal settings here:
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} container justifyContent={'flex-end'}>
                                <Button variant="outlined" onClick={() => { store.saveProviderPreferences() }}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <CardContent
                        sx={{
                            p: 4
                        }}
                    >
                        <Grid container spacing={0}>
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={4} container direction={'column'}>
                                    <Typography variant="h5">Unit Type</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            select
                                            onChange={(event: any) => {
                                                setUnitType(event.target.value as unknown as number);
                                            }}
                                            value={unitType}
                                        >
                                            <MenuItem key={1} value={1}>{'Metric'}</MenuItem>
                                            <MenuItem key={2} value={2}>{'US'}</MenuItem>
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} container direction={'column'}>
                                    <Typography variant="h5">Default Teeth Chart</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            select
                                            onChange={(event: any) => {
                                                store.providerPreferences.teethSetPref = event.target.value as unknown as number;
                                            }}
                                            value={store.providerPreferences.teethSetPref ? store.providerPreferences.teethSetPref : ''}
                                        >
                                            {teethSetMap()}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} container direction={'column'}>
                                    <Typography variant="h5">Tooth Numbering System</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            select
                                            onChange={(event: any) => {
                                                store.providerPreferences.teethSystemPref = event.target.value as unknown as number;
                                            }}
                                            value={store.providerPreferences.teethSystemPref ? store.providerPreferences.teethSystemPref : ''}
                                        >
                                            {teethNumSystemMap()}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} container direction={'column'}>
                                    <Typography variant="h5">CBCT Machine Brand</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            select
                                            onChange={(event: any) => {
                                                store.providerPreferences.cbctsbrand = event.target.value as unknown as number;
                                            }}
                                            value={store.providerPreferences.cbctsbrand ? store.providerPreferences.cbctsbrand : ''}
                                        >
                                            {cbctBrandMap()}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} container direction={'column'}>
                                    <Typography variant="h5">CBCT FOV Height (cm)</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            onChange={(event: any) => {
                                                store.providerPreferences.fov1 = event.target.value as unknown as number;
                                            }}
                                            value={store.providerPreferences.fov1 ? store.providerPreferences.fov1 : ''}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} container direction={'column'}>
                                    <Typography variant="h5">CBCT FOV Width (cm)</Typography>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            onChange={(event: any) => {
                                                store.providerPreferences.fov2 = event.target.value as unknown as number;
                                            }}
                                            value={store.providerPreferences.fov2 ? store.providerPreferences.fov2 : ''}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} padding={1}>
                <Card>
                    <Box
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid container>
                            <Grid item xs={6}>
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        Profile Image and Logo
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        Update default imaging for your account.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <CardContent
                        sx={{
                            p: 4
                        }}
                    >
                        <Typography variant="subtitle2">
                            <Grid container spacing={0}>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Grid xs={12} container direction={'row'} className="headerLine ">
                                            <Grid className="label">
                                                <Grid>LOGO</Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid className="viewBody" xs={12} container direction={'column'}>
                                            <UploadImgDragDrop
                                                type=".jpg"
                                                typeOptions={[]}
                                                vivosId={store.providerPreferences.firstName ? store.providerPreferences.firstName : ""}
                                                visitId={store.providerPreferences.id ? store.providerPreferences.id : 0}
                                                extImages={store.logoImage}
                                                parentCallback={() => { }}
                                                deleteCallback={() => { }} />

                                            {store.logoUploaded && <Grid container direction={'row'} className="formLabel">
                                                <Grid>Image uploaded successfully.</Grid>
                                            </Grid>}
                                        </Grid>
                                        <Grid>
                                            <Grid container direction={'row'} justifyContent={'flex-end'}>
                                                <Button variant={'outlined'} onClick={() => { store.saveLogoImage() }}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Grid xs={12} container direction={'row'} className="headerLine ">
                                            <Grid className="label">
                                                <Grid>PROFILE IMAGE</Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid className="viewBody" xs={12} container direction={'column'}>
                                            <UploadImgDragDrop
                                                type=".jpg"
                                                typeOptions={[]}
                                                vivosId={store.providerPreferences.firstName ? store.providerPreferences.firstName : ""}
                                                visitId={store.providerPreferences.id ? store.providerPreferences.id : 0}
                                                extImages={store.profileImage}
                                                parentCallback={() => { }}
                                                deleteCallback={() => { }} />

                                            {store.profileImageUploaded && <Grid container direction={'row'} className="formLabel">
                                                <Grid>Image uploaded successfully.</Grid>
                                            </Grid>}
                                        </Grid>
                                        <Grid>
                                            <Grid container direction={'row'} justifyContent={'flex-end'}>
                                                <Button variant={'outlined'} onClick={() => { store.saveProfileImage() }}>
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
});