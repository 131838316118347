import * as React from 'react';
import "../../scss/_AddSessions.scss";
import _ from "lodash";
import { observer } from "mobx-react";
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import QuestionaireMetaData from '../../entities/QuestionaireMetaData';
import { Gateway } from '../../api/Gateway';
import InitialEvaluation from './InitialEvaluation';
import GeneralSessionNotes from './GeneralSessionNotes';
import { any } from 'prop-types';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { FrenectomyStatus } from '../../entities/Enums';
import PatientQuestionnaireResponse from '../../entities/PatientQuestionnaireResponse';
import { GlobalUtils } from '../../api/GlobalUtils';
import MyoFunctionalEvaluation from './MyoFunctionalEvaluation';
import {
  Button,
  CircularProgress,
  Link,
  Typography,
  Box,
  Grid,
} from "@mui/material"


const AppointmentNotes = (props) => {
  const { currentAppointmentNote,
    currentSession,
    currentVivosId,
    currentPatientId,
    questionsID,
    prevSessionUrl,
    setCurrentAppointmentNoteProp,
    setCurrentAppointmentNotePropCheckbox,
    setCurrentAppointmentNote,
    toggleOpenSessionNotesModal,
    editedAfterTimePeriod
  } = useMainStoreContext().createAppointmentNotesStore;
  const [isLoading, setIsloading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("");

  const { loadCurrentSessions, loadPlan, setSelectedFrenectomy } = useMainStoreContext().sessionsStore
  const { currentRow, loadPatient, setCurrentRow } = useMainStoreContext().myoCorrectStore;
  React.useEffect(() => {
    loadData();
  }, [])


  const loadData = async () => {
    var url = "/questionaire/response/" + currentSession.id + "/" + currentPatientId;
    setIsloading(true);
    Gateway.get(url)
      .then(async (respData) => {
        if (respData.length > 0) {
          let responsesData: { [key: string]: PatientQuestionnaireResponse } = {};
          questionsID.map((answer) => {
            var elementAt = respData.find((obj) => {
              return obj.questionId === answer.id;
            });
            if (elementAt) {
              responsesData[answer.value] = new PatientQuestionnaireResponse({ questionId: elementAt.id, markAsEdited: elementAt.markAsEdited, response: elementAt.response });
            }
            /*else{
              responsesData[answer.value] = new PatientQuestionnaireResponse({questionId:answer.id,markAsEdited:false,response: (answer.defaultValue === undefined ? '' : answer.defaultValue)});
            }*/
          });

          if (responsesData) {
            setCurrentAppointmentNote(responsesData);
          }

        }
        await loadCurrentSessions(currentPatientId);

        setIsloading(false);
      })
  }
  const handleChangeEditor = async (editor, propertyToUpdate: string) => {
    let newValue = editor.getText();
    let oldValue = currentAppointmentNote['assessmentnotes']?.response
    if (newValue !== oldValue) {
      setCurrentAppointmentNoteProp(newValue, propertyToUpdate)
    }
  };
  const handleSave = async () => {
    setLoadingText("Saving....Please wait")
    setIsloading(true);
    var questionArray: any[] = [];
    var frenectomyStatuses: string[] = [];
    if (questionsID != null && questionsID.length > 0) {
      questionsID.map((question: any) => {
        if (currentAppointmentNote[question.value]) {
          if (currentAppointmentNote[question.value].response.toString() !== '') {
            questionArray.push({
              "questionId": question.id,
              "response": currentAppointmentNote[question.value].response.toString(),
              "comments": "",
              "markAsEdited": currentAppointmentNote[question.value].markAsEdited
            });
          }


          switch (question.value) {
            case 'lip':
              if (currentAppointmentNote[question.value]?.response.toString() === '2' || currentAppointmentNote[question.value]?.response.toString() === '1') {
                frenectomyStatuses.push(FrenectomyStatus['Lip Frenectomy'].toString());
              }
              break;
            case 'buccal':
              if (currentAppointmentNote[question.value]?.response === '2' || currentAppointmentNote[question.value]?.response === '1') {
                frenectomyStatuses.push(FrenectomyStatus['Buccal Frenectomy'].toString());
              }
              break;
            case 'frenulum':
              if (currentAppointmentNote[question.value]?.response === '2' || currentAppointmentNote[question.value]?.response === '1') {
                frenectomyStatuses.push(FrenectomyStatus['Lingual Frenectomy'].toString());
              }
              break;
            default:
              break;
          }
        }
      });
    }

    if (frenectomyStatuses.length >= 1) {
      //frenectomyStatuses.push(FrenectomyStatus['None Needed'].toString())

      var request = {
        PatientId: currentPatientId,
        Statuses: frenectomyStatuses
      }
      await Gateway.post("myocorrect/patients/setfrenectomy", request);
    }
    if (questionArray.length > 0) {
      await Gateway.post("/questionaire/response/session/" + currentSession.id + "/" + currentPatientId, questionArray).then(async () => {
        //const newPatient = await loadPatient(currentRow.vivosId);
        //setCurrentRow(newPatient);
        loadPlan(currentRow.myoCorrectPlanId).then(() => {
          loadCurrentSessions(currentRow.id).then(() => {
            if (currentRow.frenectomyStatuses) {
              setSelectedFrenectomy(JSON.parse(currentRow.frenectomyStatuses).map(th => th.Status));
            }
          })

        })
      })
    }
    toggleOpenSessionNotesModal();
    setIsloading(false);
  }

  return (
    <div style={{
      width: "100%",
      height: "100%"
    }}>
      {isLoading &&
        <div style={{
          // do your styles depending on your needs.
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}>
          {
            <Grid container direction="row" justifyContent="center">
              <Grid container justifyContent="center"><Grid>{loadingText}</Grid></Grid>
              <CircularProgress sx={{
                width: "90%",
                height: "90%"
              }} />
            </Grid>
          }
        </div>

      }
      {
        !isLoading &&
        <form>
          <Box paddingTop={2} sx={{ flexGrow: 1, height: "100%", width: '100%' }}>
            <Grid>
              <Grid container paddingLeft={4} columnSpacing={0} marginBottom={"25px"} >
                <Grid item xs={12} mt={1} paddingBottom={2}>
                  <Typography variant="h3">General Information</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        Patient Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentRow.firstName} {currentRow.lastName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        vCloud ID:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentVivosId}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid container spacing={1} paddingBottom={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        Appointment Number:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentSession.sessionIndexNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} paddingBottom={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        Appointment Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentSession.appointmentType === '--' ? 'Initial Appointment' : currentSession.appointmentType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} paddingBottom={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        Appointment Date:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentSession.scheduleDateTime}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid container spacing={1} paddingBottom={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        Evaluation By:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentSession.assignedTherapistId}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} paddingBottom={1}>
                    <Grid item xs={5}>
                      <Typography variant='h5' textAlign={'right'}>
                        Evaluation Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>
                        {currentSession.evaluationType}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {currentSession.evaluationType.toString() === 'Initial Evaluation' &&
                <InitialEvaluation />}
              {currentSession.evaluationType.toString() !== 'Initial Evaluation' &&
                <GeneralSessionNotes />}
              <MyoFunctionalEvaluation />
              <Grid container direction="row" spacing={2} padding={1}>
                <Grid item xs={2}>
                  <Button style={{ width: '200px' }} onClick={toggleOpenSessionNotesModal} variant='contained' color='warning'>Cancel</Button>
                </Grid>
                <Grid item xs={10}>
                  <Grid container justifyContent={'right'}>
                    {!_.isNull(currentSession.sessionNotesPdf) &&
                      <Link href={GlobalUtils.getMyoCorrectUrl(currentSession.sessionNotesPdf, currentVivosId)} target="_blank" rel="noopener noreferrer" underline="none">
                        <Button style={{ width: '200px' }} variant='contained' color='info'>Open PDF</Button> </Link>}
                    <Grid paddingLeft={1}>
                      <Button style={{ width: '200px' }} onClick={handleSave} variant='contained'>Save</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box >
        </form>
      }</div>
  );
};
export default observer(AppointmentNotes);


