import { Button, Grid, IconButton, Link, Typography } from "@mui/material";
import { Comment } from '@mui/icons-material'
import { observer } from "mobx-react";
import { Fragment, useEffect, useState } from "react";
import { DashboardInfoBox } from "../components/DashboardInfoBox/DashboardInfoBox";
import DataGrid from "../components/DataGrid/DataGrid";
import { LeftNav } from "../components/LeftNav/LeftNav";
import ModalContainer from "../components/ModalContainer";
import { MyoCorrectGridHeader } from "../components/MyoCorrectGridHeader";
import { useMainStoreContext } from "../stores/OldStores/MainStore";
import { LabManagementFilters } from '../entities/Enums';
import { ClaimReview } from "../modals/Claim/ClaimReview";
import { PrescriptionReview } from "../components/Prescription/PrescriptionReview";
import PageTitleWrapper from "../components/UI/PageTitleWrapper";
import { ApprovalCode } from "./Lincare/ApprovalCode";

export const TreatmentDashboard = () => {
    const [selectedRow, setSelectedRow] = useState<{id:number}>({ id: 0 });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [dataGrid, setDataGrid] = useState([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const store = useMainStoreContext().labManagementStore;
    const {
        toggleFilterResultsExpand,
        isLeftNavExpanded,
        userId,
        loadTreatmentPage,
        setisLoadingData,
        treatmentDashboardData,
        setTreatmentDashboardData,
        isLoadingData
    } = store;
    useEffect(() => {
        //loadFilterCount();
        loadData();
    }, [])

    function loadData() {
        setisLoadingData(true);
        setLoadingFlag(true);
        loadTreatmentPage().then((response) => {
            if (response) {
                setTreatmentDashboardData(response);
                setDataGrid(response);
                setisLoadingData(false);
                setLoadingFlag(false);
            }
            setisLoadingData(false);
        });
    }
   /*  useEffect(()=>{
        console.log(labManagementData);
        loadTreatmentPage();
    }, [labManagementData]) */
    const numResults = 0;
    const hdrButtons: any = [];
    const infoBoxes: any = [];

    const handleRxClick = async (row: any) => {
        //const labData = await loadLabData(row.id);
        selectedRow.id = row.id;
        setSelectedRow(selectedRow);
        setShowModal(true);
    };

    const defaultPage = "LAB MANAGEMENT";

    hdrButtons.push(
        <Button
            id={'myocorrecttherapist.header.btn.filterPatients.' + userId}
            className="vivButton large"
            onClick={toggleFilterResultsExpand}
        >
            FILTER RESULTS
        </Button>,
    );
    const columns = [
        {
            field: 'submissionDate',
            headerName: 'Date Submitted',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { submissionDate } }) => {
                return submissionDate?.replace('T', ' ').replace('Z', '')
            }
        },
        {
            field: 'daysInQueue',
            headerName: 'Days in Queue',
            headerAlign: 'center',
            minWidth: 150,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { daysInQueue } }) => {
                const color = daysInQueue <= 3 ? 'green' :
                    daysInQueue <= 6 ? 'orange' :
                        'red';
                return (<Typography variant="h6" sx={{ color, fontWeight: 'bold' }}>{daysInQueue}</Typography>)
            },
        },
        {
            field: 'rxId',
            headerName: 'RX ID',
            headerAlign: 'center',
            minWidth: 120,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row }) => (<Button variant="text" onClick={() => handleRxClick(row)}>{row.rxId}</Button>)
        },
        {
            field: 'provider',
            headerName: 'Provider',
            headerAlign: 'center',
            minWidth: 120,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'patient',
            headerName: 'Patient',
            headerAlign: 'center',
            minWidth: 120,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'lab',
            headerName: 'Lab',
            headerAlign: 'center',
            minWidth: 100,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'appliance',
            headerName: 'Appliance',
            headerAlign: 'center',
            minWidth: 100,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field:'lincareApprovalCode',
            headerName: 'Approval Code',
            minWidth: 100,
            headerClassName: 'colHeader',
            align: 'left',
        }
       /*  {
            field: 'productionStatus',
            headerName: 'Prod Status',
            headerAlign: 'center',
            minWidth: 140,
            headerClassName: 'colHeader',
            align: 'center',
        },
        {
            field: 'neededByDate',
            headerName: 'Date Need',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { neededByDate } }) => {
                return neededByDate?.replace('T', ' ').replace('Z', '')
            },
        },
        {
            field: 'fabricatedOn',
            headerName: 'Date of Fab',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { fabricatedOn } }) => {
                return fabricatedOn?.replace('T', ' ').replace('Z', '')
            },
        },
        {
            field: 'estimateShippedDate',
            headerName: 'Est Ship Date',
            headerAlign: 'center',
            minWidth: 200,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: ({ row: { estimateShippedDate } }) => {
                return estimateShippedDate?.replace('T', ' ').replace('Z', '')
            },
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerAlign: 'center',
            minWidth: 80,
            headerClassName: 'colHeader',
            align: 'center',
            renderCell: (param) => <IconButton color="primary"><Comment /></IconButton>
        },
        {
            field: 'tags',
            headerName: 'Tags',
            headerAlign: 'center',
            headerClassName: 'colHeader',
            align: 'center',
        } */
    ];

    return (
        <Fragment>
            <PageTitleWrapper>
                <h1>Treatment Navigator Dashboard</h1>
            </PageTitleWrapper>
            <Grid xs={12} item container direction="row" className="page">
                {/* <LeftNav onToggle={toggleLeftNav} defaultPage={defaultPage} bl={store} /> */}
                <Grid className={'content' + (isLeftNavExpanded ? ' small' : ' big')}>
                    <Grid container direction="row" sx={{ marginBottom: '15px;' }} justifyContent={'space-between'}>
                        {infoBoxes}
                    </Grid>
                    <Grid className="grid" id={'aireporttech.dashboard' + userId}>
                        <DataGrid columns={columns} loading={loadingFlag} data={dataGrid} />
                    </Grid>
                </Grid>
            </Grid>
            <ModalContainer
                    show={showModal}
                    noScroll={true}
                    title="Enter Stripe Approval Code"
                    onClose={() => {
                        setShowModal(false);
                    }}
                    onClick={() => {}}
                    smallScreen={true}
                    >
            <ApprovalCode id={selectedRow.id} showModal={showModal} onClose={()=>{setShowModal(false); loadData();}}/>
        </ModalContainer>
        </Fragment>
    )
};
export const LabManagementDashboard = observer(TreatmentDashboard);