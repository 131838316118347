// Vendors
import React, { FC, useEffect } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';

// Components
import PatientHeaderLayout from '../../components/PatientProfile/PatientDetailComponents/PatientHeaderLayout';
import { RxManagementDetails } from './components/RxManagementDetails';
import { RxHeaderDetails } from './components/RxHeaderDetails';
import { RxActionButtons } from './components/RxActionButtons';
import { RxDetails } from './components/RxDetails';
import { PatientVisitRecords } from '../../components/PatientProfile/PatientVisitRecordComponents/PatientVisitRecords';

// Stores
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

//Entities
import ProviderPatient from '../../entities/ProviderPatient';

// API
import * as _ from 'lodash';
import { observer } from 'mobx-react';
import { TeethCharting } from '../../components/TeethCharting/TeethCharting';
import { usePrescriptionStore } from '../../stores/Prescription/CreateEdit/MainStoreContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const RXSTATUS = 0;
const VISITRECORD = 1;
// const RXDETAILS = 1;
const TEETHCHARTING = 2;


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid>
                    <Typography>{children}</Typography>
                </Grid>
            )}
        </div>
    );
}
const rxTabs = [
    'RX Status',
    'Visit Records',
    // 'RX Details',
    'Teeth Charting'
];

interface IGIProps {
    prescription: any;
    patient: any;
    labAccessed: boolean;
}

export const RxManagement: FC<IGIProps> = observer(({ prescription, patient, labAccessed }) => {
    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(0);

    const visitStore = useMainStoreContext().visitRecordStore;
    const prescriptionStore = usePrescriptionStore().prescriptionStore;
    const labStore = useMainStoreContext().labCaseReviewStore;

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setActiveTab(newValue);
    };

    useEffect(() => {
        if (patient && labStore && visitStore && prescriptionStore) {
            labStore.setPatientId(patient.id);
            labStore.loadPatient().then(() => {
                visitStore.setVisitPatientId(patient.id);
                visitStore.setId(prescription.visitId ? prescription.visitId : 0);
                visitStore.loadVisit();
                prescriptionStore.loadPrintRxValues();
                setHasLoaded(true);
            });
        }
    }, [])

    return hasLoaded ? (
        <Grid container>
            <Grid item xs={12}>
                <PatientHeaderLayout
                    patientImageUrl={labStore.patient?.patientBigSmileFrontalPhoto}
                    patient={labStore.patient}
                    provider={labStore.patient?.provider}
                    providerPhone={prescription?.provider?.person?.primaryPhone}
                    providerEmail={prescription?.provider?.person?.emailAddress}
                    tabs={rxTabs}
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    isLoading={false}  // Replace with dynamic field
                    statusColumnContent={

                        <RxHeaderDetails
                            prescriptionData={prescription}
                            labAccessed={labAccessed}
                        />
                    }
                    buttonColumnContent={
                        <RxActionButtons
                            patient={patient as unknown as ProviderPatient}
                            prescription={prescription}
                            labAccessed={labAccessed}                        // onClose={undefined}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12} padding={2}>
                <TabPanel value={value} index={RXSTATUS}>
                    <RxManagementDetails
                        prescription={prescription}
                        patient={patient as unknown as ProviderPatient}
                        labAccessed={labAccessed}
                    />
                </TabPanel>
                <TabPanel value={value} index={VISITRECORD}>
                    <div style={{ pointerEvents: 'none' }}>
                        <PatientVisitRecords
                            patient={patient}
                        />
                    </div>
                </TabPanel>
                {/* <TabPanel value={value} index={RXDETAILS}>
                    <RxDetails
                        prescription={prescription}
                        patient={patient as unknown as ProviderPatient}
                        labAccessed={labAccessed}
                    />
                </TabPanel> */}
                <TabPanel value={value} index={TEETHCHARTING}>
                    <div style={{ pointerEvents: 'none' }}>
                        <TeethCharting
                            existingPatient={patient}
                        />
                    </div>
                </TabPanel>
            </Grid>
        </Grid>
    ) : (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
        }}>
            {
                <Grid container direction="row" justifyContent="center">
                    <Grid container justifyContent="center"><Grid>Loading...</Grid></Grid>

                    <CircularProgress sx={{
                        width: "90%",
                        height: "90%"
                    }} />
                </Grid>
            }
        </div>
    );
});
