// Vendors
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  CircularProgress,
  CircularProgressProps,
  styled,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Tooltip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Components
import PatientPersonalInformation from './PatientPersonalInformation';
import ScanCBCT from '../ImagesAndDocs/ScanCbct';
import ScanOcclusion from '../ImagesAndDocs/ScanOcclusion';
import IntraoralImages from '../ImagesAndDocs/IntraoralImages';
import { TeethCharting } from '../TeethCharting/TeethCharting';
import ExtraoralImages from '../ImagesAndDocs/ExtraoralImages';
import Documents from '../ImagesAndDocs/Documents';
import SleepTest from '../ImagesAndDocs/SleepTest';
import { DigitalFormResponses } from '../PatientProfile/PatientModalCustomForm/DigitalFormResponses';

// Store
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { useCreatePatientMainStoreContext, store } from '../../stores/Patient/Create/CreatePatientMainStore';

// Entities
import LegalGuardianData from '../../entities/LegalGuardian';
import { OcclusionModelType } from '../../entities/Enums';
import PatientFile from '../../entities/PatientFIle';

// API
import { observer } from "mobx-react";
import * as _ from "lodash";
import { GlobalUtils } from '../../api/GlobalUtils';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { isMinor } from '../../api/getAge';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { Gateway } from '../../api/Gateway';

// Styling
import '../../scss/main.scss';
import PatientImage from '../../entities/PatientImage';


//'Sleep Test',
const steps = ['General Information', 'Digital Forms', 'Documents', 'Extraoral Images', 'Intraoral Images', 'CBCT Upload', 'Impressions', 'Teeth Charting', 'Sleep Test'];

const BoxBorderBottom = styled(Box)(
  () => `
        border-bottom: transparent 5px solid;
  `
);

interface ICreatePatientProps {
  onClose(): any;
}


function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const CreatePatientWizard: React.FC<ICreatePatientProps> = observer(({ onClose }) => {


  /* const { person, setPerson } = useCreatePatientMainStoreContext().personStore;
  const { patient } = useCreatePatientMainStoreContext().patientStore;
  const { visit } = useCreatePatientMainStoreContext().visitStore; */
  const { validatePatient, legalGuardians, visitStore, patientStore, savePersonAndHandleActions, resetStore } = useCreatePatientMainStoreContext();

  //Create Patient Store
  const { createPatientReset, setIntraoralImageType, setExtraoralImageType, dateOfBirth,
    setPatientId, setCbctMachineBrandType, setMachineBrand3DType, setpatientOcclusionImageType,
    setpatientImageType, softValidateExtraoralImages, softValidateIntraoralImages, softValidateCBCTUploadImages, uploadImages, setPatientImages,
    softValidateOcclusion3DImages, isLoading, loadingText, setIsLoading, setIsLoadingText, setMolarRelationshipType,
    patientExtraoral3DImages, patientExtraoralImages, patientIntraoralImages, patientOcclusionImages, setEthnicities, validateExtraoralImages } = useMainStoreContext().createPatientStore;

  const {createPatientStore } = useMainStoreContext();

  //Create Legal GuardianStore
  const { addGuardian, validateGuardians, guardiansReset } = useMainStoreContext().createLegalGuardianStore;

  //Vitals and measurements Store
  const { vitalsAndMeasurementsReset } = useMainStoreContext().vitalsAndMeasurementsStore;

  //Patient Edit Store
  const { patientHistoryReset, setMedicalList, setDentalList, setAllergyList,
    setCpapList } = useMainStoreContext().patientEditStore;

  //Teeth Chart Store
  const { saveTeethChart, resetTeethChart, softValidateTeethChart } = useMainStoreContext().teethChartStore;

  //Scan Store
  const scanStore = useMainStoreContext().scanStore;

  //Occlusion Store
  const { upsertOcclusionDetails: updateOcclusionDetails, updateOcclusionModelType, occlusionModelTypeId, resetToInitialValues } = useMainStoreContext().occlusionStore;

  //Visit Record Store
  const visitRecordStore = useMainStoreContext().visitRecordStore;

  //Visit Record Store Reset
  const { resetVisit } = useMainStoreContext().visitRecordStore;

  //Treatment Plan Store
  const { validateTreatmentPlan, resetTreatmentPlanStore, saveTreatmentPlan } = useMainStoreContext().treatmentPlanStore;

  //Intake Report Store
  const intakeReportsStore = useMainStoreContext().intakeReportsStore;


  //Local Component States
  const [userID, setUserId] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [toggleValidateModal, setToggleValidateModal] = React.useState(false);
  const [toggleSoftValidateModal, setToggleSoftValidateModal] = React.useState(false);
  const [validationList, setValidationList] = React.useState<any[]>([]);
  const [softValidationList, setSoftValidationList] = React.useState<any[]>([]);


  const { values, handleSubmit } = useFormik<any>({
    initialValues: {
      sleepTestType: "",
      dateOfSleepTest: "",
      treatmentStage: "",
      ahi3: "",
      rdi3: "",
      odi3: "",
      ahi4: "",
      rdi4: "",
      odi4: "",
      sleepHours: "",
      sleepMinutes: "",
      averageOxygenSat: "",
      averageBPM: "",
      averageSnoreDB: "",
      pctREM: "",
      pctDeNadirs: "",
      sleepLatency: "",
      usingOralAppliance: false,
      readByQualifiedPhys: false,
      noDiagnosis: false,
      obstructiveSleepApnea: false,
      upperAirwayResistanceSyndrome: false,
      centralSleepApnea: false,
      otherSleepDiagnosis: false,
    },
    onSubmit: () => { },
    validationSchema: Yup.object().shape({
      sleepTestType: Yup.string()
        .notRequired(),
      dateOfSleepTest: Yup.date()
        .nullable()
        .required('Date of sleep test is required.')
        .max(new Date(), 'The birthday cannot be in the future'),
      treatmentStage: Yup.string()
        .notRequired(),
      ahi3: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
        }),
      rdi3: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
        }),
      odi3: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
        }),
      rdi4: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
        }),
      odi4: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 150 && /^\d+(\.\d{1})?$/.test(value);
        }),
      sleepHours: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 24 && /^\d+(\.\d{1})?$/.test(value);
        }),
      sleepMinutes: Yup.string()
        .notRequired()
        .test('is-valid', 'Invalid Value', (value) => {
          if (!value) return true; // Allow empty values
          const floatValue = parseFloat(value);
          return floatValue >= 0 && floatValue <= 1440 && /^\d+(\.\d{1})?$/.test(value);
        }),
      averageOxygenSat: Yup.string()
        .notRequired(),
      averageBPM: Yup.string()
        .notRequired(),
      averageSnoreDB: Yup.string()
        .notRequired(),
      pctREM: Yup.string()
        .notRequired(),
      pctDeNadirs: Yup.string()
        .notRequired(),
      sleepLatency: Yup.string()
        .notRequired(),
      usingOralAppliance: Yup.boolean()
        .notRequired(),
      readByQualifiedPhys: Yup.boolean()
        .notRequired(),
      noDiagnosis: Yup.boolean()
        .notRequired(),
      obstructiveSleepApnea: Yup.boolean()
        .notRequired(),
      upperAirwayResistanceSyndrome: Yup.boolean()
        .notRequired(),
      centralSleepApnea: Yup.boolean()
        .notRequired(),
      otherSleepDiagnosis: Yup.boolean()
        .notRequired(),
    })
  });

  const [valuesParent, setValuesParent] = useState(values);

  const updateValuesParent = (newValues) => {
    setValuesParent(newValues);
  };

  const isStepOptional = (step: number) => {
    return step === step + 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const validate = (): any[] => {
    let validations = validatePatient();
    validations = validations.concat(validateGuardians());
    setValidationList(validations);
    return validations;
  }

  const softValidate = (validationFunction): any[] => {
    let softValidations: string[] = [];
    softValidations = softValidations.concat(validationFunction());
    setSoftValidationList(softValidations);
    return softValidations;
  }

  useEffect(() => {
    var token = sessionStorage.getItem('token');
    if (token) {
      var tokenData: { roles; id } = jwt_decode(token);
      setUserId(tokenData.id);
    }
    handleReset();
    populateLists();

    if (moment(dateOfBirth).isSame(new Date(), "day")) {
      setDateOptions(dateOfBirth);
    }
  }, []);

  const GENERALINFOSTEP = 0;
  const DIGITALFORMSTEP = 1;
  const DOCUMENTSTEP = 2;
  // const PATIENTDOCUMENTSSTEP = 1;
  // const CLINICALDOCUMENTSSTEP = 2;
  // const PATIENTHISTORYSTEP = 2;
  const EXTRAORALIMAGESSTEP = 3;
  const INTRAORALIMAGESSTEP = 4;
  const CBCTIMAGESSTEP = 5;
  const OCCLUSIONSTEP = 6;
  const TEETHCHARTINGSTEP = 7;
  const SLEEPTESTSTEP = 8;
  const populateLists = () => {
    var medhxopts: any[] = [];
    GlobalUtils.getMedicalHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        medhxopts[s.listItemKey] = s.value;
      });
      setMedicalList(medhxopts);
    });

    var dopts: any[] = [];
    GlobalUtils.getDentalHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        dopts[s.listItemKey] = s.value;
      });
      setDentalList(dopts);
    });

    var copts: any[] = [];
    GlobalUtils.getCpapMadHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        copts[s.listItemKey] = s.value;
      });
      setCpapList(copts);
    });

    var aopts: any[] = [];
    GlobalUtils.getAllergyHxOptions().then((data) => {
      _.forEach(data[0].dictionaryListItems, (s) => {
        aopts[s.listItemKey] = s.value;
      });
      setAllergyList(aopts);
    });


  }

  const handleNext = (isProspect?: boolean) => {
    if (activeStep === GENERALINFOSTEP) {
      if (patientStore.patient.id > 0) {
        setPatientId(patientStore.patient.id);
        setIsLoading(false);
        if (isProspect) {
          onClose();
        } else {
          doNextStep();
        }
      }
      else {
        if (isProspect) {
          patientStore.patient.isProspect = true;
        }
        const validations = validate();
        if (validations.length <= 0) {
          setIsLoadingText("Saving Patient...Please Standby");
          setIsLoading(true);
          savePersonAndHandleActions().then((resp) => {
            setPatientId(patientStore.patient.id);
            if (resp) {
              visitRecordStore.id = visitStore.visit.id;
              visitRecordStore.loadVisit();
              setIsLoading(false);
              if (isProspect) {
                onClose();
              } else {
                doNextStep();
              }
            }
          });
        } else {
          setToggleValidateModal(true);
          return false;
        }
      }

    } else if (activeStep === DIGITALFORMSTEP) {
      setIsLoadingText("Saving Documents...Please Standby");
      setIsLoading(true);
      visitRecordStore.uploadFiles(patientStore.patient.id, visitStore.visit.id).then(() => {
        setIsLoading(false);
        doNextStep();
      });

    } else if (activeStep === DOCUMENTSTEP) {
      setIsLoadingText("Saving Documents...Please Standby");
      setIsLoading(true);
      visitRecordStore.uploadFiles(patientStore.patient.id, visitStore.visit.id).then(() => {
        setIsLoading(false);
        doNextStep();
      });

    }
    // else if (activeStep === PATIENTHISTORYSTEP) {
    //   const validations = softValidate(softValidatePatientHistory);
    //   if (validations.length > 0 && !toggleSoftValidateModal) {
    //     setToggleSoftValidateModal(true);
    //   }
    //   else {
    //     setToggleSoftValidateModal(false);
    //     setIsLoadingText("Saving Patient History...Please Standby");
    //     setIsLoading(true);
    //     let history = getPatientHistory();
    //     setPatientHistory(history as unknown as PatientHistory);
    //     setHistory(history);
    //     history["patientId"] = patient.id;
    //     Gateway.post(`/patient/${patient.id}/history`, history);
    //     setVisitPatientId(patient.id);
    //     let vitals = getVitalsAndMeasurements();
    //     vitals["patientId"] = patient.id;
    //     setPatientVitalsAndMeasurements(vitals);
    //     saveVisit().then((resp) => {
    //       var visit = resp as unknown as PatientVisit;
    //       if (visit) {
    //         setVisit(visit);
    //         setVisitId(visit.id);
    //         saveVitals().then((resp) => {
    //           setVisit(resp);
    //         });
    //       }
    //     });

    //     setIsLoading(false);
    //     doNextStep();
    //   }
    // } 
    else if (activeStep === EXTRAORALIMAGESSTEP) {
      setPatientImages(patientExtraoralImages.concat(patientExtraoral3DImages));
      const softValidations = softValidate(softValidateExtraoralImages);
      const validations = validateExtraoralImages();
      if (softValidations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else if (validations.length > 0) {
        setValidationList(validations);
        setToggleValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading Extraoral Images....Please Standby")
        setIsLoading(true);
        uploadImages(visitStore.visit.id, userID).then(() => {
          setPatientImages([]);
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === INTRAORALIMAGESSTEP) {
      setPatientImages(patientIntraoralImages);
      const validations = softValidate(softValidateIntraoralImages);
      if (validations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading Intraoral Images...Please Standby")
        setIsLoading(true);
        uploadImages(visitStore.visit.id, userID).then(() => {
          setPatientImages([]);
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === CBCTIMAGESSTEP) {
      const validations = softValidate(softValidateCBCTUploadImages);
      if (validations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading CBCT Scans...Please Standby");
        setIsLoading(true);
        scanStore.setVisitId(visitStore.visit.id);
        scanStore.updateCbctDetails().then(() => {
          setPatientImages([]);
          setIsLoading(false);
          doNextStep();
        });
      }
    } else if (activeStep === OCCLUSIONSTEP) {
      setPatientImages(patientOcclusionImages);
      const validations = softValidate(softValidateOcclusion3DImages);
      if (validations.length > 0 && !toggleSoftValidateModal && occlusionModelTypeId !== OcclusionModelType.Physical) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Uploading Occlusion Images...Please Standby");
        setIsLoading(true);
        uploadImages(visitStore.visit.id, userID).then(() => {
          updateOcclusionDetails(visitStore.visit.id).then(() => {
            updateOcclusionModelType().then(() => {
              setPatientImages([]);
              setIsLoading(false);
              doNextStep();
            })
          });
        });
      }
    } else if (activeStep === TEETHCHARTINGSTEP) {
      const validations = softValidate(softValidateTeethChart);
      if (validations.length > 0 && !toggleSoftValidateModal) {
        setToggleSoftValidateModal(true);
      }
      else {
        setIsLoadingText("Saving Teeth Charting...Please Standby");
        setIsLoading(true);
        setPatientId(patientStore.patient.id);
        saveTeethChart().then(() => {
          setIsLoading(false);
          doNextStep();
        });
      }
      visitRecordStore.setId(visitStore.visit.id);
      visitRecordStore.setVisitPatientId(patientStore.patient.id);
      visitRecordStore.loadVisit();
    } else if (activeStep === SLEEPTESTSTEP) {
      // mapFormikToIntakeReport();
      setIsLoadingText("Saving Sleep Test...Please Standby");
      setIsLoading(true);
      _.forEach(intakeReportsStore.reportFiles, (doc, index) => {
        Gateway.postStrongType<any>(`/patient/${patientStore.patient.id}/${visitStore.visit.id}/${intakeReportsStore.userId}/addpatientfile`, doc).then(
          (resp) => {
            var reportInfo = {
              visitId: visitStore.visit.id,
              reportValues: intakeReportsStore.reportFileValues[index],
              patientFileId: resp.id,
              patientId: patientStore.patient.id
            }
            Gateway.post(`/patient/reportinfo/sleepstudy/${intakeReportsStore.userId}`, reportInfo).then(() => {
              var vPatientFiles = visitRecordStore.patientFiles;
              vPatientFiles.push(resp as PatientFile);
              visitRecordStore.setPatientFiles(vPatientFiles);
              visitRecordStore.setPatientUploadFiles([]);
              setIsLoading(false);
              doNextStep();
            })
          }
        )
      });
      onClose();
    }
  }

  const mapFormikToIntakeReport = () => {

    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 1).Value = valuesParent.dateOfSleepTest;
    //intakeReportsStore.reportValuesData.find(x => x.PropertyId === 17).Value = valuesParent.dateOfSleepTest;
    //intakeReportsStore.reportValuesData.find(x => x.PropertyId === 11).Value = valuesParent.sleepTestType;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 25).Value = valuesParent.sleepTestType;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 18).Value = valuesParent.treatmentStage;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 4).Value = valuesParent.ahi3;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 5).Value = valuesParent.rdi3;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 6).Value = valuesParent.odi3;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 8).Value = valuesParent.ahi4;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 10).Value = valuesParent.rdi4;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 9).Value = valuesParent.odi4;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 3).Value = String((Number(valuesParent.sleepHours) * 60) + Number(valuesParent.sleepMinutes));
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 12).Value = valuesParent.averageOxygenSat;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 7).Value = valuesParent.averageBPM;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 15).Value = valuesParent.averageSnoreDB;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 13).Value = valuesParent.pctREM;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 14).Value = valuesParent.pctDeNadirs;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 16).Value = valuesParent.sleepLatency;
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 26).Value = String(valuesParent.usingOralAppliance);
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 19).Value = String(valuesParent.readByQualifiedPhys);
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 20).Value = String(valuesParent.noDiagnosis);
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 21).Value = String(valuesParent.obstructiveSleepApnea);
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 22).Value = String(valuesParent.upperAirwayResistanceSyndrome);
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 23).Value = String(valuesParent.centralSleepApnea);
    intakeReportsStore.reportValuesData.find(x => x.PropertyId === 24).Value = String(valuesParent.otherSleepDiagnosis);
  };

  const doNextStep = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      //createPatientReset();
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setIsLoading(false);
    setActiveStep(0);
    setValidationList([]);
    createPatientReset();
    vitalsAndMeasurementsReset();
    patientHistoryReset();
    setPatientImages([]);
    resetTeethChart();
    guardiansReset();
    resetTreatmentPlanStore();
    resetStore();
    resetVisit();
    resetToInitialValues();
  };

  const LGDEFAULTVALUEPARENT = 18;
  const defaultLGValues = {
    id: 0,
    firstName: "",
    lastName: "",
    relationshipTypeId: LGDEFAULTVALUEPARENT,
    emailAddress: "",
    primaryPhone: "",
    altPhone: "",
    deleted: false,
    patientID: 0
  }

  const setDateOptions = (newValue: any) => {
    if (isMinor(newValue) && legalGuardians.length === 0) {
      addGuardian(new LegalGuardianData(
        defaultLGValues
      ));
    } else if (!isMinor(newValue)) {
      guardiansReset();
    }
    //setLegalGuardians(legalGuardians);
    //setGuardians(legalGuardians);
  }

  React.useMemo(() => {
    setIntraoralImageType(GlobalUtils.getIntraOralImages());
    setExtraoralImageType(GlobalUtils.getExtraOralImages());
    setMachineBrand3DType(GlobalUtils.get3DMachineBrands());
    setCbctMachineBrandType(GlobalUtils.getCBCTMachineBrands());
    setpatientOcclusionImageType(GlobalUtils.getPatientOcclusionImageTypes());
    setpatientImageType(GlobalUtils.getPatientImageTypes());
    setMolarRelationshipType(GlobalUtils.getMolarRelationships());
    setEthnicities(GlobalUtils.getEthnicities());
  }, [])

  const handleImagesChangeOcclusion = (images: PatientImage[]) => {
    createPatientStore.patientOcclusionImages = images;
  }

  return (
    <div style={{
      width: "100%",
      height: "100%"
    }}>
      {isLoading &&
        <div style={{
          // do your styles depending on your needs.
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}>
          {
            <Grid container direction="row" justifyContent="center">
              <Grid container justifyContent="center"><Grid>{loadingText}</Grid></Grid>

              <CircularProgress sx={{
                width: "90%",
                height: "90%"
              }} />
            </Grid>
          }
        </div>
      }
      {
        !isLoading &&
        <Box minHeight={'100%'} >

          <Dialog
            open={toggleValidateModal}
            onClose={() => { }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <Grid display="flex" alignItems="center">
                <Grid flexGrow={1} >Invalid Patient Data</Grid>
                <Grid>
                  {<IconButton className="closeButton" onClick={() => { setToggleValidateModal(false) }}>
                    <CloseIcon />
                  </IconButton>}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid>
                <Grid>Please correct the following:</Grid>
                <Grid>{_.map(validationList, (vl) => {
                  return <Grid>{vl}</Grid>
                })}</Grid>
              </Grid>
              <Grid container padding={1} sx={{ justifyContent: "end" }}><Button variant="contained" onClick={() => { setToggleValidateModal(false) }}>OK</Button></Grid>
            </DialogContent>
          </Dialog>
          <Dialog
            open={toggleSoftValidateModal}
            onClose={() => { }}
            aria-labelledby="form-dialog-soft"
          >
            <DialogTitle id="form-dialog-soft">
              <Grid display="flex" alignItems="center">
                <Grid flexGrow={1} >Invalid Patient Data</Grid>
                <Grid>
                  {<IconButton className="closeButton" onClick={() => { setToggleSoftValidateModal(false) }}>
                    <CloseIcon />
                  </IconButton>}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid marginBottom={3}>
                <Grid marginBottom={1}>Please correct the following:</Grid>
                <Grid>{_.map(softValidationList, (vl) => {
                  return <Grid>{vl}</Grid>
                })}</Grid>
              </Grid>
              <Grid container sx={{ justifyContent: "end" }}>
                <Button variant="contained" onClick={() => { setToggleSoftValidateModal(false) }}>Fix Now</Button>&nbsp;&nbsp;
                <Button variant="contained" onClick={() => { handleNext(false); setToggleSoftValidateModal(false); }}>Complete Later</Button>
              </Grid>
            </DialogContent>
          </Dialog>
          <Stepper activeStep={activeStep}
            alternativeLabel={true}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) { }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps} className="stepperStep">
                  <StepLabel className="stepperStepLabel" {...labelProps}>{label}</StepLabel>
                  {/* <StepConnector className="line"></StepConnector> */}
                </Step>
              );
            })}
          </Stepper>
          <hr />
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button className='vivButton large' onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box>
                {activeStep === GENERALINFOSTEP ? (<Box>
                  <PatientPersonalInformation />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === DIGITALFORMSTEP ? (<Box>
                  {/* <DigitalFormResponses isIntake={true} visit={visitStore.visit} isPatientFormStep={false} patient={patientStore.patient} /> */}
                  <div style={{ textAlign: 'center', padding: '50px' }}>
                    <Typography variant="h4" gutterBottom>
                      Coming Soon
                    </Typography>
                    <Typography variant="body1">
                      This feature is under development and will be available soon. Stay tuned!
                    </Typography>
                  </div>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === DOCUMENTSTEP ? (<Box>
                  <Documents isIntake={true} visit={visitStore.visit} isPatientFormStep={false} patient={patientStore.patient} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }

                {/* {activeStep === PATIENTHISTORYSTEP ? (<Box>
                  <PatientHealthInfo />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                } */}
                {activeStep === EXTRAORALIMAGESSTEP ? (<Box>
                  <ExtraoralImages isIntake={true} isVisit={false} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === INTRAORALIMAGESSTEP ? (<Box>
                  <IntraoralImages isIntake={true} isVisit={false} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === CBCTIMAGESSTEP ? (<Box>
                  <ScanCBCT isIntake={true} visit={undefined} visitId={visitStore.visit.id} patientId={patientStore.patient.id} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === OCCLUSIONSTEP ? (<Box padding={3}>
                  <ScanOcclusion vivosId={patientStore.patient.vivosId} images={patientOcclusionImages} handleImagesChange={handleImagesChangeOcclusion} isIntake={true} visit={visitRecordStore.currentVisit} isRework={false} isPrescription={false} />
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === TEETHCHARTINGSTEP ? (<Box>
                  <Grid><TeethCharting id={patientStore.patient.id} /></Grid>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }
                {activeStep === SLEEPTESTSTEP ? (<Box>
                  <Grid>
                    {/* <Formik initialValues={values} onSubmit={() => { }}> */}
                    <SleepTest visit={visitRecordStore.currentVisit}
                    />
                    {/* </Formik> */}
                  </Grid>
                </Box>) : (<Typography sx={{ mt: 2, mb: 1 }}></Typography>)
                }

                <BoxBorderBottom
                  padding={1}
                  sx={(theme) => ({
                    borderBottomColor: theme.palette.primary.main,
                    width: '100%',
                    backgroundColor: theme.colors.alpha.black[5],
                    position: 'absolute',
                    bottom: '0',
                  })}>
                  <Grid container justifyContent={'right'} alignContent={'center'}>
                    {activeStep !== 0 && <Button
                      variant="contained"
                      color='inherit'
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{
                        mt: { xs: 2, sm: 0, margin: 5 }
                      }}
                    >
                      Back
                    </Button>}
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                      </Button>
                    )}
                    {activeStep === GENERALINFOSTEP &&
                      <Tooltip title={'Quickly create patient profile and add visit records at a later time.'} arrow placement='top'>
                        <Button sx={{
                          mt: { xs: 2, sm: 0, margin: 5 }
                        }} variant="contained" color="info" onClick={() => { handleNext(true) }}>
                          Save & Finish Later
                        </Button></Tooltip>
                    }
                    <Button sx={{
                      mt: { xs: 2, sm: 0, margin: 5 }
                    }} variant="contained" color="primary" onClick={() => { handleNext(false) }}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Save & Continue'}
                    </Button>
                  </Grid>
                </BoxBorderBottom>
              </Box>
            </React.Fragment>
          )}
        </Box>
      }
    </div>
  );
});

