// Vendors
import React, { FC, useEffect, useState } from 'react';
import {
    Box,
    StepLabel,
    Stepper,
    Typography,
    Step,
    Button,
    styled,
    Grid,
    CircularProgress
} from '@mui/material';

// Components
import { PatientComplaints } from './PatientComplaints';
import { DiagnosisPage } from './Diagnosis';
import { TreatmentObjectivesPage } from './TreatmentObjectives';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';
import { TreatmentPlantToDiagnosis } from './TreatmentPlanToDiagnosis';
import { TreatmentPlanPage } from './TreatmentPlan';
import { EstimatedTreatmentTime } from './EstimatedTreatmentTime';

// API
import { observer } from 'mobx-react';
import { useCreateUpdateTreatmentPlanMainStoreContext } from '../../../stores/TreatmentPlan/Create/CreateUpdateTreatmentPlanMainStore';

const steps = [
    'Patient',
    'Diagnosis',
    'Objective',
    'Plan',
    'Review',
];

interface IAirReportSteps {
    onClosed: () => void;
    patient: any;
}

const BoxBorderBottom = styled(Box)(
    () => `
          border-bottom: transparent 5px solid;
    `
);

const enum StepType {
    PatientComplaints,
    Diagnosis,
    TreatmentObjectives,
    TreatmentPlan,
    EstimatedTreatmentTime,
}

export const PatientTreatmentSteps: FC<IAirReportSteps> = observer(({ onClosed, patient }) => {

    const { saveTreatment } = useCreateUpdateTreatmentPlanMainStoreContext();
    const { treatmentPlan, setTreatmentPlan } = useCreateUpdateTreatmentPlanMainStoreContext().treatmentPlanStore;
    const { loadTreatment } = useCreateUpdateTreatmentPlanMainStoreContext();
    const [isLoadingLocal, setIsLoading] =  useState(true);
    const [loadingText, setLoadingText] = useState('');

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };


    const handleNext = () => {
        if (activeStep == StepType.EstimatedTreatmentTime) {
            setIsLoading(true);
            setLoadingText('Saving Treatment Plan');
            saveTreatment().then((treatment) => {
                doNextStep();
                setIsLoading(false);
                onClosed();
                
            });
        }
        else {
            doNextStep();
        }

    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const closeWizzard = () => { };

    const doNextStep = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    useEffect(()=>{
        setIsLoading(true);
        setLoadingText('Loading...');
        loadTreatment().then(()=>{
           setIsLoading(false); 
        });
    },[]);

    React.useEffect(() => {
        setIsLoading(true);
        setLoadingText('Loading...');
        loadTreatment().then(()=>{
           setIsLoading(false); 
        });

        return () => {
            //resetTreatmentPlan();
        };
    }, [patient.id]);

    return (
        <Grid className="stepper-wizard">
            <Stepper activeStep={activeStep} alternativeLabel={true}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: { optional?: React.ReactNode } = {};

                    return (
                        <Step key={label} {...stepProps} className="stepperStep">
                            <StepLabel className="stepperStepLabel" {...labelProps}>
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <hr />
            {isLoadingLocal &&
                <div style={{
                    // do your styles depending on your needs.
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%"
                }}>
                    {
                        <Grid container direction="row" justifyContent="center">
                            <Grid container justifyContent="center"><Grid>{loadingText}</Grid></Grid>
                            <CircularProgress sx={{
                                width: "90%",
                                height: "90%"
                            }} />
                        </Grid>
                    }
                </div>

            }
            {!isLoadingLocal &&
                <>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button className="vivButton large" onClick={() => closeWizzard}>
                                    RETURN TO PATIENT PORTAL
                                </Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        <>
                            {activeStep === StepType.PatientComplaints ? (
                                <Box>
                                    <PatientComplaints
                                        complaint={patient.areasOfConcern}
                                        complaintNote={patient.areasOfConcernNotes}
                                    />
                                </Box>
                            ) : (
                                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                            )}
                            {activeStep === StepType.Diagnosis ? (
                                <Box>
                                    <DiagnosisPage />
                                </Box>
                            ) : (
                                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                            )}
                            {activeStep === StepType.TreatmentObjectives ? (
                                <Box>
                                    <TreatmentObjectivesPage />
                                </Box>
                            ) : (
                                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                            )}
                            {activeStep === StepType.TreatmentPlan ? (
                                <Box>
                                    <TreatmentPlanPage />
                                </Box>
                            ) : (
                                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                            )}

                            {activeStep === StepType.EstimatedTreatmentTime ? (
                                <Box>
                                    <EstimatedTreatmentTime />
                                </Box>
                            ) : (
                                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                            )}


                        </>
                    )}
                    <BoxBorderBottom
                        padding={1}
                        sx={(theme) => ({
                            borderBottomColor: theme.palette.primary.main,
                            width: '100%',
                            backgroundColor: theme.colors.alpha.black[5],
                            position: 'absolute',
                            bottom: '0',
                        })}>
                        <Grid container justifyContent={'right'} alignContent={'center'}>

                            {activeStep !== 0 && (
                                <Button
                                    variant="contained"
                                    color='inherit'
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{
                                        mt: { xs: 2, sm: 0, margin: 5 }
                                    }}
                                >
                                    Back
                                </Button>
                            )}

                            <Button
                                variant='contained'
                                sx={{
                                    mt: { xs: 2, sm: 0, margin: 5 }
                                }}
                                onClick={event => {
                                    handleNext();
                                }}
                            >
                                {activeStep === steps.length - 1 ? 'Finish' : 'Confirm & Continue'}
                            </Button>
                        </Grid>
                    </BoxBorderBottom>
                </>}
        </Grid>
    );
});
