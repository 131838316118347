// Vendors
import React from 'react';
import { FC, useState } from 'react';
import { Button, Grid } from '@mui/material';

// Components
import { CommentsPanel } from '../../../components/CommentsPanel/CommentsPanel';

// Entities
import Patient from '../../../entities/Patient';

// Stores

// API
import { observer } from 'mobx-react';
import * as _ from 'lodash';
import CommentEditStore from '../../../components/CommentsPanel/CommentsEditStore';
import { useMainStoreContext } from '../../../stores/OldStores/MainStore';




interface IGIProps {
    patient: Patient;
    claim: any;
}
let commentsEdit = new CommentEditStore();
export const ClaimActionButtons: FC<IGIProps> = observer(({ claim, patient }) => {
    const [openComments, setOpenComments] = useState(false);
    const store = useMainStoreContext().reworkClaimStore;

    return (

        <Grid container direction="column" paddingLeft={5}>
            {(store.isAISAdminManager || store.isAISSupervisor || store.isAISAdmin) && <><CommentsPanel
                commentType={15553}
                significantId={patient.id}
                isOpen={openComments}
                onClose={() => setOpenComments(!openComments)}
            />
                <Grid item xs={12} padding={1} paddingRight={0}>
                    <CommentsPanel
                        //commentType={15552}
                        commentType={15553}
                        significantId={patient.id}
                        isOpen={commentsEdit.isOpenModal}
                        onClose={commentsEdit.handleCloseModal.bind(this)}
                    />
                    <Button
                        fullWidth
                        onClick={commentsEdit.handleClickModal}
                        variant='contained'
                    >
                        Rework Collaboration
                    </Button>
                </Grid>
                <Grid item xs={12} padding={1} paddingRight={0}>
                    <Button fullWidth variant='contained'>
                        Create Action Item
                    </Button>
                </Grid>
                <Grid item xs={12} padding={1} paddingRight={0}>
                    <Button fullWidth variant='contained'>
                        Generate CCR Form
                    </Button>
                </Grid></>}
        </Grid>

    )
});
