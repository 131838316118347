// Vendors
import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Button,
    Card,
    styled,
    alpha
} from '@mui/material';
import { AiReportSelectVisit } from '../../../components/AiReportOrder/AiReportSelectVisit';
import { RxImpressionSelectVisit } from '../../PrescriptionWizard/RxStepComponents/RxImpressionSelectVisit';
import { observer } from 'mobx-react';
import moment from 'moment';

const CardWrapper = styled(Card)(
    ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[10], 0.05)};
    `
);
const BoxComposedContent = styled(Box)(
    ({ theme }) => `
      position: relative;
      z-index: 7;
  
      .MuiTypography-root {
          color: ${theme.palette.primary.contrastText};
  
          & + .MuiTypography-root {
              color: ${alpha(theme.palette.primary.contrastText, 0.7)};
          }
      }
      
    `
);

interface IGIProps {
    visits?: any;
    singleSelect: boolean;
    rx: boolean;
    height?: string;
    onSelectVisit?: (visit: any) => void;  // Callback to output the selected visit
    onAddNewVisit?: () => void;            // Callback to handle adding a new visit
    onVisitsChange?: (visits: any[]) => void;
    shouldAddImpressionButton?: boolean;
}

export const VisitRecordSectionModule: React.FC<IGIProps> = observer(({ visits, singleSelect, rx, height, onVisitsChange, onSelectVisit, onAddNewVisit, shouldAddImpressionButton = false }) => {
    const [selectedVisit, setSelectedVisit] = useState<any>(null);

    const handleVisitSelect = (visit: any) => {
        setSelectedVisit(visit);
        if (onSelectVisit) {
            onSelectVisit(visit);  // Send the selected visit back to the parent
        }
    };

    useEffect(() => {
        setSelectedVisit(null);
    }, [singleSelect])

    return (
        <Box sx={{ overflowY: 'auto' }}>
            <CardWrapper
                elevation={0}
                sx={{
                    textAlign: 'center',
                    pb: 2.5,
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={(theme) => ({
                                position: `relative`,
                                left: `0`,
                                top: `0`,
                                height: `75px`,
                                borderRadius: `${theme.general.borderRadius}`,
                                background: `${theme.colors.gradients.black2}`,
                            })}
                        >
                            <BoxComposedContent py={2}>
                                <Typography variant='h3'>
                                    Select Visit Records to Use
                                </Typography>
                            </BoxComposedContent>
                        </Box>
                    </Grid>

                    <Grid item xs={12} padding={1}>
                        {rx ? (
                            <RxImpressionSelectVisit
                                visits={visits}
                                singleSelect={singleSelect}
                                onVisitsChange={onVisitsChange}
                                onSelectVisit={handleVisitSelect}  // Handle selection
                            />
                        ) : (
                            <AiReportSelectVisit
                                visits={visits}
                                singleSelect={singleSelect}
                                onSelectVisit={handleVisitSelect}  // Handle selection
                            />
                        )}
                    </Grid>

                    {/* Add New Visit Button */}
                    <Grid item xs={12} padding={2}>
                        {rx && shouldAddImpressionButton && (
                            <Button variant="contained" color="primary" onClick={onAddNewVisit}>
                                Add New Impression
                            </Button>
                        )}
                    </Grid>

                    {selectedVisit && (
                        <Grid item xs={12} padding={2}>
                            <Typography variant="subtitle1">
                                Selected Visit ID{Array.isArray(selectedVisit) ? 's' : ''}: {!Array.isArray(selectedVisit) ? selectedVisit.id : selectedVisit.map(x => x.id).join(', ')}
                            </Typography>
                            <Typography variant="body2">
                                Visit Date{Array.isArray(selectedVisit) ? 's' : ''}: {!Array.isArray(selectedVisit)
                                    ? moment(selectedVisit.createdOn).format('LL')
                                    : selectedVisit.map(x => moment(x.createdOn).format('LL')).join(', ')}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </CardWrapper>
        </Box>
    );
});
