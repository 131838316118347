import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment } from "@mui/material";
import { observable } from "mobx";
import * as React from 'react';
import { observer } from "mobx-react";
import { GlobalUtils } from "../api/GlobalUtils";
import CssTextField from "./CssTextField";
import Item from "./Item";

interface IModalProps {
    title: string;
    currentObj: any;
    fieldName: string;
    isOpen: boolean;
    type: string;
    onSave(value: string): any;
    onClose: (onClose: boolean) => void;
}

export const SingleLineTextModal: React.FC<IModalProps> = observer(
    ({ isOpen, onClose, title, onSave, currentObj, fieldName, type }) => {
        const handleOnClick = () => {
            setHelper("");
            setErr(false);
            onClose(false);
        };
        const [helper, setHelper] = React.useState("");
        const [err, setErr] = React.useState(false);


        function validation(type: string, value: string) {
            let isValid = true;
            if (value !== "") {
                if (type === "email") {
                    isValid = GlobalUtils.validateEmail(value);
                    if (!isValid) {
                        setHelper("The email address is not valid");
                    }
                } else if (type === "tel") {
                    isValid = GlobalUtils.validatePhoneNumber(value);
                    if (!isValid) {
                        setHelper("The phone number is not valid");
                    }
                }
            }
            return isValid;
        };

        function validationInput(type: string, value: string) {
            let isValid = true;
            if (value !== "") {
                if (type === "tel") {
                    isValid = GlobalUtils.validatePhoneCharacter(value);
                }
            }
            return isValid;
        };

        React.useEffect(() => {
            setHelper("");
            setErr(false);
        }, []);

        return (
            <Dialog open={isOpen}>
                <DialogTitle id='form-dialog-title' className='Title'>
                    <Box display='flex' alignItems='center'>
                        <Box
                            sx={{
                                height: '30px'
                            }}
                            flexGrow={1}
                            fontSize='24px'
                            fontWeight={900}
                            fontFamily='Proxima Nova'
                        >
                            {title}
                        </Box>
                        <Box>
                            <IconButton
                                onClick={handleOnClick}
                                sx={{
                                    fontSize: 25,
                                    backgroundColor: 'transparent',
                                    color: 'blue'
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid className={"formBasics"} sx={{ margin: "25px" }}>
                        <Grid xs>
                            <FormControl>
                                <Item>
                                    <Grid className="formLabel" id="hb">{""}</Grid>
                                    <CssTextField id="custom-css-outlined-input"
                                        variant="outlined"
                                        className="TextBox"
                                        size="small"
                                        fullWidth
                                        type={type}
                                        error={err} 
                                        onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                            if(!validationInput(type, event.key)) {
                                                event.preventDefault();
                                            }                                         
                                        }}                                        
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if(currentObj!=null){
                                                currentObj[fieldName] = (event.target.value);
                                            }                                             
                                        }}
                                        value={currentObj[fieldName]}
                                        helperText={helper}
                                    />
                                </Item>
                            </FormControl>
                        </Grid>
                        <Grid container direction="row" xs justifyContent={"flex-end"}>
                            <Grid><Button className="vivButton large invert" onClick={handleOnClick}>Cancel</Button></Grid>
                            <Grid><Button className="vivButton large invert" onClick={() => {
                                if (validation(type, currentObj[fieldName])) {
                                    setHelper("");
                                    setErr(false);
                                    onSave(currentObj[fieldName]);
                                } else {
                                    setErr(true);
                                }
                            }}>Save</Button></Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }
);