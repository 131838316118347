// Vendors
import { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    Card,
    Divider,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TableContainer,
    styled,
    TextField,
    MenuItem
} from '@mui/material';

// API
import { useMainStoreContext } from '../../stores/OldStores/MainStore';

import { AIReportAddOn, AIReportType } from '../../entities/Enums';
import _ from 'lodash';
import { AIReporteCommTypes, shopifyItems } from '../../entities/const';
import { Gateway } from '../../api/Gateway';
import { AIReportSetup } from '../../models/state_models/aireportsetup';
import { AddonsSetup } from '../../models/state_models/addonsSetup';
import { useCreateAIReportMainStore } from '../../stores/AiReport/CreateAIReportMainStore';
import ShopifyCartReview from '../shopify/ShopifyCartReview';

const TableWrapper = styled(Box)(
    ({ theme }) => `
    border: 1px solid ${theme.colors.alpha.black[10]};
    border-bottom: 0;
    margin: ${theme.spacing(4)} 0;
  `
);

export const AiReportPayment = (props) => {
    const [shipping, setShipping] = useState({
        shipToName: '',
        company: '',
        locationType: {
            business: false,
            patientHome: false,
        },
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        homePhone: '',
        mobilePhone: '',
    });

    const { reportTypeId, addOns, reportsList, aiReportSetupSelected } = useCreateAIReportMainStore().aiReportOrder;
    const { cart } = useCreateAIReportMainStore().shopifyStore;

    let decodedToken: any = [];
    const [locationType, setLocationType] = useState({
        business: false,
        patientHome: false,
    });

    const [shopifyAddOns, setShopifyAddOns] = useState<any[]>([]);
    const [total, setTotal] = useState(0);
    const [mainProductPrice, setMainProductPrice] = useState<number>(0);
    const [isPrintedAirway, setIsPrintedAirway] = useState(false);
    const [shopifyItems, setShopifyItems] = useState<AddonsSetup[]>([]);

    const handleLocationType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocationType({ ...locationType, [event.target.name]: event.target.checked });
    };

    const handleShipping = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShipping({ ...shipping, [event.target.name]: event.target.value });
    };

    const { shipToName, company, streetAddress, city, state, zip, email, homePhone, mobilePhone } = shipping;
    useEffect(() => {
        const mainProduct = reportsList.find((t) => t.id === reportTypeId);
        if (mainProduct) {
            setMainProductPrice(Number(mainProduct.price.amount));
        }
    }, []);

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={8} padding={2}>
                <Card
                    sx={{
                        p: 3,
                        mb: 3
                    }}
                >
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                    // height={'150px'}
                    >
                        <Box>
                            <Typography variant="h1" gutterBottom>
                                Airway Intelligence Report
                            </Typography>
                            <Typography variant="h3" color="text.secondary">
                                Order Review
                            </Typography>
                        </Box>
                        {/* <Box display="flex" flexDirection="column" width={'300px'}>
                            <LogoWrapper>
                                <Logo />
                            </LogoWrapper>
                        </Box> */}
                    </Box>
                    <Divider
                        sx={{
                            my: 4
                        }}
                    />
                    <TableWrapper>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Report Type</TableCell>
                                        <TableCell>Add-Ons</TableCell>
                                        <TableCell align='right'>Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        <TableCell>
                                            <Typography variant='h5'>{AIReportType[reportTypeId]}</Typography>
                                        </TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align='right'>
                                            ${aiReportSetupSelected && aiReportSetupSelected.shopifyProduct && aiReportSetupSelected.shopifyProduct.variants && aiReportSetupSelected.shopifyProduct.variants[0] && aiReportSetupSelected.shopifyProduct.variants[0].price && aiReportSetupSelected.shopifyProduct.variants[0].price.amount ? Number(aiReportSetupSelected.shopifyProduct.variants[0].price.amount).toFixed(2) : '0.00'}
                                        </TableCell>
                                    </TableRow>
                                    {addOns.map((t, key: number) => {
                                        var addOnSubtotal = 0;

                                        const variantAddOn = t.shopifyProduct?.variants?.[0];

                                        if (t.ecommerceItem.providerTypePrices && t.ecommerceItem.providerTypePrices.length > 0) {
                                            const lowestPriorityItem = t.ecommerceItem.providerTypePrices.reduce((lowest, item) => {
                                                return item.priority < lowest.priority ? item : lowest;
                                            }, t.ecommerceItem.providerTypePrices[0]);

                                            if (lowestPriorityItem) {
                                                addOnSubtotal += lowestPriorityItem.price;
                                            }
                                        } else if (variantAddOn) {
                                            addOnSubtotal += Number(variantAddOn.price.amount);
                                        }

                                        return (
                                            <TableRow key={key}>
                                                <TableCell>
                                                    <Typography noWrap></Typography>
                                                </TableCell>
                                                <TableCell>{t?.name}</TableCell>
                                                <TableCell align='right'>
                                                    ${variantAddOn ? addOnSubtotal.toFixed(2) : '0.00'}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell align="right">
                                            <Typography
                                                variant='h2'
                                                gutterBottom
                                                color="text.secondary"
                                                fontWeight="bold"
                                            >
                                                Total:
                                            </Typography>
                                        </TableCell>

                                        <TableCell align='right'>
                                            <Typography variant="h3" fontWeight="bold">
                                                {addOns.reduce((acc, t) => {
                                                    const variantAddOn = t.shopifyProduct?.variants?.[0];
                                                    let addOnSubtotal = 0;

                                                    if (t.ecommerceItem.providerTypePrices && t.ecommerceItem.providerTypePrices.length > 0) {
                                                        const lowestPriorityItem = t.ecommerceItem.providerTypePrices.reduce((lowest, item) => {
                                                            return item.priority < lowest.priority ? item : lowest;
                                                        }, t.ecommerceItem.providerTypePrices[0]);

                                                        if (lowestPriorityItem) {
                                                            addOnSubtotal += lowestPriorityItem.price;
                                                        }
                                                    } else if (variantAddOn) {
                                                        addOnSubtotal += Number(variantAddOn.price.amount);
                                                    }

                                                    return acc + addOnSubtotal;
                                                }, mainProductPrice + (aiReportSetupSelected && aiReportSetupSelected.shopifyProduct && aiReportSetupSelected.shopifyProduct.variants && aiReportSetupSelected.shopifyProduct.variants[0] && aiReportSetupSelected.shopifyProduct.variants[0].price && aiReportSetupSelected.shopifyProduct.variants[0].price.amount ? Number(aiReportSetupSelected.shopifyProduct.variants[0].price.amount) : 0)).toFixed(2)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </TableWrapper>
                    {/* <ShopifyCartReview checkoutData={cart} /> */}
                    <Typography variant="subtitle2" gutterBottom sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        Please Proceed to Checkout for Final Pricing
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        Additional information:
                    </Typography>
                    <Typography variant="body2">
                        Once submitted, our team of Airway Professionals will conduct a review of your selected visit records
                        and will complete the requested Custom AI Report.
                    </Typography>
                </Card>
            </Grid>
            {isPrintedAirway &&
                <Grid item xs={4} padding={2}>
                    <Card
                        sx={{
                            p: 3,
                            mb: 3
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            height={'150px'}
                        >

                            <Grid
                                container
                                spacing={1}
                                justifyContent="flex-start"
                            >
                                <Grid item xs={12} paddingBottom={5}>
                                    <Typography variant="h1" gutterBottom>
                                        3D Printed Airway
                                    </Typography>
                                    <Typography variant="h3" color="text.secondary">
                                        Shipping Information
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} padding={1}>
                                    <TextField
                                        variant='outlined'
                                        required
                                        name='shipToName'
                                        value={shipToName}
                                        onChange={handleShipping}
                                        placeholder="Shipping To Name"
                                        label="Shipping To Name"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={8} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        placeholder="Company"
                                        label="Company"
                                        name="company"
                                        value={company}
                                        onChange={handleShipping}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        placeholder="Location Type"
                                        label="Location Type"
                                        name="locationType"
                                        value={locationType}
                                        onChange={handleLocationType}
                                        select
                                        fullWidth  // Add fullWidth to ensure the dropdown takes the full width
                                    >
                                        <MenuItem value="business">Business</MenuItem>
                                        <MenuItem value="patientHome">Patient Home</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        name="streetAddress"
                                        value={streetAddress}
                                        placeholder="Street Address"
                                        onChange={handleShipping}
                                        label="Street Address"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        onChange={handleShipping}
                                        label="City"
                                        fullWidth
                                        name="city"
                                        value={city}
                                        placeholder="City"
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="State"
                                        fullWidth
                                        name="state"
                                        value={state}
                                        placeholder="State"
                                        onChange={handleShipping}
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="Zip"
                                        fullWidth
                                        placeholder="Zip"
                                        name="zip"
                                        value={zip}
                                        onChange={handleShipping}
                                    />
                                </Grid>
                                <Grid item xs={8} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="Email"
                                        fullWidth
                                        placeholder="Email"
                                        name="email"
                                        value={email}
                                        onChange={handleShipping}
                                    />
                                </Grid>
                                <Grid item xs={4} padding={1}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        label="Phone Number"
                                        fullWidth
                                        placeholder="Phone Number"
                                        name="mobilePhone"
                                        value={mobilePhone}
                                        onChange={handleShipping}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                </Grid>
            }
        </Grid>
    );
};
