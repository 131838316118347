// Vendors
import { FC } from 'react';

// Components
import { CollaborationReferral } from './CollaborationReferral';
import { DentalTreatmentPlan } from './Dental';
import { OtherTreatmentPlan } from './Other';
import { PrimaryTherapy } from './PrimaryTherapy';
import { SuplementalTherapies } from './SuplementalTherapies';
import { Surgery } from './Surgery';
import TreatmentPlanningBoard from './TreatmentPlanningBoard';

// API
import { observer } from 'mobx-react';




interface ITreatmentPlanPage {
    patient?: any;
}

export const TreatmentPlanPage: FC<ITreatmentPlanPage> = observer(({ patient }) => {
    return (
        <>
            <TreatmentPlanningBoard />

            {/* <PrimaryTherapy />

            <SuplementalTherapies />

            <DentalTreatmentPlan />

            <CollaborationReferral />

            <Surgery />

            <OtherTreatmentPlan /> */}
        </>
    );
});
