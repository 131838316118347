import { observable, action, makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { MyoCorrectStore } from '../../api/MyoCorrectStore';
import { MainStore } from '../../stores/OldStores/MainStore';
import { MyoCorrectFilters } from '../../entities/Enums';
import { Gateway } from '../../api/Gateway';
import { Provider } from '../../entities/Provider';

const navItems = [
    'PATIENTS',
    'NOTIFICATIONS',
    'ORDERS',
    'MY PRACTICE',
    'REPORTS',
    'TEETH CHARTING',
    'ADMIN',
    'SIGN OUT',
    'CASES',
    'REPORTING',
    'SCHEDULING',
    'MY CASES',
    'CUSTOM FORMS',
    'TEAMS',
    'PENDING CASES',
    'CUSTOM FORM BUILDER',
    'ADMIN TOOLS',
    'CLINICAL ADVISOR',
    'MY NETWORK',
    'LAB MANAGEMENT',
    'CLAIMS',
    'CATALOG',
    'CONSULTATIONS',
    'DASHBOARD'
];
export default class LeftNavBL {
    @observable private _toggleCollapse: boolean = false;
    @observable private _currentNavItem: number = -1;
    @observable private _username: string = '';
    @observable private _roles: any = [];
    @observable private _userRoles: any = [];
    @observable private _isMyoCorrectAdvisor = false;
    @observable private _isMyoCorrectAdvocate = false;
    @observable private _isMyoCorrectAdmin = false;
    @observable private _isVivosAdmin = false;
    @observable private _isProvider = false;
    @observable private _isAiReportAdmin = false;
    @observable private _isAiReportTech = false;
    @observable private _isClinicalAdvisor = false;
    @observable private _isClinicalAdvocate = false;
    @observable private _isAISAdminManager = false;
    @observable private _isAISSupervisor = false;
    @observable private _isAISAdmin = false;
    @observable private _isComplianceManager = false;
    @observable private _isLabAdmin = false;
    @observable private _isLabTech = false;
    @observable private _isLabManager = false;
    @observable private _isExternalLab = false;
    @observable private _isTreatmentNav = false;
    @observable private _isAISSpecialist = false;
    @observable private _store: MyoCorrectStore;
    @observable private _mainstore: MainStore;
    @observable ProviderID: number = 0;
    @observable providerFullName: string = "";

    constructor() {
        makeAutoObservable(this);
        var token = sessionStorage.getItem('token');
        if (token) {
            var tokenData: { roles; id; userFullName; providerId } = jwt_decode(token);
            this.userRoles = JSON.parse(tokenData.roles);
            this.username = tokenData.userFullName;
            this.ProviderID = tokenData.providerId;
        }
        this._mainstore = new MainStore();
        this._store = new MyoCorrectStore(this._mainstore);
    }

    get toggleCollapse(): boolean {
        return this._toggleCollapse;
    }

    @action
    set toggleCollapse(value: any) {
        this._toggleCollapse = value;
    }

    get currentNavItem(): number {
        return this._currentNavItem;
    }

    @action
    set currentNavItem(value: any) {
        this._currentNavItem = value;
    }

    get navItems(): any {
        return navItems;
    }

    get username(): string {
        return this._username;
    }

    @action
    set username(value: string) {
        this._username = value;
    }

    get roles(): any {
        return this._roles;
    }

    @action
    set roles(value: any) {
        this._roles = value;
    }

    get store(): any {
        return this._store;
    }

    @action
    set store(value: any) {
        this._store = value;
    }

    get isMyoCorrectAdvisor(): any {
        return this._isMyoCorrectAdvisor;
    }

    @action
    set isMyoCorrectAdvisor(value: any) {
        this._isMyoCorrectAdvisor = value;
    }

    get isMyoCorrectAdvocate(): any {
        return this._isMyoCorrectAdvocate;
    }

    @action
    set isMyoCorrectAdvocate(value: any) {
        this._isMyoCorrectAdvocate = value;
    }

    get isMyoCorrectAdmin(): any {
        return this._isMyoCorrectAdmin;
    }

    @action
    set isMyoCorrectAdmin(value: any) {
        this._isMyoCorrectAdmin = value;
    }

    get isVivosAdmin(): any {
        return this._isVivosAdmin;
    }

    @action
    set isVivosAdmin(value: any) {
        this._isVivosAdmin = value;
    }
    get isProvider(): any {
        return this._isProvider;
    }

    @action
    set isProvider(value: any) {
        this._isProvider = value;
    }

    @action
    set isAiReportAdmin(value: boolean) {
        this._isAiReportAdmin = value;
    }

    get isAiReportAdmin(): boolean {
        return this._isAiReportAdmin;
    }

    @action
    set isAiReportTech(value: boolean) {
        this._isAiReportTech = value;
    }

    get isAiReportTech(): boolean {
        return this._isAiReportTech;
    }

    @action
    set isLabAdmin(value: boolean) {
        this._isLabAdmin = value;
    }

    get isLabAdmin(): boolean {
        return this._isLabAdmin;
    }

    @action
    set isLabTech(value: boolean) {
        this._isLabTech = value;
    }

    get isLabTech(): boolean {
        return this._isLabTech;
    }

    @action
    set isLabManager(value: boolean) {
        this._isLabManager = value;
    }

    get isLabManager(): boolean {
        return this._isLabManager;
    }

    @action
    set isClinicalAdvisor(value: boolean) {
        this._isClinicalAdvisor = value;
    }

    get isClinicalAdvisor(): boolean {
        return this._isClinicalAdvisor;
    }

    @action
    set isClinicalAdvocate(value: boolean) {
        this._isClinicalAdvocate = value;
    }

    get isClinicalAdvocate(): boolean {
        return this._isClinicalAdvocate;
    }

    @action
    set isAISAdminManager(value: boolean) {
        this._isAISAdminManager = value;
    }

    get isAISAdminManager(): boolean {
        return this._isAISAdminManager;
    }

    @action
    set isAISSupervisor(value: boolean) {
        this._isAISSupervisor = value;
    }

    get isAISSupervisor(): boolean {
        return this._isAISSupervisor;
    }

    @action
    set isAISAdmin(value: boolean) {
        this._isAISAdmin = value;
    }

    get isAISAdmin(): boolean {
        return this._isAISAdmin;
    }

    @action
    set isComplianceManager(value: boolean) {
        this._isComplianceManager = value;
    }

    get isComplianceManager(): boolean {
        return this._isComplianceManager;
    }

    @action
    set isExternalLab(value: boolean) {
        this._isExternalLab = value;
    }

    get isExternalLab(): boolean {
        return this._isExternalLab;
    }

    @action
    set isTreatmentNav(value: boolean) {
        this._isTreatmentNav = value;
    }

    get isTreatmentNav(): boolean {
        return this._isTreatmentNav;
    }

    @action
    set isAISSpecialist(value: boolean) {
        this._isAISSpecialist = value;
    }

    get isAISSpecialist(): boolean {
        return this._isAISSpecialist;
    }

    get userRoles(): any {
        return this._userRoles;
    }

    @action
    set userRoles(value: any) {
        this._userRoles = value;
    }

    setDefaultNav = () => {
        // if(this.isMyoCorrectAdmin || this.isMyoCorrectAdvisor || this.isMyoCorrectAdvocate) {
        //     this.currentNavItem = 8;
        // }
        // else if(this.isProvider) {
        //     this.currentNavItem = 0;
        // }
        // else if (this.isAiReportAdmin || this.isAiReportAdmin) {
        //     this.currentNavItem = 14;
        // }
    };

    toggleMenuCollapse = () => {
        this.toggleCollapse = !this.toggleCollapse;
    };

    onNavMenuItemClick = (event: any) => {
        this.currentNavItem = _.indexOf(
            this.navItems,
            event.currentTarget.innerText.indexOf('\n') > 0
                ? event.currentTarget.innerText.substring(0, event.currentTarget.innerText.indexOf('\n'))
                : event.currentTarget.innerText,
        );
    };

    onSignOut = () => {
        sessionStorage.removeItem('token');
    };

    getSelectedStatus = (id: number): boolean => {
        return this.currentNavItem === id;
    };

    onFilter = (filter: string) => {
        this.store.filterPatients(this.store.patientData, MyoCorrectFilters[filter]);
    };

    checkRoles = () => {
        for (let role of this.userRoles) {
            if (role.Name === process.env.REACT_APP_VIVOS_ADMIN_NAME) {
                this.isVivosAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_PROVIDER) {
                this.isProvider = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVISOR) {
                this.isMyoCorrectAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADVOCATE) {
                this.isMyoCorrectAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_MYOCORRECT_ADMIN) {
                this.isMyoCorrectAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_ADMIN) {
                this.isAiReportAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_LAB_ADMIN) {
                this.isLabAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_LAB_MANAGER) {
                this.isLabManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_CLINICAL_ADVISOR) {
                this.isClinicalAdvisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_CLINICAL_ADVOCATE) {
                this.isClinicalAdvocate = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN_MANAGER) {
                this.isAISAdminManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SUPERVISOR) {
                this.isAISSupervisor = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_ADMIN) {
                this.isAISAdmin = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_COMPLIANCE_MANAGER) {
                this.isComplianceManager = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_EXTERNAL_LAB) {
                this.isExternalLab = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_TREATMENT_NAV) {
                this.isTreatmentNav = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AI_REPORT_TECH) {
                this.isAiReportTech = true;
            }
            if (role.Name === process.env.REACT_APP_VIVOS_AIS_SPECIALIST) {
                this.isAISSpecialist = true;
            }
        }
        return;
    };

    @action
    getProviderData = async () => {
        var url = '/provider/details/' + this.ProviderID;
        await Gateway.get(url).then(data => {
            let providerData = data as unknown as Provider;
            this.providerFullName = providerData.lastName + ", " + providerData.firstName
        });
    };
}
