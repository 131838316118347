import { observable } from 'mobx';

export class Node<T> {
  @observable node: T;

  constructor(node: T) {
    this.node = node;
  }
}

export class Edge<T> {
  @observable edges: Node<T>[];

  constructor(edges: Node<T>[] = []) {
    this.edges = edges;
  }
}
