// Vendors
import React, { FC, useState, useEffect } from "react";
import {
    Card,
    Box,
    CardHeader,
    List,
    IconButton,
    Typography,
    ListItemText,
    ListItemButton,
    styled,
    useTheme,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    ListItem,
    DialogContent,
    TextField,
    MenuItem
} from '@mui/material';

// API
import { observer } from "mobx-react-lite"
import { useMainStoreContext } from "../../../stores/OldStores/MainStore";

const RootWrapper = styled(Card)(
    ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    color: ${theme.colors.alpha.trueWhite[100]};
    
    .MuiList-root {
      margin-bottom: ${theme.spacing(2)};

      .MuiButtonBase-root {
        color: ${theme.colors.alpha.trueWhite[70]};
      
        &:hover, &:active, .active {
            background: ${theme.colors.alpha.trueWhite[10]};
            color: ${theme.colors.alpha.trueWhite[100]};
        }
      }
      

    }
`
);

const ListItemWrapper = styled(ListItemButton)(
    () => `
    border-radius: 0;
`
);

const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
`
);

const TypographySecondary = styled(Typography)(
    ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
`
);

interface IGIProps {
    claimData: any,
    erfQuote: number,
    erfEstimateUpcharge: number,
    erfApprovalDate: any,
    erfApprovalStatus: any,
    modelPrintFee: number
    onErfTotalAmountChange: (newTotalAmount: string) => void;
}

interface ComponentOption {
    name: string;
    fee: number;
}

export const LabEstimatedRepairFees: FC<IGIProps> = observer(({
    claimData, erfQuote, erfEstimateUpcharge, erfApprovalDate, erfApprovalStatus, modelPrintFee, onErfTotalAmountChange }) => {
    const store = useMainStoreContext().reworkClaimStore;
    const [isConversionPopupOpen, setIsConversionPopupOpen] = useState(false);
    const [isComponentPopupOpen, setIsComponentPopupOpen] = useState(false);
    const [isAdjustmentPopupOpen, setIsAdjustmentPopupOpen] = useState(false);
    const [selectedConversionOption, setSelectedConversionOption] = useState('No Conversion');
    const [conversionFee, setConversionFee] = useState(0);
    const [selectedComponentOptions, setSelectedComponentOptions] = useState<ComponentOption[]>([{ name: 'No Component Replacement Fees', fee: 0 }]);
    const [componentFee, setComponentFee] = useState(0);
    const [adjustmentValue, setAdjustmentValue] = useState(0);
    const [erfStatus, setErfStatus] = useState(store.vwClaim?.erfstatusId);

    useEffect(() => {
        const sumOfComponentFees = selectedComponentOptions.reduce((acc, curr) => acc + curr.fee, 0);
        setComponentFee(sumOfComponentFees);
    }, [selectedComponentOptions]);

    const conversionOptions = [
        { name: 'No Conversion', fee: 0 },
        { name: 'DNA to mRNA', fee: 200 },
        { name: 'DNA to mmRNA', fee: 300 },
        { name: 'mRNA to DNA', fee: 0 },
        { name: 'mRNA to mmRNA', fee: 100 },
        { name: 'mmRNA to DNA', fee: 0 },
        { name: 'mmRNA to mRNA', fee: 0 },
    ];
    const componentOptions = [
        { name: 'No Component Replacement Fees', fee: 0 },
        { name: 'mRNA Dorsal Fin Replacement', fee: 75 },
        { name: 'mRNA RPE Fin Replacement', fee: 50 },
        { name: 'mmRNA Arm Replacement', fee: 105 },
    ];

    const handleComponentOptionSelection = (option) => {
        const isSelected = selectedComponentOptions.some(selected => selected.name === option.name);
        if (isSelected) {
            setSelectedComponentOptions(selectedComponentOptions.filter(selected => selected.name !== option.name));
        } else {
            setSelectedComponentOptions([...selectedComponentOptions, option]);
        }
    };

    const updatedClaim = {
        ...claimData,
        erfTotalAmount: (erfQuote + erfEstimateUpcharge + conversionFee + modelPrintFee + componentFee + adjustmentValue).toFixed(2)
    }

    return (
        <RootWrapper
            sx={{
                height: '100%'
            }}
        >
            <Grid padding={2}>
                <TypographyPrimary variant="h4">Estimated Repair Fee</TypographyPrimary>
                <TypographyPrimary variant="h5">Vivos ONLY</TypographyPrimary>
            </Grid>
            <Grid>
                <TypographyPrimary variant="h4" textAlign={'center'}>Fee Breakdown</TypographyPrimary>
            </Grid>
            <List disablePadding>
                <ListItemWrapper>
                    <ListItemText
                        primary="Lab Quote"
                        primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                    />
                    <Typography
                        align="right"
                        variant="body1"
                        noWrap
                        sx={{
                            mr: 2
                        }}
                    >
                        ${erfQuote.toFixed(2)}
                    </Typography>
                </ListItemWrapper>
                <ListItemWrapper>
                    <ListItemText
                        primary="Standard Upcharge"
                        primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                    />
                    <Typography
                        align="right"
                        variant="body1"
                        noWrap
                        sx={{
                            mr: 2
                        }}
                    >
                        ${erfEstimateUpcharge.toFixed(2)}
                    </Typography>
                </ListItemWrapper>
                <ListItemWrapper onClick={() => setIsConversionPopupOpen(true)}>
                    <ListItemText
                        primary={selectedConversionOption}
                        primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                    />
                    <Typography
                        align="right"
                        variant="body1"
                        noWrap
                        sx={{
                            mr: 2
                        }}
                    >
                        ${conversionFee.toFixed(2)}
                    </Typography>
                </ListItemWrapper>
                {selectedComponentOptions.map(y => (
                    <>
                        <ListItemWrapper onClick={() => setIsComponentPopupOpen(true)}>
                            <ListItemText
                                primary={y.name}
                                primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                            />
                            <Typography
                                align="right"
                                variant="body1"
                                noWrap
                                sx={{
                                    mr: 2
                                }}
                            >
                                ${y.fee.toFixed(2)}
                            </Typography>


                        </ListItemWrapper>
                    </>
                ))}
                <ListItemWrapper>
                    <ListItemText
                        primary="Print Fee not in lab ERF"
                        primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                    />
                    <Typography
                        align="right"
                        variant="body1"
                        noWrap
                        sx={{
                            mr: 2
                        }}
                    >
                        ${modelPrintFee.toFixed(2)}
                    </Typography>
                </ListItemWrapper>
                <ListItemWrapper onClick={() => setIsAdjustmentPopupOpen(true)}>
                    <ListItemText
                        primary="Fee Adjustment"
                        primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                    />
                    <Typography
                        align="right"
                        variant="body1"
                        noWrap
                        sx={{
                            mr: 2
                        }}
                    >
                        ${adjustmentValue.toFixed(2)}
                    </Typography>
                </ListItemWrapper>
            </List>
            <CardHeader
                title={'Total Estimated Repair Fee'}
                action={
                    <>
                        <TypographyPrimary
                            variant="h6"
                            fontWeight="bold"
                            sx={{
                                pr: 0.5,
                                display: 'inline-flex'
                            }}
                        >
                            ${(erfQuote + erfEstimateUpcharge + conversionFee + modelPrintFee + componentFee + adjustmentValue).toFixed(2)}
                            {onErfTotalAmountChange((erfQuote + erfEstimateUpcharge + conversionFee + modelPrintFee + componentFee + adjustmentValue).toFixed(2))}
                        </TypographyPrimary>
                    </>
                }
            />

            <Box
                sx={{
                    mb: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button variant="contained" color={'warning'}>
                    Request Provider Approval
                </Button>
            </Box>
            <Grid paddingBottom={1}>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="h5" textAlign={'center'}>
                            Approval Date:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5" textAlign={'center'}>
                            <b>{erfApprovalDate}</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography variant="h5" textAlign={'center'}>
                            Approval Status:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            InputProps={{ style: { backgroundColor: 'white', height: '50px' } }}
                            id={'erfStatus'}
                            select
                            value={erfStatus}
                            onChange={event => {
                                const statusId = Number(event.target.value);
                                setErfStatus(statusId);
                                store.handleErfStatusChange(store.vwClaim, statusId);
                            }}
                        >
                            {
                                store.erfStatusValues.filter(x => x.listItemKey !== 'NOTUSED').map((erf) =>
                                    (<MenuItem key={erf.id} value={erf.id}>{erf.name}</MenuItem>))
                            }
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={isConversionPopupOpen} onClose={() => setIsConversionPopupOpen(false)}>
                <DialogTitle>Select Conversion Option</DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={1}>
                        {conversionOptions.map((option) => (
                            <Grid item xs={6} key={option.name}>
                                <Button
                                    fullWidth
                                    key={option.name}
                                    variant={selectedConversionOption === option.name ? "contained" : "outlined"}
                                    onClick={() => {
                                        setSelectedConversionOption(option.name);
                                        setConversionFee(option.fee);
                                        setIsConversionPopupOpen(false); // Close the dialog
                                    }}
                                >
                                    {option.name} - ${option.fee}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={isComponentPopupOpen} onClose={() => setIsComponentPopupOpen(false)} maxWidth='md'>
                <DialogTitle>Select Conversion Option</DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={1}>
                        {componentOptions.map(option => (
                            <Grid item xs={6} key={option.name}>
                                <Button
                                    fullWidth
                                    variant={selectedComponentOptions.some(y => y.name === option.name) ? "contained" : "outlined"}
                                    onClick={() => handleComponentOptionSelection(option)}
                                >
                                    {option.name}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={isAdjustmentPopupOpen} onClose={() => setIsAdjustmentPopupOpen(false)} maxWidth='md'>
                <DialogTitle>Input any necessary adjustments to fee breakdown</DialogTitle>
                <DialogContent>
                    <Grid container direction='row' spacing={1}>
                        <TextField
                            label="Adjustment Value"
                            type="number"
                            fullWidth
                            value={adjustmentValue}
                            onChange={(e) => setAdjustmentValue(Number(e.target.value))}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                </DialogContent>
            </Dialog>
        </RootWrapper>

    );
})