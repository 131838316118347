import React, { FC } from 'react';

import { ModalContainer } from '../ModalContainer';
import { AirReportSteps } from './AirReportSteps';
import { useMainStoreContext } from '../../stores/OldStores/MainStore';
import { useCreateAIReportMainStore } from '../../stores/AiReport/CreateAIReportMainStore';

interface IAiReportModal {
    isOpen: boolean;
    onClose: () => void;
    patient?: any;
    submission?: any;
    visit?: any;
    reportsOrdered: number;
}

export const AiReportModal: FC<IAiReportModal> = ({ isOpen, patient, submission, visit, onClose, reportsOrdered }) => {
    const { resetVisitRecord, resetAiReportOrderData } = useCreateAIReportMainStore().aiReportOrder;

    return (
        <ModalContainer
            show={isOpen}
            onClose={() => {
                onClose();
                resetVisitRecord();
                resetAiReportOrderData();
            }}
            title={`Create AI Report Order - ${patient?.firstName} ${patient?.lastName} - ${patient?.vivosId}`}

            onClick={() => {
                onClose();
                resetVisitRecord();
                resetAiReportOrderData();
            }}
        >
            <AirReportSteps patient={patient} submission={submission} visit={visit} onClosed={onClose} reportsOrdered={reportsOrdered} />
        </ModalContainer >
    );
};
